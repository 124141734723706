import React, {useState, useEffect, useCallback, useMemo, useRef, useContext} from "react";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import TableComponent from "../TablaPaginada/index";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import AuthContext from "../../context/Auth/AuthContext";
import InventarioContext from "../../context/Inventario/InventarioContext";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import CatTipoProductoContext from "../../context/CatTipoProducto/CatTipoProductoContext";
import BajaInventarioContext from "../../context/BajaInventario/BajaInventarioContext";
import CatEstatusBajaInventarioContext from "../../context/CatEstatusBajaInventario/CatEstatusBajaInventarioContext";

import useWindowDimensions from "../../hooks/dimensionsHook";
import formatDateWithoutHour from "../../utils/formatDateWithoutHour";

export default function BajasInventario(props) {
    const {width} = useWindowDimensions();

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_cancelar_baja: false,
        confirm_alert_motivo: false,
        motivo: "",
        archivos: null,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [pageCount, setPageCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [rowSeleccionada, setRowSeleccionada] = useState(null);
    const fetchIdRef = useRef(0);
    const [bajaInventarioSeleccionado, setBajaInventarioSeleccionado] = useState(null);
    const [toggleSearchBy, setToggleSearchBy] = useState(false);

    const tipoProductoCtx = useContext(CatTipoProductoContext);
    const {tiposProductos, listarProductoTiposProductos} = tipoProductoCtx;

    const almacenesCtx = useContext(AlmacenesContext);
    const {almacenes, listarAlmacenes} = almacenesCtx;

    const catEstBajInvCtx = useContext(CatEstatusBajaInventarioContext);
    const {catEstatusBajaInventario, listarCatEstatusBajaInventario} = catEstBajInvCtx;

    const inventarioCtx = useContext(InventarioContext);
    const {cleanInventarioSeleccionado} = inventarioCtx;

    const ctxAuth = useContext(AuthContext);
    const {usuario} = ctxAuth;

    const administrador =
        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
        usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
        usuario.authorities.includes("ROLE_DIRECCION"); 

    const bajaInvCtx = useContext(BajaInventarioContext);
    const {
        bajaInventarios,
        filtrosBajaInventario,
        listarBajasInventario,
        setFiltrosBajaInventario,
        uploadActualizarBajaInventario,
        exportArchivoBack
    } = bajaInvCtx;

    const buscarPorFiltros = async (e) => {
        e.preventDefault();
        fetchData({pageSize: 10, pageIndex: 0, byBusquedaFiltros: true});
    };

    const onChange = (e) => {
        setFiltrosBajaInventario({
            ...filtrosBajaInventario,
            [e.target.name]: e.target.value,
        });
    };

    const limpiarFiltros = () => {
        setFiltrosBajaInventario({
            folioBajaInventario: "",
            idCatTipoProducto: "",
            numIdentificacion: "",
            codigoEAN: "",
            nombreComercial: "",
            lote: "",
            idCatAlmacen: "",
            idCatEstatusBajaInventario: "",
        });
    };

    const toggleSearch = () => {
        limpiarFiltros();
        setToggleSearchBy(!toggleSearchBy);
    };

    const handdleCheckedBajaInventario = useCallback(
        (row) => {
            if (rowSeleccionada === null) {
                setBajaInventarioSeleccionado(row.values);
                setRowSeleccionada(row.id);
            } else if (rowSeleccionada === row.id) {
                setRowSeleccionada(null);
                setBajaInventarioSeleccionado(null);
            }
        },
        [rowSeleccionada]
    );

    const fetchData = async ({pageSize, pageIndex}) => {
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: false,
            });
        }
    };
 
    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);
            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);                  
            }
            const response = await listarBajasInventario({page, limit});

            // const count = response.data.count;
            // setRowSeleccionada(null);
            // if (count % limit > 0) {
            //     setPageCount(Math.ceil(count / limit));
            // } else {
            //     setPageCount(count / limit);
            // }

            setLoadingData(false);
            return response;
        } catch (e) {
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "idBajaInventario",
                Cell: ({row}) => {
                    return (
                        <input
                            disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
                            checked={rowSeleccionada === row.id ? true : false}
                            type="checkbox"
                            onChange={() => handdleCheckedBajaInventario(row)}
                        />
                    );
                },
                show: true,
                disableFilters: true,
                disableSortBy: true,
            },
            {Header: "Folio", accessor: "folioBajaInventario", show: true},
            {Header: "Tipo", accessor: "tipoProducto", show: true},
            {Header: "Identificación", accessor: "numIdentificacion", show: true},
            {Header: "Código EAN", accessor: "codigoEAN", show: true},
            {Header: "Descripción", accessor: "nombreComercial", show: true},
            {Header: "Lote", accessor: "lote", show: true},
            {
                Header: "Caducidad",
                accessor: "caducidad",
                Cell: ({row}) => {
                    return row.original.caducidad ? formatDateWithoutHour(row.original.caducidad) : "SIN CADUCIDAD";
                },
                show: true,
            },
            {Header: "Piezas", accessor: "piezas", show: true},
            {
                Header: "Motivo",
                accessor: "motivo",
                Cell: ({row}) => {
                    return (
                        <>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                type="button"
                                onClick={() => {
                                    setUISweetAlert({
                                        ...UISweetAlert,
                                        confirm_alert_motivo: true,
                                        motivo: row.values.motivo,
                                    });
                                }}>
                                Motivo
                            </button>
                        </>
                    );
                },
                show: true,
            },
            {
                Header: "Documentos",
                accessor: "archivos",
                Cell: ({row}) => {
                    return (
                        <>
                            {row.values.archivos && row.values.archivos.length > 0 ? (
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick={() => {
                                        setUISweetAlert({
                                            ...UISweetAlert,
                                            confirm_alert_documentos: true,
                                            archivos: row.original.archivos,
                                        });
                                    }}>
                                    Documentos
                                </button>
                            ) : null}
                        </>
                    );
                },
                show: true,
            },
            {Header: "Almacén", accessor: "almacen", show: true},
            {
                Header: "Estatus",
                accessor: "estatusbajainventario",
                Cell: ({row}) => {
                    if (
                        row.values.estatusbajainventario[row.values.estatusbajainventario.length - 1].catEstatusbajainventario
                            .idCatEstatusBajaInventario == 1
                    ) {
                        return (
                            <span
                                className="badge badge-info"
                                style={{
                                    height: "1.9rem",
                                    paddingTop: "0.6rem",
                                    border: "1px solid #dfdada",
                                    color: "black",
                                    fontWeight: "bold",
                                    //verde
                                    backgroundColor: "#C1E05C",
                                    borderRadius: "5px",
                                }}>
                {
                    row.values.estatusbajainventario[row.values.estatusbajainventario.length - 1].catEstatusbajainventario
                        .estatusBajaInventario
                }
              </span>
                        );
                    } else {
                        return (
                            <span
                                className="badge badge-info"
                                style={{
                                    height: "1.9rem",
                                    paddingTop: "0.6rem",
                                    border: "1px solid #dfdada",
                                    color: "white",
                                    fontWeight: "bold",
                                    //morado
                                    backgroundColor: "#602a96",
                                    borderRadius: "5px",
                                }}>
                {
                    row.values.estatusbajainventario[row.values.estatusbajainventario.length - 1].catEstatusbajainventario
                        .estatusBajaInventario
                }
              </span>
                        );
                    }
                },
                show: true,
            },
        ],
        [rowSeleccionada, handdleCheckedBajaInventario]
    );

    const confirmCancelarBajaInventario = async () => {
        if (!bajaInventarioSeleccionado) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione una baja de inventario",
            });
            return;
        }

        if (
            bajaInventarioSeleccionado.estatusbajainventario[bajaInventarioSeleccionado.estatusbajainventario.length - 1]
                .catEstatusbajainventario.idCatEstatusBajaInventario != 1
        ) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "No se puede cancelar la baja de inventario",
            });
            return;
        }

        bajaInventarioSeleccionado.idEstatusNuevo = 4;
        setBajaInventarioSeleccionado(bajaInventarioSeleccionado);

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_cancelar_baja: true,
        });
    };

    const onChangeCancelacion = async (e) => {
        let value = e.target.value;
        setBajaInventarioSeleccionado({
            ...bajaInventarioSeleccionado,
            [e.target.name]: value,
        });
    };

    const cleanBajaInventarioSeleccionado = async () => {
        delete bajaInventarioSeleccionado.aprobado;
        delete bajaInventarioSeleccionado.motivo;
        delete bajaInventarioSeleccionado.idEstatusNuevo;
        setBajaInventarioSeleccionado(bajaInventarioSeleccionado);
    };

    const checkUploadCancelacionBaja = async () => {
        let errorCancelacionBajaInventario = false;

        if (!bajaInventarioSeleccionado.motivo) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: `Por favor, introduzca el motivo de la cancelación`,
            });
            errorCancelacionBajaInventario = true;
            return;
        }

        if (!errorCancelacionBajaInventario) {
            setLoading(true);
            const res = await uploadActualizarBajaInventario(bajaInventarioSeleccionado);
            if (res.data.error) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else if (res) {
                cleanBajaInventarioSeleccionado();

                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_alert_cancelar_baja: false,
                    dynamic_description: "Se guardaron los cambios de manera exitosa.",
                });

                fetchData({pageSize: 10, pageIndex: 0, byBusquedaFiltros: true});
            }
        }
    };

    const onChangeBajaInventario = async (e) => {
        let value = e.target.value;

        if (e.target.name == "aprobado") {
            value = !bajaInventarioSeleccionado.aprobado;
            setBajaInventarioSeleccionado({
                ...bajaInventarioSeleccionado,
                motivo: "",
                idEstatusNuevo: value ? 2 : 3,
                [e.target.name]: value,
            });
        } else {
            setBajaInventarioSeleccionado({
                ...bajaInventarioSeleccionado,
                [e.target.name]: value,
            });
        }
    };

    const confirmAprobarRechazarBajaInventario = async () => {
        if (!bajaInventarioSeleccionado) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione una baja de inventario",
            });
            return;
        }

        if (
            bajaInventarioSeleccionado.estatusbajainventario[bajaInventarioSeleccionado.estatusbajainventario.length - 1]
                .catEstatusbajainventario.idCatEstatusBajaInventario != 1
        ) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "No se puede cancelar la baja de inventario",
            });
            return;
        }

        bajaInventarioSeleccionado.idEstatusNuevo = 2;
        bajaInventarioSeleccionado.aprobado = true;
        setBajaInventarioSeleccionado(bajaInventarioSeleccionado);

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_aprobar_rechazar: true,
        });
    };

    const checkUploadAprobarRechazarBajaInventario = async () => {
        if (!bajaInventarioSeleccionado.aprobado && !bajaInventarioSeleccionado.motivo) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: `Por favor, introduzca el motivo de rechazo`,
            });
            return;
        }

        setLoading(true);
        const res = await uploadActualizarBajaInventario(bajaInventarioSeleccionado);
        if (res.data.error) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            cleanBajaInventarioSeleccionado();

            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_aprobar_rechazar: false,
                dynamic_description: "Se guardaron los cambios de manera exitosa.",
            });

            fetchData({pageSize: 10, pageIndex: 0, byBusquedaFiltros: true});
        }
    };

    useEffect(() => {
        listarProductoTiposProductos();
        listarCatEstatusBajaInventario();
        listarAlmacenes(false,administrador);
        cleanInventarioSeleccionado();
    }, []);

    const exportArchivo = async (archivo) => {
        // convert image file to base64 string
        const archivoVo = {
            rutaArchivo: archivo.rutaArchivo,
            nombreArchivo: archivo.nombreArchivo,
            mimeType: archivo.mimeType,
        };
        const res = await exportArchivoBack(archivoVo);
        return res;
    };

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Bajas de Inventario</h3>

            <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mb-4 mt-6 mr-6 hover:text-white"
                type="button"
                onClick={toggleSearch}>
                {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
            </button>

            <Card hidden={!toggleSearchBy}>
                <form onSubmit={(e) => buscarPorFiltros(e)}>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                                <Row
                                    className="form-group"
                                    style={{
                                        marginBottom: "0",
                                    }}>
                                    <Col sm={10} className="flex col-sm-10">
                                        <input
                                            className="form-control mr-2.5"
                                            type="text"
                                            name="folioBajaInventario"
                                            placeholder="Escribe tu folio"
                                            onChange={onChange}
                                            value={filtrosBajaInventario.folioBajaInventario}
                                        />
                                        <button
                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                            style={{
                                                marginRight: "2.5rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            type="submit">
                                            Buscar
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </form>
            </Card>

            <Card hidden={toggleSearchBy}>
                <CardBody>
                    <form onSubmit={(e) => buscarPorFiltros(e)}>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Tipo Producto</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatTipoProducto"
                                            onChange={onChange}
                                            value={filtrosBajaInventario.idCatTipoProducto}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {tiposProductos.map((tipoProducto) => {
                                                return (
                                                    <>
                                                        <option value={tipoProducto.idCatTipoProducto}>{tipoProducto.tipoProducto}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Identificación</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="numIdentificacion"
                                            onChange={onChange}
                                            value={filtrosBajaInventario.numIdentificacion}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Código EAN</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="codigoEAN"
                                            onChange={onChange}
                                            value={filtrosBajaInventario.codigoEAN}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Descripción</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nombreComercial"
                                            onChange={onChange}
                                            value={filtrosBajaInventario.nombreComercial}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Lote</label>
                                    <Col sm={7}>
                                        <input className="form-control" name="lote" onChange={onChange} value={filtrosBajaInventario.lote}/>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Almacén</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatAlmacen"
                                            onChange={onChange}
                                            value={filtrosBajaInventario.idCatAlmacen}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {almacenes.map((almacen) => {
                                                return (
                                                    <>
                                                        <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Estatus Baja Inventario</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatEstatusBajaInventario"
                                            onChange={onChange}
                                            value={filtrosBajaInventario.idCatEstatusBajaInventario}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {catEstatusBajaInventario.map((estatus) => {
                                                return (
                                                    <>
                                                        <option value={estatus.idCatEstatusBajaInventario}>{estatus.estatusBajaInventario}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4" type="submit">
                                    Aplicar Filtros
                                </button>
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick={limpiarFiltros}>
                                    Limpiar Filtros
                                </button>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>

            <Card className="mt-4">
                <CardBody>
                    <Row>
                        <Col>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                type="button"
                                hidden={usuario.authorities.includes("OP_ALMACEN_APROBAR_BAJA_INVENTARIO") ? false : true}
                                onClick={() => confirmAprobarRechazarBajaInventario()}>
                                Aprobar / Cancelar
                            </button>

                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                type="button"
                                hidden={usuario.authorities.includes("OP_ALMACEN_CANCELAR_BAJA_INVENTARIO") ? false : true}
                                onClick={() => confirmCancelarBajaInventario()}>
                                Cancelar
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TableComponent
                                pageCount={pageCount}
                                fetchData={fetchData}
                                columns={columns}
                                loading={loadingData}
                                data={bajaInventarios}
                                indexOfPage={controlledPageIndex}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {UISweetAlert.confirm_alert_aprobar_rechazar ? (
                <SweetAlert
                    title="Aprobación / Rechazo de Baja Inventario"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => checkUploadAprobarRechazarBajaInventario()}
                    closeOnClickOutside={false}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_aprobar_rechazar: false,
                        });
                        cleanBajaInventarioSeleccionado();
                    }}
                    style={{
                        width: width && width < 851 ? "100%" : "60%",
                    }}>
                    <div className="mt-2">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={bajaInventarioSeleccionado.aprobado}
                                    onChange={onChangeBajaInventario}
                                    name="aprobado"
                                    color="primary"
                                />
                            }
                            label={bajaInventarioSeleccionado.aprobado ? "Aprobado" : "Rechazado"}
                        />
                        {!bajaInventarioSeleccionado.aprobado ? (
                            <>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Motivo</label>
                                    <Col sm={7}>
                    <textarea
                        type="text"
                        className="form-control"
                        onChange={onChangeBajaInventario}
                        name="motivo"
                        value={bajaInventarioSeleccionado.motivo}
                    />
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_cancelar_baja ? (
                <SweetAlert
                    title="Cancelación baja de inventario"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => checkUploadCancelacionBaja()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_cancelar_baja: false,
                        });
                        cleanBajaInventarioSeleccionado();
                    }}
                    style={{
                        width: width && width < 851 ? "100%" : "60%",
                    }}>
                    <div className="mt-4">
                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Motivo</label>
                            <Col sm={7}>
                <textarea
                    type="text"
                    className="form-control"
                    onChange={onChangeCancelacion}
                    name="motivo"
                    value={bajaInventarioSeleccionado.motivo}
                />
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_motivo ? (
                <SweetAlert
                    title="Motivo baja de inventario"
                    warning
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    closeOnClickOutside={false}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_motivo: false,
                            motivo: "",
                        })
                    }
                    style={{
                        width: width && width < 851 ? "100%" : "60%",
                    }}>
                    <div className="mt-4">
                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Motivo</label>
                            <Col sm={7}>
                                <textarea type="text" className="form-control" disabled={true} name="motivo" value={UISweetAlert.motivo}/>
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_documentos ? (
                <SweetAlert
                    title="Documentos baja de inventario"
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    closeOnClickOutside={false}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_documentos: false,
                            archivos: null,
                        })
                    }
                    style={{
                        width: width && width < 851 ? "100%" : "50%",
                    }}>
                    <div className="mt-2">
                        <Row>
                            <Col>
                                {UISweetAlert.archivos.map((archivo) => {
                                    return (
                                        <button onClick={() => exportArchivo(archivo)} className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-2  mt-2" type="button">
                                            {archivo.nombreArchivo}
                                        </button>
                                    );
                                })}
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
