/* eslint-disable import/no-anonymous-default-export */
import {
  LISTAR_USUARIOS,
  SET_USUARIO_EDITAR,
  EDITAR_USUARIO,
  ERROR_EDITAR_USUARIO,
  SET_NUEVO_USUARIO,
  NUEVO_USUARIO,
  ERROR_NUEVO_USUARIO,
  REINICIARPASSWORD_USUARIO,
  ERROR_REINICIARPASSWORD_USUARIO,
  LISTAR_USUARIOS_SIN_MEDICO,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_USUARIOS:
    case EDITAR_USUARIO:
    case REINICIARPASSWORD_USUARIO:
      return {
        ...state,
        usuarios: action.payload,
      };
    case SET_USUARIO_EDITAR:
      return {
        ...state,
        usuarioEditar: action.payload,
      };
    case LISTAR_USUARIOS_SIN_MEDICO:
      return {
        ...state,
        usuariosSinMedico: action.payload,
      };
    case SET_NUEVO_USUARIO:
      return {
        ...state,
        nuevoUsuario: action.payload,
      };
    case NUEVO_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.concat(action.payload),
      };
    case ERROR_EDITAR_USUARIO:
    case ERROR_NUEVO_USUARIO:
    case ERROR_REINICIARPASSWORD_USUARIO:
      return {
        ...state,
      };
    default:
      return state;
  }
};
