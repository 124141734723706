import React, { useContext } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import VentaMostradorContext from "../../context/VentaMostrador/VentaMostradorContext";

export default function InfoGeneral(props) {
  // ACCESO A ESTADO GLOBAL - VENTA MOSTRADOR
  const ctxVentaMostrador = useContext(VentaMostradorContext);
  const { ventaMostradorSeleccionada } = ctxVentaMostrador;

  // useEffect(() => {}, []);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Información General</h3>

      <Card
        className="mt-10 card"
        style={{
          padding: "1.5rem 1.5rem 1.5rem",
        }}>
        <CardBody>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Fecha</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={ventaMostradorSeleccionada.fechaVenta ? ventaMostradorSeleccionada.fechaVenta.split("T")[0] : ""}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Cliente</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ventaMostradorSeleccionada.catClientes
                        ? `${ventaMostradorSeleccionada.catClientes.catPersona.nombres} ${ventaMostradorSeleccionada.catClientes.catPersona.apellidoPaterno} ${ventaMostradorSeleccionada.catClientes.catPersona.apellidoMaterno}`
                        : ventaMostradorSeleccionada.cliente
                        ? ventaMostradorSeleccionada.cliente
                        : ""
                    }
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Paciente</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ventaMostradorSeleccionada.catPacientes
                        ?  ventaMostradorSeleccionada.catPacientes.catPersona.nombres + " " +
                           ventaMostradorSeleccionada.catPacientes.catPersona.apellidoPaterno + " " +
                           ventaMostradorSeleccionada.catPacientes.catPersona.apellidoMaterno + " " 
                        : ""
                    }
                  />
                </Col>
              </Row>              

              
            </Col>

            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Folio Venta Mostrador</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={ventaMostradorSeleccionada.folioMostrador ? ventaMostradorSeleccionada.folioMostrador : ""}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Vendedor</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={ventaMostradorSeleccionada.usuarioVendedor ? ventaMostradorSeleccionada.usuarioVendedor.nombre : ""}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Tipo de Venta</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ventaMostradorSeleccionada.catTipoVentaMostrador
                        ? ventaMostradorSeleccionada.catTipoVentaMostrador.tipoVentaMostrador
                        : ""
                    }
                  />
                </Col>
              </Row>

            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
