import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";
import ValesContext from "../../context/Vales/ValesContext";
import AuthContext from "../../context/Auth/AuthContext";

export default function InfoGeneral(props) {
  // ACCESO A ESTADO GLOBAL - VALES
  const ctxVales = useContext(ValesContext);
  const { valeSeleccionado, exportCarta } = ctxVales;

  const [comentarioTemp, setComentarioTemp] = useState(null);

  const ctxAuth = useContext(AuthContext);
  const {usuario} = ctxAuth;

  function checkIfEvidencia(vale) {
    const estatusVale = vale && vale.estatusvales && vale.estatusvales.length > 0 ? vale.estatusvales[0] : null;
    const archivo =
      estatusVale && estatusVale.archivovales && estatusVale.archivovales.length > 0 ? estatusVale.archivovales[0] : null;
    const archivoProceso = archivo && archivo.archivosproceso ? archivo.archivosproceso : null;
    const archivoVo = archivoProceso
      ? {
          rutaArchivo: archivoProceso.rutaArchivo ? archivoProceso.rutaArchivo : "",
          nombreArchivo: archivoProceso.rutaArchivo ? archivoProceso.nombreArchivo : "",
          mimeType: archivoProceso.mimeType ? archivoProceso.mimeType : "",
        }
      : null;
    return archivoVo;
  }
  //global.comentarioGlobal = valeSeleccionado && valeSeleccionado.valecomentarios? valeSeleccionado.valecomentarios[valeSeleccionado.valecomentarios.length-1].comentario : "";
  const getArchivo = async (vale) => {
    const archivoVo = checkIfEvidencia(vale);

    if (archivoVo) {
      const res = await exportCarta(archivoVo);
      return res;
    }
  };

  const ModificaVariableGlobal = async (value) => {
    //valeSeleccionado.valecomentarios[valeSeleccionado.valecomentarios.length-1].comentario = value;
    global.comentarioGlobal = value;
    setComentarioTemp(value);
  };

  const onChangeDataValeAseguradora = async (e) => 
  {
    let value = e.target.value;
    ModificaVariableGlobal(value);
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Información General</h3>

      <Card
        className="mt-10 card"
        style={{
          padding: "1.5rem 1.5rem 1.5rem",
        }}>
        <CardBody>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Fecha</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={valeSeleccionado ? valeSeleccionado.fechaVale : ""}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Paciente</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      valeSeleccionado
                        ? `${valeSeleccionado.catPacientes.catPersona.nombres} ${valeSeleccionado.catPacientes.catPersona.apellidoPaterno} ${valeSeleccionado.catPacientes.catPersona.apellidoMaterno}`
                        : ""
                    }
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Médico Tratante</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      valeSeleccionado
                        ? `${valeSeleccionado.catMedicos.catPersona.nombres} ${valeSeleccionado.catMedicos.catPersona.apellidoPaterno} ${valeSeleccionado.catMedicos.catPersona.apellidoMaterno}`
                        : ""
                    }
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Tipo Vale</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={valeSeleccionado ? valeSeleccionado.catTipovale.tipoVale : ""}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Folio Vale</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={valeSeleccionado ? valeSeleccionado.folioVale : ""}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Usuario Solicitante</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={valeSeleccionado ? valeSeleccionado.usuarioSolicitante.nombre : ""}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Aseguradora</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      valeSeleccionado
                        ? [valeSeleccionado.catTipovale.idCatTipoVale == 1 ? "N/A" : valeSeleccionado.catAseguradoras.razonSocial]
                        : ""
                    }
                  />
                </Col>
              </Row>              
            </Col>

            <Col xl={6} lg={12} md={12}>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Esquema de Tratamiento</label>
                <Col sm={7}>
                  <textarea
                    rows={5}
                    disabled
                    className="form-control"
                    value={valeSeleccionado && valeSeleccionado.esquemaTratamiento ? valeSeleccionado.esquemaTratamiento : ""}
                  />
                  {/* <input
                        disabled
                        className="form-control"
                        type="text"
                        value={valeSeleccionado && valeSeleccionado.esquemaTratamiento ? valeSeleccionado.esquemaTratamiento : "" }
                        /> */}
                </Col>
              </Row>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Evidencias del esquema</label>
                <Col sm={7}>
                  {checkIfEvidencia(valeSeleccionado) ? (
                    <button
                      name="evidencias"
                      className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                      type="button"
                      onClick={() => getArchivo(valeSeleccionado)}
                      style={{
                        marginRight: "2.5rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Ver evidencia
                    </button>
                  ) : (
                    "Sin evidencias"
                  )}
                </Col>
              </Row>              
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Comentario</label>
                <Col sm={7}>
                  <textarea
                    rows={5}
                    disabled = {valeSeleccionado? usuario.authorities.includes("ROLE_FARMACIA") && !valeSeleccionado.historicoVale ? false : true : true}
                    className="form-control"
                    value={comentarioTemp ? comentarioTemp : valeSeleccionado && valeSeleccionado.valecomentarios[valeSeleccionado.valecomentarios.length-1]? valeSeleccionado.valecomentarios[valeSeleccionado.valecomentarios.length-1].comentario : global.comentarioGlobal }
                    onChange={onChangeDataValeAseguradora}             
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
