import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

import formatNumber from "../../utils/index";
import AuthContext from "../../context/Auth/AuthContext";
import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import CatTipoSolicitudCompraContext from "../../context/CatTipoSolicitudCompra/CatTipoSolicitudCompraContext";
import CatTipoOrdenCompraContext from "../../context/CatTipoOrdenCompra/CatTipoOrdenCompraContext";
import EstatusSolicitudesComprasContext from "../../context/EstatusSolicitudCompra/EstatusSolicitudCompraContext";

import TableComponent from "../TablaPaginadaSinUpdate/index";

export default function SolicitudesCompras(props) {
  const [toggleSearchBy, setToggleSearchBy] = useState(false);
  const [loading, setLoading] = useState(false);

  const [rowSeleccionada, setRowSeleccionada] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);

  const [solicitudCompraSeleccionada, setSolicitudCompraSeleccionada] = useState(null);

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await listarSolicitudesComprasPaginadas({ page, limit });

      // Cantidad de datos disponibles en la tabla pacientes de la base de datos
      const count = response.count;

      setRowSeleccionada(null);
      setSolicitudCompraSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    console.log("fetchData is being called");

    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
    // setLoadingData(true);
    // fetchAPIData({
    //   page: pageIndex + 1,
    //   limit: pageSize,
    //   byBusquedaFiltros: false,
    // });
  };

  const handleCheckedSolicitudCompraPaginada = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setSolicitudCompraSeleccionada({
          ...row.original,
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setSolicitudCompraSeleccionada(null);
      } else if (rowSeleccionada !== row.id) {
        setRowSeleccionada(row.id);
        setSolicitudCompraSeleccionada({
          ...row.original,
        });
      }
    },
    [rowSeleccionada]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idSolicitudCompra",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handleCheckedSolicitudCompraPaginada(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Folio", accessor: "folioSolicitudCompra" },
      {
        Header: "Fecha SC",
        accessor: "fechaSolicitudCompra",
        Cell: ({ row }) => {
          return <>{row.original.fechaSolicitudCompra ? row.original.fechaSolicitudCompra.split("T")[0] : ""}</>;
        },
      },
      {
        Header: "Fecha Requisición",
        accessor: "fechaRequisicion",
        Cell: ({ row }) => {
          return <>{row.original.fechaRequisicion ? row.original.fechaRequisicion.split("T")[0] : ""}</>;
        },
      },      
      { Header: "Tipo Solicitud de Compra", accessor: "tipoSolicitudCompra" },
      { Header: "Tipo Compra", accessor: "tipoOrdenCompra" },
      { Header: "Comprador", accessor: "usuarioCompradorNombre" },
      { Header:  "Vale / Venta", accessor: "vales", show: true , className:"fixed-width-150",
        Cell: ({ value }) => (
          <div style={{ width: '90px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
            {value}
          </div>
        )
      },      
      {
        Header: "Estatus",
        accessor: "estatusActual",
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.idEstatusActual == 6 || row.original.idEstatusActual == 7 || row.original.idEstatusActual == 8 ? (
                <span
                  className="badge badge-info"
                  style={{
                    height: "1.9rem",
                    paddingTop: "0.6rem",
                    border: "1px solid #dfdada",
                    color: "white",
                    fontWeight: "bold",
                    //morado
                    backgroundColor: "#602a96",
                    borderRadius: "5px",
                  }}>
                  {row.original.estatusActual}
                </span>
              ) : (
                <span
                  className="badge badge-info"
                  style={{
                    height: "1.9rem",
                    paddingTop: "0.6rem",
                    border: "1px solid #dfdada",
                    color: "black",
                    fontWeight: "bold",
                    //verde
                    backgroundColor: "#C1E05C",
                    borderRadius: "5px",
                  }}>
                  {row.original.estatusActual}
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: () => <div className="text-center">Importe Total</div>,
        accessor: "importeTotal",

        Cell: ({ row }) => {
          return <div className="text-right">{formatNumber(row.original.importeTotal + row.original.ivaTotal)}</div>;
        },
      },
      {
        Header: "Histórico",
        accessor: "historico",
        Cell: ({ row }) => {
          return (
            <Link
              to={`/solicitudescompra/historico/${row.original.idSolicitudCompra}`}
              className="btn btn-sm btn-primary-orange-umo"
              style={{
                background: "#f93",
                color: "white",
                fontWeight: "bold",
              }}>
              Histórico
            </Link>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [rowSeleccionada, handleCheckedSolicitudCompraPaginada]
  );

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_cancelar_solicitud: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  //ACCESO AL ESTADO GLOBAL - Usuarios
  const ctxUsuarios = useContext(UsuariosContext);
  const { usuarios, listarUsuarios } = ctxUsuarios;

  //ACCESO AL ESTADO GLOBAL - Tipos de Solicitud de compra
  const ctxTiposSolicitudCompra = useContext(CatTipoSolicitudCompraContext);
  const { tiposSolicitudCompra, listarTiposSolicitudCompra } = ctxTiposSolicitudCompra;

  //ACCESO AL ESTADO GLOBAL - Tipos de compra
  const ctxTiposOrdenCompra = useContext(CatTipoOrdenCompraContext);
  const { tiposOrdenCompra, listarTiposOrdenCompra } = ctxTiposOrdenCompra;

  //ACCESO AL ESTADO GLOBAL - Estatus de Solicitud de compra
  const ctxEstatusSolicitudCompra = useContext(EstatusSolicitudesComprasContext);
  const { estatusSolicitudCompra, listarEstatusSolicitudCompra } = ctxEstatusSolicitudCompra;

  const solicitudesComprasContext = useContext(SolicitudesComprasContext);
  const {
    solicitudesComprasFiltradas,

    filtrosSolicitudesCompras,
    setFiltrosSolicitudesCompras,
    limpiarSolicitudCompraSeleccionada,

    limpiarNuevaSolicitudCompra,
    uploadCancelarSolicitudCompra,
    cancelarSolicitudCompra,
    setCancelarSolicitudCompra,
    listarSolicitudesComprasPaginadas,
  } = solicitudesComprasContext;

  const onChange = (e) => {
    const { name, value, checked } = e.target;
    if (name == "fechaInicial") {
      setFiltrosSolicitudesCompras({
        ...filtrosSolicitudesCompras,
        [name]: value,
        fechaFinal: value,
      });
    } else if (name == "aprobadas") {
      setFiltrosSolicitudesCompras({
        ...filtrosSolicitudesCompras,
        [name]: checked,
      });
    } else {
      setFiltrosSolicitudesCompras({
        ...filtrosSolicitudesCompras,
        [name]: value,
      });
    }
  };

  const toggleSearch = () => {
    setToggleSearchBy(!toggleSearchBy);
    limpiarFiltros();
  };

  const buscarPorFolio = (e) => {
    e.preventDefault();
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const buscarPorFiltros = (e) => {
    e.preventDefault();
    if (filtrosSolicitudesCompras.fechaInicial && !filtrosSolicitudesCompras.fechaFinal) {
      return;
    }

    if (filtrosSolicitudesCompras.fechaFinal) {
      filtrosSolicitudesCompras.fechaFinal = filtrosSolicitudesCompras.fechaFinal + "T23:59:59";
    }

    fetchData({ pageSize: 10, pageIndex: 0 });

    if (filtrosSolicitudesCompras.fechaFinal) {
      filtrosSolicitudesCompras.fechaFinal = filtrosSolicitudesCompras.fechaFinal.split("T")[0];
    }
  };

  const limpiarFiltros = () => {
    setFiltrosSolicitudesCompras({
      fechaInicial: "",
      tipoSolicitudCompra: "",
      tipoOrdenCompra: "",
      fechaFinal: "",
      usuarioComprador: "",
      folioSolicitudCompra: null,
      estatusSolicitudCompra: "",
      importeMaximo: null,
    });
  };

  const onChangeImporteMaximo = (e) => {
    let value = e.target.value;
    value = Number(value.replace("$", ""));

    setFiltrosSolicitudesCompras({
      ...filtrosSolicitudesCompras,
      importeMaximo: value,
    });
  };

  const isSolicitudCompraChecked = async () => {
    const solicitudChecked = solicitudCompraSeleccionada !== null;
    if (!solicitudChecked) {
      //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione una solicitud",
      });
    }

    return solicitudChecked;
  };

  const registrarCotizacion = async () => {
    const solicitudChecked = await isSolicitudCompraChecked();

    if (solicitudChecked) {
      const estatusActual = solicitudCompraSeleccionada.idEstatusActual;

      if (estatusActual == 2) {
        //lo llevamos a la vista para registar la cotizacion
        props.history.push(`/solicitudescompra/cotizacion/${solicitudCompraSeleccionada.idSolicitudCompra}`);
        setSolicitudCompraSeleccionada(null);
      } else {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "No se puede registrar la cotización",
        });
      }
    }
  };

  const solicitarOrdenCompraProveedor = async () => {
    const solicitudChecked = await isSolicitudCompraChecked();
    if (solicitudChecked) {
      const estatusActual = solicitudCompraSeleccionada.idEstatusActual;
      if (estatusActual == 5) {
        //Debera existir un servicio que solicite la OC
        props.history.push(`/solicitudescompra/ordencompra/${solicitudCompraSeleccionada.idSolicitudCompra}`);
        setSolicitudCompraSeleccionada(null);
      } else {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "No se puede solicitar la orden de compra",
        });
      }
    }
  };

  const autorizarCotizacion = async () => {
    const solicitudChecked = await isSolicitudCompraChecked();
    if (solicitudChecked) {
      const estatusActual = solicitudCompraSeleccionada.idEstatusActual;

      if (estatusActual == 3 || estatusActual == 4) {
        //los llevamos a la vista para autorizar cotizacion
        props.history.push(`/solicitudescompra/autorizacion/${solicitudCompraSeleccionada.idSolicitudCompra}`);
        setSolicitudCompraSeleccionada(null);
      } else {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "No se puede autorizar la solicitud de compra ya que esta " + solicitudCompraSeleccionada.estatusActual,
        });
      }
    }
  };

  const cancelarSolicitudCompraConfirm = async () => {
    const solicitudChecked = await isSolicitudCompraChecked();
    if (solicitudChecked) {
      const estatusActual = solicitudCompraSeleccionada.idEstatusActual;

      if (estatusActual == 6 || estatusActual == 7 || estatusActual == 8) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "No se puede autorizar la solicitud de compra ya que esta FINALIZADA",
        });
      } else {
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_cancelar_solicitud: true,
        });
      }
    }
  };

  const onChangeCancelarSolicitudCompra = async (e) => {
    setCancelarSolicitudCompra({
      ...cancelarSolicitudCompra,
      [e.target.name]: e.target.value,
    });
  };

  const solicitudCompraCancelar = async () => {
    //servicio para cancelar la solicitud

    if (cancelarSolicitudCompra.comentarioEstatus == "" || cancelarSolicitudCompra.fechaCancelacion == "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor llene la información necesaria.",
      });
      return;
    }

    setLoading(true);

    const res = await uploadCancelarSolicitudCompra({
      idEstatusNuevo: 8,
      idSolicitudCompra: solicitudCompraSeleccionada.idSolicitudCompra,
      comentarioEstatus: cancelarSolicitudCompra.comentarioEstatus,
    });

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_cancelar_solicitud: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error al cancelar la solicitud de compra. Inténtalo por favor más tarde.",
      });
    } else if (!res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_cancelar_solicitud: false,
        dynamic_description: "Solicitud de compra cancelada",
      });
      setCancelarSolicitudCompra({
        fechaCancelacion: new Date().toISOString().slice(0, 10),
        comentarioEstatus: "",
      });
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setSolicitudCompraSeleccionada(null);
    }
  };

  useEffect(() => {
    limpiarSolicitudCompraSeleccionada();
    limpiarNuevaSolicitudCompra();
    listarUsuarios();
    listarTiposSolicitudCompra();
    listarTiposOrdenCompra();
    listarEstatusSolicitudCompra();
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Lista de Solicitudes de Compra</h3>

        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearch}>
          {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
        </button>

        {usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CREAR") ? (
          <Link to={`/solicitudescompra/nuevo`}>
            <button className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6">
              Nueva Solicitud de Compra
            </button>
          </Link>
        ) : null}

        {usuario.authorities.includes("ROLE_COMPRAS") ? (
        <Link to={`/requerimientos/pendientes`}>
          <button className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-6 mr-6">Requerimientos Pendientes</button>
        </Link>
        ) : null}        

        <Card
          hidden={!toggleSearchBy}
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFolio(e)}>
            <CardBody>
              <Row>
                <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                  <Row
                    className="form-group"
                    style={{
                      marginBottom: "0",
                    }}>
                    <Col sm={10} className="flex col-sm-10">
                      <input
                        className="form-control mr-2.5"
                        type="text"
                        name="folioSolicitudCompra"
                        placeholder="Escribe tu folio"
                        onChange={onChange}
                        value={
                          filtrosSolicitudesCompras.folioSolicitudCompra ? filtrosSolicitudesCompras.folioSolicitudCompra : ""
                        }
                      />
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                        style={{
                          marginRight: "2.5rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        type="submit">
                        Buscar
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>

        <Card
          hidden={toggleSearchBy}
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFiltros(e)}>
            <CardBody>
              <Row>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaInicial"
                        onChange={onChange}
                        value={filtrosSolicitudesCompras.fechaInicial}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Tipo Solicitud de Compra</label>
                    <Col sm={7}>
                      <select
                        className="form-control"
                        name="tipoSolicitudCompra"
                        onChange={onChange}
                        value={filtrosSolicitudesCompras.tipoSolicitudCompra}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tiposSolicitudCompra.map((tiposSolCom) => {
                          return (
                            <>
                              <option value={tiposSolCom.idCatTipoSolicitudCompra}>{tiposSolCom.tipoSolicitudCompra}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Tipo Compra</label>
                    <Col sm={7}>
                      <select
                        className="form-control"
                        name="tipoOrdenCompra"
                        onChange={onChange}
                        value={filtrosSolicitudesCompras.tipoOrdenCompra}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tiposOrdenCompra.map((tipoOrdenCompra) => {
                          return (
                            <>
                              <option value={tipoOrdenCompra.idCatTipoOrdenCompra}>{tipoOrdenCompra.tipoOrdenCompra}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Estatus Solicitud de Compra</label>
                    <Col sm={7}>
                      <select
                        className="form-control"
                        name="estatusSolicitudCompra"
                        onChange={onChange}
                        value={filtrosSolicitudesCompras.estatusSolicitudCompra}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {estatusSolicitudCompra.map((tiposSolCom) => {
                          return (
                            <>
                              <option value={tiposSolCom.estatusSolicitudCompra}>{tiposSolCom.estatusSolicitudcompra}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                </Col>

                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fecha Final</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaFinal"
                        disabled={!filtrosSolicitudesCompras.fechaInicial ? true : false}
                        min={filtrosSolicitudesCompras.fechaInicial}
                        onChange={onChange}
                        value={filtrosSolicitudesCompras.fechaFinal}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Comprador</label>
                    <Col sm={7}>
                      <select
                        name="usuarioComprador"
                        className="form-control"
                        onChange={onChange}
                        value={filtrosSolicitudesCompras.usuarioComprador}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {usuarios.map((usuario) => {
                          return (
                            <>
                              <option value={usuario.idCatUsuario}>{usuario.nombre}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Importe Máximo</label>
                    <Col sm={7}>
                      <input
                        type="text"
                        className="form-control"
                        name="importeMaximo"
                        value={filtrosSolicitudesCompras.importeMaximo ? `$${filtrosSolicitudesCompras.importeMaximo}` : `$0`}
                        onChange={onChangeImporteMaximo}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">SC Aprobadas</label>
                    <Col sm={2}>
                      <input
                        type="checkbox"
                        className="form-control"
                        name="aprobadas"
                        checked={filtrosSolicitudesCompras.aprobadas}
                        value={filtrosSolicitudesCompras.aprobadas}
                        onChange={onChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="submit"
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Aplicar Filtros
                  </button>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="button"
                    onClick={limpiarFiltros}
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Limpiar Filtros
                  </button>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <Row>
                  <Col>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      hidden={
                        usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ||
                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                          ? false
                          : true
                      }
                      onClick={cancelarSolicitudCompraConfirm}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Cancelar
                    </button>

                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      hidden={
                        usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_COTIZAR") ||
                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                          ? false
                          : true
                      }
                      onClick={registrarCotizacion}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Registrar Cotización
                    </button>

                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      hidden={
                        usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_AUTORIZAR") ||
                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                          ? false
                          : true
                      }
                      onClick={autorizarCotizacion}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Autorizar
                    </button>

                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      hidden={
                        usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_SOLICITAR_OC") ||
                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                          ? false
                          : true
                      }
                      onClick={solicitarOrdenCompraProveedor}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Solicitar OC a Proveedor
                    </button>
                  </Col>
                </Row>
                {/* <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">FOLIO</th>
                        <th scope="col">
                          Fecha de<br></br>Venta
                        </th>
                        <th scope="col">TIPO SC</th>
                        <th scope="col">TIPO COMPRA</th>
                        <th scope="col">COMPRADOR</th>
                        <th scope="col">
                          ESTATUS<br></br>ACTUAL
                        </th>
                        <th scope="col" className="text-center">
                          IMPORTE<br></br>TOTAL
                        </th>
                        <th scope="col">HISTORICO</th>
                      </tr>
                    </thead>
                    <tbody>
                      {solicitudesComprasFiltradas.map((solicitudCompra, index) => {
                        return (
                          <>
                            <tr key={solicitudCompra.idSolicitudCompra}>
                              <td>{<input type="checkbox" onClick={() => handdleCheckedSolicitudCompra(solicitudCompra.idSolicitudCompra)} checked={solicitudCompra.checked} />}</td>
                              <td>{solicitudCompra.folioSolicitudCompra}</td>
                              <td> {solicitudCompra.fechaSolicitud ? solicitudCompra.fechaSolicitud.split("T")[0] : ""} </td>
                              <td> {solicitudCompra.catTiposolicitudcompra ? solicitudCompra.catTiposolicitudcompra.tipoSolicitudCompra : ""} </td>
                              <td> {solicitudCompra.catTipoordencompra ? solicitudCompra.catTipoordencompra.tipoOrdenCompra : ""} </td>
                              <td> {solicitudCompra.usuarioComprador ? solicitudCompra.usuarioComprador.nombre : ""} </td>
                              <td>
                                {solicitudCompra.estatussolicitudcompra ? (
                                  <div>
                                    {solicitudCompra.estatussolicitudcompra[solicitudCompra.estatussolicitudcompra.length - 1].catEstatussolicitudcompra.idCatEstatusSolicitudCompra == 6 ||
                                    solicitudCompra.estatussolicitudcompra[solicitudCompra.estatussolicitudcompra.length - 1].catEstatussolicitudcompra.idCatEstatusSolicitudCompra == 7 ||
                                    solicitudCompra.estatussolicitudcompra[solicitudCompra.estatussolicitudcompra.length - 1].catEstatussolicitudcompra.idCatEstatusSolicitudCompra == 8 ? (
                                      <span
                                        className="badge badge-info"
                                        style={{
                                          height: "1.9rem",
                                          paddingTop: "0.6rem",
                                          border: "1px solid #dfdada",
                                          color: "white",
                                          //morado
                                          backgroundColor: "#602a96",
                                          borderRadius: "5px",
                                        }}>
                                        {solicitudCompra.estatussolicitudcompra[solicitudCompra.estatussolicitudcompra.length - 1].catEstatussolicitudcompra.estatusSolicitudcompra}
                                      </span>
                                    ) : (
                                      <span
                                        className="badge badge-info"
                                        style={{
                                          height: "1.9rem",
                                          paddingTop: "0.6rem",
                                          border: "1px solid #dfdada",
                                          //verde
                                          backgroundColor: "#C1E05C",
                                          borderRadius: "5px",
                                        }}>
                                        {solicitudCompra.estatussolicitudcompra[solicitudCompra.estatussolicitudcompra.length - 1].catEstatussolicitudcompra.estatusSolicitudcompra}
                                      </span>
                                    )}
                                  </div>
                                ) : null}
                              </td>
                              <td className="text-right">{solicitudCompra ? formatNumber(solicitudCompra.importeTotal + solicitudCompra.ivaTotal) : "$0"}</td>
                              <td>
                                <Link
                                  to={`/solicitudescompra/historico/${solicitudCompra.idSolicitudCompra}`}
                                  className="btn btn-sm btn-primary-orange-umo"
                                  style={{
                                    background: "#f93",
                                    color: "white",
                                    fontWeight: "bold",
                                  }}>
                                  Histórico
                                </Link>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div> */}
                <TableComponent
                  pageCount={pageCount}
                  fetchData={fetchData}
                  columns={columns}
                  loading={loadingData}
                  data={solicitudesComprasFiltradas}
                  indexOfPage={controlledPageIndex}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        {UISweetAlert.confirm_alert_cancelar_solicitud ? (
          <SweetAlert
            title="¿Desea cancelar la solicitud de compra?"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => solicitudCompraCancelar()}
            onCancel={() => {
              setCancelarSolicitudCompra({
                ...cancelarSolicitudCompra,
                comentarioEstatus: "",
              });
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_cancelar_solicitud: false,
              });
            }}>
            <div>
              <Row>
                <Col>Llene por favor la siguiente información</Col>
              </Row>

              <Row className="form-group mt-2">
                <label className="col-sm-4 col-form-label">Motivo</label>
                <Col sm={7}>
                  <textarea
                    rows={6}
                    className="form-control"
                    name="comentarioEstatus"
                    value={cancelarSolicitudCompra.comentarioEstatus}
                    onChange={onChangeCancelarSolicitudCompra}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Fecha Cancelación</label>
                <Col sm={7}>
                  <input
                    type="date"
                    className="form-control"
                    disabled={true}
                    name="fechaCancelacion"
                    value={cancelarSolicitudCompra.fechaCancelacion}
                  />
                </Col>
              </Row>
            </div>
          </SweetAlert>
        ) : null}

        {UISweetAlert.success_dlg ? (
          <SweetAlert
            success
            title={UISweetAlert.dynamic_title}
            onConfirm={() => {
              if (UISweetAlert.redirect) {
                props.history.push(UISweetAlert.path);
              }
              setUISweetAlert({
                ...UISweetAlert,
                success_dlg: false,
              });
            }}>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {UISweetAlert.error_dlg ? (
          <SweetAlert
            error
            title={UISweetAlert.dynamic_title}
            onConfirm={() =>
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: false,
              })
            }>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {loading ? (
          <SweetAlert title="Cargando!" showConfirm={false}>
            <Spinner />
          </SweetAlert>
        ) : null}
      </div>
    </>
  );
}
