import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import { Link } from "react-router-dom";

import VentaMostradorContext from "../../context/VentaMostrador/VentaMostradorContext";
import AuthContext from "../../context/Auth/AuthContext";
import ClientesContext from "../../context/Clientes/ClientesContext";
import CatTipoCobroContext from "../../context/CatTipoCobro/CatTipoCobroContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import EstatusVentaMostradorContext from "../../context/EstatusVentaMostrador/EstatusVentaMostradorContext";
import CatTipoProductoContext from "../../context/CatTipoProducto/CatTipoProductoContext";

import TableComponent from "../TablaPaginadaSinUpdate/index";

import formatNumber from "../../utils/index";

export default function VentaMostrador(props) {
  const [toggleSearchBy, setToggleSearchBy] = useState(false);
  const [loading, setLoading] = useState(false);

  const [rowSeleccionada, setRowSeleccionada] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);

  const [ventaMostradorSeleccionada, setVentaMostradorSeleccionada] = useState(null);

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await listarVentasMostradorPaginados({ page, limit });

      // Cantidad de datos disponibles en la tabla pacientes de la base de datos
      const count = response.count;

      setRowSeleccionada(null);
      setVentaMostradorSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    console.log("fetchData is being called");

    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
    // setLoadingData(true);
    // fetchAPIData({
    //   page: pageIndex + 1,
    //   limit: pageSize,
    //   byBusquedaFiltros: false,
    // });
  };

  const handleCheckedVentaMostrador = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setVentaMostradorSeleccionada({
          ...row.original,
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setVentaMostradorSeleccionada(null);
      } else if (rowSeleccionada !== row.id) {
        setRowSeleccionada(row.id);
        setVentaMostradorSeleccionada({
          ...row.original,
        });
      }
    },
    [rowSeleccionada]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idVentaMostrador",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handleCheckedVentaMostrador(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Folio", accessor: "folioVentaMostrador" },
      {
        Header: () => (
          <>
            Fecha de<br></br>Venta
          </>
        ),
        accessor: "fechaVenta",
        Cell: ({ row }) => {
          return <>{row.original.fechaVenta ? row.original.fechaVenta.split("T")[0] : ""}</>;
        },
      },
      {
        Header: "Cliente / Paciente",
        accessor: "cliente",
        Cell: ({ row }) => {
          return (
            <>{row.original.usuarioVendedor.usuario ? row.original.usuarioVendedor.usuario : row.original.usuarioVendedor} 
            {row.original.usuarioVendedor.usuario}</>
          );
        },        
      },
      {
        Header: "Vendedor",
        accessor: "usuarioVendedor",
        Cell: ({ row }) => {
          return (
            <>{row.original.usuarioVendedor.usuario ? row.original.usuarioVendedor.usuario : row.original.usuarioVendedor} 
            {row.original.usuarioVendedor.usuario}</>
          );
        },
      },
      // {
      //   Header: "VENDEDOR",
      //   accessor: "usuarioVendedor",
      // },
      {
        Header: () => (
          <>
            Estatus<br></br>Actual
          </>
        ),
        accessor: "idEstatusActual",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.idEstatusActual ? (
                <div>
                  {row.original.idEstatusActual == 5 || row.original.idEstatusActual == 7 || row.original.idEstatusActual == 8 ? (
                    <span
                      className="badge badge-info"
                      style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "white",
                        fontWeight: "bold",
                        //morado
                        backgroundColor: "#602a96",
                        borderRadius: "5px",
                      }}>
                      {row.original.estatusVentaMostrador ? row.original.estatusVentaMostrador : ""}
                    </span>
                  ) : (
                    <span
                      className="badge badge-info"
                      style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "black",
                        fontWeight: "bold",
                        //verde
                        backgroundColor: "#C1E05C",
                        borderRadius: "5px",
                      }}>
                      {row.original.estatusVentaMostrador ? row.original.estatusVentaMostrador : ""}
                    </span>
                  )}
                </div>
              ) : null}
            </>
          );
        },
      },
      {
        Header: () => (
          <>
            Tipo de<br></br>Venta
          </>
        ),
        accessor: "tipoVentaMostrador",
      },
      {
        Header: () => (
          <>
            Importe<br></br>Total
          </>
        ),
        accessor: "importeTotal",
        Cell: ({ row }) => {
          return <>{formatNumber(row.original.importeTotal + row.original.ivaTotal)}</>;
        },
      },
      {
        Header: () => (
          <>
            Completar<br></br>Venta
          </>
        ),
        accessor: "completar",
        Cell: ({ row }) => {
          return (
            <>
              {row.original && (row.original.idCatTipoVentaMostrador == 1 || row.original.idCatTipoVentaMostrador == 3)
                ? [
                    ((row.original.idEstatusActual == 3 || row.original.idEstatusActual == 1) && usuario.authorities.includes("OP_MOSTRADOR_VENTA_EDITAR")) ? (
                      <Link
                        to={`/ventasmostrador/completar/${row.original.idVentaMostrador}`}
                        className="btn btn-sm btn-primary-orange-umo"
                        name="cambioEstatus"
                        style={{
                          background: "#f93",
                          color: "white",
                          fontWeight: "bold",
                        }}>
                        Completar
                      </Link>
                    ) : (
                      ""
                    ),
                  ]
                : ""}
            </>
          );
        },
      },
      {
        Header: "Cobro",
        accessor: "cobro",
        Cell: ({ row }) => {
          return (
            <>
              {row.original && (row.original.idCatTipoVentaMostrador == 1 || row.original.idCatTipoVentaMostrador == 3)
                ? [
                    row.original.idEstatusActual == 4 && usuario.authorities.includes("OP_MOSTRADOR_VENTA_COBRO") ? (
                      <Link
                        to={`/ventasmostrador/cobro/${row.original.idVentaMostrador}`}
                        className="btn btn-sm btn-primary-orange-umo"
                        name="cobro"
                        style={{
                          background: "#f93",
                          color: "white",
                          fontWeight: "bold",
                        }}>
                        Cobro
                      </Link>
                    ) : (
                      ""
                    ),
                  ]
                : [
                    row.original && row.original.idCatTipoVentaMostrador == 2
                      ? [
                          row.original.idEstatusActual == 3 && usuario.authorities.includes("OP_MOSTRADOR_VENTA_COBRO") ? (
                            <Link
                              to={`/ventasmostrador/cobro/${row.original.idVentaMostrador}`}
                              className="btn btn-sm btn-primary-orange-umo"
                              style={{
                                background: "#f93",
                                color: "white",
                                fontWeight: "bold",
                              }}>
                              Cobro
                            </Link>
                          ) : (
                            ""
                          ),
                        ]
                      : "",
                  ]}
            </>
          );
        },
      },
      {
        Header: "Histórico",
        accessor: "historico",
        Cell: ({ row }) => {
          return (
            <Link
              to={`/ventasmostrador/historico/${row.original.idVentaMostrador}`}
              className="btn btn-sm btn-primary-orange-umo"
              style={{
                background: "#f93",
                color: "white",
                fontWeight: "bold",
              }}>
              Histórico
            </Link>
          );
        },
      },
    ],
    [rowSeleccionada, handleCheckedVentaMostrador]
  );

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_cancelar_venta: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const fechaActual = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "";

  const [cancelarVenta, setCancelarVenta] = useState({
    fechaCancelacion: new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "",
    comentarioEstatus: "",
  });

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxVentaMostrador = useContext(VentaMostradorContext);
  const {
    ventasMostradorFiltradas,
    filtrosVentaMostrador,
    setFiltrosVentaMostrador,
    limpiarVentaMostradorSeleccionada,
    cancelarVentaMostrador,
    exportCotizacionVentaMostrador,
    listarVentasMostradorPaginados,
  } = ctxVentaMostrador;

  const ctxClientes = useContext(ClientesContext);
  const { clientes, listarClientes } = ctxClientes;

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxTipoDeCobros = useContext(CatTipoCobroContext);
  const { tipoCobros, listarCatTipoCobros } = ctxTipoDeCobros;

  //ACCESO AL ESTADO GLOBAL - Estatus Vale
  const ctxUsuarios = useContext(UsuariosContext);
  const { usuarios, listarUsuarios } = ctxUsuarios;

  //ACCESO AL ESTADO GLOBAL - Estatus Vale
  const ctxTipoProducto = useContext(CatTipoProductoContext);
  const { tiposProductos, listarProductoTiposProductos } = ctxTipoProducto;

  //ACCESO AL ESTADO GLOBAL - Estatus Vale
  const ctxEstatusVentaMostrador = useContext(EstatusVentaMostradorContext);
  const { estatusVentaMostrador, listarEstatusVentaMostrador, getEstatusCancelado, estatusCancelado } = ctxEstatusVentaMostrador;

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const onChange = (e) => {
    if (e.target.name == "fechaInicial") {
      setFiltrosVentaMostrador({
        ...filtrosVentaMostrador,
        [e.target.name]: e.target.value,
        fechaFinal: e.target.value,
      });
    } else {
      setFiltrosVentaMostrador({
        ...filtrosVentaMostrador,
        [e.target.name]: e.target.value,
      });
    }
  };

  const toggleSearch = () => {
    setToggleSearchBy(!toggleSearchBy);
    limpiarFiltros();
  };

  const buscarPorFolio = (e) => {
    e.preventDefault();
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const buscarPorFiltros = (e) => {
    e.preventDefault();
    if (filtrosVentaMostrador.fechaInicial && !filtrosVentaMostrador.fechaFinal) {
      return;
    }

    if (filtrosVentaMostrador.fechaFinal) {
      filtrosVentaMostrador.fechaFinal = filtrosVentaMostrador.fechaFinal + "T23:59:59";
    }

    fetchData({ pageSize: 10, pageIndex: 0 });

    if (filtrosVentaMostrador.fechaFinal) {
      filtrosVentaMostrador.fechaFinal = filtrosVentaMostrador.fechaFinal.split("T")[0];
    }
  };

  const limpiarFiltros = () => {
    setFiltrosVentaMostrador({
      fechaInicial: "",
      cliente: "",
      estatusVentaMostrador: "",
      fechaFinal: "",
      usuarioSolicitante: "",
      folioMostrador: null,
      tipoCobro: "",
      tipoProducto: "",
      importeMaximo: null,
    });
  };

  // const handdleCheckedVentaMostrador = (idVentaMostrador) => {
  //   ventasMostradorFiltradas.map((venta) => {
  //     if (venta.idVentaMostrador == idVentaMostrador) {
  //       venta.checked = !venta.checked;
  //     } else {
  //       venta.checked = false;
  //     }
  //   });
  //   setVentasMostradorFiltradas(ventasMostradorFiltradas);
  // };

  const onChangeImporteMaximo = (e) => {
    let value = e.target.value;
    value = Number(value.replace("$", ""));

    setFiltrosVentaMostrador({
      ...filtrosVentaMostrador,
      importeMaximo: value,
    });
  };

  const cancelarVentaMostradorConfirm = async () => {
    const ventaChecked = await isVentaChecked();
    // console.log(ventaChecked);

    if (ventaChecked) {
      //Checamos que no haya sido previamente cancelada
      if (ventaMostradorSeleccionada.idEstatusActual == 7) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "La venta ya ha sido cancelada",
        });
      } else {
        //Si la venta no se ha cancelado, les permitimos cancelar la venta

        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_cancelar_venta: true,
        });
      }
    }
  };

  const isVentaChecked = async () => {
    let ventaChecked = ventaMostradorSeleccionada !== null;

    if (!ventaChecked) {
      //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione una venta",
      });
    }

    return ventaChecked;
  };

  const changeEstatusCancelado = async () => {
    // eslint-disable-next-line no-unused-vars
    const ventaChecked = await isVentaChecked();

    if (cancelarVenta.comentarioEstatus == "" || cancelarVenta.fechaCancelacion == "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor llene la información necesaria.",
      });
      return;
    }

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_cancelar_vale: false,
    });

    setLoading(true);
    const res = await cancelarVentaMostrador({
      idEstatusNuevo: estatusCancelado.idCatEstatusVentaMostrador,
      idVentaMostrador: ventaMostradorSeleccionada.idVentaMostrador,
      comentarioEstatus: cancelarVenta.comentarioEstatus,
    });
    if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_cancelar_venta: false,
        dynamic_description: "Venta cancelada",
      });
      setVentaMostradorSeleccionada(null);
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setCancelarVenta({
        fechaCancelacion: new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "",
        comentarioEstatus: "",
      });
    } else {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_cancelar_venta: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error al cancelar la venta, Inténtalo por favor más tarde.",
      });
    }
  };

  const onChangeCancelarVenta = async (e) => {
    setCancelarVenta({
      ...cancelarVenta,
      [e.target.name]: e.target.value,
    });
  };

  const imprimirCotizacion = async () => {
    const ventaChecked = await isVentaChecked();
    //Al principio preguntamos si tenemos un vale seleccionado
    if (ventaChecked) {
      setLoading(true);
      const res = await exportCotizacionVentaMostrador(ventaMostradorSeleccionada.idVentaMostrador);

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    listarClientes();

    listarCatTipoCobros();

    listarUsuarios();

    listarEstatusVentaMostrador();

    listarProductoTiposProductos();

    limpiarVentaMostradorSeleccionada();
    getEstatusCancelado();
  }, []);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Listado de Ventas Mostrador</h3>

      <button
        className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
        type="button"
        onClick={toggleSearch}>
        {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
      </button>
      {usuario.authorities.includes("OP_MOSTRADOR_VENTA_CREAR") ? (
        <Link to={`/ventasmostrador/nuevo`}>
          <button className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6">Nueva venta</button>
        </Link>
      ) : null}

      <Card
        hidden={!toggleSearchBy}
        className="mt-10 card"
        style={{
          padding: "1.5rem 1.5rem 1.5rem",
        }}>
        <form onSubmit={(e) => buscarPorFolio(e)}>
          <CardBody>
            <Row>
              <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                <Row
                  className="form-group"
                  style={{
                    marginBottom: "0",
                  }}>
                  <Col sm={10} className="flex col-sm-10">
                    <input
                      className="form-control mr-2.5"
                      type="text"
                      name="folioMostrador"
                      placeholder="Escribe tu folio"
                      onChange={onChange}
                      value={filtrosVentaMostrador.folioMostrador ? filtrosVentaMostrador.folioMostrador : ""}
                    />
                    <button
                      className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                      style={{
                        marginRight: "2.5rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      type="submit">
                      Buscar
                    </button>
                  </Col>
                </Row>
              </Col>

              {/* <Col xl={4} lg={4} md={4} className={}>
                            
                            </Col> */}
            </Row>
          </CardBody>
        </form>
      </Card>

      <Card
        hidden={toggleSearchBy}
        className="mt-10 card"
        style={{
          padding: "1.5rem 1.5rem 1.5rem",
        }}>
        <form onSubmit={(e) => buscarPorFiltros(e)}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                  <Col sm={7}>
                    <input
                      className="form-control"
                      type="date"
                      name="fechaInicial"
                      onChange={onChange}
                      value={filtrosVentaMostrador.fechaInicial}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Lista de clientes</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="cliente"
                      id="cliente"
                      onChange={onChange}
                      value={filtrosVentaMostrador.cliente}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {clientes.map((cliente) => {
                        return (
                          <>
                            <option
                              value={
                                cliente.idCatCliente
                              }>{`${cliente.catPersona.nombres} ${cliente.catPersona.apellidoPaterno} ${cliente.catPersona.apellidoMaterno}`}</option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Estatus Actual</label>
                  <Col sm={7}>
                    {/* <Select
                                        // className="form-control"
                                        placeholder="Seleccione estatus"
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="estatusVale"
                                        options={estatusvale}
                                        isMulti
                                        onChange={onChangeEstatusVale}
                                        value={filtrosVale.estatusValeOpciones}
                                        placeholder="Seleccione una opción"
                                        /> */}
                    <select
                      className="form-control"
                      name="estatusVentaMostrador"
                      onChange={onChange}
                      value={filtrosVentaMostrador.estatusVentaMostrador}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {estatusVentaMostrador.map((estatus) => {
                        return (
                          <>
                            <option value={estatus.estatusVentaMostrador}>{estatus.estatusVentaMostrador}</option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Importe Máximo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="importeMaximo"
                      value={filtrosVentaMostrador.importeMaximo ? `$${filtrosVentaMostrador.importeMaximo}` : `$0`}
                      onChange={onChangeImporteMaximo}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Fecha Final</label>
                  <Col sm={7}>
                    <input
                      className="form-control"
                      type="date"
                      // type="datetime-local"
                      name="fechaFinal"
                      disabled={!filtrosVentaMostrador.fechaInicial ? true : false}
                      min={filtrosVentaMostrador.fechaInicial}
                      onChange={onChange}
                      value={filtrosVentaMostrador.fechaFinal}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Usuario Solicitante</label>
                  <Col sm={7}>
                    <select
                      name="usuarioSolicitante"
                      className="form-control"
                      onChange={onChange}
                      value={filtrosVentaMostrador.usuarioSolicitante}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {usuarios.map((usuario) => {
                        return (
                          <>
                            <option value={usuario.idCatUsuario}>{usuario.nombre}</option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Forma de Pago</label>
                  <Col sm={7}>
                    <select name="tipoCobro" className="form-control" onChange={onChange} value={filtrosVentaMostrador.tipoCobro}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {tipoCobros.map((cobro) => {
                        return (
                          <>
                            <option value={cobro.idCatTipoCobro}>{cobro.tipoCobro}</option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Tipo Producto</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="tipoProducto"
                      onChange={onChange}
                      value={filtrosVentaMostrador.tipoProducto}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {tiposProductos.map((tipoProd) => {
                        return (
                          <>
                            <option value={tipoProd.idCatTipoProducto}>{tipoProd.tipoProducto}</option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="submit"
                  style={{
                    marginRight: "2.5rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}>
                  Aplicar Filtros
                </button>
                <button
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="button"
                  onClick={limpiarFiltros}
                  style={{
                    marginRight: "2.5rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}>
                  Limpiar Filtros
                </button>
              </Col>
            </Row>
          </CardBody>
        </form>
      </Card>

      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_FARMACIA_VALE_CANCELAR") ? false : true}
                    onClick={cancelarVentaMostradorConfirm}
                    style={{
                      marginRight: "2.5rem",
                      marginTop: "1rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Cancelar Venta
                  </button>

                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_FARMACIA_VALE_CANCELAR") ? false : true}
                    onClick={imprimirCotizacion}
                    style={{
                      marginRight: "2.5rem",
                      marginTop: "1rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Cotización Venta
                  </button>
                </Col>
              </Row>

              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={ventasMostradorFiltradas}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {UISweetAlert.confirm_alert_cancelar_venta ? (
        <SweetAlert
          title="¿Desea cancelar la venta?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          onConfirm={() => changeEstatusCancelado()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_cancelar_venta: false,
            })
          }>
          <div>
            <Row>
              <Col>Llene por favor la siguiente información</Col>
            </Row>

            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">
                Motivo<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7}>
                <textarea
                  rows={6}
                  className="form-control"
                  name="comentarioEstatus"
                  value={cancelarVenta.comentarioEstatus}
                  onChange={onChangeCancelarVenta}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Fecha Cancelación</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  name="fechaCancelacion"
                  value={fechaActual}
                />
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
