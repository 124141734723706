import React, { useReducer } from "react";
import CatTipoPagoContext from "./CatTipoPagoContext";
import CatTipoPagoReducer from "./CatTipoPagoReducer";

import clienteAxios from "../../config/axios";

import { LISTAR_TIPOPAGOS } from "../../types";

export default function CatTipoPagoState(props) {
  const initialState = {
    tipoPagos: [],
  };

  const [state, dispatch] = useReducer(CatTipoPagoReducer, initialState);

  // Retorna el usuario autenticado
  const listarCatTipoPagos = async () => {
    const res = await clienteAxios.get("/tipopago", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_TIPOPAGOS,
      payload: res.data,
    });
  };

  return (
    <CatTipoPagoContext.Provider
      value={{
        tipoPagos: state.tipoPagos,
        listarCatTipoPagos,
      }}>
      {props.children}
    </CatTipoPagoContext.Provider>
  );
}
