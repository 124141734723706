import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
// import CatProductosContext from "../../context/CatProductos/CatProductosContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import FabricantesContext from "../../context/Fabricantes/FabricantesContext";
import ProductosCompraExternaContext from "../../context/ProductosCompraExterna/ProductosCompraExternaContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";

// import CargaMasivaModal from "../CargaMasivaModal/index";
import MedicamentoFalso from "../MedicamentoFalso";

import useWindowDimensions from "../../hooks/dimensionsHook";

const Medicamentos = () => {
  const { width } = useWindowDimensions();

  const filtrosVacios = {
    numIdentificacion: "",
    codigoEAN: "",
    nombreComercial: "",
    principioActivo: "",
    idCatProveedor: "",
    idCatLaboratorio: "",
  };

  const datosMedicamentoVacio = {
    numIdentificacion: "",
    codigoEAN: "",
    nombreComercial: "",
    principioActivo: "",
    idCatProveedor: "",
    idCatLaboratorio: "",
    opcionOtroProveedor: false,
    opcionOtroLaboratorio: false,
    otroProveedor : "",
    otroFabricante : "",
    lote : "",
    caducidad : "",
  };

  const [datosNuevoMedicamento, setDatosNuevoMedicamento] = useState(datosMedicamentoVacio);
  const [medicamentoSeleccionado, setMedicamentoSeleccionado] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_medicamento: false,
    confirm_nuevo_medicamento: false,
    confirm_eliminar_medicamento: false,
    confirm_modificar_precios: false,
    confirm_modificar_precios_final: false,
    confirm_carga_masiva: false,
    confirm_medicamento_falso: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
  const fetchIdRef = useRef(0);

  // const catProductosContext = useContext(CatProductosContext);
  const productosCompraExternaContext = useContext(ProductosCompraExternaContext);
  const proveedoresContext = useContext(ProveedoresContext);
  const fabricantesContext = useContext(FabricantesContext);

  const {
    filtrosProductosCompraExterna,
    productosCompraExternaPaginados,
    setDataProductosCompraExterna,
    setFiltrosProductosCompraExterna,
    uploadNuevoProducto,
    eliminarProducto,
    updateProducto,
  } = productosCompraExternaContext;

  const { listarProveedores, proveedores } = proveedoresContext;

  const { listarFabricantes, fabricantes } = fabricantesContext;

  useEffect(() => {
    const fetchContextData = () => {
      listarProveedores();
      listarFabricantes();
    };
    fetchContextData();
  }, []);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setDataProductosCompraExterna({ page, limit });

      // Cantidad de datos disponibles en la tabla pacientes de la base de datos
      const count = response.count;

      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const handdleCheckedMedicamento = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setMedicamentoSeleccionado({
          ...row.original,
          idCatProveedor: row.original.idCatProveedor,
          idCatLaboratorio: row.original.idCatLaboratorio,
          caducidad :  row.original.caducidad? row.original.caducidad.split("T")[0] : "",
          opcionOtroProveedor : row.original.idCatProveedor? false : true,
          opcionOtroLaboratorio : row.original.idCatLaboratorio? false : true,
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setMedicamentoSeleccionado(null);
      } else {
        setRowSeleccionada(row.id);
        setMedicamentoSeleccionado({
          ...row.original,
          idCatProveedor: row.original.idCatProveedor,
          idCatLaboratorio: row.original.idCatLaboratorio,
          opcionOtroProveedor : row.original.idCatProveedor? false : true,
          caducidad :  row.original.caducidad? row.original.caducidad.split("T")[0] : "",
          opcionOtroLaboratorio : row.original.idCatLaboratorio? false : true,          
        });
      }
    },
    [rowSeleccionada]
  );

  // Se definen las columnas para la tabla de react-tabe
  // Se debe usar useMemo para optimización y porque lo pide la librería
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idProductoCompraExterna",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedMedicamento(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Es Falso",
        accessor: "falso",
        Cell: ({ row }) => {
          return (
            <p className={"text-center font-bold m-0 " + (row.original.falso ? "bg-red" : "bg-green")}>
              {row.original.falso ? "SI" : "NO"}
            </p>

            // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Identificación", accessor: "numIdentificacion" },
      { Header: "Nombre Comercial", accessor: "nombreComercial" },
      { Header: "Principio Activo", accessor: "principioActivo" },
      { Header: "Presentación", accessor: "presentacion" },
      { Header: "Concentración", accessor: "concentracion" },
      { Header: "Lote", accessor: "lote" },
      {
        Header: () => (
            <>
                Caducidad
            </>
        ),
        accessor: "caducidad",
        Cell: ({row}) => {
            return <>{row.original.caducidad ? row.original.caducidad.split("T")[0] : ""}</>;
        },
      },
      { Header: "Proveedor", accessor: "proveedorCadena" },
      { Header: "Fabricante", accessor: "fabricanteCadena" },
      {
        Header: "Medicamento Falso",
        accessor: "precios",
        Cell: ({ row }) => {
          return autorizado ? (
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              onClick={() => handleModalFalso(row.original)}>
              Medicamento Falso
            </button>
          ) : null;
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [rowSeleccionada, handdleCheckedMedicamento]
  );

  const [idProductoExterno, setIdProductoExterno] = useState(null);

  const handleModalFalso = async (producto) => {
    global.ventanaDocumentoFalso  = true;
    setIdProductoExterno(producto.idProductoCompraExterna);
    setUISweetAlert({
      ...UISweetAlert,
      confirm_modificar_medicamento: false,
      confirm_nuevo_medicamento: false,
      confirm_eliminar_medicamento: false,
      confirm_modificar_precios: false,
      confirm_modificar_precios_final: false,
      confirm_medicamento_falso: true,
      success_dlg: false,
      reload_page: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
    });
  };

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  const autorizado =
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_CREAR") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_MODIFICAR") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_MODIFICAR_PRECIO") ||
    usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_ELIMINAR");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function setDataProductosCompraExterna
     * @param {object} filtrosProductosCompraExterna Filtros enviados al back para filtrar los productos
     * @param {int} page Página a regresar de pacientes
     * @param {int} limit Número de pacientes que se van a regresar por llamada al back
     */

    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosProductosCompraExterna(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name == "falso") {
      setFiltrosProductosCompraExterna({
        ...filtrosProductosCompraExterna,
        [name]: e.target.checked,
      });
    } else {
      setFiltrosProductosCompraExterna({
        ...filtrosProductosCompraExterna,
        [name]: value,
      });
    }
  };

  const changeNuevoMedicamento = (e) => {
    const { name, value, checked } = e.target;
    if(e.target.name=="opcionOtroProveedor"){

      if(checked== true){
        datosNuevoMedicamento.otroProveedor = "";
      }else{
        datosNuevoMedicamento.idCatProveedor = "";
      }

      setDatosNuevoMedicamento({
        ...datosNuevoMedicamento,
        [name]: checked,
        idCatProveedor: "",
        otroProveedor: "",
      });

    }else if (e.target.name=="opcionOtroLaboratorio"){
      if(checked== true){
        datosNuevoMedicamento.otroFabricante = "";
      }else{
        datosNuevoMedicamento.idCatLaboratorio = "";
      }

      setDatosNuevoMedicamento({
        ...datosNuevoMedicamento,
        [name]: checked,
        idCatLaboratorio: "",
        otroFabricante: "",
      });

    }else{
      setDatosNuevoMedicamento({
        ...datosNuevoMedicamento,
        [name]: value,      
      });
    }
  };

  const changeModificarMedicamento = (e) => {
    const { name, value, checked } = e.target;
    if(e.target.name=="opcionOtroProveedor"){

      if(checked== true){
        medicamentoSeleccionado.otroProveedor = "";
      }else{
        medicamentoSeleccionado.idCatProveedor = "";
      }

      setMedicamentoSeleccionado({
        ...medicamentoSeleccionado,
        [name]: checked,
        idCatProveedor: "",
        otroProveedor: "",
      });

    }else if (e.target.name=="opcionOtroLaboratorio"){
      if(checked== true){
        medicamentoSeleccionado.otroFabricante = "";
      }else{
        medicamentoSeleccionado.idCatLaboratorio = "";
      }

      setMedicamentoSeleccionado({
        ...medicamentoSeleccionado,
        [name]: checked,
        idCatLaboratorio: "",
        otroFabricante: "",
      });

    }else{
      setMedicamentoSeleccionado({
        ...medicamentoSeleccionado,
        [name]: value,      
      });
    }
  };

  const opcionesProveedor = proveedores.map(({ idCatProveedor, proveedor, ...rest }) => ({
    ...rest,
    id: idCatProveedor,
    nombre: proveedor,
  }));

  const opcionesFabricante = fabricantes.map(({ idCatLaboratorio, laboratorio, ...rest }) => ({
    ...rest,
    id: idCatLaboratorio,
    nombre: laboratorio,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Fecha Inicial",
          type: "date",
          name: "fechaInicial",
          value: filtrosProductosCompraExterna.fechaInicial,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Identificacion",
          type: "text",
          name: "numIdentificacion",
          value: filtrosProductosCompraExterna.numIdentificacion,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Código EAN",
          type: "text",
          name: "codigoEAN",
          value: filtrosProductosCompraExterna.codigoEAN,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Nombre Comercial",
          type: "text",
          name: "nombreComercial",
          value: filtrosProductosCompraExterna.nombreComercial,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Fecha Final",
          type: "date",
          name: "fechaFinal",
          value: filtrosProductosCompraExterna.fechaFinal,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Principio Activo",
          type: "text",
          name: "principioActivo",
          value: filtrosProductosCompraExterna.principioActivo,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Proveedor",
          name: "idCatProveedor",
          value: filtrosProductosCompraExterna.idCatProveedor,
          opciones: opcionesProveedor,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Fabricante",
          name: "idCatLaboratorio",
          value: filtrosProductosCompraExterna.idCatLaboratorio,
          opciones: opcionesFabricante,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Es Falso",
          type: "checkbox",
          name: "falso",
          checked: filtrosProductosCompraExterna.falso,
          value: filtrosProductosCompraExterna.falso,
          onChange: onChange,
        },
      ],
    },
  ];

  const validarDatosMedicamento = (medicamento) => {
    if (medicamento.numIdentificacion === "" || medicamento.numIdentificacion === null) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el número de identificación",
      });
      return false;
    }
    if (medicamento.nombreComercial === "" || medicamento.nombreComercial === null) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la descripción",
      });
      return false;
    }
    if (medicamento.principioActivo === "" || medicamento.principioActivo === null) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el principio activo",
      });
      return false;
    }
    if (medicamento.presentacion === "" || medicamento.presentacion === null) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la presentación",
      });
      return false;
    }

    if (medicamento.lote === "" || medicamento.lote === null) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el lote",
      });
      return false;
    }

    if (medicamento.caducidad === "" || medicamento.caducidad === null) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la fecha de caducidad",
      });
      return false;
    }

    if (medicamento.concentracion === "" || medicamento.concentracion === null) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la concentración",
      });
      return false;
    }
    if ((medicamento.idCatProveedor === "" || medicamento.idCatProveedor === null) && (medicamento.otroProveedor === "" || medicamento.otroProveedor === null)) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el proveedor",
      });
      return false;
    }
    if ((medicamento.idCatLaboratorio === "" || medicamento.idCatLaboratorio === null) && (medicamento.otroLaboratorio === "" || medicamento.otroLaboratorio === null)) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el fabricante",
      });
      return false;
    }

    return true;
  };

  const confirmNuevoMedicamento = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nuevo_medicamento: true,
      });
    }
  };

  const agregarNuevoMedicamento = async () => {
    const medicamentoValido = validarDatosMedicamento(datosNuevoMedicamento);

    if (medicamentoValido) {
      setLoading(true);
      const res = await uploadNuevoProducto({ ...datosNuevoMedicamento, idCatTipoProducto: 2 });
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nuevo_medicamento: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
        setDatosNuevoMedicamento(datosMedicamentoVacio);
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nuevo_medicamento: false,
          dynamic_description: "Se guardó el nuevo medicamento correctamente",
        });
        // setData([...data, res.data]);
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setDatosNuevoMedicamento(datosMedicamentoVacio);
      }
    }
  };

  const checkEliminarMedicamento = () => {
    if (autorizado && medicamentoSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_medicamento: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un medicamento",
      });
    }
  };

  const confirmEliminarMedicamento = async () => {
    setLoading(true);
    const res = await eliminarProducto(medicamentoSeleccionado.idProductoCompraExterna);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_medicamento: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_medicamento: false,
        dynamic_description: "Se eliminó el medicamento correctamente",
      });
      // updateDataOnDeleteMedicamento(medicamentoSeleccionado.idProductoCompraExterna);
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setMedicamentoSeleccionado(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarMedicamento = () => {
    if (autorizado && medicamentoSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_medicamento: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un medicamento",
      });
    }
  };

  const confirmModicarMedicamento = async () => {
    const medicamentoValido = validarDatosMedicamento(medicamentoSeleccionado);

    if (medicamentoValido) {
      setLoading(true);
      const res = await updateProducto(medicamentoSeleccionado);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_medicamento: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_medicamento: false,
          dynamic_description: "Se modificó el medicamento correctamente",
        });
        // actualizarProductosPaginados(medicamentosActualizados);
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setMedicamentoSeleccionado(null);
        setRowSeleccionada(null);
      }
    }
  };

  const onCancel = () => {
    global.ventanaDocumentoFalso  = false;
    setUISweetAlert({
      ...UISweetAlert,
      confirm_medicamento_falso: false,
    });
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Medicamentos Compra Externa</h3>
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Medicamentos</h4>

      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevoMedicamento()}>
                      Nueva Compra Externa
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarMedicamento()}>
                      Modificar Compra Externa
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarMedicamento()}>
                      Eliminar Compra Externa
                    </button>
                  ) : null}
                </Col>
              </Row>

              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={productosCompraExternaPaginados}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nuevo_medicamento ? (
        <SweetAlert
          title="Nuevo Medicamento Compra Externa"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "90%",
          }}
          onConfirm={() => {
            agregarNuevoMedicamento();
          }}
          onCancel={() => {
            setDatosNuevoMedicamento(datosMedicamentoVacio);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nuevo_medicamento: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody className="mt-3">
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-3 col-form-label">
                    Número de Identificación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="text"
                      className="form-control"
                      name="numIdentificacion"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.numIdentificacion ? datosNuevoMedicamento.numIdentificacion : ""}
                    />
                    {datosNuevoMedicamento.numIdentificacion === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-3 col-form-label">
                    Nombre Comercial<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombreComercial"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.nombreComercial ? datosNuevoMedicamento.nombreComercial : ""}
                    />
                    {datosNuevoMedicamento.nombreComercial === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-3 col-form-label">
                    Principio Activo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="text"
                      className="form-control"
                      name="principioActivo"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.principioActivo ? datosNuevoMedicamento.principioActivo : ""}
                    />
                    {(datosNuevoMedicamento.principioActivo == null || datosNuevoMedicamento.principioActivo == "") && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-3 col-form-label">
                    Presentación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="text"
                      className="form-control"
                      name="presentacion"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.presentacion ? datosNuevoMedicamento.presentacion : ""}
                    />
                    {(datosNuevoMedicamento.presentacion == null || datosNuevoMedicamento.presentacion == "") && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  
                  <label className="col-sm-3 col-form-label">
                    Lote<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="text"
                      className="form-control"
                      name="lote"
                      maxLength={50}
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.lote ? datosNuevoMedicamento.lote : ""}
                    />
                    {datosNuevoMedicamento.precioPublico === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-3 col-form-label">
                    Fecha Caducidad<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="date"
                      name="caducidad"
                      value={datosNuevoMedicamento.caducidad}
                      disabled={false}
                      onChange={changeNuevoMedicamento}
                      className="form-control"
                    />
                    {datosNuevoMedicamento.caducidad === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  
                <label className="col-sm-3 col-form-label">
                    Concentración<span className="text-xs text-red-700">*</span>
                </label>                  
                <Col sm={9}>
                    <input
                      type="text"
                      name="concentracion"
                      value={datosNuevoMedicamento.concentracion? datosNuevoMedicamento.concentracion : ""}
                      className="form-control"
                      onChange={changeNuevoMedicamento}
                    />
                    {datosNuevoMedicamento.concentracion === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}                    
                  </Col>

                  <label className="col-sm-3 col-form-label">
                    Proveedor<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <select
                      className="form-control"
                      name="idCatProveedor"
                      disabled = {datosNuevoMedicamento.opcionOtroProveedor==true? true : false}
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.idCatProveedor}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesProveedor.map((proveedor) => {
                        return <option value={proveedor.id}>{proveedor.nombre}</option>;
                      })}
                    </select>
                    {datosNuevoMedicamento.opcionOtroProveedor==false && datosNuevoMedicamento.idCatProveedor === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <Col sm={3}>
                  <input
                      type="checkbox"
                      name="opcionOtroProveedor"
                      width={30}
                      onChange={changeNuevoMedicamento}
                      checked={datosNuevoMedicamento.opcionOtroProveedor}
                  />      
                  <label className="col-sm-7 col-form-label">Otro Proveedor</label>
                  </Col>  

                  <Col sm={9}>
                    <input
                      type="text"
                      name="otroProveedor"
                      disabled = {datosNuevoMedicamento.opcionOtroProveedor==true? false : true}
                      value={datosNuevoMedicamento.otroProveedor? datosNuevoMedicamento.otroProveedor : ""}
                      className="form-control"
                      onChange={changeNuevoMedicamento}
                    />
                    {datosNuevoMedicamento.opcionOtroProveedor==true && datosNuevoMedicamento.otroProveedor === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}                    
                  </Col>

                  {/* **************************************************************************** */}
                  <Col sm={3}>
                  <input
                      type="checkbox"
                      name="opcionOtroProveedor"
                      hidden = {true}
                      width={30}
                      onChange={changeNuevoMedicamento}
                      checked={datosNuevoMedicamento.opcionOtroProveedor}
                  />      
                  <label className="col-sm-7 col-form-label"><br></br> <br></br></label>
                  </Col>  

                  <Col sm={9}>
                    <input
                      type="text"
                      hidden = {true}
                      name="otroProveedor"
                      value={datosNuevoMedicamento.otroProveedor? datosNuevoMedicamento.otroProveedor : ""}
                      className="form-control"
                      onChange={changeNuevoMedicamento}
                    />
                  </Col>
                  {/* **************************************************************************** */}

                  <label className="col-sm-3 col-form-label">
                    Fabricante<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <select
                      className="form-control"
                      name="idCatLaboratorio"
                      onChange={changeNuevoMedicamento}
                      disabled = {datosNuevoMedicamento.opcionOtroLaboratorio==true? true : false}
                      value={datosNuevoMedicamento.idCatLaboratorio}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesFabricante.map((fabricante) => {
                        return <option value={fabricante.id}>{fabricante.nombre}</option>;
                      })}
                    </select>
                    {datosNuevoMedicamento.opcionOtroLaboratorio==false  && datosNuevoMedicamento.idCatLaboratorio === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <Col sm={3}>
                  <input
                      type="checkbox"
                      name="opcionOtroLaboratorio"
                      width={30}
                      onChange={changeNuevoMedicamento}
                      checked={datosNuevoMedicamento.opcionOtroLaboratorio}
                  />      
                  <label className="col-sm-7 col-form-label">Otro Fabricante</label>
                  </Col>  

                  <Col sm={9}>
                    <input
                      type="text"
                      name="otroFabricante"
                      disabled = {datosNuevoMedicamento.opcionOtroLaboratorio==true? false : true}
                      value={datosNuevoMedicamento.otroFabricante? datosNuevoMedicamento.otroFabricante : ""}
                      className="form-control"
                      onChange={changeNuevoMedicamento}
                    />
                    {datosNuevoMedicamento.opcionOtroLaboratorio==true  && datosNuevoMedicamento.otroFabricante === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}                    
                  </Col>

                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-3 col-form-label"></label>
                  <Col sm={9}>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDatosNuevoMedicamento(datosMedicamentoVacio)}>
                      Limpiar Datos
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}
      
      {UISweetAlert.confirm_eliminar_medicamento ? (
        <SweetAlert
          title="¿Desea eliminar el medicamento de compra externa seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: width && width < 851 ? "100%" : "60%",
          }}
          onConfirm={() => confirmEliminarMedicamento()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_medicamento: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_medicamento ? (
        <SweetAlert
          title="Modificar Medicamento Compra Externa"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          closeOnClickOutside={false}
          cancelBtnBsStyle="danger"
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "90%",
          }}
          onConfirm={() => {
            confirmModicarMedicamento();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_medicamento: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-3 col-form-label">
                    Número de Identificación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="numIdentificacion"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.numIdentificacion ? medicamentoSeleccionado.numIdentificacion : ""}
                    />
                  </Col>
                  <label className="col-sm-3 col-form-label">
                    Nombre Comercial<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombreComercial"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.nombreComercial ? medicamentoSeleccionado.nombreComercial : ""}
                    />
                  </Col>
                  <label className="col-sm-3 col-form-label">
                    Principio Activo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="text"
                      className="form-control"
                      name="principioActivo"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.principioActivo ? medicamentoSeleccionado.principioActivo : ""}
                    />
                  </Col>
                  <label className="col-sm-3 col-form-label">
                    Presentación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="text"
                      className="form-control"
                      name="presentacion"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.presentacion ? medicamentoSeleccionado.presentacion : ""}
                    />
                  </Col>

                  <label className="col-sm-3 col-form-label">
                    Lote<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="text"
                      className="form-control"
                      name="lote"
                      maxLength={50}
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.lote ? medicamentoSeleccionado.lote : ""}
                    />
                  </Col>

                  <label className="col-sm-3 col-form-label">
                    Fecha Caducidad<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="date"
                      name="caducidad"
                      value={medicamentoSeleccionado.caducidad}
                      disabled={false}
                      onChange={changeModificarMedicamento}
                      className="form-control"
                    />
                  </Col>                  
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-3 col-form-label">
                    Concentración<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <input
                      type="text"
                      className="form-control"
                      name="concentracion"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.concentracion ? medicamentoSeleccionado.concentracion : ""}
                    />
                  </Col>
                  <label className="col-sm-3 col-form-label">
                    Proveedor<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <select
                      className="form-control"
                      name="idCatProveedor"
                      disabled = {medicamentoSeleccionado.opcionOtroProveedor==true? true : false}
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.idCatProveedor}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesProveedor.map((proveedor) => {
                        return <option value={proveedor.id}>{proveedor.nombre}</option>;
                      })}
                    </select>
                  </Col>
                  <Col sm={3}>
                  <input
                      type="checkbox"
                      name="opcionOtroProveedor"
                      width={30}
                      onChange={changeModificarMedicamento}
                      checked={medicamentoSeleccionado.opcionOtroProveedor}
                  />      
                  <label className="col-sm-7 col-form-label">Otro Proveedor</label>
                  </Col>  

                  <Col sm={9}>
                    <input
                      type="text"
                      name="otroProveedor"
                      disabled = {medicamentoSeleccionado.opcionOtroProveedor==true? false : true}
                      value={medicamentoSeleccionado.otroProveedor? medicamentoSeleccionado.otroProveedor : ""}
                      className="form-control"
                      onChange={changeModificarMedicamento}
                    />                 
                  </Col>
                  {/* **************************************************************************** */}
                  <Col sm={3}>
                  <input
                      type="checkbox"
                      name="opcionOtroProveedor"
                      hidden = {true}
                      width={30}
                      onChange={changeNuevoMedicamento}
                      checked={datosNuevoMedicamento.opcionOtroProveedor}
                  />      
                  <label className="col-sm-7 col-form-label"><br></br> <br></br></label>
                  </Col>  

                  <Col sm={9}>
                    <input
                      type="text"
                      hidden = {true}
                      name="otroProveedor"
                      value={medicamentoSeleccionado.otroProveedor? medicamentoSeleccionado.otroProveedor : ""}
                      className="form-control"
                      onChange={changeNuevoMedicamento}
                    />
                  </Col>
                  {/* **************************************************************************** */}
                  <label className="col-sm-3 col-form-label">
                    Fabricante<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={9}>
                    <select
                      className="form-control"
                      name="idCatLaboratorio"
                      disabled = {medicamentoSeleccionado.opcionOtroLaboratorio==true? true : false}
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.idCatLaboratorio}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesFabricante.map((fabricante) => {
                        return <option value={fabricante.id}>{fabricante.nombre}</option>;
                      })}
                    </select>
                  </Col>

                  <Col sm={3}>
                  <input
                      type="checkbox"
                      name="opcionOtroLaboratorio"
                      width={30}
                      onChange={changeModificarMedicamento}
                      checked={medicamentoSeleccionado.opcionOtroLaboratorio}
                  />      
                  <label className="col-sm-7 col-form-label">Otro Fabricante</label>
                  </Col>  

                  <Col sm={9}>
                    <input
                      type="text"
                      name="otroFabricante"
                      disabled = {medicamentoSeleccionado.opcionOtroLaboratorio==true? false : true}
                      value={medicamentoSeleccionado.otroFabricante? medicamentoSeleccionado.otroFabricante : ""}
                      className="form-control"
                      onChange={changeModificarMedicamento}
                    />                  
                  </Col>

                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {global.ventanaDocumentoFalso==true? (
        <> 
          <MedicamentoFalso
            onCancel={onCancel}
            fetchData={() => fetchData({ pageSize: 10, pageIndex: controlledPageIndex })}
            idProductoCompraExterna={idProductoExterno}
          />
        </>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default Medicamentos;
