import React, { useReducer } from "react";
import {
  LISTAR_CATPRODUCTOS,
  SET_PRODUCTOS_PAGINADOS,
  FILTRO_PRODUCTOS,
  LISTAR_PRECIOS_PRODUCTO,
  SET_DOCUMENTO_PREVIO,
  LISTAR_SOLUCIONES,
} from "../../types";

import clienteAxios from "../../config/axios";
import CatProductosReducer from "./CatProductosReducer";
import CatProductosContext from "./CatProductosContext";

export default function CatProductosState(props) {
  const initialState = {
    catProductos: [],
    soluciones: [],
    productosPaginados: [],
    productosSelect: [],
    filtrosProductos: {},
    preciosProducto: null,
    progress: 0,
    documentoPrevio: null,
  };

  const [state, dispatch] = useReducer(CatProductosReducer, initialState);

  const listarCatProductos = async () => {
    const res = await clienteAxios.get(`/productos/activos`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_CATPRODUCTOS,
      payload: res.data,
    });
  };

  const listarMedicamentos = async () => {
    const res = await clienteAxios.get(`/productos/medicamentos/activos`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_CATPRODUCTOS,
      payload: res.data,
    });
  };

  const MedicamentosVentaMostradorPaciente = async (IdCatPaciente) => {
    try {
      const res = await clienteAxios.get(`/productos/medicamentos/mostrador/paciente?id=${IdCatPaciente}`, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const MedicamentosCompraExterna = async (IdCatPaciente) => {
    try {
      const res = await clienteAxios.get(`/productos/medicamentos/compra/externa?id=${IdCatPaciente}`, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const listarSoluciones = async () => {
    const res = await clienteAxios.get(`/productos/soluciones/activos`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_SOLUCIONES,
      payload: res.data,
    });
  };

  const setDataProductos = async ({ page, limit, idCatTipoProducto }) => {
    // idCatTipoProducto
    // INSUMO - 1
    // MEDICAMENTO - 2
    // SERVICIO - 3
    // SOLUCIÓN - 4

    try {
      const response = await clienteAxios.post(
        "/productos/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          idCatTipoProducto: idCatTipoProducto,
          ...state.filtrosProductos,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      dispatch({
        type: SET_PRODUCTOS_PAGINADOS,
        payload: response.data.productos,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const loadProductosSelect = async (page, limit, searchQuery) => {
    try {
      const response = await clienteAxios.post(
        "/productos/paginado/",
        { pageSize: limit, pageIndex: page + 1, nombreComercial: searchQuery },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      // const productosTemp = [...state.productosSelect, ...response.data.productos];
      const currentCount = limit * page;

      const hasMore = currentCount < response.data.count;

      const newRes = { data: response.data.productos, hasMore: hasMore };

      // dispatch({
      //   type: LISTAR_PRODUCTOS_SELECT,
      //   payload: productosTemp,
      // });
      return newRes;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const listarPreciosProducto = async (idCatProducto) => {
    try {
      const responsePreciosAseguradora = await clienteAxios.get(
        `/productoservicio/precios/aseguradora?id=${idCatProducto}`,

        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );
      const responsePreciosProductoServicio = await clienteAxios.get(
        `/productoservicio/precios/productoservicio?id=${idCatProducto}`,

        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      const precios = {
        preciosAseguradora: responsePreciosAseguradora.data,
        preciosProductoServicio: responsePreciosProductoServicio.data[0],
      };

      dispatch({
        type: LISTAR_PRECIOS_PRODUCTO,
        payload: precios,
      });

      return precios;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const actualizarProductosPaginados = async (productosActualizados) => {
    dispatch({
      type: SET_PRODUCTOS_PAGINADOS,
      payload: productosActualizados,
    });
  };

  const setFiltrosProductos = async (filtrosProductos) => {
    dispatch({
      type: FILTRO_PRODUCTOS,
      payload: filtrosProductos,
    });
  };

  const uploadNuevoProducto = async (datosProducto) => {
    try {
      const res = await clienteAxios.post(`/productos/guardar`, datosProducto, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarProducto = async (idCatProducto, eliminacionEstatus,justificacion) => {
    try {
      const response = await clienteAxios.post(
        "/productos/eliminar/",
        { idCatProducto: idCatProducto , eliminacionEstatus: eliminacionEstatus, justificacion: justificacion },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarMedicamento = async (idCatProducto,justificacion) => {
    try {
      const response = await clienteAxios.post(
        "/productos/eliminar/medicamento",
        { idCatProducto: idCatProducto, justificacion: justificacion },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const updateProducto = async (productoModificado) => {
    try {
      const res = await clienteAxios.post(`/productos/guardar`, productoModificado, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const actualizarMedicamentosVo = async (logVo) => {
    // Checar que el paciente no tenga el mismo CURP que otro paciente en la base datos
    try {
      const res = await clienteAxios.post(`productoservicio/actualizar/precios/log`, logVo, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const actualizarPreciosProducto = async (productosActualizado) => {
    // Si se actualizaron precios de aseguradora y de producto servicio
    if (productosActualizado.preciosAseguradora.length > 0 && productosActualizado.preciosProductoServicio !== undefined) {
      try {
        const responsePreciosAseguradora = await clienteAxios.post(
          `productoservicio/actualizar/precios/aseguradoras`,
          productosActualizado.preciosAseguradora,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Cache-Control': 'no-store', // Evita el caché del navegador
            },
          }
        );
        const responsePreciosProductoServicio = await clienteAxios.post(
          `productoservicio/actualizar/precios/productoservicio`,
          productosActualizado.preciosProductoServicio,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Cache-Control': 'no-store', // Evita el caché del navegador
            },
          }
        );

        return { preciosAseguradora: responsePreciosAseguradora, preciosProductoServicio: responsePreciosProductoServicio };
      } catch (error) {
        if (!error.response) {
          error.response = {
            data: {
              message: "Fallo en la conexión al servidor",
              error: "hubo error",
            },
          };
        }
        return error.response;
      }
    }
    // Si solo se actualizaron precios de aseguradora
    if (productosActualizado.preciosAseguradora.length > 0 && productosActualizado.preciosProductoServicio === undefined) {
      try {
        const res = await clienteAxios.post(
          `productoservicio/actualizar/precios/aseguradoras`,
          productosActualizado.preciosAseguradora,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Cache-Control': 'no-store', // Evita el caché del navegador
            },
          }
        );

        return res;
      } catch (error) {
        if (!error.response) {
          error.response = {
            data: {
              message: "Fallo en la conexión al servidor",
              error: "hubo error",
            },
          };
        }

        return error.response;
      }
    }
    // Si solo se actualizaron precios de producto servicio
    if (productosActualizado.preciosAseguradora.length <= 0 && productosActualizado.preciosProductoServicio !== undefined) {
      try {
        const res = await clienteAxios.post(
          `productoservicio/actualizar/precios/productoservicio`,
          productosActualizado.preciosProductoServicio,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Cache-Control': 'no-store', // Evita el caché del navegador
            },
          }
        );

        return res;
      } catch (error) {
        if (!error.response) {
          error.response = {
            data: {
              message: "Fallo en la conexión al servidor",
              error: "hubo error",
            },
          };
        }

        return error.response;
      }
    }
  };

  const uploadPrecios = async (datosCargaArchivo, onUploadProgress) => {
    return clienteAxios.post(
      "/productos/precios/actualizacion",
      {
        ...datosCargaArchivo,
        archivoPrecios: [
          {
            inputStream: datosCargaArchivo.inputStream,
            mimeType: datosCargaArchivo.mimeType,
            nombreArchivo: datosCargaArchivo.nombreArchivo,
            tamano: datosCargaArchivo.tamano,
          },
        ],
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
        onUploadProgress,
      }
    );
  };

  const descargarFormatoPrecios = async () => {
    try {
      const response = await clienteAxios.post(
        "/productos/precios/layout",
        { nombreArchivo: "precios.xlsx" },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
          responseType: "blob",
        }
      );

      var file = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const uploadDocumentoMedicamentoFalso = async (datosCargaArchivo) => {
    try {
      const response = await clienteAxios.post(
        "/medicamentofalso/agregar/archivo",
        {
          ...datosCargaArchivo,
          archivos: [
            {
              inputStream: datosCargaArchivo.inputStream,
              mimeType: datosCargaArchivo.mimeType,
              nombreArchivo: datosCargaArchivo.nombreArchivo,
              tamano: datosCargaArchivo.tamano,
            },
          ],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const setDocumentoPrevio = async (idProductoCompraExterna) => {
    try {
      const response = await clienteAxios.post(
        "/medicamentofalso/archivo",
        {
          idProductoCompraExterna: idProductoCompraExterna,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      dispatch({
        type: SET_DOCUMENTO_PREVIO,
        payload: response.data,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const exportFormatoMedicamentoFalso = async () => {
    // try {
    //   const res = await clienteAxios.post(`/productoservicio/export/formato`, {
    //     responseType: "arraybuffer",
    //     headers: {
    //       "Content-Disposition": "attachment; filename=nota.pdf",
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   });
    //   var file = new Blob([res.data], { type: "application/pdf" });
    //   var fileURL = URL.createObjectURL(file);
    //   window.open(fileURL);
    //   return res;
    // } catch (error) {
    //   if (!error.response) {
    //     error.response = {
    //       data: {
    //         message: "Fallo en la conexión al servidor",
    //         error: "hubo error",
    //       },
    //     };
    //   } else {
    //     error.response = {
    //       data: {
    //         message: "Error, por favor inténtalo más tarde",
    //         error: "Error",
    //       },
    //     };
    //   }
    //   return error.response;
    // }
  };

  return (
    <CatProductosContext.Provider
      value={{
        catProductos: state.catProductos,
        filtrosProductos: state.filtrosProductos,
        productosPaginados: state.productosPaginados,
        preciosProducto: state.preciosProducto,
        progress: state.progress,
        documentoPrevio: state.documentoPrevio,
        soluciones: state.soluciones,
        productosSelect: state.productosSelect,
        listarCatProductos,
        setDataProductos,
        setFiltrosProductos,
        uploadNuevoProducto,
        eliminarProducto,
        eliminarMedicamento,
        updateProducto,
        actualizarPreciosProducto,
        actualizarMedicamentosVo,
        actualizarProductosPaginados,
        listarPreciosProducto,
        uploadPrecios,
        listarMedicamentos,
        MedicamentosVentaMostradorPaciente,
        MedicamentosCompraExterna,
        uploadDocumentoMedicamentoFalso,
        setDocumentoPrevio,
        exportFormatoMedicamentoFalso,
        listarSoluciones,
        descargarFormatoPrecios,
        loadProductosSelect,
      }}>
      {props.children}
    </CatProductosContext.Provider>
  );
}
