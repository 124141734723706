import React, {useReducer} from 'react'
import NotificacionesContext from './NotificacionesContext'
import NotificacionesReducer from './NotificacionesReducer'
import SinWifi from "../../assets/images/sinwifi.jpg"
import ConWifi from "../../assets/images/conwifi.jpg"
import clienteAxios from '../../config/axios'
import {
    LISTAR_NOTIFICACIONES,
    FILTROS_NOTIFICACIONES,
    NUMERO_NOTIFICACIONES,
    WIFI_EN_LINEA
} from '../../types'

export default function NotificacionesState (props) {

    const initialState = { 
        notificaciones:[],
        filtrosNotificacion:{},
        numeroDeNotificaciones:0,
        WifiActivo:ConWifi
    }

    const [state, dispatch] = useReducer(NotificacionesReducer,initialState)

    const listarNotificaciones = async (filtrosNotificacion) => {
        try
        {
            const res = await clienteAxios.post("/notificaciones", filtrosNotificacion, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            })
            dispatch({
                type: LISTAR_NOTIFICACIONES,
                payload: res.data
            })
        }catch(error){
            dispatch({
                type:WIFI_EN_LINEA,
                payload:SinWifi                  
            })            
        }
    }

    const setFiltrosNotificacion = async (filtrosNotificacion) => {
        try
        {
            dispatch({
                type: FILTROS_NOTIFICACIONES,
                payload: filtrosNotificacion
            })
        }catch(error){
            dispatch({
                type:WIFI_EN_LINEA,
                payload:SinWifi                
            })           
        }            
    }

    const readNotificacion = async (idNotificacion) => 
    {   try
        {
            await clienteAxios.post("/notificaciones/leida", {idNotificacion:idNotificacion}, 
            {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            })
            obtenerNumeroNotificaciones();
        }catch(error){
        }        
    }

    const unReadNotificacion = async (idNotificacion) => 
    {   try
        {
            await clienteAxios.post("/notificaciones/noleida", {idNotificacion:idNotificacion}, 
            {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            })
            obtenerNumeroNotificaciones();
        }catch(error){
        }        
    }

    const obtenerNumeroNotificaciones = async () => {
        try
        {
            let fecha = global.fechaInicial;
           
            const res = await clienteAxios.post("/notificaciones/numero",{
                fechaInicial: fecha,
                fechaFinal: new Date().toISOString().slice(0, 10),
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }) 
            dispatch({
                type: NUMERO_NOTIFICACIONES,
                payload: res.data
            })

            dispatch({
                type:WIFI_EN_LINEA,
                payload:ConWifi
            })
        }catch(error){
                alert("Se perdió la conexión con el servidor, por favor notifique a su departamento de soporte")
                dispatch({
                    type:WIFI_EN_LINEA,
                    payload:SinWifi
                })
        }
    }

    return (
        <NotificacionesContext.Provider value={{
            notificaciones: state.notificaciones,
            filtrosNotificacion: state.filtrosNotificacion,
            numeroDeNotificaciones: state.numeroDeNotificaciones,
            WifiActivo:state.WifiActivo,
            listarNotificaciones,
            setFiltrosNotificacion,
            readNotificacion,
            unReadNotificacion,
            obtenerNumeroNotificaciones
        }}>
            {props.children}
        </NotificacionesContext.Provider>
    )
}