import React, { useReducer } from "react";

import CatViasContext from "./CatViasContext";
import CatViasReducer from "./CatViasReducer";

import { LISTAR_CAT_VIAS } from "../../types";

import clienteAxios from "../../config/axios";

export default function CatViasState(props) {
  const initialState = {
    vias: [],
  };

  const [state, dispatch] = useReducer(CatViasReducer, initialState);

  const listarCatVias = async () => {
    const res = await clienteAxios.get("/vias/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CAT_VIAS,
      payload: res.data,
    });
  };

  return (
    <CatViasContext.Provider
      value={{
        vias: state.vias,
        listarCatVias,
      }}>
      {props.children}
    </CatViasContext.Provider>
  );
}
