import { SET_EDITADO, SET_GUARDADO } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case SET_EDITADO:
      return {
        ...state,
        seEdito: action.payload,
      };
    case SET_GUARDADO:
      return {
        ...state,
        seGuardaronCambiosContext: action.payload,
      };

    default:
      return state;
  }
};
