import React, { useContext, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Row, Col, Card, CardBody } from "reactstrap";

import TabListadoEstudiosLaboratorio from "../EstudiosLaboratorio/TabListadoEstudiosLaboratorio/index";
import TabListadoEstudiosImagen from "./TabListadoEstudiosImagen";
import TabMarcadores from "../EstudiosLaboratorio/TabMarcadores/index";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import SexosContext from "../../context/Sexos/SexosContext";
import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";
import EstudiosLaboratorioContext from "../../context/EstudiosLaboratorio/EstudiosLaboratorioContext";

import PacientesContext from "../../context/Pacientes/PacientesContext";

import DrawerExpedientePaciente from "../DrawerExpedientePaciente/index";

import Galeria from "../Galeria";

export default function EstudiosLaboratorio(props) {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabIndexEstudios, setTabIndexEstudios] = useState(0);

  // eslint-disable-next-line no-unused-vars

  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado, idExpedienteMedicoDelPaciente } = pacientesContext;

  // eslint-disable-next-line no-unused-vars
  const [idExpedienteMedico, setIdExpedienteMedico] = useState(
    props && props.history.location.state && props.history.location.state.historiaClinicaInicialProps
      ? props.history.location.state.historiaClinicaInicialProps.expedienteMedico.idExpedienteMedico
      : idExpedienteMedicoDelPaciente
  );

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  const ctxEstLab = useContext(EstudiosLaboratorioContext);
  const { listarEstudiosLaboratorio, estudiosLaboratorio, archivosImagen } = ctxEstLab;

  // Recibo todos los estudios y filtro por tipo 2 para llamar a "/view/archivo/{idE}/{idA}"

  useEffect(() => {
    const fetchAll = async () => {
      listarSexos();
      listarAseguradoras();
      listarEscolaridades();
      listarEstadosCiviles();
      listarLugares();
      listarOcupaciones();
      listarMedicos();
    };
    fetchAll();
  }, []);

  useEffect(() => {
    if (idExpedienteMedico) {
      if (props && props.history.location.state && props.history.location.state.historiaClinicaInicialProps) {
        listarEstudiosLaboratorio(
          props.history.location.state.historiaClinicaInicialProps.paciente.idCatPaciente,
          idExpedienteMedico
        );
      }
    } else if (idExpedienteMedicoDelPaciente) {
      listarEstudiosLaboratorio(idCatPacienteSeleccionado, idExpedienteMedicoDelPaciente);
    }
  }, []);

  const redirectHistoriaClinicaInicial = async () => {
    props.history.push("/historia/clinica/inicial");
  };

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }, []);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Estudios del Paciente</h3>
      <DrawerExpedientePaciente
        historiaClinicaInicial={historiaClinicaInicial}
        sexos={sexos}
        aseguradoras={aseguradoras}
        medicos={medicos}
        lugares={lugares}
        ocupaciones={ocupaciones}
        estadosCiviles={estadosCiviles}
        escolaridades={escolaridades}
      />
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col>
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                type="button"
                onClick={redirectHistoriaClinicaInicial}>
                Historia Clínica Inicial
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs selectedIndex={tabIndexEstudios} onSelect={(index) => setTabIndexEstudios(index)}>
                <TabList>
                  <Tab>Estudios de Laboratorio</Tab>
                  <Tab>Estudios de Imagen</Tab>
                </TabList>
                <TabPanel>
                  <Row>
                    <Col>
                      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList>
                          <Tab>Listado de Estudios de Laboratorio</Tab>
                          <Tab>Marcadores</Tab>
                        </TabList>
                        <TabPanel>
                          <TabListadoEstudiosLaboratorio props={props} />
                        </TabPanel>
                        <TabPanel>
                          <TabMarcadores props={props} />
                        </TabPanel>
                      </Tabs>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <Row>
                    <Col>
                      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList>
                          <Tab>Listado de Estudios de Imagen</Tab>
                          <Tab>Galería</Tab>
                        </TabList>
                        <TabPanel>
                          <TabListadoEstudiosImagen props={props} idExpedienteMedico={idExpedienteMedico} />
                        </TabPanel>
                        <TabPanel>
                          {(props &&
                            props.history.location.state &&
                            props.history.location.state.historiaClinicaInicialProps &&
                            props.history.location.state.historiaClinicaInicialProps.expedienteMedico.idExpedienteMedico) ||
                          idExpedienteMedicoDelPaciente ? (
                            <Galeria
                              // idExpedienteMedico={
                              //   historiaClinicaInicial &&
                              //   historiaClinicaInicial.expedienteMedico &&
                              //   historiaClinicaInicial.expedienteMedico.idExpedienteMedico
                              //     ? historiaClinicaInicial.expedienteMedico.idExpedienteMedico
                              //     : ""
                              // }
                              estudios={estudiosLaboratorio}
                              archivosImagen={archivosImagen}
                              galleryID={1}
                            />
                          ) : null}
                        </TabPanel>
                      </Tabs>
                    </Col>
                  </Row>
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
