import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AuthContext from "../../context/Auth/AuthContext";
import UbicacionesContext from "../../context/Ubicaciones/UbicacionesContext";
import EstantesContext from "../../context/Estantes/EstantesContext";
import AnaquelesContext from "../../context/Anaqueles/AnaquelesContext";

export default function Ubicaciones(props) {
  const { idCatEstante } = useParams();

  const cleanUbicacion = {
    activo: true,
  };

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_ubicacion: false,
    editar: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  const [loading, setLoading] = useState(false);

  const [ubicacionNuevoEditar, setUbicacionNuevoEditar] = useState(cleanUbicacion);

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const ubicacionesCtx = useContext(UbicacionesContext);
  const { estanteSeleccionado, ubicaciones, seleccionarEstante, uploadUbicacion, uploadDeleteUbicacion } = ubicacionesCtx;

  const estantesCtx = useContext(EstantesContext);
  const { anaquelSeleccionado, seleccionarAnaquelByEstante } = estantesCtx;

  const anaquelesCtx = useContext(AnaquelesContext);
  const { almacenSeleccionado, seleccionarAlmacenByAnaquel } = anaquelesCtx;

  const checkUploadUbicacion = async () => {
    let errorUbicacion = false;

    if (!ubicacionNuevoEditar.ubicacion && !errorUbicacion) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el nombre de la ubicación",
      });
      errorUbicacion = true;
      return;
    }

    if (!errorUbicacion) {
      setLoading(true);
      const res = await uploadUbicacion(ubicacionNuevoEditar);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          editar: false,
          dynamic_title: "Exito",
          confirm_alert_ubicacion: false,
          dynamic_description: "Se generó una nueva ubicación",
        });
        setUbicacionNuevoEditar(cleanUbicacion);
      }
    }
  };

  const onChangeUbicacion = async (e) => {
    let value = e.target.value;

    if (e.target.name == "activo") {
      value = !ubicacionNuevoEditar.activo;
    }

    setUbicacionNuevoEditar({
      ...ubicacionNuevoEditar,
      [e.target.name]: value,
    });
  };

  const eliminarUbicacion = async () => {
    setLoading(true);
    const res = await uploadDeleteUbicacion(ubicacionNuevoEditar);
    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_ubicacion_eliminar: false,
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_ubicacion_eliminar: false,
        dynamic_description: "Se eliminó la ubicación de manera correcta.",
      });
      setUbicacionNuevoEditar(cleanUbicacion);
    }
  };

  useEffect(() => {
    if (Object.entries(estanteSeleccionado).length === 0) {
      seleccionarEstante(idCatEstante);
    }

    if (Object.entries(estanteSeleccionado).length !== 0 && Object.entries(anaquelSeleccionado).length === 0) {
      seleccionarAnaquelByEstante(estanteSeleccionado.idCatEstante);
    }

    if (Object.entries(anaquelSeleccionado).length !== 0 && Object.entries(almacenSeleccionado).length === 0) {
      seleccionarAlmacenByAnaquel(anaquelSeleccionado.idCatAnaquel);
    }
  }, [estanteSeleccionado, anaquelSeleccionado]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Lista de Ubicaciones</h3>

      <Card className="mt-10 card" style={{ padding: "1.5rem 1.5rem 1.5rem" }}>
        <CardBody>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Almacén Seleccionado</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={almacenSeleccionado ? almacenSeleccionado.almacen : ""}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Anaquel Seleccionado</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={anaquelSeleccionado ? anaquelSeleccionado.anaquel : ""}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Estante Seleccionado</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={estanteSeleccionado ? estanteSeleccionado.estante : ""}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Responsable</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={almacenSeleccionado.responsable ? almacenSeleccionado.responsable.usuario : ""}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col>
              <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mr-6 hover:text-white"
                type="button"
                hidden={usuario.authorities.includes("OP_ALMACEN_CREAR") ? false : true}
                onClick={() => {
                  setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_ubicacion: true,
                    editar: false,
                  });
                }}>
                Nueva Ubicación
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-responsive mt-4">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        Nombre<br></br>Ubicación
                      </th>
                      <th scope="col">Activo</th>
                      <th scope="col">Editar</th>
                      <th scope="col">Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ubicaciones.map((ubicacion) => {
                      return (
                        <tr key={ubicacion}>
                          <td>{ubicacion.ubicacion ? ubicacion.ubicacion : ""}</td>
                          <td>{ubicacion.activo ? "SI" : "NO"} </td>
                          <td>
                            <button
                              className="btn btn-primary-purple-umo w-md waves-effect waves-light mr-4"
                              type="button"
                              hidden={usuario.authorities.includes("OP_ALMACEN_EDITAR") ? false : true}
                              onClick={() => {
                                setUISweetAlert({
                                  ...UISweetAlert,
                                  confirm_alert_ubicacion: true,
                                  editar: true,
                                });
                                setUbicacionNuevoEditar(ubicacion);
                              }}>
                              Editar
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-primary-purple-umo w-md waves-effect waves-light mr-4"
                              type="button"
                              hidden={usuario.authorities.includes("OP_ALMACEN_BORRAR") ? false : true}
                              onClick={() => {
                                setUISweetAlert({
                                  ...UISweetAlert,
                                  confirm_alert_ubicacion_eliminar: true,
                                });
                                setUbicacionNuevoEditar(ubicacion);
                              }}>
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_ubicacion ? (
        <SweetAlert
          title={UISweetAlert.editar ? "Editar Ubicación" : "Nuevo Ubicación"}
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: "50%",
          }}
          onConfirm={() => checkUploadUbicacion()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_ubicacion: false,
              editar: false,
            });
            setUbicacionNuevoEditar(cleanUbicacion);
          }}>
          <div className="mt-2">
            <FormControlLabel
              control={
                <Switch
                  disabled={!UISweetAlert.editar}
                  checked={ubicacionNuevoEditar.activo}
                  onChange={onChangeUbicacion}
                  name="activo"
                  color="primary"
                />
              }
              label="Activo"
            />
          </div>
          <div className="mt-2">
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">
                Nombre<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  onChange={onChangeUbicacion}
                  name="ubicacion"
                  value={ubicacionNuevoEditar.ubicacion}
                />
                {(ubicacionNuevoEditar.ubicacion === "" || !ubicacionNuevoEditar.ubicacion) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_ubicacion_eliminar ? (
        <SweetAlert
          title="¿Desea eliminar la ubicación?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarUbicacion()}
          onCancel={() => {
            setUbicacionNuevoEditar(cleanUbicacion);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_ubicacion_eliminar: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
