import React, { useReducer } from "react";

import ClientesContext from "./ClientesContext";
import ClientesReducer from "./ClientesReducer";

import { LISTAR_CLIENTES, FILTRO_CLIENTES, SET_CLIENTE } from "../../types";

import clienteAxios from "../../config/axios";

export default function ClientesState(props) {
  const initialState = {
    clientes: [],
    clientesActivos: [],
    filtrosClientes: {},
    cliente: {},
    idCatClienteSeleccionado: localStorage.getItem("idCatClienteSeleccionado"),
  };

  const [state, dispatch] = useReducer(ClientesReducer, initialState);

  const setDataCliente = async ({ page, limit }) => {
    try {
      const response = await clienteAxios.post(
        "/clientes/paginado/",
        { pageSize: limit, pageIndex: page, ...state.filtrosClientes },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const listarClientes = async () => {
    const res = await clienteAxios.get("/clientes/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CLIENTES,
      payload: res.data,
    });
  };

  const setFiltrosClientes = async (filtrosClientes) => {
    dispatch({
      type: FILTRO_CLIENTES,
      payload: filtrosClientes,
    });
  };

  const uploadNuevoCliente = async (datosCliente) => {
    // Checar que el cliente no tenga el mismo CURP que otro cliente en la base datos

    try {
      const res = await clienteAxios.post(`/clientes/guardar`, datosCliente, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarClienteLog = async (logVo) => {
    // Checar que el paciente no tenga el mismo CURP que otro paciente en la base datos
    try {
      const res = await clienteAxios.post(`clientes/eliminar/log`, logVo, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarCliente = async (idCatCliente) => {
    // Sustituir delete con dirección del back
    // const res = await clienteAxios.delete(`/clientes/${idCatCliente}`)
    try {
      const response = await clienteAxios.post(
        "/clientes/eliminar/",
        { idCatCliente: idCatCliente },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const updateCliente = async (clienteModificado) => {
    try {
      const res = await clienteAxios.post(`/clientes/guardar`, clienteModificado, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setClienteWithDetalles = async (idCatCliente) => {
    const res = await clienteAxios.get(`/clientes/detalles?id=${idCatCliente}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: SET_CLIENTE,
      payload: res.data,
    });

    return res.data;
  };

  return (
    <ClientesContext.Provider
      value={{
        clientes: state.clientes,
        filtrosClientes: state.filtrosClientes,
        cliente: state.cliente,
        listarClientes,
        setFiltrosClientes,
        uploadNuevoCliente,
        eliminarClienteLog,
        eliminarCliente,
        updateCliente,
        setDataCliente,
        setClienteWithDetalles,
      }}>
      {props.children}
    </ClientesContext.Provider>
  );
}
