import { FILTRO_REPORTE_VENTAS, FILTRO_REPORTE_UTILIDADES } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case FILTRO_REPORTE_VENTAS:
      return {
        ...state,
        filtrosReporteVentas: action.payload,
      };
    case FILTRO_REPORTE_UTILIDADES:
      return {
        ...state,
        filtrosReporteUtilidades: action.payload,
      };

    default:
      return state;
  }
};
