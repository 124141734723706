import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

const FiltroBusqueda = (props) => {
  return (
    <Card
      hidden={props.toggleSearchBy}
      className="mt-10 card"
      style={{
        padding: "1.5rem 1.5rem 1.5rem",
      }}>
      <form onSubmit={(e) => props.buscarPorFiltros(e)}>
        <CardBody>
          <Row>
            {props.paramsInput.map((input) => {
              return (
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    {input.col.map((inputCol) => {
                      return (
                        <>
                          {inputCol.colType === "input" ? (
                            <>
                              <label className="col-sm-4 col-form-label" hidden={inputCol.hidden}>
                                {inputCol.label}
                              </label>
                              <Col sm={7} hidden={inputCol.hidden}>
                                <input
                                  className={inputCol.type !== "checkbox" ? "form-control" : ""}
                                  type={inputCol.type}
                                  name={inputCol.name}
                                  disabled={inputCol.disabled}
                                  min={inputCol.min}
                                  max={inputCol.max}
                                  onChange={inputCol.onChange}
                                  value={inputCol.value ? inputCol.value : ""}
                                  checked={inputCol.checked}
                                  hidden={inputCol.hidden}
                                />
                              </Col>
                            </>
                          ) : null}

                          {inputCol.colType === "select" ? (
                            <>
                              <label className="col-sm-4 col-form-label" hidden={inputCol.hidden}>
                                {inputCol.label}
                              </label>
                              <Col sm={7} hidden={inputCol.hidden}>
                                {!inputCol.customSelect ? (
                                  <select
                                    className="form-control"
                                    name={inputCol.name}
                                    onChange={inputCol.onChange}
                                    hidden={inputCol.hidden}
                                    disabled={inputCol.disabled ? inputCol.disabled : false}
                                    value={inputCol.value ? inputCol.value : ""}>
                                    <option selected value="" hidden={inputCol.hidden}>
                                      Seleccione una opción
                                    </option>

                                    {inputCol.opciones.map((opcion) => {
                                      return (
                                        <option hidden={inputCol.hidden} value={opcion.id}>
                                          {opcion.nombre}
                                        </option>
                                      );
                                    })}
                                  </select>
                                ) : inputCol.customSelect ? (
                                  inputCol.customSelect
                                ) : null}
                              </Col>
                            </>
                          ) : null}    

                          {inputCol.colType === "selectExterno" ? (
                            <>
                              <label className="col-sm-4 col-form-label" hidden={inputCol.hidden}>
                                {inputCol.label}
                              </label>
                              <Col sm={7} hidden={inputCol.hidden}>
                                {!inputCol.customSelect ? (
                                  <select
                                    className="form-control"
                                    name={inputCol.name}
                                    onChange={inputCol.onChange}
                                    hidden={inputCol.hidden}
                                    disabled={inputCol.disabled ? inputCol.disabled : false}
                                    value={inputCol.value ? inputCol.value : ""}>
                                    <option selected value="" hidden={inputCol.hidden}>
                                      Seleccione una opción
                                    </option>

                                    {inputCol.opciones.map((opcion) => {
                                      return (
                                        <option hidden={inputCol.hidden} value={opcion.idProductoCompraExterna}>
                                          {opcion.nombreComercial} - {opcion.concentracion} - {opcion.presentacion}
                                        </option>
                                      );
                                    })}
                                  </select>
                                ) : inputCol.customSelect ? (
                                  inputCol.customSelect
                                ) : null}
                              </Col>
                            </>
                          ) : null}                              

                          {inputCol.colType === "radio" ? (
                            <>
                              <label className="col-sm-4 col-form-label" hidden={inputCol.hidden}>
                                {inputCol.label}
                              </label>
                              <Col sm={7} hidden={inputCol.hidden}>
                                <input
                                    type="radio"
                                    name="opcion"
                                    width={30}
                                    //disabled = {!existeProductoVentaMostrador}
                                    onChange={inputCol.onChange}
                                    //checked={changeNuevaBitacora.cheked}
                                    value = {1}
                                />    
                                <label className="col-sm-3 col-form-label" >Vale</label>
                                
                                <input
                                    type="radio"
                                    name="opcion"
                                    width={30}
                                    //disabled = {!existeProductoVentaMostrador}
                                    onChange={inputCol.onChange}
                                    //checked={changeNuevaBitacora.cheked}
                                    value = {2}
                                />    
                                <label className="col-sm-3 col-form-label">Venta Mostrador   </label>                 

                                <input
                                    type="radio"
                                    name="opcion"
                                    width={30}
                                    //disabled = {!existeProductoVentaMostrador}
                                    onChange={inputCol.onChange}
                                    //checked={changeNuevaBitacora.cheked}
                                    value = {3}
                                />    
                                <label className="col-sm-3 col-form-label">Compra Externa   </label>  
                              </Col>
                            </>
                          ) : null}                                                   
                        </>
                      );
                    })}
                  </Row>
                </Col>
              );
            })}
            <Row>
              <Col>
                <button
                  id="aplicarFiltros"
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="submit"
                  style={{
                    marginRight: "2.5rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}>
                  {props.botonAplicar ? props.botonAplicar : "Aplicar Filtros"}
                </button>
                <button
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="button"
                  onClick={props.limpiarFiltros}
                  style={{
                    marginRight: "2.5rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}>
                  Limpiar Filtros
                </button>
              </Col>
            </Row>
          </Row>
        </CardBody>
      </form>
    </Card>
  );
};

export default FiltroBusqueda;
