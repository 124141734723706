import { SET_NOTAS_ENFERMERIA_PAGINADOS, SET_NOTAS_EVOLUCION } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case SET_NOTAS_ENFERMERIA_PAGINADOS:
      return {
        ...state,
        notasEnfermeriaPaginadas: action.payload,
      };
    case SET_NOTAS_EVOLUCION:
      return {
        ...state,
        notasEvolucionPaginadas: action.payload,
      };

    default:
      return state;
  }
};
