import { LISTAR_CATUSOCFDI } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case LISTAR_CATUSOCFDI:
      return {
        ...state,
        catUsoCfdis: action.payload,
      };
    default:
      return state;
  }
};
