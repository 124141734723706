import React, {useContext, useEffect, useState} from "react";
import useWindowDimensions from "../../hooks/dimensionsHook";

// import { Link } from "react-router-dom";
import AuthContext from "../../context/Auth/AuthContext";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import AnaquelesContext from "../../context/Anaqueles/AnaquelesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";

import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {maxCharTelefonoAlmacen} from "../../config/variables";

export default function Almacenes(props) {
    const {width} = useWindowDimensions();
    const cleanAlmacen = {
        activo: true,
    };

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_almacen: false,
        editar: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    const [loading, setLoading] = useState(false);

    const [almacenNuevoEditar, setAlmacenNuevoEditar] = useState(cleanAlmacen);

    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    const almacenesCtx = useContext(AlmacenesContext);
    const {almacenes, listarAlmacenes, uploadAlmacen, uploadDeleteAlmacen} = almacenesCtx;

    const anaquelesCtx = useContext(AnaquelesContext);
    const {limpiarAnaqueles} = anaquelesCtx;

    const ursCtx = useContext(UsuariosContext);
    const {usuarios, listarUsuarios} = ursCtx;

    const lugaresContext = useContext(LugaresContext);
    const {lugares, listarLugares} = lugaresContext;

    const administrador =
        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
        usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
        usuario.authorities.includes("ROLE_DIRECCION");    

    /* ************ EN ESTA SECCIÓN SE VALIDA LA INFORMACION DE CADA CAMPO DE LA TABLA  **************** */
    const checkUploadAlmacen = async () => {
        let errorAlmacen = false;

        if (!almacenNuevoEditar.almacen && !errorAlmacen) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el nombre del almacén",
            });
            errorAlmacen = true;
            return;
        }

        if (!almacenNuevoEditar.calle && !almacenNuevoEditar.personal && !errorAlmacen) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca la calle del almacén",
            });
            errorAlmacen = true;
            return;
        }

        if (!almacenNuevoEditar.numero && !almacenNuevoEditar.personal && !errorAlmacen) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el número de la calle del almacén",
            });
            errorAlmacen = true;
            return;
        }

        if (!almacenNuevoEditar.municipio && !almacenNuevoEditar.personal && !errorAlmacen) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el municipio del almacén",
            });
            errorAlmacen = true;
            return;
        }

        if (!almacenNuevoEditar.idEstado && !almacenNuevoEditar.personal && !errorAlmacen) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el estado del almacén",
            });
            errorAlmacen = true;
            return;
        }

        if (!almacenNuevoEditar.pais && !almacenNuevoEditar.personal && !errorAlmacen) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el país del almacén",
            });
            errorAlmacen = true;
            return;
        }

        if (!almacenNuevoEditar.cp && !almacenNuevoEditar.personal && !errorAlmacen) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el código postal del almacén",
            });
            errorAlmacen = true;
            return;
        }

        if (!almacenNuevoEditar.telefono && !almacenNuevoEditar.personal && !errorAlmacen) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el teléfono del almacén",
            });
            errorAlmacen = true;
            return;
        }

        if (!almacenNuevoEditar.idResponsable && !almacenNuevoEditar.idResponsable && !errorAlmacen) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el responsable del almacén",
            });
            errorAlmacen = true;
            return;
        }

        if (!errorAlmacen) {
            setLoading(true);
            const res = await uploadAlmacen(almacenNuevoEditar, administrador);

            if (res.data.error) {
                setLoading(false);

                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else if (res) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    editar: false,
                    dynamic_title: "Exito",
                    confirm_alert_almacen: false,
                    dynamic_description: "Se generó o modificó un nuevo almacén",
                });

                setAlmacenNuevoEditar(cleanAlmacen);
            }
        }
    };
    
    /* ********************************************************************************************* */
    const onChangeAlmacen = async (e) => {
        let value = e.target.value;

        if (e.target.name == "activo") {
            value = !almacenNuevoEditar.activo;
        }

        if (e.target.name == "personal") {
            value = !almacenNuevoEditar.personal;
        }

        setAlmacenNuevoEditar({
            ...almacenNuevoEditar,
            [e.target.name]: value,
        });
    };

    const eliminarAlmacen = async () => {
        if (almacenNuevoEditar.anaqueles && almacenNuevoEditar.anaqueles.length > 0) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_almacen_eliminar: false,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "No se pueden eliminar almacenes que tengan anaqueles asociados",
            });
            return;
        }

        setLoading(true);
        const res = await uploadDeleteAlmacen(almacenNuevoEditar, administrador);

        if (res.data.error) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                confirm_alert_almacen_eliminar: false,
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_almacen_eliminar: false,
                dynamic_description: "Se eliminó el almacén de manera correcta.",
            });

            setAlmacenNuevoEditar(cleanAlmacen);
        }
    };

    useEffect(() => {
        listarAlmacenes(true, administrador);
        listarUsuarios();
        limpiarAnaqueles();
        listarLugares();
    }, []);

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Lista de Almacenes</h3>
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <button
                                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mr-6 hover:text-white"
                                type="button"
                                hidden={usuario.authorities.includes("OP_ALMACEN_CREAR") ? false : true}
                                onClick={() => {
                                    setUISweetAlert({
                                        ...UISweetAlert,
                                        confirm_alert_almacen: true,
                                        editar: false,
                                    });
                                }}>
                                Nuevo Almacén
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="table-responsive mt-4">
                                <table className="table table-hover table-centered table-nowrap mb-0">
                                    <thead>
                                    <tr>
                                        <th scope="col">
                                            Nombre<br></br>Almacén
                                        </th>
                                        <th scope="col">Calle</th>
                                        <th scope="col">Número</th>
                                        <th scope="col">Colonia</th>
                                        <th scope="col">Municipio</th>
                                        <th scope="col">Estado</th>
                                        <th scope="col">País</th>
                                        <th scope="col">CP</th>
                                        <th scope="col">Telefóno</th>
                                        <th scope="col">Responsable</th>
                                        <th scope="col">Activo</th>
                                        <th scope="col" colSpan={2}>   Anaqueles</th>
                                        <th scope="col">Editar</th>
                                        <th scope="col">Eliminar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {almacenes.map((almacen) => {
                                        return (
                                            <tr key={almacen}>
                                                <td>{almacen.almacen ? almacen.almacen : ""}</td>
                                                <td>{almacen.calle ? almacen.calle : ""}</td>
                                                <td>{almacen.numero ? almacen.numero : ""}</td>
                                                <td>{almacen.colonia ? almacen.colonia : ""}</td>
                                                <td>{almacen.municipio ? almacen.municipio : ""}</td>
                                                <td>{almacen.estado ? almacen.estado : ""}</td>
                                                <td>{almacen.pais ? almacen.pais : ""}</td>
                                                <td>{almacen.cp ? almacen.cp : ""}</td>
                                                <td>{almacen.telefono ? almacen.telefono : ""}</td>
                                                <td>{almacen.responsable ? almacen.responsable : ""}</td>
                                                <td>{almacen.activo ? "SI" : "NO"} </td>
                                                <td>{almacen.anaqueles ? almacen.anaqueles.length : "0"}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                                        type="button"
                                                        hidden={usuario.authorities.includes("OP_ALMACEN_EDITAR") ? false : true}
                                                        onClick={() => props.history.push(`/anqueles/${almacen.idCatAlmacen}`)}>
                                                        Detalles
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                                        type="button"
                                                        hidden={usuario.authorities.includes("OP_ALMACEN_EDITAR") ? false : true}
                                                        onClick={() => {
                                                            setUISweetAlert({
                                                                ...UISweetAlert,
                                                                confirm_alert_almacen: true,
                                                                editar: true,
                                                            });
                                                            setAlmacenNuevoEditar(almacen);
                                                        }}>
                                                        Editar
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                                        type="button"
                                                        hidden={usuario.authorities.includes("OP_ALMACEN_BORRAR") ? false : true}
                                                        onClick={() => {
                                                            setUISweetAlert({
                                                                ...UISweetAlert,
                                                                confirm_alert_almacen_eliminar: true,
                                                            });
                                                            setAlmacenNuevoEditar(almacen);
                                                        }}>
                                                        Eliminar
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            
            {UISweetAlert.confirm_alert_almacen ? (
                <SweetAlert
                    title={UISweetAlert.editar ? "Editar Almacén" : "Nuevo Almacén"}
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => checkUploadAlmacen()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_almacen: false,
                            editar: false,
                        });
                        setAlmacenNuevoEditar(cleanAlmacen);
                    }}>
                    <div className="mt-2">
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={!UISweetAlert.editar}
                                            checked={almacenNuevoEditar.activo}
                                            onChange={onChangeAlmacen}
                                            name="activo"
                                            color="primary"
                                        />
                                    }
                                    label="Activo"
                                />
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        Nombre<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeAlmacen}
                                            name="almacen"
                                            value={almacenNuevoEditar.almacen}
                                        />
                                        {(almacenNuevoEditar.almacen === "" || !almacenNuevoEditar.almacen) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        Número<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeAlmacen}
                                            name="numero"
                                            value={almacenNuevoEditar.numero}
                                        />
                                        {(almacenNuevoEditar.numero === "" || !almacenNuevoEditar.numero) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        Municipio<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeAlmacen}
                                            name="municipio"
                                            value={almacenNuevoEditar.municipio}
                                        />
                                        {(almacenNuevoEditar.municipio === "" || !almacenNuevoEditar.municipio) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        País<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeAlmacen}
                                            name="pais"
                                            value={almacenNuevoEditar.pais}
                                        />
                                        {(almacenNuevoEditar.pais === "" || !almacenNuevoEditar.pais) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        Teléfono<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            maxLength={maxCharTelefonoAlmacen}
                                            onChange={onChangeAlmacen}
                                            name="telefono"
                                            value={almacenNuevoEditar.telefono}
                                        />
                                        {(almacenNuevoEditar.telefono === "" || !almacenNuevoEditar.telefono) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={almacenNuevoEditar.personal} onChange={onChangeAlmacen} 
                                            name="personal" color="primary"/>
                                    }
                                    label="Personal"
                                />
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        Calle<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeAlmacen}
                                            name="calle"
                                            value={almacenNuevoEditar.calle}
                                        />
                                        {(almacenNuevoEditar.calle === "" || !almacenNuevoEditar.calle) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        Colonia<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeAlmacen}
                                            name="colonia"
                                            value={almacenNuevoEditar.colonia}
                                        />
                                        {(almacenNuevoEditar.colonia === "" || !almacenNuevoEditar.colonia) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        Nota<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeAlmacen}
                                            name="nota"
                                            value={almacenNuevoEditar.nota}
                                        />
                                        {(almacenNuevoEditar.nota === "" || !almacenNuevoEditar.nota) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        Estado<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <select
                                            className="form-control"
                                            onChange={onChangeAlmacen}
                                            name="idEstado"
                                            value={almacenNuevoEditar.idEstado}>
                                            <option selected disabled>
                                                Seleccione una opción
                                            </option>
                                            {lugares.map((lugar) => {
                                                return <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>;
                                            })}
                                        </select>
                                        {(almacenNuevoEditar.idEstado === "" || !almacenNuevoEditar.idEstado) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        CP<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeAlmacen}
                                            name="cp"
                                            value={almacenNuevoEditar.cp}
                                        />
                                        {(almacenNuevoEditar.cp === "" || !almacenNuevoEditar.cp) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">
                                        Reesponsable<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <select
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeAlmacen}
                                            name="idResponsable"
                                            disabled={!administrador} 
                                            value={almacenNuevoEditar.idResponsable}>
                                            <option selected disabled>
                                                Seleccione una opción
                                            </option>
                                            {usuarios.map((usr) => {
                                                return <option value={usr.idCatUsuario}>{usr.usuario}</option>;
                                            })}
                                        </select>
                                        {(almacenNuevoEditar.idResponsable === "" || !almacenNuevoEditar.idResponsable) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_almacen_eliminar ? (
                <SweetAlert
                    title="¿Desea eliminar el almacén?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarAlmacen()}
                    onCancel={() => {
                        setAlmacenNuevoEditar(cleanAlmacen);
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_almacen_eliminar: false,
                        });
                    }}>
                    Se perderán todos los cambios realizados en el lote
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
