import React, { useReducer } from "react";

import ContabilidadReportesContext from "./ContabilidadReportesContext";
import ContabilidadReportesReducer from "./ContabilidadReportesReducer";

import { FILTRO_REPORTE_VENTAS, FILTRO_REPORTE_UTILIDADES } from "../../types";

import clienteAxios from "../../config/axios";

export default function ContabilidadReportesState(props) {
  const initialState = {
    filtrosReporteVentas: {},
    filtrosReporteUtilidades: {},
  };

  const [state, dispatch] = useReducer(ContabilidadReportesReducer, initialState);

  const setFiltrosReporteVentas = async (filtrosReporteVentas) => {
    dispatch({
      type: FILTRO_REPORTE_VENTAS,
      payload: filtrosReporteVentas,
    });
  };
  const setFiltrosReporteUtilidades = async (filtrosReporteUtilidades) => {
    dispatch({
      type: FILTRO_REPORTE_UTILIDADES,
      payload: filtrosReporteUtilidades,
    });
  };

  const exportReporteUtilidades = async () => {
    try {
      const res = await clienteAxios.post(`/reportes/utilidades`, state.filtrosReporteUtilidades, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=reporteutilidades.xlsx",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "reporteutilidades.xlsx");
      document.body.appendChild(link);
      link.click();

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };
  const exportReporteVentas = async () => {
    try {
      const res = await clienteAxios.post(`/reportes/ventas`, state.filtrosReporteVentas, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=reporteventas.xlsx",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "reporteventas.xlsx");
      document.body.appendChild(link);
      link.click();

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <ContabilidadReportesContext.Provider
      value={{
        filtrosReporteVentas: state.filtrosReporteVentas,
        filtrosReporteUtilidades: state.filtrosReporteUtilidades,

        setFiltrosReporteVentas,
        setFiltrosReporteUtilidades,
        exportReporteVentas,
        exportReporteUtilidades,
      }}>
      {props.children}
    </ContabilidadReportesContext.Provider>
  );
}
