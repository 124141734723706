import DatePicker from "react-datepicker";
import React, {useContext} from "react";

import {addYears, startOfYear} from "date-fns";
import SelectPacientesPaginado from "../SelectAsyncPaginate";
import {opcionesSelectPacientes} from "../../config/configOpcionesSelect";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import {formatCatPersonaFullName} from "../../utils/formatCatPersona";
import {meses} from "../../config/variables";
import useWindowDimensions from "../../hooks/dimensionsHook";


const ChartLayout = (props) => {
    const ctxPacientes = useContext(PacientesContext);
    const {loadPacientesSelect} = ctxPacientes;
    const {width} = useWindowDimensions();
    const minYear = startOfYear(addYears(new Date(), -4));
    const maxYear = startOfYear(new Date());

    return (
        <div className="chartBox" style={{
            width: width && width < 851 ? "100%" : "100%",
        }} >
            {props.extraInfo ? (
                <div className="flex flex-col lg:flex-row justify-center items-center mb-2">
                    {props.selectAseguradoras ? <div>
                        <select
                            name="idAseguradora"
                            value={props.filtros?.idAseguradora ? props.filtros?.idAseguradora : ""}
                            onChange={props.onChange}
                            hidden={!props.withFilters}
                            className="rounded border-gray-300 w-full lg:w-3/3 md:w-3/4 mb-3 mt-3 md:m-0"
                        >
                            <option value="">TODAS LAS ASEGURADORAS</option>
                            {props.aseguradoras &&
                                props.aseguradoras.map((option) => (
                                    <option key={option.idCatAseguradora} value={option.idCatAseguradora}>
                                        {option.razonSocial}
                                    </option>
                                ))}
                        </select>
                    </div> : null}
                    {props.selectMedicos ? <div>
                        <select
                            name="idMedico"
                            value={props.filtros?.idMedico ? props.filtros?.idMedico : ""}
                            onChange={props.onChange}
                            hidden={!props.withFilters}
                            className="rounded border-gray-300 w-full lg:w-3/3 md:w-3/4 mb-3 mt-3 md:m-0"
                        >
                        <option value="">TODOS LOS MÉDICOS</option>
                            {props.medicos &&
                                props.medicos.map((option) => (
                                    <option key={option.idCatMedico} value={option.idCatMedico}>
                                        {formatCatPersonaFullName(option.catPersona)}
                                    </option>
                                ))}
                        </select>
                    </div> : null}

                    {props.medicamentos ? <div>
                        <select
                            name="idCatProducto"
                            value={props.filtros?.idCatProducto ? props.filtros?.idCatProducto : ""}
                            onChange={props.onChange}
                            width ={400}
                            hidden={!props.withFilters}
                            className="rounded border-gray-400 w-full lg:w-4/4 md:w-3/4 mb-4 mt-4 md:m-0"
                        >
                            <option value="">TODOS LOS MEDICAMENTOS</option>
                            {props.productosServicios &&
                                props.productosServicios.map((option) => (
                                    <option key={option.idCatProducto} value={option.idCatProducto}>
                                              {option.nombreComercial}                                         
                                    </option>
                                ))}
                        </select>
                    </div> : null}                    

                    {props.selectPacientes ? <div className="text-left w-full mb-3 mt-3 md:m-0 lg:w-1/4 lg:mr-6">
                        <SelectPacientesPaginado
                            opcionSeleccionadaCompleta={props.filtros.pacienteCompleto}
                            onChangeOptions={(e) => props.onChange({target: {name: "paciente", value: e}})}
                            fetchOptions={loadPacientesSelect}
                            opcionesPaginado={opcionesSelectPacientes.opcionesPaginado}
                            placeholderText={opcionesSelectPacientes.placeholderText}
                            optionLabel={opcionesSelectPacientes.optionLabel}
                            optionValue={opcionesSelectPacientes.optionValue}
                            name="paciente"
                            className="rounded border-gray-300 w-full lg:w-3/3 md:w-3/4 mb-3 mt-3 md:m-0"
                        />
                    </div> : null}
                    {props.conFechas ? (
                        <div className="lg:flex-row flex-col lg:flex mt-2">
                            <div className="mb-2 lg:mb-0 lg:mr-2"> {/* Use margin utility classes */}
                                <label className="pr-2 font-bold">Fecha Inicial</label>
                                <input
                                    className="border-gray-300 rounded"
                                    type="date"
                                    name="fechaInicial"
                                    onChange={props.onChange}
                                    value={props.filtros?.fechaInicial}
                                />
                            </div>
                            <div className="mb-2 lg:mb-0"> {/* Use margin utility classes */}
                                <label className="pr-2 pl-2 font-bold">Fecha Final</label>
                                <input
                                    className="border-gray-300 rounded"
                                    type="date"
                                    name="fechaFinal"
                                    onChange={props.onChange}
                                    value={props.filtros?.fechaFinal}
                                />
                            </div>
                        </div>
                    ) : null}

                    {props.conAnio ? 
                    <div className="lg:flex-row flex-col lg:flex mt-2">
                        <div className="mb-2 lg:mb-0 lg:mr-2">
                        <DatePicker
                            className="form-control-inputs"
                            selected={props.filtros?.fullDate}
                            name="anio"
                            onChange={(date) => props.onChange({target: {name: "anio", value: date}})}
                            dateFormat="yyyy"
                            showYearPicker
                            value={props.filtros.anio}
                            minDate={minYear}
                            maxDate={maxYear}
                            onKeyDown={(e) => {
                                e.preventDefault();
                             }}
                        />
                        </div>
                    </div> : null}

                    {props.conMes ? 
                    <div className="lg:flex-row flex-col lg:flex mt-2">
                         <div className="mb-2 lg:mb-0 lg:mr-2">
                        <select
                            className="form-control"
                            name="mes"
                            onChange={props.onChange}
                            value={props.filtros.mes ? props.filtros.mes : ""}>
                            <option selected value= "">
                                Seleccione Mes
                            </option>
                            {meses.map((mes) => {
                                return <option value={mes.meses}>{mes.descripcion}</option>;
                            })}
                        </select>
                        </div>
                    </div> : null}                       
                </div>
            ) : null}

            <div>{props.children}</div>
               <button className={"btn btn-primary-blue-umo w-md waves-effect waves-light flex m-4"} onClick={() => props.exportChart(props.refGrafica)}>Exportar</button>
            </div>
    );
}
export default ChartLayout;
