import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const formatDateWithoutHour = (dateTimeString) => {
  try {
    // create a Day.js object from the date string and parse it as an ISO 8601 formatted string
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const formattedDate = dayjs(dateTimeString).utc().format("YYYY-MM-DD");
    return formattedDate;
  } catch (error) {
    console.error("Error occurred while formatting the date:", error);
    return null;
  }
};

export default formatDateWithoutHour;
