import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import formatNumber from "../../utils/index";

import FiltroFolio from "../Filtros/FiltroFolio/FiltroFolio";
import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";

import AuthContext from "../../context/Auth/AuthContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import PagosMedicoContext from "../../context/PagosMedico/PagosMedicoContext";
import CatTipoCobroContext from "../../context/CatTipoCobro/CatTipoCobroContext";
import CatTipoPagoContext from "../../context/CatTipoPago/CatTipoPagoContext";

export default function NuevoPagoMedico(props) {
  const [toggleSearchBy, setToggleSearchBy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledCheckVales, setDisabledCheckVales] = useState(false);
  const [pagoEliminar, setPagoEliminar] = useState(null);

  const [nuevoRegistroPago, setNuevoRegistroPago] = useState({
    restantePagar: 0,
  });

  // Cambiar el diseño del nuevo pago

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_nuevo_pago: false,
    confirm_alert_eliminar_pago: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  // Estado para los vales seleccionados en donde se guarda la deuda
  // El subtotal con pendiente admin se le suma el pendiente si es diferente del total de admin y si es igual
  // se le suma el total de admin sin el pendiente
  const [valesSeleccionados, setValesSeleccionados] = useState({
    totalMedicamentos: 0,
    totalAdministracion: 0,
    saldoPendienteMedicamentos: 0,
    saldoPendienteAdministracion: 0,
    pendientesAdminPasados: 0,
    pagadoMedicamentos: 0,
    pagadoAdministracion: 0,
    total: 0,
  });

  // Filtros

  // Fecha de hoy en formato "dd/mm/aaaa"

  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  // Contexto para los pagos del médico
  const pagosMedicoContext = useContext(PagosMedicoContext);

  // ACCESO AL ESTADO GLOBAL - AUTH
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxMedicos = useContext(MedicosContext);
  const { medicosInternos, listarMedicosInternos, listarMedicosExternos } = ctxMedicos;

  // Cambiar por tipoPago

  const ctxTipoPago = useContext(CatTipoPagoContext);
  const { tipoPagos, listarCatTipoPagos } = ctxTipoPago;

  const ctxTipoCobro = useContext(CatTipoCobroContext);
  const { listarCatTipoCobros } = ctxTipoCobro;

  const {
    valesMedicosFiltrados,
    setFiltrosValesMedico,
    filtrosValesMedico,
    listarValesMedico,
    setValesMedicoFiltrados,
    registrarNuevoPagoMedico,
  } = pagosMedicoContext;

  const toggleSearch = () => {
    setToggleSearchBy(!toggleSearchBy);
    limpiarFiltros();
  };

  const limpiarFiltros = () => {
    setFiltrosValesMedico({
      fechaInicial: "",
      medico: filtrosValesMedico.medico,
      fechaFinal: "",
      folioVale: "",
      importeMaximo: "",
    });
  };

  const buscarPorFolio = (e) => {
    e.preventDefault();
    
    if(usuario.authorities.includes("ROLE_FARMACIA")){
      listarValesMedico({
        fechaInicial: "",
        medico: "",
        fechaFinal: "",
        importeMaximo: "",
        tipoMedico : 1,
        folioVale: filtrosValesMedico.folioVale,
        idVale: filtrosValesMedico.folioVale,
      });
    }else{
      if(usuario.authorities.includes("ROLE_RECEPCION")){
        listarValesMedico({
          fechaInicial: "",
          medico: "",
          fechaFinal: "",
          importeMaximo: "",
          tipoMedico : 2,
          folioVale: filtrosValesMedico.folioVale,
          idVale: filtrosValesMedico.folioVale,
        });
      }     
    }


    setValesSeleccionados({
      totalMedicamentos: 0,
      totalAdministracion: 0,
      saldoPendienteMedicamentos: 0,
      saldoPendienteAdministracion: 0,
      pendientesAdminPasados: 0,
      pagadoMedicamentos: 0,
      pagadoAdministracion: 0,
      total: 0,
    });

    setDisabledCheckVales(false);
  };

  const buscarPorFiltros = async (e) => {
    e.preventDefault();
    buscarPagos();
  };

  const buscarPagos = async () => {
    if (
      Object.entries(filtrosValesMedico).length === 0 ||
      filtrosValesMedico.medico === "" ||
      filtrosValesMedico.medico === null
    ) {
      setFiltrosValesMedico({ ...filtrosValesMedico, medico: medicosInternos[0].idCatMedico });
      if (filtrosValesMedico.fechaInicial && !filtrosValesMedico.fechaFinal) {
        return;
      }

      if (filtrosValesMedico.fechaFinal) {
        filtrosValesMedico.fechaFinal = filtrosValesMedico.fechaFinal + "T23:59:59";
      }


      const vales = await listarValesMedico({ ...filtrosValesMedico, tipoMedico: usuario.authorities.includes("ROLE_FARMACIA")? 1:2, medico: medicosInternos[0].idCatMedico });
      const valesChecked = await vales.filter((vale) => vale.checked === true);

      if (valesChecked) {
        const valesCheckedTotales = calcularTotales(valesChecked);
        setTotalesValesSeleccionados(valesCheckedTotales);
      }

      if (filtrosValesMedico.fechaFinal) {
        filtrosValesMedico.fechaFinal = filtrosValesMedico.fechaFinal.split("T")[0];
      }
    } else {
      if (filtrosValesMedico.fechaInicial && !filtrosValesMedico.fechaFinal) {
        return;
      }

      if (filtrosValesMedico.fechaFinal) {
        filtrosValesMedico.fechaFinal = filtrosValesMedico.fechaFinal + "T23:59:59";
      }

      let tipoMedico = 0;
      if(filtrosValesMedico.medico && filtrosValesMedico.medico > 0){
        medicosInternos.map((medico) => {
          if (medico.idCatMedico == filtrosValesMedico.medico ) {
            tipoMedico = medico?.catTipoMedico?.idCatTipoMedico? medico.catTipoMedico.idCatTipoMedico :0;
            filtrosValesMedico.tipoMedico =  tipoMedico;
          }
        });
      }

      const vales = await listarValesMedico(filtrosValesMedico);
      const valesChecked = await vales.filter((vale) => vale.checked === true);
      if (valesChecked) {
        const valesCheckedTotales = calcularTotales(valesChecked);
        setTotalesValesSeleccionados(valesCheckedTotales);
      }

      if (filtrosValesMedico.fechaFinal) {
        filtrosValesMedico.fechaFinal = filtrosValesMedico.fechaFinal.split("T")[0];
      }
    }

    // Habilitar los vales y cambiar el checked a false

    setDisabledCheckVales(false);
  };

  const onChangeImporte = (e) => {
    let value = e.target.value;
    value = Number(value.replace("$", ""));

    setFiltrosValesMedico({
      ...filtrosValesMedico,
      importeMaximo: value,
    });
  };

  const onChange = (e) => {
    if (e.target.name === "fechaInicial") {
      setFiltrosValesMedico({
        ...filtrosValesMedico,
        [e.target.name]: e.target.value,
        fechaFinal: e.target.value,
      });
    } else {
      let tipoMedico = 0;
      if (e.target.name === "medico") {
        medicosInternos.map((medico) => {
          if (medico.idCatMedico == e.target.value) {
            tipoMedico = medico?.catTipoMedico?.idCatTipoMedico? medico.catTipoMedico.idCatTipoMedico :0;
            setFiltrosValesMedico({
              ...filtrosValesMedico,
              [e.target.name]: e.target.value,
              tipoMedico : tipoMedico,
            });
            return;
          }
        });
      }else{
        setFiltrosValesMedico({
          ...filtrosValesMedico,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handdleCheckedVale = async (vale, value) => {
    setValesSeleccionados({
      totalMedicamentos: 0,
      totalAdministracion: 0,
      saldoPendienteAdministracion: 0,
      saldoPendienteMedicamentos: 0,
      pendientesAdminPasados: 0,
      pagadoMedicamentos: 0,
      pagadoAdministracion: 0,
    });

    if (!value) {
      vale.checked = value;
      setValesMedicoFiltrados(valesMedicosFiltrados);
      const valesChecados = await isValeChecked();
      if (!valesChecados) {
        habilitarValesChecked();
      }
    } else {
      vale.checked = value;
      setValesMedicoFiltrados(valesMedicosFiltrados);
      if (!disabledCheckVales) {
        deshabilitarValesMedico(vale.idCatMedico);
      }
    }
    const valesChecked = valesMedicosFiltrados.filter((vale) => vale.checked === true);
    const valesCheckedTotales = calcularTotales(valesChecked);
    setTotalesValesSeleccionados(valesCheckedTotales);
  };

  const setTotalesValesSeleccionados = (valesCheckedTotales) => {
    const [
      totalMedicamentos,
      totalAdministracion,
      saldoPendienteAdministracion,
      saldoPendienteMedicamentos,
      pendientesAdminPasados,
    ] = valesCheckedTotales;

    const totalValesSeleccionados = saldoPendienteAdministracion + saldoPendienteMedicamentos;

    setValesSeleccionados({
      totalMedicamentos: totalMedicamentos,
      totalAdministracion: totalAdministracion,
      saldoPendienteAdministracion: saldoPendienteAdministracion,
      saldoPendienteMedicamentos: saldoPendienteMedicamentos,
      pendientesAdminPasados: pendientesAdminPasados,
      pagadoMedicamentos: totalMedicamentos - saldoPendienteMedicamentos,
      pagadoAdministracion: totalAdministracion - saldoPendienteAdministracion,
      total: totalValesSeleccionados,
    });

    if(saldoPendienteMedicamentos > 0){
      setNuevoRegistroPago({
        ...nuevoRegistroPago,
        pagoVales: [],
        restantePagar: saldoPendienteMedicamentos,
        restantePagarTotal: totalValesSeleccionados,
      });
    }else{
      setNuevoRegistroPago({
        ...nuevoRegistroPago,
        pagoVales: [],
        restantePagar: saldoPendienteMedicamentos + pendientesAdminPasados,
        restantePagarTotal: totalValesSeleccionados,
      });
    }

  };

  const calcularTotales = (vales) => {
    let totalMedicamentos = 0;
    let totalAdministracion = 0;
    let totalSaldoPendienteAdmin = 0;
    let totalSaldoPendienteMed = 0;
    let pendientesAdminPasados = 0;

    vales.map((vale) => {
      totalMedicamentos += vale.importeMedicamentos;
      totalAdministracion += vale.importeAdministracion;
      totalSaldoPendienteAdmin += vale.saldoPendienteAdministracion;
      totalSaldoPendienteMed += vale.saldoPendienteMedicamentos;
      if (vale.saldoPendienteAdministracion !== vale.importeAdministracion || vale.saldoPendienteMedicamentos === 0) {
        pendientesAdminPasados += vale.saldoPendienteAdministracion;
      }

      return vale;
    });
    return [totalMedicamentos, totalAdministracion, totalSaldoPendienteAdmin, totalSaldoPendienteMed, pendientesAdminPasados];
  };

  const deshabilitarValesMedico = async (idCatMedico) => {
    valesMedicosFiltrados.map((vale) => {
      if (vale.idCatMedico !== idCatMedico) {
        vale.disabledCheck = true;
      }
      return vale;
    });
    setValesMedicoFiltrados(valesMedicosFiltrados);
    setDisabledCheckVales(true);
  };

  const habilitarValesChecked = async () => {
    valesMedicosFiltrados.map((vale) => {
      vale.disabledCheck = false;
      vale.checked = false;
      return vale;
    });
    setValesMedicoFiltrados(valesMedicosFiltrados);
    setDisabledCheckVales(false);
  };

  const isValeChecked = async () => {
    let valesChecked = false;

    valesMedicosFiltrados.map((vale) => {
      if (vale.checked) {
        if (!valesChecked) {
          valesChecked = [];
        }
        valesChecked = valesChecked.concat(vale);
      }
      return vale;
    });
    return valesChecked;
  };

  const refreshModalPagoVales = async () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_nuevo_pago: false,
    });
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_nuevo_pago: true,
    });
  };

  const insertarNuevoPago = async () => {
    if (!nuevoRegistroPago.pagoVales) {
      nuevoRegistroPago.pagoVales = [];
    }

    let nuevoPago = {
      idCatTipoPago: "",
      importe: "",
    };

    nuevoRegistroPago.pagoVales = nuevoRegistroPago.pagoVales.concat(nuevoPago);
    setNuevoRegistroPago({ ...nuevoRegistroPago });
    refreshModalPagoVales();
  };

  const eliminarPagoVales = async () => {
    nuevoRegistroPago.pagoVales = nuevoRegistroPago.pagoVales.filter((pagoVale) => pagoVale !== pagoEliminar);
    setNuevoRegistroPago(nuevoRegistroPago);
    refreshModalPagoVales();
    calcularRestanteTotalMedicamentos();
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_eliminar_pago: false,
      success_dlg: true,
      dynamic_title: "Exito!",
      dynamic_description: "El pago ha sido eliminado correctamente",
    });
  };

  const calcularRestanteTotalMedicamentos = async () => {
    let cantidadMinimaPagar = valesSeleccionados.saldoPendienteMedicamentos> 0? valesSeleccionados.saldoPendienteMedicamentos : valesSeleccionados.saldoPendienteMedicamentos + valesSeleccionados.pendientesAdminPasados;
    let totalAPagar = valesSeleccionados.total;
    let sumaTotalPago = 0;
    let error = false;

    nuevoRegistroPago.pagoVales.map((pago) => {
      sumaTotalPago += Number(pago.importe);
      return pago;
    });

      if (sumaTotalPago < cantidadMinimaPagar) {
        nuevoRegistroPago.restantePagar = cantidadMinimaPagar - sumaTotalPago;
      } else if (sumaTotalPago >= cantidadMinimaPagar) {
        nuevoRegistroPago.restantePagar = 0;
      }
  
      if (sumaTotalPago < totalAPagar) {
        nuevoRegistroPago.restantePagarTotal = totalAPagar - sumaTotalPago;
      } else if (sumaTotalPago >= totalAPagar) {
        nuevoRegistroPago.restantePagarTotal = 0;
      }
    
      setNuevoRegistroPago(nuevoRegistroPago);
   
  };

  const changeCantidadAPagarPago = async (e, pago) => {
    let value = e.target.value;
    let permiso = true;

    value = value.replace("$", "");
    const rgx = /^[0-9]*\.?[0-9]*$/;
    if (!value.match(rgx)) value = parseFloat(value);
   
    let sumaTotalPago = 0;
    nuevoRegistroPago.pagoVales.map((pago) => {
      sumaTotalPago += pago.importe;
      return pago;
    });

    if(valesSeleccionados.saldoPendienteMedicamentos == 0){
      let cantidad = 0.0;
      cantidad = parseFloat(value);
      let administrafcion = valesSeleccionados.saldoPendienteAdministracion.toFixed(2)
      if(cantidad > administrafcion || sumaTotalPago > administrafcion){
        permiso = false;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: `La cantidad debe ser menor o igual al saldo pendiente`,
        });
      }
    }else{
      let cantidad = 0.0;
      let cantidadSuma = 0.0;
      cantidad = parseFloat(value);
      cantidadSuma = parseFloat(valesSeleccionados.saldoPendienteAdministracion + valesSeleccionados.saldoPendienteMedicamentos).toFixed(2);
      if(cantidad> cantidadSuma || sumaTotalPago > cantidadSuma ){
        permiso = false;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: `La cantidad debe ser menor o igual al saldo pendiente en medicamentos y administraciones`,
        });
      }
    }

    if(permiso == true){
      pago.importe = value;
      setNuevoRegistroPago({ ...nuevoRegistroPago, pago });
      setNuevoRegistroPago(nuevoRegistroPago);
      refreshModalPagoVales();
      calcularRestanteTotalMedicamentos();
    }
    

  };

  const changeTipoPago = async (e, pago) => {
    pago.idCatTipoPago = e.target.value;
    setNuevoRegistroPago(nuevoRegistroPago);
    refreshModalPagoVales();
  };

  const checkNuevoPago = async () => {
    const valesChecked = await isValeChecked();
    if (!valesChecked) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione al menos un vale",
      });
      return;
    }

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_nuevo_pago: true,
    });
  };

  const submitNuevoPago = async () => {
    let totalPagos = 0;
    const valesChecked = await isValeChecked();

    const vales = valesChecked.map((vale) => vale.idVale);

    if (!nuevoRegistroPago.pagoVales) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca al menos un pago`,
      });
      return;
    } else {
      nuevoRegistroPago.pagoVales.map((pago) => {
        pago.importe = parseFloat(pago.importe);
        totalPagos += pago.importe;
        return pago;
      });

      let pagosCompletos = true;

      nuevoRegistroPago.pagoVales.map((pago) => {
        if (!pago.idCatTipoPago) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca la forma de pago para el pago`,
          });
          pagosCompletos = false;
        }
        if (!pago.importe && pagosCompletos) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el importe para el pago`,
          });
          pagosCompletos = false;
        }
        return pago;
      });
      if (!pagosCompletos) {
        return;
      }

      if (nuevoRegistroPago.restantePagar > 0 && valesSeleccionados.saldoPendienteMedicamentos > 0 && totalPagos < valesSeleccionados.saldoPendienteMedicamentos) {
        if(valesSeleccionados.saldoPendienteMedicamentos > 0){
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `El pago debe cubrir al menos los medicamentos por  ${formatNumber(
              valesSeleccionados.saldoPendienteMedicamentos 
            )}, 
            el restante por pagar es ${formatNumber(nuevoRegistroPago.restantePagar)}`,
          });
        }else{
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `La cantidad minima a pagar es de  ${formatNumber(
              valesSeleccionados.saldoPendienteMedicamentos + valesSeleccionados.pendientesAdminPasados
            )}, 
            el restante por pagar es ${formatNumber(nuevoRegistroPago.restantePagar)}`,
          });
        }

        return;
      }
      if (totalPagos > valesSeleccionados.total.toFixed(2)  ) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: `La cantidad máxima a pagar es de  ${formatNumber(valesSeleccionados.total)}, 
        el total de pagos es de ${formatNumber(totalPagos)}`,
        });
        return;
      }

      setLoading(true);

      const res = await registrarNuevoPagoMedico({
        vales: vales,
        pagos: nuevoRegistroPago.pagoVales,
        importe: totalPagos,
      });
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_alert_nuevo_pago: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setNuevoRegistroPago({
          pagoVales: [],
          restantePagar: 0,
        });
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_nuevo_pago: false,
          success_dlg: true,
          dynamic_title: "Exito",
          dynamic_description: "El pago fue registrado correctamente",
        });

        setValesSeleccionados({
          totalMedicamentos: 0,
          totalAdministracion: 0,
          saldoPendienteMedicamentos: 0,
          saldoPendienteAdministracion: 0,
          pagadoMedicamentos: 0,
          pagadoAdministracion: 0,
          total: 0,
        });
        buscarPagos();
        habilitarValesChecked();
      }
    }
  };

  const medicosInternosChangeId = medicosInternos.map(({ idCatMedico, ...medico }) => (
    {
      ...medico,
      id: idCatMedico,
      nombre: `${medico.catPersona.nombres} ${medico.catPersona.apellidoPaterno} ${medico.catPersona.apellidoMaterno}`,
    }  
  ));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Fecha Inicial",
          type: "date",
          name: "fechaInicial",
          disabled: false,
          value: filtrosValesMedico.fechaInicial,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Médico Tratante",
          name: "medico",
          value: filtrosValesMedico.medico,
          opciones: medicosInternosChangeId,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Fecha Final",
          type: "date",
          name: "fechaFinal",
          disabled: !filtrosValesMedico.fechaInicial ? true : false,
          min: filtrosValesMedico.fechaInicial,
          value: filtrosValesMedico.fechaFinal,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Importe Máximo",
          type: "text",
          name: "importeMaximo",
          disabled: false,
          value: filtrosValesMedico.importeMaximo ? `$${filtrosValesMedico.importeMaximo}` : `$0`,
          onChange: onChangeImporte,
        },
      ],
    },
  ];

  useEffect(() => {
    if(usuario.authorities.includes("ROLE_FARMACIA")){
      listarMedicosInternos();
    }else{
      if(usuario.authorities.includes("ROLE_RECEPCION")){
        listarMedicosExternos();
      }     
    }
    listarCatTipoCobros();
    listarCatTipoPagos();

    // if (medicosInternos.length <= 0) {

    // }

    // if (tipoCobros.length <= 0) {

    // }
    // if (tipoPagos.length <= 0) {

    // }
  }, []);

  useEffect(() => {
    buscarPagos();
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Nuevo Pago Médico</h3>

        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearch}>
          {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
        </button>
        {/* Buscar por folio */}
        <FiltroFolio
          toggleSearchBy={toggleSearchBy}
          buscarPorFolio={buscarPorFolio}
          onChange={onChange}
          value={filtrosValesMedico.folioVale}
          params={{
            name: "folioVale",
            placeholder: "Escribe tu Folio de Pago",
          }}
        />

        {/* Buscar por  filtros */}
        <FiltroBusqueda
          toggleSearchBy={toggleSearchBy}
          buscarPorFiltros={buscarPorFiltros}
          onChange={onChange}
          limpiarFiltros={limpiarFiltros}
          paramsInput={inputCols}
        />

        {/* Propuesta de diseño */}

        <h4 className="flex mt-5">Lista de Vales</h4>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Folio Vale</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Paciente</th>
                        <th scope="col">Médico</th>
                        <th scope="col">
                          Total<br></br>Medicamentos
                        </th>
                        <th scope="col">
                          Saldo Pendiente<br></br>Medicamentos
                        </th>
                        <th scope="col">
                          Total<br></br>Administración
                        </th>
                        <th scope="col">
                          Saldo Pendiente<br></br>Administración
                        </th>
                        <th scope="col">
                          Total<br></br>Saldo Pendiente
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {valesMedicosFiltrados.map((vale, index) => {
                        return (
                          <>
                            <tr key={vale.idVale}>
                              <td>
                                <input
                                  type="checkbox"
                                  disabled={vale.disabledCheck}
                                  onChange={() => handdleCheckedVale(vale, !vale.checked)}
                                  checked={vale.checked}
                                />
                              </td>
                              <td>{vale.folioVale ? vale.folioVale : ""}</td>
                              <td>{vale.fechaVale ? vale.fechaVale : ""}</td>
                              <td>{vale.paciente ? vale.paciente : ""}</td>
                              <td key={vale.idCatMedico}>{vale.medico ? vale.medico : ""}</td>
                              <td>{vale.importeMedicamentos ? formatNumber(vale.importeMedicamentos) : "$0"}</td>
                              <td>{vale.saldoPendienteMedicamentos ? formatNumber(vale.saldoPendienteMedicamentos) : "$0"}</td>
                              <td>{vale.importeAdministracion ? formatNumber(vale.importeAdministracion) : "$0"}</td>
                              <td>
                                {vale.saldoPendienteAdministracion ? formatNumber(vale.saldoPendienteAdministracion) : "$0"}
                              </td>
                              <td>
                                {vale.saldoPendienteAdministracion || vale.saldoPendienteMedicamentos
                                  ? formatNumber(vale.saldoPendienteMedicamentos + vale.saldoPendienteAdministracion)
                                  : "$0"}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* Información de la deuda */}
        <h4 className="flex mt-5 ">Información de la Deuda</h4>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <Row>
                  <Col>
                    <h5>
                      Fecha: <span className="text-sm ml-1">{today}</span>
                    </h5>
                  </Col>
                </Row>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Concepto</th>
                        <th scope="col">Importe</th>
                        <th scope="col">Pagado</th>
                        <th scope="col">Saldo Pendiente</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Total Medicamentos</td>
                        {/* // Total de medicamentos */}
                        <td>
                          {valesSeleccionados.totalMedicamentos > 0 ? formatNumber(valesSeleccionados.totalMedicamentos) : "$0"}
                        </td>
                        {/* Pagado medicamentos */}
                        <td>
                          {valesSeleccionados.pagadoMedicamentos > 0 ? formatNumber(valesSeleccionados.pagadoMedicamentos) : "$0"}
                        </td>
                        {/* Saldos pendientes medicamentos */}
                        <td>
                          {valesSeleccionados.saldoPendienteMedicamentos >= 0
                            ? formatNumber(valesSeleccionados.saldoPendienteMedicamentos)
                            : "$0"}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>Total Administración</td>
                        {/* Total de Administración */}
                        <td>
                          {valesSeleccionados.totalAdministracion > 0
                            ? formatNumber(valesSeleccionados.totalAdministracion)
                            : "$0"}
                        </td>
                        {/* Pagado admin */}
                        <td>
                          {valesSeleccionados.pagadoAdministracion > 0
                            ? formatNumber(valesSeleccionados.pagadoAdministracion)
                            : "$0"}
                        </td>
                        {/* Saldos pendientes */}
                        <td>
                          {valesSeleccionados.saldoPendienteAdministracion > 0
                            ? formatNumber(valesSeleccionados.saldoPendienteAdministracion)
                            : "$0"}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>Total</td>
                        {/* Total med y admin */}
                        <td>
                          {valesSeleccionados.totalMedicamentos || valesSeleccionados.totalAdministracion
                            ? formatNumber(valesSeleccionados.totalMedicamentos + valesSeleccionados.totalAdministracion)
                            : "$0"}
                        </td>
                        {/* Total pagado admin y med */}
                        <td>
                          {valesSeleccionados.pagadoMedicamentos || valesSeleccionados.pagadoAdministracion
                            ? formatNumber(valesSeleccionados.pagadoMedicamentos + valesSeleccionados.pagadoAdministracion)
                            : "$0"}
                        </td>
                        {/* Total pendientes med y admin */}
                        <td>
                          {valesSeleccionados.saldoPendienteMedicamentos || valesSeleccionados.saldoPendienteAdministracion
                            ? formatNumber(
                                valesSeleccionados.saldoPendienteMedicamentos + valesSeleccionados.saldoPendienteAdministracion
                              )
                            : "$0"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {usuario.authorities.includes("ROLE_RECEPCION") || usuario.authorities.includes("OP_PAGO_MEDICO_CREAR") ? (
              <button
                className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                type="button"
                style={{
                  marginRight: "2.5rem",
                  marginTop: "1rem",
                  background: "#ff9933",
                  color: "white",
                  fontWeight: "bold",
                }}
                onClick={() => checkNuevoPago()}>
                Registrar Pago
              </button>
            ) : null}
          </Col>
        </Row>
        {UISweetAlert.confirm_alert_nuevo_pago ? (
          <SweetAlert
            title="¿Desea pagar los vales seleccionados?"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => submitNuevoPago()}
            style={{
              width: "50%",
            }}
            onCancel={() => {
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_nuevo_pago: false,
              });
              setNuevoRegistroPago({
                ...nuevoRegistroPago,
                pagoVales: [],
                restantePagar: valesSeleccionados.saldoPendienteMedicamentos> 0? valesSeleccionados.saldoPendienteMedicamentos : valesSeleccionados.saldoPendienteMedicamentos + valesSeleccionados.pendientesAdminPasados,
              });
            }}>
            <div>
              Se debe cubrir la cantidad mínima a pagar para poder realizar el pago
              <Row>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group mt-4">
                    <label className="col-sm-6 col-form-label">Saldo Pendiente Medicamentos</label>
                    <Col sm={5}>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={
                          valesSeleccionados.saldoPendienteMedicamentos > 0
                            ? formatNumber(valesSeleccionados.saldoPendienteMedicamentos)
                            : "$0"
                        }
                        style={{
                          textAlign: "right",
                        }}
                      />
                    </Col>
                    <label className="col-sm-6 col-form-label">Saldo Pendiente Administración</label>
                    <Col sm={5}>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={
                          valesSeleccionados.saldoPendienteAdministracion > 0
                            ? formatNumber(valesSeleccionados.saldoPendienteAdministracion)
                            : "$0"
                        }
                        style={{
                          textAlign: "right",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group mt-4">
                    <label className="col-sm-6 col-form-label">Total a pagar</label>
                    <Col sm={5}>
                      <input
                        type="text"
                        className="form-control"
                        value={valesSeleccionados.total > 0 ? formatNumber(valesSeleccionados.total) : "$0"}
                        disabled={true}
                        style={{
                          textAlign: "right",
                        }}
                      />
                    </Col>
                    <label className="col-sm-6 col-form-label mb-4">Importe Mínimo a Pagar</label>
                    <Col sm={5}>
                      <input
                        type="text"
                        className="form-control"
                        value={nuevoRegistroPago.restantePagar > 0 ? formatNumber(nuevoRegistroPago.restantePagar) : "$0"}
                        disabled={true}
                        style={{
                          textAlign: "right",
                        }}
                      />
                    </Col>
                    <label className="col-sm-6 col-form-label mb-4">Restante Total a Pagar</label>
                    <Col sm={5}>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          nuevoRegistroPago.restantePagarTotal > 0 ? formatNumber(nuevoRegistroPago.restantePagarTotal) : "$0"
                        }
                        disabled={true}
                        style={{
                          textAlign: "right",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="form-group mt-2 ml-2 mr-2">
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mb-4 mt-4"
                  onClick={() => insertarNuevoPago()}>
                  Ingresar Pago
                </button>

                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        Forma de<br></br>Pago
                      </th>
                      <th scope="col">Importe</th>
                      <th scope="col">Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nuevoRegistroPago.pagoVales &&
                      nuevoRegistroPago.pagoVales.map((pago) => {
                        return (
                          <tr key={pago}>
                            <td>
                              <select
                                className="form-control"
                                name="idCatTipoPago"
                                onChange={(e) => changeTipoPago(e, pago)}
                                value={pago.idCatTipoPago}>
                                <option disabled selected value="">
                                  Seleccione una opción
                                </option>
                                {tipoPagos.map((pago) => {
                                  return (
                                    <>
                                      <option value={pago.idCatTipoPago}>{pago.tipoPago}</option>
                                    </>
                                  );
                                })}
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                name="importe"
                                onChange={(e) => changeCantidadAPagarPago(e, pago)}
                                //   `$${
                                //   pago.importe % 1 === 0
                                //     ? pago.importe.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                //     : pago.importe
                                //         .toFixed(2)
                                //         .toString()
                                //         .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                // }`
                                value={pago.importe ? `$${pago.importe}` : `$`}
                                autoComplete="off"
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                onClick={() => {
                                  setPagoEliminar(pago);
                                  setUISweetAlert({
                                    ...UISweetAlert,
                                    confirm_alert_eliminar_pago: true,
                                  });
                                }}
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex  hover:text-white">
                                Eliminar
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Row>
            </div>
          </SweetAlert>
        ) : null}

        {UISweetAlert.confirm_alert_eliminar_pago ? (
          <SweetAlert
            title="¿Desea eliminar el pago?"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => eliminarPagoVales()}
            onCancel={() => {
              setPagoEliminar(null);
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_eliminar_pago: false,
              });
            }}></SweetAlert>
        ) : null}

        {UISweetAlert.success_dlg ? (
          <SweetAlert
            success
            title={UISweetAlert.dynamic_title}
            onConfirm={() => {
              if (UISweetAlert.redirect) {
                props.history.push(UISweetAlert.path);
              }
              setUISweetAlert({
                ...UISweetAlert,
                success_dlg: false,
              });
            }}>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {UISweetAlert.error_dlg ? (
          <SweetAlert
            error
            title={UISweetAlert.dynamic_title}
            onConfirm={() =>
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: false,
              })
            }>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {loading ? (
          <SweetAlert title="Cargando!" showConfirm={false}>
            <Spinner />
          </SweetAlert>
        ) : null}
      </div>
    </>
  );
}
