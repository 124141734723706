import React, { useReducer } from "react";

import CatUsoCfdiContext from "./CatUsoCfdiContext";
import CatUsoCfdiReducer from "./CatUsoCfdiReducer";

import { LISTAR_CATUSOCFDI } from "../../types";

import clienteAxios from "../../config/axios";

export default function CatUsoCfdiState(props) {
  const initialState = {
    catUsoCfdis: [],
  };

  const [state, dispatch] = useReducer(CatUsoCfdiReducer, initialState);

  const listarCatUsoCfdis = async () => {
    const res = await clienteAxios.get("/catusocfdi/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CATUSOCFDI,
      payload: res.data,
    });
    return res;
  };

  return (
    <CatUsoCfdiContext.Provider
      value={{
        catUsoCfdis: state.catUsoCfdis,
        listarCatUsoCfdis,
      }}>
      {props.children}
    </CatUsoCfdiContext.Provider>
  );
}
