import React, { useReducer } from "react";

import CentroMezclasContext from "./CentroMezclasContext";
import CentroMezclasReducer from "./CentroMezclasReducer";

import {
  FILTRO_BITACORAS,
  SET_BITACORAS_PAGINADAS,
  FILTROS_INVENTARIO_MEDICAMENTOS,
  SET_MEDICAMENTOS_CM_RF,
  SET_MEDICAMENTOS_CM,
  SET_REGISTROS_CM,
  SET_REGISTROS_CM_RF,
  FILTRO_REPORTE_REACCIONES,
  SET_REPORTES_REACCIONES_PAGINADOS,
  SET_VALE_SELECCIONADO,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function CentroMezclasState(props) {
  const getTodayDate = () => {
    let newDate = new Date();

    // add a day
    newDate.setDate(newDate.getDate() + 1);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const d = newDate.getDate(); //

    // return `${month.toString().padStart(2, "0")}/${d.toString().padStart(2, "0")}/${year}`;

    return `${year}-${month.toString().padStart(2, "0")}-${d.toString().padStart(2, "0")}`;
  };

  // const maxDate = () => {
  //   let dateFecha = getTodayDate();
  //   let date = new Date(dateFecha);
  //   date.setDate(date.getDate() + 7);
  //   let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  //   let mm = date.getMonth() + 1;
  //   mm = mm < 10 ? "0" + mm : mm;
  //   let y = date.getFullYear();
  //   const formatDate = y + "-" + mm + "-" + dd;
  //   return formatDate;
  // };

  const minDate = () => {
    let dateFecha = getTodayDate();

    let date = new Date(dateFecha);
    // console.log(date);
    date.setDate(date.getDate() - 7);
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let mm = date.getMonth() + 1;
    mm = mm < 10 ? "0" + mm : mm;
    let y = date.getFullYear();
    const formatDate = y + "-" + mm + "-" + dd;
    // console.log(formatDate);
    return formatDate;
  };

  const initialState = {
    filtrosBitacoras: {},
    bitacorasPaginadas: [],

    // Reacciones Adversas
    filtrosReporteReacciones: {},
    reportesReacciones: [],
    idValeSeleccionado: null,

    //Inventario Centro Mezclas Medicamentos
    filtrosMedicamentosCM: { fechaInicial: minDate(), fechaFinal: getTodayDate() },
    // filtrosMedicamentosCM: {},
    medicamentosInventarioCM: [],
    medicamentosInventarioCMRedFria: [],
    movimientosCM: { entradas: [], salidas: [] },
    movimientosRedFria: { entradas: [], salidas: [] },
  };

  const [state, dispatch] = useReducer(CentroMezclasReducer, initialState);

  const setDataBitacoras = async (page, limit, {filtrosLocales}) => {
    try {
      const response = await clienteAxios.post(
        "/centromezclasbitacora/paginado",
        {
          pageSize: limit,
          pageIndex: page,
          ...filtrosLocales,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const data = response.data.centromezclasbitacoraVos.map((bitacora) => {
        return { ...bitacora, 
                    fechaRegistro: bitacora.fechaRegistro.split("T")[0] ,
                    fechaPreparacion: bitacora.fechaPreparacion ? bitacora.fechaPreparacion.split("T")[0] : null ,
                    fechaUtilizacion: bitacora.fechaUtilizacion ? bitacora.fechaUtilizacion.split("T")[0] : null
      
              };
      });
      dispatch({
        type: SET_BITACORAS_PAGINADAS,
        payload: data,
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const setDataBitacorasCentroMezclas = async (page, limit, {filtrosLocales}) => {
    try {
      
      const response = await clienteAxios.post(
        "/centromezclasbitacora/paginado",
        {
          pageSize: limit,
          pageIndex: page,
          ...filtrosLocales,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const data = response.data.centromezclasbitacoraVos.map((bitacora) => {
        return { ...bitacora, fechaRegistro: bitacora.fechaRegistro.split("T")[0] };
      });
      dispatch({
        type: SET_BITACORAS_PAGINADAS,
        payload: data,
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const setDataMedicamentosInventario = async ({ page, limit }) => {
    // Cambiar pageSize por limit cuando se corrija funcionalidad

    try {
      const response = await clienteAxios.post(
        "/centromezclasinventario/paginado/",
        { pageSize: 100, pageIndex: page, ...state.filtrosMedicamentosCM },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const responseRedFria = response.data.centromezclasInventariosRedFria;
      const responseCM = response.data.centromezclasInventarios;

      let medicamentosRedFria = [];
      let medicamentosCM = [];
      let medRedFriaEntradas = [];
      let medRedFriaSalidas = [];
      let medCMEntradas = [];
      let medCMSalidas = [];
      // let idInventarioActual = null;
      // let idValeActual = null;
      let listaIdInventarios = [];

      responseRedFria.forEach((producto) => {
        let fechaFormato = producto.fechaMovimiento.split("T")[0];
        if (producto.catTipomovimientoVo === null) {
          if (!listaIdInventarios.includes(producto.idInventario)) {
            listaIdInventarios.push(producto.idInventario);

            medicamentosRedFria.push(producto);
            medRedFriaEntradas.push({ ...producto, fechaMovimiento: fechaFormato });
            return;
          } else {
            medRedFriaEntradas.push({ ...producto, fechaMovimiento: fechaFormato });
            return;
          }
        } else {
          if (!listaIdInventarios.includes(producto.idInventario)) {
            listaIdInventarios.push(producto.idInventario);

            medicamentosRedFria.push(producto);
            medRedFriaSalidas.push({ ...producto, fechaMovimiento: fechaFormato });
            return;
          } else {
            medRedFriaSalidas.push({ ...producto, fechaMovimiento: fechaFormato });
            return;
          }
        }
      });

      responseCM.forEach((producto) => {
        let fechaFormato = producto.fechaMovimiento.split("T")[0];

        if (producto.catTipomovimientoVo === null) {
          if (!listaIdInventarios.includes(producto.idInventario)) {
            listaIdInventarios.push(producto.idInventario);
            // idInventarioActual = producto.idInventario;
            // idValeActual = producto.idVale;
            medicamentosCM.push(producto);
            medCMEntradas.push({ ...producto, fechaMovimiento: fechaFormato });
            return;
          } else {
            medCMEntradas.push({ ...producto, fechaMovimiento: fechaFormato });
            return;
          }
        } else {
          if (!listaIdInventarios.includes(producto.idInventario)) {
            listaIdInventarios.push(producto.idInventario);
            // idInventarioActual = producto.idInventario;
            // idValeActual = producto.idVale;
            medicamentosCM.push(producto);
            medCMSalidas.push({ ...producto, fechaMovimiento: fechaFormato });
            return;
          } else {
            medCMSalidas.push({ ...producto, fechaMovimiento: fechaFormato });
            return;
          }
        }
      });

      medicamentosCM.forEach((producto) => {
        producto.entradas = 0;
        producto.salidas = 0;
        producto.total = 0;
        responseCM.forEach((med) => {
          if (med.idInventario == producto.idInventario) {
            if (med.catTipomovimientoVo === null) {
              producto.entradas = producto.entradas + med.piezas;
              return;
            } else {
              producto.salidas = producto.salidas + med.piezas;
              return;
            }
          }
        });
        producto.total = producto.entradas - producto.salidas;
      });

      medicamentosRedFria.forEach((producto) => {
        producto.entradas = 0;
        producto.salidas = 0;
        producto.total = 0;
        responseRedFria.forEach((med) => {
          if (med.idInventario == producto.idInventario) {
            if (med.catTipomovimientoVo === null) {
              producto.entradas = producto.entradas + med.piezas;
              return;
            } else {
              producto.salidas = producto.salidas + med.piezas;
              return;
            }
          }
        });
        producto.total = producto.entradas - producto.salidas;
      });

      const data = {
        medicamentosRedFria: {
          medicamentos: medicamentosRedFria,
          count: response.data.count,
        },
        medicamentosCM: {
          medicamentos: medicamentosCM,
          count: medicamentosCM.length,
          // count: response.data.count,
        },
      };

      const movimientosCM = {
        entradas: medCMEntradas,
        salidas: medCMSalidas,
      };

      const movimientosRedFria = {
        entradas: medRedFriaEntradas,
        salidas: medRedFriaSalidas,
      };

      dispatch({
        type: SET_MEDICAMENTOS_CM,
        payload: data.medicamentosCM.medicamentos,
      });
      dispatch({
        type: SET_MEDICAMENTOS_CM_RF,
        payload: data.medicamentosRedFria.medicamentos,
      });

      dispatch({
        type: SET_REGISTROS_CM,
        payload: movimientosCM,
      });
      dispatch({
        type: SET_REGISTROS_CM_RF,
        payload: movimientosRedFria,
      });

      return { countCM: medicamentosCM.length, countRedFria: medicamentosRedFria.length };
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const saveInventarioCentroMezclas = async (datosInventarioCM) => {
    try {
      const res = await clienteAxios.post(`/centromezclasinventario/guardar`, datosInventarioCM, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setFiltrosBitacoras = async (filtrosBitacoras) => {
    dispatch({
      type: FILTRO_BITACORAS,
      payload: filtrosBitacoras,
    });
  };

  const uploadNuevaBitacora = async (datosBitacora) => {
    try {
      const res = await clienteAxios.post(`/centromezclasbitacora/guardar`, datosBitacora, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarBitacora = async (idCentroMezclaBitacora) => {
    try {
      const response = await clienteAxios.post(
        "/centromezclasbitacora/eliminar/",
        { idCentroMezclaBitacora: idCentroMezclaBitacora },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const updateBitacora = async (bitacoraModificada) => {
    try {
      const res = await clienteAxios.post(`/centromezclasbitacora/guardar`, bitacoraModificada, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setFiltrosMedicamentosCM = (filtrosMedicamentosCM) => {
    dispatch({
      type: FILTROS_INVENTARIO_MEDICAMENTOS,
      payload: filtrosMedicamentosCM,
    });
  };

  const setFiltrosReportesReacciones = async (filtrosReporteReacciones) => {
    dispatch({
      type: FILTRO_REPORTE_REACCIONES,
      payload: filtrosReporteReacciones,
    });
  };

  const setDataReportesReacciones = async ( page, limit, {filtrosLocales}) => {
    try {
      const response = await clienteAxios.post(
        "/reaccionadversa/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          ...filtrosLocales,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const reaccionesFechaModificada = response.data.reacciones.map((reaccion) => {
        let fechaFormato = reaccion.fechaRegistro.split("T")[0];
        return { ...reaccion, fechaRegistro: fechaFormato };
      });

      dispatch({
        type: SET_REPORTES_REACCIONES_PAGINADOS,
        payload: reaccionesFechaModificada,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setDataReportesReaccionesPaginados = async (page, limit, {filtrosLocales}) => {
    try {
      const response = await clienteAxios.post(
        "/reaccionadversa/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          ...filtrosLocales,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const reaccionesFechaModificada = response.data.reacciones.map((reaccion) => {
        let fechaFormato = reaccion.fechaRegistro.split("T")[0];
        return { ...reaccion, fechaRegistro: fechaFormato };
      });

      dispatch({
        type: SET_REPORTES_REACCIONES_PAGINADOS,
        payload: reaccionesFechaModificada,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const uploadNuevaReaccion = async (datosReaccion) => {
    try {
      const res = await clienteAxios.post(`/reaccionadversa/guardar`, datosReaccion, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarReaccion = async (reaccionAdversa) => {
    try {
      const response = await clienteAxios.post("/reaccionadversa/eliminar/", reaccionAdversa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const setValeSeleccionado = async (idVale) => {
    const idValePrueba = 1;
    dispatch({
      type: SET_VALE_SELECCIONADO,
      payload: idValePrueba,
    });
  };

  const exportListaBitacoras = async (filtrosProductosIventario) => {
    try {
      const res = await clienteAxios.post(`/centromezclasbitacora/export/bitacora`, filtrosProductosIventario, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=bitacora.xlsx",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "bitacora.xlsx");
      document.body.appendChild(link);
      link.click();

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <CentroMezclasContext.Provider
      value={{
        filtrosBitacoras: state.filtrosBitacoras,
        bitacorasPaginadas: state.bitacorasPaginadas,
        filtrosReporteReacciones: state.filtrosReporteReacciones,
        reportesReacciones: state.reportesReacciones,
        filtrosMedicamentosCM: state.filtrosMedicamentosCM,
        medicamentosInventarioCM: state.medicamentosInventarioCM,
        medicamentosInventarioCMRedFria: state.medicamentosInventarioCMRedFria,
        idValeSeleccionado: state.idValeSeleccionado,
        movimientosCM: state.movimientosCM,
        movimientosRedFria: state.movimientosRedFria,
        setFiltrosBitacoras,
        setDataBitacoras,
        setDataBitacorasCentroMezclas,
        uploadNuevaBitacora,
        eliminarBitacora,
        updateBitacora,
        setFiltrosMedicamentosCM,
        setDataMedicamentosInventario,
        setFiltrosReportesReacciones,
        setValeSeleccionado,
        setDataReportesReacciones,
        setDataReportesReaccionesPaginados,
        uploadNuevaReaccion,
        eliminarReaccion,
        saveInventarioCentroMezclas,
        exportListaBitacoras,
      }}>
      {props.children}
    </CentroMezclasContext.Provider>
  );
}
