import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import InformacionGeneralProductoInventario from "../InformacionGeneralProductoInventario";
import AuthContext from "../../context/Auth/AuthContext";
import BajaInventarioContext from "../../context/BajaInventario/BajaInventarioContext";
import InventarioContext from "../../context/Inventario/InventarioContext";

import useWindowDimensions from "../../hooks/dimensionsHook";

export default function NuevaBajaInvetario(props) {
  const { width } = useWindowDimensions();

  const { idInventario } = useParams();

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_baja_inventario: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  // const [inventarioUbicacionSeleccionado, setInventarioUbicacionSeleccionado] = useState(null);

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const bajaInvCtx = useContext(BajaInventarioContext);
  const { uploadBajaInventario } = bajaInvCtx;

  const inventarioCtx = useContext(InventarioContext);
  const { inventarioSeleccionado, seleccionarInventarioTraslado, setInventarioSeleccionado } = inventarioCtx;

  const handdleCheckedProductoInventario = async (ubicacion) => {
    ubicacion.checked = !ubicacion.checked;
    ubicacion.piezasBaja = 0;
    setInventarioSeleccionado(inventarioSeleccionado);
  };

  const onChange = async (e, ubicacion) => {
    let value = e.target.value;
    if (value < 0) {
      value = value * -1;
    }
    if (value > ubicacion.piezas) {
      value = 0;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Falla",
        dynamic_description:
          "El número de piezas a dar de baja no pueden ser mayor al número de piezas que se encuentran en esa ubicación",
      });
    }
    ubicacion.piezasBaja = value;
    setInventarioSeleccionado(inventarioSeleccionado);
  };

  const onChangeBajaInventario = async (e) => {
    inventarioSeleccionado.motivo = e.target.value;
    setInventarioSeleccionado(inventarioSeleccionado);
  };

  const confirmBajaInventario = async () => {
    let errorBajaInventario = false;

    const ubicacionesSeleccionadas =
      (await inventarioSeleccionado.ubicaciones) &&
      inventarioSeleccionado.ubicaciones.filter((ubicacion) => {
        if (!errorBajaInventario && ubicacion.checked && ubicacion.piezasBaja <= 0) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Falla",
            dynamic_description: `El número de piezas a dar de baja debe ser mayor a 0, para la ubicación '${ubicacion.ubicacion.ubicacion}'`,
          });
          errorBajaInventario = true;
        }

        if (ubicacion.checked) {
          return true;
        } else {
          return false;
        }
      });

    if (ubicacionesSeleccionadas && ubicacionesSeleccionadas.length <= 0 && !errorBajaInventario) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Falla",
        dynamic_description: `Por favor, seleccione las ubicaciones y las piezas que se daran de baja`,
      });
      errorBajaInventario = true;
    }

    if (!inventarioSeleccionado.archivos || inventarioSeleccionado.archivos.length < 1) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Falla",
        dynamic_description: `Por favor, incluya al menos un documento para la baja de inventario`,
      });
      errorBajaInventario = true;
    }

    if (!errorBajaInventario) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_baja_inventario: true,
      });
    }
  };

  const checkUploadBajaInventario = async () => {
    let errorBajaInventario = false;

    if (!inventarioSeleccionado.motivo && !errorBajaInventario) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Falla",
        dynamic_description: `Por favor, introduzca el motivo de la baja de inventario`,
      });
      errorBajaInventario = true;
    }

    const ubicacionesSeleccionadas =
      (await inventarioSeleccionado.ubicaciones) &&
      inventarioSeleccionado.ubicaciones.filter((ubicacion) => {
        if (ubicacion.checked) {
          return true;
        } else {
          return false;
        }
      });

      let cadena = "";
      cadena = cadena + "Baja:Lote[" + inventarioSeleccionado.lote  + "]"
      ubicacionesSeleccionadas.map((ubicacion) =>{
        //IdIU = Id inventario ubicación
        cadena = cadena + ",[IdIU: " + ubicacion.idInventarioUbicacion + ", P: " + ubicacion.piezasBaja + "]"
      });


      if (!errorBajaInventario) {
      let data = {
        idCatAlmacen: inventarioSeleccionado.idCatAlmacen,
        ubicaciones: ubicacionesSeleccionadas,
        motivo: inventarioSeleccionado.motivo,
        archivos: inventarioSeleccionado.archivos,
        logVo : {
          idUsuario: usuario.idUsuario,
          modulo: "Inventario",
          actividad: cadena  
      },
      };

      setLoading(true);
      const res = await uploadBajaInventario(data);
      if (res.data.error) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          redirect: true,
          path: "/productos/inventario",
          confirm_alert_traslado: false,
          dynamic_description: "Se guardaron los cambios de manera exitosa.",
        });
      }
    }
  };

  const uploadFile = async (e) => {
    const files = e.target.files;
    if (!inventarioSeleccionado.archivos) {
      inventarioSeleccionado.archivos = [];
    }
    setInventarioSeleccionado(inventarioSeleccionado);

    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          let strFile = reader.result;
          let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
          let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);

          let nuevoArchivo = {
            nombreArchivo: file.name,
            mimeType: type,
            tamano: file.size,
            inputStream: fileBase64,
          };

          inventarioSeleccionado.archivos = inventarioSeleccionado.archivos.concat(nuevoArchivo);
          setInventarioSeleccionado(inventarioSeleccionado);
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const limpiarArchivos = async () => {
    inventarioSeleccionado.archivos = [];
    setInventarioSeleccionado(inventarioSeleccionado);
  };

  useEffect(() => {
    if (Object.keys(inventarioSeleccionado).length === 0) {
      seleccionarInventarioTraslado(idInventario);
    }
  }, [inventarioSeleccionado]);

  const autorizado =
    usuario && (usuario.authorities.includes("ROLE_ALMACEN") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA"));

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Nueva baja de inventario</h3>

      <InformacionGeneralProductoInventario opcion={ true } id={0} />

      <Card className="mt-4">
        <CardBody>
          <h5 className="flex h5 mt-1 mb-2">Ubicaciones</h5>
          <Row>
            <Col>
              <div className="table-responsive mt-4">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Anaquel</th>
                      <th scope="col">Estante</th>
                      <th scope="col">Ubicación</th>
                      <th scope="col">Piezas</th>
                      <th scope="col">
                        Piezas de<br></br>Baja
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventarioSeleccionado.ubicaciones &&
                      inventarioSeleccionado.ubicaciones.map((ubicacion) => {
                        return (
                          <tr key={ubicacion}>
                            <td>
                              <input
                                checked={ubicacion.checked}
                                type="checkbox"
                                onChange={() => handdleCheckedProductoInventario(ubicacion)}
                              />
                            </td>
                            <td>{ubicacion.ubicacion.estante ? ubicacion.ubicacion.estante.anaquel.anaquel : ""}</td>
                            <td>{ubicacion.ubicacion.estante ? ubicacion.ubicacion.estante.estante : ""}</td>
                            <td>{ubicacion.ubicacion.ubicacion ? ubicacion.ubicacion.ubicacion : ""}</td>
                            <td>{ubicacion.piezas != null ? ubicacion.piezas : "0"}</td>
                            <td>
                              {ubicacion.checked ? (
                                <input
                                  type="number"
                                  className="form-control"
                                  onChange={(e) => onChange(e, ubicacion)}
                                  name="piezasBaja"
                                  onWheel={(event) => event.currentTarget.blur()}
                                  value={ubicacion.piezasBaja}
                                />
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row className="form-group mt-4">
            <label className="ml-4 col-form-label">Documentos</label>
            <Col sm={7}>
              <input className="form-control" type="file" onClick={limpiarArchivos} onChange={(e) => uploadFile(e)} multiple />
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-4"
                type="button"
                hidden={!autorizado}
                onClick={confirmBajaInventario}>
                Solicitar Baja Inventario
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_baja_inventario ? (
        <SweetAlert
          title="Solicitar baja de inventario?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={checkUploadBajaInventario}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_baja_inventario: false,
            });
          }}>
          <div className="mt-2">
            <Row>
              <Col>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Motivo</label>
                  <Col sm={7}>
                    <textarea
                      className="form-control"
                      onChange={onChangeBajaInventario}
                      name="piezas"
                      value={inventarioSeleccionado.motivo}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
