export async function getImageDimensionsFromStream(imageStream) {
  //   const blob = await imageStream.arrayBuffer();
  const image = new Image();
  const imageUrl = URL.createObjectURL(new Blob([imageStream]));
  
  return new Promise((resolve, reject) => {
    image.src = imageUrl;
    image.onload = function () {
      resolve({ width: this.width, height: this.height });
      URL.revokeObjectURL(imageUrl);
    };
    image.onerror = function () {
      reject(new Error("Failed to load image"));
      URL.revokeObjectURL(imageUrl);
    };
  });
}
