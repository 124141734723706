import React, { useReducer } from "react";

import CatTipoCorteCajaContex from "./CatTipoCorteCajaContext";
import CatTipoCorteCajaReducer from "./CatTipoCorteCajaReducer";

import { LISTAR_CATTIPOCORTECAJA } from "../../types";

import clienteAxios from "../../config/axios";

export default function CatTipoCorteCajaState(props) {
  const initialState = {
    tipoCorteCaja: [],
  };

  const [state, dispatch] = useReducer(CatTipoCorteCajaReducer, initialState);

  const listarTiposCorteCaja = async () => {
    const res = await clienteAxios.get("/estatuscortecaja/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CATTIPOCORTECAJA,
      payload: res.data,
    });
  };

  return (
    <CatTipoCorteCajaContex.Provider
      value={{
        tipoCorteCaja: state.tipoCorteCaja,
        listarTiposCorteCaja,
      }}>
      {props.children}
    </CatTipoCorteCajaContex.Provider>
  );
}
