import React, { useReducer } from "react";

import ProductosServiciosContext from "./ProductosServiciosContext";
import ProductosServiciosReducer from "./ProductosServiciosReducer";

import {
  LISTAR_PRODUCTOSSERVICIOS,
  LISTAR_NUMIDENTIFICACION,
  LISTAR_PRINCIPIOSACTIVOS,
  LISTAR_NOMBRECOMERCIALES,
  LISTAR_NOMBRECOMERCIALES_NUMIDENTIFICACION,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function ProductosServiciosState(props) {
  const initialState = {
    productosServicios: [],
    numerosIdentificacion: [],
    principiosActivos: [],
    nombresComerciales: [],
    nombreComercialNumeroIdentificacion: [],
  };

  const [state, dispatch] = useReducer(ProductosServiciosReducer, initialState);

  const listarProductosServiciosPersonal = async () => {
    const res = await clienteAxios.get("/productoservicio/personal", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    setProductosServicios(res.data);

    return res.data;
  };

  const listarProductosServiciosPersonalDivididos = async () => {
    const res = await clienteAxios.get("/productoservicio/personal/divididos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    setProductosServicios(res.data);

    return res.data;
  };

  const listarProductosServiciosAseguradora = async (idAseguradora) => {
    const res = await clienteAxios.get(`/productoservicio/aseguradora?id=${idAseguradora}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });
    setProductosServicios(res.data);
    return res.data;
  };

  const listarProductosServiciosAseguradoraDivididos = async (idAseguradora) => {
    const res = await clienteAxios.get(`/productoservicio/divididos/aseguradora?id=${idAseguradora}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });
    setProductosServicios(res.data);
    return res.data;
  };

  const listarProductosPersonal = async () => {
    const res = await clienteAxios.get("/productoservicio/productos/personal", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });
    setProductosServicios(res.data);
  };

  const setProductosServicios = async (productosServicios) => {
    dispatch({
      type: LISTAR_PRODUCTOSSERVICIOS,
      payload: productosServicios,
    });
  };

  const listarNumerosIdentificacion = async () => {
    const res = await clienteAxios.get(`/productoservicio/productos/numeroidentficacion`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    const numerosIdentificacionSinNull = res.data.filter((numeroIdentficacion) => numeroIdentficacion != null);

    dispatch({
      type: LISTAR_NUMIDENTIFICACION,
      payload: numerosIdentificacionSinNull,
    });
  };

  const listarPrincipiosActivos = async () => {
    const res = await clienteAxios.get(`/productoservicio/productos/principioactivo`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    const principiosActivosSinNull = res.data.filter((principioActivo) => principioActivo != null);

    dispatch({
      type: LISTAR_PRINCIPIOSACTIVOS,
      payload: principiosActivosSinNull,
    });
  };

  const listarNombresComerciales = async () => {
    const res = await clienteAxios.get(`/productoservicio/productos/nombrecomercial`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    const nombresComercialesSinNull = res.data.filter((nombreComercial) => nombreComercial != null);

    dispatch({
      type: LISTAR_NOMBRECOMERCIALES,
      payload: nombresComercialesSinNull,
    });
  };

  const listarNombreComercialYNumeroIdentificacion = async () => {
    const nombresComer = await listarNombresComerciales();
    const numIdent = await listarNumerosIdentificacion();

    dispatch({
      type: LISTAR_NOMBRECOMERCIALES_NUMIDENTIFICACION,
      payload: nombresComer.concat(numIdent),
    });
  };

  return (
    <ProductosServiciosContext.Provider
      value={{
        productosServicios: state.productosServicios,
        numerosIdentificacion: state.numerosIdentificacion,
        principiosActivos: state.principiosActivos,
        nombresComerciales: state.nombresComerciales,
        nombreComercialNumeroIdentificacion: state.nombreComercialNumeroIdentificacion,
        listarProductosServiciosAseguradora,
        listarProductosServiciosPersonal,
        listarProductosPersonal,
        setProductosServicios,
        listarNumerosIdentificacion,
        listarPrincipiosActivos,
        listarNombresComerciales,
        listarNombreComercialYNumeroIdentificacion,
        listarProductosServiciosPersonalDivididos,
        listarProductosServiciosAseguradoraDivididos,
      }}>
      {props.children}
    </ProductosServiciosContext.Provider>
  );
}
