import React, { useReducer } from "react";

import PagosMedicoContext from "./PagosMedicoContext";
import PagosMedicoReducer from "./PagosMedicoReducer";

import clienteAxios from "../../config/axios";

import {
  FILTRO_PAGOMEDICO,
  LISTAR_PAGOSMEDICO,
  ERROR_ESTATUS_CANCELAR_PAGO,
  PAGO_MEDICO_SELECCIONADO,
  FILTRO_VALESMEDICO,
  LISTAR_VALESMEDICO,
  REGISTRAR_NUEVO_PAGO_MEDICO,
  ERROR_REGISTRAR_NUEVO_PAGO_MEDICO,
} from "../../types";

export default function PagosMedicoState(props) {
  const initialState = {
    pagosMedicoFiltrados: [],
    filtrosPagosMedico: {},
    filtrosValesMedico: {},
    valesMedicosFiltrados: [],
    pagoMedicoSeleccionado: null,
  };

  const [state, dispatch] = useReducer(PagosMedicoReducer, initialState);

  const setFiltrosPagosMedico = async (filtrosPagosMedico) => {
    dispatch({
      type: FILTRO_PAGOMEDICO,
      payload: filtrosPagosMedico,
    });
  };

  const setFiltrosValesMedico = async (filtrosValesMedico) => {
    dispatch({
      type: FILTRO_VALESMEDICO,
      payload: filtrosValesMedico,
    });
  };

  const listarPagosMedico = async (filtrosPagosMedico) => {
    const filtrosPagosMedicoNuevo =
      filtrosPagosMedico.folioValePago === "" ? { ...filtrosPagosMedico, folioValePago: null } : filtrosPagosMedico;

    const res = await clienteAxios.post("/vales/pago/medico/detalles", filtrosPagosMedicoNuevo, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    res.data.map((pago) => {
      let fechaPago = pago.fechaPago.split("T")[0];
      fechaPago = fechaPago.split("-");
      pago.fechaPago = `${fechaPago[2]}/${fechaPago[1]}/${fechaPago[0]}`;
      return pago;
    });

    dispatch({
      type: LISTAR_PAGOSMEDICO,
      payload: res.data,
    });
  };

  const listarPagosMedicosInternos = async (filtrosPagosMedico) => {
    const filtrosPagosMedicoNuevo =
      filtrosPagosMedico.folioValePago === "" ? { ...filtrosPagosMedico, folioValePago: null } : filtrosPagosMedico;

    const res = await clienteAxios.post("/vales/pago/medico/internos", filtrosPagosMedicoNuevo, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    res.data.map((pago) => {
      let fechaPago = pago.fechaPago.split("T")[0];
      fechaPago = fechaPago.split("-");
      pago.fechaPago = `${fechaPago[2]}/${fechaPago[1]}/${fechaPago[0]}`;
      return pago;
    });

    dispatch({
      type: LISTAR_PAGOSMEDICO,
      payload: res.data,
    });
  };

  const listarPagosMedicosExternos = async (filtrosPagosMedico) => {
    const filtrosPagosMedicoNuevo =
      filtrosPagosMedico.folioValePago === "" ? { ...filtrosPagosMedico, folioValePago: null } : filtrosPagosMedico;

    const res = await clienteAxios.post("/vales/pago/medico/externos", filtrosPagosMedicoNuevo, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    res.data.map((pago) => {
      let fechaPago = pago.fechaPago.split("T")[0];
      fechaPago = fechaPago.split("-");
      pago.fechaPago = `${fechaPago[2]}/${fechaPago[1]}/${fechaPago[0]}`;
      return pago;
    });

    dispatch({
      type: LISTAR_PAGOSMEDICO,
      payload: res.data,
    });
  };

  const listarValesMedico = async (filtrosValesMedico) => {
    const res = await clienteAxios.post("/vales/filtro/medicos/internos", filtrosValesMedico, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    res.data.map((vale) => {
      let fechaVale = vale.fechaVale.split("T")[0];
      fechaVale = fechaVale.split("-");
      vale.fechaVale = `${fechaVale[2]}/${fechaVale[1]}/${fechaVale[0]}`;
      vale.total = vale.importeMedicamentos + vale.importeAdministracion;
      //Checar que el de admin sea menor al total
      if (vale.saldoPendienteAdministracion < vale.importeAdministracion || vale.saldoPendienteMedicamentos < vale.importeMedicamentos) {
        vale.checked = true;
        vale.disabledCheck = true;
        //if(vale.idVale==2281){
        //  alert("Aca esta");
        //}
      } else vale.checked = false;

      return vale;
    });

    dispatch({
      type: LISTAR_VALESMEDICO,
      payload: res.data,
    });
    return res.data;
  };

  const cancelarPago = async ({ idMedicoPago, folioVale }) => {
    try {
      const res = await clienteAxios.post(
        `/vales/pago/medico/cancelar`,
        { idMedicoPago: idMedicoPago, folioVale: folioVale },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_ESTATUS_CANCELAR_PAGO,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setPagosMedicoFiltrados = async (pagosMedicoFiltrados) => {
    dispatch({
      type: LISTAR_PAGOSMEDICO,
      payload: pagosMedicoFiltrados,
    });
  };

  const setValesMedicoFiltrados = async (valesMedicosFiltrados) => {
    dispatch({
      type: LISTAR_VALESMEDICO,
      payload: valesMedicosFiltrados,
    });
  };

  const seleccionarPagoMedico = async (folioValePago) => {
    try {
      const res = await clienteAxios.post(
        `vales/pago/medico/detalles`,
        { folioValePago: folioValePago },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      dispatch({
        type: PAGO_MEDICO_SELECCIONADO,
        payload: res.data,
      });
      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const registrarNuevoPagoMedico = async (nuevoRegistroPago) => {
    try {
      const res = await clienteAxios.post(`/vales/pago/medico`, nuevoRegistroPago, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      dispatch({
        type: REGISTRAR_NUEVO_PAGO_MEDICO,
        payload: res.data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_REGISTRAR_NUEVO_PAGO_MEDICO,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const exportPagoMedicoReport = async (idMedicoPago) => {
    // Preguntar url para exportar medico pago

    clienteAxios
      .get(`/vales/export/pago/${idMedicoPago}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        var file = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => console.log(error));
  };

  return (
    <PagosMedicoContext.Provider
      value={{
        pagosMedicoFiltrados: state.pagosMedicoFiltrados,
        filtrosPagosMedico: state.filtrosPagosMedico,
        filtrosValesMedico: state.filtrosValesMedico,
        valesMedicosFiltrados: state.valesMedicosFiltrados,
        pagoMedicoSeleccionado: state.pagoMedicoSeleccionado,
        setFiltrosPagosMedico,
        listarPagosMedico,
        listarPagosMedicosInternos,
        listarPagosMedicosExternos,
        setPagosMedicoFiltrados,
        cancelarPago,
        seleccionarPagoMedico,
        setFiltrosValesMedico,
        listarValesMedico,
        setValesMedicoFiltrados,
        registrarNuevoPagoMedico,
        exportPagoMedicoReport,
      }}>
      {props.children}
    </PagosMedicoContext.Provider>
  );
}
