import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import formatNumber from "../../utils/index";

import OrdenesCompraContext from "../../context/OrdenesCompra/OrdenesCompraContext";
import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";
import AuthContext from "../../context/Auth/AuthContext";

export default function ListadoProductosOrdenCompra({ props, isHistorico, isRecepcionFarmacia }) {
  const [loading, setLoading] = useState(false);
  const idglobal = props.match.params.idOrdenCompra

  const [loteEliminar, setLoteEliminar] = useState(null);
  const [productoEliminar, setProductoEliminar] = useState(null);
  const [productosServiciosFilter, setProductosServiciosFilter] = useState([]);
  const [formatProductos, setFormatProductos] = useState(false);
  const [diferenciaProductosInicial, setDiferenciaProductosInicial] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [formatFechaCaducidad, setFormatFechaCaducidad] = useState(false);
  const [archivoEliminar, setArchivoEliminar] = useState();
  const [posicionArchivoEliminar, setPosicionArchivoEliminar] = useState();
  const [filas, setFilas] = useState(null);
  const [factura, setFactura] = useState("");

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_guardar_completo: false,
    confirm_alert_guardar_incompleto: false,
    confirm_alert_guardar_orden: false,
    confirm_alert_eliminar_producto: false,
    confirm_alert_solicitar_aprobacion: false,
    confirm_alert_Eliminar_Archivo:false,
    confirm_alert_Agregar_Archivo:false,
    confirm_alert_fecha: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const ordenCompraContext = useContext(OrdenesCompraContext);
  const {
    ordenCompraSeleccionada,
    setOrdenCompraSeleccionada,
    ordenCompraShowEdit,
    guardarOrdenCompra,
    uploadCancelarOrdenCompra,
    cancelarOrdenCompra,
    setCancelarOrdenCompra,
    exportArchivoBack,
    solicitarAprobacionPago, 
    seleccionarOrdenCompra,
  } = ordenCompraContext;

  //ACCESO AL ESTADO GLOBAL - ProductosServicios
  const ctxProductosServicios = useContext(ProductosServiciosContext);
  const { productosServicios, listarProductosPersonal, setProductosServicios } = ctxProductosServicios;

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  //EN ESTA PARTE CREAMOS UN REGISTRO VACIO DEL NUEVO ARCHIVO LO CONCATENAMOS
  const agregarArchivo = () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_Agregar_Archivo: true,
    }) 
    
};

  const toggleExpanded = async (producto) => {
    producto.expanded = !producto.expanded;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
  };

  const ocultarBotonAprobar = ordenCompraSeleccionada.idCatEstatusOrdenCompra ==7? true:false;
 
  const seleccionarArchivo = async (e) => {
    const files = e.target.files;
    setFilas(files);
    //alert( filas[0]? filas[0].name : "" );
  }

  const uploadFile = async (e) => {
    if(factura.trim() != ""){    
      if (!ordenCompraSeleccionada.archivos) {
        ordenCompraSeleccionada.archivos = [];
        setOrdenCompraSeleccionada(ordenCompraSeleccionada);
      }
      if(filas != null){
        for (var i = 0; i < filas.length; i++) {
          let file = filas[i];

          const reader = new FileReader();

          reader.addEventListener(
            "load",
            function () {
              // convert image file to base64 string
              let strFile = reader.result;
              let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
              let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);

              let nuevoArchivo = {
                nombreArchivo: file.name,
                mimeType: type,
                tamano: file.size,
                inputStream: fileBase64,
                descripcion : factura,
                nuevo : true,
              };

              ordenCompraSeleccionada.archivos = ordenCompraSeleccionada.archivos.concat(nuevoArchivo);
              setOrdenCompraSeleccionada(ordenCompraSeleccionada);
            },
            false
          );

          if (file) {
            reader.readAsDataURL(file);
          }
        }
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_Agregar_Archivo: false,
        }) 
        setFactura("");
        setFilas(null);
      }else{
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: `Seleccione el archivo a insertar`,
        });
      }
    }else{
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Inserte la descripción del documento que esta insertando`,
      });
    }
  };

  const changeJustificacionProducto = async (e, producto) => {
    producto.justificacion = e.target.value;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
  };

  const changeLote = async (e, lote) => {
    lote.lote = e.target.value;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
  };

  const changePiezasRecibidasLote = async (e, producto, lote) => {
    const value = e.target.value;
    lote.piezasRecibidas = value;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    //hay que checar que la suma de los lotes en ese producto sea igual o menor al numero de piezas del producto
    const piezasValidas = await verificarPiezasProducto(producto, lote, value);

    if (!piezasValidas) {
      lote.piezasRecibidas = 0;
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    }
  };

  const verificarPiezasProducto = async (producto, lote, value) => {
    let piezasValidas = true;

    let totalPiezasProducto = 0;

    if (producto.piezasCompradas > 0) {
      totalPiezasProducto += Number(producto.piezasCompradas);
    }
    if (producto.piezasRegaladas > 0) {
      totalPiezasProducto += Number(producto.piezasRegaladas);
    }

    let sumaPiezasLote = 0;
    producto.lotes.map((loteProducto, index) => {
      if (loteProducto != lote) {
        sumaPiezasLote += Number(loteProducto.piezasRecibidas);
      } else if (loteProducto == lote) {
        sumaPiezasLote += Number(value);
      }
    });

    if (sumaPiezasLote > totalPiezasProducto) {
      piezasValidas = false;
      producto.diferencia = totalPiezasProducto - (sumaPiezasLote - value);
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);

      setUISweetAlert({
        ...UISweetAlert,

        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `La suma de piezas recibidas no puede ser mayor a las piezas del producto`,
      });
    } else {
      producto.diferencia = totalPiezasProducto - sumaPiezasLote;
      if (producto.diferencia == 0) {
        producto.justificacion = "";
      }
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    }

    return piezasValidas;
  };

  const changeCaducidadLote = async (e, lote) => {
    lote.caducidad = e.target.value;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
  };

  const confirmGuardarRecepcionOrdenCompra = async () => {
    //Primero verificamos que los datos ingresados estan completos
    const verificarRecepcionOrdenCompra = await checkRecepcionOrdenCompra();

    if (!verificarRecepcionOrdenCompra) {
      return;
    }

    const isOrdenCompraRecepcionCompleta = await checkOrdenCompraRecepcion();

    if (isOrdenCompraRecepcionCompleta) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_guardar_completo: true,
      });
    } else if (!isOrdenCompraRecepcionCompleta) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_guardar_incompleto: true,
      });
    }
  };

  const checkRecepcionOrdenCompra = async () => {
    let checarRecepcionOrdenCompra = true;
    await ordenCompraSeleccionada.ordencompraproducto.map(async (producto) => {
      if (producto.diferencia > 0 && producto.lotes.length > 0  && !producto.justificacion && checarRecepcionOrdenCompra) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: `Por favor, introduzca la justificación de la diferencia en el producto '${producto.catProductos.nombreComercial}'`,
        });
        checarRecepcionOrdenCompra = false;
        return;
      }

      if (checarRecepcionOrdenCompra && producto.lotes && producto.lotes.length > 0) {
        producto.lotes.map((lote) => {
          let valido = true;

          if (!lote.lote && checarRecepcionOrdenCompra) {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              dynamic_title: "Fallo",
              dynamic_description: `Por favor, introduzca el lote`,
            });
            checarRecepcionOrdenCompra = false;
          }

          if (!lote.caducidad && checarRecepcionOrdenCompra) {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              dynamic_title: "Fallo",
              dynamic_description: `Por favor, introduzca la caducidad`,
            });
            checarRecepcionOrdenCompra = false;
          }

          if (!lote.piezasRecibidas && checarRecepcionOrdenCompra) {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              dynamic_title: "Fallo",
              dynamic_description: `Por favor, introduzca el número de piezas recibidas`,
            });
            checarRecepcionOrdenCompra = false;
          }
        });
      }/*else{
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: `Por favor, introduzca los lotes`,
        });
        checarRecepcionOrdenCompra = false;
      }*/
    });

    if (ordenCompraSeleccionada.ordencompraproductoregalo && ordenCompraSeleccionada.ordencompraproductoregalo.length > 0) {
      await ordenCompraSeleccionada.ordencompraproductoregalo.map(async (producto) => {
        if (!producto.catProductos && !producto.idCatProducto && checarRecepcionOrdenCompra) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el producto`,
          });
          checarRecepcionOrdenCompra = false;
        }

        if (producto.piezasRegaladas <= 0 && checarRecepcionOrdenCompra) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el número de piezas en el producto ${
              producto.catProductos ? producto.catProductos.nombreComercial : producto.descripcion
            }`,
          });
          checarRecepcionOrdenCompra = false;
        }

        if (!producto.precio && checarRecepcionOrdenCompra) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el precio del producto`,
          });
          checarRecepcionOrdenCompra = false;
        }

        if (checarRecepcionOrdenCompra && producto.lotes && producto.lotes.length > 0) {
          producto.lotes.map((lote) => {
            if (!lote.lote && checarRecepcionOrdenCompra) {
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: `Por favor, introduzca el lote`,
              });
              checarRecepcionOrdenCompra = false;
            }

            if (!lote.caducidad && checarRecepcionOrdenCompra) {
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: `Por favor, introduzca la caducidad`,
              });
              checarRecepcionOrdenCompra = false;
            }

            if (!lote.piezasRecibidas && checarRecepcionOrdenCompra) {
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: `Por favor, introduzca el número de piezas recibidas`,
              });
              checarRecepcionOrdenCompra = false;
            }
          });
        }

        if (checarRecepcionOrdenCompra && (!producto.lotes || producto.lotes.length <= 0)) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca los lotes para el producto ${
              producto.catProductos ? producto.catProductos.nombreComercial : producto.descripcion
            }`,
          });
          checarRecepcionOrdenCompra = false;
        }

        if (producto.diferencia > 0 && checarRecepcionOrdenCompra) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Faltan ${producto.diferencia} piezas el producto ${
              producto.catProductos ? producto.catProductos.nombreComercial : producto.descripcion
            }`,
          });
          checarRecepcionOrdenCompra = false;
        }
      });
    }

    return checarRecepcionOrdenCompra;
  };

  const checkOrdenCompraRecepcion = async () => {
    let ordenCompraCompleta = true;

    ordenCompraSeleccionada.ordencompraproducto.map((producto) => {

      if (producto.diferencia > 0 && ordenCompraCompleta) {
        ordenCompraCompleta = false;
      }

      if (producto.lotes ==0 ) {
        ordenCompraCompleta = false;
      }  

      /*let suma = 0;
      producto.lotes.map((lote) => {
        suma = suma + lote.piezasRecibidas;
      })

      if(suma < producto.piezasCompradas){
        ordenCompraCompleta = false;
      }*/


    });

    return ordenCompraCompleta;
  };

  const uploadRecepcionCompletaOrdenCompra = async () => {
    const estatusActual =
      ordenCompraSeleccionada.estatusordencompra[ordenCompraSeleccionada.estatusordencompra.length - 1].catEstatusordencompra;
    ordenCompraSeleccionada.idEstatusNuevo = estatusActual.idCatEstatusOrdenCompra + 2;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    // uploadOrdenCompra();
    // uploadRecepcionOrdenCompra();
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_fecha: true,
    });
  };

  const uploadRecepcionIncompletaOrdenCompra = async () => {
    const estatusActual =
      ordenCompraSeleccionada.estatusordencompra[ordenCompraSeleccionada.estatusordencompra.length - 1].catEstatusordencompra;
    ordenCompraSeleccionada.idEstatusNuevo = estatusActual.idCatEstatusOrdenCompra + 1;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    // uploadOrdenCompra();
    // uploadRecepcionOrdenCompra();
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_fecha: true,
    });
  };

  const uploadRecepcionOrdenCompra = async () => {
    setLoading(true);
    const res = await guardarOrdenCompra();
    // console.log(res);

    if (res.data.error) {
      setLoading(false);

      ordenCompraSeleccionada.idEstatusNuevo = null;
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_guardar_completo: false,
        confirm_alert_guardar_incompleto: false,
        confirm_alert_guardar_orden: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (!res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_guardar_completo: false,
        confirm_alert_guardar_incompleto: false,
        confirm_alert_guardar_orden: false,
        redirect: true,
        path: usuario.authorities.includes("ROLE_FARMACIA") ? "/recepcion/generica/producto" : "/ordenescompra",
        dynamic_description: "Se registraron los cambios de manera exitosa!",
      });
    }
  };

  const nuevoLoteProducto = async (producto) => {
    let nuevoLote = {
      lote: "",
      caducidad: "",
      piezas: "",
    };

    if (!producto.lotes) {
      producto.lotes = [];
    }

    producto.lotes = producto.lotes.concat(nuevoLote);
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
  };

  const calcularDiferencia = async (producto) => {
    let diferencia = 0;
    if (producto.piezasCompradas > 0) {
      diferencia += Number(producto.piezasCompradas);
    }
    if (producto.piezasRegaladas > 0) {
      diferencia += Number(producto.piezasRegaladas);
    }

    if (producto.lotes && producto.lotes.length > 0) {
      let sumaPiezasLote = 0;
      producto.lotes.map((loteProducto) => {
        sumaPiezasLote += Number(loteProducto.piezasRecibidas);
      });
      diferencia = diferencia - sumaPiezasLote;
    }

    producto.diferencia = diferencia;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);

    return diferencia;
  };

  const eliminarLoteProducto = async () => {
    productoEliminar.lotes = productoEliminar.lotes.filter((loteInv) => loteInv != loteEliminar);
    calcularDiferencia(productoEliminar);
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_eliminar_lote: false,
      success_dlg: true,
      dynamic_title: "Exito!",
      dynamic_description: "Lote de producto ha sido eliminado",
    });
  };

  const uploadOrdenCompra = async () => {
    setLoading(true);
    //verificarRecepcionOrdenCompra();
    const res = await guardarOrdenCompra();
    // console.log(res);

    if (res.data.error) {
      setLoading(false);

      ordenCompraSeleccionada.idEstatusNuevo = null;
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_guardar_completo: false,
        confirm_alert_guardar_incompleto: false,
        confirm_alert_guardar_orden: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (!res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_guardar_completo: false,
        confirm_alert_guardar_incompleto: false,
        confirm_alert_guardar_orden: false,
        redirect: true,
        path: usuario.authorities.includes("ROLE_FARMACIA") ? "/recepcion/generica/producto" : "/ordenescompra",
        dynamic_description: "Se registraron los cambios de manera exitosa!",
      });
    }
  };

  const uploadAprobarPagoOrdenCompra = async () => {
    const estatusActual =
      ordenCompraSeleccionada.estatusordencompra[ordenCompraSeleccionada.estatusordencompra.length - 1].catEstatusordencompra;

    // Dias pago 0 se pasa a estatus 5
    if (estatusActual.idCatEstatusOrdenCompra == 1) {
      ordenCompraSeleccionada.idEstatusNuevo = 5;
    } else if (estatusActual.idCatEstatusOrdenCompra == 2) {
      ordenCompraSeleccionada.idEstatusNuevo = estatusActual.idCatEstatusOrdenCompra + 2;
    } else if (estatusActual.idCatEstatusOrdenCompra == 3) {
      ordenCompraSeleccionada.idEstatusNuevo = estatusActual.idCatEstatusOrdenCompra + 1;
    }
    // if (nuevoImporteOrden !== null && nuevoImporteOrden > 0) ordenCompraSeleccionada.importeTotal = nuevoImporteOrden;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    uploadOrdenCompra();
  };

  const nuevoProductoRegalo = async () => {
    if (!formatProductos) {
      replaceAcentos();
    }
    let nuevoProductoRegalo = {
      idCatProducto: "",
      idCatTipoProducto: "",
      descripcion: "",
      piezas: "",
    };

    if (!ordenCompraSeleccionada.ordencompraproductoregalo) {
      ordenCompraSeleccionada.ordencompraproductoregalo = [];
    }

    ordenCompraSeleccionada.ordencompraproductoregalo =
      ordenCompraSeleccionada.ordencompraproductoregalo.concat(nuevoProductoRegalo);
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
  };

  const changeProductoRegaloDescripcion = async (e, producto) => {
    let value = e.target.value;
    producto.descripcion = value;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);

    const productosFiltrados = await productosServicios.filter((prodServ) => {
      if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.principioActivo != null && prodServ.principioActivo.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.numIdentificacion != null && prodServ.numIdentificacion.startsWith(value, 0)) {
        return true;
      } else if (prodServ.codigoEAN != null && prodServ.codigoEAN.startsWith(value, 0)) {
        return true;
      }
    });

    setProductosServiciosFilter(productosFiltrados);

    if (value.includes("-")) {
      seleccionarProducto(value, producto);
    } else {
      producto.idCatProducto = null;
      producto.idCatTipoProducto = null;
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    }
  };

  const seleccionarProducto = async (value, producto) => {
    var lastIndex = value.lastIndexOf("-");
    const productoServicioSeleccionado = await productosServicios.filter((prodServ) => {
      if (
        prodServ.numIdentificacion != null &&
        prodServ.numIdentificacion.startsWith(value.slice(lastIndex + 1, value.length).replace(" ", ""), 0)
      ) {
        return true;
      }
    });

    if (productoServicioSeleccionado.length > 0) {
      let productoSeleccionado = productoServicioSeleccionado[0];

      const productoRepetido = await checarProductoRepetido(productoSeleccionado, producto);

      if (productoRepetido) {
        producto.idCatProducto = null;
        producto.idCatTipoProducto = null;
        producto.descripcion = "";

        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Producto repetido",
        });
      } else {
        producto.idCatProducto = productoSeleccionado.idCatProducto;
        producto.idCatTipoProducto = productoSeleccionado.idCatTipoproducto;
      }
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    } else {
      producto.idCatProducto = null;
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    }
  };

  const checarProductoRepetido = async (producto) => {
    let productoRepetido = false;

    ordenCompraSeleccionada.ordencompraproductoregalo.map((element) => {
      if (element != producto && element.idCatProducto == producto.idCatProducto && !productoRepetido) {
        productoRepetido = true;
      }
    });

    ordenCompraSeleccionada.ordencompraproducto.map((element) => {
      if (element.catProductos.idCatProducto == producto.idCatProducto && !productoRepetido) {
        productoRepetido = true;
      }
    });

    return productoRepetido;
  };

  const changePiezasProductoRegalo = async (e, producto) => {
    producto.piezasRegaladas = e.target.value;
    if (producto.lotes && producto.lotes.length > 0) {
      await producto.lotes.map((lote) => {
        lote.piezas = 0;
        setOrdenCompraSeleccionada(ordenCompraSeleccionada);
      });
    }
    calcularDiferencia(producto);
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
  };

  const replaceAcentos = async () => {
    const productosServiciosFormatted = await productosServicios.filter((prodServ) => {
      if (prodServ.principioActivo != null) {
        prodServ.principioActivo = prodServ.principioActivo
          .normalize("NFD")
          .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1")
          .normalize();
      }
      return true;
    });
    setFormatProductos(true);
    setProductosServicios(productosServiciosFormatted);
  };

  const eliminarArchivo = async () => {
    ordenCompraSeleccionada.archivos = ordenCompraSeleccionada.archivos.filter(
      (prod) => (prod.nombreArchivo != archivoEliminar.nombreArchivo && prod.nuevo == true) || (!prod.nuevo)
    );
    setArchivoEliminar(null);
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_eliminar_producto: false,
      success_dlg: true,
      dynamic_title: "Exito!",
      confirm_alert_Eliminar_Archivo:false,
      dynamic_description: "Producto ha sido eliminado",
    });
  };

  const eliminarProducto = async () => {
    ordenCompraSeleccionada.ordencompraproductoregalo = ordenCompraSeleccionada.ordencompraproductoregalo.filter(
      (prod) => prod != productoEliminar
    );
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_eliminar_producto: false,
      success_dlg: true,
      dynamic_title: "Exito!",
      dynamic_description: "Producto ha sido eliminado",
    });
  };

  const calcularDiferenciaTodosProductos = async () => {
    ordenCompraSeleccionada.ordencompraproducto.map((producto) => {
      // console.log(producto);
      calcularDiferencia(producto);
    });
    setDiferenciaProductosInicial(true);
  };

  const cancelarOrdenCompraConfirm = async () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_cancelar_orden_compra: true,
    });
  };

  const onChangeCancelarOrdenCompra = async (e) => {
    setCancelarOrdenCompra({
      ...cancelarOrdenCompra,
      [e.target.name]: e.target.value,
    });
  };

  const ordenCompraCancelar = async () => {
    if (cancelarOrdenCompra.comentarioEstatus == "" || cancelarOrdenCompra.fechaCancelacion == "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor llene la información necesaria.",
      });
      return;
    }

    setLoading(true);

    const res = await uploadCancelarOrdenCompra({
      idEstatusNuevo: 8,
      idOrdenCompra: ordenCompraSeleccionada.idOrdenCompra,
      comentarioEstatus: cancelarOrdenCompra.comentarioEstatus,
    });

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_cancelar_orden_compra: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error al cancelar la orden de compra. Inténtalo por favor más tarde.",
      });
    } else if (!res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        redirect: true,
        path: usuario.authorities.includes("ROLE_FARMACIA") ? "/recepcion/generica/producto" : "/ordenescompra",
        confirm_alert_cancelar_orden_compra: false,
        dynamic_description: "Orden de compra cancelada",
      });
      setCancelarOrdenCompra({
        fechaCancelacion: new Date().toISOString().slice(0, 10),
        comentarioEstatus: "",
      });
    }
  };

  const changePiezasRegaladas = async (e, producto) => {
    producto.piezasRegaladas = e.target.value;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);

    if (producto.lotes && producto.lotes.length > 0) {
      producto.lotes.map((lote) => {
        lote.piezasRecibidas = 0;
      });
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    }

    calcularDiferencia(producto);
  };
  const changePiezasARecibir = async (e, producto) => {
    producto.piezasCompradas = e.target.value;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);

    if (producto.lotes && producto.lotes.length > 0) {
      producto.lotes.map((lote) => {
        lote.piezasRecibidas = 0;
      });
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    }

    calcularDiferencia(producto);
  };

  const changePiezasPrecio = async (e, producto) => {
    producto.precio = e.target.value;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);

    if (producto.lotes && producto.lotes.length > 0) {
      producto.lotes.map((lote) => {
        lote.piezasRecibidas = 0;
      });
      setOrdenCompraSeleccionada(ordenCompraSeleccionada);
    }

    // calcularDiferencia(producto);
  };

  const changePrecioProductoRegalo = async (e, producto) => {
    let value = e.target.value;
    value = value.replace("$", "");
    if (value.includes("-")) {
      value = value.replace("-", "");
    }
    producto.precio = value;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
  };


  useEffect(() => {
    const fetcOrdenSeleccionada = async () => {
      await seleccionarOrdenCompra( idglobal);
      return ordenCompraSeleccionada;
    };


    if (
      !ordenCompraShowEdit.hiddenRecepcion &&
      !ordenCompraShowEdit.disabledRecepcion &&
      Object.entries(productosServicios).length === 0
    ) {
      listarProductosPersonal();
    }

    if (Object.entries(ordenCompraSeleccionada).length !== 0 && !diferenciaProductosInicial) {
      fetcOrdenSeleccionada();
      calcularDiferenciaTodosProductos();
    }

    // if (Object.entries(ordenCompraSeleccionada).length !== 0 && !formatFechaCaducidad) {
    //   checkFechaCaducidad();
    // }
  }, [formatFechaCaducidad]);
 
  const exportArchivo = async (archivoProveedor) => {
    const archivo = archivoProveedor;

    const archivoVo = {
      rutaArchivo: archivo.rutaArchivo,
      nombreArchivo: archivo.nombreArchivo,
      mimeType: archivo.mimeType,
    };
    const res = await exportArchivoBack(archivoVo);
    return res;
  };

  const confirmSolicitarAprobacionPago = async () => {
    setLoading(true);
    const res = await solicitarAprobacionPago();
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_guardar_completo: false,
        confirm_alert_guardar_incompleto: false,
        confirm_alert_guardar_orden: false,
        confirm_alert_solicitar_aprobacion: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (!res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_guardar_completo: false,
        confirm_alert_guardar_incompleto: false,
        confirm_alert_guardar_orden: false,
        confirm_alert_solicitar_aprobacion: false,
        redirect: true,
        path:usuario.authorities.includes("ROLE_FARMACIA") ? "/recepcion/generica/producto" : "/ordenescompra",
        dynamic_description: "Se registraron los cambios de manera exitosa!",
      });
    }
  };

  const verificaGuardado = async () => {
    const verificarRecepcionOrdenCompra = await checkRecepcionOrdenCompra();

    if (!verificarRecepcionOrdenCompra) {
      return;
    }
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_guardar_orden: true,
    })    
  }

  const onChangeFactura = (event) => {
    setFactura(event.target.value);
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
       
      <h3 className="flex h3">Lista de Productos</h3>

      <Card className="mt-10">
        <CardBody>
          <Row>
            <Col>
              <button
                className="btn btn-primary-orange-umo w-md mb-4 waves-effect waves-light"
                type="button"
                // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                onClick={cancelarOrdenCompraConfirm}>
                Cancelar Orden
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Descripción</th>
                      <th scope="col">
                        Piezas a<br></br>Recibir
                      </th>
                      <th scope="col">
                        Precio<br></br>Unitario
                      </th>
                      <th scope="col">
                        Piezas de<br></br>Regalo
                      </th>
                      <th scope="col">Diferencia</th>
                      <th scope="col">Justificación</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordenCompraSeleccionada && ordenCompraSeleccionada.ordencompraproducto
                      ? ordenCompraSeleccionada.ordencompraproducto.map((producto, index) => {
                          return (
                            <>
                              <tr key={producto}>
                                <td onClick={() => toggleExpanded(producto)}>
                                  {producto.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>}
                                </td>
                                <td>{producto.catProductos ? producto.catProductos.nombreComercial : ""}</td>
                                {/* <td>{producto.piezasCompradas ? producto.piezasCompradas : ""}</td> */}
                                <td>
                                  {isHistorico || isRecepcionFarmacia ? (
                                    <div>{producto.piezasCompradas ? producto.piezasCompradas : ""}</div>
                                  ) : (
                                    <input
                                      disabled={isHistorico || isRecepcionFarmacia}
                                      type="text"
                                      className="form-control"
                                      value={producto.piezasCompradas ? producto.piezasCompradas : ""}
                                      onChange={(e) => changePiezasARecibir(e, producto)}
                                    />
                                  )}
                                </td>
                                <td>
                                  {isHistorico || isRecepcionFarmacia ? (
                                    <div>{producto.precio ? formatNumber(producto.precio) : "$0"}</div>
                                  ) : (
                                    // <input
                                    //   disabled={isHistorico || isRecepcionFarmacia}
                                    //   type="text"
                                    //   className="form-control"
                                    //   value={producto.precio ? formatNumber(producto.precio) : "$0"}
                                    //   onChange={(e) => changePiezasPrecio(e, producto)}
                                    // />
                                    <input
                                      disabled={isHistorico || isRecepcionFarmacia}
                                      type="text"
                                      className="form-control"
                                      value={producto.precio ? producto.precio : "$0"}
                                      onChange={(e) => changePiezasPrecio(e, producto)}
                                    />
                                  )}
                                </td>
                                <td>
                                  {!ordenCompraShowEdit.hiddenRecepcion && !ordenCompraShowEdit.disabledRecepcion ? (
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={producto.piezasRegaladas ? producto.piezasRegaladas : ""}
                                      onChange={(e) => changePiezasRegaladas(e, producto)}
                                      min={1}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  ) : (
                                    `${producto.piezasRegaladas ? producto.piezasRegaladas : "0"}`
                                  )}
                                </td>
                                <td>{producto.diferencia >= 0 ? producto.diferencia : ""}</td>
                                <td>
                                  {!ordenCompraShowEdit.hiddenRecepcion && ordenCompraShowEdit.disabledRecepcion ? (
                                    <textarea
                                      rows={1}
                                      className="form-control"
                                      disabled={true}
                                      value={producto.justificacion ? producto.justificacion : ""}
                                    />
                                  ) : null}

                                  {!ordenCompraShowEdit.hiddenRecepcion && !ordenCompraShowEdit.disabledRecepcion ? (
                                    <textarea
                                      rows={1}
                                      className="form-control"
                                      disabled={producto.diferencia == 0 ? true : false}
                                      value={producto.justificacion ? producto.justificacion : ""}
                                      onChange={(e) => changeJustificacionProducto(e, producto)}
                                    />
                                  ) : null}
                                </td>
                              </tr>
                              {producto.expanded && (
                                <tr>
                                  <td colSpan={10}>
                                    {!ordenCompraShowEdit.hiddenRecepcion && !ordenCompraShowEdit.disabledRecepcion ? (
                                      <button
                                        type="button"
                                        hidden={producto.diferencia == 0 ? true : false}
                                        onClick={() => nuevoLoteProducto(producto)}
                                        className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-2 mr-6 hover:text-white">
                                        Nuevo Lote
                                      </button>
                                    ) : null}

                                    <table className="table table-hover table-centered table-nowrap mt-4">
                                      <thead>
                                        <tr>
                                          <th scope="col">Lote</th>
                                          <th scope="col">Caducidad</th>
                                          <th scope="col">
                                            Piezas<br></br>Recibidas
                                          </th>
                                          {!ordenCompraShowEdit.hiddenRecepcion && !ordenCompraShowEdit.disabledRecepcion ? (
                                            <th scope="col">Eliminar</th>
                                          ) : null}
                                          {!ordenCompraShowEdit.hiddenRecibida ? (
                                            <>
                                              <th scope="col">
                                                Fecha<br></br>Recepción
                                              </th>
                                              <th scope="col">
                                                Usuario<br></br>Receptor
                                              </th>
                                            </>
                                          ) : null}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {producto.lotes
                                          ? producto.lotes.map((lote, indexLote) => {
                                              return (
                                                <>
                                                  <tr key={lote}>
                                                    {!ordenCompraShowEdit.hiddenRecepcion &&
                                                    ordenCompraShowEdit.disabledRecepcion ? (
                                                      <>
                                                        <td>{lote.lote ? lote.lote : ""}</td>
                                                        <td>
                                                          {/* <input className="form-control" type="month" disabled={true} value={lote.caducidad} /> */}
                                                          <input
                                                            className="form-control"
                                                            type="date"
                                                            disabled={true}
                                                            value={lote.caducidad}
                                                          />
                                                        </td>
                                                        <td>{lote.piezasRecibidas ? lote.piezasRecibidas : ""}</td>
                                                      </>
                                                    ) : null}

                                                    {!ordenCompraShowEdit.hiddenRecepcion &&
                                                    !ordenCompraShowEdit.disabledRecepcion ? (
                                                      <>
                                                        <td>
                                                          <input
                                                            type="text"
                                                            maxLength={50}
                                                            onChange={(e) => changeLote(e, lote)}
                                                            className="form-control"
                                                            value={lote.lote ? lote.lote : ""}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            className="form-control"
                                                            type="date"
                                                            onChange={(e) => changeCaducidadLote(e, lote)}
                                                            value={lote.caducidad}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            className="form-control"
                                                            value={lote.piezasRecibidas ? lote.piezasRecibidas : ""}
                                                            onChange={(e) => changePiezasRecibidasLote(e, producto, lote)}
                                                            min={1}
                                                            onWheel={(e) => e.target.blur()}
                                                          />
                                                        </td>
                                                        <td>
                                                          <button
                                                            type="button"
                                                            hidden = {lote.idInventario}
                                                            onClick={() => {
                                                              setLoteEliminar(lote);
                                                              setProductoEliminar(producto);
                                                              setUISweetAlert({
                                                                ...UISweetAlert,
                                                                confirm_alert_eliminar_lote: true,
                                                              });
                                                            }}
                                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light flex  hover:text-white">
                                                            Eliminar
                                                          </button>
                                                        </td>
                                                      </>
                                                    ) : null}

                                                    {!ordenCompraShowEdit.hiddenRecibida ? (
                                                      <>
                                                        <td>{lote.fechaRecepcion ? lote.fechaRecepcion.split("T")[0] : ""}</td>
                                                        <td>{lote.nombreUsuarioReceptor ? lote.nombreUsuarioReceptor : ""}</td>
                                                      </>
                                                    ) : null}
                                                  </tr>
                                                </>
                                              );
                                            })
                                          : null}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* <Card className="mt-10">
        {console.log(ordenCompraSeleccionada)}
        <CardBody>
          <Row>
            <Col>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mt-4  mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Importe Total Actual</th>
                      <th scope="col">Importe Total Nuevo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{formatNumber(ordenCompraSeleccionada.importeTotal)}</td>
                      <td>
                        <input type="text" onChange={(e) => changeImporteOrden(e)} className="form-control" value={nuevoImporteOrden ? nuevoImporteOrden : "$0"} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card> */}

      <h3 className="flex h3 mt-6">Piezas de regalo</h3>

      <Card className="mt-10">
        <CardBody>
          {!ordenCompraShowEdit.hiddenRecepcion && !ordenCompraShowEdit.disabledRecepcion ? (
            <Row>
              <Col>
                <button
                  type="button"
                  onClick={() => nuevoProductoRegalo()}
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light flex hover:text-white">
                  Nuevo Producto
                </button>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mt-4  mb-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Descripción</th>
                      <th scope="col">
                        Piezas a<br></br>Recibir
                      </th>
                      <th scope="col">Precio</th>
                      {!ordenCompraShowEdit.hiddenRecepcion && !ordenCompraShowEdit.disabledRecepcion ? (
                        <th scope="col">Eliminar</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {ordenCompraSeleccionada && ordenCompraSeleccionada.ordencompraproductoregalo
                      ? ordenCompraSeleccionada.ordencompraproductoregalo.map((producto, index) => {
                          return (
                            <>
                              <tr key={producto}>
                                <td onClick={() => toggleExpanded(producto)}>
                                  {producto.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>}
                                </td>

                                {!ordenCompraShowEdit.hiddenRecepcion && ordenCompraShowEdit.disabledRecepcion ? (
                                  <>
                                    <td>{producto.catProductos ? producto.catProductos.nombreComercial : ""}</td>
                                    <td>{producto.piezas ? producto.piezas : ""}</td>
                                  </>
                                ) : null}

                                {!ordenCompraShowEdit.hiddenRecepcion && !ordenCompraShowEdit.disabledRecepcion ? (
                                  <>
                                    <td>
                                      {producto.catProductos ? (
                                        `${producto.catProductos.nombreComercial}`
                                      ) : (
                                        <>
                                          <input
                                            type="text"
                                            onChange={(e) => changeProductoRegaloDescripcion(e, producto)}
                                            className="form-control"
                                            value={producto.descripcion ? producto.descripcion : ""}
                                            list={`listaInsumos${index}`}
                                            autoComplete="off"
                                          />
                                          <datalist id={`listaInsumos${index}`}>
                                            {productosServiciosFilter.map((prodServ) => {
                                              return (
                                                <>
                                                  {
                                                    <option value={`${prodServ.nombreComercial} - ${prodServ.numIdentificacion}`}>
                                                      {prodServ.nombreComercial} - {prodServ.numIdentificacion} -
                                                      {prodServ.codigoEAN} - {prodServ.principioActivo}
                                                    </option>
                                                  }
                                                </>
                                              );
                                            })}
                                          </datalist>
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={producto.piezasRegaladas ? producto.piezasRegaladas : ""}
                                        onChange={(e) => changePiezasProductoRegalo(e, producto)}
                                        min={1}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        onChange={(e) => changePrecioProductoRegalo(e, producto)}
                                        className="form-control"
                                        // value={producto.precio ? formatNumber(producto.precio) : `$`}
                                        value={producto.precio ? `$${producto.precio}` : `$`}
                                      />
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setProductoEliminar(producto);
                                          setUISweetAlert({
                                            ...UISweetAlert,
                                            confirm_alert_eliminar_producto: true,
                                          });
                                        }}
                                        className="btn btn-primary-orange-umo w-md waves-effect waves-light flex  hover:text-white">
                                        Eliminar
                                      </button>
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                              {producto.expanded && (
                                <tr>
                                  <td colSpan={10}>
                                    {!ordenCompraShowEdit.hiddenRecepcion && !ordenCompraShowEdit.disabledRecepcion ? (
                                      <button
                                        type="button"
                                        hidden={producto.diferencia == 0 ? true : false}
                                        onClick={() => nuevoLoteProducto(producto)}
                                        className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                                        Nuevo Lote
                                      </button>
                                    ) : null}

                                    <table className="table table-hover table-centered table-nowrap mt-6">
                                      <thead>
                                        <tr>
                                          <th scope="col">Lote</th>
                                          <th scope="col">Caducidad</th>
                                          <th scope="col">
                                            Piezas<br></br>Recibidas
                                          </th>
                                          {!ordenCompraShowEdit.hiddenRecepcion && !ordenCompraShowEdit.disabledRecepcion ? (
                                            <th scope="col">Eliminar</th>
                                          ) : null}
                                          {!ordenCompraShowEdit.hiddenRecibida ? (
                                            <>
                                              <th scope="col">
                                                Fecha<br></br>Recepción
                                              </th>
                                              <th scope="col">
                                                Usuario<br></br>Receptor
                                              </th>
                                            </>
                                          ) : null}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {producto.lotes
                                          ? producto.lotes.map((lote, indexLote) => {
                                              return (
                                                <>
                                                  <tr key={lote}>
                                                    {!ordenCompraShowEdit.hiddenRecepcion &&
                                                    ordenCompraShowEdit.disabledRecepcion ? (
                                                      <>
                                                        <td>{lote.lote ? lote.lote : ""}</td>
                                                        <td>
                                                          <input
                                                            className="form-control"
                                                            type="date"
                                                            disabled={true}
                                                            value={lote.caducidad}
                                                          />
                                                        </td>
                                                        <td>{lote.piezasRecibidas ? lote.piezasRecibidas : ""}</td>
                                                      </>
                                                    ) : null}

                                                    {!ordenCompraShowEdit.hiddenRecepcion &&
                                                    !ordenCompraShowEdit.disabledRecepcion ? (
                                                      <>
                                                        <td>
                                                          <input
                                                            type="text"
                                                            onChange={(e) => changeLote(e, lote)}
                                                            className="form-control"
                                                            value={lote.lote ? lote.lote : ""}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            className="form-control"
                                                            type="date"
                                                            onChange={(e) => changeCaducidadLote(e, lote)}
                                                            value={lote.caducidad}
                                                          />
                                                        </td>
                                                        <td>
                                                          <input
                                                            type="number"
                                                            className="form-control"
                                                            value={lote.piezasRecibidas ? lote.piezasRecibidas : ""}
                                                            onChange={(e) => changePiezasRecibidasLote(e, producto, lote)}
                                                            min={1}
                                                            onWheel={(e) => e.target.blur()}
                                                          />
                                                        </td>
                                                        <td>
                                                          <button
                                                            type="button"
                                                            onClick={() => {
                                                              setLoteEliminar(lote);
                                                              setProductoEliminar(producto);
                                                              setUISweetAlert({
                                                                ...UISweetAlert,
                                                                confirm_alert_eliminar_lote: true,
                                                              });
                                                            }}
                                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light flex  hover:text-white">
                                                            Eliminar
                                                          </button>
                                                        </td>
                                                      </>
                                                    ) : null}

                                                    {!ordenCompraShowEdit.hiddenRecibida ? (
                                                      <>
                                                        <td>{lote.fechaCreacion ? lote.fechaCreacion : ""}</td>
                                                        <td>{lote.idUsuario ? lote.idUsuario : ""}</td>
                                                      </>
                                                    ) : null}
                                                  </tr>
                                                </>
                                              );
                                            })
                                          : null}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mt-10">
        {/* TODO: */}
         <CardBody>
            {!ordenCompraShowEdit.hiddenRecepcion && !ordenCompraShowEdit.disabledRecepcion ? (
            <Row className="form-group mt-8">
              <Col>
                   <button
                          className="btn btn-primary-orange-umo w-md mb-4 waves-effect waves-light"
                          type="button"
                          onClick={agregarArchivo}>
                          Agregar Factura / Documento
                   </button>                                                    
              </Col>               
            </Row>
            ):null}
            <Row>
            <Col>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Factura / Documento</th>
                      <th scope="col">Nombre Archivo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordenCompraSeleccionada && ordenCompraSeleccionada.ordencompraproducto
                      ? ordenCompraSeleccionada.archivos.map((producto, index) => {
                          return (
                            <>
                                <tr>
                                  <td>{producto.descripcion ? producto.descripcion : ""}</td>  
                                  <td>{producto.nombreArchivo ? producto.nombreArchivo : ""}</td>   
                                  <td>
                                     {producto.nuevo?(
                                      <button
                                          type="button"
                                          className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                          onClick={() => {
                                              setArchivoEliminar(producto);
                                              setPosicionArchivoEliminar(index);
                                              setUISweetAlert({
                                                  ...UISweetAlert,
                                                  confirm_alert_Eliminar_Archivo: true,
                                              });
                                          }}>
                                          Eliminar
                                      </button>  
                                    ):null}
                                  </td>                                                                                                      
                                </tr>   
                            </>
                          );
                        })
                      : null}                   
                  </tbody>
                </table>
              </div>
            </Col>              
            </Row>
         </CardBody>
       </Card>      

      <Card className="mt-4 w-full">
        <Row>
          <Col xl={6} lg={12} md={12}>
            <button
              type="submit"
              onClick={() =>
                verificaGuardado()

              }
              hidden={!ordenCompraShowEdit.guardarOrdenCompra}
              className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
              Guardar
            </button>

            <button
              type="submit"
              onClick={confirmGuardarRecepcionOrdenCompra}
              hidden={!ordenCompraShowEdit.guardarRecepcionOrdenCompra}
              className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
              Terminar Recepción
            </button>

            <button
              type="submit"
              onClick={() =>
                setUISweetAlert({
                  ...UISweetAlert,
                  confirm_alert_aprobar_pago: true,
                })
              }
              hidden={ocultarBotonAprobar? true:
                !ordenCompraShowEdit.aprobarPagoOrdenCompra ||
                (usuario.authorities.includes("ROLE_COMPRAS") &&
                  (!usuario.authorities.includes("ROLE_ADMIN_SISTEMA") || !usuario.authorities.includes("ROLE_CONTABILIDAD")))
              }
              className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
              Aprobar para Pago
            </button>
            <button
              type="submit"
              onClick={() =>
                setUISweetAlert({
                  ...UISweetAlert,
                  confirm_alert_solicitar_aprobacion: true,
                })
              }
              hidden={
                !ordenCompraShowEdit.aprobarPagoOrdenCompra ||
                usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                usuario.authorities.includes("ROLE_CONTABILIDAD")
              }
              className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
              Solicitar Aprobación para Pago
            </button>
          </Col>
          
          {/*<Col xl={6} lg={12} md={12}>
            {!ordenCompraShowEdit.hiddenArchivos && !ordenCompraShowEdit.disabledArchivos ? (
              <Row className="form-group mt-6">
                <label className="col-sm-4 col-form-label">Documentos del Proveedor</label>
                <Col sm={7}>
                  <input className="form-control" type="file" onChange={(e) => uploadFile(e)} multiple />
                </Col>
              </Row>
            ) : null}
          </Col>*/}

        </Row>
        {!ordenCompraShowEdit.hiddenArchivos && ordenCompraShowEdit.disabledArchivos ? (
          <Row>
            <Col>
              <h3 className="flex h3 mt-6">Documentos del Proveedor</h3>
              <Row className="form-group">
                <Col>
                  {ordenCompraSeleccionada.archivos &&
                    ordenCompraSeleccionada.archivos.map((archivo) => {
                      return (
                        <>
                          <button
                            type="button"
                            onClick={() => exportArchivo(archivo)}
                            className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                            {archivo.nombreArchivo}
                          </button>
                        </>
                      );
                    })}
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
      </Card>

      {UISweetAlert.confirm_alert_guardar_completo ? (
        <SweetAlert
          title="Los productos están completos, desea terminar la recepción de la orden?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadRecepcionCompletaOrdenCompra()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar_completo: false,
            })
          }>
          Se guardarán todos los cambios realizados en la orden y se continuará con el proceso
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_guardar_incompleto ? (
        <SweetAlert
          title="Faltan productos por recibir, desea hacer una recepción parcialmente?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadRecepcionIncompletaOrdenCompra()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar_incompleto: false,
            })
          }>
          Se guardarán todos los cambios realizados en la orden y se continuará con el proceso
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_guardar_orden ? (
        <SweetAlert
          title="¿Desea guardar los cambios realizados?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadOrdenCompra()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar_orden: false,
            });
          }}>
          Se guardarán todos los cambios realizados en la orden
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_aprobar_pago ? (
        <SweetAlert
          title="¿Desea aprobar la orden de compra para realizar los pagos?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadAprobarPagoOrdenCompra()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_aprobar_pago: false,
            });
          }}>
          Se guardarán todos los cambios realizados en la orden y se continuará con el proceso
        </SweetAlert>
      ) : null}
      {UISweetAlert.confirm_alert_solicitar_aprobacion ? (
        <SweetAlert
          title="¿Desea solicitar la aprobación para pago de la orden de compra?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Rechazar"
          cancelBtnBsStyle="danger"
          onConfirm={() => confirmSolicitarAprobacionPago()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_solicitar_aprobacion: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_eliminar_lote ? (
        <SweetAlert
          title="¿Desea eliminar el lote?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarLoteProducto()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_eliminar_lote: false,
            });
          }}>
          Se perderán todos los cambios realizados en el lote
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_Eliminar_Archivo ? (
        <SweetAlert
          title="¿Desea eliminar el archivo?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarArchivo()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_Eliminar_Archivo: false,
            });
          }}>          
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_eliminar_producto ? (
        <SweetAlert
          title="¿Desea eliminar el producto?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarProducto()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_eliminar_producto: false,
            });
          }}>
          Se perderán todos los cambios realizados en el lote
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_fecha ? (
        <SweetAlert
          title="¿La fecha de caducidad de los lotes es la correcta?"
          warning
          showCancel
          confirmBtnText="Si"
          confirmBtnBsStyle="success"
          cancelBtnText="No"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadRecepcionOrdenCompra()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_fecha: false,
            });
          }}></SweetAlert>
      ) : null}

      {/* TODO MENSAJE*/}
      {UISweetAlert.confirm_alert_Agregar_Archivo ? (
        <SweetAlert
          title="Inserte la factura y el archivo correspondiente"
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          onConfirm={() => uploadFile()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_Agregar_Archivo: false,
            });
          }}>
          <div>

            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Factura</label>
              <Col sm={7}>
                <textarea
                  rows={1}
                  className="form-control"
                  name="factura"
                  value={factura}
                  onChange={onChangeFactura}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Agregar archivo</label>
              <Col sm={7}>
                 <input className="form-control" type="file" onChange={(e) => seleccionarArchivo(e)} multiple = {false} />
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_cancelar_orden_compra ? (
        <SweetAlert
          title="¿Desea cancelar la orden de compra?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          onConfirm={() => ordenCompraCancelar()}
          onCancel={() => {
            setCancelarOrdenCompra({
              ...cancelarOrdenCompra,
              comentarioEstatus: "",
            });
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_cancelar_orden_compra: false,
            });
          }}>
          <div>
            <Row>
              <Col>Llene por favor la siguiente información</Col>
            </Row>

            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Motivo</label>
              <Col sm={7}>
                <textarea
                  rows={6}
                  className="form-control"
                  name="comentarioEstatus"
                  value={cancelarOrdenCompra.comentarioEstatus}
                  onChange={onChangeCancelarOrdenCompra}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Fecha Cancelación</label>
              <Col sm={7}>
                <input
                  type="date"
                  className="form-control"
                  disabled={true}
                  name="fechaCancelacion"
                  value={cancelarOrdenCompra.fechaCancelacion}
                />
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
