import React, { useState, useContext,useEffect, useCallback, useMemo, useRef } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import FabricantesContext from "../../context/Fabricantes/FabricantesContext";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";
import CargaMasivaModal from "../CargaMasivaModal/index";

import formatNumber from "../../utils/index";

import useWindowDimensions from "../../hooks/dimensionsHook";

import AuthContext from "../../context/Auth/AuthContext";
import { maxCampoNumIdentificacion} from "../../config/variables";

const Medicamentos = () => {
  const { width } = useWindowDimensions();
  const filtrosVacios = {
    numIdentificacion: "",
    codigoEAN: "",
    nombreComercial: "",
    principioActivo: "",
    proveedor: "",
    laboratorio: "",
  };

  const datosMedicamentoVacio = {
    numIdentificacion: "",
    codigoEAN: "",
    nombreComercial: "",
    principioActivo: "",
    concentracion: "",
    presentacion:"",
    idCatProveedor: "",
    idCatLaboratorio: "",
    precioPublico: 0,
    precioMostrador: 0,
    precioAseguradoras: 0,
    claveSat: "",
    unidadSat: "",
    claveUnidadSat:"",
  };

  const [datosNuevoMedicamento, setDatosNuevoMedicamento] = useState(datosMedicamentoVacio);
  const [medicamentoSeleccionado, setMedicamentoSeleccionado] = useState(null);
  const [medicamentoLog, setMedicamentoLog] = useState(null);
  const [medicamentoPreciosActualizados, setMedicamentoPreciosActualizados] = useState({ preciosAseguradora: [] });
  const [precioMostradorPublicoLog, setPrecioMostradorPublicoLog] = useState({});
  const [nuevoPrecio, setNuevoPrecio] = useState(null);
  const [medicamentoSeleccionadoPrecios, setMedicamentoSeleccionadoPrecios] = useState(null);
  const [preciosAsguradorasLog, setPreciosAsguradorasLog] = useState({});
  const [rowSeleccionada, setRowSeleccionada] = useState(null);
  const [pivoteCambio, setPivoteCambio] = useState(false);
  const[comentariosGlobal,setComentariosGlobal] = useState("");
  const[inventario,setInventario] = useState("");

  const ctxEstatusVale = useContext(EstatusValeContext);
  const {traerExistenciaProducto} = ctxEstatusVale;


  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_medicamento: false,
    confirm_nuevo_medicamento: false,
    confirm_eliminar_medicamento: false,
    confirm_aprobar_eliminar_medicamento: false,
    confirm_modificar_precios: false,
    confirm_modificar_precios_final: false,
    confirm_carga_masiva: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);

  const fetchIdRef = useRef(0);

  const catProductosContext = useContext(CatProductosContext);
  const proveedoresContext = useContext(ProveedoresContext);
  const fabricantesContext = useContext(FabricantesContext);

  const {
    filtrosProductos,
    productosPaginados,
    setDataProductos,
    setFiltrosProductos,
    uploadNuevoProducto,
    eliminarProducto,
    eliminarMedicamento,
    updateProducto,
    actualizarPreciosProducto,
    actualizarMedicamentosVo,
    listarPreciosProducto,
    preciosProducto,
  } = catProductosContext;

  const { listarProveedores, proveedores } = proveedoresContext;

  const { listarFabricantes, fabricantes } = fabricantesContext;

  
  
  useEffect(() => {
    const fetchContextData = () => {
      listarProveedores();
      listarFabricantes();
    };
    fetchContextData();
  }, []);

  const onChangePivoteCambio = async (e) => {
    let temp ;
    if(pivoteCambio== false){
        temp = true;
    } else{
        temp = false;
    }
    setPivoteCambio(temp)
  };

  const changeComentario = (e) => {
    const value = e.target.value
    setComentariosGlobal(value);
  };

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setDataProductos({ page, limit, idCatTipoProducto: 2 });

      // Cantidad de datos disponibles en la tabla pacientes de la base de datos
      const count = response.count;

      // setData(response.productos);

      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
    // setLoadingData(true);
    // fetchAPIData({
    //   page: pageIndex + 1,
    //   limit: pageSize,
    //   byBusquedaFiltros: false,
    // });
  };

  const handdleCheckedMedicamento = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setMedicamentoSeleccionado({
          ...row.original,
          idCatProveedor: row.values["catProveedorVo.idCatProveedor"],
          idCatLaboratorio: row.values["catLaboratoriosVo.idCatLaboratorio"],
        });
        setMedicamentoLog({
          ...row.original,
          idCatProveedor: row.values["catProveedorVo.idCatProveedor"],
          idCatLaboratorio: row.values["catLaboratoriosVo.idCatLaboratorio"],
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setMedicamentoSeleccionado(null);
      } else {
        setRowSeleccionada(row.id);
        setMedicamentoSeleccionado({
          ...row.original,
          idCatProveedor: row.values["catProveedorVo.idCatProveedor"],
          idCatLaboratorio: row.values["catLaboratoriosVo.idCatLaboratorio"],
        });
        setMedicamentoLog({
          ...row.original,
          idCatProveedor: row.values["catProveedorVo.idCatProveedor"],
          idCatLaboratorio: row.values["catLaboratoriosVo.idCatLaboratorio"],
        });
      }
    },
    [rowSeleccionada]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCatProducto",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              name="checkBoxMed"
              onChange={() => handdleCheckedMedicamento(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Identificación", accessor: "numIdentificacion" },
      { Header: "Código EAN", accessor: "codigoEAN" },
      { Header: "Nombre Comercial", accessor: "nombreComercial" },
      { Header: "Principio Activo", accessor: "principioActivo" },
      { Header: "Concentración", accessor: "concentracion" },
      { Header: "idProveedor", accessor: "catProveedorVo.idCatProveedor" },
      { Header: "Proveedor", accessor: "catProveedorVo.proveedor" },
      { Header: "idCatFabricante", accessor: "catLaboratoriosVo.idCatLaboratorio" },
      { Header: "Fabricante", accessor: "catLaboratoriosVo.laboratorio" },      
      {
        Header: "Estatus actual",
        accessor: "eliminacionEstatus",
        Cell: ({ row }) => {
          return autorizadoSinPrecios && row.original.eliminacionEstatus ==1 && administrador? (
            <button
              className="btn btn-primary-red-umo w-md waves-effect waves-light"
              onClick={() => {
                let cadenaLogLocal = "";

                const fetchAInventario = async () => {
                  const productosTemp = await traerExistenciaProducto(row.original.idCatProducto);
            
                  productosTemp.map((producto) => {
            
                    if(producto.lote != ""){
                      cadenaLogLocal = cadenaLogLocal + "  [ Lote: " + producto.lote + " - Cantidad: " + producto.piezas +"]  ";
                    }
                  });
                  setInventario(cadenaLogLocal);
                };
                           
                setComentariosGlobal(row.original.justificacion);
                setRowSeleccionada(row.id);
                setMedicamentoSeleccionado({
                  ...row.original,
                  idCatProveedor: row.values["catProveedorVo.idCatProveedor"],
                  idCatLaboratorio: row.values["catLaboratoriosVo.idCatLaboratorio"],
                });

                fetchAInventario();

                setUISweetAlert({
                  ...UISweetAlert,
                  confirm_aprobar_eliminar_medicamento: true,
                });
                
              }}                
            >
              Aprobar eliminación
            </button>
          ) : null;
        },

        disableFilters: true,
        disableSortBy: true,
      },      
      {
        Header: "Precios",
        accessor: "precios",
        Cell: ({ row }) => {
          return autorizadoSinPrecios ? (
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              onClick={() => handdlePrecios(row.original)}>
              Precios
            </button>
          ) : null;
        },

        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [rowSeleccionada, handdleCheckedMedicamento]
  );

  const handdlePrecios = async (medicamento) => {
    const response = await listarPreciosProducto(medicamento.idCatProducto);
    setPreciosAsguradorasLog(response.preciosAseguradora)
    setPrecioMostradorPublicoLog(response.preciosProductoServicio);
    setMedicamentoSeleccionadoPrecios({
      ...response,
      idCatProducto: medicamento.idCatProducto,
      numIdentificacion: medicamento.numIdentificacion,
      nombreComercial: medicamento.nombreComercial,
      principioActivo: medicamento.principioActivo,
    });

    setMedicamentoPreciosActualizados({
      ...response,
      idCatProducto: medicamento.idCatProducto,
      numIdentificacion: medicamento.numIdentificacion,
      nombreComercial: medicamento.nombreComercial,
      principioActivo: medicamento.principioActivo,
    });

    if (response.preciosProductoServicio || response.preciosAseguradora.length > 0) {
      setUISweetAlert({
        confirm_modificar_medicamento: false,
        confirm_nuevo_medicamento: false,
        confirm_eliminar_medicamento: false,
        confirm_modificar_precios: true,
        confirm_modificar_precios_final: false,
        confirm_carga_masiva: false,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_medicamento: false,
        confirm_nuevo_medicamento: false,
        confirm_eliminar_medicamento: false,
        confirm_modificar_precios: false,
        confirm_modificar_precios_final: false,
        confirm_carga_masiva: true,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
      });
    }
  };

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  const autorizado =
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_CREAR") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_MODIFICAR") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_MODIFICAR_PRECIO") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_ELIMINAR") ||
    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
    usuario.authorities.includes("ROLE_FARMACIA") ||
    usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
    usuario.authorities.includes("ROLE_COMPRAS");

  const autorizadoSinPrecios =
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_CREAR") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_MODIFICAR") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_MODIFICAR_PRECIO") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICAMENTO_ELIMINAR") ||
    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
    usuario.authorities.includes("ROLE_FARMACIA") ||
    usuario.authorities.includes("ROLE_COMPRAS"); 

    const administrador = usuario.authorities.includes("ROLE_ADMIN_SISTEMA");

    const editarIdentificacion =  usuario.authorities.includes("ROLE_COMPRAS");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function setDataProductos
     * @param {object} filtrosProductos Filtros enviados al back para filtrar los productos
     * @param {int} page Página a regresar de pacientes
     * @param {int} limit Número de pacientes que se van a regresar por llamada al back
     */

    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosProductos(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltrosProductos({
      ...filtrosProductos,
      [name]: value,
    });
  };

  const changeNuevoMedicamento = (e) => {
    const { name, value } = e.target;
    const camposBoolean = [
      "centroMezclas",
      "refrigerar",
      "fotosensibleAlmacenamiento",
      "fotosensibleAplicacion",
      "altoRiesgo",
      "controlado",
      "fotosensible",
      "noAgitar",
    ];
    if (camposBoolean.includes(name)) {
      setDatosNuevoMedicamento({
        ...datosNuevoMedicamento,
        [name]: e.target.checked,
      });
    } else {
      setDatosNuevoMedicamento({
        ...datosNuevoMedicamento,
        [name]: value,
      });
    }
  };

  const changeModificarMedicamento = (e) => {
    const { name, value } = e.target;
    const camposBoolean = [
      "centroMezclas",
      "refrigerar",
      "fotosensibleAlmacenamiento",
      "fotosensibleAplicacion",
      "altoRiesgo",
      "controlado",
      "fotosensible",
      "noAgitar",
    ];
    
    if (camposBoolean.includes(name)) {
      setMedicamentoSeleccionado({
        ...medicamentoSeleccionado,
        [name]: e.target.checked,
      });
    } else {
      setMedicamentoSeleccionado({
        ...medicamentoSeleccionado,
        [name]: value,
      });
    }
  };

  const opcionesProveedor = proveedores.map(({ idCatProveedor, proveedor, ...rest }) => ({
    ...rest,
    id: idCatProveedor,
    nombre: proveedor,
  }));

  const opcionesFabricante = fabricantes.map(({ idCatLaboratorio, laboratorio, ...rest }) => ({
    ...rest,
    id: idCatLaboratorio,
    nombre: laboratorio,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Identificacion",
          type: "text",
          name: "numIdentificacion",
          value: filtrosProductos.numIdentificacion,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Código EAN",
          type: "text",
          name: "codigoEAN",
          value: filtrosProductos.codigoEAN,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Nombre Comercial",
          type: "text",
          name: "nombreComercial",
          value: filtrosProductos.nombreComercial,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Principio Activo",
          type: "text",
          name: "principioActivo",
          value: filtrosProductos.principioActivo,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Proveedor",
          name: "proveedor",
          value: filtrosProductos.proveedor,
          opciones: opcionesProveedor,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Fabricante",
          name: "laboratorio",
          value: filtrosProductos.laboratorio,
          opciones: opcionesFabricante,
          onChange: onChange,
        },
      ],
    },
  ];

  const validarDatosMedicamento = (medicamento) => {
    let errorNuevoMedicamento = false;
    if (!errorNuevoMedicamento && (medicamento.numIdentificacion === "" || medicamento.numIdentificacion === null)) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el número de identificación",
      });
    }
    if (!errorNuevoMedicamento && (medicamento.nombreComercial === "" || medicamento.nombreComercial === null)) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el nombre comercial",
      });
    }
    if (!errorNuevoMedicamento && (medicamento.principioActivo === "" || medicamento.principioActivo === null)) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el principio activo",
      });
    }
    if (!errorNuevoMedicamento && (medicamento.concentracion === "" || medicamento.concentracion === null)) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la concentración",
      });
    }

    return errorNuevoMedicamento;
  };

  const validarPreciosProducto = (producto) => {
    let errorNuevoMedicamento = false;
    if (!errorNuevoMedicamento && producto.precioPublico === null) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el precio máximo al público del medicamento",
      });
    }
    if (!errorNuevoMedicamento && producto.precioMostrador === null) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el precio mostrador y médico del medicamento",
      });
    }
    if (!errorNuevoMedicamento && producto.precioAseguradoras === null) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el precio para aseguradoras del medicamento",
      });
    }
    if (
      !errorNuevoMedicamento &&
      producto.precioAseguradoras !== null &&
      producto.precioMostrador !== null &&
      producto.precioPublico !== null
    ) {
      if (parseFloat(producto.precioMostrador) > parseFloat(producto.precioPublico)) {
        errorNuevoMedicamento = true;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "El precio mostrador no puede ser mayor al precio máximo al público del medicamento",
        });
      }
      if (parseFloat(producto.precioAseguradoras) > parseFloat(producto.precioPublico)) {
        errorNuevoMedicamento = true;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "El precio de aseguradoras no puede ser mayor al precio máximo al público del medicamento",
        });
      }
    }
    return errorNuevoMedicamento;
  };

  const confirmNuevoMedicamento = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nuevo_medicamento: true,
      });
    }
  };

  const agregarNuevoMedicamento = async () => {
    const medicamentoValido = validarDatosMedicamento(datosNuevoMedicamento);
    let preciosValidos = true;
    if (!medicamentoValido) preciosValidos = validarPreciosProducto(datosNuevoMedicamento);

    if(datosNuevoMedicamento.claveUnidadSat =="H87"){
      datosNuevoMedicamento.unidadSat = "PIEZA"
    }

    if (!medicamentoValido && !preciosValidos) {

      let cadenaLogLocal = ""
      cadenaLogLocal = cadenaLogLocal+ "Nuevo:ID[@@],Medicamento[" + datosNuevoMedicamento.nombreComercial + "]";

	    datosNuevoMedicamento.logVo = cadenaLogLocal.length==0? null:{
        idUsuario: usuario.idUsuario,
        modulo: "Medicamentos",
        actividad:cadenaLogLocal,
      };

      setLoading(true);
      const res = await uploadNuevoProducto({ ...datosNuevoMedicamento, idCatTipoProducto: 2 });
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nuevo_medicamento: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
        setDatosNuevoMedicamento(datosMedicamentoVacio);
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nuevo_medicamento: false,
          dynamic_description: "Se guardó el nuevo medicamento correctamente",
        });
        // setData([...data, res.data]);
        // fetchData({ pageSize: 10, pageIndex: 0, byBusquedaFiltros: true });
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setDatosNuevoMedicamento(datosMedicamentoVacio);
      }
    }
  };

  // const updateDataOnDeleteMedicamento = async (idCatProducto) => {
  //   const updatedData = data.filter((medicamento) => medicamento.idCatProducto != idCatProducto);
  //   actualizarProductosPaginados(updatedData);
  // };

  const checkEliminarMedicamento = () => {
    let cadenaLogLocal = "";

    if (autorizado && medicamentoSeleccionado) {

      const fetchAInventario = async () => {
       const productosTemp = await traerExistenciaProducto(medicamentoSeleccionado.idCatProducto);
  
        productosTemp.map((producto) => {
  
          if(producto.lote != ""){
            cadenaLogLocal = cadenaLogLocal + "  [ Lote: " + producto.lote + " - Cantidad: " + producto.piezas +"]  ";
          }
        });
        setInventario(cadenaLogLocal);
    };

    fetchAInventario();

    setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_medicamento: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un medicamento",
      });
    }
  };
 
  const apruebaEliminarMedicamento = async () => {
    setLoading(true);

    let cadenaLogLocal = ""
    let folio = medicamentoSeleccionado.idCatProducto;
    cadenaLogLocal = cadenaLogLocal+ "AprobacionEliminar:ID[" +  folio +"],Medicamento[" + medicamentoSeleccionado.nombreComercial + "] - Justificación" + comentariosGlobal;

    medicamentoSeleccionado.logVo = cadenaLogLocal.length==0? null:{
      idUsuario: usuario.idUsuario,
      modulo: "Medicamentos",
      actividad:cadenaLogLocal,
    };

    let estatusEnvio = 0;
    if( pivoteCambio== true){
      estatusEnvio = 2;
    }else{
      estatusEnvio = 3;
    }

    const res = await eliminarProducto(medicamentoSeleccionado.idCatProducto,estatusEnvio,comentariosGlobal);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_aprobar_eliminar_medicamento: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_aprobar_eliminar_medicamento: false,
        dynamic_description: "Se ha notificado su decisión correctamente",
      });

      const res2 = await actualizarMedicamentosVo(medicamentoSeleccionado.logVo);
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setMedicamentoSeleccionado(null);
      setRowSeleccionada(null);
    }
  };

  const confirmEliminarMedicamento = async () => {
    setLoading(true);

    let cadenaLogLocal = ""
    let folio = medicamentoSeleccionado.idCatProducto;
    cadenaLogLocal = cadenaLogLocal+ "SolicitaEliminar:ID[" +  folio +"],Medicamento[" + medicamentoSeleccionado.nombreComercial + "] - Justificación" + comentariosGlobal;

    medicamentoSeleccionado.logVo = cadenaLogLocal.length==0? null:{
      idUsuario: usuario.idUsuario,
      modulo: "Medicamentos",
      actividad:cadenaLogLocal,
    };

    const res = await eliminarMedicamento(medicamentoSeleccionado.idCatProducto,comentariosGlobal);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_medicamento: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_medicamento: false,
        dynamic_description: "Se solicitó eliminar el medicamento correctamente",
      });

      const res2 = await actualizarMedicamentosVo(medicamentoSeleccionado.logVo);
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setMedicamentoSeleccionado(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarMedicamento = () => {
    if (autorizado && medicamentoSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_medicamento: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un medicamento",
      });
    }
  };

  const confirmModicarMedicamento = async () => {
    const medicamentoValido = validarDatosMedicamento(medicamentoSeleccionado);

    if (!medicamentoValido) {
      setLoading(true);

      if(medicamentoSeleccionado.claveUnidadSat =="H87"){
        medicamentoSeleccionado.unidadSat = "PIEZA"
      }

      let cadenaLogLocal = ""
      let folio = medicamentoSeleccionado.idCatProducto;
      cadenaLogLocal = cadenaLogLocal+ "Actualización:ID[" + folio + "]";

      if(medicamentoSeleccionado.numIdentificacion != medicamentoLog.numIdentificacion){
        cadenaLogLocal = cadenaLogLocal+ ",NI[" + medicamentoLog.numIdentificacion + "->" + medicamentoSeleccionado.numIdentificacion +"]";
      }

      if(medicamentoSeleccionado.codigoEAN != medicamentoLog.codigoEAN){
        cadenaLogLocal = cadenaLogLocal+ ",EAN[" + medicamentoLog.codigoEAN + "->" + medicamentoSeleccionado.codigoEAN +"]";
      }
    
      if(medicamentoSeleccionado.nombreComercial != medicamentoLog.nombreComercial){
        cadenaLogLocal = cadenaLogLocal+ ",NC[" + medicamentoLog.nombreComercial + "->" + medicamentoSeleccionado.nombreComercial +"]";
      }  
      
      if(medicamentoSeleccionado.principioActivo != medicamentoLog.principioActivo){
        cadenaLogLocal = cadenaLogLocal+ ",PA[" + medicamentoLog.principioActivo + "->" + medicamentoSeleccionado.principioActivo +"]";
      }   

      if(medicamentoSeleccionado.presentacion != medicamentoLog.presentacion){
        cadenaLogLocal = cadenaLogLocal+ ",PRE[" + medicamentoLog.presentacion + "->" + medicamentoSeleccionado.presentacion +"]";
      }  
      
      if(medicamentoSeleccionado.concentracion != medicamentoLog.concentracion){
        cadenaLogLocal = cadenaLogLocal+ ",CON[" + medicamentoLog.concentracion + "->" + medicamentoSeleccionado.concentracion +"]";
      }  

      if(medicamentoSeleccionado.idCatProveedor != medicamentoLog.idCatProveedor){
        cadenaLogLocal = cadenaLogLocal+ ",PROV[" + medicamentoLog.idCatProveedor + "->" + medicamentoSeleccionado.idCatProveedor +"]";
      }  

      if(medicamentoSeleccionado.idCatLaboratorio != medicamentoLog.idCatLaboratorio){
        cadenaLogLocal = cadenaLogLocal+ ",FAB[" + medicamentoLog.idCatLaboratorio + "->" + medicamentoSeleccionado.idCatLaboratorio +"]";
      }  

      if(medicamentoSeleccionado.claveSat != medicamentoLog.claveSat){
        cadenaLogLocal = cadenaLogLocal+ ",CSAT[" + medicamentoLog.claveSat + "->" + medicamentoSeleccionado.claveSat +"]";
      }  

      if(medicamentoSeleccionado.claveSat != medicamentoLog.claveSat){
        cadenaLogLocal = cadenaLogLocal+ ",USAT[" + medicamentoLog.claveSat + "->" + medicamentoSeleccionado.claveSat +"]";
      }  

      if(medicamentoSeleccionado.centroMezclas != medicamentoLog.centroMezclas){
        cadenaLogLocal = cadenaLogLocal+ ",CM[" + medicamentoLog.centroMezclas + "->" + medicamentoSeleccionado.centroMezclas +"]";
      }  

      medicamentoSeleccionado.logVo = cadenaLogLocal.length==0? null:{
        idUsuario: usuario.idUsuario,
        modulo: "Medicamentoss",
        actividad:cadenaLogLocal,
      };

      const res = await updateProducto(medicamentoSeleccionado);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_medicamento: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_medicamento: false,
          dynamic_description: "Se modificó el medicamento correctamente",
        });

        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setMedicamentoSeleccionado(null);
        setRowSeleccionada(null);
      }
    }
  };

  const changeNuevoPrecio = (e) => {
    const { value } = e.target;
    setNuevoPrecio(value);
  };

  const setNuevoPrecioMedicamento = (precioActual) => {
    if (nuevoPrecio === null || nuevoPrecio == "") return;

    let aseguradoraModificada = preciosProducto.preciosAseguradora.filter(
      (aseguradora) => aseguradora.idPrecioAseguradora == precioActual.idPrecioAseguradora
    );

    aseguradoraModificada = aseguradoraModificada[0];
    aseguradoraModificada = {
      ...aseguradoraModificada,
      precioUnitario: nuevoPrecio,
      idCatProducto: medicamentoSeleccionadoPrecios.idCatProducto,
    };

    delete aseguradoraModificada.idCatproducto;
    delete aseguradoraModificada.idCatservicio;
    let arrayPreciosAseguradora = medicamentoPreciosActualizados.preciosAseguradora;
    arrayPreciosAseguradora = arrayPreciosAseguradora.map((aseguradora) => {
      if (aseguradora.idPrecioAseguradora == aseguradoraModificada.idPrecioAseguradora) {
        aseguradora = aseguradoraModificada;
        return aseguradora;
      } else {
        return aseguradora;
      }
    });

    setMedicamentoPreciosActualizados({
      ...medicamentoPreciosActualizados,
      preciosAseguradora: arrayPreciosAseguradora,
    });
    setNuevoPrecio(null);
  };

  const setNuevoPrecioMedicamentoProductoServicio = ({ tipo }) => {
    if (nuevoPrecio === null || nuevoPrecio == "") return;
    const updatedPrecios = {
      ...medicamentoPreciosActualizados.preciosProductoServicio,
      [tipo]: nuevoPrecio,
    };

    setMedicamentoPreciosActualizados({ ...medicamentoPreciosActualizados, preciosProductoServicio: updatedPrecios });

    setNuevoPrecio(null);
  };

  const guardarPreciosMedicamento = async () => {
    if (
      medicamentoPreciosActualizados.preciosAseguradora.length < 1 &&
      medicamentoPreciosActualizados.preciosProductoServicio === undefined
    )
      return;

    setLoading(true);

    let cadenaLogLocal = ""
    let folio = medicamentoPreciosActualizados.idCatProducto;
    cadenaLogLocal = cadenaLogLocal+ "Modificar Precio:ID[" +  folio +"],Medicamento[" + medicamentoPreciosActualizados.nombreComercial + "]";

    if(precioMostradorPublicoLog.precioMostrador != medicamentoPreciosActualizados.preciosProductoServicio.precioMostrador){
      cadenaLogLocal = cadenaLogLocal + ",$PM[" + precioMostradorPublicoLog.precioMostrador +"->" + medicamentoPreciosActualizados.preciosProductoServicio.precioMostrador+"]"
    }

    if(precioMostradorPublicoLog.precioPublico != medicamentoPreciosActualizados.preciosProductoServicio.precioPublico){
      cadenaLogLocal = cadenaLogLocal + ",$PP[" + precioMostradorPublicoLog.precioPublico +"->" + medicamentoPreciosActualizados.preciosProductoServicio.precioPublico+"]"
    }

    let contador = 0;
    preciosAsguradorasLog.map((proveedor) => {

      if(proveedor.precioUnitario != medicamentoPreciosActualizados.preciosAseguradora[contador].precioUnitario){
          cadenaLogLocal = cadenaLogLocal + "," + proveedor.aseguradora + "[" + proveedor.precioUnitario +"->" + medicamentoPreciosActualizados.preciosAseguradora[contador].precioUnitario+"]"
      }
      contador ++ ;
    })

    medicamentoPreciosActualizados.logVo = cadenaLogLocal.length==0? null:{
      idUsuario: usuario.idUsuario,
      modulo: "Medicamentos",
      actividad:cadenaLogLocal,
    };

     const res = await actualizarPreciosProducto(medicamentoPreciosActualizados);
   
    if (res.data && res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_modificar_precios: false,
        confirm_modificar_precios_final: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
      setMedicamentoSeleccionadoPrecios(null);
      setMedicamentoPreciosActualizados({ preciosAseguradora: [] });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_modificar_precios: false,
        confirm_modificar_precios_final: false,
        dynamic_description: "Se guardaron los precios correctamente",
      });
      // const productosFiltrados = productosPaginados.map((producto) => {
      //   if (producto.idCatProducto != medicamentoPreciosActualizados.idCatProducto) return producto;
      //   return medicamentoPreciosActualizados;
      // });
      // actualizarProductosPaginados(productosFiltrados);
      const res2 = await actualizarMedicamentosVo(medicamentoPreciosActualizados.logVo);
      setMedicamentoSeleccionadoPrecios(null);
      setMedicamentoPreciosActualizados({ preciosAseguradora: [] });
    }    
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Catálogo de Medicamentos</h3>
      {autorizadoSinPrecios ? (
        // <Link to={`/carga/precios`}>
        //   <button className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white" type="button" onClick={() => setUISweetAlert({
        //       ...UISweetAlert,
        //       confirm_nuevo_medicamento: false,
        //     }) }>
        //     Carga Masiva de Precios
        //   </button>
        // </Link>
        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_carga_masiva: true,
            })
          }>
          Carga Masiva de Precios
        </button>
      ) : null}
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Medicamentos</h4>

      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevoMedicamento()}>
                      Nuevo Medicamento
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      id="modicarMed"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarMedicamento()}>
                      Modificar Medicamento
                    </button>
                  ) : null}
                  {editarIdentificacion ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarMedicamento()}>
                      Eliminar Medicamento
                    </button>
                  ) : null}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={productosPaginados}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {UISweetAlert.confirm_nuevo_medicamento ? (
        <SweetAlert
          title="Nuevo Medicamento"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            agregarNuevoMedicamento();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nuevo_medicamento: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody className="mt-3">
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">
                    Número de Identificación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numIdentificacion"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.numIdentificacion ? datosNuevoMedicamento.numIdentificacion : ""}
                    />
                    {datosNuevoMedicamento.numIdentificacion === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-5 col-form-label">Código EAN</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="codigoEAN"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.codigoEAN ? datosNuevoMedicamento.codigoEAN : ""}
                    />
                    {/* {datosNuevoMedicamento.codigoEAN === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )} */}
                  </Col>
                  <label className="col-sm-5 col-form-label">
                    Nombre Comercial<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombreComercial"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.nombreComercial ? datosNuevoMedicamento.nombreComercial : ""}
                    />
                    {datosNuevoMedicamento.nombreComercial === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-5 col-form-label">
                    Principio Activo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="principioActivo"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.principioActivo ? datosNuevoMedicamento.principioActivo : ""}
                    />
                    {datosNuevoMedicamento.principioActivo === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-5 col-form-label">
                    Presentación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="presentacion"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.presentacion ? datosNuevoMedicamento.presentacion : ""}
                    />
                    {datosNuevoMedicamento.presentacion === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>                  
                  <label className="col-sm-5 col-form-label">
                    Concentración<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="concentracion"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.concentracion ? datosNuevoMedicamento.concentracion : ""}
                    />
                    {datosNuevoMedicamento.concentracion === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-5 col-form-label">
                    Precio Máximo al Público<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="number"
                      className="form-control"
                      name="precioPublico"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.precioPublico ? datosNuevoMedicamento.precioPublico : null}
                    />
                    {datosNuevoMedicamento.precioPublico == "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-5 col-form-label">
                    Precio Aseguradoras<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="number"
                      className="form-control"
                      name="precioAseguradoras"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.precioAseguradoras ? datosNuevoMedicamento.precioAseguradoras : null}
                    />
                    {datosNuevoMedicamento.precioAseguradoras == "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-5 col-form-label">
                    Precio Mostrador y Médico<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="number"
                      className="form-control"
                      name="precioMostrador"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.precioMostrador ? datosNuevoMedicamento.precioMostrador : null}
                    />
                    {datosNuevoMedicamento.precioMostrador == "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-5 col-form-label">Proveedor</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatProveedor"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.idCatProveedor}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesProveedor.map((proveedor) => {
                        return <option value={proveedor.id}>{proveedor.nombre}</option>;
                      })}
                    </select>
                    {/* {datosNuevoMedicamento.idCatProveedor === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )} */}
                  </Col>
                  <label className="col-sm-5 col-form-label">Fabricante</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatLaboratorio"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.idCatLaboratorio}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesFabricante.map((fabricante) => {
                        return <option value={fabricante.id}>{fabricante.nombre}</option>;
                      })}
                    </select>
                  </Col>                                
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                <label className="col-sm-5 col-form-label">Clave Sat</label>
                    <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="claveSat"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.claveSat}
                    />
                  </Col>  
                  <label className="col-sm-5 col-form-label">Unidad Sat</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="claveUnidadSat"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.claveUnidadSat}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      <option   value="H87">H87 - PIEZA</option>
                    </select>
                  </Col>  

                  <label className="col-sm-5 col-form-label">Es para Centro de Mezclas</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="centroMezclas"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      checked={datosNuevoMedicamento.centroMezclas ? datosNuevoMedicamento.centroMezclas : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Reconstituido</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="reconstituido"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.reconstituido ? datosNuevoMedicamento.reconstituido : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Diluido</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="diluido"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      value={datosNuevoMedicamento.diluido ? datosNuevoMedicamento.diluido : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Fotosensible</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="fotosensible"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      checked={datosNuevoMedicamento.fotosensible ? datosNuevoMedicamento.fotosensible : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Fotosensible Almacenamiento</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="fotosensibleAlmacenamiento"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      checked={
                        datosNuevoMedicamento.fotosensibleAlmacenamiento
                          ? datosNuevoMedicamento.fotosensibleAlmacenamiento
                          : false
                      }
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Fotosensible Aplicación</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="fotosensibleAplicacion"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      checked={
                        datosNuevoMedicamento.fotosensibleAplicacion ? datosNuevoMedicamento.fotosensibleAplicacion : false
                      }
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Alto Riesgo</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="altoRiesgo"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      checked={datosNuevoMedicamento.altoRiesgo ? datosNuevoMedicamento.altoRiesgo : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Controlado</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="controlado"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      checked={datosNuevoMedicamento.controlado ? datosNuevoMedicamento.controlado : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">No Agitar</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="noAgitar"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      checked={datosNuevoMedicamento.noAgitar ? datosNuevoMedicamento.noAgitar : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Refrigerar</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="refrigerar"
                      autoComplete="off"
                      onChange={changeNuevoMedicamento}
                      checked={datosNuevoMedicamento.refrigerar ? datosNuevoMedicamento.refrigerar : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label"></label>
                  <Col sm={7}>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDatosNuevoMedicamento(datosMedicamentoVacio)}>
                      Limpiar Datos
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}
      
      {UISweetAlert.confirm_eliminar_medicamento ? (
        <SweetAlert
          title="¿Desea eliminar el medicamento seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: width && width < 851 ? "100%" : "50%",
          }}
          onConfirm={() => confirmEliminarMedicamento()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_medicamento: false,
            });
          }}>
          Por favor inserte la justificación, ya que el administrador del sistema tendrá que aprobar o rechazar la solicitud.
          <div className="mt-2">
              {/*<FormControlLabel
                  control={
                      <Switch checked={pivoteCambio} onChange={onChangePivoteCambio} name="decision" color="primary"/>
                  }
                  label={pivoteCambio ? "Aprobado" : "Rechazado"}
                />*/}
              <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Justificación</label>
                  <Col sm={7}>
                  <textarea
                      rows={5}
                      type="text"
                      className="form-control"
                      onChange={changeComentario}
                      value={comentariosGlobal}
                  />
                  </Col>
              </Row>

              <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Inventario Disponible</label>
                  <Col sm={7}>
                  {inventario}
                  </Col>                 
              </Row>

          </div> 
          </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_aprobar_eliminar_medicamento ? (
        <SweetAlert
          title="¿Aprueba eliminar el medicamento seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: width && width < 851 ? "100%" : "50%",
          }}
          onConfirm={() => apruebaEliminarMedicamento()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_aprobar_eliminar_medicamento: false,
            });
          }}>
          {medicamentoSeleccionado.nombreComercial}
          <div className="mt-2">
              <FormControlLabel
                  control={
                      <Switch checked={pivoteCambio} onChange={onChangePivoteCambio} name="decision" color="primary"/>
                  }
                  label={pivoteCambio ? "Aprobado" : "Rechazado"}
              />
              <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Justificación</label>
                  <Col sm={7}>
                  <textarea
                      rows={5}
                      type="text"
                      className="form-control"
                      onChange={changeComentario}                      
                      value={comentariosGlobal}
                  />
                  </Col>
              </Row>

              <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Inventario Disponible</label>
                  <Col sm={7}>
                  {inventario}
                  </Col>                 
              </Row>


          </div> 
          </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_medicamento ? (
        <SweetAlert
          title="Modificar Datos del Medicamento"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            confirmModicarMedicamento();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_medicamento: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">Número de Identificación</label>
                  <Col sm={7}>
                    <input
                      disabled={editarIdentificacion? false: true}
                      type="text"
                      className={editarIdentificacion? "form-control": "form-control-disabled"}
                      name="numIdentificacion"
                      autoComplete="off"
                      maxLength={maxCampoNumIdentificacion}
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.numIdentificacion ? medicamentoSeleccionado.numIdentificacion : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Código EAN</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="codigoEAN"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.codigoEAN ? medicamentoSeleccionado.codigoEAN : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Nombre Comercial</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombreComercial"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.nombreComercial ? medicamentoSeleccionado.nombreComercial : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Principio Activo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="principioActivo"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.principioActivo ? medicamentoSeleccionado.principioActivo : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Presentación</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="presentacion"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.presentacion ? medicamentoSeleccionado.presentacion : ""}
                    />
                  </Col>                  
                  <label className="col-sm-5 col-form-label">Concentración</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="concentracion"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.concentracion ? medicamentoSeleccionado.concentracion : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Proveedor</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatProveedor"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.idCatProveedor}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesProveedor.map((proveedor) => {
                        return <option value={proveedor.id}>{proveedor.nombre}</option>;
                      })}
                    </select>
                  </Col>
                  <label className="col-sm-5 col-form-label">Fabricante</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatLaboratorio"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.idCatLaboratorio}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesFabricante.map((fabricante) => {
                        return <option value={fabricante.id}>{fabricante.nombre}</option>;
                      })}
                    </select>
                  </Col>

                  <label className="col-sm-5 col-form-label">Clave Sat</label>
                    <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="claveSat"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.claveSat}
                    />
                  </Col>  

                  <label className="col-sm-5 col-form-label">Unidad Sat</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="claveUnidadSat"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.claveUnidadSat}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      <option   value="H87">H87 - PIEZA</option>
                    </select>
                  </Col>                       

                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">

                <label className="col-sm-5 col-form-label">Es para Centro de Mezclas</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="centroMezclas"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      checked={medicamentoSeleccionado.centroMezclas ? medicamentoSeleccionado.centroMezclas : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Reconstituido</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="reconstituido"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.reconstituido ? medicamentoSeleccionado.reconstituido : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Diluido</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="diluido"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      value={medicamentoSeleccionado.diluido ? medicamentoSeleccionado.diluido : ""}
                    />
                  </Col>                     
                  <label className="col-sm-5 col-form-label">Fotosensible</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="fotosensible"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      checked={medicamentoSeleccionado.fotosensible ? medicamentoSeleccionado.fotosensible : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Fotosensible Almacenamiento</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="fotosensibleAlmacenamiento"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      checked={
                        medicamentoSeleccionado.fotosensibleAlmacenamiento
                          ? medicamentoSeleccionado.fotosensibleAlmacenamiento
                          : false
                      }
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Fotosensible Aplicación</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="fotosensibleAplicacion"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      checked={
                        medicamentoSeleccionado.fotosensibleAplicacion ? medicamentoSeleccionado.fotosensibleAplicacion : false
                      }
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Alto Riesgo</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="altoRiesgo"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      checked={medicamentoSeleccionado.altoRiesgo ? medicamentoSeleccionado.altoRiesgo : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Controlado</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="controlado"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      checked={medicamentoSeleccionado.controlado ? medicamentoSeleccionado.controlado : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">No Agitar</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="noAgitar"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      checked={medicamentoSeleccionado.noAgitar ? medicamentoSeleccionado.noAgitar : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Refrigerar</label>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      className=""
                      name="refrigerar"
                      autoComplete="off"
                      onChange={changeModificarMedicamento}
                      checked={medicamentoSeleccionado.refrigerar ? medicamentoSeleccionado.refrigerar : false}
                      // value={datosNuevoMedicamento.fotosensibleAlmacenamiento ? datosNuevoMedicamento.fotosensibleAlmacenamiento : false}
                    />
                  </Col>
              
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_precios ? (
        <SweetAlert
          title={""}
          showCancel
          showConfirm
          confirmBtnText="Guardar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios_final: true,
            });
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios: false,
            });
            setMedicamentoPreciosActualizados({ preciosAseguradora: [] });
            setNuevoPrecio(null);
          }}>
          {preciosProducto.preciosProductoServicio || preciosProducto.preciosAseguradora.length > 0 ? (
            <>
              <h5 className="h5 mb-4">
                Medicamento
                <p className="text-gray-600">{`${medicamentoSeleccionadoPrecios.principioActivo} - ${medicamentoSeleccionadoPrecios.nombreComercial}`}</p>
              </h5>
              {/* <h5 className="h5 mb-4">
                Principio Activo
                <p className="text-gray-600">{`${medicamentoSeleccionadoPrecios.principioActivo}`}</p>
              </h5> */}
              <table className="table table-hover table-centered table-wrap mb-0 ">
                <thead>
                  <tr>
                    <th scope="col">Precio Definido para</th>
                    <th scope="col">Precio Actual</th>
                    <th scope="col">Nuevo Precio</th>
                  </tr>
                </thead>
                <tbody>
                  {preciosProducto.preciosProductoServicio ? (
                    <>
                      <tr key={preciosProducto.preciosProductoServicio.idPrecioProductoServicio}>
                        <td style={{ width: "40%" }}>Precio Máximo al Público</td>
                        <td style={{ width: "25%" }}>
                          <input
                            // value={`$${formatoMexico(preciosProducto.preciosProductoServicio.precioPublico)}`}
                            value={formatNumber(preciosProducto.preciosProductoServicio.precioPublico)}
                            disabled={true}
                            type="text"
                            name="precioPublico"
                          />
                        </td>
                        <td style={{ width: "25%" }}>
                          <input
                            disabled={false}
                            type="number"
                            name="precioPublico"
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => changeNuevoPrecio(e)}
                            onBlur={() => setNuevoPrecioMedicamentoProductoServicio({ tipo: "precioPublico" })}
                          />
                        </td>
                      </tr>
                      <tr key={preciosProducto.preciosProductoServicio.idPrecioProductoServicio}>
                        <td>Precio Mostrador y Médico</td>
                        <td>
                          <input
                            // value={`$${formatoMexico(preciosProducto.preciosProductoServicio.precioMostrador)}`}
                            value={formatNumber(preciosProducto.preciosProductoServicio.precioMostrador)}
                            disabled={true}
                            type="text"
                            name="precioMostrador"
                          />
                        </td>
                        <td>
                          <input
                            disabled={false}
                            type="number"
                            name="precioMostrador"
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => changeNuevoPrecio(e)}
                            onBlur={() => setNuevoPrecioMedicamentoProductoServicio({ tipo: "precioMostrador" })}
                          />
                        </td>
                      </tr>
                    </>
                  ) : null}

                  {preciosProducto.preciosAseguradora.length > 0 &&
                    preciosProducto.preciosAseguradora.map((precio, index) => {
                      return (
                        <>
                          <tr key={precio.idPrecioAseguradora}>
                            <td style={{ width: "40%" }}>{precio.aseguradora ? precio.aseguradora : ""}</td>
                            <td style={{ width: "25%" }}>
                              <input
                                // value={`$${formatoMexico(precio.precioUnitario)}`}
                                value={formatNumber(precio.precioUnitario)}
                                disabled={true}
                                type="text"
                                name="precioUnitario"
                              />
                            </td>
                            <td style={{ width: "25%" }}>
                              <input
                                disabled={false}
                                type="number"
                                name="precio"
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => changeNuevoPrecio(e)}
                                onBlur={() => setNuevoPrecioMedicamento(precio)}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </>
          ) : (
            // <Link to={`/carga/precios`}>
            //   <button className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white" type="button">
            //     Carga Masiva de Precios
            //   </button>
            // </Link>
            <button
              onClick={() => {
                setUISweetAlert({
                  ...UISweetAlert,
                  confirm_carga_masiva: true,
                });
              }}
              className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
              type="button">
              Carga Masiva de Precios
            </button>
          )}
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_precios_final ? (
        <SweetAlert
          title={"¿Desea modificar los precios del medicamento?"}
          showConfirm
          showCancel
          confirmBtnText="SI"
          confirmBtnBsStyle="success"
          cancelBtnText="NO"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "50%",
          }}
          onConfirm={() => guardarPreciosMedicamento()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios_final: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_carga_masiva ? (
        <SweetAlert
          title={"Carga Masiva de Precios"}
          showConfirm
          confirmBtnText="Salir"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          closeOnClickOutside={false}
          cancelBtnBsStyle="danger"
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_carga_masiva: false,
            });
          }}>
          <CargaMasivaModal disabled={true} idCatTipoProducto={2} />
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default Medicamentos;
