import React, { useContext, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import AuthContext from "../../context/Auth/AuthContext";

import RecetaProductoContext from "../../context/RecetaProducto/RecetaProductoContext";
import ExpedienteMedicoContext from "../../context/ExpedienteMedico/ExpedienteMedicoContext";

import PacientesContext from "../../context/Pacientes/PacientesContext";

import TabListaHojaIndicaciones from "../TabListaHojaIndicaciones/index";
import TabNuevaHojaIndicaciones from "../TabNuevaHojaIndicaciones/index";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import SexosContext from "../../context/Sexos/SexosContext";

import DrawerExpedientePaciente from "../DrawerExpedientePaciente/index";

import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";

export default function HojaIndicaciones(props) {
  const [UISweetAlert, setUISweetAlert] = useState({
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [medicoSeleccionado, setMedicoSeleccionado] = useState(null);
  const [expedienteMedicoSeleccionado, setExpedienteMedicoSeleccionado] = useState(null);

  const [tabIndex, setTabIndex] = useState(0);
  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado } = pacientesContext;

  const expedienteMedicoContext = useContext(ExpedienteMedicoContext);
  const { getExpedienteMedicoMasReciente } = expedienteMedicoContext;

  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos, setMedicoWithDetalles } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  useEffect(() => {
    const fetchAll = () => {
      listarSexos();
      listarAseguradoras();
      listarEscolaridades();
      listarEstadosCiviles();
      listarLugares();
      listarOcupaciones();
      listarMedicos();
    };
    fetchAll();
  }, []);

  const autorizado = usuario.authorities.includes("ROLE_MEDICO") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA");

  const recetaProductoContext = useContext(RecetaProductoContext);
  const { listarRecetaProductos, recetaProductos } = recetaProductoContext;

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }, [historiaClinicaInicial]);

  useEffect(() => {
    const fetchMedico = async () => {
      try {
        // const medico = await getMedicoByExpedienteMedico(props.idExpedienteMedico);
        const idCatMedico = usuario.idCatMedico ? usuario.idCatMedico : null;

        const expediente = await getExpedienteMedicoMasReciente(idCatPacienteSeleccionado);

        const medico = idCatMedico ? await setMedicoWithDetalles(idCatMedico) : null;

        setExpedienteMedicoSeleccionado(expediente);
        setMedicoSeleccionado(medico);
        return expediente;
      } catch (err) {}
    };
    fetchMedico()
      .then((expediente) => {
        if (usuario.idCatMedico) listarRecetaProductos(usuario.idCatMedico);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const redirectHistoriaClinicaInicial = async () => {
    props.history.push("/historia/clinica/inicial");
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Hoja de Indicaciones</h3>
      <DrawerExpedientePaciente
        historiaClinicaInicial={historiaClinicaInicial}
        sexos={sexos}
        aseguradoras={aseguradoras}
        medicos={medicos}
        lugares={lugares}
        ocupaciones={ocupaciones}
        estadosCiviles={estadosCiviles}
        escolaridades={escolaridades}
      />
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col>
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                type="button"
                onClick={redirectHistoriaClinicaInicial}>
                Historia Clínica Inicial
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                  <Tab>Ficha de Identificación</Tab>
                  {autorizado && usuario.idCatMedico ? <Tab>Nueva Hoja de Indicaciones</Tab> : null}
                  <Tab>Lista de Hojas de Indicaciones</Tab>
                </TabList>
                <TabPanel>
                  {/* <FichaIdentificacion props={props} historiaClinicaInicial={historiaClinicaInicial} setHistoriaClinicaInicial={setHistoriaClinicaInicial} disabled={true} /> */}
                  <div className="mt-4">
                    <h3>Diagnóstico</h3>
                    <textarea
                      disabled={true}
                      className="form-control"
                      value={
                        historiaClinicaInicial.expedienteMedico &&
                        historiaClinicaInicial.expedienteMedico.diagnosticos &&
                        historiaClinicaInicial.expedienteMedico.diagnosticos.length > 0
                          ? historiaClinicaInicial.expedienteMedico.diagnosticos[
                              historiaClinicaInicial.expedienteMedico.diagnosticos.length - 1
                            ].diagnostico
                          : ""
                      }
                    />
                  </div>
                  <div className="mt-4">
                    <h3>Etapa Clínica</h3>
                    <textarea
                      disabled={true}
                      className="form-control"
                      value={
                        historiaClinicaInicial.expedienteMedico &&
                        historiaClinicaInicial.expedienteMedico.etapasClinicas &&
                        historiaClinicaInicial.expedienteMedico.etapasClinicas.length > 0
                          ? historiaClinicaInicial.expedienteMedico.etapasClinicas[
                              historiaClinicaInicial.expedienteMedico.etapasClinicas.length - 1
                            ].etapaClinica
                          : ""
                      }
                    />
                  </div>
                </TabPanel>
                {autorizado && usuario.idCatMedico ? (
                  <TabPanel>
                    <TabNuevaHojaIndicaciones
                      recetaProductos={recetaProductos}
                      expedienteMedico={expedienteMedicoSeleccionado}
                      medico={medicoSeleccionado}
                      autorizado={autorizado}
                    />
                  </TabPanel>
                ) : null}
                <TabPanel>
                  <TabListaHojaIndicaciones props={props} expedienteMedico={expedienteMedicoSeleccionado} />
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
