const formatNumber = (number) => {
  if (number !== "" && number !== null && number !== undefined && !isNaN(number)) {
    const numberConverted = parseFloat(number);

    if (numberConverted % 1 === 0) {
      return `$${numberConverted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    } else {
      return `$${numberConverted
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
  }
};

export default formatNumber;
