import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "react-select";
import formatNumber from "../../utils/index";
import ConfiguracionContext from "../../context/Configuracion/ConfiguracionContext";
import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";
import AuthContext from "../../context/Auth/AuthContext";
import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import useWindowDimensions from "../../hooks/dimensionsHook";


export default function ListadoProductosSolicitudCompra({ props }) {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [productosFilter, setProductosFilter] = useState([]);
  const [auxProductoSustituto, setAuxProductoSustituto] = useState({});

  const ctxConfiguracion = useContext(ConfiguracionContext);
  const { getIvaConfiguracion } = ctxConfiguracion;

  const [detallesPrecio, setDetallesPrecio] = useState({
    total: 0,
    subtotal: 0,
    iva: 0,
  });

  const [permitirProductoSustituto, setPermitirProductoSustituto] = useState(false);

  const ctxProveedores = useContext(ProveedoresContext);
  const { proveedores, listarProveedores } = ctxProveedores;

  const [proveedorSeleccionado, setProveedorSeleccionado] = useState(null);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [indexProductoBorrado, setIndexProductoBorrado] = useState(null);
  const [indexProveedorBorrado, setIndexProveedorBorrado] = useState(null);
  const [ocultarBotonautorizada, setOcultarBotonautorizada] = useState(false);
  const [cadenaLog, setCadenaLog] = useState("");

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_guardar: false,
    confirm_alert_delete_producto: false,
    confirm_alert_solicitar_autorizacion: false,
    confirm_alert_producto_sustituto: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    confirm_alert_rechazo : false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const solicitudesComprasContext = useContext(SolicitudesComprasContext);
  const {
    solicitudCompraSeleccionada,
    setSolicitudCompraSeleccionada,
    solicitudShowEdit,
    guardarSolicitudCompra,
    uploadCancelarSolicitudCompra,
    cancelarSolicitudCompra,
    setCancelarSolicitudCompra,
    setNuevaSolicitudCompra,
    limpiarSolicitudCompraSeleccionada,
    guardarNuevaSolicitudCompra,
  } = solicitudesComprasContext;

  //ACCESO AL ESTADO GLOBAL - ProductosServicios
  const ctxProductosServicios = useContext(ProductosServiciosContext);
  const { productosServicios, listarProductosPersonal } = ctxProductosServicios;

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;
  const [ivaConfig, setIvaConfig] = useState(null);
  const ocultarAgregarGlobal =  usuario.authorities.includes("ROLE_COMPRAS") && 
       (solicitudCompraSeleccionada.estatussolicitudcompra? solicitudCompraSeleccionada.estatussolicitudcompra[solicitudCompraSeleccionada.estatussolicitudcompra.length-1].catEstatussolicitudcompra.idCatEstatusSolicitudCompra==2: false)

  const checarProveedorRepetido = async (proveedor, indexProducto, indexProveedor) => {
    let proveedorRepetido = false;

    solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].solicitudcompraproveedor.map((element, i) => {
      if (indexProveedor != i && element.idCatProveedor == proveedor.idCatProveedor && !proveedorRepetido) {
        proveedorRepetido = true;
      }
    });
    return proveedorRepetido;
  };

  const confirmSolicitudCompra = async (e, solicitar) => {
    e.preventDefault();

    const errorEnValidacion = await validateFormSolicitudCompra();

    if (errorEnValidacion) {
      return;
    }

    if (solicitar) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_solicitar: true,
      });
    }
  };

  const validateFormSolicitudCompra = async () => {
    let errorEnValidacion = false;

    /*/if (!nuevaSolicitudCompra.idCatTipoSolicitudCompra && !errorEnValidacion) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca el tipo de solicitud de compra`,
      });
      errorEnValidacion = true;
    }*/

    if (!solicitudCompraSeleccionada.idCatTipoOrdenCompra && !errorEnValidacion) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca el tipo de compra`,
      });
      errorEnValidacion = true;
    }

    if (!solicitudCompraSeleccionada.idCatTipoProducto && !errorEnValidacion) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca el tipo de producto`,
      });
      errorEnValidacion = true;
    }

    if (solicitudCompraSeleccionada.solicitudcompraproducto.length < 1 && !errorEnValidacion) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca productos a la solicitud`,
      });
      errorEnValidacion = true;
    } else if (!errorEnValidacion) {
      solicitudCompraSeleccionada.solicitudcompraproducto.map((producto, index) => {
        if (!producto.idCatProducto && !errorEnValidacion) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el producto en el renglon ${index + 1}`,
          });
          errorEnValidacion = true;
        }

        if (!producto.piezas && !errorEnValidacion) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca las piezas en el renglon ${index + 1}`,
          });
          errorEnValidacion = true;
        }

        if(usuario.authorities.includes("ROLE_COMPRAS")){
          if (producto.solicitudcompraproveedor.length < 1 && !errorEnValidacion) {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              dynamic_title: "Fallo",
              dynamic_description: `Por favor, introduzca al menos un proveedor para el producto en el renglon ${index + 1}`,
            });
            errorEnValidacion = true;
          } else if (!errorEnValidacion) {
            producto.solicitudcompraproveedor.map((proveedor, index) => {
              if (!proveedor.idCatProveedor && !errorEnValidacion) {
                setUISweetAlert({
                  ...UISweetAlert,
                  error_dlg: true,
                  dynamic_title: "Fallo",
                  dynamic_description: `Por favor, introduzca el proveedor en el renglon ${index + 1}`,
                });
                errorEnValidacion = true;
              }
            });
          }
        }
      });
    }
    return errorEnValidacion;
  };

  const toggleExpanded = async (index) => {
    let productosNuevaSolicitudCompra = solicitudCompraSeleccionada.solicitudcompraproducto;
    let producto = productosNuevaSolicitudCompra[index];
    producto.expanded = !producto.expanded;

    setSolicitudCompraSeleccionada({
      ...solicitudCompraSeleccionada,
      solicitudcompraproducto: productosNuevaSolicitudCompra,
    });
  };

  const setProveedor = async (proveedor, indexProducto, indexProveedor) => {
    let proveedorSol = solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].solicitudcompraproveedor[indexProveedor];
    const proveedorRepetido = await checarProveedorRepetido(proveedor, indexProducto, indexProveedor);

    if (proveedorRepetido) {
      proveedorSol.idCatProveedor = "";
      proveedorSol.proveedor = "";
      proveedorSol.contacto = "";
      proveedorSol.correo = "";

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Proveedor repetido",
      });
    } else {
      proveedorSol.idCatProveedor = proveedor.idCatProveedor;
      proveedorSol.proveedor = proveedor.proveedor;
      proveedorSol.contacto = proveedor.contacto;
      proveedorSol.correo = proveedor.correo;
    }
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
  };

  const setPrecioTotal = async () => {
    let subtotal = 0;
    let iva = 0;
    let total = 0;
    setDetallesPrecio({
      subtotal: 0,
      iva: 0,
      total: 0,
    });

    if (solicitudCompraSeleccionada.solicitudcompraproducto.length > 0) {
      solicitudCompraSeleccionada.solicitudcompraproducto.map((producto) => {

        let registro =  null
        if(producto){
          registro = producto;
        }

        subtotal += Number(producto.precio) ;

       if (Number(producto.iva) != null) {
          iva += Number(producto.iva);
        }
      });
    }
    total = subtotal + iva;

    setDetallesPrecio({
      subtotal: (Math.round(subtotal * 100) / 100).toFixed(2),
      iva: (Math.round(iva * 100) / 100).toFixed(2),
      total: (Math.round(total * 100) / 100).toFixed(2),
    });
  };

  const uploadFile = async (e, proveedor) => {
    const files = e.target.files;
    if (!proveedor.archivos) {
      proveedor.archivos = [];
    }
    // solicitudCompraSeleccionada.archivos = []
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          let strFile = reader.result;
          let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
          let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);

          let nuevoArchivo = {
            nombreArchivo: file.name,
            mimeType: type,
            tamano: file.size,
            inputStream: fileBase64,
          };

          proveedor.archivos = proveedor.archivos.concat(nuevoArchivo);
          setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const changeProveedorSugerido = async (producto, proveedor) => {
    proveedor.sugerido = !proveedor.sugerido;
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
  };

  const changeComentarioProveedor = async (e, proveedor) => {
    let value = e.target.value;
    proveedor.comentario = value;
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
  };

  const confirmSolicitudCompraSolicitarAutorizacion = async () => {
    solicitudCompraSeleccionada.idCatTipoSolicitudCompra = solicitudCompraSeleccionada.catTiposolicitudcompra.idCatTipoSolicitudCompra
    solicitudCompraSeleccionada.idCatTipoOrdenCompra = solicitudCompraSeleccionada.catTipoordencompra.idCatTipoOrdenCompra;
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

    const validoAutorizarSolicitudCompra = await validateAutorizacionSolicitudCompra();
    
    if (!validoAutorizarSolicitudCompra) {
      return;
    }

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_solicitar_autorizacion: true,
    });
  };

  const validateAutorizacionSolicitudCompra = async () => {
    let validacionCorrecta = true;
    let cadenaProveedor = "";
    solicitudCompraSeleccionada.solicitudcompraproducto.map((producto,index) => {

      if(producto.solicitudcompraproveedor==0){
        cadenaProveedor = cadenaProveedor + " - " + producto.catProductos.nombreComercial
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nueva_venta_apartado: false,
          confirm_nueva_venta_completa: false,
          dynamic_title: "Fallo",
          dynamic_description: `Por favor, introduzca un proveedor para ${cadenaProveedor} `,
        });
        validacionCorrecta = false;
        return;
      }

      producto.solicitudcompraproveedor.map((proveedorTemp) => {
        let proveedor = null;

        if (proveedorTemp.catProveedores){
          proveedor = proveedorTemp.catProveedores;
        }else{
          proveedor = proveedorTemp;
        }

        if (validacionCorrecta && (proveedor.proveedor == "" || !proveedor.proveedor)) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            confirm_nueva_venta_apartado: false,
            confirm_nueva_venta_completa: false,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, seleccione un proveedor para el producto ${proveedor.producto}`,
          });
          validacionCorrecta = false;
          return;
        }

        if (validacionCorrecta && (proveedorTemp.precio <= 0 || !proveedorTemp.precio)) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            confirm_nueva_venta_apartado: false,
            confirm_nueva_venta_completa: false,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el precio para el proveedor ${proveedor.proveedor} del producto ${producto.catProductos.nombreComercial}`,
          });
          validacionCorrecta = false;
          return;
        }

        if (validacionCorrecta && (proveedorTemp.piezasSolicitadas <= 0 || !proveedorTemp.piezasSolicitadas)) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            confirm_nueva_venta_apartado: false,
            confirm_nueva_venta_completa: false,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca las piezas disponibles para el proveedor ${proveedor.proveedor} del producto ${producto.catProductos.nombreComercial}`,
          });
          validacionCorrecta = false;
          return;
        }

        if (validacionCorrecta && (proveedorTemp.comentario == 0 || !proveedorTemp.comentario)) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            confirm_nueva_venta_apartado: false,
            confirm_nueva_venta_completa: false,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca los comentarios de la solicitud para el proveedor ${proveedor.proveedor} del producto ${producto.catProductos.nombreComercial}`,
          });
          validacionCorrecta = false;
          return;
        }

      });
    });

    return validacionCorrecta;
  };

  const changeProveedorDecision = async (e) => {
    let value = e.target.value;
    if (e.target.name == "decision") {
      proveedorSeleccionado.decision = !proveedorSeleccionado.decision;
      proveedorSeleccionado.piezasCompradas = null;
      proveedorSeleccionado.motivo = null;

      if (proveedorSeleccionado.solicitudcompraproductosustituto) {
        proveedorSeleccionado.solicitudcompraproductosustituto.piezasCompradas = null;
      }
    }

    if (e.target.name == "piezasCompradas") {
      proveedorSeleccionado.piezasCompradas = value;
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

      checarPiezasCompradas(true);
    }

    if (e.target.name == "piezasCompradasProductoSustituto") {
      proveedorSeleccionado.solicitudcompraproductosustituto.piezasCompradas = Number(value);
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

      checarPiezasCompradas(false);
    }

    if (e.target.name == "motivo") {
      proveedorSeleccionado.motivo = value;
      proveedorSeleccionado.decision = false;
    }
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
  };

  const checarPiezasCompradas = async (productoOriginal) => {
    let errorPiezasCompradas = false;

    let sumaPiezasCompradasProveedores = 0;

    productoSeleccionado.solicitudcompraproveedor.map((proveedor) => {
      if (proveedor.piezasCompradas) {
        sumaPiezasCompradasProveedores += Number(proveedor.piezasCompradas);
      }
      if (proveedor.solicitudcompraproductosustituto && proveedor.solicitudcompraproductosustituto.piezasCompradas) {
        sumaPiezasCompradasProveedores += Number(proveedor.solicitudcompraproductosustituto.piezasCompradas);
      }
    });

    if (sumaPiezasCompradasProveedores > productoSeleccionado.piezas && !errorPiezasCompradas) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Las piezas a adquirir de los proveedores es mayor a la cantidad de piezas solicitadas del producto, 
                se requieren ${productoSeleccionado.piezas}, y se sumaron ${sumaPiezasCompradasProveedores} piezas a adquirir`,
      });
      errorPiezasCompradas = true;

      if (productoOriginal) {
        proveedorSeleccionado.piezasCompradas = 0;
      } else {
        proveedorSeleccionado.solicitudcompraproductosustituto.piezasCompradas = 0;
      }
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
    }

    if (
      productoOriginal &&
      proveedorSeleccionado.piezasSolicitadas < proveedorSeleccionado.piezasCompradas &&
      !errorPiezasCompradas
    ) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "La piezas a comprar no pueden ser mayor a la piezas disponibles del proveedor",
      });
      errorPiezasCompradas = true;
      proveedorSeleccionado.piezasCompradas = 0;
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
    }

    if (
      !productoOriginal &&
      proveedorSeleccionado.solicitudcompraproductosustituto.piezasSolicitadas <
        proveedorSeleccionado.solicitudcompraproductosustituto.piezasCompradas &&
      !errorPiezasCompradas
    ) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "La piezas a comprar no pueden ser mayor a la piezas disponibles del proveedor",
      });
      errorPiezasCompradas = true;
      proveedorSeleccionado.solicitudcompraproductosustituto.piezasCompradas = 0;
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
    }
  };

  const confirmSolicitudCompraFinalizarAutorizacion = async () => {
    
    solicitudCompraSeleccionada.idCatTipoSolicitudCompra = solicitudCompraSeleccionada.catTiposolicitudcompra.idCatTipoSolicitudCompra
    solicitudCompraSeleccionada.idCatTipoOrdenCompra = solicitudCompraSeleccionada.catTipoordencompra.idCatTipoOrdenCompra;
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

    const validoSolicitudCompra = await validateFinalizarAutorizacionSolicitudCompra();

    if (!validoSolicitudCompra) {
      return;
    }

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_finalizar_autorizacion: true,
    });
  };

  const validateFinalizarAutorizacionSolicitudCompra = async () => {
    let validacionCorrecta = true;

    solicitudCompraSeleccionada.solicitudcompraproducto.map((producto) => {
      producto.solicitudcompraproveedor.map((proveedor) => {
        if (validacionCorrecta && proveedor.decision == null) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            confirm_nueva_venta_apartado: false,
            confirm_nueva_venta_completa: false,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, tome la decision para el proveedor ${proveedor.catProveedores.proveedor} del producto ${producto.catProductos.nombreComercial}`,
          });
          validacionCorrecta = false;
        } else if (validacionCorrecta && !proveedor.decision && !proveedor.motivo) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            confirm_nueva_venta_apartado: false,
            confirm_nueva_venta_completa: false,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el motivo de rechazo para el proveedor ${proveedor.catProveedores.proveedor} del producto ${producto.catProductos.nombreComercial}`,
          });
          validacionCorrecta = false;
        } else if (
          validacionCorrecta &&
          proveedor.decision &&
          !proveedor.solicitudcompraproductosustituto &&
          proveedor.piezasCompradas <= 0
        ) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            confirm_nueva_venta_apartado: false,
            confirm_nueva_venta_completa: false,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca las piezas para el proveedor ${proveedor.catProveedores.proveedor} del producto ${producto.catProductos.nombreComercial}`,
          });
          validacionCorrecta = false;
        } else if (
          validacionCorrecta &&
          proveedor.decision &&
          proveedor.solicitudcompraproductosustituto &&
          proveedor.piezasCompradas <= 0 &&
          (!proveedor.solicitudcompraproductosustituto.piezasCompradas ||
            proveedor.solicitudcompraproductosustituto.piezasCompradas <= 0)
        ) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            confirm_nueva_venta_apartado: false,
            confirm_nueva_venta_completa: false,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca las piezas del producto original o del producto sustituto para el proveedor ${proveedor.catProveedores.proveedor}`,
          });
          validacionCorrecta = false;
        }
      });
    });

    return validacionCorrecta;
  };

  const eliminarProducto = async () => {
    let productos = solicitudCompraSeleccionada.solicitudcompraproducto[indexProductoBorrado].solicitudcompraproveedor;
    let producto = productos[indexProveedorBorrado];
    solicitudCompraSeleccionada.solicitudcompraproducto[indexProductoBorrado].solicitudcompraproveedor = false
    //solicitudCompraSeleccionada.solicitudcompraproducto[indexProductoBorrado].solicitudcompraproveedor.filter(
    //  (item) => item != producto
    //);

    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
    setPrecioTotal();
    setIndexProductoBorrado(null);

    setUISweetAlert({
      ...UISweetAlert,
      success_dlg: true,
      dynamic_title: "Borrado",
      confirm_alert_delete_producto: false,
      dynamic_description: "El producto ha sido borrado correctamente.",
    });
  };

  const confirmaCancelacion = async () => {
    /*if(proveedorSeleccionado.decision==true){
      solicitudCompraSeleccionada*/

    let cadenaTemporal = cadenaLog;
    cadenaTemporal = cadenaTemporal + "Confirma rechazo:ID[" + solicitudCompraSeleccionada.idSolicitudCompra + "] "
    setCadenaLog(cadenaTemporal);

    setProveedorSeleccionado(null);
    setProductoSeleccionado(null);

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_decision_proveedor: false,
      confirm_alert_rechazo : false,
    });
  };


  const changePrecioProveedor = async (e, proveedor, indexProducto) => {
    let value = e.target.value;
    let total = parseFloat(0);
    let ivaTemp = parseFloat(0);
    value = value.replace(/(?!-)[^0-9.]/g, "").replace("-", "");

    proveedor.precio = value;
    solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].solicitudcompraproveedor.map((proveedorTemp,index) => {
        total = parseFloat(total) + (parseFloat(proveedorTemp.precio) * parseFloat(proveedorTemp.piezasSolicitadas));
    })

    if(total > 0){
      let tipoProductoTemp = solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].catProductos.catTipoproducto.idCatTipoProducto;
      if(tipoProductoTemp == 1){
         ivaTemp = total * ivaConfig;
         proveedor.iva = ivaTemp;
      }
    }

    proveedor.iva = ivaTemp;
    solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].precio = total;
    solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].iva = ivaTemp;

    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
    setPrecioTotal();
  };

  const changeProveedorPiezasSolicitadas = async (e, proveedor, indexProducto) => {
    let value = e.target.value;
    let total = parseFloat(0);
    let ivaTemp = parseFloat(0);
    proveedor.piezasSolicitadas = value;

    solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].solicitudcompraproveedor.map((proveedorTemp) => {
        total = parseFloat(total) + (parseFloat(proveedorTemp.precio) * parseFloat(proveedorTemp.piezasSolicitadas));
    })

    if(total > 0){
      let tipoProductoTemp = solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].catProductos.catTipoproducto.idCatTipoProducto;
      if(tipoProductoTemp == 1){
         ivaTemp = total * ivaConfig;
         proveedor.iva = ivaTemp;
      }
    }
              
    proveedor.iva = ivaTemp;
    solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].precio = total;
    solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].piezasSolicitadas = value;
    solicitudCompraSeleccionada.solicitudcompraproducto[indexProducto].iva = ivaTemp;

    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
    setPrecioTotal();
  };

  const uploadCambiosSolicitudCompra = async () => {
    const validoAutorizarSolicitudCompra = await validateAutorizacionSolicitudCompra();
    
    if (!validoAutorizarSolicitudCompra) {      
      return;
    }

    setLoading(true);

    solicitudCompraSeleccionada.idCatTipoSolicitudCompra = solicitudCompraSeleccionada.catTiposolicitudcompra.idCatTipoSolicitudCompra
    solicitudCompraSeleccionada.idCatTipoOrdenCompra = solicitudCompraSeleccionada.catTipoordencompra.idCatTipoOrdenCompra;

    solicitudCompraSeleccionada.logVo ={
      idUsuario: usuario.idUsuario,
      modulo: "Autorizaciones SC",
      actividad: cadenaLog,
    }

    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

    const res = await guardarSolicitudCompra();

    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_guardar: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);

      solicitudCompraSeleccionada.estatussolicitudcompra = res.data.estatussolicitudcompra;
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_guardar: false,
        redirect: true,
        path: "/solicitudescompra",
        dynamic_description: "Se registraron los cambios de manera exitosa!",
      });
    }
  };

  const submitSolicitarAutorizacion = async () => {
    solicitudCompraSeleccionada.idCatTipoSolicitudCompra = solicitudCompraSeleccionada.catTiposolicitudcompra.idCatTipoSolicitudCompra
    solicitudCompraSeleccionada.idCatTipoOrdenCompra = solicitudCompraSeleccionada.catTipoordencompra.idCatTipoOrdenCompra;
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

    const estatusActual =
      solicitudCompraSeleccionada.estatussolicitudcompra[solicitudCompraSeleccionada.estatussolicitudcompra.length - 1]
        .catEstatussolicitudcompra;

    solicitudCompraSeleccionada.idEstatusNuevo = estatusActual.idCatEstatusSolicitudCompra + 1;
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

    setLoading(true);

    const res = await guardarSolicitudCompra();

    if (res.data.error) {
      setLoading(false);

      solicitudCompraSeleccionada.idEstatusNuevo = null;
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_solicitar_autorizacion: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (!res.data.error) {
      setLoading(false);

      solicitudCompraSeleccionada.estatussolicitudcompra = res.data.estatussolicitudcompra;
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_solicitar_autorizacion: false,
        redirect: true,
        path: "/solicitudescompra",
        dynamic_description: "Se registraron los cambios de manera exitosa!",
      });
    }
  };

  const submitFinalizaAutorizacion = async () => {
    const estatusActual =
      solicitudCompraSeleccionada.estatussolicitudcompra[solicitudCompraSeleccionada.estatussolicitudcompra.length - 1]
        .catEstatussolicitudcompra;
    solicitudCompraSeleccionada.idEstatusNuevo = estatusActual.idCatEstatusSolicitudCompra + 2;

    solicitudCompraSeleccionada.logVo ={
      idUsuario: usuario.idUsuario,
      modulo: "Autorizaciones SC",
      actividad: cadenaLog,
    }

    let ultimoEstatus = solicitudCompraSeleccionada.estatussolicitudcompra[solicitudCompraSeleccionada.estatussolicitudcompra.length - 1].catEstatussolicitudcompra.idCatEstatusSolicitudCompra;
    let decision =  false;
    solicitudCompraSeleccionada.solicitudcompraproducto.map((producto) => {
      producto.solicitudcompraproveedor.map((proveedor) => {
        if(proveedor.decision == true){
          decision = true;
        }

      });
    });
    //SOSPECHA
    if(ultimoEstatus==3){
      if(decision==true) {
        solicitudCompraSeleccionada.idEstatusNuevo = 5;
      }else{
        solicitudCompraSeleccionada.idEstatusNuevo = 7;
      }
    }

    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

    setLoading(true);

    const res = await guardarSolicitudCompra();

    if (res.data.error) {
      setLoading(false);

      solicitudCompraSeleccionada.idEstatusNuevo = null;
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_finalizar_autorizacion: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);

      solicitudCompraSeleccionada.estatussolicitudcompra = res.data.estatussolicitudcompra;
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_finalizar_autorizacion: false,
        redirect: true,
        path: "/solicitudescompra",
        dynamic_description: "Se registraron los cambios de manera exitosa!",
      });
    }
  };

  const cancelarSolicitudCompraConfirm = async () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_cancelar_solicitud: true,
    });
  };




  const solicitudCompraCancelar = async () => {
    //servicio para cancelar la solicitud

    if (cancelarSolicitudCompra.comentarioEstatus == "" || cancelarSolicitudCompra.fechaCancelacion == "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor llene la información necesaria.",
      });
      return;
    }

    setLoading(true);

    const res = await uploadCancelarSolicitudCompra({
      idEstatusNuevo: 8,
      idSolicitudCompra: solicitudCompraSeleccionada.idSolicitudCompra,
      comentarioEstatus: cancelarSolicitudCompra.comentarioEstatus,
    });

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_cancelar_solicitud: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error al cancelar la solicitud de compra. Inténtalo por favor más tarde.",
      });
    } else if (!res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_cancelar_solicitud: false,
        redirect: true,
        path: "/solicitudescompra",
        dynamic_description: "Solicitud de compra cancelada",
      });

      setCancelarSolicitudCompra({
        fechaCancelacion: new Date().toISOString().slice(0, 10),
        comentarioEstatus: "",
      });
    }
  };

  const onChangeCancelarSolicitudCompra = async (e) => {
    setCancelarSolicitudCompra({
      ...cancelarSolicitudCompra,
      [e.target.name]: e.target.value,
    });
  };

  const checkPermitirProductoSustituto = async () => {
    let permitirProdSus = false;
    solicitudCompraSeleccionada.solicitudcompraproducto.map((producto) => {
      if (producto.vale || producto.ventaMostrador) {
        permitirProdSus = true;
      }
    });
    solicitudCompraSeleccionada.productoSustitutoPermitido = permitirProdSus;
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
    setPermitirProductoSustituto(true);

    //Si es posible introducir productos sustitutos, y ademas los podemos editar, llenamos el listado de productos
    if (permitirProdSus && !solicitudShowEdit.disabledProductoSustituto) {
      listarProductosPersonal();
    }
  };

  const changeProductoSustitutoProveedor = async (e) => {
    let value = e.target.value;

    auxProductoSustituto.descripcion = value;
    setAuxProductoSustituto(auxProductoSustituto);

    const productosFiltrados = await productosServicios.filter((prodServ) => {
      if (prodServ.idCatTipoproducto == productoSeleccionado.catProductos.catTipoproducto.idCatTipoProducto) {
        if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0)) {
          return true;
        } else if (prodServ.numIdentificacion != null && prodServ.numIdentificacion.startsWith(value, 0)) {
          return true;
        } else if (prodServ.codigoEAN != null && prodServ.codigoEAN.startsWith(value, 0)) {
          return true;
        }
      } else {
        return false;
      }
    });
    setProductosFilter(productosFiltrados);

    if (value.includes("-")) {
      seleccionarProducto(value);
    } else {
      auxProductoSustituto.idCatProducto = null;
      auxProductoSustituto.idCatTipoProducto = null;
      // setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
      setAuxProductoSustituto(auxProductoSustituto);
    }
  };

  const seleccionarProducto = async (value) => {
    var lastIndex = value.lastIndexOf("-");
    const productoServicioSeleccionado = await productosServicios.filter((prodServ) => {
      if (
        prodServ.numIdentificacion != null &&
        prodServ.numIdentificacion.startsWith(value.slice(lastIndex + 1, value.length).replace(" ", ""), 0)
      ) {
        return true;
      }
    });

    if (productoServicioSeleccionado.length > 0) {
      let producto = productoServicioSeleccionado[0];

      if (producto.idCatProducto == productoSeleccionado.catProductos.idCatProducto) {
        auxProductoSustituto.idCatProducto = null;
        auxProductoSustituto.idCatTipoProducto = null;
        auxProductoSustituto.descripcion = "";

        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Producto repetido",
        });
      } else {
        auxProductoSustituto.idCatProducto = producto.idCatProducto;
        auxProductoSustituto.idCatTipoProducto = producto.idCatTipoproducto;
      }

      setAuxProductoSustituto(auxProductoSustituto);
      // setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
    } else {
      auxProductoSustituto.idCatProducto = null;
      auxProductoSustituto.idCatTipoProducto = null;
      setAuxProductoSustituto(auxProductoSustituto);
      // setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
    }
  };

  const checkProductosFilter = async () => {
    if (
      !proveedorSeleccionado.solicitudcompraproductosustituto ||
      (proveedorSeleccionado.solicitudcompraproductosustituto &&
        !proveedorSeleccionado.solicitudcompraproductosustituto.descripcion)
    ) {
      const productosFiltrados = await productosServicios.filter((prodServ) => {
        if (
          prodServ.idCatTipoproducto == productoSeleccionado.catProductos.catTipoproducto.idCatTipoProducto &&
          productoSeleccionado.catProductos.idCatProducto != prodServ.idCatProducto &&
          prodServ.principioActivo.toLowerCase().startsWith(productoSeleccionado.catProductos.principioActivo.toLowerCase(), 0)
        ) {
          return true;
        }
      });
      setProductosFilter(productosFiltrados);
    } else if (
      proveedorSeleccionado.solicitudcompraproductosustituto &&
      proveedorSeleccionado.solicitudcompraproductosustituto.descripcion
    ) {
      const productosFiltrados = await productosServicios.filter((prodServ) => {
        if (
          prodServ.idCatTipoproducto == productoSeleccionado.catProductos.catTipoproducto.idCatTipoProducto &&
          productoSeleccionado.catProductos.idCatProducto != prodServ.idCatProducto &&
          prodServ.principioActivo.toLowerCase().startsWith(productoSeleccionado.catProductos.principioActivo.toLowerCase(), 0)
        ) {
          if (
            proveedorSeleccionado.solicitudcompraproductosustituto.descripcion != "" &&
            prodServ.nombreComercial
              .toLowerCase()
              .startsWith(proveedorSeleccionado.solicitudcompraproductosustituto.descripcion.toLowerCase(), 0)
          ) {
            return true;
          } else {
            return true;
          }
        }
      });
      setProductosFilter(productosFiltrados);
    }
  };

  const setProductoSustitutoAuxliar = async (proveedor) => {
    if (proveedor.solicitudcompraproductosustituto) {
      if (proveedor.solicitudcompraproductosustituto.idCatProducto && proveedor.solicitudcompraproductosustituto.descripcion) {
        setAuxProductoSustituto({
          idCatProducto: proveedor.solicitudcompraproductosustituto.idCatProducto
            ? proveedor.solicitudcompraproductosustituto.idCatProducto
            : null,
          descripcion: proveedor.solicitudcompraproductosustituto.descripcion
            ? proveedor.solicitudcompraproductosustituto.descripcion
            : null,
          piezas: proveedor.solicitudcompraproductosustituto.piezas ? proveedor.solicitudcompraproductosustituto.piezas : null,
          precio: proveedor.solicitudcompraproductosustituto.precio ? proveedor.solicitudcompraproductosustituto.precio : null,
          idCatTipoProducto: proveedor.solicitudcompraproductosustituto.idCatTipoProducto
            ? proveedor.solicitudcompraproductosustituto.idCatTipoProducto
            : null,
        });
      } else if (proveedor.solicitudcompraproductosustituto.catProductos) {
        setAuxProductoSustituto({
          idCatProducto: proveedor.solicitudcompraproductosustituto.catProductos.idCatProducto,
          descripcion: `${proveedor.solicitudcompraproductosustituto.catProductos.nombreComercial} - ${proveedor.solicitudcompraproductosustituto.catProductos.numIdentificacion}`,
          idCatTipoProducto: proveedor.solicitudcompraproductosustituto.catProductos.catTipoproducto.idCatTipoProducto,
          piezas: proveedor.solicitudcompraproductosustituto.piezas,
          precio: proveedor.solicitudcompraproductosustituto.precio,
          catProductos: proveedor.solicitudcompraproductosustituto.catProductos,
        });
      }
    }
  };

  const changeProductoSustituto = async (e) => {
    let value = e.target.value;
    value = value.replaceAll("$", "").replaceAll("-", "");
    setAuxProductoSustituto({
      ...auxProductoSustituto,
      [e.target.name]: value,
    });
  };

  const checkProductoSustitutoCompleto = async () => {
    if (Object.entries(auxProductoSustituto).length !== 0) {
      let productoSustitutoCompleto = true;

      if (productoSustitutoCompleto && !auxProductoSustituto.idCatProducto) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, introduzca el producto",
        });
        productoSustitutoCompleto = false;
      }

      if (productoSustitutoCompleto && !auxProductoSustituto.piezas) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, introduzca las piezas",
        });
        productoSustitutoCompleto = false;
      }

      if (productoSustitutoCompleto && !auxProductoSustituto.precio) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, introduzca el precio",
        });
        productoSustitutoCompleto = false;
      }

      if (productoSustitutoCompleto) {
        if (
          proveedorSeleccionado.solicitudcompraproductosustituto != null &&
          proveedorSeleccionado.solicitudcompraproductosustituto.idSolicitudCompraProductoSustituto
        ) {
          auxProductoSustituto.idSolicitudCompraProductoSustituto =
            proveedorSeleccionado.solicitudcompraproductosustituto.idSolicitudCompraProductoSustituto;
          setAuxProductoSustituto(auxProductoSustituto);
        }
        proveedorSeleccionado.solicitudcompraproductosustituto = auxProductoSustituto;
        setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
        setAuxProductoSustituto({});
        setProductoSeleccionado(null);
        setProveedorSeleccionado(null);

        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_producto_sustituto: false,
        });
      }
    } else {
      if (
        proveedorSeleccionado.solicitudcompraproductosustituto != null &&
        proveedorSeleccionado.solicitudcompraproductosustituto.idSolicitudCompraProductoSustituto
      ) {
        auxProductoSustituto.idSolicitudCompraProductoSustituto =
          proveedorSeleccionado.solicitudcompraproductosustituto.idSolicitudCompraProductoSustituto;
        setAuxProductoSustituto(auxProductoSustituto);
      }
      proveedorSeleccionado.solicitudcompraproductosustituto = auxProductoSustituto;
      setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
      setAuxProductoSustituto({});
      setProductoSeleccionado(null);
      setProveedorSeleccionado(null);

      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_producto_sustituto: false,
      });
    }
  };

  const confirmModicarDatos = async () => 
  {
    let error = false;
    let mensaje = ""
    if(proveedorSeleccionado.decision==false || proveedorSeleccionado.decision==null){
      if(proveedorSeleccionado.motivo == "" || proveedorSeleccionado.motivo == null){
        error = true;
        mensaje= "Debe justificar el motivo del rechazo"        
      }
    }else{
      if(proveedorSeleccionado.piezasCompradas==null || proveedorSeleccionado.piezasCompradas==0){
        error = true;
        mensaje= "Las Piezas a Adquirir debe ser mayor a 0"
      }
    }

    if (error) 
    {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_insumo: false,
          dynamic_title: "Fallo",
          dynamic_description: mensaje,
        });
    } else 
    {

      if(proveedorSeleccionado.decision==false){
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_rechazo: true,
        });

      }else{
        let cadenaTemporal = cadenaLog;
        cadenaTemporal = cadenaTemporal + "Autorización:ID[" + solicitudCompraSeleccionada.folioSolicitudCompra + "] "
        setCadenaLog(cadenaTemporal);

        setProveedorSeleccionado(null);
        setProductoSeleccionado(null);
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_decision_proveedor: false,
        });
      }
    }
  };

  const nuevoProveedor = async (index) => {
    const nuevoProv = {
      idCatProveedor: null,
      proveedor: "",
      contacto: "",
      correo: "",
	    precio:0,
	    piezasSolicitadas:0,
	    comentario:"",
	    sugerido:"",
	    producto: solicitudCompraSeleccionada.solicitudcompraproducto[index].catProductos.nombreComercial
    };
    solicitudCompraSeleccionada.solicitudcompraproducto[index].solicitudcompraproveedor =
    solicitudCompraSeleccionada.solicitudcompraproducto[index].solicitudcompraproveedor.concat(nuevoProv);
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
  };

  useEffect(() => {
   
    listarProveedores();

    const fetchAllContextData = async () => {
      // listarConfiguracion();
      const iva = await getIvaConfiguracion();
      setIvaConfig(iva);
    };
    fetchAllContextData();
    
    if (solicitudCompraSeleccionada && solicitudCompraSeleccionada.solicitudcompraproducto) {

      setPrecioTotal();
    }

    if (Object.entries(solicitudCompraSeleccionada).length !== 0 && !permitirProductoSustituto) {
      checkPermitirProductoSustituto();
    }
  }, [solicitudCompraSeleccionada]);

  // const formatOnBlur = (proveedor) => {
  //   proveedor.precio = formatNumber(proveedor.precio);
  //   setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
  // };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">
        
      </h3>

      <Card className="mt-10">
        <CardBody>
          <Row hidden={solicitudShowEdit.cancelarSolicitud ? false : true}>
            <Col>
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4"
                type="button"
                hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                onClick={cancelarSolicitudCompraConfirm}>
                Cancelar Solicitud
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Descripción</th>
                      <th scope="col">
                        Principio<br></br>Activo
                      </th>
                      <th scope="col">Piezas</th>
                      <th scope="col">
                        Precio Unitario<br></br>(Estimado)
                      </th>
                      <th scope="col">
                        IVA<br></br>Unitario
                      </th>
                      <th scope="col">Total</th>
                      <th scope="col">Tipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {solicitudCompraSeleccionada && solicitudCompraSeleccionada.solicitudcompraproducto
                      ? solicitudCompraSeleccionada.solicitudcompraproducto.map((producto, index) => {
                          return (
                            <>
                              <tr key={producto}>
                                <td onClick={() => toggleExpanded(index)}>
                                  {producto.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>}
                                </td>
                                <td>{producto.catProductos.nombreComercial}</td>
                                <td>
                                  {producto.catProductos.principioActivo != "X" ? producto.catProductos.principioActivo : ""}
                                </td>
                                <td>{producto.piezas}</td>
                                <td>{producto.precio ? formatNumber(producto.precio) : ""}</td>
                                {/* <td>{producto.precio ? `$${producto.precio.toFixed(2)}` : ""}</td> */}
                                <td>{producto.iva ? formatNumber(producto.iva) : "$0"}</td>
                                {/* <td>{producto.iva ? `$${producto.iva.toFixed(2)}` : "$0"}</td> */}
                                <td>
                                  {producto.iva > 0
                                    ? formatNumber(producto.piezas * (producto.precio + producto.iva))
                                    : formatNumber(producto.piezas * producto.precio)}
                                </td>
                                {/* <td>{producto.iva > 0 ? `$${(producto.piezas * (producto.precio + producto.iva)).toFixed(2)}` : `$${(producto.piezas * producto.precio).toFixed(2)}`}</td> */}
                                <td>{producto.catProductos.catTipoproducto.tipoProducto}</td>
                              </tr>
                              {producto.expanded && (
                                <tr>
                                  <td colSpan={10}>
                                    <h4 className="flex mt-2">Proveedores Sugeridos</h4>
                                    {!solicitudShowEdit.disabledCotizacion	? (
                                      <button
                                        className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                                        type="button"
                                        hidden = {!ocultarAgregarGlobal}
                                        onClick={() => nuevoProveedor(index)}>
                                        Agregar Proveedor
                                      </button>
                                    ) : null}
                                    <table className="table table-hover table-centered table-nowrap mb-10">
                                      <thead>
                                        <tr>
                                          <th scope="col">Nombre</th>
                                          <th scope="col">Contacto</th>
                                          <th scope="col">Correo</th>
                                          {!solicitudShowEdit.hiddenCotizacion ? (
                                            <>
                                              <th scope="col">
                                                Precio<br></br>Unitario<span className="text-xs text-red-700">*</span>
                                              </th>
                                              <th scope="col">
                                                Piezas<br></br>Disponibles<span className="text-xs text-red-700">*</span>
                                              </th>
                                              <th scope="col">
                                                Comentario<br></br>Compras<span className="text-xs text-red-700">*</span>
                                              </th>
                                              <th scope="col">
                                                Sugerencia<br></br>Compras
                                              </th>
                                            </>
                                          ) : null}
                                          {!solicitudShowEdit.hiddenCotizacion &&
                                          solicitudCompraSeleccionada.productoSustitutoPermitido ? (
                                            <th scope="col">
                                              Producto<br></br>Sustituto
                                            </th>
                                          ) : null}
                                          {!solicitudShowEdit.hiddenArchivosCotizacion &&
                                          !solicitudShowEdit.disabledArchivosCotizacion ? (
                                            <th scope="col">
                                              Cotización<br></br>Proveedor
                                            </th>
                                          ) : null}
                                          {!solicitudShowEdit.hiddenDecision ? (
                                            <th colSpan={2} scope="col">
                                              Decisión<span className="text-xs text-red-700">*</span>
                                            </th>
                                          ) : null}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {producto.solicitudcompraproveedor &&
                                          producto.solicitudcompraproveedor.map((proveedor, indexProveedor) => {
                                            return (
                                              <>
                                             <tr>

                                                  <td style={{ width: "15%" }}>                                                                                                     
                                                     {(producto.idCatProducto || producto.catProductos) && usuario.authorities.includes("ROLE_COMPRAS") &&
                                                     solicitudCompraSeleccionada.estatussolicitudcompra && !solicitudShowEdit.disabledCotizacion? (
                                                    <Select
                                                      isSearchable
                                                      autoComplete="off" 
                                                      options={proveedores}
                                                      getOptionLabel={(option) => option.proveedor}
                                                      value={
                                                        !proveedor.catProveedores?
                                                        proveedores.filter((prov) => prov.proveedor == proveedor.proveedor) :
                                                        proveedores.filter((prov) => prov.proveedor == proveedor.catProveedores.proveedor) 
                                                      }
                                                      onChange={(option) => setProveedor(option, index, indexProveedor)}
                                                      noOptionsMessage={() => "No hay opciones"}
                                                      placeholder="Seleccione una opción"                                                      
                                                    />
                                                  ):(proveedor.catProveedores? proveedor.catProveedores.proveedor:  proveedor.proveedor)}
                                                  </td>
                                                  <td>
                                                    {proveedor.catProveedores? proveedor.catProveedores.contacto : proveedor.contacto }
                                                  </td>
                                                  <td>
                                                    {proveedor.catProveedores? proveedor.catProveedores.correo: proveedor.correo}
                                                  </td>

                                                  {!solicitudShowEdit.hiddenCotizacion && solicitudShowEdit.disabledCotizacion ? (
                                                    <>
                                                      <td>{proveedor.precio ? formatNumber(proveedor.precio) : `$0`} </td>
                                                      <td>{proveedor.piezasSolicitadas ? proveedor.piezasSolicitadas : ""}</td>
                                                      <td>
                                                        <textarea
                                                          rows={8}
                                                          cols={20}
                                                          autoComplete="off" 
                                                          className="form-control"
                                                          value={proveedor.comentario ? proveedor.comentario : ""}
                                                          disabled
                                                        />
                                                      </td>
                                                      <td>
                                                        <input type="checkbox" checked={proveedor.sugerido} disabled />
                                                      </td>
                                                    </>
                                                  ) : null}

                                                  {!solicitudShowEdit.hiddenCotizacion &&
                                                  !solicitudShowEdit.disabledCotizacion ? (
                                                    <>
                                                      <td style={{ width: "10%" }}>
                                                        <input
                                                          type="text"
                                                          autoComplete="off" 
                                                          // onBlur={() => formatOnBlur(proveedor)}
                                                          onChange={(e) => changePrecioProveedor(e, proveedor,index, indexProveedor)}
                                                          className="form-control"
                                                          value={proveedor.precio ? `$${proveedor.precio}` : `$`}
                                                        />
                                                        {(proveedor.precio === "" || !proveedor.precio) && (
                                                          <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                                        )}
                                                      </td>
                                                      <td style={{ width: "5%" }}>
                                                        <input
                                                          type="number"
                                                          autoComplete="off" 
                                                          className="form-control"
                                                          value={proveedor.piezasSolicitadas ? proveedor.piezasSolicitadas : ""}
                                                          name="piezasSolicitadas"
                                                          onChange={(e) =>
                                                            changeProveedorPiezasSolicitadas(e, proveedor, index)
                                                          }
                                                          min={1}
                                                          onWheel={(e) => e.target.blur()}
                                                        />
                                                        {(proveedor.piezasSolicitadas === "" || !proveedor.piezasSolicitadas) && (
                                                          <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                                        )}
                                                      </td>
                                                      <td>
                                                        <textarea
                                                          rows={8}
                                                          cols={20}
                                                          autoComplete="off" 
                                                          className="form-control"
                                                          value={proveedor.comentario ? proveedor.comentario : ""}
                                                          onChange={(e) => changeComentarioProveedor(e, proveedor)}
                                                        />
                                                        {(proveedor.comentario === "" || !proveedor.comentario) && (
                                                          <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                                        )}
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="checkbox"
                                                          autoComplete="off" 
                                                          checked={proveedor.sugerido}
                                                          onClick={() => changeProveedorSugerido(producto, proveedor)}
                                                        />
                                                      </td>
                                                    </>
                                                  ) : null}
                                                  {!solicitudShowEdit.hiddenCotizacion &&
                                                  solicitudCompraSeleccionada.productoSustitutoPermitido ? (
                                                    <td>
                                                      {solicitudShowEdit.cotizarProductoSustituto ||
                                                      (!solicitudShowEdit.cotizarProductoSustituto &&
                                                        proveedor.solicitudcompraproductosustituto != null) ? (
                                                        <button
                                                          className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                                          type="button"
                                                          autoComplete="off" 
                                                          onClick={() => {
                                                          setProductoSeleccionado(producto);
                                                          setProveedorSeleccionado(proveedor);
                                                          setProductoSustitutoAuxliar(proveedor);
                                                          setUISweetAlert({
                                                              ...UISweetAlert,
                                                              confirm_alert_producto_sustituto: true,
                                                            });
                                                          }}>
                                                          {/* {!proveedor.solicitudcompraproductosustituto ? "Nuevo":"Producto"} */}
                                                          {proveedor.solicitudcompraproductosustituto
                                                            ? [
                                                                proveedor.solicitudcompraproductosustituto.catProductos ||
                                                                proveedor.solicitudcompraproductosustituto.idCatProducto
                                                                  ? "Producto"
                                                                  : "Nuevo",
                                                              ]
                                                            : "Nuevo"}
                                                        </button>
                                                      ) : null}
                                                    </td>
                                                  ) : null}

                                                  {!solicitudShowEdit.hiddenArchivosCotizacion &&
                                                  !solicitudShowEdit.disabledArchivosCotizacion ? (
                                                    <td style={{ width: "10%" }}>
                                                      <input
                                                        autoComplete="off" 
                                                        className="form-control"
                                                        type="file"
                                                        onChange={(e) => uploadFile(e, proveedor)}
                                                        multiple
                                                      />
                                                    </td>
                                                  ) : null}                                              

                                                  {!solicitudShowEdit.hiddenDecision ? (
                                                    <>
                                                      <td style={{ width: "1%" }}>
                                                        {proveedor.decision!=null ? (
                                                          proveedor.decision==true ? 
                                                          <i className="ti-check"></i> 
                                                          : <i className="ti-close"></i>
                                                        ) : (
                                                          <label className="col-sm-3 col-form-label"> ¿ ? </label>
                                                        )}
                                                      </td>
                                                      <td>
                                                        <button
                                                          type="submit"
                                                          onClick={() => {
                                                            setProveedorSeleccionado(proveedor);
                                                            setProductoSeleccionado(producto);
                                                            setUISweetAlert({
                                                              ...UISweetAlert,
                                                              confirm_alert_decision_proveedor: true,
                                                            });
                                                          }}
                                                          className="btn btn-primary-orange-umo hover:text-white">
                                                          Decisión
                                                        </button>
                                                      </td>
                                                    </>
                                                  ) : null}
                                                  <td>
                                                    {/*(producto.idCatProducto || producto.catProductos) && usuario.authorities.includes("ROLE_COMPRAS") &&
                                                     solicitudCompraSeleccionada.estatussolicitudcompra && !solicitudShowEdit.disabledCotizacion? (
                                                        <button
                                                          type="button"
                                                          className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                                          onClick={() => {
                                                            setIndexProductoBorrado(index);
                                                            setIndexProveedorBorrado(indexProveedor);
                                                            
                                                            setUISweetAlert({
                                                              ...UISweetAlert,
                                                              confirm_alert_delete_producto: true,
                                                            });
                                                          }}>
                                                          Eliminar
                                                        </button>
                                                        ) : null*/}      
                                                  </td>                                                  
                                                </tr>
                                              </>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <button
                type="submit"
                onClick={() =>
                  setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_guardar: true,
                  })
                }
                hidden={!solicitudShowEdit.guardarSolicitud}
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                Guardar
              </button>

              <button
                type="submit"
                hidden={!solicitudShowEdit.solicitarAutorizacion}
                onClick={confirmSolicitudCompraSolicitarAutorizacion}
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                Solicitar Autorización
              </button>

              <button
                type="submit"
                hidden={!solicitudShowEdit.finalizarAutorizacion}
                onClick={confirmSolicitudCompraFinalizarAutorizacion}
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                Finalizar Autorización
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mt-4 w-full">
        <Card>
          <Row>
            <Col md="12">
              <table
                className="w-2/6"
                style={{
                  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  backgroundColor: "inherit",
                  margin: "0",
                  float: "right",
                }}
                bgcolor="#f6f6f6">
                <tbody>
                  <tr
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                        textAlign: "left",
                      }}
                      valign="top">
                      Sub-Total
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(parseFloat(detallesPrecio.subtotal))}
                      {/* ${detallesPrecio.subtotal} */}
                    </td>
                  </tr>
                  <tr
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                        textAlign: "left",
                      }}
                      valign="top">
                      IVA
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(parseFloat(detallesPrecio.iva))}
                      {/* ${detallesPrecio.iva} */}
                    </td>
                  </tr>
                  <tr
                    className="total"
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      width="80%"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "left",
                        borderTopWidth: "5px",
                        borderTopColor: "#333",
                        borderTopStyle: "double",
                        borderBottomColor: "#333",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        fontWeight: "700",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="left"
                      valign="top">
                      Total
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "5px",
                        borderTopColor: "#333",
                        borderTopStyle: "double",
                        borderBottomColor: "#333",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        fontWeight: "700",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(parseFloat(detallesPrecio.total))}
                      {/* ${detallesPrecio.total} */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Card>
      </Card>

      {UISweetAlert.confirm_alert_decision_proveedor ? (
        <SweetAlert
          title="Decisión del proveedor"
          warning
          showCancel={true}
          showConfirm={true}
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{width: width && width < 851 ? "100%" : "80%",}}
          onCancel={() => {
            proveedorSeleccionado.decision = null;
            proveedorSeleccionado.piezasCompradas = null;
            proveedorSeleccionado.motivo = null;     
            setProveedorSeleccionado(proveedorSeleccionado)       
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_decision_proveedor: false,
            });
          }}         
          onConfirm={() => {
            confirmModicarDatos();
          }}>
          <div>
            <FormControlLabel
              control={
                <Switch
                  onChange={changeProveedorDecision}
                  checked={proveedorSeleccionado.decision}
                  disabled={solicitudShowEdit.disabledDecision}
                  color="primary"
                  name="decision"
                />
              }
              label={proveedorSeleccionado.decision ? "Aprobado" : "Rechazado"}
            />
          </div>
          {proveedorSeleccionado.decision ? (
            <Row>
              <Col>
                <label className="col-sm-4 col-form-label">{productoSeleccionado.catProductos.nombreComercial}</label>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Piezas Solicitadas</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="number"
                      className="form-control"
                      value={productoSeleccionado.piezas ? productoSeleccionado.piezas : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Piezas Disponibles Proveedor</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="number"
                      className="form-control"
                      value={proveedorSeleccionado.piezasSolicitadas ? proveedorSeleccionado.piezasSolicitadas : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">
                    {proveedorSeleccionado.solicitudcompraproductosustituto ? "Piezas Producto Original" : "Piezas a Adquirir"}
                    <span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="number"
                      className="form-control"
                      name="piezasCompradas"
                      disabled={solicitudShowEdit.disabledDecision}
                      onChange={changeProveedorDecision}
                      value={proveedorSeleccionado.piezasCompradas ? proveedorSeleccionado.piezasCompradas : ""}
                      onWheel={(e) => e.target.blur()}
                      min={1}
                    />
                    {(proveedorSeleccionado.piezasCompradas <= 0 || !proveedorSeleccionado.piezasCompradas) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                {proveedorSeleccionado.solicitudcompraproductosustituto ? (
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Piezas Producto Sustituto</label>
                    <Col sm={7}>
                      <input
                        type="number"
                        className="form-control"
                        name="piezasCompradasProductoSustituto"
                        disabled={solicitudShowEdit.disabledDecision}
                        onChange={changeProveedorDecision}
                        value={
                          proveedorSeleccionado.solicitudcompraproductosustituto.piezasCompradas
                            ? proveedorSeleccionado.solicitudcompraproductosustituto.piezasCompradas
                            : ""
                        }
                        onWheel={(e) => e.target.blur()}
                        min={1}
                      />
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
              <label className="col-sm-4 col-form-label">{productoSeleccionado.catProductos.nombreComercial}</label>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">
                    Motivo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <textarea
                      rows={3}
                      className="form-control"
                      name="motivo"
                      disabled={solicitudShowEdit.disabledDecision}
                      value={proveedorSeleccionado.motivo ? proveedorSeleccionado.motivo : ""}
                      onChange={changeProveedorDecision}
                    />
                    {(proveedorSeleccionado.motivo === "" || !proveedorSeleccionado.motivo) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_guardar ? (
        <SweetAlert
          title="¿Desea guardar los cambios en la solicitud de compra?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadCambiosSolicitudCompra()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar: false,
            })
          }>
          Se guardarán todos los cambios realizados en la solicitud de compra
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_solicitar_autorizacion ? (
        <SweetAlert
          title="¿Desea pedir la autorización de la solicitud de compra?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => submitSolicitarAutorizacion()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_solicitar_autorizacion: false,
            })
          }>
          Se guardarán los cambios en la solicitud de compra y se pedirá la autorización de la misma
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_finalizar_autorizacion ? (
        <SweetAlert
          title="¿Desea finalizar la autorización de la solicitud de compra?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => submitFinalizaAutorizacion()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_finalizar_autorizacion: false,
            })
          }>
          Se guardarán los cambios en la solicitud de compra y se finalizará la autorización
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_cancelar_solicitud ? (
        <SweetAlert
          title="¿Desea cancelar la solicitud de compra?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          onConfirm={() => solicitudCompraCancelar()}
          onCancel={() => {
            setCancelarSolicitudCompra({
              ...cancelarSolicitudCompra,
              comentarioEstatus: "",
            });
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_cancelar_solicitud: false,
            });
          }}>
          <div>
            <Row>
              <Col>Llene por favor la siguiente información</Col>
            </Row>

            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Motivo</label>
              <Col sm={7}>
                <textarea
                  rows={6}
                  className="form-control"
                  name="comentarioEstatus"
                  value={cancelarSolicitudCompra.comentarioEstatus}
                  onChange={onChangeCancelarSolicitudCompra}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Fecha Cancelación</label>
              <Col sm={7}>
                <input
                  type="date"
                  className="form-control"
                  disabled={true}
                  name="fechaCancelacion"
                  value={cancelarSolicitudCompra.fechaCancelacion}
                />
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_delete_producto ? (
          <SweetAlert
            title="¿Desea eliminar el insumo?"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => eliminarProducto()}
            onCancel={() => {
              setIndexProductoBorrado(null);
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_delete_producto: false,
              });
            }}>
            Se perderá la información
          </SweetAlert>
        ) : null}

        {UISweetAlert.confirm_alert_rechazo ? (
          <SweetAlert
            title="¿Confirma la decisión de rechazar?"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => confirmaCancelacion()}
            style={{width: width && width < 851 ? "70%" : "40%",}}
            onCancel={() => {
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_rechazo: false,
                confirm_alert_decision_proveedor : false,
              });
            }}>
            confirme la decisión de rechazar al proveedor
          </SweetAlert>
        ) : null}

      {UISweetAlert.confirm_alert_producto_sustituto ? (
        <SweetAlert
          title="Producto Sustituto"
          warning
          showCancel
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          closeOnClickOutside={false}
          showConfirm={solicitudShowEdit.disabledProductoSustituto ? false : true}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            checkProductoSustitutoCompleto();
          }}
          onCancel={() => {
            setProveedorSeleccionado(null);
            setProductoSeleccionado(null);
            setAuxProductoSustituto({});
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_producto_sustituto: false,
            });
          }}>
          <div>
            <Row hidden={solicitudShowEdit.disabledProductoSustituto ? true : false}>
              <Col>
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4"
                  type="button"
                  onClick={() => {
                    setAuxProductoSustituto({});
                  }}>
                  Eliminar Producto
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Nuevo Producto</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      disabled={solicitudShowEdit.disabledProductoSustituto}
                      name="descripcion"
                      onChange={(e) => changeProductoSustitutoProveedor(e)}
                      onClick={checkProductosFilter}
                      value={auxProductoSustituto.descripcion ? auxProductoSustituto.descripcion : ""}
                      list={`listaProductos`}
                      autoComplete="off"
                    />
                    <datalist id={`listaProductos`}>
                      {productosFilter.map((prodServ) => {
                        return (
                          <>
                            {
                              <option value={`${prodServ.nombreComercial} - ${prodServ.numIdentificacion}`}>
                                {prodServ.nombreComercial} - {prodServ.numIdentificacion} -{prodServ.codigoEAN} -{" "}
                                {prodServ.principioActivo}
                              </option>
                            }
                          </>
                        );
                      })}
                    </datalist>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Precio Unitario</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="precio"
                      disabled={solicitudShowEdit.disabledProductoSustituto}
                      onChange={(e) => changeProductoSustituto(e)}
                      value={auxProductoSustituto.precio ? `$${auxProductoSustituto.precio}` : `$`}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Piezas Disponibles</label>
                  <Col sm={7}>
                    <input
                      className="form-control"
                      type="number"
                      min={1}
                      name="piezas"
                      onWheel={(e) => e.target.blur()}
                      disabled={solicitudShowEdit.disabledProductoSustituto}
                      onChange={(e) => changeProductoSustituto(e)}
                      value={auxProductoSustituto.piezas ? auxProductoSustituto.piezas : ""}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
