import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Card, CardBody, Alert, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// import ReCAPTCHA from "react-google-recaptcha";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import logoSm from "../assets/images/logo-umo.png";

import { Link } from "react-router-dom";
import AuthContext from "../context/Auth/AuthContext";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function Login(props) {
  const [UISweetAlert, setUISweetAlert] = useState({
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  // eslint-disable-next-line no-unused-vars
  const [isVerified, setIsVerified] = useState(false);
  // const [intentos, setIntentos] = useState(0);

  const [loading, setLoading] = useState(false);

  const authContext = useContext(AuthContext);
  const { autenticado, iniciarSesion } = authContext;

  const [error, setError] = useState(null);

  const [usuario, guardarUsuario] = useState({
    user: "",
    password: "",
  });

  // Extraer de usuario
  const { user, password } = usuario;

  const onChange = (e) => {
    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const handleInicioSesion = async (e) => {
    //const recaptchaToken = await executeRecaptcha("submit");
    const recaptchaToken = null

    e.preventDefault();

    setLoading(true);
    const res = await iniciarSesion({ user, password, recaptchaToken });
    guardarUsuario({
      ...usuario,
      [password]: "",
    });
    if (res.data.error) {
      setError(true);
      // const intentosTemp = intentos + 1;
      // setIntentos(intentosTemp);
      setLoading(false);
 
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    }
  };

  const [resetCaptcha, setResetCaptcha] = useState(false);

  useEffect(() => {
    if (resetCaptcha) {
      executeRecaptcha();
      setResetCaptcha(false);
    }
  }, [resetCaptcha, executeRecaptcha]);

  const handleRefreshClick = () => {
    setResetCaptcha(true);
  };

  // const onChangeReCaptcha = async (value) => {
  //   if (value == null) {
  //     setIsVerified(false);
  //   } else {
  //     setIsVerified(true);
  //     // const res = await checkReCaptchaToken(value);
  //     // console.log(res);
  //   }
  // };

  useEffect(() => {}, [isVerified]);

  useEffect(() => {
    if (autenticado) {
      props.history.push("/");
    }
  }, [autenticado]);

  return (
    <>
      <div className="account-pages my-5 pt-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="position-relative">
                {/* {props.loading ? <Loader /> : null} */}

                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20">Bienvenid@ a UMO</h5>
                      <p className="text-white-50 font-bold">Ingresa tus datos para continuar</p>
                      <Link to="/agenda" className="logo logo-admin">
                        <img src={logoSm} height="24" alt="logo" className=" umo-logo" />
                      </Link>
                    </div>
                  </div>

                  <CardBody className="p-4">
                    <div className="p-3">
                      <AvForm className="form-horizontal mt-4">
                        {false ? <Alert color="danger">{}</Alert> : null}
                        <div className="form-group">
                          <AvField
                            // disabled={intentos >= 3}
                            name="user"
                            label="Usuario"
                            className="form-control"
                            onChange={onChange}
                            value={user}
                            type="text"
                            validate={{
                              required: { value: true, errorMessage: "No dejes espacios en blanco" },
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            // disabled={intentos >= 3}
                            name="password"
                            label="Contraseña"
                            type="password"
                            required
                            onChange={onChange}
                            value={password}
                            placeholder="Introduce tu contraseña"
                          />
                        </div>
                        <Row className="form-group">
                          {/* <Col sm={6}>
                              &nbsp; */}
                          {/* <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" for="customControlInline">Remember me</label>
                                                        </div> */}
                          {/* </Col> */}
                          {/* <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} /> */}
                          <Col
                            className="form-group row w-full"
                            style={{
                              flexDirection: "row-reverse",
                              marginLeft: "0",
                            }}>
                            <button
                              // hidden={!isVerified}
                              // disabled={!isVerified}
                              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                              type="button"
                              onClick={(e) => handleInicioSesion(e)}>
                              Iniciar sesión
                            </button>
                          </Col>
                        </Row>
                        {error ? (
                          <Row className="form-group">
                            Por favor, intenta recargar el recaptcha si hay algún error.
                            <Col className="form-group row w-full ">
                              <button
                                // hidden={!isVerified}
                                // disabled={!isVerified}
                                className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                type="button"
                                onClick={() => handleRefreshClick()}>
                                Recargar captcha
                              </button>
                            </Col>
                          </Row>
                        ) : null}
                        This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                      </AvForm>
                      {/* <ReCAPTCHA sitekey={"6LfzMN8hAAAAANjhJa-d-RmwrIdxmL8HSEnOh9IB"} onChange={onChangeReCaptcha} /> */}
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div className="mt-5 text-center">
                <p className="mb-0">© {new Date().getFullYear()} Unidad Médica Oncológica</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
  <div className="max-w-md w-full space-y-8">
    <div>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Iniciar sesión
      </h2>
    </div>
    <form className="mt-8 space-y-6" action="#" method="POST">
      <input type="hidden" name="remember" value="true" />
      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label for="email-address" className="sr-only">Correo electrónico</label>
          <input id="email-address" name="email" type="email" autocomplete="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" />
        </div>
        <div>
          <label for="password" className="sr-only">Password</label>
          <input id="password" name="password" type="password" autocomplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" />
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 text-indigo-600  focus:ring-indigo-500 border-gray-300 rounded" />
          <label for="remember_me" className="ml-2 block text-sm text-gray-900">
            Recordar cuenta
          </label>
        </div>

        <div className="text-sm">
          <Link href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
            Recuperar contraseña
          </Link>
        </div>
      </div>

      <div>
        <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </span>
          Acceder
        </button>
      </div>
    </form>
  </div>
</div> */}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" onConfirm={() => {}} showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </>
  );
}
