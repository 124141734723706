import React, {useReducer} from 'react'

import AnaquelesContext from './AnaquelesContext'
import AnaquelesReducer from './AnaquelesReducer'

import {
    LISTAR_ANAQUELES,
    LIMPIAR_ANAQUELES
} from '../../types'

import clienteAxios from '../../config/axios'


export default function AnaquelesState (props) {

    const initialState = { 
        anaqueles:[],
        almacenSeleccionado:{},
    }

    const [state, dispatch] = useReducer(AnaquelesReducer,initialState)

    const seleccionarAlmacen = async (idCatAlmacen) =>{
        const res = await clienteAxios.get(`/almacen/detalles?id=${idCatAlmacen}`, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
        dispatch({
            type: LISTAR_ANAQUELES,
            payload: res.data
        })

    }

    const uploadAnaquel = async (anaquel) => {
        try{

            const res = await clienteAxios.post("/anaquel/guardar", {...anaquel, almacen: {...state.almacenSeleccionado, responsable: null}}, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                    }
            })
    
            seleccionarAlmacen(state.almacenSeleccionado.idCatAlmacen);
            
            return res;

        }catch (error){

            if(!error.response){
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error:"hubo error"
                    }
                }
            }

            return error.response;
        }
    }

    const seleccionarAlmacenByAnaquel = async (idCatAnaquel) => {
        const res = await clienteAxios.get(`/almacen/anaquel/detalles?id=${idCatAnaquel}`, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
        dispatch({
            type: LISTAR_ANAQUELES,
            payload: res.data
        })
    }

    const limpiarAnaqueles = async() => {
        dispatch({
            type: LIMPIAR_ANAQUELES,
            payload: null
        })
    }

    const uploadDeleteAnaquel = async (anaquel) => {
        try{

            const res = await clienteAxios.post("/anaquel/eliminar", anaquel, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                    }
            })
    
            seleccionarAlmacen(state.almacenSeleccionado.idCatAlmacen);
            
            return res;

        }catch (error){

            if(!error.response){
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error:"hubo error"
                    }
                }
            }

            return error.response;
        }
    }

    return (

        <AnaquelesContext.Provider value={{
            anaqueles: state.anaqueles,
            almacenSeleccionado: state.almacenSeleccionado,
            seleccionarAlmacen,
            uploadAnaquel,
            seleccionarAlmacenByAnaquel,
            limpiarAnaqueles,
            uploadDeleteAnaquel
        }}>
            {props.children}
        </AnaquelesContext.Provider>

    )


}