import React, { useContext } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col } from "reactstrap";
// import useCloseWindowWarning from "../../../hooks/useCloseWindowWarning";

import { maxCamposAntecedentesGineco } from "../../../config/variables";

import WindowWarningContext from "../../../context/WindowWarning/WindowWarningContext";

export default function AntecedentesGinecoobstetricos({ props, historiaClinicaInicial, setHistoriaClinicaInicial }) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito } = windowWarningContext;

  const onChange = async (e) => {
    if (!seEdito) changeEdit(true);
    setHistoriaClinicaInicial({
      ...historiaClinicaInicial,
      antecedentesGinecoobstetricos: {
        ...historiaClinicaInicial.antecedentesGinecoobstetricos,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <div className="mt-4 container-fluid" style={{ textAlign: "center" }}>
      <Row>
        <h4>Antecedentes Ginecoobstétricos</h4>
      </Row>
      <Row>
        <Col>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Menarca</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="menarca"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos &&
                  historiaClinicaInicial.antecedentesGinecoobstetricos.menarca
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.menarca
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">FUM</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="fum"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos && historiaClinicaInicial.antecedentesGinecoobstetricos.fum
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.fum
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Menopausia</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="menopausia"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos &&
                  historiaClinicaInicial.antecedentesGinecoobstetricos.menopausia
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.menopausia
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Método de Planificación</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="metodoPlanificacion"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos &&
                  historiaClinicaInicial.antecedentesGinecoobstetricos.metodoPlanificacion
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.metodoPlanificacion
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Mastografía</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="mastografia"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos &&
                  historiaClinicaInicial.antecedentesGinecoobstetricos.mastografia
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.mastografia
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Ultrasonido</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="ultrasonido"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos &&
                  historiaClinicaInicial.antecedentesGinecoobstetricos.ultrasonido
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.ultrasonido
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>

          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Papanicolau</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="papanicolau"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos &&
                  historiaClinicaInicial.antecedentesGinecoobstetricos.papanicolau
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.papanicolau
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Gestaciones</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="g"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos && historiaClinicaInicial.antecedentesGinecoobstetricos.g
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.g
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Partos</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="p"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos && historiaClinicaInicial.antecedentesGinecoobstetricos.p
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.p
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Cesáreas</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="c"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos && historiaClinicaInicial.antecedentesGinecoobstetricos.c
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.c
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Abortos</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="a"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos && historiaClinicaInicial.antecedentesGinecoobstetricos.a
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.a
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Otros</label>
            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesGineco}
                name="otros"
                value={
                  historiaClinicaInicial.antecedentesGinecoobstetricos &&
                  historiaClinicaInicial.antecedentesGinecoobstetricos.otros
                    ? historiaClinicaInicial.antecedentesGinecoobstetricos.otros
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* {Prompt} */}
    </div>
  );
}
