/* eslint-disable import/no-anonymous-default-export */
import {
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    TOKEN_EXPIRADO
} from '../../types'

export default (state, action) => {
    switch(action.type){

        case LOGIN_EXITOSO:
            localStorage.setItem('token', action.payload.token)

            return{
                ...state,
                // autenticado: true,
                // mensaje: null

                autenticado: true,
                // usuario: action.payload,
                cargando: false
            }

        case CERRAR_SESION:
        case LOGIN_ERROR:    
        case TOKEN_EXPIRADO:
            localStorage.removeItem('token')
            
            return {
                ...state,
                token: null,
                usuario: null,
                autenticado: null,
                mensaje: null,
                cargando: false
            }

        case OBTENER_USUARIO:
            return{
                ...state,
                autenticado: true,
                usuario: action.payload,
                cargando: false
            }

        default:
            return state
    }
}