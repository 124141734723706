import React, {useReducer} from 'react'

import EstatusOrdenCompraContext from './EstatusOrdenCompraContext'
import EstatusOrdenCompraReducer from './EstatusOrdenCompraReducer'

import {
    LISTAR_ESTATUSORDENCOMPRAS,
} from '../../types'

import clienteAxios from '../../config/axios'


export default function EstatusOrdenCompraState (props) {

    const initialState = { 
        estatusOrdenCompra:[],
    }

    const [state, dispatch] = useReducer(EstatusOrdenCompraReducer,initialState)

    const listarEstatusOrdenCompra = async () => {
        const res = await clienteAxios.get("/estatusordencompra/activos/" , {
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
        })

  
        dispatch({
            type: LISTAR_ESTATUSORDENCOMPRAS,
            payload: res.data
        })

    }

    return (

        <EstatusOrdenCompraContext.Provider value={{
            estatusOrdenCompra: state.estatusOrdenCompra,
            listarEstatusOrdenCompra,
        }}>
            {props.children}
        </EstatusOrdenCompraContext.Provider>

    )


}