/* eslint-disable import/no-anonymous-default-export */
import {
  LISTAR_PACIENTES,
  FILTRO_PACIENTES,
  LISTAR_PACIENTES_ACTIVOS,
  ID_PACIENTE_SELECCIONADO,
  LISTAR_MARCADORES_PACIENTE,
  LISTAR_ARCHIVOS_IMAGEN,
  LISTAR_ARCHIVOS_NO_IMAGEN,
  SET_EXPEDIENTE_MEDICO_PACIENTE,
  LISTAR_PACIENTES_SELECT,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case SET_EXPEDIENTE_MEDICO_PACIENTE:
      localStorage.setItem("idExpedienteMedicoDelPaciente", action.payload);
      return {
        ...state,
        idExpedienteMedicoDelPaciente: action.payload,
      };
    case LISTAR_PACIENTES:
      return {
        ...state,
        pacientes: action.payload,
      };
    case LISTAR_PACIENTES_SELECT:
      return {
        ...state,
        pacientesSelect: action.payload,
      };
    case LISTAR_ARCHIVOS_IMAGEN:
      return {
        ...state,
        archivosPacienteImagen: action.payload,
      };
    case LISTAR_ARCHIVOS_NO_IMAGEN:
      return {
        ...state,
        archivosPacienteNoImagen: action.payload,
      };
    case LISTAR_PACIENTES_ACTIVOS:
      return {
        ...state,
        pacientesActivos: action.payload,
      };
    case FILTRO_PACIENTES:
      return {
        ...state,
        filtrosPacientes: action.payload,
      };
    case ID_PACIENTE_SELECCIONADO:
      localStorage.setItem("idCatPacienteSeleccionado", action.payload);
      return {
        ...state,
        idCatPacienteSeleccionado: action.payload,
        flagPacienteSeleccionado: true,
      };
    case LISTAR_MARCADORES_PACIENTE:
      return {
        ...state,
        marcadores: action.payload,
      };
    default:
      return state;
  }
};
