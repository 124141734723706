import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// import AuthContext from "../../context/Auth/AuthContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";

import MedicamentoFalsoModal from "../MedicamentoFalsoModal";

const MedicamentoFalso = (props) => {
  const [UISweetAlert, setUISweetAlert] = useState({
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  // Se pone la fecha de hoy
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const datosCargaArchivoVacio = {
    fecha: today,
    selectedFile: null,
    comentario: null,
  };

  const [datosCargaArchivo, setDatosCargaArchivo] = useState({
    fecha: today,
    selectedFile: null,
    comentario: null,
  });

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  // const ctxAuth = useContext(AuthContext);
  // const { usuario } = ctxAuth;

  //const autorizado = usuario.authorities.includes("OP_CARGAR_ARCHIVO_MEDICAMENTO_FALSO");

  // const autorizado = usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") || usuario.authorities.includes("ROLE_FARMACIA");

  const catProductosContext = useContext(CatProductosContext);

  const { uploadDocumentoMedicamentoFalso, setDocumentoPrevio, documentoPrevio, exportFormatoMedicamentoFalso } =
    catProductosContext;

  useEffect(() => {
    if (documentoPrevio === null) setDocumentoPrevio(2);
  }, []);

  const onFileChange = (event) => {
    // setDatosCargaArchivo({ ...datosCargaArchivo, selectedFile: event.target.files[0] });
    const file = event.target.files[0];
    const reader = new FileReader();

    // convert image file to base64 string

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string

        let strFile = reader.result;
        let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
        let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);
        setDatosCargaArchivo({
          ...datosCargaArchivo,
          selectedFile: file,
          nombreArchivo: file.name,
          mimeType: type,
          tamano: file.size,
          inputStream: fileBase64,
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const changeCargaArchivo = (e) => {
    const { name, value } = e.target;
    setDatosCargaArchivo({
      ...datosCargaArchivo,
      [name]: value,
    });
  };

  const validarDatosArchivo = () => {
    let errorDatosArchivo = false;
    if (!errorDatosArchivo && datosCargaArchivo.selectedFile === null) {
      errorDatosArchivo = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, cargue un archivo de evidencia",
      });
    }
    if (!errorDatosArchivo && (datosCargaArchivo.comentario === null || datosCargaArchivo.comentario === "")) {
      errorDatosArchivo = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un comentario",
      });
    }
    return errorDatosArchivo;
  };

  const onFileUpload = async () => {
    const datosValidos = validarDatosArchivo();
    if (!datosValidos) {
      delete datosCargaArchivo.selectedFile;
      const res = await uploadDocumentoMedicamentoFalso({
        ...datosCargaArchivo,
        idProductoCompraExterna: props.idProductoCompraExterna,
      });
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        dynamic_description: "Se guardó correctamente el archivo y el medicamento ha quedado marcado como falso.",
      });
      props.fetchData();
      setDatosCargaArchivo(datosCargaArchivoVacio);
      return res;
    }
  };

  const infoSweetAlert = { title: "Medicamento Falso", confirmBtnText: "Guardar", cancelBtnText: "Cancelar" };
  const onCancel = () => {
    props.onCancel();
    setDatosCargaArchivo(datosCargaArchivoVacio);
  };
  return (
    <>
      <MedicamentoFalsoModal
        datosCargaArchivo={datosCargaArchivo}
        setDatosCargaArchivo={setDatosCargaArchivo}
        infoSweetAlert={infoSweetAlert}
        onFileUpload={onFileUpload}
        onCancel={onCancel}
        onFileChange={(e) => onFileChange(e)}
        onChange={(e) => changeCargaArchivo(e)}
        exportFormato={exportFormatoMedicamentoFalso}
        documentoPrevio={documentoPrevio}
      />
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            global.ventanaDocumentoFalso  = false;
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </>
  );
};

export default MedicamentoFalso;
