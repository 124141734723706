import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import formatNumber from "../../utils/index";

import CortesCajaContext from "../../context/CortesCaja/CortesCajaContext";

import FiltroFolio from "../Filtros/FiltroFolio/FiltroFolio";
import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";

import AuthContext from "../../context/Auth/AuthContext";
import CatTipoCorteCajaContext from "../../context/CatTipoCorteCaja/CatTipoCorteCajaContext";

export default function ListadoCortesCaja(props) {
  const [toggleSearchBy, setToggleSearchBy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [corteCajaSeleccionado, setCorteCajaSeleccionado] = useState(null);
  const [comentarioCorteCaja, setComentarioCorteCaja] = useState("");
  const [corteCajaDecision, setCorteCajaDecision] = useState(false);
  const [estatusAutorizado, setEstatusAutorizado] = useState(false);

  // confirm_alert_nuevo_pago: false,
  // confirm_alert_eliminar_cobro: false,
  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_aprobar_corte_caja: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  // ACCESO AL ESTADO GLOBAL - AUTH
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const cortesCajaContext = useContext(CortesCajaContext);
  const catTipoCorteCaja = useContext(CatTipoCorteCajaContext);

  const {
    cortesCajaFiltrados,
    filtrosCortesCaja,
    listarCortesCaja,
    setFiltrosCortesCaja,
    setCortesCajaFiltrados,
    cambiarStatusCorteCaja,
  } = cortesCajaContext;

  const { listarTiposCorteCaja, tipoCorteCaja } = catTipoCorteCaja;

  const limpiarFiltros = () => {
    setFiltrosCortesCaja({
      fechaInicial: "",
      estatusCorte: "",
      fechaFinal: "",
      folio: "",
      importe: "",
    });
  };

  const buscarPorFolio = (e) => {
    e.preventDefault();
    listarCortesCaja({
      folio: filtrosCortesCaja.folioCorteCaja,
      role: usuario.nombre,
    });
    habilitarCortesCaja();
  };

  const toggleSearch = () => {
    setToggleSearchBy(!toggleSearchBy);
    limpiarFiltros();
  };

  const buscarPorFiltros = (e) => {
    e.preventDefault();
    buscarCortesCaja();
  };

  const buscarCortesCaja = () => {
    if (filtrosCortesCaja.fechaInicial && !filtrosCortesCaja.fechaFinal) {
      return;
    }

    if (filtrosCortesCaja.fechaFinal) {
      filtrosCortesCaja.fechaFinal = filtrosCortesCaja.fechaFinal + "T23:59:59";
    }

    if (filtrosCortesCaja.fechaFinal) {
      filtrosCortesCaja.fechaFinal = filtrosCortesCaja.fechaFinal.split("T")[0];
    }

    listarCortesCaja({ ...filtrosCortesCaja, role: usuario.nombre });
    habilitarCortesCaja();
  };

  const onChange = (e) => {
    if (e.target.name === "fechaInicial") {
      setFiltrosCortesCaja({
        ...filtrosCortesCaja,
        [e.target.name]: e.target.value,
        fechaFinal: e.target.value,
      });
    } else {
      setFiltrosCortesCaja({
        ...filtrosCortesCaja,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onChangeImporte = (e) => {
    let value = e.target.value;
    value = Number(value.replace("$", ""));

    setFiltrosCortesCaja({
      ...filtrosCortesCaja,
      importe: value,
    });
  };

  const handleCheckCorteCaja = async (corteCaja) => {
    if (!corteCajaSeleccionado) {
      const disableAprobarRechazar = corteCaja.idEstatus === 3 || corteCaja.idEstatus === 4;
      corteCaja.checked = true;
      setCorteCajaSeleccionado(corteCaja);
      setEstatusAutorizado(disableAprobarRechazar);
      deshabilitarCortesCaja(corteCaja.idCorteCaja);
    } else {
      corteCaja.checked = false;
      habilitarCortesCaja();
      setCortesCajaFiltrados(cortesCajaFiltrados);
    }
  };

  const deshabilitarCortesCaja = async (idCorteCaja) => {
    cortesCajaFiltrados.map((corte) => {
      if (corte.idCorteCaja !== idCorteCaja) {
        corte.disabledCheck = true;
      } else {
        corte.disabledCheck = false;
      }
      return corte;
    });
    setCortesCajaFiltrados(cortesCajaFiltrados);
  };

  const habilitarCortesCaja = async () => {
    setCorteCajaSeleccionado(null);
    setEstatusAutorizado(false);
    cortesCajaFiltrados.map((corte) => (corte.disabledCheck = false));
    cortesCajaFiltrados.map((corte) => (corte.checked = false));
  };

  const detalleCorte = async () => {
    if (!corteCajaSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione un Corte de Caja",
      });
      return;
    }
    // Enviar a página detalle de corte de caja
    habilitarCortesCaja();
    props.history.push(`/cortescaja/detalle/${corteCajaSeleccionado.idCorteCaja}`);
  };

  const cerrarCorte = async () => {
    // if (!corteCajaSeleccionado) {
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor seleccione un Corte de Caja",
    //   });
    //   return;
    // }
    // Cerrar el corte de caja, se debe enviar a la página "cerrar corte de caja"
    habilitarCortesCaja();
    // props.history.push(`/cortescaja/cerrar/${corteCajaSeleccionado.idCorteCaja}`);
    props.history.push(`/cortescaja/cerrar`);
  };
  
  const checkAprobarRechazarCorte = async () => {
    if (!corteCajaSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione un Corte de Caja",
      });
      return;
    }
    // Aprobar o rechazar el corte de caja con el sweet alert y mostrar text area para comentario
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_aprobar_corte_caja: true,
    });
  };

  const aprobarRechazarCorte = async () => {
    if (comentarioCorteCaja.length === 0) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca un comentario`,
      });
      return;
    }
    if (corteCajaDecision) {
      const res = await cambiarStatusCorteCaja({
        idCorteCaja: corteCajaSeleccionado.idCorteCaja,
        idEstatusNuevo: corteCajaDecision ? 3 : 4,
        comentarioEstatus: comentarioCorteCaja,
      });
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_alert_nuevo_pago: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_aprobar_corte_caja: false,
          success_dlg: true,
          dynamic_title: "Exito",
          dynamic_description: "El corte de caja se aprobó con exito",
        });
        filtrarCortesStatus({
          idCorteCaja: corteCajaSeleccionado.idCorteCaja,
          idEstatusNuevo: corteCajaDecision ? 3 : 4,
          estatusActual: corteCajaDecision ? "APROBADO" : "RECHAZADO",
          comentarioEstatus: comentarioCorteCaja,
        });
      }
    } else if (!corteCajaDecision) {
      const res = await cambiarStatusCorteCaja({
        idCorteCaja: corteCajaSeleccionado.idCorteCaja,
        idEstatusNuevo: corteCajaDecision ? 3 : 4,
        comentarioEstatus: comentarioCorteCaja,
      });
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_alert_nuevo_pago: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_aprobar_corte_caja: false,
          success_dlg: true,
          dynamic_title: "Exito",
          dynamic_description: "El corte de caja se rechazó con exito",
        });
        filtrarCortesStatus({
          idCorteCaja: corteCajaSeleccionado.idCorteCaja,
          idEstatusNuevo: corteCajaDecision ? 3 : 4,
          estatusActual: corteCajaDecision ? "APROBADO" : "RECHAZADO",
          comentarioEstatus: comentarioCorteCaja,
        });
      }
      // Aprobado 3 y rechazado 4
    }
    listarCortesCaja({
      folio: filtrosCortesCaja.folioCorteCaja,
      role: usuario.nombre,
    });
    habilitarCortesCaja();
    setComentarioCorteCaja("");
    setCorteCajaDecision(false);
  };

  const filtrarCortesStatus = async ({ idCorteCaja, idEstatusNuevo, comentarioEstatus, estatusActual }) => {
    const cortesFiltrados = cortesCajaFiltrados.map((corte) => {
      if (corte.idCorteCaja == idCorteCaja)
        return { ...corte, idEstatus: idEstatusNuevo, estatusActual: estatusActual, comentarioEstatus: comentarioEstatus };
      else return corte;
    });

    setCortesCajaFiltrados(cortesFiltrados);
  };

  const tipoStatus = tipoCorteCaja.map(({ idCatEstatusCorteCaja, estatusCortecaja, ...tipoCorteCaja }) => ({
    ...tipoCorteCaja,
    id: idCatEstatusCorteCaja,
    nombre: estatusCortecaja,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Fecha Inicial",
          type: "date",
          name: "fechaInicial",
          disabled: false,
          value: filtrosCortesCaja.fechaInicial,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Status",
          name: "estatusCorte",
          value: filtrosCortesCaja.estatusCorte,
          opciones: tipoStatus,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Fecha Final",
          type: "date",
          name: "fechaFinal",
          disabled: !filtrosCortesCaja.fechaInicial ? true : false,
          min: filtrosCortesCaja.fechaInicial,
          value: filtrosCortesCaja.fechaFinal,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Importe Máximo",
          type: "text",
          name: "importe",
          disabled: false,
          value: filtrosCortesCaja.importe ? `$${filtrosCortesCaja.importe}` : `$0`,
          onChange: onChangeImporte,
        },
      ],
    },
  ];

  const estatusActual = (corteCaja) => {
    const idEstatus = corteCaja.idEstatus;
    const nombreEstatus = corteCaja.estatusActual;
    switch (idEstatus) {
      case 1:
        return (
          <>
            <span
              className="badge badge-info"
              style={{
                height: "1.9rem",
                paddingTop: "0.6rem",
                border: "1px solid #dfdada",
                color: "white",
                fontWeight: "bold",
                //morado
                backgroundColor: "#602a96",
                borderRadius: "5px",
              }}>
              {nombreEstatus}
            </span>
          </>
        );

      case 2:
        return (
          <>
            <span
              className="badge badge-info"
              style={{
                height: "1.9rem",
                paddingTop: "0.6rem",
                border: "1px solid #dfdada",
                color: "white",
                fontWeight: "bold",
                //morado
                backgroundColor: "#602a96",
                borderRadius: "5px",
              }}>
              {nombreEstatus}
            </span>
          </>
        );
      case 3:
        return (
          <>
            <span
              className="badge badge-info"
              style={{
                height: "1.9rem",
                paddingTop: "0.6rem",
                border: "1px solid #dfdada",
                color: "black",
                fontWeight: "bold",
                //verde
                backgroundColor: "#C1E05C",
                borderRadius: "5px",
              }}>
              {nombreEstatus}
            </span>
          </>
        );
      case 4:
        return (
          <>
            <span
              className="badge badge-info"
              style={{
                height: "1.9rem",
                paddingTop: "0.6rem",
                border: "1px solid #dfdada",
                color: "white",
                fontWeight: "bold",
                //morado
                backgroundColor: "#602a96",
                borderRadius: "5px",
              }}>
              {nombreEstatus}
            </span>
          </>
        );

      default:
        return <></>;
    }
  };

  useEffect(() => {
    listarTiposCorteCaja();
    // if (tipoCorteCaja.length === 0) {
    //   listarTiposCorteCaja();
    // }
    listarCortesCaja({
      folio: filtrosCortesCaja.folioCorteCaja,
      role: usuario.nombre,
    });
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Lista de Cortes de Caja</h3>
        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearch}>
          {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
        </button>

        {usuario.authorities.includes("ROLE_FARMACIA") || usuario.authorities.includes("ROLE_RECEPCION") ? (
            <button
            className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
              type="button"
              onClick={() => cerrarCorte()}>
              Nuevo Corte de Caja
            </button>
          ) : null}        

        <FiltroFolio
          toggleSearchBy={toggleSearchBy}
          buscarPorFolio={buscarPorFolio}
          onChange={onChange}
          value={filtrosCortesCaja.folioCorteCaja}
          params={{
            name: "folioCorteCaja",
            placeholder: "Escribe tu Folio de Corte",
          }}
        />
        <FiltroBusqueda
          toggleSearchBy={toggleSearchBy}
          buscarPorFiltros={buscarPorFiltros}
          onChange={onChange}
          limpiarFiltros={limpiarFiltros}
          paramsInput={inputCols}
        />
        {/* Lista de Cortes de Caja */}
        <h4 className="flex mt-5">Lista de Cortes de Caja</h4>
        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <Row>
                  <Col>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => detalleCorte()}>
                      Detalle del Corte
                    </button>
                    {usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ? (
                      <button
                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                        type="button"
                        style={{
                          marginRight: "2.5rem",
                          marginTop: "1rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        disabled={estatusAutorizado}
                        onClick={() => checkAprobarRechazarCorte()}>
                        Aprobar / Cancelar Corte
                      </button>
                    ) : null}
                  </Col>
                </Row>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Fecha Corte</th>
                        <th scope="col">Folio</th>
                        <th scope="col">
                          Estatus<br></br>Actual
                        </th>
                        <th scope="col">Comentario</th>
                        <th scope="col">Efectivo Físico</th>
                        <th scope="col">
                          Efectivo Depósito<br></br>en Cuenta
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Crédito
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Débito
                        </th>
                        <th scope="col">Transferencia</th>
                        <th scope="col">
                          Importe<br></br>Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cortesCajaFiltrados.map((corteCaja, index) => {
                        return (
                          <>
                            <tr key={corteCaja.folioCorteCaja}>
                              <td>
                                <input
                                  key={corteCaja.folioCorteCaja}
                                  type="checkbox"
                                  disabled={corteCaja.disabledCheck}
                                  onChange={() => handleCheckCorteCaja(corteCaja)}
                                  checked={corteCaja.checked}
                                />
                              </td>
                              <td>{corteCaja.fechaCorteCaja ? corteCaja.fechaCorteCaja : ""}</td>
                              <td>{corteCaja.folioCorteCaja ? corteCaja.folioCorteCaja : ""}</td>
                              <td>{estatusActual(corteCaja)}</td>

                              <td>
                                <textarea
                                  className="resize-y border rounded-md"
                                  placeholder={corteCaja.comentarioEstatus ? corteCaja.comentarioEstatus : ""}
                                  disabled={true}
                                />
                              </td>

                              <td>{corteCaja.importeEfectivoFisico ? formatNumber(corteCaja.importeEfectivoFisico) : "$0"}</td>
                              <td>
                                {corteCaja.importeEfectivoDeposito ? formatNumber(corteCaja.importeEfectivoDeposito) : "$0"}
                              </td>
                              <td>{corteCaja.importeTarjetaCredito ? formatNumber(corteCaja.importeTarjetaCredito) : "$0"}</td>
                              <td>{corteCaja.importeTarjetaDebito ? formatNumber(corteCaja.importeTarjetaDebito) : "$0"}</td>

                              <td>{corteCaja.importeTransferencia ? formatNumber(corteCaja.importeTransferencia) : "$0"}</td>
                              <td>{corteCaja.importeTotal ? formatNumber(corteCaja.importeTotal) : "$0"}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {UISweetAlert.confirm_alert_aprobar_corte_caja ? (
          <SweetAlert
            title="Aprobar / Rechazar Corte de Caja"
            warning
            showCancel={false}
            showConfirm={true}
            confirmBtnText="Confirmar"
            confirmBtnBsStyle="success"
            style={{
              width: "30%",
            }}
            onConfirm={() => aprobarRechazarCorte()}
            onCancel={() => {
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_aprobar_corte_caja: false,
              });
              setComentarioCorteCaja("");
              setCorteCajaDecision(false);
            }}>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() => setCorteCajaDecision(!corteCajaDecision)}
                    checked={corteCajaDecision}
                    color="primary"
                    name="decision"
                  />
                }
                label={corteCajaDecision ? "Aprobado" : "Rechazado"}
              />
            </div>
            <label className="mb-3 mt-2">Comentarios</label>
            <div>
              <textarea
                id="comentarioCorteCaja"
                name="comentarioCorteCaja"
                onChange={(e) => setComentarioCorteCaja(e.target.value)}
                rows="5"
                cols="33"></textarea>
            </div>
          </SweetAlert>
        ) : null}

        {UISweetAlert.success_dlg ? (
          <SweetAlert
            success
            title={UISweetAlert.dynamic_title}
            onConfirm={() => {
              if (UISweetAlert.redirect) {
                props.history.push(UISweetAlert.path);
              }
              setUISweetAlert({
                ...UISweetAlert,
                success_dlg: false,
              });
            }}>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}
        {UISweetAlert.error_dlg ? (
          <SweetAlert
            error
            title={UISweetAlert.dynamic_title}
            onConfirm={() =>
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: false,
              })
            }>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}
        {loading ? (
          <SweetAlert title="Cargando!" showConfirm={false}>
            <Spinner />
          </SweetAlert>
        ) : null}
      </div>
    </>
  );
}
