import React, {useReducer} from "react";

import DashboardContext from "./DashboardContext";
import DashboardReducer from "./DashboardReducer";
// import CortesCajaReducer from "./CortesCajaReducer";

import clienteAxios from "../../config/axios";

import {} from "../../types";

export default function DashboardState(props) {
    const initialState = {};

    const [state, dispatch] = useReducer(DashboardReducer, initialState);

    const getGraficaAseguradoraAcumulado = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/acumulado`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };
    const getGraficaAseguradoraFacturacionMensual = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/facturacion/mensual`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };
    const getGraficaAseguradoraPromedioLiquidacion = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/promedio/liquidacion`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaAseguradoraFacturacion = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/facturacion`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };
    

    const getGraficaAseguradoraImportes = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/importes`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaAseguradoraImportesPaciente = async (filtros) => {
        delete filtros.pacienteCompleto
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/importes/paciente`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaAServiciosseguradora = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/servicios`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaTotalServicios = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/total/servicios`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaAseguradoraAdministracionInyeccionMensual = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/administracion/inyeccion/mensual`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaAseguradoraAdministracionMedico = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/administraciones/medico`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaAseguradoraPacientesMensuales = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/pacientes/mensuales`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaAseguradoraEsquemasTx = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/esquemas/tx`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaAseguradoraTiempoMaximoMinimo = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/aseguradora/tiempo/maximo/minimo`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res.data;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }
            return error.response;
        }
    };
    /* ********************************************************************************************
                                 COMIENZA SECCION DE MEZCLAS
    ******************************************************************************************** */
    const getGraficaMezclasPreparaciones = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/mezclas/preparaciones/totales`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaMezclasPreparacionesPareto = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/mezclas/preparaciones/pareto`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaMezclasMedicos = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/mezclas/preparaciones/medicos`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const graficaMedicamentosPorMes = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/mezclas/medicamentos/mes`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getGraficaMezclasReacciones = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/mezclas/preparaciones/reacciones`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    /* ********************************************************************************************
                                 COMIENZA SECCION DE COMPRAS
    ******************************************************************************************** */
    const getFacturadoMensual = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/compras/facturado/mensual`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getTotalTipoCompra = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/compras/total/tipoCompra`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getComprasPagadas = async (filtros) => { 
        try {
            const res = await clienteAxios.post(
                `/dashboard/compras/pagadas`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getConsumoMensual = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/compras/tipo/producto`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getComprasAcumulados = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/compras/tipo/producto`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getOrdenesCompra = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/compras/general`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const getPendientesPago = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/compras/pendientes/pagos`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };    

    const getVentasMedicamentos = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/farmacia/venta/nostrador`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }
            return error.response;
        }
    };   

    const getComprasProveedor = async (filtros) => {
        try {
            const res = await clienteAxios.post(
                `/dashboard/compras/proveedores`,
                filtros,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };    

    const obtenerProductosInventario = async () => {
        const res = await clienteAxios.post(
          `/dashboard/producto`,
          { ...state.filtrosProductosInventario, pageSize: 0, pageIndex: 0 },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Cache-Control': 'no-store', // Evita el caché del navegador
            },
          }
        );
    
        dispatch({
          payload: res.data.inventario,
        });
    
        return res;
      };

      const obtenerProductosTotales = async () => {
        const res = await clienteAxios.post(
          `/dashboard/producto/totales`,
          { ...state.filtrosProductosInventario, pageSize: 0, pageIndex: 0 },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Cache-Control': 'no-store', // Evita el caché del navegador
            },
          }
        );
    
        dispatch({
          payload: res.data.inventario,
        });
    
        return res;
      };
    
/* ***************************************************************************************************************************** */
/* ***************************************************************************************************************************** */
/* ***************************************************************************************************************************** */
    return (
        <DashboardContext.Provider
            value={{getGraficaMezclasPreparaciones,getGraficaMezclasMedicos,getGraficaMezclasReacciones,
                getGraficaAseguradoraAcumulado, getGraficaAseguradoraFacturacionMensual, getGraficaAseguradoraPromedioLiquidacion,
                getGraficaAseguradoraFacturacion, getGraficaAseguradoraImportes, getGraficaAseguradoraImportesPaciente,
                getGraficaAServiciosseguradora, getGraficaAseguradoraAdministracionInyeccionMensual, getGraficaAseguradoraAdministracionMedico,
                getGraficaAseguradoraPacientesMensuales, getGraficaAseguradoraEsquemasTx, getGraficaAseguradoraTiempoMaximoMinimo,
                getGraficaMezclasPreparacionesPareto,graficaMedicamentosPorMes,
                getFacturadoMensual,getTotalTipoCompra,getComprasPagadas,getConsumoMensual,getComprasAcumulados,getOrdenesCompra,getPendientesPago,
                getComprasProveedor,getGraficaTotalServicios,getVentasMedicamentos,obtenerProductosInventario,obtenerProductosTotales
            }}>
            {props.children}
        </DashboardContext.Provider>
    );
}
