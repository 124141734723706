import React, { useReducer } from "react";

import CatTipoRecetaContext from "./CatTipoRecetaContext";
import CatTipoRecetaReducer from "./CatTipoRecetaReducer";

import { LISTAR_CATTIPORECETAS } from "../../types";

import clienteAxios from "../../config/axios";

export default function CatTipoRecetaState(props) {
  const initialState = {
    tiposRecetas: [],
  };

  const [state, dispatch] = useReducer(CatTipoRecetaReducer, initialState);

  const listarTiposRecetas = async () => {
    const res = await clienteAxios.get("/tiporecetas/recetas/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CATTIPORECETAS,
      payload: res.data,
    });
    return res;
  };

  return (
    <CatTipoRecetaContext.Provider
      value={{
        tiposRecetas: state.tiposRecetas,
        listarTiposRecetas,
      }}>
      {props.children}
    </CatTipoRecetaContext.Provider>
  );
}
