import React, {useState, useEffect} from 'react';

function TimePassedComponent({start, end, title}) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const [timePassed, setTimePassed] = useState('');

    useEffect(() => {
        if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
            // const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
            let dayCounter = 0;

            for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
                if (currentDate.getDay() !== 0) { // Exclude Sundays (0 represents Sunday)
                    dayCounter++;
                }
            }

            const daysPassed = dayCounter - 1; // Subtracting one day for the end date

            const hoursPassed = Math.floor(daysPassed * 24 + (endDate.getHours() - startDate.getHours()));

            setTimePassed(`${daysPassed} días, ${hoursPassed % 24} horas`);
        } else {
            setTimePassed('Sin tiempo transcurrido');
        }
    }, [startDate, endDate]);

    return (
        <div className="w-100 bg-gray-200 p-3 rounded">
            <h4 className="pb-2 h4 pr-2">{title}</h4>
            <p className="bg-light-purple-umo rounded p-3 text-white text-base font-bold lg:w-1/5 block">{timePassed}</p>
        </div>
    );
}

export default TimePassedComponent;