import React, {useState, useContext, useEffect, useRef} from "react";

import DashboardContext from "../../context/Dashboard/DashboardContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import formatNumber from "../../utils";
import ChartLayout from "../ChartLayout/ChartLayout";
import PieChart from "../Charts/PieChart";
import LineChart from "../Charts/LineChart";
import BarChart from "../Charts/BarChart";
import HorizontalStackedBarChart from "../Charts/HorizontalStackedBarChart";
import { Bar, Title } from "react-chartjs-2";
import CustomSpinner from "../UI/customSpinner";
import {assignRandomColorsToDatasets, assignRandomColorsToDatasetsBar} from "../../utils/assingRandomColorsToDatasets";
import {barChartOptions} from "../../config/chartOptions";

const EstadisticasAseguradora = () => {
    const refGraficaAcumulado = useRef(null);
    const refGraficaFacturacionMensual = useRef(null);
    const refGraficaDiasPromedio = useRef(null);
    const refGraficaFacturacionAseguradora = useRef(null);
    const refGraficaImportesAseguradora = useRef(null);
    const refGraficaImportesPaciente = useRef(null);
    const refGraficaServiciosAseguradoras = useRef(null);
    const refGraficaTotalServicios = useRef(null);    
    const refGraficaAdministracionMedico = useRef(null);
    const refGraficaPacientesMensuales = useRef(null);
    const refGraficaEsquemasTx = useRef(null);
    const refGraficaMinMaxCarta = useRef(null);

    const dashboardContext = useContext(DashboardContext);

    const {
        getGraficaAseguradoraAcumulado,
        getGraficaAseguradoraFacturacionMensual,
        getGraficaAseguradoraPromedioLiquidacion,
        getGraficaAseguradoraFacturacion,
        getGraficaAseguradoraImportes,
        getGraficaTotalServicios,
        getGraficaAseguradoraImportesPaciente,
        getGraficaAServiciosseguradora,
        getGraficaAseguradoraAdministracionMedico,
        getGraficaAseguradoraPacientesMensuales,
        getGraficaAseguradoraEsquemasTx,
        getGraficaAseguradoraTiempoMaximoMinimo
    } = dashboardContext;

    const aseguradorasContext = useContext(AseguradorasContext);
    const {getAllAseguradoras} = aseguradorasContext;

    const ctxPacientes = useContext(PacientesContext);
    const {loadPacientesSelect} = ctxPacientes;

    const ctxMedicos = useContext(MedicosContext);
    const {listarMedicos} = ctxMedicos;

    const [cantidadAseguradoras, setCantidadAseguradoras] = useState(0);
    const [cantidadPacientes, setCantidadPacientes] = useState(0);
    const[cantidadMinMaxCarta,setCantidadMinMaxCarta] = useState(0);
   /* EN ESTA SECCION SE TRAEN TODAS LAS ASEGURADORAS PARA LLENAR EL COMBO DE SELECION DE ASEGURADORAS */ 
    useEffect(() => {
        const fetchAllAseguradoras = async () => {
            const data = await getAllAseguradoras();
            setAllAseguradoras(data);
        }
        fetchAllAseguradoras();
    }, []);

    const [allAseguradoras, setAllAseguradoras] = useState(null);
    const [allMedicos, setAllMedicos] = useState(null);

    useEffect(() => {
        const fetchAllMedicos = async () => {
            const data = await listarMedicos();
            setAllMedicos(data);
        }
        
        fetchAllMedicos();
    }, []);

    const [loadingAcumulado, setLoadingAcumulado] = useState(false); 
    const [loadingFacturacionMensual, setLoadingFacturacionMensual] = useState(false);
    const [loadingDiasPromedio, setLoadingDiasPromedio] = useState(false);
    const [loadingFacturacionAseguradora, setLoadingFacturacionAseguradora] = useState(false);
    const [loadingImportesAseguradora, setLoadingImportesAseguradora] = useState(false);
    const [loadingImportesPaciente, setLoadingImportesPaciente] = useState(false);
    const [loadingServiciosAseguradoras, setLoadingServiciosAseguradoras] = useState(false);
    const [loadingTotalServicios, setLoadingTotalServicios] = useState(false);

    
    const [loadingAdministracionMedico, setLoadingAdministracionMedico] = useState(false);
    const [loadingPacientesMensuales, setLoadingPacientesMensuales] = useState(false);
    const [loadingEsquemasTx, setLoadingEsquemasTx] = useState(false);
    const [loadingMinMaxCarta, setLoadingMinMaxCarta] = useState(false);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // Gráfica acumulado
    const chartLayoutAcumulado = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosAcumulado, setFiltrosAcumulado] = useState({anio: currentYear, fullDate: currentDate})
    const [dataAcumulado, setDataAcumulado] = useState({datasets: []});
    const fixedTitleAcumulado = "Acumulado - "
    const [titleAcumulado, setTitleAcumulado] = useState(fixedTitleAcumulado + currentYear);

    // Gráfica facturacion mensual
    const chartLayoutFacturacionMensual = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosFacturacionMensual, setFiltrosFacturacionMensual] = useState({anio: currentYear, fullDate: currentDate})
    const [dataFacturacionMensual, setDataFacturacionMensual] = useState({datasets: []});
    const fixedTitleFM = "Facturación Mensual - "
    const [titleFM, setTitleFM] = useState(fixedTitleFM + currentYear);
 
    // Gráfica dias promedio
    const chartLayoutDiasPromedio = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosDiasPromedio, setFiltrosDiasPromedio] = useState({})
    const [dataDiasPromedio, setDataDiasPromedio] = useState({datasets: []});
    const fixedTitleDiasPromedio = "Días promedio para liquidar facturas - "
    const [titleDiasPromedio, setTitleDiasPromedio] = useState(fixedTitleDiasPromedio);

    // Gráfica facturación por aseguradora
    const chartLayoutFacturacionAseguradora = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosFacturacionAseguradora, setFiltrosFacturacionAseguradora] = useState({})
    const emptyPieData = {
        datasets: [
            {
                data: []
            }
        ]
    }
    const [dataFacturacionAseguradora, setDataFacturacionAseguradora] = useState(emptyPieData
    );
    const fixedTitleFacturacionAseguradora = "Facturación por Aseguradora - "
    const [titleFacturacionAseguradora, setTitleFacturacionAseguradora] = useState(fixedTitleFacturacionAseguradora);

    // Gráfica importes por aseguradora
    const chartLayoutImportesAseguradora = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosImportesAseguradora, setFiltrosImportesAseguradora] = useState({})
    const emptyHorizontalBarData = {
        datasets: []
    }
    const [dataImportesAseguradora, setDataImportesAseguradora] = useState(emptyHorizontalBarData);
    const fixedTitleImportesAseguradora = "Importes por Aseguradora - "
    const [titleImportesAseguradora, setTitleImportesAseguradora] = useState(fixedTitleImportesAseguradora);

    // Gráfica importes por paciente
    const chartLayoutImportesPaciente = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosImportesPaciente, setFiltrosImportesPaciente] = useState({anio: currentYear, fullDate: currentDate})
    const [dataImportesPaciente, setDataImportesPaciente] = useState(emptyHorizontalBarData
    );
    const fixedTitleImportesPaciente = "Importes por Paciente - "
    const [titleImportesPaciente, setTitleImportesPaciente] = useState(fixedTitleImportesPaciente);

    // Gráfica importes por Administracion // Inyeccion
    const emptyDataBar = {
        // labels,
        datasets: [],
    };
    const chartLayoutServiciosAseguradoras = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosServiciosAseguradoras, setFiltrosServiciosAseguradoras] = useState({})
    const [dataServiciosAseguradoras, setDataServiciosAseguradoras] = useState(emptyDataBar);
    const fixedTitleServiciosAseguradoras = "Servicios por Aseguradora - "
    const [titleServiciosAseguradoras, setTitleServiciosAseguradoras] = useState(fixedTitleServiciosAseguradoras);

    // Gráfica importes por Administracion // Inyeccion Mensual
    const chartLayoutTotalServicios = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosTotalServicios, setFiltrosTotalServicios] = useState({anio: currentYear, fullDate: currentDate})
    const [dataTotalServicios, setDataTotalServicios] = useState(emptyDataBar);
    const fixedTitleTotalServicios = "Total Servicios - "
    const [titleTotalServicios, setTitleTotalServicios] = useState(fixedTitleTotalServicios + currentYear);

        
    // Gráfica Administración por médico
    const chartLayoutAdministracionMedico = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosAdministracionMedico, setFiltrosAdministracionMedico] = useState({})
    const [dataAdministracionMedico, setDataAdministracionMedico] = useState(emptyPieData);
    const fixedTitleAdministracionMedico = "Administración por Médico - "
    const [titleAdministracionMedico, setTitleAdministracionMedico] = useState(fixedTitleAdministracionMedico);

    // Gráfica Pacientes Mensuales
    const chartLayoutPacientesMensuales = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosPacientesMensuales, setFiltrosPacientesMensuales] = useState({anio: currentYear, fullDate: currentDate})
    const [dataPacientesMensuales, setDataPacientesMensuales] = useState(emptyDataBar);
    const fixedTitlePacientesMensuales = "Pacientes Mensuales - "
    const [titlePacientesMensuales, setTitlePacientesMensuales] = useState(fixedTitlePacientesMensuales + currentYear);

    // Gráfica Esquemas Tx
    const chartLayoutEsquemasTx = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosEsquemasTx, setFiltrosEsquemasTx] = useState({})
    const [dataEsquemasTx, setDataEsquemasTx] = useState(emptyPieData);
    const fixedTitleEsquemasTx = "Esquemas de TX. - "
    const [titleEsquemasTx, setTitleEsquemasTx] = useState(fixedTitleEsquemasTx);

    // Gráfica Min Max Carta
    const chartLayoutMinMaxCarta = {
        extraInfo: true,
        withFilters: true
    };
    const [filtrosMinMaxCarta, setFiltrosMinMaxCarta] = useState({})
    const [dataMinMaxCarta, setDataMinMaxCarta] = useState(emptyHorizontalBarData);
    const fixedTitleMinMaxCarta = "Tiempo Máximo // Mínimo en Recepción de Carta Autorizada - "
    const [titleMinMaxCarta, setTitleMinMaxCarta] = useState(fixedTitleMinMaxCarta);

    /* ******************************************************************************************** */
    /*                      SOLICITUD PARA LLENAR LA GRAFICA DE ACUMULADO                           */
    useEffect(() => {
        const fetchAcumulado = async () => {
            setLoadingAcumulado(true);
            try {
                const res = await getGraficaAseguradoraAcumulado(filtrosAcumulado);
                const datasetsWithRandomColors = assignRandomColorsToDatasets(res.data);
                setDataAcumulado({datasets: datasetsWithRandomColors});
            } catch (e) {
                setDataAcumulado({datasets: []})
                setLoadingAcumulado(false);
                console.log(e);
            }
            setLoadingAcumulado(false);
        };

        fetchAcumulado();
    }, [filtrosAcumulado]);

    const onChangeAcumulado = (e) => {
        const {name, value} = e.target;
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTitleAcumulado(fixedTitleAcumulado + year)
            } catch (error) {
            }
            setFiltrosAcumulado({
                ...filtrosAcumulado,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosAcumulado({
                ...filtrosAcumulado,
                [name]: value
            });
        }
    }

    /* ********************************************************************************************** /
    /*                 SOLICITUD PARA LLENAR LA GRAFICA DE FACTURACION MENSUAL                       */
    useEffect(() => {
        const fetchFacturacionMensual = async () => {
            setLoadingFacturacionMensual(true);
            try {
                const res = await getGraficaAseguradoraFacturacionMensual(filtrosFacturacionMensual);
                const datasetsWithRandomColors = assignRandomColorsToDatasetsBar(res.data);
                setDataFacturacionMensual({datasets: datasetsWithRandomColors});
            } catch (e) {
                setDataFacturacionMensual({datasets: []})
                setLoadingFacturacionMensual(false);
                console.log(e);
            }
            setLoadingFacturacionMensual(false);
        };

        fetchFacturacionMensual();
    }, [filtrosFacturacionMensual]);

    const onChangeFacturacionMensual = (e) => {
        const {name, value} = e.target;
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTitleFM(fixedTitleFM + year)
            } catch (error) {
            }
            setFiltrosFacturacionMensual({
                ...filtrosFacturacionMensual,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosFacturacionMensual({
                ...filtrosFacturacionMensual,
                [name]: value
            });
        }
    }

    /* ********************************************************************************************** /
    /*           SOLICITUD DE INFORMACION PARA LA GRAFICA DIAS PROMEDIO PARA LIQUIDAR FACTURAS       */ 
    useEffect(() => {
        const fetchDiasPromedio = async () => {
            setLoadingDiasPromedio(true);
            try {
                const res = await getGraficaAseguradoraPromedioLiquidacion(filtrosDiasPromedio);
                if (res.data && res.data.data.length > 0) {
                    const finalPc = res.data.data.map((value,index) => {
                        const labelTemp = value.x;
                        const color = assignRandomColorsToDatasetsLocal();
                        let retorno = {
                            label : value.x,
                            backgroundColor:  color,
                            borderColor  : color,           
                            data : [{ x : labelTemp, y : parseFloat(value.y, 10)}]
                        }
                        return retorno
                    });
                    setDataDiasPromedio({datasets: finalPc});
                } else {
                    setDataDiasPromedio({
                        datasets: [],
                    })
                }
            } catch (e) {
                setDataDiasPromedio({datasets: []})
                setLoadingDiasPromedio(false);
                console.log(e);
            }
            setLoadingDiasPromedio(false);
        };

        fetchDiasPromedio();
    }, [filtrosDiasPromedio]);

    const onChangeDiasPromedio = (e) => {
        const {name, value} = e.target;
        if (name === "fechaInicial") {
            const stringFechaFinal = filtrosDiasPromedio.fechaFinal ? " a " + filtrosDiasPromedio.fechaFinal : ""
            setTitleDiasPromedio(fixedTitleDiasPromedio + value + stringFechaFinal)
            setFiltrosDiasPromedio({
                ...filtrosDiasPromedio,
                [name]: value
            });
        } else if (name === "fechaFinal") {
            const stringFechaIncial = filtrosDiasPromedio.fechaInicial ? filtrosDiasPromedio.fechaInicial + " a " : ""
            setTitleDiasPromedio(fixedTitleDiasPromedio + stringFechaIncial + value)
            setFiltrosDiasPromedio({
                ...filtrosDiasPromedio,
                [name]: value
            });
        } else {
            setFiltrosDiasPromedio({
                ...filtrosDiasPromedio,
                [name]: value
            });
        }
    }

    /* ********************************************************************************************** /
    /*             SOLICITUD DE INFORMACION PARA LA GRAFICA FACTURACION PÓR ASEGURADORA              */ 

    function assignRandomColorsToDatasetsLocal(){
        const randomComponent = () => Math.floor(Math.random() * 256);
        const r = randomComponent();
        const g = randomComponent();
        const b = randomComponent();
        return `rgb(${r}, ${g}, ${b})`;
    }

    useEffect(() => {
        const fetchFacturacionAseguradora = async () => {
            setLoadingFacturacionAseguradora(true);
            try {
                let dataTemp = [];
                let labelsTemp = [];
                let backgroundColorTemp = [];
                let granTotal = 0;
                const res = await getGraficaAseguradoraFacturacion(filtrosFacturacionAseguradora);
                if (res?.data) {
                    if (res.data.data.length > 0) {
                        res.data.data.map((value) => {
                            granTotal = granTotal + parseInt(value.y, 10);
                        });  

                        res.data.data.map((value) => {
                            dataTemp.push(parseInt(value.y, 10));
                            let porcentaje = ((100 / granTotal) * parseInt(value.y, 10)).toFixed(2);
                            labelsTemp.push(value.x + "  (" + parseInt(value.y, 10) +  " =  " +  porcentaje +"%)");
                            backgroundColorTemp.push(assignRandomColorsToDatasetsLocal());
                        });   
                        const dataset = [{data: dataTemp, backgroundColor: backgroundColorTemp}]
                        setDataFacturacionAseguradora({datasets: dataset, labels : labelsTemp})
                    } else {
                        setDataFacturacionAseguradora(emptyPieData)
                    }
                } else {
                    setDataFacturacionAseguradora(emptyPieData)
                }
            } catch (e) {
                setDataFacturacionAseguradora(emptyPieData)
                setLoadingFacturacionAseguradora(false);
                console.log(e);
            }
            setLoadingFacturacionAseguradora(false);
        };

        fetchFacturacionAseguradora();
    }, [filtrosFacturacionAseguradora]);

    const onChangeFacturacionAseguradora = (e) => {
        const {name, value} = e.target;
        if (name === "fechaInicial") {
            const stringFechaFinal = filtrosFacturacionAseguradora.fechaFinal ? " a " + filtrosFacturacionAseguradora.fechaFinal : ""
            setTitleFacturacionAseguradora(fixedTitleFacturacionAseguradora + value + stringFechaFinal)
            setFiltrosFacturacionAseguradora({
                ...filtrosFacturacionAseguradora,
                [name]: value
            });
        } else if (name === "fechaFinal") {
            const stringFechaIncial = filtrosFacturacionAseguradora.fechaInicial ? filtrosFacturacionAseguradora.fechaInicial + " a " : ""
            setTitleFacturacionAseguradora(fixedTitleFacturacionAseguradora + stringFechaIncial + value)
            setFiltrosFacturacionAseguradora({
                ...filtrosFacturacionAseguradora,
                [name]: value
            });
        } else {
            setFiltrosFacturacionAseguradora({
                ...filtrosFacturacionAseguradora,
                [name]: value
            });
        }
    }

    /* ********************************************************************************************** /
    /*               SOLICITUD DE INFORMACION PARA LA GRAFICA IMPORTES POR ASEGURADORA               */     
    useEffect(() => {
        setLoadingImportesAseguradora(true);
        const fetchImportesAseguradora = async () => {           
            try {
                const res = await getGraficaAseguradoraImportes(filtrosImportesAseguradora);
                let labelsTemp = [];
                let porPagarTemp = [];
                let pagadosTemp = [];
                let contador = 1;

                if (res?.data && res?.data?.labels && res?.data?.labels.length > 0) {                 
                    //COBRADO
                    res.data.datasets[0].data.map((value,index) => {
                        pagadosTemp.push(parseFloat(value.toFixed(2), 10));
                    });  
                    
                    //PAGADO
                    res.data.datasets[1].data.map((value,index) => {
                        porPagarTemp.push(parseFloat(value.toFixed(2), 10));  
                    });     

                    contador = 0;
                    res.data.labels.map((value,index) => {
                        labelsTemp.push(value);                  
                        contador = contador + 1;
                    });          
                            
                    if(contador < 5){
                        setCantidadAseguradoras(contador * 70);
                    }else{
                        setCantidadAseguradoras(contador * 45);
                    }

                    const dataset = [{labels : labelsTemp, pagados : pagadosTemp, porPagar : porPagarTemp }]
                    setDataImportesAseguradora(dataset);                    
                } else {
                    setDataImportesAseguradora(emptyHorizontalBarData)
                }
            } catch (e) {
                setDataImportesAseguradora(emptyHorizontalBarData)
                setLoadingImportesAseguradora(false);
                console.log(e);
            }           
        };       
        //setCantidadAseguradoras(0);
        fetchImportesAseguradora();
        setLoadingImportesAseguradora(false);
    }, [filtrosImportesAseguradora]);

    const onChangeImportesAseguradora = (e) => {
        const {name, value} = e.target;
        if (name === "fechaInicial") {
            const stringFechaFinal = filtrosImportesAseguradora.fechaFinal ? " a " + filtrosImportesAseguradora.fechaFinal : ""
            setTitleImportesAseguradora(fixedTitleImportesAseguradora + value + stringFechaFinal)
            setFiltrosImportesAseguradora({
                ...filtrosImportesAseguradora,
                [name]: value
            });
        } else if (name === "fechaFinal") {
            const stringFechaIncial = filtrosImportesAseguradora.fechaInicial ? filtrosImportesAseguradora.fechaInicial + " a " : ""
            setTitleImportesAseguradora(fixedTitleImportesAseguradora + stringFechaIncial + value)
            setFiltrosImportesAseguradora({
                ...filtrosImportesAseguradora,
                [name]: value
            });
        } else {
            setFiltrosImportesAseguradora({
                ...filtrosImportesAseguradora,
                [name]: value
            });
        }
    }

    /* ********************************************************************************************** /
    /*               SOLICITUD DE INFORMACION PARA LA GRAFICA IMPORTES POR PACIENTE               */     
    useEffect(() => {
        setLoadingImportesPaciente(true);
        const fetchImportesPaciente = async () => {           
            try {
                const res = await getGraficaAseguradoraImportesPaciente(filtrosImportesPaciente);
                let labelsTemp = [];
                let porPagarTemp = [];
                let pagadosTemp = [];
                let contador = 1;

                if (res?.data && res?.data?.labels && res?.data?.labels.length > 0) {                 
                    //COBRADO
                    res.data.datasets[0].data.map((value,index) => {
                        pagadosTemp.push(parseFloat(value.toFixed(2), 10));
                    });  
                    
                    //PAGADO
                    res.data.datasets[1].data.map((value,index) => {
                        porPagarTemp.push(parseFloat(value.toFixed(2), 10));  
                    });        
                    
                    contador = 0;
                    res.data.labels.map((value) => {
                            labelsTemp.push(value);                  
                            contador = contador + 1;
                    });    

                    if(contador < 5){
                        setCantidadPacientes(contador * 70);
                    }else{
                        setCantidadPacientes(contador * 45);
                    }

                    const dataset = [{labels : labelsTemp, pagados : pagadosTemp, porPagar : porPagarTemp }]
                    setDataImportesPaciente(dataset);                    
                } else {
                    setDataImportesPaciente(emptyHorizontalBarData)
                }
            } catch (e) {
                setDataImportesPaciente(emptyHorizontalBarData)
                setLoadingImportesPaciente(false);
                console.log(e);
            }           
        };       
        //setCantidadAseguradoras(0);
        fetchImportesPaciente();
        setLoadingImportesPaciente(false);
    }, [filtrosImportesPaciente]);

    const onChangeImportesPaciente = (e) => {
        const {name, value} = e.target;
        if (name === "fechaInicial") {
            const stringFechaFinal = filtrosImportesPaciente.fechaFinal ? " a " + filtrosImportesPaciente.fechaFinal : ""
            setTitleImportesPaciente(fixedTitleImportesPaciente + value + stringFechaFinal)
            setFiltrosImportesPaciente({
                ...filtrosImportesPaciente,
                [name]: value
            });
        } else if (name === "fechaFinal") {
            const stringFechaIncial = filtrosImportesPaciente.fechaInicial ? filtrosImportesPaciente.fechaInicial + " a " : ""
            setTitleImportesPaciente(fixedTitleImportesPaciente + stringFechaIncial + value)
            setFiltrosImportesPaciente({
                ...filtrosImportesPaciente,
                [name]: value
            });
        } else {
            setFiltrosImportesPaciente({
                ...filtrosImportesPaciente,
                [name]: value
            });
        }
    }
    
    /* ********************************************************************************************** /
    /*             SOLICITUD DE INFORMACION PARA LA GRAFICA ADMINISTRACION // INYECCION              */     
    useEffect(() => {
        const fetchServiciosAseguradoras = async () => {
            setLoadingServiciosAseguradoras(true);
            try {
                const res = await getGraficaAServiciosseguradora(filtrosServiciosAseguradoras);
                const datasetsWithRandomColors = assignRandomColorsToDatasetsBar(res.data);
                setDataServiciosAseguradoras({datasets: datasetsWithRandomColors});

            } catch (e) {
                setDataServiciosAseguradoras(emptyDataBar)
                setLoadingServiciosAseguradoras(false);
                console.log(e);
            }
            setLoadingServiciosAseguradoras(false);
        };

        fetchServiciosAseguradoras();
    }, [filtrosServiciosAseguradoras]);
    
    const onChangeServiciosAseguradoras = (e) => {
        const {name, value} = e.target;
        if (name === "fechaInicial") {
            const stringFechaFinal = filtrosServiciosAseguradoras.fechaFinal ? " a " + filtrosServiciosAseguradoras.fechaFinal : ""
            setTitleServiciosAseguradoras(fixedTitleServiciosAseguradoras + value + stringFechaFinal)
            setTitleTotalServicios(fixedTitleTotalServicios + value + stringFechaFinal)
            setFiltrosServiciosAseguradoras({
                ...filtrosServiciosAseguradoras,
                [name]: value
            });
        } else if (name === "fechaFinal") {
            const stringFechaIncial = filtrosServiciosAseguradoras.fechaInicial ? filtrosServiciosAseguradoras.fechaInicial + " a " : ""
            setTitleServiciosAseguradoras(fixedTitleServiciosAseguradoras + stringFechaIncial + value)
            setTitleTotalServicios(fixedTitleTotalServicios + stringFechaIncial + value)
            setFiltrosServiciosAseguradoras({
                ...filtrosServiciosAseguradoras,
                [name]: value
            });
        } else {
            setFiltrosServiciosAseguradoras({
                ...filtrosServiciosAseguradoras,
                [name]: value
            });
        }
    }
    /* ********************************************************************************************** /
    /*             SOLICITUD DE INFORMACION PARA LA GRAFICA ADMINISTRACION // INYECCION              */     
    useEffect(() => {
        const fetchTotalServicios = async () => {
            setLoadingTotalServicios(true);
            try {
                const res = await getGraficaTotalServicios(filtrosServiciosAseguradoras);

                const finalPc = res.data.map((value,index) => {
                    const labelTemp = value.x;
                    let retorno = {
                        label : "",
                        barPercentage:1,
                        backgroundColor: assignRandomColorsToDatasetsLocal(), 
                        borderWidth: 1,
                        height:50,
                        width : 50,            
                        data : [{ x : labelTemp, y : parseFloat(value.y, 10)}]
                    }
                    return retorno
                });                
                setDataTotalServicios({datasets: finalPc});

            } catch (e) {
                setDataTotalServicios(emptyDataBar)
                setLoadingTotalServicios(false);
                console.log(e);
            }
            setLoadingTotalServicios(false);
        };

        fetchTotalServicios();
    }, [filtrosTotalServicios,filtrosServiciosAseguradoras]);

    /* ********************************************************************************************** /
    /*             SOLICITUD DE INFORMACION PARA LA GRAFICA ADMINISTRACION POR MEDICO                */      
    useEffect(() => {
        const fetchAdministracionMedico = async () => {
            setLoadingAdministracionMedico(true);
            try {
                let dataTemp = [];
                let labelsTemp = [];
                let backgroundColorTemp = [];
                let granTotal = 0;
                const res = await getGraficaAseguradoraAdministracionMedico(filtrosAdministracionMedico);
                if (res?.data) {
                    if (res.data.length > 0) {
                        res.data.map((value) => {
                            granTotal = granTotal + parseInt(value.y, 10);
                        });  

                        res.data.map((value) => {
                            dataTemp.push(parseInt(value.y, 10));
                            let porcentaje = ((100 / granTotal) * parseInt(value.y, 10)).toFixed(2);
                            labelsTemp.push(value.x + "  (" + parseInt(value.y, 10) +  " =  " +  porcentaje +"%)");
                            backgroundColorTemp.push(assignRandomColorsToDatasetsLocal());
                        });   
                        const dataset = [{data: dataTemp, backgroundColor: backgroundColorTemp}]
                        setDataAdministracionMedico({datasets: dataset, labels : labelsTemp})
                    } else {
                        setDataAdministracionMedico(emptyPieData)
                    }
                } else {
                    setDataAdministracionMedico(emptyPieData)
                }
            } catch (e) {
                setDataAdministracionMedico(emptyPieData)
                setLoadingAdministracionMedico(false);
                console.log(e);
            }
            setLoadingAdministracionMedico(false);
        };

        fetchAdministracionMedico();
    }, [filtrosAdministracionMedico]);

    const onChangeAdministracionMedico = (e) => {
        const {name, value} = e.target;
        if (name === "fechaInicial") {
            const stringFechaFinal = filtrosAdministracionMedico.fechaFinal ? " a " + filtrosAdministracionMedico.fechaFinal : ""
            setTitleAdministracionMedico(fixedTitleAdministracionMedico + value + stringFechaFinal)
            setFiltrosAdministracionMedico({
                ...filtrosAdministracionMedico,
                [name]: value
            });
        } else if (name === "fechaFinal") {
            const stringFechaIncial = filtrosAdministracionMedico.fechaInicial ? filtrosAdministracionMedico.fechaInicial + " a " : ""
            setTitleAdministracionMedico(fixedTitleAdministracionMedico + stringFechaIncial + value)
            setFiltrosAdministracionMedico({
                ...filtrosAdministracionMedico,
                [name]: value
            });
        } else {
            setFiltrosAdministracionMedico({
                ...filtrosAdministracionMedico,
                [name]: value
            });
        }
    }

    /* ********************************************************************************************** /
    /*                 SOLICITUD DE INFORMACION PARA LA GRAFICA PACIENTES MENSUALES                  */    
    useEffect(() => {
        const fetchPacientesMensuales = async () => {
            setLoadingPacientesMensuales(true);
            try {
                const res = await getGraficaAseguradoraPacientesMensuales(filtrosPacientesMensuales);
                if (res?.data && res?.data.length > 0) {
                    const datasetsWithRandomColors = assignRandomColorsToDatasetsBar(res.data);
                    setDataPacientesMensuales({datasets: datasetsWithRandomColors});
                } else {
                    setDataPacientesMensuales(emptyDataBar)
                }
            } catch (e) {
                setDataPacientesMensuales(emptyDataBar)
                setLoadingPacientesMensuales(false);
                console.log(e);
            }
            setLoadingPacientesMensuales(false);
        };

        fetchPacientesMensuales();
    }, [filtrosPacientesMensuales]);

    const onChangePacientesMensuales = (e) => {
        const {name, value} = e.target;
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTitlePacientesMensuales(fixedTitlePacientesMensuales + year)
            } catch (error) {
            }
            setFiltrosPacientesMensuales({
                ...filtrosPacientesMensuales,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosPacientesMensuales({
                ...filtrosPacientesMensuales,
                [name]: value
            });
        }
    }

    /* *********************************************************************************************** */
    /*                   SOLICITUD DE INFORMACION PARA LA GRAFICA ESQUEMAS DE TX                       */   
    useEffect(() => {
        const fetchEsquemasTx = async () => {
            setLoadingEsquemasTx(true);
            try {
                let dataTemp = [];
                let labelsTemp = [];
                let backgroundColorTemp = [];
                let granTotal = 0;

                const res = await getGraficaAseguradoraEsquemasTx(filtrosEsquemasTx);
                if (res?.data) {
                    if (res.data.length > 0) {
                        res.data.map((value) => {
                            granTotal = granTotal + parseInt(value.y, 10);
                        });  

                        res.data.map((value) => {
                            dataTemp.push(parseInt(value.y, 10));
                            let porcentaje = ((100 / granTotal) * parseInt(value.y, 10)).toFixed(2);
                            labelsTemp.push(value.x + " días (" + parseInt(value.y, 10) +  " =  " +  porcentaje +"%)");
                            backgroundColorTemp.push(assignRandomColorsToDatasetsLocal());
                        });   
                        const dataset = [{data: dataTemp, backgroundColor: backgroundColorTemp}]
                        setDataEsquemasTx({datasets: dataset, labels : labelsTemp})
                    } else {
                        setDataEsquemasTx(emptyPieData)
                    }
                } else {
                    setDataEsquemasTx(emptyPieData)
                }
                
            } catch (e) {
                setDataEsquemasTx(emptyPieData)
                setLoadingEsquemasTx(false);
                console.log(e);
            }
            setLoadingEsquemasTx(false);
        };

        fetchEsquemasTx();
    }, [filtrosEsquemasTx]);

    const onChangeEsquemasTx = (e) => {
        const {name, value} = e.target;
        if (name === "fechaInicial") {
            const stringFechaFinal = filtrosEsquemasTx.fechaFinal ? " a " + filtrosEsquemasTx.fechaFinal : ""
            setTitleEsquemasTx(fixedTitleEsquemasTx + value + stringFechaFinal)
            setFiltrosEsquemasTx({
                ...filtrosEsquemasTx,
                [name]: value
            });
        } else if (name === "fechaFinal") {
            const stringFechaIncial = filtrosEsquemasTx.fechaInicial ? filtrosEsquemasTx.fechaInicial + " a " : ""
            setTitleEsquemasTx(fixedTitleEsquemasTx + stringFechaIncial + value)
            setFiltrosEsquemasTx({
                ...filtrosEsquemasTx,
                [name]: value
            });
        } else {
            setFiltrosEsquemasTx({
                ...filtrosEsquemasTx,
                [name]: value
            });
        }
    }

    /* *********************************************************************************************** */
    /* SOLICITUD DE INFORMACION PARA LA GRAFICA TIEMPO MAXIMO // MINIMO EN RECEPCION CARTA AUTORIZADA  */       
    useEffect(() => {
        const fetchMinMaxCarta = async () => {
            setLoadingMinMaxCarta(true);
            try {
                              let labelsTemp = [];
                let maximos = [];
                let minimos = [];
                let contador2 = 1;
                const res = await getGraficaAseguradoraTiempoMaximoMinimo(filtrosMinMaxCarta);
                if (res?.datasets && res?.labels?.length > 0) {                 
                    //COBRADO
                    res.datasets[0].data.map((value,index) => {
                        maximos.push(parseInt(value));
                    });  
                    
                    //PAGADO
                    res.datasets[1].data.map((value,index) => {
                        if(value == null){
                            minimos.push(0); 
                        }else{
                            minimos.push(parseInt(value)); 
                        }
                         
                    });        
                    
                    contador2 = 0;
                    res.labels.map((value) => {
                        if(value.length > 1){
                            labelsTemp.push(value);                  
                            contador2 ++;
                        }
                    });          

                    if(contador2 < 5){
                        setCantidadMinMaxCarta(contador2 * 70);
                    }else{
                        setCantidadMinMaxCarta(contador2 * 45);
                    }

                    const dataset = [{labels : labelsTemp, maximos : maximos, minimos : minimos }]
                    setDataMinMaxCarta(dataset);                    
                } else {
                    setDataMinMaxCarta(emptyHorizontalBarData)
                }
            } catch (e) {
                setDataMinMaxCarta(emptyHorizontalBarData)
                setLoadingMinMaxCarta(false);
                console.log(e);
            }
            setLoadingMinMaxCarta(false);
        };

        fetchMinMaxCarta();
    }, [filtrosMinMaxCarta]);

    const onChangeMinMaxCarta = (e) => {
        const {name, value} = e.target;
        if (name === "fechaInicial") {
            const stringFechaFinal = filtrosMinMaxCarta.fechaFinal ? " a " + filtrosMinMaxCarta.fechaFinal : ""
            setTitleMinMaxCarta(fixedTitleMinMaxCarta + value + stringFechaFinal)
            setFiltrosMinMaxCarta({
                ...filtrosMinMaxCarta,
                [name]: value
            });
        } else if (name === "fechaFinal") {
            const stringFechaIncial = filtrosMinMaxCarta.fechaInicial ? filtrosMinMaxCarta.fechaInicial + " a " : ""
            setTitleMinMaxCarta(fixedTitleMinMaxCarta + stringFechaIncial + value)
            setFiltrosMinMaxCarta({
                ...filtrosMinMaxCarta,
                [name]: value
            });
        } else {
            setFiltrosMinMaxCarta({
                ...filtrosMinMaxCarta,
                [name]: value
            });
        }
    }

    /* ********************************************************************************************* */ 
    /* ********************** COMIENZA LA CONSTRUCCION DEL MUDULO                                    */
    /* ********************************************************************************************* */
    const exportChart = (chartRef) => {
        if (chartRef.current) {
            const canvas = chartRef.current.canvas; // Access the canvas directly
            canvas.toBlob((blob) => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'grafica.png'; // You can specify the filename here
                link.click();
            });
        } else {
            console.error("Chart ref is null or undefined.");
        }
    };

    return (
        <>
            <div >
                <h1> Informes de Aseguradoras</h1>         
                    <ChartLayout aseguradoras={allAseguradoras} 
                                 selectAseguradoras={true} 
                                 filtros={filtrosAcumulado} 
                                 conAnio={true}
                                 onChange={onChangeAcumulado} 
                                 exportChart={exportChart} 
                                 medicos={allMedicos} 
                                 selectMedicos={true} 
                                 refGrafica={refGraficaAcumulado} {...chartLayoutAcumulado}>
                        {loadingAcumulado ? 
                            (<CustomSpinner height={"700px"} 
                                            infoText={"Gráfica Acumulado"}/>) 
                            : (dataAcumulado?.datasets?.length > 0 ?
                                (<LineChart ref={refGraficaAcumulado} 
                                            title={titleAcumulado} 
                                            data={dataAcumulado}
                                            displayDataLabels={false} 
                                            requiresMoneyFormat={true} 
                                            displayYAxis={true} 
                                            displayLabel={true}/>)
                                    : (<h3>{`${titleAcumulado} SIN DATOS`}</h3>)
                            )
                        }
                    </ChartLayout>

                    <ChartLayout aseguradoras={allAseguradoras} 
                                 selectAseguradoras={true} 
                                 filtros={filtrosFacturacionMensual} 
                                 conAnio={true}
                                 conMes = {true}
                                 onChange={onChangeFacturacionMensual} 
                                 exportChart={exportChart}
                                 medicos={allMedicos} 
                                 selectMedicos={true} 
                                 refGrafica={refGraficaFacturacionMensual} {...chartLayoutFacturacionMensual}>
                        {loadingFacturacionMensual ? 
                            (<CustomSpinner height={"700px"} 
                                            infoText={"Facturación mensual"}/>) 
                            : (dataFacturacionMensual?.datasets?.length > 0 ?
                                (<BarChart ref={refGraficaFacturacionMensual} 
                                           title={titleFM} 
                                           data={dataFacturacionMensual} 
                                           requiresMoneyFormat={true}/>)
                                    : (<h3>{`${titleFM} SIN DATOS`}</h3>)
                            )
                        }
                    </ChartLayout>

                    <ChartLayout aseguradoras={allAseguradoras} 
                                 selectAseguradoras={true} 
                                 filtros={filtrosDiasPromedio} 
                                 conFechas={true}
                                 onChange={onChangeDiasPromedio} 
                                 exportChart={exportChart} 
                                 medicos={allMedicos}
                                 selectMedicos={true}  
                                 refGrafica={refGraficaDiasPromedio} {...chartLayoutDiasPromedio}>
                        {loadingDiasPromedio ? 
                            (<CustomSpinner height={"700px"} 
                                            infoText={"Días promedio para liquidar facturas"}/>) 
                            :(dataDiasPromedio?.datasets?.length > 0 ?
                                (<LineChart ref={refGraficaDiasPromedio} 
                                            title={titleDiasPromedio} 
                                            data={dataDiasPromedio} 
                                            displayDataLabels={true}
                                            requiresMoneyFormat={false} 
                                            displayYAxis={true}
                                            displayLabel={true}/>)
                                    : (<h3>{`${titleDiasPromedio} SIN DATOS`}</h3>)
                            )
                        }
                    </ChartLayout>

                    <ChartLayout aseguradoras={allAseguradoras} 
                                 selectAseguradoras={true} 
                                 filtros={filtrosFacturacionAseguradora} 
                                 conAnio={false}
                                 conMes = {false}
                                 conFechas={true}
                                 onChange={onChangeFacturacionAseguradora} 
                                 exportChart={exportChart} 
                                 medicos={allMedicos} 
                                 selectMedicos={true} 
                                 refGrafica={refGraficaFacturacionAseguradora} {...chartLayoutFacturacionAseguradora}>
                        {loadingFacturacionAseguradora ? 
                            (<CustomSpinner height={"700px"} infoText={"Facturación por aseguradora"}/>) 
                            :(dataFacturacionAseguradora.datasets[0]?.data?.length > 0 ? 
                                (<PieChart 
                                    title={titleFacturacionAseguradora} 
                                    data={dataFacturacionAseguradora} 
                                    displayDataLabels={true}
                                    requiresMoneyFormat={true} 
                                    onlyShowPercentage={false} 
                                    displayYAxis={false} 
                                    displayLabel={false}/>) 
                                    : (<h3>{`${titleFacturacionAseguradora} SIN DATOS`}</h3>)
                            ) 
                        }
                    </ChartLayout>

                    {/* ******************* ACA LA GRAFICA COMBINADA PARA PENDIENTES Y ORDENES PAAGAS ********** */}
    
                    <ChartLayout 
                        selectAseguradoras={true} 
                        aseguradoras={allAseguradoras}
                        filtros={filtrosImportesAseguradora} 
                        conAnio={false}
                        conFechas={true}
                        conMes = {false}
                        onChange={onChangeImportesAseguradora} 
                        exportChart={exportChart}
                        medicos={allMedicos} 
                        selectMedicos={true} 
                        refGrafica={refGraficaImportesAseguradora} {...chartLayoutImportesAseguradora}>
                        {loadingImportesAseguradora ? 
                            (
                                <CustomSpinner height={"700px"} infoText={titleImportesAseguradora}/>
                            ) 
                            :(dataImportesAseguradora?.length > 0 ? 
                                (     
                                    <Bar
                                        ref={refGraficaImportesAseguradora} 
                                        requiresMoneyFormat = {true}
                                        data={{
                                            // Name of the variables on x-axies for each bar
                                            labels: dataImportesAseguradora[0].labels,
                                            datasets: [
                                                { 
                                                    // Label for bars
                                                    label: "Pagado",
                                                    // Data or value of your each variable
                                                    data: dataImportesAseguradora[0].pagados,
                                                    // Color of each bar
                                                    backgroundColor: 
                                                        ["#aed6f1", "#aed6f1", "#aed6f1", "#aed6f1"],
                                                    // Border color of each bar
                                                    borderColor: ["85c1e9", "85c1e9", "85c1e9", "85c1e9"],
                                                    borderWidth: 0.5,
                                                },                                     
                                                {
                                                    // Label for bars
                                                    label: "Por pagar",
                                                    // Data or value of your each variable
                                                    data: dataImportesAseguradora[0].porPagar,
                                                    // Color of each bar
                                                    backgroundColor: 
                                                        ["#f0b27a", "#f0b27a", "#f0b27a", "#f0b27a"],
                                                    // Border color of each bar
                                                    borderColor: ["orange", "orange", "orange", "orange"],
                                                    borderWidth: 0.5,
                                                },                                   
                                            ],
                                        }}
                                        // Height of graph
                                        height={cantidadAseguradoras}                                                
                                        options={{ 
                                            maintainAspectRatio: false,
                                            horizontal : true,
                                            responsive: true,
                                            indexAxis: 'y',
                                            plugins: {
                                                legend: {
                                                    display: true,
                                                    position: "bottom",                                    
                                                },
                                                title: {
                                                    ...barChartOptions.plugins.title,
                                                    text: titleImportesAseguradora,
                                                    padding: {
                                                        bottom: 50,
                                                    }
                                                },
                                                datalabels: {
                                                    anchor: 'end',                 
                                                    display: true,
                                                    align: 'right',
                                                    formatter: (value) => value !== null ? value > 0 ?(""  + formatNumber(value) ) : "" : "",
                                                    color: 'black',
                                                    font: {
                                                        size: 11,
                                                        //weight: 'bold'
                                                    }
                                                },
                                            },
                                            bar: {
                                                borderRadius: 40,
                                                borderWidth: 0.7,
                                            },
                                            ticks: {
                                                padding: 15
                                            },
                                            scales: {
                                                yAxes: [
                                                    {
                                                        ticks: {
                                                    // The y-axis value will start from zero
                                                            beginAtZero: false,
                                                        },
                                                    },
                                                ],
                                            },
                                            legend: {
                                                labels: {
                                                    fontSize: 11,
                                                },
                                            },
                                            responsive: true,
                                            scales: {
                                            x: {
                                                    stacked: true,
                                                },
                                                y: {
                                                    stacked: true

                                                }
                                            }                                
                                        }}
                                    />
                                    ) 
                                : (<h3>{`${titleImportesAseguradora} SIN DATOS`}</h3>)
                            ) 
                        }
                    </ChartLayout>   

                    <ChartLayout 
                        selectAseguradoras={true} 
                        aseguradoras={allAseguradoras}
                        filtros={filtrosImportesPaciente} 
                        conAnio={false}
                        conMes ={false}
                        conFechas={true}
                        onChange={onChangeImportesPaciente} 
                        exportChart={exportChart}
                        medicos={allMedicos} 
                        selectMedicos={true} 
                        refGrafica={refGraficaImportesPaciente} {...chartLayoutImportesPaciente}>
                        {loadingImportesPaciente ? 
                            (
                                <CustomSpinner height={"700px"} infoText={titleImportesPaciente}/>
                            ) 
                            :(dataImportesPaciente?.length > 0 ? 
                                (     
                                    <Bar
                                        ref={refGraficaImportesPaciente} 
                                        requiresMoneyFormat = {true}
                                        data={{
                                            // Name of the variables on x-axies for each bar
                                            labels: dataImportesPaciente[0].labels,
                                            datasets: [
                                                { 
                                                    // Label for bars
                                                    label: "Pagado",
                                                    // Data or value of your each variable
                                                    data: dataImportesPaciente[0].pagados,
                                                    // Color of each bar
                                                    backgroundColor: "#aed6f1",
                                                    // Border color of each bar
                                                    borderColor: "85c1e9",
                                                    borderWidth: 0.5,
                                                },                                     
                                                {
                                                    // Label for bars
                                                    label: "Por pagar",
                                                    // Data or value of your each variable
                                                    data: dataImportesPaciente[0].porPagar,
                                                    // Color of each bar
                                                    backgroundColor: "#f0b27a",
                                                    // Border color of each bar
                                                    borderColor: "orange",
                                                    borderWidth: 0.5,
                                                },                                   
                                            ],
                                        }}
                                        // Height of graph
                                        height={cantidadPacientes}                                                
                                        options={{ 
                                            maintainAspectRatio: false,
                                            horizontal : true,
                                            indexAxis: 'y',
                                            plugins: {
                                                legend: {
                                                    display: true,
                                                    position: "bottom",                                    
                                                },
                                                title: {
                                                    ...barChartOptions.plugins.title,
                                                    text: titleImportesPaciente,
                                                    padding: {
                                                        bottom: 50,
                                                    }
                                                },
                                                datalabels: {
                                                    anchor: 'end',                 
                                                    display: true,
                                                    align: 'right',
                                                    formatter: (value) => value !== null ? value > 0 ?(""  + formatNumber(value) ) : "" : "",
                                                    color: 'black',
                                                    font: {
                                                        size: 10,
                                                        //weight: 'bold'
                                                    }
                                                },

                                            },
                                            bar: {
                                                borderRadius: 40,
                                                borderWidth: 0.7,
                                            },
                                            ticks: {
                                                padding: 15
                                            },
                                            scales: {
                                                yAxes: [
                                                    {
                                                        ticks: {
                                                    // The y-axis value will start from zero
                                                            beginAtZero: false,
                                                        },
                                                    },
                                                ],
                                            },
                                            legend: {
                                                labels: {
                                                    fontSize: 10,
                                                },
                                            },
                                            responsive: true,
                                            scales: {
                                            x: {
                                                stacked: true,
                                            },
                                            y: {
                                                stacked: true
                                            }
                                            }    

                                        }}
                                    />
                                    ) 
                                :(<h3>{`${titleImportesPaciente} SIN DATOS`}</h3>)
                            ) 
                        }
                    </ChartLayout> 

                    <ChartLayout selectAseguradoras={true} 
                                 aseguradoras={allAseguradoras} 
                                 filtros={filtrosServiciosAseguradoras} 
                                 conAnio={false}
                                 conMes = {false}
                                 conFechas={true}
                                 onChange={onChangeServiciosAseguradoras} 
                                 exportChart={exportChart}
                                 medicos={allMedicos} 
                                 selectMedicos={true} 
                                 refGrafica={refGraficaServiciosAseguradoras} {...chartLayoutServiciosAseguradoras}>
                        {loadingServiciosAseguradoras ? 
                            (<CustomSpinner 
                                height={"700px"} 
                                infoText={titleServiciosAseguradoras}/>) 
                            :(dataServiciosAseguradoras?.datasets?.length > 0 ?
                                    (
                                    <BarChart 
                                        ref={refGraficaServiciosAseguradoras} 
                                        requiresMoneyFormat={false} 
                                        title={titleServiciosAseguradoras} 
                                        data={dataServiciosAseguradoras}/>
                                    )
                                    : (<h3>{`${titleServiciosAseguradoras} SIN DATOS`}</h3>)
                            )
                        }
                    </ChartLayout>

                    <ChartLayout selectAseguradoras={false} 
                                 aseguradoras={allAseguradoras} 
                                 filtros={filtrosTotalServicios} 
                                 conAnio={false}
                                 conMes = {false}
                                 conFechas={false}
                                 exportChart={exportChart}
                                 medicos={allMedicos} 
                                 selectMedicos={true} 
                                 refGrafica={refGraficaTotalServicios} {...chartLayoutTotalServicios}>
                        {loadingTotalServicios ? 
                            (<CustomSpinner 
                                height={"700px"} 
                                infoText={titleTotalServicios}/>) 
                            :(dataTotalServicios?.datasets?.length > 0 ?
                                    (<BarChart 
                                        ref={refGraficaTotalServicios} 
                                        requiresMoneyFormat={false} 
                                        title={titleTotalServicios} 
                                        mostrarTitulos = {false}
                                        data={dataTotalServicios}/>
                                    )
                                    : (<h3>{`${titleTotalServicios} SIN DATOS`}</h3>)
                            )
                        }
                    </ChartLayout>


                    <ChartLayout selectMedicos={false} 
                                 medicos={allMedicos} 
                                 filtros={filtrosAdministracionMedico} 
                                 conFechas={true}
                                 onChange={onChangeAdministracionMedico} 
                                 exportChart={exportChart} 
                                 refGrafica={refGraficaAdministracionMedico} {...chartLayoutAdministracionMedico}>
                        {loadingAdministracionMedico ? 
                            (<CustomSpinner height={"700px"} 
                                            infoText={titleAdministracionMedico}/>)
                            :(dataAdministracionMedico.datasets[0]?.data?.length > 0 ? 
                                (<PieChart title={titleAdministracionMedico} 
                                           data={dataAdministracionMedico} 
                                           displayDataLabels={true}
                                           requiresMoneyFormat={false} 
                                           onlyShowPercentage={false} 
                                           displayYAxis={false}
                                           displayLabel={false}/>)
                                    : (<h3>{`${titleAdministracionMedico} SIN DATOS`}</h3>)
                            )
                        }
                    </ChartLayout>

                    <ChartLayout selectAseguradoras={true} 
                                 medicos={allMedicos} 
                                 aseguradoras={allAseguradoras} 
                                 filtros={filtrosPacientesMensuales} 
                                 conAnio={true}
                                 onChange={onChangePacientesMensuales} 
                                 exportChart={exportChart}
                                 selectMedicos={true} 
                                 refGrafica={refGraficaPacientesMensuales} {...chartLayoutPacientesMensuales}>
                        {loadingPacientesMensuales ? 
                            (<CustomSpinner height={"700px"} infoText={titlePacientesMensuales}/>) 
                            :(dataPacientesMensuales?.datasets?.length > 0 ?
                                (
                                    <LineChart ref={refGraficaPacientesMensuales} 
                                            title={"Pacientes Mensuales"} 
                                            data={dataPacientesMensuales}
                                            displayDataLabels={true} 
                                            requiresMoneyFormat={false} 
                                            displayYAxis={true} 
                                            displayLabel={true}/>
                                    
                                    )
                                    : (<h3>{`${titlePacientesMensuales} SIN DATOS`}</h3>)
                            )
                        }
                    </ChartLayout>

                    <ChartLayout selectAseguradoras={true} 
                                 aseguradoras={allAseguradoras} 
                                 filtros={filtrosEsquemasTx} 
                                 conFechas={true}
                                 onChange={onChangeEsquemasTx} 
                                 exportChart={exportChart} 
                                 medicos={allMedicos}
                                 selectMedicos={true}  
                                 refGrafica={refGraficaEsquemasTx} {...chartLayoutEsquemasTx}>
                        {loadingEsquemasTx ? 
                            (<CustomSpinner 
                                height={"700px"} 
                                infoText={titleEsquemasTx}/>)
                            :(dataEsquemasTx.datasets[0]?.data?.length > 0 ? 
                                (<PieChart title={titleEsquemasTx} 
                                           data={dataEsquemasTx} 
                                           displayDataLabels={true} 
                                           requiresMoneyFormat={false}
                                           displayYAxis={false} 
                                           onlyShowPercentage={true} 
                                           displayLabel={false}/>)
                                    : (<h3>{`${titleEsquemasTx} SIN DATOS`}</h3>)
                        )}
                    </ChartLayout>


                                    <ChartLayout 
                                    selectAseguradoras={true} 
                                    aseguradoras={allAseguradoras}
                                    filtros={filtrosMinMaxCarta} 
                                    conAnio={false}
                                    conMes ={false}
                                    conFechas={true}
                                    onChange={onChangeMinMaxCarta} 
                                    exportChart={exportChart}
                                    medicos={allMedicos} 
                                    selectMedicos={true} 
                                    refGrafica={refGraficaMinMaxCarta} {...chartLayoutMinMaxCarta}>
                                    {loadingMinMaxCarta ? 
                                        (
                                            <CustomSpinner height={"700px"} infoText={titleMinMaxCarta}/>
                                        ) 
                                        :(dataMinMaxCarta?.length > 0 ? 
                                            (     
                                                <Bar
                                                    ref={refGraficaMinMaxCarta} 
                                                    requiresMoneyFormat = {true}
                                                    data={{
                                                        // Name of the variables on x-axies for each bar
                                                        labels: dataMinMaxCarta[0].labels,
                                                        datasets: [
                                                            { 
                                                                // Label for bars
                                                                label: "Máx. de TIEMPO REC CARTA",
                                                                // Data or value of your each variable
                                                                data: dataMinMaxCarta[0].maximos,
                                                                // Color of each bar
                                                                backgroundColor: "#aed6f1",
                                                                // Border color of each bar
                                                                borderColor: "85c1e9",
                                                                borderWidth: 0.5,
                                                            },                                     
                                                            {
                                                                // Label for bars
                                                                label:  "Min. de TIEMPO REC CARTA",
                                                                // Data or value of your each variable
                                                                data: dataMinMaxCarta[0].minimos,
                                                                // Color of each bar
                                                                backgroundColor: "#f0b27a",
                                                                // Border color of each bar
                                                                borderColor: "orange",
                                                                borderWidth: 0.5,
                                                            },                                   
                                                        ],
                                                    }}
                                                    // Height of graph
                                                    height={cantidadMinMaxCarta}                                                
                                                    options={{ 
                                                        maintainAspectRatio: false,
                                                        horizontal : true,
                                                        indexAxis: 'y',
                                                        plugins: {
                                                            legend: {
                                                                display: true,
                                                                position: "bottom",                                    
                                                            },
                                                            title: {
                                                                ...barChartOptions.plugins.title,
                                                                text: titleMinMaxCarta,
                                                                padding: {
                                                                    bottom: 50,
                                                                }
                                                            },
                                                            datalabels: {
                                                                anchor: 'end',                 
                                                                display: true,
                                                                align: 'right',
                                                                formatter: (value) => value !== null ? value > 0 ? value : "" : "",
                                                                color: 'black',
                                                                font: {
                                                                    size: 11,
                                                                    //weight: 'bold'
                                                                }
                                                            },
            
                                                        },
                                                        bar: {
                                                            borderRadius: 40,
                                                            borderWidth: 0.7,
                                                        },
                                                        ticks: {
                                                            padding: 15
                                                        },
                                                        scales: {
                                                            yAxes: [
                                                                {
                                                                    ticks: {
                                                                // The y-axis value will start from zero
                                                                        beginAtZero: false,
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                        legend: {
                                                            labels: {
                                                                fontSize: 15,
                                                            },
                                                        },
                                                        responsive: true,
                                                        scales: {
                                                        x: {
                                                            stacked: true,
                                                        },
                                                        y: {
                                                            stacked: true
                                                        }
                                                        }    
            
                                                    }}
                                                />
                                                ) 
                                                : (<h3>{`${titleMinMaxCarta} SIN DATOS`}</h3>)
                                        ) 
                                    }
                                    </ChartLayout> 

            </div>
        </>
    )
}

export default EstadisticasAseguradora;