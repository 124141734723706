import React from "react";
import { Row, Col } from "reactstrap";

const Marcadores = ({ marcadores, onChange }) => {
  return (
    <Row>
      <Col xl={6} lg={12} md={12}>
        <Row className="form-group">
          <label className="col-sm-5 col-form-label">Talla (cm)</label>
          <Col sm={7}>
            <input
              type="number"
              name="talla"
              value={marcadores.talla ? marcadores.talla : ""}
              onChange={onChange}
              className="form-control"
            />
          </Col>
        </Row>
        <Row className="form-group">
          <label className="col-sm-5 col-form-label">Peso (kg)</label>
          <Col sm={7}>
            <input
              type="number"
              name="peso"
              value={marcadores.peso ? marcadores.peso : ""}
              onChange={onChange}
              className="form-control"
            />
          </Col>
        </Row>
        <Row className="form-group">
          <label className="col-sm-5 col-form-label">Indice de Masa Corporal (IMC)</label>
          <Col sm={7}>
            <input
              type="text"
              disabled={true}
              value={marcadores.indiceMasaCorporal ? marcadores.indiceMasaCorporal : ""}
              className="form-control"
            />
          </Col>
        </Row>
        <Row className="form-group">
          <label className="col-sm-5 col-form-label">Superficie Corporal Total (SCT)</label>
          <Col sm={7}>
            <input
              type="text"
              disabled={true}
              value={marcadores.superficieCorporalTotal ? marcadores.superficieCorporalTotal : ""}
              className="form-control"
            />
          </Col>
        </Row>
        <Row className="form-group">
          <label className="col-sm-5 col-form-label">Saturación de Oxigeno (%)</label>
          <Col sm={7}>
            <input
              type="number"
              name="saturacionOxigeno"
              value={marcadores.saturacionOxigeno ? marcadores.saturacionOxigeno : ""}
              onChange={onChange}
              className="form-control"
            />
          </Col>
        </Row>
      </Col>
      <Col xl={6} lg={12} md={12}>
        <Row className="form-group">
          <label className="col-sm-5 col-form-label">Tensión Arterial Sistólica (mmHg)</label>
          <Col sm={7}>
            <input
              type="number"
              name="tensionArterialSistolica"
              value={marcadores.tensionArterialSistolica ? marcadores.tensionArterialSistolica : ""}
              onChange={onChange}
              className="form-control"
            />
          </Col>
        </Row>
        <Row className="form-group">
          <label className="col-sm-5 col-form-label">Tensión Arterial Diastólica (mmHg)</label>
          <Col sm={7}>
            <input
              type="number"
              name="tensionArterialDiastolica"
              value={marcadores.tensionArterialDiastolica ? marcadores.tensionArterialDiastolica : ""}
              onChange={onChange}
              className="form-control"
            />
          </Col>
        </Row>
        <Row className="form-group">
          <label className="col-sm-5 col-form-label">Frecuencia cardiaca (latidos/min)</label>
          <Col sm={7}>
            <input
              type="number"
              name="frecuenciaCardiaca"
              value={marcadores.frecuenciaCardiaca ? marcadores.frecuenciaCardiaca : ""}
              onChange={onChange}
              className="form-control"
            />
          </Col>
        </Row>

        <Row className="form-group">
          <label className="col-sm-5 col-form-label">Frecuencia Respiratoria (resp/min)</label>
          <Col sm={7}>
            <input
              type="number"
              name="frecuenciaRespiratoria"
              value={marcadores.frecuenciaRespiratoria ? marcadores.frecuenciaRespiratoria : ""}
              onChange={onChange}
              className="form-control"
            />
          </Col>
        </Row>
        <Row className="form-group">
          <label className="col-sm-5 col-form-label">Temperatura (°C)</label>
          <Col sm={7}>
            <input
              type="number"
              name="temperatura"
              value={marcadores.temperatura ? marcadores.temperatura : ""}
              onChange={onChange}
              className="form-control"
            />
          </Col>
        </Row>

        <Row className="form-group">
          <label className="col-sm-5 col-form-label">
            <a href="https://www.nccn.org/guidelines/category_1" target="_blank" rel="noreferrer">
              Guía NCCN
            </a>
          </label>
        </Row>
      </Col>
    </Row>
  );
};

export default Marcadores;
