import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import formatNumber from "../../utils/index";

import { Link } from "react-router-dom";

import AuthContext from "../../context/Auth/AuthContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import PagosMedicoContext from "../../context/PagosMedico/PagosMedicoContext";

export default function ListadoPagosMedicos(props) {
  const [toggleSearchBy, setToggleSearchBy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagoSeleccionado, setPagoSeleccionado] = useState();
  const [disabledCheckPagos, setDisabledCheckPagos] = useState(false);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_cancelar_pago: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const semanas = Array.from({ length: 52 }, (v, k) => k + 1);

  const pagosMedicoContext = useContext(PagosMedicoContext);

  const {
    filtrosPagosMedico,
    setFiltrosPagosMedico,
    listarPagosMedico,
    listarPagosMedicosInternos,
    listarPagosMedicosExternos,
    pagosMedicoFiltrados,
    setPagosMedicoFiltrados,
    cancelarPago,
    exportPagoMedicoReport,
  } = pagosMedicoContext;

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxMedicos = useContext(MedicosContext);
  const { medicosInternos,  listarMedicosInternos, listarMedicosExternos } = ctxMedicos;

  const toggleSearch = () => {
    setToggleSearchBy(!toggleSearchBy);
    limpiarFiltros();
  };

  const limpiarFiltros = () => {
    setFiltrosPagosMedico({
      fechaInicial: "",
      medico: "",
      fechaFinal: "",
      semana: "",
      folioValePago: "",
      importe: "",
    });
  };

  // const mostrarDetallePago = async () => {
  //   let pagoMedicoChecado = await isPagoChecked();
  //   if (!pagoMedicoChecado) {
  //     //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un pago
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       dynamic_title: "Fallo",
  //       dynamic_description: "Por favor seleccione un pago",
  //     });
  //     return;
  //   }
  //   const { folioValePago } = pagoMedicoChecado;

  //   habilitarPagosChecked();
  //   props.history.push(`/pagos/medico/detalle/${folioValePago}`);
  // };

  const buscarPorFolio = (e) => {
    e.preventDefault();
    listarPagosMedico({
      folioValePago: filtrosPagosMedico.folioValePago,
    });
  };

  const buscarPorFiltros = (e) => {
    e.preventDefault();
    if (filtrosPagosMedico.fechaInicial && !filtrosPagosMedico.fechaFinal) {
      return;
    }

    if (filtrosPagosMedico.fechaFinal) {
      filtrosPagosMedico.fechaFinal = filtrosPagosMedico.fechaFinal + "T23:59:59";
    }

    listarPagosMedico(filtrosPagosMedico);

    if (filtrosPagosMedico.fechaFinal) {
      filtrosPagosMedico.fechaFinal = filtrosPagosMedico.fechaFinal.split("T")[0];
    }
  };

  const onChangeimporte = (e) => {
    let value = e.target.value;
    value = Number(value.replace("$", ""));

    setFiltrosPagosMedico({
      ...filtrosPagosMedico,
      importe: value,
    });
  };

  const onChange = (e) => {
    if (e.target.name === "fechaInicial") {
      setFiltrosPagosMedico({
        ...filtrosPagosMedico,
        [e.target.name]: e.target.value,
        fechaFinal: e.target.value,
      });
    } else {
      setFiltrosPagosMedico({
        ...filtrosPagosMedico,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCheckedPagoMedico = async (pagoMedico, value) => {
    if (!value) {
      // pagoMedico.checked = value;
      pagosMedicoFiltrados.map((pago) => {
        if (pagoMedico.idMedicoPago == pago.idMedicoPago) {
          pago.checked = value;
        }
      });
      setPagosMedicoFiltrados(pagosMedicoFiltrados);
      const pagoChecado = await isPagoChecked();
      if (!pagoChecado) {
        habilitarPagosChecked();
      }
    } else {
      // pagoMedico.checked = value;
      pagosMedicoFiltrados.map((pago) => {
        if (pagoMedico.idMedicoPago == pago.idMedicoPago) {
          pago.checked = value;
        }
      });
      setPagosMedicoFiltrados(pagosMedicoFiltrados);
      if (!disabledCheckPagos) {
        deshabilitarPagosChecked(pagoMedico.idMedicoPago);
      }
    }
  };

  const isPagoChecked = async () => {
    let pagoChecked = false;
    pagosMedicoFiltrados.map((pagoMedico) => {
      if (pagoMedico.checked) {
        if (!pagoChecked) {
          pagoChecked = pagoMedico;
        }
        pagoChecked = pagoMedico;
      }
    });
    return pagoChecked;
  };

  const deshabilitarPagosChecked = async (idMedicoPago) => {
    pagosMedicoFiltrados.map((pago) => {
      if (pago.idMedicoPago !== idMedicoPago) {
        pago.disabledCheck = true;
      }
    });
    setPagosMedicoFiltrados(pagosMedicoFiltrados);
    setDisabledCheckPagos(true);
  };

  const habilitarPagosChecked = async () => {
    pagosMedicoFiltrados.map((pago) => {
      return (pago.disabledCheck = false);
    });
    pagosMedicoFiltrados.map((pago) => {
      return (pago.checked = false);
    });
    setPagosMedicoFiltrados(pagosMedicoFiltrados);
    setDisabledCheckPagos(false);
  };

  const imprimirPago = async () => {
    let pagoMedicoChecado = await isPagoChecked();

    const { idMedicoPago } = pagoMedicoChecado;

    if (!pagoMedicoChecado) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione un pago",
      });
      return;
    }

    if (idMedicoPago) {
      exportPagoMedicoReport(idMedicoPago);
    }
  };

  const confirmCancelarPagos = async () => {
    let pagoMedicoChecado = await isPagoChecked();

    if (!pagoMedicoChecado) {
      //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione un pago",
      });
      return;
    }

    setPagoSeleccionado(pagoMedicoChecado);

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_cancelar_pago: true,
    });
  };

  const cancelarPagos = async () => {
    const res = await cancelarPago({
      idMedicoPago: pagoSeleccionado.idMedicoPago,
      folioVale: pagoSeleccionado.folioVale,
    });
    setLoading(true);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      habilitarPagosChecked();
      const pagosFiltrados = await pagosMedicoFiltrados.filter((pago) => pago.idMedicoPago !== pagoSeleccionado.idMedicoPago);
      setPagosMedicoFiltrados(pagosFiltrados);
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_cancelar_pago: false,
        dynamic_description: "Se cancelaron los pagos seleccionados",
      });
    }
  };

  useEffect(() => {
    if(usuario.authorities.includes("ROLE_FARMACIA")){
      listarMedicosInternos();
      listarPagosMedicosInternos(filtrosPagosMedico);
    }else{
      listarMedicosExternos();
      listarPagosMedicosExternos(filtrosPagosMedico);
    }
    
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Lista de Pagos Médico</h3>
        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearch}>
          {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
        </button>
        {usuario.authorities.includes("OP_PAGO_MEDICO_CREAR") ||
        usuario.authorities.includes("ROLE_RECEPCION") ||
        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ? (
          <Link to={`/pagos/medico/nuevo`}>
            <button
              className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
              type="button">
              Nuevo Pago Médico
            </button>
          </Link>
        ) : null}

        <h4 className="flex mt-5">Filtros de Búsqueda</h4>
        {/* Buscar por folio */}
        <Card
          hidden={!toggleSearchBy}
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFolio(e)}>
            <CardBody>
              <Row>
                <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                  <Row
                    className="form-group"
                    style={{
                      marginBottom: "0",
                    }}>
                    <Col sm={10} className="flex col-sm-10">
                      <input
                        className="form-control mr-2.5"
                        type="text"
                        name="folioValePago"
                        placeholder="Escribe tu Folio de Pago"
                        onChange={onChange}
                        value={filtrosPagosMedico.folioValePago ? filtrosPagosMedico.folioValePago : ""}
                      />
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                        style={{
                          marginRight: "2.5rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        type="submit">
                        Buscar
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>
        {/* Buscar por  filtros */}
        <Card
          hidden={toggleSearchBy}
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFiltros(e)}>
            <CardBody>
              <Row>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaInicial"
                        onChange={onChange}
                        value={filtrosPagosMedico.fechaInicial}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Médico tratante</label>
                    <Col sm={7}>
                      <select
                        name="medico"
                        id="medico"
                        className="form-control"
                        onChange={onChange}
                        value={filtrosPagosMedico.medico}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {medicosInternos.map((medico) => {
                          return (
                            <>
                              <option value={medico.idCatMedico}>{`${medico.prefijo ? medico.prefijo : ""} ${
                                medico.catPersona.nombres
                              } ${medico.catPersona.apellidoPaterno} ${medico.catPersona.apellidoMaterno}`}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Semana (Año)</label>
                    <Col sm={7}>
                      <select name="semana" className="form-control" onChange={onChange} value={filtrosPagosMedico.semana}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {semanas.map((prov) => {
                          return (
                            <>
                              <option value={prov}>{prov}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fecha Final</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaFinal"
                        disabled={!filtrosPagosMedico.fechaInicial ? true : false}
                        min={filtrosPagosMedico.fechaInicial}
                        onChange={onChange}
                        value={filtrosPagosMedico.fechaFinal}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Importe Máximo</label>
                    <Col sm={7}>
                      <input
                        type="text"
                        className="form-control"
                        name="importe"
                        value={filtrosPagosMedico.importe ? `$${filtrosPagosMedico.importe}` : `$0`}
                        onChange={onChangeimporte}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="submit"
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Aplicar Filtros
                  </button>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="button"
                    onClick={limpiarFiltros}
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Limpiar Filtros
                  </button>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>
        <h4 className="flex mt-5">Lista de Pagos</h4>
        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <Row>
                  <Col>
                    {/* {usuario.authorities.includes("ROLE_FARMACIA") ? (
                      <button
                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                        type="button"
                        style={{
                          marginRight: "2.5rem",
                          marginTop: "1rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        onClick={() => mostrarDetallePago()}>
                        Detalle del Pago
                      </button>
                    ) : null} */}
                    {usuario.authorities.includes("ROLE_FARMACIA") ||
                    usuario.authorities.includes("ROLE_RECEPCION") ||
                    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ? (
                      <button
                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                        type="button"
                        style={{
                          marginRight: "2.5rem",
                          marginTop: "1rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        onClick={() => imprimirPago()}>
                        Detalle del Pago
                      </button>
                    ) : null}

                    {usuario.authorities.includes("OP_PAGO_MEDICO_ELIMINAR") ||
                    usuario.authorities.includes("ROLE_RECEPCION") ||
                    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ? (
                      <button
                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                        type="button"
                        style={{
                          marginRight: "2.5rem",
                          marginTop: "1rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        onClick={() => confirmCancelarPagos()}>
                        Cancelar Pago
                      </button>
                    ) : null}
                  </Col>
                </Row>

                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Folio Vale</th>
                        <th scope="col">Folio Pago</th>
                        <th scope="col">Fecha Pago</th>
                        <th scope="col">Año</th>
                        <th scope="col">Semana</th>
                        <th scope="col">Médico</th>
                        <th scope="col">
                          Importe<br></br>Total Pago
                        </th>
                        {/* <th scope="col">
                          IMPORTE<br></br>PENDIENTE
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {pagosMedicoFiltrados
                        ? pagosMedicoFiltrados.map((pagoMedico, index) => {
                            return (
                              <>
                                <tr key={pagoMedico}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      disabled={pagoMedico.disabledCheck}
                                      onClick={() => handleCheckedPagoMedico(pagoMedico, !pagoMedico.checked)}
                                      checked={pagoMedico.checked}
                                    />
                                  </td>
                                  <td>{pagoMedico.folioVale ? pagoMedico.folioVale : ""}</td>
                                  <td>{pagoMedico.folioValePago ? pagoMedico.folioValePago : ""}</td>
                                  <td>{pagoMedico.fechaPago ? pagoMedico.fechaPago : ""}</td>
                                  <td>{pagoMedico.anio ? pagoMedico.anio : ""}</td>
                                  <td>{pagoMedico.semana ? pagoMedico.semana : ""}</td>

                                  <td key={pagoMedico.idCatMedico}>{pagoMedico.medico ? pagoMedico.medico : ""}</td>
                                  <td>{pagoMedico.importe ? formatNumber(pagoMedico.importe) : ""}</td>
                                  {/* <td>
                                    {pagoMedico.importePendiente
                                      ? `$${
                                          pagoMedico.importePendiente % 1 === 0
                                            ? pagoMedico.importePendiente.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            : pagoMedico.importePendiente
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }`
                                      : "$0"}
                                  </td> */}
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {UISweetAlert.confirm_alert_cancelar_pago ? (
          <SweetAlert
            title="¿Desea cancelar el pago seleccionado?"
            showCancel
            showConfirm
            confirmBtnText="Confirmar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            style={{
              width: "45%",
            }}
            onConfirm={() => cancelarPagos()}
            onCancel={() => {
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_cancelar_pago: false,
              });
              setPagoSeleccionado(null);
            }}></SweetAlert>
        ) : null}
        {UISweetAlert.success_dlg ? (
          <SweetAlert
            success
            title={UISweetAlert.dynamic_title}
            onConfirm={() => {
              if (UISweetAlert.redirect) {
                props.history.push(UISweetAlert.path);
              }
              setUISweetAlert({
                ...UISweetAlert,
                success_dlg: false,
              });
            }}>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {UISweetAlert.error_dlg ? (
          <SweetAlert
            error
            title={UISweetAlert.dynamic_title}
            onConfirm={() =>
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: false,
              })
            }>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {loading ? (
          <SweetAlert title="Cargando!" showConfirm={false}>
            <Spinner />
          </SweetAlert>
        ) : null}
      </div>
    </>
  );
}
