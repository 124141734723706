import React, { useContext } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col } from "reactstrap";
// import useCloseWindowWarning from "../../../hooks/useCloseWindowWarning";

import WindowWarningContext from "../../../context/WindowWarning/WindowWarningContext";

export default function AntecedentesHeredofamiliares({ props, historiaClinicaInicial, setHistoriaClinicaInicial }) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito } = windowWarningContext;

  // const [Prompt, setEditTrue, setEditFalse, isEdit] = useCloseWindowWarning();

  const onChange = async (e) => {
    // if (!isEdit) setEditTrue();
    if (!seEdito) changeEdit(true);
    setHistoriaClinicaInicial({
      ...historiaClinicaInicial,
      antecedentesHeredoFamiliares: {
        ...historiaClinicaInicial.antecedentesHeredoFamiliares,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <div className="mt-4 container-fluid" style={{ textAlign: "center" }}>
      <Row>
        <h4>Antecedentes Heredofamiliares</h4>
      </Row>
      <Row>
        <Col>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Materno</label>
            <Col sm={10}>
              <textarea
                name="materno"
                value={
                  historiaClinicaInicial.antecedentesHeredoFamiliares &&
                  historiaClinicaInicial.antecedentesHeredoFamiliares.materno
                    ? historiaClinicaInicial.antecedentesHeredoFamiliares.materno
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Paterno</label>
            <Col sm={10}>
              <textarea
                name="paterno"
                value={
                  historiaClinicaInicial.antecedentesHeredoFamiliares &&
                  historiaClinicaInicial.antecedentesHeredoFamiliares.paterno
                    ? historiaClinicaInicial.antecedentesHeredoFamiliares.paterno
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Hermanos</label>
            <Col sm={10}>
              <textarea
                name="hermanos"
                value={
                  historiaClinicaInicial.antecedentesHeredoFamiliares &&
                  historiaClinicaInicial.antecedentesHeredoFamiliares.hermanos
                    ? historiaClinicaInicial.antecedentesHeredoFamiliares.hermanos
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Otros</label>
            <Col sm={10}>
              <textarea
                name="otros"
                value={
                  historiaClinicaInicial.antecedentesHeredoFamiliares && historiaClinicaInicial.antecedentesHeredoFamiliares.otros
                    ? historiaClinicaInicial.antecedentesHeredoFamiliares.otros
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* {Prompt} */}
    </div>
  );
}
