import React, { useReducer } from "react";

import CatTipoProductoContext from "./CatTipoProductoContext";
import CatTipoProductoReducer from "./CatTipoProductoReducer";

import { LISTAR_CATTIPOPRODCUTOS, LISTAR_CATTIPOPRODCUTOS_ALL } from "../../types";

import clienteAxios from "../../config/axios";

export default function CatTipoProductoState(props) {
  const initialState = {
    tiposProductos: [],
    catTipoProductosAll: [],
  };

  const [state, dispatch] = useReducer(CatTipoProductoReducer, initialState);

  const listarProductoTiposProductos = async () => {
    const res = await clienteAxios.get("/tipoproductos/productos/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CATTIPOPRODCUTOS,
      payload: res.data,
    });
  };

  const listarTiposProductos = async () => {
    const res = await clienteAxios.get("/tipoproductos/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CATTIPOPRODCUTOS_ALL,
      payload: res.data,
    });
  };

  return (
    <CatTipoProductoContext.Provider
      value={{
        tiposProductos: state.tiposProductos,
        catTipoProductosAll: state.catTipoProductosAll,
        listarProductoTiposProductos,
        listarTiposProductos,
      }}>
      {props.children}
    </CatTipoProductoContext.Provider>
  );
}
