import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

import CatTipoProductoContext from "../../context/CatTipoProducto/CatTipoProductoContext";
import ReporteDesplazamientoProductoContext from "../../context/ReporteDesplazamientoProducto/ReporteDesplazamientoProductoContext";
import CatTipoVentaContext from "../../context/CatTipoVenta/CatTipoVentaContext";
import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";
import CatLaboratoriosContext from "../../context/CatLaboratorios/CatLaboratoriosContext";
import TableComponent from "../TablaPaginadaSinUpdate";

export default function ReporteDesplazamientoProducto(props) {
  const [años, setAños] = useState([]);
  const [loading, setLoading] = useState(false);

  const [UISweetAlert, setUISweetAlert] = useState({
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);

  const ctxLaboratorios = useContext(CatLaboratoriosContext);
  const { catLaboratorios, listarLaboratorios } = ctxLaboratorios;

  const ctxProdServ = useContext(ProductosServiciosContext);
  const { principiosActivos, listarPrincipiosActivos } = ctxProdServ;

  //ACCESO AL ESTADO GLOBAL - Tipo Productos
  const ctxTipoProducto = useContext(CatTipoProductoContext);
  const { tiposProductos, listarProductoTiposProductos } = ctxTipoProducto;

  //ACCESO AL ESTADO GLOBAL - Tipo Productos
  const ctxTipoVenta = useContext(CatTipoVentaContext);
  const { tipoVentas, listarTiposVenta } = ctxTipoVenta;

  //ACCESO AL ESTADO GLOBAL - Consulta Compras Producto
  const ctxReptDesProd = useContext(ReporteDesplazamientoProductoContext);
  const {
    filtrosReporteDesplazamientoProducto,
    setFiltrosReporteDesplazamientoProducto,
    reportesDesplazamientoProducto,
    exportDesplazamientoProducto,
    listarReporteDesplazamientoProductoPaginado,
  } = ctxReptDesProd;

  const onChange = (e) => {
    // if(reportesDesplazamientoProducto.length > 0){limpiarReportes();}
    if (e.target.name == "fechaInicial") {
      setFiltrosReporteDesplazamientoProducto({
        ...filtrosReporteDesplazamientoProducto,
        [e.target.name]: e.target.value,
        fechaFinal: e.target.value,
      });
    } else {
      setFiltrosReporteDesplazamientoProducto({
        ...filtrosReporteDesplazamientoProducto,
        [e.target.name]: e.target.value,
      });
    }
  };

  const buscarPorFiltros = async (e) => {
    e.preventDefault();
    setLoading(true);
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosReporteDesplazamientoProducto({
      anio: new Date().getFullYear(),
      tipoProducto: "",
      tipoVenta: "",
      top10: false,
      principioActivo: null,
      laboratorio: null,
    });
  };

  const exportarTablaExcel = async () => {
    setLoading(true);
    const res = await exportDesplazamientoProducto();

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
    }
  };

  const changeTopProductos = () => {
    setFiltrosReporteDesplazamientoProducto({
      ...filtrosReporteDesplazamientoProducto,
      top10: !filtrosReporteDesplazamientoProducto.top10,
    });
  };

  const llenarAnios = async () => {
    var currentTime = new Date();
    setAños([
      currentTime.getFullYear() - 5,
      currentTime.getFullYear() - 4,
      currentTime.getFullYear() - 3,
      currentTime.getFullYear() - 2,
      currentTime.getFullYear() - 1,
      currentTime.getFullYear(),
    ]);
  };

  useEffect(() => {
    const fetchAllContextData = () => {
      listarProductoTiposProductos();
      listarLaboratorios();
      listarTiposVenta();
      llenarAnios();
      listarPrincipiosActivos();
    };

    fetchAllContextData();
  }, []);

  const setPrincipioActivo = async (option) => {
    setFiltrosReporteDesplazamientoProducto({
      ...filtrosReporteDesplazamientoProducto,
      principioActivo: option && option.principioActivo ? option.principioActivo : "",
    });
  };

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);

      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await listarReporteDesplazamientoProductoPaginado({ page, limit });

      //Cantidad de datos disponibles en la tabla servicios de la base de datos
      const count = response.count;

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoading(false);
      setLoadingData(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);

    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const columns = useMemo(
    () => [
      { Header: "Número de Identificación", accessor: "numIdentificacion" },
      { Header: "Código EAN", accessor: "codigoEAN" },
      { Header: "Nombre Comercial", accessor: "nombreComercial" },
      { Header: "Fabricante", accessor: "laboratorio" },
      { Header: "Principio Activo", accessor: "principioActivo" },
      { Header: "Total Vendido", accessor: "totalVendido" },
      { Header: "E", accessor: "enero" },
      { Header: "F", accessor: "febrero" },
      { Header: "M", accessor: "marzo" },
      { Header: "A", accessor: "abril" },
      { Header: "M", accessor: "mayo" },
      { Header: "J", accessor: "junio" },
      { Header: "J", accessor: "julio" },
      { Header: "A", accessor: "agosto" },
      { Header: "S", accessor: "septiembre" },
      { Header: "O", accessor: "octubre" },
      { Header: "N", accessor: "noviembre" },
      { Header: "D", accessor: "diciembre" },
    ],
    []
  );

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Reporte de Desplazamiento de Productos</h3>

        <Card
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFiltros(e)}>
            <CardBody>
              <Row>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Tipo de Producto</label>
                    <Col sm={7}>
                      <select
                        className="form-control"
                        name="tipoProducto"
                        onChange={onChange}
                        value={filtrosReporteDesplazamientoProducto.tipoProducto}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tiposProductos.map((tipoProd) => {
                          return (
                            <>
                              <option value={tipoProd.idCatTipoProducto}>{tipoProd.tipoProducto}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Año</label>
                    <Col sm={7}>
                      <select
                        name="anio"
                        className="form-control"
                        onChange={onChange}
                        value={filtrosReporteDesplazamientoProducto.anio}>
                        {años.map((año) => {
                          return (
                            <>
                              <option value={año}>{año}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col sm={4}>
                      <label className="col-sm-4 col-form-label">Top 10</label>
                      <input
                        type="radio"
                        checked={filtrosReporteDesplazamientoProducto.top10}
                        disabled={filtrosReporteDesplazamientoProducto.top10}
                        onClick={changeTopProductos}
                      />
                    </Col>
                    <Col sm={4}>
                      <label className="col-sm-4 col-form-label">Todos</label>
                      <input
                        type="radio"
                        checked={!filtrosReporteDesplazamientoProducto.top10}
                        disabled={!filtrosReporteDesplazamientoProducto.top10}
                        onClick={changeTopProductos}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Tipo de Venta</label>
                    <Col sm={7}>
                      <select
                        name="tipoVenta"
                        className="form-control"
                        onChange={onChange}
                        value={filtrosReporteDesplazamientoProducto.tipoVenta}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tipoVentas.map((tipVenta) => {
                          return (
                            <>
                              <option value={tipVenta.idCatTipoVenta}>{tipVenta.tipoVenta}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Principio Activo</label>
                    <Col sm={7}>
                      <Select
                        isSearchable
                        isClearable
                        options={principiosActivos}
                        getOptionValue={(option) => option.idCatProducto}
                        getOptionLabel={(option) => `${option.principioActivo}`}
                        value={principiosActivos.filter((principioActivo) => principioActivo.principioActivo 
                            == filtrosReporteDesplazamientoProducto.principioActivo)}
                        onChange={(option) => setPrincipioActivo(option)}
                        placeholder="Seleccione una opción"
                        noOptionsMessage={() => "No hay opciones"}
                        name="principioActivo"
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fabricante</label>
                    <Col sm={7}>
                      <select
                        name="laboratorio"
                        className="form-control"
                        onChange={onChange}
                        value={
                          filtrosReporteDesplazamientoProducto.laboratorio ? filtrosReporteDesplazamientoProducto.laboratorio : ""
                        }>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {catLaboratorios.map((laboratorio) => {
                          return (
                            <>
                              <option value={laboratorio.idCatLaboratorio}>{laboratorio.laboratorio}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="submit"
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Aplicar Filtros
                  </button>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="button"
                    onClick={limpiarFiltros}
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Limpiar Filtros
                  </button>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>

        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col>
                {reportesDesplazamientoProducto.length > 0 && (
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                    type="button"
                    onClick={exportarTablaExcel}>
                    Exportar (Excel)
                  </button>
                )}
              </Col>
            </Row>
            <TableComponent
              pageCount={pageCount}
              fetchData={fetchData}
              columns={columns}
              loading={loadingData}
              data={reportesDesplazamientoProducto}
              indexOfPage={controlledPageIndex}
            />
            {}
          </CardBody>
        </Card>
      </div>

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </>
  );
}
