import { Col, Drawer, Row, Form } from "antd";
import { useState, useContext } from "react";

import HojaIndicacionContext from "../../context/HojaIndicacion/HojaIndicacionContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";

import formatDateWithoutHour from "../../utils/formatDateWithoutHour";

const DrawerHojaIndicacion = ({ hojaIndicacion }) => {
  const hojaIndicacionesContext = useContext(HojaIndicacionContext);
  const { exportHojaIndicacion } = hojaIndicacionesContext;

  const pacientesContext = useContext(PacientesContext);
  const { exportArchivoBack } = pacientesContext;

  const nombresMedico =
    hojaIndicacion.catMedico && hojaIndicacion.catMedico.catPersona
      ? hojaIndicacion.catMedico.catPersona.nombres
        ? hojaIndicacion.catMedico.catPersona.nombres
        : ""
      : "";
  const apellidoPaternoMedico =
    hojaIndicacion.catMedico && hojaIndicacion.catMedico.catPersona
      ? hojaIndicacion.catMedico.catPersona.apellidoPaterno
        ? hojaIndicacion.catMedico.catPersona.apellidoPaterno
        : ""
      : "";

  const apellidoMaternoMedico =
    hojaIndicacion.catMedico && hojaIndicacion.catMedico.catPersona
      ? hojaIndicacion.catMedico.catPersona.apellidoMaterno
        ? hojaIndicacion.catMedico.catPersona.apellidoMaterno
        : ""
      : "";

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const exportArchivo = async (archivo) => {
    // convert image file to base64 string

    const archivoVo = {
      rutaArchivo: archivo.rutaArchivo,
      nombreArchivo: archivo.nombreArchivo,
      mimeType: archivo.mimeType,
    };
    const res = await exportArchivoBack(archivoVo);
    return res;
  };

  const handdleCheckedHojaIndicaciones = async () => {
    if (hojaIndicacion.archivoHojaIndicacion?.length > 0) {
      const archivo = hojaIndicacion.archivoHojaIndicacion[0].archivosproceso;
      const res = await exportArchivo(archivo);
      return res;
    } else if (hojaIndicacion.idHojaIndicacion != null) {
      const res = await exportHojaIndicacion({ idHojaIndicacion: hojaIndicacion.idHojaIndicacion });
      if (res.data && res.data.error) {
        return res;
      } else if (res) {
        return res;
      }
    }
  };
  const handdleCloseDrawer = () => {
    setVisible(false);
  };

  return (
    <>
      <button className="btn btn-primary-orange-umo w-md waves-effect waves-light" type="button" onClick={showDrawer}>
        Ver Hoja de Indicación
      </button>
      <Drawer
        key={hojaIndicacion.idHojaIndicacion}
        width={"80%"}
        zIndex={200}
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}>
        <button
          className="btn btn-primary-purple-umo w-md waves-effect waves-light"
          onClick={() => handdleCheckedHojaIndicaciones()}>
          Imprimir
        </button>

        <Form layout="vertical">
          {hojaIndicacion.archivoHojaIndicacion?.length > 0 ? (
            <Row gutter={16} className="pt-4">
              <Col span={12}>
                <label className="col-form-label">
                  Esta hoja de indicación fue cargada al sistema con un archivo, para ver toda la información, imprima la hoja de
                  indicación.
                </label>
              </Col>
            </Row>
          ) : null}
          <Row gutter={16} className="pt-4">
            <Col span={12}>
              <label className="col-form-label">Médico Tratante</label>
              <input
                name="medico"
                className="form-control"
                disabled={true}
                value={
                  hojaIndicacion.catMedico && hojaIndicacion.catMedico.catPersona
                    ? `${nombresMedico} ${apellidoPaternoMedico} ${apellidoMaternoMedico}`
                    : ""
                }
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <label className="col-form-label">Fecha de Registro</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fechaRegistro ? formatDateWithoutHour(hojaIndicacion.fechaRegistro) : ""}
              />
            </Col>
            <Col span={6}>
              <label className="col-form-label">Vía</label>
              <input name="via" className="form-control" disabled={true} value={hojaIndicacion.otra ? hojaIndicacion.otra : ""} />
            </Col>
            <Col span={6}>
              <label className="col-form-label">Ciclos</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.ciclos ? hojaIndicacion.ciclos : ""}
              />
            </Col>
            <Col span={6}>
              <label className="col-form-label">Ciclo (Intervalo)</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.ciclo ? hojaIndicacion.ciclo : ""}
              />
            </Col>
          </Row>
          <Row gutter={16} className="pt-3">
            <Col span={5}>
              <label className="col-form-label">Fecha 1</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fecha1 ? hojaIndicacion.fecha1 : ""}
              />
            </Col>
            <Col span={5}>
              <label className="col-form-label">Fecha 2</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fecha2 ? hojaIndicacion.fecha2 : ""}
              />
            </Col>
            <Col span={5}>
              <label className="col-form-label">Fecha 3</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fecha3 ? hojaIndicacion.fecha3 : ""}
              />
            </Col>
            <Col span={5}>
              <label className="col-form-label">Fecha 4</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fecha4 ? hojaIndicacion.fecha4 : ""}
              />
            </Col>
            <Col span={4}>
              <label className="col-form-label">Fecha 5</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fecha5 ? hojaIndicacion.fecha5 : ""}
              />
            </Col>
          </Row>
          <Row gutter={16} className="pt-3">
            <Col span={5}>
              <label className="col-form-label">Fecha 6</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fecha6 ? hojaIndicacion.fecha6 : ""}
              />
            </Col>
            <Col span={5}>
              <label className="col-form-label">Fecha 7</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fecha7 ? hojaIndicacion.fecha7 : ""}
              />
            </Col>
            <Col span={5}>
              <label className="col-form-label">Fecha 8</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fecha8 ? hojaIndicacion.fecha8 : ""}
              />
            </Col>
            <Col span={5}>
              <label className="col-form-label">Fecha 9</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fecha9 ? hojaIndicacion.fecha9 : ""}
              />
            </Col>
            <Col span={4}>
              <label className="col-form-label">Fecha 10</label>
              <input
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.fecha10 ? hojaIndicacion.fecha10 : ""}
              />
            </Col>
          </Row>
          <Row gutter={16} className="pt-4">
            <label className="col-form-label">Esquema de premedicación y tratamiento</label>
          </Row>
          <Row gutter={16} className="pt-4">
            {hojaIndicacion.esquemas &&
              hojaIndicacion.esquemas.map((esquema, index) => {
                return (
                  <>
                    <Col span={4} key={esquema.idEsquema + index}>
                      <label className="col-form-label">Nombre Medicamento</label>
                      <input
                        name="fechaRegistro"
                        className="form-control"
                        disabled={true}
                        value={
                          esquema.recetaProducto && esquema.recetaProducto.nombreComercial
                            ? esquema.recetaProducto.nombreComercial
                            : ""
                        }
                      />
                    </Col>

                    <Col span={4} key={esquema.idEsquema + index + 1}>
                      <label className="col-form-label">Día del Ciclo</label>
                      <input
                        name="fechaRegistro"
                        className="form-control"
                        disabled={true}
                        value={esquema.diaCiclo ? esquema.diaCiclo : ""}
                      />
                    </Col>

                    <Col span={4} key={esquema.idEsquema + index + 2}>
                      <label className="col-form-label">Dosis</label>
                      <input
                        name="fechaRegistro"
                        className="form-control"
                        disabled={true}
                        value={esquema.dosis ? esquema.dosis : ""}
                      />
                    </Col>

                    <Col span={4} key={esquema.idEsquema + index + 3}>
                      <label className="col-form-label">Volumen y Soluciones</label>
                      <input
                        name="fechaRegistro"
                        className="form-control"
                        disabled={true}
                        value={esquema.volumenSolucion ? esquema.volumenSolucion : ""}
                      />
                    </Col>

                    <Col span={4} key={esquema.idEsquema + index + 4}>
                      <label className="col-form-label">Vía de Admon</label>
                      <input
                        name="fechaRegistro"
                        className="form-control"
                        disabled={true}
                        value={esquema.viaAdministracion ? esquema.viaAdministracion : ""}
                      />
                    </Col>

                    <Col span={4} key={esquema.idEsquema + index + 5}>
                      <label className="col-form-label">Tiempo de Admon</label>
                      <input
                        name="fechaRegistro"
                        className="form-control"
                        disabled={true}
                        value={esquema.tiempoAdministracion ? esquema.tiempoAdministracion : ""}
                      />
                    </Col>
                  </>
                );
              })}
          </Row>
          <Row gutter={16} className="pt-4">
            <Col span={12}>
              <label className="col-form-label">Comentarios extra de las indicaciones</label>
              <textarea
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.comentarioExtra ? hojaIndicacion.comentarioExtra : ""}
              />
            </Col>
            <Col span={12}>
              <label className="col-form-label">Modificaciones a tratamiento</label>
              <textarea
                name="fechaRegistro"
                className="form-control"
                disabled={true}
                value={hojaIndicacion.modificaciones ? hojaIndicacion.modificaciones : ""}
              />
            </Col>
          </Row>
          <button className="mt-4 btn btn-primary-orange-umo w-md waves-effect waves-light" onClick={() => handdleCloseDrawer()}>
            Cerrar
          </button>
        </Form>
      </Drawer>
    </>
  );
};

export default DrawerHojaIndicacion;
