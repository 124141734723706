import React, { useState, useEffect, useCallback } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";

import formatNumber from "../../utils/index";

export default function ListadoValesPagoMedico({ pagoMedico }) {
  const [totalesVales, setTotalesVales] = useState({
    totalMedicamentos: 0,
    totalAdministracion: 0,
    totalPagadoMedicamentos: 0,
    totalPagadoAdministracion: 0,
    totalSaldosPendientesMed: 0,
    totalSaldosPendientesAdmin: 0,
  });

  const vales = pagoMedico.vales;
  const pagos = pagoMedico.pagos;
  let totalPagos = 0;
  pagos.map((pago) => (totalPagos += pago.importe));

  const calcularTotales = useCallback(() => {
    for (const vale of vales) {
      setTotalesVales((prev) => {
        return {
          totalMedicamentos: prev.totalMedicamentos + vale.importeMedicamentos,
          totalAdministracion: prev.totalAdministracion + vale.importeAdministracion,
          totalPagadoMedicamentos: prev.totalPagadoMedicamentos + (vale.importeMedicamentos - vale.saldoPendienteMedicamentos),
          totalPagadoAdministracion: prev.totalPagadoAdministracion + (vale.importeAdministracion - vale.saldoPendienteAdministracion),
          totalSaldosPendientesMed: prev.totalSaldosPendientesMed + vale.saldoPendienteMedicamentos,
          totalSaldosPendientesAdmin: prev.totalSaldosPendientesAdmin + vale.saldoPendienteAdministracion,
        };
      });
    }
  }, [vales]);

  useEffect(() => {
    calcularTotales();
  }, [calcularTotales]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex">Lista de Vales</h3>
      {vales ? (
        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0 text-center">
                    <thead>
                      <tr>
                        <th scope="col">Folio Vale</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Paciente</th>
                        <th scope="col">Médico</th>
                        <th scope="col">
                          Total<br></br>Medicamentos
                        </th>
                        <th scope="col">
                          Saldo Pendiente<br></br>Medicamentos
                        </th>
                        <th scope="col">
                          Total<br></br>Administración
                        </th>
                        <th scope="col">
                          Saldo Pendiente<br></br>Administración
                        </th>
                        <th scope="col">
                          Total<br></br>Saldo Pendiente
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vales.map((vale, index) => {
                        return (
                          <>
                            <tr key={vale.idVale}>
                              <td>{vale.folioVale ? vale.folioVale : ""}</td>
                              <td>{vale.fechaVale ? vale.fechaVale : ""}</td>
                              <td>{vale.paciente ? vale.paciente : ""}</td>
                              <td key={vale.idCatMedico}>{vale.medico ? vale.medico : ""}</td>
                              <td>{formatNumber(vale.importeMedicamentos)}</td>
                              <td>{formatNumber(vale.saldoPendienteMedicamentos)}</td>
                              <td>{formatNumber(vale.importeAdministracion)}</td>
                              <td>{formatNumber(vale.saldoPendienteAdministracion)}</td>
                              <td>{formatNumber(vale.saldoPendienteAdministracion + vale.saldoPendienteMedicamentos)}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        "No existe el pago de médico con ese ID"
      )}

      <h3 className="flex mt-5">Desglose de Pagos</h3>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              {/* <Row>
                <Col>
                  <h5>
                    Fecha: <span className="text-sm ml-1">{today}</span>
                  </h5>
                </Col>
              </Row> */}
              <div className="table-responsive mt-4">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Concepto</th>
                      <th scope="col">Importe</th>
                      <th scope="col">Pagado</th>
                      <th scope="col">Saldo Pendiente</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Total Medicamentos</td>
                      {/* // Total de medicamentos */}
                      <td>{formatNumber(totalesVales.totalMedicamentos)}</td>
                      {/* Pagado medicamentos */}
                      <td>{formatNumber(totalesVales.totalPagadoMedicamentos)}</td>
                      {/* Saldos pendientes medicamentos */}
                      <td>{formatNumber(totalesVales.totalSaldosPendientesMed)}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>Total Adminstración</td>
                      {/* Total de Administración */}
                      <td>{formatNumber(totalesVales.totalAdministracion)}</td>
                      {/* Pagado admin */}
                      <td>{formatNumber(totalesVales.totalPagadoAdministracion)}</td>
                      {/* Saldos pendientes */}
                      <td>{formatNumber(totalesVales.totalSaldosPendientesAdmin)}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>Total</td>
                      {/* Total med y admin */}
                      <td>{formatNumber(totalesVales.totalMedicamentos + totalesVales.totalAdministracion)}</td>
                      {/* Total pagado admin y med */}
                      <td>{formatNumber(totalesVales.totalPagadoMedicamentos + totalesVales.totalPagadoAdministracion)}</td>
                      {/* Total pendientes med y admin */}
                      <td>{formatNumber(totalesVales.totalSaldosPendientesMed + totalesVales.totalSaldosPendientesAdmin)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
        {pagos ? (
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0 text-center">
                    <thead>
                      <tr>
                        <th scope="col">Tipo de Pago</th>
                        <th scope="col">Importe</th>
                      </tr>
                    </thead>

                    <tbody>
                      {pagos.map((pago, index) => {
                        return (
                          <>
                            <tr>
                              <td>{pago.tipoPago ? pago.tipoPago : ""}</td>
                              <td>{formatNumber(pago.importe)}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                    <thead>
                      <tr>
                        <th scope="col">Total</th>
                        <th scope="col">{formatNumber(totalPagos)}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        ) : null}
      </Row>
    </div>
  );
}
