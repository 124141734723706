import {
    LISTAR_ANAQUELES,
    LIMPIAR_ANAQUELES,
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_ANAQUELES: 
            return{
                ...state, 
                anaqueles: action.payload.anaqueles,
                almacenSeleccionado: {
                    ...action.payload,
                    anaqueles: null
                }
            }
        case LIMPIAR_ANAQUELES: 
            return{
                ...state, 
                anaqueles: [],
                almacenSeleccionado:{}
            }
        default:
            return state
    }
        
}