import React, {useState, useContext, useEffect} from "react";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CatTipoOrdenCompraContext from "../../context/CatTipoOrdenCompra/CatTipoOrdenCompraContext";
import CatTipoSolicitudCompraContext from "../../context/CatTipoSolicitudCompra/CatTipoSolicitudCompraContext";
import RequerimientosCompraPendientesContext from "../../context/RequerimientosCompraPendientes/RequerimientosCompraPendientesContext";
import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";
import AuthContext from "../../context/Auth/AuthContext";
import formatNumber from "../../utils/index";

export default function RequerimientosCompraPendientes(props) {
    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_Eliminar_Requerimiento :  false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    
    const fechaActual = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "";
    //ACCESO AL ESTADO GLOBAL - AUTENCIACION
    const ctxAuth = useContext(AuthContext);
    const {usuario} = ctxAuth;

    //ACCESO AL ESTADO GLOBAL - Tipos de compra

    
    const ctxTiposOrdenCompra = useContext(CatTipoOrdenCompraContext);
    const {listarTiposOrdenCompra} = ctxTiposOrdenCompra;

    const [loading, setLoading] = useState(false);
    const [comentarios,setComentarios] = useState("");
    //ACCESO AL ESTADO GLOBAL - Tipos de Solicitud de compra
    const ctxTiposSolicitudCompra = useContext(CatTipoSolicitudCompraContext);
    const {tiposSolicitudCompra, listarTiposSolicitudCompra} = ctxTiposSolicitudCompra;

    const ctxReqComprasPendientes = useContext(RequerimientosCompraPendientesContext);
    const {
        filtrosRequerimientosPendientes,
        setFiltrosRequerimientosPendientes,
        limpiarFiltrosRequerimientosPendientes,
        listarRequerimientosComprasPendientes,
        requerimientosPendientesFiltradas,
        setRequerimientosPendientesFiltradas,
        limpiarListaRequerimientosPendientes,
        eliminarRequerimientosComprasPendientes,
    } = ctxReqComprasPendientes;

    const solicitudesComprasContext = useContext(SolicitudesComprasContext);
    const {limpiarNuevaSolicitudCompraPendiente} = solicitudesComprasContext;

    const buscarPorFiltros = (e) => {
        e.preventDefault();
        filtrosRequerimientosPendientes.sumarizados = false;
        filtrosRequerimientosPendientes.idVale = null;
        filtrosRequerimientosPendientes.idVentaMostrador = null;
        
        if (filtrosRequerimientosPendientes.fechaInicial && !filtrosRequerimientosPendientes.fechaFinal) {
            return;
        }

        if (filtrosRequerimientosPendientes.fechaFinal) {
            filtrosRequerimientosPendientes.fechaFinal = filtrosRequerimientosPendientes.fechaFinal + "T23:59:59";
        }

        listarRequerimientosComprasPendientes();
        // fetchData({ pageSize: 10, pageIndex: 0, byBusquedaFiltros: true });

        if (filtrosRequerimientosPendientes.fechaFinal) {
            filtrosRequerimientosPendientes.fechaFinal = filtrosRequerimientosPendientes.fechaFinal.split("T")[0];
        }
    };

    useEffect(() => {
        listarRequerimientosComprasPendientes();
    }, []);

    const onChangeComentarios = (e) => {
        setComentarios(e.target.value);        
    };

    const onChange = (e) => {
        if (e.target.name == "fechaInicial") {
            setFiltrosRequerimientosPendientes({
                ...filtrosRequerimientosPendientes,
                [e.target.name]: e.target.value,
                fechaFinal: e.target.value,
            });
        } else {
            setFiltrosRequerimientosPendientes({
                ...filtrosRequerimientosPendientes,
                [e.target.name]: e.target.value,
            });
        }
        // setRequerimientosPendientesFiltradas([]);
    };

    // const onChangeImporteMaximo = (e) => {
    //   let value = e.target.value;
    //   value = Number(value.replace("$", ""));

    //   setFiltrosRequerimientosPendientes({
    //     ...filtrosRequerimientosPendientes,
    //     importeMaximo: value,
    //   });
    //   setRequerimientosPendientesFiltradas([]);
    // };

    const handdleCheckedRequerimientoPendiente = async (req) => {
        requerimientosPendientesFiltradas.map((reqPendientes) => {
            if (reqPendientes === req) {
                reqPendientes.checked = !reqPendientes.checked;
            } else {
                reqPendientes.checked = false;
            }
        });

        setRequerimientosPendientesFiltradas(requerimientosPendientesFiltradas);
    };

    const confirmarBorrado = async () => {
        let requerimientoPendienteChecked = false;

        requerimientosPendientesFiltradas.map((reqPendientes) => {
            if (reqPendientes.checked) {
                requerimientoPendienteChecked = reqPendientes;
            }
        });

        if (!requerimientoPendienteChecked) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor seleccione un requerimiento pendiente",
            });
        }else{
            const idVale = requerimientoPendienteChecked?.idVale ? requerimientoPendienteChecked?.idVale : null;
            const idVentaMostrador = requerimientoPendienteChecked?.idVentaMostrador ? requerimientoPendienteChecked?.idVentaMostrador : null;
            setFiltrosRequerimientosPendientes({
                ...filtrosRequerimientosPendientes,
                idVentaMostrador: idVentaMostrador,
                idVale: idVale,
            });
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_Eliminar_Requerimiento: true,
            });
        }
    }

    const generarSolicitudCompra = async () => {
        const requerimientoPendienteChecked = await isRequerimientoPendienteChecked();
        //te redirijo a nueva solicitud requerimiento y hacemos la busqueda por los filtros, incluimos en los filtros el tipo de producto
        //usamos esos mismos filtros para hacer la peticion de los productos
        if (requerimientoPendienteChecked) {
            limpiarNuevaSolicitudCompraPendiente();
            filtrosRequerimientosPendientes.tipoProducto = requerimientoPendienteChecked.idCatTipoProducto;
            filtrosRequerimientosPendientes.sumarizados = false;
            // if (requerimientoPendienteChecked.idVale) {
            //     filtrosRequerimientosPendientes.idVale = requerimientoPendienteChecked.idVale;
            // }
            // if (requerimientoPendienteChecked.idVentaMostrador) {
            //     filtrosRequerimientosPendientes.idVentaMostrador = requerimientoPendienteChecked.idVentaMostrador;
            // }

            setFiltrosRequerimientosPendientes(filtrosRequerimientosPendientes);
            limpiarListaRequerimientosPendientes();
            props.history.push({
                pathname: "/solicitudescompra/nuevo/pendientes",
                state: requerimientoPendienteChecked,
            });
        }
    };

    const eliminarRequerimientosPendientes = async () => {
        let requerimientoPendienteChecked = false;

        requerimientosPendientesFiltradas.map((reqPendientes) => {
            if (reqPendientes.checked) {
                requerimientoPendienteChecked = reqPendientes;
            }
        });

        if(comentarios.length < 2){
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor Ingrese el motivo de la cancelación.",
            });
            return;
        }
        setLoading(true);
        
        const idVale = requerimientoPendienteChecked?.idVale ? requerimientoPendienteChecked?.idVale : null;
        const idVentaMostrador = requerimientoPendienteChecked?.idVentaMostrador ? requerimientoPendienteChecked?.idVentaMostrador : null;

        let cadenaLogLocal = ""
        let folio = idVale? requerimientoPendienteChecked.folioVale : requerimientoPendienteChecked.folioVentaMostrador;
        cadenaLogLocal = cadenaLogLocal+ "Borrado:ID[" + folio + "]";
        cadenaLogLocal = cadenaLogLocal+  ",(IdV[" + idVale + "]"
        cadenaLogLocal = cadenaLogLocal+  ",(IdVM[" + idVentaMostrador + "]"
        cadenaLogLocal = cadenaLogLocal+  ",(TP[" + requerimientoPendienteChecked.tipoProducto + "]"
        cadenaLogLocal = cadenaLogLocal+  ",(FS[" + requerimientoPendienteChecked.fechaSolicitud + "]"
        cadenaLogLocal = cadenaLogLocal+  ",(COM[" + comentarios + "]"

        setFiltrosRequerimientosPendientes({
            ...filtrosRequerimientosPendientes,
            idVentaMostrador: null,
            idVale: null,
            logVo : cadenaLogLocal.length==0? null:{
                idUsuario: usuario.idUsuario,
                modulo: "Requerimientos Pendientes",
                actividad:cadenaLogLocal,
            },
        });        
        
        filtrosRequerimientosPendientes.logVo = {
            idUsuario: usuario.idUsuario,
            modulo: "Requerimientos Pendientes",
            actividad:cadenaLogLocal,
        };

        let regreso = await eliminarRequerimientosComprasPendientes();

        filtrosRequerimientosPendientes.idVentaMostrador = null;
        filtrosRequerimientosPendientes.idVale = null;
        listarRequerimientosComprasPendientes();      
        setComentarios("");
        setLoading(false);
        setUISweetAlert({
            ...UISweetAlert,
            success_dlg: true,
            dynamic_title: "Exito",
            confirm_alert_Eliminar_Requerimiento: false,
            dynamic_description: "Se eliminó el requerimiento pendiente exitosamente.",
        });

    }

    const isRequerimientoPendienteChecked = async () => {
        let requerimientoPendienteChecked = false;

        requerimientosPendientesFiltradas.map((reqPendientes) => {
            if (reqPendientes.checked) {
                requerimientoPendienteChecked = reqPendientes;
            }
        });

        if (!requerimientoPendienteChecked) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor seleccione un requerimiento pendiente",
            });
        }
        return requerimientoPendienteChecked;
    };

    useEffect(() => {
        listarTiposOrdenCompra();
        listarTiposSolicitudCompra();
    }, []);

    return (
        <>
            <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
                <h3 className="flex h3">Requerimientos Pendientes (Compras pendientes)</h3>
                <Card
                    className="mt-10 card"
                    style={{
                        padding: "1.5rem 1.5rem 1.5rem",
                    }}>
                    <form onSubmit={(e) => buscarPorFiltros(e)}>
                        <CardBody>
                            <Row>
                                <Col xl={6} lg={12} md={12}>
                                    <Row className="form-group">
                                        <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                                        <Col sm={7}>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="fechaInicial"
                                                onChange={onChange}
                                                value={filtrosRequerimientosPendientes.fechaInicial ? filtrosRequerimientosPendientes.fechaInicial : ""}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-4 col-form-label">Tipo Solicitud Compra</label>
                                        <Col sm={7}>
                                            <select
                                                className="form-control"
                                                name="tipoSolicitudCompra"
                                                onChange={onChange}
                                                value={
                                                    filtrosRequerimientosPendientes.tipoSolicitudCompra
                                                        ? filtrosRequerimientosPendientes.tipoSolicitudCompra
                                                        : ""
                                                }>
                                                <option selected value="">
                                                    Todos
                                                </option>
                                                {tiposSolicitudCompra.map((tiposSolCom) => {
                                                    return (
                                                        <>
                                                            <option value={tiposSolCom.idCatTipoSolicitudCompra}>{tiposSolCom.tipoSolicitudCompra}</option>
                                                        </>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={6} lg={12} md={12}>
                                    <Row className="form-group">
                                        <label className="col-sm-4 col-form-label">Fecha Final</label>
                                        <Col sm={7}>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="fechaFinal"
                                                disabled={!filtrosRequerimientosPendientes.fechaInicial ? true : false}
                                                min={filtrosRequerimientosPendientes.fechaInicial}
                                                onChange={onChange}
                                                value={filtrosRequerimientosPendientes.fechaFinal ? filtrosRequerimientosPendientes.fechaFinal : ""}
                                            />
                                        </Col>
                                    </Row>
                                    {/* <Row className="form-group">
                                        <label className="col-sm-4 col-form-label">
                                            Importe Máximo
                                        </label>
                                        <Col sm={7}>
                                            <input
                                            type="text"
                                            className="form-control"
                                            name="importeMaximo"
                                            value={filtrosRequerimientosPendientes.importeMaximo ? `$${filtrosRequerimientosPendientes.importeMaximo}` : `$0`}
                                            onChange={onChangeImporteMaximo}
                                            />
                                        </Col>
                                    </Row> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="submit"
                                        style={{
                                            marginRight: "2.5rem",
                                            background: "#ff9933",
                                            color: "white",
                                            fontWeight: "bold",
                                        }}>
                                        Aplicar Filtros
                                    </button>
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="button"
                                        onClick={limpiarFiltrosRequerimientosPendientes}
                                        style={{
                                            marginRight: "2.5rem",
                                            background: "#ff9933",
                                            color: "white",
                                            fontWeight: "bold",
                                        }}>
                                        Limpiar Filtros
                                    </button>
                                </Col>
                            </Row>
                        </CardBody>
                    </form>
                </Card>
                <Row>
                    <Col>
                        <Card className="mt-4">
                            <CardBody>
                                <Row>
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="button"
                                        onClick={generarSolicitudCompra}
                                        style={{
                                            marginRight: "2.5rem",
                                            marginTop: "1rem",
                                            background: "#ff9933",
                                            color: "white",
                                            fontWeight: "bold",
                                        }}>
                                        Generar Solicitud de Compra
                                    </button>
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="button"
                                        onClick={confirmarBorrado}
                                        style={{
                                            marginRight: "2.5rem",
                                            marginTop: "1rem",
                                            background: "#ff9933",
                                            color: "white",
                                            fontWeight: "bold",
                                        }}>
                                        Borrar Requerimiento Pendiente
                                    </button>                                    
                                </Row>                              
                                <div className="table-responsive mt-4">
                                    <table className="table table-hover table-centered table-nowrap mb-0">
                                        <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">
                                                Tipo de<br></br>Compra
                                            </th>
                                            <th scope="col">
                                                Folio de<br></br>Venta
                                            </th>
                                            <th scope="col">
                                                Fecha de<br></br>Venta
                                            </th>
                                            <th scope="col">
                                                Total de<br></br>Piezas
                                            </th>
                                            <th scope="col">
                                                Precio<br></br>Unitario
                                            </th>
                                            <th scope="col">
                                                Tipo de<br></br>Producto
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {requerimientosPendientesFiltradas.map((reqPendientes, index) => {
                                            return (
                                                <>
                                                    <tr key={reqPendientes}>
                                                        <td>
                                                            {
                                                                <input
                                                                    type="checkbox"
                                                                    onClick={() => handdleCheckedRequerimientoPendiente(reqPendientes)}
                                                                    checked={reqPendientes.checked}
                                                                />
                                                            }
                                                        </td>
                                                        <td>{reqPendientes.tipo ? reqPendientes.tipo : ""}</td>
                                                        <td>
                                                            {reqPendientes.idVale && reqPendientes.folioVale
                                                                ? reqPendientes.folioVale
                                                                : [
                                                                    reqPendientes.idVentaMostrador && reqPendientes.folioVentaMostrador
                                                                        ? reqPendientes.folioVentaMostrador
                                                                        : "",
                                                                ]}
                                                        </td>
                                                        <td>{reqPendientes.fechaSolicitud ? reqPendientes.fechaSolicitud : ""}</td>
                                                        <td>{reqPendientes.piezas ? reqPendientes.piezas : ""}</td>
                                                        <td>{reqPendientes.precio ? formatNumber(reqPendientes.precio) : ""}</td>
                                                        <td>{reqPendientes.tipoProducto ? reqPendientes.tipoProducto : ""}</td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <TableComponent pageCount={pageCount} fetchData={fetchData} columns={columns} loading={loadingData} data={requerimientosPendientesFiltradas} indexOfPage={controlledPageIndex} /> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {UISweetAlert.confirm_alert_Eliminar_Requerimiento ? (
                <SweetAlert
                    title="¿Desea eliminar el requerimiento pendiente?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarRequerimientosPendientes()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_Eliminar_Requerimiento: false,
                        })
                    }
                    }>
                    <br></br>
                    <br></br>
                    <div>
                        <Row>
                            <Col>Llene por favor la siguiente información</Col>
                        </Row>

                        <Row className="form-group mt-2">
                            <label className="col-sm-4 col-form-label">Motivo</label>
                            <Col sm={7}>
                            <textarea
                                rows={6}
                                autoComplete="off" 
                                className="form-control"
                                name="comentarios"
                                value={comentarios}
                                onChange={onChangeComentarios}
                            />
                           </Col>
                        </Row>

                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Fecha de Eliminación</label>
                            <Col sm={7}>
                                <input
                                    type="text"
                                    autoComplete="off" 
                                    className="form-control"
                                    disabled={true}
                                    name="fechaCancelacion"
                                    value={fechaActual}
                                />
                            </Col>
                        </Row>
                    </div>                    
                </SweetAlert>
            ) : null}  

                {UISweetAlert.success_dlg ? (
                    <SweetAlert
                        success
                        title={UISweetAlert.dynamic_title}
                        onConfirm={() => {
                            if (UISweetAlert.redirect) {
                                props.history.push(UISweetAlert.path);
                            }
                            setUISweetAlert({
                                ...UISweetAlert,
                                success_dlg: false,
                            });
                        }}>
                        {UISweetAlert.dynamic_description}
                    </SweetAlert>
                ) : null}

                {UISweetAlert.error_dlg ? (
                    <SweetAlert
                        error
                        title={UISweetAlert.dynamic_title}
                        onConfirm={() =>
                            setUISweetAlert({
                                ...UISweetAlert,
                                error_dlg: false,
                            })
                        }>
                        {UISweetAlert.dynamic_description}
                    </SweetAlert>
                ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}

            </div>
        </>
    );
}
