import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router-dom";

import InformacionGeneralSolicitudCompra from "../InformacionGeneralSolicitudCompra/index";
import ListadoProductosSolicitudCompra from "../ListadoProductosSolicitudCompra/index";
import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";

export default function SolicitudCompraRegistroCotizacion(props) {
  const { idSolicitudCompra } = useParams();

  const [UISweetAlert, setUISweetAlert] = useState({
    error_dlg: false,
  });

  const [flagSeleccionarCompra, setFlagSeleccionarCompra] = useState(false);

  // ACCESO A ESTADO GLOBAL - VALES
  const ctxSolicCompra = useContext(SolicitudesComprasContext);
  const {
    seleccionarSolicitudCompra,
    solicitudShowEdit,
    setSolicitudShowEdit,
    errorSolicitudCompraSeleccionada,
    solicitudCompraSeleccionada,
  } = ctxSolicCompra;

  const validarSolicitudCompraRegistarCotizacion = async () => {
    const estatusActual =
      solicitudCompraSeleccionada.estatussolicitudcompra[solicitudCompraSeleccionada.estatussolicitudcompra.length - 1]
        .catEstatussolicitudcompra;
    if (estatusActual.idCatEstatusSolicitudCompra != 2) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
      });
    }
  };

  useEffect(() => {
    setSolicitudShowEdit({
      ...solicitudShowEdit,
      hiddenCotizacion: false,
      disabledCotizacion: false,
      guardarSolicitud: true,
      finalizarAutorizacion: false,
      solicitarAutorizacion: true,
      hiddenArchivosCotizacion: false,
      disabledArchivosCotizacion: false,
      hiddenDecision: true,
      cancelarSolicitud: true,
      disabledDecision: true,
      disabledProductoSustituto: false,
      cotizarProductoSustituto: true,
    });

    if (!errorSolicitudCompraSeleccionada && !flagSeleccionarCompra) {
      seleccionarSolicitudCompra(idSolicitudCompra);
      setFlagSeleccionarCompra(true);
    }

    if (!errorSolicitudCompraSeleccionada && flagSeleccionarCompra && Object.entries(solicitudCompraSeleccionada).length !== 0) {
      validarSolicitudCompraRegistarCotizacion();
    }

    if (errorSolicitudCompraSeleccionada) {
      props.history.push("/solicitudescompra");
    }
  }, [errorSolicitudCompraSeleccionada, solicitudCompraSeleccionada]);

  return (
    <div className="mt-4">
      <InformacionGeneralSolicitudCompra props={props} />
      <ListadoProductosSolicitudCompra props={props} />

      {UISweetAlert.error_dlg ? (
        <SweetAlert error title="Fallo" onConfirm={() => props.history.push("/solicitudescompra")}>
          No se puede registar la cotización
        </SweetAlert>
      ) : null}
    </div>
  );
}
