import React, { useReducer } from "react";
import { LISTAR_CATDIAGNOSTICOS } from "../../types";

import clienteAxios from "../../config/axios";
import CatDiagnosticosReducer from "./CatDiagnosticosReducer";
import CatDiagnosticosContext from "./CatDiagnosticosContext";

export default function CatServiciosState(props) {
  const initialState = {
    catDiagnosticos: null,
  };

  const [state, dispatch] = useReducer(CatDiagnosticosReducer, initialState);

  const listarCatDiagnosticos = async () => {
    const res = await clienteAxios.get(`/diagnosticos/activos`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CATDIAGNOSTICOS,
      payload: res.data,
    });
  };

  const listarCatDiagnosticosMatch = async (diagnostico) => {
    const res = await clienteAxios.post(
      `/diagnosticos/activos/match`,
      { diagnostico: diagnostico },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch({
      type: LISTAR_CATDIAGNOSTICOS,
      payload: res.data,
    });
  };

  const loadDiagnosticosSelect = async (page, limit, searchQuery) => {
    try {
      const response = await clienteAxios.post(
        "/diagnosticos/paginado/",
        { pageSize: limit, pageIndex: page + 1, diagnostico: searchQuery },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      // const productosTemp = [...state.productosSelect, ...response.data.productos];
      const currentCount = limit * page;

      const hasMore = currentCount < response.data.count;

      const newRes = { data: response.data.diagnosticos, hasMore: hasMore };

      // dispatch({
      //   type: LISTAR_PRODUCTOS_SELECT,
      //   payload: productosTemp,
      // });
      return newRes;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <CatDiagnosticosContext.Provider
      value={{
        catDiagnosticos: state.catDiagnosticos,
        listarCatDiagnosticos,
        listarCatDiagnosticosMatch,
        loadDiagnosticosSelect,
      }}>
      {props.children}
    </CatDiagnosticosContext.Provider>
  );
}
