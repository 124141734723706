import React, { useContext, useEffect, useState } from "react";

import useWindowDimensions from "../../hooks/dimensionsHook";

import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectAsyncPaginate from "../SelectAsyncPaginate";

import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import AuthContext from "../../context/Auth/AuthContext";
import SexosContext from "../../context/Sexos/SexosContext";

import DrawerExpedientePaciente from "../DrawerExpedientePaciente/index";

import TabListaConsultaSeguimiento from "../TabListaConsultaSeguimiento/index";
import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import ConsultaSeguimientoContext from "../../context/ConsultaSeguimiento/ConsultaSeguimientoContext";
import CatDiagnosticosContext from "../../context/CatDiagnosticos/CatDiagnosticosContext";
import useCloseWindowWarning from "../../hooks/useCloseWindowWarning";
import formatDateWithoutHour from "../../utils/formatDateWithoutHour";
import { opcionesSelectDiagnosticos } from "../../config/configOpcionesSelect";

export default function ConsultaSeguimiento(props) {
  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_guardar_nuevo_diagnostico: false,
    confirm_alert_guardar_nueva_etapaclinica: false,
    confirm_alert_guardar_nuevo_plan: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const [Prompt, setEditTrue, setEditFalse, isEdit] = useCloseWindowWarning();

  const [isEditExpediente, setIsEditExpediente] = useState({
    diagnostico: false,
    etapa: false,
    plan: false,
  });

  const [nuevoDiagnostico, setNuevoDiagnostico] = useState(null);
  const [nuevaEtapa, setNuevaEtapa] = useState(null);
  const [nuevoPlan, setNuevoPlan] = useState(null);

  const { width } = useWindowDimensions();

  // const [opcionDiagnostico, setOpcionDiagnostico] = useState("");

  // const [diagnosticosFiltered, setDiagnosticosFiltered] = useState(null);

  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const autorizado = usuario.authorities.includes("ROLE_MEDICO") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA");

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  useEffect(() => {
    const fetchAll = () => {
      listarSexos();
      listarAseguradoras();
      listarEscolaridades();
      listarEstadosCiviles();
      listarLugares();
      listarOcupaciones();
      listarMedicos();
    };
    fetchAll();
  }, []);

  const ctxCatDiagnosticos = useContext(CatDiagnosticosContext);
  const { listarCatDiagnosticos, loadDiagnosticosSelect } = ctxCatDiagnosticos;

  const [loading, setLoading] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado } = pacientesContext;

  const consultaSeguimientoContext = useContext(ConsultaSeguimientoContext);
  const { uploadNuevoDiagnostico, uploadNuevoPlan, uploadNuevaEtapaClinica } = consultaSeguimientoContext;

  const onChangeOpcionDiagnostico = (e) => {
    if (!isEdit) setEditTrue();
    setIsEditExpediente({ ...isEditExpediente, diagnostico: true });
    const diagnostico = nuevoDiagnostico ? nuevoDiagnostico + ", " + e.diagnostico : e.diagnostico;
    setNuevoDiagnostico(diagnostico);
  };

  const onChangeExpediente = (e) => {
    if (!isEdit) setEditTrue();
    const { value, name } = e.target;
    // if (name == "diagnosticoOpcion") {
    //   setIsEditExpediente({ ...isEditExpediente, diagnostico: true });
    //   const diagnosticosFiltrados =
    //     value !== "" &&
    //     catDiagnosticos &&
    //     catDiagnosticos.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);

    //   setDiagnosticosFiltered(diagnosticosFiltrados);

    //   setOpcionDiagnostico(value);
    //   if (diagnosticosFiltrados.length == 1) {
    //     const diagnostico = nuevoDiagnostico ? nuevoDiagnostico + ", " + value : value;
    //     setNuevoDiagnostico(diagnostico);
    //     // setHistoriaClinicaInicial({
    //     //   ...historiaClinicaInicial,
    //     //   expedienteMedico: {
    //     //     ...historiaClinicaInicial.expedienteMedico,
    //     //     nuevoDiagnostico: historiaClinicaInicial.expedienteMedico.nuevoDiagnostico
    //     //       ? historiaClinicaInicial.expedienteMedico.nuevoDiagnostico + value
    //     //       : value,
    //     //   },
    //     // });
    //   }
    // }
    if (name == "nuevoDiagnostico") {
      setIsEditExpediente({ ...isEditExpediente, diagnostico: true });
      setNuevoDiagnostico(value);
    } else if (name == "nuevoPlan") {
      setIsEditExpediente({ ...isEditExpediente, plan: true });
      setNuevoPlan(value);
    } else if (name == "nuevaEtapa") {
      setIsEditExpediente({ ...isEditExpediente, etapa: true });
      setNuevaEtapa(value);
    }
    //  else {
    //   setHistoriaClinicaInicial({
    //     ...historiaClinicaInicial,
    //     expedienteMedico: {
    //       ...historiaClinicaInicial.expedienteMedico,
    //       [e.target.name]: e.target.value,
    //     },
    //   });
    // }
  };

  const uploadNuevoDiagnosticoConfirm = async () => {
    let error = false;

    if (!nuevoDiagnostico && !error) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el nuevo diagnóstico del paciente",
      });
      error = true;
      return;
    }
    // if (!historiaClinicaInicial.expedienteMedico.nuevoDiagnostico && !error) {
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, introduzca el nuevo diagnóstico del paciente",
    //   });
    //   error = true;
    //   return;
    // }

    if (!error) {
      setLoading(true);
      const res = await uploadNuevoDiagnostico({
        nuevoDiagnostico: nuevoDiagnostico,
        idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      });
      // const res = await uploadNuevoDiagnostico({
      //   nuevoDiagnostico: historiaClinicaInicial.expedienteMedico.nuevoDiagnostico,
      //   idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      // });
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        checkTodoGuardado("diagnostico");
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          editar: false,
          dynamic_title: "Exito",
          confirm_alert_guardar_nuevo_diagnostico: false,
          dynamic_description: "Se guardó de manera correcta la información.",
        });
        setNuevoDiagnostico(null);
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
        // setOpcionDiagnostico("");
      }
    }
  };

  const uploadNuevaEtapaClinicaConfirm = async () => {
    let error = false;

    if (!nuevaEtapa && !error) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca la nueva etapa clínica del paciente",
      });
      error = true;
      return;
    }

    if (!error) {
      setLoading(true);
      const res = await uploadNuevaEtapaClinica({
        nuevaEtapaClinica: nuevaEtapa,
        idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      });
      // const res = await uploadNuevaEtapaClinica({
      //   nuevaEtapaClinica: historiaClinicaInicial.expedienteMedico.nuevaEtapaClinica,
      //   idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      // });
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        checkTodoGuardado("etapa");
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          editar: false,
          dynamic_title: "Exito",
          confirm_alert_guardar_nueva_etapaclinica: false,
          dynamic_description: "Se guardó de manera correcta la información.",
        });
        setNuevaEtapa(null);
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      }
    }
  };

  const uploadNuevoPlanConfirm = async () => {
    let error = false;

    if (!nuevoPlan && !error) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el nuevo plan del paciente",
      });
      error = true;
      return;
    }

    if (!error) {
      setLoading(true);

      const res = await uploadNuevoPlan({
        nuevoPlan: nuevoPlan,
        idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      });
      
      if (res.data.error) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        checkTodoGuardado("plan");
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          editar: false,
          dynamic_title: "Exito",
          confirm_alert_guardar_nuevo_plan: false,
          dynamic_description: "Se guardó de manera correcta la información.",
        });
        setNuevoPlan(null);
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      }
    }
  };

  const redirectHistoriaClinicaInicial = async () => {
    props.history.push("/historia/clinica/inicial");
  };

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }, []);

  useEffect(() => {
    listarCatDiagnosticos();
    // if (!catDiagnosticos)
  }, []);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Consultas de Seguimiento</h3>
      <DrawerExpedientePaciente
        historiaClinicaInicial={historiaClinicaInicial}
        sexos={sexos}
        aseguradoras={aseguradoras}
        medicos={medicos}
        lugares={lugares}
        ocupaciones={ocupaciones}
        estadosCiviles={estadosCiviles}
        escolaridades={escolaridades}
      />
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col>
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                type="button"
                onClick={redirectHistoriaClinicaInicial}>
                Historia Clínica Inicial
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                  <Tab>Ficha de Identificación</Tab>
                  <Tab>Lista de Consultas de Seguimiento</Tab>
                </TabList>
                <TabPanel>
                  {/* <FichaIdentificacion props={props} historiaClinicaInicial={historiaClinicaInicial} setHistoriaClinicaInicial={setHistoriaClinicaInicial} disabled={true} /> */}
                  <div className="mt-5 lg:grid-cols-3 sm:grid-cols-1 gap-2">
                    <div>
                      <h3>Diagnóstico</h3>
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                        type="button"
                        onClick={() => {
                          setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_historico_diagnosticos: true,
                          });
                        }}>
                        Ver Diagnósticos Anteriores
                      </button>
                    </div>
                    <div>
                      <label className="col-sm-5 col-form-label mt-4">Diagnóstico más reciente</label>
                      <textarea
                        className="form-control-disabled"
                        disabled={true}
                        onChange={onChangeExpediente}
                        value={
                          historiaClinicaInicial.expedienteMedico &&
                          historiaClinicaInicial.expedienteMedico.diagnosticos &&
                          historiaClinicaInicial.expedienteMedico.diagnosticos.length > 0
                            ? historiaClinicaInicial.expedienteMedico.diagnosticos[
                                historiaClinicaInicial.expedienteMedico.diagnosticos.length - 1
                              ].diagnostico
                            : "No hay diagnósticos"
                        }
                      />
                    </div>

                    <div>
                      <label className="col-sm-5 col-form-label mt-4">Nuevo Diagnóstico</label>

                      <SelectAsyncPaginate
                        // opcionSeleccionadaCompleta={pacienteSeleccionadoCompleto}
                        onChangeOptions={onChangeOpcionDiagnostico}
                        fetchOptions={loadDiagnosticosSelect}
                        opcionesPaginado={opcionesSelectDiagnosticos.opcionesPaginado}
                        placeholderText={opcionesSelectDiagnosticos.placeholderText}
                        optionLabel={opcionesSelectDiagnosticos.optionLabel}
                        optionValue={opcionesSelectDiagnosticos.optionValue}
                      />
                      {/* <input
                        placeholder="Catálogo de diagnosticos"
                        name="diagnosticoOpcion"
                        disabled={!autorizado}
                        id="diagnosticoOpcion"
                        value={opcionDiagnostico}
                        onChange={(e) => onChangeExpediente(e)}
                        list={"diagnosticos"}
                        className="form-control w-full mb-2"
                      /> */}
                      {/* <datalist id={"diagnosticos"}>
                        {diagnosticosFiltered &&
                          diagnosticosFiltered.map((diagnostico, index) => {
                            return (
                              <>
                                <option key={diagnostico.idCatDiagnostico} value={diagnostico.diagnostico}>
                                  {diagnostico.diagnostico}
                                </option>
                              </>
                            );
                          })}
                      </datalist> */}
                      <textarea
                        className="form-control mt-2"
                        name="nuevoDiagnostico"
                        disabled={!autorizado}
                        onChange={onChangeExpediente}
                        value={nuevoDiagnostico ? nuevoDiagnostico : ""}
                        // value={
                        //   historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.nuevoDiagnostico
                        //     ? historiaClinicaInicial.expedienteMedico.nuevoDiagnostico
                        //     : ""
                        // }
                      />
                      <button
                        className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-2"
                        type="button"
                        disabled={!autorizado}
                        onClick={() => {
                          setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_guardar_nuevo_diagnostico: true,
                          });
                        }}>
                        Guardar Diagnóstico
                      </button>
                    </div>
                  </div>

                  <div className="mt-5 lg:grid-cols-3 sm:grid-cols-1 gap-2">
                    <div>
                      <h3>Etapa Clínica</h3>
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                        type="button"
                        onClick={() => {
                          setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_historico_etapasclinica: true,
                          });
                        }}>
                        Ver Etapas Clínicas Anteriores
                      </button>
                    </div>
                    <div>
                      <label className="col-sm-5 col-form-label mt-4">Etapa Clínica más reciente</label>

                      <textarea
                        className="form-control-disabled"
                        disabled={true}
                        onChange={onChangeExpediente}
                        value={
                          historiaClinicaInicial.expedienteMedico &&
                          historiaClinicaInicial.expedienteMedico.etapasClinicas &&
                          historiaClinicaInicial.expedienteMedico.etapasClinicas.length > 0
                            ? historiaClinicaInicial.expedienteMedico.etapasClinicas[
                                historiaClinicaInicial.expedienteMedico.etapasClinicas.length - 1
                              ].etapaClinica
                            : "No hay Etapas Clínicas"
                        }
                      />
                    </div>

                    <div>
                      <label className="col-sm-5 col-form-label mt-4">Nueva Etapa Clínica</label>
                      <textarea
                        className="form-control"
                        // name="nuevaEtapaClinica"
                        name="nuevaEtapa"
                        maxLength={100}
                        onChange={onChangeExpediente}
                        value={nuevaEtapa ? nuevaEtapa : ""}
                        disabled={!autorizado}
                        // value={
                        //   historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.nuevaEtapaClinica
                        //     ? historiaClinicaInicial.expedienteMedico.nuevaEtapaClinica
                        //     : ""
                        // }
                      />
                      <button
                        className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-2"
                        type="button"
                        disabled={!autorizado}
                        onClick={() => {
                          setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_guardar_nueva_etapaclinica: true,
                          });
                        }}>
                        Guardar Etapa Clínica
                      </button>
                    </div>
                  </div>

                  <div className="mt-5">
                    <div>
                      <h3>Plan</h3>
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                        type="button"
                        onClick={() => {
                          setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_historico_planes: true,
                          });
                        }}>
                        Ver Planes Anteriores
                      </button>
                    </div>
                    <div>
                      <label className="col-sm-5 col-form-label mt-4">Plan más reciente</label>

                      <textarea
                        className="form-control-disabled"
                        disabled={true}
                        onChange={onChangeExpediente}
                        value={
                          historiaClinicaInicial.expedienteMedico &&
                          historiaClinicaInicial.expedienteMedico.planes &&
                          historiaClinicaInicial.expedienteMedico.planes.length > 0
                            ? historiaClinicaInicial.expedienteMedico.planes[
                                historiaClinicaInicial.expedienteMedico.planes.length - 1
                              ].plan
                            : "No hay Planes"
                        }
                      />
                    </div>
                    <div>
                      <label className="col-sm-5 col-form-label mt-4">Nuevo Plan</label>
                      <textarea
                        className="form-control"
                        name="nuevoPlan"
                        disabled={!autorizado}
                        onChange={onChangeExpediente}
                        value={nuevoPlan ? nuevoPlan : ""}
                        // value={
                        //   historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.nuevoPlan
                        //     ? historiaClinicaInicial.expedienteMedico.nuevoPlan
                        //     : ""
                        // }
                      />
                      <button
                        className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-2"
                        type="button"
                        disabled={!autorizado}
                        onClick={() => {
                          setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_guardar_nuevo_plan: true,
                          });
                        }}>
                        Guardar Plan
                      </button>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <TabListaConsultaSeguimiento
                    props={props}
                    historiaClinicaInicial={historiaClinicaInicial}
                    autorizado={autorizado}
                  />
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_guardar_nuevo_diagnostico ? (
        <SweetAlert
          title="¿Desea guardar un nuevo diagnóstico?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadNuevoDiagnosticoConfirm()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar_nuevo_diagnostico: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_guardar_nueva_etapaclinica ? (
        <SweetAlert
          title="¿Desea guardar una nueva etapa clínica?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadNuevaEtapaClinicaConfirm()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar_nueva_etapaclinica: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_guardar_nuevo_plan ? (
        <SweetAlert
          title="¿Desea guardar el nuevo plan?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadNuevoPlanConfirm()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar_nuevo_plan: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_historico_diagnosticos ? (
        <SweetAlert
          title="Histórico de Diagnósticos"
          showCancel
          showConfirm={false}
          cancelBtnText="Cerrar"
          cancelBtnBsStyle="danger"
          openAnim={false}
          style={{
            width: width && width < 851 ? "100%" : "90%",
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_historico_diagnosticos: false,
            });
          }}>
          <div className="mt-2">
            <table className="table table-hover table-centered table-nowrap mb-0">
              <thead>
                <tr>
                  <th scope="col">
                    Fecha de<br></br>Captura
                  </th>
                  <th scope="col">Diagnóstico</th>
                </tr>
              </thead>
              <tbody>
                {historiaClinicaInicial.expedienteMedico.diagnosticos &&
                  historiaClinicaInicial.expedienteMedico.diagnosticos.map((diagnostico) => {
                    return (
                      <tr key={diagnostico}>
                        <td>{diagnostico.fechaRegistro ? formatDateWithoutHour(diagnostico.fechaRegistro) : ""}</td>
                        <>
                          <textarea
                            disabled={true}
                            rows={4}
                            cols={100}
                            className="form-control-disabled"
                            value={diagnostico.diagnostico ? diagnostico.diagnostico : ""}
                          />
                        </>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_historico_etapasclinica ? (
        <SweetAlert
          title="Histórico de Etapas Clínicas"
          showCancel
          showConfirm={false}
          cancelBtnText="Cerrar"
          cancelBtnBsStyle="danger"
          openAnim={false}
          style={{
            width: width && width < 851 ? "100%" : "90%",
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_historico_etapasclinica: false,
            });
          }}>
          <div className="mt-2">
            <Row>
              <Col>
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        Fecha de<br></br>Captura
                      </th>
                      <th scope="col">Etapa Clínica</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historiaClinicaInicial.expedienteMedico.etapasClinicas &&
                      historiaClinicaInicial.expedienteMedico.etapasClinicas.map((etapaClinica) => {
                        return (
                          <tr key={etapaClinica}>
                            <td>{etapaClinica.fechaRegistro ? formatDateWithoutHour(etapaClinica.fechaRegistro) : ""}</td>
                            <textarea
                              disabled={true}
                              rows={4}
                              cols={100}
                              className="form-control-disabled"
                              value={etapaClinica.etapaClinica ? etapaClinica.etapaClinica : ""}
                            />
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_historico_planes ? (
        <SweetAlert
          title="Histórico de Planes"
          showCancel
          showConfirm={false}
          cancelBtnText="Cerrar"
          cancelBtnBsStyle="danger"
          openAnim={false}
          style={{
            width: width && width < 851 ? "100%" : "90%",
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_historico_planes: false,
            });
          }}>
          <div className="mt-2">
            <Row>
              <Col>
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        Fecha de<br></br>Captura
                      </th>
                      <th scope="col">PLAN</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historiaClinicaInicial.expedienteMedico.planes &&
                      historiaClinicaInicial.expedienteMedico.planes.map((plan) => {
                        return (
                          <tr key={plan}>
                            <td>{plan.fechaRegistro ? formatDateWithoutHour(plan.fechaRegistro) : ""}</td>

                            <td>
                              <textarea 
                                 disabled={true} 
                                 rows={4} 
                                 cols={100} 
                                 className="form-control-disabled"
                                 value={plan.plan ? plan.plan : ""} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}
      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
      {Prompt}
    </div>
  );

  // Checa si los otros campos fueron editados y no guardados
  // Si ninguno ha sido editado o ya fueron guardados, se hace setEditFalse para no mostrar la advertencia al tratar de salir
  function checkTodoGuardado(name) {
    let todoGuardado = true;
    // if (isEditExpediente.etapa || isEditExpediente.plan) todoGuardado = false;
    for (const key in isEditExpediente) {
      if (key != name) {
        if (isEditExpediente[key]) {
          todoGuardado = false;
        }
      }
    }

    setIsEditExpediente({ ...isEditExpediente, [name]: false });
    // const todoGuardado = Object.values(isEditExpediente).every((value) => value === true);
    if (todoGuardado) setEditFalse();
  }
}
