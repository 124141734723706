import React from "react";

import { Row, Col, Card, CardBody } from "reactstrap";

export default function InfoGeneralPagoMedico({ pagoMedico }) {
  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex">Información del Pago</h3>
      {pagoMedico ? (
        <Card
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Fecha del Pago</label>
                  <Col sm={7}>
                    <input disabled className="form-control" type="text" value={pagoMedico.fechaPago ? pagoMedico.fechaPago : ""} />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Folio Pago Médico</label>
                  <Col sm={7}>
                    <input disabled className="form-control" type="text" value={pagoMedico.folioValePago ? pagoMedico.folioValePago : ""} />
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Año</label>
                  <Col sm={7}>
                    <input disabled className="form-control" type="text" value={pagoMedico.anio ? pagoMedico.anio : ""} />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Semana</label>
                  <Col sm={7}>
                    <input disabled className="form-control" type="text" value={pagoMedico.semana ? pagoMedico.semana : ""} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        "No existe el pago de médico con ese ID"
      )}
    </div>
  );
}
