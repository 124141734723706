import React, {useReducer} from "react";

import RequerimientosCompraPendientesContext from "./RequerimientosCompraPendientesContext";
import RequerimientosCompraPendientesReducer from "./RequerimientosCompraPendientesReducer";

import {FILTRO_REQUERIMIENTOSCOMPRASPENDIENTES, LISTAR_REQUERIMIENTOSCOMPRAPENDIENTES_FILTRADO} from "../../types";

import clienteAxios from "../../config/axios";

export default function RequerimientosCompraPendientesState(props) {
    const initialState = {
        requerimientosPendientesFiltradas: [],
        filtrosRequerimientosPendientes: {},
    };

    const [state, dispatch] = useReducer(RequerimientosCompraPendientesReducer, initialState);

    const setFiltrosRequerimientosPendientes = async (filtrosRequerimientosPendientes) => {
        dispatch({
            type: FILTRO_REQUERIMIENTOSCOMPRASPENDIENTES,
            payload: filtrosRequerimientosPendientes,
        });
    };

    // const getFiltrosRequerimientosPendientes = async () => {
    //     const filtrosRequerimientosPendientes = JSON.parse(localStorage.getItem("filtrosRequerimientosPendientes"));
    //     if (filtrosRequerimientosPendientes != null) {
    //         dispatch({
    //             type: FILTRO_REQUERIMIENTOSCOMPRASPENDIENTES,
    //             payload: filtrosRequerimientosPendientes,
    //         });
    //     }
    // };

    const listarRequerimientosComprasPendientes = async () => {
        const res = await clienteAxios.post("/solicitudcompra/filtro/pendientes", state.filtrosRequerimientosPendientes, {
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        });

        res.data.map((reqPend) => {
            let fechaVentaFormat = reqPend.fechaSolicitud.split("T")[0].split("-");
            fechaVentaFormat = `${fechaVentaFormat[2]}/${fechaVentaFormat[1]}/${fechaVentaFormat[0]}`;
            reqPend.fechaSolicitud = fechaVentaFormat;
        });

        dispatch({
            type: LISTAR_REQUERIMIENTOSCOMPRAPENDIENTES_FILTRADO,
            payload: res.data,
        });
    };

    const listarRequerimientosComprasPendientesPaginados = async ({page, limit}) => {
        // LLAMADA A BACKEND
        try {
            const response = await clienteAxios.post(
                "/solicitudcompra/pendientes/paginado",
                {
                    pageSize: limit,
                    pageIndex: page,
                    ...state.filtrosRequerimientosPendientes,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );

            dispatch({
                type: LISTAR_REQUERIMIENTOSCOMPRAPENDIENTES_FILTRADO,
                payload: response.data.preSolicitudCompraProductoVoList,
            });

            return response.data;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const eliminarRequerimientosComprasPendientes = async () => {
        // LLAMADA A BACKEND
        try {
            const response = await clienteAxios.post(
                "/solicitudcompra/borrar",
                {...state.filtrosRequerimientosPendientes,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
             return true;   
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return false;
        }
    };

    const limpiarFiltrosRequerimientosPendientes = async () => {
        localStorage.removeItem("filtrosRequerimientosPendientes");

        const filtrosLimpios = {
            fechaInicial: "",
            tipoSolicitudCompra: "",
            fechaFinal: "",
            importeMaximo: "",
            idCatTipoProducto: null,
        };

        setRequerimientosPendientesFiltradas([]);

        dispatch({
            type: FILTRO_REQUERIMIENTOSCOMPRASPENDIENTES,
            payload: filtrosLimpios,
        });
    };

    const setRequerimientosPendientesFiltradas = async (requerimientosPendientesFiltradas) => {
        dispatch({
            type: LISTAR_REQUERIMIENTOSCOMPRAPENDIENTES_FILTRADO,
            payload: requerimientosPendientesFiltradas,
        });
    };

    const limpiarListaRequerimientosPendientes = async () => {
        dispatch({
            type: LISTAR_REQUERIMIENTOSCOMPRAPENDIENTES_FILTRADO,
            payload: [],
        });
    };

    return (
        <RequerimientosCompraPendientesContext.Provider
            value={{
                requerimientosPendientesFiltradas: state.requerimientosPendientesFiltradas,
                filtrosRequerimientosPendientes: state.filtrosRequerimientosPendientes,
                setFiltrosRequerimientosPendientes,
                listarRequerimientosComprasPendientes,
                limpiarFiltrosRequerimientosPendientes,
                setRequerimientosPendientesFiltradas,
                limpiarListaRequerimientosPendientes,
                listarRequerimientosComprasPendientesPaginados,
                eliminarRequerimientosComprasPendientes,
            }}>
            {props.children}
        </RequerimientosCompraPendientesContext.Provider>
    );
}
