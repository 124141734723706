import React, {useState, useContext, useEffect} from "react";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import {useParams} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import InventarioContext from "../../context/Inventario/InventarioContext";
import InformacionGeneralProductoInventario from "../InformacionGeneralProductoInventario";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import AuthContext from "../../context/Auth/AuthContext";
import useWindowDimensions from "../../hooks/dimensionsHook";

export default function TrasladoProductoInventario(props) {
    const {width} = useWindowDimensions();

    const {idInventario} = useParams();

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_ubicaciones: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    const [loading, setLoading] = useState(false);
    const [dataTraslado, setDataTraslado] = useState({});
    const [inventarioUbicacionSeleccionado, setInventarioUbicacionSeleccionado] = useState(null);

    const authContext = useContext(AuthContext);
    const {usuario} = authContext;
    
    const almacenesCtx = useContext(AlmacenesContext);
    const {almacenes, listarAlmacenes} = almacenesCtx;

    const inventarioCtx = useContext(InventarioContext);
    const {inventarioSeleccionado, seleccionarInventarioTraslado, uploadTrasladoProductoInventario} = inventarioCtx;

    const administrador =
        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
        usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
        usuario.authorities.includes("ROLE_DIRECCION"); 

    const handdleCheckedProductoInventario = async (ubicacion) => {
        if (inventarioUbicacionSeleccionado == null || inventarioUbicacionSeleccionado != ubicacion) {
            setInventarioUbicacionSeleccionado(ubicacion);
            return;
        }

        if (inventarioUbicacionSeleccionado != null && inventarioUbicacionSeleccionado == ubicacion) {
            setInventarioUbicacionSeleccionado(null);
            return;
        }
    };

    const confirmTraslado = async () => {

        if(inventarioSeleccionado.idCatAlmacenDestino){
            if(inventarioSeleccionado.idCatAlmacenDestino==inventarioSeleccionado.idCatAlmacen){
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, seleccione un almacén diferente al origen",
                });
                return;
            }
        }else{
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione un almacén destino.",
            });
            return;
        }

        if (inventarioUbicacionSeleccionado != null) {
            dataTraslado.idCatAlmacenDestino = inventarioSeleccionado.idCatAlmacenDestino;
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_traslado: true,
            });
        } else {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor seleccione una ubicación",
            });
        }
    };

    const onChangeTraslado = async (e) => {
        let value = e.target.value;

        if (e.target.name == "piezas" && value > inventarioUbicacionSeleccionado.piezas) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Las piezas de traslado no deben de ser mayores a las piezas de la ubicación",
            });
            value = 0;
        }
        setDataTraslado({
            ...dataTraslado,
            [e.target.name]: value,
        });
    };

    const confirmUploadTraslado = async () => {
        let errorUploadTraslado = false;

        if (!inventarioUbicacionSeleccionado && !errorUploadTraslado) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor seleccione una ubicación",
            });
            errorUploadTraslado = true;
            return;
        }

        if (!dataTraslado.idCatAlmacenDestino && !errorUploadTraslado) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione el almacén de destino",
            });
            errorUploadTraslado = true;
            return;
        }

        if ((!dataTraslado.piezas || dataTraslado.piezas <= 0) && !errorUploadTraslado) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el número de piezas para trasladar",
            });
            errorUploadTraslado = true;
            return;
        }

        // let x = {
        //   ...dataTraslado,
        //   idInventarioUbicacion: inventarioUbicacionSeleccionado.idInventarioUbicacion,
        // };

        if (!errorUploadTraslado) {
            setLoading(true);
            const res = await uploadTrasladoProductoInventario({
                ...dataTraslado,
                idInventarioUbicacion: inventarioUbicacionSeleccionado.idInventarioUbicacion,
            });
            if (res.data.error) {
                setLoading(false);

                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else if (res) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    redirect: true,
                    path: "/productos/inventario",
                    confirm_alert_traslado: false,
                    dynamic_description: "Se guardaron los cambios de manera exitosa.",
                });
            }
        }
    };

    useEffect(() => {
        listarAlmacenes(false,administrador);

        if (Object.keys(inventarioSeleccionado).length === 0) {
            seleccionarInventarioTraslado(idInventario);
        }
    }, [inventarioSeleccionado]);

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Nuevo traslado entre almacenes</h3>

            <InformacionGeneralProductoInventario opcion={ false } id = {inventarioSeleccionado.idCatAlmacenDestino} />

            <Card className="mt-4">
                <CardBody>
                    <h5 className="flex h5 mt-1 mb-2">Ubicaciones</h5>
                    <Row>
                        <Col>
                            <div className="table-responsive mt-4">
                                <table className="table table-hover table-centered table-nowrap mb-0">
                                    <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Anaquel</th>
                                        <th scope="col">Estante</th>
                                        <th scope="col">Ubicación</th>
                                        <th scope="col">Piezas</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {inventarioSeleccionado.ubicaciones &&
                                        inventarioSeleccionado.ubicaciones.map((ubicacion) => {
                                            return (
                                                <tr key={ubicacion}>
                                                    <td>
                                                        <input
                                                            checked={inventarioUbicacionSeleccionado == ubicacion ? true : false}
                                                            type="checkbox"
                                                            autoComplete="off" 
                                                            onChange={() => handdleCheckedProductoInventario(ubicacion)}
                                                        />
                                                    </td>
                                                    <td>{ubicacion.ubicacion.estante ? ubicacion.ubicacion.estante.anaquel.anaquel : ""}</td>
                                                    <td>{ubicacion.ubicacion.estante ? ubicacion.ubicacion.estante.estante : ""}</td>
                                                    <td>{ubicacion.ubicacion.ubicacion ? ubicacion.ubicacion.ubicacion : ""}</td>
                                                    <td>{ubicacion.piezas != null ? ubicacion.piezas : "0"}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button
                                className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-4"
                                type="button"
                                hidden={!(usuario.authorities.includes("ROLE_ALMACEN") || usuario.authorities.includes("ROLE_COMPRAS") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA"))}
                                onClick={confirmTraslado}>
                                Solicitar Traslado
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {UISweetAlert.confirm_alert_traslado ? (
                <SweetAlert
                    title="Solicitud de Traslado"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => confirmUploadTraslado()}
                    onCancel={() => {
                        setDataTraslado({});
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_traslado: false,
                        });
                    }}>
                    <div className="mt-2">
                        <Row>
                            <Col>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Almacén Destino</label>
                                    <Col sm={7}>
                                        <select
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeTraslado}
                                            name="idCatAlmacenDestino"
                                            disabled = {true}
                                            value={inventarioSeleccionado.idCatAlmacen}>
                                            <option selected disabled>
                                                Seleccione una opción
                                            </option>
                                            {almacenes.map((almacen) => { 
                                                let IdAlmacenTemp = almacen.idCatAlmacen; //TRAEMOS EL NOMBRE DEL ALMACEN

                                                if(!administrador && IdAlmacenTemp != 3 ){
                                                return (
                                                    <>
                                                        <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                                                    </>
                                                );
                                                }else
                                                {  
                                                if(administrador)
                                                {
                                                    return (
                                                        <>
                                                            <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                                                        </>
                                                    );
                                                }
                                                }
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Piezas disponibles</label>
                                    <Col sm={7}>
                                        <input
                                            type="number"
                                            className="form-control"
                                            autoComplete="off" 
                                            onChange={onChangeTraslado}
                                            name="piezas"
                                            value={inventarioSeleccionado.piezas}
                                        />
                                    </Col>
                                </Row>                                
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Piezas a trasladar</label>
                                    <Col sm={7}>
                                        <input
                                            type="number"
                                            className="form-control"
                                            autoComplete="off" 
                                            onChange={onChangeTraslado}
                                            name="piezas"
                                            value={dataTraslado.piezas}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
