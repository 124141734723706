/* eslint-disable import/no-anonymous-default-export */
import {
    TOGGLE_SIDEBAR
} from './../../types'

export default (state, action) => {
    switch (action.type){
        case TOGGLE_SIDEBAR:
            return{
                ...state,
                showSidebar: action.payload
            };
        default:
            return state
    }
}