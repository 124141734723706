import React, {useReducer} from "react";

import ValesContext from "./ValesContext";
import ValesReducer from "./ValesReducer";

import {
    LISTAR_VALE,
    TODOS_VALES,
    VALES_MEDICO,
    VALE_SELECCIONADO,
    NUEVO_VALE,
    ERROR_VALE_NUEVO,
    CAMBIOS_VALE_SELECCIONADO,
    LIMPIAR_VALE_SELECCIONADO,
    SET_VALES_FILTRADOS,
    ESTATUS_CANCELAR_VALE,
    ERROR_ESTATUS_CANCELAR_VALE,
    ESTATUS_DEVOLVER_VALE,
    ERROR_ESTATUS_DEVOLVER_VALE,
    CAMBIOS_FILTROS_VALE,
    CAMBIOS_FOLIO_FILTRO,
    NUEVO_COBRO_VALE,
    ERROR_NUEVO_COBRO_VALE,
    ELIMINAR_COBRO_VALE,
    ERROR_ELIMINAR_COBRO_VALE,
    ELIMINAR_PROGRESO_COBRO_VALE,
    ERROR_ELIMINAR_PROGRESO_COBRO_VALE,
    CAMBIO_CONCENTRACION_VALE,
    ERROR_CAMBIO_CONCENTRACION_VALE,
    ACTUALIZAR_VALE_FILTRADO,
    ERROR_ELIMINAR_VALE_PRODUCTOSERVICO,
    ELIMINAR_VALE_PRODUCTOSERVICO,
    MODIFICAR_VALE_PRODUCTOSERVICO,
    ERROR_MODIFICAR_VALE_PRODUCTOSERVICO,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function ValesState(props) {
    const initialState = {
        folioVale: null,
        valesFiltrados: [],
        valesFiltradosMedico: [],
        valeSeleccionado: null,
        filtrosVale: {},
        folioFiltro: {
            folioVale: null,
        },
        msg: "",
        errStatus: null,
        nuevoVale: {
            valeproductoservicios: [],
        },
    };

    const [state, dispatch] = useReducer(ValesReducer, initialState);

    // FUNCIONES CRUD

    const listarValePorFolio = async (folio) => {
        // const result = await clienteAxios.get(`${process.env.REACT_APP_BASE_URL}/1`)
        // console.log(result)

        dispatch({
            type: LISTAR_VALE,
            payload: null,
        });
    };

    const listarTodosVales = async () => {
        const res = await clienteAxios.post("/vales/filtro/json/simple", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        res.data.map((e) => {
            e.expanded = false;
            e.checked = false;
        });

        dispatch({
            type: TODOS_VALES,
            payload: res.data,
        });
        return res.data;
    };

    
    const listarValesFiltrados = async (data) => {
        // LLAMADA A BACKEND
        const res = await clienteAxios.post("/vales/filtro/json/simple", data, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        // CAMBIO EN EL ESTADO
        dispatch({
            type: TODOS_VALES,
            payload: res.data,
        });
    };


    const listarValesPaginadosAutorizaciones = async ({page, limit, necesitaAutorizacionTemp}) => {
        // LLAMADA A BACKEND
        try {
            const response = await clienteAxios.post(
                "/vales/paginado",
                {
                    pageSize: limit,
                    pageIndex: page,
                    necesitaAutorizacion: necesitaAutorizacionTemp,
                    ...state.filtrosVale,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );
            dispatch({
                type: TODOS_VALES,
                payload: response.data.valesVoList,
            });

            return response.data;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const listarValesPaginados = async ({page, limit, necesitaAutorizacionTemp}) => {
        // LLAMADA A BACKEND
        try {
            const response = await clienteAxios.post(
                "/vales/paginado",
                {
                    pageSize: limit,
                    pageIndex: page,
                    necesitaAutorizacion: necesitaAutorizacionTemp,
                    ...state.filtrosVale,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            dispatch({
                type: TODOS_VALES,
                payload: response.data.valesVoList,
            });

            return response.data;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const seleccionarVale = async (idVale, historicoVale) => {
        const res = await clienteAxios.get(`/vales/detalles?id=${idVale}`, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });
        let encontrado = false;
        res.data.historicoVale = historicoVale;
        res.data.estatusvales.map((estatus) => {
            estatus.expanded = false;
        });
        global.autorizarVale = false;

        res.data.valeproductoservicios.map((e) => {
            e.completed = false;
            e.expanded = false;
            e.productoServicioCambiado =  false;
            e.precioOriginal = e.precio;
            e.precioCorriente = e.precio;
            e.piezasOriginales = e.piezasVale;
            e.piezasCorriente = e.piezasVale;
            e.ivaCorriente = e.iva;
            e.ivaOriginal = e.iva;e.existeCambio = false;
            e.existeCambio = false;

            if(e.catProductos) {
                let cadena = ""
                
                e.catProductos.inventarios.map((inventario) => {
                    if(inventario){
                        cadena = cadena + "" + inventario.lote + " (" + inventario.piezas + "), ";
                    }
                    e.existencia = cadena;
                })
            } else {
                e.existencia = ""
            }

            // DEBEMOS VER QUE SI VIENE EN ESTATUS = 2 PONER LOS VALOS
            if (e.catServicios != null) {
                e.completed = true;
                e.idCatTipoProductoPersonal = 3;
                e.personalIdCatServicio = e.catServicios.idCatServicio
                if(!e.catServiciosOriginal){
                    e.personalIdCatServicioOriginal =0
                }else{
                    e.personalIdCatServicioOriginal = e.catServiciosOriginal.idCatServicio;
                }
                
                /*e.personalIdCatProducto = 0
                e.personalIdCatProductoOriginal = 0
                if(e.catServicios.idCatServicio !=  e.personalIdCatServicioOriginal){
                    e.existeCambio = true;
                    encontrado = true;
                }*/
            }

            if (e.catProductos != null) {
                e.personalIdCatProducto = e.catProductos.idCatProducto
                if(e.catProductos.catTipoproducto){
                    e.idCatTipoProductoPersonal = e.catProductos.catTipoproducto.idCatTipoProducto;
                }

                if(!e.catProductosOriginal){
                    e.personalIdCatProductoOriginal = 0
                }
                else {
                    e.personalIdCatProductoOriginal = e.catProductosOriginal.idCatProducto;
                }

                e.personalIdCatServicio = 0
                e.personalIdCatServicioOriginal = 0
                if(e.catProductos.idCatProducto !=  e.personalIdCatProductoOriginal && e.idCatTipoProductoPersonal == 2){
                    e.existeCambio = true;
                    encontrado = true;
                }
            }

            if (e.catServicios == null && e.catProductos == null) {
                e.personalIdCatServicio = 0
                e.personalIdCatServicioOriginal = 0
                e.personalIdCatProducto = 0
                e.personalIdCatProductoOriginal = 0
            }

            if (e.inventarioProductoServicios.length > 0) {
                let count = 0;
                e.inventarioProductoServicios.map((lote) => {
                    count += lote.piezasAsignadas;
                });

                if (count === e.piezasVale) {
                    e.completed = true;
                }
            }
        });

        dispatch({
            type: VALE_SELECCIONADO,
            payload: res.data,
        });
        
        if(res.data.valecomentarios && res.data.valecomentarios.length > 0){
            global.comentarioGlobal = res.data.valecomentarios[res.data.valecomentarios.length-1].comentario;
        }else{
            global.comentarioGlobal = ""
        }

        if(encontrado){
           /* if(res.data.necesitaAutorizacion==3){
                res.data.necesitaAutorizacion = 1;
                global.cambio  = true;
            }else{
                if(res.data.necesitaAutorizacion==2){
                    res.data.necesitaAutorizacion = 0
                    global.cambio  = false;
                }else{
                    if(res.data.necesitaAutorizacion==0){
                        res.data.necesitaAutorizacion = 1
                        global.cambio  = true;
                    }
                }
            }*/
            global.cambio  = true;
        }else{
            res.data.necesitaAutorizacion = 0
            global.cambio  = false;
        }

        res.data.decision = false;
        return res.data;
    };


    const setChangesValeSeleccionado = async (vale) => {
        dispatch({
            type: CAMBIOS_VALE_SELECCIONADO,
            payload: vale,
        });
    };

    const cleanValeSeleccionado = async () => {
        dispatch({
            type: LIMPIAR_VALE_SELECCIONADO,
            payload: null,
        });
    };

    const crearNuevoVale = async (data) => {
        try {
            const res = await clienteAxios.post(`/vales/nuevo`, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });
            return res;
        } catch (e) {
            console.log(e);
            //Error

            dispatch({
                type: ERROR_VALE_NUEVO,
                payload: "Error al crear nuevo vale, Inténtalo por favor más tarde.",
            });

            return false;
        }
    };

    const setValesFiltrados = async (valesFiltrados) => {
        dispatch({
            type: SET_VALES_FILTRADOS,
            payload: valesFiltrados,
        });
    };

    const cancelarVale = async (data) => {
        try {
            const res = await clienteAxios.post(`/vales/actualizar/estatus`, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            dispatch({
                type: ESTATUS_CANCELAR_VALE,
                payload: data.idVale,
            });

            return res;
        } catch (e) {
            console.log(e);
            //Error

            dispatch({
                type: ERROR_ESTATUS_CANCELAR_VALE,
            });

            return false;
        }
    };

    const cambiarFechaTratamiento = async (data) => {
        try {
            const res = await clienteAxios.post(`/vales/actualizar/fecha/tratamiento`, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            dispatch({
                type: ESTATUS_CANCELAR_VALE,
                payload: data.idVale,
            });

            return res;
        } catch (e) {
            console.log(e);
            //Error

            dispatch({
                type: ERROR_ESTATUS_CANCELAR_VALE,
            });

            return false;
        }
    };

    const devolverVale = async (data) => {
        try {
            const res = await clienteAxios.post(`/vales/actualizar/estatus`, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            dispatch({
                type: ESTATUS_DEVOLVER_VALE,
                payload: data.idVale,
            });

            return res;
        } catch (e) {
            console.log(e);
            //Error

            dispatch({
                type: ERROR_ESTATUS_DEVOLVER_VALE,
            });

            return false;
        }
    };

    const setChangesFiltrosVale = async (filtrosVale) => {
        dispatch({
            type: CAMBIOS_FILTROS_VALE,
            payload: filtrosVale,
        });
    };

    const setChangesFolioFiltro = async (folioFiltro) => {
        dispatch({
            type: CAMBIOS_FOLIO_FILTRO,
            payload: folioFiltro,
        });
    };

    const generarNuevoCobro = async (nuevoCobro) => {
        try {
            const res = await clienteAxios.post(`/vales/cobro`, nuevoCobro, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            dispatch({
                type: NUEVO_COBRO_VALE,
                payload: res.data,
            });

            return res;
        } catch (error) {
            // console.log(error.response);
            // console.log(error);
            //Error

            dispatch({
                type: ERROR_NUEVO_COBRO_VALE,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const eliminarNuevoCobro = async (idVentaValeCobro) => {
        try {
            const res = await clienteAxios.post(`/vales/cobro/eliminar?id=${idVentaValeCobro}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            state.valeSeleccionado.ventavales[0].ventavalecobro = state.valeSeleccionado.ventavales[0].ventavalecobro.filter(
                (cobro) => cobro.idVentaValeCobro != idVentaValeCobro
            );

            dispatch({
                type: ELIMINAR_COBRO_VALE,
                payload: state.valeSeleccionado,
            });

            return res;
        } catch (error) {
            // console.log(error.response);
            // console.log(error);
            //Error

            dispatch({
                type: ERROR_ELIMINAR_COBRO_VALE,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const eliminarProgresoCobroValePersonal = async (idVale) => {
        try {
            const res = await clienteAxios.post(`/vales/cobro/eliminar/progreso?id=${idVale}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            state.valeSeleccionado.ventavales[0].ventavalecobro = [];

            dispatch({
                type: ELIMINAR_PROGRESO_COBRO_VALE,
                payload: state.valeSeleccionado,
            });

            return res;
        } catch (error) {
            // console.log(error.response);
            // console.log(error);
            //Error

            dispatch({
                type: ERROR_ELIMINAR_PROGRESO_COBRO_VALE,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const exportValeReport = async (idVale) => {
        // const res = await clienteAxios.get(`/vales/export/vale/${idVale}`, {
        //     headers: {
        //       "Access-Control-Allow-Origin": "*"
        //         }
        // })
        // console.log(res)

        clienteAxios
        .get(`/vales/export/vale/${idVale}`, {
            responseType: "arraybuffer",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/pdf",
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        })
        .then((response) => {
            var file = new Blob([response.data], {type: "application/pdf"});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            // console.log(response)
        })
        .catch((error) => console.log(error));
    };

    const downloadValeReport = async (idVale, folioVale) => {
        clienteAxios
        .get(`/vales/export/vale/${idVale}`, {
            responseType: "arraybuffer",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/pdf",
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        })
        .then((response) => {
            var file = new Blob([response.data], {type: "application/pdf"});
            var fileURL = URL.createObjectURL(file);

            const a = document.createElement("a");
            a.href = fileURL;
            a.download = `vale-${folioVale}.pdf`;
            a.click();
        })
        .catch((error) => console.log(error));
    };

    const actualizarValeFiltrado = async (vale) => {
        dispatch({
            type: ACTUALIZAR_VALE_FILTRADO,
            payload: vale,
        });
    };

    const cambiarConcentracionVale = async (data) => {
        //Se manda, el idVale, idEstatusNuevo (cancelado), "comentarioEstatus": "Cambio de Concentración"
        try {
            const res = await clienteAxios.post(`/vales/cambio/concentracion`, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });
            // console.log(res);

            dispatch({
                type: CAMBIO_CONCENTRACION_VALE,
                payload: res.data,
            });
            return res;
        } catch (error) {
            dispatch({
                type: ERROR_CAMBIO_CONCENTRACION_VALE,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    // const exportValeCotizacion = async (idVale) => {
    //   try {
    //     const res = await clienteAxios.get(`/vales/export/cotizacion/${idVale}`, {
    //       responseType: "arraybuffer",

    //       headers: {
    //         "Content-Disposition": "attachment; filename=cotizacion.pdf",
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //     });

    //     var file = new Blob([res.data], { type: "application/pdf" });
    //     var fileURL = URL.createObjectURL(file);
    //     window.open(fileURL);

    //     return res;
    //   } catch (error) {

    //     if (!error.response) {
    //       error.response = {
    //         data: {
    //           message: "Fallo en la conexión al servidor",
    //           error: "hubo error",
    //         },
    //       };

    //     }

    //     return error.response;
    //   }
    // };
    const exportValeCotizacion = async (valeVo) => {
        try {
            const res = await clienteAxios.post(`/vales/export/cotizacion`, valeVo, {
                responseType: "arraybuffer",
                headers: {
                    "Content-Disposition": "attachment; filename=cotizacion.pdf",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            var file = new Blob([res.data], {type: "application/pdf"});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const eliminarValeProductoServicio = async (idValeProductoServicio) => {
        try {
            const res = await clienteAxios.post(`/vales/eliminar/valeproductoservicio?id=${idValeProductoServicio}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            dispatch({
                type: ELIMINAR_VALE_PRODUCTOSERVICO,
                payload: null,
            });

            return res;
        } catch (error) {
            dispatch({
                type: ERROR_ELIMINAR_VALE_PRODUCTOSERVICO,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const exportReciboPagoVale = async (idVentaValeCobro) => {
        try {
            const res = await clienteAxios.get(`/vales/export/recibo/${idVentaValeCobro}`, {
                responseType: "arraybuffer",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/pdf",
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            var file = new Blob([res.data], {type: "application/pdf"});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            } else {
                error.response = {
                    data: {
                        message: "Error, por favor inténtalo más tarde",
                        error: "Error",
                    },
                };
            }

            return error.response;
        }
    };

    const submitModificaciones = async (data) => {
        try {
            const res = await clienteAxios.post(`/vales/modificar/valeproductoservicio`, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            dispatch({
                type: MODIFICAR_VALE_PRODUCTOSERVICO,
                payload: null,
            });

            return res;
        } catch (error) {
            dispatch({
                type: ERROR_MODIFICAR_VALE_PRODUCTOSERVICO,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const setNuevoVale = async (nuevoVale) => {
        dispatch({
            type: NUEVO_VALE,
            payload: nuevoVale,
        });
    };

    const revivirVale = async (voRevivir,logVo) => {
        try {
            const res = await clienteAxios.post(`/vales/revivir`, voRevivir,{
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const exportCarta = async (archivoVo) => {
        try {
            const res = await clienteAxios.post(`/vales/export/archivo`, archivoVo, {
                responseType: "arraybuffer",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });
            // var file = new Blob([res.data], { type: "application/pdf" });
            // var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            var file = new Blob([res.data], {type: archivoVo.mimeType});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            } else {
                error.response = {
                    data: {
                        message: "Error, por favor inténtalo más tarde",
                        error: "Error",
                    },
                };
            }
            return error.response;
        }
    };

    const listarValesPaginadosMedico = async ({page, limit}, filtrosVale, idCatMedico) => {
        // LLAMADA A BACKEND
        try {
            const response = await clienteAxios.post(
                "/vales/paginado",
                {
                    pageSize: limit,
                    pageIndex: page,
                    medico: idCatMedico,
                    ...filtrosVale,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            dispatch({
                type: VALES_MEDICO,
                payload: response.data.valesVoList,
            });

            return response.data;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };


    return (
        <ValesContext.Provider
            value={{
                valesFiltrados: state.valesFiltrados,
                valeSeleccionado: state.valeSeleccionado,
                msg: state.msg,
                errStatus: state.errStatus,
                filtrosVale: state.filtrosVale,
                folioFiltro: state.folioFiltro,
                ventaValeCobros: state.ventaValeCobros,
                nuevoVale: state.nuevoVale,
                valesFiltradosMedico: state.valesFiltradosMedico,
                listarValePorFolio,
                listarTodosVales,
                listarValesFiltrados,
                seleccionarVale,
                crearNuevoVale,
                setChangesValeSeleccionado,
                cleanValeSeleccionado,
                setValesFiltrados,
                cancelarVale,
                devolverVale,
                setChangesFiltrosVale,
                setChangesFolioFiltro,
                generarNuevoCobro,
                eliminarNuevoCobro,
                eliminarProgresoCobroValePersonal,
                exportValeReport,
                cambiarConcentracionVale,
                exportValeCotizacion,
                actualizarValeFiltrado,
                eliminarValeProductoServicio,
                exportReciboPagoVale,
                submitModificaciones,
                setNuevoVale,
                revivirVale,
                listarValesPaginados,
                listarValesPaginadosAutorizaciones,
                exportCarta,
                downloadValeReport,
                listarValesPaginadosMedico,
                cambiarFechaTratamiento,
            }}>
            {props.children}
        </ValesContext.Provider>
    );
}
