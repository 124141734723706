import React, { useReducer } from "react";

import EstatusValeContext from "./EstatusValeContext";
import EstatusValeReducer from "./EstatusValeReducer";
import "../../config/global.js"
import {
  LISTAR_ESTATUSVALE,
  PROXIMOS_ESTATUS,
  DATA_CAMBIO_ESTATUS,
  ESTATUS_CANCELADO,
  UPLOAD_CAMBIO_ESTATUS,
  ERROR_CAMBIO_ESTATUS,
  CLEAN_PROXIMOS_ESTATUS,
  ESTATUS_DEVUELTO,
  LISTAR_ESTATUSVALE_SALDOS_ASEGURADORA,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function EstatusValeState(props) {
  const initialState = {
    estatusvale: [],
    proximosEstatusVale: [],
    cambioEstatusData: {
      valeProductoServicio: [],
    },
    estatusCancelado: [],
    estatusDevuelto: [],
    msg: "",
    errStatus: null,
    estatusValeSaldosAseguradora: [],
  };

  const [state, dispatch] = useReducer(EstatusValeReducer, initialState);

  const listarEstatusVale = async (nombreDeEstatus) => {
    const res = await clienteAxios.get("/estatusvale/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    //Si solo queremos el nombre de los estatus, mandamos un true, para que nos regrese el nombre sin estatus repetidos
    if (nombreDeEstatus) {
      let x = [];
      res.data.map((e) => {
        //Es para el select multiple
        // let estatus = {
        //     value: "" +e.estatusVale,
        //     label: "" +e.estatusVale,
        // }
        x.push(e.estatusVale);
      });
      let unique = [...new Set(x)];
      res.data = unique;
    }

    dispatch({
      type: LISTAR_ESTATUSVALE,
      payload: res.data,
    });
  };

  const getProximosEstatusVale = async (idVale) => {
    // OBTENER VALE DETALLADO A TRAVÉS DE SU ID
    const res = await clienteAxios.get(`/vales/proximos/estatus?id=${idVale}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    // CAMBIO DE ESTADO
    dispatch({
      type: PROXIMOS_ESTATUS,
      payload: res.data,
    });
  };

  const getEstatusCancelado = async (idVale) => {
    const resEstatusCancelado = await clienteAxios.get(`/vales/estatus/cancelado?id=${idVale}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });
    dispatch({
      type: ESTATUS_CANCELADO,
      payload: resEstatusCancelado.data,
    });
  };

  const getEstatusDevuelto = async (idVale) => {
    const res = await clienteAxios.get(`/vales/estatus/devuelto?id=${idVale}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });
    dispatch({
      type: ESTATUS_DEVUELTO,
      payload: res.data,
    });
  };

  const setcambioEstatusData = async (data) => {
    dispatch({
      type: DATA_CAMBIO_ESTATUS,
      payload: data,
    });
  };

  const AutorizarVale = async (autorizarVale) => {
    try {
      const res = await clienteAxios.post(`/vales/autorizar`, autorizarVale, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });
      // console.log(resCambioEstatus);

      dispatch({
        type: UPLOAD_CAMBIO_ESTATUS,
        payload: {
          msg: "Exito! Actualizacion al estatus registrado",
          data: res,
        },
      });
      
      return res;
    } catch (e) {
      console.log(e);
      //Error

      dispatch({
        type: ERROR_CAMBIO_ESTATUS,
        payload: "Error al subir los cambios, Inténtalo por favor más tarde.",
      });
      return false;
    }
  };

  const actualizarOCambiarEstatus = async (cambioEstatusData) => {
    try {
      cambioEstatusData.comentario = global.comentarioGlobal;
      cambioEstatusData.impreso = true;
      const resCambioEstatus = await clienteAxios.post(`/vales/actualizar/estatus`, cambioEstatusData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });
      // console.log(resCambioEstatus);

      dispatch({
        type: UPLOAD_CAMBIO_ESTATUS,
        payload: {
          msg: "Exito! Actualizacion al estatus registrado",
          data: resCambioEstatus,
        },
      });
      
      return resCambioEstatus;
    } catch (e) {
      console.log(e);
      //Error

      dispatch({
        type: ERROR_CAMBIO_ESTATUS,
        payload: "Error al subir los cambios, Inténtalo por favor más tarde.",
      });

      return false;
    }
  };

  const cleanProximosEstatusVale = async () => {
    dispatch({
      type: CLEAN_PROXIMOS_ESTATUS,
      payload: "Error al subir los cambios, Inténtalo por favor más tarde.",
    });
  };

  const listarEstatusValeSaldosAseguradora = async () => {
    const res = await clienteAxios.get("/estatusvale/saldos/aseguradora", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_ESTATUSVALE_SALDOS_ASEGURADORA,
      payload: res.data,
    });
  };

  const traerExistenciaProducto = async (IdProductoServicio) => {
    try{
      const res = await clienteAxios.get(`/inventario/disponible/producto?id=${IdProductoServicio}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });
      return res.data;
    } catch (e) {
      console.log(e);
      //Error

      dispatch({
        type: ERROR_CAMBIO_ESTATUS,
        payload: "Error al realizar la consulta, Inténtalo por favor más tarde.",
      });

      return false;
    }
  }

  const verificarEstatus = async (ValeId) => {
    try{
      const res = await clienteAxios.get(`/vales/estatus/actual?id=${ValeId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });
      return res.data;
    } catch (e) {
      console.log(e);
      //Error

      dispatch({
        type: ERROR_CAMBIO_ESTATUS,
        payload: "Error al realizar la consulta, Inténtalo por favor más tarde.",
      });

      return false;
    }
  };

  return (
    <EstatusValeContext.Provider
      value={{
        estatusvale: state.estatusvale,
        proximosEstatusVale: state.proximosEstatusVale,
        cambioEstatusData: state.cambioEstatusData,
        estatusCancelado: state.estatusCancelado,
        estatusDevuelto: state.estatusDevuelto,
        msg: state.msg,
        errStatus: state.errStatus,
        estatusValeSaldosAseguradora: state.estatusValeSaldosAseguradora,
        traerExistenciaProducto,
        listarEstatusVale,
        getProximosEstatusVale,
        cleanProximosEstatusVale,
        setcambioEstatusData,
        getEstatusCancelado,
        getEstatusDevuelto,
        actualizarOCambiarEstatus,
        listarEstatusValeSaldosAseguradora,
        AutorizarVale,
        verificarEstatus,
      }}>
      {props.children}
    </EstatusValeContext.Provider>
  );
}
