import {
  CAMBIOS_FILTRO_SOLICITUDES_COMPRAS,
  LIMPIAR_SOLICITUD_COMPRA_SELECCIONADA,
  NUEVA_SOLICITUD_COMPRA,
  LISTAR_SOLICITUDESCOMPRA_FILTRADO,
  CREAR_NUEVA_SOLICITUDCOMPRA,
  ERROR_CREAR_NUEVA_SOLICITUDCOMPRA,
  NUEVA_SOLICITUD_COMPRA_PENDIENTE,
  SOLICITUD_COMPRA_SELECCIONADA,
  SOLICITUD_COMPRA_SHOWEDIT,
  ERROR_SOLICITUD_COMPRA_SELECCIONADA,
  UPLOAD_SOLICITUD_COMPRA,
  ERROR_UPLOAD_SOLICITUD_COMPRA,
  SOLICITUDES_ORDENESCOMPRA_PROVEEDOR,
  ERROR_SOLICITUDES_ORDENESCOMPRA_PROVEEDOR,
  CANCELAR_SOLICITUD_COMPRA,
} from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case CAMBIOS_FILTRO_SOLICITUDES_COMPRAS:
      return {
        ...state,
        filtrosSolicitudesCompras: action.payload,
      };
    case NUEVA_SOLICITUD_COMPRA:
      return {
        ...state,
        nuevaSolicitudCompra: action.payload,
      };
    case LISTAR_SOLICITUDESCOMPRA_FILTRADO:
      return {
        ...state,
        solicitudesComprasFiltradas: action.payload,
      };
    case CREAR_NUEVA_SOLICITUDCOMPRA:
    case ERROR_CREAR_NUEVA_SOLICITUDCOMPRA:
    case ERROR_UPLOAD_SOLICITUD_COMPRA:
      return {
        ...state,
      };
    case UPLOAD_SOLICITUD_COMPRA:
      return {
        solicitudesComprasFiltradas: state.solicitudesComprasFiltradas.filter((solicitud) => {
          if (solicitud.idSolicitudCompra == action.payload.idSolicitudCompra) {
            solicitud.estatussolicitudcompra = action.payload.estatussolicitudcompra;
          }
          return true;
        }),
        ...state,
      };
    case NUEVA_SOLICITUD_COMPRA_PENDIENTE:
      return {
        ...state,
        nuevaSolicitudCompraPendiente: action.payload,
      };
    case SOLICITUD_COMPRA_SELECCIONADA:
    case LIMPIAR_SOLICITUD_COMPRA_SELECCIONADA:
      return {
        ...state,
        solicitudCompraSeleccionada: action.payload,
        errorSolicitudCompraSeleccionada: false,
      };
    case ERROR_SOLICITUD_COMPRA_SELECCIONADA:
      return {
        ...state,
        solicitudCompraSeleccionada: {},
        errorSolicitudCompraSeleccionada: true,
      };
    case SOLICITUD_COMPRA_SHOWEDIT:
      return {
        ...state,
        solicitudShowEdit: action.payload,
      };
    case SOLICITUDES_ORDENESCOMPRA_PROVEEDOR:
      return {
        ...state,
        solicitudOrdenesCompraProveedores: action.payload,
        errorSolicitudCompraSeleccionada: false,
      };
    case ERROR_SOLICITUDES_ORDENESCOMPRA_PROVEEDOR:
      return {
        ...state,
        solicitudOrdenesCompraProveedores: {},
        errorSolicitudCompraSeleccionada: true,
      };
    case CANCELAR_SOLICITUD_COMPRA:
      return {
        ...state,
        cancelarSolicitudCompra: action.payload,
      };
    default:
      return state;
  }
};
