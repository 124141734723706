/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from "../../context/Auth/AuthContext";
import NotificacionesContext from "../../context/Notificaciones/NotificacionesContext";
import WindowWarningContext from "../../context/WindowWarning/WindowWarningContext";

export default function RutaPrivada({ component: Component, ...props }) {
  const authContext = useContext(AuthContext);
  const { autenticado, cargando, usuarioAutenticado } = authContext;

  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit } = windowWarningContext;

  const notfContext = useContext(NotificacionesContext);
  const { obtenerNumeroNotificaciones } = notfContext;

  //Cada minuto va a pedir las notificaciones
  function obtenerNotifRecursivo() {
    obtenerNumeroNotificaciones();
    // Cada 60 segundos
    setTimeout(obtenerNotifRecursivo, 60000);
  }

  useEffect(() => {
    usuarioAutenticado(); // INCLUYE LA VERIFICACIÓN DEL USUARIO EN CADA COMPONENTE

    if(autenticado){
      obtenerNotifRecursivo();
      changeEdit(false);
    }
  }, [Component]);

  return (
    <Route
      {...props}
      render={(props) =>
        cargando
          ? null
          : [
              !autenticado ? ( // ESTE USUARIO ESTÁ AUTENTICADO ?
                // NO, NO ESTÁ AUTENTICADO
                <Redirect to="/login" />
              ) : (
                // SI, SI ESTÁ AUTENTICADO
                <Component {...props} />
              ),
            ]
      }></Route>
  );
}
