import React, { useContext, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import OrdenesCompraContext from "../../context/OrdenesCompra/OrdenesCompraContext";

export default function InformacionGeneralSolicitudCompra(props) {
  const ordenesCompraContext = useContext(OrdenesCompraContext);
  const { ordenCompraSeleccionada, setOrdenCompraSeleccionada } = ordenesCompraContext;

  const formatDate = async () => {
    if (!ordenCompraSeleccionada.fechaOrden.includes("T")) {
      return;
    }

    let splitDate = ordenCompraSeleccionada.fechaOrden.split("T")[0].split("-");
    splitDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];

    ordenCompraSeleccionada.fechaOrden = splitDate;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
  };

  useEffect(() => {
    if (ordenCompraSeleccionada && ordenCompraSeleccionada.fechaOrden) {
      formatDate();
    }
  }, [ordenCompraSeleccionada]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Información General</h3>

      <Card
        className="mt-10 card"
        style={{
          padding: "1.5rem 1.5rem 1.5rem",
        }}>
        <CardBody>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Fecha</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ordenCompraSeleccionada && ordenCompraSeleccionada.fechaOrdenCompra
                        ? `${ordenCompraSeleccionada.fechaOrdenCompra.split("T")[0].split("-")[2]}/${
                            ordenCompraSeleccionada.fechaOrdenCompra.split("T")[0].split("-")[1]
                          }/${ordenCompraSeleccionada.fechaOrdenCompra.split("T")[0].split("-")[0]}`
                        : ""
                    }
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Proveedor</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ordenCompraSeleccionada && ordenCompraSeleccionada.catProveedores
                        ? ordenCompraSeleccionada.catProveedores.proveedor
                        : ""
                    }
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Tipo Solicitud de Compras</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ordenCompraSeleccionada && ordenCompraSeleccionada.catTiposolicitudcompra
                        ? ordenCompraSeleccionada.catTiposolicitudcompra.tipoSolicitudCompra
                        : ""
                    }
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Tipo Compra</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ordenCompraSeleccionada && ordenCompraSeleccionada.catTipocompra
                        ? ordenCompraSeleccionada.catTipocompra.tipoCompra
                        : ""
                    }
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Comprador</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ordenCompraSeleccionada && ordenCompraSeleccionada.usuarioCompradorNombre
                        ? ordenCompraSeleccionada.usuarioCompradorNombre
                        : ""
                    }
                  />
                </Col>
              </Row>
            </Col>

            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Folio OC</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={ordenCompraSeleccionada ? ordenCompraSeleccionada.folioOrdenCompra : ""}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Lugar de Entrega</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ordenCompraSeleccionada && ordenCompraSeleccionada.catAlmacen
                        ? ordenCompraSeleccionada.catAlmacen.almacen
                        : ""
                    }
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Usuario Receptor</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ordenCompraSeleccionada && ordenCompraSeleccionada.usuarioReceptorNombre
                        ? ordenCompraSeleccionada.usuarioReceptorNombre
                        : ""
                    }
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Estatus</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      ordenCompraSeleccionada && ordenCompraSeleccionada.estatusordencompra
                        ? ordenCompraSeleccionada.estatusordencompra[ordenCompraSeleccionada.estatusordencompra.length - 1]
                            .catEstatusordencompra.estatusOrdencompra
                        : ""
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
