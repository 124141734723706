export const calcularSCT = (talla, peso) => {
  if (talla && peso) {
    let sct = peso ** 0.425 * talla ** 0.725 * 0.007184;
    return sct.toFixed(2);
  } else {
    return 0;
  }
};

export const calcularIMC = (talla, peso) => {
  if (talla && peso) {
    const tallEnMetros = talla / 100;
    let imc = peso / Math.pow(tallEnMetros, 2);
    return imc.toFixed(2);
  } else {
    return 0;
  }
};
