import {
    PADECIMIENTO_ACTUAL,
} from "../../types";
  
export default (state, action) => {
    switch (action.type) {
        case PADECIMIENTO_ACTUAL:
            return {
                ...state,
                padecimientoActual: action.payload,
            };
        default:
            return state;
    }
};
  