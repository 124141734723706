import React, { useReducer } from "react";

import OrdenesCompraContext from "./OrdenesCompraContext";
import OrdenesCompraReducer from "./OrdenesCompraReducer";

import {
  FILTRO_ORDENESCOMPRA,
  LISTAR_ORDENESCOMPRA,
  ORDEN_COMPRA_SELECCIONADA,
  ERROR_ORDEN_COMPRA_SELECCIONADA,
  ORDEN_COMPRA_SHOWEDIT,
  UPLOAD_ORDEN_COMPRA,
  ERROR_UPLOAD_ORDEN_COMPRA,
  NUEVA_ORDEN_COMPRA,
  ERROR_NUEVA_ORDEN_COMPRA,
  CANCELAR_ORDEN_COMPRA,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function OrdenesCompraState(props) {
  const initialState = {
    ordenesCompraFiltradas: [],
    filtrosOrdenesCompra: {},
    ordenCompraSeleccionada: {},
    errorOrdenCompraSeleccionada: false,
    ordenCompraShowEdit: {
      hiddenRecepcion: true,
      disabledRecepcion: true,
      guardarOrdenCompra: true,
      guardarRecepcionOrdenCompra: true,
      guardarRecibidaOrdenCompra: true,
      aprobarPagoOrdenCompra: true,
      hiddenArchivos: true,
      disabledArchivos: true,
      hiddenRecibida: true,
      cancelarOrdenCompra: false,
    },
    cancelarOrdenCompra: {
      fechaCancelacion: new Date().toISOString().slice(0, 10),
      comentarioEstatus: "",
    },
  };

  const [state, dispatch] = useReducer(OrdenesCompraReducer, initialState);

  const setFiltrosOrdenesCompra = async (filtrosOrdenesCompra) => {
    dispatch({
      type: FILTRO_ORDENESCOMPRA,
      payload: filtrosOrdenesCompra,
    });
  };

  const listarOrdenesCompras = async (filtrosOrdenesCompra) => {
    const res = await clienteAxios.post("/ordencompra/filtro/json/detalle", filtrosOrdenesCompra, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_ORDENESCOMPRA,
      payload: res.data,
    });
  };

  const listarOrdenesCompraPaginadas = async ({ page, limit }) => {
    try {
      const response = await clienteAxios.post(
        "/ordencompra/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          ...state.filtrosOrdenesCompra,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      dispatch({
        type: LISTAR_ORDENESCOMPRA,
        payload: response.data.ordenCompraVoList,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const limpiarOrdenCompraSeleccionada = async () => {
    dispatch({
      type: ORDEN_COMPRA_SELECCIONADA,
      payload: {},
    });
  };

  const seleccionarOrdenCompra = async (idOrdenCompra) => {
    try {
      const res = await clienteAxios.get(`/ordencompra/detalles?id=${idOrdenCompra}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      dispatch({
        type: ORDEN_COMPRA_SELECCIONADA,
        payload: res.data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_ORDEN_COMPRA_SELECCIONADA,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setOrdenesCompraFiltradas = async (ordenesCompraFiltradas) => {
    dispatch({
      type: LISTAR_ORDENESCOMPRA,
      payload: ordenesCompraFiltradas,
    });
  };

  const setOrdenCompraSeleccionada = async (ordenCompraSeleccionada) => {
    dispatch({
      type: ORDEN_COMPRA_SELECCIONADA,
      payload: ordenCompraSeleccionada,
    });
  };

  const setOrdenCompraShowEdit = async (ordenCompraShowEdit) => {
    dispatch({
      type: ORDEN_COMPRA_SHOWEDIT,
      payload: ordenCompraShowEdit,
    });
  };

  const guardarOrdenCompra = async () => {
    try {
      const res = await clienteAxios.post(`/ordencompra/modificar/ordencompraproducto`, state.ordenCompraSeleccionada, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      dispatch({
        type: UPLOAD_ORDEN_COMPRA,
        payload: res.data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_UPLOAD_ORDEN_COMPRA,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };
  const solicitarAprobacionPago = async () => {
    try {
      const res = await clienteAxios.post(`/ordencompra/solicitar/aprobacion`, state.ordenCompraSeleccionada, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const solicitudNuevaOrdenCompra = async (nuevaOrdenCompra) => {
    try {
      const res = await clienteAxios.post(`/ordencompra/nueva`, nuevaOrdenCompra, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      dispatch({
        type: NUEVA_ORDEN_COMPRA,
        payload: res.data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_NUEVA_ORDEN_COMPRA,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const guardarRecepcionOrdenCompra = async () => {
    try {
      const res = await clienteAxios.post(`/ordencompra/recepcion`, state.ordenCompraSeleccionada, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      dispatch({
        type: UPLOAD_ORDEN_COMPRA,
        payload: res.data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_UPLOAD_ORDEN_COMPRA,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const uploadCancelarOrdenCompra = async (data) => {
    try {
      const res = await clienteAxios.post(`/ordencompra/modificar/estatus`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      dispatch({
        type: UPLOAD_ORDEN_COMPRA,
        payload: res.data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_UPLOAD_ORDEN_COMPRA,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setCancelarOrdenCompra = async (cancelarOrdenCompra) => {
    dispatch({
      type: CANCELAR_ORDEN_COMPRA,
      payload: cancelarOrdenCompra,
    });
  };

  const exportOrdenCompra = async (idOrdenCompra) => {
    try {
      const res = await clienteAxios.get(`/ordencompra/export/orden/${idOrdenCompra}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      var file = new Blob([res.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  const exportOrdenCompraReabasto = async (idOrdenCompra) => {
    try {
      const res = await clienteAxios.get(`/ordencompra/export/reabasto/${idOrdenCompra}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      var file = new Blob([res.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  const exportArchivoBack = async (archivoVo) => {
    try {
      const res = await clienteAxios.post(`/ordencompra/export/archivo`, archivoVo, {
        responseType: "arraybuffer",
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });
      var file = new Blob([res.data], { type: archivoVo.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }
      return error.response;
    }
  };

  return (
    <OrdenesCompraContext.Provider
      value={{
        ordenesCompraFiltradas: state.ordenesCompraFiltradas,
        filtrosOrdenesCompra: state.filtrosOrdenesCompra,
        ordenCompraSeleccionada: state.ordenCompraSeleccionada,
        errorOrdenCompraSeleccionada: state.errorOrdenCompraSeleccionada,
        ordenCompraShowEdit: state.ordenCompraShowEdit,
        cancelarOrdenCompra: state.cancelarOrdenCompra,
        setFiltrosOrdenesCompra,
        listarOrdenesCompras,
        limpiarOrdenCompraSeleccionada,
        seleccionarOrdenCompra,
        setOrdenesCompraFiltradas,
        setOrdenCompraSeleccionada,
        setOrdenCompraShowEdit,
        guardarOrdenCompra,
        solicitudNuevaOrdenCompra,
        guardarRecepcionOrdenCompra,
        uploadCancelarOrdenCompra,
        setCancelarOrdenCompra,
        exportOrdenCompra,
        exportOrdenCompraReabasto,
        listarOrdenesCompraPaginadas,
        exportArchivoBack,
        solicitarAprobacionPago,
      }}>
      {props.children}
    </OrdenesCompraContext.Provider>
  );
}
