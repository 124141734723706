import React, {useReducer} from 'react'

import CatTipoCompraContext from './CatTipoCompraContext'
import CatTipoCompraReducer from './CatTipoCompraReducer'

import {
    LISTAR_CATTIPOCOMPRAS,
} from '../../types'

import clienteAxios from '../../config/axios'


export default function CatTipoCompraState (props) {

    const initialState = { 
        tiposCompra:[],
    }

    const [state, dispatch] = useReducer(CatTipoCompraReducer,initialState)

    const listarTiposCompra = async () => {

        const res = await clienteAxios.get("/tipocompra/activos"  , {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
            dispatch({
                type: LISTAR_CATTIPOCOMPRAS,
                payload: res.data
            })
    }

    return (

        <CatTipoCompraContext.Provider value={{
            tiposCompra: state.tiposCompra,
            listarTiposCompra,
        }}>
            {props.children}
        </CatTipoCompraContext.Provider>

    )


}