import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import QuimioContext from "../../context/QuimioContext/QuimioContext";
import ExpedienteMedicoContext from "../../context/ExpedienteMedico/ExpedienteMedicoContext";

import PacientesContext from "../../context/Pacientes/PacientesContext";

import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import SexosContext from "../../context/Sexos/SexosContext";

import RegistroMarcadores from "../RegistroMarcadores/index";

import DrawerExpedientePaciente from "../DrawerExpedientePaciente/index";

import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";

import useWindowDimensions from "../../hooks/dimensionsHook";

import TableComponent from "../TablaPaginada/index";

import { calcularSCT, calcularIMC } from "../../utils/calcularInformacionFisica";

import formatDateWithoutHour from "../../utils/formatDateWithoutHour";

import SpanError from "../UI/spanError";

import formatMarcadoresToObject from "../../utils/formatMarcadoresToObject";

// import dayjs from "dayjs";

const NotaEvolucion = (props) => {
  const { width } = useWindowDimensions();
  const { idExpedienteMedico } = useParams();
  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const [nombrePaciente, setNombrePaciente] = useState(null);

  const expedienteMedicoContext = useContext(ExpedienteMedicoContext);
  const { imprimirExpedienteMedico } = expedienteMedicoContext;

  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  const cleanNotaEvolucion = {
    fechaRegistro: null,
    horaIngreso: null,
    horaEgreso: null,
    s: "",
    o: "",
    a: "",
    p: "",
    comentario: "",
  };

  const [tipoMarcador, setTipoMarcador] = useState(null);

  const [idEvolucionSeleccionada, setIdEvolucionSeleccionada] = useState(null);

  const marcadoresVacio = {
    idEvolucion: null,
    talla: null,
    peso: null,
    frecuenciaCardiaca: null,
    frecuenciaRespiratoria: null,
    saturacionOxigeno: null,
    temperatura: null,
    tensionArterialDiastolica: null,
    tensionArterialSistolica: null,
    indiceMasaCorporal: null,
    superficieCorporalTotal: null,
  };

  const [marcadores, setMarcadores] = useState(marcadoresVacio);

  const [notaEvolucion, setNotaEvolucion] = useState(cleanNotaEvolucion);

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }, []);

  useEffect(() => {
    if (historiaClinicaInicial && historiaClinicaInicial.paciente) {
      const nombres = historiaClinicaInicial.paciente.nombres ? historiaClinicaInicial.paciente.nombres : "";
      const apellidoPaterno = historiaClinicaInicial.paciente.apellidoPaterno
        ? historiaClinicaInicial.paciente.apellidoPaterno
        : "";
      const apellidoMaterno = historiaClinicaInicial.paciente.apellidoMaterno
        ? historiaClinicaInicial.paciente.apellidoMaterno
        : "";
      const nombreCompleto = `${nombres} ${apellidoPaterno} ${apellidoMaterno}`;
      setNombrePaciente(nombreCompleto);
    }
  }, []);

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado, guardarMarcadores, listarMarcadoresEvolucion } = pacientesContext;

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  useEffect(() => {
    const fetchAll = () => {
      listarSexos();
      listarAseguradoras();
      listarEscolaridades();
      listarEstadosCiviles();
      listarLugares();
      listarOcupaciones();
      listarMedicos();
    };
    fetchAll();
  }, []);

  const quimioContext = useContext(QuimioContext);

  const { uploadNuevaNotaEvolucion, notasEvolucionPaginadas, setNotasEvolucion, eliminarNotaEvolucion } = quimioContext;

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_nueva_nota: false,
    confirm_eliminar_nota: false,
    confirm_alert_registrar_marcadores: false,
    confirm_ver_nota: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [controlledPageIndex, setControlledPageIndex] = useState(0);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);
  const [notaSeleccionada, setNotaSeleccionada] = useState(null);

  // let today = new Date();
  // const dd = String(today.getDate()).padStart(2, "0");
  // const mm = String(today.getMonth() + 1).padStart(2, "0");
  // const yyyy = today.getFullYear();

  // today = yyyy + "-" + mm + "-" + dd;

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      // const esMedico = usuario.authorities.includes("ROLE_MEDICO") && !usuario.authorities.includes("ROLE_ADMIN_SISTEMA");

      const response = await setNotasEvolucion({ page, limit, idExpedienteMedico: idExpedienteMedico });

      const count = response.count;

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }
      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server.
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);

    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
      });
    }
  };

  const handdleCheckedRow = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setNotaSeleccionada(row.original);
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setNotaSeleccionada(null);
      } else {
        setRowSeleccionada(row.id);
        setNotaSeleccionada(row.original);
      }
    },
    [rowSeleccionada]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idEvolucion",
        Cell: ({ row }) => {
          return (
            <input checked={rowSeleccionada === row.id ? true : false} type="checkbox" onChange={() => handdleCheckedRow(row)} />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Fecha de Registro",
        Cell: ({ row }) => {
          return <>{row.original.fechaRegistro ? formatDateWithoutHour(row.original.fechaRegistro) : ""}</>;
        },
        show: true,
      },

      // { Header: "Fecha de Registro", accessor: "fechaRegistro", show: true },
      { Header: "Comentario", accessor: "comentario", show: true },
      {
        Header: "Ingreso Signos Vitales",
        Cell: ({ row }) => {
          return (
            <button
              className="btn btn-primary-orange-umo w-md waves-effect waves-light"
              type="button"
              hidden={!(usuario.authorities.includes("ROLE_QUIMIO") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA"))}
              onClick={() => registrarMarcadoresConfirm(row.original, { ingreso: true, egreso: false })}>
              Registrar
            </button>
          );
        },
        show: true,
      },
      {
        Header: "Egreso Signos Vitales",
        Cell: ({ row }) => {
          return (
            <button
              className="btn btn-primary-orange-umo w-md waves-effect waves-light"
              type="button"
              hidden={!(usuario.authorities.includes("ROLE_QUIMIO") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA"))}
              onClick={() => registrarMarcadoresConfirm(row.original, { ingreso: false, egreso: true })}>
              Registrar
            </button>
          );
        },
        show: true,
      },
      {
        Header: "Imprimir",
        Cell: ({ row }) => {
          return (
            <button
              className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-2 mt-2"
              onClick={() => imprimirNota(row.original)}
              type="button">
              Imprimir
            </button>
          );
        },
        show: true,
      },
    ],
    [rowSeleccionada, handdleCheckedRow]
  );

  const imprimirNota = async (nota) => {
    const expedienteImpresionVo = {
      imprimirNotasEvolucion: true,
      idExpedienteMedico: nota.idExpedienteMedico,
      idCatPaciente: idCatPacienteSeleccionado,
      idEvolucion: nota.idEvolucion,
    };
    setLoading(true);
    try {
      const res = await imprimirExpedienteMedico(expedienteImpresionVo);
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const autorizado = usuario.authorities.includes("ROLE_QUIMIO");

  const onChangeNotaEvolucion = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    // Se revisa si el valor incluye indicadores AM/PM
    if (name == "horaIngreso" || name == "horaEgreso") {
      if (value.length === 5) {
        const [hours, minutes] = value.split(":");

        if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
          setNotaEvolucion({
            ...notaEvolucion,
            [name]: value,
          });
        }
      } else {
        setNotaEvolucion({
          ...notaEvolucion,
          [name]: value,
        });
      }
      if (value.includes("AM") || value.includes("PM")) {
        // Se convierte a 24 horas con una fecha dummy porque solo se necesita la hora
        const time12h = new Date("1970-01-01 " + value);
        formattedValue = time12h.toLocaleTimeString("es-MX", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        });
        setNotaEvolucion({
          ...notaEvolucion,
          [name]: formattedValue,
        });
      } else {
        setNotaEvolucion({
          ...notaEvolucion,
          [name]: value,
        });
      }
    } else {
      setNotaEvolucion({
        ...notaEvolucion,
        [name]: value,
      });
    }
  };

  const checkNuevaNota = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nueva_nota: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Lo siento, no cuenta con los permisos necesarios para esta acción",
      });
    }
  };

  const checkEliminarNota = () => {
    if (autorizado && notaSeleccionada) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_nota: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione una nota de evolución",
      });
    }
  };

  const checkVerNota = () => {
    if (autorizado && notaSeleccionada) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_ver_nota: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione una nota de evolución",
      });
    }
  };

  const eliminarNotaSeleccionada = async () => {
    setLoading(true);
    const res = await eliminarNotaEvolucion(notaSeleccionada);

    if (res === undefined) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_nota: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error con la conexión al servidor",
      });
      return;
    } else if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_nota: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_nota: false,
        dynamic_description: "Se eliminó la nota correctamente",
      });

      fetchData({ pageSize: 10, pageIndex: 0, byBusquedaFiltros: true });
      setNotaSeleccionada(null);
      setRowSeleccionada(null);
    }
  };

  const redirectHistoriaClinicaInicial = async () => {
    props.history.push("/historia/clinica/inicial");
  };

  const validateInputs = () => {
    if (!notaEvolucion.fechaRegistro || notaEvolucion.fechaRegistro === "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "Por favor, ingrese la fecha de registro.",
      });
      return false;
    }
    if (!notaEvolucion.horaIngreso || notaEvolucion.horaIngreso === "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "Por favor, capture la hora de ingreso.",
      });
      return false;
    }
    if (!notaEvolucion.horaEgreso || notaEvolucion.horaEgreso === "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "Por favor, capture la hora de egreso.",
      });
      return false;
    }
    return true;
  };

  const uploadNotaEvolucion = async () => {
    setLoading(true);
    const notaValid = validateInputs();
    if (notaValid) {
      try {
        // let formattedDateIngreso;
        // let formattedDateEgreso;
        let horaIngreso;
        let horaEgreso;

        if (notaEvolucion.fechaRegistro) {
          horaIngreso = notaEvolucion.horaIngreso ? notaEvolucion.horaIngreso + ":00" : null;
          // formattedDateIngreso = horaIngreso ? dayjs(horaIngreso).format("YYYY-MM-DD HH:mm:ss") : null;
          horaEgreso = notaEvolucion.horaEgreso ? notaEvolucion.horaEgreso + ":00" : null;
          // formattedDateEgreso = horaEgreso ? dayjs(horaEgreso).format("YYYY-MM-DD HH:mm:ss") : null;
        }

        const nuevaNotaEvolucion = await uploadNuevaNotaEvolucion({
          ...notaEvolucion,
          horaIngreso: horaIngreso,
          horaEgreso: horaEgreso,
          idExpedienteMedico: idExpedienteMedico,
        });
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nueva_nota: false,
          dynamic_description: "Se guardó la nota de evolución correctamente",
        });
        fetchData({ pageSize: 10, pageIndex: 0, byBusquedaFiltros: true });
        setNotaEvolucion(cleanNotaEvolucion);
        return nuevaNotaEvolucion;
      } catch (error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Ocurrio un error, por favor intentelo más tarde",
        });
      }
    } else {
      setLoading(false);
    }
  };

  const onChangeMarcadores = (e) => {
    const { name, value } = e.target;
    if (name == "talla") {
      const sct = calcularSCT(value, marcadores.peso ? marcadores.peso : null);
      const imc = calcularIMC(value, marcadores.peso ? marcadores.peso : null);
      setMarcadores({
        ...marcadores,
        talla: value,
        superficieCorporalTotal: sct,
        indiceMasaCorporal: imc,
      });
    } else if (name == "peso") {
      const sct = calcularSCT(marcadores.talla ? marcadores.talla : null, value);
      const imc = calcularIMC(marcadores.talla ? marcadores.talla : null, value);
      setMarcadores({
        ...marcadores,
        peso: value,
        superficieCorporalTotal: sct,
        indiceMasaCorporal: imc,
      });
    } else
      setMarcadores({
        ...marcadores,
        [name]: value,
      });
  };

  const updateMarcadores = async () => {
    setLoading(true);
    try {
      const idCatPaciente =
        historiaClinicaInicial && historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idCatPaciente
          ? historiaClinicaInicial.paciente.idCatPaciente
          : null;

      const marcadoresGuardados = await guardarMarcadores({
        ...marcadores,
        idEvolucion: idEvolucionSeleccionada,
        idCatPaciente: idCatPaciente,
        tipo: tipoMarcador,
      });
      setMarcadores(marcadoresVacio);
      setTipoMarcador(null);
      setIdEvolucionSeleccionada(null);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_registrar_marcadores: false,
        dynamic_description: "Marcadores guardados correctamente",
      });
      setLoading(false);
      return marcadoresGuardados;
    } catch (error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Error al guardar los marcadores, intentalo más tarde.",
      });
      return error;
    }
  };

  const registrarMarcadoresConfirm = async (nota, tipoMarcador) => {
    // I: Ingreso
    // E: Egreso
    // tipo: I o E
    setIdEvolucionSeleccionada(nota.idEvolucion);
    const tipo = tipoMarcador.ingreso ? "I" : tipoMarcador.egreso ? "E" : null;
    const pacienteVo = {
      idEvolucion: nota.idEvolucion,
      tipo: tipo,
      idCatPaciente: idCatPacienteSeleccionado,
    };
    setLoading(true);
    try {
      const res = await listarMarcadoresEvolucion(pacienteVo);
      const marcadores = await res.data;

      if (marcadores) {
        const marcadoresFormateados = await formatMarcadoresToObject(marcadores);

        setMarcadores(marcadoresFormateados);
      }
      setLoading(false);
    } catch (error) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Ocurrió un error al buscar los marcadores del paciente o todavía no existen los marcadores.",
      });
      console.log(error);
      setLoading(false);
    }
    setTipoMarcador(tipo);
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_registrar_marcadores: true,
    });
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Notas de Evolución</h3>
      <DrawerExpedientePaciente
        historiaClinicaInicial={historiaClinicaInicial}
        sexos={sexos}
        aseguradoras={aseguradoras}
        medicos={medicos}
        lugares={lugares}
        ocupaciones={ocupaciones}
        estadosCiviles={estadosCiviles}
        escolaridades={escolaridades}
      />
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    onClick={redirectHistoriaClinicaInicial}>
                    Historia Clínica Inicial
                  </button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkNuevaNota()}>
                      Nueva Nota de Evolución
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkVerNota()}>
                      Ver Nota de Evolución
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarNota()}>
                      Eliminar Nota de Evolución
                    </button>
                  ) : null}
                  {/* {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      hidden={
                        !(usuario.authorities.includes("ROLE_QUIMIO") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA"))
                      }
                      onClick={registrarMarcadoresConfirm}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Registrar Signos Vitales
                    </button>
                  ) : null} */}
                </Col>
              </Row>
              <h4 className="flex h4 mt-5">Lista de Notas de Evolución</h4>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={notasEvolucionPaginadas}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nueva_nota ? (
        <SweetAlert
          title="Nueva Nota de Evolución"
          showCancel
          confirmBtnText="Guardar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cerrar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => uploadNotaEvolucion()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nueva_nota: false,
            });
          }}>
          <>
            <Row className="mb-5">
              <Col>{nombrePaciente ? nombrePaciente : ""}</Col>
            </Row>

            <div className="flex items-center justify-center text-center">
              <CardBody>
                <Row>
                  <Col xl={6} lg={12} md={12}>
                    <Row className="form-group mt-3">
                      <label className="col-sm-4 col-form-label">Fecha de Registro {<SpanError>*</SpanError>}</label>
                      <Col sm={8} className="mb-3">
                        <input
                          type="date"
                          className="form-control"
                          name="fechaRegistro"
                          onChange={onChangeNotaEvolucion}
                          value={notaEvolucion.fechaRegistro ? notaEvolucion.fechaRegistro : ""}
                        />
                        {(!notaEvolucion.fechaRegistro || notaEvolucion.fechaRegistro === "") && <SpanError />}
                      </Col>
                      <label className="col-sm-4 col-form-label">Hora de Ingreso {<SpanError>*</SpanError>}</label>
                      <Col sm={8} className="mb-3">
                        <input
                          type="time"
                          pattern="[0-9]{2}:[0-9]{2}"
                          className="form-control"
                          name="horaIngreso"
                          required
                          onChange={onChangeNotaEvolucion}
                          value={notaEvolucion.horaIngreso ? notaEvolucion.horaIngreso : ""}
                        />
                        {(!notaEvolucion.horaIngreso || notaEvolucion.horaIngreso === "") && <SpanError />}
                      </Col>
                      <label className="col-sm-4 col-form-label">Hora de Egreso {<SpanError>*</SpanError>}</label>
                      <Col sm={8} className="mb-3">
                        <input
                          type="time"
                          required
                          pattern="[0-9]{2}:[0-9]{2}"
                          className="form-control"
                          name="horaEgreso"
                          onChange={onChangeNotaEvolucion}
                          value={notaEvolucion.horaEgreso ? notaEvolucion.horaEgreso : ""}
                        />
                        {(!notaEvolucion.horaEgreso || notaEvolucion.horaEgreso === "") && <SpanError />}
                      </Col>
                      <label className="col-sm-4 col-form-label">Comentario</label>
                      <Col sm={8} className="mb-3">
                        <textarea
                          type="text"
                          rows={5}
                          className="form-control"
                          name="comentario"
                          onChange={onChangeNotaEvolucion}
                          value={notaEvolucion.comentario ? notaEvolucion.comentario : ""}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={12} md={12}>
                    <Row className="form-group mt-3">
                      <label className="col-sm-4 col-form-label">S</label>
                      <Col sm={8} className="mb-3">
                        <textarea
                          type="text"
                          className="form-control"
                          name="s"
                          onChange={onChangeNotaEvolucion}
                          value={notaEvolucion.s ? notaEvolucion.s : ""}
                        />
                      </Col>
                      <label className="col-sm-4 col-form-label">O</label>
                      <Col sm={8} className="mb-3">
                        <textarea
                          type="text"
                          className="form-control"
                          name="o"
                          onChange={onChangeNotaEvolucion}
                          value={notaEvolucion.o ? notaEvolucion.o : ""}
                        />
                      </Col>
                      <label className="col-sm-4 col-form-label">A</label>
                      <Col sm={8} className="mb-3">
                        <textarea
                          type="text"
                          className="form-control"
                          name="a"
                          onChange={onChangeNotaEvolucion}
                          value={notaEvolucion.a ? notaEvolucion.a : ""}
                        />
                      </Col>
                      <label className="col-sm-4 col-form-label">P</label>
                      <Col sm={8} className="mb-3">
                        <textarea
                          type="text"
                          className="form-control"
                          name="p"
                          onChange={onChangeNotaEvolucion}
                          value={notaEvolucion.p ? notaEvolucion.p : ""}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </div>
          </>
        </SweetAlert>
      ) : null}
      {UISweetAlert.confirm_ver_nota ? (
        <SweetAlert
          title="Nota de Evolución"
          confirmBtnText="Cerrar"
          confirmBtnBsStyle="success"
          closeOnClickOutside={true}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_ver_nota: false,
            });
          }}>
          <>
            <Row className="mb-5">
              <Col>{nombrePaciente ? nombrePaciente : ""}</Col>
            </Row>

            <div className="flex items-center justify-center text-center">
              <CardBody>
                <Row>
                  <Col xl={6} lg={12} md={12}>
                    <Row className="form-group mt-3">
                      <label className="col-sm-4 col-form-label">Fecha de Registro</label>
                      <Col sm={8} className="mb-3">
                        <input
                          type="date"
                          disabled={true}
                          className="form-control"
                          name="fechaRegistro"
                          value={notaSeleccionada.fechaRegistro ? formatDateWithoutHour(notaSeleccionada.fechaRegistro) : ""}
                        />
                      </Col>
                      <label className="col-sm-4 col-form-label">Hora de Ingreso</label>
                      <Col sm={8} className="mb-3">
                        <input
                          type="time"
                          disabled={true}
                          className="form-control"
                          name="horaIngreso"
                          onChange={onChangeNotaEvolucion}
                          value={notaSeleccionada.horaIngreso ? notaSeleccionada.horaIngreso : ""}
                        />
                      </Col>
                      <label className="col-sm-4 col-form-label">Hora de Egreso</label>
                      <Col sm={8} className="mb-3">
                        <input
                          type="time"
                          disabled={true}
                          className="form-control"
                          name="horaEgreso"
                          onChange={onChangeNotaEvolucion}
                          value={notaSeleccionada.horaEgreso ? notaSeleccionada.horaEgreso : ""}
                        />
                      </Col>
                      <label className="col-sm-4 col-form-label">Comentario</label>
                      <Col sm={8} className="mb-3">
                        <textarea
                          type="text"
                          disabled={true}
                          rows={5}
                          className="form-control"
                          name="comentario"
                          onChange={onChangeNotaEvolucion}
                          value={notaSeleccionada.comentario ? notaSeleccionada.comentario : ""}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={12} md={12}>
                    <Row className="form-group mt-3">
                      <label className="col-sm-4 col-form-label">S</label>
                      <Col sm={8} className="mb-3">
                        <textarea
                          type="text"
                          disabled={true}
                          className="form-control"
                          name="s"
                          value={notaSeleccionada.s ? notaSeleccionada.s : ""}
                        />
                      </Col>

                      <label className="col-sm-4 col-form-label">O</label>
                      <Col sm={8} className="mb-3">
                        <textarea
                          type="text"
                          disabled={true}
                          className="form-control"
                          name="o"
                          value={notaSeleccionada.o ? notaSeleccionada.o : ""}
                        />
                      </Col>
                      <label className="col-sm-4 col-form-label">A</label>
                      <Col sm={8} className="mb-3">
                        <textarea
                          type="text"
                          disabled={true}
                          className="form-control"
                          name="a"
                          value={notaSeleccionada.a ? notaSeleccionada.a : ""}
                        />
                      </Col>
                      <label className="col-sm-4 col-form-label">P</label>
                      <Col sm={8} className="mb-3">
                        <textarea
                          type="text"
                          disabled={true}
                          className="form-control"
                          name="p"
                          value={notaSeleccionada.p ? notaSeleccionada.p : ""}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </div>
          </>
        </SweetAlert>
      ) : null}
      {UISweetAlert.confirm_eliminar_nota ? (
        <SweetAlert
          title="¿Desea eliminar la nota seleccionada?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => eliminarNotaSeleccionada()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_nota: false,
            });
          }}></SweetAlert>
      ) : null}
      {UISweetAlert.confirm_alert_registrar_marcadores ? (
        <SweetAlert
          title="Registro de Signos Vitales"
          showCancel
          confirmBtnText="Guardar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "90%",
          }}
          onConfirm={() => updateMarcadores()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_registrar_marcadores: false,
            });
            setTipoMarcador(null);
            setMarcadores(marcadoresVacio);
            setIdEvolucionSeleccionada(null);
          }}>
          <>
            <Row className="mb-3">
              <Col>
                <span className="font-bold">{"Paciente: "}</span>
                {historiaClinicaInicial && historiaClinicaInicial.paciente
                  ? historiaClinicaInicial.paciente.nombres +
                    " " +
                    historiaClinicaInicial.paciente.apellidoPaterno +
                    " " +
                    historiaClinicaInicial.paciente.apellidoMaterno
                  : ""}
              </Col>
            </Row>
            <Row className="mb-5">
              <Col>
                <span className="font-bold">{"Tipo de registro:"}</span> {tipoMarcador == "I" ? "Ingreso" : "Egreso"}
              </Col>
            </Row>

            {<RegistroMarcadores marcadores={marcadores} onChange={onChangeMarcadores} />}
          </>
        </SweetAlert>
      ) : null}
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default NotaEvolucion;
