import React, {useState, useEffect, useCallback, useMemo, useRef, useContext} from "react";
import {Row, Col, Card, CardBody, Spinner} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import CentroMezclasContext from "../../context/CentroMezclas/CentroMezclasContext";

// import WindowedSelect from "react-windowed-select";

import PacientesContext from "../../context/Pacientes/PacientesContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import ExpedienteMedicoContext from "../../context/ExpedienteMedico/ExpedienteMedicoContext";
import TiposAdmistracionContext from "../../context/TiposAdministracion/TiposAdministracionContext";
import ResultadosReaContext from "../../context/ResultadosRea/ResultadosReaContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginada/index";

import SelectAsyncPaginate from "../SelectAsyncPaginate";

import useWindowDimensions from "../../hooks/dimensionsHook";

import {opcionesSelectPacientes} from "../../config/configOpcionesSelect";

const ReporteReaccionesPaginadas = () => {
    const {width} = useWindowDimensions();
    // const { idVale } = useParams();

    const filtrosVacios = {
        fechaInicial: "",
        fechaFinal: "",
        idCatPaciente: "",
        idCatProducto: "",
    };

    const centroMezclasContext = useContext(CentroMezclasContext);
    const {setFiltrosReportesReacciones, filtrosReporteReacciones, reportesReacciones,  setDataReportesReaccionesPaginados} =
        centroMezclasContext;

    const ctxPacientes = useContext(PacientesContext);
    const {listarPacientesActivos, pacientes, loadPacientesSelect} = ctxPacientes;

    //ACCESO AL ESTADO GLOBAL - MEDICOS
    const ctxMedicos = useContext(MedicosContext);
    const {medicos, listarMedicos} = ctxMedicos;

    const ctxExpedienteMedico = useContext(ExpedienteMedicoContext);
    const {setDatosExpedienteMedico} = ctxExpedienteMedico;

    const ctxTiposAdministracion = useContext(TiposAdmistracionContext);
    const {tiposAdministracion, listarTiposAdministracion} = ctxTiposAdministracion;

    const ctxResultadosRea = useContext(ResultadosReaContext);
    const {resultadosRea, listarResultadosRea} = ctxResultadosRea;

    const ctxProductos = useContext(CatProductosContext);
    const {catProductos, listarMedicamentos,MedicamentosVentaMostradorPaciente, MedicamentosCompraExterna} = ctxProductos;

    useEffect(() => {
        // if (usuarios.length <= 0)
        const fetchtComprasPagadas = async () => {
          const medicamentosCETemp = await MedicamentosCompraExterna(1);
          setMedicamentosCEGlobal(medicamentosCETemp);
    
          const medicamentosVMTemp = await MedicamentosVentaMostradorPaciente(0);    
          setMedicamentosVMGlobal(medicamentosVMTemp);
    
          listarPacientesActivos();
          listarMedicos();
          listarTiposAdministracion();
          listarResultadosRea();
          listarMedicamentos();
        }
        fetchtComprasPagadas();
        // fetchVale();
      }, []);

    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;

    // const todayFormat = yyyy + "-" + mm + "-" + dd;

    const [reporteSeleccionado, setReporteSeleccionado] = useState(null);
    const [rowSeleccionada, setRowSeleccionada] = useState(null);
    const [pacienteCompleto, setPacienteCompleto] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [pacienteSeleccionado, setPacienteSeleccionado] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [medicamentoSeleccionado, setMedicamentoSeleccionado] = useState(null);
    const [medicamentos, setMedicamentos] = useState([]);
    const [listaMedicamentosVentaMostrador, setListaMedicamentosVentaMostrador] = useState([]);
    const [listaMedicamentosCompraExterna, setListaMedicamentosCompraExterna] = useState([]);
    const [medicamentosCEGlobal, setMedicamentosCEGlobal] = useState([]);
    const [medicamentosVMGlobal, setMedicamentosVMGlobal] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [isFirstSearch, setIsFirstSearch] = useState(true);

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_modificar_reporte_paciente: false,
        confirm_modificar_reporte_medicamento: false,
        confirm_modificar_reporte_reaccion: false,
        confirm_nuevo_reporte_paciente: false,
        confirm_nuevo_reporte_medicamento: false,
        confirm_nuevo_reporte_reaccion: false,
        confirm_eliminar_reporte: false,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
    });

    const [filtrosLocales, setFiltrosLocales] = useState({
        fechaFinal: "",
        fechaInicial: "",
        idCatPaciente: null,
        idCatPaciente: null,
        idCatProducto: null,
        idExpedienteMedico: null,
        idReaccionAdversa: null,
    });

    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const [pageCount, setPageCount] = useState(0);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [listaMedicamentosSeleccionado, setListaMedicamentosSeleccionado] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState([
        {
            idReaccionAdversa: "",
            fechaRegistro: "",
            prescripcionMedica: "",
            responsableRevision: "",
            responsablePreparacion: "",
            idCatProducto: "",
            medicamento: "",
            ordenPreparacion: "",
            calculos: "",
            horaEntrega: "",
        },
    ]);

    const fetchIdRef = useRef(0);

    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);
            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);
            }

            const response = await setDataReportesReaccionesPaginados(page, limit,{filtrosLocales});
            const count = response.count;
            setData(response.bitacoras);
            setRowSeleccionada(null);
            if (count % limit > 0) {
                setPageCount(Math.ceil(count / limit));
            } else {
                setPageCount(count / limit);
            }
            setLoadingData(false);
        } catch (e) {
            console.log("Error while fetching", e);
        }
    };

    const fetchData = async ({pageSize, pageIndex, byBusquedaFiltros}) => {
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: byBusquedaFiltros ? byBusquedaFiltros : false,
            });
        }
    };

    const handdleCheckedReporte = useCallback(
        (row) => {
            if (rowSeleccionada === null || rowSeleccionada !== row.id) {
                setRowSeleccionada(row.id);
                const fetchExpediente = async (idCatPaciente) => {
                    setLoading(true);
                    let opcionTemp = 0;
                    let laboratorioTemp = "";
                    let principioActivoTemp = "";
                    let presentacionTemp = "";
                    let NombrecomercialTemp = ""

                    const medicamentosCETemp = await MedicamentosCompraExterna(row.original.idCatPaciente);
                    setListaMedicamentosCompraExterna(medicamentosCETemp);
                    
                    if(row.original.idProductoCompraExterna != null){
                        let medCETemp = listaMedicamentosCompraExterna.filter((medicamento) => medicamento.idProductoCompraExterna == row.original.idProductoCompraExterna);
                        let medCE = medCETemp[0];
                        if(medCE){
                            opcionTemp = 3;
                            laboratorioTemp = medCE.laboratorio? medCE.laboratorio : "";
                            principioActivoTemp = medCE.principioActivo? medCE.principioActivo : "";
                            presentacionTemp = medCE.presentacion? medCE.presentacion : "";
                            NombrecomercialTemp = medCE.nombreComercial + " " + medCE.concentracion + " " + medCE.presentacion ;
                        }else{
                            setRowSeleccionada(null);
                            setReporteSeleccionado(null);
                        }
                    } 

                    if(row.original.idCatProducto != null || row.original.idCatProductoVentaMostrador != null){
                        let idTemp = row.original.idCatProducto? row.original.idCatProducto : row.original.idCatProductoVentaMostrador;
                        opcionTemp  = row.original.idCatProducto? 1 : 2;
                        let medCETemp = catProductos.filter((medicamento) => medicamento.idCatProducto == idTemp);
                        let medCE = medCETemp[0];
                        if(medCE){
                            laboratorioTemp = medCE.laboratorio? medCE.laboratorio : "";
                            principioActivoTemp = medCE.principioActivo? medCE.principioActivo : "";
                            presentacionTemp = medCE.presentacion? medCE.presentacion : "";
                            NombrecomercialTemp = medCE.nombreComercial;
                        }else{
                            setRowSeleccionada(null);
                            setReporteSeleccionado(null);
                        }
                    }                   

                    if (row.original.expedienteMedico && row.original.expedienteMedico.idExpedienteMedico) {
                        const res = await setDatosExpedienteMedico(row.original.expedienteMedico.idExpedienteMedico, null);
                        let expedienteMedico = res[0];

                        setReporteSeleccionado({
                            ...row.original,
                            diagnostico: expedienteMedico.diagnostico ? expedienteMedico.diagnostico : "",
                            idCatProducto: row.original.idCatProducto? row.original.idCatProducto : null ,
                            idCatProductoVentaMostrador: row.original.idCatProductoVentaMostrador? row.original.idCatProductoVentaMostrador : null,
                            idCatProductoCompraExterna: row.original.idProductoCompraExterna? row.original.idProductoCompraExterna : null,
                            sexo: row.original.sexo,
                            edad: row.original.fechaNacimiento ? calcEdad(row.original.fechaNacimiento) : "",
                            cronicoDegenerativos: row.original.cronicoDegenerativos,
                            idCatPaciente: row.original.idCatPaciente
                                ? row.original.idCatPaciente
                                : expedienteMedico.catPaciente && expedienteMedico.catPaciente.idCatPaciente
                                    ? expedienteMedico.catPaciente.idCatPaciente
                                    : "",
                            laboratorio: laboratorioTemp,
                            principioActivo: principioActivoTemp,
                            presentacion: presentacionTemp,
                            opcion : opcionTemp,
                            Nombrecomercial1 : opcionTemp ==1? NombrecomercialTemp : "No hay medicamentos",
                            Nombrecomercial2 : opcionTemp ==2? NombrecomercialTemp : "No hay medicamentos",
                            Nombrecomercial3 : opcionTemp ==3? NombrecomercialTemp : "No hay medicamentos",
                        });
                        return res[0];
                    } else {
                        const res = await setDatosExpedienteMedico(null, idCatPaciente);
                        let expedienteMedico = res[0];
                        setReporteSeleccionado({
                            ...row.original,
                            diagnostico: expedienteMedico.diagnostico ? expedienteMedico.diagnostico : "",
                            idCatProducto: row.original.idCatProducto? row.original.idCatProducto : null ,
                            idCatProductoVentaMostrador: row.original.idCatProductoVentaMostrador? row.original.idCatProductoVentaMostrador : null,
                            idCatProductoCompraExterna: row.original.idProductoCompraExterna? row.original.idProductoCompraExterna : null,
                            sexo: row.original.sexo,
                            edad: row.original.fechaNacimiento ? calcEdad(row.original.fechaNacimiento) : "",
                            cronicoDegenerativos: row.original.cronicoDegenerativos,
                            idCatPaciente: row.original.idCatPaciente
                                ? row.original.idCatPaciente
                                : expedienteMedico.catPaciente && expedienteMedico.catPaciente.idCatPaciente
                                    ? expedienteMedico.catPaciente.idCatPaciente
                                    : "",
                            laboratorio: laboratorioTemp,
                            principioActivo: principioActivoTemp,
                            presentacion: presentacionTemp,
                            opcion : opcionTemp,
                            Nombrecomercial1 : opcionTemp ==1? NombrecomercialTemp : "No hay medicamentos",
                            Nombrecomercial2 : opcionTemp ==2? NombrecomercialTemp : "No hay medicamentos",
                            Nombrecomercial3 : opcionTemp ==3? NombrecomercialTemp : "No hay medicamentos",
                        });
                        return res[0];
                    }
                };
                try {
                    fetchExpediente(row.original.idCatPaciente);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            } else {
                setRowSeleccionada(null);
                setReporteSeleccionado(null);
            }
        },
        [rowSeleccionada, pacientes]
    );

    // Se definen las columnas para la tabla de react-tabe
    // Se debe usar useMemo para optimización y porque lo pide la librería

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "idReaccionAdversa",
                Cell: ({row}) => {
                    return (
                        <input
                            // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
                            checked={rowSeleccionada === row.id ? true : false}
                            type="checkbox"
                            onChange={() => handdleCheckedReporte(row)}
                        />
                    );
                },

                disableFilters: true,
                disableSortBy: true,
            },
            {Header: "Fecha de Registro", accessor: "fechaRegistro"},
            {Header: "Paciente", accessor: "nombrePaciente"},
            {Header: "idCatPaciente", accessor: "catPaciente.idCatPaciente"},
            {Header: "Medicamento", accessor: "nombrecomercialMostrar"},
            {Header: "Medicamento", accessor: "catProducto.idCatProducto"},
            // { Header: "Síntomas", accessor: "sintomas" },
            {
                Header: "Síntomas",
                accessor: "sintomas",
                Cell: ({row}) => {
                    return <textarea disabled={true} value={row.original.sintomas}/>;
                },

                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: "Observaciones",
                accessor: "observaciones",
                Cell: ({row}) => {
                    return <textarea disabled={true} value={row.original.observaciones}/>;
                },

                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [rowSeleccionada, handdleCheckedReporte]
    );

    const medicosChangeId = medicos.map(({idCatMedico, catPersona, ...medico}) => ({
        ...medico,
        nombre: `${catPersona && catPersona.nombres ? catPersona.nombres : ""} ${
            catPersona && catPersona.apellidoPaterno ? catPersona.apellidoPaterno : ""
        } ${catPersona && catPersona.apellidoMaterno ? catPersona.apellidoMaterno : ""}`,
        id: idCatMedico,
    }));

    const pacientesChangeId = pacientes.map(({idCatPaciente, nombres, apellidoPaterno, apellidoMaterno, ...paciente}) => ({
        ...paciente,
        id: idCatPaciente,
        nombre: nombres + " " + apellidoPaterno + " " + apellidoMaterno,
    }));

    const medicamentosActivos = catProductos.map(({idCatProducto, nombreComercial, ...medicamento}) => ({
        ...medicamento,
        id: idCatProducto,
        nombre: nombreComercial,
    }));

    const onChange = (e, name) => {
        if (name && name == "idCatPaciente") {
            setPacienteCompleto(e);
            setFiltrosLocales({
                ...filtrosLocales,
                [name]: e.idCatPaciente,
            });
        } else {
            if (e.target.name === "fechaInicial") {
                setFiltrosLocales({
                    ...filtrosLocales,
                    [e.target.name]: e.target.value,
                    fechaFinal: e.target.value,
                });
            } else {
                setFiltrosLocales({
                    ...filtrosLocales,
                    [e.target.name]: e.target.value,
                });
            }

            if (e.target.name === "opcion") {
    
                if(e.target.value == 1){
                  setListaMedicamentosSeleccionado(medicamentosActivos);
                }
          
                if(e.target.value == 2){
                  setListaMedicamentosSeleccionado(medicamentosVMGlobal);
                }
          
                if(e.target.value == 3){
                  setListaMedicamentosSeleccionado(medicamentosCEGlobal);
                }
          
                setFiltrosLocales({
                  ...filtrosLocales,
                  [e.target.name]: e.target.value,
                  tipoListaMedicamento : e.target.value,
                });
              } 
        }
      };

    const inputCols = [
        {
            col: [
                {
                    colType: "input",
                    label: "Fecha Inicial",
                    type: "date",
                    name: "fechaInicial",
                    disabled: false,
                    value: filtrosLocales.fechaInicial,
                    onChange: onChange,
                },
                {
                    colType: "select",
                    label: "Paciente",
                    name: "idCatPaciente",
                    disabled: false,
                    value: filtrosLocales.idCatPaciente,
                    opciones: pacientesChangeId,
                    onChange: onChange,
                    customSelect: (
                        <SelectAsyncPaginate
                            opcionSeleccionadaCompleta={pacienteCompleto}
                            onChangeOptions={onChange}
                            fetchOptions={loadPacientesSelect}
                            opcionesPaginado={opcionesSelectPacientes.opcionesPaginado}
                            placeholderText={opcionesSelectPacientes.placeholderText}
                            optionLabel={opcionesSelectPacientes.optionLabel}
                            optionValue={opcionesSelectPacientes.optionValue}
                            name="idCatPaciente"
                        />
                    ),
                },
            ],
        },
        {
            col: [
                {
                  colType: "input",
                  label: "Fecha Final",
                  type: "date",
                  name: "fechaFinal",
                  disabled: !filtrosLocales.fechaInicial ? true : false,
                  min: filtrosLocales.fechaInicial,
                  value: filtrosLocales.fechaFinal,
                  onChange: onChange,
                },
                {
                  colType: "radio",
                  label: "",
                  name: "listaSeleccionada",
                  value: filtrosLocales.listaSeleccionada,
                  onChange: onChange,
                },  
                filtrosLocales.opcion == 3?
                {
                  colType: "selectExterno",
                  label: "Medicamento",
                  name: "idCatProducto",
                  value: filtrosLocales.idCatProducto,
                  opciones: listaMedicamentosSeleccionado,
                  onChange: onChange,
                }:
                {
                  colType: "select",
                  label: "Medicamento",
                  name: "idCatProducto",
                  value: filtrosLocales.idCatProducto,
                  opciones: listaMedicamentosSeleccionado,
                  onChange: onChange,
                }
                ,
              ],
        },
    ];
    //ACCESO AL ESTADO GLOBAL - AUTENCIACION
    const ctxAuth = useContext(AuthContext);
    const {usuario} = ctxAuth;

    // const autorizado = usuario.authorities.includes("ROLE_CENTRO_MEZCLAS");

    const autorizado =
        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
        usuario.authorities.includes("ROLE_QUIMIO") ||
        usuario.authorities.includes("ROLE_CENTRO_MEZCLAS");

    const buscarPorFiltros = async (e) => {
        e.preventDefault();
        setFiltrosReportesReacciones(filtrosReporteReacciones);
        fetchData({pageSize: 10, pageIndex: 0, byBusquedaFiltros: true});
        setIsFirstSearch(false);
    };

    const limpiarFiltros = () => {
        setPacienteCompleto(null);
        setFiltrosReportesReacciones(filtrosVacios);
        setFiltrosLocales({
            ...filtrosLocales,
            fechaInicial:  "",
            fechaFinal : "",
            idCatProducto : null,
            idCatMedico : null,
          });
      
          if(filtrosLocales.tipoListaMedicamento == 1){
            setListaMedicamentosSeleccionado(medicamentosActivos);
          }
      
          if(filtrosLocales.tipoListaMedicamento == 2){
            setListaMedicamentosSeleccionado(medicamentosVMGlobal);
          }
      
          if(filtrosLocales.tipoListaMedicamento  == 3){
            setListaMedicamentosSeleccionado(medicamentosCEGlobal);
          }
    };

    const calcEdad = (fechaNacimientoPaciente) => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const fechaNacimiento = fechaNacimientoPaciente ? fechaNacimientoPaciente.split("-") : null;
        const añoFechaNacimiento = fechaNacimiento ? fechaNacimiento[0] : null;
        return currentYear - añoFechaNacimiento;
    };

    const confirmModificarReaccion = () => {        
        if (autorizado && reporteSeleccionado) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_modificar_reporte_paciente: true,
            });
        } else {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione una reacción adversa",
            });
        }
    };

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Reacciones Adversas</h3>

            <FiltroBusqueda
                toggleSearchBy={false}
                buscarPorFiltros={buscarPorFiltros}
                onChange={onChange}
                limpiarFiltros={limpiarFiltros}
                paramsInput={inputCols}
            />
            <h4 className="flex h4 mt-5">Lista de Reacciones Adversas</h4>
            <Row>
                <Col>
                    <Card className="mt-4">
                        <CardBody>
                            <Row>
                                <Col>
                                    {autorizado ? (
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            style={{
                                                marginRight: "2.5rem",
                                                marginTop: "1rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => confirmModificarReaccion()}>
                                            Ver Reacción
                                        </button>
                                    ) : null}
                                </Col>
                            </Row>
                            <TableComponent
                                pageCount={pageCount}
                                fetchData={fetchData}
                                columns={columns}
                                loading={loadingData}
                                data={reportesReacciones}
                                indexOfPage={controlledPageIndex}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* TODO */ }
            {UISweetAlert.confirm_modificar_reporte_medicamento ? (
                <SweetAlert
                    title="Datos de la Reacción Adversa"
                    confirmBtnText="Siguiente"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Atrás"
                    cancelBtnBsStyle="danger"
                    showCancel
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_reaccion: true,
                            confirm_modificar_reporte_medicamento: false,
                        });
                    }}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_paciente: true,
                            confirm_modificar_reporte_medicamento: false,
                        });
                    }}>
                    <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                            <div>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-purple">Progreso</span>
                            </div>
                            <div className="text-right">
                                <span className="text-xs font-semibold inline-block text-purple">2/3</span>
                            </div>
                        </div>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-100">
                            <div
                                style={{width: "60%"}}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple"></div>
                        </div>
                    </div>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                
                                    <label className="col-sm-5 col-form-label"> Medicamento (Vale)</label>                                   
                                    <Col sm={7} className="pb-3">
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="presentacion"
                                            autoComplete="off"
                                            value={reporteSeleccionado.Nombrecomercial1}
                                        />
                                    </Col>

                                     <label className="col-sm-5 col-form-label">Medicamento (Venta Mostrador)</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="presentacion2"
                                            autoComplete="off"
                                            value={reporteSeleccionado.Nombrecomercial2}
                                        />
                                    </Col>                                    
                                    {/* ************************************************************** */}
                                    <label className="col-sm-5 col-form-label">Medicamento (Compra Externa)</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="presentacion3"
                                            autoComplete="off"
                                            value={reporteSeleccionado.Nombrecomercial3}
                                        />
                                    </Col>  

                                    <label className="col-sm-5 col-form-label">Presentación</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="presentacion"
                                            autoComplete="off"
                                            value={reporteSeleccionado.presentacion ? reporteSeleccionado.presentacion : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Conc. Administrada</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="concentracionAdministracion"
                                            autoComplete="off"
                                            value={
                                                reporteSeleccionado.concentracionAdministracion ? reporteSeleccionado.concentracionAdministracion : ""
                                            }
                                        />
                                    </Col>

                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                <label className="col-sm-4 col-form-label">Vía Ad.</label>
                                    <Col sm={7}>
                                        <select
                                            disabled={true}
                                            className="form-control"
                                            name="idCatTipoAdministracion"
                                            value={reporteSeleccionado.idCatTipoAdministracion}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {tiposAdministracion.map((via) => {
                                                return <option value={via.idCatTipoAdministracion}>{via.clave}</option>;
                                            })}
                                        </select>
                                    </Col>                                    
                                    <label className="col-sm-4 col-form-label">Principio Activo</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="principioActivo"
                                            autoComplete="off"
                                            value={reporteSeleccionado.principioActivo ? reporteSeleccionado.principioActivo : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Fabricante</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="laboratorio"
                                            autoComplete="off"
                                            value={reporteSeleccionado.laboratorio ? reporteSeleccionado.laboratorio : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Diluyente</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="diluyente"
                                            autoComplete="off"
                                            value={reporteSeleccionado.diluyente ? reporteSeleccionado.diluyente : "No hay diluyente"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </SweetAlert>
            ) : null}

|           {UISweetAlert.confirm_modificar_reporte_paciente ? (
            <SweetAlert
                    title="Datos de la Reacción Adversa"
                    confirmBtnText="Siguiente"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    showCancel
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_paciente: false,
                            confirm_modificar_reporte_medicamento: true,
                        });
                    }}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_paciente: false,
                        });
                    }}>
                    <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                            <div>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-purple">Progreso</span>
                            </div>
                            <div className="text-right">
                                <span className="text-xs font-semibold inline-block text-purple">1/3</span>
                            </div>
                        </div>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-100">
                            <div
                                style={{width: "30%"}}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple"></div>
                        </div>
                    </div>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-5 col-form-label">Fecha de Registro</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="fechaRegistro"
                                            autoComplete="off"
                                            value={reporteSeleccionado.fechaRegistro}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Paciente</label>
                                    <Col sm={7}>
                                        <select
                                            disabled={true}
                                            className="form-control"
                                            name="idCatPaciente"
                                            // onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.idCatPaciente}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {pacientesChangeId.map((paciente) => {
                                                return <option value={paciente.id}>{paciente.nombre}</option>;
                                            })}
                                        </select>
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Sexo</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="sexo"
                                            autoComplete="off"
                                            value={reporteSeleccionado.sexo ? reporteSeleccionado.sexo : ""}
                                        />
                                    </Col>

                                    <label className="col-sm-5 col-form-label">Médico</label>
                                    <Col sm={7}>
                                        <select className="form-control" disabled={true} name="idCatMedico" value={reporteSeleccionado.idCatMedico}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {medicosChangeId.map((medico) => {
                                                return <option value={medico.id}>{medico.nombre}</option>;
                                            })}
                                        </select>
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Tratamiento</label>
                                    <Col sm={7}>
                                    <textarea
                                        disabled={true}
                                        className="form-control mb-2"
                                        name="tratamiento"
                                        autoComplete="off"
                                        value={reporteSeleccionado.tratamiento ? reporteSeleccionado.tratamiento : ""}
                                    />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Edad</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="edad"
                                            autoComplete="off"
                                            value={reporteSeleccionado.edad ? reporteSeleccionado.edad : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Enf. Crónico Degenerativas</label>
                                    <Col sm={7}>
                                    <textarea
                                        disabled={true}
                                        type="text"
                                        className="form-control mb-2"
                                        name="cronicoDegenerativos"
                                        autoComplete="off"
                                        value={reporteSeleccionado.cronicoDegenerativos ? reporteSeleccionado.cronicoDegenerativos : ""}
                                    />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Diagnóstico</label>
                                    <Col sm={7}>
                                    <textarea
                                        disabled={true}
                                        className="form-control mb-2"
                                        name="diagnostico"
                                        autoComplete="off"
                                        value={reporteSeleccionado.diagnostico ? reporteSeleccionado.diagnostico : ""}
                                    />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Ciclo</label>
                                    <Col sm={7}>
                            <textarea
                                disabled={true}
                                className="form-control"
                                name="ciclo"
                                autoComplete="off"
                                value={reporteSeleccionado.ciclo ? reporteSeleccionado.ciclo : ""}
                            />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_modificar_reporte_reaccion ? (
                <SweetAlert
                    title="Datos de la Reacción Adversa PRUEBA"
                    confirmBtnText="Cerrar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Atrás"
                    cancelBtnBsStyle="danger"
                    showCancel
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_reaccion: false,
                        });
                    }}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_medicamento: true,
                            confirm_modificar_reporte_reaccion: false,
                        });
                    }}>
                    <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                            <div>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-purple">Progreso</span>
                            </div>
                            <div className="text-right">
                                <span className="text-xs font-semibold inline-block text-purple">3/3</span>
                            </div>
                        </div>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-100">
                            <div
                                style={{width: "100%"}}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple"></div>
                        </div>
                    </div>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-5 col-form-label">Síntomas</label>
                                    <Col sm={7}>
                                    <textarea
                                        type="text"
                                        className="form-control mb-2"
                                        name="sintomas"
                                        autoComplete="off"
                                        disabled={true}
                                        value={reporteSeleccionado.sintomas ? reporteSeleccionado.sintomas : ""}
                                    />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Resultado de REA</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatResultadoRea"
                                            disabled={true}
                                            value={reporteSeleccionado.idCatResultadoRea}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {resultadosRea.map((opcion) => {
                                                return <option value={opcion.idCatResultadoRea}>{opcion.resultadoRea}</option>;
                                            })}
                                        </select>
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Reporte</label>
                                    <Col sm={7}>
                                    <textarea
                                        type="text"
                                        className="form-control mb-2"
                                        name="reporte"
                                        autoComplete="off"
                                        disabled={true}
                                        value={reporteSeleccionado.reporte ? reporteSeleccionado.reporte : ""}
                                    />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Observaciones</label>
                                    <Col sm={7}>
                                    <textarea
                                        type="text"
                                        className="form-control mb-2"
                                        name="observaciones"
                                        autoComplete="off"
                                        disabled={true}
                                        value={reporteSeleccionado.observaciones ? reporteSeleccionado.observaciones : ""}
                                    />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-5 col-form-label">Duración</label>
                                    <Col sm={7}>
                                    <textarea
                                        type="text"
                                        className="form-control mb-2"
                                        name="duracion"
                                        autoComplete="off"
                                        disabled={true}
                                        value={reporteSeleccionado.duracion ? reporteSeleccionado.duracion : ""}
                                    />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">SRAM</label>
                                    <Col sm={7}>
                                    <textarea
                                        type="text"
                                        className="form-control mb-2"
                                        name="medicamentoControlRea"
                                        autoComplete="off"
                                        disabled={true}
                                        value={reporteSeleccionado.medicamentoControlRea ? reporteSeleccionado.medicamentoControlRea : ""}
                                    />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Premedicación</label>
                                    <Col sm={7}>
                                    <textarea
                                        type="text"
                                        className="form-control mb-2"
                                        name="premedicacion"
                                        autoComplete="off"
                                        disabled={true}
                                        value={reporteSeleccionado.premedicacion ? reporteSeleccionado.premedicacion : ""}
                                    />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.reload_page) {
                            window.location.reload();
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                            reload_page: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}
            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}
            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
};

export default ReporteReaccionesPaginadas;
