import React, { useReducer } from "react";

import LugaresContext from "./LugaresContext";
import LugaresReducer from "./LugaresReducer";

import { LISTAR_LUGARES } from "../../types";

import clienteAxios from "../../config/axios";

export default function LugaresState(props) {
  const initialState = {
    lugares: [],
  };

  const [state, dispatch] = useReducer(LugaresReducer, initialState);

  const listarLugares = async () => {
    const res = await clienteAxios.get("/lugares/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_LUGARES,
      payload: res.data,
    });
  };

  return (
    <LugaresContext.Provider
      value={{
        lugares: state.lugares,
        listarLugares,
      }}>
      {props.children}
    </LugaresContext.Provider>
  );
}
