import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import CatTipoRecetaContext from "../../context/CatTipoReceta/CatTipoRecetaContext";
import RecetaProductoContext from "../../context/RecetaProducto/RecetaProductoContext";
import RecetaExpedienteContext from "../../context/RecetaExpediente/RecetaExpedienteContext";

import RecetaMedicamento from "./RecetaMedicamento";
import RecetaNormal from "./RecetaNormal";
import TableComponent from "../TablaPaginada/index";

import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";

import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import SexosContext from "../../context/Sexos/SexosContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";

import DrawerExpedientePaciente from "../DrawerExpedientePaciente/index";

const RecetaExpediente = (props) => {
  const { idConsultaSeguimiento } = useParams();

  const [idCatMedico, setIdCatMedico] = useState(null);

  const [imprimirDiagnostico, setImprimirDiagnostico] = useState(false);

  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;
  const autorizado = usuario.authorities.includes("ROLE_MEDICO");

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado } = pacientesContext;

  const catTipoRecetaContext = useContext(CatTipoRecetaContext);
  const { tiposRecetas, listarTiposRecetas } = catTipoRecetaContext;

  const recetaProductoContext = useContext(RecetaProductoContext);
  const { listarRecetaProductos, recetaProductos } = recetaProductoContext;

  const recetaExpedienteContext = useContext(RecetaExpedienteContext);
  const { uploadReceta, getMedicoByConsultaSeguimiento, exportReceta, recetas, setRecetasPaginadas } = recetaExpedienteContext;

  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }, []);

  useEffect(() => {
    const fetchTiposRecetas = async () => {
      try {
        await listarTiposRecetas();
      } catch (err) {}
    };

    fetchTiposRecetas();
    // if (tiposRecetas.length <= 0) return listarTiposRecetas();
  }, []);

  useEffect(() => {
    const fetchMedico = async () => {
      try {
        const medico = await getMedicoByConsultaSeguimiento(idConsultaSeguimiento);

        setIdCatMedico(medico);
        return medico;
      } catch (err) {}
    };

    fetchMedico()
      .then((medico) => {
        listarRecetaProductos(medico);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [idConsultaSeguimiento]);

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  useEffect(() => {
    const fetchAll = () => {
      listarSexos();
      listarAseguradoras();
      listarEscolaridades();
      listarEstadosCiviles();
      listarLugares();
      listarOcupaciones();
      listarMedicos();
    };
    fetchAll();
  }, []);

  const [loadingData, setLoadingData] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setRecetasPaginadas({ page, limit, idConsultaSeguimiento });

      // Cantidad de datos disponibles en la tabla pacientes de la base de datos
      const count = response.count;

      setRowSeleccionada(null);
      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }
      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    console.log("fetchData is being called");
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server.
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const handleReceta = async (row) => {
    if (row.idReceta !== null) {
      setLoading(true);
      const res = await exportReceta({ idReceta: row.idReceta, imprimirDiagnostico: imprimirDiagnostico });
      if (res.data && res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
        return res;
      } else if (res) {
        setLoading(false);
        return res;
      }
    }
  };

  const columns = useMemo(
    () => [
      { Header: "Fecha de Registro", accessor: "fechaReceta" },

      { Header: "Tipo de Receta", accessor: "catTipoRecetaVo.tipoReceta" },

      {
        Header: "Indicaciones",
        accessor: "observaciones",
        Cell: ({ row }) => {
          return <textarea disabled={true} value={row.original.indicaciones} />;
        },

        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Imprimir",
        Cell: ({ row }) => {
          return (
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              onClick={() => handleReceta(row.original)}>
              Imprimir
            </button>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [imprimirDiagnostico]
  );

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  // today = yyyy + "-" + mm + "-" + dd;
  today = dd + "/" + mm + "/" + yyyy;

  const recetaNormalVacia = {
    fecha: today,
    indicaciones: null,
  };

  const recetaMedicamentosVacia = {
    fecha: today,
    indicaciones: null,
  };

  const principiosActivos = recetaProductos ? recetaProductos.map((med) => med.principioActivo) : null;
  const principiosActivosFiltered = principiosActivos ? [...new Set(principiosActivos)] : [];

  const presentaciones = recetaProductos ? recetaProductos.map((med) => med.presentacion) : null;
  const presentacionesFiltered = presentaciones ? [...new Set(presentaciones)] : [];

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_guardar_receta: false,
    confirm_guardar_imprimir_receta: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [medicamentosReceta, setMedicamentosReceta] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tipoReceta, setTipoReceta] = useState(null);
  const [nuevaRecetaNormal, setNuevaRecetaNormal] = useState(recetaNormalVacia);
  const [nuevaRecetaConMedicamentos, setNuevaRecetaConMedicamentos] = useState(recetaMedicamentosVacia);

  const changeTipoReceta = (e) => {
    const { value } = e.target;
    setTipoReceta(value);
  };

  const onChangeRecetaNormal = (e) => {
    const { name, value } = e.target;
    setNuevaRecetaNormal({
      ...nuevaRecetaNormal,
      [name]: value,
    });
  };

  const validarDatosRecetaNormal = async () => {
    let errorNuevaReceta = false;
    if (!errorNuevaReceta && (nuevaRecetaNormal.indicaciones === "" || nuevaRecetaNormal.indicaciones === null)) {
      errorNuevaReceta = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese las indicaciones de la receta.",
      });
    }

    return errorNuevaReceta;
  };

  const validarDatosRecetaConMedicamentos = async () => {
    let errorNuevaReceta = false;
    if (!errorNuevaReceta && medicamentosReceta.length === 0) {
      errorNuevaReceta = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese al menos un medicamento para la receta.",
      });
    }

    if (!errorNuevaReceta && medicamentosReceta.length > 0) {
      medicamentosReceta.forEach((medicamento, index) => {
        if (
          // medicamento.nombreComercial === "" ||
          // medicamento.nombreComercial === null ||
          medicamento.principioActivo === "" ||
          medicamento.principioActivo === null ||
          medicamento.presentacion === "" ||
          medicamento.presentacion === null ||
          medicamento.indicaciones === "" ||
          medicamento.indicaciones === null
        ) {
          errorNuevaReceta = true;
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, ingrese todos los datos para cada medicamento.`,
          });
        }
      });
    }
    if (
      !errorNuevaReceta &&
      (nuevaRecetaConMedicamentos.indicaciones === "" || nuevaRecetaConMedicamentos.indicaciones === null)
    ) {
      errorNuevaReceta = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese las indicaciones adicionales para la receta.",
      });
    }
    return errorNuevaReceta;
  };

  const checkGuardarReceta = async () => {
    let errorNuevaReceta = true;
    if (tipoReceta == 1) {
      errorNuevaReceta = await validarDatosRecetaNormal();
      if (autorizado && !errorNuevaReceta)
        return setUISweetAlert({
          ...UISweetAlert,
          confirm_guardar_receta: true,
        });
    } else if (tipoReceta == 2) {
      errorNuevaReceta = await validarDatosRecetaConMedicamentos();
      if (autorizado && !errorNuevaReceta)
        return setUISweetAlert({
          ...UISweetAlert,
          confirm_guardar_receta: true,
        });
    }
  };

  const checkImprimirReceta = async () => {
    let errorNuevaReceta = true;
    if (tipoReceta == 1) {
      errorNuevaReceta = await validarDatosRecetaNormal();
      if (autorizado && !errorNuevaReceta)
        return setUISweetAlert({
          ...UISweetAlert,
          confirm_guardar_imprimir_receta: true,
        });
    } else if (tipoReceta == 2) {
      errorNuevaReceta = await validarDatosRecetaConMedicamentos();
      if (autorizado && !errorNuevaReceta)
        return setUISweetAlert({
          ...UISweetAlert,
          confirm_guardar_imprimir_receta: true,
        });
    }
  };

  const checkMatchedMedicamentos = async (medicamentosSinIdFiltered) => {
    let medicamentosSinIdFilteredCopy = medicamentosSinIdFiltered;
    let medicamentosMatched = [];
    let medNoMatched;
    medicamentosSinIdFiltered.forEach((medSinId, index) => {
      recetaProductos.every((medGuardadoBase) => {
        if (
          medSinId.nombreComercial.toLowerCase().normalize() === medGuardadoBase.nombreComercial.toLowerCase().normalize() &&
          medSinId.principioActivo.toLowerCase().normalize() === medGuardadoBase.principioActivo.toLowerCase().normalize() &&
          medSinId.presentacion.toLowerCase().normalize() === medGuardadoBase.presentacion.toLowerCase().normalize()
        ) {
          medicamentosMatched.push({ ...medSinId, idRecetaProducto: medGuardadoBase.idRecetaProducto });
          medicamentosSinIdFilteredCopy[index] = null;
          return false;
        }

        return true;
      });
    });

    medNoMatched = medicamentosSinIdFilteredCopy.filter((med) => med !== null);
    return [medicamentosMatched, medNoMatched];
  };

  const filterAllMedicamentos = async () => {
    const medicamentosSinIdFiltered = medicamentosReceta.filter((med) => med.idRecetaProducto === null);
    const medicamentosConIdFiltered = medicamentosReceta.filter((med) => med.idRecetaProducto !== null);
    let medicamentosWithMatch = [];
    let medicamentosNoMatch = [];
    if (medicamentosSinIdFiltered.length > 0) {
      const medFiltrados = await checkMatchedMedicamentos(medicamentosSinIdFiltered);
      medicamentosWithMatch = medFiltrados[0];
      medicamentosNoMatch = medFiltrados[1];
    }
    const allMedicamentosConId = [...medicamentosWithMatch, ...medicamentosConIdFiltered];
    const allMedicamentos = {
      medicamentosConId: allMedicamentosConId.length > 0 ? allMedicamentosConId : null,
      medicamentosSinId: medicamentosNoMatch.length > 0 ? medicamentosNoMatch : null,
    };

    if (allMedicamentos.medicamentosConId && allMedicamentos.medicamentosConId.length > 0)
      allMedicamentos.medicamentosConId.map((med) => (med.idCatMedico = idCatMedico));
    if (allMedicamentos.medicamentosSinId && allMedicamentos.medicamentosSinId.length > 0)
      allMedicamentos.medicamentosSinId.map((med) => (med.idCatMedico = idCatMedico));

    if (allMedicamentos.medicamentosConId === null) {
      return {
        ...nuevaRecetaConMedicamentos,
        idCatTipoReceta: tipoReceta,
        recetaProductosVo: allMedicamentos.medicamentosSinId,
        idConsultaSeguimiento: idConsultaSeguimiento,
      };
    } else if (allMedicamentos.medicamentosSinId === null) {
      return {
        ...nuevaRecetaConMedicamentos,
        idCatTipoReceta: tipoReceta,
        recetaProductosVo: allMedicamentos.medicamentosConId,
        idConsultaSeguimiento: idConsultaSeguimiento,
      };
    } else {
      return {
        ...nuevaRecetaConMedicamentos,
        idCatTipoReceta: tipoReceta,
        recetaProductosVo: [...allMedicamentos.medicamentosConId, ...allMedicamentos.medicamentosSinId],
        idConsultaSeguimiento: idConsultaSeguimiento,
      };
    }
  };

  const guardarReceta = async () => {
    if (tipoReceta == 1) {
      setLoading(true);
      const res = await uploadReceta({
        ...nuevaRecetaNormal,
        idCatTipoReceta: tipoReceta,
        idConsultaSeguimiento: idConsultaSeguimiento,
      });
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_guardar_receta: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
        return res;
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_guardar_receta: false,
          dynamic_description: "Se guardó la receta correctamente",
        });
        setNuevaRecetaNormal(recetaNormalVacia);
        fetchData({ pageSize: 10, pageIndex: 0, byBusquedaFiltros: true });
        return res;
      }
    } else if (tipoReceta == 2) {
      setLoading(true);

      try {
        const recetaVo = await filterAllMedicamentos();
        const res = await uploadReceta({ ...recetaVo, idConsultaSeguimiento: idConsultaSeguimiento });
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_guardar_receta: false,
          dynamic_description: "Se guardó la receta correctamente",
        });
        setNuevaRecetaConMedicamentos(recetaMedicamentosVacia);
        setMedicamentosReceta([]);
        listarRecetaProductos(idCatMedico);
        fetchData({ pageSize: 10, pageIndex: 0, byBusquedaFiltros: true });
        return res;
      } catch (error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_guardar_receta: false,
          dynamic_title: "Fallo",
          dynamic_description: error,
        });
      }
    }
  };

  const imprimirReceta = async () => {
    const response = await guardarReceta();
    const receta = response.data;

    if (receta.idReceta !== null) {
      setLoading(true);
      const res = await exportReceta({ ...receta, imprimirDiagnostico: imprimirDiagnostico });
      if (res.data && res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_guardar_imprimir_receta: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
        return res;
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_guardar_imprimir_receta: false,
          dynamic_description: "Se imprimió la receta correctamente",
        });

        return res;
      }
    }
  };

  const nuevoRenglon = () => {
    const nuevoMedicamento = {
      idRecetaProducto: null,
      principioActivo: null,
      nombreComercial: "",
      presentacion: null,
      indicaciones: null,
    };
    setMedicamentosReceta([...medicamentosReceta, nuevoMedicamento]);
  };

  // const checkPrincipioActivo = (name, value, index) => {
  //   setMedicamentosReceta((state) => {
  //     const medicamentosActualizados = state.map((medicamento, i) => {
  //       if (i === index) {
  //         return {
  //           ...medicamento,
  //           [name]: value.toUpperCase(),
  //           idRecetaProducto: null,
  //         };
  //       } else {
  //         return medicamento;
  //       }
  //     });
  //     return medicamentosActualizados;
  //   });
  // };

  const checkPrincipioActivo = (name, value, index) => {
    const med = recetaProductos
      ? recetaProductos.filter((pa, index) => {
          if (pa.principioActivo !== null) {
            // return pa.principioActivo.toLowerCase().normalize() === principioActivo.toLowerCase().normalize() && indexSelected == index;
            return (index + " " + pa.principioActivo).toLowerCase().normalize() === value.toLowerCase().normalize();
          }
        })
      : [];

    if (med.length === 1) {
      setMedicamentosReceta((state) => {
        const medicamentosActualizados = state.map((medicamento, i) => {
          if (i === index) {
            let principioActivoSeparado = value.split(" ");
            principioActivoSeparado.shift();
            const principioActivo = principioActivoSeparado.join(" ");
            return {
              ...medicamento,
              [name]: principioActivo.toUpperCase(),
              idRecetaProducto: med[0].idRecetaProducto,
              nombreComercial: med[0].nombreComercial,
              presentacion: med[0].presentacion,
            };
          } else {
            return medicamento;
          }
        });
        return medicamentosActualizados;
      });
    } else {
      setMedicamentosReceta((state) => {
        const medicamentosActualizados = state.map((medicamento, i) => {
          if (i === index) {
            return {
              ...medicamento,
              [name]: value.toUpperCase(),
              idRecetaProducto: null,
            };
          } else {
            return medicamento;
          }
        });
        return medicamentosActualizados;
      });
    }
  };
  const checkNombreComercial = (name, value, index) => {
    const med = recetaProductos
      ? recetaProductos.filter((pa, index) => {
          if (pa.nombreComercial !== null) {
            // return pa.principioActivo.toLowerCase().normalize() === principioActivo.toLowerCase().normalize() && indexSelected == index;
            return (index + " " + pa.nombreComercial).toLowerCase().normalize() === value.toLowerCase().normalize();
          }
        })
      : [];

    if (med.length === 1) {
      setMedicamentosReceta((state) => {
        const medicamentosActualizados = state.map((medicamento, i) => {
          if (i === index) {
            let nombreComercialSeparado = value.split(" ");
            nombreComercialSeparado.shift();
            const nombreComercial = nombreComercialSeparado.join(" ");
            return {
              ...medicamento,
              [name]: nombreComercial.toUpperCase(),
              idRecetaProducto: med[0].idRecetaProducto,
              principioActivo: med[0].principioActivo,
              presentacion: med[0].presentacion,
            };
          } else {
            return medicamento;
          }
        });
        return medicamentosActualizados;
      });
    } else {
      setMedicamentosReceta((state) => {
        const medicamentosActualizados = state.map((medicamento, i) => {
          if (i === index) {
            return {
              ...medicamento,
              [name]: value.toUpperCase(),
              idRecetaProducto: null,
            };
          } else {
            return medicamento;
          }
        });
        return medicamentosActualizados;
      });
    }
  };

  const checkPresentacion = (name, value, index) => {
    setMedicamentosReceta((state) => {
      const medicamentosActualizados = state.map((medicamento, i) => {
        if (i === index) {
          return {
            ...medicamento,
            [name]: value.toUpperCase(),
            idRecetaProducto: null,
          };
        } else {
          return medicamento;
        }
      });
      return medicamentosActualizados;
    });
  };

  const changeMedicamentoLista = (e, index) => {
    const { name, value } = e.target;

    if (name === "principioActivo") {
      checkPrincipioActivo(name, value, index);
      return;
    }
    if (name === "nombreComercial") {
      checkNombreComercial(name, value, index);
      return;
    }
    if (name === "presentacion") {
      checkPresentacion(name, value, index);
      return;
    }

    setMedicamentosReceta((state) => {
      const medicamentosActualizados = state.map((medicamento, i) => {
        if (i === index) {
          return { ...medicamento, [name]: value };
        } else {
          return medicamento;
        }
      });

      return medicamentosActualizados;
    });
  };

  const changeIndicaciones = (e) => {
    const { name, value } = e.target;
    setNuevaRecetaConMedicamentos({ ...nuevaRecetaConMedicamentos, [name]: value });
  };

  const eliminarMedicamento = (index) => {
    const listaMedicamentosFiltrados = medicamentosReceta.filter((medicamento, i) => index !== i);
    setMedicamentosReceta(listaMedicamentosFiltrados);
  };

  const redirectConsultaSeguimiento = async () => {
    props.history.push("/consulta/seguimiento");
  };

  const changeImprimirDiagnostico = (e) => {
    setImprimirDiagnostico(e.target.checked);
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Recetas y Solicitudes</h3>
      <DrawerExpedientePaciente
        historiaClinicaInicial={historiaClinicaInicial}
        sexos={sexos}
        aseguradoras={aseguradoras}
        medicos={medicos}
        lugares={lugares}
        ocupaciones={ocupaciones}
        estadosCiviles={estadosCiviles}
        escolaridades={escolaridades}
      />
      {/* {historiaClinicaInicial ? (
        <DrawerExpedientePaciente
          historiaClinicaInicial={historiaClinicaInicial}
          sexos={sexos}
          aseguradoras={aseguradoras}
          medicos={medicos}
          lugares={lugares}
          ocupaciones={ocupaciones}
          estadosCiviles={estadosCiviles}
          escolaridades={escolaridades}
        />
      ) : null} */}
      <Row className="mt-4">
        <Col>
          <button
            className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
            type="button"
            onClick={redirectConsultaSeguimiento}>
            Consulta de Seguimiento o Evolución
          </button>
        </Col>
      </Row>

      <Row>
        <Col>
          {autorizado ? (
            <>
              <div>
                <Row>
                  <Col>
                    <h5 className="flex mt-4 mb-3">
                      Por favor, elige el tipo de receta o solicitud que quieras crear
                      <span className="text-xs text-red-700">*</span>
                    </h5>
                    <select className="form-control" name="tipoReceta" onChange={(e) => changeTipoReceta(e)} value={tipoReceta}>
                      <option disabled selected value="">
                        Seleccione una opción
                      </option>

                      {tiposRecetas &&
                        tiposRecetas.map((opcion) => {
                          return <option value={opcion.idCatTipoReceta}>{opcion.tipoReceta}</option>;
                        })}
                    </select>
                  </Col>
                  <Col>
                    <h5 className="flex mt-4 mb-3">¿Quieres imprimir las recetas con el diagnóstico?</h5>

                    <input
                      type="checkbox"
                      name="imprimirDiagnostico"
                      className="form-control"
                      onChange={changeImprimirDiagnostico}
                      checked={imprimirDiagnostico}
                    />
                  </Col>
                </Row>
              </div>
            </>
          ) : null}
        </Col>
      </Row>
      <div className="mt-5 ml-1">
        {tipoReceta && tipoReceta == 1 ? (
          <RecetaNormal
            nuevaReceta={nuevaRecetaNormal}
            onChangeRecetaNormal={onChangeRecetaNormal}
            checkGuardarReceta={checkGuardarReceta}
            checkImprimirReceta={checkImprimirReceta}
          />
        ) : null}
        {tipoReceta && tipoReceta == 2 ? (
          <RecetaMedicamento
            medicamentosReceta={medicamentosReceta}
            nuevoRenglon={nuevoRenglon}
            changeMedicamentoLista={changeMedicamentoLista}
            eliminarMedicamento={eliminarMedicamento}
            medicamentos={recetaProductos ? recetaProductos : []}
            principiosActivos={principiosActivosFiltered}
            presentaciones={presentacionesFiltered}
            checkGuardarReceta={checkGuardarReceta}
            checkImprimirReceta={checkImprimirReceta}
            nuevaRecetaConMedicamentos={nuevaRecetaConMedicamentos}
            changeIndicaciones={changeIndicaciones}
          />
        ) : null}
      </div>
      <Row>
        <Col>
          <TableComponent
            pageCount={pageCount}
            fetchData={fetchData}
            columns={columns}
            loading={loadingData}
            data={recetas}
            indexOfPage={controlledPageIndex}
          />
        </Col>
      </Row>
      {UISweetAlert.confirm_guardar_receta ? (
        <SweetAlert
          title="¿Desea guardar la receta?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => guardarReceta()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_guardar_receta: false,
            });
          }}></SweetAlert>
      ) : null}
      {UISweetAlert.confirm_guardar_imprimir_receta ? (
        <SweetAlert
          title="¿Desea imprimir la receta?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => imprimirReceta()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_guardar_imprimir_receta: false,
            });
          }}></SweetAlert>
      ) : null}
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default RecetaExpediente;
