import React, { useContext, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import PacientesContext from "../../../context/Pacientes/PacientesContext";
import AuthContext from "../../../context/Auth/AuthContext";
import EstudiosLaboratorioContext from "../../../context/EstudiosLaboratorio/EstudiosLaboratorioContext";
import ExpedienteMedicoContext from "../../../context/ExpedienteMedico/ExpedienteMedicoContext";
import HistoriaClinicaInicialContext from "../../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";

import useWindowDimensions from "../../../hooks/dimensionsHook";
import formatDateWithoutHour from "../../../utils/formatDateWithoutHour";

export default function TabListadoEstudiosLaboratorio({ props, idExpedienteMedico }) {
  const { width } = useWindowDimensions();
  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);
  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }, []);

  const expedienteMedicoContext = useContext(ExpedienteMedicoContext);
  const { exportArchivoBack } = expedienteMedicoContext;

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado } = pacientesContext;

  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const ctxEstLab = useContext(EstudiosLaboratorioContext);
  const { listarEstudiosLaboratorio, uploadNuevoEstudioLaboratorio, estudiosLaboratorio, listarMarcadores } = ctxEstLab;

  const cleanNuevoEstudioLaboratorio = {
    idCatPaciente: idCatPacienteSeleccionado,
  };

  const [estudioLaboratorioSeleccionado, setEstudioLaboratorioSeleccionado] = useState(cleanNuevoEstudioLaboratorio);
  const [marcadorSeleccionado, setMarcadorSeleccionado] = useState(null);
  // const [listado, setListado] = useState(false);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_estudio_laboratorio: false,
    nuevoEstudio: true,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  const [loading, setLoading] = useState(false);

  const uploadFile = async (e) => {
    const files = e.target.files;
    if (!estudioLaboratorioSeleccionado.archivos) {
      estudioLaboratorioSeleccionado.archivos = [];
    }

    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      let nuevoArchivo;

      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }

      reader.addEventListener(
        "load",
        function () {
          let strFile = reader.result;
          let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
          let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);

          nuevoArchivo = {
            nombreArchivo: file.name,
            mimeType: type,
            tamano: file.size,
            inputStream: fileBase64,
          };
        },
        false
      );
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          const archivoTempNuevo = { ...nuevoArchivo, width: width, height: height };
          nuevoArchivo = archivoTempNuevo;
          estudioLaboratorioSeleccionado.archivos = estudioLaboratorioSeleccionado.archivos.concat(nuevoArchivo);
          setEstudioLaboratorioSeleccionado(estudioLaboratorioSeleccionado);
          return true;
        };
      };
    }
  };

  const limpiarArchivos = async () => {
    estudioLaboratorioSeleccionado.archivos = [];
    setEstudioLaboratorioSeleccionado(estudioLaboratorioSeleccionado);
  };

  const onChangeEstudioLaboratorio = async (e) => {
    setEstudioLaboratorioSeleccionado({
      ...estudioLaboratorioSeleccionado,
      [e.target.name]: e.target.value,
    });
  };

  // const nuevoMarcador = async () => {
  //   let nuevoMarc = {
  //     marcador: "",
  //     valor: "",
  //   };

  //   if (!estudioLaboratorioSeleccionado.estudiosLaboratorioMarcador) {
  //     estudioLaboratorioSeleccionado.estudiosLaboratorioMarcador = [];
  //     setEstudioLaboratorioSeleccionado(estudioLaboratorioSeleccionado);
  //   }

  //   estudioLaboratorioSeleccionado.estudiosLaboratorioMarcador =
  //     estudioLaboratorioSeleccionado.estudiosLaboratorioMarcador.concat(nuevoMarc);
  //   setEstudioLaboratorioSeleccionado(estudioLaboratorioSeleccionado);

  //   refreshModal();
  // };

  const refreshModal = async () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_estudio_laboratorio: false,
    });
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_estudio_laboratorio: true,
    });
  };

  const changeMarcador = async (marcador, e) => {
    marcador.marcador = e.target.value;
    setEstudioLaboratorioSeleccionado(estudioLaboratorioSeleccionado);
    refreshModal();
  };

  const changeValor = async (marcador, e) => {
    marcador.valor = e.target.value;
    setEstudioLaboratorioSeleccionado(estudioLaboratorioSeleccionado);
    refreshModal();
  };

  const uploadNuevoEstudioLaboratorioConfirm = async () => {
    let error = false;

    if (!estudioLaboratorioSeleccionado.fechaToma && !error) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca la fecha de toma para el nuevo estudio.",
      });
      error = true;
      return;
    }
    if (!estudioLaboratorioSeleccionado.comentario && !error) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el comentario para el nuevo estudio.",
      });
      error = true;
      return;
    }

    if (!error) {
      setLoading(true);
      console.log(estudioLaboratorioSeleccionado);
      const res = await uploadNuevoEstudioLaboratorio({
        ...estudioLaboratorioSeleccionado,
        idCatMedico: usuario.idCatMedico,
        idCatTipoEstudioLaboratorio: 2,
        idCatPaciente: idCatPacienteSeleccionado,
        idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      });
      if (res.data.error) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          editar: false,
          dynamic_title: "Exito",
          confirm_alert_estudio_laboratorio: false,
          dynamic_description: "Se guardó de manera correcta la información.",
        });
        listarEstudiosLaboratorio(idCatPacienteSeleccionado, idExpedienteMedico);
        // listarMarcadores(idCatPacienteSeleccionado);
      }
    }
  };

  const eliminarMarcador = async () => {
    estudioLaboratorioSeleccionado.estudiosLaboratorioMarcador =
      estudioLaboratorioSeleccionado.estudiosLaboratorioMarcador.filter((marc) => marc != marcadorSeleccionado);
    setEstudioLaboratorioSeleccionado(estudioLaboratorioSeleccionado);

    refreshModal();

    setMarcadorSeleccionado(null);
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_eliminar_marcador: false,
    });
  };

  const nuevoEstudioLabo = async () => {
    // let marcadoresArr = [];
    // marcadores.map((marcador) => {
    //   let nuevoMarc = {
    //     marcador: marcador.marcador,
    //     valor: "",
    //   };
    //   marcadoresArr = marcadoresArr.concat(nuevoMarc);
    // });

    // setEstudioLaboratorioSeleccionado({
    //   ...cleanNuevoEstudioLaboratorio,
    //   estudiosLaboratorioMarcador: marcadoresArr,
    // });

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_estudio_laboratorio: true,
      nuevoEstudio: true,
    });
  };

  const exportArchivo = async (archivo) => {
    // convert image file to base64 string

    const archivoVo = {
      rutaArchivo: archivo.rutaArchivo,
      nombreArchivo: archivo.nombreArchivo,
      mimeType: archivo.mimeType,
    };
    const res = await exportArchivoBack(archivoVo);
    console.log("🚀 ~ file: index.js:269 ~ exportArchivo ~ res", res);
    return res;
  };

  useEffect(() => {
    listarEstudiosLaboratorio(idCatPacienteSeleccionado, idExpedienteMedico);
    listarMarcadores(idCatPacienteSeleccionado);
    // if (!listado) {
    //   listarEstudiosLaboratorio(idCatPacienteSeleccionado, idExpedienteMedico);
    //   listarMarcadores(idCatPacienteSeleccionado);
    //   setListado(true);
    // }
  }, []);

  return (
    <div className="mt-4 container-fluid">
      <Row>
        <Col>
          <h4>Listado de Estudios de Imagen</h4>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <button
            className="btn btn-primary-orange-umo w-md waves-effect waves-light"
            type="button"
            onClick={() => {
              nuevoEstudioLabo();
            }}>
            Nuevo Estudio
          </button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <table className="table table-hover table-centered table-nowrap mb-0">
            <thead>
              <tr>
                <th scope="col">
                  Fecha de<br></br>Registro
                </th>
                <th scope="col">
                  Fecha de<br></br>Toma
                </th>
                <th scope="col">Detalles</th>
              </tr>
            </thead>
            <tbody>
              {estudiosLaboratorio &&
                estudiosLaboratorio.map((estudioLab) => {
                  if (estudioLab.idCatTipoEstudioLaboratorio == 2) {
                    return (
                      <tr key={estudioLab.idEstudioLaboratorio}>
                        <td>
                            {estudioLab.fechaRegistro ? formatDateWithoutHour(estudioLab.fechaRegistro) : ""}
                        </td>
                        <td>
                            {estudioLab.fechaToma ? formatDateWithoutHour(estudioLab.fechaToma) : ""}
                        </td>
                        <td>
                          <button
                            className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                            type="button"
                            onClick={() => {
                              setEstudioLaboratorioSeleccionado(estudioLab);
                              setUISweetAlert({
                                ...UISweetAlert,
                                confirm_alert_estudio_laboratorio: true,
                                nuevoEstudio: false,
                              });
                            }}>
                            Detalles
                          </button>
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        </Col>
      </Row>

      {UISweetAlert.confirm_alert_estudio_laboratorio ? (
        <SweetAlert
          title={UISweetAlert.nuevoEstudio ? "Nuevo Estudio de Imagen" : "Información del Estudio de Imagen"}
          warning
          showCancel
          showConfirm={UISweetAlert.nuevoEstudio}
          confirmBtnText="Guardar"
          confirmBtnBsStyle="success"
          cancelBtnText={UISweetAlert.nuevoEstudio ? "Cancelar" : "Cerrar"}
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "70%",
          }}
          onConfirm={() => uploadNuevoEstudioLaboratorioConfirm()}
          onCancel={() => {
            setEstudioLaboratorioSeleccionado(cleanNuevoEstudioLaboratorio);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_estudio_laboratorio: false,
            });
          }}>
          <div>
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">
                Fecha de Toma<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7} className="pb-3">
                <input
                  type="date"
                  className="form-control"
                  name="fechaToma"
                  disabled={!UISweetAlert.nuevoEstudio}
                  onChange={onChangeEstudioLaboratorio}
                  value={estudioLaboratorioSeleccionado.fechaToma ? formatDateWithoutHour(estudioLaboratorioSeleccionado.fechaToma) : ""}
                />
                {estudioLaboratorioSeleccionado.fechaToma == null && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
              <label className="col-sm-4 col-form-label">
                Comentarios<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7} className="pb-3">
                <textarea
                  type="text"
                  className="form-control"
                  name="comentario"
                  disabled={!UISweetAlert.nuevoEstudio}
                  onChange={onChangeEstudioLaboratorio}
                  value={estudioLaboratorioSeleccionado.comentario ? estudioLaboratorioSeleccionado.comentario : ""}
                />
                {(estudioLaboratorioSeleccionado.comentario == "" || estudioLaboratorioSeleccionado.comentario == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
            </Row>
            {UISweetAlert.nuevoEstudio ? (
              <Row className="form-group mt-3">
                <label className="col-sm-4 col-form-label">Documentos</label>
                <Col sm={7}>
                  <input
                    className="form-control"
                    type="file"
                    onClick={limpiarArchivos}
                    onChange={(e) => uploadFile(e)}
                    multiple
                    accept="image/*"
                  />
                </Col>
              </Row>
            ) : (
              <>
                <Row hidden={!estudioLaboratorioSeleccionado.archivos || estudioLaboratorioSeleccionado.archivos.length < 1}>
                  <Col>
                    <label>Documentos</label>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col>
                    {estudioLaboratorioSeleccionado.archivos.map((archivo) => {
                      return (
                        <button
                          className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-2 mt-2"
                          type="button"
                          onClick={() => exportArchivo(archivo)}>
                          {archivo.nombreArchivo}
                        </button>
                      );
                    })}
                  </Col>
                </Row>
              </>
            )}
            {/* {UISweetAlert.nuevoEstudio ? (
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4"
                    type="button"
                    onClick={() => {
                      nuevoMarcador();
                    }}>
                    Nuevo Marcador
                  </button>
                </Col>
              </Row>
            ) : null} */}

            <Row
              hidden={
                !estudioLaboratorioSeleccionado.estudiosLaboratorioMarcador ||
                estudioLaboratorioSeleccionado.estudiosLaboratorioMarcador.length < 1
              }>
              <Col>
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Marcador</th>
                      <th scope="col">Valor</th>
                      {UISweetAlert.nuevoEstudio && <th scope="col">Eliminar</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {estudioLaboratorioSeleccionado.estudiosLaboratorioMarcador &&
                      estudioLaboratorioSeleccionado.estudiosLaboratorioMarcador.map((marcador) => {
                        return (
                          <tr key={marcador}>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) => changeMarcador(marcador, e)}
                                disabled={!UISweetAlert.nuevoEstudio}
                                value={marcador.marcador ? marcador.marcador : ""}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) => changeValor(marcador, e)}
                                disabled={!UISweetAlert.nuevoEstudio}
                                value={marcador.valor ? marcador.valor : ""}
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                type="button"
                                onClick={() => {
                                  setMarcadorSeleccionado(marcador);
                                  setUISweetAlert({
                                    ...UISweetAlert,
                                    confirm_alert_eliminar_marcador: true,
                                  });
                                }}>
                                Eliminar
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_eliminar_marcador ? (
        <SweetAlert
          title="Desde eliminar el marcador?"
          warning
          showCancel
          showConfirm
          confirmBtnText="Guardar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarMarcador()}
          onCancel={() => {
            setMarcadorSeleccionado(null);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_eliminar_marcador: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
