import { LISTAR_TRASLADO_PRODUCTOSINVENTARIOS, FILTROS_TRASLADOS } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case LISTAR_TRASLADO_PRODUCTOSINVENTARIOS:
      return {
        ...state,
        trasladosProductoInventario: action.payload,
      };
    case FILTROS_TRASLADOS:
      return {
        ...state,
        filtrosTraslados: action.payload,
      };
    default:
      return state;
  }
};
