import React, { useState, useEffect, useContext } from "react";
// import { useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import TablaArchivosPaciente from "../TablaArchivosPaciente";
import TabArchivosImagenClinica from "../TabArchivosImagenClinica";

import PacientesContext from "../../context/Pacientes/PacientesContext";

import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import SexosContext from "../../context/Sexos/SexosContext";

import DrawerExpedientePaciente from "../DrawerExpedientePaciente/index";

import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";

const ArchivosPaciente = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [archivosPaciente, setArchivosPaciente] = useState(null);

  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }, []);

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado, getArchivosPaciente, exportArchivoBack, archivosPacienteImagen, archivosPacienteNoImagen } =
    pacientesContext;

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  useEffect(() => {
    const fetchArchivos = async () => {
      const archivos = await getArchivosPaciente(idCatPacienteSeleccionado);

      setArchivosPaciente(archivos);
      return archivos;
      // if (
      //   props.history.location.state &&
      //   props.history.location.state.historiaClinicaInicialProps &&
      //   props.history.location.state.historiaClinicaInicialProps.expedienteMedico.idExpedienteMedico
      // ) {
      //   // const archivos = await getArchivosPaciente(
      //   //   idCatPacienteSeleccionado,
      //   //   props.history.location.state.historiaClinicaInicialProps.expedienteMedico.idExpedienteMedico
      //   // );
      //   const archivos = await getArchivosPaciente(idCatPacienteSeleccionado);

      //   setArchivosPaciente(archivos);
      //   return archivos;
      // }
    };
    fetchArchivos();
  }, []);

  useEffect(() => {
    const fetchAll = () => {
      listarSexos();
      listarAseguradoras();
      listarEscolaridades();
      listarEstadosCiviles();
      listarLugares();
      listarOcupaciones();
      listarMedicos();
    };
    fetchAll();
  }, []);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_cargar_archivo: false,
    confirm_eliminar_archivo: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const exportArchivo = async (archivo) => {
    // convert image file to base64 string

    const archivoVo = {
      rutaArchivo: archivo.rutaArchivo,
      nombreArchivo: archivo.nombreArchivo,
      mimeType: archivo.mimeType,
    };
    const res = await exportArchivoBack(archivoVo);
    return res;
  };

  const redirectHistoriaClinicaInicial = async () => {
    props.history.push("/historia/clinica/inicial");
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Archivos del Paciente</h3>
      <DrawerExpedientePaciente
        historiaClinicaInicial={historiaClinicaInicial}
        sexos={sexos}
        aseguradoras={aseguradoras}
        medicos={medicos}
        lugares={lugares}
        ocupaciones={ocupaciones}
        estadosCiviles={estadosCiviles}
        escolaridades={escolaridades}
      />
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    onClick={redirectHistoriaClinicaInicial}>
                    Historia Clínica Inicial
                  </button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabList>
                      <Tab>Archivos Personales</Tab>
                      <Tab>Archivos Imagen Clínica</Tab>
                    </TabList>
                    <TabPanel>
                      <TablaArchivosPaciente archivosPaciente={archivosPaciente} exportArchivo={exportArchivo} />
                    </TabPanel>
                    <TabPanel>
                      <TabArchivosImagenClinica
                        archivosPaciente={archivosPacienteNoImagen}
                        archivosImagen={archivosPacienteImagen}
                        exportArchivo={exportArchivo}
                      />
                    </TabPanel>
                  </Tabs>
                </Col>
              </Row>
              {/* <h4 className="flex h4 mt-5">Lista de Archivos del Paciente</h4> */}
              {/* Tabla lista archivos */}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default ArchivosPaciente;
