import React from "react";

const TablaArchivosPaciente = (props) => {
  return (
    <div className="table-responsive mt-4">
      <table className="table table-hover table-centered table-nowrap mb-0">
        <thead>
          <tr>
            <th scope="col">Fecha de Subida</th>
            <th scope="col">Archivo</th>
          </tr>
        </thead>
        <tbody>
          {props.archivosPaciente &&
            props.archivosPaciente.map((archivo) => {
              return (
                <tr key={archivo.idArchivo}>
                  <td>
                    {archivo.fechaCreacion
                      ? `${archivo.fechaCreacion.split("T")[0].split("-")[2]}/${
                          archivo.fechaCreacion.split("T")[0].split("-")[1]
                        }/${archivo.fechaCreacion.split("T")[0].split("-")[0]}`
                      : ""}
                  </td>

                  <td>
                    <button
                      className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-2 mt-2"
                      onClick={() => props.exportArchivo(archivo)}
                      type="button">
                      {archivo.nombreArchivo}
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TablaArchivosPaciente;
