import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// import { Link } from "react-router-dom";

import RecepcionGenericaProductosContext from "../../context/RecepcionGenericaProductos/RecepcionGenericaProductosContext";
import CatTipoRecepcionContext from "../../context/CatTipoRecepcion/CatTipoRecepcionContext";
import OrdenesCompraContext from "../../context/OrdenesCompra/OrdenesCompraContext";

import TableComponent from "../TablaPaginada/index";

import formatNumber from "../../utils/index";

export default function RecepcionGenericaProductos(props) {
  const [rowSeleccionada, setRowSeleccionada] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);

  const [ordenCompraSeleccionada, setOrdenCompraSeleccionada] = useState(null);
  const [devolucionCancelacion, setDevolucionCancelacion] = useState(null);

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }

      let count;

      if (recepcionGenericaProductoFiltro.idTipoRecepcion == 1) {
        const response = await listarRecepcionComprasPaginado({ page, limit });
        count = response.count;
      } else if (recepcionGenericaProductoFiltro.idTipoRecepcion == 2) {
        const response = await listarDevolucionesOCancelacionesPaginado({ page, limit });
        count = response.count;
      }

      setRowSeleccionada(null);
      setOrdenCompraSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    console.log("fetchData is being called");

    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
    // setLoadingData(true);
    // fetchAPIData({
    //   page: pageIndex + 1,
    //   limit: pageSize,
    //   byBusquedaFiltros: false,
    // });
  };

  const handleCheckedOrdenCompra = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setOrdenCompraSeleccionada({
          ...row.original,
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setOrdenCompraSeleccionada(null);
      } else if (rowSeleccionada !== row.id) {
        setRowSeleccionada(row.id);
        setOrdenCompraSeleccionada({
          ...row.original,
        });
      }
    },
    [rowSeleccionada]
  );
  const handleCheckedDevolucionesCancelaciones = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setDevolucionCancelacion({
          ...row.original,
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setDevolucionCancelacion(null);
      } else if (rowSeleccionada !== row.id) {
        setRowSeleccionada(row.id);
        setDevolucionCancelacion({
          ...row.original,
        });
      }
    },
    [rowSeleccionada]
  );

  const columnsRecepcionOrdenes = useMemo(
    () => [
      {
        Header: "",
        accessor: "idOrdenCompra",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handleCheckedOrdenCompra(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => (
          <>
            Orden de <br></br>Compra
          </>
        ),
        accessor: "folioOrdenCompra",
      },
      {
        Header: () => (
          <>
            Fecha<br></br>OC
          </>
        ),
        accessor: "fechaOrdenCompra",
        Cell: ({ row }) => {
          return <>{row.original.fechaOrdenCompra ? row.original.fechaOrdenCompra.split("T")[0] : ""}</>;
        },
      },

      {
        Header: "Proveedor",
        accessor: "catProveedores",
        Cell: ({ row }) => {
          return <>{row.original.catProveedores ? row.original.catProveedores.proveedor : ""}</>;
        },
      },
      {
        Header: () => (
          <>
            Importe<br></br>Total
          </>
        ),
        accessor: "importeTotal",
        Cell: ({ row }) => {
          return <>{row.original.importeTotal ? formatNumber(row.original.importeTotal) : ""}</>;
        },
      },
    ],
    [rowSeleccionada, handleCheckedOrdenCompra]
  );

  const columnsRecepcionDevoluciones = useMemo(
    () => [
      {
        Header: "",
        accessor: "id",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handleCheckedDevolucionesCancelaciones(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => (
          <>
            Tipo<br></br>Venta
          </>
        ),
        accessor: "tipoVenta",
      },
      {
        Header: () => (
          <>
            Tipo<br></br>Recepción
          </>
        ),
        accessor: "tipoRecepcion",
      },
      {
        Header: "Folio",
        accessor: "folioVenta",
      },
      {
        Header: "Fecha",
        accessor: "fechaVenta",
        Cell: ({ row }) => {
          return <>{row.original.fechaVenta ? row.original.fechaVenta.split("T")[0] : ""}</>;
        },
      },

      {
        Header: () => (
          <>
            Total<br></br>Piezas
          </>
        ),
        accessor: "totalPiezas",
      },
    ],
    [rowSeleccionada, handleCheckedOrdenCompra]
  );

  const [UISweetAlert, setUISweetAlert] = useState({
    error_dlg: false,
    redirect: false,
    success_dlg: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const [tipoDeRecepcion, setTipoDeRecepcion] = useState(false);

  const recepGenProdContext = useContext(RecepcionGenericaProductosContext);
  const {
    recepcionOrdenesCompra,
    recepcionDevolucionesOCancelaciones,
    recepcionGenericaProductoFiltro,
    setFiltrosRecepcionGenericaProductos,
    filtroDevolucionCancelacionVenta,
    setFiltroDevolucionCancelacionVenta,
    limpiarFiltroDevolucionCalcecionVenta,
    limpiarRecepcionVentaSeleccionada,
    limpiarListaOrdenesCompra,
    limpiarListaDevolucionesCancelaciones,
    listarRecepcionComprasPaginado,
    listarDevolucionesOCancelacionesPaginado,
  } = recepGenProdContext;

  const catTipoRecepcionContext = useContext(CatTipoRecepcionContext);
  const { tiposRecepecion, listarTipoRecepcion } = catTipoRecepcionContext;

  const ctxOrdenCompra = useContext(OrdenesCompraContext);
  const { limpiarOrdenCompraSeleccionada } = ctxOrdenCompra;

  const enviarRecepcionProductoFiltrados = async (e) => {
    e.preventDefault();
    if (recepcionGenericaProductoFiltro.fechaInicial && !recepcionGenericaProductoFiltro.fechaFinal) {
      return;
    }

    if (recepcionGenericaProductoFiltro.fechaFinal) {
      recepcionGenericaProductoFiltro.fechaFinal = recepcionGenericaProductoFiltro.fechaFinal + "T23:59:59";
    }

    if (recepcionGenericaProductoFiltro.idTipoRecepcion == 1) {
      fetchData({ pageSize: 10, pageIndex: 0, byBusquedaFiltros: true });
    } else if (recepcionGenericaProductoFiltro.idTipoRecepcion == 2) {
      fetchData({ pageSize: 10, pageIndex: 0, byBusquedaFiltros: true });
    }

    if (recepcionGenericaProductoFiltro.fechaFinal) {
      recepcionGenericaProductoFiltro.fechaFinal = recepcionGenericaProductoFiltro.fechaFinal.split("T")[0];
    }
  };
  // const enviarRecepcionProductoFiltrados = async (e) => {
  //   e.preventDefault();
  //   if (recepcionGenericaProductoFiltro.fechaInicial && !recepcionGenericaProductoFiltro.fechaFinal) {
  //     return;
  //   }

  //   if (recepcionGenericaProductoFiltro.fechaFinal) {
  //     recepcionGenericaProductoFiltro.fechaFinal = recepcionGenericaProductoFiltro.fechaFinal + "T23:59:59";
  //   }

  //   if (recepcionGenericaProductoFiltro.idTipoRecepcion == 1) {
  //     listarRecepcionCompras();
  //   } else if (recepcionGenericaProductoFiltro.idTipoRecepcion == 2) {
  //     listarDevolucionesOCancelaciones();
  //   }

  //   if (recepcionGenericaProductoFiltro.fechaFinal) {
  //     recepcionGenericaProductoFiltro.fechaFinal = recepcionGenericaProductoFiltro.fechaFinal.split("T")[0];
  //   }
  // };

  const onChange = async (e) => {
    let value = e.target.value;
    if (value == 1) {
      setTipoDeRecepcion(true);
      recepcionGenericaProductoFiltro.idEstatusOrdenCompra = 1;
    } else if (value == 2) {
      setTipoDeRecepcion(false);
      recepcionGenericaProductoFiltro.idEstatusOrdenCompra = null;
    }

    setFiltrosRecepcionGenericaProductos({
      ...recepcionGenericaProductoFiltro,
      [e.target.name]: value,
    });
  };

  // const handdleCheckedOrdenCompra = async (index) => {
  //   recepcionOrdenesCompra.map((ordenCompra, i) => {
  //     if (i == index) {
  //       ordenCompra.checked = !ordenCompra.checked;
  //     } else {
  //       ordenCompra.checked = false;
  //     }
  //   });
  //   setRecepcionOrdenesCompra(recepcionOrdenesCompra);
  // };

  // const handdleCheckedDevolucionesCancelaciones = async (index) => {
  //   recepcionDevolucionesOCancelaciones.map((devCan, i) => {
  //     if (i == index) {
  //       devCan.checked = !devCan.checked;
  //     } else {
  //       devCan.checked = false;
  //     }
  //   });
  //   setRecepcionDevolucionOCancelacion(recepcionDevolucionesOCancelaciones);
  // };

  const recibirProducto = async () => {
    if (recepcionGenericaProductoFiltro.idTipoRecepcion == 1) {
      const ordenCompraChecked = await isOrdenCompraChecked();
      if (ordenCompraChecked) {
        limpiarListaOrdenesCompra();
        //aqui seria redirigir con a /recepcionproducto/ordencompra/idordencompra
        props.history.push(`/recepcionproducto/ordenescompra/${ordenCompraSeleccionada.idOrdenCompra}`);
        setOrdenCompraSeleccionada(null);
      }
    } else if (recepcionGenericaProductoFiltro.idTipoRecepcion == 2) {
      const devolucionCancelacionChecked = await isDevolucionCancelacionChecked();

      if (!devolucionCancelacionChecked) {
        return;
      }

      limpiarListaDevolucionesCancelaciones();

      //hay que mandar el objeto de ordencomprafiltro con el idvale y idventamostrador

      if (devolucionCancelacion.idVale != null) {
        filtroDevolucionCancelacionVenta.idVale = devolucionCancelacion.idVale;
        filtroDevolucionCancelacionVenta.idVentaMostrador = null;
      } else if (devolucionCancelacion.idVentaMostrador != null) {
        filtroDevolucionCancelacionVenta.idVentaMostrador = devolucionCancelacion.idVentaMostrador;
        filtroDevolucionCancelacionVenta.idVale = null;
      }

      setFiltroDevolucionCancelacionVenta(filtroDevolucionCancelacionVenta);

      setDevolucionCancelacion(null);
      props.history.push(`/recepcionproducto/venta`);
    }
  };

  const isOrdenCompraChecked = async () => {
    // let ordenCompraChecked = false;
    let ordenCompraChecked = ordenCompraSeleccionada !== null;

    // recepcionOrdenesCompra.map((ordenCompra) => {
    //   if (ordenCompra.checked) {
    //     ordenCompraChecked = ordenCompra;
    //   }
    // });

    if (!ordenCompraChecked) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione una orden de compra",
      });
    }
    return ordenCompraChecked;
  };

  const isDevolucionCancelacionChecked = async () => {
    let devolucionCancelacionChecked = devolucionCancelacion !== null;
    // let devolucionCancelacionChecked = false;

    // recepcionDevolucionesOCancelaciones.map((devCanc) => {
    //   if (devCanc.checked) {
    //     devolucionCancelacionChecked = devCanc;
    //   }
    // });

    if (!devolucionCancelacionChecked) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione un vale o una venta mostrador",
      });
    }
    return devolucionCancelacionChecked;
  };

  const limpiarFiltros = () => {
    setFiltrosRecepcionGenericaProductos({
      fechaInicial: "",
      fechaFinal: "",
      idTipoRecepcion: "",
    });
    // limpiarListaDevolucionesCancelaciones();
    // limpiarListaOrdenesCompra();
  };

  useEffect(() => {
    listarTipoRecepcion();
    // if (tiposRecepecion.length <= 0) {
    //   listarTipoRecepcion();
    // }
    if (Object.entries(recepcionGenericaProductoFiltro).length !== 0) {
      if (recepcionGenericaProductoFiltro.idEstatusOrdenCompra && recepcionGenericaProductoFiltro.idEstatusOrdenCompra == 1) {
        setTipoDeRecepcion(true);
      }
    }
    limpiarFiltroDevolucionCalcecionVenta();

    limpiarRecepcionVentaSeleccionada();
    limpiarOrdenCompraSeleccionada();
  }, []);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Recepción Genérica de Productos</h3>

      <Card className="mt-10 card" style={{ padding: "1.5rem 1.5rem 1.5rem" }}>
        <form onSubmit={(e) => enviarRecepcionProductoFiltrados(e)}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                  <Col sm={7}>
                    <input
                      className="form-control"
                      type="date"
                      name="fechaInicial"
                      onChange={onChange}
                      value={recepcionGenericaProductoFiltro.fechaInicial}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Tipo de Recepción</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idTipoRecepcion"
                      onChange={onChange}
                      value={recepcionGenericaProductoFiltro.idTipoRecepcion}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {tiposRecepecion.map((tipoRecep) => {
                        return (
                          <>
                            <option value={tipoRecep.idCatTipoRecepcion}>{tipoRecep.tipoRecepcion}</option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Fecha Final</label>
                  <Col sm={7}>
                    <input
                      className="form-control"
                      type="date"
                      name="fechaFinal"
                      disabled={!recepcionGenericaProductoFiltro.fechaInicial ? true : false}
                      min={recepcionGenericaProductoFiltro.fechaInicial}
                      onChange={onChange}
                      value={recepcionGenericaProductoFiltro.fechaFinal}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  disabled={!recepcionGenericaProductoFiltro.idTipoRecepcion}
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="submit"
                  style={{
                    marginRight: "2.5rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}>
                  Aplicar Filtros
                </button>
                <button
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="button"
                  onClick={limpiarFiltros}
                  style={{
                    marginRight: "2.5rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}>
                  Limpiar Filtros
                </button>
              </Col>
            </Row>
          </CardBody>
        </form>
      </Card>

      <Card className="mt-4">
        <CardBody>
          <Row>
            <button
              className="btn btn-primary-orange-umo w-md waves-effect ml-3 waves-light"
              type="button"
              // hidden={usuario.authorities.includes("OP_FARMACIA_VALE_EDITAR") ? false : true}
              onClick={recibirProducto}>
              Recibir
            </button>
          </Row>
          {/* {tipoDeRecepcion ? (
            <Row>
              <div className="table-responsive mt-4">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">
                        ORDEN DE <br></br>COMPRA
                      </th>
                      <th scope="col">
                        FECHA<br></br>OC
                      </th>
                      <th scope="col">PROVEEDOR</th>
                      <th scope="col">
                        IMPORTE<br></br>TOTAL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {recepcionOrdenesCompra.map((ordenCompra, index) => {
                      return (
                        <>
                          <tr key={ordenCompra}>
                            <td>{<input type="checkbox" onClick={() => handdleCheckedOrdenCompra(index)} checked={ordenCompra.checked} />}</td>
                            <td> {ordenCompra.folioOrdenCompra ? ordenCompra.folioOrdenCompra : ""} </td>
                            <td> {ordenCompra.fechaOrdenCompra ? ordenCompra.fechaOrdenCompra.split("T")[0] : ""} </td>
                            <td>{ordenCompra.catProveedores ? ordenCompra.catProveedores.proveedor : ""}</td>
                            <td> {ordenCompra.importeTotal ? formatNumber(ordenCompra.importeTotal) : ""} </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Row>
          ) : (
            <Row>
              <div className="table-responsive mt-4">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">
                        Tipo<br></br>Venta
                      </th>
                      <th scope="col">
                        Tipo<br></br>Recepción
                      </th>
                      <th scope="col">Folio</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">
                        Total<br></br>Piezas
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {recepcionDevolucionesOCancelaciones.map((devolucionCancelacion, index) => {
                      return (
                        <>
                          <tr key={devolucionCancelacion}>
                            <td>{<input type="checkbox" onClick={() => handdleCheckedDevolucionesCancelaciones(index)} checked={devolucionCancelacion.checked} />}</td>
                            <td> {devolucionCancelacion.tipoVenta ? devolucionCancelacion.tipoVenta : ""} </td>
                            <td> {devolucionCancelacion.tipoRecepcion ? devolucionCancelacion.tipoRecepcion : ""} </td>
                            <td> {devolucionCancelacion.folioVenta ? devolucionCancelacion.folioVenta : ""} </td>
                            <td> {devolucionCancelacion.fechaVenta ? devolucionCancelacion.fechaVenta.split("T")[0] : ""} </td>
                            <td> {devolucionCancelacion.totalPiezas ? devolucionCancelacion.totalPiezas : ""} </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Row>
          )} */}
          {tipoDeRecepcion ? (
            <TableComponent
              pageCount={pageCount}
              fetchData={fetchData}
              columns={columnsRecepcionOrdenes}
              loading={loadingData}
              data={recepcionOrdenesCompra}
              indexOfPage={controlledPageIndex}
            />
          ) : (
            <TableComponent
              pageCount={pageCount}
              fetchData={fetchData}
              columns={columnsRecepcionDevoluciones}
              loading={loadingData}
              data={recepcionDevolucionesOCancelaciones}
              indexOfPage={controlledPageIndex}
            />
          )}
        </CardBody>
      </Card>

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}
    </div>
  );
}
