/* eslint-disable import/no-anonymous-default-export */
import { LISTAR_CONSULTAS_SEGUIMIENTO } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_CONSULTAS_SEGUIMIENTO:
        return {
            ...state,
            consultasSeguimiento: action.payload,
        };
    default:
        return state;
  }
};
