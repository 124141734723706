import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";

import formatNumber from "../../utils/index";

// import AuthContext from "../../context/Auth/AuthContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import CatTipoSolicitudCompraContext from "../../context/CatTipoSolicitudCompra/CatTipoSolicitudCompraContext";
import CatTipoOrdenCompraContext from "../../context/CatTipoOrdenCompra/CatTipoOrdenCompraContext";
import OrdenesCompraContext from "../../context/OrdenesCompra/OrdenesCompraContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import EstatusOrdenCompraContext from "../../context/EstatusOrdenCompra/EstatusOrdenCompraContext";
import AuthContext from "../../context/Auth/AuthContext";
import TableComponent from "../TablaPaginadaSinUpdate/index";

export default function OrdenesDeCompra(props) {
  const authContext = useContext(AuthContext);
  const {usuario} = authContext;

  const [toggleSearchBy, setToggleSearchBy] = useState(false);
  const [loading, setLoading] = useState(false);

  const [rowSeleccionada, setRowSeleccionada] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);

  const [ordenCompraSeleccionada, setOrdenCompraSeleccionada] = useState(null);
  const [listaProveedoresSimple, setListaProveedoresSimple] = useState(null);

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await listarOrdenesCompraPaginadas({ page, limit });

      // Cantidad de datos disponibles en la tabla pacientes de la base de datos
      const count = response.count;

      setRowSeleccionada(null);
      setOrdenCompraSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    console.log("fetchData is being called");

    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const handdleCheckedOrdenesCompraPaginada = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setOrdenCompraSeleccionada({
          ...row.original,
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setOrdenCompraSeleccionada(null);
      } else if (rowSeleccionada !== row.id) {
        setRowSeleccionada(row.id);
        setOrdenCompraSeleccionada({
          ...row.original,
        });
      }
    },
    [rowSeleccionada]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idOrdenCompra",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedOrdenesCompraPaginada(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Folio", accessor: "folioOrdenCompra" },
      {
        Header: "Fecha OC",
        accessor: "fechaOrdenCompra",
        Cell: ({ row }) => {
          return <>{row.original.fechaOrdenCompra ? row.original.fechaOrdenCompra.split("T")[0] : ""}</>;
        },
      },
      { Header: "Proveedor", accessor: "proveedor" },
      { Header: "Tipo compra", accessor: "tipoOrdenCompra" },
      { Header: "Solicitud de Compra", accessor: "folioSolicitudCompra" },
      { Header: "Facturas / Documentos", accessor: "cadenaArchivos" },
      {
        Header: "Estatus",
        accessor: "estatusActual",
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.idEstatusActual == 6 || row.original.idEstatusActual == 7 || row.original.idEstatusActual == 8 ? (
                <span
                  className="badge badge-info"
                  style={{
                    height: "1.9rem",
                    paddingTop: "0.6rem",
                    border: "1px solid #dfdada",
                    color: "white",
                    fontWeight: "bold",
                    //morado
                    backgroundColor: "#602a96",
                    borderRadius: "5px",
                  }}>
                  {row.original.estatusActual}
                </span>
              ) : (
                <span
                  className="badge badge-info"
                  style={{
                    height: "1.9rem",
                    paddingTop: "0.6rem",
                    border: "1px solid #dfdada",
                    color: "black",
                    fontWeight: "bold",
                    //verde
                    backgroundColor: "#C1E05C",
                    borderRadius: "5px",
                  }}>
                  {row.original.estatusActual}
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: () => <div className="text-center">Importe Total</div>,
        accessor: "importeTotal",
        Cell: ({ row }) => {
          return <div className="text-right">{formatNumber(row.original.importeTotal + row.original.ivaTotal)}</div>;
        },
      },
      {
        Header: "Histórico",
        accessor: "historico",
        Cell: ({ row }) => {
          return (
            <Link
              to={`/ordenescompra/historico/${row.original.idOrdenCompra}`}
              className="btn btn-sm btn-primary-orange-umo"
              style={{
                background: "#f93",
                color: "white",
                fontWeight: "bold",
              }}>
              Histórico
            </Link>
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [rowSeleccionada, handdleCheckedOrdenesCompraPaginada]
  );

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_cancelar_orden_compra: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  // const authContext = useContext(AuthContext);
  // const { usuario } = authContext;

  //ACCESO AL ESTADO GLOBAL - Usuarios
  const ctxUsuarios = useContext(UsuariosContext);
  const { usuarios, listarUsuarios } = ctxUsuarios;

  //ACCESO AL ESTADO GLOBAL - Tipos de Solicitud de compra
  const ctxTiposSolicitudCompra = useContext(CatTipoSolicitudCompraContext);
  const { tiposSolicitudCompra, listarTiposSolicitudCompra } = ctxTiposSolicitudCompra;

  //ACCESO AL ESTADO GLOBAL - Tipos de compra
  const ctxTiposOrdenCompra = useContext(CatTipoOrdenCompraContext);
  const { tiposOrdenCompra, listarTiposOrdenCompra } = ctxTiposOrdenCompra;

  //ACCESO AL ESTADO GLOBAL - Proveedores
  const ctxProveedores = useContext(ProveedoresContext);
  const { proveedores, listarProveedoresSimple, listarProveedores } = ctxProveedores;

  //ACCESO AL ESTADO GLOBAL - Proveedores
  const ctxEstatusOrdenCompra = useContext(EstatusOrdenCompraContext);
  const { estatusOrdenCompra, listarEstatusOrdenCompra } = ctxEstatusOrdenCompra;

  const ordenesCompraContext = useContext(OrdenesCompraContext);
  const {
    filtrosOrdenesCompra,
    setFiltrosOrdenesCompra,
    limpiarOrdenCompraSeleccionada,
    ordenesCompraFiltradas,
    uploadCancelarOrdenCompra,
    cancelarOrdenCompra,
    setCancelarOrdenCompra,
    exportOrdenCompra,
    exportOrdenCompraReabasto,
    listarOrdenesCompraPaginadas,
  } = ordenesCompraContext;

  const onChange = (e) => {
    if (e.target.name == "fechaInicial") {
      setFiltrosOrdenesCompra({
        ...filtrosOrdenesCompra,
        [e.target.name]: e.target.value,
        fechaFinal: e.target.value,
      });
    } else {
      setFiltrosOrdenesCompra({
        ...filtrosOrdenesCompra,
        [e.target.name]: e.target.value,
      });
    }
  };

  const toggleSearch = () => {
    setToggleSearchBy(!toggleSearchBy);
    limpiarFiltros();
  };

  const buscarPorFolio = (e) => {
    e.preventDefault();
    fetchData({ pageSize: 10, pageIndex: 0 });
  };
  // const buscarPorFolio = (e) => {
  //   e.preventDefault();
  //   listarOrdenesCompras({
  //     folioOrdenCompra: filtrosOrdenesCompra.folioOrdenCompra,
  //   });
  // };

  const buscarPorFiltros = (e) => {
    e.preventDefault();
    if (filtrosOrdenesCompra.fechaInicial && !filtrosOrdenesCompra.fechaFinal) {
      return;
    }

    if (filtrosOrdenesCompra.fechaFinal) {
      filtrosOrdenesCompra.fechaFinal = filtrosOrdenesCompra.fechaFinal + "T23:59:59";
    }

    fetchData({ pageSize: 10, pageIndex: 0 });

    if (filtrosOrdenesCompra.fechaFinal) {
      filtrosOrdenesCompra.fechaFinal = filtrosOrdenesCompra.fechaFinal.split("T")[0];
    }
  };
  // const buscarPorFiltros = (e) => {
  //   e.preventDefault();
  //   if (filtrosOrdenesCompra.fechaInicial && !filtrosOrdenesCompra.fechaFinal) {
  //     return;
  //   }

  //   if (filtrosOrdenesCompra.fechaFinal) {
  //     filtrosOrdenesCompra.fechaFinal = filtrosOrdenesCompra.fechaFinal + "T23:59:59";
  //   }

  //   listarOrdenesCompras(filtrosOrdenesCompra);

  //   if (filtrosOrdenesCompra.fechaFinal) {
  //     filtrosOrdenesCompra.fechaFinal = filtrosOrdenesCompra.fechaFinal.split("T")[0];
  //   }
  // };

  const limpiarFiltros = () => {
    setFiltrosOrdenesCompra({
      fechaInicial: "",
      tipoSolicitudCompra: "",
      tipoOrdenCompra: "",
      fechaFinal: "",
      usuarioComprador: "",
      folioOrdenCompra: null,
      estatusOrdenCompra: "",
      importeMaximo: null,
      proveedor: "",
    });
  };

  const onChangeImporteMaximo = (e) => {
    let value = e.target.value;
    value = value.replace("$", "");

    setFiltrosOrdenesCompra({
      ...filtrosOrdenesCompra,
      importeMaximo: value,
    });
  };

  // const handdleCheckedOrdenCompra = (idOrdenCompra) => {
  //   ordenesCompraFiltradas.map((ordenCompra) => {
  //     if (ordenCompra.idOrdenCompra == idOrdenCompra) {
  //       ordenCompra.checked = !ordenCompra.checked;
  //     } else {
  //       ordenCompra.checked = false;
  //     }
  //   });
  //   setOrdenesCompraFiltradas(ordenesCompraFiltradas);
  // };

  const isOrdenCompraChecked = async () => {
    let ordenCompraChecked = ordenCompraSeleccionada !== null;

    // ordenesCompraFiltradas.map((ordenCompra) => {
    //   if (ordenCompra.checked) {
    //     ordenCompraChecked = ordenCompra;
    //   }
    // });

    if (!ordenCompraChecked) {
      //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione una orden de compra",
      });
    }

    return ordenCompraChecked;
  };

  const cancelarOrdenCompraConfirm = async () => {
    const ordenCompraChecked = await isOrdenCompraChecked();

    if (ordenCompraChecked) {
      const estatusActual = ordenCompraSeleccionada.idEstatusActual;

      // if (estatusActual == 7 || estatusActual == 8 || estatusActual == 6) {
      //   setUISweetAlert({
      //     ...UISweetAlert,
      //     error_dlg: true,
      //     dynamic_title: "Fallo",
      //     dynamic_description: "No se puede cancelar la orden de compra",
      //   });
      // } else {

      if (estatusActual !== 1) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "No se puede cancelar la orden de compra",
        });
      } else {
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_cancelar_orden_compra: true,
        });
      }
    }
  };

  const onChangeCancelarOrdenCompra = async (e) => {
    setCancelarOrdenCompra({
      ...cancelarOrdenCompra,
      [e.target.name]: e.target.value,
    });
  };

  const ordenCompraCancelar = async () => {
    // eslint-disable-next-line no-unused-vars
    const ordenCompraChecked = await isOrdenCompraChecked();

    if (cancelarOrdenCompra.comentarioEstatus == "" || cancelarOrdenCompra.fechaCancelacion == "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor llene la información necesaria.",
      });
      return;
    }

    setLoading(true);

    const res = await uploadCancelarOrdenCompra({
      idEstatusNuevo: 8,
      idOrdenCompra: ordenCompraSeleccionada.idOrdenCompra,
      comentarioEstatus: cancelarOrdenCompra.comentarioEstatus,
    });

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_cancelar_orden_compra: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error al cancelar la orden de compra. Inténtalo por favor más tarde.",
      });
    } else if (!res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_cancelar_orden_compra: false,
        dynamic_description: "Orden de compra cancelada",
      });
      setCancelarOrdenCompra({
        fechaCancelacion: new Date().toISOString().slice(0, 10),
        comentarioEstatus: "",
      });
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setOrdenCompraSeleccionada(null);
    }
  };

  const imprimirOrdenCompra = async () => {
    const ordenCompraChecked = await isOrdenCompraChecked();

    if (ordenCompraChecked) {
      setLoading(true);
      const res = await exportOrdenCompra(ordenCompraSeleccionada.idOrdenCompra);

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
      }
    }
  };

  const imprimirOrdenreabasto = async () => {
    const ordenCompraChecked = await isOrdenCompraChecked();

    if (ordenCompraChecked) {     
      if(ordenCompraSeleccionada.idCatTipoOrdenCompra == 2){
        setLoading(true);
        const res = await exportOrdenCompraReabasto(ordenCompraSeleccionada.idOrdenCompra);
  
        if (res.data.error) {
          setLoading(false);
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: res.data.message,
          });
        } else if (res) {
          setLoading(false);
        }
        
      }else{
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_alert_cancelar_orden_compra: false,
          dynamic_title: "Fallo",
          dynamic_description: "El tipo de compra seleccionado debe ser CONSIGNACIÓN",
        });
        return;
      }
    }else{
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_cancelar_orden_compra: false,
        dynamic_title: "Fallo",
        dynamic_description: "Seleccione un folio y que el tipo de compra sea CONSIGNACIÓN",
      });
      return;
    }

  };

  const recibidaOrdenCompraConfirm = async () => {
    const ordenCompraChecked = await isOrdenCompraChecked();

    if (ordenCompraChecked) {
      const estatusActual = ordenCompraSeleccionada.idEstatusActual;

      if (estatusActual == 2 || estatusActual == 3 || (ordenCompraSeleccionada.diasPago == 0 && !(estatusActual == 6))) {
        props.history.push(`/ordenescompra/recibida/${ordenCompraSeleccionada.idOrdenCompra}`);

        setOrdenCompraSeleccionada(null);
      } else {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "No se han recibido los productos de la orden de compra",
        });
      }
    }
  };

  useEffect(() => {

    const fetchFacturacionMensual = async () => {
      const listaSimpleRegreso = await listarProveedoresSimple();
      setListaProveedoresSimple(listaSimpleRegreso);
   };

  fetchFacturacionMensual();

    listarUsuarios();
    listarTiposSolicitudCompra();
    listarTiposOrdenCompra();
    //listarProveedores();
    listarEstatusOrdenCompra();
    limpiarOrdenCompraSeleccionada();
    
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Lista de Ordenes de Compra</h3>

        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearch}>
          {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
        </button>

        <Card
          hidden={!toggleSearchBy}
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFolio(e)}>
            <CardBody>
              <Row>
                <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                  <Row
                    className="form-group"
                    style={{
                      marginBottom: "0",
                    }}>
                    <Col sm={10} className="flex col-sm-10">
                      <input
                        className="form-control mr-2.5"
                        type="text"
                        name="folioOrdenCompra"
                        placeholder="Escribe tu folio"
                        onChange={onChange}
                        value={filtrosOrdenesCompra.folioOrdenCompra ? filtrosOrdenesCompra.folioOrdenCompra : ""}
                      />
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                        style={{
                          marginRight: "2.5rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        type="submit">
                        Buscar
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>

        <Card
          hidden={toggleSearchBy}
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFiltros(e)}>
            <CardBody>
              <Row>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaInicial"
                        onChange={onChange}
                        value={filtrosOrdenesCompra.fechaInicial}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Tipo Orden de Compra</label>
                    <Col sm={7}>
                      <select
                        className="form-control"
                        name="tipoSolicitudCompra"
                        onChange={onChange}
                        value={filtrosOrdenesCompra.tipoSolicitudCompra}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tiposSolicitudCompra.map((tiposSolCom) => {
                          return (
                            <>
                              <option value={tiposSolCom.idCatTipoSolicitudCompra}>{tiposSolCom.tipoSolicitudCompra}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Tipo Compra</label>
                    <Col sm={7}>
                      <select
                        className="form-control"
                        name="tipoOrdenCompra"
                        onChange={onChange}
                        value={filtrosOrdenesCompra.tipoOrdenCompra}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tiposOrdenCompra.map((tipoOrdenCompra) => {
                          return (
                            <>
                              <option value={tipoOrdenCompra.idCatTipoOrdenCompra}>{tipoOrdenCompra.tipoOrdenCompra}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Estatus Orden de Compra</label>
                    <Col sm={7}>
                      <select
                        className="form-control"
                        name="estatusOrdenCompra"
                        onChange={onChange}
                        value={filtrosOrdenesCompra.estatusOrdenCompra}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {estatusOrdenCompra.map((estatusOrdnCompra) => {
                          return (
                            <>
                              <option value={estatusOrdnCompra.idCatEstatusOrdenCompra}>
                                {estatusOrdnCompra.estatusOrdencompra}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                </Col>

                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fecha Final</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaFinal"
                        disabled={!filtrosOrdenesCompra.fechaInicial ? true : false}
                        min={filtrosOrdenesCompra.fechaInicial}
                        onChange={onChange}
                        value={filtrosOrdenesCompra.fechaFinal}
                      />
                    </Col>
                  </Row>
                  {/* TODO:  */}
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Proveedor</label>
                    <Col sm={7}>
                      <select
                        name="proveedor"
                        className="form-control"
                        onChange={onChange}
                        value={filtrosOrdenesCompra.proveedor}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {listaProveedoresSimple ? listaProveedoresSimple.map((prov) => {
                          return (
                            <>
                              <option value={prov.idCatProveedor}>
                              {prov.razonSocial + "   MC: " +  formatNumber(prov.montoCredito) + "     CU: " + formatNumber(prov.creditousado) + "    CD: " + formatNumber(prov.creditodisponible)}
                              </option>

                            </>
                          );
                        }):null}
                      </select>
                    </Col>
                  </Row>            

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Comprador</label>
                    <Col sm={7}>
                      <select
                        name="usuarioComprador"
                        className="form-control"
                        onChange={onChange}
                        value={filtrosOrdenesCompra.usuarioComprador}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {usuarios.map((usuario) => {
                          return (
                            <>
                              <option value={usuario.idCatUsuario}>{usuario.nombre}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Importe Máximo</label>
                    <Col sm={7}>
                      <input
                        type="text"
                        className="form-control"
                        name="importeMaximo"
                        value={filtrosOrdenesCompra.importeMaximo ? `$${filtrosOrdenesCompra.importeMaximo}` : `$`}
                        onChange={onChangeImporteMaximo}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="submit"
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Aplicar Filtros
                  </button>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="button"
                    onClick={limpiarFiltros}
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Limpiar Filtros
                  </button>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <Row>
                  <Col>
                      {(!usuario.authorities.includes("ROLE_ADMINISTRACION")) ? (
                      <>
                      <button
                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                        type="button"
                        // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                        onClick={cancelarOrdenCompraConfirm}
                        style={{
                          marginRight: "2.5rem",
                          marginTop: "1rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}>
                        Cancelar
                      </button>

                      <button
                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                        type="button"
                        // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                        onClick={recibidaOrdenCompraConfirm}
                        style={{
                          marginRight: "2.5rem",
                          marginTop: "1rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}>
                        Recepción Compras
                      </button>
                      </>
                    ) : null }

                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_COTIZAR") ? false : true}
                      onClick={() => props.history.push(`/ordenescompra/pago`)}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Planeación y Registro de Pagos
                    </button>

                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_AUTORIZAR") ? false : true}
                      onClick={() => props.history.push(`/comprasproducto`)}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Consulta Compras por Producto
                    </button>

                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_SOLICITAR_OC") ? false : true}
                      onClick={imprimirOrdenCompra}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Imprimir Orden de Compra
                    </button>

                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_SOLICITAR_OC") ? false : true}
                      onClick={imprimirOrdenreabasto}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Imprimir Orden de Reabasto
                    </button>                    
                  </Col>
                </Row>

                <TableComponent
                  pageCount={pageCount}
                  fetchData={fetchData}
                  columns={columns}
                  loading={loadingData}
                  data={ordenesCompraFiltradas}
                  indexOfPage={controlledPageIndex}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        {UISweetAlert.confirm_alert_cancelar_orden_compra ? (
          <SweetAlert
            title="¿Desea cancelar la orden de compra?"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => ordenCompraCancelar()}
            onCancel={() => {
              setCancelarOrdenCompra({
                ...cancelarOrdenCompra,
                comentarioEstatus: "",
              });
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_cancelar_orden_compra: false,
              });
            }}>
            <div>
              <Row>
                <Col>Llene por favo la siguiente información</Col>
              </Row>

              <Row className="form-group mt-2">
                <label className="col-sm-4 col-form-label">Motivo</label>
                <Col sm={7}>
                  <textarea
                    rows={6}
                    className="form-control"
                    name="comentarioEstatus"
                    value={cancelarOrdenCompra.comentarioEstatus}
                    onChange={onChangeCancelarOrdenCompra}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Fecha Cancelación</label>
                <Col sm={7}>
                  <input
                    type="date"
                    className="form-control"
                    disabled={true}
                    name="fechaCancelacion"
                    value={cancelarOrdenCompra.fechaCancelacion}
                  />
                </Col>
              </Row>
            </div>
          </SweetAlert>
        ) : null}

        {UISweetAlert.success_dlg ? (
          <SweetAlert
            success
            title={UISweetAlert.dynamic_title}
            onConfirm={() => {
              if (UISweetAlert.redirect) {
                props.history.push(UISweetAlert.path);
              }
              setUISweetAlert({
                ...UISweetAlert,
                success_dlg: false,
              });
            }}>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {UISweetAlert.error_dlg ? (
          <SweetAlert
            error
            title={UISweetAlert.dynamic_title}
            onConfirm={() =>
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: false,
              })
            }>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {loading ? (
          <SweetAlert title="Cargando!" showConfirm={false}>
            <Spinner />
          </SweetAlert>
        ) : null}
      </div>
    </>
  );
}
