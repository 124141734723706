import {
  FILTRO_ESPACIOSATENCION,
  LISTAR_ESPACIOSATENCION,
  UPLOAD_NUEVO_ESPACIOATENCION,
  ERROR_UPLOAD_NUEVO_ESPACIOATENCION,
  UPLOAD_ESPACIOATENCION,
  ERROR_UPLOAD_ESPACIOATENCION,
  DELETE_ESPACIOATENCION,
  ERROR_DELETE_ESPACIOATENCION,
  LISTAR_ESPACIOSATENCION_AGENDA,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case FILTRO_ESPACIOSATENCION:
      return {
        ...state,
        filtrosEspaciosAtencion: action.payload,
      };
    case LISTAR_ESPACIOSATENCION_AGENDA:
      return {
        ...state,
        espaciosAtencionAgenda: action.payload,
      };
    case LISTAR_ESPACIOSATENCION:
    case UPLOAD_ESPACIOATENCION:
    case DELETE_ESPACIOATENCION:
      return {
        ...state,
        espaciosAtencion: action.payload,
      };
    case UPLOAD_NUEVO_ESPACIOATENCION:
      return {
        ...state,
        espaciosAtencion: state.espaciosAtencion.concat(action.payload),
      };
    case ERROR_UPLOAD_NUEVO_ESPACIOATENCION:
    case ERROR_UPLOAD_ESPACIOATENCION:
    case ERROR_DELETE_ESPACIOATENCION:
      return {
        ...state,
      };
    default:
      return state;
  }
};
