import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import VentaMostradorContext from "../../context/VentaMostrador/VentaMostradorContext";

import InfoGeneralVentaMostrador from "../InformacionGeneralVentaMostrador/index";
import ListadoEstatusVentaMostrador from "../ListadoEstatusVentaMostrador/index";
import ListadoProductosVentaMostrador from "../ListadoProductosVentaMostrador/index";

export default function HistoricoVentaMostrador(props) {
  const { idVentaMostrador } = useParams();

  // ACCESO A ESTADO GLOBAL - VENTA MOSTRADOR
  const ctxVentaMostrador = useContext(VentaMostradorContext);
  const { seleccionarVentaMostrador } = ctxVentaMostrador;

  useEffect(() => {
    seleccionarVentaMostrador(idVentaMostrador, true);
  }, []);

  return (
    <div className="mt-4">
      <InfoGeneralVentaMostrador props={props} />
      <ListadoProductosVentaMostrador props={props} />
      <ListadoEstatusVentaMostrador props={props} />
    </div>
  );
}
