import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import CatRegimenFiscalContext from "../../context/CatRegimenFiscal/CatRegimenFiscalContext";
import CatUsoCfdiContext from "../../context/CatUsoCfdi/CatUsoCfdiContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import LugaresContext from "../../context/Lugares/LugaresContext";

import useWindowDimensions from "../../hooks/dimensionsHook";

import {
  maxCharTelefono,
  maxCharRazonSocial,
  maxCharContacto,
  maxCharRfc,
  maxCharCorreo,
  maxCharCalle,
  maxCharNumExterno,
  maxCharNumInterno,
  maxCharColonia,
  maxCharMunicipio,
  maxCharCP,
} from "../../config/variables";

const Aseguradoras = () => {
  const { width } = useWindowDimensions();
  const catUsoCfdiContext = useContext(CatUsoCfdiContext);
  const { listarCatUsoCfdis, catUsoCfdis } = catUsoCfdiContext;

  const catRegimenFiscalContext = useContext(CatRegimenFiscalContext);
  const { listarCatRegimenFiscales, catRegimenFiscales } = catRegimenFiscalContext;

  const aseguradorasContext = useContext(AseguradorasContext);

  //ACCESO AL ESTADO GLOBAL - AUTENTICACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const lugaresContext = useContext(LugaresContext);
  const { lugares, listarLugares } = lugaresContext;

  const {
    setFiltrosAseguradoras,
    filtrosAseguradoras,
    uploadNuevaAseguradora,
    eliminarAseguradora,
    setDataAseguradora,
    updateAseguradora,
  } = aseguradorasContext;

  const filtrosVacios = {
    razonSocial: "",
    contacto: "",
    rfc: "",
  };

  const datosAseguradoraVacio = {
    aseguradora: "",
    razonSocial: "",
    contacto: "",
    correo: "",
    telefono: "",
    rfc: "",
    calle: "",
    colonia: "",
    idEstado: "",
    municipio: "",
    numExterno: "",
    numInterno: "",
    cp: "",
    telefonoFijo: "",
    telefonoCelular: "",
    cotizacionSinInsumos: null,
  };

  const [datosNuevaAseguradora, setDatosNuevaAseguradora] = useState(datosAseguradoraVacio);
  const [aseguradoraSeleccionada, setAseguradoraSeleccionada] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_aseguradora: false,
    confirm_nueva_aseguradora: false,
    confirm_eliminar_aseguradora: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = useState(0);

  const [data, setData] = useState([
    {
      aseguradora: "",
      razonSocial: "",
      contacto: "",
      correo: "",
      telefono: "",
      rfc: "",
      calle: "",
      colonia: "",
      idEstado: "",
      municipio: "",
      numExterno: "",
      numInterno: "",
      cp: "",
      telefonoFijo: "",
      telefonoCelular: "",
      cotizacionSinInsumos: null,
    },
  ]);
  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setDataAseguradora({ page, limit });

      const data = response.aseguradoras;

      const count = response.count;

      setData(data);

      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server.
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
    // setLoadingData(true);
    // fetchAPIData({
    //   page: pageIndex + 1,
    //   limit: pageSize,
    //   byBusquedaFiltros: false,
    // });
  };

  const handdleCheckedAseguradora = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);

        setAseguradoraSeleccionada(row.original);
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setAseguradoraSeleccionada(null);
      } else {
        setRowSeleccionada(row.id);
        setAseguradoraSeleccionada(row.original);
      }
    },
    [rowSeleccionada]
  );

  useEffect(() => {
    const fetchAllContextData = async () => {
      listarCatRegimenFiscales();
      listarCatUsoCfdis();
      listarLugares();
    };
    fetchAllContextData();
  }, []);

  // Se definen las columnas para la tabla de react-tabe
  // Se debe usar useMemo para optimización y porque lo pide la librería

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCatAseguradora",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedAseguradora(row)}
            />
          );
        },
        show: true,
        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Aseguradora", accessor: "aseguradora", show: true },
      { Header: "Razon Social", accessor: "razonSocial", show: true },
      { Header: "Contacto", accessor: "contacto", show: true },
      { Header: "Correo de Contacto", accessor: "correo", show: true },
      { Header: "Teléfono Contacto", accessor: "telefono", show: true },
      { Header: "RFC", accessor: "rfc", show: true },
      { Header: "Calle", accessor: "calle", show: true },
      { Header: "Colonia", accessor: "colonia", show: true },
      { Header: "Estado", accessor: "estado", show: true },
      { Header: "Num. Externo", accessor: "numExterno", show: true },
      { Header: "Num. Interno", accessor: "numInterno", show: true },
      { Header: "Código Postal", accessor: "cp", show: true },
      { Header: "Teléfono Fijo", accessor: "telefonoFijo", show: true },
      { Header: "Teléfono Celular", accessor: "telefonoCelular", show: true },
      { Header: "Cotización sin Insumos",
        accessor: "cotizacionSinInsumos",
        Cell: ({row}) => {
            return (
                <div className="text-center">
                    {" "}
                    {row.original.cotizacionSinInsumos? <i className="ti-check"></i> : <i className="ti-close"></i>}{" "}
                </div>
            ); 
        },
      },
      { Header: "Factura sin Insumos",
        accessor: "facturaSinInsumos",
        Cell: ({row}) => {
            return (
                <div className="text-center">
                    {" "}
                    {row.original.facturaSinInsumos? <i className="ti-check"></i> : <i className="ti-close"></i>}{" "}
                </div>
            ); 
        },
      },
      { Header: "Cotización sin Soluciones",
        accessor: "cotizacionSinSoluciones",
        Cell: ({row}) => {
            return (
                <div className="text-center">
                    {" "}
                    {row.original.cotizacionSinSoluciones? <i className="ti-check"></i> : <i className="ti-close"></i>}{" "}
                </div>
            ); 
        },
      },
      { Header: "Factura sin Soluciones",
        accessor: "facturaSinSoluciones",
        Cell: ({row}) => {
            return (
                <div className="text-center">
                    {" "}
                    {row.original.facturaSinSoluciones? <i className="ti-check"></i> : <i className="ti-close"></i>}{" "}
                </div>
            ); 
        },
      },                  
    ],
    [rowSeleccionada, handdleCheckedAseguradora]
  );

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  const autorizado =
    usuario.authorities.includes("OP_CATALOGO_ASEGURADORA_CREAR") ||
    usuario.authorities.includes("OP_CATALOGO_ASEGURADORA_MODIFICAR") ||
    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
    usuario.authorities.includes("ROLE_CONTABILIDAD") ||
    usuario.authorities.includes("OP_CATALOGO_ASEGURADORA_ELIMINAR");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function fetchData
     * @param {object} filtrosAseguradoras Filtros enviados al back para filtrar los pacientes
     * @param {int} page Página a regresar de pacientes
     * @param {int} limit Número de pacientes que se van a regresar por llamada al back
     */

    setFiltrosAseguradoras(filtrosAseguradoras);
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosAseguradoras(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltrosAseguradoras({
      ...filtrosAseguradoras,
      [name]: value,
    });
  };

  const changeNuevaAseguradora = (e) => {
    const { name, value, checked } = e.target;
    
    if ((name == "cotizacionSinInsumos") || (name == "facturaSinInsumos") || (name == "cotizacionSinSoluciones") || (name == "facturaSinSoluciones")) {
      setDatosNuevaAseguradora({
        ...datosNuevaAseguradora,
        [name]: checked,
      });
    }else{
      setDatosNuevaAseguradora({
        ...datosNuevaAseguradora,
        [name]: value,
      });
    }
  };

  const changeModificarAseguradora = (e) => {
    const { name, value, checked} = e.target;
    if ((name == "cotizacionSinInsumos") || (name == "facturaSinInsumos") || (name == "cotizacionSinSoluciones") || (name == "facturaSinSoluciones")) {
      setAseguradoraSeleccionada({
        ...aseguradoraSeleccionada,
        [name]: checked,
      });
    }else{
      setAseguradoraSeleccionada({
        ...aseguradoraSeleccionada,
        [name]: value,
      });
    }
  };

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Aseguradora",
          type: "text",
          name: "aseguradora",
          value: filtrosAseguradoras.aseguradora,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Razon Social",
          type: "text",
          name: "razonSocial",
          value: filtrosAseguradoras.razonSocial,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "RFC",
          type: "text",
          name: "rfc",
          value: filtrosAseguradoras.rfc,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Nombre Contacto",
          type: "text",
          name: "contacto",
          value: filtrosAseguradoras.contacto,
          onChange: onChange,
        },
      ],
    },
  ];

  const confirmNuevaAseguradora = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nueva_aseguradora: true,
      });
    }
  };

  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneformat = /^\d{10}$/;
  const rfcformat = /^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?$/;
  const cpformat = /^\d{4,5}$/;

  const validarDatosAseguradora = (aseguradora) => {
    let errorNuevaAseguradora = false;
    if (!errorNuevaAseguradora && (aseguradora.razonSocial === "" || aseguradora.razonSocial === null)) {
      errorNuevaAseguradora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el nombre de la aseguradora",
      });
    }

    if (!errorNuevaAseguradora && aseguradora.correo !== "" && aseguradora.correo && !aseguradora.correo.match(mailformat)) {
      errorNuevaAseguradora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un correo de contacto válido",
      });
    }

    if (
      !errorNuevaAseguradora &&
      aseguradora.telefono !== "" &&
      aseguradora.telefono &&
      !aseguradora.telefono.match(phoneformat)
    ) {
      errorNuevaAseguradora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un teléfono de contacto válido",
      });
    }
    if (!errorNuevaAseguradora && (aseguradora.rfc === "" || aseguradora.rfc === null)) {
      errorNuevaAseguradora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el RFC de la aseguradora",
      });
    }
    if (!errorNuevaAseguradora && !aseguradora.rfc.match(rfcformat)) {
      errorNuevaAseguradora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un RFC válido",
      });
    }

    // if (!errorNuevaAseguradora && (aseguradora.idEstado == null || aseguradora.idEstado === "")) {
    //   errorNuevaAseguradora = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, seleccione el Estado de la aseguradora",
    //   });
    // }

    // if (!errorNuevaAseguradora && (aseguradora.cp === "" || aseguradora.cp === null)) {
    //   errorNuevaAseguradora = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el código postal de la aseguradora",
    //   });
    // }
    if (!errorNuevaAseguradora && aseguradora.cp && aseguradora.cp !== "" && !aseguradora.cp.toString().match(cpformat)) {
      errorNuevaAseguradora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un código postal válido",
      });
    }
    // if (!errorNuevaAseguradora && (aseguradora.telefonoFijo === "" || aseguradora.telefonoFijo === null)) {
    //   errorNuevaAseguradora = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el teléfono fijo",
    //   });
    // }
    if (
      !errorNuevaAseguradora &&
      aseguradora.telefonoFijo !== "" &&
      aseguradora.telefonoFijo &&
      !aseguradora.telefonoFijo.match(phoneformat)
    ) {
      errorNuevaAseguradora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un teléfono fijo válido",
      });
    }
    // if (!errorNuevaAseguradora && (aseguradora.telefonoCelular === "" || aseguradora.telefonoCelular === null)) {
    //   errorNuevaAseguradora = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el teléfono celular",
    //   });
    // }
    if (
      !errorNuevaAseguradora &&
      aseguradora.telefonoCelular !== "" &&
      aseguradora.telefonoCelular &&
      !aseguradora.telefonoCelular.match(phoneformat)
    ) {
      errorNuevaAseguradora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un teléfono celular válido",
      });
    }
    // if (!errorNuevaAseguradora && (aseguradora.idCatRegimenFiscal == null || aseguradora.idCatRegimenFiscal === "")) {
    //   errorNuevaAseguradora = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, seleccione el Régimen Fiscal de la aseguradora",
    //   });
    // }

    // if (!errorNuevaAseguradora && (aseguradora.idCatUsoCfdi == null || aseguradora.idCatUsoCfdi === "")) {
    //   errorNuevaAseguradora = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, seleccione el Uso de Cfdi de la aseguradora",
    //   });
    // }

    return errorNuevaAseguradora;
  };

  const agregarNuevaAseguradora = async () => {
    const aseguradoraValida = validarDatosAseguradora(datosNuevaAseguradora);
    console.log("🚀 ~ file: index.js:495 ~ agregarNuevaAseguradora ~ aseguradoraValida:", aseguradoraValida);
    if (!aseguradoraValida) {
      setLoading(true);

      const res = await uploadNuevaAseguradora(datosNuevaAseguradora);

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nueva_aseguradora: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nueva_aseguradora: false,
          dynamic_description: "Se guardó la nueva aseguradora correctamente",
        });
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setDatosNuevaAseguradora(datosAseguradoraVacio);
      }
    }
  };

  // const updateDataOnDeleteAseguradora = async (idCatAseguradora) => {
  //   const updatedData = data.filter((paciente) => paciente.idCatAseguradora != idCatAseguradora);
  //   setData(updatedData);
  // };

  const checkEliminarAseguradora = () => {
    if (autorizado && aseguradoraSeleccionada) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_aseguradora: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione una aseguradora",
      });
    }
  };

  const confirmEliminarAseguradora = async () => {
    setLoading(true);
    const res = await eliminarAseguradora(aseguradoraSeleccionada.idCatAseguradora);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_aseguradora: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_aseguradora: false,
        dynamic_description: "Se eliminó la aseguradora correctamente",
      });
      // updateDataOnDeleteAseguradora(aseguradoraSeleccionada.idCatAseguradora);
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setAseguradoraSeleccionada(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarAseguradora = () => {
    if (autorizado && aseguradoraSeleccionada) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_aseguradora: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione una aseguradora",
      });
    }
  };

  const confirmModicarAseguradora = async () => {
    const aseguradoraValida = validarDatosAseguradora(aseguradoraSeleccionada);
    if (!aseguradoraValida) {
      setLoading(true);
      const res = await updateAseguradora(aseguradoraSeleccionada);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_aseguradora: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_aseguradora: false,
          dynamic_description: "Se modificó la aseguradora correctamente",
        });

        // const aseguradorasActualizadas = await data.map((aseguradora) => {
        //   if (res.data.idCatAseguradora == aseguradora.idCatAseguradora) {
        //     return (aseguradora = res.data);
        //   } else {
        //     return aseguradora;
        //   }
        // });

        // setData(aseguradorasActualizadas);
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setAseguradoraSeleccionada(null);
        setRowSeleccionada(null);
      }
    }
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Catálogo de Aseguradoras</h3>
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Aseguradoras</h4>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevaAseguradora()}>
                      Nueva Aseguradora
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarAseguradora()}>
                      Modificar Aseguradora
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarAseguradora()}>
                      Eliminar Aseguradora
                    </button>
                  ) : null}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={data}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nueva_aseguradora ? (
        <SweetAlert
          title="Nueva Aseguradora"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            agregarNuevaAseguradora();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nueva_aseguradora: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">
                    Nombre Aseguradora<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="aseguradora"
                      maxLength={maxCharRazonSocial}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.aseguradora ? datosNuevaAseguradora.aseguradora : ""}
                    />
                    {datosNuevaAseguradora.aseguradora === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>   
                  <label className="col-sm-4 col-form-label">
                    Razon Social<span className="text-xs text-red-700">*</span>
                  </label>                                 
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="razonSocial"
                      maxLength={maxCharRazonSocial}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.razonSocial ? datosNuevaAseguradora.razonSocial : ""}
                    />
                    {datosNuevaAseguradora.razonSocial === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-4 col-form-label">Nombre Contacto</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="contacto"
                      maxLength={maxCharContacto}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.contacto ? datosNuevaAseguradora.contacto : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Correo Contacto</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="correo"
                      maxLength={maxCharCorreo}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.correo ? datosNuevaAseguradora.correo : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Contacto</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="telefono"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.telefono ? datosNuevaAseguradora.telefono : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">
                    RFC<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="rfc"
                      maxLength={maxCharRfc}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.rfc ? datosNuevaAseguradora.rfc : ""}
                    />
                    {datosNuevaAseguradora.rfc === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    {!datosNuevaAseguradora.rfc.match(rfcformat) && datosNuevaAseguradora.rfc !== "" && (
                      <span className="text-sm text-red-700">RFC inválido</span>
                    )}
                  </Col>

                  <label className="col-sm-4 col-form-label">Calle</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="calle"
                      maxLength={maxCharCalle}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.calle ? datosNuevaAseguradora.calle : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Colonia</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="colonia"
                      maxLength={maxCharColonia}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.colonia ? datosNuevaAseguradora.colonia : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Estado</label>
                  <Col sm={7} className="pb-3">
                    <select
                      className="form-control"
                      onChange={changeNuevaAseguradora}
                      name="idEstado"
                      value={datosNuevaAseguradora.idEstado ? datosNuevaAseguradora.idEstado : ""}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {lugares.map((lugar) => {
                        return <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">Municipio</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="municipio"
                      maxLength={maxCharMunicipio}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.municipio ? datosNuevaAseguradora.municipio : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Num. Externo</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="numExterno"
                      maxLength={maxCharNumExterno}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.numExterno ? datosNuevaAseguradora.numExterno : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Num. Interno</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="numInterno"
                      maxLength={maxCharNumInterno}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.numInterno ? datosNuevaAseguradora.numInterno : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Código Postal</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="cp"
                      maxLength={maxCharCP}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.cp ? datosNuevaAseguradora.cp : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Fijo</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoFijo"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.telefonoFijo ? datosNuevaAseguradora.telefonoFijo : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Celular</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoCelular"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.telefonoCelular ? datosNuevaAseguradora.telefonoCelular : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Régimen Fiscal</label>
                  <Col sm={7} className="pb-3">
                    <select
                      className="form-control"
                      name="idCatRegimenFiscal"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.idCatRegimenFiscal ? datosNuevaAseguradora.idCatRegimenFiscal : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catRegimenFiscales.map((regimen) => {
                        return (
                          <option value={regimen.idCatRegimenFiscal}>{`${regimen.clave} - ${regimen.regimenFiscal}`}</option>
                        );
                      })}
                    </select>
                  </Col>

                  <label className="col-sm-4 col-form-label">Uso CFDi</label>
                  <Col sm={7} className="pb-3">
                    <select
                      className="form-control"
                      name="idCatUsoCfdi"
                      onChange={changeNuevaAseguradora}
                      value={datosNuevaAseguradora.idCatUsoCfdi ? datosNuevaAseguradora.idCatUsoCfdi : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catUsoCfdis.map((usoCfdi) => {
                        return <option value={usoCfdi.idCatUsoCfdi}>{`${usoCfdi.clave} - ${usoCfdi.usoCfdi}`}</option>;
                      })} 
                    </select>
                  </Col>

                  <label className="col-sm-4 col-form-label">Cotización sin insumos</label>
                  <Col sm={7}>
                      <input
                          type="checkbox"
                          name="cotizacionSinInsumos"
                          className="form-control"
                          onChange={changeNuevaAseguradora}
                          checked={datosNuevaAseguradora.cotizacionSinInsumos}
                      />
                  </Col>     

                  <label className="col-sm-4 col-form-label">Factura sin insumos</label>
                  <Col sm={7}>
                      <input
                          type="checkbox"
                          name="facturaSinInsumos"
                          className="form-control"
                          onChange={changeNuevaAseguradora}
                          checked={datosNuevaAseguradora.facturaSinInsumos}
                      />
                  </Col> 
                  
                  <label className="col-sm-4 col-form-label">Cotización sin Soluciones</label>
                  <Col sm={7}>
                      <input
                          type="checkbox"
                          name="cotizacionSinSoluciones"
                          className="form-control"
                          onChange={changeNuevaAseguradora}
                          checked={datosNuevaAseguradora.cotizacionSinSoluciones}
                      />
                  </Col> 

                  <label className="col-sm-4 col-form-label">Factura sin Soluciones</label>
                  <Col sm={7}>
                      <input
                          type="checkbox"
                          name="facturaSinSoluciones"
                          className="form-control"
                          onChange={changeNuevaAseguradora}
                          checked={datosNuevaAseguradora.facturaSinSoluciones}
                      />
                  </Col>                                                       

                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label"></label>
                  <Col sm={7} className="pb-3">
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDatosNuevaAseguradora(datosAseguradoraVacio)}>
                      Limpiar Datos
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}
      {UISweetAlert.confirm_eliminar_aseguradora ? (
        <SweetAlert
          title="¿Desea eliminar la aseguradora seleccionada?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "60%",
          }}
          onConfirm={() => confirmEliminarAseguradora()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_aseguradora: false,
            });
          }}></SweetAlert>
      ) : null}
      {UISweetAlert.confirm_modificar_aseguradora ? (
        <SweetAlert
          title="Modificar Datos de la Aseguradora"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          showCancel
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            confirmModicarAseguradora();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_aseguradora: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                <label className="col-sm-4 col-form-label">Nombre Aseguradora</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="aseguradora"
                      maxLength={maxCharRazonSocial}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.aseguradora ? aseguradoraSeleccionada.aseguradora : ""}
                    />
                  </Col>                  
                  <label className="col-sm-4 col-form-label">Razon Social</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="razonSocial"
                      maxLength={maxCharRazonSocial}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.razonSocial ? aseguradoraSeleccionada.razonSocial : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Nombre Contacto</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="contacto"
                      maxLength={maxCharContacto}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.contacto ? aseguradoraSeleccionada.contacto : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Correo Contacto</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="correo"
                      autoComplete="off"
                      maxLength={maxCharCorreo}
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.correo ? aseguradoraSeleccionada.correo : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Contacto</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefono"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.telefono ? aseguradoraSeleccionada.telefono : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">RFC</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="rfc"
                      maxLength={maxCharRfc}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.rfc ? aseguradoraSeleccionada.rfc : ""}
                    />
                    {aseguradoraSeleccionada.rfc === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                    {!aseguradoraSeleccionada.rfc.match(rfcformat) && aseguradoraSeleccionada.rfc !== "" && (
                      <span className="text-sm text-red-700">RFC inválido</span>
                    )}
                  </Col>

                  <label className="col-sm-4 col-form-label">Calle</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="calle"
                      maxLength={maxCharCalle}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.calle ? aseguradoraSeleccionada.calle : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Colonia</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="colonia"
                      maxLength={maxCharColonia}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.colonia ? aseguradoraSeleccionada.colonia : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Estado</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      onChange={changeModificarAseguradora}
                      name="idEstado"
                      value={aseguradoraSeleccionada.idEstado ? aseguradoraSeleccionada.idEstado : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {lugares.map((lugar) => {
                        return <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">Municipio</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="municipio"
                      maxLength={maxCharMunicipio}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.municipio ? aseguradoraSeleccionada.municipio : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Num. Externo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numExterno"
                      maxLength={maxCharNumExterno}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.numExterno ? aseguradoraSeleccionada.numExterno : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Num. Interno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numInterno"
                      maxLength={maxCharNumInterno}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.numInterno ? aseguradoraSeleccionada.numInterno : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Código Postal</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cp"
                      maxLength={maxCharCP}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.cp ? aseguradoraSeleccionada.cp : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Fijo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoFijo"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.telefonoFijo ? aseguradoraSeleccionada.telefonoFijo : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Celular</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoCelular"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.telefonoCelular ? aseguradoraSeleccionada.telefonoCelular : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Régimen Fiscal</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatRegimenFiscal"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.idCatRegimenFiscal ? aseguradoraSeleccionada.idCatRegimenFiscal : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catRegimenFiscales.map((regimen) => {
                        return (
                          <option value={regimen.idCatRegimenFiscal}>{`${regimen.clave} - ${regimen.regimenFiscal}`}</option>
                        );
                      })}
                    </select>
                  </Col>

                  <label className="col-sm-4 col-form-label">Uso CFDi</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatUsoCfdi"
                      onChange={changeModificarAseguradora}
                      value={aseguradoraSeleccionada.idCatUsoCfdi ? aseguradoraSeleccionada.idCatUsoCfdi : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catUsoCfdis.map((usoCfdi) => {
                        return <option value={usoCfdi.idCatUsoCfdi}>{`${usoCfdi.clave} - ${usoCfdi.usoCfdi}`}</option>;
                      })}
                    </select>
                  </Col>

                  <label className="col-sm-4 col-form-label">Cotización sin Insumos</label>
                  <Col sm={7}>
                      <input
                          type="checkbox"
                          name="cotizacionSinInsumos"
                          className="form-control"
                          onChange={changeModificarAseguradora}
                          checked={aseguradoraSeleccionada.cotizacionSinInsumos}
                      />
                  </Col>  

                  <label className="col-sm-4 col-form-label">Factura sin Insumos</label>
                  <Col sm={7}>
                      <input
                          type="checkbox"
                          name="facturaSinInsumos"
                          className="form-control"
                          onChange={changeModificarAseguradora}
                          checked={aseguradoraSeleccionada.facturaSinInsumos}
                      />
                  </Col>

                  <label className="col-sm-4 col-form-label">Cotización sin Soluciones</label>
                  <Col sm={7}>
                      <input
                          type="checkbox"
                          name="cotizacionSinSoluciones"
                          className="form-control"
                          onChange={changeModificarAseguradora}
                          checked={aseguradoraSeleccionada.cotizacionSinSoluciones}
                      />
                  </Col>

                  <label className="col-sm-4 col-form-label">Factura sin Soluciones</label>
                  <Col sm={7}>
                      <input
                          type="checkbox"
                          name="facturaSinSoluciones"
                          className="form-control"
                          onChange={changeModificarAseguradora}
                          checked={aseguradoraSeleccionada.facturaSinSoluciones}
                      />
                  </Col>                                                      
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default Aseguradoras;
