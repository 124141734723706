import React, { useReducer } from "react";

import CalendarioContext from "./CalendarioContext";
import CalendarioReducer from "./CalendarioReducer";

import { LISTAR_CITAS, FILTRO_CITAS_ESPACIOS, FILTRO_CITAS_PACIENTES, FILTRO_CITAS_ASEGURADORAS } from "../../types";

import clienteAxios from "../../config/axios";

export default function CalendarioState(props) {
  const initialState = {  
    citas: [],
    filtroCitasEspacios: null,
    filtroCitasPacientes: null,
  };

  const [state, dispatch] = useReducer(CalendarioReducer, initialState);

  const listarCitas = async (fechaBusqueda, espacios, pacientes, AseguradorasPar,todosEspacios, todosPacientes, porAseguradora) => {
    const checkedEspacios = espacios && espacios.length > 0 ? espacios : [];

    /*let lista = [];
    if(AseguradorasPar.length > 0){
      AseguradorasPar.map((optionsInt) =>
      {
        let Temporal = {
          idCatAseguradora : optionsInt.idCatAseguradora,
          aseguradora : optionsInt.aseguradora,
        }           
        lista.push(Temporal);
      });
    };

    const  aseguradorasRegisro = lista;*/

    const res = await clienteAxios.post(
      "/agenda/filtro/citas",
      {
        incluirTratamientos: false,
        espaciosVo: checkedEspacios,
        pacientesVo: pacientes,
        //aseguradorasVo: aseguradorasRegisro,
        aseguradorasVo: AseguradorasPar,
        todosEspacios: todosEspacios,
        todosPacientes: todosPacientes,
        fechaBusqueda: fechaBusqueda,
        porAseguradora: porAseguradora,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      }
    );
   /* res.data TRAE LA DESCRIPCION DE NOMBRE, MEDICO Y TIPO DE CONSULTA Y LO DIBUJA COLOR UVA   */
    if (res.data) {
      //alert(res.data.length)
      res.data = res.data.map((cita) => {
        let className = "font-bold bg-purple";
        if (cita.extendedProps.color != "") {
          className = cita.extendedProps.color;
        }

        let textColor = "white"; // Default to automatic text color
        switch (className) {
          case "font-bold bg-yellow":
            textColor = "black";
            break;
          default:
            break;
        }
        return { ...cita, className, textColor };
      });
    }

    dispatch({
      type: LISTAR_CITAS,
      payload: res.data,
    });
    return res.data;
  };

  const setFiltroCitasEspacios = async (filtroCitasEspacios) => {
    dispatch({
      type: FILTRO_CITAS_ESPACIOS,
      payload: filtroCitasEspacios,
    });

    return true;
  };

  const setFiltroCitasPacientes = async (filtroCitasPacientes) => {
    dispatch({
      type: FILTRO_CITAS_PACIENTES,
      payload: filtroCitasPacientes,
    });

    return true;
  };

  const setFiltroCitasAseguradoras = async (filtroCitasAseguradoras) => {
    dispatch({
      type: FILTRO_CITAS_ASEGURADORAS,
      payload: filtroCitasAseguradoras,
    });

    return true;
  };
 
  const uploadCita = async (cita) => {
    try {
      const res = await clienteAxios.post(`/agenda/cita/guardar`, cita, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      //Aqui checo si la cita esta en la lista, si no esta la agrego, y si esta la remplazo con los cambios
      let citas;

      const citaEdited = state.citas.filter((cita) => cita.id == res.data.id)[0];

      if (!citaEdited) {
        citas = state.citas.concat(res.data);
      } else {
        citas = state.citas.map((cita) => {
          if (cita.id == res.data.id) {
            cita = res.data;
          }
          return cita;
        });
      }

      dispatch({
        type: LISTAR_CITAS,
        payload: citas,
      });

      return res;
    } catch (error) {
      // dispatch({
      //     type: ERROR_UPLOAD_ORDEN_COMPRA,
      //     payload: null
      // })

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }

    // if(cita.id){

    //     const citas = await state.citas.map( (env) =>  {
    //         if(env.id == cita.id){
    //             env.start = cita.start
    //             env.end = cita.end
    //             env.title = cita.title
    //             env.extendedProps = {
    //                 espacio: cita.espacio,
    //                 cliente: cita.cliente,
    //                 comentarios: cita.comentarios,
    //             }
    //             return env;
    //         }else{
    //             return env
    //         }
    //     })
    //     dispatch({
    //         type: LISTAR_CITAS,
    //         payload: citas
    //     })

    // }else{

    //     let id = state.citas[state.citas.length -1].id +1;

    //     let nueva = {
    //         id:id,
    //         title: cita.title,
    //         start: cita.start,
    //         end: cita.end,
    //         // className: "bg-osvaldo",
    //         color: 'blue',
    //         extendedProps: {
    //             espacio: cita.espacio,
    //             cliente: cita.cliente,
    //             comentarios: cita.comentarios,
    //         }
    //     }
    //     dispatch({
    //         type: LISTAR_CITAS,
    //         payload: [...state.citas, nueva]
    //     })

    // }
  };

  const deleteCita = async (cita) => {
    try {
      const res = await clienteAxios.post(`/agenda/cita/eliminar`, cita, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      const citas = await state.citas.filter((env) => env.id != cita.id);

      dispatch({
        type: LISTAR_CITAS,
        payload: citas,
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };
  
  const exportAgenda = async (filtrosImpresion) => {
    try {
      const res = await clienteAxios.post("/agenda/export", filtrosImpresion, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=agenda.pdf",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

        var file = new Blob([res.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  const exportAgendaPorDia = async (filtrosImpresion) => {
    try {
      const res = await clienteAxios.post("/agenda/export/dia", filtrosImpresion, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=agenda.pdf",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      var file = new Blob([res.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  const listarCitasPaciente = async (idCatPaciente) => {
    const res = await clienteAxios.post(
      "/agenda/paciente/citas",
      {
        idCatPaciente: idCatPaciente,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      }
    );
    console.log("🚀 ~ file: CalendarioState.js:293 ~ listarCitasPaciente ~ res:", res);

    return res;
  };

  const traerCita = async (id) => {
    const res = await clienteAxios.get(`/agenda/cita/${id}`, { 
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });
    return res.data;
  };
  
  return (
    <CalendarioContext.Provider
      value={{
        citas: state.citas,
        filtroCitasEspacios: state.filtroCitasEspacios,
        filtroCitasPacientes: state.filtroCitasPacientes,
        filtroCitasAseguradoras: state.setFiltroCitasAseguradoras,
        setFiltroCitasEspacios,
        setFiltroCitasPacientes,
        setFiltroCitasAseguradoras,
        listarCitas,
        uploadCita,
        deleteCita,
        exportAgenda,
        exportAgendaPorDia,
        listarCitasPaciente,
        traerCita,       
      }}>
      {props.children}
    </CalendarioContext.Provider>
  );
}
