/* eslint-disable import/no-anonymous-default-export */
import { LISTAR_OCUPACIONES } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_OCUPACIONES:
      return {
        ...state,
        ocupaciones: action.payload,
      };

    default:
      return state;
  }
};
