import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import AuthContext from "../../context/Auth/AuthContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import CatRolesContext from "../../context/CatRoles/CatRolesContext";

import useWindowDimensions from "../../hooks/dimensionsHook";

export default function Usuarios(props) {
  const { width } = useWindowDimensions();

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_editar_usuario: false,
    confirm_nuevo_usuario: false,
    editar_usuario: false,
    refresh_editar_usuario: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);

  const [indexeliminarRol, setIndexEliminarRol] = useState(null);

  //ACCESO AL ESTADO GLOBAL - Estatus Vale
  const ctxUsuarios = useContext(UsuariosContext);
  const {
    usuarios,
    listarUsuarios,
    usuarioEditar,
    setUsuarioEditar,
    uploadEditarUsario,
    nuevoUsuario,
    setNuevoUsuario,
    uploadNuevoUsuario,
    solicitarReinicioPassword,
  } = ctxUsuarios;

  //ACCESO AL ESTADO GLOBAL - ROLES
  const ctxRoles = useContext(CatRolesContext);
  const { roles, listarRoles } = ctxRoles;

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const usuarioNuevoRol = async () => {
    let nuevoRolObjt = {
      idCatRol: "",
      rol: "",
      nuevoRolUsr: true,
    };
    usuarioEditar.catRoles = usuarioEditar.catRoles.concat(nuevoRolObjt);
    setUsuarioEditar(usuarioEditar);
    refreshModalEditarUsuario();
  };

  const changeUsarioEditar = async (e) => {
    let value = e.target.value;

    if (e.target.name == "password" && value == "") {
      value = null;
    }

    if (e.target.name == "activo") {
      value = !usuarioEditar.activo;
    }

    setUsuarioEditar({
      ...usuarioEditar,
      [e.target.name]: value,
    });
  };

  const resetUsuarioEditar = async () => {
    usuarioEditar.catRoles = usuarioEditar.catRoles.filter((role) => !role.nuevoRolUsr);
    setUsuarioEditar({});
  };

  const changeNuevoRol = async (e, index) => {
    let idCatRolNuevo = e.target.value;

    let nuevoRol = usuarioEditar.catRoles[index];

    const rolRepetido = await checarRolRepetidoUsuario(usuarioEditar, index, idCatRolNuevo);
    if (rolRepetido) {
      nuevoRol.idCatRol = "";
      nuevoRol.rol = "";
      setUsuarioEditar(usuarioEditar);
      return;
    }

    let rolSeleccionado = roles.filter((rol) => rol.idCatRol == idCatRolNuevo)[0];

    nuevoRol.idCatRol = rolSeleccionado.idCatRol;
    nuevoRol.rol = rolSeleccionado.rol;
    setUsuarioEditar(usuarioEditar);
  };

  const [UsuariosFiltrados, setUsuariosFiltrados] = useState([]);

  const onChangeFiltrarNombre = async (e) => 
  {
    setUsuariosFiltrados
    ({
        ...UsuariosFiltrados,
        [e.target.nombre]: e.target.value,
    });

    let value = e.target.value;
    setUsuariosFiltrados
    (
        usuarios.filter((usuariosp) => 
        {
            if (usuariosp.nombre.toLowerCase().startsWith(value.toLowerCase(), 0) 
               ||  usuariosp.usuario.toLowerCase().startsWith(value.toLowerCase(), 0) ) 
            {
                return true;
            } else {
                return false;
            }
        })
    );
  };

  const checarRolRepetidoUsuario = async (usr, index, idCatRolNuevo) => {
    let repetido = false;
    usr.catRoles.map((rol, i) => {
      if (i != index && rol.idCatRol == idCatRolNuevo && !repetido) {
        repetido = true;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "El rol esta repetido en este usuario",
        });
      }
    });
    return repetido;
  };

  const refreshModalEditarUsuario = async () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_editar_usuario: false,
    });
    setUISweetAlert({
      ...UISweetAlert,
      confirm_editar_usuario: true,
      refresh_editar_usuario: false,
    });
  };

  const eliminarUsuarioNuevoRol = async () => {
    usuarioEditar.catRoles = usuarioEditar.catRoles.filter((rol, index) => index != indexeliminarRol);
    setUsuarioEditar(usuarioEditar);
    refreshModalEditarUsuario();

    setUISweetAlert({
      ...UISweetAlert,
      success_dlg: true,
      dynamic_title: "Exito",
      confirm_eliminar_rol: false,
      dynamic_description: "Se eliminó el rol",
    });
  };

  const uploadUsuarioEditar = async () => {
    let readyToSend = true;

    if (!usuarioEditar.nombre) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, llene el campo nombre",
      });
      return;
    }

    if (!usuarioEditar.usuario) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, llene el campo usuario",
      });
      return;
    }

    if (!usuarioEditar.correo) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, llene el campo correo",
      });
      return;
    } else {
      const validEmail = await validateEmail(usuarioEditar);
      if (!validEmail) {
        readyToSend = validEmail;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, introduzca un correo válido",
        });
        return;
      }
    }

    if (usuarioEditar.password != null && usuarioEditar.confirmPassword != usuarioEditar.password) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Las contraseñas nos son iguales",
      });
      return;
    }

    usuarioEditar.catRoles.map((catRolesUsr) => {
      if (catRolesUsr.idCatRol == "" && readyToSend) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, llene el nuevo rol",
        });
        readyToSend = false;
      }
    });

    if (readyToSend) {
      setLoading(true);
      const res = await uploadEditarUsario();

      if (res.data.error) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_editar_usuario: false,
          dynamic_description: "Se registraron los cambios",
        });
        listarUsuarios();
        listarRoles();
      }
    }
  };

  const changeUsarioNuevo = async (e) => {
    setNuevoUsuario({
      ...nuevoUsuario,
      [e.target.name]: e.target.value,
    });
  };

  const nuevoUsuarioNuevoRol = async () => {
    let nuevoRolObjt = {
      idCatRol: "",
      rol: "",
      nuevoRolUsr: true,
    };
    nuevoUsuario.catRoles = nuevoUsuario.catRoles.concat(nuevoRolObjt);
    setNuevoUsuario(nuevoUsuario);
  };

  const changeNuevoRolNuevoUsuario = async (e, index) => {
    let idCatRolNuevo = e.target.value;

    let nuevoRol = nuevoUsuario.catRoles[index];

    const rolRepetido = await checarRolRepetidoUsuario(nuevoUsuario, index, idCatRolNuevo);
    if (rolRepetido) {
      nuevoRol.idCatRol = "";
      nuevoRol.rol = "";
      setNuevoUsuario(nuevoUsuario);
      return;
    }

    let rolSeleccionado = roles.filter((rol) => rol.idCatRol == idCatRolNuevo)[0];
    nuevoRol.idCatRol = rolSeleccionado.idCatRol;
    nuevoRol.rol = rolSeleccionado.rol;
    setNuevoUsuario(nuevoUsuario);
  };

  const eliminirRolNuevoUsuario = async () => {
    nuevoUsuario.catRoles = nuevoUsuario.catRoles.filter((rol, index) => index != indexeliminarRol);
    setNuevoUsuario(nuevoUsuario);

    setUISweetAlert({
      ...UISweetAlert,
      success_dlg: true,
      dynamic_title: "Exito",
      confirm_eliminar_rol_nuevo_usuario: false,
      dynamic_description: "Se eliminó el rol",
    });
  };

  const uploadUsuarioNuevo = async () => {
    let readyToSend = true;
    if (!nuevoUsuario.nombre) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, llene el campo nombre",
      });
      return;
    }

    if (!nuevoUsuario.usuario) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, llene el campo usuario",
      });
      return;
    }

    if (!nuevoUsuario.correo) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, llene el campo correo",
      });
      return;
    } else {
      const validEmail = await validateEmail(nuevoUsuario);
      if (!validEmail) {
        readyToSend = validEmail;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, introduzca un correo válido",
        });
        return;
      }
    }

    if (!nuevoUsuario.password) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, llene el campo de contrseña",
      });
      return;
    }

    if (nuevoUsuario.confirmPassword != nuevoUsuario.password) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Las contraseñas nos son iguales",
      });
      return;
    }

    if (!nuevoUsuario.tipoUsuario) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, llene el campo de tipo de usuario",
      });
      return;
    }

    if (nuevoUsuario.catRoles.length < 1 && readyToSend) {
      readyToSend = false;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, incluya al menos 1 rol",
      });
    }

    if (readyToSend) {
      nuevoUsuario.catRoles.map((catRolesUsr) => {
        if (catRolesUsr.idCatRol == "" && readyToSend) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Por favor, llene el nuevo rol",
          });
          readyToSend = false;
        }
      });
    }

    if (readyToSend) {
      setLoading(true);
      const res = await uploadNuevoUsuario();
      if (res.data.error) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nuevo_usuario: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nuevo_usuario: false,
          dynamic_description: "Se registraron los cambios",
        });
        listarUsuarios();
        listarRoles();
      }
    }
  };

  const validateEmail = async (usr) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(usr.correo);
  };

  const reiniciarContraseñaUsuario = async () => {
    setLoading(true);
    const res = await solicitarReinicioPassword();
    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_reiniciar_contraseña: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_reiniciar_contraseña: false,
        dynamic_description: "Se registraron los cambios",
      });
      listarUsuarios();
      listarRoles();
    }
  };

  const changeTipoUsuarioNuevo = async (e) => {
    let tipoUsuario = e.target.value;
    nuevoUsuario.tipoUsuario = tipoUsuario;
    if (tipoUsuario == "MEDICO") {
      nuevoUsuario.medico = true;
      nuevoUsuario.empleado = false;
    }
    if (tipoUsuario == "EMPLEADO") {
      nuevoUsuario.medico = false;
      nuevoUsuario.empleado = true;
    }
    setNuevoUsuario(nuevoUsuario);
  };

  useEffect(() => {
    const fetchUsuarios = async () => {
      const lista = await listarUsuarios();
      setUsuariosFiltrados(lista);
      listarRoles();
    };
    fetchUsuarios();
  }, []);

  return (
    <div  className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Administración de Usuarios</h3>
      <Card className="mt-4">
        <CardBody className="mt-4">
          <Row>
              <Col>
                  {usuario.authorities.includes("OP_ADM_USR_CREAR") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ? (
                      <button
                        className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-2 mr-4"
                        onClick={() => {
                          setUISweetAlert({
                            ...UISweetAlert,
                            confirm_nuevo_usuario: true,
                          });
                        }}>
                        Nuevo Usuario
                      </button>
                    ) : null}
                  {/* <button
                      className="btn btn-primary-orange-umo w-md waves-effect waves-light mt-2 mr-4"
                      type="button"
                      hidden={usuario.authorities.includes("OP_CONSULTA_ESPACIOS_BORRAR") ? false : true}
                      onClick={confirmEliminarEspacio}
                  >
                      Borrar
                  </button> */}
              </Col>
              <label className="col-sm-2 col-form-label">Nombre/Usuario</label> 
              <Col sm={4}>
                  <input
                      className="form-control"
                      type="text"
                      name="nombreUsuario"
                      placeholder="Nombre del usuario..."
                      onChange={onChangeFiltrarNombre}
                      //value={UsuariosFiltrados.nombre ? UsuariosFiltrados.nombre : ""}
                  />
              </Col>
          </Row>

          <div className="table-responsive mt-4">
            <table className="table table-hover table-centered table-nowrap mb-0">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Correo</th>
                  <th scope="col">
                    Fecha de<br></br>Creación
                  </th>
                  <th scope="col">Activo</th>
                  <th scope="col">Detalles</th>
                  <th scope="col">Editar</th>
                  <th scope="col">
                    Reiniciar<br></br>Contraseña
                  </th>
                </tr>
              </thead>
              <tbody>
                {UsuariosFiltrados.map((usuarioLista, index) => {
                  return (
                    <>
                      <tr key={usuarioLista}>
                        <td>{usuarioLista.nombre}</td>
                        <td>{usuarioLista.usuario}</td>
                        <td>{usuarioLista.correo}</td>
                        <td> {usuarioLista.fechaCreacion ? usuarioLista.fechaCreacion.split("T")[0] : ""} </td>
                        <td> {usuarioLista.activo ? "SI" : "NO"} </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                            onClick={() => {
                              setUsuarioEditar(usuarioLista);
                              setUISweetAlert({
                                ...UISweetAlert,
                                confirm_editar_usuario: true,
                                editar_usuario: false,
                              });
                            }}>
                            Detalles
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                            onClick={() => {
                              setUsuarioEditar(usuarioLista);
                              setUISweetAlert({
                                ...UISweetAlert,
                                confirm_editar_usuario: true,
                                editar_usuario: true,
                              });
                            }}>
                            Editar
                          </button>
                        </td>
                        <td>
                          <button
                            hidden={usuarioLista.reiniciarPassword}
                            type="button"
                            className="btn btn-primary-blue-umo w-md waves-effect waves-light flex"
                            onClick={() => {
                              setUsuarioEditar(usuarioLista);
                              setUISweetAlert({
                                ...UISweetAlert,
                                confirm_reiniciar_contraseña: true,
                              });
                            }}>
                            Reinicio
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_editar_usuario ? (
        <SweetAlert
          title={UISweetAlert.editar_usuario ? "Editar Usuario" : "Detalles de Usuario"}
          showCancel
          showConfirm={UISweetAlert.editar_usuario}
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            uploadUsuarioEditar();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_editar_usuario: false,
            });
            resetUsuarioEditar();
          }}>
          <div>
            Para que se vean reflejados los cambios, se debe de confirmar.
            <div>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!UISweetAlert.editar_usuario}
                    checked={usuarioEditar.activo}
                    onChange={changeUsarioEditar}
                    name="activo"
                    color="primary"
                  />
                }
                label="Activo"
              />
            </div>
          </div>

          <div>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Nombre</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="nombre"
                  disabled={!UISweetAlert.editar_usuario}
                  onChange={changeUsarioEditar}
                  value={usuarioEditar.nombre}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Usuario</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="usuario"
                  disabled={!UISweetAlert.editar_usuario}
                  onChange={changeUsarioEditar}
                  value={usuarioEditar.usuario}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Correo</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="correo"
                  disabled={!UISweetAlert.editar_usuario}
                  onChange={changeUsarioEditar}
                  value={usuarioEditar.correo}
                />
              </Col>
            </Row>
            {UISweetAlert.editar_usuario && (
              <>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Contraseña</label>
                  <Col sm={7}>
                    <input
                      placeholder="Deje en blanco para permanecer con la misma contraseña"
                      type="password"
                      className="form-control"
                      name="password"
                      disabled={!UISweetAlert.editar_usuario}
                      onChange={changeUsarioEditar}
                      value={usuarioEditar.password}
                    />
                  </Col>
                </Row>

                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Confirmar Contraseña</label>
                  <Col sm={7}>
                    <input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      disabled={!UISweetAlert.editar_usuario}
                      onChange={changeUsarioEditar}
                      value={usuarioEditar.confirmPassword}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="form-group mt-2 ml-2 mr-2">
              <button
                hidden={!UISweetAlert.editar_usuario}
                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mb-4"
                onClick={() => usuarioNuevoRol()}>
                Nuevo Rol
              </button>
              <table className="table table-hover table-centered table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">ROL</th>
                    {UISweetAlert.editar_usuario && <th scope="col">ELIMINAR</th>}
                  </tr>
                </thead>
                <tbody>
                  {usuarioEditar.catRoles &&
                    usuarioEditar.catRoles.map((catRol, index) => {
                      return (
                        <>
                          <tr key={catRol}>
                            <td>
                              {!catRol.nuevoRolUsr ? (
                                catRol.rol
                              ) : (
                                <select
                                  className="form-control"
                                  name="idCatRol"
                                  onChange={(e) => changeNuevoRol(e, index)}
                                  value={catRol.idCatRol}>
                                  <option selected value="">
                                    Seleccione una opción
                                  </option>
                                  {roles.map((rol) => {
                                    return (
                                      <>
                                        <option value={rol.idCatRol}>{rol.rol}</option>
                                      </>
                                    );
                                  })}
                                </select>
                              )}
                            </td>
                            {UISweetAlert.editar_usuario && (
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                  onClick={() => {
                                    setIndexEliminarRol(index);
                                    setUISweetAlert({
                                      ...UISweetAlert,
                                      confirm_eliminar_rol: true,
                                    });
                                  }}>
                                  Eliminar
                                </button>
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_nuevo_usuario ? (
        <SweetAlert
          title="Nuevo Usuario"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            uploadUsuarioNuevo();
          }}
          onCancel={() => {
            setNuevoUsuario({
              catRoles: [],
              reiniciarPassword: false,
            });
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nuevo_usuario: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <div>
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">Nombre</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="nombre"
                  autoComplete="off"
                  onChange={changeUsarioNuevo}
                  value={nuevoUsuario.nombre ? nuevoUsuario.nombre : ""}
                />
              </Col>
            </Row>
          </div>
          <Row className="form-group mt-2">
            <label className="col-sm-4 col-form-label">Usuario</label>
            <Col sm={7}>
              <input
                type="text"
                className="form-control"
                name="usuario"
                autoComplete="off"
                onChange={changeUsarioNuevo}
                value={nuevoUsuario.usuario ? nuevoUsuario.usuario : ""}
              />
            </Col>
          </Row>
          <Row className="form-group mt-2">
            <label className="col-sm-4 col-form-label">Correo</label>
            <Col sm={7}>
              <input
                type="text"
                className="form-control"
                name="correo"
                autoComplete="off"
                onChange={changeUsarioNuevo}
                value={nuevoUsuario.correo ? nuevoUsuario.correo : ""}
              />
            </Col>
          </Row>
          <Row className="form-group mt-2">
            <label className="col-sm-4 col-form-label">Contraseña</label>
            <Col sm={7}>
              <input
                type="password"
                className="form-control"
                name="password"
                autoComplete="off"
                onChange={changeUsarioNuevo}
                value={nuevoUsuario.password ? nuevoUsuario.password : ""}
              />
            </Col>
          </Row>
          <Row className="form-group mt-2">
            <label className="col-sm-4 col-form-label">Confirmar Contraseña</label>
            <Col sm={7}>
              <input
                type="password"
                className="form-control"
                name="confirmPassword"
                autoComplete="off"
                onChange={changeUsarioNuevo}
                value={nuevoUsuario.confirmPassword ? nuevoUsuario.confirmPassword : ""}
              />
            </Col>
          </Row>
          <Row className="form-group mt-2">
            <label className="col-sm-4 col-form-label">Tipo de Usuario</label>
            <Col sm={7}>
              <select
                className="form-control"
                name="tipoUsuario"
                onChange={(e) => changeTipoUsuarioNuevo(e)}
                value={nuevoUsuario.tipoUsuario}>
                <option selected disabled value="">
                  Seleccione una opción
                </option>
                <option value="MEDICO">MÉDICO</option>
                <option value="EMPLEADO">EMPLEADO</option>
              </select>
            </Col>
          </Row>
          <Row className="form-group mt-2 ml-2 mr-2">
            <button
              className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mb-4"
              onClick={() => nuevoUsuarioNuevoRol()}>
              Nuevo Rol
            </button>
            <table className="table table-hover table-centered table-nowrap mb-0">
              <thead>
                <tr>
                  <th scope="col">Rol</th>
                  <th scope="col">Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {nuevoUsuario.catRoles.map((catRol, index) => {
                  return (
                    <>
                      <tr key={catRol}>
                        <td>
                          <select
                            className="form-control"
                            name="idCatRol"
                            onChange={(e) => changeNuevoRolNuevoUsuario(e, index)}
                            value={catRol.idCatRol}>
                            <option selected value="">
                              Seleccione una opción
                            </option>
                            {roles.map((rol) => {
                              return (
                                <>
                                  <option value={rol.idCatRol}>{rol.rol}</option>
                                </>
                              );
                            })}
                          </select>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                            onClick={() => {
                              setIndexEliminarRol(index);
                              setUISweetAlert({
                                ...UISweetAlert,
                                confirm_eliminar_rol_nuevo_usuario: true,
                              });
                            }}>
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </Row>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_eliminar_rol ? (
        <SweetAlert
          title="¿Desea eliminar el rol?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarUsuarioNuevoRol()}
          onCancel={() => {
            setIndexEliminarRol(null);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_rol: false,
            });
          }}>
          Se perderá la información
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_eliminar_rol_nuevo_usuario ? (
        <SweetAlert
          title="¿Desea eliminar el rol?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminirRolNuevoUsuario()}
          onCancel={() => {
            setIndexEliminarRol(null);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_rol_nuevo_usuario: false,
            });
          }}>
          Se perderá la información
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_reiniciar_contraseña ? (
        <SweetAlert
          title="¿Desea reiniciar la contraseña del usuario?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => reiniciarContraseñaUsuario()}
          onCancel={() => {
            setUsuarioEditar({});
            setUISweetAlert({
              ...UISweetAlert,
              confirm_reiniciar_contraseña: false,
            });
          }}></SweetAlert>
      ) : null}
      {/*Siempre van al final los mensajes de error, carga y de exito*/}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
