/* eslint-disable import/no-anonymous-default-export */
import {
    LISTAR_TIPOS_VALES
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_TIPOS_VALES:
            return{
                ...state,
                tiposVale: action.payload
            };
        default:
            return state
    }
        
}