import {
    LISTAR_CATTIPOVENTA
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_CATTIPOVENTA:
            return{
                ...state,
                tipoVentas: action.payload
            };
        default:
            return state
    }
        
}