import React from "react";
import { Row, Col, CardBody } from "reactstrap";

const RecetaNormal = ({ nuevaReceta, onChangeRecetaNormal, checkGuardarReceta, checkImprimirReceta }) => {
  return (
    <>
      <CardBody>
        <Row>
          <Col xl={8} lg={12} md={12}>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">Fecha</label>
              <Col sm={7}>
                <input
                  disabled={true}
                  className="form-control"
                  type="text"
                  name="fecha"
                  value={nuevaReceta.fecha ? nuevaReceta.fecha : ""}
                />
              </Col>
              <label className="col-sm-3 col-form-label">
                Indicaciones<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7}>
                <textarea
                  className="form-control"
                  name="indicaciones"
                  onChange={onChangeRecetaNormal}
                  value={nuevaReceta.indicaciones ? nuevaReceta.indicaciones : ""}
                />
                {(nuevaReceta.indicaciones === "" || nuevaReceta.indicaciones == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
              <label className="col-sm-3 col-form-label"></label>
              <Col sm={7}>
                <button
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="button"
                  style={{
                    marginRight: "2.5rem",
                    marginTop: "1rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  onClick={checkGuardarReceta}>
                  Guardar
                </button>
                <button
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="button"
                  style={{
                    marginRight: "2.5rem",
                    marginTop: "1rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  onClick={checkImprimirReceta}>
                  Imprimir
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </>
  );
};

export default RecetaNormal;
