import { LISTAR_PRODUCTOSCOMPRAEXTERNA, SET_PRODUCTOSCOMPRAEXTERNA_PAGINADOS, FILTRO_PRODUCTOSCOMPRAEXTERNA } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case LISTAR_PRODUCTOSCOMPRAEXTERNA:
      return {
        ...state,
        productosCompraExterna: action.payload,
      };

    case SET_PRODUCTOSCOMPRAEXTERNA_PAGINADOS:
      return {
        ...state,
        productosCompraExternaPaginados: action.payload,
      };
    case FILTRO_PRODUCTOSCOMPRAEXTERNA:
      return {
        ...state,
        filtrosProductosCompraExterna: action.payload,
      };

    default:
      return state;
  }
};
