import React, {useState, useContext, useEffect, useRef, useCallback} from "react";

import ChartLayout from "../ChartLayout/ChartLayout";
import BarChart from "../Charts/BarChart";
import CustomSpinner from "../UI/customSpinner";
import DashboardContext from "../../context/Dashboard/DashboardContext";
import PieChart from "../Charts/PieChart";
import { lightGreen } from "@material-ui/core/colors";
import useWindowDimensions from "../../hooks/dimensionsHook";

const InformeMezclas = () => {
    const dashboardContext = useContext(DashboardContext);
    const { 
        getGraficaMezclasPreparaciones,graficaMedicamentosPorMes,getGraficaMezclasPreparacionesPareto,getGraficaMezclasMedicos,getGraficaMezclasReacciones
    } = dashboardContext;
    const {width} = useWindowDimensions();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const numeroRegistros = 15;

    const tituloTotalGeneralesGlobal= "Total General - ";
    const [tituloTotalGenerales, setTituloTotalGenerales] = useState(tituloTotalGeneralesGlobal + currentYear);

    const tituloTotalesGlobal= "Totales (Top 20) - ";
    const [tituloTotales, setTituloTotales] = useState(tituloTotalesGlobal + currentYear);

    const tituloTotalMedicinasGlobal= "Medicamentos por mes - ";
    const [tituloTotalMedicinas, setTituloTotalMedicinas] = useState(tituloTotalMedicinasGlobal + currentYear);

    const tituloMedicosGlobal= "Mezclas por Médicos - ";
    const [tituloMedicos, setTituloMedicos] = useState(tituloMedicosGlobal + currentYear);    

    const tituloReaccionesGlobal= "Medicamentos ( Reacciones Adversas ) - ";
    const [tituloReacciones, setTituloReacciones] = useState(tituloReaccionesGlobal + currentYear);    

    const [dataTotalGenerales, setDataTotalGenerales] = useState({datasets: []});
    const [dataTotales, setDataTotales] = useState({datasets: []});
    const [dataMedicos, setDataMedicos] = useState({datasets: []});
    const [dataTotalMedicinas, setDataTotalMedicinas] = useState({datasets: []});
    const [dataReacciones, setDataReacciones] = useState({datasets: []});

    const [filtrosTotalGenerales, setFiltrosTotalGenerales] = useState({anio: currentYear, fullDate: new Date()})
    const [filtrosTotales, setFiltrosTotales] = useState({anio: currentYear, fullDate: new Date(), mes: null})
    const [filtrosMedicos, setFiltrosMedicos] = useState({anio: currentYear, fullDate: new Date(), mes: null})
    const [filtrosTotalMedicinas, setFiltrosTotalMedicinas] = useState({anio: currentYear, fullDate: new Date()})
    const [filtrosReacciones, setFiltrosReacciones] = useState({anio: currentYear, fullDate: new Date(), mes: null})

    const refGraficaTotalGenerales = useRef(null);
    const refGraficaTotales = useRef(null);
    const refGraficaMedicos = useRef(null);
    const refGraficaTotalMedicinas = useRef(null);
    const refGraficaReacciones = useRef(null);

    const chartLayoutTotalGenerales = {extraInfo: true,withFilters: true};
    const chartLayoutTotales = {extraInfo: true,withFilters: true};
    const chartLayoutMedicos = {extraInfo: true,withFilters: true};
    const chartLayoutTotalMedicinas = {extraInfo: true,withFilters: true};
    const chartLayoutReacciones = {extraInfo: true,withFilters: true};

    const [loadingTotalGenerales, setLoadingTotalGenerales] = useState(false);
    const [loadingTotales, setLoadingTotales] = useState(false);
    const [loadingMedicos, setLoadingMedicos] = useState(false);
    const [loadingTotalMedicinas, setLoadingTotalMedicinas] = useState(false);
    const [loadingReacciones, setLoadingReacciones] = useState(false);

    const [paginaActual, setPaginaActual] = useState(0);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [medicamenteosFiltrados, setMedicamenteosFiltrados] = useState([]);
    const [totalesFiltrados, setTotalesFiltrados] = useState([]);
    const [totalesmedicinas, setTotalesmedicinas] = useState([]);
    const [listaMedicinasImprimir, setListaMedicinasImprimir] = useState([]);

    const [eneroGlobal, setEneroGlobal] = useState(0);
    const [febreroGlobal, setFebreroGlobal] = useState(0);
    const [marzoGlobal, setMarzoGlobal] = useState(0);
    const [abrilGlobal, setAbrilGlobal] = useState(0);
    const [mayoGlobal, setMayoGlobal] = useState(0);
    const [junioGlobal, setJunioGlobal] = useState(0);
    const [JulioGlobal, setJulioGlobal] = useState(0);
    const [agostoGlobal, setAgostoGlobal] = useState(0);
    const [septiembreGlobal, setSeptiembreGlobal] = useState(0);
    const [octubreGlobal, setOctubreGlobal] = useState(0);
    const [noviembreGlobal, setNoviembreGlobal] = useState(0);
    const [diciembreGlobal, setDiciembreGlobal] = useState(0);
    const [totalGlobal, setTotalGlobal] = useState(0);

    const downloadImage = useCallback(() =>{
        const link = document.createElement("a");
        link.download = "chart.png"
        link.href = refGraficaMedicos.current.toBase64Image();
        link.click();
    },[])

    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = 'Medicamento,Enero,Febrero,Marzo,Abril,Mayo,Junio,Julio,Agosto,Septiembre,Octubre,Noviembre,Diciembre,total' + '\r\n';
    
        for (let i = 0; i < array.length; i++) {
          let line = '';
          for (let index in array[i]) {
            if (line !== '') line += ',';
    
            line += array[i][index];
          }
          str += line + '\r\n';
        }
        str += "Total General," +  eneroGlobal + "," + febreroGlobal + "," + marzoGlobal + "," + abrilGlobal + "," + mayoGlobal + "," + junioGlobal + "," + JulioGlobal + 
               "," + agostoGlobal + "," + septiembreGlobal + "," + octubreGlobal + "," + noviembreGlobal + "," + diciembreGlobal + ", " + totalGlobal + '\r\n'
        return str;
      };

    const exportTable  = () => {
        const cadena = convertToCSV(listaMedicinasImprimir);
        const csvData = new Blob([cadena], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        //link.download = `${fileName}.csv`;
        link.download = `MedicamentosPorMes.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    const exportChart = (chartRef) => {
        if (chartRef.current) {
            const canvas = chartRef.current.canvas; // Access the canvas directly
            canvas.toBlob((blob) => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.style = "color: red; background-color: blue";
                link.download = 'grafica.png'; // You can specify the filename here
                link.click();
            });
        } else {
            console.error("Chart ref is null or undefined.");
        }
    };

    function assignRandomColorsToDatasetsLocal(){
        const randomComponent = () => Math.floor(Math.random() * 256);
        const r = randomComponent();
        const g = randomComponent();
        const b = randomComponent();
        return `rgb(${r}, ${g}, ${b})`;
    }

    /* ********************************************************************************************
                           FUNCIONES PARA PREPARADOS DE MEZCLAS ANUAL
    ******************************************************************************************** */
    useEffect(() => {
        const fetchTotalGenerales = async () => {
            setLoadingTotalGenerales(false)
            try {
                setLoadingTotalGenerales(true);
                //ESTE ES EL PROCESO PARA LLENAR LA GRAFICA DE PREPARADOS ANUAL
                const res = await getGraficaMezclasPreparaciones(filtrosTotalGenerales);
                const final = res.data.map((value) => {
                    const labelTemp = value.x;
                    let retorno = {
                        label : "",
                        barPercentage:1,
                        backgroundColor: assignRandomColorsToDatasetsLocal(), 
                        borderWidth: 1,
                        height:50,
                        width : 50,            
                        data : [{ x : labelTemp, y : parseInt(value.y, 10)}]
                    }
                    return retorno
                });
                setDataTotalGenerales({datasets: final});
            } catch (e) {
                setDataTotalGenerales({datasets: []})
                console.log(e);
            }  
            setLoadingTotalGenerales(false);               
        };
        fetchTotalGenerales();
    }, [filtrosTotalGenerales]);

    /* ********************************************************************************************
                           FUNCIONES PARA PREPARADOS DE MEZCLAS ANUAL fetchData({pageSize: 10, pageIndex: 0});
    ******************************************************************************************** */
    useEffect(() => {
        const fetchTotales = async () => {
            setLoadingTotales(true)
            try{             
                const resPc = await getGraficaMezclasPreparacionesPareto(filtrosTotales);
                const finalPc = resPc.data.map((value,index) => {
                    const labelTemp = value.x;
                    let retorno = {
                        label : "",
                        barPercentage:1,
                        backgroundColor: assignRandomColorsToDatasetsLocal(), 
                        borderWidth: 1,
                        height:50,
                        width : 50,            
                        data : [{ x : labelTemp, y : parseInt(value.y, 10)}]
                    }
                    return retorno
                });                
                setDataTotales({datasets: finalPc});
            }catch (e) {
                setDataTotales({datasets: []})
                console.log(e);
            }
            setLoadingTotales(false)
     }   
        fetchTotales();
    }, [filtrosTotales]);

    /* ********************************************************************************************
                        FUNCIONES PARA INFORMES POR DETALLES DE  MEDICINAS POR MES
    ******************************************************************************************** */
    useEffect(() => {
    const fetchTotalMedicinas = async () => {
        let eneroTemp = 0;
        let febreroTemp = 0;
        let marzoTemp = 0;
        let abrilTemp = 0;
        let mayoTemp = 0;
        let junioTemp = 0;
        let julioTemp = 0;
        let agostoTemp = 0;
        let septiembreTemp = 0;
        let octubreTemp = 0;
        let noviembreTemp = 0;
        let diciembreTemp = 0;
        let totalTemp = 0;
        setLoadingTotalMedicinas(false)
        try {
            setLoadingTotalMedicinas(true);
            //ESTE ES EL PROCESO PARA LLENAR LA GRAFICA DE PREPARADOS ANUAL
            const res = await graficaMedicamentosPorMes(filtrosTotalMedicinas);
            let paginas = res.data.length? parseInt(res.data.length/15) : 0;
            setTotalesmedicinas(res.data.length? res.data.length : 0)
            setTotalPaginas(paginas+1);
            setDataTotalMedicinas({datasets: res.data});

            res.data.map((value) => {
                eneroTemp = eneroTemp + value.enero;
                febreroTemp = febreroTemp + value.febrero;
                marzoTemp = marzoTemp + value.marzo;
                abrilTemp = abrilTemp + value.abril;
                mayoTemp = mayoTemp + value.mayo;
                junioTemp = junioTemp + value.junio;
                julioTemp = julioTemp + value.julio;
                agostoTemp = agostoTemp + value.agosto;
                septiembreTemp = septiembreTemp + value.septiembre;
                octubreTemp = octubreTemp + value.octubre;
                noviembreTemp = noviembreTemp + value.noviembre;
                diciembreTemp = diciembreTemp + value.diciembre;
                totalTemp = totalTemp + value.total;
            });

            setEneroGlobal(eneroTemp);
            setFebreroGlobal(febreroTemp);
            setMarzoGlobal(marzoTemp);
            setAbrilGlobal(abrilTemp);
            setMayoGlobal(mayoTemp);
            setJunioGlobal(junioTemp);
            setJulioGlobal(julioTemp);
            setAgostoGlobal(agostoTemp);
            setSeptiembreGlobal(septiembreTemp);
            setOctubreGlobal(octubreTemp);
            setNoviembreGlobal(noviembreTemp);
            setDiciembreGlobal(diciembreTemp);
            setTotalGlobal(totalTemp);
            setListaMedicinasImprimir(res.data);
            gotoPage(0,true, res.data);
        } catch (e) {
            setDataTotalMedicinas({datasets: []})
            console.log(e);
        }  
        setLoadingTotalMedicinas(false);               
    };
    fetchTotalMedicinas();    
}, [filtrosTotalMedicinas]);

    /* ********************************************************************************************
                           FUNCIONES PARA INFORMES POR MEDICO
    ******************************************************************************************** */  
    useEffect(() => {
        const fetchMedicos = async () => {
            setLoadingMedicos(true)
            try{             
                const resPc = await getGraficaMezclasMedicos(filtrosMedicos);
                /* ACA LLENAMOS LOS DATOS CON LOS CUALES SE REPARTIRA LOS ESPACIO DEL PASTEL*/ 
                let dataTemp = [];
                let labelsTemp = [];
                let backgroundColorTemp = [];
                let granTotal = 0;

                resPc.data.map((value) => {
                    granTotal = granTotal + parseInt(value.y, 10);
                });  

                 resPc.data.map((value) => {
                    dataTemp.push(parseInt(value.y, 10));
                    let porcentaje = ((100 / granTotal) * parseInt(value.y, 10)).toFixed(2);
                    labelsTemp.push(value.x + "  (" + parseInt(value.y, 10) +  " =  " +  porcentaje +"%)");
                    backgroundColorTemp.push(assignRandomColorsToDatasetsLocal());
                });   
                const dataset = [{data: dataTemp, backgroundColor: backgroundColorTemp}]
                setDataMedicos({datasets: dataset, labels : labelsTemp})
            }catch (e) {
                setDataMedicos({datasets: []})
                console.log(e);
            }
            setLoadingMedicos(false)
        }   
        fetchMedicos();
    }, [filtrosMedicos]);

        /* ********************************************************************************************
                           FUNCIONES PARA INFORMES POR REACCIONES 
    ******************************************************************************************** */  
    useEffect(() => {
        const fetchReacciones = async () => {
            setLoadingReacciones(true)
            try{             
                const resPc = await getGraficaMezclasReacciones(filtrosReacciones);
                const finalPc = resPc.data.map((value,index) => {
                    const labelTemp = value.x;
                    let retorno = {
                        label : "",
                        barPercentage:1,
                        backgroundColor: assignRandomColorsToDatasetsLocal(), 
                        borderWidth: 1,
                        height:50,
                        width : 50,            
                        data : [{ x : labelTemp, y : parseInt(value.y, 10)}]
                    }
                    return retorno
                });                
                setDataReacciones({datasets: finalPc});
            }catch (e) {
                setDataReacciones({datasets: []})
                console.log(e);
            }
            setLoadingReacciones(false)
        }   
        fetchReacciones();
    }, [filtrosReacciones]);

    //***************************************************************************************************** */                        

    const onChangeTotalGenerales = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloTotalGenerales(tituloTotalGeneralesGlobal + year)
            } catch (error) {
            }
            setFiltrosTotalGenerales({
                ...filtrosTotalGenerales,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosTotalGenerales({
                ...filtrosTotalGenerales,
                [name]: value
            });
        }
    }

    const onChangeTotales = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloTotales(tituloTotalesGlobal + year)
            } catch (error) {
            }
            setFiltrosTotales({
                ...filtrosTotales,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosTotales({
                ...filtrosTotales,
                [name]: value
            });
        }
    }

    const onChangeTotalMedicinas = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloTotalMedicinas(tituloTotalMedicinasGlobal + year)
            } catch (error) {
            }
            setFiltrosTotalMedicinas({
                ...filtrosTotalMedicinas,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosTotalMedicinas({
                ...filtrosTotalMedicinas,
                [name]: value
            });
        }
    }    

    const onChangeMedicos = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloMedicos(tituloMedicosGlobal + year)
            } catch (error) {
            }
            setFiltrosMedicos({
                ...filtrosMedicos,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosMedicos({
                ...filtrosMedicos,
                [name]: value
            });
        }
    }

    const onChangeReacciones = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloReacciones(tituloReaccionesGlobal + year)
            } catch (error) {
            }
            setFiltrosReacciones({
                ...filtrosReacciones,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosReacciones({
                ...filtrosReacciones,
                [name]: value
            });
        }
    }

    const gotoPage = (parametro,nuevo,lista) => {
        let contador = 0;
        let base = 0;
        let techo = 0;
        let paginaTemp = paginaActual;

        if(parametro ==0)
        {
            paginaTemp = 1;
        }else
        {
            if(parametro ==1)
            {
                if(paginaTemp > 1)
                {
                    paginaTemp = paginaActual- 1;
                }
            }else
			{
                if(parametro ==2)
                {
                    if(paginaTemp < totalPaginas)
                    {
                        paginaTemp = paginaActual + 1;
                    }
                }else
                {
                    if(parametro ==3)
                    {
                        paginaTemp = totalPaginas;
                     }
                }
            }
        }
        setPaginaActual(paginaTemp)
        base = (paginaTemp - 1) * numeroRegistros;
        techo = paginaTemp  * numeroRegistros;

        if(nuevo==true)
        {
            const filtro = lista.filter((value) => 
            {
                contador ++;
                if(contador > base && contador <= techo)
                {
                    return value;
                }
            })
            setMedicamenteosFiltrados(filtro);
        }else
        {
            const filtro2 = dataTotalMedicinas.datasets.filter((value) => 
            {
                contador ++;
                if(contador > base && contador <= techo)
                {
                    return value;
                }
            })
            setMedicamenteosFiltrados(filtro2);
        }
    }

    return (
        <> 
            <div backgroundColor = {lightGreen}>
                <h1> Informes de Centro de Mezclas</h1> 

                <ChartLayout aseguradoras={null} 
                            selectAseguradoras={false} 
                            filtros={filtrosTotalGenerales} 
                            conAnio={true}
                            onChange={onChangeTotalGenerales} 
                            exportChart={exportChart}
                            refGrafica={refGraficaTotalGenerales} {...chartLayoutTotalGenerales}>
                            {loadingTotalGenerales ? 
                                (
                                    <CustomSpinner height={"700px"} infoText={" total General"}/>
                                ) 
                                :(dataTotalGenerales.datasets[0]?.data?.length > 0 ? 
                                    (<BarChart  ref={refGraficaTotalGenerales} 
                                           title={tituloTotalGenerales} 
                                            data={dataTotalGenerales} 
                             requiresMoneyFormat={false}/>) 
                                    : (<h3>{`${tituloTotalGenerales} SIN DATOS`}</h3>)
                                ) 
                            }
                </ChartLayout>
                
                <div backgroundColor="gray">

                </div>
                <ChartLayout aseguradoras={null} 
                            selectAseguradoras={false} 
                            filtros={filtrosTotales} 
                            conAnio={true}
                            conMes ={true}
                            onChange={onChangeTotales} 
                            exportChart={exportChart}
                            refGrafica={refGraficaTotales} {...chartLayoutTotales}>
                            {loadingTotales ? 
                                (
                                    <CustomSpinner height={"700px"} infoText={"Totales"}/>
                                ) 
                                :(dataTotales.datasets[0]?.data?.length > 0 ? 
                                    (<BarChart  ref={refGraficaTotales} 
                                                title={tituloTotales} 
                                                data={dataTotales} 
                                                requiresMoneyFormat={false}/>) 
                                    : (<h3>{`${tituloTotales} SIN DATOS`}</h3>)
                                ) 
                            }
                </ChartLayout>

                <ChartLayout aseguradoras={null} 
                            selectAseguradoras={false} 
                            filtros={filtrosTotalMedicinas} 
                            conAnio={true}
                            title={tituloTotalMedicinas}
                            onChange={onChangeTotalMedicinas} 
                            exportChart={exportTable}
                            refGrafica={refGraficaTotalMedicinas} {...chartLayoutTotalMedicinas}>
                            {loadingTotalMedicinas ? 
                                (
                                    <CustomSpinner height={"700px"} infoText={" total General"}/>
                                ) 
                                :(dataTotalMedicinas.datasets.length > 0 ? 
                                    (   
                                        <div align = "left" style={{width: "70%"}}>
                                            <table style={{width: "70%"}}>
                                                <tr>
                                                    <td align="center" >
                                                        <label className="col-sm-15 col-form-label">
                                                        {tituloTotalMedicinas}
                                                        </label>                                                       
                                                        <br></br>
                                                        <br></br>
                                                    </td>
                                                </tr>
                                            </table>
                                            <table  className="table table-hover table-centered table-nowrap mb-0">
                                                <thead>

                                                <tr>
                                                    <th scope="col">Medicamento</th>
                                                    <th scope="col">Enero</th>
                                                    <th scope="col">Febrero</th>
                                                    <th scope="col">Marzo</th>
                                                    <th scope="col">Abril</th>
                                                    <th scope="col">Mayo</th>
                                                    <th scope="col">Junio</th>
                                                    <th scope="col">Junio</th>
                                                    <th scope="col">Agosto</th>
                                                    <th scope="col">Septiembre</th>
                                                    <th scope="col">Octubre</th>
                                                    <th scope="col">Noviembre</th>
                                                    <th scope="col">Diciembre</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {medicamenteosFiltrados? medicamenteosFiltrados.map((medicina) => {
                                                    return (
                                                        <tr key={medicina.medicina}>
                                                            <td style={{width: "20%"}} align="left">{medicina.medicina ? medicina.medicina : "0"}</td>
                                                            <td>{medicina.enero ? medicina.enero : "0"}</td>
                                                            <td>{medicina.febrero ? medicina.febrero: "0"}</td>
                                                            <td>{medicina.marzo ? medicina.marzo : "0"}</td>
                                                            <td>{medicina.abril ? medicina.abril : "0"}</td>
                                                            <td>{medicina.mayo ? medicina.mayo : "0"}</td>
                                                            <td>{medicina.junio ? medicina.junio : "0"}</td>
                                                            <td>{medicina.julio ? medicina.julio : "0"}</td>
                                                            <td>{medicina.agosto ?medicina.agosto : "0"}</td>
                                                            <td>{medicina.septiembre ? medicina.septiembre : "0"}</td>
                                                            <td>{medicina.octubre ? medicina.octubre : "0"}</td>
                                                            <td>{medicina.noviembre ? medicina.noviembre : "0"} </td>
                                                            <td>{medicina.diciembre ? medicina.diciembre : "0"}</td>
                                                            <td>{medicina.total ? medicina.total : "0"}</td>
                                                        </tr>
                                                    );
                                                }):null}
                                                {totalesFiltrados?
                                                        <tr key={""}>
                                                            <h4 align="left">{"Total General"}</h4>
                                                            <td>{eneroGlobal}</td>
                                                            <td>{febreroGlobal}</td>
                                                            <td>{marzoGlobal}</td>
                                                            <td>{abrilGlobal}</td>
                                                            <td>{mayoGlobal}</td>
                                                            <td>{junioGlobal}</td>
                                                            <td>{JulioGlobal}</td>
                                                            <td>{agostoGlobal}</td>
                                                            <td>{septiembreGlobal}</td>
                                                            <td>{octubreGlobal}</td>
                                                            <td>{noviembreGlobal} </td>
                                                            <td>{diciembreGlobal}</td>
                                                            <td>{totalGlobal}</td>
                                                        </tr>
                                                :null}                                            
                                                </tbody>
                                                <tr>
                                                    <td>
                                                        <button
                                                            className="border text-grey-300 font-semibold py-2 px-4 rounded-left disabled:opacity-50"
                                                            onClick={() => gotoPage(0,false,null)}
                                                            disabled={false}>
                                                            Primera
                                                        </button>
                                                        <button
                                                            className="border text-grey-300 font-semibold py-2 px-4 disabled:opacity-50"
                                                            onClick={() => gotoPage(1,false,null)}
                                                            disabled={false}>
                                                            {"<"}
                                                        </button>
                                                        <button
                                                            className="border text-grey-300 font-semibold py-2 px-4 disabled:opacity-50"
                                                            onClick={() => gotoPage(2,false,null)}
                                                            disabled={false}
                                                            >
                                                            {">"}
                                                        </button>
                                                        <button
                                                            className="border text-grey-300 font-semibold py-2 px-4 disabled:opacity-50"
                                                            onClick={() => gotoPage(3,false,null)}
                                                            disabled={false}>
                                                            Última
                                                        </button>
                                                        <button className="border text-grey-300 font-semibold py-2 px-4 rounded-right disabled:opacity-50">
                                                            Página{" "}
                                                            <strong>
                                                                {paginaActual} de {totalPaginas}
                                                            </strong>
                                                        </button>
                                                        <button className="border text-grey-300 font-semibold py-2 px-4 rounded-right disabled:opacity-50">
                                                            <strong>
                                                                No de registros: {totalesmedicinas}
                                                            </strong>
                                                        </button>                                                
                                                    </td>
                                                </tr>                                              
                                            </table>
                                        </div>
                                    ) 
                                    : (<h3>{`${tituloTotalMedicinas} SIN DATOS`}</h3>)
                                ) 
                            }
                </ChartLayout>

                <table>
                  
                </table>

                <ChartLayout aseguradoras={null} 
                             selectAseguradoras={false} 
                             filtros={filtrosMedicos} 
                             conAnio={true}
                             conMes ={true}
                             onChange={onChangeMedicos} 
                             exportChart={exportChart} 
                             refGrafica={refGraficaMedicos} {...chartLayoutMedicos}>
                            {loadingMedicos ? 
                                (
                                  <CustomSpinner height={"700px"} infoText={"Mezclas por Médico"}/>
                                ) 
                               :(dataMedicos.datasets[0]?.data?.length > 0 ? 
                                    (<PieChart pie={true}  
                                               ref={refGraficaMedicos} 
                                               data={dataMedicos} 
                                               displayDataLabels={true}
                                               title={tituloMedicos}  
                                               requiresMoneyFormat={false} 
                                               onlyShowPercentage={false} 
                                               displayYAxis={false} 
                                               displayLabel={false}/>) 
                                    : (<h3>{`${tituloMedicos} SIN DATOS`}</h3>)
                                ) 
                            }
                </ChartLayout>
        
                <ChartLayout aseguradoras={null} 
                            selectAseguradoras={false} 
                            filtros={filtrosReacciones} 
                            conAnio={true}
                            conMes ={true}
                            onChange={onChangeReacciones} 
                            exportChart={exportChart}
                            refGrafica={refGraficaReacciones} {...chartLayoutReacciones}>
                            {loadingReacciones ? 
                                (
                                    <CustomSpinner height={"700px"} infoText={"Facturación por aseguradora"}/>
                                ) 
                                :(dataReacciones.datasets[0]?.data?.length > 0 ? 
                                    (<BarChart  ref={refGraficaReacciones} 
                                                title={tituloReacciones} 
                                                data={dataReacciones} 
                                                requiresMoneyFormat={false}/>) 
                                    : (<h3>{`${tituloReacciones} SIN DATOS`}</h3>)
                                ) 
                            }
                </ChartLayout>
                                      
            </div>    
        </>
    )

}

export default InformeMezclas;