import React, { useReducer } from "react";

import ResultadosReaContext from "./ResultadosReaContext";
import ResultadosReaReducer from "./ResultadosReaReducer";

import { LISTAR_RESULTADOS_REA } from "../../types";

import clienteAxios from "../../config/axios";

export default function ResultadosReaState(props) {
  const initialState = {
    resultadosRea: [],
  };

  const [state, dispatch] = useReducer(ResultadosReaReducer, initialState);

  const listarResultadosRea = async () => {
    const res = await clienteAxios.get("/resultadosrea/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_RESULTADOS_REA,
      payload: res.data,
    });
  };

  return (
    <ResultadosReaContext.Provider
      value={{
        resultadosRea: state.resultadosRea,
        listarResultadosRea,
      }}>
      {props.children}
    </ResultadosReaContext.Provider>
  );
}
