import React, {useContext, useEffect, useState} from "react";
// import { useParams } from "react-router-dom";
import {Row, Col, Card, CardBody, Spinner} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import {NumericFormat} from "react-number-format";

import formatNumber from "../../utils/index";

import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";
import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";
import ValesContext from "../../context/Vales/ValesContext";
import ConfiguracionContext from "../../context/Configuracion/ConfiguracionContext";
import AuthContext from "../../context/Auth/AuthContext";

import useWindowDimensions from "../../hooks/dimensionsHook";
import {maxCampoPiezas} from "../../config/variables";
import { TrainRounded } from "@material-ui/icons";

export default function ListaInsumos(props) {
    const {width} = useWindowDimensions();
    // const { idVale } = useParams();
    const [productosServiciosFilter, setProductosServiciosFilter] = useState([]);
    const [modificarInsumos, setModificarInsumos] = useState(false);
    const [hiddenAgregarLote, setHiddenAgregarLote] = useState(true);
    const [hiddenCompletarPrincipioActivo, setHiddenCompletarPrincipioActivo] = useState(false);
    const [insumoModificar, setInsumoModificar] = useState();
    const [posicionInsumoModificar, setPosicionInsumoModificar] = useState();
    const [hideLote, setHideLote] = useState(false);
    const [insumoTemporal, setInsumoTemporal] = useState(null);

    const [detallesPrecio, setDetallesPrecio] = useState({
        total: 0,
        subtotal: 0,
        iva: 0,
    });
    const [ivaConfiguracion, setIvaConfiguracion] = useState(0);
    const [loading, setLoading] = useState(false);

    const [modificarInsumoData, setModificarInsumoData] = useState({});

    const [productoSeleccEliminar, setProductoSeleccEliminar] = useState(null);
    const [loteSeleccEliminar, setLoteSeleccEliminar] = useState(null);

    const [idCatTipoProductoGlobal, setIidCatTipoProductoGlobal] = useState(0);

    //State que se pueden usar, para bloquear el poder hacer cambios a las piezas utilizadas
    const [disabledPiezasUtlizadas, setDisabledPiezasUtlizadas] = useState(true);
    const [hiddenPiezasUtilizadas, setHiddenPiezasUtilizadas] = useState(true);

    //State que se pueden usar, para bloquear el poder hacer cambios a las piezas asignadas
    // const [disabledPiezasAsignadas, setDisabledPiezasAsignadas] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [hiddenPiezasAsignadas, setHiddenPiezasAsignadas] = useState(false);

    //State que se pueden usar, para bloquear el poder hacer cambios a las piezas recibidas
    const [disabledPiezasRecibidas, setDisabledPiezasRecibidas] = useState(false);
    const [hiddenPiezasRecibidas, setHiddenPiezasRecibidas] = useState(false);

    const [hiddenCompleto, setHiddenCompleto] = useState(false);
    const [esServicioGlobal, setEsServicioGlobal] = useState(false);
    // const [hiddenEliminarLote, setHiddenEliminarLote] = useState(false);
    // const [hiddenEliminarInsumo, setHiddenEliminarInsumo] = useState(true);
    const [enableExpand, setEnableExpand] = useState(true);
    const [autorizacionPE, setAutorizacionPE] = useState(false);
    const [cadenaLog, setCadenaLog] = useState("");
    const fechaActual = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "";
    const[eliminarComentario,setEliminarComentario] = useState("")
    const [UISweetAlert, setUISweetAlert] = useState({
        success_dlg: false,
        confirm_alert_delete_lote_internal: false,
        confirm_alert_delete_lote_db: false,
        confirm_alert_delete_insumo: false,
        confirm_alert_delete_insumo_db: false,
        confirm_alert_modificar_insumo: false,
        confirm_alert_modificar_insumo_compra_externa: false,
        confirm_alert_ver_insumo: false,
        confirm_alert_autorizar_vale: false,
        confirm_alert_EliminarExistente_insumo:false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
    });

    // ACCESO A ESTADO GLOBAL - VALES
    const ctxVales = useContext(ValesContext);
    const {valeSeleccionado, setChangesValeSeleccionado, submitModificaciones, seleccionarVale} = ctxVales;

    // ACCESO A ESTADO GLOBAL - VALES
    const ctxEstatusVale = useContext(EstatusValeContext);
    const {setcambioEstatusData, cambioEstatusData, traerExistenciaProducto,proximosEstatusVale,verificarEstatus,actualizarOCambiarEstatus} = ctxEstatusVale;

    //ACCESO AL ESTADO GLOBAL - ProductosServicios
    const ctxProductosServicios = useContext(ProductosServiciosContext);
    const {productosServicios, listarProductosServiciosAseguradora, listarProductosServiciosPersonal} = ctxProductosServicios;

    // ACCESO A ESTADO GLOBAL - Configuracion
    const ctxConfiguracion = useContext(ConfiguracionContext);
    const {configuracion} = ctxConfiguracion;

    //ACCESO AL ESTADO GLOBAL - AUTENCIACION
    const ctxAuth = useContext(AuthContext);
    const {usuario} = ctxAuth;

    const MostrarLotes = valeSeleccionado? (valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length -1].catEstatusvale.idCatEstatusVale < 5 &&
                        valeSeleccionado.catTipovale.idCatTipoVale ==1) || (valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length -1].catEstatusvale.idCatEstatusVale < 15 &&
                            valeSeleccionado.catTipovale.idCatTipoVale ==2) ? true:false : false;

    const agregarInsumo = () => {
        let nuevoInsumo = {
            idCatProducto: null,
            idCatServicio: null,
            piezas: "",
            precio: null,
            iva: 0,
            principioActivo: "",
            catTipoProducto: null,
            descripcion: "",
            Nuevo:true,
            personalIdCatServicio: 0,
            personalIdCatProducto : 0,
            autorizacion:true,
            necesita_autorizacion:false,
        };  
        cambioEstatusData.valeProductoServicio = cambioEstatusData.valeProductoServicio.concat(nuevoInsumo);
        setcambioEstatusData(cambioEstatusData);
        //setListarInsumosPorTipoProductoNuevo(1)
    };  

    const onChangeComentario = async (e) => {
        setEliminarComentario(e.target.value)
    };

    const setListarInsumosPorTipoProductoNuevo = async (index) => {
        // let concentracionValeProducto = valeSeleccionado.valeproductoservicios[index].concentracion;
        setIidCatTipoProductoGlobal(0);
        const productosServiciosFiltrados = await productosServicios.filter((prodServ) => {
            if (
                prodServ.principioActivo != null && prodServ.idCatTipoproducto != 5
            ) {
                return true;
            } else {
                if(prodServ.idCatTipoproducto == 3 ){
                    return true;
                }
            }
        });
        
        // prodServ.concentracion != null && prodServ.concentracion.toLowerCase() === concentracionValeProducto.toLowerCase();
        setProductosServiciosFilter(productosServiciosFiltrados);
    };

    function BuscaLote (inventarioProductoServicios,index) {
        let valeproductoservicios = valeSeleccionado.valeproductoservicios;
        let insumo = valeproductoservicios[index];
        let Regreso = false;

        insumo.inventarioProductoServicios.map((producto, index) => {
            if(producto.inventario){
                if(producto.inventario.idInventario ==inventarioProductoServicios.idInventario){
                    Regreso = true;
                }
            }else{
                if(producto.idInventario ==inventarioProductoServicios.idInventario){
                    Regreso = true;
                }
            }
        })

        return Regreso
    }

    const agregarLote = (index) => {
        let valeproductoservicios = valeSeleccionado.valeproductoservicios;
        let insumo = valeproductoservicios[index];

        let nuevoLote = {
            idInventarioProductoServicio: null,
            idInventario: null,
            idInventarioOriginal: null,
            lote: "",
            caducidad: "",
            piezasAsignadas: 0,
            piezasRecibidas: 0,
            piezasUtilizadas: 0,
            piezasOriginales: 0,
        };

        /*insumo.catProductos.inventarios = insumo.catProductos.inventarios.filter(
            (item) => {
                if(!BuscaLote(item,index)){
                    return item;
                }
            }
        );*/

        insumo.inventarioProductoServicios = insumo.inventarioProductoServicios.concat(nuevoLote);
        setChangesValeSeleccionado(valeSeleccionado);
    };

    const changeInsumoPiezas = async (e, index, nuevoProducto) => {
        if (!props.changedProducts) props.setChangedProducts(true);
        if (nuevoProducto) {
            let valeproductoservicios = cambioEstatusData.valeProductoServicio;
            let insumo = valeproductoservicios[index];
            let value = e.target.value;
            if (value?.length <= maxCampoPiezas) {
                insumo.piezas = value;
                setcambioEstatusData(cambioEstatusData);
                setPrecioTotal();
            }
        } else {
            let value = e.target.value;
            if (value?.length <= maxCampoPiezas) {
                let valeproductoservicios = valeSeleccionado.valeproductoservicios;
                let insumo = valeproductoservicios[index];
                insumo.piezasVale = value;
                insumo.piezasCorriente = parseInt(value);
                insumo.productoServicioCambiado = true;
                setChangesValeSeleccionado(valeSeleccionado);
                setPrecioTotal();
            }
        }
    };

    const changeInsumoPrecio = async (e, index, nuevoProducto) => {
        if (!props.changedProducts) props.setChangedProducts(true);
        if (nuevoProducto) {
            let valeproductoservicios = cambioEstatusData.valeProductoServicio;
            let insumo = valeproductoservicios[index];
            let value = e.target.value;
            if (value?.length <= maxCampoPiezas) {
                insumo.precio = value;
                if(insumo.catTipoProducto == "INSUMO" || insumo.catTipoProducto == "SERVICIO"){
                    insumo.iva =insumo.precio * ivaConfiguracion;
                }
                setcambioEstatusData(cambioEstatusData);
                setPrecioTotal();
            }
        } else {
            let value = e.target.value;
            if (value?.length <= maxCampoPiezas) {
                let valeproductoservicios = valeSeleccionado.valeproductoservicios;
                let insumo = valeproductoservicios[index];
                insumo.precio = value;
                insumo.precioCorriente = parseFloat(value)
                insumo.productoServicioCambiado = true;
                setChangesValeSeleccionado(valeSeleccionado);
                setPrecioTotal();
            }
        }
    };

    const changeCompraExterna = (e, index, confirmado) => {
        let valeproductoservicios = valeSeleccionado.valeproductoservicios;
        let insumo = valeproductoservicios[index];
        let value = insumo.compraExterna ? !insumo.compraExterna : true;
        if (value && !confirmado) {
            setInsumoTemporal({e: e, index: index});
            setUISweetAlert({...UISweetAlert, confirm_alert_modificar_insumo_compra_externa: true});
        } else {
            setUISweetAlert({...UISweetAlert, confirm_alert_modificar_insumo_compra_externa: false});
            insumo.compraExterna = value;
            setChangesValeSeleccionado(valeSeleccionado);
        }
    };

    const changeInsumoPrecioUnitario = async (e, index, nuevoProducto) => {
        if (!props.changedProducts) props.setChangedProducts(true);
        if (nuevoProducto) {
            let valeproductoservicios = cambioEstatusData.valeProductoServicio;
            let insumo = valeproductoservicios[index];
            let value = e.target.value;

            insumo.precio = value;
            setcambioEstatusData(cambioEstatusData);
            setPrecioTotal();
        } else {
            let value = e.target.value;
            let valeproductoservicios = valeSeleccionado.valeproductoservicios;
            let insumo = valeproductoservicios[index];
            insumo.precio = value;
            insumo.precioCorriente = value;
            insumo.iva = 0;

            if(insumo.catTipoProducto){
                if(insumo.catTipoProducto == "INSUMO" || insumo.catTipoProducto == "SERVICIO"){
                    insumo.iva =insumo.precio * ivaConfiguracion;
                    insumo.ivaCorriente = insumo.precio * ivaConfiguracion;
                }
            }
            
            if(insumo.catServicios){
                if(insumo.catServicios.causaIva==true){
                    insumo.iva =insumo.precio * ivaConfiguracion;
                    insumo.ivaCorriente =insumo.precio * ivaConfiguracion;
                }
            }

            if(insumo.catProductos){
                if(insumo.catProductos.causaIva==true){
                    insumo.iva =insumo.precio * ivaConfiguracion;
                    insumo.ivaCorriente =insumo.precio * ivaConfiguracion;
                }
            }
            insumo.productoServicioCambiado = true;
            
            setChangesValeSeleccionado(valeSeleccionado);
            setPrecioTotal();
        }
    };

    const eliminarInsumo = async () => {
        const productosFiltrados = cambioEstatusData.valeProductoServicio.filter((item) => item != productoSeleccEliminar);

        cambioEstatusData.valeProductoServicio = productosFiltrados;
        setcambioEstatusData(cambioEstatusData);

        setUISweetAlert({
            ...UISweetAlert,
            success_dlg: true,
            dynamic_title: "Borrado",
            confirm_alert_delete_insumo: false,
            dynamic_description: "El insumo ha sido borrado correctamente.",
        });
        BuscaCambios();
        setEliminarComentario("")
        setPrecioTotal();
    };

    const eliminarInsumoExistente = async () => {

        if (eliminarComentario.length==0) {  
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca la justificación de la eliminación del producto",
            });
            return;
        }
        setLoading(false);
        let productoServicioTemp = valeSeleccionado.valeproductoservicios[posicionInsumoModificar];
        productoServicioTemp.activo = false;
        productoServicioTemp.eliminarComentario = eliminarComentario;
        productoServicioTemp.productoServicioCambiado = true;

        //productoServicioTemp.IdUsuario =  usuario.idUsuario
        setPosicionInsumoModificar(0);

        setChangesValeSeleccionado(valeSeleccionado);
        setPrecioTotal();

        setUISweetAlert({
            ...UISweetAlert,
            success_dlg: true,
            dynamic_title: "Exito",
            confirm_alert_EliminarExistente_insumo: false,
            confirm_alert_modificar_insumo: false,
            dynamic_description: "Se ha eliminado el producto de la lista",
        });
        BuscaCambios();
    }

    const changeInsumoCat = async (e, index) => {
        let value = e.target.value;
        let valeproductoservicios = cambioEstatusData.valeProductoServicio;
        let insumo = valeproductoservicios[index];
        let encontrado = false;
        insumo.descripcion = value;
        insumo.producto = value;
        let idGeneral = 0;
        const productosServiciosFiltrados = await productosServicios.filter((prodServ) => {
            if(prodServ.idCatTipoproducto !=5){
                if (prodServ.servicio != null ) {
                    if (prodServ.servicio.toLowerCase() == value.toLowerCase()) {
                        encontrado = true;
                        return true;
                    }
                    else if (prodServ.servicio != null && prodServ.servicio.toLowerCase().startsWith(value.toLowerCase(), 0)) {
                        return true;
                    }
                } else
                if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase() + " - " + prodServ.numIdentificacion.toLowerCase()  == value.toLowerCase()) {
                    encontrado = true;
                    if(prodServ.idCatProducto){
                        idGeneral = prodServ.idCatProducto
                    }
                    return true;
                } else            
                if(prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0)) {
                    if (idCatTipoProductoGlobal == prodServ.idCatTipoproducto){
                        return true;
                    }
                    else{
                        return true;
                    }
                } else if (prodServ.servicio != null && prodServ.servicio.toLowerCase().startsWith(value.toLowerCase(), 0)) {
                        return true;
                } else if (prodServ.principioActivo != null && prodServ.principioActivo.toLowerCase().startsWith(value.toLowerCase(), 0)) {
                    if (idCatTipoProductoGlobal == prodServ.idCatTipoproducto){
                        return true;
                    }
                } else if (prodServ.numIdentificacion != null && prodServ.numIdentificacion.startsWith(value, 0)) {
                    if (idCatTipoProductoGlobal == prodServ.idCatTipoproducto){
                        return true;
                    }
                } else if (prodServ.codigoEAN != null && prodServ.codigoEAN.startsWith(value, 0)) {
                    if (idCatTipoProductoGlobal == prodServ.idCatTipoproducto){
                        return true;
                    }
                }
            }
        });
        setProductosServiciosFilter(productosServiciosFiltrados);
        // console.log(productosServiciosFiltrados);

        if (encontrado) 
        {
            let repetido = false;
            if(productosServiciosFiltrados[0].idCatServicio){
                repetido = BuscaRepetido(productosServiciosFiltrados[0].idCatServicio,3);
            }else{
                repetido = BuscaRepetido(productosServiciosFiltrados[0].idCatProducto,124);
            }


            if(!repetido)
            {
                var lastIndex = value.lastIndexOf("-");
                let numeroIdentificacionAux = "" + value.slice(lastIndex + 1, value.length).replace(" ", "");
                let servicioAux = "" + value.slice(0, lastIndex - 1);

                const productoServicioSeleccionado = await productosServicios.filter((prodServ) => {
                    //Si la busqueda incluye la palabra "SERVICIO", se selecciono un servicio
                    //Para la busqueda del servicio, pedimos la cadena antes del "-"
                    if (prodServ.servicio != null) {
                        if (prodServ.servicio == value){
                            return true;
                        }
                    } else if (prodServ.numIdentificacion != null && prodServ.numIdentificacion == numeroIdentificacionAux) {
                        return true;
                    }
                });

                // console.log(productoServicioSeleccionado);

                if (productoServicioSeleccionado.length == 1) {
                    if (productoServicioSeleccionado[0].idCatProducto != null) {
                        insumo.idCatProducto = productoServicioSeleccionado[0].idCatProducto;
                        insumo.principioActivo = productoServicioSeleccionado[0].principioActivo;
                        insumo.concentracion = productoServicioSeleccionado[0].concentracion;
                        insumo.existencia = await BuscarExistencia(insumo.idCatProducto)
                        insumo.personalIdCatProducto = productoServicioSeleccionado[0].idCatProducto
                        insumo.personalIdCatProductoOriginal = productoServicioSeleccionado[0].idCatProducto
                        insumo.personalIdCatServicio = 0;
                        insumo.personalIdCatServicioOriginal = 0;
                        insumo.idCatTipoProducto = productoServicioSeleccionado[0].idCatTipoproducto
                        insumo.personalidCatTipoProducto = productoServicioSeleccionado[0].idCatTipoproducto
                    } else if (productoServicioSeleccionado[0].idCatServicio != null) {
                        insumo.idCatServicio = productoServicioSeleccionado[0].idCatServicio;
                        
                        insumo.personalIdCatServicio = productoServicioSeleccionado[0].idCatServicio
                        insumo.personalIdCatServicioOriginal = productoServicioSeleccionado[0].idCatServicio
                        insumo.personalIdCatProducto = 0;
                        insumo.personalIdCatProductoOriginal = 0;
                        insumo.personalidCatTipoproducto = productoServicioSeleccionado[0].idCatTipoproducto
                        insumo.idCatTipoProducto = productoServicioSeleccionado[0].idCatTipoproducto
                    }
                    
                    //Ademos debemos revisar el tipoVale, para saber si usaremos los precios de la aseguradora o de ventamostrador
                    if (valeSeleccionado.catTipovale.idCatTipoVale == 1) {
                        insumo.precio = productoServicioSeleccionado[0].precioProductoServicio[0].precioMostrador;
                        if (productoServicioSeleccionado[0].causaIva) {
                            insumo.iva = insumo.precio * ivaConfiguracion;
                        }
                        setPrecioTotal();
                    } else if (valeSeleccionado.catTipovale.idCatTipoVale == 2) {
                        insumo.precio = productoServicioSeleccionado[0].precioAseguradora[0].precioUnitario;
                        if (productoServicioSeleccionado[0].causaIva) {
                            insumo.iva = insumo.precio * ivaConfiguracion;
                        }
                        setPrecioTotal();
                    }
                    //Obtenemos el idCatTipoProducto, y guardamos en el state, el tipoCatProducto
                    if (productoServicioSeleccionado[0].idCatTipoproducto == 1) {
                        insumo.catTipoProducto = "INSUMO";
                    } else if (productoServicioSeleccionado[0].idCatTipoproducto == 2) {
                        insumo.catTipoProducto = "MEDICAMENTO";
                    } else if (productoServicioSeleccionado[0].idCatTipoproducto == 3) {
                        //setAutorizacion(false);
                        insumo.necesita_autorizacion = 0;
                        insumo.catTipoProducto = "SERVICIO";
                    } else if (productoServicioSeleccionado[0].idCatTipoproducto == 4) {
                        insumo.catTipoProducto = "SOLUCION";
                    }
                    insumo.Nuevo = false;
                    setcambioEstatusData(cambioEstatusData);
                    BuscaCambios();

                    //if(cambios){
                    //    insumo.productoServicioCambiado = true;
                    //    setcambioEstatusData(cambioEstatusData);
                    //}

                } else {
                    //Mientras no se encuentre un solo producto/servicio, el insumo se mentiene limpio
                    insumo.idCatServicio = null;
                    insumo.idCatProducto = null;
                    insumo.precio = "";
                    insumo.iva = 0;
                    insumo.principioActivo = "";
                    insumo.catTipoProducto = null;
                    insumo.concentracion = "";
                    insumo.existencia = "";
                    setcambioEstatusData(cambioEstatusData);
                }
            } else {
                insumo.idCatServicio = null;
                insumo.idCatProducto = null;
                insumo.precio = null;
                insumo.iva = 0;
                insumo.principioActivo = "";
                insumo.catTipoProducto = null;
                insumo.concentracion = "";
                insumo.personalIdCatProducto = 0;
                insumo.personalIdCatServicio = 0;
                insumo.existencia = "";
                if(repetido){
                    insumo.descripcion = "";
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "Producto o servicio repetido",
                      });
                }
                setPrecioTotal();
                setcambioEstatusData(cambioEstatusData);
                //BuscaCambios();
            }                
        }else{
            insumo.idCatServicio = null;
            insumo.idCatProducto = null;
            insumo.precio = null;
            insumo.iva = 0;
            insumo.principioActivo = "";
            insumo.catTipoProducto = null;
            insumo.concentracion = "";
            insumo.personalIdCatProducto = 0;
            insumo.personalIdCatServicio = 0;
            insumo.existencia = "";
            setPrecioTotal();
            setcambioEstatusData(cambioEstatusData);
            //BuscaCambios();
        }
    };

    const setPrecioTotalAseguradora = async () => {
        let subtotal = 0;
        let iva = 0;
        let total = 0;

        setDetallesPrecio({
            subtotal: 0,
            iva: 0,
            total: 0,
        });

        if (valeSeleccionado.valeproductoservicios.length > 0) {
            valeSeleccionado.valeproductoservicios.map((insumo) => {
                subtotal += insumo.precio * insumo.piezasVale;
                if (iva != null) {
                    iva += insumo.iva * insumo.piezasVale;
                }
            });
        }

        if (cambioEstatusData.valeProductoServicio && cambioEstatusData.valeProductoServicio.length > 0) {
            cambioEstatusData.valeProductoServicio.map((insumo) => {
                subtotal += insumo.precio * insumo.piezas;
                if (iva != null) {
                    iva += insumo.iva * insumo.piezas;
                }
            });
        }

        total = subtotal + iva;

        setDetallesPrecio({
            subtotal: (Math.round(subtotal * 100) / 100).toFixed(2),
            iva: (Math.round(iva * 100) / 100).toFixed(2),
            total: (Math.round(total * 100) / 100).toFixed(2),
        });
    };

    const setPrecioTotal = async () => {
        let subtotal = 0;
        let iva = 0;
        let total = 0;

        setDetallesPrecio({
            subtotal: 0,
            iva: 0,
            total: 0,
        });

        if (valeSeleccionado.valeproductoservicios.length > 0) {
            valeSeleccionado.valeproductoservicios.map((insumo) => {
                if(insumo.activo==true){
                    subtotal += insumo.precio * insumo.piezasVale;
                    if (iva != null) {
                        iva += insumo.iva * insumo.piezasVale;
                    }
                }
            });
        }

        if (cambioEstatusData.valeProductoServicio && cambioEstatusData.valeProductoServicio.length > 0) {
            cambioEstatusData.valeProductoServicio.map((insumo) => {
                subtotal += insumo.precio * insumo.piezas;
                if (iva != null) {
                    iva += insumo.iva * insumo.piezas;
                }
            });
        }

        total = subtotal + iva;

        setDetallesPrecio({
            subtotal: (Math.round(subtotal * 100) / 100).toFixed(2),
            iva: (Math.round(iva * 100) / 100).toFixed(2),
            total: (Math.round(total * 100) / 100).toFixed(2),
        });
    };

    const checkMatchedProductos = async (value) => {
        let productosMatched = [];

        let valueFormat = value;
        let numIdentificacion = null;
        let esServicio = false;
        if(esServicioGlobal){

        }
        
        if(esServicioGlobal){
            esServicio = true;
        }

        if (value.includes("-")) {
            numIdentificacion = value.split(" - ")[1];
            if (value.split("-")[1].trim().toLowerCase().normalize() == "servicio") {
                esServicio = true;
                valueFormat = value.split("-")[0];
            }
        }

        if (esServicio) {
            productosServicios.forEach((producto, index) => {
                if (
                    valueFormat &&
                    producto.servicio &&
                    producto.servicio.trim().toLowerCase().normalize() === valueFormat.trim().toLowerCase().normalize()
                ) {
                    productosMatched.push(producto);
                    return false;
                }
                return true;
            });
        } else {
            productosServicios.forEach((producto, index) => {
                if (
                    numIdentificacion &&
                    producto.numIdentificacion &&
                    producto.numIdentificacion.trim().toLowerCase().normalize() === numIdentificacion.trim().toLowerCase().normalize()
                ) {
                    productosMatched.push(producto);
                    return false;
                }
                return true;
            });
        }
        return productosMatched;
    };

    const checkMatchedProductosAseguradora = async (value) => {
        let productosMatched = [];

        let esServicio = false;
       
        if(esServicioGlobal){
            esServicio = true;
        }

        if (esServicio) {
            productosServicios.forEach((producto, index) => {
                if (
                    producto.servicio &&
                    producto.servicio.trim().toLowerCase() == value.trim().toLowerCase()
                ) {
                    productosMatched.push(producto);
                    return false;
                }
                return true;
            });
        } else {
            productosServicios.forEach((producto, index) => {
                if(producto.nombreComercial){
                    if (
                        producto.nombreComercial.trim().toLowerCase().normalize() === value.trim().toLowerCase().normalize()
                    ) {
                        productosMatched.push(producto);
                        return false;
                    }
                }
                return true;
            });
        }
        return productosMatched;
    };

    function TraeTipo(Tipo){
        let retorno;
        //alert(Tipo);
        if (Tipo == 1) {
            retorno = "INSUMO";
        } else if (Tipo == 2) {
            retorno = "MEDICAMENTO";
        } else if (Tipo == 3) {
            retorno = "SERVICIO";
        } else if (Tipo == 4) {
            retorno = "SOLUCION";
        }
        return retorno;
    }

    function BuscaRepetido (id,tipoProdcutoPar) {
        let repetido = false;

        valeSeleccionado.valeproductoservicios.map((producto, index) => {
            if(producto.activo){
                if(id  == producto.personalIdCatServicio && id > 0 && tipoProdcutoPar == 3)
                {
                    repetido = true;
                }
                else if(id  == producto.personalIdCatProducto  && id > 0 && tipoProdcutoPar == 124){
                    repetido = true;
                }else if(producto.catProductos){
                    if(id  == producto.catProductos.idCatProducto  && id > 0 && tipoProdcutoPar == 124){
                        repetido = true;
                    }
                }
            }
        })

        cambioEstatusData.valeProductoServicio.map((producto, index) => {
            if(producto.Nuevo != true)
            {
                if(id  == producto.personalIdCatServicio  && id > 0 && tipoProdcutoPar == 3)
                    {
                        repetido = true;
                    }
                    else if(id  == producto.personalIdCatProducto  && id > 0 && tipoProdcutoPar == 124){
                        repetido = true;
                    }
            }
        })
        return repetido
    }
    // se busca en los dos VoBv si hay cambios que deban autorizarse, en nuievo solo que sean diferentes a 3 y en los existente
    // los que si id original sea diferentes del  id actual

    function BuscaCambios () {
        let autorizacionLocal = false;
        let autorizacionLog = false;
        
        valeSeleccionado.valeproductoservicios.map((producto) => {
            if(producto.activo==1){
                if(producto.personalIdCatServicio != producto.personalIdCatServicioOriginal && producto.personalIdCatServicioOriginal > 0)
                {
                    autorizacionLog = TrainRounded
                }else
                if(producto.personalIdCatProducto != producto.personalIdCatProductoOriginal && producto.personalIdCatProductoOriginal > 0)
                {
                    autorizacionLog = true
                    
                    if(producto.tipoProducto.idCatTipoProducto==2){
                        autorizacionLocal = true; 
                    }                    
                }
            }
        })

        cambioEstatusData.valeProductoServicio.map((producto) => {
            if(producto.idCatTipoProducto == 2 && (producto.idCatProducto!=null || producto.idCatServicio != null))
            {
                autorizacionLocal = true;
            }
        })
        
        if(autorizacionLocal==true)
        {
            global.cambio = true; 
        }else if(!autorizacionLocal && !autorizacionPE ){
            global.cambio = false; 
        }
        return autorizacionLog
    }

    const changeInsumoValeSeleccionado = async (e, index) => {
        let value = e.target.value;
        let valeproductoservicios = valeSeleccionado.valeproductoservicios;
        let insumo = valeproductoservicios[index];
        setChangesValeSeleccionado(valeSeleccionado);
        const productoMatched = await checkMatchedProductos(value);
        //Se filtran
        const productosServiciosFiltrados = productosServicios.filter((prodServ) => {
            if (
                prodServ.principioActivo != null &&
                valeSeleccionado.valeproductoservicios[index].principioActivo &&
                valeSeleccionado.valeproductoservicios[index].principioActivo.toLowerCase() &&
                prodServ.principioActivo
                .toLowerCase()
                .startsWith(valeSeleccionado.valeproductoservicios[index].principioActivo.toLowerCase(), 0) &&
                prodServ.nombreComercial != null &&
                prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0)
            ) {
                if(idCatTipoProductoGlobal==prodServ.idCatTipoproducto){
                    return true;
                }
            } else {
                if(idCatTipoProductoGlobal==prodServ.idCatTipoproducto){
                    return true;
                }
            }
        });

        setProductosServiciosFilter(productosServiciosFiltrados);
        if (productoMatched.length > 0) {

            let repetido = false;
            //TODO
            if(productoMatched[0].idCatServicio==null){
                repetido = BuscaRepetido(productoMatched[0].idCatProducto,124);
            }else{
                    repetido = BuscaRepetido(productoMatched[0].idCatServicio,3);
            }
           
            if(!repetido){
                insumo.repetido = false;
                if (productoMatched[0].idCatProducto != null && productoMatched[0].idCatServicio == null) {
                    insumo.idCatProducto = productoMatched[0].idCatProducto;
                    insumo.concentracion = productoMatched[0].concentracion;
                    insumo.principioActivo = productoMatched[0].principioActivo;
                    insumo.personalIdCatProducto = productoMatched[0].idCatProducto;
                    insumo.existencia = await BuscarExistencia(productoMatched[0].idCatProducto)

                } else if (productoMatched[0].idCatProducto == null && productoMatched[0].idCatServicio != null) {
                    insumo.idCatServicio = productoMatched[0].idCatServicio;
                    insumo.personalIdCatServicio = productoMatched[0].idCatServicio;
                }
                //**************************************************** */
                let temporal = TraeTipo(productoMatched[0].idCatTipoproducto);
                insumo.catTipoProducto = temporal;              
                //****** TODO TENEMOS QUE LIMPIAR EL PRODCUTO SERVICIOS CUANDO HAYA ERROR O REPETIDO **********************************************  */

                if (valeSeleccionado.catTipovale.idCatTipoVale == 1) {
                    insumo.precio = productoMatched[0].precioProductoServicio
                        ? productoMatched[0].precioProductoServicio[0].precioMostrador
                        : "";
                    if (productoMatched[0].causaIva) {
                        insumo.iva = insumo.precio * ivaConfiguracion;
                    }
                    setPrecioTotal();
                } else if (valeSeleccionado.catTipovale.idCatTipoVale == 2) {
                    insumo.precio = productoMatched[0].precioAseguradora[0].precioUnitario;
                    if (productoMatched[0].causaIva) {
                        insumo.iva = insumo.precio * ivaConfiguracion;
                    }
                    setPrecioTotal();
                }
                valeSeleccionado.valeproductoservicios[index].catTipoProducto = temporal;
                insumo.producto = value;
                setChangesValeSeleccionado(valeSeleccionado);
                BuscaCambios();
            }
            else{
                insumo.idCatServicio = null;
                insumo.idCatProducto = null;
                insumo.precio = null;
                insumo.iva = 0;
                insumo.principioActivo = "";
                insumo.catTipoProducto = null;
                insumo.concentracion = "";
                insumo.nombreComercial = "";
                insumo.servicio = "";
                value = ""
                insumo.descripcion = "";
                insumo.repetido = true;
                insumo.personalIdCatServicio = 0;
                insumo.personalIdCatProducto =  0;
                insumo.existencia = "";
                
                setPrecioTotal();
                setChangesValeSeleccionado(valeSeleccionado);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Producto o servicio repetido",
                    });
            }
        } else {
            insumo.idCatServicio = null;
            insumo.idCatProducto = null;
            insumo.precio = null;
            insumo.iva = 0;
            insumo.repetido = false;
            insumo.personalIdCatServicio = 0;
            insumo.personalIdCatProducto =  0;
            insumo.existencia = "";
            // insumo.nombreComercial = value;
            setPrecioTotal();
            setChangesValeSeleccionado(valeSeleccionado);
        }
    };

    // ************************************************************************************       
    // ************************************************************************************       
    const changeInsumoValeSeleccionadoAseguradoras = async (e, index) => {
        let value = e.target.value;
        let valeproductoservicios = valeSeleccionado.valeproductoservicios;
        let insumo = valeproductoservicios[index];
        let encontrado = false;
        let principioActivoTemp = ""
        let numIdentificacionTemp = ""

        const productosServiciosFiltrados = productosServicios.filter((prodServ) => {
            if(prodServ.idCatTipoproducto==3 && insumo.idCatTipoProductoPersonal == 3){
                if(prodServ.servicio.toLowerCase()==value.toLowerCase()){
                    encontrado = true;
                }

                if(prodServ.servicio.toLowerCase().startsWith(value.toLowerCase())){
                    return true;
                }
            }else{
                if(prodServ.idCatTipoproducto==2 && insumo.idCatTipoProductoPersonal == 2){
                    if(prodServ.principioActivo.toLowerCase()== insumo.principioActivo.toLowerCase() &&  prodServ.nombreComercial.toLowerCase() ==  value.toLowerCase())
                    {
                    encontrado = true;
                    } else  
                    
                    
                    if(prodServ.principioActivo.toLowerCase()== insumo.principioActivo.toLowerCase() &&  prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase()))
                    {
                    return true;
                    }             
                }else{
                    if(prodServ.idCatTipoproducto==1 && insumo.idCatTipoProductoPersonal == 1){
                        if( prodServ.nombreComercial.toLowerCase() ==  value.toLowerCase())
                        {
                           encontrado = true;
                        } else  
                        
                        
                        if(prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase()))
                        {
                           return true;
                        }  
                    }else{
                        if(prodServ.idCatTipoproducto==4 && insumo.idCatTipoProductoPersonal == 4){
                            if( prodServ.nombreComercial.toLowerCase() ==  value.toLowerCase())
                            {
                               encontrado = true;
                            } else  
                            
                            
                            if(prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase()))
                            {
                               return true;
                            }  
                        }
                    }
                }
            }
        });

        setProductosServiciosFilter(productosServiciosFiltrados);

        if(encontrado){
          principioActivoTemp = insumo.principioActivo;
          numIdentificacionTemp = insumo.numIdentificacion;
        }

        const productoMatched  = await checkMatchedProductosAseguradora(value,productosServiciosFiltrados.principioActivo);
       
        if (productoMatched.length > 0) {
            let repetido = false;
            //TODO
            if(productoMatched[0].idCatServicio==null){
                repetido = BuscaRepetido(productoMatched[0].idCatProducto,124);
            }else{
                    repetido = BuscaRepetido(productoMatched[0].idCatServicio,3);
            }
           
            if(!repetido){
                insumo.repetido = false;
                if (productoMatched[0].idCatProducto != null && productoMatched[0].idCatServicio == null) {
                    insumo.idCatProducto = productoMatched[0].idCatProducto;
                    insumo.concentracion = productoMatched[0].concentracion;
                    insumo.principioActivo = productoMatched[0].principioActivo;
                    insumo.personalIdCatProducto = productoMatched[0].idCatProducto;
                    insumo.existencia = await BuscarExistencia(productoMatched[0].idCatProducto)

                } else if (productoMatched[0].idCatProducto == null && productoMatched[0].idCatServicio != null) {
                    insumo.idCatServicio = productoMatched[0].idCatServicio;
                    insumo.personalIdCatServicio = productoMatched[0].idCatServicio;
                }
                //**************************************************** */
                let temporal = TraeTipo(productoMatched[0].idCatTipoproducto);
                insumo.catTipoProducto = temporal;              
                //****** TODO TENEMOS QUE LIMPIAR EL PRODCUTO SERVICIOS CUANDO HAYA ERROR O REPETIDO **********************************************  */

                if (valeSeleccionado.catTipovale.idCatTipoVale == 1) {
                    insumo.precio = productoMatched[0].precioProductoServicio
                        ? productoMatched[0].precioProductoServicio[0].precioMostrador
                        : "";
                    if (productoMatched[0].causaIva) {
                        insumo.iva = insumo.precio * ivaConfiguracion;
                    }
                    setPrecioTotal();
                } else if (valeSeleccionado.catTipovale.idCatTipoVale == 2) {
                    insumo.precio = productoMatched[0].precioAseguradora[0].precioUnitario;
                    if (productoMatched[0].causaIva) {
                        insumo.iva = insumo.precio * ivaConfiguracion;
                    }
                    setPrecioTotal();
                }

                if (insumo.catServicios){
                    insumo.catServicios.servicio = value;
                }else{
                    insumo.catProductos.nombreComercial = value;
                }
                valeSeleccionado.valeproductoservicios[index].catTipoProducto = temporal;
                insumo.producto = value;
                setChangesValeSeleccionado(valeSeleccionado);
                BuscaCambios();
            }
            else{
                insumo.idCatServicio = null;
                insumo.idCatProducto = null;
                insumo.precio = null;
                insumo.iva = 0;
                insumo.catTipoProducto = null;
                insumo.concentracion = "";
                insumo.nombreComercial = "";
                insumo.servicio = "";
                value = ""
                insumo.descripcion = "";
                insumo.repetido = true;
                insumo.personalIdCatServicio = 0;
                insumo.personalIdCatProducto =  0;
                insumo.existencia = "";
                if (insumo.catServicios){
                    insumo.catServicios.servicio = "";
                }else{
                    insumo.catProductos.nombreComercial = value;
                }
                
                setPrecioTotal();
                setChangesValeSeleccionado(valeSeleccionado);

                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Producto o servicio repetido",
                    });
            }
        } else {
            insumo.idCatServicio = null;
            insumo.idCatProducto = null;
            insumo.precio = null;
            insumo.iva = 0;
            insumo.repetido = false;
            insumo.personalIdCatServicio = 0;
            insumo.personalIdCatProducto =  0;
            insumo.producto = value;
            insumo.existencia = "";
            if (insumo.catServicios){
                insumo.catServicios.servicio = value;
            }else{
                insumo.catProductos.nombreComercial = value;
            }
            // insumo.nombreComercial = value;
            setPrecioTotal();
            setChangesValeSeleccionado(valeSeleccionado);
        }
     };    

    const setListarInsumosPorTipoProductoAseguradoras = async (e, index) => 
    {
        let catServiciosLocal= valeSeleccionado.valeproductoservicios[index].catServicios
        let catProductosLocal = valeSeleccionado.valeproductoservicios[index].catProductos
        let esServicioTemp = valeSeleccionado.valeproductoservicios[index]
        let idCatTipoProductoLocal = 0
        let esServicioLocal = false;
        let tipoProductoLocal = 0;
        let principioActivoLocal = "";

        
        if (catServiciosLocal == null && catProductosLocal == null){
            idCatTipoProductoLocal = valeSeleccionado.valeproductoservicios[index].tipoProducto.idCatTipoProducto
        }else
        {
            if(catServiciosLocal){
                tipoProductoLocal = catServiciosLocal.catTipoproducto.idCatTipoProducto
                setEsServicioGlobal(true);
                esServicioLocal = true;
            }else{
                tipoProductoLocal = catProductosLocal.catTipoproducto.idCatTipoProducto
                setEsServicioGlobal(false);
                esServicioLocal = false;
            }
        }

        if(esServicioLocal == false){
            principioActivoLocal = catProductosLocal.principioActivo;
        }

        // let concentracionValeProducto = valeSeleccionado.valeproductoservicios[index].concentracion;
        const productosServiciosFiltrados = await productosServicios.filter((prodServ) => {
            if (esServicioLocal && prodServ.idCatTipoproducto == 3) {
                return true;
            } else {
                if(!esServicioLocal && (tipoProductoLocal == 2 && prodServ.principioActivo == principioActivoLocal) ){
                    return true;
                }

                if(!esServicioLocal && (tipoProductoLocal != 2 && prodServ.idCatTipoproducto == tipoProductoLocal) ){
                    return true;
                }                
            }
        });
        
        // prodServ.concentracion != null && prodServ.concentracion.toLowerCase() === concentracionValeProducto.toLowerCase();
        setProductosServiciosFilter(productosServiciosFiltrados);
    };

    const setListarInsumosPorTipoProducto = async (e, index) => 
    {
        let catServiciosLocal= valeSeleccionado.valeproductoservicios[index].catServicios
        let catProductosLocal = valeSeleccionado.valeproductoservicios[index].catProductos
        let esServicioTemp = valeSeleccionado.valeproductoservicios[index]
        let idCatTipoProductoLocal = 0

        if (catServiciosLocal == null && catProductosLocal == null){
            idCatTipoProductoLocal = valeSeleccionado.valeproductoservicios[index].tipoProducto.idCatTipoProducto
        }

        //Cuando le damos clic al input, se filtran todos los productosServicios, por el principio activo indicado en el insumo
        let principioActivoValeProducto = valeSeleccionado.valeproductoservicios[index].principioActivo;
        setIidCatTipoProductoGlobal(idCatTipoProductoLocal);

        if(esServicioTemp.tipoProducto){
            if(esServicioTemp.tipoProducto.idCatTipoProducto ==3){
                setEsServicioGlobal(true);
            }else{
                setEsServicioGlobal(false);
            }
        }else{
            if(esServicioTemp.catServicios){
                setEsServicioGlobal(true);
            }else{
                setEsServicioGlobal(false);
            }
        }

        // let concentracionValeProducto = valeSeleccionado.valeproductoservicios[index].concentracion;
        const productosServiciosFiltrados = await productosServicios.filter((prodServ) => {
            if (
                prodServ.principioActivo != null &&
                principioActivoValeProducto != null &&
                prodServ.principioActivo.toLowerCase() === principioActivoValeProducto.toLowerCase()
            ) {
                return true;
            } else {
                if(prodServ.idCatTipoproducto == idCatTipoProductoLocal ){
                    return true;
                }
            }
        });
        
        // prodServ.concentracion != null && prodServ.concentracion.toLowerCase() === concentracionValeProducto.toLowerCase();
        setProductosServiciosFilter(productosServiciosFiltrados);
    };

    //EN ESTA PARTE LLEGAN CUANDO SE DA CLICK EN LA LISTA DE PRODUCTO A MODIFICAR
    const filterInsumosModificar = async () => {
        //Cuando le damos clic al input, se filtran todos los productosServicios, por el principio activo indicado en el insumo
        const productosServiciosFiltrados = await productosServicios.filter((prodServ) => {
            if (
                prodServ.idCatProducto != null &&
                prodServ.idCatProducto != insumoModificar.catProductos.idCatProducto &&
                prodServ.principioActivo != null &&
                prodServ.principioActivo.toLowerCase() === insumoModificar.principioActivo.toLowerCase() && !BuscaRepetido(prodServ.idCatProducto,124)
            ) {
                //if(idCatTipoProductoGlobal==prodServ.idCatTipoproducto){
                    return true;
                //}
            }
        });
        if (productosServiciosFiltrados.length <= 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Falla",
                dynamic_description: "No existen productos con el mismo principio activo o ya estan en la lista.",
            });
        }

        setProductosServiciosFilter(productosServiciosFiltrados);
    };

    const toggleExpanded = async (index) => {
        let valeproductoservicios = valeSeleccionado.valeproductoservicios;
        let insumo = valeproductoservicios[index];
        if (insumo.catProductos && enableExpand) {
            insumo.expanded = !insumo.expanded;
        }

        setChangesValeSeleccionado(valeSeleccionado);
    };

    const changeLoteInsumo = async (e, index, indexLote) => {
        // console.log(`Recibi el idInventario ${e.target.value} para el insumo con index ${index} en el invetarioproductoservicio con index ${indexLote}`)

        if (e.target.value != "") {
            let insumo = valeSeleccionado.valeproductoservicios[index];
            let inventario = insumo.catProductos.inventarios.filter((inv) => inv.idInventario == e.target.value)[0];
            let lote = insumo.inventarioProductoServicios[indexLote];

            //TODO: LOTES PARA REGRESAR EL VALOR SI CAMBIAN DE OPINION
            //SI LA CANTIDAD YA ES MAYOR QUE O VAMOS A BUSCAR POR EL ID Y VAMOS A TRAER LA CANTIDAD ORIGINAL

            let loteRepetido = false;
            insumo.inventarioProductoServicios.map((element) => {
                if (!element.idInventarioProductoServicio && element.idInventario == e.target.value) {
                    loteRepetido = true;
                } else if (element.idInventarioProductoServicio && element.inventario.idInventario == e.target.value) {
                    loteRepetido = true;
                }
            });

            //Checamos si hay existe otro caso con el mismo lote, para no permitir tener 2 del mismo lote
            if (loteRepetido) {
                lote.caducidad = "";
                lote.lote = "";
                lote.idInventario = "";
                alert("Ya se tiene un registro con el mismo lote");
            } else {

                lote.piezasAsignadas = 0;
                lote.caducidad = inventario.caducidad;
                lote.lote = inventario.lote;
                lote.idInventario = inventario.idInventario;
                lote.piezasDisponibles = inventario.piezas;

                let contador = 0;
                let posicion = 0;
                let encontrado =  false;
                insumo.catProductos.inventarios.map((inv) => {
                    if (inv.idInventario == lote.idInventarioOriginal) {
                       posicion = contador;
                        encontrado = true;
                    }
                    contador = contador + 1;
                });

                if(lote.idInventarioOriginal != inventario.idInventario){

                    if(encontrado && lote.piezasOriginales > 0){
                        insumo.catProductos.inventarios[posicion].piezas = lote.piezasOriginales;
                    }
                   
                    lote.idInventarioOriginal = inventario.idInventario;
                    lote.piezasOriginales = inventario.piezas;
                    lote.piezasAsignadas = 0;

                }
            }
            lote.piezasAsignadas = 0;
            lote.piezasRecibidas = 0;
            lote.piezasUtilizadas = 0;

            setChangesValeSeleccionado(valeSeleccionado);
        }
    };

    const changePienzasAsginadas = async (e, index, indexLote) => {
        let value = e.target.value;
        if (value < 0) {
            value = value * -1;
        }

        let insumo = valeSeleccionado.valeproductoservicios[index];
        let lote = insumo.inventarioProductoServicios[indexLote];
        let inventario = insumo.catProductos.inventarios.filter((inv) => inv.idInventario == lote.idInventario)[0];

        //Checamos de todos los lotes, si ya tenemos piezasAsignadas, para sumarlos, y que no podamos escoger mas piezas que nos indica el insumo
        let sumaPiezasAsignadas = 0;
        insumo.inventarioProductoServicios.map((element, i) => {
            if (element.piezasAsignadas > 0 && i != indexLote) {
                sumaPiezasAsignadas += parseInt(element.piezasAsignadas, 10);
            }
        });
        //Si ya tenemos piezas asignadas, y le hacemos un cambio, tenemos que regresar lo que teniamos en piezasAsignadas el inventario
        //para mas adelante hacer el analisis de si existen piezas disponibles
        if (lote.piezasAsignadas > 0) {
            inventario.piezas = parseInt(inventario.piezas, 10) + parseInt(lote.piezasAsignadas, 10);
            lote.piezasAsignadas = value;
        } else {
            lote.piezasAsignadas = value;
        }
        //Si tenemos ese numero de piezas o mas en el inventario, se guarda, pero si no, guardamos un 0 y enseñanmos un mensaje para mostar las piezas disponibles
        //Tambien debemos checar que las piezas asignadas, no sean mayores a la piezas que se indican en el valeproductoservicio
        if (inventario.piezas >= lote.piezasAsignadas && insumo.piezasVale - sumaPiezasAsignadas >= lote.piezasAsignadas) {
            inventario.piezas = inventario.piezas - lote.piezasAsignadas;

            if (insumo.piezasVale == sumaPiezasAsignadas + parseInt(lote.piezasAsignadas, 10)) {
                insumo.completed = true;
            } else {
                insumo.completed = false;
            }
        } else if (inventario.piezas < lote.piezasAsignadas) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description:
                    "No hay suficientes piezas, el numero de piezas disponibles para '" +
                    insumo.catProductos.nombreComercial +
                    "' es de: " +
                    inventario.piezas,
            });
            lote.piezasAsignadas = 0;
            insumo.completed = false;
        } else if (insumo.piezasVale - sumaPiezasAsignadas < lote.piezasAsignadas) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "El número de piezas asignadas no puede ser mayor al número de piezas indicadas por el insumo",
            });
            lote.piezasAsignadas = 0;
            insumo.completed = false;
        }
        setChangesValeSeleccionado(valeSeleccionado);
    };

    const changePienzasRecibidas = async (e, index, indexLote) => {
        let value = e.target.value;
        let insumo = valeSeleccionado.valeproductoservicios[index];
        let lote = insumo.inventarioProductoServicios[indexLote];
        let originalPiezasRecibidas = lote.piezasRecibidas;

        if (lote.inventario && lote.piezasRecibidasAuxiliar == null) {
            lote.piezasRecibidasAuxiliar = originalPiezasRecibidas;
            setChangesValeSeleccionado(valeSeleccionado);
        }

        if (value < 0) {
            value = value * -1;
        }

        //Si borramose el valor de piezas recibidas de un lote ya guardado en la base, tenemos que manter ese valor guardado
        if (value == "") {
            lote.piezasRecibidas = value;
        } else {
            //Checamos si el lote tiene inventario (significa que esta guardado en la base de datos)
            //Si lo tiene, entonces checamos que el valor que nos manden, no sea menor a las piezasAsignadas ya guardadas
            if (lote.inventario && lote.piezasRecibidasAuxiliar > value) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: `No se pueden recibir menos piezas de '${lote.piezasRecibidasAuxiliar}'`,
                });
                lote.piezasRecibidas = lote.piezasRecibidasAuxiliar;
            } else {
                //Si no tenemos el lote guardado en base de datos, no nos importa si ponen mas o menos piezasRecibidas
                lote.piezasRecibidas = value;
            }

            //Si lo que le pasamos de piezasRecibidas es mayor a lo que tenemos de piezasAsignadas, marcamos un error
            //Pero ademas preguntamos, si el lote tiene un numero ya guardado de piezasRecibidas, si es asi
            //hacemos un reset al input con el valor guardado, si no se regresa a un 0
            if (parseInt(lote.piezasAsignadas, 10) < value) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "No se pueden recibir mas piezas de las que están asignadas",
                });
                if (lote.piezasRecibidasAuxiliar) {
                    lote.piezasRecibidas = lote.piezasRecibidasAuxiliar;
                } else {
                    lote.piezasRecibidas = 0;
                }
            }
        }
        setChangesValeSeleccionado(valeSeleccionado);
    };

    const changePienzasUtilizadas = async (e, index, indexLote) => {
        let value = e.target.value;
        let insumo = valeSeleccionado.valeproductoservicios[index];
        let lote = insumo.inventarioProductoServicios[indexLote];
        let originalPiezasUtilizadas = lote.piezasUtilizadas;

        //Si borramos el valor de piezas utilizadas de un lote ya guardado en la base, tenemos que manter ese valor guardado
        if (lote.inventario && lote.piezasUtilizadasAuxiliar == null) {
            lote.piezasUtilizadasAuxiliar = originalPiezasUtilizadas;
            setChangesValeSeleccionado(valeSeleccionado);
        }

        if (value < 0) {
            value = value * -1;
        }

        if (value == "") {
            lote.piezasUtilizadas = value;
        } else {
            //Checamos si el lote tiene inventario (significa que esta guardado en la base de datos)
            //Si lo tiene, entonces checamos que el valor que nos manden, no sea menor a las piezasUtilizadas ya guardadas
            if (lote.inventario && lote.piezasUtilizadasAuxiliar > value) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: `No se pueden utlizar menos piezas de '${lote.piezasUtilizadasAuxiliar}'`,
                });
                lote.piezasUtilizadas = lote.piezasUtilizadasAuxiliar;
            } else {
                //Si no tenemos el lote guardado en base de datos, no nos importa si ponen mas o menos piezasUtilizadas
                lote.piezasUtilizadas = value;
            }
        }

        setChangesValeSeleccionado(valeSeleccionado);
    };

    const eliminarLote = async () => {
        let inventario = productoSeleccEliminar.catProductos.inventarios.filter(
            (inv) => inv.idInventario == loteSeleccEliminar.idInventario
        )[0];

        if (loteSeleccEliminar.piezasAsignadas > 0) {
            inventario.piezas = parseInt(inventario.piezas, 10) + parseInt(loteSeleccEliminar.piezasAsignadas, 10);
        }
        productoSeleccEliminar.inventarioProductoServicios = productoSeleccEliminar.inventarioProductoServicios.filter(
            (item) => item != loteSeleccEliminar
        );
        productoSeleccEliminar.completed = false;

        setChangesValeSeleccionado(valeSeleccionado);
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_delete_lote_internal: false,
            success_dlg: true,
            dynamic_title: "Borrado",
            dynamic_description: "El lote ha sido borrado correctamente.",
        });

        setProductoSeleccEliminar(null);
        setLoteSeleccEliminar(null);
    };

    //ACA ES DONDE SE CONTROLA CUANDO SE ESCRIBE EN EL CUADRO DE PRODUCTO AM ODIFICAR

    const selectInsumoNuevoModificar = async (e) => {
        
        let value = e.target.value;
        let numeroIdentificacionAux = ""
        let cadenas = value.split(' -CveInt: ');
        let id = 0;
        value = cadenas[0];
        if(cadenas[1]){
            id = parseInt(cadenas[1], 10);
        }

        modificarInsumoData.descripcion = value;

        //Se filtran
        let Encontrado = false
        const productosServiciosFiltrados = await productosServicios.filter((prodServ) => {
             if (
                prodServ.idCatProducto != null &&
                prodServ.idCatProducto != insumoModificar.catProductos.idCatProducto &&
                prodServ.principioActivo != null &&
                prodServ.principioActivo.toLowerCase() === insumoModificar.principioActivo.toLowerCase() &&
                prodServ.nombreComercial != null && id == prodServ.idCatProducto &&
                prodServ.nombreComercial.toLowerCase()==value.toLowerCase() && !BuscaRepetido(prodServ.idCatProducto,124)
            ) 
            {
                numeroIdentificacionAux = prodServ.numIdentificacion;
                Encontrado = true;
                return true;
            }else if (
                prodServ.idCatProducto != null &&
                prodServ.idCatProducto != insumoModificar.catProductos.idCatProducto &&
                prodServ.principioActivo != null &&
                prodServ.principioActivo.toLowerCase() === insumoModificar.principioActivo.toLowerCase() &&
                prodServ.nombreComercial != null && id == 0 &&
                prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0) &&   !BuscaRepetido(prodServ.idCatProducto,124)
            ) {
                    return true;
            }
        });

        setProductosServiciosFilter(productosServiciosFiltrados);

        if (Encontrado && id > 0) {
            //var lastIndex = value.lastIndexOf("-");
            //let numeroIdentificacionAux = "" + value.slice(lastIndex + 1, value.length).replace(" ", "");

            //El producto que se escoge, tiene que tener el mismo principio activo indicado por el insumo
            const productoServicioSeleccionado = await productosServicios.filter((prodServ) => {
                if (
                    prodServ.numIdentificacion != null &&
                    prodServ.numIdentificacion == numeroIdentificacionAux && id == prodServ.idCatProducto &&
                    prodServ.principioActivo.toLowerCase().startsWith(insumoModificar.principioActivo.toLowerCase(), 0)
                ) {
                    return true;
                }
            });

            if (productoServicioSeleccionado.length == 1) {
                let insumoSeleccionado = productoServicioSeleccionado[0];
                modificarInsumoData.idCatProducto = insumoSeleccionado.idCatProducto;
                modificarInsumoData.presentacion = insumoSeleccionado.presentacion;
                modificarInsumoData.principioActivo = insumoSeleccionado.principioActivo;
                modificarInsumoData.concentracion = insumoSeleccionado.concentracion;
                modificarInsumoData.numIdentificacion = insumoSeleccionado.numIdentificacion;
                modificarInsumoData.lotes = insumoSeleccionado.lotes;
                modificarInsumoData.existencia = insumoSeleccionado.lotes;
                //Ademos debemos revisar el tipoVale, para saber si usaremos los precios de la aseguradora o de ventamostrador
                if (valeSeleccionado.catTipovale.idCatTipoVale == 1) {
                    modificarInsumoData.precio = insumoSeleccionado.precioProductoServicio[0].precioMostrador;
                    if (insumoSeleccionado.causaIva) {
                        modificarInsumoData.iva = modificarInsumoData.precio * ivaConfiguracion;
                    }
                    // setPrecioTotal();
                } else if (valeSeleccionado.catTipovale.idCatTipoVale == 2) {
                    modificarInsumoData.precio = insumoSeleccionado.precioAseguradora[0].precioUnitario;
                    if (insumoSeleccionado.causaIva) {
                        modificarInsumoData.iva = modificarInsumoData.precio * ivaConfiguracion;
                    }
                    // setPrecioTotal();
                }
                setModificarInsumoData(modificarInsumoData);
                //insumoModificar
            } else {
                //Mientras no se encuentre un solo producto/servicio, el insumo se mentiene limpio
                modificarInsumoData.idCatProducto = null;
                modificarInsumoData.precio = null;
                modificarInsumoData.iva = 0;
                modificarInsumoData.catTipoProducto = null;
                setModificarInsumoData(modificarInsumoData);
            }
        } else {
            modificarInsumoData.idCatProducto = null;
            modificarInsumoData.precio = null;
            modificarInsumoData.iva = 0;
            // setPrecioTotal();
            setModificarInsumoData(modificarInsumoData);
        }
    };

    const piezasModificarInsumo = async (e) => {
        let value = e.target.value;
        if (value < 0) {
            value = value * -1;
        }

        if (value?.length <= maxCampoPiezas) {
            setModificarInsumoData({
                ...modificarInsumoData,
                piezas: value,
            });
        }
    };

    async  function BuscarExistencia (IdProductoServicio){
        let cadenaRegreso = ""

            const productosTemp = await traerExistenciaProducto(IdProductoServicio);

            if(productosTemp.length > 0){
                let cadena = ""
                productosTemp.map((inventario) => {
                    if(inventario){
                        cadena = cadena + "" + inventario.lote + " (" + inventario.piezas + "), ";
                    }
                    cadenaRegreso = cadena;
                })
            }else{
                cadenaRegreso = ""
            }
          return cadenaRegreso;
    }


    const submitModificarInsumo = async () => {

        let estatusTemp = valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length-1].catEstatusvale.idCatEstatusVale
        let IdValeTemp = valeSeleccionado.idVale;
        const estatusLocal = await verificarEstatus(IdValeTemp);

        if (estatusTemp != estatusLocal.catEstatusvale.idCatEstatusVale) {
            // alert("Por favor introduzca el nuevo estatus");
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "El vale ya fue actualizado por otro usuario,ya no es posible actualizar",
            });
            return;
        }

        if (!modificarInsumoData.piezas && !modificarInsumoData.idCatProducto) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca las modificaciones al insumo",
            });
            return;
        }

        if (modificarInsumoData.piezas <= 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca un número de piezas mayor a 0",
            });
            return;
        }

        if (modificarInsumoData.idCatProducto == insumoModificar.catProductos.idCatProducto) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca un insumo diferente al actual",
            });
            return;
        }

        setLoading(true);
       
        const res = await submitModificaciones(modificarInsumoData);

        if (res.data.error) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_modificar_insumo: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);
            /*//Con el id del prod, insertamos lo nuevo
            let cambiLocal = false;
            let productoOriginal = 0
            let precioOriginal = 0
            setAutorizacionPE(false);
            let contador = 0;
            valeSeleccionado.valeproductoservicios = valeSeleccionado.valeproductoservicios.filter((insumo) => {
                if (insumo.idValeProductoServicio == res.data.idValeProductoServicio) {
                    productoOriginal = insumo.personalIdCatProductoOriginal
                    precioOriginal = insumo.precio
                    
                    insumo.catProductos = res.data.catProductos;
                    insumo.personalIdCatProducto = res.data.catProductos.idCatProducto;
                    insumo.iva = res.data.iva;
                    insumo.piezasVale = res.data.piezasVale;
                    insumo.precio = res.data.precio;
                    
                    insumo.personalIdCatProductoOriginal = productoOriginal;
                    //insumo.precio  = precioOriginal
                    insumo.productoServicioCambiado = true
                    
                }
                if(insumo.personalIdCatProducto != insumo.personalIdCatProductoOriginal){
                    cambiLocal = true;
                }
                contador = contador + 1;
                return true;
            });
            valeSeleccionado.valeproductoservicios[contador-1].existencia = await BuscarExistencia(valeSeleccionado.valeproductoservicios[contador-1].catProductos.idCatProducto)
            //BuscaCambios();
*/
            // TODO ACA ESTA LA LINEA QUE MARCA EL CAMBIO AL CUAL DEBEMOS VOLVER SI NO SIRVE LO SIGUIENTE
            const permiso = await seleccionarVale(valeSeleccionado.idVale, false);
            setChangesValeSeleccionado(permiso);
            setPrecioTotal();

            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_modificar_insumo: false,
                dynamic_description: "Se realizaron las modificaciones al producto",
            });
        }
    };

    const llenarPiezasUtilizadas = (value, index, indexLote) => {
        let insumo = valeSeleccionado.valeproductoservicios[index];
        let lote = insumo.inventarioProductoServicios[indexLote];
        let originalPiezasUtilizadas = lote.piezasUtilizadas;

        //Si borramos el valor de piezas utilizadas de un lote ya guardado en la base, tenemos que manter ese valor guardado
        if (lote.inventario && lote.piezasUtilizadasAuxiliar == null) {
            lote.piezasUtilizadasAuxiliar = originalPiezasUtilizadas;
            setChangesValeSeleccionado(valeSeleccionado);
        }

        if (value < 0) {
            value = value * -1;
        }

        if (value == "") {
            lote.piezasUtilizadas = value;
        } else {
            //Checamos si el lote tiene inventario (significa que esta guardado en la base de datos)
            //Si lo tiene, entonces checamos que el valor que nos manden, no sea menor a las piezasUtilizadas ya guardadas
            if (lote.inventario && lote.piezasUtilizadasAuxiliar > value) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: `No se pueden utlizar menos piezas de '${lote.piezasUtilizadasAuxiliar}'`,
                });
                lote.piezasUtilizadas = lote.piezasUtilizadasAuxiliar;
            } else {
                //Si no tenemos el lote guardado en base de datos, no nos importa si ponen mas o menos piezasUtilizadas
                lote.piezasUtilizadas = value;
            }
        }
        setChangesValeSeleccionado(valeSeleccionado);
    };

    const uploadCambioEstatus = async (exportCotizacion) => {
            setLoading(true);
            cambioEstatusData.idVale = valeSeleccionado.idVale;
            cambioEstatusData.idEstatusNuevo = "";
            const res = await actualizarOCambiarEstatus({
                ...cambioEstatusData,
                necesitaAutorizacion: 2,
             });

            if (res) {
                setLoading(false);

                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_alert_save_changes: false,
                    confirm_alert_autorizar_vale: false,
                    confirm_alert_upload_vale: false,
                    confirm_alert_incompleto: false,
                    confirm_alert_imprimir_cotizacion: false,
                    confirm_alert_status_change_enrecoleccion: false,
                    path: "/autorizaciones",
                    dynamic_description: "Se registraron los cambios del vale.",
                    redirect: true,
                });
            } else {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_alert_upload_vale: false,
                    confirm_alert_save_changes: false,
                    confirm_alert_imprimir_cotizacion: false,
                    confirm_alert_status_change_enrecoleccion: false,
                    confirm_alert_incompleto: false,
                    dynamic_title: "Fallo",
                    dynamic_description: "Error al subir los cambios, Inténtalo por favor más tarde.",
                });
            }
        }

    useEffect(() => {
        //Cuando ya se tenga lleno el valeSeleccionado, y sea falso el historicoVale, estamos hablando de que podemos modificar los insumos
        if (valeSeleccionado && !valeSeleccionado.historicoVale) {
            let idCatEstatusValeActual =
                valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale;
            let estatusValeProx = null;

            if (proximosEstatusVale.length == 1) {
                estatusValeProx = proximosEstatusVale[0];
            }

            if (estatusValeProx != null && (estatusValeProx.idCatEstatusVale == 8 || estatusValeProx.idCatEstatusVale == 18)) {
                setHiddenPiezasUtilizadas(false);
                setDisabledPiezasUtlizadas(true);
            }

            switch (idCatEstatusValeActual) {
                //Solicitado
                //Checamos si estamos en SOLICITADO, para habilitar el boton de "Agregar Insumos", para esconder la columna "COMPLETO"
                //y para no permitir expandir el renlgon
                case 1:
                case 11:
                case 12:
                    setModificarInsumos(true);
                    setHiddenCompleto(true);
                    setEnableExpand(false);
                    if (valeSeleccionado.catTipovale.idCatTipoVale == 1) {
                        listarProductosServiciosPersonal();
                    } else if (valeSeleccionado.catTipovale.idCatTipoVale == 2) {
                        listarProductosServiciosAseguradora(valeSeleccionado.catAseguradoras.idCatAseguradora);
                    }

                    //Solo cuando estamos en SOLICITADO nos importa, el cambiar los insumos
                    // if (productosServicios.length < 1) {
                    //   if (valeSeleccionado.catTipovale.idCatTipoVale == 1) {
                    //     listarProductosServiciosPersonal();
                    //   } else if (valeSeleccionado.catTipovale.idCatTipoVale == 2) {
                    //     listarProductosServiciosAseguradora(valeSeleccionado.catAseguradoras.idCatAseguradora);
                    //   }
                    // }
                    break;
                //Cotizado
                case 2:
                    setHiddenCompleto(true);
                    setEnableExpand(false);
                    break;

                //Aprobado
                //Recoleccion
                case 3:
                case 13:
                case 4:
                case 14:
                    setHiddenAgregarLote(false);
                    setHiddenPiezasRecibidas(true);
                    break;
                //Completo
                case 5:
                case 15:
                    if (usuario.authorities.includes("ROLE_FARMACIA")) {
                        //Si entra farmacia, cuando esta en COMPLETO, es solo porque se hizo una entrega parcial y solo puede agregar lotes
                        //pero no puede cambiar el estatus, solo guardar los cambios
                        setHiddenAgregarLote(false);
                    }
                    if (usuario.authorities.includes("ROLE_CENTRO_MEZCLAS")) {
                        //Y permitimos el editar piezasRecibidas
                        setDisabledPiezasRecibidas(true);
                    }
                    break;

                //Por aplicar o cobrado
                case 6:
                case 16:
                case 23:
                case 24:
                    valeSeleccionado.valeproductoservicios.map((insumos, index) => {
                        insumos.inventarioProductoServicios.map((lote, indexLote) => {
                            const value = lote.piezasAsignadas ? lote.piezasAsignadas : 0;
                            llenarPiezasUtilizadas(value, index, indexLote);
                        });
                    });

                    break;

                //Aplicado
                //Reclamado y Aceptado
                case 8:
                case 18:
                case 19:
                case 20:
                    setHiddenPiezasUtilizadas(false);
                    setDisabledPiezasUtlizadas(false);
                    break;

                //Cobrado
                case 9:
                    setHiddenPiezasUtilizadas(false);
                    if (valeSeleccionado.catMedicos.catTipoMedico.idCatTipoMedico == 1) {
                        setDisabledPiezasUtlizadas(false);
                    }
                    break;
                case 21:
                    setHiddenPiezasUtilizadas(false);
                    setDisabledPiezasUtlizadas(true);
                    break;

                default:
                    break;
            }

            setPrecioTotal();

            if (proximosEstatusVale.length == 1) {
                let estatusValeProx = proximosEstatusVale[0];
                if (estatusValeProx.idCatEstatusVale == 8) {
                    setHiddenPiezasUtilizadas(false);
                    setDisabledPiezasUtlizadas(true);
                }
            }
        } else if (valeSeleccionado && valeSeleccionado.historicoVale) {
            setHiddenPiezasUtilizadas(false);
            setDisabledPiezasUtlizadas(false);
            setHiddenCompletarPrincipioActivo(true);
            setPrecioTotal();
        }

        configuracion.map((config) => {
            if (config.clave == "IVA") {
                setIvaConfiguracion(parseFloat(config.valor) / 100);
            }
        });

        const estatusNoHide = [3, 4, 13, 14];
        let hideRowLote =
            valeSeleccionado && valeSeleccionado.estatusvales.length > 0
                ? valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1]
                : null;
        hideRowLote = hideRowLote ? hideRowLote.catEstatusvale.idCatEstatusVale : null;

        const contieneEstatus = estatusNoHide.includes(hideRowLote);
        setHideLote(!contieneEstatus);
    }, [valeSeleccionado, configuracion]);

    return (
        <div autoComplete="off"  className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Lista de Productos</h3>

            <Card className="mt-10">
                <CardBody>
                    <Row hidden={!modificarInsumos}>
                        <Col>
                            {usuario.authorities.includes("OP_FARMACIA_VALE_INSUMO_INSERTAR") ||(usuario.authorities.includes("ROLE_ASEGURADORAS")) ? (
                                <button
                                    className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                    type="button"
                                    onClick={agregarInsumo}>
                                    Agregar Producto
                                </button>
                            ) : null}                                                      
                        </Col>                       
                    </Row>                                    
                    <Row className="mt-4">
                        <Col>
                            <div className="table-responsive">
                                <table className="table table-hover table-centered table-wrap mb-0">
                                    <thead>
                                    <tr>
                                        <th  scope="col"></th>
                                        <th   hidden={hideLote} scope="col">
                                            Con Lotes
                                        </th>
                                        <th width={300}  scope="col">Producto</th>
                                        <th   scope="col">
                                            Tipo<br></br>Producto
                                        </th>
                                         {MostrarLotes ? <th scope="col">Existencia<br></br>Lote (Cantidad)</th> : null}
                                        <th  scope="col">
                                            Principio<br></br>Activo
                                        </th>
                                        <th scope="col">Concentración</th>
                                        <th scope="col">Piezas</th>
                                        <th scope="col">
                                            Precio<br></br>Unitario
                                        </th>
                                        <th scope="col">Iva</th>
                                        <th scope="col">Total</th>
                                        {!hiddenCompleto ? <th scope="col">Completo</th> : null}
                                        {modificarInsumos ? (
                                            <>
                                                <th scope="col"></th>
                                                <th scope="col">Es Compra<br></br>Externa</th>
                                                {modificarInsumos && usuario.authorities.includes("OP_FARMACIA_VALE_INSUMO_MODIFICAR") ? <th scope="col">Modificar</th>
                                                  : null}
                                                <th scope="col">Eliminar</th>
                                            </>
                                        ) : null}
                                        {valeSeleccionado? valeSeleccionado.necesitaAutorizacion===1 && usuario.authorities.includes("ROLE_QUIMIO")? (
                                            <>
                                                <th scope="col">Autorizar</th>
                                            </>
                                        ) : null:null}                                        
                                    </tr>
                                    </thead>
                                    <tbody>
                                       {valeSeleccionado &&
                                        valeSeleccionado.valeproductoservicios.map((insumos, index) => {
                                            return (
                                                insumos.activo?
                                                <>
                                                    <tr key={insumos.idValeProductoServicio} onClick={() => toggleExpanded(index)}>
                                                        <td>
                                                            {insumos.catProductos
                                                                ? [insumos.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>]
                                                                : ""}
                                                        </td>
                                                        <td hidden={hideLote}>
                                                            {insumos.inventarioProductoServicios && insumos.inventarioProductoServicios.length > 0
                                                                ? "SI"
                                                                : "NO"}
                                                        </td>
                                                        <td >
                                                            {!insumos.catServicios  && valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale != 12 || valeSeleccionado.historicoVale? 
                                                            (
                                                                insumos.catProductos || insumos.catServicios ||
                                                                (insumos.producto && valeSeleccionado.historicoVale) ||
                                                                (usuario.authorities.includes("ROLE_ASEGURADORAS") &&
                                                                !usuario.authorities.includes("OP_FARMACIA_VALE_INSUMO_INSERTAR"))
                                                                ? [
                                                                    insumos.catProductos
                                                                        ? insumos.catProductos.nombreComercial
                                                                        : [insumos.catServicios ? insumos.catServicios.servicio : insumos.producto],
                                                                ]
                                                                : [
                                                                    !hiddenCompletarPrincipioActivo && 
                                                                    usuario.authorities.includes("OP_FARMACIA_VALE_INSUMO_INSERTAR") ? (
                                                                        <div>
                                                                            <input
                                                                                type="text"
                                                                                autoComplete="off" 
                                                                                id={`productoservicio${index}`}
                                                                                onClick={(e) => setListarInsumosPorTipoProducto(e, index)}
                                                                                onChange={(e) => changeInsumoValeSeleccionado(e, index)}
                                                                                list={`listaInsumosPrincipioActivo${index}`}
                                                                                className="form-control-vales"
                                                                            />   

                                                                            {!insumos.idCatServicio && !insumos.idCatProducto? insumos.producto : "" }
                                                                            <datalist autoComplete="off"  id={`listaInsumosPrincipioActivo${index}`}>
                                                                                {productosServiciosFilter.map((prodServ) => {
                                                                                    return (
                                                                                        <>
                                                                                            {!insumos.repetido?
                                                                                                prodServ.idCatProducto != null ? (
                                                                                                    <option value={`${prodServ.nombreComercial} - ${prodServ.numIdentificacion}`}>
                                                                                                        {prodServ.nombreComercial} - {prodServ.numIdentificacion} -
                                                                                                        {prodServ.codigoEAN} - {prodServ.principioActivo}
                                                                                                    </option>
                                                                                                ) : (
                                                                                                    <option value={`${prodServ.servicio}`}>
                                                                                                        {prodServ.servicio}
                                                                                                    </option>
                                                                                                )
                                                                                            :""}
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </datalist>
                                                                        </div>
                                                                    ) : null,
                                                                ]
                                                            ) : 
                                                            (
                                                                (valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale == 12 ||
                                                                  valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale == 11 ||
                                                                    valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale == 1   )&& !valeSeleccionado.historicoVale? 
                                                                [                                                               
                                                                 <div >
                                                                    <input
                                                                        type="text"
                                                                        id={`productoservicio${index}`}
                                                                        autoComplete="off" 
                                                                        onClick={(e) => setListarInsumosPorTipoProductoAseguradoras(e, index)}
                                                                        onChange={(e) => changeInsumoValeSeleccionadoAseguradoras(e, index)}
                                                                        list={`listaInsumosPrincipioActivo${index}`}
                                                                        className="form-control-vales"
                                                                        value={ insumos.catServicios != null ? insumos.catServicios.servicio : insumos.catProductos? insumos.catProductos.nombreComercial:insumos.producto}
                                                                    />   
                                                                    <datalist autoComplete="off" id={`listaInsumosPrincipioActivo${index}`}>
                                                                        {productosServiciosFilter.map((prodServ2) => {
                                                                            return (
                                                                                <>
                                                                                    {!insumos.repetido?
                                                                                        prodServ2.idCatProducto != null ? (
                                                                                            <option value={`${prodServ2.nombreComercial}`}>
                                                                                            </option>
                                                                                        ) : (
                                                                                            <option value={`${prodServ2.servicio}`}>
                                                                                                {prodServ2.servicio}
                                                                                            </option>
                                                                                        )
                                                                                    :""}
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </datalist>
                                                                </div>  
                                                               ]:[!insumos.idCatServicio && !insumos.idCatProducto? insumos.producto : "" ]
                                                            )                                                          
                                                            }
                                                        </td>
                                                        
                                                        <td>
                                                            {insumos.catTipoProducto
                                                                ? insumos.catTipoProducto
                                                                : (insumos.catProductos !== null && insumos.catProductos !== undefined) ||
                                                                  (insumos.catServicios !== null && insumos.catServicios)
                                                                    ? insumos.catProductos
                                                                        ? insumos.catProductos.catTipoproducto.tipoProducto
                                                                        : insumos.catServicios.catTipoproducto.tipoProducto
                                                                    : insumos.tipoProducto ? insumos.tipoProducto.tipoProducto : ""}
                                                        </td> 
                                                        {MostrarLotes?<td>
                                                            <div style={{ width: '160px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                                                                {MostrarLotes?insumos.existencia:""}
                                                            </div>                                                            
                                                         </td>:null}
                                                        
                                                        <td> {insumos.principioActivo && insumos.principioActivo} </td>
                                                        <td> {insumos.concentracion && insumos.concentracion} </td>

                                                        {(valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.estatusVale == "SOLICITADO" ||
                                                        valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.estatusVale == "COTIZADO" )
                                                             && !valeSeleccionado.historicoVale ? (
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    autoComplete="off" 
                                                                    min="0"
                                                                    value={insumos.piezasVale}
                                                                    onChange={(e) => changeInsumoPiezas(e, index, false)}
                                                                    className="form-control-inputs-vales text-right"
                                                                />
                                                            </td>
                                                        ) : (
                                                            <td> {insumos.piezasVale} </td>
                                                        )}
                                                        {(valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale .estatusVale == "SOLICITADO" || 
                                                        valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale .estatusVale == "COTIZADO")
                                                              && !valeSeleccionado.historicoVale ? (
                                                            <td>
                                                                <NumericFormat
                                                                    // disabled={true}
                                                                    id="precio"
                                                                    name="precio"
                                                                    autoComplete="off" 
                                                                    thousandSeparator={true}
                                                                    prefix="$"
                                                                    decimalScale={2}
                                                                    allowNegative={false}
                                                                    allowLeadingZeros={false}
                                                                    displayType="input"
                                                                    value={insumos.precio ? insumos.precio : "$0"}
                                                                    onValueChange={(values) => {
                                                                        changeInsumoPrecioUnitario(
                                                                            {
                                                                                target: {
                                                                                    value: values.value,
                                                                                    name: "precioCompra",
                                                                                },
                                                                            },
                                                                            index,
                                                                            false
                                                                        );
                                                                    }}
                                                                    // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                                                    className={ (insumos.personalIdCatServicio==0 && insumos.personalIdCatProducto == 0)? "form-control-inputs-vales-amarillo text-right" : "form-control-inputs-vales text-right"}
                                                                />
                                                                {}
                                                            </td>
                                                        ) : (
                                                            <td className="text-right">
                                                                <NumericFormat
                                                                    // disabled={true}
                                                                    id="precio"
                                                                    name="precio"
                                                                    autoComplete="off" 
                                                                    thousandSeparator={true}
                                                                    prefix="$"
                                                                    decimalScale={2}
                                                                    allowNegative={false}
                                                                    allowLeadingZeros={false}
                                                                    displayType="text"
                                                                    value={insumos.precio ? insumos.precio : "$0"}
                                                                    // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                                                    className="text-right"
                                                                />
                                                            </td>
                                                        )}
                                                        {/* <td className="text-right"> {insumos.precio && formatNumber(insumos.precio)} </td> */}
                                                        <td className="text-right">
                                                            <NumericFormat
                                                                // disabled={true}
                                                                autoComplete="off" 
                                                                id="iva"
                                                                name="iva"
                                                                thousandSeparator={true}
                                                                prefix="$"
                                                                decimalScale={2}
                                                                allowNegative={false}
                                                                allowLeadingZeros={false}
                                                                displayType="text"
                                                                value={insumos.iva * insumos.piezasVale}
                                                                // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                                                className="text-right"
                                                            />
                                                            {/* {formatNumber(insumos.iva * insumos.piezasVale)} */}
                                                        </td>
                                                        <td  className="text-right">
                                                            <NumericFormat
                                                                // disabled={true}
                                                                autoComplete="off" 
                                                                id="total"
                                                                name="total"
                                                                thousandSeparator={true}
                                                                prefix="$"
                                                                decimalScale={2}
                                                                allowNegative={false}
                                                                allowLeadingZeros={false}
                                                                displayType="text"
                                                                value={insumos.precio * insumos.piezasVale + insumos.iva * insumos.piezasVale}
                                                                // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                                                className="text-right"
                                                            />
                                                            {}
                                                            {}
                                                        </td>
                                                        {!hiddenCompleto ? (
                                                            <td>
                                                                <div>
                                                                    <span
                                                                        id={`completo${index}`}
                                                                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                                                            insumos.completed ? "bg-green-100" : "bg-red-100"
                                                                        }  capitalize`}>
                                                                    {insumos.completed ? "SI" : "NO"}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        ) : null}
                                                        {modificarInsumos ? <td></td> : null}
                                                        {/* {modificarInsumos && insumos.catServicios ? <td></td> : null} */}
                                                        {usuario.authorities.includes("ROLE_ASEGURADORAS") && modificarInsumos 
                                                            && !insumos.catServicios ? (
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    autoComplete="off" 
                                                                    name="compraExterna"
                                                                    onChange={(e) => changeCompraExterna(e, index, false)}
                                                                    checked={insumos.compraExterna ? insumos.compraExterna : false}
                                                                    // onChange={modificarProductoCompraExterna}
                                                                    // checked={modificarInsumoData.compraExterna}
                                                                    className="form-control"
                                                                />
                                                            </td>
                                                        ) : modificarInsumos ? (
                                                            <td></td>
                                                        ) : null}
                                                        {modificarInsumos && insumos.catProductos 
                                                            && !insumos.catServicios && usuario.authorities.includes("OP_FARMACIA_VALE_INSUMO_MODIFICAR") ? (
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                                                    onClick={() => {
                                                                        setInsumoModificar(insumos);
                                                                        setModificarInsumoData({
                                                                            idValeProductoServicio: insumos.idValeProductoServicio,
                                                                        });
                                                                        setUISweetAlert({
                                                                            ...UISweetAlert,
                                                                            confirm_alert_modificar_insumo: true,
                                                                        });
                                                                    }}>
                                                                    Modificar
                                                                </button>
                                                            </td>
                                                        ) : null}

                                                        {insumos.catServicios && 
                                                        (valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length-1].catEstatusvale.idCatEstatusVale==11 ||
                                                            valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length-1].catEstatusvale.idCatEstatusVale==1)?
                                                         <td></td>:null}  

                                                        {modificarInsumos 
                                                            && (usuario.authorities.includes("ROLE_FARMACIA")|| !usuario.authorities.includes("ROLE_CONTABILIDAD") ) ? (
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                                                    onClick={() => {
                                                                        setEliminarComentario("")
                                                                        setInsumoModificar(insumos);
                                                                        setPosicionInsumoModificar(index);
                                                                        setUISweetAlert({
                                                                            ...UISweetAlert,
                                                                            confirm_alert_EliminarExistente_insumo: true,
                                                                        });
                                                                    }}>
                                                                    Eliminar
                                                                </button>
                                                            </td>
                                                        ) : null}   

                                                       {insumos.catProductos &&  insumos.existeCambio && valeSeleccionado.necesitaAutorizacion===1 
                                                       && usuario.authorities.includes("ROLE_QUIMIO")? (
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary-blue-umo w-md waves-effect waves-light flex"
                                                                    onClick={() => {
                                                                        setInsumoModificar(insumos);
                                                                        setModificarInsumoData({
                                                                            idValeProductoServicio: insumos.idValeProductoServicio,
                                                                        });
                                                                        setUISweetAlert({
                                                                            ...UISweetAlert,
                                                                            confirm_alert_ver_insumo: true,
                                                                        });
                                                                    }}>
                                                                    Ver Cambios
                                                                </button>
                                                            </td>
                                                        ) : null}                                                        
                                                    </tr>

                                                    {insumos.expanded && (
                                                        <tr>
                                                            <td colSpan={12}>
                                                                <div>
                                                                    <div hidden={hiddenAgregarLote || (!insumos.completed ? false : true)}>
                                                                        <button
                                                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                                                                            type="button"
                                                                            onClick={() => agregarLote(index)}>
                                                                            Agregar Lote
                                                                        </button>
                                                                    </div>
                                                                    {insumos.inventarioProductoServicios.length > 0 && (
                                                                        <div className="table-responsive">
                                                                            <table className="table table-hover table-centered table-nowrap mb-10">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th scope="col">Lote</th>
                                                                                    <th scope="col">Caducidad</th>
                                                                                    <th hidden={hiddenAgregarLote} scope="col">
                                                                                        Piezas Disponibles
                                                                                    </th>
                                                                                    {!hiddenPiezasAsignadas ? (
                                                                                        <th scope="col">
                                                                                            Piezas Asignadas<br></br> (Farmacia)
                                                                                        </th>
                                                                                    ) : null}
                                                                                    {!hiddenPiezasRecibidas ? (
                                                                                        <th scope="col">
                                                                                            Piezas Recibidas<br></br> (CM)
                                                                                        </th>
                                                                                    ) : null}
                                                                                    {!hiddenPiezasUtilizadas ? (
                                                                                        <th scope="col">
                                                                                            Piezas Utilizadas<br></br> (Quimio)
                                                                                        </th>
                                                                                    ) : null}
                                                                                    {!hiddenAgregarLote ? <th scope="col">Eliminar</th> : null}
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {insumos.inventarioProductoServicios.map((lote, indexLote) => {
                                                                                    return (
                                                                                        <>
                                                                                            <tr key={insumos.idValeProductoServicio}>
                                                                                                <td>
                                                                                                    {lote.inventario ? (
                                                                                                        lote.inventario.lote
                                                                                                    ) : (
                                                                                                        <div>
                                                                                                            <select
                                                                                                                autoComplete="off" 
                                                                                                                className="form-control"
                                                                                                                style={{
                                                                                                                    padding: "0 2rem 0 0",
                                                                                                                }}
                                                                                                                value={lote.idInventario}
                                                                                                                onChange={(e) => changeLoteInsumo(e, index, indexLote)}>
                                                                                                                <option selected value="">
                                                                                                                    Seleccione un lote
                                                                                                                </option>
                                                                                                                {insumos.catProductos.inventarios.map((inventarios) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            {inventarios.activo && (
                                                                                                                                <option value={inventarios.idInventario}>
                                                                                                                                    {inventarios.lote + " ( " + inventarios.piezas + " ) "}
                                                                                                                                </option>
                                                                                                                            )}
                                                                                                                        </>
                                                                                                                    );
                                                                                                                })}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </td>
                                                                                                <td>{lote.inventario ? lote.inventario.caducidad : lote.caducidad}</td>

                                                                                                <td hidden={hiddenAgregarLote}>
                                                                                                    {lote.inventario
                                                                                                        ? lote.inventario.piezasDisponibles
                                                                                                        : lote.piezasDisponibles}
                                                                                                </td>

                                                                                                {!hiddenPiezasAsignadas ? (
                                                                                                    <td>
                                                                                                        <input
                                                                                                            type="number"
                                                                                                            autoComplete="off" 
                                                                                                            min="0"
                                                                                                            value={lote.piezasAsignadas}
                                                                                                            disabled={lote.idInventario ? false : true}
                                                                                                            onKeyDown={(e) => {
                                                                                                                // Permite solo teclas numéricas, Backspace y Delete
                                                                                                                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                                                                                                                    e.preventDefault();
                                                                                                                }
                                                                                                            }}                                                                                                              
                                                                                                            onChange={(e) => changePienzasAsginadas(e, index, indexLote)}
                                                                                                            className="form-control"
                                                                                                        />
                                                                                                    </td>
                                                                                                ) : null}
                                                                                                {!hiddenPiezasRecibidas ? (
                                                                                                    <td>
                                                                                                        <input
                                                                                                            type="number"
                                                                                                            autoComplete="off" 
                                                                                                            min={lote.idInventario ? 0 : lote.piezasRecibidas}
                                                                                                            value={lote.piezasRecibidas}
                                                                                                            disabled={
                                                                                                                lote.piezasAsignadas > 0 && disabledPiezasRecibidas ? false : true
                                                                                                            }
                                                                                                            onKeyDown={(e) => {
                                                                                                                // Permite solo teclas numéricas, Backspace y Delete
                                                                                                                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                                                                                                                    e.preventDefault();
                                                                                                                }
                                                                                                            }}                                                                                                            
                                                                                                            onChange={(e) => changePienzasRecibidas(e, index, indexLote)}
                                                                                                            className="form-control"
                                                                                                            name="piezasRecibidas"
                                                                                                        />
                                                                                                    </td>
                                                                                                ) : null}
                                                                                                {!hiddenPiezasUtilizadas ? (
                                                                                                    <td>
                                                                                                        <input
                                                                                                            type="number"
                                                                                                            autoComplete="off" 
                                                                                                            min={lote.idInventario ? 0 : lote.piezasUtilizadas}
                                                                                                            value={lote.piezasUtilizadas}
                                                                                                            // disabled={
                                                                                                            //   lote.piezasRecibidas > 0 && disabledPiezasUtlizadas ? false : true
                                                                                                            // }
                                                                                                            disabled={!disabledPiezasUtlizadas}
                                                                                                            onChange={(e) => changePienzasUtilizadas(e, index, indexLote)}
                                                                                                            className="form-control"
                                                                                                            name="piezasUtilizadas"
                                                                                                        />
                                                                                                    </td>
                                                                                                ) : null}

                                                                                                {!hiddenAgregarLote ? (
                                                                                                    <td>
                                                                                                        {!lote.idInventarioProductoServicio && (
                                                                                                            <div>
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                                                                                                    onClick={() => {
                                                                                                                        setProductoSeleccEliminar(insumos);
                                                                                                                        setLoteSeleccEliminar(lote);
                                                                                                                        setUISweetAlert({
                                                                                                                            ...UISweetAlert,
                                                                                                                            confirm_alert_delete_lote_internal: true,
                                                                                                                        });
                                                                                                                    }}>
                                                                                                                    Eliminar
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </td>
                                                                                                ) : null}
                                                                                            </tr>
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            :null);
                                        })}
                                    {cambioEstatusData.valeProductoServicio &&
                                        cambioEstatusData.valeProductoServicio.map((insumos, index) => (
                                            <tr key={index}>
                                                <td></td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        autoComplete="off" 
                                                        value={insumos.descripcion}
                                                        onClick={(e) => setListarInsumosPorTipoProductoNuevo(e, index)}
                                                        onChange={(e) => changeInsumoCat(e, index)}
                                                        list={`listaInsumos${index}`}
                                                        className="form-control"
                                                    />
                                                    <datalist autoComplete="off"  id={`listaInsumos${index}`}>
                                                        {productosServiciosFilter.map((prodServ) => {
                                                            return (
                                                                <>
                                                                    {prodServ.idCatProducto != null ? (
                                                                        <option value={`${prodServ.nombreComercial} - ${prodServ.numIdentificacion}`}>
                                                                            {prodServ.nombreComercial} - {prodServ.numIdentificacion} -{prodServ.codigoEAN} -{" "}
                                                                            {prodServ.principioActivo}
                                                                        </option>
                                                                    ) : (
                                                                        <option value={`${prodServ.servicio}`}>{prodServ.servicio}</option>
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                    </datalist>
                                                </td>
                                                <td>{insumos.catTipoProducto}</td>
                                                {MostrarLotes?<td>
                                                    <div style={{ width: '180px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                                                    {MostrarLotes?insumos.existencia:""}
                                                    </div>                                                            
                                                </td>:null}
                                                <td>{insumos.principioActivo}</td>
                                                <td>{insumos.concentracion}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        autoComplete="off" 
                                                        min="0"
                                                        value={insumos.piezas}
                                                        onChange={(e) => changeInsumoPiezas(e, index, true)}
                                                        className="form-control-inputs-vales"
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat
                                                            // disabled={true}
                                                            name="precio"
                                                            autoComplete="off" 
                                                            thousandSeparator={true}
                                                            prefix="$"
                                                            decimalScale={2}
                                                            allowNegative={false}
                                                            allowLeadingZeros={false}
                                                            displayType="input"
                                                            value={insumos.precio ? insumos.precio : "$0"}
                                                            onValueChange={(values) => {
                                                                changeInsumoPrecio(
                                                                    {
                                                                        target: {
                                                                            value: values.value,
                                                                        },
                                                                    },
                                                                    index,
                                                                    true
                                                                );
                                                            }}
                                                            //onChange={(e) => changeInsumoPrecio(e, index, true)}
                                                            // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                                            className={ insumos.precio == 0? "form-control-inputs-vales-amarillo text-right" : "form-control-inputs-vales text-right"}
                                                        />                                                    
                                                </td>                                                
                                                {/* <td style={{ textAlign: "right" }}>{insumos.precio && `$${insumos.precio}`}</td> */}
                                                <td style={{textAlign: "right"}}> {formatNumber(insumos.iva * insumos.piezas)}</td>
                                                {/* <td style={{ textAlign: "right" }}> ${(insumos.iva * insumos.piezas).toFixed(2)}</td> */}
                                                <td style={{textAlign: "right"}}>
                                                    {" "}
                                                    {insumos.precio && insumos.piezas
                                                        ? formatNumber(insumos.precio * insumos.piezas + insumos.iva * insumos.piezas)
                                                        : "$0"}{" "}
                                                </td>
                                                <td style={{textAlign: "right"}}>
                                                    {" "}
                                                </td>    
                                                <td style={{textAlign: "right"}}>
                                                    {" "}
                                                </td>  
                                                {modificarInsumos && usuario.authorities.includes("OP_FARMACIA_VALE_INSUMO_MODIFICAR") ? 
                                                <td style={{textAlign: "right"}}>
                                                    {" "}
                                                </td>  
                                                  : null}                                                                                                                                            
                                                {/* <td style={{ textAlign: "right" }}> {insumos.precio && insumos.piezas ? `$${(insumos.precio * insumos.piezas + insumos.iva * insumos.piezas).toFixed(2)}` : "$0"} </td> */}
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                                        // onClick={() => eliminarInsumo(index)}
                                                        onClick={() => {
                                                            setProductoSeleccEliminar(insumos);
                                                            setUISweetAlert({
                                                                ...UISweetAlert,
                                                                confirm_alert_delete_insumo: true,
                                                            });
                                                        }}>
                                                        Eliminar
                                                    </button>
                                                </td>
                                                <td></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card className="mt-4 w-full">
                <Card>
                    <Row>
                        <Col md="12">
                            <table
                                className="w-2/6"
                                style={{
                                    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                    boxSizing: "border-box",
                                    fontSize: "14px",
                                    backgroundColor: "inherit",
                                    margin: "0",
                                    float: "right",
                                }}
                                bgcolor="#f6f6f6">
                                <tbody>
                                <tr
                                    style={{
                                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                    }}>
                                    <td
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            borderTopWidth: "1px",
                                            borderTopColor: "#eee",
                                            borderTopStyle: "solid",
                                            margin: "0",
                                            padding: "5px 0",
                                            textAlign: "left",
                                        }}
                                        valign="top">
                                        Sub-Total
                                    </td>
                                    <td
                                        className="alignright"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "right",
                                            borderTopWidth: "1px",
                                            borderTopColor: "#eee",
                                            borderTopStyle: "solid",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="right"
                                        valign="top">
                                        {formatNumber(parseFloat(detallesPrecio.subtotal))}
                                        {/* ${detallesPrecio.subtotal} */}
                                    </td>
                                </tr>
                                <tr
                                    style={{
                                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                    }}>
                                    <td
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            borderTopWidth: "1px",
                                            borderTopColor: "#eee",
                                            borderTopStyle: "solid",
                                            margin: "0",
                                            padding: "5px 0",
                                            textAlign: "left",
                                        }}
                                        valign="top">
                                        IVA
                                    </td>
                                    <td
                                        className="alignright"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "right",
                                            borderTopWidth: "1px",
                                            borderTopColor: "#eee",
                                            borderTopStyle: "solid",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="right"
                                        valign="top">
                                        {formatNumber(parseFloat(detallesPrecio.iva))}
                                        {/* ${detallesPrecio.iva} */}
                                    </td>
                                </tr>
                                <tr
                                    className="total"
                                    style={{
                                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                    }}>
                                    <td
                                        width="80%"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "left",
                                            borderTopWidth: "5px",
                                            borderTopColor: "#333",
                                            borderTopStyle: "double",
                                            borderBottomColor: "#333",
                                            borderBottomWidth: "2px",
                                            borderBottomStyle: "solid",
                                            fontWeight: "700",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="left"
                                        valign="top">
                                        Total
                                    </td>
                                    <td
                                        className="alignright"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "right",
                                            borderTopWidth: "5px",
                                            borderTopColor: "#333",
                                            borderTopStyle: "double",
                                            borderBottomColor: "#333",
                                            borderBottomWidth: "2px",
                                            borderBottomStyle: "solid",
                                            fontWeight: "700",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="right"
                                        valign="top">
                                        {formatNumber(parseFloat(detallesPrecio.total))}
                                        {/* ${detallesPrecio.total} */}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Card>
            </Card>

            {UISweetAlert.confirm_alert_delete_lote_internal ? (
                <SweetAlert
                    title="¿Desea eliminar el lote?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarLote()}
                    onCancel={() => {
                        setProductoSeleccEliminar(null);
                        setLoteSeleccEliminar(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_delete_lote_internal: false,
                        });
                    }}>
                    Se perderá la información
                </SweetAlert>
            ) : null}
            {UISweetAlert.confirm_alert_modificar_insumo_compra_externa ? (
                <SweetAlert
                    title="¿Esta seguro que desea marcar este producto como compra externa?"
                    warning
                    showCancel
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => changeCompraExterna(insumoTemporal.e, insumoTemporal.index, true)}
                    onCancel={() => {
                        setInsumoTemporal(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_modificar_insumo_compra_externa: false,
                        });
                    }}></SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_ver_insumo ? (
                <SweetAlert
                    title="Información general del cambio de producto"
                    confirmBtnText="Regresar"
                    confirmBtnBsStyle="success"
                    closeOnClickOutside={false}
                    onConfirm={() => {
                        setInsumoModificar({});
                        setModificarInsumoData({});
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_ver_insumo: false,
                        });
                    }}
                    style={{
                        width: width && width < 851 ? "100%" : "50%",
                    }}>
                    <Row className="form-group mt-5">
                        <label className="col-sm-3 col-form-label">
                            Producto Original
                        </label>
                        <Col sm={7}>
                            <input
                                type="text"
                                autoComplete="off" 
                                disabled
                                className="form-control"
                                value={insumoModificar.catProductosOriginal ? insumoModificar.catProductosOriginal.nombreComercial : ""}
                            />
                        </Col>
                    </Row>

                    <Row className="form-group mt-3">
                        <label className="col-sm-3 col-form-label">
                            Producto Nuevo
                        </label>
                        <Col sm={7}>
                            <input
                                type="text"
                                autoComplete="off" 
                                disabled
                                className="form-control"
                                value={insumoModificar.catProductos ? insumoModificar.catProductos.nombreComercial : ""}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group mt-3">
                        <label className="col-sm-3 col-form-label">Piezas Originales</label>
                        <Col sm={2}>
                            <input
                                autoComplete="off" 
                                type="text"
                                disabled
                                className="form-control"
                                value={insumoModificar.piezasOriginales ? insumoModificar.piezasOriginales : ""}
                            />
                        </Col>
                        <label className="col-sm-3 col-form-label">Piezas Nuevas</label>
                        <Col sm={2}>
                            <input
                                autoComplete="off" 
                                type="text"
                                disabled
                                className="form-control"
                                value={insumoModificar.piezasCorriente ? insumoModificar.piezasCorriente : ""}
                            />
                        </Col>
                    </Row>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_modificar_insumo ? (
                <SweetAlert
                    title="¿Desea modificar el producto?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => submitModificarInsumo()}
                    onCancel={() => {
                        setInsumoModificar({});
                        setModificarInsumoData({});
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_modificar_insumo: false,
                        });
                    }}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}>
                    <Row>
                        <Col>Todas las modificaciones serán notificadas al departamento de Quimioterapia</Col>
                    </Row>
                    <Row className="form-group mt-5">
                        <label className="col-sm-3 col-form-label">
                            Nombre Comercial <br></br>(Actual)
                        </label>
                        <Col sm={7}>
                            <input
                                autoComplete="off" 
                                type="text"
                                disabled
                                className="form-control"
                                value={insumoModificar.catProductos ? insumoModificar.catProductos.nombreComercial : ""}
                            />
                        </Col>
                    </Row>

                    <Row className="form-group mt-3">
                        <label className="col-sm-3 col-form-label">
                            Nombre Comercial <br></br>(Nuevo)
                        </label>
                        <Col sm={7}>
                            <input
                                type="text"
                                autoComplete="off" 
                                onFocus={(e) => filterInsumosModificar()}
                                onChange={(e) => selectInsumoNuevoModificar(e)}
                                list={`listaInsumosPrincipioActivo`}
                                value={modificarInsumoData.descripcion ? modificarInsumoData.descripcion : ""}
                                className="form-control"
                            />
                            <datalist style={{ width: '600px' }} autoComplete="off"  id={`listaInsumosPrincipioActivo`}>
                                {productosServiciosFilter.map((prodServ) => {
                                    return (
                                        <>
                                            {prodServ.idCatProducto != null ? (
                                                <option value={`${prodServ.nombreComercial} -CveInt: ${prodServ.idCatProducto} `}>
                                                    {prodServ.numIdentificacion} - {prodServ.codigoEAN} - {"Lotes:[" + prodServ.lotes + "]"}
                                                    - {prodServ.principioActivo}
                                                </option>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </datalist>
                            <label className="col-sm-3 col-form-label">
                              {modificarInsumoData.lotes}
                            </label>                            
                        </Col>
                    </Row>
                    <Row className="form-group mt-3">
                        <label className="col-sm-3 col-form-label">Piezas (Actual)</label>
                        <Col sm={2}>
                            <input
                                type="text"
                                autoComplete="off" 
                                disabled
                                className="form-control"
                                value={insumoModificar.piezasVale ? insumoModificar.piezasVale : ""}
                            />
                        </Col>
                        <label className="col-sm-3 col-form-label">Piezas (Nuevo)</label>
                        <Col sm={2}>
                            <input
                                type="number"
                                autoComplete="off" 
                                name="piezas"
                                onChange={(e) => piezasModificarInsumo(e)}
                                onWheel={(e) => e.target.blur()}
                                className="form-control"
                                value={modificarInsumoData.piezas ? modificarInsumoData.piezas : ""}
                            />
                        </Col>
                    </Row>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_autorizar_vale ? (
                <SweetAlert
                    title="¿Desea autorizar el vale con los productos modificiados?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => uploadCambioEstatus()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_autorizar_vale: false,
                        })
                    }>
                    Se perderá la información
                </SweetAlert>
            ) : null}            

            {UISweetAlert.confirm_alert_delete_insumo ? (
                <SweetAlert
                    title="¿Desea eliminar el insumo?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarInsumo()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_delete_insumo: false,
                        })
                    }>
                    Se perderá la información
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_EliminarExistente_insumo ? (
                <SweetAlert
                    title="¿Desea eliminar el producto?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarInsumoExistente()}
                    onCancel={() => {
                        setEliminarComentario("");
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_EliminarExistente_insumo: false,
                        })
                    }
                    }>
                    <br></br>
                    {posicionInsumoModificar > 0? insumoModificar.catProductos? insumoModificar.catProductos.nombreComercial : insumoModificar.producto:""}
                    <br></br>
                    <br></br>
                    <div>
                        <Row>
                            <Col>Llene por favor la siguiente información</Col>
                        </Row>

                        <Row className="form-group mt-2">
                            <label className="col-sm-4 col-form-label">Motivo</label>
                            <Col sm={7}>
                            <textarea
                                rows={6}
                                autoComplete="off" 
                                className="form-control"
                                name="comentarioEstatus"
                                value={eliminarComentario}
                                onChange={onChangeComentario}
                            />
                           </Col>
                        </Row>

                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Fecha de Eliminación</label>
                            <Col sm={7}>
                                <input
                                    type="text"
                                    autoComplete="off" 
                                    className="form-control"
                                    disabled={true}
                                    name="fechaCancelacion"
                                    value={fechaActual}
                                />
                            </Col>
                        </Row>
                    </div>                    
                </SweetAlert>
            ) : null}            

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.reload_page) {
                            window.location.reload();
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                            reload_page: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
