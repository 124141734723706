import React from "react";
// import { Buffer } from "buffer";
import "photoswipe/dist/photoswipe.css";

// import clienteAxios from "../../config/axios";

import { Gallery, Item } from "react-photoswipe-gallery";

const Galeria = (props) => {
  return (
    <div className="grid gap-3 grid-flow-row-dense md:grid-cols-3 md:grid-rows-3">
      <Gallery>
        {props.archivosImagen &&
          props.archivosImagen.map((imagen) => {
            return (
              <Item
                id={imagen.idArchivo}
                original={`data:${imagen.mimeType};base64,` + imagen.base64S}
                thumbnail={`data:${imagen.mimeType};base64,` + imagen.base64S}
                width={imagen.width ? imagen.width : "500"}
                height={imagen.height ? imagen.height : "800"}>
                {({ ref, open }) => (
                  <img
                    loading="lazy"
                    ref={ref}
                    alt={imagen.nombreArchivo ? imagen.nombreArchivo : "imagen laboratorio"}
                    onClick={open}
                    src={`data:${imagen.mimeType};base64,` + imagen.base64S}
                  />
                )}
              </Item>
            );
          })}
      </Gallery>
    </div>
  );
};

export default Galeria;
