import React, {useState, useContext, useEffect, useRef, useMemo} from "react";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Row, Col, Card, CardBody, Spinner, Alert} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import ReactDOM from 'react-dom';
import "moment-timezone";
import AgendaFlotantePantallas from './AgendaCitasflotantes/AgendaFlotantePantallas';
import AgendaFlotanteInformeGeneral from './AgendaCitasflotantes/AgendaFlotanteInformeGeneral';
// import { Alert, Space } from "antd";
// import Select from "react-select";

import WindowedSelect from "react-windowed-select";
import SelectPacientesPaginado from "../components/SelectAsyncPaginate";
import useWindowDimensions from "../hooks/dimensionsHook";

// Librería para el calendario
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/daygrid/main.css";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import listPlugin from "@fullcalendar/list";

// import DayjsUtils from "@date-io/dayjs";
// import "dayjs/locale/es";

import DrawerHistoricoCitas from "./DrawerHistoricoCitas";

// Imports de Context necesarios
import CalendarioContext from "../context/Calendario/CalendarioContext";
import AuthContext from "../context/Auth/AuthContext";
import EspaciosAtencionContext from "../context/EspaciosAtencion/EspaciosAtencionContext";
import PacientesContext from "../context/Pacientes/PacientesContext";
import CatTipoCitaContext from "../context/CatTipoCita/CatTipoCitaContext";
import MedicosContext from "../context/Medicos/MedicosContext";
import AseguradorasContext from "../context/Aseguradoras/AseguradorasContext";  
//Utils
import formatDate from "../utils/formatDate";

import {opcionesSelectPacientes} from "../config/configOpcionesSelect";

export default function Calendario(props) {
    // const calendarComponentRef = React.createRef();
    moment.tz.setDefault("America/Mexico_City");
    const calendarComponentRef = useRef(React.createRef());

    const {width} = useWindowDimensions();

    // Se obtienen métodos y variables de los contextos
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    //ACCESO AL ESTADO GLOBAL - Calendario
    const ctxCalendario = useContext(CalendarioContext);
    const {
        citas,
        listarCitas,
        filtroCitasEspacios,
        setFiltroCitasEspacios,
        filtroCitasPacientes,
        setFiltroCitasPacientes,
        filtroCitasAseguradoras,
        setFiltroCitasAseguradoras,
        uploadCita,
        deleteCita,
        exportAgenda,
        exportAgendaPorDia,
        listarCitasPaciente,
        traerCita,
    } = ctxCalendario;

    const ctxMedicos = useContext(MedicosContext);
    const {listarMedicos, medicos} = ctxMedicos;

    //ACCESO AL ESTADO GLOBAL - Espacios Atencion
    const ctxEspAtn = useContext(EspaciosAtencionContext);
    const {espaciosAtencionAgenda, listarEspaciosAtencionAgenda} = ctxEspAtn;

    const pacientesContext = useContext(PacientesContext);
    const {setIdCatPacienteSeleccionado, pacientes, listarPacientesActivos, loadPacientesSelect} = pacientesContext;

    const catTipoCitaContext = useContext(CatTipoCitaContext);
    const {tipoCitas, listarCatTipoCitas} = catTipoCitaContext;

    const ctxAseguradora = useContext(AseguradorasContext);
    const { aseguradoras, listarAseguradoras } = ctxAseguradora;

    // const [errorFetch, setErrorFetch] = useState({ show: false, description: "" });
    const [horarioMinimoCalendario, setHorarioMinimoCalendario] = useState("06:00:00");

    const tienePermisoAseguradoras = usuario && usuario.authorities.includes("ROLE_ASEGURADORAS");

    const esMedico = usuario.idCatMedico != null  && usuario.authorities.includes("ROLE_MEDICO")

    const esMedicoNoAdministrador = (usuario.idCatMedico != null  && !usuario.authorities.includes("ROLE_ADMIN_SISTEMA")) ||
                                     usuario.authorities.includes("ROLE_ASISTENTE");

    const esAsistente =  usuario.authorities.includes("ROLE_ASISTENTE");

    const esFarmacia =  usuario.authorities.includes("ROLE_FARMACIA");

    const banMostrarObjetos = (usuario.authorities.includes("ROLE_AGENDA") && (!usuario.authorities.includes("ROLE_MEDICO")
                               && usuario.authorities.includes("ROLE_AGENDA")) || usuario.authorities.includes("ROLE_ADMIN_SISTEMA") 
                               )

    const banCrearCitas = ( (usuario.authorities.includes("ROLE_AGENDA") && usuario.authorities.includes("ROLE_RECEPCION") ) || 
                            (usuario.authorities.includes("ROLE_MEDICO") && usuario.idCatMedico != null )                    ||
                            ( usuario.authorities.includes("ROLE_ASISTENTE")                          )                      ||
                             usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                          );
  
    const banCrearPaciente = (usuario.authorities.includes("ROLE_RECEPCION") || (usuario.authorities.includes("ROLE_MEDICO") 
                             && usuario.idCatMedico != null )|| usuario.authorities.includes("ROLE_ADMIN_SISTEMA"));                           

    const esMedicoConRoleAgenda = usuario.authorities.includes("ROLE_AGENDA") 
                                   && usuario.authorities.includes("ROLE_MEDICO");

    const banOcultarCalendario = (usuario.authorities.includes("ROLE_MEDICO") && (usuario.idCatMedico == null )
                                 && !usuario.authorities.includes("ROLE_ADMIN_SISTEMA") 
                                 );


    const banImprimiragenda = ( ( usuario.authorities.includes("ROLE_AGENDA") && usuario.authorities.includes("ROLE_RECEPCION") ) || 
                                ( usuario.authorities.includes("ROLE_AGENDA")                          )                          ||
                                ( usuario.authorities.includes("ROLE_ASISTENTE")                          )                       ||
                                ( usuario.authorities.includes("ROLE_MEDICO") && usuario.idCatMedico != null                    ) ||
                                  usuario.authorities.includes("ROLE_ADMIN_SISTEMA") 
                              );                               

    const sweetAlertDefault = {
        confirm_alert_eliminar_cita: false,
        confirm_alert_cita: false,
        confirm_alert_cita_no_autorizado: false,
        confirm_imprimir_agenda: false,
        confirm_historico_paciente: false,
        confirm_exponer_agenda : false,
        confirm_exponer_agendaGeneral : false,
        editar: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    };

    const [UISweetAlert, setUISweetAlert] = useState(sweetAlertDefault);

    const [loading, setLoading] = useState(false);
    const [comentarioPaciente, setComentarioPaciente] = useState(false);

    // Variable State para guardar el paciente de la cita seleccionada
    const [pacienteDeCitaSeleccionada, setPacienteDeCitaSeleccionada] = useState(null);
    //const [nombrePaciente, setNombrePaciente] = useState(null);
    //const [tieneAseguradora, setTieneAseguradora] = useState(false);

    const [citasPaciente, setCitasPaciente] = useState([]);
    const [infoCalGlobal, setInfoCalGlobal] = useState([]);

    const [llamadasOnchange, setLlamadasOnchange] = useState(0);
   
    const [comentarioAseguradoraOriginal, setComentarioAseguradoraOriginal] = useState("");
    const [comentarioFarmaciaOriginal, setComentarioFarmaciaOriginal] = useState("");
    const [comentarioTratamientoOriginal, setComentarioTratamientoOriginal] = useState("");
    const [comentarioRecepcionOriginal, setComentarioRecepcionOriginal] = useState("");    
    const [paciente,setPaciente] = useState("");    
    const [tipoCitaId,setTipoCitaId] = useState(0);   
    const [espacioOriginal,setEspacioOriginal] = useState(0);    
    const [consultorio,setConsultorio] = useState("");    
    const [fechaInicio,setFechaInicio] = useState(""); 
    const [fechafinal,setFechaFinal] = useState(""); 
    const [pacienteIdOriginal,setPacienteIdOriginal] = useState(0); 
    const [existenFiltros,setExistenFiltros] = useState(false); 
    // eslint-disable-next-line
    // const [espaciosAtencionIniciales, setEspaciosAtencionIniciales] = useState(seleccionarEspaciosAtencionDefault());

    let today = new Date();
 
    let delayTime = 2000;

    const [fechaActual, setFechaActual] = useState(today);
    const [disabledEditar, setDisabledEditar] = useState(false);

    const [opcionesExtras, setOpcionesExtras] = useState({
        seleccionarTodosEspacios: (esAsistente?false:!esMedicoNoAdministrador),
        seleccionarTodosPacientes: (esAsistente?false:!esMedicoNoAdministrador),
        porAseguradora: false,
    });
  
    const [filtrosImpresion, setFiltrosImpresion] = useState({
        fechaImpresion: "",
        porDia: false,
        incluirTratamientos: true,
        incluirCitas: true,
    });

    // const [listarPacientesDone, setListarPacientesDone] = useState(false);
    // eslint-disable-next-line no-unused-vars

    const [espaciosImpresion, setEspaciosImpresion] = useState();
    const [datosPaciente, setDatosPaciente] = useState({});
    const [citaActual, setCitaActual] = useState({});

    const [pacienteSeleccionadoHistorico, setPacienteSeleccionadoHistorico] = useState(null);

    const [opcionesEspacios, setOpcionesEspacios] = useState({}); 

    const [opcionesPacientes, setOpcionesPacientes] = useState([]);
    const [opcionesAseguradoras, setOpcionesAseguradoras] = useState([]);
    const [nombrePacienteGlobal, setNombrePacienteGlobal] = useState(null);

    const[confirmaSobreEscribirCita,setConfirmaSobreEscribirCita] = useState(false)
    const[mensajeSobreEscribirCita,setMensajeSobreEscribirCita] = useState("")
    const[ignorarBack,setIgnorarBack] = useState(false)

    // 5 minutos * 60000 = 300000 
    // 30 minutos * 60000 = 1800000
    // eslint-disable-next-line
    const [tiempoCita, setTiempoCita] = useState(1800000);

    const newStartDate = new Date(); 
    const startDate = formatDate(newStartDate, true);
    const newEndDate = new Date(new Date().getTime() + tiempoCita);
    const endDate = formatDate(newEndDate, true);

    const [citaSeleccionada, setCitaSeleccionada] = useState({
        start: startDate,
        end: endDate,
        todoDia: false, 
    });
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const [mostrarFinDeSemana, setMostrarFinDeSemana] = useState(false);

    const [isFloatingWindowOpen, setIsFloatingWindowOpen] = useState(false);
    const [data, setData] = useState('Aquí van los datos que deseas enviar');
  
    const openFloatingWindow = () => {
      setIsFloatingWindowOpen(true);
    };
  
    const closeFloatingWindow = () => {
      setIsFloatingWindowOpen(false);
    };
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const delay = async (ms) => {
        return new Promise((resolve) => 
            setTimeout(resolve, ms));
    }; 

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const espaciosFiltrados = espaciosAtencionAgenda.filter((espacio) => {
        if (existenFiltros && filtroCitasEspacios){
            let encontrado = false;
            filtroCitasEspacios.map((filtro) =>{
                if(filtro.idEspacio == espacio.idEspacio){
                    encontrado = true;
                }
            })
            if(encontrado){
                return espacio;
            }
        }else{
            if(esAsistente){
                if (espacio.idAsistente == usuario.idCatUsuario){
                    return espacio;
                }
            }else{
                return espacio.idCatMedico == usuario.idCatMedico;
            }
        }       
    });
   
    const [espaciosAtencionDefault, setEspaciosAtencionDefault] = useState(espaciosFiltrados);


        const openNewWindowGeneral = (datos) => {
            // Abre una nueva pestaña
            const newWindow = window.open('', '_blank'); // Abre una nueva pestaña
          
            // Contenido HTML básico para la nueva pestaña
            const content = `
              <html>
              <head>
                  <title>Componente en nueva pestaña</title>
                  <style>
                  body {
                      font-family: Arial, sans-serif;
                      background-color: #D3D3D3;
                      margin: 0;
                      padding: 20px;
                  }
                  h1 {
                      color: #333;
                  }
                  </style>
              </head>
              <body>
                  <div id="root"></div>
              </body>
              </html>
            `;
          
            // Escribir el contenido HTML en la nueva pestaña
            newWindow.document.write(content);
            newWindow.document.close();
          
            // Aquí puedes establecer los datos que necesites para tu componente
            datos.porDia = true;
            datos.incluirCitas = true;
            datos.incluirTratamientos = true;
          
            // Usar ReactDOM para renderizar el componente React en la nueva pestaña
            ReactDOM.render(
              <AgendaFlotanteInformeGeneral data={datos} />,
              newWindow.document.getElementById('root')
            );
          };

    const openNewWindow = (datos) => {
      // Abre una nueva ventana
        const newWindow = window.open('', 'Ventana2', 'width=1600,height=900,location=no,menubar=no');
        // Contenido HTML básico
        //background-color: #8A2BE2; /* Color de fondo */
        const content = `
            <html>
            <head>
                <title>Ventana con fondo de color</title>
                <style>
                body {
                    font-family: Arial, sans-serif;
                    background-color: #D3D3D3;
                    margin: 0;
                    padding: 20px;
                }
                h1 {
                    color: #333;
                }
                </style>
            </head>
            </html>
        `;
        newWindow.document.write(content);
        // Rendirizar el informe en la nueva ventana
        newWindow.document.body.innerHTML = `<div  id="Pantallas"></div>`;
        // Usamos ReactDOM para renderizar el componente React en la nueva ventana
        // Necesitamos acceder al contenedor de la nueva ventana
        import('react-dom').then(({ render }) => {
            datos.porDia = true;
            datos.incluirCitas = true;
            datos.incluirTratamientos = true;
            // Establecer el tamaño de la ventana emergente (por ejemplo, 2500x1500)
            const windowWidth = 1600;
            const windowHeight = 900;
            //render(<Report width = {2500} data={datos} />, newWindow.document.getElementById('report'));
            render(<AgendaFlotantePantallas width={windowWidth} height={windowHeight} data={datos} />, newWindow.document.getElementById('Pantallas'));
        });
      
    };

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    useEffect(() => {
        const fetchCatTipoCitas = async () => {
            const tipoCatCitas = await listarCatTipoCitas();
            return tipoCatCitas;
        };

        const fetchPacientesActivos = async () => {
            // setUISweetAlert({
            //   ...UISweetAlert,
            //   dynamic_description: "Cargando pacientes activos",
            // });
            // setLoading(true);
            try {
                const pacientesA = await listarPacientesActivos();
                // setLoading(false);
                // setErrorFetch({ show: true, description: "Se cargaron los pacientes correctamente" });
                return pacientesA;
            } catch (error) {
                // setUISweetAlert({
                //   ...UISweetAlert,
                //   error_dlg: true,
                //   dynamic_description: "Error al cargar los pacientes del catálogo",
                // });
                // setLoading(false);
            }
        };

        const fetchMedicos = async () => {
            const medicosActivos = await listarMedicos();
            return medicosActivos;
        };
        listarAseguradoras();
        fetchCatTipoCitas();
        fetchPacientesActivos();
        fetchMedicos();
    }, []);

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const nuevaCitaFechaSeleccionada = async (arg) => {
        // 2018-06-14T00:00
        const newStartDate = formatDate(arg.date, false);
        const newEndDate = formatDate(new Date(arg.date.getTime() + tiempoCita), false);
        //setNombrePaciente(null);
        setComentarioPaciente("")

        setPacienteDeCitaSeleccionada(null);
        if (usuario && esMedicoNoAdministrador) {
            if (espaciosAtencionDefault && espaciosAtencionDefault.length > 0) {
                LimpiarDatos();
                setCitaSeleccionada({
                    ...citaSeleccionada,
                    start: newStartDate,
                    // start: arg.date,
                    end: newEndDate,
                    espacio: espaciosAtencionDefault[0].idEspacio,
                    tipoEspacio: 1,
                    paciente: null,
                    comentarioRecepcion:null,
                    comentarioTratamiento:null,
                    comentarioAseguradora:null,
                    comentarioFarmacia:null,
                    nombrePaciente:null,
                });
            }
        } else {
            LimpiarDatos();
            setCitaSeleccionada({
                start: newStartDate,
                // start: arg.date,
                end: newEndDate,
            });
        }

        setDisabledEditar(false);
        setUISweetAlert({
            ...sweetAlertDefault,
            confirm_alert_cita: true,
        });
    };

     /* **********************************************************************************************************     */
    /*                            ACA ES EL PROCESO CUANDO SE DA UN CLICK EN EL CALENDARIO                             */
    /* *************************************************************************************************************** */
    const editCitaConfirm = async (env) => {
        const startDateCita = formatDate(env.event.start, false, citaSeleccionada.todoDia);
        const endDateCita = formatDate(env.event.end, false, citaSeleccionada.todoDia);
        const date = new Date();
        let nombreTemp;

        //setNombrePaciente(null);
        
        if (env.event.start >= date) {
            setDisabledEditar(true);
        } else {
            setDisabledEditar(false);
        }

        //let encontrado = false;
        //let contador=1;

        //alert("Id del paciente seleccionado: " + env.event.extendedProps.paciente);
        
        //const pacienteFiltrado = pacientes.filter((paciente) => {
        //    contador = contador + 1
        //    if (paciente.idCatPaciente === env.event.extendedProps.paciente) {
        //        encontrado = true;
        //        return paciente;
        //    }
        //});
        //alert(contador);

        //if (encontrado){
        //    nombreTemp =  pacienteFiltrado[0].nombres + " " + pacienteFiltrado[0].apellidoPaterno + " " + pacienteFiltrado[0].apellidoMaterno 
        //    alert(nombreTemp);
        //    setNombrePaciente(nombreTemp);
        //}

        setLoading(true);

        filterPacienteCitaSeleccionada(env.event.extendedProps.paciente);
        setCitaSeleccionada({
            id: env.event.id,
            title: env.event.title,
            start: startDateCita,
            end: endDateCita,
            todoDia: env.event.extendedProps.todoDia,
            espacio: env.event.extendedProps.espacio,
            tipoEspacio: env.event.extendedProps.tipoEspacio,
            paciente: env.event.extendedProps.paciente,
            comentarioRecepcion: env.event.extendedProps.comentarioRecepcion,
            comentarioTratamiento: env.event.extendedProps.comentarioTratamiento,
            comentarioFarmacia: env.event.extendedProps.comentarioFarmacia,
            comentarioAseguradora: env.event.extendedProps.comentarioAseguradora,
            nombrePaciente: env.event.extendedProps.nombrePaciente,
            consultorio: env.event.extendedProps.consultorio,
            className: env.event.classNames[0],
            idCatTipoCita: env.event.extendedProps.idCatTipoCita,
            description: env.event.extendedProps.description,
            idCatMedico: env.event.extendedProps.idCatMedico,
            tieneAseguradora: env.event.extendedProps.tieneAseguradora,
        }); 
        let comentariosPacienteLocal = TraeComentariosPaciente(env.event.extendedProps.paciente)
        if(comentariosPacienteLocal ==null){
            comentariosPacienteLocal = "Sin comentarios"
        }
        setComentarioPaciente(comentariosPacienteLocal)

        setComentarioAseguradoraOriginal(env.event.extendedProps.comentarioAseguradora)
        setComentarioFarmaciaOriginal(env.event.extendedProps.comentarioFarmacia)
        setComentarioTratamientoOriginal(env.event.extendedProps.comentarioTratamiento)
        setComentarioRecepcionOriginal(env.event.extendedProps.comentarioRecepcion)
        setPaciente(env.event.extendedProps.nombrePaciente)
        setTipoCitaId(env.event.extendedProps.idCatTipoCita)
        setEspacioOriginal(env.event.extendedProps.espacio)
        setConsultorio(env.event.extendedProps.consultorio)
        setFechaInicio(startDateCita)
        setFechaFinal(endDateCita)
        setPacienteIdOriginal(env.event.extendedProps.paciente)
        //await delay(1000);
        setLoading(false); 
        
        setUISweetAlert({
            ...sweetAlertDefault,
            editar: true,
            confirm_alert_cita: true,
        });
    };

    const onChangeNuevaCita = (e) => {
        if (e.target.name == "espacio") 
        {
            const espacio = espaciosAtencionAgenda.filter((esp) => esp.idEspacio == e.target.value)[0];
            setCitaSeleccionada({
                ...citaSeleccionada,
                espacio: e.target.value,
                tipoEspacio: espacio.idCatTipoEspacio,
                cliente: espacio.idCatTipoEspacio == 1 ? citaSeleccionada.cliente : "",
                title: "",
            });
            return;
        }

        if (e.target.name == "idCatMedico") {
            setCitaSeleccionada({
                ...citaSeleccionada,
                idCatMedico: e.target.value,
            });
            return;
        }

        if (e.target.name == "idCatTipoCita") {
            setCitaSeleccionada({
                ...citaSeleccionada,
                idCatTipoCita: e.target.value,
            });
            return;
        }

        if (e.target.name === "todoDia") {
            let value = !citaSeleccionada.todoDia;
            let startDate = formatDate(citaSeleccionada.start, false, value);
            let endDate = value
                ? null
                : formatDate(new Date(startDate).setTime(new Date(startDate).getTime() + tiempoCita), false, false);

            setCitaSeleccionada({
                ...citaSeleccionada,
                todoDia: value,
                start: startDate,
                end: endDate,
            });
            return;
        }

        setCitaSeleccionada({
            ...citaSeleccionada,
            [e.target.name]: e.target.value,
        });
        return;
    };

    const onChangeDatosPaciente = async (e) => {
        const {name, value} = e.target;

        if(name=='nombrePaciente'){
            setNombrePacienteGlobal(value)
        }
        
        setDatosPaciente({
            ...datosPaciente,
            [name]: value,
        });
    };

    const changeFechaInicalNuevaCita = async (date) => {
        setCitaSeleccionada({
            ...citaSeleccionada,
            start: date.target.value,
        });
    };

    const changeFechaFinalNuevaCita = async (date) => {
        setCitaSeleccionada({
            ...citaSeleccionada,
            end: date.target.value,
        });
    };

    const confirmEliminarCita = async () => {
        setUISweetAlert({
            ...sweetAlertDefault,
            editar: true,
            confirm_alert_cita: true,
            confirm_alert_eliminar_cita: true,
        });
    };

    const borrarCita = async () => {
        try {
            let CadenaString = "";

            CadenaString = CadenaString + ",P[" +citaSeleccionada.nombrePaciente + "]"
            CadenaString = CadenaString + ",FI[" +citaSeleccionada.start + "]"
            CadenaString = CadenaString + ",FF[" +citaSeleccionada.end + "]"

            let originalCadenas;
            let nuevoCadena;
            tipoCitas.filter((TipoCita) => 
            {
                    if (citaSeleccionada.idCatTipoCita == TipoCita.idCatTipoCita){
                        nuevoCadena = TipoCita.tipoCita;
                    }
            })  
            CadenaString = CadenaString+ ",TC[" + nuevoCadena + "]"                

            citaSeleccionada.logVo ={
                idUsuario: usuario.idUsuario,
                modulo: "Agenda",
                actividad:"Eliminar:ID[" + citaSeleccionada.id + "]" + CadenaString
            }

            const res = await deleteCita(citaSeleccionada);
            if (res.data.error) {
                setLoading(false);
                LimpiarDatos();
                setUISweetAlert({
                    ...sweetAlertDefault,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Error en el sistema",
                });
                LimpiarDatos();
                return;
            }
            const newStartDate = new Date();
            const startDate = formatDate(newStartDate, true);
            const newEndDate = new Date(new Date().getTime() + tiempoCita);
            const endDate = formatDate(newEndDate, true);
            setCitaSeleccionada({
                start: startDate,
                end: endDate,
            });

            setDisabledEditar(false);
            LimpiarDatos();
            setUISweetAlert({
                ...sweetAlertDefault,
                success_dlg: true,
                dynamic_title: "Exito",
                dynamic_description: "Se eliminó la cita",
            });
        } catch (error) {
            setLoading(false);
            setUISweetAlert({
                ...sweetAlertDefault,
                confirm_alert_cita: true,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Ocurrió un error, por favor intentalo más tarde.",
            });
        }
        setLoading(false);
        LimpiarDatos();
    };

    /* ****************************** */

    function TraePaciente(idPar){
        let retorno;
        pacientes.filter((pacientefilter) => 
        {
                if (pacientefilter.idCatPaciente == idPar){
                    retorno = pacientefilter.nombres + " " + pacientefilter.apellidoPaterno + " " + pacientefilter.apellidoMaterno;
                }
        }) 
        return retorno 
    }

    function TraeComentariosPaciente(idPar){
        let retorno;
        pacientes.filter((pacientefilter) => 
        {
                if (pacientefilter.idCatPaciente == idPar){
                    retorno = pacientefilter.comentario;
                }
        }) 
        return retorno 
    }

    function CitaModificada(citaBackPar){
        let cadenaGeneral="";
        let cadenaRetorno="";

        if(citaBackPar.extendedProps.comentarioRecepcion != comentarioRecepcionOriginal){
            cadenaGeneral = cadenaGeneral+ "CR[" + citaBackPar.extendedProps.comentarioRecepcion + "]"
        }

        if(citaBackPar.extendedProps.comentarioAseguradora != comentarioAseguradoraOriginal){
           cadenaGeneral = cadenaGeneral+ ",CA[" + citaBackPar.extendedProps.comentarioAseguradora + "]"
        }

        if(citaBackPar.extendedProps.comentarioFarmacia != comentarioFarmaciaOriginal){
           cadenaGeneral = cadenaGeneral+ ",CF[" + citaBackPar.extendedProps.comentarioFarmacia  + "]"
        }

        if(citaBackPar.extendedProps.comentarioTratamiento != comentarioTratamientoOriginal){
            cadenaGeneral = cadenaGeneral+ ",CT[" + citaBackPar.extendedProps.comentarioTratamiento + "]"
        }

        //SE VERIFICA CAMBIO DE PACIENTE
        let pacienteTemporal = citaBackPar.extendedProps.nombrePaciente;
        if(pacienteTemporal != paciente){
            cadenaGeneral = cadenaGeneral+ ",P[" + pacienteTemporal + "]"
        }   

        var dateBack = new Date(citaBackPar.start);
        var dateOriginal = new Date(fechaInicio);
        let newdateBack = dateBack.toLocaleString();
        let newdateOriginal = dateOriginal.toLocaleString();
 
         if(newdateBack != newdateOriginal){
            cadenaGeneral = cadenaGeneral+ ",FI[" + newdateBack + "]"
        }

        var dateBackEnd = new Date(citaBackPar.end);
        var dateOriginalEnd = new Date(fechafinal);
        let newdateBackEnd = dateBackEnd.toLocaleString();
        let newdateOriginalEnd = dateOriginalEnd.toLocaleString();
        //SE VERIFICA CAMBIO DE FECHA FINAL
        if(newdateBackEnd != newdateOriginalEnd){
            cadenaGeneral = cadenaGeneral+ ",FF[" + newdateBackEnd + "]"
        }     

        //SE VERIFICA CAMBIO DEL DOCTOR POR MEDIO DEL ID DEL ESPACIO QUE CONTIENE EL NOMBRE DEL DOCTOR
        if(citaBackPar.extendedProps.espacio != espacioOriginal){
            let nuevoCadena;
            espaciosAtencionAgenda.filter((espacio) => 
            {
                if (citaBackPar.espacio.idEspacio == espacio.idEspacio){
                    nuevoCadena = espacio.espacio;
                } 
            })  
            cadenaGeneral = cadenaGeneral+ ",E[" + nuevoCadena + "]"
        }

        if(citaBackPar.extendedProps.idCatTipoCita != tipoCitaId){
            let nuevoCadena;
            tipoCitas.filter((TipoCita) => 
            {
                if (citaBackPar.tipoCita.idCatTipoCita == TipoCita.idCatTipoCita){
                    nuevoCadena = TipoCita.tipoCita;
                }
            })  
            cadenaGeneral = cadenaGeneral+ ",TC[" + nuevoCadena + "]"
        }
 
        if (cadenaGeneral.length > 1) {
            cadenaRetorno = "La cita que esta editando ha sido modificada por el usuario " + citaBackPar.extendedProps.usuarioSolicitante  +", confirme si desea salvar sus datos y sobrescribir lo salvado por el usuario antes mencionado, de lo contrario cancele. Información del cambio detectado: "  + cadenaGeneral;
        }
        
        return cadenaRetorno;
    }

     function LimpiarDatos (){
        setComentarioAseguradoraOriginal("") 
        setComentarioFarmaciaOriginal("")
        setComentarioTratamientoOriginal("")
        setComentarioRecepcionOriginal("")
        setPaciente("")
        setTipoCitaId(1)  
        setEspacioOriginal(0)
        setConsultorio("")
        setFechaInicio("")
        setFechaFinal("")
        setNombrePacienteGlobal(null)
        setIgnorarBack(false);
        setConfirmaSobreEscribirCita(false);
        setPacienteIdOriginal(0);

        setCitaSeleccionada({
            id: 0,
            title: "",
            start: "",
            end: "",
            todoDia: false,
            espacio: 0,
            tipoEspacio: 1,
            paciente: null,
            comentarioRecepcion: "",
            idCatTipoCita:null,
            comentarioTratamiento: "",
            comentarioFarmacia: "",
            comentarioAseguradora: "",
            nombrePaciente: "",
            consultorio: "",
            idCatTipoCita: 0,
            description: "",
            idCatMedico: 0,
            tieneAseguradora: false,
        });

        setCitaSeleccionada({
            ...citaSeleccionada,
            start: newStartDate,
            // start: arg.date,
            end: newEndDate,
            espacio: espaciosAtencionDefault[0].idEspacio,
            tipoEspacio: 1,
            paciente: null,
            comentarioRecepcion:null,
            idCatTipoCita:null,
            comentarioTratamiento:null,
            comentarioAseguradora:null,
            comentarioFarmacia:null,
            nombrePaciente:null,
        });
    }
 
    const checkUploadCita = async () => { 
        let errorCita = false;

        if (!errorCita && !citaSeleccionada.title && citaSeleccionada.tipoEspacio == 2) {
            setUISweetAlert({
                ...sweetAlertDefault,
                confirm_alert_cita: true,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca un título para la cita",
            });
            errorCita = true;
            return;
        }

        if (!errorCita && !citaSeleccionada.start) {
            setUISweetAlert({
                ...sweetAlertDefault,
                confirm_alert_cita: true,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca una fecha de inicio",
            });
            errorCita = true;
            return;
        }

        const date = new Date();
        const dateWithFormat = formatDate(date, true);

        if (!errorCita && !citaSeleccionada.todoDia && 
            citaSeleccionada.end < citaSeleccionada.start) {
            setUISweetAlert({
                ...sweetAlertDefault,
                confirm_alert_cita: true,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "La fecha final no puede ser menor que la fecha inicial",
            });
            errorCita = true;
            return;
        }

        if (!errorCita && !citaSeleccionada.espacio) {
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el espacio asignado para la cita",
            });
            errorCita = true;
            return;
        }

        if (!errorCita && !citaSeleccionada.idCatTipoCita) {
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el tipo de cita",
            });
            errorCita = true;
            return;
        }

        if (!errorCita && citaSeleccionada.tipoEspacio == 1 &&
            !citaSeleccionada.paciente &&
            citaSeleccionada.idCatTipoCita != 11) {
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el paciente para la cita",
            });
            errorCita = true;
            return;
        }

        if (!errorCita &&
            citaSeleccionada.tipoEspacio == 1 &&
            citaSeleccionada.idCatTipoCita == 11 &&
            !citaSeleccionada.paciente &&
            !datosPaciente.nombrePaciente
        ) {
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el nombre del paciente para la cita de primera vez",
            });
            errorCita = true;
            return;
        }

        if (!errorCita &&
            citaSeleccionada.tipoEspacio == 1 &&
            citaSeleccionada.idCatTipoCita == 11 &&
            !citaSeleccionada.paciente &&
            !datosPaciente.telefonoPaciente
        ) {
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el teléfono del paciente para la cita de primera vez",
            });
            errorCita = true;
            return;
        }

        if (!errorCita && !citaSeleccionada.comentarioRecepcion) {
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca los comentarios para la cita",
            });
            errorCita = true;
            return;
        }

        let comentario = null;

        if (citaSeleccionada.idCatTipoCita == 11 &&
            citaSeleccionada.tipoEspacio == 1 &&
            (citaSeleccionada.paciente == null || citaSeleccionada.paciente == "") &&
            !UISweetAlert.editar
        ) {
            comentario =
                citaSeleccionada.comentarioRecepcion +
                "\n" +
                "Nombre del Paciente: " +
                datosPaciente.nombrePaciente +
                "\n" +
                "Telefono del paciente: " +
                datosPaciente.telefonoPaciente;
        }

        let titulo = null;

        if (citaSeleccionada.tipoEspacio == 1) {
            const tipoCitaSeleccionado = tipoCitas.filter((tipoCita) => tipoCita.idCatTipoCita == citaSeleccionada.idCatTipoCita);
            titulo = tipoCitaSeleccionado[0].tipoCita;
        }

        if (citaSeleccionada.tipoEspacio == 3) {
            titulo = tituloFormatear();
        }
        // Si es espacio médico, enviar id del paciente y del médico para crear nuevo expediente
        let requestExpedienteMedicoPaciente;

        if (citaSeleccionada.tipoEspacio == 1) {
            const medico =
                espaciosAtencionAgenda && espaciosAtencionAgenda.filter((espacio) => espacio.idEspacio == citaSeleccionada.espacio);

            // eslint-disable-next-line
            requestExpedienteMedicoPaciente = {
                idCatPaciente: citaSeleccionada.paciente,
                idCatMedico: medico[0].idCatMedico,
            };
        }
        // const tituloConcatenado = nombrePaciente + " - " + nombreMedico + " - " + citaSeleccionada.title;
        const tituloModificado =
            (citaSeleccionada.tipoEspacio == 1 || citaSeleccionada.tipoEspacio == 3) && titulo ? titulo : citaSeleccionada.title;
        setLoading(true);

        /* ************************************************************************************************************************ */ 
        /* ***************************         VERIFICAMOS SI HUBO CAMBIOS PARA EL LOG           ********************************** */ 
        let banderaEncontrado = false;
        let cadenaGeneral;
        let banderaNuevo =  false;

        if(!comentarioRecepcionOriginal && !citaSeleccionada.nombrePaciente){
            banderaNuevo = true
        }

        if(!citaSeleccionada.id){
            banderaNuevo = true
        }

        if(!banderaNuevo)
        {
            cadenaGeneral = "Actualización:ID[" + citaSeleccionada.id + "]"

            if(citaSeleccionada.comentarioRecepcion != comentarioRecepcionOriginal){
                cadenaGeneral = cadenaGeneral+ ",CR[" + comentarioRecepcionOriginal + "->" + citaSeleccionada.comentarioRecepcion + "]"
                banderaEncontrado = true;
            }

            if(citaSeleccionada.comentarioAseguradora != comentarioAseguradoraOriginal){
                if(comentarioAseguradoraOriginal){
                    cadenaGeneral = cadenaGeneral+ ",CA[" + comentarioAseguradoraOriginal + "->" + citaSeleccionada.comentarioAseguradora + "]"
                } else{
                    cadenaGeneral = cadenaGeneral+ ",CA[->" + citaSeleccionada.comentarioAseguradora + "]"
                }
                
                banderaEncontrado = true; 
            }

            if(citaSeleccionada.comentarioFarmacia != comentarioFarmaciaOriginal){
                if(comentarioFarmaciaOriginal){
                    cadenaGeneral = cadenaGeneral+ ",CF[" + comentarioFarmaciaOriginal + "->" + citaSeleccionada.comentarioFarmacia + "]"
                }else{
                    cadenaGeneral = cadenaGeneral+ ",CF[->" + citaSeleccionada.comentarioFarmacia + "]"
                }
                banderaEncontrado = true;
            }

            if(citaSeleccionada.comentarioTratamiento != comentarioTratamientoOriginal){
                if(citaSeleccionada.comentarioTratamiento==undefined){
                    citaSeleccionada.comentarioTratamiento = ""
                }

                if(comentarioTratamientoOriginal){
                    cadenaGeneral = cadenaGeneral+ ",CT[" + comentarioTratamientoOriginal + "->" + citaSeleccionada.comentarioTratamiento + "]"
                }else{
                    cadenaGeneral = cadenaGeneral+ ",CT[->" + citaSeleccionada.comentarioTratamiento + "]"
                }
                banderaEncontrado = true;
            }

            //SE VERIFICA CAMBIO DE PACIENTE
            if(citaSeleccionada.nombrePaciente != paciente){
                cadenaGeneral = cadenaGeneral+ ",P[" + paciente + "->" + citaSeleccionada.nombrePaciente + "]"
                banderaEncontrado = true;
            }   

            if(citaSeleccionada.paciente != pacienteIdOriginal){
                cadenaGeneral = cadenaGeneral+ ",P[" + fechafinal + "->" + citaSeleccionada.end + "]"
                banderaEncontrado = true;
            } 

                    //SE VERIFICA CAMBIO DEL DOCTOR POR MEDIO DEL ID DEL ESPACIO QUE CONTIENE EL NOMBRE DEL DOCTOR
            if(citaSeleccionada.paciente != pacienteIdOriginal){
                let originalCadenas;
                let nuevoCadena;
                pacientes.filter((pacienteTemp) => 
                {
                        if (citaSeleccionada.paciente == pacienteTemp.idCatPaciente){
                            nuevoCadena = pacienteTemp.nombres + " "  + pacienteTemp.apellidoPaterno +  " "  + pacienteTemp.apellidoMaterno;
                        }
                        if (pacienteIdOriginal == pacienteTemp.idCatPaciente){
                            originalCadenas= pacienteTemp.nombres + " "  + pacienteTemp.apellidoPaterno +  " "  + pacienteTemp.apellidoMaterno;
                        } 
                })
                if(originalCadenas ==undefined){
                    originalCadenas = "SIN ASIGNAR" 
                }

                if(!banderaNuevo){
                    cadenaGeneral = cadenaGeneral+ ",P[" + originalCadenas + "->" + nuevoCadena + "]"
                }else{
                    cadenaGeneral = cadenaGeneral+ ",E[" + nuevoCadena + "]"
                }
                banderaEncontrado = true;
            }

            //SE VERIFICA CAMBIO DE CONSULTORIO
            if(citaSeleccionada.consultorio != consultorio){
                /*if (consultorio == undefined || consultorio ==null){
                    consultorio = ""
                }*/
                cadenaGeneral = cadenaGeneral+ ",C[" + consultorio + "->" + citaSeleccionada.consultorio  + "]"
                banderaEncontrado = true;
            }          

            //SE VERIFICA CAMBIO DE FECHA INICIAL
            if(citaSeleccionada.start != fechaInicio){
                cadenaGeneral = cadenaGeneral+ ",FI[" + fechaInicio + "->" + citaSeleccionada.start + "]"
                banderaEncontrado = true;
            }
            //SE VERIFICA CAMBIO DE FECHA FINAL
            if(citaSeleccionada.end != fechafinal){
                cadenaGeneral = cadenaGeneral+ ",FF[" + fechafinal + "->" + citaSeleccionada.end + "]"
                banderaEncontrado = true;
            }    


        }else{

            if(citaSeleccionada.end){
                cadenaGeneral = "Nuevo:ID[@@],FI[" + citaSeleccionada.start  + "],FF[" + citaSeleccionada.end+"]"
            }else{
                cadenaGeneral = "Nuevo:ID[@@],TD[" + citaSeleccionada.start  + "]"
            } 

            if(!nombrePacienteGlobal){
                let nombrePacienteLocal;
                nombrePacienteLocal = TraePaciente(citaSeleccionada.paciente)
                cadenaGeneral = cadenaGeneral + ",P[" + nombrePacienteLocal + "],CR[" + citaSeleccionada.comentarioRecepcion + "]" 
            }else{
                cadenaGeneral = cadenaGeneral + ",P[" + nombrePacienteGlobal + "],CR[" + citaSeleccionada.comentarioRecepcion + "]" 
            }
        }
        //SE VERIFICA CAMBIO DEL DOCTOR POR MEDIO DEL ID DEL ESPACIO QUE CONTIENE EL NOMBRE DEL DOCTOR
        if(citaSeleccionada.espacio != espacioOriginal){
            let originalCadenas;
            let nuevoCadena;
            espaciosAtencionAgenda.filter((espacio) => 
            {
                    if (citaSeleccionada.espacio == espacio.idEspacio){
                        nuevoCadena = espacio.espacio;
                    }
                    if (espacioOriginal == espacio.idEspacio){
                        originalCadenas= espacio.espacio;
                    } 

            }) 
            if(!banderaNuevo){
                cadenaGeneral = cadenaGeneral+ ",E[" + originalCadenas + "->" + nuevoCadena + "]"
            }else{
                cadenaGeneral = cadenaGeneral+ ",E[" + nuevoCadena + "]"
            }
            banderaEncontrado = true;
        }

        if(citaSeleccionada.idCatTipoCita != tipoCitaId){
            let originalCadenas;
            let nuevoCadena;
            tipoCitas.filter((TipoCita) => 
            {
                    if (citaSeleccionada.idCatTipoCita == TipoCita.idCatTipoCita){
                        nuevoCadena = TipoCita.tipoCita;
                    }
                    if (tipoCitaId == TipoCita.idCatTipoCita){
                        originalCadenas= TipoCita.tipoCita;
                    } 

            })  
            if(!banderaNuevo){
                cadenaGeneral = cadenaGeneral+ ",TC[" + originalCadenas + "->" + nuevoCadena + "]"                
            }else{
                cadenaGeneral = cadenaGeneral+ ",TC["  + nuevoCadena + "]"
            }
            banderaEncontrado = true;
        }

        if(!banderaEncontrado && !banderaNuevo){
            cadenaGeneral = "";
            setLoading(false);
            LimpiarDatos();
            setUISweetAlert({
                ...sweetAlertDefault,
                success_dlg: true,
                dynamic_title: "Exito", 
                dynamic_description: "Se guardaron los cambios de la cita",
            });
            return;
        }  

        if(!banderaNuevo && !ignorarBack && !confirmaSobreEscribirCita)
        {
            let citaBack = await  traerCita(citaSeleccionada.id);
            let cadenaCambioDetectado = CitaModificada(citaBack);
            setMensajeSobreEscribirCita(cadenaCambioDetectado);
            if(cadenaCambioDetectado.length > 1){
                setLoading(false);
                setConfirmaSobreEscribirCita(true);
                return;
            }
        }

        /* ************************************************************************************************************************ */ 
        /* ************************************************************************************************************************ */ 
        /* ************************************************************************************************************************ */ 
        //alert(cadenaGeneral);
        try {
            const res = await uploadCita({
                id: citaSeleccionada.id ? citaSeleccionada.id : null,
                title: tituloModificado,
                start: citaSeleccionada.start,
                logVo : !banderaEncontrado? null:{
                    idUsuario: usuario.idUsuario,
                    modulo: "Agenda",
                    actividad:cadenaGeneral  
                },
                end: citaSeleccionada.end,
                className: citaSeleccionada.className ? citaSeleccionada.className : null,
                extendedProps: {
                    espacio: citaSeleccionada.espacio,
                    tipoEspacio: citaSeleccionada.tipoEspacio,
                    comentarioRecepcion:
                        comentario && citaSeleccionada.idCatTipoCita == 11 && !UISweetAlert.editar
                            ? comentario
                            : citaSeleccionada.comentarioRecepcion,
                    paciente: citaSeleccionada.paciente,
                    nombrePaciente: nombrePacienteGlobal, 
                    comentarioAseguradora:citaSeleccionada.comentarioAseguradora,
                    comentarioFarmacia:citaSeleccionada.comentarioFarmacia,
                    comentarioTratamiento:citaSeleccionada.comentarioTratamiento, 

                    salvaComentarioTratamiento:!(comentarioTratamientoOriginal==citaSeleccionada.comentarioTratamiento)? true:false,
                    salvaComentarioRecepcion:!(comentarioRecepcionOriginal==citaSeleccionada.comentarioRecepcion)? true:false,
                    salvaComentarioFarmacia:!(comentarioFarmaciaOriginal==citaSeleccionada.comentarioFarmacia)? true:false,
                    salvaComentarioAseguradora:!(comentarioAseguradoraOriginal==citaSeleccionada.comentarioAseguradora)? true:false,
                     
                    todoDia: citaSeleccionada.todoDia,
                    idCatTipoCita: citaSeleccionada.idCatTipoCita,
                    consultorio: citaSeleccionada.consultorio,
                    solicitaMedico: esAsistente? true:( usuario.idCatMedico != null ? true : false),
                    usuarioSolicitante: usuario.nombre,
                    idCatMedico: citaSeleccionada.idCatMedico,
                },
            });
            if (res.data.error) {
                setLoading(false);
                LimpiarDatos();
                setUISweetAlert({
                    ...sweetAlertDefault,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else {
                const newStartDate = new Date();
                const startDate = formatDate(newStartDate, true);
                const newEndDate = new Date(new Date().getTime() + tiempoCita);
                const endDate = formatDate(newEndDate, true);
                setCitaSeleccionada({
                    start: startDate,
                    end: endDate,
                });
                setDisabledEditar(false);
                setDatosPaciente({});
                const espacios = esAsistente ? espaciosAtencionDefault : (usuario.idCatMedico ? espaciosAtencionDefault : opcionesEspacios);

                let temporal = false;
                if(esMedico || esAsistente){
                    temporal = opcionesExtras.seleccionarTodosEspacios;
                    opcionesExtras.seleccionarTodosEspacios =  false;
                }

                listarCitas( 
                    fechaActual,
                    espacios,
                    opcionesPacientes,
                    opcionesAseguradoras,
                    opcionesExtras.seleccionarTodosEspacios,
                    opcionesExtras.seleccionarTodosPacientes,
                    opcionesExtras.porAseguradora
                );

                if(esMedico || esAsistente){
                    opcionesExtras.seleccionarTodosEspacios =  temporal;
                }

                LimpiarDatos();
                setLoading(false);
                /* AQUI ESTA EL PROCESO DONDE DECIDIMOS SI EL MENSAJE DE GUARDADO ES DE TIPO CONSULTORIO O NO*/
                /* SI ES TIPO CONSULTORIO INFORMA EL CONSULTOR, DE OTRA MANERA SOLO INFORMA QUE SE GUARDO CON EXITO LA CITA*/ 
                if (res.data.consultorio != null && citaSeleccionada.tipoEspacio == 1){
                    let Cadena =  ", consultorio: " + res.data.consultorio 
                    LimpiarDatos();          
                    setUISweetAlert({
                        ...sweetAlertDefault,
                        success_dlg: true,
                        dynamic_title: "Exito", 
                        dynamic_description: "Se guardaron los cambios de la cita"  + Cadena,
                    });
                }
                else{
                    LimpiarDatos();
                    setUISweetAlert({
                        ...sweetAlertDefault,
                        success_dlg: true,
                        dynamic_title: "Exito", 
                        dynamic_description: "Se guardaron los cambios de la cita" ,
                    });
                } 

            }
        } catch (error) {
            setLoading(false);
            LimpiarDatos();
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Error en el servidor",
            });
        }
     };

    const tituloFormatear = () => {
        let nombrePaciente = "";
        let nombreMedico = "";
        let nombreTipoCita = "";

        if (citaSeleccionada.paciente != null) {
            const pacienteFiltrado = pacientes.filter((paciente) => {
                if (paciente.idCatPaciente == citaSeleccionada.paciente) return paciente;
            });
            if (pacienteFiltrado && pacienteFiltrado.length > 0) {
                nombrePaciente =
                    (pacienteFiltrado[0].nombres ? pacienteFiltrado[0].nombres : "") +
                    " " +
                    (pacienteFiltrado[0].apellidoPaterno ? pacienteFiltrado[0].apellidoPaterno : "") +
                    " " +
                    (pacienteFiltrado[0].apellidoMaterno ? pacienteFiltrado[0].apellidoMaterno : "");
            }
        }

        if (citaSeleccionada.idCatMedico != null) {
            const medicoFiltrado = medicos.filter((medico) => {
                if (medico.idCatMedico == citaSeleccionada.idCatMedico) return medico;
            });

            if (medicoFiltrado && medicoFiltrado.length > 0 && medicoFiltrado[0].catPersona) {
                nombreMedico =
                    (medicoFiltrado[0].catPersona.nombres ? medicoFiltrado[0].catPersona.nombres : "") +
                    " " +
                    (medicoFiltrado[0].catPersona.apellidoPaterno ? medicoFiltrado[0].catPersona.apellidoPaterno : "") +
                    " " +
                    (medicoFiltrado[0].catPersona.apellidoMaterno ? medicoFiltrado[0].catPersona.apellidoMaterno : "");
            }
        }

        if (citaSeleccionada.idCatTipoCita != null) {
            const tipoCitaFiltrada = tipoCitas.filter((tipoCita) => {
                if (tipoCita.idCatTipoCita == citaSeleccionada.idCatTipoCita) return tipoCita;
            });

            if (tipoCitaFiltrada && tipoCitaFiltrada.length > 0) {
                nombreTipoCita = tipoCitaFiltrada[0].tipoCita ? tipoCitaFiltrada[0].tipoCita : "";
            }
        }

        return nombrePaciente + " - " + nombreMedico + " - " + nombreTipoCita;
    };

    const cambioHoraCita = async (e) => {
        if (autorizado) {
            if (new Date() > e.oldEvent.start) {
                setUISweetAlert({
                    ...sweetAlertDefault,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Citas que su hora de inicio ya paso, no pueden ser modificadas.",
                });
                return;
            }

            if (new Date() > e.event.start) {
                setUISweetAlert({
                    ...sweetAlertDefault,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "No se pueden mover a citas al pasado.",
                });
                return;
            }

            setLoading(true);
            try {
                let diffMinutes = 0; // 
                let fechaback = null;
                if(e.event.endStr==''){
                    citas.map((cita) =>
                    {
                        if(cita.id == e.event.id){
                            const fechaIni = new Date(cita.start);
                            const fechaFin = new Date(cita.end);
                            const diffTime = Math.abs(fechaFin - fechaIni);
                            diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60)); // 
                            
                            const newDateObj = new Date(e.event.startStr);
                            newDateObj.setMinutes(newDateObj.getMinutes() + diffMinutes);
                            fechaback = new Date(newDateObj);
                        }
                    })                     
                }

                const res = await uploadCita({
                    id: e.event.id,
                    start: e.event.startStr,
                    end: e.event.endStr != ''? e.event.endStr : fechaback,
                    title: e.event.title,
                    extendedProps: e.event.extendedProps,
                    className: e.event.classNames[0],
                });

                if (res.data.error) {
                    setLoading(false);

                    setUISweetAlert({
                        ...sweetAlertDefault,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: res.data.message,
                    });
                } else {
                    setLoading(false);
                    
                    setUISweetAlert({
                        ...sweetAlertDefault,
                        success_dlg: true,
                        dynamic_title: "Exito",
                        dynamic_description: "Se guardaron los cambios de la cita" ,
                    });
                }
            } catch (error) {
                setLoading(false);
                setUISweetAlert({
                    ...sweetAlertDefault,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Ocurrió un error, por favor intentalo más tarde.",
                });
            }
        } else {
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "No tienes los permisos necesarios para cambiar la fecha de una cita.",
            });
        }
    };

    const viewchange = async (e) => {
        if (e.view.type == "dayGridMonth") {
            setMostrarFinDeSemana(true);
        } else {
            setMostrarFinDeSemana(true);
        }
    };

    const changeEspacioFiltro = async (optionsPar) => {
        setLoading(true);
        if(optionsPar.length > 0){
            setExistenFiltros(true);
        }else{
            setExistenFiltros(false);
        }
        //setOpcionesEspacios(optionsPar);
        setFiltroCitasEspacios(optionsPar);

        setOpcionesEspacios( 
            optionsPar.map((optionsInt) =>
            {
                    return optionsInt; 
            })  
        )

        try {
            
            let temporal = false;
            if(esMedico || esAsistente){
                temporal = opcionesExtras.seleccionarTodosEspacios;
                opcionesExtras.seleccionarTodosEspacios =  false;
            }

            const res = await listarCitas(
                fechaActual,
                optionsPar, 
                opcionesPacientes,
                opcionesAseguradoras,
                opcionesExtras.seleccionarTodosEspacios,
                opcionesExtras.seleccionarTodosPacientes,
                opcionesExtras.porAseguradora
            );
            
            if(esMedico || esAsistente){
                opcionesExtras.seleccionarTodosEspacios =  temporal;
            }

            await delay(delayTime);
            setLoading(false);
            return res;
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const changeAseguradoraFiltro = async (optionsPar) => {
        setLoading(true);
        if(optionsPar.length > 0){
            setExistenFiltros(true);
        }else{
            setExistenFiltros(false);
        }

        setFiltroCitasAseguradoras(optionsPar);
        setOpcionesAseguradoras( 
            optionsPar.map((optionsInt) =>
            {
                    return optionsInt; 
            })  
        )

        try {            
            const res = await listarCitas(
                fechaActual,
                opcionesEspacios, 
                opcionesPacientes,
                optionsPar, 
                opcionesExtras.seleccionarTodosEspacios,
                opcionesExtras.seleccionarTodosPacientes,
                opcionesExtras.porAseguradora
            );

            await delay(delayTime);
            setLoading(false);
            return res;
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const changePacientesFiltro = async (optionsPar) => {
        setLoading(true);
        setOpcionesPacientes(optionsPar);
        // setPacienteDeCitaSeleccionada(options);
        setFiltroCitasPacientes(optionsPar);
        //const espacios = usuario.idCatMedico ? espaciosAtencionDefault : filtroCitasEspacios;
        const espacios = esAsistente ? espaciosAtencionDefault : (usuario.idCatMedico ? espaciosAtencionDefault : filtroCitasEspacios);        

        try {

            let temporal = false;
            if(esMedico || esAsistente){
                temporal = opcionesExtras.seleccionarTodosEspacios;
                opcionesExtras.seleccionarTodosEspacios =  false;
            }

            if(optionsPar){
                opcionesExtras.seleccionarTodosPacientes =  false;
            }

            const res = await listarCitas(
                fechaActual,  
                espacios,
                optionsPar,
                opcionesAseguradoras,
                opcionesExtras.seleccionarTodosEspacios,
                opcionesExtras.seleccionarTodosPacientes,
                opcionesExtras.porAseguradora
            );

            if(esMedico || esAsistente){
                opcionesExtras.seleccionarTodosEspacios =  temporal;
            }

            await delay(delayTime);
            setLoading(false);
            return res;
        } catch (error) {
            setLoading(false);
        }
    };

    // const changeEspacioFiltroImpresion = async (options) => {
    //   setEspaciosImpresion(options);
    // };

    const checkIncluyeEspacioMedico = () => {
        let hayEspacioMedico = false;
        if (opcionesExtras.seleccionarTodosEspacios) {
            return true;
        } else if (filtroCitasEspacios && filtroCitasEspacios.length > 0) {
            const numeroDeEspacios = filtroCitasEspacios.length;
            for (let i = 0; i < numeroDeEspacios; i++) {
                if (filtroCitasEspacios[i].idCatTipoEspacio == 1) {
                    hayEspacioMedico = true;
                    break;
                }
            }
            return hayEspacioMedico;
        } else {
            return false;
        }
    };

    const RefreshCalendar = async () => {
        let startDate = infoCalGlobal;
        
        const fechaFormateada = formatDate(startDate, true, true);

        setFechaActual(fechaFormateada);
        setFiltrosImpresion({fechaImpresion: fechaFormateada, incluirCitas: true, incluirTratamientos: true});
        const espacios = esAsistente? espaciosAtencionDefault:(usuario.idCatMedico ? espaciosAtencionDefault : filtroCitasEspacios);

        setLoading(true);
        try {

            let temporal = false;
            if(esMedico || esAsistente){
                temporal = opcionesExtras.seleccionarTodosEspacios;
                opcionesExtras.seleccionarTodosEspacios =  false;
            }


            const res = await listarCitas(
                fechaFormateada,
                espacios,
                filtroCitasPacientes,
                opcionesAseguradoras,
                opcionesExtras.seleccionarTodosEspacios,
                opcionesExtras.seleccionarTodosPacientes,
                opcionesExtras.porAseguradora
            );

            if(esMedico || esAsistente){
                opcionesExtras.seleccionarTodosEspacios =  temporal;
            }
            await delay(delayTime);
            setLoading(false);
            return res;
        } catch (error) {
            setLoading(false);
        }
    };

    // Change fecha causa llamado a listarCitas cuando el componente se renderiza
    const changeFecha = async (info) => {
        let startDate = info.start;
        setInfoCalGlobal(startDate)

        const fechaFormateada = formatDate(startDate, true, true);

        setFechaActual(fechaFormateada);
        setFiltrosImpresion({fechaImpresion: fechaFormateada, incluirCitas: true, incluirTratamientos: true});
        const espacios = esAsistente? espaciosAtencionDefault:(usuario.idCatMedico ? espaciosAtencionDefault : filtroCitasEspacios);
               
            if(espacios!=null)
            {
                if(espacios.length > 0)
                {
                    let temporal = false;
                    if(esMedico || esAsistente){
                        temporal = opcionesExtras.seleccionarTodosEspacios;
                        opcionesExtras.seleccionarTodosEspacios =  false;
                    }

                    setLoading(true);
                    try {
                        const res = await listarCitas(
                            fechaFormateada,
                            espacios,
                            filtroCitasPacientes,
                            opcionesAseguradoras,
                            opcionesExtras.seleccionarTodosEspacios,
                            opcionesExtras.seleccionarTodosPacientes,
                            opcionesExtras.porAseguradora
                        );

                        if(esMedico || esAsistente){
                            opcionesExtras.seleccionarTodosEspacios =  temporal;
                        }

                        await delay(delayTime);
                        setLoading(false);
                        return res;
                    } catch (error) {
                        setLoading(false);
                    } 
                }   
            }else{
                setLoading(true);
                try {

                    let temporal = false;
                    if(esMedico || esAsistente){
                        temporal = opcionesExtras.seleccionarTodosEspacios;
                        opcionesExtras.seleccionarTodosEspacios =  false;
                    }

                    const res = await listarCitas(
                        fechaFormateada,
                        espacios,
                        filtroCitasPacientes,
                        opcionesAseguradoras,
                        opcionesExtras.seleccionarTodosEspacios,
                        opcionesExtras.seleccionarTodosPacientes,
                        opcionesExtras.porAseguradora
                    );

                    if(esMedico || esAsistente){
                        opcionesExtras.seleccionarTodosEspacios =  temporal;
                    }

                    await delay(delayTime);
                    setLoading(false);
                    return res;
                } catch (error) {
                    setLoading(false);
                }                
            }
    };

    const nuevoPacienteRedirect = async () => {
        if (banCrearPaciente) {
            setIdCatPacienteSeleccionado(null);
            props.history.push("/historia/clinica/inicial");
        } else {
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "No tiene habilitada la creación de un nuevo paciente desde este módulo.",
            });
        }
    };

    const changePacienteNuevaCita = async (option) => {
        let comentariosPacienteLocal = TraeComentariosPaciente(option.idCatPaciente)
        if(comentariosPacienteLocal ==null){
            comentariosPacienteLocal = "Sin comentarios"
        }
        setComentarioPaciente(comentariosPacienteLocal)

        filterPacienteCitaSeleccionada(option.idCatPaciente);
        if (option == null) {
            setCitaSeleccionada({
                ...citaSeleccionada,
                paciente: "",
            });
        } else {
            setCitaSeleccionada({
                ...citaSeleccionada,
                paciente: option.idCatPaciente,
            });
        }
    };

    const changePacienteHistorico = async (option) => {
        if (option == null) {
            setPacienteSeleccionadoHistorico("");
        } else {
            setLoading(true);
            setPacienteSeleccionadoHistorico(option);
            try {
                const res = await listarCitasPaciente(option.idCatPaciente);

                if (res && res.data) {
                    setLoading(false);
                    setCitasPaciente(res.data);
                } else {
                    setLoading(false);
                    setUISweetAlert({
                        ...sweetAlertDefault,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "Error en el servidor.",
                    });
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setUISweetAlert({
                    ...sweetAlertDefault,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Error en el servidor.",
                });
            }
        }
    };

    const expedienteMedicoPaciente = async () => {
        setIdCatPacienteSeleccionado(citaSeleccionada.paciente);
        window.open("/historia/clinica/inicial");
    };

    useEffect(() => {
        setFiltroCitasEspacios(null);

        setOpcionesExtras({
            ...opcionesExtras,
            seleccionarTodosEspacios : true,
            porAseguradora : false,
            seleccionarTodosPacientes : true,
        });
        const filterEspaciosMedico = checkIfMedico();

        filterEspaciosMedico();
        checkHorarioEspacio();
      
    }, []);

    const imprimirAgenda = () => {
        if (banImprimiragenda) {
            setUISweetAlert({
                ...sweetAlertDefault,
                confirm_imprimir_agenda: true,
            });
        }else{
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Aviso!",
                dynamic_description: "No tienes los permisos para imprimir la agenda",
            });
        }
    };

    const visualizarDiaCitas = () => {
        if (banImprimiragenda) {
            setUISweetAlert({
                ...sweetAlertDefault,
                confirm_exponer_agenda: true,
            });
        }
    };

    const visualizarDiaCitasGeneral = () => {
        if (banImprimiragenda) {
            setUISweetAlert({
                ...sweetAlertDefault,
                confirm_exponer_agendaGeneral: true,
            });
        }
    };

    const visualizarCitas = async () => {
        const conFiltros =
            ((filtroCitasEspacios && filtroCitasEspacios.length >= 1) ||
                (espaciosAtencionDefault && espaciosAtencionDefault.length >= 1) ||
                (filtroCitasPacientes && filtroCitasPacientes.length >= 1) ||
                opcionesExtras.seleccionarTodosEspacios ||
                opcionesExtras.seleccionarTodosPacientes) &&
            filtrosImpresion.fechaImpresion &&
            filtrosImpresion.fechaImpresion !== "";

        if (checkIncluyeEspacioMedico()) {
            if (!filtrosImpresion.incluirTratamientos && !filtrosImpresion.incluirCitas) {
                setUISweetAlert({
                    ...sweetAlertDefault,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Se requiere tener al menos seleccionado el checkbox incluir tratamientos o incluir citas",
                });
                return;
            }
        }
        try {
            const datos = {
                fechaBusqueda: filtrosImpresion.fechaImpresion,
                todosEspacios: opcionesExtras.seleccionarTodosEspacios ? opcionesExtras.seleccionarTodosEspacios : false,
                todosPacientes: opcionesExtras.seleccionarTodosPacientes ? opcionesExtras.seleccionarTodosPacientes : false,
                porDia: filtrosImpresion.porDia,
                incluirTratamientos: filtrosImpresion.incluirTratamientos,
                incluirCitas: filtrosImpresion.incluirCitas,
                porAseguradora: opcionesExtras.porAseguradora ? opcionesExtras.porAseguradora : false,
                espaciosVo: opcionesExtras.seleccionarTodosEspacios ? null : filtroCitasEspacios,
            }
            openNewWindow(datos);

        } catch (error) {
            setLoading(false);
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Error en el servidor",
            });
        }

        // exportAgenda({ ...filtrosImpresion, espacios: espaciosImpresion });
    };

    const visualizarCitasGeneral = async () => {
        const conFiltros =
            ((filtroCitasEspacios && filtroCitasEspacios.length >= 1) ||
                (espaciosAtencionDefault && espaciosAtencionDefault.length >= 1) ||
                (filtroCitasPacientes && filtroCitasPacientes.length >= 1) ||
                opcionesExtras.seleccionarTodosEspacios ||
                opcionesExtras.seleccionarTodosPacientes) &&
            filtrosImpresion.fechaImpresion &&
            filtrosImpresion.fechaImpresion !== "";

        if (checkIncluyeEspacioMedico()) {
            if (!filtrosImpresion.incluirTratamientos && !filtrosImpresion.incluirCitas) {
                setUISweetAlert({
                    ...sweetAlertDefault,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Se requiere tener al menos seleccionado el checkbox incluir tratamientos o incluir citas",
                });
                return;
            }
        }
        try {
            const datos = {
                fechaBusqueda: filtrosImpresion.fechaImpresion,
                todosEspacios: opcionesExtras.seleccionarTodosEspacios ? opcionesExtras.seleccionarTodosEspacios : false,
                todosPacientes: opcionesExtras.seleccionarTodosPacientes ? opcionesExtras.seleccionarTodosPacientes : false,
                porDia: filtrosImpresion.porDia,
                incluirTratamientos: filtrosImpresion.incluirTratamientos,
                incluirCitas: filtrosImpresion.incluirCitas,
                porAseguradora: opcionesExtras.porAseguradora ? opcionesExtras.porAseguradora : false,
                espaciosVo: opcionesExtras.seleccionarTodosEspacios ? null : filtroCitasEspacios,
            }
            openNewWindowGeneral(datos);
        } catch (error) {
            setLoading(false);
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Error en el servidor",
            });
        }

        // exportAgenda({ ...filtrosImpresion, espacios: espaciosImpresion });
    };
    

    const confirmImprimirAgenda = async () => {
        const conFiltros =
            ((filtroCitasEspacios && filtroCitasEspacios.length >= 1) ||
                (espaciosAtencionDefault && espaciosAtencionDefault.length >= 1) ||
                (filtroCitasPacientes && filtroCitasPacientes.length >= 1) ||
                opcionesExtras.seleccionarTodosEspacios ||
                opcionesExtras.seleccionarTodosPacientes) &&
            filtrosImpresion.fechaImpresion &&
            filtrosImpresion.fechaImpresion !== "";

        if (checkIncluyeEspacioMedico()) {
            if (!filtrosImpresion.incluirTratamientos && !filtrosImpresion.incluirCitas) {
                setUISweetAlert({
                    ...sweetAlertDefault,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Se requiere tener al menos seleccionado el checkbox incluir tratamientos o incluir citas",
                });
                return;
            }
        }
        if ((!filtrosImpresion.porDia && conFiltros) ) {
            // TODO: Se debe agregar también en exportAgendaB los filtros de pacientes y el filtro si estan seleccionado todo
            setLoading(true);

            if(esAsistente || esMedico){
                opcionesExtras.seleccionarTodosEspacios =  false;
            }

            if (filtroCitasEspacios && filtroCitasEspacios.length > 0 && !opcionesExtras.seleccionarTodosEspacios) {
                filtroCitasEspacios.forEach(async (espacio) => {
                    // eslint-disable-next-line
                    
                    try {
                        const res = await exportAgenda({...filtrosImpresion, espacioVo: espacio});
                        await delay(500);

                        if (res.data && res.data.error) {
                            setLoading(false);
                            setUISweetAlert({
                                ...sweetAlertDefault,
                                error_dlg: true,
                                dynamic_title: "Fallo",
                                dynamic_description: res.data.message ? res.data.message : "",
                            });
                        } else {
                            setLoading(false);
                        }
                        setLoading(false);
                    } catch (error) {
                        setLoading(false);
                        setUISweetAlert({
                            ...sweetAlertDefault,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: "Error en el servidor",
                        });
                    }
                });
            } else if (espaciosAtencionDefault && espaciosAtencionDefault.length > 0 && !opcionesExtras.seleccionarTodosEspacios) {
                espaciosAtencionDefault.forEach(async (espacio) => {
                    // eslint-disable-next-line
                    try {
                        const res = await exportAgenda({...filtrosImpresion, espacioVo: espacio});
                        if (res.data && res.data.error) {
                            setLoading(false);
                            setUISweetAlert({
                                ...sweetAlertDefault,
                                error_dlg: true,
                                dynamic_title: "Fallo",
                                dynamic_description: res.data.message ? res.data.message : "",
                            });
                        } else {
                            setLoading(false);
                        }
                        setLoading(false);
                    } catch (error) {
                        setLoading(false);
                        setUISweetAlert({
                            ...sweetAlertDefault,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: "Error en el servidor",
                        });
                    }
                });
            } else if (opcionesExtras.seleccionarTodosEspacios) {
                espaciosAtencionAgenda.forEach(async (espacio) => {
                    // eslint-disable-next-line
                    try {
                        const res = await exportAgenda({...filtrosImpresion, espacioVo: espacio});
                        await delay(500);
                        
                        if (res.data && res.data.error) {
                            setLoading(false);
                            setUISweetAlert({
                                ...sweetAlertDefault,
                                error_dlg: true,
                                dynamic_title: "Fallo",
                                dynamic_description: res.data.message ? res.data.message : "",
                            });
                        } else {
                            setLoading(false);
                        }
                    } catch (error) {
                        setLoading(false);
                        setUISweetAlert({
                            ...sweetAlertDefault,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: "Error en el servidor",
                        });
                    }
                });
            }
        } else if (filtrosImpresion.porDia && filtrosImpresion.fechaImpresion) {
            setLoading(true);
            try {
                const res = await exportAgendaPorDia({
                    fechaBusqueda: filtrosImpresion.fechaImpresion,
                    todosEspacios: opcionesExtras.seleccionarTodosEspacios ? opcionesExtras.seleccionarTodosEspacios : false,
                    todosPacientes: opcionesExtras.seleccionarTodosPacientes ? opcionesExtras.seleccionarTodosPacientes : false,
                    porDia: filtrosImpresion.porDia,
                    incluirTratamientos: filtrosImpresion.incluirTratamientos,
                    incluirCitas: filtrosImpresion.incluirCitas,
                    porAseguradora: opcionesExtras.porAseguradora ? opcionesExtras.porAseguradora : false,
                    espaciosVo: opcionesExtras.seleccionarTodosEspacios ? null : filtroCitasEspacios,
                });
                if (res.data && res.data.error) {
                    setLoading(false);
                    setUISweetAlert({
                        ...sweetAlertDefault,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: res.data.message ? res.data.message : "",
                    });
                } else {
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                setUISweetAlert({
                    ...sweetAlertDefault,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Error en el servidor",
                });
            }
        } else {
            setUISweetAlert({
                ...sweetAlertDefault,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, elige la fecha y los espacios a imprimir.",
            });
        }
        // exportAgenda({ ...filtrosImpresion, espacios: espaciosImpresion });
    };

    const onChangeFiltroImpresion = (e) => {
        const {name, value, checked} = e.target;
        if (name == "porDia") {
            setFiltrosImpresion({
                ...filtrosImpresion,
                [name]: checked,
            });
        } else if (name == "incluirTratamientos") {
            if (!filtrosImpresion.incluirCitas && !checked) {
                return;
            } else if (opcionesExtras.seleccionarTodosEspacios) {
                return;
            }
            setFiltrosImpresion({
                ...filtrosImpresion,
                [name]: checked,
            });
        } else if (name == "incluirCitas") {
            if (!filtrosImpresion.incluirTratamientos && !checked) {
                return;
            } else if (opcionesExtras.seleccionarTodosEspacios) {
                return;
            }
            setFiltrosImpresion({
                ...filtrosImpresion,
                [name]: checked,
            });
        } else if (name == "fechaImpresion") {
            if (filtrosImpresion.fechaImpresionFinal && value > filtrosImpresion.fechaImpresionFinal) {
                setFiltrosImpresion({
                    ...filtrosImpresion,
                    [name]: value,
                    fechaImpresionFinal: value,
                });
            } else {
                setFiltrosImpresion({
                    ...filtrosImpresion,
                    [name]: value,
                });
            }
        } else {
            setFiltrosImpresion({
                ...filtrosImpresion,
                [name]: value,
            });
        }
    };

    const historicoPacientes = () => {
        setUISweetAlert({
            ...sweetAlertDefault,
            confirm_historico_paciente: true,
        });
    };

    const autorizado =
        usuario &&
        (usuario.authorities.includes("ROLE_RECEPCION") ||
            usuario.authorities.includes("ROLE_MEDICO") ||
            usuario.authorities.includes("ROLE_ASISTENTE") ||
            usuario.authorities.includes("ROLE_ADMIN_SISTEMA"));

    const autorizadoHistorico = usuario;

    const checkPermisosCalendario = () => {
        if (autorizado && banCrearCitas) {
            if (usuario && esMedicoNoAdministrador) {
                if (espaciosAtencionDefault && espaciosAtencionDefault.length > 0) {
                    setCitaSeleccionada({...citaSeleccionada, espacio: espaciosAtencionDefault[0].idEspacio, tipoEspacio: 1});
                }
            }
            setUISweetAlert({
                ...sweetAlertDefault,
                confirm_alert_cita: true,
            });
        } else {
            setUISweetAlert({
                ...sweetAlertDefault,
                confirm_alert_cita_no_autorizado: true,
            });
        }
    };

    const changeOpcionesExtras = async (e) => {
        const {name, value, checked} = e.target;
        if (name == "seleccionarTodosEspacios") {
            setOpcionesExtras({
                ...opcionesExtras,
                [name]: checked,
            });
            if (checked) {
                setFiltrosImpresion({
                    ...filtrosImpresion,
                    incluirCitas: true,
                    incluirTratamientos: true,
                });
                setExistenFiltros(false);
            }else{
                if(filtroCitasEspacios){
                    if(filtroCitasEspacios.length > 0){
                        setExistenFiltros(true);
                    }else{
                        setExistenFiltros(false);
                    }
                }else{
                    setExistenFiltros(false);
                }
            }
            setLoading(true);
            try {

                let temporal = false;
                if(esMedico || esAsistente){
                    temporal = opcionesExtras.seleccionarTodosEspacios;
                    checked =  false;
                }

                const res = await listarCitas(
                    fechaActual,
                    filtroCitasEspacios,
                    filtroCitasPacientes,
                    opcionesAseguradoras,
                    checked,
                    opcionesExtras.seleccionarTodosPacientes,
                    opcionesExtras.porAseguradora
                );

                if(esAsistente || esMedico){
                    checked =  temporal;
                }

                await delay(delayTime);
                setLoading(false);
                return res;
            } catch (error) {
                setLoading(false);
            }
        } else if (name == "seleccionarTodosPacientes") 
        {
            setOpcionesExtras({
                ...opcionesExtras,
                [name]: checked,
            }); 
            setLoading(true);
            try {

                let temporal = false;
                if(esMedico || esAsistente){
                    temporal = opcionesExtras.seleccionarTodosEspacios;
                    opcionesExtras.seleccionarTodosEspacios =  false;
                }

                const res = await listarCitas(
                    fechaActual,
                    filtroCitasEspacios,
                    filtroCitasPacientes,
                    opcionesAseguradoras,
                    opcionesExtras.seleccionarTodosEspacios,
                    checked,
                    opcionesExtras.porAseguradora
                );

                if(esAsistente || esMedico){
                    opcionesExtras.seleccionarTodosEspacios =  temporal;
                }

                await delay(delayTime);
                setLoading(false);
                return res;
            } catch (error) {
                setLoading(false);
            }  
        } else if (name == "porAseguradora") 
        {
            //RefreshCalendar();
            setOpcionesExtras({
                ...opcionesExtras,
                [name]: checked,
            }); 
            setLoading(true);
            try {
                
                let temporal = false;
                if(esMedico || esAsistente){
                    temporal = opcionesExtras.seleccionarTodosEspacios;
                    opcionesExtras.seleccionarTodosEspacios =  false;
                }

                const res = await listarCitas(
                    fechaActual,
                    filtroCitasEspacios,
                    filtroCitasPacientes,
                    opcionesAseguradoras,
                    opcionesExtras.seleccionarTodosEspacios,
                    opcionesExtras.seleccionarTodosPacientes,
                    checked
                );

                if(esAsistente || esMedico){
                    opcionesExtras.seleccionarTodosEspacios =  temporal;
                }

                await delay(delayTime);
                setLoading(false);
                return res;
            } catch (error) {
                setLoading(false);
            }
        } else {
            setOpcionesExtras({
                ...opcionesExtras,
                [name]: value,
            });
        }
    };

    /*  EN ESTA PARTE SE HACE EL FILTRO POR DOCTOR PARA QUE NADA MAS PUEDA VER SUS CONSULTAS  */
    const seleccionarEspaciosAtencionDefault = (espaciosFetched) => {
        const espaciosDefault = espaciosFetched.filter((espacio) => {
            if (esAsistente){
                if (espacio.idAsistente == usuario.idCatUsuario){
                    return espacio;
                }
            }else{
                return espacio.idCatMedico == usuario.idCatMedico;
            } 
        });
        setEspaciosAtencionDefault(espaciosDefault);
        return espaciosDefault;
    };

    // Método para checar si hay espacios de tipo médico en los espacios seleccionados
    const checkHorarioEspacio = (espacios) => {
        let horarioMin = null;
        let horarioMinInt = null;
        if (espacios && espacios.length == 1) {
            if (espacios[0].semana && espacios[0].semana.length > 0) {
                espacios[0].semana.forEach((dia) => {
                    if (dia.horarios && dia.horarios.length > 0 && dia.horarios[0] && dia.horarios[0].disponible) {
                        if (horarioMin == null) {
                            horarioMin = dia.horarios[0].inicio;
                            horarioMinInt = parseInt(dia.horarios[0].inicio.split(":")[0]);
                        } else {
                            let horarioMinTemp = parseInt(dia.horarios[0].inicio.split(":")[0]);
                            if (horarioMinInt > horarioMinTemp) {
                                horarioMin = dia.horarios[0].inicio;
                                horarioMinInt = parseInt(dia.horarios[0].inicio.split(":")[0]);
                            }
                        }
                    }
                });
            }
        } else {
            horarioMin = "06:00:00";
        }
        setHorarioMinimoCalendario(horarioMin);
    };

    const eventContent = (eventInfo) => {
        return (
          <div className="custom-event-content">
            <strong>{eventInfo.event.title}</strong>
            <span>{eventInfo.event.start.toLocaleString()}</span>
          </div>
        );
      };

    const renderCalendar = useMemo(() => {
        return (
            <FullCalendar
                timeZone="America/Mexico_City"
                utc={true}
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                ref={calendarComponentRef}
                initialView="timeGridWeek"
                navLinks={true}
                nowIndicator={true}
                progressiveEventRendering={false}
                dayMaxEventRows={5}
                eventMaxStack={2}
                autoComplete="off" 
                editable={true}
                eventDrop={cambioHoraCita}
                eventResize={cambioHoraCita}
                slotMinTime={horarioMinimoCalendario ? horarioMinimoCalendario : "06:00:00"}
                slotMaxTime={"23:59:00"}
                slotEventOverlap={true}
                eventLimit={1}
                slotDuration={"00:15:00"}
                allDaySlot={false}
                className="fc-event-title fc-event "
                eventStack={true}
                weekends={mostrarFinDeSemana}
                eventSources={[
                    !banOcultarCalendario?{
                        events: citas,
                        color: "#000000",
                        // color: 'green',
                    }:false
                ]}
                headerToolbar={{
                    left: "prev,next today nuevoEvento nuevoPaciente",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                buttonText={{
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Día",
                }}
                customButtons={{
                    nuevoEvento: {
                        text: "Nueva Cita",
                        click: () => {
                            checkPermisosCalendario();
                        },
                    },
                    nuevoPaciente: {
                        text: "Nuevo Paciente",
                        click: () => {
                            nuevoPacienteRedirect();
                        },
                    },
                }}
                initialDate={new Date()}
                dateClick={autorizado ? nuevaCitaFechaSeleccionada : null}
                eventClick={editCitaConfirm}
                viewDidMount={viewchange}
                datesSet={(info) => changeFecha(info)}
                locale="es"
            />
        );
    }, [citas, pacientes]);
    return (
        <>
            {usuario &&
            (usuario.authorities.includes("OP_CONSULTA_CALENDARIO") ||
                usuario.authorities.includes("ROLE_CONTABILIDAD") ||
                usuario.authorities.includes("ROLE_FARMACIA") ||
                usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                usuario.authorities.includes("ROLE_ASISTENTE") ||
                usuario.authorities.includes("ROLE_MEDICO")  ||
                usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                usuario.authorities.includes("ROLE_COMPRAS") ) ? (
                <>
                    {}
                    <div className="mt-8" style={{textAlign: "left"}}>
                        <h3 className="flex h3 ">Agenda</h3>
                        <Card className="mt-4">
                            <CardBody>
                                <Row>
                                    <Col xl={6} lg={12} md={20}>
                                        <Row className="form-group">
                                            <label className="col-sm-3 col-form-label">Espacios de atención</label>
                                            <Col sm={9}>
                                                <WindowedSelect
                                                    isDisabled={
                                                        opcionesExtras.seleccionarTodosEspacios || (esMedicoNoAdministrador && !esMedicoConRoleAgenda)
                                                    }
                                                    isSearchable
                                                    options=  
                                                    {
                                                        esAsistente?
                                                        (
                                                             espaciosAtencionAgenda.filter((espacios) => espacios.idAsistente == usuario.idCatUsuario)
                                                        ) : (
                                                            esMedicoNoAdministrador && !esMedicoConRoleAgenda
                                                            ? espaciosAtencionAgenda.filter((espacios) => espacios.idCatMedico == usuario.idCatMedico)
                                                            : espaciosAtencionAgenda
                                                        )
                                                    }
                                                    isMulti
                                                    autoComplete="off" 
                                                    getOptionLabel={(option) => option.espacio}
                                                    getOptionValue={(option) => option.idEspacio}
                                                    // styles={colourStyles}
                                                    value={
                                                        esMedicoNoAdministrador && !esMedicoConRoleAgenda? espaciosAtencionDefault 
                                                        : filtroCitasEspacios
                                                    }
                                                    menuPortalTarget={document.body}
                                                    menuPosition={"fixed"}
                                                    onChange={(option) => changeEspacioFiltro(option)}
                                                    noOptionsMessage={() => "No hay opciones"}
                                                    placeholder="Seleccione una opción"
                                                />
                                            </Col>
                                        </Row>
                                        {(banMostrarObjetos)? (
                                            <Row className="form-group">
                                                <label className="col-sm-3 col-form-label">Todos los espacios</label>
                                                <Col sm={2}>
                                                    <input
                                                        disabled={esMedicoNoAdministrador && !esMedicoConRoleAgenda}
                                                        autoComplete="off" 
                                                        type="checkbox"
                                                        name="seleccionarTodosEspacios"
                                                        className="form-control"
                                                        onChange={changeOpcionesExtras}
                                                        checked={opcionesExtras.seleccionarTodosEspacios}
                                                    />
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </Col>
                                    <Col xl={6} lg={12} md={20}>
                                        <Row className="form-group">
                                            <label className="col-sm-3 col-form-label">Pacientes</label>
                                            <Col sm={9}>
                                                <SelectPacientesPaginado
                                                    opcionSeleccionadaCompleta={opcionesPacientes}
                                                    onChangeOptions={changePacientesFiltro}
                                                    fetchOptions={loadPacientesSelect}
                                                    opcionesPaginado={opcionesSelectPacientes.opcionesPaginado}
                                                    placeholderText={opcionesSelectPacientes.placeholderText}
                                                    optionLabel={opcionesSelectPacientes.optionLabel}
                                                    optionValue={opcionesSelectPacientes.optionValue}
                                                    isDisabled={esMedico || esAsistente? false :  opcionesExtras.seleccionarTodosPacientes}
                                                    isMulti={true}
                                                />    
                                                {}
                                            </Col>
                                            {/*  TODO: */}
                                            {usuario.authorities.includes("ROLE_ASEGURADORAS") ? (
                                                <>
                                                <label className="col-sm-3 col-form-label">Aseguradoras</label>
                                                <Col sm={9}>
                                                    <WindowedSelect
                                                        isDisabled={!opcionesExtras.porAseguradora}
                                                        isSearchable
                                                        options= {aseguradoras.filter((aseguradoaInterno) => aseguradoaInterno.activo == true) }
                                                        isMulti
                                                        autoComplete="off" 
                                                        getOptionLabel={(option) => option.aseguradora}
                                                        getOptionValue={(option) => option.idCatAseguradora}
                                                        // styles={colourStyles}
                                                        value={filtroCitasAseguradoras}
                                                        menuPortalTarget={document.body}
                                                        menuPosition={"fixed"}
                                                        onChange={(option) => changeAseguradoraFiltro(option)}
                                                        noOptionsMessage={() => "No hay opciones"}
                                                        placeholder="Seleccione una opción"
                                                    />   
                                                    {}
                                                </Col>    
                                            </>
                                            ) :null}                                                                                    
                                            {(banMostrarObjetos) ? (
                                                <>
                                                    <label className="col-sm-3 col-form-label">Pacientes con aseguradora</label>
                                                    <Col sm={2}>
                                                        <input
                                                            type="checkbox"
                                                            autoComplete="off" 
                                                            name="porAseguradora"
                                                            className="form-control"
                                                            onChange={changeOpcionesExtras}
                                                            checked={opcionesExtras.porAseguradora}
                                                        />
                                                    </Col>
                                                    <label className="col-sm-3 col-form-label">Todos los pacientes</label>
                                                    <Col sm={2}>
                                                        <input
                                                            type="checkbox"
                                                            autoComplete="off" 
                                                            name="seleccionarTodosPacientes"
                                                            className="form-control"
                                                            onChange={changeOpcionesExtras}
                                                            checked={opcionesExtras.seleccionarTodosPacientes}
                                                        />
                                                    </Col>
                                                </>
                                            ) :null}
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card className="mt-4">
                            <CardBody className="flex flex-col md:flex-row justify-center md:justify-start">
                                <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light md:mr-3"
                                    type="button"
                                    onClick={imprimirAgenda}
                                    style={{
                                        background: "#ff9933",
                                        color: "white",
                                        marginTop: "1rem",
                                        fontWeight: "bold",
                                        height:42,      
                                        margin:5,    
                                        padding:7,                              
                                    }}>
                                    Imprimir Agenda
                                </button>

                                <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick = {visualizarDiaCitasGeneral}                                    
                                    style={{
                                        background: "#ff9933",
                                        color: "white",
                                        marginTop: "1rem",
                                        fontWeight: "bold",
                                        margin:5,
                                        height:42,
                                        padding:7,
                                    }}>
                                    Ver Agenda
                                </button>  

                                <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick={RefreshCalendar}
                                    style={{
                                        background: "#ff9933",
                                        color: "white",
                                        marginTop: "1rem",
                                        fontWeight: "bold",
                                        margin:5,
                                        height:42,
                                        padding:7,
                                    }}>
                                    Recargar Agenda
                                </button>    

                                <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick={historicoPacientes}
                                    style={{
                                        background: "#ff9933",
                                        color: "white",
                                        marginTop: "1rem",
                                        fontWeight: "bold",
                                        height:42,    
                                        margin:5,    
                                        padding:7,                                
                                    }}>
                                    Histórico de Pacientes
                                </button>
  
                                <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick = {visualizarDiaCitas}                                    
                                    style={{
                                        background: "#ff9933",
                                        color: "white",
                                        marginTop: "1rem",
                                        fontWeight: "bold",
                                        margin:5,
                                        height:42,
                                        padding:7,
                                    }}>
                                    Pantalla de Agenda
                                </button>                                                                                                                            
                                {}
                            </CardBody>
                        </Card>
                    </div>
                    {renderCalendar}
                    {}

                    {UISweetAlert.confirm_alert_cita ? (
                        <SweetAlert
                            title={UISweetAlert.editar ? "Editar Cita" : "Nueva Cita"}
                            warning
                            showCancel
                            showConfirm={autorizado || tienePermisoAseguradoras || esFarmacia}
                            confirmBtnText="Continuar"
                            confirmBtnBsStyle="success"
                            cancelBtnText="Cancelar"
                            cancelBtnBsStyle="danger"
                            closeOnClickOutside={false}
                            style={{
                                width: width && width < 851 ? "100%" : "65%",
                            }}
                            onConfirm={() => checkUploadCita()}
                            onCancel={() => {
                                setUISweetAlert({
                                    ...sweetAlertDefault,
                                });
                                setCitaSeleccionada({
                                    start: formatDate(new Date(), true),
                                    end: formatDate(new Date(new Date().getTime() + tiempoCita), true),
                                });
                                setDisabledEditar(false);
                                LimpiarDatos();
                            }}>
                            <div hidden={UISweetAlert.editar ? false : true}>
                                <Row>
                                    <Col sm={11} style={{textAlign: "right"}}>
                                        <button
                                            hidden={!autorizado}
                                            onClick={confirmEliminarCita}
                                            type="button"
                                            className="btn btn-primary-blue-umo w-md waves-effect waves-light">
                                            Eliminar Cita
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className="form-group mt-2">
                                    <label className="col-sm-4 col-form-label">
                                        Espacio/Médico<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={8}>
                                        <select
                                            className="form-control"
                                            autoComplete="off" 
                                            disabled={!autorizado}
                                            name="espacio"
                                            onChange={onChangeNuevaCita}
                                            value={citaSeleccionada.espacio}>
                                            <option selected disabled value={""}>
                                                Seleccione una opción
                                            </option>
                                            {
                                                esAsistente || existenFiltros?(
                                                    espaciosFiltrados.map((espacio) => {
                                                        return (
                                                            <>
                                                                <option
                                                                    key={espacio.idEspacio}
                                                                    disabled={false}
                                                                    hidden={usuario.idCatMedico && usuario.idCatMedico != espacio.idCatMedico 
                                                                        || espacio.idCatTipoEspacio == 4}
                                                                    value={espacio.idEspacio}>
                                                                    {espacio.espacio}
                                                                </option>
                                                            </>
                                                        );  
                                                    })
                                                )
                                                : (
                                                    espaciosAtencionAgenda.map((espacio) => {
                                                        return (
                                                            <>
                                                                {(esMedicoNoAdministrador && usuario.idCatMedico == espacio.idCatMedico) ||
                                                                !esMedicoNoAdministrador ? (
                                                                    <option
                                                                        key={espacio.idEspacio}
                                                                        disabled={esMedicoNoAdministrador && usuario.idCatMedico != espacio.idCatMedico 
                                                                            || espacio.idCatTipoEspacio == 4 }
                                                                        hidden={usuario.idCatMedico && usuario.idCatMedico != espacio.idCatMedico 
                                                                            || espacio.idCatTipoEspacio == 4}
                                                                        value={espacio.idEspacio}>
                                                                        {espacio.espacio}
                                                                    </option>
                                                                ) : null}
                                                            </>
                                                        );
                                                    })
                                                )
                                            }
                                        </select>
                                        {(citaSeleccionada.espacio === "" || !citaSeleccionada.espacio) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                {citaSeleccionada && citaSeleccionada.tipoEspacio != 1 ? 
                                (
                                <Row className="form-group mt-3">
                                        <label className="col-sm-2 col-form-label">
                                            Título<span className="text-xs text-red-700">*</span>
                                        </label>
                                    <Col sm={10}>
                                       {UISweetAlert && citaSeleccionada.tipoEspacio != 2 ? (
                                                <input
                                                    type="text"
                                                    autoComplete="off" 
                                                    disabled={true}
                                                    className="form-control"
                                                    name="title"
                                                    onChange={onChangeNuevaCita}
                                                    // value={UISweetAlert.editar ? citaSeleccionada.description : citaSeleccionada.title}
                                                    value={tituloFormatear()}
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    autoComplete="off" 
                                                    disabled={!autorizado}
                                                    className="form-control"
                                                    name="title"
                                                    onChange={onChangeNuevaCita}
                                                    // value={UISweetAlert.editar ? citaSeleccionada.description : citaSeleccionada.title}
                                                    value={citaSeleccionada.title}
                                                />
                                            )}
                                            {(citaSeleccionada.title === "" || !citaSeleccionada.title) && citaSeleccionada.tipoEspacio == 2 && (
                                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                            )}
                                    </Col>
                                </Row>
                                ) : null}
                                <Row>

                                </Row>
                                {citaSeleccionada.todoDia ? 
                                (
                                    <Row className="form-group mt-2">
                                        <label className="col-sm-4 col-form-label"> 
                                            Fecha
                                        </label>
                                        <Col sm={3}>
                                            <input
                                                type="date"
                                                autoComplete="off" 
                                                disabled={!autorizado}
                                                className="form-control"
                                                name="start"
                                                onChange={onChangeNuevaCita}
                                                value={citaSeleccionada.start ? formatDate(citaSeleccionada.start, false, citaSeleccionada.todoDia) : ""}
                                            />
                                            {/* <MuiPickersUtilsProvider disabled={!autorizado} locale="es" utils={DayjsUtils} className="form-control">
                                                <DateTimePicker
                                                fullWidth
                                                // format="dd/MM/yyyy"
                                                // label="Date of birth"
                                                disabled={!autorizado}
                                                value={citaSeleccionada.start ? citaSeleccionada.start : ""}
                                                onChange={changeFechaInicalNuevaCita}
                                                />
                                            </MuiPickersUtilsProvider> */}
                                        </Col>
                                        <Col sm={3}>
                                        <FormControlLabel
                                                disabled={!autorizado}
                                                control={
                                                    <Switch
                                                        defaultChecked={false}
                                                        onChange={onChangeNuevaCita}
                                                        checked={citaSeleccionada.todoDia ? citaSeleccionada.todoDia : false}
                                                        color="primary"
                                                        name="todoDia"
                                                    />
                                                }
                                                label="Todo el Día"
                                            /> 
                                        </Col>                                       
                                    </Row>
                                ) : (
                                    <>
                                        {/******************  ESPACIO PARA CONSULTORIO ********************** */}
                                        {UISweetAlert.editar  && citaSeleccionada.tipoEspacio == 1 ? 
                                        (
                                        <Row className="form-group mt-2" >
                                            <label className="col-sm-4 col-form-label">
                                            Consultorio
                                            </label>                                     
                                            <Col  sm={4}>
                                                    <input
                                                        disabled={true}
                                                        autoComplete="off" 
                                                        className="form-control"
                                                        name="consultorio"
                                                        rows={8}
                                                        // value={UISweetAlert.editar ? citaSeleccionada.description : citaSeleccionada.title}
                                                        value={citaSeleccionada.consultorio}
                                                    />  
                                            </Col>
                                        </Row>
                                        ):null}

                                        <Row >
                                        <Col  sm={10}>
                                            <label className="col-sm-2 col-form-label">
                                                Fecha Inicio<span className="">*</span>
                                            </label>
                                                {/* <input type={} /> */}
                                                <input
                                                    type="datetime-local"
                                                    autoComplete="off" 
                                                    className=""
                                                    value={citaSeleccionada.start ? citaSeleccionada.start : ""}
                                                    disabled={!autorizado}
                                                    onChange={(e) => changeFechaInicalNuevaCita(e)}
                                                />
                                                {/* <MuiPickersUtilsProvider disabled={!autorizado} locale="es" utils={DayjsUtils} className="form-control">
                                                <DateTimePicker
                                                    fullWidth
                                                    disabled={!autorizado}
                                                    value={citaSeleccionada.start ? citaSeleccionada.start : ""}
                                                    onChange={changeFechaInicalNuevaCita}
                                                />
                                                </MuiPickersUtilsProvider> */}

                                            <label className="col-sm-2 col-form-label">
                                                Fecha Final<span className="text-xs text-red-700">*</span>
                                            </label>

                                                <input
                                                    type="datetime-local"
                                                    autoComplete="off" 
                                                    className=""
                                                    value={citaSeleccionada.end ? citaSeleccionada.end : ""}
                                                    disabled={!autorizado}
                                                    onChange={(e) => changeFechaFinalNuevaCita(e)}
                                                />
                                                {/* <MuiPickersUtilsProvider disabled={!autorizado} locale="es" utils={DayjsUtils} className="form-control">
                                                    <DateTimePicker
                                                        fullWidth
                                                        disabled={!autorizado}
                                                        value={citaSeleccionada.end ? citaSeleccionada.end : ""}
                                                        onChange={changeFechaFinalNuevaCita}
                                                    />
                                                    </MuiPickersUtilsProvider> */}
                                            </Col>
                                            <FormControlLabel
                                                disabled={!autorizado}
                                                control={
                                                    <Switch
                                                        defaultChecked={false}
                                                        onChange={onChangeNuevaCita}
                                                        checked={citaSeleccionada.todoDia ? citaSeleccionada.todoDia : false}
                                                        color="primary"
                                                        name="todoDia"
                                                    />
                                                }
                                                label="Todo el Día"
                                            />

                                        </Row>
                                    </>
                                )}

                                <Row className="form-group mt-2">
                                    <label className="col-sm-4 col-form-label">
                                        Tipo de Cita<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={8}>
                                        <select
                                            disabled={!autorizado}
                                            autoComplete="off" 
                                            className="form-control"
                                            name="idCatTipoCita"
                                            onChange={onChangeNuevaCita}
                                            value={citaSeleccionada.idCatTipoCita}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {tipoCitas &&
                                                tipoCitas.map((tipoCita) => {
                                                    return (
                                                        <>
                                                            {  
                                                                !esAsistente ?
                                                                (
                                                                    esMedicoNoAdministrador ? 
                                                                    (
                                                                        tipoCita.medico ? (
                                                                            <option key={tipoCita.idCatTipoCita} value={tipoCita.idCatTipoCita}>
                                                                                {tipoCita.tipoCita}
                                                                            </option>
                                                                        ) : null
                                                                    ) : 
                                                                    (
                                                                        <option key={tipoCita.idCatTipoCita} value={tipoCita.idCatTipoCita}>
                                                                            {tipoCita.tipoCita}
                                                                        </option>
                                                                )
                                                                ) : 
                                                                (
                                                                    tipoCita.asistente ?  (
                                                                        <option key={tipoCita.idCatTipoCita} value={tipoCita.idCatTipoCita}>
                                                                            {tipoCita.tipoCita}
                                                                        </option>
                                                                    ) : null
                                                                )
                                                            }
                                                        </>
                                                    );
                                                })}
                                        </select>
                                        {(citaSeleccionada.idCatTipoCita === "" || !citaSeleccionada.idCatTipoCita) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                {citaSeleccionada.tipoEspacio && citaSeleccionada.tipoEspacio == 3 ? (
                                    <Row className="form-group mt-2">
                                        <label className="col-sm-4 col-form-label">
                                            Médico<span className="text-xs text-red-700">*</span>
                                        </label>
                                        <Col sm={8}>
                                            <select
                                                disabled={!autorizado}
                                                autoComplete="off" 
                                                className="form-control"
                                                name="idCatMedico"
                                                onChange={onChangeNuevaCita}
                                                value={citaSeleccionada.idCatMedico}>
                                                <option selected disabled value="">
                                                    Seleccione una opción
                                                </option>
                                                {medicos &&
                                                    medicos.map((medico) => {
                                                        return (
                                                            <>
                                                                <option key={medico.idCatMedico} value={medico.idCatMedico}>{`${
                                                                    medico.prefijo ? medico.prefijo : ""
                                                                } ${medico.catPersona.nombres} ${medico.catPersona.apellidoPaterno} ${
                                                                    medico.catPersona.apellidoMaterno
                                                                }`}</option>
                                                            </>
                                                        );
                                                    })}
                                            </select>
                                        </Col>
                                    </Row>
                                ) : null}

                                <Row className="form-group mt-2" hidden={!citaSeleccionada.tipoEspacio || citaSeleccionada.tipoEspacio == 2}>
                                    <label className="col-sm-4 col-form-label">
                                        Paciente<span className="text-xs text-red-700">{citaSeleccionada.idCatTipoCita != 11 ? "*" : ""}</span>
                                    </label>

                                    <Col sm={8}>
                                    {!citaSeleccionada.nombrePaciente? (
                                        <SelectPacientesPaginado
                                            opcionSeleccionadaCompleta={pacienteDeCitaSeleccionada}
                                            onChangeOptions={changePacienteNuevaCita}
                                            fetchOptions={loadPacientesSelect}
                                            opcionesPaginado={opcionesSelectPacientes.opcionesPaginado}
                                            placeholderText={opcionesSelectPacientes.placeholderText}
                                            optionLabel={opcionesSelectPacientes.optionLabel}
                                            optionValue={opcionesSelectPacientes.optionValue}
                                            isDisabled={!autorizado}
                                            isMulti={false}
                                            hidden = {citaSeleccionada.nombrePaciente? true:false}
                                            // opcionesExtras={{ menuPortalTarget: document.body, menuPosition: "fixed" }}
                                        />
                                    ):null}
                                        <input
                                            name="porAseguradora"
                                            autoComplete="off" 
                                            className="form-control-disabled"
                                            disabled={true}
                                            value={citaSeleccionada.nombrePaciente}
                                            hidden = {citaSeleccionada.nombrePaciente? false:true}
                                        />

                                        {/* <WindowedSelect
                                        isDisabled={!autorizado}
                                        isSearchable
                                        options={pacientes}
                                        getOptionLabel={(option) => `${option.nombres} ${option.apellidoPaterno} ${option.apellidoMaterno}`}
                                        getOptionValue={(option) => option.idCatPaciente}
                                        // styles={colourStyles}
                                        isClearable
                                        isMulti={false}
                                        // value={pacientes.filter((paciente) => paciente.idCatPaciente == citaSeleccionada.paciente)}
                                        value={pacienteDeCitaSeleccionada}
                                        menuPortalTarget={document.body}
                                        menuPosition={"fixed"}
                                        onChange={(option) => changePacienteNuevaCita(option)}
                                        noOptionsMessage={() => "No hay opciones"}
                                        placeholder="Seleccione un paciente"
                                        /> */}
                                        {citaSeleccionada.idCatTipoCita != 11
                                            ? (citaSeleccionada.paciente === "" || !citaSeleccionada.paciente) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )
                                            : ""}
                                    </Col>
                                    
                                    
                                </Row>
                                <Row className="form-group mt-1" hidden={!citaSeleccionada.paciente ? true : false}>
                                    <Col sm={4} style={{textAlign:"left"}}>
                                        
                                    </Col>                                      
                                    <Col sm={5} style={{textAlign:"left"}}>
                                        <label width={200} >{comentarioPaciente}</label>
                                    </Col>   
                                    <Col sm={2} style={{textAlign:"right"}}>
                                        <button
                                            type="button"
                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                            hidden={
                                                usuario.authorities.includes("OP_CATALOGO_PACIENTE_CREAR") ||
                                                usuario.authorities.includes("OP_CATALOGO_PACIENTE_MODIFICAR") ||
                                                usuario.authorities.includes("ROLE_ASEGURADORAS") 
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => expedienteMedicoPaciente()}>
                                            Expediente
                                        </button>

                                    </Col>
                                </Row>

                                {citaSeleccionada.idCatTipoCita &&
                                (citaSeleccionada.paciente == null || citaSeleccionada.paciente == "") &&
                                citaSeleccionada.tipoEspacio &&
                                citaSeleccionada.idCatTipoCita == 11 &&
                                citaSeleccionada.tipoEspacio == 1 &&
                                !UISweetAlert.editar ? (
                                    <>
                                        <Row >
                                            <label className="col-sm-4 col-form-label">
                                                Nombre del Paciente<span className="text-xs text-red-700">*</span>
                                            </label>
                                            <Col sm={4}>
                                                <input
                                                    disabled={!autorizado}
                                                    autoComplete="off" 
                                                    type="text"
                                                    placeholder="Escriba el nombre del paciente si no se encuentra en la lista de arriba"
                                                    className="form-control"
                                                    name="nombrePaciente"
                                                    onChange={onChangeDatosPaciente}
                                                    value={datosPaciente.nombrePaciente ? datosPaciente.nombrePaciente : ""}
                                                />
                                                {(datosPaciente.nombrePaciente === "" || !datosPaciente.nombrePaciente) && (
                                                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                                )}
                                            </Col>
                                            <label className="col-sm-2 col-form-label">
                                                Teléfono<span className="text-xs text-red-700">*</span>
                                            </label>
                                            <Col sm={2}>
                                                <input
                                                    type="text"
                                                    autoComplete="off" 
                                                    disabled={!autorizado}
                                                    className="form-control"
                                                    name="telefonoPaciente"
                                                    onChange={onChangeDatosPaciente}
                                                    value={datosPaciente.telefonoPaciente ? datosPaciente.telefonoPaciente : ""}
                                                />
                                                {(datosPaciente.telefonoPaciente === "" || !datosPaciente.telefonoPaciente) && (
                                                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                ) : null}
                                <Row className="form-group mt-2">
                                    <label className="col-sm-4 col-form-label">
                                        Comentarios Recepción<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={8}>
                                        <textarea
                                            autoComplete="off" 
                                            disabled={
                                                !autorizado && 
                                                !(
                                                    esAsistente &&
                                                    !tienePermisoAseguradoras &&
                                                    pacienteDeCitaSeleccionada &&
                                                    pacienteDeCitaSeleccionada.expedienteMedico &&
                                                    (pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora ||
                                                        pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora == "")
                                                )
                                            }    
                                            rows={3}
                                            className={
                                                !autorizado && 
                                                !(
                                                    esAsistente &&
                                                    !tienePermisoAseguradoras &&
                                                    pacienteDeCitaSeleccionada &&
                                                    pacienteDeCitaSeleccionada.expedienteMedico &&
                                                    (pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora ||
                                                        pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora == "")
                                                )?"form-control-disabled": "form-control"
                                            }    
                                            name="comentarioRecepcion"
                                            onChange={onChangeNuevaCita}
                                            value={citaSeleccionada.comentarioRecepcion}
                                        />
                                        {(citaSeleccionada.comentarioRecepcion === "" || !citaSeleccionada.comentarioRecepcion) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group mt-2">
                                    <label className="col-sm-4 col-form-label">
                                        Comentarios Aseguradora<span className="text-xs text-red-700">*</span>
                                    </label> 
                                    <Col sm={8}>
                                        <textarea maxLength="500"
                                            autoComplete="off" 
                                            disabled={
                                                esAsistente || esMedico ? true:(
                                                !autorizado && 
                                                !(
                                                    
                                                    tienePermisoAseguradoras &&
                                                    citaSeleccionada.tieneAseguradora
                                                    //pacienteDeCitaSeleccionada &&
                                                    //pacienteDeCitaSeleccionada.expedienteMedico &&
                                                    //(pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora ||
                                                    //    pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora == "")
                                                ))
                                            }
                                            rows={3}    
                                            name="comentarioAseguradora"
                                            onChange={onChangeNuevaCita}
                                            className = {
                                                (esAsistente || esMedico ? true:(
                                                !autorizado && 
                                                !(
                                                    tienePermisoAseguradoras &&
                                                    citaSeleccionada.tieneAseguradora
                                                    //pacienteDeCitaSeleccionada &&
                                                    //pacienteDeCitaSeleccionada.expedienteMedico &&
                                                    //(pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora ||
                                                     //   pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora == "")
                                                )))?"form-control-disabled": "form-control"
                                            }
                                            value={citaSeleccionada.comentarioAseguradora} 
                                        />

                                    </Col>  
                                </Row>   
                                <Row className="form-group mt-2">
                                    <label className="col-sm-4 col-form-label">
                                        Comentarios Farmacia<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={8}>
                                        <textarea maxLength="500"
                                            autoComplete="off" 
                                            disabled={!esFarmacia && !usuario.authorities.includes("ROLE_RECEPCION")}
                                            rows={3}
                                            className={
                                                (!esFarmacia && !usuario.authorities.includes("ROLE_RECEPCION"))?"form-control-disabled": "form-control"
                                            }
                                            name="comentarioFarmacia"
                                            onChange={onChangeNuevaCita}
                                            value={citaSeleccionada.comentarioFarmacia}
                                        />
                                    </Col>
                                </Row>       
                                <Row className="form-group mt-2">
                                    <label className="col-sm-4 col-form-label">
                                        Comentarios Tratamiento<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={8}>
                                        <textarea maxLength="500"
                                            autoComplete="off" 
                                            disabled={
                                                esAsistente || esMedico || esFarmacia? true:(
                                                !autorizado &&
                                                !(
                                                    !tienePermisoAseguradoras &&
                                                    pacienteDeCitaSeleccionada &&
                                                    pacienteDeCitaSeleccionada.expedienteMedico &&
                                                    (pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora ||
                                                        pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora == "")
                                                ) )
                                            }
                                            rows={3}
                                            className={
                                                (esAsistente || esMedico || esFarmacia ? true:(
                                                !autorizado &&  
                                                !(  
                                                    !tienePermisoAseguradoras &&
                                                    pacienteDeCitaSeleccionada &&
                                                    pacienteDeCitaSeleccionada.expedienteMedico &&
                                                    (pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora ||
                                                        pacienteDeCitaSeleccionada.expedienteMedico.idCatAseguradora == "")
                                                ) ))?"form-control-disabled": "form-control"
                                            }
                                            name="comentarioTratamiento"
                                            onChange={onChangeNuevaCita}
                                            value={citaSeleccionada.comentarioTratamiento}
                                        />
                                    </Col>
                                </Row>                      
                            </div> 
                        </SweetAlert>
                    ) : null}

                    {UISweetAlert.confirm_alert_eliminar_cita ? (
                        <SweetAlert
                            title="¿Desea borrar la cita?"
                            warning
                            showCancel
                            confirmBtnText="Continuar"
                            confirmBtnBsStyle="success"
                            cancelBtnText="Cancelar"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => borrarCita()}
                            onCancel={() => {
                                setUISweetAlert({
                                    ...sweetAlertDefault,
                                });
                                setCitaSeleccionada({
                                    start: formatDate(new Date(), true),
                                    end: formatDate(new Date(new Date().getTime() + tiempoCita), true),
                                });
                                setLoading(false);
                                LimpiarDatos();
                            }}>
                            Se borrará la cita
                        </SweetAlert>
                    ) : null}
                    
                    {confirmaSobreEscribirCita ? (
                        <SweetAlert
                            title="¿Desea sobrescribir la cita?"
                            warning
                            showCancel
                            confirmBtnText="Continuar"
                            confirmBtnBsStyle="success"
                            cancelBtnText="Cancelar"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                setIgnorarBack(true)
                                checkUploadCita();
                                }
                                      }
                            onCancel={() => {
                                setConfirmaSobreEscribirCita(false)
                            }}>
                            {mensajeSobreEscribirCita}
                        </SweetAlert>
                    ) : null}                    
                    
                    {UISweetAlert.confirm_imprimir_agenda ? (
                        <SweetAlert
                            title={
                                checkFechaFinalImpresion()
                                    ? "Por favor, seleccione el rango de fechas a imprimir"
                                    : "Por favor, seleccione el día a imprimir"
                            }
                            showCancel
                            confirmBtnText="Imprimir"
                            confirmBtnBsStyle="success"
                            cancelBtnText="Cancelar"
                            cancelBtnBsStyle="danger"
                            closeOnClickOutside={false}
                            style={{
                                width: width && width < 851 ? "100%" : "70%",
                            }}
                            onConfirm={() => confirmImprimirAgenda()}
                            onCancel={() => {
                                setUISweetAlert({
                                    ...sweetAlertDefault,
                                });
                            }}>
                            <Row className="form-group mt-6">
                                <label className={checkFechaFinalImpresion() ? "col-sm-2 col-form-label" : "col-sm-4 col-form-label"}>
                                    {checkFechaFinalImpresion() ? "Fecha Inicial" : "Fecha"}
                                    <span className="text-xs text-red-700">*</span>
                                </label>
                                <Col sm={checkFechaFinalImpresion() ? 4 : 7}>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="fechaImpresion"
                                        onChange={onChangeFiltroImpresion}
                                        value={filtrosImpresion.fechaImpresion ? filtrosImpresion.fechaImpresion : ""}
                                    />
                                    {(filtrosImpresion.fechaImpresion == "" || !filtrosImpresion.fechaImpresion) && (
                                        <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                    )}
                                </Col>
                                {checkFechaFinalImpresion() ? (
                                    <>
                                        <label className="col-sm-2 col-form-label">
                                            Fecha Final<span className="text-xs text-red-700">*</span>
                                        </label>
                                        <Col sm={4}>
                                            <input
                                                type="date"
                                                autoComplete="off" 
                                                min={filtrosImpresion.fechaImpresion ? filtrosImpresion.fechaImpresion : ""}
                                                className="form-control"
                                                name="fechaImpresionFinal"
                                                onChange={onChangeFiltroImpresion}
                                                value={filtrosImpresion.fechaImpresionFinal ? filtrosImpresion.fechaImpresionFinal : ""}
                                            />
                                            {/* {(filtrosImpresion.fechaImpresion == "" || !filtrosImpresion.fechaImpresion) && (
                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                  )} */}
                                        </Col>
                                    </>
                                ) : null}
                            </Row>
                            <Row className="form-group mt-6">
                                {!esMedicoNoAdministrador || esMedicoConRoleAgenda ? (
                                    <>
                                        <label className="col-sm-3 col-form-label">Agenda de todo el día</label>
                                        <Col sm={1}>
                                            <input
                                                type="checkbox"
                                                autoComplete="off" 
                                                className="form-control"
                                                name="porDia"
                                                onChange={onChangeFiltroImpresion}
                                                checked={filtrosImpresion.porDia}
                                                value={filtrosImpresion.porDia}
                                            />
                                        </Col>
                                    </>
                                ) : null}
                                {/* Tambien checar si hay algun espacio seleccionado de tipo médico */}

                                {usuario &&
                                (usuario.authorities.includes("ROLE_RECEPCION") ||
                                    usuario.authorities.includes("ROLE_AGENDA") ||
                                    usuario.authorities.includes("ROLE_ADMIN_SISTEMA")) &&
                                checkIncluyeEspacioMedico() ? (
                                    <>
                                        <label className="col-sm-2 col-form-label">Incluir citas</label>
                                        <Col sm={1}>
                                            <input
                                                type="checkbox"
                                                autoComplete="off" 
                                                className="form-control"
                                                name="incluirCitas"
                                                onChange={onChangeFiltroImpresion}
                                                checked={filtrosImpresion.incluirCitas}
                                                // value={filtrosImpresion.incluirCitas}
                                            />
                                        </Col>
                                        <label className="col-sm-2 col-form-label">Incluir tratamientos</label>
                                        <Col sm={1}>
                                            <input
                                                type="checkbox"
                                                autoComplete="off" 
                                                className="form-control"
                                                name="incluirTratamientos"
                                                onChange={onChangeFiltroImpresion}
                                                checked={filtrosImpresion.incluirTratamientos}
                                                // value={filtrosImpresion.incluirTratamientos}
                                            />
                                        </Col>
                                    </>
                                ) : null}
                                {}
                            </Row>
                        </SweetAlert>
                    ) : null}

                    {UISweetAlert.confirm_exponer_agendaGeneral ? (
                        <SweetAlert
                            title="Por favor, seleccione el día a visualizar en sus datos generales"
                            showCancel
                            confirmBtnText="Visualizar"
                            confirmBtnBsStyle="success"
                            cancelBtnText="Cancelar"
                            cancelBtnBsStyle="danger"
                            closeOnClickOutside={false}
                            style={{
                                width: width && width < 851 ? "100%" : "70%",
                            }}
                            onConfirm={() => visualizarCitasGeneral()}
                            onCancel={() => {
                                setUISweetAlert({
                                    ...sweetAlertDefault,
                                });
                            }}>
                            <Row className="form-group mt-6">
                                <label className={checkFechaFinalImpresion() ? "col-sm-2 col-form-label" : "col-sm-4 col-form-label"}>
                                    {checkFechaFinalImpresion() ? "Fecha Inicial" : "Fecha"}
                                    <span className="text-xs text-red-700">*</span>
                                </label>
                                <Col sm={checkFechaFinalImpresion() ? 4 : 7}>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="fechaImpresion"
                                        onChange={onChangeFiltroImpresion}
                                        value={filtrosImpresion.fechaImpresion ? filtrosImpresion.fechaImpresion : ""}
                                    />
                                    {(filtrosImpresion.fechaImpresion == "" || !filtrosImpresion.fechaImpresion) && (
                                        <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                    )}
                                </Col>
                                {checkFechaFinalImpresion() ? (
                                    <>
                                        <label className="col-sm-2 col-form-label">
                                            Fecha Final<span className="text-xs text-red-700">*</span>
                                        </label>
                                        <Col sm={4}>
                                            <input
                                                type="date"
                                                autoComplete="off" 
                                                min={filtrosImpresion.fechaImpresion ? filtrosImpresion.fechaImpresion : ""}
                                                className="form-control"
                                                name="fechaImpresionFinal"
                                                onChange={onChangeFiltroImpresion}
                                                value={filtrosImpresion.fechaImpresionFinal ? filtrosImpresion.fechaImpresionFinal : ""}
                                            />
                                            {/* {(filtrosImpresion.fechaImpresion == "" || !filtrosImpresion.fechaImpresion) && (
                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                  )} */}
                                        </Col>
                                    </>
                                ) : null}
                            </Row>
                            <Row className="form-group mt-6">
                                {!esMedicoNoAdministrador || esMedicoConRoleAgenda ? (
                                    <>
                                        <label hidden = {true} className="col-sm-3 col-form-label">Agenda de todo el día</label>
                                        <Col sm={1}>
                                            <input
                                                type="checkbox"
                                                autoComplete="off" 
                                                className="form-control"
                                                name="porDia"
                                                hidden = {true}
                                                onChange={onChangeFiltroImpresion}
                                                checked={filtrosImpresion.porDia}
                                                value={filtrosImpresion.porDia}
                                            />
                                        </Col>
                                    </>
                                ) : null}
                                {/* Tambien checar si hay algun espacio seleccionado de tipo médico */}

                                {usuario &&
                                (usuario.authorities.includes("ROLE_RECEPCION") ||
                                    usuario.authorities.includes("ROLE_AGENDA") ||
                                    usuario.authorities.includes("ROLE_ADMIN_SISTEMA")) &&
                                checkIncluyeEspacioMedico() ? (
                                    <>
                                        <label hidden = {true} className="col-sm-2 col-form-label">Incluir citas</label>
                                        <Col sm={1}>
                                            <input
                                                type="checkbox"
                                                autoComplete="off" 
                                                className="form-control"
                                                hidden = {true}
                                                name="incluirCitas"
                                                onChange={onChangeFiltroImpresion}
                                                checked={filtrosImpresion.incluirCitas}
                                                // value={filtrosImpresion.incluirCitas}
                                            />
                                        </Col>
                                        <label hidden = {true} className="col-sm-2 col-form-label">Incluir tratamientos</label>
                                        <Col sm={1}>
                                            <input
                                                type="checkbox"
                                                autoComplete="off" 
                                                hidden = {true}
                                                className="form-control"
                                                name="incluirTratamientos"
                                                onChange={onChangeFiltroImpresion}
                                                checked={filtrosImpresion.incluirTratamientos}
                                                // value={filtrosImpresion.incluirTratamientos}
                                            />
                                        </Col>
                                    </>
                                ) : null}
                                {}
                            </Row>
                        </SweetAlert>
                    ) : null}                       

                    {UISweetAlert.confirm_exponer_agenda ? (
                        <SweetAlert
                            title="Por favor, seleccione el día a visualizar"
                            showCancel
                            confirmBtnText="Visualizar"
                            confirmBtnBsStyle="success"
                            cancelBtnText="Cancelar"
                            cancelBtnBsStyle="danger"
                            closeOnClickOutside={false}
                            style={{
                                width: width && width < 851 ? "100%" : "70%",
                            }}
                            onConfirm={() => visualizarCitas()}
                            onCancel={() => {
                                setUISweetAlert({
                                    ...sweetAlertDefault,
                                });
                            }}>
                            <Row className="form-group mt-6">
                                <label className={checkFechaFinalImpresion() ? "col-sm-2 col-form-label" : "col-sm-4 col-form-label"}>
                                    {checkFechaFinalImpresion() ? "Fecha Inicial" : "Fecha"}
                                    <span className="text-xs text-red-700">*</span>
                                </label>
                                <Col sm={checkFechaFinalImpresion() ? 4 : 7}>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="fechaImpresion"
                                        onChange={onChangeFiltroImpresion}
                                        value={filtrosImpresion.fechaImpresion ? filtrosImpresion.fechaImpresion : ""}
                                    />
                                    {(filtrosImpresion.fechaImpresion == "" || !filtrosImpresion.fechaImpresion) && (
                                        <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                    )}
                                </Col>
                                {checkFechaFinalImpresion() ? (
                                    <>
                                        <label className="col-sm-2 col-form-label">
                                            Fecha Final<span className="text-xs text-red-700">*</span>
                                        </label>
                                        <Col sm={4}>
                                            <input
                                                type="date"
                                                autoComplete="off" 
                                                min={filtrosImpresion.fechaImpresion ? filtrosImpresion.fechaImpresion : ""}
                                                className="form-control"
                                                name="fechaImpresionFinal"
                                                onChange={onChangeFiltroImpresion}
                                                value={filtrosImpresion.fechaImpresionFinal ? filtrosImpresion.fechaImpresionFinal : ""}
                                            />
                                            {/* {(filtrosImpresion.fechaImpresion == "" || !filtrosImpresion.fechaImpresion) && (
                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                  )} */}
                                        </Col>
                                    </>
                                ) : null}
                            </Row>
                            <Row className="form-group mt-6">
                                {!esMedicoNoAdministrador || esMedicoConRoleAgenda ? (
                                    <>
                                        <label hidden = {true} className="col-sm-3 col-form-label">Agenda de todo el día</label>
                                        <Col sm={1}>
                                            <input
                                                type="checkbox"
                                                autoComplete="off" 
                                                className="form-control"
                                                name="porDia"
                                                hidden = {true}
                                                onChange={onChangeFiltroImpresion}
                                                checked={filtrosImpresion.porDia}
                                                value={filtrosImpresion.porDia}
                                            />
                                        </Col>
                                    </>
                                ) : null}
                                {/* Tambien checar si hay algun espacio seleccionado de tipo médico */}

                                {usuario &&
                                (usuario.authorities.includes("ROLE_RECEPCION") ||
                                    usuario.authorities.includes("ROLE_AGENDA") ||
                                    usuario.authorities.includes("ROLE_ADMIN_SISTEMA")) &&
                                checkIncluyeEspacioMedico() ? (
                                    <>
                                        <label hidden = {true} className="col-sm-2 col-form-label">Incluir citas</label>
                                        <Col sm={1}>
                                            <input
                                                type="checkbox"
                                                autoComplete="off" 
                                                className="form-control"
                                                hidden = {true}
                                                name="incluirCitas"
                                                onChange={onChangeFiltroImpresion}
                                                checked={filtrosImpresion.incluirCitas}
                                                // value={filtrosImpresion.incluirCitas}
                                            />
                                        </Col>
                                        <label hidden = {true} className="col-sm-2 col-form-label">Incluir tratamientos</label>
                                        <Col sm={1}>
                                            <input
                                                type="checkbox"
                                                autoComplete="off" 
                                                hidden = {true}
                                                className="form-control"
                                                name="incluirTratamientos"
                                                onChange={onChangeFiltroImpresion}
                                                checked={filtrosImpresion.incluirTratamientos}
                                                // value={filtrosImpresion.incluirTratamientos}
                                            />
                                        </Col>
                                    </>
                                ) : null}
                                {}
                            </Row>
                        </SweetAlert>
                    ) : null}                    

                    {UISweetAlert.confirm_historico_paciente ? (
                        <SweetAlert
                            title="Por favor, seleccione un paciente para ver su historial"
                            confirmBtnText="Cerrar"
                            confirmBtnBsStyle="danger"
                            style={{
                                width: width && width < 851 ? "100%" : "65%",
                                height: "60%",
                            }}
                            onConfirm={() => {
                                setUISweetAlert({
                                    ...sweetAlertDefault,
                                });
                                setPacienteSeleccionadoHistorico(null);
                                setCitasPaciente([]);
                            }}> 
                            <Row >
                                <label className="col-sm-2 col-form-label">
                                    Paciente<span className="text-xs text-red-700">*</span>
                                </label>
                                <Col sm={6}>
                                    <SelectPacientesPaginado
                                        opcionSeleccionadaCompleta={pacienteSeleccionadoHistorico}
                                        onChangeOptions={changePacienteHistorico}
                                        fetchOptions={loadPacientesSelect}
                                        opcionesPaginado={opcionesSelectPacientes.opcionesPaginado}
                                        placeholderText={opcionesSelectPacientes.placeholderText}
                                        optionLabel={opcionesSelectPacientes.optionLabel}
                                        optionValue={opcionesSelectPacientes.optionValue}
                                        isDisabled={!autorizadoHistorico}
                                        width="60%"
                                        isMulti={false}
                                        // opcionesExtras={{ menuPortalTarget: document.body, menuPosition: "fixed" }}
                                    />
                                </Col>
                                <Col sm={3}>
                                 
                                    {pacienteSeleccionadoHistorico && citasPaciente && citasPaciente.length > 0 ? (
                                        <DrawerHistoricoCitas citasPaciente={citasPaciente} paciente={pacienteSeleccionadoHistorico}/>
                                    ) : (
                                        "El paciente seleccionado no tiene citas"
                                    )}      
                                 </Col>                                
                                    {/* <WindowedSelect
                    isSearchable
                    options={pacientes}
                    getOptionLabel={(option) => `${option.nombres} ${option.apellidoPaterno} ${option.apellidoMaterno}`}
                    getOptionValue={(option) => option}
                    // styles={colourStyles}
                    isClearable
                    value={pacientes.filter((paciente) =>
                      pacienteSeleccionadoHistorico ? paciente.idCatPaciente == pacienteSeleccionadoHistorico.idCatPaciente : ""
                    )}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    onChange={(option) => changePacienteHistorico(option)}
                    noOptionsMessage={() => "No hay opciones"}
                    placeholder="Seleccione un paciente"
                  /> */}

                                
                            </Row>


                        </SweetAlert>
                    ) : null}

                    {UISweetAlert.confirm_alert_cita_no_autorizado ? (
                        <SweetAlert
                            error
                            title={UISweetAlert.dynamic_title}
                            onConfirm={() =>
                                setUISweetAlert({
                                    ...sweetAlertDefault,
                                })
                            }>
                            No tienes los permisos necesarios para crear una cita.
                        </SweetAlert>
                    ) : null}

                    {UISweetAlert.success_dlg ? (
                        <SweetAlert
                            success
                            title={UISweetAlert.dynamic_title}
                            onConfirm={() => {
                                if (UISweetAlert.redirect) {
                                    props.history.push(UISweetAlert.path);
                                }
                                setUISweetAlert({
                                    ...sweetAlertDefault,
                                });
                            }}>
                            {UISweetAlert.dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {UISweetAlert.error_dlg ? (
                        <SweetAlert
                            error
                            title={UISweetAlert.dynamic_title}
                            onConfirm={() =>
                                setUISweetAlert({
                                    ...sweetAlertDefault,
                                    confirm_alert_cita: UISweetAlert.confirm_alert_cita,
                                })
                            }>
                            {UISweetAlert.dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {loading ? (
                        <SweetAlert title="Cargando!" onConfirm={() => {
                        }} showConfirm={false}>
                            <Spinner/>
                            {/* {UISweetAlert.dynamic_description ? UISweetAlert.dynamic_description : ""} */}
                        </SweetAlert>
                    ) : null}
                </>
            ) : null}
        </>
    );

    function checkFechaFinalImpresion() {
        return (
            !opcionesExtras.seleccionarTodosEspacios &&
            filtroCitasEspacios &&
            filtroCitasEspacios.length > 0 &&
            !filtrosImpresion.porDia
        );
    }

    function filterPacienteCitaSeleccionada(idCatPaciente) {
        let pacienteCompletoCita = null;

        const posicionPacienteDeCita = pacientes
        .map(function (x) {
            return x.idCatPaciente;
        })
        .indexOf(idCatPaciente);

        if (posicionPacienteDeCita >= 0) {
            pacienteCompletoCita = pacientes[posicionPacienteDeCita];

            setPacienteDeCitaSeleccionada(pacienteCompletoCita);
        } else {
            setPacienteDeCitaSeleccionada(null);
        }
        return pacienteCompletoCita;
    }

    function checkIfMedico() {
        return async () => {
            setLoading(true);
            let espaciosFetched = null;
            espaciosFetched = await listarEspaciosAtencionAgenda(true);
            let espaciosDefault;
            if (espaciosFetched && espaciosFetched.length > 0) {
                espaciosDefault = await seleccionarEspaciosAtencionDefault(espaciosFetched);
            }
            if ((esMedicoNoAdministrador && !esMedicoConRoleAgenda) || esAsistente) {
                /* ACA ENTRA SI ES MEDICO*/
                    const espacios = espaciosDefault && espaciosDefault.length > 0 ? espaciosDefault : [];
                    checkHorarioEspacio(espacios);
                    try {
                        
                        let temporal = false;
                        if(esMedico || esAsistente){
                            temporal = opcionesExtras.seleccionarTodosEspacios;
                            opcionesExtras.seleccionarTodosEspacios =  false;
                        }

                        const res = await listarCitas(
                            fechaActual,
                            espacios,
                            filtroCitasPacientes,
                            filtroCitasAseguradoras,
                            opcionesExtras.seleccionarTodosEspacios,
                            opcionesExtras.seleccionarTodosPacientes,
                            opcionesExtras.porAseguradora
                        );

                        if(esMedico || esAsistente){
                            opcionesExtras.seleccionarTodosEspacios =  temporal;
                        }

                        await delay(delayTime);
                        setLoading(false);
                    } catch (error) {
                        setLoading(false);
                    }
                
            } else if (esMedicoConRoleAgenda && usuario.idCatMedico != null) {
                const espacios = espaciosDefault && espaciosDefault.length > 0 ? espaciosDefault : [];
                checkHorarioEspacio(espacios);
                setFiltroCitasEspacios(espacios);
                try {
                    
                    let temporal = false;
                    if(esMedico || esAsistente){
                        temporal = opcionesExtras.seleccionarTodosEspacios;
                        opcionesExtras.seleccionarTodosEspacios =  false;
                    }

                    const res = await listarCitas(
                        fechaActual,
                        espacios,
                        filtroCitasPacientes,
                        filtroCitasAseguradoras,
                        opcionesExtras.seleccionarTodosEspacios,
                        opcionesExtras.seleccionarTodosPacientes,
                        opcionesExtras.porAseguradora
                    );

                    if(esMedico || esAsistente){
                        opcionesExtras.seleccionarTodosEspacios =  temporal;
                    }

                    await delay(delayTime);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            } else if (filtroCitasEspacios != null) {
                checkHorarioEspacio(filtroCitasEspacios);
                try {
                    let temporal = false;
                    if(esMedico || esAsistente){
                        temporal = opcionesExtras.seleccionarTodosEspacios;
                        opcionesExtras.seleccionarTodosEspacios =  false;
                    }
                    const res = await listarCitas(
                        fechaActual,
                        filtroCitasEspacios, 
                        filtroCitasPacientes,
                        opcionesAseguradoras,
                        opcionesExtras.seleccionarTodosEspacios,
                        opcionesExtras.seleccionarTodosPacientes,
                        opcionesExtras.porAseguradora
                    );

                    if(esMedico || esAsistente){
                        opcionesExtras.seleccionarTodosEspacios =  temporal;
                    }

                    await delay(delayTime);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            }
            setLoading(false);
        }
    }
}
// <FullCalendar
//   // eventContent={renderEventContent}
//   timeZone="America/Mexico_City"
//   plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
//   ref={calendarComponentRef}
//   initialView="timeGridWeek"
//   navLinks={true}
//   nowIndicator={true}
//   progressiveEventRendering={false}
//   dayMaxEventRows={5}
//   eventMaxStack={2}
//   editable={true}
//   eventDrop={cambioHoraCita}
//   eventResize={cambioHoraCita}
//   slotMinTime={horarioMinimoCalendario ? horarioMinimoCalendario : "06:00:00"}
//   slotMaxTime={"23:45:00"}
//   slotDuration={"00:15:00"}
//   allDaySlot={false}
//   weekends={mostrarFinDeSemana}
//   eventSources={[
//     {
//       events: citas,
//       color: "#000000",
//       // color: 'green',
//     },
//   ]}
//   headerToolbar={{
//     left: "prev,next today nuevoEvento nuevoPaciente",
//     center: "title",
//     right: "dayGridMonth,timeGridWeek,timeGridDay",
//   }}
//   buttonText={{
//     today: "Hoy",
//     month: "Mes",
//     week: "Semana",
//     day: "Día",
//   }}
//   customButtons={{
//     nuevoEvento: {
//       text: "Nueva Cita",
//       click: () => {
//         checkPermisosCalendario();
//       },
//     },
//     nuevoPaciente: {
//       text: "Nuevo Paciente",
//       click: () => {
//         nuevoPacienteRedirect();
//       },
//     },
//   }}
//   initialDate={formatDate(new Date(), true)}
//   dateClick={autorizado ? nuevaCitaFechaSeleccionada : null}
//   eventClick={editCitaConfirm}
//   viewDidMount={viewchange}
//   datesSet={(info) => changeFecha(info)}
//   locale="es"
// />;
