import { SET_HOJAS_INDICACION_PAGINADAS } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case SET_HOJAS_INDICACION_PAGINADAS:
      return {
        ...state,
        hojasIndicacion: action.payload,
      };
    default:
      return state;
  }
};
