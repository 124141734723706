import CatTipoCobroState from "./context/CatTipoCobro/CatTipoCobroState";
import CatTipoPagoState from "./context/CatTipoPago/CatTipoPagoState";
import ValesState from "./context/Vales/ValesState";
import ClientesState from "./context/Clientes/ClientesState";
import MedicosState from "./context/Medicos/MedicosState";
import PacientesState from "./context/Pacientes/PacientesState";
import AseguradorasState from "./context/Aseguradoras/AseguradorasState";
import EstatusValeState from "./context/EstatusVale/EstatusValeState";
import UsuariosState from "./context/Usuarios/UsuariosState";
import TipoValeState from "./context/TipoVale/TipoValeState";
import CatProductosState from "./context/CatProductos/CatProductosState";
import CatServiciosState from "./context/CatServicios/CatServiciosState";
import ProductosServiciosState from "./context/ProductosServicios/ProductosServiciosState";
import ConfiguracionState from "./context/Configuracion/ConfiguracionState";
import SidebarState from "./context/Sidebar/SidebarState";
import AuthState from "./context/Auth/AuthState";
import VentaMostradorState from "./context/VentaMostrador/VentaMostradorState";
import CatTipoVentaMostradorState from "./context/CatTipoVentaMostrador/CatTipoVentaMostradorState";
import InventarioState from "./context/Inventario/InventarioState";
import EstatusVentaMostradorState from "./context/EstatusVentaMostrador/EstatusVentaMostradorState";
import SolicitudesComprasState from "./context/SolicitudesCompras/SolicitudesComprasState";
import CatTipoSolicitudCompraState from "./context/CatTipoSolicitudCompra/CatTipoSolicitudCompraState";
import CatTipoCompraState from "./context/CatTipoCompra/CatTipoCompraState";
import CatTipoMedicoState from "./context/CatTipoMedico/CatTipoMedicoState";
import CatTipoOrdenCompraState from "./context/CatTipoOrdenCompra/CatTipoOrdenCompraState";
import EstatusSolicitudCompraState from "./context/EstatusSolicitudCompra/EstatusSolicitudCompraState";
import CatTipoProductoState from "./context/CatTipoProducto/CatTipoProductoState";
import ProveedoresState from "./context/Proveedores/ProveedoresState";
import RecepcionGenericaProductosState from "./context/RecepcionGenericaProductos/RecepcionGenericaProductosState";
import CatRolesState from "./context/CatRoles/CatRolesState";
import RequerimientosCompraPendientesState from "./context/RequerimientosCompraPendientes/RequerimientosCompraPendientesState";
import CatTipoRecepcionState from "./context/CatTipoRecepcion/CatTipoRecepcionState";
import OrdenesCompraState from "./context/OrdenesCompra/OrdenesCompraState";
import EstatusOrdenCompraState from "./context/EstatusOrdenCompra/EstatusOrdenCompraState";
import AlmacenesState from "./context/Almacenes/AlmacenesState";
import ConsultaCompraProductoState from "./context/ConsultaCompraProducto/ConsultaCompraProductoState";
import CatLaboratoriosState from "./context/CatLaboratorios/CatLaboratoriosState";
import ReporteDesplazamientoProductoState from "./context/ReporteDesplazamientoProducto/ReporteDesplazamientoProductoState";
import CatTipoVentaState from "./context/CatTipoVenta/CatTipoVentaState";
import RegistroPagosOrdenCompraState from "./context/RegistroPagosOrdenCompra/RegistroPagosOrdenCompraState";
import PagosMedicoState from "./context/PagosMedico/PagosMedicoState";
import CortesCajaState from "./context/CortesCaja/CortesCajaState";
import EspaciosAtencionState from "./context/EspaciosAtencion/EspaciosAtencionState";
import CalendarioState from "./context/Calendario/CalendarioState";
import TipoEspacioState from "./context/CatTipoEspacio/CatTipoEspacioState";
import LugaresState from "./context/Lugares/LugaresState";
import OcupacionesState from "./context/Ocupaciones/OcupacionesState";
import EscolaridadesState from "./context/Escolaridades/EscolaridadesState";
import EstadosCivilesState from "./context/EstadosCiviles/EstadosCivilesState";
import SexosState from "./context/Sexos/SexosState";
import AnaquelesState from "./context/Anaqueles/AnaquelesState";
import EstantesState from "./context/Estantes/EstantesState";
import UbicacionesState from "./context/Ubicaciones/UbicacionesState";
import CatTipoCorteCajaState from "./context/CatTipoCorteCaja/CatTipoCorteCajaState";
import FabricantesState from "./context/Fabricantes/FabricantesState";
import TrasladoProductoInventarioState from "./context/TrasladoProductoInventario/TrasladoProductoInventarioState";
import CatEstatusTrasladoState from "./context/CatEstatusTraslado/CatEstatusTrasladoState";
import ExpedienteMedicoState from "./context/ExpedienteMedico/ExpedienteMedicoState";
import BajaInventarioState from "./context/BajaInventario/BajaInventarioState";
import CatEstatusBajaInventarioState from "./context/CatEstatusBajaInventario/CatEstatusBajaInventarioState";
import QuimioState from "./context/QuimioContext/QuimioState";
import CentroMezclasState from "./context/CentroMezclas/CentroMezclasState";
import TiposAdministracionState from "./context/TiposAdministracion/TiposAdministracionState";
import ResultadosReaState from "./context/ResultadosRea/ResultadosReaState";
import HistoriaClinicaInicialState from "./context/HistoriaClinicaInicial/HistoriaClinicaInicialState";
import PadecimientoActualState from "./context/PadecimientoActual/PadecimientoActualState";
import CatTipoPrecioState from "./context/CatTipoPrecio/CatTipoPrecioState";
import ConsultaSeguimientoState from "./context/ConsultaSeguimiento/ConsultaSeguimientoState";
import CatTipoRecetaState from "./context/CatTipoReceta/CatTipoRecetaState";
import RecetaProductoState from "./context/RecetaProducto/RecetaProductoState";
import RecetaExpedienteState from "./context/RecetaExpediente/RecetaExpedienteState";
import CatTipoInterconsultaState from "./context/CatTipoInterconsulta/CatTipoInterconsultaState";
import NotaInterconsultState from "./context/NotaInterconsulta/NotaInterconsultaState";
import EstudiosLaboratorioState from "./context/EstudiosLaboratorio/EstudiosLaboratorioState";
import HojaIndicacionState from "./context/HojaIndicacion/HojaIndicacionState";
import ContabilidadReportesState from "./context/ContabilidadReportes/ContabilidadReportesState";
import CatViasState from "./context/CatVias/CatViasState";
import ContabilidadFacturasState from "./context/ContabilidadFacturas/ContabilidadFacturasState";
import CatUsoCfdiState from "./context/CatUsoCfdi/CatUsoCfdiState";
import CatRegimenFiscalState from "./context/CatRegimenFiscal/CatRegimenFiscalState";
import NotificacionesState from "./context/Notificaciones/NotificacionesState";
import FacturaState from "./context/Factura/FacturaState";
import CatPeriodicidadState from "./context/CatPeriodicidad/CatPeriodicidadState";
import ReporteContabilidadState from "./context/ReporteContabilidad/ReporteContabilidadState";
import CatDiagnosticosState from "./context/CatDiagnosticos/CatDiagnosticosState";
import CatTipoCitaState from "./context/CatTipoCita/CatTipoCitaState";
import WindowWarningState from "./context/WindowWarning/WindowWarningState";
import CatTipoServiciosState from "./context/CatTipoServicios/CatTipoServiciosState";
import ProductosCompraExternaState from "./context/ProductosCompraExterna/ProductosCompraExternaState";

import Routes from "./Routes";
import DashboardState from "./context/Dashboard/DashboardState";

const AppStateManager = () => {
    return (
        <AuthState>
            <ValesState>
                <MedicosState>
                    <PacientesState>
                        <AseguradorasState>
                            <EstatusValeState>
                                <UsuariosState>
                                    <TipoValeState>
                                        <CatProductosState>
                                            <CatServiciosState>
                                                <ProductosServiciosState>
                                                    <ConfiguracionState>
                                                        <CatTipoCobroState>
                                                            <CatTipoPagoState>
                                                                <VentaMostradorState>
                                                                    <CatTipoVentaMostradorState>
                                                                        <InventarioState>
                                                                            <EstatusVentaMostradorState>
                                                                                <SolicitudesComprasState>
                                                                                    <CatTipoSolicitudCompraState>
                                                                                        <CatTipoCompraState>
                                                                                            <CatTipoMedicoState>
                                                                                                <CatTipoOrdenCompraState>
                                                                                                    <EstatusSolicitudCompraState>
                                                                                                        <CatTipoProductoState>
                                                                                                            <ProveedoresState>
                                                                                                                <RecepcionGenericaProductosState>
                                                                                                                    <CatRolesState>
                                                                                                                        <RequerimientosCompraPendientesState>
                                                                                                                            <CatTipoRecepcionState>
                                                                                                                                <OrdenesCompraState>
                                                                                                                                    <EstatusOrdenCompraState>
                                                                                                                                        <AlmacenesState>
                                                                                                                                            <ConsultaCompraProductoState>
                                                                                                                                                <CatLaboratoriosState>
                                                                                                                                                    <ReporteDesplazamientoProductoState>
                                                                                                                                                        <CatTipoVentaState>
                                                                                                                                                            <RegistroPagosOrdenCompraState>
                                                                                                                                                                <PagosMedicoState>
                                                                                                                                                                    <CortesCajaState>
                                                                                                                                                                        <CalendarioState>
                                                                                                                                                                            <EspaciosAtencionState>
                                                                                                                                                                                <TipoEspacioState>
                                                                                                                                                                                    <LugaresState>
                                                                                                                                                                                        <OcupacionesState>
                                                                                                                                                                                            <EscolaridadesState>
                                                                                                                                                                                                <EstadosCivilesState>
                                                                                                                                                                                                    <SexosState>
                                                                                                                                                                                                        <AnaquelesState>
                                                                                                                                                                                                            <EstantesState>
                                                                                                                                                                                                                <UbicacionesState>
                                                                                                                                                                                                                    <CatTipoCorteCajaState>
                                                                                                                                                                                                                        <FabricantesState>
                                                                                                                                                                                                                            <TrasladoProductoInventarioState>
                                                                                                                                                                                                                                <CatEstatusTrasladoState>
                                                                                                                                                                                                                                    <ExpedienteMedicoState>
                                                                                                                                                                                                                                        <BajaInventarioState>
                                                                                                                                                                                                                                            <CatEstatusBajaInventarioState>
                                                                                                                                                                                                                                                <HistoriaClinicaInicialState>
                                                                                                                                                                                                                                                    <PadecimientoActualState>
                                                                                                                                                                                                                                                        <QuimioState>
                                                                                                                                                                                                                                                            <CentroMezclasState>
                                                                                                                                                                                                                                                                <TiposAdministracionState>
                                                                                                                                                                                                                                                                    <ResultadosReaState>
                                                                                                                                                                                                                                                                        <ClientesState>
                                                                                                                                                                                                                                                                            <CatTipoPrecioState>
                                                                                                                                                                                                                                                                                <ConsultaSeguimientoState>
                                                                                                                                                                                                                                                                                    <CatTipoRecetaState>
                                                                                                                                                                                                                                                                                        <RecetaProductoState>
                                                                                                                                                                                                                                                                                            <RecetaExpedienteState>
                                                                                                                                                                                                                                                                                                <CatTipoInterconsultaState>
                                                                                                                                                                                                                                                                                                    <NotaInterconsultState>
                                                                                                                                                                                                                                                                                                        <EstudiosLaboratorioState>
                                                                                                                                                                                                                                                                                                            <HojaIndicacionState>
                                                                                                                                                                                                                                                                                                                <ContabilidadReportesState>
                                                                                                                                                                                                                                                                                                                    <CatViasState>
                                                                                                                                                                                                                                                                                                                        <ContabilidadFacturasState>
                                                                                                                                                                                                                                                                                                                            <CatUsoCfdiState>
                                                                                                                                                                                                                                                                                                                                <CatRegimenFiscalState>
                                                                                                                                                                                                                                                                                                                                    <NotificacionesState>
                                                                                                                                                                                                                                                                                                                                        <FacturaState>
                                                                                                                                                                                                                                                                                                                                            <CatPeriodicidadState>
                                                                                                                                                                                                                                                                                                                                                <ReporteContabilidadState>
                                                                                                                                                                                                                                                                                                                                                    <CatDiagnosticosState>
                                                                                                                                                                                                                                                                                                                                                        <CatTipoCitaState>
                                                                                                                                                                                                                                                                                                                                                            <WindowWarningState>
                                                                                                                                                                                                                                                                                                                                                                <CatTipoServiciosState>
                                                                                                                                                                                                                                                                                                                                                                    <ProductosCompraExternaState>
                                                                                                                                                                                                                                                                                                                                                                        <DashboardState>
                                                                                                                                                                                                                                                                                                                                                                            <SidebarState>
                                                                                                                                                                                                                                                                                                                                                                                <Routes/>
                                                                                                                                                                                                                                                                                                                                                                            </SidebarState>
                                                                                                                                                                                                                                                                                                                                                                        </DashboardState>
                                                                                                                                                                                                                                                                                                                                                                    </ProductosCompraExternaState>
                                                                                                                                                                                                                                                                                                                                                                </CatTipoServiciosState>
                                                                                                                                                                                                                                                                                                                                                            </WindowWarningState>
                                                                                                                                                                                                                                                                                                                                                        </CatTipoCitaState>
                                                                                                                                                                                                                                                                                                                                                    </CatDiagnosticosState>
                                                                                                                                                                                                                                                                                                                                                </ReporteContabilidadState>
                                                                                                                                                                                                                                                                                                                                            </CatPeriodicidadState>
                                                                                                                                                                                                                                                                                                                                        </FacturaState>
                                                                                                                                                                                                                                                                                                                                    </NotificacionesState>
                                                                                                                                                                                                                                                                                                                                </CatRegimenFiscalState>
                                                                                                                                                                                                                                                                                                                            </CatUsoCfdiState>
                                                                                                                                                                                                                                                                                                                        </ContabilidadFacturasState>
                                                                                                                                                                                                                                                                                                                    </CatViasState>
                                                                                                                                                                                                                                                                                                                </ContabilidadReportesState>
                                                                                                                                                                                                                                                                                                            </HojaIndicacionState>
                                                                                                                                                                                                                                                                                                        </EstudiosLaboratorioState>
                                                                                                                                                                                                                                                                                                    </NotaInterconsultState>
                                                                                                                                                                                                                                                                                                </CatTipoInterconsultaState>
                                                                                                                                                                                                                                                                                            </RecetaExpedienteState>
                                                                                                                                                                                                                                                                                        </RecetaProductoState>
                                                                                                                                                                                                                                                                                    </CatTipoRecetaState>
                                                                                                                                                                                                                                                                                </ConsultaSeguimientoState>
                                                                                                                                                                                                                                                                            </CatTipoPrecioState>
                                                                                                                                                                                                                                                                        </ClientesState>
                                                                                                                                                                                                                                                                    </ResultadosReaState>
                                                                                                                                                                                                                                                                </TiposAdministracionState>
                                                                                                                                                                                                                                                            </CentroMezclasState>
                                                                                                                                                                                                                                                        </QuimioState>
                                                                                                                                                                                                                                                    </PadecimientoActualState>
                                                                                                                                                                                                                                                </HistoriaClinicaInicialState>
                                                                                                                                                                                                                                            </CatEstatusBajaInventarioState>
                                                                                                                                                                                                                                        </BajaInventarioState>
                                                                                                                                                                                                                                    </ExpedienteMedicoState>
                                                                                                                                                                                                                                </CatEstatusTrasladoState>
                                                                                                                                                                                                                            </TrasladoProductoInventarioState>
                                                                                                                                                                                                                        </FabricantesState>
                                                                                                                                                                                                                    </CatTipoCorteCajaState>
                                                                                                                                                                                                                </UbicacionesState>
                                                                                                                                                                                                            </EstantesState>
                                                                                                                                                                                                        </AnaquelesState>
                                                                                                                                                                                                    </SexosState>
                                                                                                                                                                                                </EstadosCivilesState>
                                                                                                                                                                                            </EscolaridadesState>
                                                                                                                                                                                        </OcupacionesState>
                                                                                                                                                                                    </LugaresState>
                                                                                                                                                                                </TipoEspacioState>
                                                                                                                                                                            </EspaciosAtencionState>
                                                                                                                                                                        </CalendarioState>
                                                                                                                                                                    </CortesCajaState>
                                                                                                                                                                </PagosMedicoState>
                                                                                                                                                            </RegistroPagosOrdenCompraState>
                                                                                                                                                        </CatTipoVentaState>
                                                                                                                                                    </ReporteDesplazamientoProductoState>
                                                                                                                                                </CatLaboratoriosState>
                                                                                                                                            </ConsultaCompraProductoState>
                                                                                                                                        </AlmacenesState>
                                                                                                                                    </EstatusOrdenCompraState>
                                                                                                                                </OrdenesCompraState>
                                                                                                                            </CatTipoRecepcionState>
                                                                                                                        </RequerimientosCompraPendientesState>
                                                                                                                    </CatRolesState>
                                                                                                                </RecepcionGenericaProductosState>
                                                                                                            </ProveedoresState>
                                                                                                        </CatTipoProductoState>
                                                                                                    </EstatusSolicitudCompraState>
                                                                                                </CatTipoOrdenCompraState>
                                                                                            </CatTipoMedicoState>
                                                                                        </CatTipoCompraState>
                                                                                    </CatTipoSolicitudCompraState>
                                                                                </SolicitudesComprasState>
                                                                            </EstatusVentaMostradorState>
                                                                        </InventarioState>
                                                                    </CatTipoVentaMostradorState>
                                                                </VentaMostradorState>
                                                            </CatTipoPagoState>
                                                        </CatTipoCobroState>
                                                    </ConfiguracionState>
                                                </ProductosServiciosState>
                                            </CatServiciosState>
                                        </CatProductosState>
                                    </TipoValeState>
                                </UsuariosState>
                            </EstatusValeState>
                        </AseguradorasState>
                    </PacientesState>
                </MedicosState>
            </ValesState>
        </AuthState>
    );
};

export default AppStateManager;
