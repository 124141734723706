import {
    FILTRO_REPORTEDESPLAZAMIENTOPRODUCTO,
    LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO,
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case FILTRO_REPORTEDESPLAZAMIENTOPRODUCTO: 
            return{
                ...state, 
                filtrosReporteDesplazamientoProducto: action.payload
            }
        case LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO:
            return{
                ...state,
                reportesDesplazamientoProducto: action.payload
            }
        default:
            return state
    }
        
}