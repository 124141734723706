/* eslint-disable import/no-anonymous-default-export */
import {
    LISTAR_VALE,
    TODOS_VALES,
    VALE_SELECCIONADO,
    NUEVO_VALE,
    ERROR_VALE_NUEVO,
    CAMBIOS_VALE_SELECCIONADO,
    LIMPIAR_VALE_SELECCIONADO,
    SET_VALES_FILTRADOS,
    ESTATUS_CANCELAR_VALE,
    ERROR_ESTATUS_CANCELAR_VALE,
    ESTATUS_DEVOLVER_VALE,
    ERROR_ESTATUS_DEVOLVER_VALE,
    CAMBIOS_FILTROS_VALE,
    CAMBIOS_FOLIO_FILTRO,
    NUEVO_COBRO_VALE,
    ERROR_NUEVO_COBRO_VALE,
    ELIMINAR_COBRO_VALE,
    ERROR_ELIMINAR_COBRO_VALE,
    ELIMINAR_PROGRESO_COBRO_VALE,
    ERROR_ELIMINAR_PROGRESO_COBRO_VALE,
    CAMBIO_CONCENTRACION_VALE,
    ERROR_CAMBIO_CONCENTRACION_VALE,
    ACTUALIZAR_VALE_FILTRADO,
    ERROR_ELIMINAR_VALE_PRODUCTOSERVICO,
    ELIMINAR_VALE_PRODUCTOSERVICO,
    MODIFICAR_VALE_PRODUCTOSERVICO,
    ERROR_MODIFICAR_VALE_PRODUCTOSERVICO, VALES_MEDICO,
} from './../../types'

export default (state, action) => {
    switch (action.type) {
        case LISTAR_VALE:
            return {
                ...state,
                valesFiltrados: []
            }

        case TODOS_VALES:
            return {
                ...state,
                valesFiltrados: action.payload
            };

        case VALES_MEDICO:
            return {
                ...state,
                valesFiltradosMedico: action.payload
            }
        case VALE_SELECCIONADO:
            return {
                ...state,
                valeSeleccionado: action.payload
            };
        case LIMPIAR_VALE_SELECCIONADO:
            return {
                ...state,
                valeSeleccionado: null
            };
        case CAMBIOS_VALE_SELECCIONADO:
            return {
                ...state,
                valeSeleccionado: action.payload
            };
        case NUEVO_VALE:
            return {
                ...state,
                nuevoVale: action.payload,
            };
        case ERROR_VALE_NUEVO:
            return {
                ...state,
                msg: action.payload,
                errStatus: true
            };
        case SET_VALES_FILTRADOS:
            return {
                ...state,
                valesFiltrados: action.payload
            }
        case ESTATUS_CANCELAR_VALE:
        case ESTATUS_DEVOLVER_VALE:
            return {
                ...state,
                valesFiltrados: state.valesFiltrados.filter((item) => item.idVale != action.payload)
            }
        case CAMBIOS_FILTROS_VALE:
            return {
                ...state,
                filtrosVale: action.payload
            }
        case CAMBIOS_FOLIO_FILTRO:
            return {
                ...state,
                folioFiltro: action.payload
            }
        case ELIMINAR_COBRO_VALE:
        case NUEVO_COBRO_VALE:
        case ELIMINAR_PROGRESO_COBRO_VALE:
            return {
                ...state,
                valeSeleccionado: action.payload,
            }
        case ERROR_NUEVO_COBRO_VALE:
        case ERROR_ELIMINAR_COBRO_VALE:
        case ERROR_ELIMINAR_PROGRESO_COBRO_VALE:
        case ERROR_ESTATUS_DEVOLVER_VALE:
        case ERROR_ESTATUS_CANCELAR_VALE:
        case ERROR_CAMBIO_CONCENTRACION_VALE:
        case ERROR_ELIMINAR_VALE_PRODUCTOSERVICO:
        case ELIMINAR_VALE_PRODUCTOSERVICO:
        case ERROR_MODIFICAR_VALE_PRODUCTOSERVICO:
        case MODIFICAR_VALE_PRODUCTOSERVICO:
            return {
                ...state
            }
        case CAMBIO_CONCENTRACION_VALE:
            return {
                ...state,
                // valesFiltrados: state.valesFiltrados.concat(action.payload[1]),
                valesFiltrados: state.valesFiltrados.filter((vale) => vale.idVale != action.payload[0].idVale)
            }
        case ACTUALIZAR_VALE_FILTRADO:
            return {
                ...state,
                valesFiltrados: state.valesFiltrados.map((vale) => {
                    if (vale.idVale == action.payload.idVale) {
                        return action.payload
                    } else {
                        return vale
                    }
                })
            }
        default:
            return state
    }
}