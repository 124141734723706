import {
    LISTAR_TIPOCOBROS
} from '../../types'

export default (state, action) => {
    switch(action.type){
        case LISTAR_TIPOCOBROS:
            return{
                ...state,
                tipoCobros: action.payload
            }

        default:
            return state
    }
}