import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

const FiltroFolio = (props) => {
  //value props.value

  return (
    <Card
      hidden={!props.toggleSearchBy}
      className="mt-10 card"
      style={{
        padding: "1.5rem 1.5rem 1.5rem",
      }}
    >
      <form onSubmit={(e) => props.buscarPorFolio(e)}>
        <CardBody>
          <Row>
            <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
              <Row
                className="form-group"
                style={{
                  marginBottom: "0",
                }}
              >
                <Col sm={10} className="flex col-sm-10">
                  <input
                    className="form-control mr-2.5"
                    type="text"
                    name={props.params.name}
                    placeholder={props.params.placeholder}
                    onChange={props.onChange}
                    value={props.value ? props.value : ""}
                  />
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    type="submit"
                  >
                    Buscar
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </form>
    </Card>
  );
};

export default FiltroFolio;
