import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

// import AuthContext from "../../context/Auth/AuthContext";
import NotificacionesContext from "../../context/Notificaciones/NotificacionesContext";
import SweetAlert from "react-bootstrap-sweetalert";
import AuthContext from "../../context/Auth/AuthContext";
import formatDateWithoutHour from "../../utils/formatDateWithoutHour";
import formatDate from "../../utils/formatDate";

export default function Notificaciones(props) {
  // const ctxAuth = useContext(AuthContext);
  // const { usuario } = ctxAuth;

  const notfContext = useContext(NotificacionesContext);
  const { notificaciones, obtenerNumeroNotificaciones, listarNotificaciones, readNotificacion,unReadNotificacion, filtrosNotificacion, setFiltrosNotificacion } = notfContext;

  const [listadoNotifFlag, setListadoNotifFlag] = useState(false);
  const [notificacionSeleccionada, setNotificacionSeleccionada] = useState({});

  const authContext = useContext(AuthContext);
  const {usuario} = authContext;

  const isAdmin = usuario.authorities.includes("ROLE_ADMIN_SISTEMA")? true:false; 

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_leer_notificacion: false,
    confirm_alert_UnRead_notificacion: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const onChange = (e) => {
    setFiltrosNotificacion({
      ...filtrosNotificacion,
      [e.target.name]: e.target.value,
    });
  };

  const buscarPorFiltros = () => {
    global.fechaInicial = filtrosNotificacion.fechaInicial
    listarNotificaciones(filtrosNotificacion);
    obtenerNumeroNotificaciones();
   
  };

  const unRead = (notificacion) => {

    if (notificacion.leido) {
      return (
        <button
          className="btn btn-primary-orange-umo w-md waves-effect waves-light"
          type="button"
          onClick={() => {
            setNotificacionSeleccionada(notificacion);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_UnRead_notificacion: true,
            });
          }}>
          No leido
        </button>
      );
    } 
  };

  const butttonRedirect = (notificacion) => {
    if (!notificacion.path) {
      return <></>;
    }

    // if (notificacion.idAlmacenResponsable && notificacion.idAlmacenResponsable != usuario.idCatUsuario) {
    //   return <></>;
    // }

    if (notificacion.leido) {
      return (
        <button
          className="btn btn-primary-orange-umo w-md waves-effect waves-light"
          type="button"
          hidden={!notificacion.redirigir}
          onClick={() => {
            setNotificacionSeleccionada(notificacion);
            props.history.push(notificacion.path);
          }}>
          Redirigir
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-primary-orange-umo w-md waves-effect waves-light"
          type="button"
          hidden={!notificacion.redirigir}
          onClick={() => {
            setNotificacionSeleccionada(notificacion);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_leer_notificacion: true,
            });
          }}>
          Redirigir
        </button>
      );
    }
  };

  useEffect(() => {
    if (!listadoNotifFlag) {
      if (!filtrosNotificacion.fechaFinal && !filtrosNotificacion.fechaInicial) {
        filtrosNotificacion.fechaInicial = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
        filtrosNotificacion.fechaFinal = new Date().toISOString().slice(0, 10);

        global.fechaInicial = filtrosNotificacion.fechaInicial;
        setFiltrosNotificacion(filtrosNotificacion);
        listarNotificaciones(filtrosNotificacion);
      } else {
        listarNotificaciones(filtrosNotificacion);
      }

      setListadoNotifFlag(true);
    }
  }, [notificaciones]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Notificaciones</h3>

      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                <Col sm={7}>
                  <input
                    type="date"
                    className="form-control"
                    name="fechaInicial"
                    onChange={onChange}
                    max={filtrosNotificacion.fechaFinal}
                    value={filtrosNotificacion.fechaInicial}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Fecha Final</label>
                <Col sm={7}>
                  <input
                    type="date"
                    className="form-control"
                    name="fechaFinal"
                    max={new Date().toISOString().slice(0, 10)}
                    onChange={onChange}
                    value={filtrosNotificacion.fechaFinal}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                type="button"
                onClick={buscarPorFiltros}>
                Aplicar Filtros
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mt-4 overflow-x-auto">
        <CardBody>
          <Row>
            <Col>
              <table className="table table-centered table-wrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Notificación</th>
                    <th scope="col">Mensaje</th>
                    <th scope="col">Leído</th>
                    <th scope="col">Redirigir</th>
                    <th align="center" scope="col">Marcar como <br></br>No leido</th>
                  </tr>
                </thead>
                <tbody>
                  {notificaciones.map((notificacion) => {
                    return (
                      <tr
                        className={
                          (notificacion.mensaje.includes("se requiere que registre su aplicación")) && usuario.authorities.includes("ROLE_QUIMIO") && !notificacion.leido?
                            "text-black font-bold bg-green-umo"  :                          
                          (notificacion.asunto.includes("Vale Aseguradora Cotizado") ||  notificacion.mensaje.includes("se requiere su revisión")) && usuario.authorities.includes("ROLE_ASEGURADORAS") && !notificacion.leido?
                            "text-black font-bold bg-green-umo"  :
                          notificacion.asunto.includes("Solicitud de Eliminación de Medicamento") && isAdmin && !notificacion.leido?
                            "text-black font-bold bg-green-umo"  :
                          notificacion.asunto && (notificacion.asunto.includes("Solicitud de Compra de Producto") || notificacion.asunto.includes("Solicitud de Eliminación de Medicamento") )  
                          && !notificacion.leido && !isAdmin? 
                            "text-black font-bold bg-green-umo"  :
                          notificacion.asunto && !(notificacion.asunto.includes("Aprobación") || notificacion.asunto.includes("Vale"))? 
                          (!notificacion.leido ? "text-white font-bold bg-blue-umo" : "") : 
                          (notificacion.mensaje.includes("Se requiere su aprobación") && !notificacion.leido) ? 
                            "text-black font-bold bg-green-umo" : 
                          (notificacion.asunto.includes("Aprobación") || notificacion.asunto.includes("Vale")) && !notificacion.leido ? 
                            "text-white font-bold bg-light-purple-umo"  : "" 
                        }
                        key={notificacion.idNotificacion}>
                        <td>
                            {notificacion.fechaEnvio ? notificacion.fechaEnvio.split("T")[0] :""}
                        </td>
                        <td>{notificacion.asunto ? notificacion.asunto : ""}</td>
                        <td>{notificacion.mensaje ? notificacion.mensaje : ""}</td>
                        <td>{notificacion.leido ? <i className="ti-check"></i> : <i className="ti-close"></i>}</td>
                        <td>{butttonRedirect(notificacion)}</td>
                        <td>{unRead(notificacion)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_leer_notificacion ? (
        <SweetAlert
          title="¿Desea marcar como leída la notificación y ser redirigido(a)?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            readNotificacion(notificacionSeleccionada.idNotificacion);
            props.history.push(notificacionSeleccionada.path);
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_leer_notificacion: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_UnRead_notificacion ? (
        <SweetAlert
          title="¿Desea marcar como no leída la notificación?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            unReadNotificacion(notificacionSeleccionada.idNotificacion);
            listarNotificaciones(filtrosNotificacion);
            setListadoNotifFlag(false);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_UnRead_notificacion: false,
            });
            //props.history.push(notificacionSeleccionada.path);
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_UnRead_notificacion: false,
            });
          }}></SweetAlert>
      ) : null}

    </div>
  );
}
