/* eslint-disable import/no-anonymous-default-export */
import { LISTAR_RECETA_PRODUCTOS } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_RECETA_PRODUCTOS:
      return {
        ...state,
        recetaProductos: action.payload,
      };

    default:
      return state;
  }
};
