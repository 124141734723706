import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import "./config/global.js"

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LdocJYlAAAAABP1KjmtYeJ1g2o4fKPIkNMt8x4z">
    <App />
  </GoogleReCaptchaProvider>,

  document.getElementById("root")
);
