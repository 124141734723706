import React, { useReducer } from "react";

import EscolaridadesContext from "./EscolaridadesContext";
import EscolaridadesReducer from "./EscolaridadesReducer";

import { LISTAR_ESCOLARIDADES } from "../../types";

import clienteAxios from "../../config/axios";

export default function EscolaridadesState(props) {
  const initialState = {
    escolaridades: [],
  };

  const [state, dispatch] = useReducer(EscolaridadesReducer, initialState);

  const listarEscolaridades = async () => {
    const res = await clienteAxios.get("/escolaridades/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_ESCOLARIDADES,
      payload: res.data,
    });
  };

  return (
    <EscolaridadesContext.Provider
      value={{
        escolaridades: state.escolaridades,
        listarEscolaridades,
      }}>
      {props.children}
    </EscolaridadesContext.Provider>
  );
}
