import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
// import { useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
// import ValesContext from "../../context/Vales/ValesContext";
import CentroMezclasContext from "../../context/CentroMezclas/CentroMezclasContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";
import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import ValesContext from "../../context/Vales/ValesContext";
import useWindowDimensions from "../../hooks/dimensionsHook";

const BitacoraMezclasPaginadas = () => {
  const { width } = useWindowDimensions();

  // const { idVale } = useParams();
  const centroMezclasContext = useContext(CentroMezclasContext);
  const { exportListaBitacoras,filtrosBitacoras, bitacorasPaginadas, setFiltrosBitacoras, setDataBitacorasCentroMezclas } = centroMezclasContext;

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const ctxVales = useContext(ValesContext);
  const {seleccionarVale, valeSeleccionado} = ctxVales;

  const ctxProductos = useContext(CatProductosContext);
  const { catProductos, listarMedicamentos , MedicamentosVentaMostradorPaciente , MedicamentosCompraExterna} = ctxProductos;

  const authContext = useContext(AuthContext);
  const {usuario} = authContext;

  const autorizadoExcel =
  usuario &&
  (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
      usuario.authorities.includes("ROLE_CENTRO_MEZCLAS"));  

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  today = mm + "/" + dd + "/" + yyyy;
  

  // const ctxVales = useContext(ValesContext);
  // const { seleccionarVale, valeSeleccionado } = ctxVales;

  const [bitacoraSeleccionada, setBitacoraSeleccionada] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);
  const [medicamentosCEGlobal, setMedicamentosCEGlobal] = useState([]);
  const [medicamentosVMGlobal, setMedicamentosVMGlobal] = useState([]);
  
  // const [isFirstSearch, setIsFirstSearch] = useState(true);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_bitacora: false,
    confirm_nueva_bitacora: false,
    confirm_eliminar_bitacora: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [filtrosLocales, setFiltrosLocales] = useState({
    fechaFinal: "",
    fechaInicial: "",
    idCatPaciente: null,
    idCatProducto: null,
    idExpedienteMedico: null,
    idReaccionAdversa: null,    
});

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = useState(0);

  const [listaMedicamentosVentaMostrador, setListaMedicamentosVentaMostrador] = useState([]);
  const[existeProductoVentaMostrador,setExisteProductoVentaMostrador] = useState(true)
  const [listaMedicamentosCompraExterna, setListaMedicamentosCompraExterna] = useState([]);
  const[existeProductoCompraExterna,setExisteProductoCompraExterna] = useState(true)
  const [listaMedicamentosSeleccionado, setListaMedicamentosSeleccionado] = useState([]);
  // eslint-disable-next-line
  const [data, setData] = useState([
    {
      idBitacora: "",
      fechaRegistro: "",
      prescripcionMedica: "",
      responsableRevision: "",
      responsablePreparacion: "",
      idCatProducto: "",
      idCatProductoVentaMostrador: "",
      idProductoCompraExterna: "",  
      opcion : 1,
      medicamento: "",
      ordenPreparacion: "",
      calculos: "",
      horaEntrega: "",
    },
  ]);

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setDataBitacorasCentroMezclas(page, limit,{filtrosLocales});

      const count = response.count;
      
      setData(response.centromezclasbitacoraVos);

      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
      if(count == 0){
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Los criterios no mostraron resultados",
        });
      }
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const exportarTablaExcel = async () => {
    setLoading(true);
    const res = await exportListaBitacoras(filtrosLocales);

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
    }
  };

  const fetchData = async ({ pageSize, pageIndex, byBusquedaFiltros }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: byBusquedaFiltros ? byBusquedaFiltros : false,
      });
    }
  };

  const handdleCheckedBitacora = useCallback(
    (row) => {
      if (rowSeleccionada === null || rowSeleccionada !== row.id) {
        setRowSeleccionada(row.id);
        
        CargaMedicamentos(row.original);

        let opcionTemp = 0;
        if(row.original.catProducto.idCatProducto){
          opcionTemp = 1;
        }

        if(row.original.idCatProductoVentaMostrador){
          opcionTemp = 2;
        }

        if(row.original.idProductoCompraExterna){
          opcionTemp = 3;
        }
        setBitacoraSeleccionada({
          ...row.original,
          idCatMedico: row.original.expedienteMedico.idCatMedico,
          idCatProducto: row.original.catProducto.idCatProducto,
          idCatProductoVentaMostrador: row.original.idCatProductoVentaMostrador,
          idProductoCompraExterna: row.original.idProductoCompraExterna,
          nombreMedicamento: row.original.catProducto.nombreComercial,
          opcion : opcionTemp,
        });
      } else {
        setRowSeleccionada(null);
        setBitacoraSeleccionada(null);
      }
      // else {
      //   setRowSeleccionada(row.id);
      //   setBitacoraSeleccionada({
      //     ...row.original,
      //     idCatMedico: row.original.expedienteMedico.idCatMedico,
      //     idCatProducto: row.original.catProducto.idCatProducto,
      //     nombreMedicamento: row.original.catProducto.nombreComercial,
      //   });
      // }
    },
    [rowSeleccionada]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCentroMezclaBitacora",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedBitacora(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Fecha de Registro", accessor: "fechaRegistro" },
      { Header: "Prescripción Médica", accessor: "nombreMedico" },
      { Header: "idCatMedico", accessor: "medico.idCatMedico" },
      { Header: "Orden de Preparación", accessor: "ordenPreparacion" },
      { Header: "P. Responsable de Revisión de Orden", accessor: "nombreResponsableRevision" },
      { Header: "Cálculos", accessor: "calculos" },
      { Header: "Q. Responsable de Preparación", accessor: "nombreResponsablePreparacion" },
      { Header: "Hora de Entrega", accessor: "horaEntrega" },
      { Header: "Medicamento", accessor: "nombreComercialMostrar" },
      { Header: "Medicamento", accessor: "catProducto.idCatProducto" },
    ],
    [rowSeleccionada, handdleCheckedBitacora]
  );

  const medicosChangeId = medicos.map(({ idCatMedico, catPersona, ...medico }) => ({
    ...medico,
    nombre: `${catPersona.nombres} ${catPersona.apellidoPaterno} ${catPersona.apellidoMaterno}`,
    id: idCatMedico,
  }));

  const medicamentosActivos = catProductos.map(({ idCatProducto, nombreComercial, ...medicamento }) => ({
    ...medicamento,
    id: idCatProducto,
    nombre: nombreComercial,
  }));

  const onChange = (e) => {
    if (e.target.name === "fechaInicial") {
      setFiltrosLocales({
        ...filtrosLocales,
        [e.target.name]: e.target.value,
        fechaFinal: e.target.value,
      });
    } else {
      setFiltrosLocales({
        ...filtrosLocales,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "opcion") {

      if(e.target.value == 1){
        setListaMedicamentosSeleccionado(medicamentosActivos);
      }

      if(e.target.value == 2){
        setListaMedicamentosSeleccionado(medicamentosVMGlobal);
      }

      if(e.target.value == 3){
        setListaMedicamentosSeleccionado(medicamentosCEGlobal);
      }

      setFiltrosLocales({
        ...filtrosLocales,
        [e.target.name]: e.target.value,
        tipoListaMedicamento : e.target.value,
      });
    } 
  };

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Fecha Inicial",
          type: "date",
          name: "fechaInicial",
          disabled: false,
          value: filtrosLocales.fechaInicial,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Médico",
          name: "idCatMedico",
          value: filtrosLocales.idCatMedico,
          opciones: medicosChangeId,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Fecha Final",
          type: "date",
          name: "fechaFinal",
          disabled: !filtrosLocales.fechaInicial ? true : false,
          min: filtrosLocales.fechaInicial,
          value: filtrosLocales.fechaFinal,
          onChange: onChange,
        },
        {
          colType: "radio",
          label: "",
          name: "listaSeleccionada",
          value: filtrosLocales.listaSeleccionada,
          onChange: onChange,
        },  
        filtrosLocales.opcion == 3?
        {
          colType: "selectExterno",
          label: "Medicamento",
          name: "idCatProducto",
          value: filtrosLocales.idCatProducto,
          opciones: listaMedicamentosSeleccionado,
          onChange: onChange,
        }:
        {
          colType: "select",
          label: "Medicamento",
          name: "idCatProducto",
          value: filtrosLocales.idCatProducto,
          opciones: listaMedicamentosSeleccionado,
          onChange: onChange,
        }
        ,
      ],

    },
  ];
  //ACCESO AL ESTADO GLOBAL - AUTENCIACION

  const ctxUsuarios = useContext(UsuariosContext);
  const { listarUsuarios, usuarios } = ctxUsuarios;

  useEffect(() => {
    // if (usuarios.length <= 0)
    const fetchtComprasPagadas = async () => {
      const medicamentosCETemp = await MedicamentosCompraExterna(1);
      setMedicamentosCEGlobal(medicamentosCETemp);

      const medicamentosVMTemp = await MedicamentosVentaMostradorPaciente(0);    
      setMedicamentosVMGlobal(medicamentosVMTemp);

      listarMedicos();
      listarMedicamentos();
    }
    listarUsuarios();
    fetchtComprasPagadas();
    // fetchVale();
  }, []);

  // const fetchVale = async () => {
  //   seleccionarVale(idVale);
  // };

  // Filtrar usuarios que tengan ROLE_CENTRO_MEZCLAS y ROLE_QUIMIO id_Cat_Rol 8 y 9

  let usuariosFiltrados = usuarios.map((element) => {
    return {
      ...element,
      catRoles: element.catRoles.filter((subElement) => subElement.idCatRol === 8 || subElement.idCatRol === 9),
    };
  });

  usuariosFiltrados = usuariosFiltrados.filter((el) => el.catRoles.length > 0);

  const usuariosResponsables = usuariosFiltrados.map(({ idCatUsuario, ...usuario }) => ({
    ...usuario,
    id: idCatUsuario,
  }));

  // const autorizado = usuario.authorities.includes("ROLE_CENTRO_MEZCLAS");

  const autorizado =
    usuario.authorities.includes("OP_BITACORA_MEZCLA_CREAR") ||
    usuario.authorities.includes("OP_BITACORA_MEZCLA_MODIFICAR") ||
    usuario.authorities.includes("OP_BITACORA_MEZCLA_ELIMINAR") ||
    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
    usuario.authorities.includes("ROLE_CENTRO_MEZCLAS");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function fetchData
     * @param {object} filtrosBitacoras Filtros enviados al back para filtrar las bitácoras
     * @param {int} page Página a regresar de bitácoras
     * @param {int} limit Número de bitácoras que se van a regresar por llamada al back
     */

    setFiltrosBitacoras(filtrosBitacoras);
    fetchData({ pageSize: 10, pageIndex: 0 });
    // setIsFirstSearch(false);
  };

  const limpiarFiltros = () => {
    
    setFiltrosLocales({
      ...filtrosLocales,
      fechaInicial:  "",
      fechaFinal : "",
      idCatProducto : null,
      idCatMedico : null,
    });

    if(filtrosLocales.tipoListaMedicamento == 1){
      setListaMedicamentosSeleccionado(medicamentosActivos);
    }

    if(filtrosLocales.tipoListaMedicamento == 2){
      setListaMedicamentosSeleccionado(medicamentosVMGlobal);
    }

    if(filtrosLocales.tipoListaMedicamento  == 3){
      setListaMedicamentosSeleccionado(medicamentosCEGlobal);
    }

  };

  const CargaMedicamentos = async (valePar) => { 
    const vale = await seleccionarVale(valePar.idVale);
    const medicamentosVMTemp = await MedicamentosVentaMostradorPaciente(vale.catPacientes.idCatPaciente);      
        
    if(medicamentosVMTemp.length > 0){
        setExisteProductoVentaMostrador(true);
    }else{
        setExisteProductoVentaMostrador(false);
    }
    setListaMedicamentosVentaMostrador(medicamentosVMTemp);

    if(medicamentosCEGlobal.length > 0){
      setExisteProductoCompraExterna(true);
    }else{
        setExisteProductoCompraExterna(false);
    }
    setListaMedicamentosCompraExterna(medicamentosCEGlobal);
      
  }

  const checkModificarBitacora = () => {
    if (autorizado && bitacoraSeleccionada) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_bitacora: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione una mezcla",
      });
    }
  };
  

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Bitácora de Mezclas</h3>
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Bitácoras de Mezclas</h4>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarBitacora()}>
                      Ver Mezcla
                    </button>
                  ) : null}

                  {autorizado ? (
                    <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                        type="button"
                        style={{
                          marginRight: "2.5rem",
                          marginTop: "1rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}                        
                        hidden={!autorizado}
                        onClick={exportarTablaExcel}>
                        Exportar (Excel)
                    </button> 
                  ) : null}                    
                </Col>
                <Col>
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={bitacorasPaginadas}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {UISweetAlert.confirm_modificar_bitacora ? (
        <SweetAlert
          title="Datos de la Mezcla"
          confirmBtnText="Cerrar"
          confirmBtnBsStyle="success"
          closeOnClickOutside={true}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_bitacora: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">Fecha Registro</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="fechaRegistro"
                      autoComplete="off"
                      value={bitacoraSeleccionada.fechaRegistro ? bitacoraSeleccionada.fechaRegistro : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Prescripción Médica</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="nombreMedico"
                      autoComplete="off"
                      value={bitacoraSeleccionada.nombreMedico ? bitacoraSeleccionada.nombreMedico : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label mb-2">P. Responsable de Revisión de Orden</label>
                  <Col sm={7}>
                    <select
                      disabled={true}
                      className="form-control"
                      name="responsableRevision"
                      // onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.responsableRevision}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {usuariosResponsables.map((usuario) => {
                        return <option value={usuario.id}>{usuario.nombre}</option>;
                      })}
                    </select>
                  </Col>

                  <label className="col-sm-5 col-form-label">Q. Responsable de Preparación</label>
                  <Col sm={7}>
                    <select
                      disabled={true}
                      className="form-control"
                      name="responsablePreparacion"
                      // onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.responsablePreparacion}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {usuariosResponsables.map((usuario) => {
                        return <option value={usuario.id}>{usuario.nombre}</option>;
                      })}
                    </select>
                  </Col>

                    {/* TODO */}
                    <label className="col-sm-5 col-form-label">
                      Medicamento{bitacoraSeleccionada.opcion==1? " (Vale)" : bitacoraSeleccionada.opcion==2? " (Venta Mostrador)" : " (Compra Externa)"}<span className="text-xs text-red-700">*</span>
                    </label>

                    {bitacoraSeleccionada.opcion==1?(
                      <Col sm={7}>
                        <input
                          disabled={true}
                          type="text"
                          className="form-control"
                          name="nombreMedico"
                          autoComplete="off"
                          value={bitacoraSeleccionada.nombreMedicamento ? bitacoraSeleccionada.nombreMedicamento : ""}
                        />
                      </Col>
                    ):null}

                    {bitacoraSeleccionada.opcion==2?(
                      <Col sm={7} className="pb-3">                    
                          <select
                            className="form-control"
                            name="idCatProductoVentaMostrador"
                            disabled = {true}
                            value={bitacoraSeleccionada.idCatProductoVentaMostrador}>
                            <option selected disabled value="">
                              Seleccione una opción
                              </option>
                              {listaMedicamentosVentaMostrador.map((medicamento) => {
                                return <option value={medicamento.id}>{medicamento.nombre}</option>;
                              })}
                          </select>
                      </Col>
                    ):null}

                    {bitacoraSeleccionada.opcion==3?(
                      <Col sm={7} className="pb-3">                    
                          <select
                            className="form-control"
                            name="idProductoCompraExterna"
                            disabled = {true}
                            value={bitacoraSeleccionada.idProductoCompraExterna}>
                            <option selected disabled value="">
                              Seleccione una opción
                              </option>
                              {listaMedicamentosCompraExterna.map((medicamento) => {
                                return <option value={medicamento.idProductoCompraExterna}>{`${medicamento.nombreComercial}  ${medicamento.concentracion}  ${medicamento.presentacion}`}</option>;
                              })}
                          </select>
                      </Col>
                    ):null}  
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">Orden de Preparación</label>
                  <Col sm={7}>
                    <textarea
                      disabled={true}
                      className="form-control mb-2"
                      name="ordenPreparacion"
                      autoComplete="off"
                      // onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.ordenPreparacion ? bitacoraSeleccionada.ordenPreparacion : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Cálculos</label>
                  <Col sm={7}>
                    <textarea
                      disabled={true}
                      className="form-control mb-2"
                      name="calculos"
                      autoComplete="off"
                      // onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.calculos ? bitacoraSeleccionada.calculos : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Hora de Entrega</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="time"
                      className="form-control"
                      name="horaEntrega"
                      autoComplete="off"
                      // onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.horaEntrega ? bitacoraSeleccionada.horaEntrega : ""}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default BitacoraMezclasPaginadas;
