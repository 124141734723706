import React, { useReducer } from "react";

import UsuariosContext from "./UsuariosContext";
import UsuariosReducer from "./UsuariosReducer";

import {
  LISTAR_USUARIOS,
  SET_USUARIO_EDITAR,
  EDITAR_USUARIO,
  ERROR_EDITAR_USUARIO,
  SET_NUEVO_USUARIO,
  NUEVO_USUARIO,
  ERROR_NUEVO_USUARIO,
  REINICIARPASSWORD_USUARIO,
  ERROR_REINICIARPASSWORD_USUARIO,
  LISTAR_USUARIOS_SIN_MEDICO,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function UsuariosState(props) {
  const initialState = {
    usuarios: [],
    usuariosSinMedico: [],
    usuarioEditar: {},
    nuevoUsuario: {
      catRoles: [],
      reiniciarPassword: false,
    },
  };

  const [state, dispatch] = useReducer(UsuariosReducer, initialState);

  const listarUsuarios = async () => {
    const res = await clienteAxios.get("/usuarios/activos/inactivos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    dispatch({
      type: LISTAR_USUARIOS,
      payload: res.data,
    });
    return res.data;
  };

  const listarUsuariosSinMedico = async () => {
    const res = await clienteAxios.get("/usuarios/not/used", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_USUARIOS_SIN_MEDICO,
      payload: res.data,
    });
    return res.data;
  };

  const setUsuarioEditar = async (usuario) => {
    dispatch({
      type: SET_USUARIO_EDITAR,
      payload: usuario,
    });
  };

  const uploadEditarUsario = async () => {
    // let tieneRoleMedico = false;

    // if (state.usuarioEditar.catRoles && state.usuarioEditar.catRoles.length > 0) {
    //   for (let role of state.usuarioEditar.catRoles) {
    //     if (role.authority == "ROLE_MEDICO") {
    //       tieneRoleMedico = true;
    //       break;
    //     }
    //   }
    //   // state.usuarioEditar.catRoles.forEach((role) => {});
    // }
    // console.log("🚀 ~ file: UsuariosState.js:68 ~ uploadEditarUsario ~ tieneRoleMedico", tieneRoleMedico);
    const esMedico = null;
    const catUsuarioVo = { ...state.usuarioEditar, medico: esMedico };

    try {
      const res = await clienteAxios.post(`/usuarios/editar`, catUsuarioVo, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      state.usuarios.filter((usuario, index) => {
        if (usuario.idCatUsuario == res.data.idCatUsuario) {
          state.usuarios[index] = res.data;
        }
        return true;
      });

      dispatch({
        type: EDITAR_USUARIO,
        payload: state.usuarios,
      });

      setUsuarioEditar({});

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_EDITAR_USUARIO,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const setNuevoUsuario = async (nuevoUsr) => {
    dispatch({
      type: SET_NUEVO_USUARIO,
      payload: nuevoUsr,
    });
  };

  const uploadNuevoUsuario = async () => {
    try {
      const res = await clienteAxios.post(`/usuarios/crear`, state.nuevoUsuario, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      dispatch({
        type: NUEVO_USUARIO,
        payload: res.data,
      });

      setNuevoUsuario({
        catRoles: [],
        reiniciarPassword: false,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_NUEVO_USUARIO,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const solicitarReinicioPassword = async () => {
    try {
      const res = await clienteAxios.post(`/usuarios/reiniciarPassword?id=${state.usuarioEditar.idCatUsuario}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      state.usuarios.filter((usuario, index) => {
        if (usuario.idCatUsuario == state.usuarioEditar.idCatUsuario) {
          state.usuarios[index].reiniciarPassword = true;
        }
        return true;
      });

      dispatch({
        type: REINICIARPASSWORD_USUARIO,
        payload: state.usuarios,
      });

      setUsuarioEditar({});

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_REINICIARPASSWORD_USUARIO,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const guardarArchivoUsuario = (usuarioVo) => {
    console.log("🚀 ~ file: UsuariosState.js:216 ~ guardarArchivoUsuario ~ usuarioVo:", usuarioVo);
  };

  return (
    <UsuariosContext.Provider
      value={{
        usuarios: state.usuarios,
        usuarioEditar: state.usuarioEditar,
        nuevoUsuario: state.nuevoUsuario,
        usuariosSinMedico: state.usuariosSinMedico,
        listarUsuarios,
        setUsuarioEditar,
        uploadEditarUsario,
        setNuevoUsuario,
        uploadNuevoUsuario,
        solicitarReinicioPassword,
        listarUsuariosSinMedico,
        guardarArchivoUsuario,
      }}>
      {props.children}
    </UsuariosContext.Provider>
  );
}
