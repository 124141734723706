import {
  LISTAR_ESTATUSVALE,
  PROXIMOS_ESTATUS,
  DATA_CAMBIO_ESTATUS,
  ESTATUS_CANCELADO,
  ERROR_CAMBIO_ESTATUS,
  UPLOAD_CAMBIO_ESTATUS,
  CLEAN_PROXIMOS_ESTATUS,
  ESTATUS_DEVUELTO,
  LISTAR_ESTATUSVALE_SALDOS_ASEGURADORA,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_ESTATUSVALE:
      return {
        ...state,
        estatusvale: action.payload,
      };
    case PROXIMOS_ESTATUS:
      return {
        ...state,
        proximosEstatusVale: action.payload,
      };
    case DATA_CAMBIO_ESTATUS:
      return {
        ...state,
        cambioEstatusData: action.payload,
      };

    case ESTATUS_CANCELADO:
      return {
        ...state,
        estatusCancelado: action.payload,
      };
    case ESTATUS_DEVUELTO:
      return {
        ...state,
        estatusDevuelto: action.payload,
      };
    case UPLOAD_CAMBIO_ESTATUS:
      return {
        ...state,
        msg: action.payload.msg,
        errStatus: null,
      };
    case ERROR_CAMBIO_ESTATUS:
      return {
        ...state,
        msg: action.payload,
        errStatus: true,
      };
    case CLEAN_PROXIMOS_ESTATUS:
      return {
        ...state,
        proximosEstatusVale: [],
      };
    case LISTAR_ESTATUSVALE_SALDOS_ASEGURADORA:
      return {
        ...state,
        estatusValeSaldosAseguradora: action.payload,
      };
    default:
      return state;
  }
};
