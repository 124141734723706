import React, {useReducer} from 'react'

import CatEstatusTrasladoContext from './CatEstatusTrasladoContext'
import CatEstatusTrasladoReducer from './CatEstatusTrasladoReducer'

import {
    LISTAR_CATESTATUSTRASLADO,
} from '../../types'

import clienteAxios from '../../config/axios'


export default function CatEstatusTrasladoState (props) {

    const initialState = { 
        catEstatusTraslado:[],
    }

    const [state, dispatch] = useReducer(CatEstatusTrasladoReducer,initialState)

    const listarCatEstatusTraslado = async () => {

        const res = await clienteAxios.get(`/estatustraslado/activos`, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            }
        })


        dispatch({
            type: LISTAR_CATESTATUSTRASLADO,
            payload: res.data
        })

        
    }

    return (

        <CatEstatusTrasladoContext.Provider value={{
            catEstatusTraslado: state.catEstatusTraslado,
            listarCatEstatusTraslado,
        }}>
            {props.children}
        </CatEstatusTrasladoContext.Provider>

    )


}