import React from "react";
import Galeria from "../Galeria";
import TablaArchivosPaciente from "../TablaArchivosPaciente";

const TabArchivosImagenClinica = (props) => {
  return (
    <div>
      <div className="mt-4">
        <h4>Galería de Imagenes</h4>
        <Galeria archivosImagen={props.archivosImagen} galleryID={1} />
      </div>
      <div className="mt-4">
        <h4>Otros Archivos</h4>
        <TablaArchivosPaciente exportArchivo={props.exportArchivo} archivosPaciente={props.archivosPaciente} />
      </div>
    </div>
  );
};

export default TabArchivosImagenClinica;
