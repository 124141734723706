import { LISTAR_CATTIPOPRODCUTOS, LISTAR_CATTIPOPRODCUTOS_ALL } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_CATTIPOPRODCUTOS:
      return {
        ...state,
        tiposProductos: action.payload,
      };
    case LISTAR_CATTIPOPRODCUTOS_ALL:
      return {
        ...state,
        catTipoProductosAll: action.payload,
      };
    default:
      return state;
  }
};
