import React, {useState, useContext, useEffect} from "react";
import {useLocation} from 'react-router-dom';
import {Row, Col, Card, CardBody, Spinner} from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

import CatTipoSolicitudCompraContext from "../../context/CatTipoSolicitudCompra/CatTipoSolicitudCompraContext";
import CatTipoOrdenCompraContext from "../../context/CatTipoOrdenCompra/CatTipoOrdenCompraContext";
import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import AuthContext from "../../context/Auth/AuthContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";

import formatNumber from "../../utils/index";

export default function NuevaSolicitudCompraPendientes(props) {
    const location = useLocation();
    const requerimientoPendiente = location.state;
    const folioVenta = requerimientoPendiente.folioVentaMostrador || requerimientoPendiente.folioVale;
  

    // eslint-disable-next-line no-unused-vars
    const [fechaDefault, setFechaDefault] = useState(new Date().toISOString().slice(0, 10));
    const [indexProductoBorrado, setIndexProductoBorrado] = useState(null);
    const [indexProveedorBorrado, setIndexProveedorBorrado] = useState(null);
    const [pacienteDeRequerimiento, setPacienteDeRequerimiento] = useState(null);
    const [detallesPrecio, setDetallesPrecio] = useState({
        total: 0,
        subtotal: 0,
        iva: 0,
    });

    const [loading, setLoading] = useState(false);
    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_delete_proveedor: false,
        confirm_alert_solicitar: false,
        confirm_alert_guardar: false,
        error_dlg: false,
        redirect: false,
        success_dlg: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    const pacienteContext = useContext(PacientesContext);
    const {getPacienteByIdVale} = pacienteContext;

    //ACCESO AL ESTADO GLOBAL - Tipos de Solicitud de compra
    const ctxTiposSolicitudCompra = useContext(CatTipoSolicitudCompraContext);
    const {listarTiposSolicitudCompra} = ctxTiposSolicitudCompra;

    //ACCESO AL ESTADO GLOBAL - Tipos de compra
    const ctxTiposOrdenCompra = useContext(CatTipoOrdenCompraContext);
    const {tiposOrdenCompra, listarTiposOrdenCompra} = ctxTiposOrdenCompra;

    const solicitudesComprasContext = useContext(SolicitudesComprasContext);
    const {
        nuevaSolicitudCompraPendiente,
        setNuevaSolicitudCompraPendiente,
        seleccionarNuevaSolicitudCompraPendiente,
        guardarNuevaSolicitudCompra,
    } = solicitudesComprasContext;

    //ACCESO AL ESTADO GLOBAL - Proovedores
    const ctxProveedores = useContext(ProveedoresContext);
    const {proveedores, listarProveedores} = ctxProveedores;

    const onChange = (e) => {
        setNuevaSolicitudCompraPendiente({
            ...nuevaSolicitudCompraPendiente,
            [e.target.name]: e.target.value,
        });
    };

    const confirmSolicitudCompra = async (e, solicitar) => {
        e.preventDefault();

        const errorEnValidacion = await validateFormSolicitudCompra();

        if (errorEnValidacion) {
            return;
        }

        if (solicitar) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_solicitar: true,
            });
        }
    };

    const guardarSolicitudCompra = async () => {
        nuevaSolicitudCompraPendiente.importeTotal = detallesPrecio.subtotal;
        nuevaSolicitudCompraPendiente.ivaTotal = detallesPrecio.iva;
        nuevaSolicitudCompraPendiente.idUsuarioComprador = usuario.idCatUsuario;
        nuevaSolicitudCompraPendiente.solicitarCotizacion = true;
        setNuevaSolicitudCompraPendiente(nuevaSolicitudCompraPendiente);

        setLoading(true);
        const res = await guardarNuevaSolicitudCompra(nuevaSolicitudCompraPendiente);
        if (res) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_guardar: false,
                confirm_alert_solicitar: false,
                dynamic_description: "Se guardó con éxito la solicitud de compra.",
                redirect: true,
                path: "/solicitudescompra",
            });
        } else {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_guardar: false,
                confirm_alert_solicitar: false,
                dynamic_title: "Fallo",
                dynamic_description: "Error al subir los cambios, Inténtalo por favor más tarde.",
            });
        }
    };

    const validateFormSolicitudCompra = async () => {
        let errorEnValidacion = false;

        if (!nuevaSolicitudCompraPendiente.idCatTipoOrdenCompra && !errorEnValidacion) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: `Por favor, introduzca el tipo de compra`,
            });
            errorEnValidacion = true;
        }

        nuevaSolicitudCompraPendiente.solicitudcompraproducto.map((producto, index) => {
            if (producto.piezas <= 0 && !errorEnValidacion) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: `Por favor, introduzca las piezas en el renglon ${index + 1}`,
                });
                errorEnValidacion = true;
            } else if (!errorEnValidacion && producto.piezasAux && producto.piezasAux > producto.piezas) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: `Por favor, el numero de piezas en el renglon ${index + 1}, no puede ser menor a ${
                        producto.piezasAux
                    }`,
                });
                errorEnValidacion = true;
            }

            if ((!producto.solicitudcompraproveedor || producto.solicitudcompraproveedor.length < 1) && !errorEnValidacion) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: `Por favor, introduzca al menos un proveedor para el producto en el renglon ${index + 1}`,
                });
                errorEnValidacion = true;
            } else if (!errorEnValidacion) {
                producto.solicitudcompraproveedor.map((proveedor, index) => {
                    if (!proveedor.idCatProveedor && !errorEnValidacion) {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: `Por favor, introduzca el proveedor en el renglon ${index + 1}`,
                        });
                        errorEnValidacion = true;
                    }
                });
            }
        });

        return errorEnValidacion;
    };

    const toggleExpanded = async (index) => {
        let productosNuevaSolicitudCompra = nuevaSolicitudCompraPendiente.solicitudcompraproducto;
        let producto = productosNuevaSolicitudCompra[index];
        producto.expanded = !producto.expanded;
        if (!producto.solicitudcompraproveedor) {
            producto.solicitudcompraproveedor = [];
        }
        setNuevaSolicitudCompraPendiente({
            ...nuevaSolicitudCompraPendiente,
            solicitudcompraproducto: productosNuevaSolicitudCompra,
        });
    };

    // const changePiezasProducto = async (e, index) => {
    //   let productos = nuevaSolicitudCompraPendiente.solicitudcompraproducto;
    //   let producto = productos[index];
    //   let value = e.target.value;

    //   //Si es la primera vez que cambiamos el numero, guardamos el valor original de piezas en una auxiliar
    //   if (!producto.piezasAux) {
    //     producto.piezasAux = producto.piezas;
    //   }

    //   //Quitamos cualquier valor negativo que nos puedan mandar
    //   if (value < 0) {
    //     value = value * -1;
    //   }

    //   producto.piezas = Number(value);

    //   setNuevaSolicitudCompraPendiente(nuevaSolicitudCompraPendiente);
    //   setPrecioTotal();
    // };

    const setPrecioTotal = async () => {
        let subtotal = 0;
        let iva = 0;
        let total = 0;

        setDetallesPrecio({
            subtotal: 0,
            iva: 0,
            total: 0,
        });

        if (nuevaSolicitudCompraPendiente.solicitudcompraproducto.length > 0) {
            nuevaSolicitudCompraPendiente.solicitudcompraproducto.map((producto) => {
                subtotal += Number(producto.precio) * Number(producto.piezas);
                if (Number(producto.iva) != null) {
                    iva += Number(producto.iva) * Number(producto.piezas);
                }
            });
        }

        total = subtotal + iva;

        setDetallesPrecio({
            subtotal: (Math.round(subtotal * 100) / 100).toFixed(2),
            iva: (Math.round(iva * 100) / 100).toFixed(2),
            total: (Math.round(total * 100) / 100).toFixed(2),
        });
    };

    const nuevoProveedor = async (index) => {
        const nuevoProv = {
            idCatProveedor: null,
            proveedor: "",
            contacto: "",
            correo: "",
        };

        nuevaSolicitudCompraPendiente.solicitudcompraproducto[index].solicitudcompraproveedor =
            nuevaSolicitudCompraPendiente.solicitudcompraproducto[index].solicitudcompraproveedor.concat(nuevoProv);
        setNuevaSolicitudCompraPendiente(nuevaSolicitudCompraPendiente);
    };

    const setProveedor = async (proveedor, indexProducto, indexProveedor) => {
        let proveedorSol =
            nuevaSolicitudCompraPendiente.solicitudcompraproducto[indexProducto].solicitudcompraproveedor[indexProveedor];
        const proveedorRepetido = await checarProveedorRepetido(proveedor, indexProducto, indexProveedor);

        if (proveedorRepetido) {
            proveedorSol.idCatProveedor = "";
            proveedorSol.proveedor = "";
            proveedorSol.contacto = "";
            proveedorSol.correo = "";

            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Proveedor repetido",
            });
        } else {
            proveedorSol.idCatProveedor = proveedor.idCatProveedor;
            proveedorSol.proveedor = proveedor.proveedor;
            proveedorSol.contacto = proveedor.contacto;
            proveedorSol.correo = proveedor.correo;
        }

        setNuevaSolicitudCompraPendiente(nuevaSolicitudCompraPendiente);
    };

    const checarProveedorRepetido = async (proveedor, indexProducto, indexProveedor) => {
        let proveedorRepetido = false;

        nuevaSolicitudCompraPendiente.solicitudcompraproducto[indexProducto].solicitudcompraproveedor.map((element, i) => {
            if (indexProveedor != i && element.idCatProveedor == proveedor.idCatProveedor && !proveedorRepetido) {
                proveedorRepetido = true;
            }
        });
        return proveedorRepetido;
    };

    const eliminarProveedor = async () => {
        let proveedor =
            nuevaSolicitudCompraPendiente.solicitudcompraproducto[indexProductoBorrado].solicitudcompraproveedor[indexProveedorBorrado];
        nuevaSolicitudCompraPendiente.solicitudcompraproducto[indexProductoBorrado].solicitudcompraproveedor =
            nuevaSolicitudCompraPendiente.solicitudcompraproducto[indexProductoBorrado].solicitudcompraproveedor.filter(
                (item) => item != proveedor
            );

        setNuevaSolicitudCompraPendiente(nuevaSolicitudCompraPendiente);

        setIndexProveedorBorrado(null);
        setIndexProductoBorrado(null);

        setUISweetAlert({
            ...UISweetAlert,
            success_dlg: true,
            dynamic_title: "Borrado",
            confirm_alert_delete_proveedor: false,
            dynamic_description: "El producto ha sido borrado correctamente.",
        });
    };

    useEffect(() => {
        const fetchAllContextData = () => {
            listarTiposSolicitudCompra();
            listarTiposOrdenCompra();
            listarProveedores();
        };
        fetchAllContextData();
    }, []);

    useEffect(() => {
        const getPaciente = async (idVale) => {
            setLoading(true);
            try {
                const paciente = await getPacienteByIdVale(idVale);
                if (paciente) {
                    setPacienteDeRequerimiento(paciente);
                }
                setLoading(false);
                return paciente;
            } catch (error) {
                setLoading(false);
            }
        };

        if (nuevaSolicitudCompraPendiente == null) {
            seleccionarNuevaSolicitudCompraPendiente(requerimientoPendiente);
        } else {
            //aqui hay que calcular el precio total de la orden
            setPrecioTotal();

            if (
                nuevaSolicitudCompraPendiente?.solicitudcompraproducto && nuevaSolicitudCompraPendiente?.solicitudcompraproducto.length > 0 &&
                nuevaSolicitudCompraPendiente?.solicitudcompraproducto[0].idVale
            ) {
                if (pacienteDeRequerimiento == null) getPaciente(nuevaSolicitudCompraPendiente.solicitudcompraproducto[0].idVale);
            }
        }
    }, [nuevaSolicitudCompraPendiente]);

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Nueva Solicitud de Compra (Pendiente)</h3>

            <Card
                hidden=""
                className="mt-10 card"
                style={{
                    padding: "1.5rem 1.5rem 1.5rem",
                }}>
                <form onSubmit="">
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha de Solicitud</label>
                                    <Col sm={8}>
                                        <input type="date" value={fechaDefault} className="form-control" disabled={true}/>
                                    </Col>
                                </Row>
                                <Row
                                    className="form-group"
                                    hidden={nuevaSolicitudCompraPendiente && nuevaSolicitudCompraPendiente.catTiposolicitudcompra ? false : true}>
                                    <label className="col-sm-4 col-form-label">Tipo Solicitud de Compra</label>
                                    <Col sm={8}>
                                        <input
                                            className="form-control"
                                            name="idCatTipoSolicitudCompra"
                                            type="text"
                                            disabled={true}
                                            value={
                                                nuevaSolicitudCompraPendiente && nuevaSolicitudCompraPendiente.catTiposolicitudcompra
                                                    ? nuevaSolicitudCompraPendiente.catTiposolicitudcompra.tipoSolicitudCompra
                                                    : ""
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Tipo de Compra</label>
                                    <Col sm={8}>
                                        <select
                                            className="form-control"
                                            name="idCatTipoOrdenCompra"
                                            onChange={onChange}
                                            value={
                                                nuevaSolicitudCompraPendiente && nuevaSolicitudCompraPendiente.idCatTipoOrdenCompra
                                                    ? nuevaSolicitudCompraPendiente.idCatTipoOrdenCompra
                                                    : ""
                                            }>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {tiposOrdenCompra.map((tipo) => {
                                                return (
                                                    <>
                                                        <option value={tipo.idCatTipoOrdenCompra}>{tipo.tipoOrdenCompra}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Tipo de Producto</label>
                                    <Col sm={8}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="idCatTipoProducto"
                                            value={requerimientoPendiente.tipoProducto}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-3 col-form-label">Comprador</label>
                                    <Col sm={9}>
                                        <input type="text" value={usuario.nombre} className="form-control" disabled={true}/>
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-3 col-form-label">Fólio Venta</label>
                                    <Col sm={9}>
                                        <input type="text" value={folioVenta} className="form-control" disabled={true}/>
                                    </Col>
                                </Row>

                                {pacienteDeRequerimiento ? (
                                <Row className="form-group">
                                    <label className="col-sm-3 col-form-label">Paciente</label>
                                    <Col sm={9}>
                                        {/* <label className="col-sm-7 col-form-label">{`${pacienteDeRequerimiento.nombres} ${pacienteDeRequerimiento.apellidoPaterno} ${pacienteDeRequerimiento.apellidoMaterno}`}</label> */}
                                        <input
                                            type="text"
                                            value={`${pacienteDeRequerimiento.nombres} ${pacienteDeRequerimiento.apellidoPaterno} ${pacienteDeRequerimiento.apellidoMaterno}`}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                
                                ) : null}
                            </Col>
                        </Row>
                    </CardBody>
                </form>
            </Card>

            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <table className="table table-hover table-centered table-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Descripción</th>
                                    <th scope="col">
                                        Principio<br></br>Activo
                                    </th>
                                    <th scope="col">Piezas</th>
                                    <th scope="col">
                                        Precio Unitario<br></br>(Estimado)
                                    </th>
                                    <th scope="col">IVA</th>
                                    <th scope="col">Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {nuevaSolicitudCompraPendiente &&
                                    nuevaSolicitudCompraPendiente.solicitudcompraproducto.map((producto, index) => {
                                        return (
                                            <>
                                                <tr key={producto}>
                                                    <td onClick={() => toggleExpanded(index)}>
                                                        {producto.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>}
                                                    </td>
                                                    <td>{producto.producto}</td>
                                                    <td>{producto.principioActivo ? producto.principioActivo : ""}</td>
                                                    <td>{producto.piezas ? producto.piezas : ""}</td>
                                                    {/* <td>
                                                            <input 
                                                            value={producto.piezas == 0 ? "" : producto.piezas} 
                                                            onWheel={(e) => e.target.blur()}
                                                            type="number"
                                                            onChange={(e) => changePiezasProducto(e, index)}
                                                            className="form-control"
                                                            />
                                                        </td> */}
                                                    <td>{producto.precio ? formatNumber(producto.precio) : ""}</td>
                                                    <td>{producto.iva ? formatNumber(producto.iva) : ""}</td>
                                                    <td>
                                                        {producto.piezas > 0
                                                            ? [
                                                                producto.iva > 0
                                                                    ? formatNumber(producto.piezas * (producto.precio + producto.iva))
                                                                    : formatNumber(producto.piezas * producto.precio),
                                                            ]
                                                            : ""}
                                                    </td>
                                                </tr>
                                                {producto.expanded && (
                                                    <tr>
                                                        <td colSpan={10}>
                                                            <button
                                                                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                                                                type="button"
                                                                onClick={() => nuevoProveedor(index)}>
                                                                Agregar Proveedor
                                                            </button>

                                                            {producto.solicitudcompraproveedor.length > 0 && (
                                                                <table className="table table-hover table-centered table-nowrap mb-10">
                                                                    <thead>
                                                                    <tr>
                                                                        <th scope="col">Nombre</th>
                                                                        <th scope="col">Contacto</th>
                                                                        <th scope="col">Correo</th>
                                                                        <th scope="col">Eliminar</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {producto.solicitudcompraproveedor.map((proveedor, indexProveedor) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <Select
                                                                                            isSearchable
                                                                                            options={proveedores}
                                                                                            getOptionLabel={(option) => option.proveedor}
                                                                                            value={proveedores.filter((prov) => prov.proveedor == proveedor.proveedor)}
                                                                                            onChange={(option) => setProveedor(option, index, indexProveedor)}
                                                                                            placeholder="Seleccione una opción"
                                                                                            noOptionsMessage={() => "No hay opciones"}
                                                                                        />
                                                                                    </td>
                                                                                    <td>{proveedor.contacto}</td>
                                                                                    <td>{proveedor.correo}</td>
                                                                                    <td>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                                                                            onClick={() => {
                                                                                                setIndexProveedorBorrado(indexProveedor);
                                                                                                setIndexProductoBorrado(index);
                                                                                                setUISweetAlert({
                                                                                                    ...UISweetAlert,
                                                                                                    confirm_alert_delete_proveedor: true,
                                                                                                });
                                                                                            }}>
                                                                                            Eliminar
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        );
                                                                    })}
                                                                    </tbody>
                                                                </table>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Card className="mt-4 w-full">
                <Card>
                    <Row>
                        <Col md="12">
                            <table
                                className="w-2/6"
                                style={{
                                    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                    boxSizing: "border-box",
                                    fontSize: "14px",
                                    backgroundColor: "inherit",
                                    margin: "0",
                                    float: "right",
                                }}
                                bgcolor="#f6f6f6">
                                <tbody>
                                <tr
                                    style={{
                                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                    }}>
                                    <td
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            borderTopWidth: "1px",
                                            borderTopColor: "#eee",
                                            borderTopStyle: "solid",
                                            margin: "0",
                                            padding: "5px 0",
                                            textAlign: "left",
                                        }}
                                        valign="top">
                                        Sub-Total
                                    </td>
                                    <td
                                        className="alignright"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "right",
                                            borderTopWidth: "1px",
                                            borderTopColor: "#eee",
                                            borderTopStyle: "solid",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="right"
                                        valign="top">
                                        {formatNumber(detallesPrecio.subtotal)}
                                    </td>
                                </tr>
                                <tr
                                    style={{
                                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                    }}>
                                    <td
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            borderTopWidth: "1px",
                                            borderTopColor: "#eee",
                                            borderTopStyle: "solid",
                                            margin: "0",
                                            padding: "5px 0",
                                            textAlign: "left",
                                        }}
                                        valign="top">
                                        IVA
                                    </td>
                                    <td
                                        className="alignright"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "right",
                                            borderTopWidth: "1px",
                                            borderTopColor: "#eee",
                                            borderTopStyle: "solid",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="right"
                                        valign="top">
                                        {formatNumber(detallesPrecio.iva)}
                                    </td>
                                </tr>
                                <tr
                                    className="total"
                                    style={{
                                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                    }}>
                                    <td
                                        width="80%"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "left",
                                            borderTopWidth: "5px",
                                            borderTopColor: "#333",
                                            borderTopStyle: "double",
                                            borderBottomColor: "#333",
                                            borderBottomWidth: "2px",
                                            borderBottomStyle: "solid",
                                            fontWeight: "700",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="left"
                                        valign="top">
                                        Total
                                    </td>
                                    <td
                                        className="alignright"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "right",
                                            borderTopWidth: "5px",
                                            borderTopColor: "#333",
                                            borderTopStyle: "double",
                                            borderBottomColor: "#333",
                                            borderBottomWidth: "2px",
                                            borderBottomStyle: "solid",
                                            fontWeight: "700",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="right"
                                        valign="top">
                                        {formatNumber(detallesPrecio.total)}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Card>
            </Card>

            <Card className="w-full">
                <CardBody>
                    <div>
                        <button
                            type="submit"
                            onClick={(e) => confirmSolicitudCompra(e, true)}
                            className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                            Solicitar
                        </button>
                    </div>
                </CardBody>
            </Card>

            {UISweetAlert.confirm_alert_solicitar ? (
                <SweetAlert
                    title="Se generará el registro de solicitud de compra para los proveedores"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        guardarSolicitudCompra();
                    }}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_solicitar: false,
                        })
                    }></SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_delete_proveedor ? (
                <SweetAlert
                    title="¿Desea eliminar el proveedor?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarProveedor()}
                    onCancel={() => {
                        setIndexProveedorBorrado(null);
                        setIndexProductoBorrado(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_delete_proveedor: false,
                        });
                    }}>
                    Se perderá la información
                </SweetAlert>
            ) : null}

            {/* Dejar al final los mensajes de loading, success, y error */}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}
        </div>
    );
}
