import React, { useReducer } from "react";

import ReporteDesplazamientoProductoContext from "./ReporteDesplazamientoProductoContext";
import ReporteDesplazamientoProductoReducer from "./ReporteDesplazamientoProductoReducer";

import { FILTRO_REPORTEDESPLAZAMIENTOPRODUCTO, LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO } from "../../types";

import clienteAxios from "../../config/axios";

const isEqual = require("lodash/isEqual");

export default function ReporteDesplazamientoProductoState(props) {
  const initialState = {
    reportesDesplazamientoProducto: [],
    filtrosReporteDesplazamientoProducto: {
      top10: false,
      anio: new Date().getFullYear(),
      tipoVenta: "",
    },
    filtrosReporteDesplazamientoProductoCache: {},
    reportesDesplazamientoProductoCache: null,
  };

  const [state, dispatch] = useReducer(ReporteDesplazamientoProductoReducer, initialState);

  const setFiltrosReporteDesplazamientoProducto = async (filtrosReporteDesplazamientoProducto) => {
    dispatch({
      type: FILTRO_REPORTEDESPLAZAMIENTOPRODUCTO,
      payload: filtrosReporteDesplazamientoProducto,
    });
  };

  const listarReporteDesplazamientoProducto = async (data) => {
    const res = await clienteAxios.post("/ordencompra/detalle/producto/desplazamiento", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO,
      payload: res.data,
    });

    return res;
  };

  const listarReporteDesplazamientoProductoPaginado = async ({ page, limit }) => {
    // Si tiene un tipo de venta, se hace la paginación desde el back
    if (state.filtrosReporteDesplazamientoProducto.tipoVenta !== "") {
      const res = await clienteAxios.post("/ordencompra/detalle/producto/desplazamiento/paginado",
        {
          pageSize: limit,
          pageIndex: page,
          ...state.filtrosReporteDesplazamientoProducto,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      dispatch({
        type: LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO,
        payload: res.data.productosDesplazamiento,
      });

      return res.data;
    } else {
      // Si se busca para todo tipo de venta, el back regresa todos los productos y en el front se hace la paginación
      // Si los filtros no cambiaron, se usan los productos guardados en el state para hacer la paginación de forma correcta
      if (isEqual(state.filtrosReporteDesplazamientoProducto, state.filtrosReporteDesplazamientoProductoCache)) {
        const data = await handlePagination({
          productosDesplazamiento: [...state.reportesDesplazamientoProductoCache],
          page,
          limit,
        });

        dispatch({
          type: LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO,
          payload: data,
        });

        const count = state.reportesDesplazamientoProductoCache ? state.reportesDesplazamientoProductoCache.length : 0;

        return { count: count, data: data };
        // Si no son los mismos filtros, se necesita mandar a traer de nuevo los datos en base a los filtros actualizados
      } else {
        if (!state.filtrosReporteDesplazamientoProducto.top10) {
          const res = await clienteAxios.post("/ordencompra/detalle/producto/desplazamiento/paginado",
            {
              pageSize: limit,
              pageIndex: page,
              ...state.filtrosReporteDesplazamientoProducto,
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
            }
          );
          // Se guardan los filtros nuevos en el cache de filtros
          state.filtrosReporteDesplazamientoProductoCache = state.filtrosReporteDesplazamientoProducto;
          // Se manda a llamar el método para manejar la paginación en el front
          const data = await handlePagination({ ...res.data, page, limit });
          // Se guardan los nuevos productos en el cache de productos
          state.reportesDesplazamientoProductoCache = res.data.productosDesplazamiento;

          dispatch({
            type: LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO,
            payload: data,
          });
          const count = res && res.data && res.data.productosDesplazamiento ? res.data.productosDesplazamiento.length : 0;

          return { count: count, data: data };
        } else {
          const res = await clienteAxios.post("/ordencompra/detalle/producto/desplazamiento/paginado",
            {
              pageSize: limit,
              pageIndex: page,
              ...state.filtrosReporteDesplazamientoProducto,
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
            }
          );

          let orderDataByTotalVendido = await res.data.productosDesplazamiento;
          orderDataByTotalVendido.sort((a, b) => b.totalVendido - a.totalVendido);

          const data = await handlePagination({ productosDesplazamiento: orderDataByTotalVendido, page: 1, limit: 10 });

          dispatch({
            type: LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO,
            payload: data,
          });
          const count = 10;

          return { count: count, data: data };
        }
      }
    }
  };

  // Se maneja la paginación, se usa page y limit para tomar una parte especifica del array de productos
  const handlePagination = async ({ productosDesplazamiento, page, limit }) => {
    // calculate the start and end index of the subset to extract
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    // extract the subset of the original array based on the start and end index
    const subset = productosDesplazamiento.slice(startIndex, endIndex);

    return subset;
  };

  const exportDesplazamientoProducto = async () => {
    try {
      const res = await clienteAxios.post(`/ordencompra/export/producto/desplazamiento`,
        state.filtrosReporteDesplazamientoProducto,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Disposition": "attachment; filename=desplazamiento.xlsx",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "desplazamiento.xlsx");
      document.body.appendChild(link);
      link.click();

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  const limpiarReportes = async () => {
    dispatch({
      type: LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO,
      payload: [],
    });
  };

  return (
    <ReporteDesplazamientoProductoContext.Provider
      value={{
        reportesDesplazamientoProducto: state.reportesDesplazamientoProducto,
        filtrosReporteDesplazamientoProducto: state.filtrosReporteDesplazamientoProducto,
        setFiltrosReporteDesplazamientoProducto,
        listarReporteDesplazamientoProducto,
        exportDesplazamientoProducto,
        limpiarReportes,
        listarReporteDesplazamientoProductoPaginado,
      }}>
      {props.children}
    </ReporteDesplazamientoProductoContext.Provider>
  );
}
