import React, { useReducer } from "react";

import RecepcionGenericaProductosContext from "./RecepcionGenericaProductosContext";
import RecepcionGenericaProductosReducer from "./RecepcionGenericaProductosReducer";

import {
  LISTAR_ORDENESCOMPRA,
  LISTAR_DEVOLUCIONESCANCELACIONES,
  SET_FILTROS_RECEPCIONGENERICA,
  FILTRO_DEVOLUCIONCANCELACION_VENTA,
  RECEPCIONPRODUCTOS_VENTA_SELECCIONADA,
  ERROR_RECEPCIONPRODUCTOS_VENTA_SELECCIONADA,
  ERROR_UPLOAD_RECEPCCIONPRODUCTOS_VENTA_SELECCIONADA,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function RecepcionGenericaProductosState(props) {
  const initialState = {
    recepcionGenericaProductoFiltro: {
      idTipoRecepcion: 1,
      idEstatusOrdenCompra: 1,
    },
    recepcionOrdenesCompra: [],
    recepcionDevolucionesOCancelaciones: [],
    filtroDevolucionCancelacionVenta: {},
    recepcionVentaProductosSeleccionada: {},
    errorRecepcionVentaProducto: false,
  };

  const [state, dispatch] = useReducer(RecepcionGenericaProductosReducer, initialState);

  const listarRecepcionCompras = async () => {
    const res = await clienteAxios.post("/recepcionproducto/generica/compra", state.recepcionGenericaProductoFiltro, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_ORDENESCOMPRA,
      payload: res.data,
    });
  };

  const listarRecepcionComprasPaginado = async ({ page, limit }) => {
    // LLAMADA A BACKEND
    try {
      const response = await clienteAxios.post(
        "/recepcionproducto/generica/compra/paginado",
        {
          pageSize: limit,
          pageIndex: page,
          ...state.recepcionGenericaProductoFiltro,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      dispatch({
        type: LISTAR_ORDENESCOMPRA,
        payload: response.data.ordenCompraVoList,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const listarDevolucionesOCancelaciones = async () => {
    const res = await clienteAxios.post("/recepcionproducto/generica/venta", state.recepcionGenericaProductoFiltro, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_DEVOLUCIONESCANCELACIONES,
      payload: res.data,
    });
  };

  const listarDevolucionesOCancelacionesPaginado = async ({ page, limit }) => {
    // LLAMADA A BACKEND
    try {
      const response = await clienteAxios.post(
        "/recepcionproducto/generica/venta/paginado",
        {
          pageSize: limit,
          pageIndex: page,
          ...state.recepcionGenericaProductoFiltro,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      dispatch({
        type: LISTAR_DEVOLUCIONESCANCELACIONES,
        payload: response.data.ventaValeMostradorVoList,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setFiltrosRecepcionGenericaProductos = async (recepcionGenericaProductoFiltro) => {
    dispatch({
      type: SET_FILTROS_RECEPCIONGENERICA,
      payload: recepcionGenericaProductoFiltro,
    });
  };

  const setRecepcionOrdenesCompra = async (recepcionOrdenesCompra) => {
    dispatch({
      type: LISTAR_ORDENESCOMPRA,
      payload: recepcionOrdenesCompra,
    });
  };

  const setRecepcionDevolucionOCancelacion = async (recepcionDevolucionesOCancelaciones) => {
    dispatch({
      type: LISTAR_DEVOLUCIONESCANCELACIONES,
      payload: recepcionDevolucionesOCancelaciones,
    });
  };

  const setFiltroDevolucionCancelacionVenta = async (filtroDevolucionCancelacionVenta) => {
    localStorage.setItem("filtroDevolucionCancelacionVenta", JSON.stringify(filtroDevolucionCancelacionVenta));
    dispatch({
      type: FILTRO_DEVOLUCIONCANCELACION_VENTA,
      payload: filtroDevolucionCancelacionVenta,
    });
  };

  const limpiarFiltroDevolucionCalcecionVenta = async () => {
    setFiltroDevolucionCancelacionVenta({});
    localStorage.removeItem("filtroDevolucionCancelacionVenta");
  };

  const seleccionarVenta = async () => {
    try {
      const res = await clienteAxios.post("/recepcionproducto/generica/venta/detalles", state.filtroDevolucionCancelacionVenta, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      dispatch({
        type: RECEPCIONPRODUCTOS_VENTA_SELECCIONADA,
        payload: res.data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_RECEPCIONPRODUCTOS_VENTA_SELECCIONADA,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setRecepcionVentaProductosSeleccionada = async (recepcionVentaProductosSeleccionada) => {
    dispatch({
      type: RECEPCIONPRODUCTOS_VENTA_SELECCIONADA,
      payload: recepcionVentaProductosSeleccionada,
    });
  };

  const recepcionProductosVentaUpload = async () => {
    try {
      const res = await clienteAxios.post("/recepcionproducto/actualizar/venta", state.recepcionVentaProductosSeleccionada, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      dispatch({
        type: RECEPCIONPRODUCTOS_VENTA_SELECCIONADA,
        payload: res.data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_UPLOAD_RECEPCCIONPRODUCTOS_VENTA_SELECCIONADA,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const limpiarRecepcionVentaSeleccionada = async () => {
    dispatch({
      type: RECEPCIONPRODUCTOS_VENTA_SELECCIONADA,
      payload: {},
    });
  };

  const limpiarListaOrdenesCompra = async () => {
    dispatch({
      type: LISTAR_ORDENESCOMPRA,
      payload: [],
    });
  };

  const limpiarListaDevolucionesCancelaciones = async () => {
    dispatch({
      type: LISTAR_DEVOLUCIONESCANCELACIONES,
      payload: [],
    });
  };

  return (
    <RecepcionGenericaProductosContext.Provider
      value={{
        recepcionGenericaProductoFiltro: state.recepcionGenericaProductoFiltro,
        recepcionOrdenesCompra: state.recepcionOrdenesCompra,
        recepcionDevolucionesOCancelaciones: state.recepcionDevolucionesOCancelaciones,
        filtroDevolucionCancelacionVenta: state.filtroDevolucionCancelacionVenta,
        recepcionVentaProductosSeleccionada: state.recepcionVentaProductosSeleccionada,
        errorRecepcionVentaProducto: state.errorRecepcionVentaProducto,
        listarRecepcionCompras,
        listarDevolucionesOCancelaciones,
        setFiltrosRecepcionGenericaProductos,
        setRecepcionOrdenesCompra,
        setRecepcionDevolucionOCancelacion,
        setFiltroDevolucionCancelacionVenta,
        limpiarFiltroDevolucionCalcecionVenta,
        seleccionarVenta,
        setRecepcionVentaProductosSeleccionada,
        recepcionProductosVentaUpload,
        limpiarRecepcionVentaSeleccionada,
        limpiarListaOrdenesCompra,
        limpiarListaDevolucionesCancelaciones,
        listarRecepcionComprasPaginado,
        listarDevolucionesOCancelacionesPaginado,
      }}>
      {props.children}
    </RecepcionGenericaProductosContext.Provider>
  );
}
