import { LISTAR_CITAS, FILTRO_CITAS_ESPACIOS, FILTRO_CITAS_PACIENTES } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_CITAS:
      return {
        ...state,
        citas: action.payload,
      };
    case FILTRO_CITAS_ESPACIOS:
      return {
        ...state,
        filtroCitasEspacios: action.payload,
      };
    case FILTRO_CITAS_PACIENTES:
      return {
        ...state,
        filtroCitasPacientes: action.payload,
      };
    default:
      return state;
  }
};
