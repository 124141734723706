import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import CatTipoProductoContext from "../../context/CatTipoProducto/CatTipoProductoContext";
import ConsultaCompraProductoContext from "../../context/ConsultaCompraProducto/ConsultaCompraProductoContext";
import CatLaboratoriosContext from "../../context/CatLaboratorios/CatLaboratoriosContext";
import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";
import TableComponent from "../TablaPaginadaSinUpdate";

export default function ConsultaCompraProducto(props) {
  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
  const [toggleSearchBy, setToggleSearchBy] = useState(false);
  const [loading, setLoading] = useState(false);

  const [UISweetAlert, setUISweetAlert] = useState({
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  //ACCESO AL ESTADO GLOBAL - Proveedores
  const ctxProveedores = useContext(ProveedoresContext);
  const { proveedores, listarProveedores } = ctxProveedores;

  //ACCESO AL ESTADO GLOBAL - Tipo Productos
  const ctxTipoProducto = useContext(CatTipoProductoContext);
  const { tiposProductos, listarProductoTiposProductos } = ctxTipoProducto;

  //ACCESO AL ESTADO GLOBAL - Laboratorios
  const ctxLaboratorios = useContext(CatLaboratoriosContext);
  const { catLaboratorios, listarLaboratorios } = ctxLaboratorios;

  //ACCESO AL ESTADO GLOBAL - Productos Servicios
  const ctxProdServ = useContext(ProductosServiciosContext);
  const {
    principiosActivos,
    listarPrincipiosActivos,
    nombresComerciales,
    numerosIdentificacion,
    listarNombresComerciales,
    listarNumerosIdentificacion,
  } = ctxProdServ;

  //ACCESO AL ESTADO GLOBAL - Consulta Compras Producto
  const ctxConsulCompraProd = useContext(ConsultaCompraProductoContext);
  const {
    filtrosConsultaComprasProducto,
    setFiltrosConsultaComprasProducto,
    consultaComprasProductoFiltradas,
    exportConsultaComprasProducto,
    listarConsultaComprasProductoPaginado,
  } = ctxConsulCompraProd;

  const onChange = (e) => {
    if (e.target.name == "fechaInicial") {
      setFiltrosConsultaComprasProducto({
        ...filtrosConsultaComprasProducto,
        [e.target.name]: e.target.value,
        fechaFinal: e.target.value,
      });
    } else {
      setFiltrosConsultaComprasProducto({
        ...filtrosConsultaComprasProducto,
        [e.target.name]: e.target.value,
      });
    }
  };

  const buscarPorFiltros = (e) => {
    e.preventDefault();

    if (filtrosConsultaComprasProducto.fechaInicial && !filtrosConsultaComprasProducto.fechaFinal) {
      return;
    }

    if (filtrosConsultaComprasProducto.fechaFinal) {
      filtrosConsultaComprasProducto.fechaFinal = filtrosConsultaComprasProducto.fechaFinal + "T23:59:59";
    }

    fetchData({ pageSize: 10, pageIndex: 0 });

    if (filtrosConsultaComprasProducto.fechaFinal) {
      filtrosConsultaComprasProducto.fechaFinal = filtrosConsultaComprasProducto.fechaFinal.split("T")[0];
    }
  };

  const buscarPorFolio = (e) => {
    e.preventDefault();
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const toggleSearch = () => {
    setToggleSearchBy(!toggleSearchBy);
    limpiarFiltros();
  };

  const limpiarFiltros = () => {
    setFiltrosConsultaComprasProducto({
      fechaInicial: "",
      fechaFinal: "",
      tipoProducto: null,
      proveedor: null,
      laboratorio: null,
      numIdentificacion: null,
      principioActivo: null,
      nombreComercial: null,
      folioOrdenCompra: null,
    });
  };

  const exportarTablaExcel = async () => {
    setLoading(true);
    const res = await exportConsultaComprasProducto();

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
    }
  };

  const setNumeroIdentificacion = async (option) => {
    setFiltrosConsultaComprasProducto({
      ...filtrosConsultaComprasProducto,
      numIdentificacion: option && option.numIdentificacion ? option.numIdentificacion : "",
    });
  };
  
  const setNombreComercial = async (option) => {
    setFiltrosConsultaComprasProducto({
      ...filtrosConsultaComprasProducto,
      nombreComercial: option && option.nombreComercial ? option.nombreComercial : "",
    });
  };

  const setPrincipioActivo = async (option) => {
    setFiltrosConsultaComprasProducto({
      ...filtrosConsultaComprasProducto,
      principioActivo: option && option.principioActivo ? option.principioActivo : "",
    });
  };

  useEffect(() => {
    const fetchAllContextData = () => {
      listarProductoTiposProductos();
      listarProveedores();
      listarLaboratorios();
      listarPrincipiosActivos();
      listarNombresComerciales();
      listarNumerosIdentificacion();
    };

    fetchAllContextData();
  }, []);

  const fetchIdRef = useRef(0);
  const [loadingData, setLoadingData] = useState(false);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await listarConsultaComprasProductoPaginado({ page, limit });

      //Cantidad de datos disponibles en la tabla servicios de la base de datos
      const count = response.count;

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const columns = useMemo(
    () => [
      { Header: "Tipo", accessor: "tipoProducto" },
      { Header: "Número de identificación", accessor: "numIdentificacion" },
      { Header: "Principio activo", accessor: "principioActivo" },
      { Header: "Nombre comercial", accessor: "nombreComercial" },
      { Header:  "Vale / Venta", accessor: "vales", show: true , className:"fixed-width-150",
        Cell: ({ value }) => (
          <div style={{ width: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
            {value}
          </div>
        )
      },
      { Header: "Fabricante", accessor: "laboratorio" },
      { Header: "Proveedor", accessor: "proveedor" },
      { Header: "Orden compra", accessor: "folioOrdenCompra" },
      {
        Header: () => (
          <>
            Fecha de<br></br>compra
          </>
        ),
        accessor: "fechaRecepcion",
        Cell: ({ row }) => {
          return <>{row.original.fechaRecepcion ? row.original.fechaRecepcion.split("T")[0] : ""}</>;
        },
      },
    ],
    []
  );
  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Consulta de Compras por Producto</h3>
        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearch}>
          {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio de compra"}
        </button>
        <Card
          hidden={!toggleSearchBy}
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFolio(e)}>
            <CardBody>
              <Row>
                <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                  <Row
                    className="form-group"
                    style={{
                      marginBottom: "0",
                    }}>
                    <Col sm={10} className="flex col-sm-10">
                      <input
                        className="form-control mr-2.5"
                        type="text"
                        name="folioOrdenCompra"
                        placeholder="Escribe tu folio"
                        onChange={onChange}
                        value={
                          filtrosConsultaComprasProducto.folioOrdenCompra ? filtrosConsultaComprasProducto.folioOrdenCompra : ""
                        }
                      />
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                        style={{
                          marginRight: "2.5rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        type="submit">
                        Buscar
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>
        <Card
          hidden={toggleSearchBy}
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFiltros(e)}>
            <CardBody>
              <Row>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaInicial"
                        onChange={onChange}
                        value={filtrosConsultaComprasProducto.fechaInicial}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Tipo de Producto</label>
                    <Col sm={7}>
                      <select
                        className="form-control"
                        name="tipoProducto"
                        onChange={onChange}
                        value={filtrosConsultaComprasProducto.tipoProducto ? filtrosConsultaComprasProducto.tipoProducto : ""}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tiposProductos.map((tipoProd) => {
                          return (
                            <>
                              <option value={tipoProd.idCatTipoProducto}>{tipoProd.tipoProducto}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Proveedor</label>
                    <Col sm={7}>
                      <select
                        name="proveedor"
                        className="form-control"
                        onChange={onChange}
                        value={filtrosConsultaComprasProducto.proveedor ? filtrosConsultaComprasProducto.proveedor : ""}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {proveedores.map((prov) => {
                          return (
                            <>
                              <option value={prov.idCatProveedor}>{prov.proveedor}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fabricante</label>
                    <Col sm={7}>
                      <select
                        name="laboratorio"
                        className="form-control"
                        onChange={onChange}
                        value={filtrosConsultaComprasProducto.laboratorio ? filtrosConsultaComprasProducto.laboratorio : ""}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {catLaboratorios.map((laboratorio) => {
                          return (
                            <>
                              <option value={laboratorio.idCatLaboratorio}>{laboratorio.laboratorio}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fecha Final</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaFinal"
                        disabled={!filtrosConsultaComprasProducto.fechaInicial ? true : false}
                        min={filtrosConsultaComprasProducto.fechaInicial}
                        onChange={onChange}
                        value={filtrosConsultaComprasProducto.fechaFinal}
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Nombre Comercial</label>
                    <Col sm={7}>
                      <Select
                        isSearchable
                        isClearable
                        options={nombresComerciales}
                        getOptionValue={(option) => option.idCatProducto}
                        getOptionLabel={(option) => `${option.nombreComercial}`}
                        value={nombresComerciales.filter((nombreComercial) => nombreComercial.nombreComercial 
                            == filtrosConsultaComprasProducto.nombreComercial)}
                        onChange={(option) => setNombreComercial(option)}
                        placeholder="Seleccione una opción"
                        noOptionsMessage={() => "No hay opciones"}
                        name="nombreComercial"
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Número de Identificación</label>
                    <Col sm={7}>
                      <Select
                        isSearchable
                        isClearable
                        options={numerosIdentificacion}
                        getOptionValue={(option) => option.idCatProducto}
                        getOptionLabel={(option) => `${option.numIdentificacion}`}
                        value={numerosIdentificacion.filter((numIdentificacion) => numIdentificacion.numIdentificacion 
                            == filtrosConsultaComprasProducto.numIdentificacion)}
                        onChange={(option) => setNumeroIdentificacion(option)}
                        placeholder="Seleccione una opción"
                        noOptionsMessage={() => "No hay opciones"}
                        name="numIdentificacion"
                      />
                    </Col>
                  </Row>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Principio Activo</label>
                    <Col sm={7}>
                      <Select
                        isSearchable
                        isClearable
                        options={principiosActivos}
                        getOptionValue={(option) => option.idCatProducto}
                        getOptionLabel={(option) => `${option.principioActivo}`}
                        value={principiosActivos.filter((principioActivo) => principioActivo.principioActivo 
                            == filtrosConsultaComprasProducto.principioActivo)}
                        onChange={(option) => setPrincipioActivo(option)}
                        placeholder="Seleccione una opción"
                        noOptionsMessage={() => "No hay opciones"}
                        name="principioActivo"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="submit"
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Aplicar Filtros
                  </button>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="button"
                    onClick={limpiarFiltros}
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Limpiar Filtros
                  </button>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col>
                {consultaComprasProductoFiltradas.length > 0 && (
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                    type="button"
                    onClick={exportarTablaExcel}>
                    Exportar (Excel)
                  </button>
                )}
              </Col>
            </Row>
            <TableComponent
              pageCount={pageCount}
              fetchData={fetchData}
              columns={columns}
              loading={loadingData}
              data={consultaComprasProductoFiltradas}
              indexOfPage={controlledPageIndex}
            />
            {}
          </CardBody>
        </Card>
      </div>

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </>
  );
}
