import React, {useContext, useEffect, useState} from "react";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import AuthContext from "../../context/Auth/AuthContext";
import EspaciosAtencionContext from "../../context/EspaciosAtencion/EspaciosAtencionContext";
import CatTipoEspacioContext from "../../context/CatTipoEspacio/CatTipoEspacioContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import WindowedSelect from "react-windowed-select";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import useWindowDimensions from "../../hooks/dimensionsHook";

import SpanError from "../UI/spanError";
import {formatCatPersonaFullName} from "../../utils/formatCatPersona";

export default function EspaciosAtencion(props) {
    const {width} = useWindowDimensions();

    

    const [espaciosAtencionFiltrados, setEspaciosAtencionFiltrados] = useState([]);
    const [espacioAtencion, setEspacioAtencion] = useState({});
    const [diaEliminar, setDiaEliminar] = useState({});
    const [horarioEliminar, setHorarioEliminar] = useState({});
    const [tipoEspacioId, setTipoEspacioId] = useState(0)
    const [inicialFiltroVariable, setInicialFiltroVariable ] = useState("")
    const [message, setMessage] = useState('');  // BORRAR EL INPUT DE FILTRO POR LETRA CUANDO SE APLIQUE BOTON FILTRO

    const [loading, setLoading] = useState(false);

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_espacio: false,
        editar_espacios: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    const ctxUsuarios = useContext(UsuariosContext);
    const {
      usuarios,
      listarUsuarios,
      usuarioEditar,
      setUsuarioEditar,
      uploadEditarUsario,
      nuevoUsuario,
      setNuevoUsuario,
      uploadNuevoUsuario,
      solicitarReinicioPassword,
    } = ctxUsuarios;

    const [usuariosAsistentes, setUsuariosAsistentes] = useState([]);

    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    const ctxTipoEspacio = useContext(CatTipoEspacioContext);
    const {tipoEspacios, listarTipoEspacios} = ctxTipoEspacio;

    const ctxMedico = useContext(MedicosContext);
    const {medicosAgenda, listarMedicosAgenda} = ctxMedico;
    const {medicosActivos, listarMedicosActivos} = ctxMedico;

    const espAtnCntx = useContext(EspaciosAtencionContext);

    const {
        espaciosAtencion,
        setEspaciosAtencion,
        listarEspaciosAtencion,
        filtrosEspaciosAtencion,
        setFiltrosEspaciosAtencion,
        uploadEspacioAtencion,
    } = espAtnCntx;

    // ESTA FUNCION ES LANZADA CUANDO EL SELECT DE FILTRO DE ESPACIO ES USADO, TRAE EL ID Y LO INGRESA LA VARIABLE
    // STATE tipoEspacioId Y SU FUNCION DE CONTROL ES   setTipoEspacioId
    const onChange = async (e) => {
        //setFiltrosEspaciosAtencion({
        //    ...filtrosEspaciosAtencion,
        //    [e.target.name]: e.target.value,
        //});
        setTipoEspacioId(e.target.value)
    };
    // ESTA FUNCION ES LANZADA CUANDO EL BOTON DE FILTRO ES ACCIONADO, USA EL ARREGLO GENERAL espaciosAtencion PARA 
    // HACER UN SUB ARREGLO QUE ES EL QUE SE MANDA A LA TABLA, EL CUAL YA ESTARÁ FILTRADO DE ACUERDO AL CRITERIO SELECCIONADO,
    // DICHO SUB ARREGLO ES espaciosAtencionFiltrados Y SE HACE FILTRANDO POR EL TIPO DE ESPACIO tipoEspacioId
    const BotonOnClick = async() =>{
        setMessage(''); // BORRAMOS LO QUE ESTE ESCRITO EN EL INPUT DE FILTRO POR LETRAS
        setInicialFiltroVariable("")
        if (tipoEspacioId!=''){
            const lista = await listarEspaciosAtencion(false);
            setFiltrosEspaciosAtencion(lista); 

            setEspaciosAtencionFiltrados(
                espaciosAtencion.filter((espacio) => {
                    if (espacio.idCatTipoEspacio == tipoEspacioId) {
                        return true;
                    } else {
                        return false;
                    }
                })
            );
        }else{
            const lista = await listarEspaciosAtencion(false);
            setEspaciosAtencionFiltrados(espaciosAtencion);
        }
    }

    // ESTA ES LA FUNCION QUE SE LANZA CUANDO EN EL INPUT DE FILTRO SE HACE UN CAMBIO YA SEA AGREGANDO UNA LETRA O QUITANDO
    // UNA LETRA, USA EL ARREGLO GENERAL espaciosAtencion Y HACE EL SUB ARREGLO espaciosAtencionFiltrados QUE VA A LA 
    // TABLA DEPENDIENDO  DEL TIPO DE ESPACIO Y LAS INCIALES QUE SE ESTEN ESCRIBIENDO
    const onChangeFiltrarNombre = async (e) => {
        setMessage(e.target.value);
        setInicialFiltroVariable(e.target.value)
        
        setFiltrosEspaciosAtencion({
            ...filtrosEspaciosAtencion,
            [e.target.name]: e.target.value,
        });

        let value = e.target.value;
        if (tipoEspacioId!=''){
            setEspaciosAtencionFiltrados(
                espaciosAtencion.filter((espacio) => {
                    if (espacio.espacio.toLowerCase().startsWith(value.toLowerCase(), 0) && espacio.idCatTipoEspacio == tipoEspacioId) {
                        return true;
                    } else {
                        return false;
                    }
                })
            );
        }else{
            setEspaciosAtencionFiltrados(
                espaciosAtencion.filter((espacio) => {
                    if (espacio.espacio.toLowerCase().startsWith(value.toLowerCase(), 0)) {
                        return true;
                    } else {
                        return false;
                    }
                })
            );
        }
    };

    const confirmEspacioAtencion = async () => {
        let errorEspacioAtencion = false;

        if (!espacioAtencion.idEspacio && !espacioAtencion.idCatTipoEspacio && !errorEspacioAtencion) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione el tipo de espacio de atención",
            });
            errorEspacioAtencion = true;
            return;
        }
        
        if (!espacioAtencion.idEspacio && espacioAtencion.idCatTipoEspacio == 1 &&
            !espacioAtencion.idCatMedico && !errorEspacioAtencion) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione el medico para el espacio de atención",
            });
            errorEspacioAtencion = true;
            return;
        }

        if ((espacioAtencion.idCatTipoEspacio == 2 || espacioAtencion.idCatTipoEspacio == 4) 
                && !espacioAtencion.espacio && !errorEspacioAtencion) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, introduzca el nombre para el espacio de atención",
            });
            errorEspacioAtencion = true;
            return;
        }

        espacioAtencion.semana.map((dia) => {
            if (dia.disponible && (!dia.horarios || dia.horarios.length < 1) && !errorEspacioAtencion) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: `Por favor, introduzca al menos un horario disponible para el ${dia.dia}`,
                });
                errorEspacioAtencion = true;
                return;
            }

            if (!errorEspacioAtencion && dia.horarios) {
                dia.horarios.map((horario, index) => {
                    if (!horario.inicio && !errorEspacioAtencion) {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: `Por favor, introduzca la hora de inicio para el nuevo horario para el día ${dia.dia}`,
                        });
                        errorEspacioAtencion = true;
                        return;
                    }

                    if (!horario.termino && !errorEspacioAtencion) {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: `Por favor, introduzca la hora final para el nuevo horario para el día ${dia.dia}`,
                        });
                        errorEspacioAtencion = true;
                        return;
                    }

                    const formatHoraInicio = /(^[0-9]{2})(:{1})([0-9]{2})/.test(horario.inicio);
                    const formatHoraTermino = /(^[0-9]{2})(:{1})([0-9]{2})/.test(horario.termino);

                    if (!errorEspacioAtencion && (!formatHoraInicio || !formatHoraTermino)) {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: `Por favor, introduzca el formato de hora correcta 'hh:mm' para el día ${dia.dia}`,
                        });
                        errorEspacioAtencion = true;
                        return;
                    }

                    if (Date.parse("01/01/1999 " + horario.inicio + ":00") >= Date.parse("01/01/1999 " + horario.termino + ":00") 
                        && !errorEspacioAtencion) {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: `Por favor, la hora de inicio debe ser menor a la hora de termino para el día ${dia.dia}`,
                        });
                        errorEspacioAtencion = true;
                        return;
                    }

                    if (!errorEspacioAtencion && dia.horarios[index + 1] && dia.horarios[index + 1].disponible 
                        && horario.termino.disponible) {
                        if (Date.parse("01/01/1999 " + horario.termino + ":00") >=
                            Date.parse("01/01/1999 " + dia.horarios[index + 1].inicio + ":00")) {
                            setUISweetAlert({
                                ...UISweetAlert,
                                error_dlg: true,
                                dynamic_title: "Fallo",
                                dynamic_description: `Por favor, la hora de inicio no debe ser mayor a la hora final del horario anterior en el renglon ${
                                    index + 1
                                } para el día ${dia.dia}`,
                            });
                            errorEspacioAtencion = true;
                            return;
                        }
                    }
                });
            }
        });
        
        //ACA ESTA LA LINEA DONDE SE ENVIA EL NUEVO ESPACIO DE ATENCION
        if (!errorEspacioAtencion) {
            setLoading(true);
            const res = await uploadEspacioAtencion(espacioAtencion);

            if (res.data.error) {
                setLoading(false);

                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else if (res) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_alert_espacio: false,
                    dynamic_description: "Se generó un nuevo espacio de atención",
                });

                if (espacioAtencion.idEspacio) {
                    const listaEspAtn = await espaciosAtencion.map((espacio) => {
                        if (espacio.idEspacio == res.data.idEspacio) {
                            return res.data;
                        } else {
                            return espacio;
                        }
                    });

                    setEspaciosAtencion(listaEspAtn);
                    //setInicialFiltroVariable("")
                    //setMessage("");
                    if (tipoEspacioId!=''){
                        setEspaciosAtencionFiltrados(
                            listaEspAtn.filter((espacio) => 
                            {
                                if (espacio.espacio !== null && espacio.espacio !== undefined) 
                                {
                                    if (espacio.espacio.toLowerCase().startsWith(inicialFiltroVariable.toLowerCase(), 0) 
                                    && espacio.idCatTipoEspacio == tipoEspacioId){
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            })
                        );
                    }else{
                        setEspaciosAtencionFiltrados(
                            listaEspAtn.filter((espacio) => 
                            {
                                if (espacio.espacio !== null && espacio.espacio !== undefined) 
                                {
                                    if (espacio.espacio.toLowerCase().startsWith(inicialFiltroVariable, 0)){
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            })
                        );                        
                        
                    }
                } else {
                    let listaEspAtn;

                    if (filtrosEspaciosAtencion.tipoEspacio && filtrosEspaciosAtencion.tipoEspacio == res.data.idCatTipoEspacio) {
                        listaEspAtn = await espaciosAtencion.concat(res.data);
                    } else if (!filtrosEspaciosAtencion.tipoEspacio) {
                        listaEspAtn = await espaciosAtencion.concat(res.data);
                    } else {
                        listaEspAtn = espaciosAtencion;
                    }

                    setEspaciosAtencion(listaEspAtn);

                    if (tipoEspacioId!=''){
                        setEspaciosAtencionFiltrados(
                            listaEspAtn.filter((espacio) => 
                            {
                                if (espacio.espacio !== null && espacio.espacio !== undefined) 
                                {
                                    if (espacio.espacio.toLowerCase().startsWith(inicialFiltroVariable.toLowerCase(), 0) 
                                    && espacio.idCatTipoEspacio == tipoEspacioId){
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            })
                        );
                    }else{
                        setEspaciosAtencionFiltrados(
                            listaEspAtn.filter((espacio) => 
                            {
                                if (espacio.espacio !== null && espacio.espacio !== undefined) 
                                {
                                    if (espacio.espacio.toLowerCase().startsWith(inicialFiltroVariable.toLowerCase(), 0)){
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            })
                        );                        
                        
                    }
                }
                setEspacioAtencion({});
            }
        }
    };

    const onChangeEspacioAtencion = async (e) => {
        let value = e.target.value;

        if (e.target.name == "idCatTipoEspacio") {
            espacioAtencion.idCatMedico = "";
            espacioAtencion.espacio = "";
            espacioAtencion.idAsistente = "";
        }

        if (e.target.name == "activo") {
            value = !espacioAtencion.activo;  
        }

        setEspacioAtencion({
            ...espacioAtencion,
            [e.target.name]: value,
        });
    };

    const onChangeMedicosConsultorio = (options) => {
        // Update the medicos property with the extracted idCatMedicos array
        setEspacioAtencion({
            ...espacioAtencion,
            medicos: options
        });
    }

    const toggleExpanded = async (dia) => {
        dia.expanded = !dia.expanded;
        setEspacioAtencion(espacioAtencion);
        refreshModal();
    };

    const refreshModal = async () => {
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_espacio: false,
        });
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_espacio: true,
        });
    };

    const handdleCheckedDisponibilidad = async (dia) => {
        dia.disponible = !dia.disponible;

        if (!dia.disponible) {
            dia.horarios = [];
            dia.expanded = false;
        }
        setEspacioAtencion(espacioAtencion);
        refreshModal();
    };

    const nuevoHorario = async (dia) => {
        if (!dia.horarios) {
            dia.horarios = [];
        }

        let horarioNuevoObj = {
            inicio: "",
            termino: "",
            disponible: true,
        };

        dia.horarios = dia.horarios.concat(horarioNuevoObj);
        setEspacioAtencion(espacioAtencion);
        refreshModal();
    };

    const generarNuevoEspacio = async () => {
        setEspacioAtencion({
            semana: [
                {
                    dia: "Lunes",
                    idDia: 1,
                    disponible: false,
                },
                {
                    idDia: 2,
                    dia: "Martes",
                    disponible: false,
                },
                {
                    idDia: 3,
                    dia: "Miercoles",
                    disponible: false,
                },
                {
                    idDia: 4,
                    dia: "Jueves",
                    disponible: false,
                },
                {
                    idDia: 5,
                    dia: "Viernes",
                    disponible: false,
                },
                {
                    idDia: 6,
                    dia: "Sabado",
                    disponible: false,
                },
                {
                    idDia: 7,
                    dia: "Domingo",
                    disponible: false,
                },
            ],
            activo: true,
        });
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_espacio: true,
            editar_espacios: false,
        });
    };

    const changeHoraInicio = async (e, horario) => {
        let value = e.target.value;

        if (value.length == 2 && !value.includes(":")) {
            value = value + ":";
        }

        if (value.length == 6) {
            value = value.substring(0, 5);
        }

        horario.inicio = value;
        setEspacioAtencion(espacioAtencion);
        refreshModal();
    };

    const changeHoraFinal = async (e, horario) => {
        let value = e.target.value;
        
        if (value.length == 2 && !value.includes(":")) {
            value = value + ":";
        }

        if (value.length == 6) {
            value = value.substring(0, 5);
        }

        horario.termino = value;

        setEspacioAtencion(espacioAtencion);
        refreshModal();
    };

    const checkHorariosEspacioAtencion = async () => {
        if (espacioAtencion.idEspacio) {
            espacioAtencion.semana.map((dia) => {
                if (dia.disponible) {
                    dia.horarios = dia.horarios.filter((horario) => {
                        if (horario.inicio == "" || horario.termino == "") {
                            return false;
                        } else {
                            return true;
                        }
                    });
                }
            });

            setEspacioAtencion(espacioAtencion);
        }

        setEspacioAtencion({});
    };

    const eliminarHorario = async () => {
        diaEliminar.horarios = diaEliminar.horarios.filter((horariosDisp) => horariosDisp != horarioEliminar);
        setEspacioAtencion(espacioAtencion);
        refreshModal();

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_eliminar_horario: false,
            success_dlg: true,
            dynamic_title: "Exito!",
            dynamic_description: "Horario ha sido eliminado",
        });
    };

    const isEspacioChecked = async () => {
        let espacioChecked = false;

        espaciosAtencionFiltrados.map((espacio) => {
            if (espacio.checked) {
                espacioChecked = espacio;
            }
        });

        if (!espacioChecked) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione un espacio de atención",
            });
        }

        return espacioChecked;
    };

    const eliminarEspacioAtencion = async () => {
        const espacioChecked = await isEspacioChecked();

        setEspaciosAtencionFiltrados(espaciosAtencionFiltrados.filter((espacio) => espacio != espacioChecked));

        setUISweetAlert({
            ...UISweetAlert,
            success_dlg: true,
            dynamic_title: "Exito",
            confirm_alert_eliminar_espacio: false,
            dynamic_description: "Se ha eliminado el espacio de atención",
        });
    };

    function VerificaRolAsistente(objetopar)
    {
        let resultado = false;
        objetopar.map((filtroint) => 
        {
            let objetotemp = filtroint.authority;
            if (objetotemp=="ROLE_ASISTENTE") {
                resultado = true
            }
        })
       return resultado;
    }

    useEffect(() => {
        const fetchEspacios = async () => {
            const lista = await listarEspaciosAtencion(false);
            setEspaciosAtencionFiltrados(lista);

            setFiltrosEspaciosAtencion({
                ...filtrosEspaciosAtencion,
                nombreEspacio: "",
            });
        };
        fetchEspacios();
    }, []);

    useEffect(() => {
        const fetchMedicosytipoEspaciosAgenda = async () => {
            const listaMedicos = await listarMedicosAgenda();
            const listaMedicosActivos = await listarMedicosActivos();
            const listaTipoEspacios = await listarTipoEspacios();
            
            const lista = await listarUsuarios();

            setUsuariosAsistentes
            (
                lista.filter((usuariosp) => 
                {
                    if (VerificaRolAsistente(usuariosp.catRoles)) 
                    {        
                        return usuariosp;
                    } else {
                        return false;
                    }
                })
            );

            return {
                listaMedicos: listaMedicos, 
                listaMedicosActivos: listaMedicosActivos,
                listaTipoEspacios: listaTipoEspacios,
                lista:lista
            };
        };

        fetchMedicosytipoEspaciosAgenda();
    }, []);

    const checkTipoEspacio = (idCatTipoEspacio) => {
        switch (idCatTipoEspacio) {
            case 1:
                return "Médico"
            case 2:
                return "Espacio"
            case 3:
                return "Tratamiento"
            case 4:
                return "Consultorio"
            default:
                return "Espacio"
        }
    }

    return (
        <>
            <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
                <h3 className="flex h3">Espacios de Atención</h3>

                <Card className="mt-10">
                    <form >
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Tipo de Espacio</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            onChange={onChange}
                                            name="tipoEspacio"
                                            value={filtrosEspaciosAtencion.tipoEspacio}>
                                            <option selected value="">
                                                TODOS LOS ESPACIOS
                                            </option>
                                            {tipoEspacios.map((tipoespacio) => {
                                                return (
                                                    <>
                                                    <option value={tipoespacio.idCatTipoEspacio}>{tipoespacio.tipoEspacio}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick={() => BotonOnClick()}
                                    style={{
                                        marginRight: "2.5rem",
                                        background: "#ff9933",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}>
                                    Aplicar Filtro
                                </button>
                            </Col>
                        </Row>
                    </form>
                </Card>

                <Row>
                    <Col>
                        <Card className="mt-4">
                            <CardBody>
                                <Row >
                                    <Col>
                                        <button
                                            className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-2 mr-4"
                                            type="button"
                                            hidden={
                                                usuario.authorities.includes("OP_CONSULTA_ESPACIOS_CREAR") ||
                                                usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => generarNuevoEspacio()}>
                                            Nuevo Espacio
                                        </button>
                                        {}
                                    </Col>
                                    <label className="col-sm-1 col-form-label">Nombre</label>                                          
                                    <Col sm={4}>
                                            <input
                                            className="form-control"
                                            type="text"
                                            name="nombreEspacio"
                                            placeholder="Nombre del espacio..."
                                            value={message}
                                            onChange={onChangeFiltrarNombre}
                                            //value={filtrosEspaciosAtencion.nombreEspacio ? filtrosEspaciosAtencion.nombreEspacio : ""}
                                        />                                            
                                    </Col>
                                </Row>
                                <div className="table-responsive mt-4">
                                    <table className="table table-hover table-centered table-nowrap mb-0">
                                        <thead>
                                        <tr>
                                            {/* <th scope="col"></th> */}
                                            <th scope="col">Nombre</th>
                                            <th scope="col">Tipo Espacio</th>
                                            <th scope="col">Activo</th>
                                            <th scope="col">Editar</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {espaciosAtencionFiltrados.map((espacio, index) => {
                                            return (
                                                <>
                                                    <tr key={espacio}>
                                                        {}
                                                        <td> {espacio.espacio ? espacio.espacio : ""} </td>
                                                        <td> {checkTipoEspacio(espacio.idCatTipoEspacio)} </td>
                                                        <td> {espacio.activo ? "SI" : "NO"} </td>
                                                        <td>
                                                            <button
                                                                disabled={espacio.idCatTipoEspacio == 3}
                                                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mt-2 mr-4"
                                                                type="button"
                                                                hidden={
                                                                    !(usuario.authorities.includes("OP_CONSULTA_ESPACIOS_EDITAR") ||
                                                                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA"))
                                                                }
                                                                onClick={() => {
                                                                    setUISweetAlert({
                                                                        ...UISweetAlert,
                                                                        confirm_alert_espacio: true,
                                                                        editar_espacios: true,
                                                                    });
                                                                    setEspacioAtencion(espacio);
                                                                }}>
                                                                Editar
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            {UISweetAlert.confirm_alert_espacio ? (
                <SweetAlert
                    title={UISweetAlert.editar_espacios ? "Editar espacio de atención" : "Nuevo espacio de atención"}
                    showCancel
                    showConfirm={UISweetAlert.editar_usuario}
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "70%",
                    }}
                    onConfirm={() => {
                        confirmEspacioAtencion();
                    }}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_espacio: false,
                            editar_espacios: false,
                        });
                        checkHorariosEspacioAtencion();
                    }}>
                    <div className="mt-2">
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={!UISweetAlert.editar_espacios}
                                    checked={espacioAtencion.activo}
                                    onChange={onChangeEspacioAtencion}
                                    name="activo"
                                    color="primary"
                                />
                            }
                            label="Activo"
                        />
                    </div>
                    <div className="mt-2">
                        <Row 
                            hidden={UISweetAlert.editar_espacios ? true : false} className="form-group">
                            <label className="col-sm-4 col-form-label">
                                Tipo de Espacio<SpanError>*</SpanError>
                            </label>
                            <Col sm={7}>
                                <select
                                    className="form-control"
                                    onChange={onChangeEspacioAtencion}
                                    name="idCatTipoEspacio"
                                    value={espacioAtencion.idCatTipoEspacio}>
                                    <option selected disabled value="">
                                        Seleccione una opción
                                    </option>
                                    {tipoEspacios.map((tipoespacio) => {
                                        return (
                                            <>
                                            {tipoespacio.idCatTipoEspacio != 3 ? (
                                                <option value={tipoespacio.idCatTipoEspacio}>{tipoespacio.tipoEspacio}</option>
                                            ) : null}
                                            </>
                                        );
                                    })}
                                </select>
                                {(espacioAtencion.idCatTipoEspacio === "" || !espacioAtencion.idCatTipoEspacio) && <SpanError/>}
                            </Col>
                        </Row>

                        <Row
                            hidden={(espacioAtencion.idCatTipoEspacio && parseInt(espacioAtencion.idCatTipoEspacio) === 1) 
                                || espacioAtencion.idCatTipoEspacio === undefined}
                            className="form-group">
                            <label className="col-sm-4 col-form-label">
                                Nombre Espacio<SpanError>*</SpanError>
                            </label>
                            <Col sm={7}>
                                <input
                                    className="form-control"
                                    onChange={onChangeEspacioAtencion}
                                    name="espacio"
                                    value={espacioAtencion.espacio}
                                />
                                {(espacioAtencion.espacio === "" || !espacioAtencion.espacio) && <SpanError/>}
                            </Col>
                        </Row>
                        
                        <Row
                            hidden={(espacioAtencion.idCatTipoEspacio && !(parseInt(espacioAtencion.idCatTipoEspacio) === 4)) 
                                || espacioAtencion.idCatTipoEspacio === undefined}
                            className="form-group">
                            <label className="col-sm-4 col-form-label">
                                Médicos
                            </label>
                            <Col sm={7}>
                                <WindowedSelect
                                    isSearchable
                                    options={medicosActivos}
                                    isMulti
                                    getOptionValue={(option) => option.idCatMedico}
                                    getOptionLabel={(option) => formatCatPersonaFullName(option)}
                                    value={espacioAtencion.medicos ? espacioAtencion.medicos : []}
                                    menuPortalTarget={document.body}
                                    menuPosition={"fixed"}
                                    onChange={(option) => onChangeMedicosConsultorio(option)}
                                    noOptionsMessage={() => "No hay médicos"}
                                    placeholder="Seleccione un médico"
                                />
                            </Col>
                        </Row>

                        <Row
                            hidden={espacioAtencion.idCatTipoEspacio && espacioAtencion.idCatTipoEspacio == 1 ? false : true}
                            className="form-group">
                            <label className="col-sm-4 col-form-label">
                                Médico<SpanError>*</SpanError>
                            </label>
                            {UISweetAlert.editar_espacios ? (
                                <Col sm={7}>
                                    <input className="form-control" type="text" value={espacioAtencion.espacio} disabled={true}/>
                                </Col>
                            ) : (
                                <Col sm={7}>
                                    <select
                                        className="form-control"
                                        onChange={onChangeEspacioAtencion}
                                        name="idCatMedico"
                                        disabled={UISweetAlert.editar_espacios ? true : false}
                                        value={espacioAtencion.idCatMedico ? espacioAtencion.idCatMedico : ""}>
                                        <option selected disabled value="">
                                            Seleccione una opción
                                        </option>
                                        {medicosAgenda.map((medico) => {
                                            return (
                                                <>
                                                <option
                                                    value={
                                                        medico.idCatMedico
                                                    }>
                                                    {`${medico.catPersona.nombres
                                                        ?medico.catPersona.nombres:(medico.nombres
                                                            ?medico.nombres:"")} 
                                                      ${medico.catPersona.apellidoPaterno
                                                        ?medico.catPersona.apellidoPaterno:(medico.apellidoPaterno
                                                            ?medico.apellidoPaterno:"")} 
                                                      ${medico.catPersona.apellidoMaterno
                                                        ?medico.catPersona.apellidoMaterno:(medico.apellidoMaterno
                                                        ?medico.apellidoMaterno:"")}`}
                                                </option>
                                                </>
                                            );
                                        })}
                                    </select>
                                    {(espacioAtencion.idCatMedico === "" || !espacioAtencion.idCatMedico) && <SpanError/>}
                                </Col>
                            )}
                        </Row>

                        <Row
                            hidden={espacioAtencion.idCatTipoEspacio && espacioAtencion.idCatTipoEspacio == 1 ? false : true}
                            className="form-group">
                            <label className="col-sm-4 col-form-label">
                                Asistente
                            </label>
                                <Col sm={7}>  
                                    <select
                                        className="form-control"
                                        onChange={onChangeEspacioAtencion}
                                        name="idAsistente"
                                        value={espacioAtencion.idAsistente ? espacioAtencion.idAsistente : ""}>
                                        <option selected disabled value="" >
                                            Seleccione una opción
                                        </option>
                                        {usuariosAsistentes.map((asistente) => {
                                            return (
                                                <>
                                                <option value={asistente.idCatUsuario}>
                                                    {asistente.nombre}
                                                </option>
                                                </> 
                                            ); 
                                        })}
                                    </select>
                                </Col>
                        </Row>

                        <Row className="form-group mt-2 ml-2 mr-2">
                            <table className="table table-hover table-centered table-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Día</th>
                                    <th scope="col">Disponible</th>
                                </tr>
                                </thead>
                                <tbody>
                                {espacioAtencion.semana.map((dia) => {
                                    return (
                                        <>
                                        <tr key={dia}>
                                            {dia.disponible ? (
                                                <td onClick={() => toggleExpanded(dia)}>
                                                    {dia.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>}
                                                </td>
                                            ) : (
                                                <td></td>
                                            )}
                                            <td>{dia.dia}</td>
                                            <td>
                                                <input type="checkbox" onClick={() => handdleCheckedDisponibilidad(dia)} checked={dia.disponible}/>
                                            </td>
                                        </tr>
                                        {dia.expanded ? (
                                            <tr key={"horarioslunes"}>
                                                <td colSpan={10}>
                                                    <button
                                                        type="button"
                                                        hidden={dia.disponible ? false : true}
                                                        onClick={() => nuevoHorario(dia)}
                                                        className="btn btn-primary-orange-umo w-md waves-effect waves-light flex hover:text-white">
                                                        Nuevo
                                                    </button>
                                                    <table className="table table-hover table-centered table-nowrap mt-2">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                Hora<br></br>Inicial
                                                            </th>
                                                            <th scope="col">
                                                                Hora<br></br>Final
                                                            </th>
                                                            <th scope="col">Eliminar</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {dia.horarios &&
                                                            dia.horarios.map((horario) => {
                                                                return (
                                                                    <>
                                                                        <tr hidden={!horario.disponible} key={horario}>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    onChange={(e) => changeHoraInicio(e, horario)}
                                                                                    value={horario.inicio ? horario.inicio : ""}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    disabled={horario.inicio ? false : true}
                                                                                    onChange={(e) => changeHoraFinal(e, horario)}
                                                                                    value={horario.termino ? horario.termino : ""}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    type="button"
                                                                                    // onClick={() => eliminarHorario(dia, horario)}
                                                                                    onClick={() => {
                                                                                        setDiaEliminar(dia);
                                                                                        setHorarioEliminar(horario);
                                                                                        setUISweetAlert({
                                                                                            ...UISweetAlert,
                                                                                            confirm_alert_eliminar_horario: true,
                                                                                        });
                                                                                    }}
                                                                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex hover:text-white">
                                                                                    Eliminar
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        ) : null}
                                        </>
                                    );
                                })}
                                </tbody>
                            </table>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_eliminar_horario ? (
                <SweetAlert
                    title="¿Desea eliminar el horario?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarHorario()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_eliminar_horario: false,
                        });
                        setHorarioEliminar({});
                        setDiaEliminar({});
                    }}>
                    Se perderán todos los cambios realizados
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_eliminar_espacio ? (
                <SweetAlert
                    title="¿Desea eliminar el espacio de atención?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarEspacioAtencion()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_eliminar_espacio: false,
                        });
                    }}>
                    Se perderán todos los cambios realizados
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </>
    );
}
