const moment = require("moment-timezone");

// Esta función se utiliza para formatear una fecha
const formatDate = (date, requiresFormat, esTodoElDia = false) => {
  // Verifica que la fecha exista antes de continuar
  if (date) {
    // Verifica si la bandera esTodoElDia es verdadera o falsa
    if (!esTodoElDia) {
      // Crea una instancia de moment con la fecha en UTC
      let momentDate = moment.tz(date, "UTC");
      // Verifica si se requiere el formato especificado en requiresFormat
      if (requiresFormat) {
        // Si se requiere, establece la zona horaria a America/Mexico_City
        momentDate = momentDate.tz("America/Mexico_City");
      }
      // Formatea la fecha con el formato "YYYY-MM-DDTHH:mm"
      const formattedDate = momentDate.format("YYYY-MM-DDTHH:mm");
      // Devuelve la fecha formateada
      return formattedDate;
    } else {
      // Si esTodoElDia es verdadero, crea una instancia de moment con la fecha en UTC
      let momentDate = moment.tz(date, "UTC");
      // Formatea la fecha con el formato "YYYY-MM-DD"
      const formattedDate = momentDate.format("YYYY-MM-DD");
      // Devuelve la fecha formateada
      return formattedDate;
    }
  }
};

export default formatDate;
