import { LISTAR_FABRICANTES, SET_FABRICANTES_PAGINADAS, FILTRO_FABRICANTES } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_FABRICANTES:
      return {
        ...state,
        fabricantes: action.payload,
      };
    case FILTRO_FABRICANTES:
      return {
        ...state,
        filtrosFabricantes: action.payload,
      };
    case SET_FABRICANTES_PAGINADAS:
      return {
        ...state,
        fabricantesPaginados: action.payload,
      };
    default:
      return state;
  }
};
