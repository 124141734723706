import React, {useState, useEffect, useMemo, useRef, useContext} from "react";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import TableComponent from "../TablaPaginada/index";
import AuthContext from "../../context/Auth/AuthContext";
import InventarioContext from "../../context/Inventario/InventarioContext";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import formatDateWithoutHour from "../../utils/formatDateWithoutHour";

export default function ListaProductosUbicacion(props) {
    const [UISweetAlert, setUISweetAlert] = useState({
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState([]);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
    const [rowSeleccionada, setRowSeleccionada] = useState(null);
    const fetchIdRef = useRef(0);

    const [anaqueles, setAnaqueles] = useState([]);
    const [estantes, setEstantes] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);

    const almacenesCtx = useContext(AlmacenesContext);
    const {almacenes, listarAlmacenes} = almacenesCtx;

	const authContext = useContext(AuthContext);
	const {usuario} = authContext;

    const administrador =
        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
        usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
        usuario.authorities.includes("ROLE_DIRECCION");

    const inventarioCtx = useContext(InventarioContext);
    const {
        productosUbicacion,
        obtenerProductosUbicacion,
        filtrosProductosUbicacion,
        setFiltrosProductosUbicacion,
        cleanInventarioSeleccionado,
    } = inventarioCtx;

    const onChange = (e) => {
        if (e.target.name == "idAlmacen") {
            obtenerAnaqueles(e.target.value);
            setFiltrosProductosUbicacion({
                ...filtrosProductosUbicacion,
                idAnaquel: "",
                idEstante: "",
                idUbicacion: "",
                [e.target.name]: e.target.value,
            });
        }

        if (e.target.name == "idAnaquel") {
            obtenerEstantes(e.target.value);
            setFiltrosProductosUbicacion({
                ...filtrosProductosUbicacion,
                idEstante: "",
                idUbicacion: "",
                [e.target.name]: e.target.value,
            });
        }

        if (e.target.name == "idEstante") {
            obtenerUbicaciones(e.target.value);
            setFiltrosProductosUbicacion({
                ...filtrosProductosUbicacion,
                idUbicacion: "",
                [e.target.name]: e.target.value,
            });
        }

        if (e.target.name == "idUbicacion") {
            setFiltrosProductosUbicacion({
                ...filtrosProductosUbicacion,
                [e.target.name]: e.target.value,
            });
        }
    };

    const buscarPorFiltros = async (e) => {
        e.preventDefault();
        // if (!filtrosProductosUbicacion.idAlmacen) {
        //   setUISweetAlert({
        //     ...UISweetAlert,
        //     error_dlg: true,
        //     dynamic_title: "Validación",
        //     dynamic_description: "Por favor introduzca el almacén",
        //   });
        //   return;
        // }

        fetchData({pageSize: 10, pageIndex: 0, byBusquedaFiltros: true});
    };

    const limpiarFiltros = () => {
        setFiltrosProductosUbicacion({
            idAlmacen: "",
            idAnaquel: "",
            idEstante: "",
            idUbicacion: "",
        });
    };

    const obtenerAnaqueles = async (idCatAlmacen) => {
        if (idCatAlmacen == "") {
            setAnaqueles([]);
        } else {
            const almacen = almacenes.filter((almc) => almc.idCatAlmacen == idCatAlmacen)[0];
            if (almacen.anaqueles != null) {
                setAnaqueles(almacen.anaqueles);
            } else {
                setAnaqueles([]);
            }
        }

        setEstantes([]);
        setUbicaciones([]);
    };

    const obtenerEstantes = async (idCatAnaquel) => {
        if (idCatAnaquel == "") {
            setEstantes([]);
        } else {
            const anaquel = anaqueles.filter((anaquel) => anaquel.idCatAnaquel == idCatAnaquel)[0];
            if (anaquel.estantes != null) {
                setEstantes(anaquel.estantes);
            } else {
                setEstantes([]);
            }
        }
        setUbicaciones([]);
    };

    const obtenerUbicaciones = async (idCatEstante) => {
        if (idCatEstante == "") {
            setUbicaciones([]);
        } else {
            const estante = estantes.filter((estante) => estante.idCatEstante == idCatEstante)[0];
            if (estante.ubicaciones != null) {
                setUbicaciones(estante.ubicaciones);
            } else {
                setUbicaciones([]);
            }
        }
    };

    const fetchData = async ({pageSize, pageIndex}) => {
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: false,
            });
        }
    };

    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);
            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);
            }
            const response = await obtenerProductosUbicacion({page, limit});
            const count = response.data.count;
            setData(data);
            setRowSeleccionada(null);

            if (count % limit > 0) {
                setPageCount(Math.ceil(count / limit));
            } else {
                setPageCount(count / limit);
            }

            setLoadingData(false);
        } catch (e) {
            console.log("Error while fetching", e);
            // setLoading(false)
        }
    };

    const columns = useMemo(
        () => [
            {Header: "Tipo", accessor: "tipoProducto", show: true},
            {Header: "Identificación", accessor: "numIdentificacion", show: true},
            {Header: "Código EAN", accessor: "codigoEAN", show: true},
            {Header: "Descripción", accessor: "nombreComercial", show: true},
            {Header: "Principio Activo", accessor: "principioActivo", show: true},
            {Header: "Proveedor", accessor: "proveedor", show: true},
            {Header: "Fabricante", accessor: "laboratorio", show: true},
            {
                Header: "Precio Compra",
                accessor: "precio",
                Cell: ({row}) => {
                    return `$${row.values.precio ? row.values.precio.toFixed(2) : "0.00"}`;
                },
                show: true,
            },
            {
                Header: "IVA",
                accessor: "iva",
                Cell: ({row}) => {
                    return `$${row.values.iva.toFixed(2)}`;
                },
                show: true,
            },
            {Header: "Lote", accessor: "lote", show: true},
            {
                Header: "Caducidad",
                accessor: "caducidad",
                Cell: ({row}) => {
                    return row.original.caducidad ? formatDateWithoutHour(row.original.caducidad) : "SIN CADUCIDAD"
                },
                show: true,
            },
            // {
            //     Header: "Caducidad",
            //     accessor: "caducidad",
            //     Cell: ({row}) => {
            //         return `${row.values.caducidad.split("-")[2]}/${row.values.caducidad.split("-")[1]}/${
            //             row.values.caducidad.split("-")[0]
            //         }`;
            //     },
            //     show: true,
            // },
            {Header: "Piezas", accessor: "piezas", show: true},
            {Header: "Almacén Recepción", accessor: "almacen", show: true},
            {
                Header: "Ubicación",
                accessor: "ubicaciones",
                Cell: ({row}) => {
                    return `${row.values.ubicaciones[0].ubicacion.ubicacion}`;
                },
                show: true,
            },
        ],
        [rowSeleccionada]
    );

    useEffect(() => {
        cleanInventarioSeleccionado();
        listarAlmacenes(false,administrador);
    }, []);

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Lista de Productos por Ubicación</h3>

            <Card>
                <CardBody>
                    <form onSubmit={(e) => buscarPorFiltros(e)}>
                        <Row>
                            <Col>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Almacén</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idAlmacen"
                                            onChange={onChange}
                                            value={filtrosProductosUbicacion.idAlmacen}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {almacenes.map((almacen) => {
                                                return (
                                                    <>
                                                        <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Anaquel</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idAnaquel"
                                            disabled={!filtrosProductosUbicacion.idAlmacen}
                                            onChange={onChange}
                                            value={filtrosProductosUbicacion.idAnaquel}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {anaqueles.map((anaquel) => {
                                                return (
                                                    <>
                                                        <option value={anaquel.idCatAnaquel}>{anaquel.anaquel}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Estantes</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idEstante"
                                            disabled={!filtrosProductosUbicacion.idAnaquel}
                                            onChange={onChange}
                                            value={filtrosProductosUbicacion.idEstante}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {estantes.map((estante) => {
                                                return (
                                                    <>
                                                        <option value={estante.idCatEstante}>{estante.estante}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Ubicaciones</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idUbicacion"
                                            disabled={!filtrosProductosUbicacion.idEstante}
                                            onChange={onChange}
                                            value={filtrosProductosUbicacion.idUbicacion}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {ubicaciones.map((ubicacion) => {
                                                return (
                                                    <>
                                                        <option value={ubicacion.idCatUbicacion}>{ubicacion.ubicacion}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4" type="submit">
                                    Aplicar Filtros
                                </button>
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick={limpiarFiltros}>
                                    Limpiar Filtros
                                </button>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>

            <Card className="mt-4">
                <CardBody>
                    <Row>
                        <Col>
                            <TableComponent
                                pageCount={pageCount}
                                fetchData={fetchData}
                                columns={columns}
                                loading={loadingData}
                                data={productosUbicacion}
                                indexOfPage={controlledPageIndex}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
