import { SET_CONSENTIMIENTOS_PAGINADOS, SET_DATOS_EXPEDIENTE_MEDICO, SET_ID_EXPEDIENTE_MEDICO } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case SET_CONSENTIMIENTOS_PAGINADOS:
      return {
        ...state,
        consentimientosPaginados: action.payload,
      };
    case SET_DATOS_EXPEDIENTE_MEDICO:
      return {
        ...state,
        datosExpedienteMedico: action.payload,
      };
    case SET_ID_EXPEDIENTE_MEDICO:
      return {
        ...state,
        idExpedienteMedico: action.payload,
      };
    default:
      return state;
  }
};
