import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";

import CatServiciosContext from "../../context/CatServicios/CatServiciosContext";
import CatTipoServiciosContext from "../../context/CatTipoServicios/CatTipoServiciosContext";

import ConfiguracionContext from "../../context/Configuracion/ConfiguracionContext";

import CargaMasivaModal from "../CargaMasivaModal/index";

import useWindowDimensions from "../../hooks/dimensionsHook";

import formatNumber from "../../utils/index";

import { maxClaveSat, maxCampoNumIdentificacion, maxCampoServicio } from "../../config/variables";

const CatServicios = () => {
  const { width } = useWindowDimensions();
  const filtrosVacios = {
    servicio: "",
    idCatTipoServicio: null,
  };

  const datosServicioVacio = {
    servicio: "",
    idCatTipoServicio: null,
    numIdentificacion: "",
    claveSat: "",
    unidadSat: "",
    claveUnidadSat:"",
  };

  const [datosNuevoServicio, setDatosNuevoServicio] = useState(datosServicioVacio);
  const [servicioSeleccionado, setServicioSeleccionado] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);
  const [servicioSeleccionadoPrecios, setServicioSeleccionadoPrecios] = useState(null);
  const [servicioPreciosActualizados, setServicioPreciosActualizados] = useState({ preciosAseguradora: [] });
  const [nuevoPrecio, setNuevoPrecio] = useState(null);

  const [ivaConfig, setIvaConfig] = useState(null);

  const catTipoServiciosContext = useContext(CatTipoServiciosContext);
  const { tipoServicios, listarCatTipoServicios, listarPreciosServicio, preciosServicio } = catTipoServiciosContext;

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_servicio: false,
    confirm_nuevo_servicio: false,
    confirm_eliminar_servicio: false,
    confirm_modificar_precios: false,
    confirm_modificar_precios_final: false,
    confirm_carga_masiva: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);

  const ctxConfiguracion = useContext(ConfiguracionContext);
  const { getIvaConfiguracion } = ctxConfiguracion;

  const catServiciosContext = useContext(CatServiciosContext);
  const {
    setServiciosPaginados,
    setChangesFiltrosServicios,
    filtrosServicios,
    serviciosPaginados,
    updateOrCreateServicio,
    eliminarServicio,
    actualizarPreciosProducto,
  } = catServiciosContext;

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setServiciosPaginados({ page, limit });

      //Cantidad de datos disponibles en la tabla servicios de la base de datos
      const count = response.count;

      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const handdleCheckedServicio = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setServicioSeleccionado({
          ...row.original,
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setServicioSeleccionado(null);
      } else {
        setRowSeleccionada(row.id);
        setServicioSeleccionado({
          ...row.original,
        });
      }
    },
    [rowSeleccionada]
  );

  // Se definen las columnas para la tabla de react-tabe
  // Se debe usar useMemo para optimización y porque lo pide la librería
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCatServicio",
        Cell: ({ row }) => {
          return (
            <input
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedServicio(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Tipo de Cáncer", accessor: "catTipoServicio.tipoServicio" },
      { Header: "Número de Identificación", accessor: "numIdentificacion" },
      { Header: "Servicio", accessor: "servicio" },
      {
        Header: "Precios",
        accessor: "precios",
        Cell: ({ row }) => {
          return autorizado || autorizadoAseguradora ? (
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              onClick={() => handdlePrecios(row.original)}>
              Precios
            </button>
          ) : null;
        },

        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [rowSeleccionada, handdleCheckedServicio]
  );

  const handdlePrecios = async (servicio) => {
    const response = await listarPreciosServicio(servicio.idCatServicio);

    setServicioSeleccionadoPrecios({
      ...response,
      idCatServicio: servicio.idCatServicio,
      servicio: servicio.servicio,
    });

    setServicioPreciosActualizados({
      ...response,
      idCatServicio: servicio.idCatServicio,
      servicio: servicio.servicio,
    });

    if (response.preciosProductoServicio || response.preciosAseguradora.length > 0) {
      setUISweetAlert({
        confirm_modificar_servicio: false,
        confirm_nuevo_servicio: false,
        confirm_eliminar_servicio: false,
        confirm_modificar_precios: true,
        confirm_modificar_precios_final: false,
        confirm_carga_masiva: false,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_servicio: false,
        confirm_nuevo_servicio: false,
        confirm_eliminar_servicio: false,
        confirm_modificar_precios: true,
        confirm_modificar_precios_final: false,
        confirm_carga_masiva: false,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
      });
    }
  };

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  const autorizado =
    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
    usuario.authorities.includes("ROLE_ADMINISTRACION") ||
    usuario.authorities.includes("ROLE_COMPRAS") ||
    usuario.authorities.includes("ROLE_FARMACIA");

    const autorizadoAseguradora = usuario.authorities.includes("ROLE_ASEGURADORAS");

  const editarIdentificacion =  usuario.authorities.includes("ROLE_COMPRAS");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setChangesFiltrosServicios(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setChangesFiltrosServicios({
      ...filtrosServicios,
      [name]: value,
    });
  };

  const changeNuevoServicio = (e) => {
    const { name, value } = e.target;
    setDatosNuevoServicio({
      ...datosNuevoServicio,
      [name]: value,
    });
  };

  const changeModificarServicio = (e) => {
    const { name, value } = e.target;
    setServicioSeleccionado({
      ...servicioSeleccionado,
      [name]: value,
    });
  };

  const opcionesTipoServicios = tipoServicios.map(({ idCatTipoServicio, tipoServicio, ...rest }) => ({
    ...rest,
    id: idCatTipoServicio,
    nombre: tipoServicio,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "select",
          label: "Tipo de Cáncer",
          name: "idCatTipoServicio",
          value: filtrosServicios.idCatTipoServicio,
          opciones: opcionesTipoServicios,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Número de identificación",
          type: "text",
          name: "numIdentificacion",
          value: filtrosServicios.numIdentificacion,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Servicio",
          type: "text",
          name: "servicio",
          value: filtrosServicios.servicio,
          onChange: onChange,
        },
      ],
    },
  ];

  const confirmNuevoServicio = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nuevo_servicio: true,
      });
    }
  };

  const agregarNuevoServicio = async () => {
    // const clienteValido = validarDatosCliente(datosNuevoServicio);
    const servicioValido = datosNuevoServicio.servicio != null && datosNuevoServicio.servicio !== "";
    if (servicioValido) {

      if(datosNuevoServicio.claveUnidadSat =="H87"){
        datosNuevoServicio.unidadSat = "SERVICIO"
      }

      setLoading(true);
      const res = await updateOrCreateServicio(datosNuevoServicio);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nuevo_servicio: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nuevo_servicio: false,
          dynamic_description: "Se guardó el nuevo servicio correctamente",
        });
        // setData([...data, res.data]);
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setDatosNuevoServicio(datosServicioVacio);
      }
    }
  };

  const checkEliminarServicio = () => {
    if (autorizado && servicioSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_servicio: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un servicio",
      });
    }
  };

  const confirmEliminarServicio = async () => {
    setLoading(true);
    const res = await eliminarServicio(servicioSeleccionado.idCatServicio);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_servicio: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_servicio: false,
        dynamic_description: "Se eliminó el servicio correctamente",
      });
      // updateDataOnDeleteCliente(servicioSeleccionado.idCatCliente);
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setServicioSeleccionado(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarServicio = () => {
    if ((autorizado || autorizadoAseguradora) && servicioSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_servicio: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un servicio",
      });
    }
  };

  const confirmModicarServicio = async () => {
    // const clienteValido = validarDatosCliente(servicioSeleccionado);
    const servicioValido = servicioSeleccionado.servicio != null && servicioSeleccionado.servicio !== "";
    if (servicioValido) {

      if(servicioSeleccionado.claveUnidadSat =="H87"){
        servicioSeleccionado.unidadSat = "SERVICIO"
      }

      setLoading(true);
      const res = await updateOrCreateServicio(servicioSeleccionado);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_servicio: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_servicio: false,
          dynamic_description: "Se modificó el servicio correctamente",
        });

        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setServicioSeleccionado(null);
        setRowSeleccionada(null);
      }
    }
  };

  useEffect(() => {
    const fetchAllContextData = async () => {
      listarCatTipoServicios();
      // listarConfiguracion();
      const iva = await getIvaConfiguracion();
      setIvaConfig(iva);
    };
    fetchAllContextData();
  }, []);

  const changeNuevoPrecio = (e) => {
    const { value } = e.target;
    setNuevoPrecio(value);
  };

  const setNuevoPrecioServicioProductoServicio = ({ tipo }) => {
    if (nuevoPrecio === null || nuevoPrecio == "") return;
    const updatedPrecios = {
      ...servicioPreciosActualizados.preciosProductoServicio,
      [tipo]: nuevoPrecio,
    };

    setServicioPreciosActualizados({ ...servicioPreciosActualizados, preciosProductoServicio: updatedPrecios });

    setNuevoPrecio(null);
  };

  const setNuevoPrecioServicio = (precioActual) => {
    if (nuevoPrecio === null || nuevoPrecio == "") return;

    let aseguradoraModificada = preciosServicio.preciosAseguradora.filter(
      (aseguradora) => aseguradora.idPrecioAseguradora == precioActual.idPrecioAseguradora
    );

    aseguradoraModificada = aseguradoraModificada[0];
    aseguradoraModificada = {
      ...aseguradoraModificada,
      precioUnitario: nuevoPrecio,
      idCatServicio: servicioSeleccionadoPrecios.idCatServicio,
    };

    delete aseguradoraModificada.idCatProducto;
    delete aseguradoraModificada.idCatServicio;

    let arrayPreciosAseguradora = servicioPreciosActualizados.preciosAseguradora;
    arrayPreciosAseguradora = arrayPreciosAseguradora.map((aseguradora) => {
      if (aseguradora.idPrecioAseguradora == aseguradoraModificada.idPrecioAseguradora) {
        aseguradora = aseguradoraModificada;
        return aseguradora;
      } else {
        return aseguradora;
      }
    });

    setServicioPreciosActualizados({
      ...servicioPreciosActualizados,
      preciosAseguradora: arrayPreciosAseguradora,
    });
    setNuevoPrecio(null);
  };

  const guardarPreciosServicio = async () => {
    if (
      servicioPreciosActualizados.preciosAseguradora.length < 1 &&
      servicioPreciosActualizados.preciosProductoServicio === undefined
    ) {
      return;
    }

    setLoading(true);

    const res = await actualizarPreciosProducto(servicioPreciosActualizados);

    if (res.data && res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_modificar_precios: false,
        confirm_modificar_precios_final: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
      setServicioSeleccionadoPrecios(null);
      setServicioPreciosActualizados({ preciosAseguradora: [] });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_modificar_precios: false,
        confirm_modificar_precios_final: false,
        dynamic_description: "Se guardaron los precios correctamente",
      });
      // const productosFiltrados = productosPaginados.map((producto) => {
      //   if (producto.idCatProducto != medicamentoPreciosActualizados.idCatProducto) return producto;
      //   return medicamentoPreciosActualizados;
      // });
      // actualizarProductosPaginados(productosFiltrados);

      setServicioSeleccionadoPrecios(null);
      setServicioPreciosActualizados({ preciosAseguradora: [] });
    }
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Catálogo de Servicios</h3>
      {autorizado ? (
        // <Link to={`/carga/precios`}>
        //   <button className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white" type="button">
        //     Carga Masiva de Precios
        //   </button>
        // </Link>
        <button
          onClick={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_carga_masiva: true,
            });
          }}
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button">
          Carga Masiva de Precios
        </button>
      ) : null}
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Servicios</h4>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevoServicio()}>
                      Nuevo Servicio
                    </button>
                  ) : null}
                  {autorizado || autorizadoAseguradora? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarServicio()}>
                      Modificar Servicio
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarServicio()}>
                      Eliminar Servicio
                    </button>
                  ) : null}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={serviciosPaginados}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nuevo_servicio ? (
        <SweetAlert
          title="Nuevo Servicio"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "40%",
          }}
          onConfirm={() => {
            agregarNuevoServicio();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nuevo_servicio: false,
            });
            setDatosNuevoServicio(datosServicioVacio);
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <Row className="form-group mt-4">
                  <label className="col-sm-2 col-form-label">Tipo de Cáncer</label>
                  <Col sm={5}>
                    <select
                      className="form-control"
                      name="idCatTipoServicio"
                      onChange={changeNuevoServicio}
                      value={datosNuevoServicio.idCatTipoServicio}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {tipoServicios.map((tipoServicio) => {
                        return <option value={tipoServicio.idCatTipoServicio}>{`${tipoServicio.tipoServicio}`}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-2 col-form-label">Número de Identificación</label>
                  <Col sm={10}>
                    <input
                      type="text"
                      className="form-control"
                      name="numIdentificacion"
                      autoComplete="off"
                      maxLength={maxCampoNumIdentificacion}
                      onChange={changeNuevoServicio}
                      value={datosNuevoServicio.numIdentificacion ? datosNuevoServicio.numIdentificacion : ""}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-2 col-form-label">
                    Servicio<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={10}>
                    <input
                      type="text"
                      className="form-control"
                      name="servicio"
                      autoComplete="off"
                      maxLength={maxCampoServicio}
                      onChange={changeNuevoServicio}
                      value={datosNuevoServicio.servicio ? datosNuevoServicio.servicio : ""}
                    />
                    {datosNuevoServicio.servicio === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-2 col-form-label">
                  Clave Sat<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={10}>
                  <input
                      type="text"
                      className="form-control"
                      name="claveSat"
                      autoComplete="off"
                      maxLength={maxClaveSat}
                      onChange={changeNuevoServicio}
                      value={datosNuevoServicio.claveSat}
                    />

                    {datosNuevoServicio.claveSat === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-2 col-form-label">
                  Unidad Sat<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={10}>
                  <select
                      className="form-control"
                      name="claveUnidadSat"
                      onChange={changeNuevoServicio}
                      value={datosNuevoServicio.claveUnidadSat}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      <option   value="H87">H87 - SERVICIO</option>
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="form-group mt-2">
              {/* <label className="col-sm-2 col-form-label"></label> */}
              <Col sm={6}>
                <button
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="button"
                  style={{
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  onClick={() => setDatosNuevoServicio(datosServicioVacio)}>
                  Limpiar Datos
                </button>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_eliminar_servicio ? (
        <SweetAlert
          title="¿Desea eliminar al servicio seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => confirmEliminarServicio()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_servicio: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_servicio ? (
        <SweetAlert
          title="Modificar Datos del Servicio"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          closeOnClickOutside={false}
          cancelBtnBsStyle="danger"
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "40%",
          }}
          onConfirm={() => confirmModicarServicio()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_servicio: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <Row className="form-group mt-2">
                  <label className="col-sm-2 col-form-label">Tipo de Cáncer</label>

                  <Col sm={5}>
                    <select
                      className="form-control"
                      name="idCatTipoServicio"
                      onChange={changeModificarServicio}
                      value={servicioSeleccionado.idCatTipoServicio}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {tipoServicios.map((tipoServicio) => {
                        return <option value={tipoServicio.idCatTipoServicio}>{`${tipoServicio.tipoServicio}`}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-2 col-form-label">Número de Identificación</label>
                  <Col sm={10}>
                    <input
                      disabled={editarIdentificacion? false: true}
                      type="text"
                      className={editarIdentificacion? "form-control": "form-control-disabled"}
                      name="numIdentificacion"
                      autoComplete="off"
                      maxLength={maxCampoNumIdentificacion}
                      onChange={changeModificarServicio}
                      value={servicioSeleccionado.numIdentificacion ? servicioSeleccionado.numIdentificacion : ""}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-2 col-form-label">
                    Servicio<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={10}>
                    <input
                      type="text"
                      className="form-control"
                      name="servicio"
                      autoComplete="off"
                      maxLength={maxCampoServicio}
                      onChange={changeModificarServicio}
                      value={servicioSeleccionado.servicio ? servicioSeleccionado.servicio : ""}
                    />
                    {servicioSeleccionado.servicio === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-2 col-form-label">
                  Clave Sat<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={10}>
                  <input
                      type="text"
                      className="form-control"
                      name="claveSat"
                      autoComplete="off"
                      maxLength={maxClaveSat}
                      onChange={changeModificarServicio}
                      value={servicioSeleccionado.claveSat}
                    />

                    {datosNuevoServicio.claveSat === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-2 col-form-label">
                  Unidad Sat<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={10}>
                  <select
                      className="form-control"
                      name="claveUnidadSat"
                      onChange={changeModificarServicio}
                      value={servicioSeleccionado.claveUnidadSat}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      <option   value="H87">H87 - SERVICIO</option>
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_precios ? (
        <SweetAlert
          title={""}
          showCancel
          showConfirm
          confirmBtnText="Guardar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios_final: true,
            });
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios: false,
            });
            setServicioPreciosActualizados({ preciosAseguradora: [] });
            setNuevoPrecio(null);
          }}>
          {preciosServicio.preciosProductoServicio || preciosServicio.preciosAseguradora.length > 0 ? (
            <>
              <h5 className="h5 mb-4">
                Servicio
                <p className="text-gray-600">{`${servicioSeleccionadoPrecios.servicio}`}</p>
                {/* <p className="text-gray-600">{insumoSeleccionadoPrecios.numIdentificacion ? insumoSeleccionadoPrecios.numIdentificacion : ""}</p> */}
              </h5>

              <table className="table table-hover table-centered mb-0">
                <thead>
                  <tr>
                    <th scope="col">Precio Definido para</th>
                    <th scope="col">Precio Actual</th>
                    <th scope="col">Iva</th>
                    <th scope="col">Nuevo Precio</th>
                  </tr>
                </thead>
                <tbody>
                  {preciosServicio.preciosProductoServicio ? (
                    <>
                      <tr key={preciosServicio.preciosProductoServicio.idPrecioProductoServicio}>
                        <td>Precio Mostrador</td>
                        <td>
                          <input
                            // value={`$${formatoMexico(preciosProducto.preciosProductoServicio.precioMostrador)}`}
                            value={formatNumber(preciosServicio.preciosProductoServicio.precioMostrador)}
                            disabled={true}
                            type="text"
                            name="precioMostrador"
                          />
                        </td>
                        <td style={{ width: "25%" }}>
                          <input
                            // value={`$${formatoMexico(preciosProducto.preciosProductoServicio.precioPublico * 0.16)}`}
                            value={formatNumber(preciosServicio.preciosProductoServicio.precioMostrador * ivaConfig)}
                            disabled={true}
                            type="text"
                            name="iva"
                          />
                        </td>
                        <td>
                          <input
                            disabled={false}
                            type="number"
                            name="precioMostrador"
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => changeNuevoPrecio(e)}
                            onBlur={() => setNuevoPrecioServicioProductoServicio({ tipo: "precioMostrador" })}
                          />
                        </td>
                      </tr>
                    </>
                  ) : null}

                  {preciosServicio.preciosAseguradora.length > 0 &&
                    preciosServicio.preciosAseguradora.map((precio, index) => {
                      return (
                        <>
                          <tr key={precio.idPrecioAseguradora}>
                            <td style={{ width: "40%" }}>{precio.aseguradora ? precio.aseguradora : ""}</td>
                            <td style={{ width: "20%" }}>
                              <input
                                // value={`$${formatoMexico(precio.precioUnitario)}`}
                                value={formatNumber(precio.precioUnitario)}
                                disabled={true}
                                type="text"
                                name="precioUnitario"
                              />
                            </td>
                            <td style={{ width: "20%" }}>
                              <input
                                // value={`$${formatoMexico(precio.precioUnitario * 0.16)}`}
                                value={formatNumber(precio.precioUnitario * ivaConfig)}
                                disabled={true}
                                type="text"
                                name="iva"
                              />
                            </td>
                            <td style={{ width: "20%" }}>
                              <input
                                disabled={false}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                name="precio"
                                onChange={(e) => changeNuevoPrecio(e)}
                                onBlur={() => setNuevoPrecioServicio(precio)}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </>
          ) : (
            <button
              onClick={() => {
                setUISweetAlert({
                  ...UISweetAlert,
                  confirm_carga_masiva: true,
                });
              }}
              className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
              type="button">
              Carga Masiva de Precios
            </button>
          )}
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_precios_final ? (
        <SweetAlert
          title={"¿Desea modificar los precios del servicio?"}
          showConfirm
          showCancel
          confirmBtnText="SI"
          confirmBtnBsStyle="success"
          cancelBtnText="NO"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "50%",
          }}
          onConfirm={() => guardarPreciosServicio()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios_final: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_carga_masiva ? (
        <SweetAlert
          title={"Carga Masiva de Precios"}
          showConfirm
          confirmBtnText="Salir"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_carga_masiva: false,
            });
          }}>
          <CargaMasivaModal disabled={true} idCatTipoProducto={3} />
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default CatServicios;
