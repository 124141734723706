import {
    LISTAR_BAJA_INVENTARIOS,
    FILTROS_BAJAINVENTARIO
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_BAJA_INVENTARIOS:
            return{
                ...state,
                bajaInventarios: action.payload
            };
        case FILTROS_BAJAINVENTARIO:
            return{
                ...state,
                filtrosBajaInventario: action.payload
            };
        default:
            return state
    }
        
}