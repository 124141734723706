import {
  OBTENER_INVETARIOS_PRODUCTOS,
  FILTROS_PRODUCTO_INVENTARIO,
  PRODUCTO_INVENTARIO,
  FILTROS_PRODUCTO_UBICACION,
  PRODUCTO_UBICACION,
  INVENTARIO_SELECCIONADO,
} from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case OBTENER_INVETARIOS_PRODUCTOS:
      return {
        ...state,
        invetarioProducto: action.payload,
      };
    case FILTROS_PRODUCTO_INVENTARIO:
      return {
        ...state,
        filtrosProductosInventario: action.payload,
      };
    case PRODUCTO_INVENTARIO:
      return {
        ...state,
        productosInventario: action.payload,
      };
    case FILTROS_PRODUCTO_UBICACION:
      return {
        ...state,
        filtrosProductosUbicacion: action.payload,
      };

    case PRODUCTO_UBICACION:
      return {
        ...state,
        productosUbicacion: action.payload,
      };
    case INVENTARIO_SELECCIONADO:
      return {
        ...state,
        inventarioSeleccionado: action.payload,
      };
    default:
      return state;
  }
};
