import React, { useState, useEffect, useContext } from "react";
import { Row, Col, CardBody } from "reactstrap";

// import AuthContext from "../../context/Auth/AuthContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import CatTipoProductoContext from "../../context/CatTipoProducto/CatTipoProductoContext";
import CatTipoPrecioContext from "../../context/CatTipoPrecio/CatTipoPrecioContext";
import "antd/dist/antd.min.css";
import { Select, Progress } from "antd";

const { Option } = Select;

const CargaMasivaModal = (props) => {
  const [datosCargaArchivo, setDatosCargaArchivo] = useState({
    selectedFile: null,
    aseguradoras: null,
  });

  const [resultadosCarga, setResultadosCarga] = useState(null);

  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  // const ctxAuth = useContext(AuthContext);
  // const { usuario } = ctxAuth;

  const catProductosContext = useContext(CatProductosContext);

  const { uploadPrecios, descargarFormatoPrecios } = catProductosContext;

  const aseguradorasContext = useContext(AseguradorasContext);

  const { aseguradoras, listarAseguradoras } = aseguradorasContext;

  useEffect(() => {
    listarAseguradoras();
    // if (aseguradoras.length <= 0) return
  }, []);

  const catTipoProductoContext = useContext(CatTipoProductoContext);

  const { catTipoProductosAll, listarTiposProductos } = catTipoProductoContext;

  useEffect(() => {
    // if (tiposProductos.length <= 0) return
    listarTiposProductos();
  }, []);

  let tipoProductoProp =
    catTipoProductosAll &&
    catTipoProductosAll.filter((tipo) => {
      if (tipo.idCatTipoProducto == props.idCatTipoProducto) return tipo;
    });

  tipoProductoProp = tipoProductoProp[0];

  const catTipoPrecioContext = useContext(CatTipoPrecioContext);
  const { tipoPrecios, listarCatTipoPrecios } = catTipoPrecioContext;

  useEffect(() => {
    // if (tipoPrecios.length <= 0) return
    listarCatTipoPrecios();
  }, []);

  const onFileChange = (event) => {
    setDatosCargaArchivo({ ...datosCargaArchivo, selectedFile: event.target.files[0] });
    const file = event.target.files[0];
    const reader = new FileReader();

    // convert image file to base64 string

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string

        let strFile = reader.result;
        let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
        let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);
        setDatosCargaArchivo({
          ...datosCargaArchivo,
          selectedFile: file,
          nombreArchivo: file.name,
          mimeType: type,
          tamano: file.size,
          inputStream: fileBase64,
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const changeCargaArchivo = (e) => {
    const { name, value } = e.target;
    setDatosCargaArchivo({
      ...datosCargaArchivo,
      [name]: value,
    });
  };

  const children = [];
  aseguradoras.forEach((element) => {
    children.push(<Option key={element.idCatAseguradora}>{element.razonSocial}</Option>);
  });

  function handleChange(value) {
    setDatosCargaArchivo({ ...datosCargaArchivo, aseguradoras: value });
  }

  const fileData = () => {
    if (datosCargaArchivo.selectedFile) {
      return (
        <>
          <label className="col-sm-4 col-form-label">Nombre del Archivo</label>
          <Col sm={7}>
            <input
              disabled={true}
              type="text"
              className="form-control"
              autoComplete="off"
              value={datosCargaArchivo.selectedFile.name}
            />
          </Col>
          <label className="col-sm-4 col-form-label">Tipo de Archivo</label>
          <Col sm={7}>
            <input
              disabled={true}
              type="text"
              className="form-control"
              autoComplete="off"
              value={datosCargaArchivo.selectedFile.type}
            />
          </Col>
        </>
      );
    } else {
      return (
        <>
          <br />
        </>
      );
    }
  };

  const cargaPrecios = () => {
    setProgress(0);
    setMessage("");
    setStatus(null);
    const catAseguradoras =
      datosCargaArchivo.aseguradoras != null && datosCargaArchivo.aseguradoras.length > 0
        ? datosCargaArchivo.aseguradoras.map((aseguradora) => {
            return { idCatAseguradora: aseguradora };
          })
        : [];

    if (!datosCargaArchivo.selectedFile && !datosCargaArchivo.idCatTipoProducto && !datosCargaArchivo.idCatTipoPrecio) return;
    if (datosCargaArchivo.idCatTipoPrecio == 1 && catAseguradoras.length === 0) {
      setMessage("Por favor, agrega al menos una aseguradora");
      return;
    }
    uploadPrecios(
      { ...datosCargaArchivo, catAseguradoras: catAseguradoras, idCatTipoProducto: props.idCatTipoProducto },
      (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      }
    )
      .then((response) => {
        if (response.data.lineasConErrores.length === 0 && response.data.registrosConErrores === 0) {
          setResultadosCarga(response.data);
          setMessage("Archivo Cargado Correctamente");
          setStatus("success");
        } else {
          const errores = response.data.lineasConErrores.map((error) => "Línea " + error.linea + ": " + error.error);
          const lineasError = errores.join(", ");

          // const listErrores = errores.map((error) => (
          //   <li className="mb-2" key={error}>
          //     {error}
          //   </li>
          // ));
          setResultadosCarga({ ...response.data, lineasConErrores: lineasError });
          setMessage("Se encontraron errores al cargar el archivo");
          setStatus("exception");
        }
      })
      .catch(() => {
        setProgress(0);
        setMessage("Error en la carga de archivo");
      });
  };

  const descargarFormato = async () => {
    descargarFormatoPrecios();
  };

  return (
    <CardBody>
      <Row>
        <Col xl={6} lg={12} md={12}>
          <Row className="form-group mt-3">
            <label className="col-sm-4 col-form-label">Archivo de Carga</label>
            <Col sm={7}>
              <input type="file" accept=".xlsx, .xls" onChange={onFileChange} />
            </Col>
            {fileData()}
            <Col sm={11} className="mt-3 mb-3">
              {message}
            </Col>
            {/* <label className="col-sm-4 col-form-label mb-3">{message}</label> */}
            <Progress percent={progress} size="small" status={status} />
          </Row>
          <button
            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
            type="button"
            style={{
              marginRight: "1rem",
              marginTop: "1rem",
              background: "#ff9933",
              color: "white",
              fontWeight: "bold",
            }}
            onClick={() => descargarFormato()}>
            Descarga de Formato
          </button>
          <button
            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
            type="button"
            style={{
              marginRight: "1rem",
              marginTop: "1rem",
              background: "#ff9933",
              color: "white",
              fontWeight: "bold",
            }}
            onClick={() => cargaPrecios()}>
            Cargar Precios
          </button>
        </Col>

        <Col xl={6} lg={12} md={12}>
          <Row className="form-group mt-3">
            <label className="col-sm-5 col-form-label">Tipo de Producto</label>
            <Col sm={7}>
              <select
                className="form-control"
                disabled={props.disabled}
                name="idCatTipoProducto"
                id="idCatTipoProducto"
                onChange={changeCargaArchivo}>
                <option selected value={props.idCatTipoProducto ? props.idCatTipoProducto : ""}>
                  {tipoProductoProp ? tipoProductoProp.tipoProducto : ""}
                </option>
                {catTipoProductosAll.map((tipoProducto) => {
                  return (
                    <>
                      <option value={tipoProducto.idCatTipoProducto}>{tipoProducto.tipoProducto}</option>
                    </>
                  );
                })}
              </select>
            </Col>
            <label className="col-sm-5 col-form-label">Tipo de Precio</label>
            <Col sm={7}>
              <select className="form-control" name="idCatTipoPrecio" id="idCatTipoPrecio" onChange={changeCargaArchivo}>
                <option selected value="">
                  Seleccione una opción
                </option>
                {tipoPrecios.map((tipoPrecio) => {
                  return (
                    <>
                      <option value={tipoPrecio.idCatTipoPrecio}>{tipoPrecio.tipoPrecio}</option>
                    </>
                  );
                })}
              </select>
            </Col>
            {datosCargaArchivo.idCatTipoPrecio && datosCargaArchivo.idCatTipoPrecio == 1 ? (
              <>
                <label className="col-sm-5 col-form-label">Aseguradoras</label>
                <Col sm={7}>
                  <Select mode="tags" style={{ width: "100%" }} allowClear={true} onChange={handleChange} tokenSeparators={[","]}>
                    {children}
                  </Select>
                </Col>
              </>
            ) : null}
            {resultadosCarga ? (
              <>
                <label className="col-sm-5 col-form-label mt-2">Registros Leidos</label>
                <Col sm={7}>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control mt-2"
                    name="registrosLeidos"
                    autoComplete="off"
                    value={resultadosCarga.registrosLeidos ? resultadosCarga.registrosLeidos : "0"}
                  />
                </Col>
                <label className="col-sm-5 col-form-label">Registros Actualizados</label>
                <Col sm={7}>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control"
                    name="registrosLeidos"
                    autoComplete="off"
                    value={resultadosCarga.registrosActualizados ? resultadosCarga.registrosActualizados : "0"}
                  />
                </Col>
                <label className="col-sm-5 col-form-label">Registros Con Errores</label>
                <Col sm={7}>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control"
                    name="registrosConErrores"
                    autoComplete="off"
                    value={resultadosCarga.registrosConErrores ? resultadosCarga.registrosConErrores : "0"}
                  />
                </Col>
                <label className="col-sm-5 col-form-label">Líneas Con Errores</label>

                <Col sm={7}>
                  {/* <ul>{resultadosCarga.lineasConErrores}</ul> */}
                  <textarea
                    disabled={true}
                    className="form-control"
                    name="lineasConErrores"
                    value={resultadosCarga.lineasConErrores.length > 0 ? resultadosCarga.lineasConErrores : "0"}
                  />
                </Col>
              </>
            ) : null}
          </Row>
        </Col>
      </Row>
    </CardBody>
  );
};

export default CargaMasivaModal;
