import React, { useState } from "react";
import { Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import MedicamentoFalso from "../MedicamentoFalso";

const PruebaModalMedicamentoFalso = (props) => {
  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_medicamento_falso: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_medicamento_falso: false,
    });
  };
  return (
    <>
      <button
        onClick={() => {
          setUISweetAlert({
            ...UISweetAlert,
            confirm_medicamento_falso: true,
          });
        }}>
        Abrir Modal medicamento falso
      </button>
      {UISweetAlert.confirm_medicamento_falso ? <MedicamentoFalso onCancel={onCancel} idCatMedicamentoFalso={1} /> : null}
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </>
  );
};

export default PruebaModalMedicamentoFalso;
