import {
  LISTAR_CATPRODUCTOS,
  SET_PRODUCTOS_PAGINADOS,
  FILTRO_PRODUCTOS,
  LISTAR_PRECIOS_PRODUCTO,
  SET_DOCUMENTO_PREVIO,
  LISTAR_SOLUCIONES,
  LISTAR_PRODUCTOS_SELECT,
} from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case LISTAR_CATPRODUCTOS:
      return {
        ...state,
        catProductos: action.payload,
      };
    case LISTAR_PRODUCTOS_SELECT:
      return {
        ...state,
        productosSelect: action.payload,
      };
    case LISTAR_SOLUCIONES:
      return {
        ...state,
        soluciones: action.payload,
      };
    case SET_PRODUCTOS_PAGINADOS:
      return {
        ...state,
        productosPaginados: action.payload,
      };
    case FILTRO_PRODUCTOS:
      return {
        ...state,
        filtrosProductos: action.payload,
      };
    case LISTAR_PRECIOS_PRODUCTO:
      return {
        ...state,
        preciosProducto: action.payload,
      };
    case SET_DOCUMENTO_PREVIO:
      return {
        ...state,
        documentoPrevio: action.payload,
      };
    default:
      return state;
  }
};
