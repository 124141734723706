import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import TiposMedicoContext from "../../context/CatTipoMedico/CatTipoMedicoContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import CatRegimenFiscalContext from "../../context/CatRegimenFiscal/CatRegimenFiscalContext";
import CatUsoCfdiContext from "../../context/CatUsoCfdi/CatUsoCfdiContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";

import useWindowDimensions from "../../hooks/dimensionsHook";

import {
  maxCharTelefono,
  maxCharNombres,
  maxCharApellidoPaterno,
  maxCharApellidoMaterno,
  maxCharRfc,
  maxCharCurp,
  maxCharCorreo,
  maxCharCalle,
  maxCharNumExterno,
  maxCharNumInterno,
  maxCharColonia,
  maxCharMunicipio,
  maxCharPais,
  maxCharCP,
  maxCharPrefijo,
  maxCharEspecialidad,
  maxCharCedulaProfesional,
  maxCharUniversidadProfesional,
  maxCharCedulaEspecialidad,
  maxCharUniversidadEspecialidad,
} from "../../config/variables";

const Medicos = () => {
  const { width } = useWindowDimensions();

  const ctxUsuarios = useContext(UsuariosContext);
  const { listarUsuarios, usuarios, listarUsuariosSinMedico, usuariosSinMedico } = ctxUsuarios;

  const [usuariosConMedicoAModificar, setUsuariosConMedicoAModificar] = useState([]);

  const filtrosVacios = {
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    especialidad: "",
    cedulaProfesional: "",
    universidadProfesional: "",
    cedulaEspecialidad: "",
    universidadEspecialidad: "",
    tipoMedico: "",
    fechaNacimiento: "",
    rfc: "",
    curp: "",
    montoMaximoFactura: "",
    telefonoFijo: "",
    telefonoCelular: "",
    correo: "",
    calle: "",
    numExterno: "",
    numInterno: "",
    colonia: "",
    municipio: "",
    estado: "",
    pais: "",
    cp: "",
  };

  const datosMedicoVacio = {
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    especialidad: "",
    cedulaProfesional: "",
    universidadProfesional: "",
    cedulaEspecialidad: "",
    universidadEspecialidad: "",
    idTipoMedico: "",
    fechaNacimiento: "",
    rfc: "",
    curp: "",
    montoMaximoFactura: "",
    telefonoFijo: "",
    telefonoCelular: "",
    correo: "",
    calle: "",
    numExterno: "",
    numInterno: "",
    colonia: "",
    municipio: "",
    /*estado: "",*/
    idEstado: "",
    idCatRegimenFiscal: "",
    idCatUsoCfdi: "",
    pais: "",
    cp: "",
    idCatUsuario: "",
  };

  const [datosNuevoMedico, setDatosNuevoMedico] = useState(datosMedicoVacio);
  const [medicoSeleccionado, setMedicoSeleccionado] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_medico: false,
    confirm_nuevo_medico: false,
    confirm_eliminar_medico: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
  const [data, setData] = useState([
    {
      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      especialidad: "",
      cedulaProfesional: "",
      universidadProfesional: "",
      cedulaEspecialidad: "",
      universidadEspecialidad: "",
      idTipoMedico: "",
      fechaNacimiento: "",
      rfc: "",
      curp: "",
      montoMaximoFactura: "",
      telefonoFijo: "",
      telefonoCelular: "",
      correo: "",
      calle: "",
      numExterno: "",
      numInterno: "",
      colonia: "",
      municipio: "",
      /*estado: "",*/
      idEstado: "",
      pais: "",
      cp: "",
    },
  ]);
  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setDataMedico({ page, limit });

      const data = response.medicos.map((medico) => {
        if (medico.fechaNacimiento)
          return {
            ...medico,
            fechaNacimiento: medico.fechaNacimiento.split("T")[0],
          };
        return medico;
      });

      // Cantidad de datos disponibles en la tabla medicos de la base de datos
      const count = response.count;

      setData(data);
      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server.
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);

    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
    // setLoadingData(true);
    // fetchAPIData({
    //   page: pageIndex + 1,
    //   limit: pageSize,
    //   byBusquedaFiltros: false,
    // });
  };

  const handdleCheckedMedico = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        mergeMedicos();
        setMedicoSeleccionado({
          ...row.original,
        });
      } else if (rowSeleccionada === row.id) {
        setUsuariosConMedicoAModificar([]);
        setRowSeleccionada(null);
        setMedicoSeleccionado(null);
      } else {
        setRowSeleccionada(row.id);
        mergeMedicos();
        setMedicoSeleccionado({
          ...row.original,
        });
      }

      function mergeMedicos() {
        let usuarioMedico;
        let usuariosConMedicoAModificarYSinMedico;

        usuarioMedico =
          usuarios &&
          usuarios.filter((usuario) => {
            if (usuario.idCatUsuario == row.original.idCatUsuario) return usuario;
          });

        if (usuarioMedico && usuarioMedico.length > 0) {
          usuariosConMedicoAModificarYSinMedico = [...usuariosSinMedico, usuarioMedico[0]];
        } else {
          usuariosConMedicoAModificarYSinMedico = [...usuariosSinMedico];
        }

        setUsuariosConMedicoAModificar(usuariosConMedicoAModificarYSinMedico);
      }
    },
    [rowSeleccionada, usuarios, usuariosSinMedico]
  );

  // Se definen las columnas para la tabla de react-tabe
  // Se debe usar useMemo para optimización y porque lo pide la librería

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCatMedico",
        Cell: ({ row }) => {
          return (
            <input
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedMedico(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Nombres", accessor: "nombres" },
      { Header: "Apellido Paterno", accessor: "apellidoPaterno" },
      { Header: "Apellido Materno", accessor: "apellidoMaterno" },
      { Header: "Especialidad", accessor: "especialidad" },
      { Header: "Cédula Prof.", accessor: "cedulaProfesional" },
      { Header: "Univ. Prof.", accessor: "universidadProfesional" },
      { Header: "Cédula Espec.", accessor: "cedulaEspecialidad" },
      { Header: "Univ. Espec.", accessor: "universidadEspecialidad" },
      { Header: "Tipo Médico", accessor: "tipoMedico" },
      { Header: "Fecha Nacimiento", accessor: "fechaNacimiento" },
      { Header: "RFC", accessor: "rfc" },
      { Header: "CURP", accessor: "curp" },
      { Header: "Monto Factura", accessor: "montoMaximoFactura" },
      { Header: "Tel Fijo", accessor: "telefonoFijo" },
      { Header: "Tel Celular", accessor: "telefonoCelular" },
      { Header: "Correo", accessor: "correo" },
      { Header: "Calle", accessor: "calle" },
      { Header: "Num Externo", accessor: "numExterno" },
      { Header: "Num Interno", accessor: "numInterno" },
      { Header: "Colonia", accessor: "colonia" },
      { Header: "Municipio", accessor: "municipio" },
      { Header: "Estado", accessor: "estado" },
      { Header: "País", accessor: "pais" },
      { Header: "CP", accessor: "cp" },
    ],
    [rowSeleccionada, handdleCheckedMedico]
  );

  const catUsoCfdiContext = useContext(CatUsoCfdiContext);
  const { listarCatUsoCfdis, catUsoCfdis } = catUsoCfdiContext;

  const catRegimenFiscalContext = useContext(CatRegimenFiscalContext);
  const { listarCatRegimenFiscales, catRegimenFiscales } = catRegimenFiscalContext;

  useEffect(() => {
    const fetchAllContextData = async () => {
      listarCatRegimenFiscales();
      listarCatUsoCfdis();
      listarUsuariosSinMedico();
      listarUsuarios();
      listarTiposMedico();
      listarLugares();
    };
    fetchAllContextData();
  }, []);

  const medicosContext = useContext(MedicosContext);
  const lugaresContext = useContext(LugaresContext);
  const tiposMedicoContext = useContext(TiposMedicoContext);

  //ACCESO AL ESTADO GLOBAL - AUTENTICACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const { lugares, listarLugares } = lugaresContext;
  const { tiposMedico, listarTiposMedico } = tiposMedicoContext;
  const { setFiltrosMedicos, filtrosMedicos, uploadNuevoMedico, eliminarMedico, setDataMedico, updateMedico } = medicosContext;

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  const autorizado =
    usuario.authorities.includes("OP_CATALOGO_MEDICO_CREAR") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICO_MODIFICAR") ||
    usuario.authorities.includes("OP_CATALOGO_MEDICO_ELIMINAR") ||
    usuario.authorities.includes("ROLE_QUIMIO") ||
    usuario.authorities.includes("ROLE_RECEPCION");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function setDataMedico
     * @param {object} filtrosMedicos Filtros enviados al back para filtrar los medicos
     * @param {int} page Página a regresar de medicos
     * @param {int} limit Número de medicos que se van a regresar por llamada al back
     */
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosMedicos(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltrosMedicos({
      ...filtrosMedicos,
      [name]: value,
    });
  };

  const changeNuevoMedico = (e) => {
    const { name, value } = e.target;
    setDatosNuevoMedico({
      ...datosNuevoMedico,
      [name]: value,
    });
  };

  const changeModificarMedico = (e) => {
    const { name, value } = e.target;
    setMedicoSeleccionado({
      ...medicoSeleccionado,
      [name]: value,
    });
  };

  const lugaresOpciones = lugares.map(({ idCatOrigenResidencia, lugar, ...origenResidencia }) => ({
    ...origenResidencia,
    id: idCatOrigenResidencia,
    nombre: lugar,
  }));

  const opcionesTipoMedicos = tiposMedico.map(({ idCatTipoMedico, tipoMedico, ...tiposMedico }) => ({
    ...tiposMedico,
    id: idCatTipoMedico,
    nombre: tipoMedico,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Nombre",
          type: "text",
          name: "nombres",
          value: filtrosMedicos.nombres,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Apellido Paterno",
          type: "text",
          name: "apellidoPaterno",
          value: filtrosMedicos.apellidoPaterno,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Apellido Materno",
          type: "text",
          name: "apellidoMaterno",
          value: filtrosMedicos.apellidoMaterno,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Especialidad",
          type: "text",
          name: "especialidad",
          value: filtrosMedicos.especialidad,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "select",
          label: "Tipo Médico",
          name: "tipoMedico",
          value: filtrosMedicos.tipoMedico,
          opciones: opcionesTipoMedicos,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Fecha de Nacimiento",
          type: "date",
          name: "fechaNacimiento",
          value: filtrosMedicos.fechaNacimiento,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "RFC",
          type: "text",
          name: "rfc",
          value: filtrosMedicos.rfc,
          onChange: onChange,
        },
        // {
        //   colType: "input",
        //   label: "CURP",
        //   type: "text",
        //   name: "curp",
        //   value: filtrosMedicos.curp,
        //   onChange: onChange,
        // },
      ],
    },
  ];

  // eslint-disable-next-line
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // const phoneformat = /^\d{10}$/;
  const rfcformat = /^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?$/;
  const cpformat = /^\d{4,5}$/;
  const currencyformat = /^\d{4,5}$/;

  const validarDatosMedico = (medico, isModificarMedico) => {
    let errorNuevoMedico = false;
    if (!errorNuevoMedico && (medico.nombres === "" || medico.nombres === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el nombre del médico",
      });
    }
    if (!errorNuevoMedico && (medico.apellidoPaterno === "" || medico.apellidoPaterno === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el apellido paterno del médico",
      });
    }
    /*if (!errorNuevoMedico && (medico.apellidoMaterno === "" || medico.apellidoMaterno === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el apellido materno del médico",
      });
    }*/
    if (!errorNuevoMedico && (medico.especialidad === "" || medico.especialidad === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la especialidad del médico",
      });
    }
    if (!errorNuevoMedico && (medico.cedulaProfesional === "" || medico.cedulaProfesional === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la cédula profesional del médico",
      });
    }
    if (!errorNuevoMedico && (medico.cedulaEspecialidad === "" || medico.cedulaEspecialidad === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la cédula de especialidad del médico",
      });
    }
    if (!errorNuevoMedico && (medico.tipoMedico === "" || medico.tipoMedico === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el tipo de médico",
      });
    }
    if (!errorNuevoMedico && (medico.fechaNacimiento === "" || medico.fechaNacimiento === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la fecha de nacimiento del médico",
      });
    }
    /*if (!errorNuevoMedico && (medico.rfc === "" || medico.rfc === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el RFC del médico",
      });
    }*/
    if (!errorNuevoMedico && medico.rfc !== "" && medico.rfc !== null && !medico.rfc.match(rfcformat)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un RFC válido",
      });
    }
    /*if (!errorNuevoMedico && (medico.curp === "" || medico.curp === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el CURP del médico",
      });
    }*/
    if (!errorNuevoMedico && !isModificarMedico) {
      if (
        !errorNuevoMedico &&
        medico.montoMaximoFactura !== "" &&
        medico.montoMaximoFactura !== null &&
        !medico.montoMaximoFactura.match(currencyformat)
      ) {
        errorNuevoMedico = true;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, ingrese un monto máximo de factura válido",
        });
      }
    }

    if (!errorNuevoMedico && (medico.calle === "" || medico.calle === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la calle del médico",
      });
    }
    if (!errorNuevoMedico && (medico.numExterno === "" || medico.numExterno === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el número externo del médico",
      });
    }
    if (!errorNuevoMedico && (medico.colonia === "" || medico.colonia === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la colonia del médico",
      });
    }
    if (!errorNuevoMedico && (medico.municipio === "" || medico.municipio === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el municipio del médico",
      });
    }
    /*if (!errorNuevoMedico && (medico.estado === "" || medico.estado === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el estado del médico",
      });
    }*/
    if (!errorNuevoMedico && (medico.idEstado === "" || medico.idEstado === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el estado del médico",
      });
    }
    if (!errorNuevoMedico && (medico.pais === "" || medico.pais === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el pais del médico",
      });
    }
    if (!errorNuevoMedico && (medico.cp === "" || medico.cp === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el CP del médico",
      });
    }
    if (!errorNuevoMedico && !medico.cp.toString().match(cpformat)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un código postal válido",
      });
    }
    // if (!errorNuevoMedico && (medico.telefonoFijo === "" || medico.telefonoFijo === null)) {
    //   errorNuevoMedico = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     confirm_alert_actualizar_fecha: false,
    //     dynamic_description: "Por favor, ingrese el teléfono fijo del médico",
    //   });
    // }
    // if (!errorNuevoMedico && !medico.telefonoFijo.match(phoneformat)) {
    //   errorNuevoMedico = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese un teléfono fijo de contacto válido",
    //   });
    // }
    if (!errorNuevoMedico && (medico.telefonoCelular === "" || medico.telefonoCelular === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el teléfono celular del médico",
      });
    }
    // if (!errorNuevoMedico && !medico.telefonoCelular.match(phoneformat)) {
    //   errorNuevoMedico = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese un teléfono celular de contacto válido",
    //   });
    // }
    if (!errorNuevoMedico && (medico.correo === "" || medico.correo === null)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el correo del médico",
      });
    }
    if (!errorNuevoMedico && !medico.correo.match(mailformat)) {
      errorNuevoMedico = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un correo de contacto válido",
      });
    }

    return errorNuevoMedico;
  };

  const confirmNuevoMedico = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nuevo_medico: true,
      });
    }
  };

  const agregarNuevoMedico = async () => {
    const medicoValido = validarDatosMedico(datosNuevoMedico);

    if (!medicoValido) {
      setLoading(true);

      const res = await uploadNuevoMedico(datosNuevoMedico);

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nuevo_medico: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nuevo_medico: false,
          dynamic_description: "Se guardó el nuevo médico correctamente",
        });
        // setData([...data, res.data]);
        listarUsuarios();
        listarUsuariosSinMedico();
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });

        setDatosNuevoMedico(datosMedicoVacio);
      }
    }
  };

  // const updateDataOnDeleteMedico = async (idCatMedico) => {
  //   const updatedData = data.filter((medico) => medico.idCatMedico !== idCatMedico);
  //   setData(updatedData);
  // };

  const checkEliminarMedico = () => {
    if (autorizado && medicoSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_medico: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un médico",
      });
    }
  };

  const confirmEliminarMedico = async () => {
    setLoading(true);
    const res = await eliminarMedico(medicoSeleccionado.idCatMedico);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_medico: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_medico: false,
        dynamic_description: "Se eliminó el médico correctamente",
      });
      // updateDataOnDeleteMedico(medicoSeleccionado.idCatMedico);
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setMedicoSeleccionado(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarMedico = () => {
    if (autorizado && medicoSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_medico: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un médico",
      });
    }
  };

  const confirmModicarMedico = async () => {
    const medicoValido = validarDatosMedico(medicoSeleccionado, true);

    if (!medicoValido) {
      setLoading(true);
      const res = await updateMedico(medicoSeleccionado);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_medico: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_medico: false,
          dynamic_description: "Se modificó el médico correctamente",
        });
        // const medicosActualizados = await data.map((medico) => {
        //   if (res.data.idCatMedico === medico.idCatMedico) {
        //     return (medico = res.data);
        //   } else {
        //     return medico;
        //   }
        // });

        // const dataActualizada = await medicosActualizados.map((medico) => {
        //   return {
        //     ...medico,
        //     //, fechaNacimiento: medico.fechaNacimiento.split("T")[0]
        //   };
        // });

        // setData(dataActualizada);
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setMedicoSeleccionado(null);
        setRowSeleccionada(null);
      }
    }
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Catálogo de Médicos</h3>
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Médicos</h4>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevoMedico()}>
                      Nuevo Medico
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarMedico()}>
                      Modificar Médico
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarMedico()}>
                      Eliminar Medico
                    </button>
                  ) : null}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={data}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nuevo_medico ? (
        <SweetAlert
          title="Nuevo Médico"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            agregarNuevoMedico();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nuevo_medico: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">
                    Nombre<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombres"
                      maxLength={maxCharNombres}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.nombres ? datosNuevoMedico.nombres : ""}
                    />
                    {datosNuevoMedico.nombres === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Apellido Paterno<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidoPaterno"
                      maxLength={maxCharApellidoPaterno}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.apellidoPaterno ? datosNuevoMedico.apellidoPaterno : ""}
                    />
                    {datosNuevoMedico.apellidoPaterno === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Apellido Materno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidoMaterno"
                      maxLength={maxCharApellidoMaterno}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.apellidoMaterno ? datosNuevoMedico.apellidoMaterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Especialidad<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="especialidad"
                      autoComplete="off"
                      maxLength={maxCharEspecialidad}
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.especialidad ? datosNuevoMedico.especialidad : ""}
                    />
                    {datosNuevoMedico.especialidad === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Cédula / Univ. Professional<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cedulaProfesional"
                      autoComplete="off"
                      maxLength={maxCharCedulaProfesional}
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.cedulaProfesional ? datosNuevoMedico.cedulaProfesional : ""}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="universidadProfesional"
                      autoComplete="off"
                      maxLength={maxCharUniversidadProfesional}
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.universidadProfesional ? datosNuevoMedico.universidadProfesional : ""}
                    />
                    {datosNuevoMedico.cedulaProfesional === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Cédula / Univ. Especialidad<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cedulaEspecialidad"
                      maxLength={maxCharCedulaEspecialidad}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.cedulaEspecialidad ? datosNuevoMedico.cedulaEspecialidad : ""}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="universidadEspecialidad"
                      maxLength={maxCharUniversidadEspecialidad}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.universidadEspecialidad ? datosNuevoMedico.universidadEspecialidad : ""}
                    />
                    {datosNuevoMedico.cedulaEspecialidad === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Tipo Médico<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idTipoMedico"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.idTipoMedico}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesTipoMedicos.map((tipoMedico) => {
                        return <option value={tipoMedico.id}>{tipoMedico.nombre}</option>;
                      })}
                    </select>
                    {datosNuevoMedico.idTipoMedico === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Fecha Nacimiento<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="date"
                      className="form-control"
                      name="fechaNacimiento"
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.fechaNacimiento ? datosNuevoMedico.fechaNacimiento : ""}
                    />
                    {datosNuevoMedico.fechaNacimiento === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">RFC</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="rfc"
                      autoComplete="off"
                      maxLength={maxCharRfc}
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.rfc ? datosNuevoMedico.rfc : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">CURP</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="curp"
                      maxLength={maxCharCurp}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.curp ? datosNuevoMedico.curp : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Monto Máximo Factura</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="montoMaximoFactura"
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.montoMaximoFactura ? datosNuevoMedico.montoMaximoFactura : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Prefijo</label>
                  <Col sm={7}>
                    <input
                      placeholder="Ej. Dra."
                      type="text"
                      className="form-control"
                      name="prefijo"
                      maxLength={maxCharPrefijo}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.prefijo ? datosNuevoMedico.prefijo : ""}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Teléfono Fijo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoFijo"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.telefonoFijo ? datosNuevoMedico.telefonoFijo : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Teléfono Celular<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoCelular"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.telefonoCelular ? datosNuevoMedico.telefonoCelular : ""}
                    />
                    {datosNuevoMedico.telefonoCelular === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Correo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="correo"
                      autoComplete="off"
                      maxLength={maxCharCorreo}
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.correo ? datosNuevoMedico.correo : ""}
                    />
                    {datosNuevoMedico.correo === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    {!datosNuevoMedico.correo.match(mailformat) && datosNuevoMedico.correo !== "" && (
                      <span className="text-sm text-red-700">Correo inválido</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Calle<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="calle"
                      autoComplete="off"
                      maxLength={maxCharCalle}
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.calle ? datosNuevoMedico.calle : ""}
                    />
                    {datosNuevoMedico.calle === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Num Externo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numExterno"
                      autoComplete="off"
                      maxLength={maxCharNumExterno}
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.numExterno ? datosNuevoMedico.numExterno : ""}
                    />
                    {datosNuevoMedico.numExterno === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Num Interno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numInterno"
                      autoComplete="off"
                      maxLength={maxCharNumInterno}
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.numInterno ? datosNuevoMedico.numInterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Colonia<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="colonia"
                      maxLength={maxCharColonia}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.colonia ? datosNuevoMedico.colonia : ""}
                    />
                    {datosNuevoMedico.colonia === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Municipio<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="municipio"
                      autoComplete="off"
                      maxLength={maxCharMunicipio}
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.municipio ? datosNuevoMedico.municipio : ""}
                    />
                    {datosNuevoMedico.municipio === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Estado<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idEstado"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.idEstado}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {lugaresOpciones.map((lugar) => {
                        return <option value={lugar.id}>{lugar.nombre}</option>;
                      })}
                    </select>
                    {datosNuevoMedico.idEstado === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    País<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="pais"
                      autoComplete="off"
                      maxLength={maxCharPais}
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.pais ? datosNuevoMedico.pais : ""}
                    />
                    {datosNuevoMedico.pais === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    CP<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cp"
                      maxLength={maxCharCP}
                      autoComplete="off"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.cp ? datosNuevoMedico.cp : ""}
                    />
                    {datosNuevoMedico.cp === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Régimen Fiscal</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatRegimenFiscal"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.idCatRegimenFiscal}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catRegimenFiscales.map((regimen) => {
                        return (
                          <option value={regimen.idCatRegimenFiscal}>{`${regimen.clave} - ${regimen.regimenFiscal}`}</option>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Uso CFDi</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatUsoCfdi"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.idCatUsoCfdi}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catUsoCfdis.map((usoCfdi) => {
                        return <option value={usoCfdi.idCatUsoCfdi}>{`${usoCfdi.clave} - ${usoCfdi.usoCfdi}`}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Usuario del Médico</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatUsuario"
                      onChange={changeNuevoMedico}
                      value={datosNuevoMedico.idCatUsuario ? datosNuevoMedico.idCatUsuario : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {usuariosSinMedico &&
                        usuariosSinMedico.map((usuario) => {
                          return <option value={usuario.idCatUsuario}>{`${usuario.nombre} - ${usuario.usuario}`}</option>;
                        })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label"></label>
                  <Col sm={7}>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDatosNuevoMedico(datosMedicoVacio)}>
                      Limpiar Datos
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}
      
      {UISweetAlert.confirm_eliminar_medico ? (
        <SweetAlert
          title="¿Desea eliminar al médico seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: width && width < 851 ? "100%" : "50%",
          }}
          onConfirm={() => confirmEliminarMedico()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_medico: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_medico ? (
        <SweetAlert
          title="Modificar Datos del Médico"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            confirmModicarMedico();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_medico: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">
                    Nombre<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombres"
                      autoComplete="off"
                      maxLength={maxCharNombres}
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.nombres ? medicoSeleccionado.nombres : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Apellido Paterno<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidoPaterno"
                      autoComplete="off"
                      maxLength={maxCharApellidoPaterno}
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.apellidoPaterno ? medicoSeleccionado.apellidoPaterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Apellido Materno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidoMaterno"
                      autoComplete="off"
                      maxLength={maxCharApellidoMaterno}
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.apellidoMaterno ? medicoSeleccionado.apellidoMaterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Especialidad<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="especialidad"
                      autoComplete="off"
                      maxLength={maxCharEspecialidad}
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.especialidad ? medicoSeleccionado.especialidad : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Cédula / Univ. Profesional<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cedulaProfesional"
                      autoComplete="off"
                      maxLength={maxCharCedulaProfesional}
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.cedulaProfesional ? medicoSeleccionado.cedulaProfesional : ""}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="universidadProfesional"
                      autoComplete="off"
                      maxLength={maxCharUniversidadProfesional}
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.universidadProfesional ? medicoSeleccionado.universidadProfesional : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Cédula / Univ. Especialidad<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cedulaEspecialidad"
                      maxLength={maxCharCedulaEspecialidad}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.cedulaEspecialidad ? medicoSeleccionado.cedulaEspecialidad : ""}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="universidadEspecialidad"
                      maxLength={maxCharCedulaEspecialidad}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.universidadEspecialidad ? medicoSeleccionado.universidadEspecialidad : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Tipo Médico<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idTipoMedico"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.idTipoMedico}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesTipoMedicos.map((tipoMedico) => {
                        return <option value={tipoMedico.id}>{tipoMedico.nombre}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Fecha Nacimiento<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="date"
                      className="form-control"
                      name="fechaNacimiento"
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.fechaNacimiento ? medicoSeleccionado.fechaNacimiento : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">RFC</label>
                  <Col sm={7}>
                    <input
                      disabled={false}
                      type="text"
                      className="form-control"
                      name="rfc"
                      maxLength={maxCharRfc}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.rfc ? medicoSeleccionado.rfc : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">CURP</label>
                  <Col sm={7}>
                    <input
                      disabled={false}
                      type="text"
                      className="form-control"
                      name="curp"
                      maxLength={maxCharCurp}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.curp ? medicoSeleccionado.curp : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Monto Máximo Factura</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="montoMaximoFactura"
                      autoComplete="off"
                      value={medicoSeleccionado.montoMaximoFactura ? medicoSeleccionado.montoMaximoFactura : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Prefijo</label>
                  <Col sm={7}>
                    <input
                      placeholder="Ej. Dra."
                      type="text"
                      className="form-control"
                      name="prefijo"
                      maxLength={maxCharPrefijo}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.prefijo ? medicoSeleccionado.prefijo : ""}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Teléfono Fijo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoFijo"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.telefonoFijo ? medicoSeleccionado.telefonoFijo : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Teléfono Celular<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoCelular"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.telefonoCelular ? medicoSeleccionado.telefonoCelular : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Correo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="correo"
                      maxLength={maxCharCorreo}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.correo ? medicoSeleccionado.correo : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Calle<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="calle"
                      maxLength={maxCharCalle}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.calle ? medicoSeleccionado.calle : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Num Externo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numExterno"
                      maxLength={maxCharNumExterno}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.numExterno ? medicoSeleccionado.numExterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Num Interno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numInterno"
                      maxLength={maxCharNumInterno}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.numInterno ? medicoSeleccionado.numInterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Colonia<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="colonia"
                      maxLength={maxCharColonia}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.colonia ? medicoSeleccionado.colonia : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Municipio<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="municipio"
                      maxLength={maxCharMunicipio}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.municipio ? medicoSeleccionado.municipio : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Estado<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idEstado"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.idEstado ? medicoSeleccionado.idEstado : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {lugaresOpciones.map((lugar) => {
                        return <option value={lugar.id}>{lugar.nombre}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    País<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="pais"
                      maxLength={maxCharPais}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.pais ? medicoSeleccionado.pais : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    CP<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cp"
                      maxLength={maxCharCP}
                      autoComplete="off"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.cp ? medicoSeleccionado.cp : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Régimen Fiscal</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatRegimenFiscal"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.idCatRegimenFiscal ? medicoSeleccionado.idCatRegimenFiscal : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catRegimenFiscales.map((regimen) => {
                        return (
                          <option value={regimen.idCatRegimenFiscal}>{`${regimen.clave} - ${regimen.regimenFiscal}`}</option>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Uso CFDi</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatUsoCfdi"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.idCatUsoCfdi ? medicoSeleccionado.idCatUsoCfdi : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catUsoCfdis.map((usoCfdi) => {
                        return <option value={usoCfdi.idCatUsoCfdi}>{`${usoCfdi.clave} - ${usoCfdi.usoCfdi}`}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  {console.log(medicoSeleccionado)}
                  <label className="col-sm-4 col-form-label">Usuario del Médico</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatUsuario"
                      onChange={changeModificarMedico}
                      value={medicoSeleccionado.idCatUsuario ? medicoSeleccionado.idCatUsuario : ""}>
                      <option selected defaultValue="">
                        Seleccione una opción
                      </option>
                      {usuariosConMedicoAModificar &&
                        usuariosConMedicoAModificar.map((usuario) => {
                          return <option value={usuario.idCatUsuario}>{`${usuario.nombre} - ${usuario.usuario}`}</option>;
                        })}
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default Medicos;
