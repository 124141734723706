import React, {useState, useContext, useEffect, useRef, useCallback, useMemo} from "react";
import {Row, Col, Card, CardBody, Spinner} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {Link} from "react-router-dom";

import ValesContext from "../../context/Vales/ValesContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import TipoValeContext from "../../context/TipoVale/TipoValeContext";
import AuthContext from "../../context/Auth/AuthContext";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import useWindowDimensions from "../../hooks/dimensionsHook";

export default function Vales(props) {

    const [rowsSeleccionadas, setRowsSeleccionadas] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const [pageCount, setPageCount] = useState(0);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);

    const [valesTablaSeleccionado, setValesTablaSeleccionado] = useState(null);
    const [mostrarPendientes, setMostrarPendientes] = useState(false);
    const fetchIdRef = useRef(0);


    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);
  
            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);
            }

            let necesitaAutorizacionTemp = 1;
            filtrosVale.estatusVale = null;
            const response = await listarValesPaginadosAutorizaciones({page, limit, necesitaAutorizacionTemp});

            // Cantidad de datos disponibles en la tabla pacientes de la base de datos
            const count = response.count;
            setRowsSeleccionadas(null);
            // setRowSeleccionada(null);
            // setValeTablaSeleccionado(null);
            setValesTablaSeleccionado(null);

            if (count % limit > 0) {
                setPageCount(Math.ceil(count / limit));
            } else {
                setPageCount(count / limit);
            }

            setLoadingData(false);
        } catch (e) {
            console.log("Error while fetching", e);
            // setLoading(false)
        }
    };

    const fetchData = async ({pageSize, pageIndex}) => {
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: false,
            });
        }
    };

    const handleCheckedVale = useCallback(
        (row) => {
            if (rowsSeleccionadas === null) {
                const rows = [row.id];
                setRowsSeleccionadas(rows);
                const vales = [{...row.original}];
                setValesTablaSeleccionado(vales);
            } else if (rowsSeleccionadas.length === 0) {
                const rows = [row.id];
                setRowsSeleccionadas(rows);
                const vales = [{...row.original}];
                setValesTablaSeleccionado(vales);
            } else if (rowsSeleccionadas.includes(row.id)) {
                const tempRows = rowsSeleccionadas.filter((r) => r !== row.id);
                setRowsSeleccionadas(tempRows);
                const tempVales = valesTablaSeleccionado.filter((v) => v.idVale !== row.original.idVale);
                setValesTablaSeleccionado(tempVales);
            } else {
                const rows = [...rowsSeleccionadas, row.id];
                setRowsSeleccionadas(rows);
                const vales = [...valesTablaSeleccionado, {...row.original}];
                setValesTablaSeleccionado(vales);
            }
        },
        [rowsSeleccionadas]
    );

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "idVale",
                Cell: ({row}) => {
                    return (
                        <input
                            // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
                            checked={rowsSeleccionadas != null ? rowsSeleccionadas.includes(row.id) : false}
                            type="checkbox"
                            onChange={() => handleCheckedVale(row)}
                        />
                    );
                },

                disableFilters: true,
                disableSortBy: true,
            },
            {Header: "Folio", accessor: "folioVale"},
            {
                Header: () => (
                    <>
                        Fecha del<br></br>Vale
                    </>
                ),
                accessor: "fechaVale",
                Cell: ({row}) => {
                    return <>{row.original.fechaVale ? row.original.fechaVale.split("T")[0] : ""}</>;
                },
            },
            {
                Header: () => (
                    <>
                        Fecha de<br></br>Tratamiento
                    </>
                ),
                accessor: "fechaTratamiento",
                Cell: ({row}) => {
                    return <>{row.original.fechaTratamiento ? row.original.fechaTratamiento.split("T")[0] : ""}</>;
                },
            },            
            {Header: "Paciente", accessor: "paciente"},
            {Header: "Médico", accessor: "medico"},
            {
                Header: "Aseguradora",
                accessor: "aseguradora",
                Cell: ({row}) => {
                    return <>{row.original.aseguradora ? row.original.aseguradora : "N/A"}</>;
                },
            },
            {
                Header: "Autorización",
                accessor: "Autorizar",
                Cell: ({row}) => {
                    return( 
                        row.original.necesitaAutorizacion== 1 ?(
                            <div className="celdaAutorizaciones" >
                                    <Link 
                                        to={`/vales/historico/${row.original.idVale}`}
                                        className={ row.original.necesitaAutorizacion== 1 ? "celdaAutorizaciones btn btn-sm btn-primary-orange-umo" : 'disabled-link' }
                                        //disabled = { row.original.necesitaAutorizacion== 1 ? false : true }
                                        >
                                        Autorizar
                                    </Link>
                                </div>
                        ):(
                            <div className="celdaAutorizaciones" >
                                {" "}
                                {row.original.necesitaAutorizacion!= 3 ?  
                                <i className=" text-center ti-check"></i> : <i className=" text-center ti-close"></i>}
                                {" "}
                                
                            </div>
                        )
                    )
                },
                disableFilters: true,
                disableSortBy: true,
            },                                
        ],
        [rowsSeleccionadas, handleCheckedVale]
    );

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_devolver_vale: false,
        confirm_alert_cancelar_vale: false,
        confirm_alert_revivir_vale: false,
        confirm_alert_cambiar_concentracion_vale: false,
        confirm_alert_imprimir_vale: false,
        confirm_alert_imprimir_cotizacion: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    const [loading, setLoading] = useState(false);

    const [datosCotizacion, setDatosCotizacion] = useState({
        encabezado: "",
        piePagina: "",
        tratamiento: "",
        aplicacion: "",
    });
     const fechaActual = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + ""
     
    const cleanCancelarDevolverVale = {
        fechaDevolucion: new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "",
        fechaCancelacion: new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "",
        //fechaDevolucion: format(new Date(), 'dd-MM-yyyy'),
        //fechaCancelacion: format(new Date(), 'dd-MM-yyyy'),
        comentarioEstatus: "",
    };
    const [cancelarDevolverVale, setCancelarDevolverVale] = useState(cleanCancelarDevolverVale);

    // ACCESO A ESTADO GLOBAL - VALES
    const ctxVales = useContext(ValesContext);
    const {
        valesFiltrados,
        valeSeleccionado,
        cleanValeSeleccionado,
        filtrosVale,
        setChangesFiltrosVale,
        listarValesPaginadosAutorizaciones
    } = ctxVales;

    //ACCESO AL ESTADO GLOBAL - MEDICOS
    const ctxMedicos = useContext(MedicosContext);
    const {medicos, listarMedicos} = ctxMedicos;

    //ACCESO AL ESTADO GLOBAL - MEDICOS
    const ctxPacientes = useContext(PacientesContext);
    const {listarPacientes, loadPacientesSelect} = ctxPacientes;

    //ACCESO AL ESTADO GLOBAL - Aseguradoras
    const ctxAseguradoras = useContext(AseguradorasContext);
    const {aseguradoras, listarAseguradoras} = ctxAseguradoras;

    //ACCESO AL ESTADO GLOBAL - Estatus Vale
    const ctxEstatusVale = useContext(EstatusValeContext);
    const {
        listarEstatusVale,
        setcambioEstatusData,
        proximosEstatusVale,
        cleanProximosEstatusVale,
    } = ctxEstatusVale;

    //ACCESO AL ESTADO GLOBAL - Estatus Vale
    const ctxUsuarios = useContext(UsuariosContext);
    const {usuarios, listarUsuarios} = ctxUsuarios;

    //ACCESO AL ESTADO GLOBAL - Estatus Vale
    const ctxTiposVale = useContext(TipoValeContext);
    const {tiposVale, listarTiposVale} = ctxTiposVale;

    //ACCESO AL ESTADO GLOBAL - AUTENCIACION
    const ctxAuth = useContext(AuthContext);
    const {usuario} = ctxAuth;

    // MANEJO DE CRUD LOCAL

    const changePendiente = async (e) => {
        const {name, value, checked} = e.target;
        if (checked) {
            setMostrarPendientes(true)
        }else{
            setMostrarPendientes(false)
        }
    };

    const onChangeFolio = (e) => {
        setChangesFiltrosVale({
            ...filtrosVale,
            [e.target.name]: e.target.value,
        });
    };

    const buscarPorFolio = (e) => {
        e.preventDefault();
        fetchData({pageSize: 10, pageIndex: 0});
    };

     useEffect(() => {
        listarEstatusVale(true);
        listarAseguradoras();
        listarMedicos();
        listarPacientes();
        listarUsuarios();
        listarTiposVale();

        setcambioEstatusData({
            valeProductoServicio: [],
        });

        if (valeSeleccionado) {
            cleanValeSeleccionado();
        }
        if (proximosEstatusVale) {
            cleanProximosEstatusVale();
        }

    }, []);
    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Listado de Vales a Autorizar</h3>
           {<Card
                className="mt-10 card"
                style={{
                    padding: "1.5rem 1.5rem 1.5rem",
                }}>
                <form onSubmit={(e) => buscarPorFolio(e)}>
                    <CardBody>
                        <Row>
                            <label className="col-sm-5 col-form-label">
                                Buscar por folio<span className="text-xs text-red-700">*</span>
                            </label>                            
                            <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                                <Row
                                    className="form-group"
                                    style={{
                                        marginBottom: "0",
                                    }}>
                                    <Col sm={10} className="flex col-sm-10">
                                        <input
                                            className="form-control mr-2.5"
                                            type="text"
                                            name="folioVale"
                                            placeholder="Escribe tu folio"
                                            onChange={onChangeFolio}
                                            value={filtrosVale.folioVale ? filtrosVale.folioVale : ""}
                                        />
                                        <button
                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                            style={{
                                                marginRight: "2.5rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            type="submit">
                                            Buscar
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                            {}
                        </Row>
                    </CardBody>
                </form>
            </Card>}
            {/*<Row>
                <Col sm={2}>
                    <input
                        type="checkbox"
                        name="mostrarPendientes"
                        className="form-control"
                        onChange={changePendiente}
                        checked={mostrarPendientes}
                    />
                </Col>
            </Row>*/}
            <Row>
                <Col>
                    <Card className="mt-4">
                        <CardBody>
                            <TableComponent
                                pageCount={pageCount}
                                fetchData={fetchData}
                                columns={columns}
                                loading={loadingData}
                                data={valesFiltrados}
                                indexOfPage={controlledPageIndex}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}
            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
