/* eslint-disable import/no-anonymous-default-export */
import { LISTAR_ESCOLARIDADES } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_ESCOLARIDADES:
      return {
        ...state,
        escolaridades: action.payload,
      };

    default:
      return state;
  }
};
