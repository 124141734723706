import React, { useReducer } from "react";

import VentaMostradorContext from "./VentaMostradorContext";
import VentaMostradorReducer from "./VentaMostradorReducer";

import {
  LISTAR_VENTASMOSTRADOR,
  CAMBIOS_FILTRO_VENTA_MOSTRADOR,
  LISTAR_VENTASMOSTRADOR_FILTRADO,
  CREAR_NUEVA_VENTA_MOSTRADOR,
  ERROR_CREAR_NUEVA_VENTA_MOSTRADOR,
  VENTA_MOSTRADOR_SELECCIONADO,
  ERROR_VENTA_MOSTRADOR_SELECCIONADO,
  LIMPIAR_VENTA_MOSTRADOR_SELECCIONADO,
  CANCELAR_VENTA,
  ERROR_CANCELAR_VENTA,
  ACTUALIZAR_ESTATUS_VENTAMOSTRADOR,
  ERROR_ACTUALIZAR_ESTATUS_VENTAMOSTRADOR,
  NUEVO_COBRO_VENTAMOSTRADOR,
  ERROR_NUEVO_COBRO_VENTAMOSTRADOR,
  ELIMINAR_COBRO_VENTAMOSTRADOR,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function VentaMostradorState(props) {
  const initialState = {
    ventasMostradorFiltradas: [],
    filtrosVentaMostrador: {},
    ventaMostradorSeleccionada: {},
  };

  const [state, dispatch] = useReducer(VentaMostradorReducer, initialState);

  const listarVentasMostrador = async () => {
    const res = await clienteAxios.get("/ventamostrador", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_VENTASMOSTRADOR,
      payload: res.data,
    });
  };

  const setFiltrosVentaMostrador = async (filtrosVentaMostrador) => {
    dispatch({
      type: CAMBIOS_FILTRO_VENTA_MOSTRADOR,
      payload: filtrosVentaMostrador,
    });
  };

  const setVentasMostradorFiltradas = async (ventasMostradorFiltradas) => {
    dispatch({
      type: LISTAR_VENTASMOSTRADOR,
      payload: ventasMostradorFiltradas,
    });
  };

  const listarVentasMostradorFiltrado = async (data) => {
    const res = await clienteAxios.post("/ventamostrador/filtro/json/detalle", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_VENTASMOSTRADOR_FILTRADO,
      payload: res.data,
    });
  };

  const crearNuevaVentaMostrador = async (data) => {
    try {
      const res = await clienteAxios.post(`/ventamostrador/nuevo`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      dispatch({
        type: CREAR_NUEVA_VENTA_MOSTRADOR,
        payload: res.data,
      });

      return res;
    } catch (error) {
      // console.log(error.response);
      // console.log(error);
      //Error

      dispatch({
        type: ERROR_CREAR_NUEVA_VENTA_MOSTRADOR,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const seleccionarVentaMostrador = async (idVentaMostrador, historicoVentaMostrador) => {
    try {
      const res = await clienteAxios.get(`/ventamostrador/detalles?id=${idVentaMostrador}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      res.data.historicoVentaMostrador = historicoVentaMostrador;

      dispatch({
        type: VENTA_MOSTRADOR_SELECCIONADO,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_VENTA_MOSTRADOR_SELECCIONADO,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setVentaMostradorSeleccionada = async (ventaMostrador) => {
    dispatch({
      type: VENTA_MOSTRADOR_SELECCIONADO,
      payload: ventaMostrador,
    });
  };

  const limpiarVentaMostradorSeleccionada = async () => {
    dispatch({
      type: LIMPIAR_VENTA_MOSTRADOR_SELECCIONADO,
      payload: null,
    });
  };

  const cancelarVentaMostrador = async (data) => {
    try {
      const res = await clienteAxios.post(`/ventamostrador/actualizar/estatus`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      dispatch({
        type: CANCELAR_VENTA,
        payload: res.data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_CANCELAR_VENTA,
        payload: null,
      });

      console.log(error.response);

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const actualizarEstatusVentaMostrador = async (data) => {
    try {
      const res = await clienteAxios.post(`/ventamostrador/actualizar/estatus`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      dispatch({
        type: ACTUALIZAR_ESTATUS_VENTAMOSTRADOR,
        payload: res.data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_ACTUALIZAR_ESTATUS_VENTAMOSTRADOR,
        payload: null,
      });

      console.log(error.response);

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const exportCotizacionVentaMostrador = async (idVentaMostrador) => {
    try {
      const res = await clienteAxios.get(`/ventamostrador/export/cotizacion/${idVentaMostrador}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      var file = new Blob([res.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  }; 

  const exportNotaDeVenta = async (data) => {
    try {
      const res = await clienteAxios.post(`/ventamostrador/export/nota`, data, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=nota.pdf",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      var file = new Blob([res.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  const registarCobroVentaMostrador = async (nuevoCobro) => {
    try {
      const res = await clienteAxios.post(`/ventamostrador/cobro`, nuevoCobro, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      dispatch({
        type: NUEVO_COBRO_VENTAMOSTRADOR,
        payload: res.data,
      });

      return res;
    } catch (error) {
      // console.log(error.response);
      // console.log(error);
      //Error

      dispatch({
        type: ERROR_NUEVO_COBRO_VENTAMOSTRADOR,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarCobroVentaMostrador = async (idVentaMostradorCobro) => {
    const res = await clienteAxios.post(`/ventamostrador/cobro/eliminar?id=${idVentaMostradorCobro}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: ELIMINAR_COBRO_VENTAMOSTRADOR,
      payload: state.ventaMostradorSeleccionada,
    });

    return res;
  };

  const listarVentasMostradorPaginados = async ({ page, limit }) => {
    // LLAMADA A BACKEND
    try {
      const response = await clienteAxios.post(
        "/ventamostrador/paginado",
        {
          pageSize: limit,
          pageIndex: page,
          ...state.filtrosVentaMostrador,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      console.log(response);

      dispatch({
        type: LISTAR_VENTASMOSTRADOR_FILTRADO,
        payload: response.data.ventaMostradorVoList,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <VentaMostradorContext.Provider
      value={{
        ventasMostradorFiltradas: state.ventasMostradorFiltradas,
        filtrosVentaMostrador: state.filtrosVentaMostrador,
        ventaMostradorSeleccionada: state.ventaMostradorSeleccionada,
        listarVentasMostrador,
        setFiltrosVentaMostrador,
        listarVentasMostradorFiltrado,
        crearNuevaVentaMostrador,
        seleccionarVentaMostrador,
        setVentaMostradorSeleccionada,
        setVentasMostradorFiltradas,
        limpiarVentaMostradorSeleccionada,
        cancelarVentaMostrador,
        actualizarEstatusVentaMostrador,
        exportCotizacionVentaMostrador,
        exportNotaDeVenta,
        registarCobroVentaMostrador,
        eliminarCobroVentaMostrador,
        listarVentasMostradorPaginados,
      }}>
      {props.children}
    </VentaMostradorContext.Provider>
  );
}
