import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import ExpedienteMedicoContext from "../../context/ExpedienteMedico/ExpedienteMedicoContext";

import PacientesContext from "../../context/Pacientes/PacientesContext";

import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import SexosContext from "../../context/Sexos/SexosContext";

import TableComponent from "../TablaPaginada/index";
// import { InformacionExpediente } from "../ConsentimientoCliente/InformacionExpediente/index";
import { CargaArchivoModal } from "../CargaArchivoModal";

import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";

import DrawerExpedientePaciente from "../DrawerExpedientePaciente";

import useWindowDimensions from "../../hooks/dimensionsHook";

const ConsentimientoCliente = (props) => {
  // const idExpedienteMedicoParam = useParams(idExpedienteMedico);
  const { width } = useWindowDimensions();
  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const expedienteMedicoContext = useContext(ExpedienteMedicoContext);

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado } = pacientesContext;

  useEffect(() => {
    const fetchAll = () => {
      listarSexos();
      listarAseguradoras();
      listarEscolaridades();
      listarEstadosCiviles();
      listarLugares();
      listarOcupaciones();
      listarMedicos();
    };
    fetchAll();
  }, []);

  const redirectHistoriaClinicaInicial = async () => {
    props.history.push("/historia/clinica/inicial");
  };

  useEffect(() => {
    const getArchivos = async () => {
      if (historiaClinicaInicialVacia) {
        if (idCatPacienteSeleccionado) {
          const res = await seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);

          const response = await setDataConsentimientoCliente({
            page: 1,
            limit: 10,
            idExpedienteMedico: res.expedienteMedico.idExpedienteMedico,
          });

          const data = response.consentimientos;
          const count = response.count;
          setData(data);
          setRowSeleccionada(null);
          if (count % 10 > 0) {
            setPageCount(Math.ceil(count / 10));
          } else {
            setPageCount(count / 10);
          }
          setLoadingData(false);
        }
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    };
    getArchivos();
  }, []);

  const {
    setDataConsentimientoCliente,
    consentimientosPaginados,
    // uploadNuevaImpresion,
    uploadDocumentoFirmado,
    exportConsentimientoClienteReport,
    setDatosExpedienteMedico,
    datosExpedienteMedico,
    exportArchivoBack,
  } = expedienteMedicoContext;

  // useEffect(() => {
  //   if (datosExpedienteMedico === null) setDatosExpedienteMedico(idExpedienteMedico);
  // });
  // eslint-disable-next-line no-unused-vars
  const [consentimientoSeleccionado, setConsentimientoSeleccionado] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_cargar_archivo: false,
    // confirm_nueva_impresion_parte1: false,
    // confirm_nueva_impresion_parte2: false,
    // confirm_nueva_impresion_parte3: false,
    confirm_nueva_impresion: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([
    {
      fechaRegistro: "",
      documento: "",
    },
  ]);

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  const datosNuevaImpresionVacio = {
    fecha: today,
    paciente: "Nombre Paciente",
    diagnostico: "Diagnóstico",
    medico: "Nombre médico",
    cedula: "Cédula Médico",
    alergias: "Alergia1, Alergia2",
    veces: 0,
    periodo: 0,
    usuario: usuario.nombre,
  };

  const datosCargaArchivoVacio = {
    fecha: today,
    selectedFile: null,
    comentario: null,
  };

  const [datosNuevaImpresion, setDatosNuevaImpresion] = useState({
    fecha: today,
    paciente: "Nombre Paciente",
    diagnostico: "Diagnóstico",
    medico: "Nombre médico",
    cedula: "Cédula Médico",
    alergias: "Alergia1, Alergia2",
    veces: 0,
    periodo: 0,
    usuario: usuario.nombre,
  });

  const [datosCargaArchivo, setDatosCargaArchivo] = useState(datosCargaArchivoVacio);

  const onFileChange = (event) => {
    setDatosCargaArchivo({ ...datosCargaArchivo, selectedFile: event.target.files[0] });
    const file = event.target.files[0];
    const reader = new FileReader();

    // convert image file to base64 string

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string

        let strFile = reader.result;
        let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
        let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);
        setDatosCargaArchivo({
          ...datosCargaArchivo,
          selectedFile: file,
          nombreArchivo: file.name,
          mimeType: type,
          tamano: file.size,
          inputStream: fileBase64,
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      if (!byBusquedaFiltros) {
        setControlledPageIndex(page - 1);
      } else {
        setControlledPageIndex(0);
      }
      const response = await setDataConsentimientoCliente({
        page,
        limit,
        idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      });

      const data = response.consentimientos;
      const count = response.count;
      setData(data);
      setRowSeleccionada(null);
      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }
      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server.
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const handdleCheckedConsentimiento = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setConsentimientoSeleccionado(row.values);
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setConsentimientoSeleccionado(null);
      }
    },
    [rowSeleccionada]
  );

  const exportArchivo = async (archivo) => {
    console.log("🚀 ~ file: index.js ~ line 293 ~ exportArchivo ~ archivo", archivo);
    const archivoVo = {
      rutaArchivo: archivo.rutaArchivo,
      nombreArchivo: archivo.nombreArchivo,
      mimeType: archivo.mimeType,
    };
    const res = await exportArchivoBack(archivoVo);
    return res;
  };

  const columns = useMemo(
    () => [
      { Header: "Fecha de Registro", accessor: "fechaCreacion", show: true },
      { Header: "Comentario", accessor: "comentario", show: true },
      {
        Header: "Documento",
        accessor: "documento",
        Cell: ({ row }) => {
          return (
            <button
              className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-2 mt-2"
              type="button"
              onClick={() => exportArchivo(row.original)}>
              {row.original.nombreArchivo}
            </button>
          );
        },
        show: true,
      },
    ],
    [rowSeleccionada, handdleCheckedConsentimiento]
  );

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  const autorizado =
    usuario.authorities.includes("OP_CONSENTIMIENTO_IMPRIMIR") || usuario.authorities.includes("OP_CONSENTIMIENTO_CARGAR");

  const changeNuevaImpresion = (e) => {
    const { name, value } = e.target;
    setDatosNuevaImpresion({
      ...datosNuevaImpresion,
      [name]: value,
    });
  };

  const changeCargaArchivo = (e) => {
    const { name, value } = e.target;
    setDatosCargaArchivo({
      ...datosCargaArchivo,
      [name]: value,
    });
  };

  const checkNuevaImpresion = async () => {
    let datos = await setDatosExpedienteMedico(historiaClinicaInicial.expedienteMedico.idExpedienteMedico, null);

    datos = datos[0];

    const paciente = datos.catPaciente.catPersona
      ? (datos.catPaciente.catPersona.nombres ? datos.catPaciente.catPersona.nombres : "") +
        " " +
        (datos.catPaciente.catPersona.apellidoPaterno ? datos.catPaciente.catPersona.apellidoPaterno : "") +
        " " +
        (datos.catPaciente.catPersona.apellidoMaterno ? datos.catPaciente.catPersona.apellidoMaterno : "")
      : "";

    setDatosNuevaImpresion({ ...datosNuevaImpresion, familiar: paciente, paciente: paciente });
    if (autorizado) {
      if (datos.length === 0) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Lo siento, no se encontró el expediente médico",
        });
      }
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nueva_impresion: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Lo siento, no cuenta con los permisos necesarios para esta acción",
      });
    }
  };
  const checkCargarDocumento = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_cargar_archivo: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Lo siento, no cuenta con los permisos necesarios para esta acción",
      });
    }
  };

  // const validarDatosImpresion = (datos) => {
  //   let errorNuevaImpresion = false;

  //   // if (!errorNuevaImpresion && (datos.medicamento === "" || datos.medicamento === undefined)) {
  //   //   errorNuevaImpresion = true;
  //   //   setUISweetAlert({
  //   //     ...UISweetAlert,
  //   //     error_dlg: true,
  //   //     dynamic_title: "Fallo",
  //   //     dynamic_description: "Por favor, ingrese el medicamento",
  //   //   });
  //   // }
  //   if (!errorNuevaImpresion && (datos.veces === "" || datos.veces === undefined)) {
  //     errorNuevaImpresion = true;
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       dynamic_title: "Fallo",
  //       dynamic_description: "Por favor, ingrese la cantidad de veces",
  //     });
  //   }
  //   if (!errorNuevaImpresion && (datos.periodo === "" || datos.periodo === undefined)) {
  //     errorNuevaImpresion = true;
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       dynamic_title: "Fallo",
  //       dynamic_description: "Por favor, ingrese el periodo",
  //     });
  //   }
  //   if (!errorNuevaImpresion && (datos.medicamentos === "" || datos.medicamentos === undefined)) {
  //     errorNuevaImpresion = true;
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       dynamic_title: "Fallo",
  //       dynamic_description: "Por favor, ingrese los medicamentos",
  //     });
  //   }
  //   if (!errorNuevaImpresion && (datos.historial === "" || datos.historial === undefined)) {
  //     errorNuevaImpresion = true;
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       dynamic_title: "Fallo",
  //       dynamic_description: "Por favor, ingrese el historial",
  //     });
  //   }
  //   if (!errorNuevaImpresion && (datos.enfermedades === "" || datos.enfermedades === undefined)) {
  //     errorNuevaImpresion = true;
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       dynamic_title: "Fallo",
  //       dynamic_description: "Por favor, ingrese las enfermedades",
  //     });
  //   }
  //   if (!errorNuevaImpresion && (datos.familiar === "" || datos.familiar === undefined)) {
  //     errorNuevaImpresion = true;
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       dynamic_title: "Fallo",
  //       dynamic_description: "Por favor, ingrese el paciente o familiar",
  //     });
  //   }

  //   return errorNuevaImpresion;
  // };

  const confirmNuevaImpresion = async () => {
    if (datosExpedienteMedico) {
      setLoading(true);
      const res = await exportConsentimientoClienteReport({
        idExpedienteMedico: 1,
        testigo1: datosNuevaImpresion.testigo1,
        testigo2: datosNuevaImpresion.testigo2,
        familiar: datosNuevaImpresion.familiar,
      });
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nueva_impresion: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nueva_impresion: false,
          dynamic_description: "Se imprimió correctamente el consentimiento",
        });
        setDatosNuevaImpresion(datosNuevaImpresionVacio);
      }
    }
  };

  const validarDatosArchivo = () => {
    let errorDatosArchivo = false;
    if (!errorDatosArchivo && datosCargaArchivo.selectedFile === null) {
      errorDatosArchivo = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, cargue un archivo de evidencia",
      });
    }
    if (!errorDatosArchivo && (datosCargaArchivo.comentario === null || datosCargaArchivo.comentario === "")) {
      errorDatosArchivo = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un comentario",
      });
    }
    return errorDatosArchivo;
  };

  const onFileUpload = async () => {
    const datosValidos = validarDatosArchivo();
    if (!datosValidos) {
      delete datosCargaArchivo.selectedFile;
      setLoading(true);
      const res = await uploadDocumentoFirmado({
        ...datosCargaArchivo,
        idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      });

      if (res.data && res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Ocurrió un error al cargar el archivo, por favor inténtalo más tarde.",
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_cargar_archivo: false,
          dynamic_description: "Se guardó correctamente el archivo",
        });
      }
      fetchData({ pageSize: 10, pageIndex: 0 });
      setDatosCargaArchivo(datosCargaArchivoVacio);
    }
  };

  const infoSweetAlert = { title: "Cargar Documento Firmado", confirmBtnText: "Guardar", cancelBtnText: "Cancelar" };

  const onCancel = () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_cargar_archivo: false,
    });
    setDatosCargaArchivo(datosCargaArchivoVacio);
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Consentimiento Informado</h3>
      <DrawerExpedientePaciente
        historiaClinicaInicial={historiaClinicaInicial}
        sexos={sexos}
        aseguradoras={aseguradoras}
        medicos={medicos}
        lugares={lugares}
        ocupaciones={ocupaciones}
        estadosCiviles={estadosCiviles}
        escolaridades={escolaridades}
      />
      <h4 className="flex h4 mt-5">Lista de Consentimientos Informados</h4>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                type="button"
                onClick={redirectHistoriaClinicaInicial}>
                Historia Clínica Inicial
              </button>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkNuevaImpresion()}>
                      Nueva Impresión
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkCargarDocumento()}>
                      Cargar Documento
                    </button>
                  ) : null}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={consentimientosPaginados}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nueva_impresion ? (
        <SweetAlert
          title="Nueva Impresión"
          confirmBtnText="Imprimir"
          confirmBtnBsStyle="success"
          cancelBtnText="Atrás"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "70%",
          }}
          onConfirm={() => {
            confirmNuevaImpresion();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nueva_impresion: false,
            });
          }}>
          {/* {datosExpedienteMedico ? (
            <InformacionExpediente datosExpedienteMedico={datosExpedienteMedico} changeNuevaImpresion={changeNuevaImpresion} />
          ) : null} */}
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">Fecha</label>
                  <Col sm={8}>
                    <input disabled={true} type="text" className="form-control" name="fecha" autoComplete="off" value={today} />
                  </Col>
                  {/* <label className="col-sm-4 col-form-label">Médico</label>
                  <Col sm={8}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="medico"
                      autoComplete="off"
                      value={`${datosExpedienteMedico.catMedico.prefijo ? datosExpedienteMedico.catMedico.prefijo : ""} ${
                        datosExpedienteMedico.catMedico.catPersona.nombres
                      } ${datosExpedienteMedico.catMedico.catPersona.apellidoPaterno} ${
                        datosExpedienteMedico.catMedico.catPersona.apellidoMaterno
                      }`}
                      // value={datosExpedienteMedico.catMedico.nombre ? datosExpedienteMedico.catMedico.nombre : ""}
                    />
                  </Col> */}
                  <label className="col-sm-4 col-form-label">Medicamentos</label>
                  <Col sm={8}>
                    <textarea
                      disabled={true}
                      readOnly={true}
                      className="form-control mb-3"
                      name="medicamentos"
                      autoComplete="off"
                      value={datosExpedienteMedico.medicamentos ? datosExpedienteMedico.medicamentos : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Veces</label>
                  <Col sm={8}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="veces"
                      autoComplete="off"
                      value={datosExpedienteMedico.veces ? datosExpedienteMedico.veces : 0}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Cronicodegenerativas</label>
                  <Col sm={8}>
                    <textarea
                      disabled={true}
                      readOnly={true}
                      className="form-control mb-3"
                      name="alergias"
                      autoComplete="off"
                      value={
                        datosExpedienteMedico.antecedentesPersonalesPatologicos[0].cronicodegenerativos
                          ? datosExpedienteMedico.antecedentesPersonalesPatologicos[0].cronicodegenerativos
                          : ""
                      }
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Alergias</label>
                  <Col sm={8}>
                    <textarea
                      disabled={true}
                      readOnly={true}
                      className="form-control mb-3"
                      name="alergias"
                      autoComplete="off"
                      value={
                        datosExpedienteMedico.antecedentesPersonalesPatologicos[0].alergias
                          ? datosExpedienteMedico.antecedentesPersonalesPatologicos[0].alergias
                          : ""
                      }
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Enfermedades</label>
                  <Col sm={8}>
                    <textarea
                      disabled={true}
                      readOnly={true}
                      className="form-control mb-3"
                      name="enfermedades"
                      autoComplete="off"
                      value={datosExpedienteMedico.enfermedades ? datosExpedienteMedico.enfermedades : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Paciente o Familiar</label>

                  <Col sm={8}>
                    <input
                      type="text"
                      className="form-control"
                      name="familiar"
                      autoComplete="off"
                      onChange={changeNuevaImpresion}
                      value={datosNuevaImpresion.familiar ? datosNuevaImpresion.familiar : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Nombre Usuario</label>

                  <Col sm={8}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="usuario"
                      autoComplete="off"
                      value={datosExpedienteMedico.usuario ? datosExpedienteMedico.usuario : ""}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">Paciente</label>
                  <Col sm={8}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="paciente"
                      autoComplete="off"
                      value={datosNuevaImpresion.paciente ? datosNuevaImpresion.paciente : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Diagnóstico</label>
                  <Col sm={8}>
                    <textarea
                      disabled={true}
                      readOnly={true}
                      className="form-control mb-3"
                      name="diagnostico"
                      autoComplete="off"
                      value={datosExpedienteMedico.diagnostico ? datosExpedienteMedico.diagnostico : ""}
                    />
                  </Col>
                  {/* <label className="col-sm-4 col-form-label">Cédula</label>
                  <Col sm={8}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="diagnostico"
                      autoComplete="off"
                      value={
                        datosExpedienteMedico.catMedico.cedulaProfesional ? datosExpedienteMedico.catMedico.cedulaProfesional : ""
                      }
                    />
                  </Col> */}
                  <label className="col-sm-4 col-form-label">Periodo</label>
                  <Col sm={8}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="periodo"
                      autoComplete="off"
                      value={datosExpedienteMedico.periodo ? datosExpedienteMedico.periodo : 0}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Historial</label>
                  <Col sm={8}>
                    <textarea
                      disabled={true}
                      readOnly={true}
                      className="form-control mb-3"
                      name="historial"
                      autoComplete="off"
                      value={datosExpedienteMedico.historial ? datosExpedienteMedico.historial : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Testigo 1</label>
                  <Col sm={8}>
                    <input
                      type="text"
                      className="form-control"
                      name="testigo1"
                      autoComplete="off"
                      onChange={changeNuevaImpresion}
                      value={datosNuevaImpresion.testigo1 ? datosNuevaImpresion.testigo1 : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Testigo 2</label>
                  <Col sm={8}>
                    <input
                      type="text"
                      className="form-control"
                      name="testigo2"
                      autoComplete="off"
                      onChange={changeNuevaImpresion}
                      value={datosNuevaImpresion.testigo2 ? datosNuevaImpresion.testigo2 : ""}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_cargar_archivo ? (
        <CargaArchivoModal
          datosCargaArchivo={datosCargaArchivo}
          setDatosCargaArchivo={setDatosCargaArchivo}
          infoSweetAlert={infoSweetAlert}
          onFileUpload={onFileUpload}
          onCancel={onCancel}
          conComentario={true}
          onFileChange={(e) => onFileChange(e)}
          onChange={(e) => changeCargaArchivo(e)}
          filesAccepted="application/pdf"
        />
      ) : null}
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default ConsentimientoCliente;
