import React, {useState, useEffect, useCallback, useMemo, useRef, useContext} from "react";
import {useParams} from "react-router-dom";
import {Row, Col, Card, CardBody, Spinner} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import CentroMezclasContext from "../../context/CentroMezclas/CentroMezclasContext";

// import WindowedSelect from "react-windowed-select";

import PacientesContext from "../../context/Pacientes/PacientesContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import ValesContext from "../../context/Vales/ValesContext";
import ExpedienteMedicoContext from "../../context/ExpedienteMedico/ExpedienteMedicoContext";
import TiposAdmistracionContext from "../../context/TiposAdministracion/TiposAdministracionContext";
import ResultadosReaContext from "../../context/ResultadosRea/ResultadosReaContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";

import useWindowDimensions from "../../hooks/dimensionsHook";

const ReporteReacciones = () => {
    const {width} = useWindowDimensions();
    const {idVale} = useParams();

    const filtrosVacios = {
        fechaInicial: "",
        fechaFinal: "",
        idCatPaciente: "",
        idCatProducto: "",
    };

    const ctxVales = useContext(ValesContext);
    const {seleccionarVale, valeSeleccionado} = ctxVales;

    const [idPaciente, setIdPaciente] = useState(null);

    const centroMezclasContext = useContext(CentroMezclasContext);
    const {
        setFiltrosReportesReacciones,
        filtrosReporteReacciones,
        reportesReacciones,
        setDataReportesReacciones,
        uploadNuevaReaccion,
        eliminarReaccion,
    } = centroMezclasContext;

    const ctxPacientes = useContext(PacientesContext);
    const {listarPacientesActivos, pacientes} = ctxPacientes;

    //ACCESO AL ESTADO GLOBAL - MEDICOS
    const ctxMedicos = useContext(MedicosContext);
    const {medicos, listarMedicos} = ctxMedicos;

    const ctxExpedienteMedico = useContext(ExpedienteMedicoContext);
    const {setDatosExpedienteMedico} = ctxExpedienteMedico;

    const ctxTiposAdministracion = useContext(TiposAdmistracionContext);
    const {tiposAdministracion, listarTiposAdministracion} = ctxTiposAdministracion;

    const ctxResultadosRea = useContext(ResultadosReaContext);
    const {resultadosRea, listarResultadosRea} = ctxResultadosRea;

    const ctxProductos = useContext(CatProductosContext);
    const {catProductos, listarMedicamentos, MedicamentosVentaMostradorPaciente, MedicamentosCompraExterna} = ctxProductos;
    
    useEffect(() => {
        // if (usuarios.length <= 0)
        const fetchtComprasPagadas = async () => {
          const medicamentosCETemp = await MedicamentosCompraExterna(1);
          setMedicamentosCEGlobal(medicamentosCETemp);
    
          const medicamentosVMTemp = await MedicamentosVentaMostradorPaciente(0);    
          setMedicamentosVMGlobal(medicamentosVMTemp);
    
          listarPacientesActivos();
          listarMedicos();
          listarTiposAdministracion();
          listarResultadosRea();
          listarMedicamentos();
          fetchVale();
          setRowSeleccionada(null);
        }
        fetchtComprasPagadas();
        // fetchVale();
      }, []);

    useEffect(() => {
        setLoading(true);
        try {
            setLoading(false);
            fetchData({pageSize: 10, pageIndex: 0});
        } catch (error) {
            setLoading(false);
        }
    }, [idPaciente]);



    const fetchVale = async () => {
        const vale = await seleccionarVale(idVale);   
        setFiltrosReportesReacciones({...filtrosReporteReacciones, idCatPaciente: vale.catPacientes.idCatPaciente});
        setIdPaciente(vale.catPacientes.idCatPaciente);
        setFolioValeGlobal(vale.folioVale);

        const medicamentosCETemp = await MedicamentosCompraExterna(vale.catPacientes.idCatPaciente);
        if(medicamentosCETemp.length > 0){
            setExisteProductoCompraExterna(true);
        }else{
            setExisteProductoCompraExterna(false);
        }

        setListaMedicamentosCompraExterna(medicamentosCETemp);
        if(vale.catPacientes){
            const medicamentosVMTemp = await MedicamentosVentaMostradorPaciente(vale.catPacientes.idCatPaciente);
            
            const tempVales = medicamentosVMTemp.filter((med) =>{ 
                let idTemp = med.id
                let encontrado = true;
                
                vale.valeproductoservicios.map((filtro) =>{
                    if(filtro.catProductos){
                        if(filtro.catProductos.idCatProducto == idTemp){
                            encontrado = false;
                        }
                    }
                 })

                if(encontrado == true ){
                    return med;
                }              
            });
            
            if(tempVales.length > 0){
                setExisteProductoVentaMostrador(true);
            }else{
                setExisteProductoVentaMostrador(false);
            }

            setListaMedicamentosVentaMostrador(tempVales);
        
        }else{
            const medicamentosTemp = null;

            setListaMedicamentosVentaMostrador(medicamentosTemp);
        }

        return vale.catPacientes.idCatPaciente;
    };

    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;

    const todayFormat = yyyy + "-" + mm + "-" + dd;

    const datosReportePacienteVacio = {
        fechaRegistro: todayFormat,
        idCatPaciente: "",
        iniciales: "",
        idCatSexo: "",
        sexo: "",
        idCatMedico: "",
        tratamiento: "",
        edad: "",
        cronicoDegenerativos: "",
        diagnostico: "",
        ciclo: "",
    };

    const datosReporteMedicamentoVacio = {
        idCatProducto: null,
        idCatProductoVentaMostrador: null,
        idCatProductoCompraExterna: null,
        OpcionMedicamento : 1,
        concentracion: "",
        presentacion: "",
        concentracionAdministracion: "",
        idCatTipoAdministracion: "",
        principioActivo: "",
        idCatLaboratorio: "",
        laboratorio: "",
        idCatProductoDiluyente: null,
    };

    const datosReporteReaccionAdversaVacio = {
        sintomas: "",
        idCatResultadoRea: "",
        reporte: "",
        observaciones: "",
        duracion: "",
        medicamentoControlRea: "",
        premedicacion: "",
    };

    const [datosReportePaciente, setDatosReportePaciente] = useState(datosReportePacienteVacio);
    const [datosReporteMedicamento, setDatosReporteMedicamento] = useState(datosReporteMedicamentoVacio);
    const [datosReporteReaccionAdversa, setDatosReporteReaccionAdversa] = useState(datosReporteReaccionAdversaVacio);
    const [reporteSeleccionado, setReporteSeleccionado] = useState(null);
    const [rowSeleccionada, setRowSeleccionada] = useState(null);
    const [listaMedicamentosVentaMostrador, setListaMedicamentosVentaMostrador] = useState([]);
    const [listaMedicamentosCompraExterna, setListaMedicamentosCompraExterna] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [pacienteSeleccionado, setPacienteSeleccionado] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [medicamentoSeleccionado, setMedicamentoSeleccionado] = useState(null);
    const [medicamentos, setMedicamentos] = useState([]);
    const [opcionesSoluciones, setOpcionesSoluciones] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [isFirstSearch, setIsFirstSearch] = useState(true);
    const[folioValeGlobal,setFolioValeGlobal] = useState("")
    const[existeProductoVale,setExisteProductoVale] = useState(true)
    const[existeProductoCompraExterna,setExisteProductoCompraExterna] = useState(true)
    const[existeProductoVentaMostrador,setExisteProductoVentaMostrador] = useState(true)
    const [medicamentosCEGlobal, setMedicamentosCEGlobal] = useState([]);
    const [medicamentosVMGlobal, setMedicamentosVMGlobal] = useState([]);

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_modificar_reporte_paciente: false,
        confirm_modificar_reporte_medicamento: false,
        confirm_modificar_reporte_reaccion: false,
        confirm_nuevo_reporte_paciente: false,
        confirm_nuevo_reporte_medicamento: false,
        confirm_nuevo_reporte_reaccion: false,
        confirm_eliminar_reporte: false,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
    });

    const [filtrosLocales, setFiltrosLocales] = useState({
        fechaFinal: "",
        fechaInicial: "",
        idCatPaciente: null,
        idCatProducto: null,
        idExpedienteMedico: null,
        idReaccionAdversa: null,    
    });

    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const [pageCount, setPageCount] = useState(0);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [listaMedicamentosSeleccionado, setListaMedicamentosSeleccionado] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState([
        {
            idReaccionAdversa: "",
            fechaRegistro: "",
            prescripcionMedica: "",
            responsableRevision: "",
            responsablePreparacion: "",
            idCatProducto: "",
            medicamento: "",
            ordenPreparacion: "",
            calculos: "",
            horaEntrega: "",
        },
    ]);

    const fetchIdRef = useRef(0);

    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);
            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);
            }
            if(filtrosReporteReacciones.idCatPaciente>0){
                filtrosLocales.idCatPaciente = filtrosReporteReacciones.idCatPaciente
            }
            const response = await setDataReportesReacciones(page, limit,{filtrosLocales});
            const count = response.count;
            setData(response.bitacoras);
            setRowSeleccionada(null);
            if (count % limit > 0) {
                setPageCount(Math.ceil(count / limit));
            } else {
                setPageCount(count / limit);
            }
            setLoadingData(false);
        } catch (e) {
            console.log("Error while fetching", e);
        }
    };

    const fetchData = async ({pageSize, pageIndex}) => {
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: false,
            });
        }
    };

    const handdleCheckedReporte = useCallback(
        (row) => {
            if (rowSeleccionada === null || rowSeleccionada !== row.id) {
                setRowSeleccionada(row.id);

                const fetchExpediente = async (idCatPaciente) => {
                    let opcionTemp = 1;
                    let laboratorioTemp = "";
                    let principioActivoTemp = "";
                    let presentacionTemp = "";
                    setExisteProductoVale(false)
                   
                    if(row.original.idCatProducto != null){
                        let error = false;

                        let lista = getProductosVale();
                        if (lista && Array.isArray(lista) && lista.length > 0) {
                            error = false;
                        }else{
                            error = true;
                        }
                        
                        if(error == true){
                            setRowSeleccionada(null);
                            setReporteSeleccionado(null);
                            return;
                        }
                            
                        let listaMedicamentos = lista[0];

                        let medTemp = listaMedicamentos.filter((medicamento) => medicamento.id == row.original.idCatProducto);
                        let med = medTemp[0];
                        if(med){
                            opcionTemp = 1;
                            setExisteProductoVale(true)
                            laboratorioTemp = med.laboratorio? med.laboratorio : "";
                            principioActivoTemp = med.principioActivo? med.principioActivo : "";
                            presentacionTemp = med.presentacion? med.presentacion : "";
                        }else{
                            setRowSeleccionada(null);
                            setReporteSeleccionado(null);
                        }
                    }

                    if(row.original.idCatProductoVentaMostrador != null){
                        let medTemp = listaMedicamentosVentaMostrador.filter((medicamento) => medicamento.id == row.original.idCatProductoVentaMostrador);
                        let med = medTemp[0];
                        if(med){
                            opcionTemp = 2;
                            laboratorioTemp = med.laboratorio? med.laboratorio : "";
                            principioActivoTemp = med.principioActivo? med.principioActivo : "";
                            presentacionTemp = med.presentacion? med.presentacion : "";
                        }else{
                            setRowSeleccionada(null);
                            setReporteSeleccionado(null);
                        }

                    }

                    if(row.original.idProductoCompraExterna != null){
                        let medCETemp = listaMedicamentosCompraExterna.filter((medicamento) => medicamento.idProductoCompraExterna == row.original.idProductoCompraExterna);
                        let medCE = medCETemp[0];
                        if(medCE){
                            opcionTemp = 3;
                            laboratorioTemp = medCE.laboratorio? medCE.laboratorio : "";
                            principioActivoTemp = medCE.principioActivo? medCE.principioActivo : "";
                            presentacionTemp = medCE.presentacion? medCE.presentacion : "";
                        }else{
                            setRowSeleccionada(null);
                            setReporteSeleccionado(null);
                        }
                    } 

                    setLoading(true);
                    if (row.original.expedienteMedico && row.original.expedienteMedico.idExpedienteMedico) {
                        const res = await setDatosExpedienteMedico(row.original.expedienteMedico.idExpedienteMedico, null);
                        let expedienteMedico = res[0];
                        setReporteSeleccionado({
                            ...row.original, 
                            diagnostico: expedienteMedico.diagnostico ? expedienteMedico.diagnostico : "",
                            idCatProducto: row.original.idCatProducto? row.original.idCatProducto : null ,
                            idCatProductoVentaMostrador: row.original.idCatProductoVentaMostrador? row.original.idCatProductoVentaMostrador : null,
                            idCatProductoCompraExterna: row.original.idProductoCompraExterna? row.original.idProductoCompraExterna : null,
                            sexo: row.original.sexo,
                            opcion : opcionTemp,
                            edad: row.original.fechaNacimiento ? calcEdad(row.original.fechaNacimiento) : "",
                            cronicoDegenerativos: row.original.cronicoDegenerativos,
                            idCatPaciente: row.original.idCatPaciente
                                ? row.original.idCatPaciente
                                : expedienteMedico.catPaciente && expedienteMedico.catPaciente.idCatPaciente
                                    ? expedienteMedico.catPaciente.idCatPaciente
                                    : "",
                            laboratorio: laboratorioTemp,
                            principioActivo: principioActivoTemp,
                            presentacion: presentacionTemp,
                        });
                        return res[0];
                    } else {
                        const res = await setDatosExpedienteMedico(null, idCatPaciente);
                        let expedienteMedico = res[0];
                        let opcionTemp = 1;

                        if(row.original.idCatProductoVentaMostrador != null){
                            opcionTemp = 2;
                        }

                        if(row.original.idProductoCompraExterna != null){
                            opcionTemp = 3;
                        }

                        setReporteSeleccionado({
                            ...row.original,
                            diagnostico: expedienteMedico.diagnostico ? expedienteMedico.diagnostico : "",
                            idCatProducto: row.original.idCatProducto? null : row.original.idCatProducto,
                            idCatProductoVentaMostrador: row.original.idCatProductoVentaMostrador? row.original.idCatProductoVentaMostrador : null,
                            idCatProductoCompraExterna: row.original.idProductoCompraExterna? row.original.idProductoCompraExterna : null,
                            sexo: row.original.sexo,
                            opcion : opcionTemp,
                            edad: row.original.fechaNacimiento ? calcEdad(row.original.fechaNacimiento) : "",
                            cronicoDegenerativos: row.original.cronicoDegenerativos,
                            idCatPaciente: row.original.idCatPaciente
                                ? row.original.idCatPaciente
                                : expedienteMedico.catPaciente && expedienteMedico.catPaciente.idCatPaciente
                                    ? expedienteMedico.catPaciente.idCatPaciente
                                    : "",
                            laboratorio: row.original.catProducto.catLaboratoriosVo.laboratorio,
                            principioActivo: row.original.catProducto.principioActivo,
                            presentacion: row.original.catProducto.presentacion,
                        });
                        return res[0];
                    }
                };
                try {
                    if(row.original.idCatPaciente > 0){
                        fetchExpediente(row.original.idCatPaciente);
                        setLoading(false);
                    }

                } catch (error) {
                    setLoading(false);
                }
            } else {
                setRowSeleccionada(null);
                setReporteSeleccionado(null);
            }
        },
        [rowSeleccionada, pacientes]
    );

    // Se definen las columnas para la tabla de react-tabe
    // Se debe usar useMemo para optimización y porque lo pide la librería

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "idReaccionAdversa",
                Cell: ({row}) => {
                    return (
                        <input
                            // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
                            checked={rowSeleccionada === row.id ? true : false}
                            type="checkbox"
                            onChange={() => handdleCheckedReporte(row)}
                        />
                    );
                },

                disableFilters: true,
                disableSortBy: true,
            },
            {Header: "Fecha de Registro", accessor: "fechaRegistro"},
            {Header: "Paciente", accessor: "nombrePaciente"},
            {Header: "idCatPaciente", accessor: "catPaciente.idCatPaciente"},
            {Header: "Medicamento", accessor: "nombrecomercialMostrar"},
            {Header: "Medicamento", accessor: "catProducto.idCatProducto"},
            // { Header: "Síntomas", accessor: "sintomas" },
            {
                Header: "Síntomas",
                accessor: "sintomas",
                Cell: ({row}) => {
                    return <textarea disabled={true} value={row.original.sintomas}/>;
                },

                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: "Observaciones",
                accessor: "observaciones",
                Cell: ({row}) => {
                    return <textarea disabled={true} value={row.original.observaciones}/>;
                },

                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [rowSeleccionada, handdleCheckedReporte]
    );

    const medicosChangeId = medicos.map(({idCatMedico, catPersona, ...medico}) => ({
        ...medico,
        nombre: `${catPersona && catPersona.nombres ? catPersona.nombres : ""} ${
            catPersona && catPersona.apellidoPaterno ? catPersona.apellidoPaterno : ""
        } ${catPersona && catPersona.apellidoMaterno ? catPersona.apellidoMaterno : ""}`,
        id: idCatMedico,
    }));

    const pacientesChangeId = pacientes.map(({idCatPaciente, nombres, apellidoPaterno, apellidoMaterno, ...paciente}) => ({
        ...paciente,
        id: idCatPaciente,
        nombre: nombres + " " + apellidoPaterno + " " + apellidoMaterno,
    }));

    const medicamentosActivos = catProductos.map(({idCatProducto, nombreComercial, ...medicamento}) => ({
        ...medicamento,
        id: idCatProducto,
        nombre: nombreComercial,
    }));

    const getProductosVale = () => {
        if (valeSeleccionado !== null) {
            let productosServiciosVale = valeSeleccionado.valeproductoservicios.map((producto) => producto.catProductos);
            productosServiciosVale = productosServiciosVale.filter((producto) => producto !== null);
            let productosFiltradosMed = productosServiciosVale.filter((producto) => producto.catTipoproducto.idCatTipoProducto == 2);
            let productosFiltradosSol = productosServiciosVale.filter((producto) => producto.catTipoproducto.idCatTipoProducto == 4);
            productosFiltradosMed = productosFiltradosMed.map(({idCatProducto, nombreComercial, ...medicamento}) => ({
                ...medicamento,
                id: idCatProducto,
                nombre: nombreComercial,
            }));
            productosFiltradosSol = productosFiltradosSol.map(({idCatProducto, nombreComercial, ...solucion}) => ({
                ...solucion,
                id: idCatProducto,
                nombre: nombreComercial,
            }));
            return [productosFiltradosMed, productosFiltradosSol];
        }
    };

    const onChange = (e) => {
        if (e.target.name === "fechaInicial") {
          setFiltrosLocales({
            ...filtrosLocales,
            [e.target.name]: e.target.value,
            fechaFinal: e.target.value,
          });
        } else {
          setFiltrosLocales({
            ...filtrosLocales,
            [e.target.name]: e.target.value,
          });
        }
    
        if (e.target.name === "opcion") {
    
          if(e.target.value == 1){
            setListaMedicamentosSeleccionado(medicamentosActivos);
          }
    
          if(e.target.value == 2){
            setListaMedicamentosSeleccionado(medicamentosVMGlobal);
          }
    
          if(e.target.value == 3){
            setListaMedicamentosSeleccionado(medicamentosCEGlobal);
          }
    
          setFiltrosLocales({
            ...filtrosLocales,
            [e.target.name]: e.target.value,
            tipoListaMedicamento : e.target.value,
          });
        } 
      };

    const inputCols = [
        {
            col: [
                {
                    colType: "input",
                    label: "Fecha Inicial",
                    type: "date",
                    name: "fechaInicial",
                    disabled: false,
                    value: filtrosLocales.fechaInicial,
                    onChange: onChange,
                },
                {
                    colType: "select",
                    label: "Paciente",
                    name: "idCatPaciente",
                    disabled: true,
                    value: filtrosReporteReacciones.idCatPaciente,
                    opciones: pacientesChangeId,
                    onChange: onChange,
                },
            ],
        },
        {
            col: [
                {
                  colType: "input",
                  label: "Fecha Final",
                  type: "date",
                  name: "fechaFinal",
                  disabled: !filtrosLocales.fechaInicial ? true : false,
                  min: filtrosLocales.fechaInicial,
                  value: filtrosLocales.fechaFinal,
                  onChange: onChange,
                },
                {
                  colType: "radio",
                  label: "",
                  name: "listaSeleccionada",
                  value: filtrosLocales.listaSeleccionada,
                  onChange: onChange,
                },  
                filtrosLocales.opcion == 3?
                {
                  colType: "selectExterno",
                  label: "Medicamento",
                  name: "idCatProducto",
                  value: filtrosLocales.idCatProducto,
                  opciones: listaMedicamentosSeleccionado,
                  onChange: onChange,
                }:
                {
                  colType: "select",
                  label: "Medicamento",
                  name: "idCatProducto",
                  value: filtrosLocales.idCatProducto,
                  opciones: listaMedicamentosSeleccionado,
                  onChange: onChange,
                }
                ,
              ],
        },
    ];
    //ACCESO AL ESTADO GLOBAL - AUTENCIACION
    const ctxAuth = useContext(AuthContext);
    const {usuario} = ctxAuth;

    // const autorizado = usuario.authorities.includes("ROLE_CENTRO_MEZCLAS");

    const autorizado =
        usuario.authorities.includes("OP_REPORTE_REACCIONES_CREAR") ||
        usuario.authorities.includes("OP_REPORTE_REACCIONES_MODIFICAR") ||
        usuario.authorities.includes("OP_REPORTE_REACCIONES_ELIMINAR");

    const buscarPorFiltros = async (e) => {
        e.preventDefault();
        setFiltrosReportesReacciones(filtrosReporteReacciones);
        fetchData({pageSize: 10, pageIndex: 0});
        setIsFirstSearch(false);
    };

    const limpiarFiltros = () => {
        setFiltrosLocales({
            ...filtrosLocales,
            fechaInicial:  "",
            fechaFinal : "",
            idCatProducto : null,
            idCatMedico : null,
          });
      
          if(filtrosLocales.tipoListaMedicamento == 1){
            setListaMedicamentosSeleccionado(medicamentosActivos);
          }
      
          if(filtrosLocales.tipoListaMedicamento == 2){
            setListaMedicamentosSeleccionado(medicamentosVMGlobal);
          }
      
          if(filtrosLocales.tipoListaMedicamento  == 3){
            setListaMedicamentosSeleccionado(medicamentosCEGlobal);
          }
    };

    const calcEdad = (fechaNacimientoPaciente) => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const fechaNacimiento = fechaNacimientoPaciente ? fechaNacimientoPaciente.split("-") : null;
        const añoFechaNacimiento = fechaNacimiento ? fechaNacimiento[0] : null;
        return currentYear - añoFechaNacimiento;
    };

    const changeNuevoReportePaciente = async (e) => {
        const {name, value} = e.target;
        if (name === "idCatPaciente") {
            // let edad;
            // const res = await setDatosExpedienteMedico(value);
            // const expedienteMedico = res[0];
            // let paciente = expedienteMedico.catPaciente;
            // let nombrePaciente = await pacientesChangeId.filter((paciente) => paciente.id == value);
            // nombrePaciente = nombrePaciente[0];
            // // if (paciente.catPersona.fechaNacimiento) {
            // //   edad = calcEdad(paciente.catPersona.fechaNacimiento);
            // // }
            // const firstLetters = nombrePaciente
            //   ? nombrePaciente.nombre
            //       .split(" ")
            //       .map((word) => word[0])
            //       .join("")
            //   : "";
            // setPacienteSeleccionado(paciente);
            // if (res.length === 0) {
            //   setUISweetAlert({
            //     ...UISweetAlert,
            //     error_dlg: true,
            //     confirm_nuevo_reporte_reaccion: false,
            //     dynamic_title: "No se encontró un expediente médico del paciente.",
            //     dynamic_description: "",
            //   });
            //   return;
            // }
            // const antecedentesPersonalesPatologicos = expedienteMedico.antecedentesPersonalesPatologicos;
            // let enfermedades = antecedentesPersonalesPatologicos ? antecedentesPersonalesPatologicos.map((antecedente) => antecedente.cronicodegenerativos) : [];
            // enfermedades = enfermedades.join(", ");
            // setDatosReportePaciente({
            //   ...datosReportePaciente,
            //   [name]: value,
            //   iniciales: firstLetters,
            //   sexo: expedienteMedico.catPaciente.sexo.sexo ? expedienteMedico.catPaciente.sexo.sexo : "",
            //   idCatSexo: expedienteMedico.catPaciente.sexo.idCatSexo ? expedienteMedico.catPaciente.sexo.idCatSexo : "",
            //   edad: paciente.catPersona.fechaNacimiento ? calcEdad(paciente.catPersona.fechaNacimiento) : "",
            //   idExpedienteMedico: expedienteMedico.idExpedienteMedico,
            //   diagnostico: expedienteMedico.diagnostico,
            //   cronicoDegenerativos: enfermedades,
            //   idCatMedico: expedienteMedico.catMedico.idCatMedico,
            // });
        } else {
            setDatosReportePaciente({
                ...datosReportePaciente,
                [name]: value,
            });
        }
    };

    const changeNuevoReporteMedicamento = (e) => {
        const {name, value, checked} = e.target;

        if(e.target.name=="opcion"){
            setMedicamentoSeleccionado(null);
            setDatosReporteMedicamento({
                ...datosReporteMedicamento,
                [name]: value,
                idCatProducto: null,
                idCatProductoVentaMostrador: null,
                idCatProductoCompraExterna: null,
                presentacion:null,
                concentracionAdministracion: null,
                idCatLaboratorio: null,
                laboratorio: null,
            });
   
        }else{
            if (name === "idCatProducto") {
                let med = medicamentos.filter((medicamento) => medicamento.id == value);
                med = med[0];
    
                setMedicamentoSeleccionado(med);
                setDatosReporteMedicamento({
                    ...datosReporteMedicamento,
                    [name]: value,
                    principioActivo: med.principioActivo,
                    presentacion: med.presentacion,
                    concentracionAdministracion: med.concentracion,
                    idCatLaboratorio: med.catLaboratorios.idCatLaboratorio,
                    laboratorio: med.catLaboratorios.laboratorio,
                });
            } else if(name === "idCatProductoVentaMostrador"){
                let med = medicamentosActivos.filter((medicamento) => medicamento.id == value);
                med = med[0];
    
                setMedicamentoSeleccionado(med);
                setDatosReporteMedicamento({
                    ...datosReporteMedicamento,
                    [name]: value,
                    principioActivo: med.principioActivo,
                    presentacion: med.presentacion,
                    concentracionAdministracion: med.concentracion,
                    idCatLaboratorio: med.catLaboratorios.idCatLaboratorio,
                    laboratorio: med.catLaboratorios.laboratorio,
                });
            } else if(name === "idCatProductoCompraExterna"){
                let med = listaMedicamentosCompraExterna.filter((medicamento) => medicamento.idProductoCompraExterna == value);
                med = med[0];
    
                setMedicamentoSeleccionado(med);
                setDatosReporteMedicamento({
                    ...datosReporteMedicamento,
                    [name]: value,
                    principioActivo: med.principioActivo? med.principioActivo : "",
                    presentacion: med.presentacion? med.presentacion : "",
                    concentracionAdministracion: med.concentracion? med.concentracion : "",
                    idCatLaboratorio: med.catLaboratorio? med.catLaboratorio.idCatLaboratorio : null,
                    laboratorio: med.catLaboratorio? med.catLaboratorio.laboratorio : null,
                });
            }
            else {
                setDatosReporteMedicamento({
                    ...datosReporteMedicamento,
                    [name]: value,
                });
            }
        }

    };

    const changeNuevoReporteReaccion = (e) => {
        const {name, value} = e.target;
        setDatosReporteReaccionAdversa({
            ...datosReporteReaccionAdversa,
            [name]: value,
        });
    };

    const changeReporteSeleccionado = async (e) => {
        const {name, value, checked} = e.target;

        if (name === "idCatPaciente") {
            let edad;
            const res = await setDatosExpedienteMedico(null, value);
            let paciente = pacientesChangeId.filter((paciente) => paciente.id == value);
            paciente = paciente[0];
            if (paciente.fechaNacimiento) {
                edad = calcEdad(paciente.fechaNacimiento);
            }
            const firstLetters = paciente.nombre
            .split(" ")
            .map((word) => word[0])
            .join("");
            setPacienteSeleccionado(paciente);
            if (res.length === 0) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_nuevo_reporte_reaccion: false,
                    dynamic_title: "No se encontró un expediente médico del paciente.",
                    dynamic_description: "",
                });
                setReporteSeleccionado({
                    ...reporteSeleccionado,
                    [name]: value,
                    iniciales: firstLetters,
                    sexo: paciente.sexo.sexo,
                    idCatSexo: paciente.sexo.idCatSexo,
                    edad: edad ? edad : "",
                    idExpedienteMedico: null,
                    diagnostico: null,
                    cronicoDegenerativos: null,
                });
                return;
            } else {
                setReporteSeleccionado({
                    ...datosReportePaciente,
                    [name]: value,
                });
            }
        }

        if(e.target.name=="porNotaVenta"){
            setMedicamentoSeleccionado(null);
            setReporteSeleccionado({
                ...datosReporteMedicamento,
                [name]: checked,
                idCatProducto : null,
                idCatProductoVenta : null,
                idCatProductoVenta : null,
                principioActivo: null,
                presentacion: null,
                idCatLaboratorio: null,
                laboratorio: null,
            });
   
        }else{
            if (name === "idCatProducto") {
                let med = medicamentos.filter((medicamento) => medicamento.id == value);
                med = med[0];
                setMedicamentoSeleccionado(med);
                setReporteSeleccionado({
                    ...reporteSeleccionado,
                    [name]: value,
                    principioActivo: med.principioActivo,
                    presentacion: med.concentracion,
                    idCatLaboratorio: med.catLaboratorios.idCatLaboratorio,
                    laboratorio: med.catLaboratorios.laboratorio,
                });
            } else if(name === "idCatProductoVenta"){
                let med = medicamentos.filter((medicamento) => medicamento.id == value);
                med = med[0];
                setMedicamentoSeleccionado(med);
                setReporteSeleccionado({
                    ...reporteSeleccionado,
                    [name]: value,
                    principioActivo: med.principioActivo,
                    presentacion: med.concentracion,
                    idCatLaboratorio: med.catLaboratorios.idCatLaboratorio,
                    laboratorio: med.catLaboratorios.laboratorio,
                });
            }
            else {
                setReporteSeleccionado({
                    ...reporteSeleccionado,
                    [name]: value,
                });
            }
        }
    };

    const confirmNuevoReporte = async () => {
        if(folioValeGlobal.length > 1){
            const productos = getProductosVale();
            setMedicamentos(productos[0]);
            setOpcionesSoluciones(productos[1]);

            const nombreMedico = valeSeleccionado.catMedicos.catPersona
                ? (valeSeleccionado.catMedicos.catPersona.nombres ? valeSeleccionado.catMedicos.catPersona.nombres : "") +
                " " +
                (valeSeleccionado.catMedicos.catPersona.apellidoPaterno ? valeSeleccionado.catMedicos.catPersona.apellidoPaterno : "") +
                " " +
                (valeSeleccionado.catMedicos.catPersona.apellidoMaterno ? valeSeleccionado.catMedicos.catPersona.apellidoMaterno : "")
                : "";
            const res = await setDatosExpedienteMedico(null, valeSeleccionado.catPacientes.idCatPaciente);
            const expedienteMedico = res[0];
            let paciente = valeSeleccionado.catPacientes;
            const nombrePaciente = valeSeleccionado.catPacientes.catPersona
                ? (valeSeleccionado.catPacientes.catPersona.nombres ? valeSeleccionado.catPacientes.catPersona.nombres : "") +
                " " +
                (valeSeleccionado.catPacientes.catPersona.apellidoPaterno
                    ? valeSeleccionado.catPacientes.catPersona.apellidoPaterno
                    : "") +
                " " +
                (valeSeleccionado.catPacientes.catPersona.apellidoMaterno ? valeSeleccionado.catPacientes.catPersona.apellidoMaterno : "")
                : "";

            const firstLetters = nombrePaciente
            .split(" ")
            .map((word) => word[0])
            .join("");

            setPacienteSeleccionado(paciente);
            if (res.length === 0) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_nuevo_reporte_reaccion: false,
                    dynamic_title: "No se encontró un expediente médico del paciente.",
                    dynamic_description: "",
                });
                return;
            }
            const antecedentesPersonalesPatologicos =
                expedienteMedico && expedienteMedico.antecedentesPersonalesPatologicos
                    ? expedienteMedico.antecedentesPersonalesPatologicos
                    : null;
            let enfermedades = antecedentesPersonalesPatologicos
                ? antecedentesPersonalesPatologicos.map((antecedente) => antecedente.cronicodegenerativos)
                : [];
            enfermedades = enfermedades.join(", ");

            setDatosReportePaciente({
                ...datosReportePaciente,
                iniciales: firstLetters,
                sexo: expedienteMedico.catPaciente.sexo.sexo ? expedienteMedico.catPaciente.sexo.sexo : "",
                idCatSexo: expedienteMedico.catPaciente.sexo.idCatSexo ? expedienteMedico.catPaciente.sexo.idCatSexo : "",
                edad: paciente.catPersona.fechaNacimiento ? calcEdad(paciente.catPersona.fechaNacimiento) : "",
                idExpedienteMedico: expedienteMedico.idExpedienteMedico,
                diagnostico: expedienteMedico.diagnostico ? expedienteMedico.diagnostico : "",
                cronicoDegenerativos: enfermedades,
                idCatMedico: valeSeleccionado.catMedicos.idCatMedico,
                medico: nombreMedico,
                idCatPaciente:
                    valeSeleccionado.catPacientes && valeSeleccionado.catPacientes.idCatPaciente
                        ? valeSeleccionado.catPacientes.idCatPaciente
                        : "",
            });
            // setDatosReportePaciente({
            //   ...datosReportePaciente,
            //   idCatMedico: valeSeleccionado.catMedicos.idCatMedico,
            //   medico: nombreMedico,
            //   idCatPaciente: valeSeleccionado.catPacientes && valeSeleccionado.catPacientes.idCatPaciente ? valeSeleccionado.catPacientes.idCatPaciente : "",
            // });
            if (autorizado) {
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_nuevo_reporte_paciente: true,
                });
            }
        }
    };

    const validarDatosReaccion = (reporte,opcion) => {
        let errorReporte = false;
        if(opcion == 1 || opcion == 3){
            if (!errorReporte && (reporte.idCatPaciente === "" || reporte.idCatPaciente === null)) {
                errorReporte = true;
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, ingrese un paciente",
                });
            }
            if (!errorReporte && (reporte.tratamiento === "" || reporte.tratamiento === null)) {
                errorReporte = true;
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, ingrese el tratamiento",
                });
            }

            if (!errorReporte && (reporte.cronicoDegenerativos === "" || reporte.cronicoDegenerativos === null)) {
                errorReporte = true;
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, ingrese enfermedades Crónico Degenerativas",
                });
            }

            if (!errorReporte && (reporte.ciclo === "" || reporte.ciclo === null)) {
                errorReporte = true;
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, ingrese el ciclo",
                });
            }
        }

        if(opcion == 2 || opcion == 3){
            if ((!errorReporte && (reporte.idCatProducto === "" || reporte.idCatProducto === null)) && (reporte.idCatProductoVentaMostrador === "" || reporte.idCatProductoVentaMostrador === null)
            && (reporte.idCatProductoCompraExterna === "" || reporte.idCatProductoCompraExterna === null)) {
                errorReporte = true;
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, ingrese el medicamento",
                });
            }

            if (!errorReporte && (reporte.concentracionAdministracion === "" || reporte.concentracionAdministracion === null)) {
                errorReporte = true;
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, ingrese la Concentración Administrada",
                });
            }

            if (!errorReporte && (reporte.idCatTipoAdministracion === "" || reporte.idCatTipoAdministracion === null)) {
                errorReporte = true;
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, ingrese la vía de administración",
                });
            }
        }

        if (!errorReporte && (reporte.sintomas === "" || reporte.sintomas === null)) {
            errorReporte = true;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, ingrese los síntomas",
            });
        }
        if (!errorReporte && (reporte.idCatResultadoRea === "" || reporte.idCatResultadoRea === null)) {
            errorReporte = true;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, ingrese elija un resultado de REA",
            });
        }
        if (!errorReporte && (reporte.reporte === "" || reporte.reporte === null)) {
            errorReporte = true;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, ingrese el reporte",
            });
        }
        if (!errorReporte && (reporte.observaciones === "" || reporte.observaciones === null)) {
            errorReporte = true;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, ingrese las observaciones",
            });
        }
        if (!errorReporte && (reporte.duracion === "" || reporte.duracion === null)) {
            errorReporte = true;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, ingrese la duración",
            });
        }
        if (!errorReporte && (reporte.medicamentoControlRea === "" || reporte.medicamentoControlRea === null)) {
            errorReporte = true;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, ingrese el SRAM",
            });
        }

        if (!errorReporte && (reporte.premedicacion === "" || reporte.premedicacion === null)) {
            errorReporte = true;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, ingrese la premedicación",
            });
        }

        return errorReporte;
    };

    const checkNuevoReporte = async () => {
        let IdproductoTemp= 0;
        let IdproductoExternoTemp= 0;

        /*if(!datosReporteMedicamento.idCatProducto){

            if(datosReporteMedicamento.idCatProductoVentaMostrador > 0){
                IdproductoTemp = datosReporteMedicamento.idCatProductoVentaMostrador
            }else{
                if(datosReporteMedicamento.idCatProductoCompraExterna > 0){
                    IdproductoTemp = datosReporteMedicamento.idCatProductoCompraExterna
                }
            }                              
        }else{
            IdproductoTemp = datosReporteMedicamento.idCatProducto
        }*/

        const datosReporteCompleto = {
            idExpedienteMedico: datosReportePaciente.idExpedienteMedico,
            idVale: idVale,
            idCatProducto: datosReporteMedicamento.idCatProducto,
            idProductoCompraExterna: datosReporteMedicamento.idCatProductoCompraExterna,
            idCatProductoVentaMostrador: datosReporteMedicamento.idCatProductoVentaMostrador,
            idCatProductoDiluyente: datosReporteMedicamento.idCatProductoDiluyente,
            idCatTipoAdministracion: datosReporteMedicamento.idCatTipoAdministracion,
            idCatResultadoRea: datosReporteReaccionAdversa.idCatResultadoRea,
            fechaRegistro: datosReportePaciente.fechaRegistro,
            concentracionAdministracion: datosReporteMedicamento.concentracionAdministracion,
            tratamiento: datosReportePaciente.tratamiento,
            ciclo: datosReportePaciente.ciclo,
            sintomas: datosReporteReaccionAdversa.sintomas,
            duracion: datosReporteReaccionAdversa.duracion,
            medicamentoControlRea: datosReporteReaccionAdversa.medicamentoControlRea,
            reporte: datosReporteReaccionAdversa.reporte,
            premedicacion: datosReporteReaccionAdversa.premedicacion,
            observaciones: datosReporteReaccionAdversa.observaciones,
            cronicoDegenerativos: datosReporteReaccionAdversa.cronicoDegenerativos,
            ...datosReportePaciente,
            // ...datosReporteMedicamento,
            // ...datosReportePaciente,
            // ...datosReporteReaccionAdversa,
        };
        const reporteValido = validarDatosReaccion(datosReporteCompleto,3);
        if (!reporteValido) {
            setLoading(true);
            const res = await uploadNuevaReaccion(datosReporteCompleto);
            if (res.data.error) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_nuevo_reporte_reaccion: false,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else if (res) {
                setLoading(false);

                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_nuevo_reporte_reaccion: false,
                    dynamic_description: "Se guardó la nueva reacción correctamente",
                });
                fetchData({pageSize: 10, pageIndex: controlledPageIndex});
                setDatosReportePaciente(datosReportePacienteVacio);
                setDatosReporteMedicamento(datosReporteMedicamentoVacio);
                setDatosReporteReaccionAdversa(datosReporteReaccionAdversaVacio);
            }
        }
    };

    const confirmModificarReaccion = () => {
        const productos = getProductosVale();
        // if (!productos) return;
        setMedicamentos(productos[0]);
        setOpcionesSoluciones(productos[1]);
        if (autorizado && reporteSeleccionado) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_modificar_reporte_paciente: true,
            });
        } else {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione una reacción adversa",
            });
        }
    };

    const checkModificarReporte = async () => {
        const reporteValido = validarDatosReaccion(reporteSeleccionado,3);
        if (!reporteValido) {
            setLoading(true);
            delete reporteSeleccionado.catProductoVo;
            delete reporteSeleccionado.catResultadoRea;
            delete reporteSeleccionado.catTipoAdministracion;
            delete reporteSeleccionado.expedienteMedicoVo;
            delete reporteSeleccionado.fechaRegistro;
            const res = await uploadNuevaReaccion(reporteSeleccionado);
            if (res.data.error) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_modificar_reporte_reaccion: false,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
                fetchData({pageSize: 10, pageIndex: controlledPageIndex});
                setReporteSeleccionado(null);
                setRowSeleccionada(null);
            } else if (res) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_modificar_reporte_reaccion: false,
                    dynamic_description: "Se modificó la reacción correctamente",
                });
                fetchData({pageSize: 10, pageIndex: controlledPageIndex});
                setReporteSeleccionado(null);
                setRowSeleccionada(null);
            }
        }
    };

    const confirmEliminarReaccion = () => {
        if (autorizado && reporteSeleccionado) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_eliminar_reporte: true,
            });
        } else {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione una reacción adversa",
            });
        }
    };

    const checkEliminarReaccion = async () => {
        setLoading(true);
        const res = await eliminarReaccion({idReaccionAdversa: reporteSeleccionado.idReaccionAdversa});
        if (res.data.error) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_eliminar_reporte: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_eliminar_reporte: false,
                dynamic_description: "Se eliminó la reacción adversa correctamente",
            });
            fetchData({pageSize: 10, pageIndex: controlledPageIndex});
            setReporteSeleccionado(null);
            setRowSeleccionada(null);
        }
    };

    // useEffect(() => {
    //   fetchVale();
    // }, []);

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            {/*( {folioValeGlobal} )*/}
            <h3 className="flex h3">Reacciones Adversas</h3>

            <FiltroBusqueda
                toggleSearchBy={false}
                buscarPorFiltros={buscarPorFiltros}
                onChange={onChange}
                limpiarFiltros={limpiarFiltros}
                paramsInput={inputCols}
            />
            <h4 className="flex h4 mt-5">Lista de Reacciones Adversas</h4>
            <Row>
                <Col>
                    <Card className="mt-4">
                        <CardBody>
                            <Row>
                                <Col>
                                    {autorizado ? (
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            disabled = {folioValeGlobal.length > 1? false : true}
                                            style={{
                                                marginRight: "2.5rem",
                                                marginTop: "1rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => confirmNuevoReporte()}>
                                            Nueva Reacción
                                        </button>
                                    ) : null}
                                    {autorizado ? (
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            disabled = {folioValeGlobal.length > 1? false : true}
                                            style={{
                                                marginRight: "2.5rem",
                                                marginTop: "1rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => confirmModificarReaccion()}>
                                            Modificar Reacción
                                        </button>
                                    ) : null}
                                    {autorizado ? (
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            disabled = {folioValeGlobal.length > 1? false : true}
                                            style={{
                                                marginRight: "2.5rem",
                                                marginTop: "1rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => confirmEliminarReaccion()}>
                                            Eliminar Reacción
                                        </button>
                                    ) : null}
                                </Col>
                            </Row>
                            {folioValeGlobal.length > 1?
                            <TableComponent
                                pageCount={pageCount}
                                fetchData={fetchData}
                                disabled = {folioValeGlobal.length > 1? false : true}
                                columns={columns}
                                loading={loadingData}
                                data={reportesReacciones}
                                indexOfPage={controlledPageIndex}
                            />:null}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {UISweetAlert.confirm_nuevo_reporte_paciente ? (
                <SweetAlert
                    title="Nueva Reacción Adversa"
                    confirmBtnText="Siguiente"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    showCancel
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => {
                        if(validarDatosReaccion(datosReportePaciente,1)==false){
                            setUISweetAlert({
                                ...UISweetAlert,
                                confirm_nuevo_reporte_paciente: false,
                                confirm_nuevo_reporte_medicamento: true,
                            });
                        }
                    }}
                    onCancel={() => {
                        setDatosReportePaciente(datosReportePacienteVacio);
                        setDatosReporteMedicamento(datosReporteMedicamentoVacio);
                        setDatosReporteReaccionAdversa(datosReporteReaccionAdversaVacio);
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_nuevo_reporte_paciente: false,
                        });
                    }}>
                    Por favor, llene todos los datos del paciente
                    <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                            <div>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-purple">Progreso</span>
                            </div>
                            <div className="text-right">
                                <span className="text-xs font-semibold inline-block text-purple">1/3</span>
                            </div>
                        </div>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-100">
                            <div
                                style={{width: "30%"}}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple"></div>
                        </div>
                    </div>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-5 col-form-label">Fecha de Registro</label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="fechaRegistro"
                                            autoComplete="off"
                                            value={datosReportePaciente.fechaRegistro}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Paciente</label>
                                    <Col sm={7} className="pb-3">
                                        <select
                                            className="form-control"
                                            disabled={true}
                                            name="idCatPaciente"
                                            onChange={changeNuevoReportePaciente}
                                            value={datosReportePaciente.idCatPaciente ? datosReportePaciente.idCatPaciente : ""}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>

                                            {pacientesChangeId.map((paciente) => {
                                                return <option value={paciente.id}>{paciente.nombre}</option>;
                                            })}
                                        </select>
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Iniciales</label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="iniciales"
                                            autoComplete="off"
                                            value={datosReportePaciente.iniciales ? datosReportePaciente.iniciales : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Sexo</label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="sexo"
                                            autoComplete="off"
                                            value={datosReportePaciente.sexo ? datosReportePaciente.sexo : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Médico</label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="medico"
                                            autoComplete="off"
                                            value={datosReportePaciente.medico ? datosReportePaciente.medico : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">
                                        Tratamiento<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <textarea
                                            className="form-control mb-2"
                                            name="tratamiento"
                                            autoComplete="off"
                                            onChange={changeNuevoReportePaciente}
                                            value={datosReportePaciente.tratamiento ? datosReportePaciente.tratamiento : ""}
                                        />
                                        {(datosReportePaciente.tratamiento === "" || !datosReportePaciente.tratamiento) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Edad</label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="edad"
                                            autoComplete="off"
                                            value={datosReportePaciente.edad ? datosReportePaciente.edad : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">
                                        Enf. Crónico Degenerativas<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="cronicoDegenerativos"
                                            autoComplete="off"
                                            onChange={changeNuevoReportePaciente}
                                            value={datosReportePaciente.cronicoDegenerativos ? datosReportePaciente.cronicoDegenerativos : ""}
                                        />
                                        {(datosReportePaciente.cronicoDegenerativos === "" || !datosReportePaciente.cronicoDegenerativos) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Diagnóstico</label>
                                    <Col sm={7} className="pb-3">
                                        <textarea
                                            disabled={true}
                                            className="form-control mb-2"
                                            name="diagnostico"
                                            autoComplete="off"
                                            onChange={changeNuevoReportePaciente}
                                            value={datosReportePaciente.diagnostico ? datosReportePaciente.diagnostico : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">
                                        Ciclo<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <textarea
                                            className="form-control"
                                            name="ciclo"
                                            autoComplete="off"
                                            onChange={changeNuevoReportePaciente}
                                            value={datosReportePaciente.ciclo ? datosReportePaciente.ciclo : ""}
                                        />
                                        {(datosReportePaciente.ciclo === "" || !datosReportePaciente.ciclo) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="form-group mt-2">
                                    <label className="col-sm-4 col-form-label"></label>
                                    <Col sm={7} className="pb-3">
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            style={{
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => setDatosReportePaciente(datosReportePacienteVacio)}>
                                            Limpiar Datos
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_nuevo_reporte_medicamento ? (
                <SweetAlert
                    title="Nueva Reacción Adversa"
                    confirmBtnText="Siguiente"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Atrás"
                    cancelBtnBsStyle="danger"
                    showCancel
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => {
                        if(validarDatosReaccion(datosReporteMedicamento,2)==false){
                            setUISweetAlert({
                                ...UISweetAlert,
                                confirm_nuevo_reporte_reaccion: true,
                                confirm_nuevo_reporte_medicamento: false,
                            });
                        }
                    }}
                    onCancel={() => {
                        setDatosReporteMedicamento({
                            ...datosReporteMedicamento,
                            idCatProducto: null,
                            idCatProductoVentaMostrador: null,
                            idCatProductoCompraExterna: null,
                            presentacion:null,
                            concentracionAdministracion: null,
                            idCatLaboratorio: null,
                            laboratorio: null,
                            idCatTipoAdministracion: "",
                            principioActivo: "",
                            idCatLaboratorio: "",
                            laboratorio: "",
                            idCatProductoDiluyente: null,                            
                        });                       
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_nuevo_reporte_paciente: true,
                            confirm_nuevo_reporte_medicamento: false,
                        });
                    }}>
                    Por favor, llene todos los datos de medicamento
                    <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                            <div>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-purple">Progreso</span>
                            </div>
                            <div className="text-right">
                                <span className="text-xs font-semibold inline-block text-purple">2/3</span>
                            </div>
                        </div>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-100">
                            <div
                                style={{width: "60%"}}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple"></div>
                        </div>
                    </div>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    {/* TODO: ************************************************************** */}
                                    <Col sm={5}>                                    
                                        <input
                                            type="radio"
                                            name="opcion"
                                            width={30}
                                            disabled = {!existeProductoVale}
                                            onChange={changeNuevoReporteMedicamento}
                                            checked={datosReporteMedicamento.porNotaVenta}
                                            value = {1}
                                        /> 
                                        <label className="col-sm-7 col-form-label">
                                            Medicamento (Vale)<span className="text-xs text-red-700">*</span>
                                        </label>                                        
                                    </Col>                                       

                                    <Col sm={7} className="pb-3">
                                        <select
                                            className="form-control"
                                            name="idCatProducto"
                                            onChange={changeNuevoReporteMedicamento}
                                            disabled = {datosReporteMedicamento.opcion != 1? true: false}
                                            value={datosReporteMedicamento.idCatProducto}>
                                            <option selected value= "">
                                                {datosReporteMedicamento.opcion==1? "Seleccione una opción" : "No hay medicamentos"}
                                            </option>

                                            {datosReporteMedicamento.opcion == 1? medicamentos.map((medicamento) => {
                                                return <option value={medicamento.id}>{medicamento.nombre}</option>;
                                            }) : null}
                                        </select>
                                        {((datosReporteMedicamento.idCatProducto === "" || !datosReporteMedicamento.idCatProducto) && datosReporteMedicamento.opcion==1) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                    {/* ************************************************************** */}
                                    <Col sm={5}>
                                        <input
                                            type="radio"
                                            name="opcion"
                                            width={30}
                                            disabled = {!existeProductoVentaMostrador}
                                            onChange={changeNuevoReporteMedicamento}
                                            checked={datosReporteMedicamento.porNotaVenta}
                                            value = {2}
                                        />    

                                        <label className="col-sm-7 col-form-label">Medicamento (Venta Mostrador)</label>
                                    </Col>  
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatProductoVentaMostrador"
                                            disabled = {datosReporteMedicamento.opcion != 2? true: false}
                                            onChange={changeNuevoReporteMedicamento}
                                            value={datosReporteMedicamento.idCatProductoVentaMostrador}>
                                            <option selected value = "">
                                                {datosReporteMedicamento.opcion == 2? "Seleccione una opción" : "No hay medicamentos"}
                                            </option>

                                            {datosReporteMedicamento.opcion ==2? listaMedicamentosVentaMostrador.map((medicamento) => {
                                                return <option value={medicamento.id}>{medicamento.nombre}</option>;
                                            }):null }
                                        </select>
                                        {((datosReporteMedicamento.idCatProductoVentaMostrador === "" || !datosReporteMedicamento.idCatProductoVentaMostrador )&& datosReporteMedicamento.opcion==2) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>                                    
                                    {/* ************************************************************** */}
                                    <Col sm={5}>
                                        <input
                                            type="radio"
                                            name="opcion"
                                            width={30}
                                            disabled = {!existeProductoCompraExterna}
                                            onChange={changeNuevoReporteMedicamento}
                                            checked={datosReporteMedicamento.porNotaVenta}
                                            value = {3}
                                        />    
                                    
                                    <label className="col-sm-7 col-form-label">Medicamento (Compra Externa)</label>
                                    </Col>  
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatProductoCompraExterna"
                                            disabled = {datosReporteMedicamento.opcion != 3? true: false}
                                            onChange={changeNuevoReporteMedicamento}
                                            value={datosReporteMedicamento.idCatProductoCompraExterna}>
                                            <option selected value = "">
                                                {datosReporteMedicamento.opcion == 3? "Seleccione una opción" : "No hay medicamentos"}
                                            </option>

                                            {datosReporteMedicamento.opcion == 3? listaMedicamentosCompraExterna.map((medicamento) => {
                                                return <option value={medicamento.idProductoCompraExterna}>{`${medicamento.nombreComercial}  ${medicamento.concentracion}  ${medicamento.presentacion} - L[${medicamento.lote?medicamento.lote:""}]  - P[${medicamento.piezas? medicamento.piezas : 0}]`}</option>;
                                            }):null }
                                        </select>
                                        {((datosReporteMedicamento.idCatProductoCompraExterna === "" || !datosReporteMedicamento.idCatProductoCompraExterna )&& datosReporteMedicamento.opcion==3) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>  

                                    {/* ************************************************************** */}
                                    <label className="col-sm-5 col-form-label">Presentación</label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="presentacion"
                                            autoComplete="off"
                                            value={datosReporteMedicamento.presentacion ? datosReporteMedicamento.presentacion : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">
                                        Conc. Administrada<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="concentracionAdministracion"
                                            autoComplete="off"
                                            onChange={changeNuevoReporteMedicamento}
                                            value={
                                                datosReporteMedicamento.concentracionAdministracion
                                                    ? datosReporteMedicamento.concentracionAdministracion
                                                    : ""
                                            }
                                        />
                                        {(datosReporteMedicamento.concentracionAdministracion === "" ||
                                            !datosReporteMedicamento.concentracionAdministracion) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">
                                        Vía Ad.<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <select
                                            className="form-control"
                                            name="idCatTipoAdministracion"
                                            onChange={changeNuevoReporteMedicamento}
                                            value={datosReporteMedicamento.idCatTipoAdministracion}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {tiposAdministracion.map((via) => {
                                                return <option value={via.idCatTipoAdministracion}>{via.clave}</option>;
                                            })}
                                        </select>
                                        {(datosReporteMedicamento.idCatTipoAdministracion === "" ||
                                            !datosReporteMedicamento.idCatTipoAdministracion) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>                                    
                                    <label className="col-sm-4 col-form-label">Principio Activo</label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="principioActivo"
                                            autoComplete="off"
                                            value={datosReporteMedicamento.principioActivo ? datosReporteMedicamento.principioActivo : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Fabricante</label>
                                    <Col sm={7} className="pb-3">
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="laboratorio"
                                            autoComplete="off"
                                            value={datosReporteMedicamento.laboratorio ? datosReporteMedicamento.laboratorio : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Diluyente</label>
                                    <Col sm={7} className="pb-3">
                                        <select
                                            className="form-control"
                                            name="idCatProductoDiluyente"
                                            onChange={changeNuevoReporteMedicamento}
                                            value={datosReporteMedicamento.idCatProductoDiluyente}>
                                            <option selected disabled value="">
                                                {opcionesSoluciones.length > 0 ? "Seleccione una opción" : "No hay diluyentes"}
                                            </option>
                                            {opcionesSoluciones.map((solucion) => {
                                                return <option value={solucion.id}>{solucion.nombre}</option>;
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group mt-2">
                                    <label className="col-sm-4 col-form-label"></label>
                                    <Col sm={7} className="pb-3">
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            style={{
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => setDatosReporteMedicamento(datosReporteMedicamentoVacio)}>
                                            Limpiar Datos
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </SweetAlert>
            ) : null}
            
            {UISweetAlert.confirm_nuevo_reporte_reaccion ? (
                <SweetAlert
                    title="Nueva Reacción Adversa"
                    confirmBtnText="Guardar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Atrás"
                    cancelBtnBsStyle="danger"
                    showCancel
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => checkNuevoReporte()}
                    onCancel={() => {
                        setDatosReporteReaccionAdversa(datosReporteReaccionAdversaVacio);    
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_nuevo_reporte_medicamento: true,
                            confirm_nuevo_reporte_reaccion: false,
                        });
                    }}>
                    Por favor, llene todos los datos de la reacción adversa
                    <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                            <div>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-purple">Progreso</span>
                            </div>
                            <div className="text-right">
                                <span className="text-xs font-semibold inline-block text-purple">3/3</span>
                            </div>
                        </div>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-100">
                            <div
                                style={{width: "100%"}}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple"></div>
                        </div>
                    </div>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-5 col-form-label">
                                        Síntomas<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="sintomas"
                                            autoComplete="off"
                                            onChange={changeNuevoReporteReaccion}
                                            value={datosReporteReaccionAdversa.sintomas ? datosReporteReaccionAdversa.sintomas : ""}
                                        />
                                        {(datosReporteReaccionAdversa.sintomas === "" || !datosReporteReaccionAdversa.sintomas) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                    <label className="col-sm-5 col-form-label">
                                        Resultado de REA<span className="text-xs text-red-700">*</span>
                                    </label>

                                    <Col sm={7} className="pb-3">
                                        <select
                                            className="form-control"
                                            name="idCatResultadoRea"
                                            onChange={changeNuevoReporteReaccion}
                                            value={datosReporteReaccionAdversa.idCatResultadoRea}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {resultadosRea.map((opcion) => {
                                                return <option value={opcion.idCatResultadoRea}>{opcion.resultadoRea}</option>;
                                            })}
                                        </select>
                                        {(datosReporteReaccionAdversa.idCatResultadoRea === "" || !datosReporteReaccionAdversa.idCatResultadoRea) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                    <label className="col-sm-5 col-form-label">
                                        Reporte<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="reporte"
                                            autoComplete="off"
                                            onChange={changeNuevoReporteReaccion}
                                            value={datosReporteReaccionAdversa.reporte ? datosReporteReaccionAdversa.reporte : ""}
                                        />
                                        {(datosReporteReaccionAdversa.reporte === "" || !datosReporteReaccionAdversa.reporte) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                    <label className="col-sm-5 col-form-label">
                                        Observaciones<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="observaciones"
                                            autoComplete="off"
                                            onChange={changeNuevoReporteReaccion}
                                            value={datosReporteReaccionAdversa.observaciones ? datosReporteReaccionAdversa.observaciones : ""}
                                        />
                                        {(datosReporteReaccionAdversa.observaciones === "" || !datosReporteReaccionAdversa.observaciones) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-5 col-form-label">
                                        Duración<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="duracion"
                                            autoComplete="off"
                                            onChange={changeNuevoReporteReaccion}
                                            value={datosReporteReaccionAdversa.duracion ? datosReporteReaccionAdversa.duracion : ""}
                                        />
                                        {(datosReporteReaccionAdversa.duracion === "" || !datosReporteReaccionAdversa.duracion) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                    <label className="col-sm-5 col-form-label">
                                        SRAM<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                    <textarea
                                        type="text"
                                        className="form-control mb-2"
                                        name="medicamentoControlRea"
                                        autoComplete="off"
                                        onChange={changeNuevoReporteReaccion}
                                        value={
                                            datosReporteReaccionAdversa.medicamentoControlRea ? datosReporteReaccionAdversa.medicamentoControlRea : ""
                                        }
                                    />
                                        {(datosReporteReaccionAdversa.medicamentoControlRea === "" ||
                                            !datosReporteReaccionAdversa.medicamentoControlRea) && (
                                            <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                        )}
                                    </Col>
                                    <label className="col-sm-5 col-form-label">
                                        Premedicación<span className="text-xs text-red-700">*</span>
                                    </label>
                                    <Col sm={7} className="pb-3">
                                    <textarea
                                        type="text"
                                        className="form-control mb-2"
                                        name="premedicacion"
                                        autoComplete="off"
                                        onChange={changeNuevoReporteReaccion}
                                        value={datosReporteReaccionAdversa.premedicacion ? datosReporteReaccionAdversa.premedicacion : ""}
                                    />
                                    {(datosReporteReaccionAdversa.premedicacion === "" || !datosReporteReaccionAdversa.premedicacion) && (
                                        <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                    )}
                                    </Col>
                                </Row>
                                <Row className="form-group mt-2">
                                    <label className="col-sm-4 col-form-label"></label>
                                    <Col sm={7} className="pb-3">
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            style={{
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => setDatosReporteReaccionAdversa(datosReporteReaccionAdversaVacio)}>
                                            Limpiar Datos
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <span className="font-bold">
                             Recuerda que debes realizar el reporte en la página de COFEPRIS{" "}
                            <a href="https://primaryreporting.who-umc.org/MX" target="_blank">https://primaryreporting.who-umc.org/MX</a>
            </span>
                    </CardBody>
                </SweetAlert>
            ) : null}
            {UISweetAlert.confirm_modificar_reporte_paciente ? (
                <SweetAlert
                    title="Modificar Datos de la Reacción Adversa"
                    confirmBtnText="Siguiente"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    showCancel
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_paciente: false,
                            confirm_modificar_reporte_medicamento: true,
                        });
                    }}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_paciente: false,
                        });
                    }}>
                    Por favor, llene todos los datos del paciente
                    <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                            <div>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-purple">Progreso</span>
                            </div>
                            <div className="text-right">
                                <span className="text-xs font-semibold inline-block text-purple">1/3</span>
                            </div>
                        </div>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-100">
                            <div
                                style={{width: "30%"}}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple"></div>
                        </div>
                    </div>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-5 col-form-label">Fecha de Registro</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}select
                                            type="text"
                                            className="form-control"
                                            name="fechaRegistro"
                                            autoComplete="off"
                                            value={reporteSeleccionado.fechaRegistro}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Paciente</label>
                                    <Col sm={7}>
                                        <select
                                            disabled={true}
                                            className="form-control"
                                            name="idCatPaciente"
                                            // onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.idCatPaciente}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {pacientesChangeId.map((paciente) => {
                                                return <option value={paciente.id}>{paciente.nombre}</option>;
                                            })}
                                        </select>
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Sexo</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="sexo"
                                            autoComplete="off"
                                            value={reporteSeleccionado.sexo ? reporteSeleccionado.sexo : ""}
                                        />
                                    </Col>

                                    <label className="col-sm-5 col-form-label">Médico</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            disabled={true}
                                            name="idCatMedico"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.idCatMedico}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {medicosChangeId.map((medico) => {
                                                return <option value={medico.id}>{medico.nombre}</option>;
                                            })}
                                        </select>
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Tratamiento</label>
                                    <Col sm={7}>
                                    <textarea
                                        className="form-control mb-2"
                                        name="tratamiento"
                                        autoComplete="off"
                                        onChange={changeReporteSeleccionado}
                                        value={reporteSeleccionado.tratamiento ? reporteSeleccionado.tratamiento : ""}
                                    />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Edad</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="edad"
                                            autoComplete="off"
                                            value={reporteSeleccionado.edad ? reporteSeleccionado.edad : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Enf. Crónico Degenerativas</label>
                                    <Col sm={7}>
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="cronicoDegenerativos"
                                            autoComplete="off"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.cronicoDegenerativos ? reporteSeleccionado.cronicoDegenerativos : ""}
                                        />
                                                        </Col>
                                                        <label className="col-sm-4 col-form-label">Diagnóstico</label>
                                                        <Col sm={7}>
                                        <textarea
                                            disabled={true}
                                            className="form-control mb-2"
                                            name="diagnostico"
                                            autoComplete="off"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.diagnostico ? reporteSeleccionado.diagnostico : ""}
                                        />
                                                        </Col>
                                                        <label className="col-sm-4 col-form-label">Ciclo</label>
                                                        <Col sm={7}>
                                        <textarea
                                            className="form-control"
                                            name="ciclo"
                                            autoComplete="off"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.ciclo ? reporteSeleccionado.ciclo : ""}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </SweetAlert>
            ) : null}
            
            {UISweetAlert.confirm_modificar_reporte_medicamento ? (
                <SweetAlert
                    title="Modificar datos de la Reacción Adversa"
                    confirmBtnText="Siguiente"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Atrás"
                    cancelBtnBsStyle="danger"
                    showCancel
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_reaccion: true,
                            confirm_modificar_reporte_medicamento: false,
                        });
                    }}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_paciente: true,
                            confirm_modificar_reporte_medicamento: false,
                        });
                    }}>
                    Por favor, llene todos los datos de medicamento
                    <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                            <div>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-purple">Progreso</span>
                            </div>
                            <div className="text-right">
                                <span className="text-xs font-semibold inline-block text-purple">2/3</span>
                            </div>
                        </div>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-100">
                            <div
                                style={{width: "60%"}}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple"></div>
                        </div>
                    </div>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    {/* TODO: ************************************************************** */}
                                    <Col sm={5}>                                    
                                        <label className="col-sm-7 col-form-label">
                                            Medicamento<br></br> (Vale)<span className="text-xs text-red-700">*</span>
                                        </label>                                        
                                    </Col>                                       

                                    <Col sm={7} className="pb-3">
                                        <select
                                            className="form-control"
                                            name="idCatProducto"
                                            onChange={changeNuevoReporteMedicamento}
                                            disabled = {true}
                                            value={reporteSeleccionado.idCatProducto}>
                                            <option selected value= "">
                                                {reporteSeleccionado.opcion==1? "Seleccione una opción" : "No hay medicamentos"}
                                            </option>

                                            { medicamentos.map((medicamento) => {
                                                return <option value={medicamento.id}>{medicamento.nombre}</option>;
                                            }) }
                                        </select>
                                    </Col>
                                    {/* ************************************************************** */}
                                    <Col sm={5}>
                                        <label className="col-sm-7 col-form-label">Medicamento<br></br> (Venta Mostrador)</label>
                                    </Col>  
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatProductoVentaMostrador"
                                            disabled = {true}
                                            onChange={changeNuevoReporteMedicamento}
                                            value={reporteSeleccionado.idCatProductoVentaMostrador}>
                                            <option selected value = "">
                                                {reporteSeleccionado.opcion == 2? "Seleccione una opción" : "No hay medicamentos"}
                                            </option>

                                            {reporteSeleccionado.opcion ==2? listaMedicamentosVentaMostrador.map((medicamento) => {
                                                return <option value={medicamento.id}>{medicamento.nombre}</option>;
                                            }):null }
                                        </select>
                                    </Col>                                    
                                    {/* ************************************************************** */}
                                    <Col sm={5}>
                                        <label className="col-sm-7 col-form-label">Medicamento <br></br>(Compra Externa)</label>
                                    </Col>  
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatProductoCompraExterna"
                                            disabled = {true}
                                            onChange={changeNuevoReporteMedicamento}
                                            value={reporteSeleccionado.idCatProductoCompraExterna}>
                                            <option selected value = "">
                                                {reporteSeleccionado.opcion == 3? "Seleccione una opción" : "No hay medicamentos"}
                                            </option>

                                            {reporteSeleccionado.opcion == 3? listaMedicamentosCompraExterna.map((medicamento) => {
                                                return <option value={medicamento.idProductoCompraExterna}>{medicamento.nombreComercial}</option>;
                                            }):null }
                                        </select>
                                    </Col>  

                                    {/* ************************************************************** */}                                   
                                    <label className="col-sm-5 col-form-label">Presentación</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="presentacion"
                                            autoComplete="off"
                                            value={reporteSeleccionado.presentacion ? reporteSeleccionado.presentacion : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Conc. Administrada</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="concentracionAdministracion"
                                            autoComplete="off"
                                            value={
                                                reporteSeleccionado.concentracionAdministracion ? reporteSeleccionado.concentracionAdministracion : ""
                                            }
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Vía Ad.</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatTipoAdministracion"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.idCatTipoAdministracion}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {tiposAdministracion.map((via) => {
                                                return <option value={via.idCatTipoAdministracion}>{via.clave}</option>;
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Principio Activo</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="principioActivo"
                                            autoComplete="off"
                                            value={reporteSeleccionado.principioActivo ? reporteSeleccionado.principioActivo : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Fabricante</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="laboratorio"
                                            autoComplete="off"
                                            value={reporteSeleccionado.laboratorio ? reporteSeleccionado.laboratorio : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-4 col-form-label">Diluyente</label>
                                    <Col sm={7}>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="diluyente"
                                            autoComplete="off"
                                            value={reporteSeleccionado.diluyente ? reporteSeleccionado.diluyente : "No hay diluyente"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </SweetAlert>
            ) : null}
            {UISweetAlert.confirm_modificar_reporte_reaccion ? (
                <SweetAlert
                    title="Modificar datos de la Reacción Adversa"
                    confirmBtnText="Guardar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Atrás"
                    cancelBtnBsStyle="danger"
                    showCancel
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => checkModificarReporte()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_modificar_reporte_medicamento: true,
                            confirm_modificar_reporte_reaccion: false,
                        });
                    }}>
                    Por favor, llene todos los datos de la reacción adversa
                    <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                            <div>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-purple">Progreso</span>
                            </div>
                            <div className="text-right">
                                <span className="text-xs font-semibold inline-block text-purple">3/3</span>
                            </div>
                        </div>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-100">
                            <div
                                style={{width: "100%"}}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple"></div>
                        </div>
                    </div>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-5 col-form-label">Síntomas</label>
                                    <Col sm={7}>
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="sintomas"
                                            autoComplete="off"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.sintomas ? reporteSeleccionado.sintomas : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Resultado de REA</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatResultadoRea"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.idCatResultadoRea}>
                                            <option selected disabled value="">
                                                Seleccione una opción
                                            </option>
                                            {resultadosRea.map((opcion) => {
                                                return <option value={opcion.idCatResultadoRea}>{opcion.resultadoRea}</option>;
                                            })}
                                        </select>
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Reporte</label>
                                    <Col sm={7}>
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="reporte"
                                            autoComplete="off"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.reporte ? reporteSeleccionado.reporte : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Observaciones</label>
                                    <Col sm={7}>
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="observaciones"
                                            autoComplete="off"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.observaciones ? reporteSeleccionado.observaciones : ""}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-5 col-form-label">Duración</label>
                                    <Col sm={7}>
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="duracion"
                                            autoComplete="off"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.duracion ? reporteSeleccionado.duracion : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">SRAM</label>
                                    <Col sm={7}>
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="medicamentoControlRea"
                                            autoComplete="off"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.medicamentoControlRea ? reporteSeleccionado.medicamentoControlRea : ""}
                                        />
                                    </Col>
                                    <label className="col-sm-5 col-form-label">Premedicación</label>
                                    <Col sm={7}>
                                        <textarea
                                            type="text"
                                            className="form-control mb-2"
                                            name="premedicacion"
                                            autoComplete="off"
                                            onChange={changeReporteSeleccionado}
                                            value={reporteSeleccionado.premedicacion ? reporteSeleccionado.premedicacion : ""}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_eliminar_reporte ? (
                <SweetAlert
                    title="¿Desea eliminar la reacción seleccionada?"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    style={{
                        width: width && width < 851 ? "100%" : "50%",
                    }}
                    onConfirm={() => checkEliminarReaccion()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_eliminar_reporte: false,
                        });
                    }}></SweetAlert>
            ) : null}
            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.reload_page) {
                            window.location.reload();
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                            reload_page: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}
            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}
            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
};

export default ReporteReacciones;
