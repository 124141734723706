import React, { useReducer } from "react";

import ConsultaSeguimientoContext from "./ConsultaSeguimientoContext";
import ConsultaSeguimientoReducer from "./ConsultaSeguimientoReducer";

import { LISTAR_CONSULTAS_SEGUIMIENTO } from "../../types";

import clienteAxios from "../../config/axios";

export default function ConsultaSeguimientoState(props) {
  const initialState = {
    consultasSeguimiento: [],
    tabIndex: 0,
  };

  const [state, dispatch] = useReducer(ConsultaSeguimientoReducer, initialState);

  const listarConsultasSeguimiento = async (idCatPaciente, todasConsultas, idExpedienteMedico, idCatMedico) => {
    const res = await clienteAxios.post(
      "/consultaseguimiento/detalles",
      {
        idCatPaciente: idCatPaciente,
        todasConsultas: todasConsultas,
        idExpedienteMedico: idExpedienteMedico,
        idCatMedico: idCatMedico,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch({
      type: LISTAR_CONSULTAS_SEGUIMIENTO,
      payload: res.data,
    });
  };

  const listarConsultasSeguimientoConMarcadores = async (idCatPaciente, todasConsultas, idExpedienteMedico, idCatMedico) => {
    const res = await clienteAxios.post(
      "/consultaseguimiento/detalles/marcadores",
      {
        idCatPaciente: idCatPaciente,
        todasConsultas: todasConsultas,
        idExpedienteMedico: idExpedienteMedico,
        idCatMedico: idCatMedico,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch({
      type: LISTAR_CONSULTAS_SEGUIMIENTO,
      payload: res.data,
    });
  };

  const uploadNuevoDiagnostico = async (expedienteMedico) => {
    try {
      const res = await clienteAxios.post(`/consultaseguimiento/nuevo/diagnostico`, expedienteMedico, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const uploadNuevoPlan = async (expedienteMedico) => {
    try {
      const res = await clienteAxios.post(`/consultaseguimiento/nuevo/plan`, expedienteMedico, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const uploadNuevaEtapaClinica = async (expedienteMedico) => {
    try {
      const res = await clienteAxios.post(`/consultaseguimiento/nueva/etapaclinica`, expedienteMedico, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const uploadNuevaConsultaSeguimiento = async (consultaSeguimiento) => {
    try {
      const res = await clienteAxios.post(`/consultaseguimiento/nueva`, consultaSeguimiento, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };
  const uploadConsultaSeguimientoModificada = async (consultaSeguimiento) => {
    try {
      const res = await clienteAxios.post(`/consultaseguimiento/modificar`, consultaSeguimiento, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <ConsultaSeguimientoContext.Provider
      value={{
        consultasSeguimiento: state.consultasSeguimiento,
        listarConsultasSeguimiento,
        uploadNuevoDiagnostico,
        uploadNuevoPlan,
        uploadNuevaEtapaClinica,
        uploadNuevaConsultaSeguimiento,
        uploadConsultaSeguimientoModificada,
        listarConsultasSeguimientoConMarcadores,
      }}>
      {props.children}
    </ConsultaSeguimientoContext.Provider>
  );
}
