import { LISTAR_ESTUDIOS_LABORATORIO, LISTAR_MARCADORES, LISTAR_ARCHIVOS_IMAGEN } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_ESTUDIOS_LABORATORIO:
      return {
        ...state,
        estudiosLaboratorio: action.payload,
      };
    case LISTAR_ARCHIVOS_IMAGEN:
      return {
        ...state,
        archivosImagen: action.payload,
      };
    case LISTAR_MARCADORES:
      return {
        ...state,
        marcadores: action.payload,
      };
    default:
      return state;
  }
};
