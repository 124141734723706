import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import formatNumber from "../../../utils";

import CortesCajaContext from "../../../context/CortesCaja/CortesCajaContext";
import MedicosContext from "../../../context/Medicos/MedicosContext";

import FiltroFolio from "../../Filtros/FiltroFolio/FiltroFolio";
import FiltroBusqueda from "../../Filtros/FiltroBusqueda/FiltroBusqueda";

// import AuthContext from "../../../context/Auth/AuthContext";
import AseguradorasContext from "../../../context/Aseguradoras/AseguradorasContext";

export default function CerrarCorteCajaRecepcion(props) {
  const history = useHistory();
  const [toggleSearchByVales, setToggleSearchByVales] = useState(false);
  const [toggleSearchByAseguradoras, setToggleSearchByAseguradoras] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comentarioNuevoVale, setComentarioNuevoVale] = useState(null);
  const [comentarioNuevoAseguradora, setComentarioNuevoAseguradora] = useState(null);
  const [detallesFiltradosCorteCaja, setDetallesFiltradosCorteCaja] = useState();

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_cerrar_corte: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const [totalesVales, setTotalesVales] = useState({
    totalEfectivoFisico: 0,
    totalEfectivoDeposito: 0,
    totalCredito: 0,
    totalDebito: 0,
    totalTransferencia: 0,
    totalImportes: 0,
  });
  const [totalesAseguradoras, setTotalesAseguradoras] = useState({
    totalEfectivoFisico: 0,
    totalEfectivoDeposito: 0,
    totalCredito: 0,
    totalDebito: 0,
    totalTransferencia: 0,
    totalImportes: 0,
  });

  // ACCESO AL ESTADO GLOBAL - AUTH
  // const authContext = useContext(AuthContext);
  // const { usuario } = authContext;

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxMedicos = useContext(MedicosContext);
  const { medicosInternos, listarMedicosInternos } = ctxMedicos;

  //ACCESO AL ESTADO GLOBAL - Aseguradoras
  const ctxAseguradoras = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradoras;

  const cortesCajaContext = useContext(CortesCajaContext);

  const {
    setFiltrosVales,
    setFiltrosAseguradoras,
    filtrosAseguradora,
    filtrosVales,
    detallesCorteCaja,
    setDetallesCorteCaja,
    updateCortesCaja,
    cerrarCorteCajaSeleccionado,
  } = cortesCajaContext;

  const limpiarFiltrosVales = () => {
    setFiltrosVales({
      medico: "",
      folioVale: "",
      importe: "",
    });
  };

  const limpiarFiltrosAseguradora = () => {
    setFiltrosAseguradoras({
      idAseguradora: "",
      folioVale: "",
      tipoMovimiento: "",
    });
  };

  const toggleSearchVales = () => {
    setToggleSearchByVales(!toggleSearchByVales);
    limpiarFiltrosVales();
    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      vales: detallesCorteCaja.vales,
    });
    setTotalesValesFiltrados(detallesCorteCaja.vales);
  };

  const toggleSearchAseguradoras = () => {
    setToggleSearchByAseguradoras(!toggleSearchByAseguradoras);
    limpiarFiltrosAseguradora();
    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      aseguradoras: detallesCorteCaja.aseguradoras,
    });
    setTotalesAseguradorasFiltradas(detallesCorteCaja.aseguradoras);
  };

  const buscarPorFolioVales = async (e) => {
    e.preventDefault();
    const valeFiltradoFolio = await detallesCorteCaja.vales.filter((vale) => {
      if (!filtrosVales.folioVale) {
        return vale;
      } else {
        return filtrosVales.folioVale.toLowerCase() === vale.folioVale.toLowerCase();
      }
    });

    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      vales: valeFiltradoFolio,
    });
    setTotalesValesFiltrados(valeFiltradoFolio);
    return valeFiltradoFolio;
  };

  const buscarPorFiltrosVales = async (e) => {
    e.preventDefault();

    const valesFiltrados = await detallesCorteCaja.vales.filter((vale) => {
      if (!filtrosVales.medico && !filtrosVales.importe) {
        return vale;
      }

      if (!filtrosVales.medico) {
        return vale.importeTotal <= filtrosVales.importe;
      }

      if (!filtrosVales.importe) {
        return vale.idCatMedico == filtrosVales.medico;
      }

      if (filtrosVales.medico && filtrosVales.importe) {
        return vale.idCatMedico == filtrosVales.medico && vale.importeTotal <= filtrosVales.importe;
      }
      return vale;
    });

    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      vales: valesFiltrados,
    });
    setTotalesValesFiltrados(valesFiltrados);
    return valesFiltrados;
  };

  const buscarPorFolioAseguradoras = async (e) => {
    e.preventDefault();
    const aseguradoraFiltradaFolio = await detallesCorteCaja.aseguradoras.filter((aseguradora) => {
      if (!filtrosAseguradora.folioVale) {
        return aseguradora;
      } else {
        return filtrosAseguradora.folioVale.toLowerCase() === aseguradora.folioVale.toLowerCase();
      }
    });

    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      aseguradoras: aseguradoraFiltradaFolio,
    });
    setTotalesAseguradorasFiltradas(aseguradoraFiltradaFolio);
    return aseguradoraFiltradaFolio;
  };

  const buscarPorFiltrosAseguradoras = (e) => {
    e.preventDefault();

    // Se filtran los filtros que no estan vacios o nulos
    const filtrosSeleccionados = {};
    Object.entries(filtrosAseguradora)
      .filter(([, value]) => value !== null && value !== "")
      .forEach(([key, value]) => (filtrosSeleccionados[key] = value));

    let aseguradorasFiltradas = [];

    // Si no hay filtros, regresar todas las aseguradoras

    // Si hay filtros recorrer las aseguradoras y checar por cada filtro si cumple con la condición

    if (Object.entries(filtrosSeleccionados).length === 0) {
      aseguradorasFiltradas = detallesCorteCaja.aseguradoras;
    } else {
      detallesCorteCaja.aseguradoras.forEach((aseguradora) => {
        let aseguradoraFiltrada = { ...aseguradora };
        for (let key of Object.keys(filtrosSeleccionados)) {
          if (key === "importe") {
            if (aseguradora["importeTotal"] > filtrosSeleccionados[key]) {
              aseguradoraFiltrada = {};
              break;
            }
          } else {
            if (aseguradora[key] != filtrosSeleccionados[key]) {
              aseguradoraFiltrada = {};
              break;
            }
          }
        }
        if (Object.entries(aseguradoraFiltrada).length > 0) aseguradorasFiltradas.push(aseguradoraFiltrada);
      });
    }

    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      aseguradoras: aseguradorasFiltradas,
    });
    setTotalesAseguradorasFiltradas(aseguradorasFiltradas);
    return aseguradorasFiltradas;
  };

  const onChangeVales = (e) => {
    setFiltrosVales({
      ...filtrosVales,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeImporteVales = (e) => {
    let value = e.target.value;
    value = Number(value.replace("$", ""));

    setFiltrosVales({
      ...filtrosVales,
      importe: value,
    });
  };
  const onChangeAseguradoras = (e) => {
    setFiltrosAseguradoras({
      ...filtrosAseguradora,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeImporteAseguradoras = (e) => {
    let value = e.target.value;
    value = Number(value.replace("$", ""));

    setFiltrosAseguradoras({
      ...filtrosAseguradora,
      importe: value,
    });
  };

  const medicosInternosChangeId = medicosInternos.map(({ idCatMedico, ...medicos}) => ({
    ...medicos,
    id: idCatMedico,
    nombre: `${medicos.catPersona.nombres} ${medicos.catPersona.apellidoPaterno} ${medicos.catPersona.apellidoMaterno}`,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "select",
          label: "Médico Tratante",
          name: "medico",
          value: filtrosVales.medico,
          opciones: medicosInternosChangeId,
          onChange: onChangeVales,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Importe Máximo",
          type: "text",
          name: "importe",
          disabled: false,
          value: filtrosVales.importe ? `$${filtrosVales.importe}` : `$0`,
          onChange: onChangeImporteVales,
        },
      ],
    },
  ];

  const aseguradorasChangeId = aseguradoras.map(({ idCatAseguradora, razonSocial, ...aseguradora }) => ({
    ...aseguradora,
    id: idCatAseguradora,
    nombre: razonSocial,
  }));

  const movimientos = [
    {
      id: 1,
      nombre: "COASEGURO",
    },
    {
      id: 2,
      nombre: "DEDUCIBLE",
    },
    {
      id: 3,
      nombre: "REEMBOLSO",
    },
  ];

  const inputColsAseguradoras = [
    {
      col: [
        {
          colType: "select",
          label: "Aseguradora",
          name: "idAseguradora",
          value: filtrosAseguradora.idAseguradora,
          opciones: aseguradorasChangeId,
          onChange: onChangeAseguradoras,
        },
        {
          colType: "select",
          label: "Movimiento",
          name: "tipoMovimiento",
          value: filtrosAseguradora.tipoMovimiento,
          opciones: movimientos,
          onChange: onChangeAseguradoras,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Importe Máximo",
          type: "text",
          name: "importe",
          disabled: false,
          value: filtrosAseguradora.importe ? `$${filtrosAseguradora.importe}` : `$0`,
          onChange: onChangeImporteAseguradoras,
        },
      ],
    },
  ];

  const setTotalesValesFiltrados = async (vales) => {
    let accEfectivo = 0;
    let accEfectivoDeposito = 0;
    let accCredito = 0;
    let accDebito = 0;
    let accTransferencia = 0;
    let accImportesTotales = 0;
    vales.map((vale) => {
      accEfectivo += vale.importeEfectivoFisico;
      accEfectivoDeposito += vale.importeEfectivoDeposito;
      accCredito += vale.importeTarjetaCredito;
      accDebito += vale.importeTarjetaDebito;
      accTransferencia += vale.importeTransferencia;
      accImportesTotales += vale.importeTotal;
    });
    setTotalesVales({
      totalEfectivoFisico: accEfectivo,
      totalEfectivoDeposito: accEfectivoDeposito,
      totalCredito: accCredito,
      totalDebito: accDebito,
      totalTransferencia: accTransferencia,
      totalImportes: accImportesTotales,
    });
  };
  const setTotalesAseguradorasFiltradas = async (aseguradoras) => {
    let accEfectivo = 0;
    let accEfectivoDeposito = 0;
    let accCredito = 0;
    let accDebito = 0;
    let accTransferencia = 0;
    let accImportesTotales = 0;
    aseguradoras &&
      aseguradoras.map((aseguradora) => {
        accEfectivo += aseguradoras.importeEfectivoFisico;
        accEfectivoDeposito += aseguradoras.importeEfectivoDeposito;
        accCredito += aseguradoras.importeTarjetaCredito;
        accDebito += aseguradoras.importeTarjetaDebito;
        accTransferencia += aseguradoras.importeTransferencia;
        accImportesTotales += aseguradoras.importeTotal;
      });
    setTotalesAseguradoras({
      totalEfectivoFisico: accEfectivo,
      totalEfectivoDeposito: accEfectivoDeposito,
      totalCredito: accCredito,
      totalDebito: accDebito,
      totalTransferencia: accTransferencia,
      totalImportes: accImportesTotales,
    });
  };

  const checkMovimientosCorte = () => {
    if (!detallesFiltradosCorteCaja) return false;
    if (detallesFiltradosCorteCaja.vales.length === 0 && detallesFiltradosCorteCaja.aseguradoras.length === 0) return false;

    return true;
  };

  const cerrarCorteCaja = async () => {
    const hayMovimientos = checkMovimientosCorte();
    if (hayMovimientos) {
      setLoading(true);
      const res = await cerrarCorteCajaSeleccionado(detallesCorteCaja);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_alert_cerrar_corte: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_cerrar_corte: false,
          success_dlg: true,
          dynamic_title: "Exito",
          dynamic_description: "El Corte de Caja fue cerrado correctamente",
        });
        setTimeout(() => history.push(`/cortescaja/`), 500);
      }
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_cerrar_corte: false,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se puede cerrar un corte de caja sin movimientos",
      });
    }
  };

  const setComentarioVale = (vale) => {
    const updatedCortesCaja = detallesCorteCaja.vales.map((corteCaja) => {
      if (corteCaja.idVentaVale == vale.idVentaVale) {
        return { ...corteCaja, comentario: comentarioNuevoVale };
      } else {
        return { ...corteCaja };
      }
    });
    updateCortesCaja({ ...detallesCorteCaja, vales: updatedCortesCaja });
    setComentarioNuevoVale(null);
  };
  const setComentarioVentaAseguradora = (aseguradora) => {
    const updatedCortesCaja = detallesCorteCaja.aseguradoras.map((corteCaja) => {
      if (corteCaja.idVentaAseguradora == aseguradora.idVentaAseguradora) {
        return { ...corteCaja, comentario: comentarioNuevoAseguradora };
      } else {
        return { ...corteCaja };
      }
    });
    updateCortesCaja({ ...detallesCorteCaja, aseguradoras: updatedCortesCaja });
    setComentarioNuevoAseguradora(null);
  };

  // const guardarCorte = async () => {
  //   setLoading(true);
  //   const res = await cerrarCorteCajaSeleccionado(detallesCorteCaja);
  //   if (res.data.error) {
  //     setLoading(false);
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       confirm_alert_cerrar_corte: false,
  //       dynamic_title: "Fallo",
  //       dynamic_description: res.data.message,
  //     });
  //   } else if (res) {
  //     setLoading(false);
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       confirm_alert_cerrar_corte: false,
  //       success_dlg: true,
  //       dynamic_title: "Exito",
  //       dynamic_description: "El Corte de Caja fue guardado correctamente",
  //     });
  //     history.push(`/cortescaja/`);
  //   }
  // };

  useEffect(() => {
    listarAseguradoras();
    listarMedicosInternos();
    // if (medicosInternos.length <= 0) {

    // }
    async function getDetalles() {
      let res = await setDetallesCorteCaja(props);

      setDetallesFiltradosCorteCaja(res);
      setTotalesValesFiltrados(res.vales);
      setTotalesAseguradorasFiltradas(res.aseguradoras);
    }

    getDetalles();
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Corte de Caja (Recepción)</h3>

        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearchVales}>
          {toggleSearchByVales ? "Buscar por filtros" : "Buscar por folio"}
        </button>

        {/* Filtros Vales */}
        <FiltroFolio
          toggleSearchBy={toggleSearchByVales}
          buscarPorFolio={buscarPorFolioVales}
          onChange={onChangeVales}
          value={filtrosVales.folioVale}
          params={{
            name: "folioVale",
            placeholder: "Escribe tu Folio de Vale",
          }}
        />

        <FiltroBusqueda
          toggleSearchBy={toggleSearchByVales}
          buscarPorFiltros={buscarPorFiltrosVales}
          onChange={onChangeVales}
          limpiarFiltros={limpiarFiltrosVales}
          paramsInput={inputCols}
        />

        <h4 className="flex mt-5">Detalles de Movimientos (Vales)</h4>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">
                          Fecha y<br></br>Hora
                        </th>
                        <th scope="col">Vale</th>
                        <th scope="col">Médico</th>
                        <th scope="col">Paciente</th>
                        <th scope="col">Efectivo Físico</th>
                        <th scope="col">
                          Efectivo Depósito<br></br>en Cuenta
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Crédito
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Débito
                        </th>
                        <th scope="col">Transferencia</th>
                        <th scope="col">
                          Importe<br></br>Total
                        </th>
                        <th scope="col">Comentario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.vales
                        ? detallesFiltradosCorteCaja.vales.map((vale, index) => {
                            return (
                              <>
                                <tr key={vale.idVale}>
                                  <td>{vale.fechaHora ? vale.fechaHora : ""}</td>
                                  <td>{vale.folio ? vale.folio : ""}</td>
                                  <td>{vale.medico ? vale.medico : ""}</td>

                                  <td>{vale.paciente ? vale.paciente : ""}</td>
                                  <td>{vale.importeEfectivoFisico ? formatNumber(vale.importeEfectivoFisico) : "$0"}</td>
                                  <td>{vale.importeEfectivoDeposito ? formatNumber(vale.importeEfectivoDeposito) : "$0"}</td>
                                  <td>{vale.importeTarjetaCredito ? formatNumber(vale.importeTarjetaCredito) : "$0"}</td>
                                  <td>{vale.importeTarjetaDebito ? formatNumber(vale.importeTarjetaDebito) : "$0"}</td>

                                  <td>{vale.importeTransferencia ? formatNumber(vale.importeTransferencia) : "$0"}</td>
                                  <td>{vale.importeTotal ? formatNumber(vale.importeTotal) : "$0"}</td>
                                  <td>
                                    <textarea
                                      value={vale.comentario}
                                      name="comentario"
                                      onChange={(e) => setComentarioNuevoVale(e.target.value)}
                                      onBlur={() => setComentarioVale(vale)}
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                    <thead>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.vales ? (
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">TOTALES</th>
                          <th scope="col">
                            {totalesVales.totalEfectivoFisico ? formatNumber(totalesVales.totalEfectivoFisico) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVales.totalEfectivoDeposito ? formatNumber(totalesVales.totalEfectivoDeposito) : "$0"}
                          </th>
                          <th scope="col">{totalesVales.totalCredito ? formatNumber(totalesVales.totalCredito) : "$0"}</th>
                          <th scope="col">{totalesVales.totalDebito ? formatNumber(totalesVales.totalDebito) : "$0"}</th>
                          <th scope="col">
                            {totalesVales.totalTransferencia ? formatNumber(totalesVales.totalTransferencia) : "$0"}
                          </th>
                          <th scope="col">{totalesVales.totalImportes ? formatNumber(totalesVales.totalImportes) : "$0"}</th>
                        </tr>
                      ) : null}
                    </thead>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearchAseguradoras}>
          {toggleSearchByAseguradoras ? "Buscar por filtros" : "Buscar por folio"}
        </button>

        {/* Filtros Aseguradora */}
        <FiltroFolio
          toggleSearchBy={toggleSearchByAseguradoras}
          buscarPorFolio={buscarPorFolioAseguradoras}
          onChange={onChangeAseguradoras}
          value={filtrosAseguradora.folioVale}
          params={{
            name: "folioVale",
            placeholder: "Escribe tu Folio de Vale",
          }}
        />
        <FiltroBusqueda
          toggleSearchBy={toggleSearchByAseguradoras}
          buscarPorFiltros={buscarPorFiltrosAseguradoras}
          onChange={onChangeAseguradoras}
          limpiarFiltros={limpiarFiltrosAseguradora}
          paramsInput={inputColsAseguradoras}
        />
        <h4 className="flex mt-5">Detalles de Movimientos (Aseguradora)</h4>
        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">
                          Fecha y<br></br>Hora
                        </th>
                        <th scope="col">Aseguradora</th>
                        <th scope="col">Vale</th>
                        <th scope="col">Paciente</th>
                        <th scope="col">Movimiento</th>
                        <th scope="col">
                          Efectivo<br></br>Físico
                        </th>
                        <th scope="col">
                          Efectivo Depósito<br></br>en Cuenta
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Crédito
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Débito
                        </th>
                        <th scope="col">Transferencia</th>
                        <th scope="col">
                          Importe<br></br>Total
                        </th>
                        <th scope="col">Comentario</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.aseguradoras
                        ? detallesFiltradosCorteCaja.aseguradoras.map((aseguradora, index) => {
                            return (
                              <>
                                <tr key={aseguradora.idMovimientoAseguradora}>
                                  <td>{aseguradora.fechaHora ? aseguradora.fechaHora : ""}</td>
                                  <td>{aseguradora.aseguradora ? aseguradora.aseguradora : ""}</td>
                                  <td>{aseguradora.folioVale ? aseguradora.folioVale : ""}</td>
                                  <td>{aseguradora.paciente ? aseguradora.paciente : ""}</td>
                                  <td>{aseguradora.movimiento ? aseguradora.movimiento : ""}</td>
                                  <td>
                                    {aseguradora.importeEfectivoFisico ? formatNumber(aseguradora.importeEfectivoFisico) : "$0"}
                                  </td>
                                  <td>
                                    {aseguradora.importeEfectivoDeposito
                                      ? formatNumber(aseguradora.importeEfectivoDeposito)
                                      : "$0"}
                                  </td>
                                  <td>
                                    {aseguradora.importeTarjetaCredito ? formatNumber(aseguradora.importeTarjetaCredito) : "$0"}
                                  </td>
                                  <td>
                                    {aseguradora.importeTarjetaDebito ? formatNumber(aseguradora.importeTarjetaDebito) : "$0"}
                                  </td>

                                  <td>
                                    {aseguradora.importeTransferencia ? formatNumber(aseguradora.importeTransferencia) : "$0"}
                                  </td>
                                  <td>{aseguradora.importeTotal ? formatNumber(aseguradora.importeTotal) : ""}</td>
                                  <td>
                                    <textarea
                                      value={aseguradora.comentario}
                                      name="comentario"
                                      onChange={(e) => setComentarioNuevoAseguradora(e.target.value)}
                                      onBlur={() => setComentarioVentaAseguradora(aseguradora)}
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                    <thead>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.aseguradoras ? (
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">Totales</th>
                          <th scope="col">
                            {totalesAseguradoras.totalEfectivoFisico
                              ? formatNumber(totalesAseguradoras.totalEfectivoFisico)
                              : "$0"}
                          </th>
                          <th scope="col">
                            {totalesAseguradoras.totalEfectivoDeposito
                              ? formatNumber(totalesAseguradoras.totalEfectivoDeposito)
                              : "$0"}
                          </th>
                          <th scope="col">
                            {totalesAseguradoras.totalCredito ? formatNumber(totalesAseguradoras.totalCredito) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesAseguradoras.totalDebito ? formatNumber(totalesAseguradoras.totalDebito) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesAseguradoras.totalTransferencia ? formatNumber(totalesAseguradoras.totalTransferencia) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesAseguradoras.totalImportes ? formatNumber(totalesAseguradoras.totalImportes) : "$0"}
                          </th>
                        </tr>
                      ) : null}
                    </thead>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              type="button"
              style={{
                marginRight: "2.5rem",
                marginTop: "1rem",
                background: "#ff9933",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() => guardarCorte()}>
              Guardar
            </button> */}
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              type="button"
              style={{
                marginRight: "2.5rem",
                marginTop: "1rem",
                background: "#ff9933",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() =>
                setUISweetAlert({
                  ...UISweetAlert,
                  confirm_alert_cerrar_corte: true,
                })
              }>
              Nuevo Corte de Caja
            </button>
          </Col>
        </Row>
        {UISweetAlert.confirm_alert_cerrar_corte ? (
          <SweetAlert
            title="¿Desea cerrar el Corte de Caja?"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => cerrarCorteCaja()}
            onCancel={() => {
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_cerrar_corte: false,
              });
            }}></SweetAlert>
        ) : null}

        {UISweetAlert.success_dlg ? (
          <SweetAlert
            success
            title={UISweetAlert.dynamic_title}
            onConfirm={() => {
              if (UISweetAlert.redirect) {
                props.history.push(UISweetAlert.path);
              }
              setUISweetAlert({
                ...UISweetAlert,
                success_dlg: false,
              });
            }}>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {UISweetAlert.error_dlg ? (
          <SweetAlert
            error
            title={UISweetAlert.dynamic_title}
            onConfirm={() =>
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: false,
              })
            }>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {loading ? (
          <SweetAlert title="Cargando!" showConfirm={false}>
            <Spinner />
          </SweetAlert>
        ) : null}
      </div>
    </>
  );
}
