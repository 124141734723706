import React, { useReducer } from "react";

import CatLaboratoriosContext from "./CatLaboratoriosContext";
import CatLaboratoriosReducer from "./CatLaboratoriosReducer";

import { LISTAR_CATLABORATORIOS } from "../../types";

import clienteAxios from "../../config/axios";

export default function CatLaboratoriosState(props) {
  const initialState = {
    catLaboratorios: [],
  };

  const [state, dispatch] = useReducer(CatLaboratoriosReducer, initialState);

  const listarLaboratorios = async () => {
    const res = await clienteAxios.get("/laboratorios/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_CATLABORATORIOS,
      payload: res.data,
    });
  };

  return (
    <CatLaboratoriosContext.Provider
      value={{
        catLaboratorios: state.catLaboratorios,
        listarLaboratorios,
      }}>
      {props.children}
    </CatLaboratoriosContext.Provider>
  );
}
