/* eslint-disable import/no-anonymous-default-export */
import {
  FILTRO_PAGOMEDICO,
  LISTAR_PAGOSMEDICO,
  ESTATUS_CANCELAR_PAGO,
  ERROR_ESTATUS_CANCELAR_PAGO,
  PAGO_MEDICO_SELECCIONADO,
  FILTRO_VALESMEDICO,
  LISTAR_VALESMEDICO,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case FILTRO_PAGOMEDICO:
      return {
        ...state,
        filtrosPagosMedico: action.payload,
      };
    case FILTRO_VALESMEDICO:
      return {
        ...state,
        filtrosValesMedico: action.payload,
      };
    case LISTAR_PAGOSMEDICO:
    case ESTATUS_CANCELAR_PAGO:
      return {
        ...state,
        pagosMedicoFiltrados: action.payload,
      };
    case LISTAR_VALESMEDICO:
      return {
        ...state,
        valesMedicosFiltrados: action.payload,
      };
    case PAGO_MEDICO_SELECCIONADO:
      return {
        ...state,
        pagoMedicoSeleccionado: action.payload,
      };
    case ERROR_ESTATUS_CANCELAR_PAGO:
      return {
        ...state,
      };

    default:
      return state;
  }
};
