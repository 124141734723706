import React from "react";
import "antd/dist/antd.min.css";
import {Alert, Button, Space} from "antd";

const RespuestaFacturas = ({respuestas, downloadFactura}) => {
    return (
        <div className="m-3">
            {respuestas &&
                respuestas.map((respuesta) => {
                    if (respuesta.error !== null) {
                        return (
                            <Alert
                                className="m-2 text-left"
                                message={<span className="h5">Error en la Facturación</span>}
                                showIcon
                                description={respuesta.error}
                                type="error"
                            />
                        );
                    } else {
                        return (
                            <Alert
                                className="m-2 text-left"
                                message={
                                    <span className="h5">
                                        {respuesta.detalleFactura ? "Detalle de la Factura" : "Factura generada correctamente"}
                                    </span>
                                }
                                showIcon
                                description={`Factura con folio ${respuesta.folio} generada correctamente.`}
                                type="success"
                                action={
                                    <Space direction="vertical">
                                        <Button
                                            size="medium"
                                            type="ghost"
                                            onClick={() => downloadFactura(respuesta.pdf, "PDF")}>
                                            Descargar PDF
                                        </Button>
                                        <Button
                                            size="medium"
                                            type="ghost"
                                            onClick={() => downloadFactura(respuesta.xml, "XML")}>
                                            Descargar XML
                                        </Button>
                                    </Space>
                                }
                            />
                        );
                    }
                })}
        </div>
    );
};

export default RespuestaFacturas;
