import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import InventarioContext from "../../context/Inventario/InventarioContext";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import InformacionGeneralProductoInventario from "../InformacionGeneralProductoInventario";
import AuthContext from "../../context/Auth/AuthContext";

import { maxCampoPiezas } from "../../config/variables";

export default function AsignarUbicacionInventario(props) {
  const { idInventario } = useParams();

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_eliminar_inventarioubicacion: false,
    confirm_alert_upload_asignarubicaciones: false,
    editar: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,  
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  
  const [loading, setLoading] = useState(false);
  const [seleccionadoAnaquelesDeAlmacen, setSeleccionadoAnaquelesDeAlmacen] = useState(false);
  const [anaqueles, setAnaqueles] = useState([]);
  const [inventarioUbicacionSeleccionada, setInventarioUbicacionSeleccionada] = useState({});
  const [disabledAlmacen, setDisableAlmacen] = useState(false);
  // const [errorAsignarUbicacion, setErrorAsignarUbicacion] = useState(false);

  const almacenesCtx = useContext(AlmacenesContext);
  const { almacenes, listarAlmacenes } = almacenesCtx;

  const inventarioCtx = useContext(InventarioContext);
  const {
    inventarioSeleccionado,
    seleccionarInventario,
    setInventarioSeleccionado,
    uploadEliminarInventarioUbicacion,
    uploadAsignarUbicaciones,
  } = inventarioCtx;

  const authContext = useContext(AuthContext);
  const {usuario} = authContext;

  const administrador =
        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
        usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
        usuario.authorities.includes("ROLE_DIRECCION"); 

  const onChange = async (e) => {
    let value = e.target.value;

    inventarioSeleccionado.idCatAlmacen = value;
    setInventarioSeleccionado(inventarioSeleccionado);

    cleanAllUbicaciones();
    obtenerAnaqueles(value);
  };

  const nuevaUbicacion = async () => {
    if (!inventarioSeleccionado.ubicaciones) {
      inventarioSeleccionado.ubicaciones = [];
    }

    let nuevaUbicacionObj = {
      idCatAnaquel: "",
      idCatEstante: "",
      idCatUbicacion: "",
      estantes: [],
      ubicaciones: [],
      piezas: 0,
      editable: true,
    };
    inventarioSeleccionado.ubicaciones = inventarioSeleccionado.ubicaciones.concat(nuevaUbicacionObj);
    setInventarioSeleccionado(inventarioSeleccionado);
  };

  const obtenerAnaqueles = async (idCatAlmacen) => {
    if (idCatAlmacen == "") {
      setAnaqueles([]);
    } else {
      const almacen = almacenes.filter((almc) => almc.idCatAlmacen == idCatAlmacen)[0];
      if (almacen.anaqueles != null) {
        setAnaqueles(almacen.anaqueles);
      } else {
        setAnaqueles([]);
      }
    }
  };

  const changeAnaquel = async (e, ubicacion) => {
    let value = e.target.value;
    ubicacion.idCatAnaquel = value;
    ubicacion.idCatEstante = "";
    ubicacion.idCatUbicacion = "";

    if (value == "") {
      ubicacion.estantes = [];
    } else {
      const anaquel = anaqueles.filter((anaquel) => anaquel.idCatAnaquel == value)[0];
      if (anaquel.estantes != null) {
        ubicacion.estantes = anaquel.estantes;
      } else {
        ubicacion.estantes = [];
      }
    }

    setInventarioSeleccionado(inventarioSeleccionado);
  };

  const changeEstante = async (e, ubicacion) => {
    let value = e.target.value;
    ubicacion.idCatEstante = value;
    ubicacion.idCatUbicacion = "";

    if (value == "") {
      ubicacion.ubicaciones = [];
    } else {
      const estante = ubicacion.estantes.filter((estante) => estante.idCatEstante == value)[0];
      if (estante.ubicaciones != null) {
        ubicacion.ubicaciones = estante.ubicaciones;
      } else {
        ubicacion.ubicaciones = [];
      }
    }

    setInventarioSeleccionado(inventarioSeleccionado);
  };

  const changeUbicacion = async (e, ubicacion) => {
    let value = e.target.value;

    inventarioSeleccionado.ubicaciones.map((ubc) => {
      if (value != "" && ubc != ubicacion && ubc.idInventarioUbicacion && ubc.ubicacion.idCatUbicacion == value) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Validación",
          dynamic_description: "No se pueden repetir las ubicaciones.",
        });
        value = "";
        return;
      } else if (value != "" && ubc != ubicacion && !ubc.idInventarioUbicacion && ubc.idCatUbicacion == value) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Validación",
          dynamic_description: "No se pueden repetir las ubicaciones.",
        });
        value = "";
        return;
      }
    });

    ubicacion.idCatUbicacion = value;
    setInventarioSeleccionado(inventarioSeleccionado);
  };

  const cleanAllUbicaciones = async () => {
    if (inventarioSeleccionado.ubicaciones) {
      inventarioSeleccionado.ubicaciones.map((ubicacion) => {
        if (!ubicacion.idInventarioUbicacion) {
          ubicacion.idCatAnaquel = "";
          ubicacion.idCatEstante = "";
          ubicacion.idCatUbicacion = "";
        }
      });
      setInventarioSeleccionado(inventarioSeleccionado);
    }
  };

  const changePiezasUbicacion = async (e, ubicacion) => {
    let value = e.target.value;
    if (value < 0) {
      value = value * -1;
    }
    if (value?.length <= maxCampoPiezas) {
      ubicacion.piezas = value;
      setInventarioSeleccionado(inventarioSeleccionado);
    }

    calcularPiezasRestantes(ubicacion);
  };

  const eliminarInventarioUbicacion = async () => {
    if (inventarioUbicacionSeleccionada.idInventarioUbicacion) {
      setLoading(true);
      const res = await uploadEliminarInventarioUbicacion(inventarioUbicacionSeleccionada.idInventarioUbicacion);
      if (res.data.error) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_alert_eliminar_inventarioubicacion: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        inventarioSeleccionado.ubicaciones = inventarioSeleccionado.ubicaciones.filter(
          (ubic) => ubic != inventarioUbicacionSeleccionada
        );
        setInventarioSeleccionado(inventarioSeleccionado);

        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_alert_eliminar_inventarioubicacion: false,
          dynamic_description: "Ubicación ha sido eliminada",
        });

        calcularPiezasRestantes();
      }
    } else {
      inventarioSeleccionado.ubicaciones = inventarioSeleccionado.ubicaciones.filter(
        (ubic) => ubic != inventarioUbicacionSeleccionada
      );
      setInventarioSeleccionado(inventarioSeleccionado);
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_eliminar_inventarioubicacion: false,
        success_dlg: true,
        dynamic_title: "Exito!",
        dynamic_description: "Ubicación ha sido eliminada",
      });
      calcularPiezasRestantes();
    }
  };

  const calcularPiezasRestantes = async (ubicacion) => {
    let piezasRestantes = inventarioSeleccionado.piezas;
    if (inventarioSeleccionado.ubicaciones) {
      inventarioSeleccionado.ubicaciones.map((ubc) => {
        if (ubc.piezas > 0) {
          piezasRestantes -= ubc.piezas;
        }
      });
    }

    if (piezasRestantes < 0) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `La suma de piezas ubicadas es mayor al número de piezas totales del inventario`,
      });
      piezasRestantes += Number(ubicacion.piezas);
      ubicacion.piezas = 0;
    }

    inventarioSeleccionado.piezasRestantes = piezasRestantes;
    setInventarioSeleccionado(inventarioSeleccionado);
  };

  const confirmUploadAsignarUbicacion = async () => {
    let errorUploadAsignarUbicacion = false;

    if (
      (inventarioSeleccionado.ubicaciones == null || inventarioSeleccionado.ubicaciones.length <= 0) &&
      !errorUploadAsignarUbicacion
    ) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca ubicaciones al inventario`,
      });
      errorUploadAsignarUbicacion = true;
      return;
    }

    if (inventarioSeleccionado.ubicaciones && inventarioSeleccionado.ubicaciones.length > 0) {
      inventarioSeleccionado.ubicaciones.map((ubicacion, index) => {
        if (!ubicacion.idInventarioUbicacion && !ubicacion.idCatAnaquel && !errorUploadAsignarUbicacion) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el anaquel para al renglon ${index + 1}`,
          });
          errorUploadAsignarUbicacion = true;
          return;
        }

        if (!ubicacion.idInventarioUbicacion && !ubicacion.idCatEstante && !errorUploadAsignarUbicacion) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el estante para al renglon ${index + 1}`,
          });
          errorUploadAsignarUbicacion = true;
          return;
        }

        if (!ubicacion.idInventarioUbicacion && !ubicacion.idCatUbicacion && !errorUploadAsignarUbicacion) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca la ubicación para al renglon ${index + 1}`,
          });
          errorUploadAsignarUbicacion = true;
          return;
        }

        if (!ubicacion.idInventarioUbicacion && (!ubicacion.piezas || ubicacion.piezas <= 0) && !errorUploadAsignarUbicacion) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el número de piezas para al renglon ${index + 1}`,
          });
          errorUploadAsignarUbicacion = true;
          return;
        }
      });
    }

    if (!errorUploadAsignarUbicacion) {      
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_upload_asignarubicaciones: true,
      });
    }
  };

  const guardarCambiosAsignarUbicaciones = async () => {
    setLoading(true);
    const res = await uploadAsignarUbicaciones();
    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_upload_asignarubicaciones: false,
        dynamic_description: "Se guardaron los cambios de manera exitosa.",
      });

      seleccionarInventario(idInventario);
    }
  };

  useEffect(() => {
    if (Object.keys(inventarioSeleccionado).length === 0) {
      seleccionarInventario(idInventario);
    }
    if (
      Object.keys(inventarioSeleccionado).length !== 0 &&
      usuario.idCatUsuario != inventarioSeleccionado.idResponsableAlmacen &&
      !autorizado
    ) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Solo el responsable del almacen, puede asignar una ubicación al producto.",
        redirect: true,
        path: "/productos/inventario",
      });
    }
    if (
      Object.keys(inventarioSeleccionado).length !== 0 &&
      almacenes.length > 0 &&
      inventarioSeleccionado.idCatAlmacen &&
      !seleccionadoAnaquelesDeAlmacen
    ) {
      setSeleccionadoAnaquelesDeAlmacen(true);
      if (
        (inventarioSeleccionado.ubicaciones && inventarioSeleccionado.ubicaciones.length > 0) ||
        inventarioSeleccionado.idCatAlmacen != null
      ) {
        setDisableAlmacen(true);
      }
      obtenerAnaqueles(inventarioSeleccionado.idCatAlmacen);
    }
    if (Object.keys(inventarioSeleccionado).length !== 0 && !inventarioSeleccionado.piezasRestantes) {
      calcularPiezasRestantes();
    }

    if (almacenes.length < 1) {
      listarAlmacenes(false,administrador);
    }
  }, [inventarioSeleccionado, almacenes]);

  const autorizado =
    usuario && (usuario.authorities.includes("ROLE_ALMACEN") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
    );

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Asignar Ubicación</h3>

      <InformacionGeneralProductoInventario  opcion={ true } />

      <Card className="mt-4">
        <CardBody>
          <h5 className="flex h5">Ubicaciones</h5>
          <Row className="form-group mt-2">
            <label className="ml-2 col-form-label">Piezas Restantes por Ubicar</label>
            <Col sm={3}>
              <input
                type="text"
                disabled={true}
                className="form-control"
                value={inventarioSeleccionado.piezasRestantes != null ? inventarioSeleccionado.piezasRestantes : ""}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <select
                className="form-control"
                name="idCatAlmacen"
                onChange={onChange}
                disabled={!administrador}
                value={inventarioSeleccionado.idCatAlmacen ? inventarioSeleccionado.idCatAlmacen : ""}>
                <option selected value="">
                  Seleccione una opción
                </option>
                {almacenes.map((almacen) => {
                  return (
                    <>
                      <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                    </>
                  );
                })}
              </select>
            </Col>
            <Col>
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light  mr-4"
                type="button"
                // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                onClick={nuevaUbicacion}
                disabled={!inventarioSeleccionado.idCatAlmacen}>
                Nueva Ubicación
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-responsive mt-4">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Anaquel</th>
                      <th scope="col">Estante</th>
                      <th scope="col">Ubicación</th>
                      <th scope="col">Piezas</th>
                      <th scope="col">Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventarioSeleccionado.ubicaciones &&
                      inventarioSeleccionado.ubicaciones.map((ubicacion) => {
                        return (
                          <tr key={ubicacion}>
                            {ubicacion.idInventarioUbicacion ? (
                              <>
                                <td>{ubicacion.ubicacion.estante ? ubicacion.ubicacion.estante.anaquel.anaquel : ""}</td>
                                <td>{ubicacion.ubicacion.estante ? ubicacion.ubicacion.estante.estante : ""}</td>
                                <td>{ubicacion.ubicacion.ubicacion ? ubicacion.ubicacion.ubicacion : ""}</td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <select
                                    className="form-control"
                                    name="idCatAnaquel"
                                    value={ubicacion.idCatAnaquel}
                                    disabled={!inventarioSeleccionado.idCatAlmacen}
                                    onChange={(e) => changeAnaquel(e, ubicacion)}>
                                    <option selected value="">
                                      Seleccione una opción
                                    </option>
                                    {anaqueles.map((anaquel) => {
                                      return (
                                        <>
                                          <option value={anaquel.idCatAnaquel}>{anaquel.anaquel}</option>
                                        </>
                                      );
                                    })}
                                  </select>
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="idCatEstante"
                                    value={ubicacion.idCatEstante}
                                    disabled={!ubicacion.idCatAnaquel}
                                    onChange={(e) => changeEstante(e, ubicacion)}>
                                    <option selected value="">
                                      Seleccione una opción
                                    </option>
                                    {ubicacion.estantes.map((estante) => {
                                      return (
                                        <>
                                          <option value={estante.idCatEstante}>{estante.estante}</option>
                                        </>
                                      );
                                    })}
                                  </select>
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="idCatUbicacion"
                                    value={ubicacion.idCatUbicacion}
                                    disabled={!ubicacion.idCatEstante}
                                    onChange={(e) => changeUbicacion(e, ubicacion)}>
                                    <option selected value="">
                                      Seleccione una opción
                                    </option>
                                    {ubicacion.ubicaciones.map((ubc) => {
                                      return (
                                        <>
                                          <option value={ubc.idCatUbicacion}>{ubc.ubicacion}</option>
                                        </>
                                      );
                                    })}
                                  </select>
                                </td>
                              </>
                            )}
                            <td>
                              <input
                                className="form-control"
                                name="piezas"
                                value={ubicacion.piezas}
                                disabled={!ubicacion.editable}
                                onChange={(e) => changePiezasUbicacion(e, ubicacion)}
                                onWheel={(e) => e.target.blur()}
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                type="button"
                                hidden={ubicacion.idInventarioUbicacion}
                                onClick={() => {
                                  setInventarioUbicacionSeleccionada(ubicacion);
                                  setUISweetAlert({
                                    ...UISweetAlert,
                                    confirm_alert_eliminar_inventarioubicacion: true,
                                  });
                                }}>
                                Eliminar
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-4"
                type="button"
                // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                onClick={confirmUploadAsignarUbicacion}>
                Guardar
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_upload_asignarubicaciones ? (
        <SweetAlert
          title="¿Desea guardar las ubicaciones al inventario?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => guardarCambiosAsignarUbicaciones()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_upload_asignarubicaciones: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_eliminar_inventarioubicacion ? (
        <SweetAlert
          title="¿Desea eliminar la ubicación?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarInventarioUbicacion()}
          onCancel={() => {
            setInventarioUbicacionSeleccionada({});
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_eliminar_inventarioubicacion: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
