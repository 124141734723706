import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import ReporteContabilidadContext from "../../context/ReporteContabilidad/ReporteContabilidadContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";

export default function ReporteSaldosAseguradora(props) {
  const reporteSaldosContext = useContext(ReporteContabilidadContext);
  const { filtroSaldosPorCobrarContabilidad, setFiltroSaldosPorCobrarContabilidad, exportReporteSaldosPorCobrarAseguradora } =
    reporteSaldosContext;

  const asgContext = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = asgContext;

  const estatusValeContext = useContext(EstatusValeContext);
  const { estatusValeSaldosAseguradora, listarEstatusValeSaldosAseguradora } = estatusValeContext;

  const [loading, setLoading] = useState(false);
  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_cancelar_complemento_pago: false,
    confirm_alert_cancelar_factura: false,
    confirm_alert_complementos_pago: false,
    confirm_alert_nuevo_complemento_pago: false,
    editar_espacios: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const onChange = async (e) => {
    setFiltroSaldosPorCobrarContabilidad({
      ...filtroSaldosPorCobrarContabilidad,
      [e.target.name]: e.target.value,
    });
  };

  const checkGenerarReporte = async () => {
    if (!filtroSaldosPorCobrarContabilidad.estatus) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "Por favor, seleccione el estatus del vale que quiera consultar",
      });
      return;
    }

    setLoading(true);
    const res = await exportReporteSaldosPorCobrarAseguradora();

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
    }
  };

  useEffect(() => {
    listarAseguradoras();
    listarEstatusValeSaldosAseguradora();
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Reporte de Saldos por Cobrar (Aseguradoras)</h3>

        <Card className="mt-10">
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Estatus</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      onChange={onChange}
                      name="estatus"
                      value={filtroSaldosPorCobrarContabilidad.estatus}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {estatusValeSaldosAseguradora.map((estatus) => {
                        return (
                          <>
                            <option value={estatus.estatusVale}>{estatus.estatusVale}</option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Aseguradora</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      onChange={onChange}
                      name="idCatAseguradora"
                      value={filtroSaldosPorCobrarContabilidad.idCatAseguradora}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {aseguradoras.map((aseguradora) => {
                        return (
                          <>
                            <option value={aseguradora.idCatAseguradora}>{aseguradora.razonSocial}</option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  className="btn btn-primary-blue-umo w-md waves-effect waves-light mr-4"
                  type="button"
                  onClick={checkGenerarReporte}>
                  Generar Reporte
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </>
  );
}
