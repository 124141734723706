import { LISTAR_PROVEEDORES, SET_PROVEEDORES_PAGINADAS, FILTRO_PROVEEDORES } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_PROVEEDORES:
      return {
        ...state,
        proveedores: action.payload,
      };
    case FILTRO_PROVEEDORES:
      return {
        ...state,
        filtrosProveedores: action.payload,
      };
    case SET_PROVEEDORES_PAGINADAS:
      return {
        ...state,
        proveedoresPaginados: action.payload,
      };
    default:
      return state;
  }
};
