import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import ValesContext from "../../context/Vales/ValesContext";
import ConfiguracionContext from "../../context/Configuracion/ConfiguracionContext";
import InfoGeneral from "../InformacionGeneralVale/index";
import Estatus from "../EstatusVale/index";
import ListaInsumos from "../ListadoInsumos/index";

export default function DetallesVale(props) {
    const {idVale} = useParams();

    const [changedProducts, setChangedProducts] = useState(false);
    const [requiereAutorizacion, setRequiereAutorizacion] = useState(false);
    const [cadenaLog, setCadenaLog] = useState("");

    // ACCESO A ESTADO GLOBAL - VALES
    const ctxVales = useContext(ValesContext);
    const {seleccionarVale} = ctxVales;

    // ACCESO A ESTADO GLOBAL - Configuracion
    const ctxConfiguracion = useContext(ConfiguracionContext);
    const {listarConfiguracion} = ctxConfiguracion;

    useEffect(() => {
        seleccionarVale(idVale, false);
        listarConfiguracion();
    }, []);

    return (
        <div className="mt-4">
            <InfoGeneral props={props}/>
            <Estatus props={props} changedProducts={changedProducts}  setRequiereAutorizacion={setRequiereAutorizacion} requiereAutorizacion={requiereAutorizacion}/>
            <ListaInsumos changedProducts={changedProducts} setChangedProducts={setChangedProducts}  setRequiereAutorizacion={setRequiereAutorizacion} requiereAutorizacion={requiereAutorizacion}/>
        </div>
    );
}
