import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {NumericFormat} from "react-number-format";
import FormatNumber from "../../utils/index";

import {Link} from "react-router-dom";

import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";
import ValesContext from "../../context/Vales/ValesContext";
import AuthContext from "../../context/Auth/AuthContext";
import ClientesContext from "../../context/Clientes/ClientesContext";

import CatTipoCobroContext from "../../context/CatTipoCobro/CatTipoCobroContext";

import useWindowDimensions from "../../hooks/dimensionsHook";
import SpanError from "../UI/spanError";
import { setRef } from "@material-ui/core";
//import SpanError from "../../UI/spanError";

export default function Estatus({props, changedProducts,requiereAutorizacion}) {
    const {width} = useWindowDimensions();

    const {idVale} = useParams();

    const cleanDataValeAseguradora = {
        fechaTratamiento: new Date().toISOString().slice(0, 10),
        fechaReclamo: "",
        folioReclamo: null,
        comentarioEstatus: null,
        reembolso: false,
        cartaAceptacion: false,
        importeCoaseguro: 0,
        importeDeducible: 0,
        nombreArchivo: null,
        mimeType: null,
        tamano: null,
        inputStream: null,
        importeTotalAPagar: null,
        importeDiferenciaAPagar: null,
        porcentajeImporte: null,
        porcentajeIva: null,
        cobrosAseguradora: [],
    };

    const [dataValeAseguradora, setDataValeAseguradora] = useState(cleanDataValeAseguradora);
    const [cobroEliminar, setCobroEliminar] = useState(null);

    // const [datosCotizacion, setDatosCotizacion] = useState({
    //   encabezado: "",
    //   piePagina: "",
    // });

    // eslint-disable-next-line no-unused-vars
    const [hiddenFileUpload, setHiddenFileUpload] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [nombreArchivo, setNombreArchivo] = useState();
    const [hiddenCancelarVale, setHiddenCancelarVale] = useState(true);
    const [hiddenDevolverVale, setHiddenDevolverVale] = useState(true);
    const [hiddenFechaTratamiento, setHiddenFechaTratamiento] = useState(true);
    const [hiddenGestionCobros, setHiddenGestionCobros] = useState(true);
    const [hiddenValeAceptado, setHiddenValeAceptado] = useState(true);
    const [hiddenInformacionDeposito, setHiddenInformacionDeposito] = useState(true);
    const [opcionGarantia, setOpcionGarantia] = useState(false);
    const [opcionGarantiaActivado, setOpcionGarantiaActivado] = useState(true);
    const [hiddenUploadEstatus, setHiddenUploadEstatus] = useState(false);
    const [hiddenUploadEstatusCompleto, setHiddenUploadEstatusCompleto] = useState(true);
    const [hiddenUploadEstatusAplicar, setHiddenUploadEstatusAplicar] = useState(true);
    const [hiddenNuevoEstatus, setHiddenNuevoEstatus] = useState(false);
    const [hiddenGuardarCambiios, setHiddenGuardarCambios] = useState(false);
    const [autorizacionlocal,setAutorizacionlocal] = useState(false)
    const fechaActual = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "";

    const [cancelarDevolverVale, setCancelarDevolverVale] = useState({
        fechaDevolucion: new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "",
        fechaCancelacion: new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "",
        comentarioEstatus: "",
    });

    const [datosCotizacion, setDatosCotizacion] = useState({
        encabezado: "",
        piePagina: "",
        tratamiento: "",
        aplicacion: "",
    });

    const [UISweetAlert, setUISweetAlert] = useState({
        success_dlg: false,
        confirm_alert_status_change: false,
        confirm_alert_save_changes: false,
        confirm_alert_incompleto: false,
        confirm_alert_completo: false,
        confirm_alert_cancelar_vale: false,
        confirm_alert_Solcitar_autorizacion: false,
        confirm_alert_imprimir_cotizacion: false,
        confirm_alert_devolver_vale: false,
        confirm_alert_status_change_aprobado_aseguradora: false,
        confirm_alert_status_reclamado_aseguradora: false,
        confirm_alert_changed_product: false,
        confirm_alert_changed_product_cambio_estatus: false,
        error_dlg: false,
        reload: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });
    const [loading, setLoading] = useState(false);

    // ACCESO A ESTADO GLOBAL - VALES
    const ctxVales = useContext(ValesContext);
    const {devolverVale, cancelarVale, valeSeleccionado,seleccionarVale, setChangesValeSeleccionado, exportValeCotizacion, actualizarValeFiltrado} = ctxVales;

    // ACCESO A ESTADO GLOBAL - VALES
    const ctxEstatusVale = useContext(EstatusValeContext);
    const {
        proximosEstatusVale,
        getProximosEstatusVale,
        setcambioEstatusData,
        cambioEstatusData,
        estatusCancelado,
        getEstatusCancelado,
        getEstatusDevuelto,
        estatusDevuelto,
        actualizarOCambiarEstatus,
        verificarEstatus,
    } = ctxEstatusVale;

    //ACCESO AL ESTADO GLOBAL - AUTENCIACION
    const ctxAuth = useContext(AuthContext);
    const {usuario} = ctxAuth;

    const ctxTipoCobro = useContext(CatTipoCobroContext);
    const {tipoCobros, listarCatTipoCobros} = ctxTipoCobro;

    const ctxCliente = useContext(ClientesContext);
    const {clientes, listarClientes} = ctxCliente;

    const changeEstatus = async (e) => {
        cambioEstatusData.idEstatusNuevo = e.target.value;
        setcambioEstatusData(cambioEstatusData);

        if (e.target.value == "") {
            setHiddenUploadEstatus(false);
            setHiddenUploadEstatusCompleto(true);
            setHiddenUploadEstatusAplicar(true);
            setHiddenGestionCobros(true);
            // setHiddenFolioReclamo(true);
            cleanFileUpload();
            setHiddenValeAceptado(true);
            setHiddenInformacionDeposito(true);
        } else {
            let estatusValeProx = await proximosEstatusVale.filter((item) => item.idCatEstatusVale == e.target.value)[0];

            if (estatusValeProx.idCatEstatusVale == 5 || estatusValeProx.idCatEstatusVale == 15) {
                setHiddenUploadEstatus(true);
                setHiddenUploadEstatusCompleto(false);
                setHiddenUploadEstatusAplicar(true);
            } else if (estatusValeProx.idCatEstatusVale == 6 || estatusValeProx.idCatEstatusVale == 16) {
                setHiddenUploadEstatus(true);
                setHiddenUploadEstatusCompleto(true);
                setHiddenUploadEstatusAplicar(false);
            } else if (estatusValeProx.idCatEstatusVale == 9) {
                setHiddenGestionCobros(false);
                // }else if(estatusValeProx.idCatEstatusVale == 19){
                //     setHiddenFolioReclamo(false);
            } else if (estatusValeProx.idCatEstatusVale == 20) {
                setHiddenValeAceptado(false);
            } else if (estatusValeProx.idCatEstatusVale == 21) {
                setHiddenInformacionDeposito(false);
            } else {
                setHiddenUploadEstatus(false);
                setHiddenUploadEstatusCompleto(true);
                setHiddenUploadEstatusAplicar(true);
            }
        }
    };

    const simpleOnChange = async (e) => {
        setcambioEstatusData({
            ...cambioEstatusData,
            [e.target.name]: e.target.value,
        });
    };

    const uploadFile = async (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();

        reader.addEventListener(
            "load",
            function () {
                // convert image file to base64 string
                let strFile = reader.result;
                let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
                let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);

                cambioEstatusData.nombreArchivo = file.name;
                cambioEstatusData.mimeType = type;
                cambioEstatusData.tamano = file.size;
                cambioEstatusData.inputStream = fileBase64;
                setcambioEstatusData(cambioEstatusData);
            },
            false
        );

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const changeEstatusCancelado = async () => {

        let estatusTemp = valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length-1].catEstatusvale.idCatEstatusVale
        let IdValeTemp = valeSeleccionado.idVale;
        const estatusLocal = await verificarEstatus(IdValeTemp);

        if (estatusTemp != estatusLocal.catEstatusvale.idCatEstatusVale) {
            // alert("Por favor introduzca el nuevo estatus");
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "El vale ya fue actualizado por otro usuario, ya no es posible modificar este vale",
            });
            return;
        }
        
        if (cancelarDevolverVale.comentarioEstatus == "" || cancelarDevolverVale.fechaCancelacion == "") {
            return;
        }

        let date = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "";

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_cancelar_vale: false,
        });

        setLoading(true);

        let cadenaLogLocal = ""
        let folio = valeSeleccionado.idVale
        cadenaLogLocal = cadenaLogLocal+ "Cancelación:ID[" + folio + "]";

        const res = await cancelarVale({
            idEstatusNuevo: estatusCancelado.idCatEstatusVale,
            idVale: idVale,
            fechaCancelacion: date,
            logVo : cadenaLogLocal.length==0? null:{
                idUsuario: usuario.idUsuario,
                modulo: "Vales",
                actividad:cadenaLogLocal,
            },
            comentarioEstatus: cancelarDevolverVale.comentarioEstatus,
        });
        if (res) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_cancelar_vale: false,
                dynamic_description: "Vale cancelado",
                redirect: true,
                path: "/vales",
            });
        } else {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_cancelar_vale: false,
                dynamic_title: "Fallo",
                dynamic_description: "Error al cancelar vale, Inténtalo por favor nuevamente.",
            });
        }
    };

    //TODO
    const uploadCambioEstatus = async () => {
        if (!hiddenFechaTratamiento && !cambioEstatusData.fechaTratamiento && !usuario.authorities.includes("ROLE_CONTABILIDAD")) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, introduzca el Fecha de Tratamiento.",
            });
            return;
        }

        if (!hiddenValeAceptado && !cambioEstatusData.fechaEstimadaPago) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, introduzca el Fecha Estimada de Pago.",
            });
            return;
        }  

        if (!hiddenValeAceptado && !cambioEstatusData.fechaAceptacion) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor introduzca el Fecha de Aceptación",
            });
            return;
        }  

        if (!hiddenInformacionDeposito && !cambioEstatusData.importeDeposito) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor introduzca el Import del Depósito",
            });
            return;
        }

        if (!cambioEstatusData.idEstatusNuevo) {
            // alert("Por favor introduzca el nuevo estatus");
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor introduzca el nuevo estatus",
            });
            return;
        }

        if (!hiddenValeAceptado) {
            //let date = cambioEstatusData.fechaEstimadaPago.split("-");
            //cambioEstatusData.fechaEstimadaPago = `${date[2]}/${date[1]}/${date[0]}`;
            setcambioEstatusData(cambioEstatusData);
        }

        /*if (!hiddenValeAceptado) {
            //let date = cambioEstatusData.fechaAceptacion.split("-");
            //cambioEstatusData.fechaAceptacion = `${date[2]}/${date[1]}/${date[0]}`;
            setcambioEstatusData(cambioEstatusData);
        }*/

        let estatusTemp = valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length-1].catEstatusvale.idCatEstatusVale
        let IdValeTemp = valeSeleccionado.idVale;
        const estatusLocal = await verificarEstatus(IdValeTemp);
/*
        let fechaActual = new Date().toISOString().split('T')[0]; // solo la fecha en formato "YYYY-MM-DD"
        let fechaTratamiento = new Date(valeSeleccionado.fechaTratamiento).toISOString().split('T')[0];
        

        if(estatusTemp==5 && fechaTratamiento  >  fechaActual ){
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "La fecha de tratamiento ",
            });
            return;
        }*/
        
        //aca se aborta
        if (estatusTemp != estatusLocal.catEstatusvale.idCatEstatusVale) {
            // alert("Por favor introduzca el nuevo estatus");
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "El vale ya fue actualizado por otro usuario, ya no es posible modificar este vale",
            });
            return;
        }
        //Si cambiamos a completo tenemos que asegurarnos que los insumos esten completos
        const validar = await validarTablaInsumos(true);

        if (!validar) {
            return;
        }

        const listarValeProdEnData = await setListaInsumosOnCambioEstatusData();

        if (cambioEstatusData.idEstatusNuevo == 4 || cambioEstatusData.idEstatusNuevo == 14) {
            cambioEstatusData.notificarCompras = true;
            setcambioEstatusData(cambioEstatusData);
        }

        if (listarValeProdEnData) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_save_changes: false,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
            }); 

            setLoading(true); 

            let cadenaLogLocal = ""
            let idbol = false;
            let folio = valeSeleccionado.idVale
            if (valeSeleccionado.valeproductoservicios.length > 0) {
                valeSeleccionado.valeproductoservicios.map((element) => {
                    // console.log(element);
                    if(element.productoServicioCambiado == true){
                        if(element.catProductos || element.catServicios){

                            if(!element.activo){
                                cadenaLogLocal = cadenaLogLocal+ "Eliminado:ID[" + folio + "],C[ " + element.eliminarComentario +  "]";
                            }else  if (!idbol){
                                cadenaLogLocal = cadenaLogLocal+ "Actualización:ID[" + folio + "]";
                                idbol = true;
                            }

                            if(element.personalIdCatServicio > 0){
                                if(element.personalIdCatServicio != element.personalIdCatServicioOriginal){
                                    cadenaLogLocal = cadenaLogLocal+  ",(S[" + element.personalIdCatServicioOriginal + "->" + element.personalIdCatServicio +"]"
                                } else{
                                    cadenaLogLocal = cadenaLogLocal+  ",(S[" + element.personalIdCatServicioOriginal + "->" + element.personalIdCatServicioOriginal +"]"
                                }
                            }

                            if(element.personalIdCatProducto > 0){
                                if(element.personalIdCatProducto != element.personalIdCatProductoOriginal){
                                    cadenaLogLocal = cadenaLogLocal+  ",(P[" + element.personalIdCatProductoOriginal + "->" + element.personalIdCatProducto +"]"
                                } else{
                                    cadenaLogLocal = cadenaLogLocal+  ",(P[" + element.personalIdCatProductoOriginal + "->" + element.personalIdCatProductoOriginal +"]"
                                }
                            }
    
                            if(element.precioOriginal != element.precioCorriente){
                                cadenaLogLocal = cadenaLogLocal+  ",$[" + element.precioOriginal + "->" + element.precioCorriente +"]"
                                if(element.ivaOriginal > 0 || element.ivaCorriente > 0){
                                    cadenaLogLocal = cadenaLogLocal+  ",@[" + element.ivaOriginal + "->" + element.ivaCorriente +"]"
                                }
                            }
    
                            if(element.piezasOriginales != element.piezasCorriente){
                                cadenaLogLocal = cadenaLogLocal+ ",#[" + element.piezasOriginales + "->" + element.piezasCorriente +"]"
                            }  
                            cadenaLogLocal = cadenaLogLocal + ")  "
  
                        }     
                    }
     
                });
            }

            const res = await actualizarOCambiarEstatus({
                ...cambioEstatusData,
                valeProductoServicio: cambioEstatusData.valeProductoServicio.concat(listarValeProdEnData),
                necesitaAutorizacion: 0,
                logVo : cadenaLogLocal.length==0? null:{
                    idUsuario: usuario.idUsuario,
                    modulo: "Vales",
                    actividad:cadenaLogLocal,
                },
            });

            if (res) {
                setLoading(false);
                actualizarValeFiltrado(res.data);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_alert_status_change: false,
                    confirm_alert_incompleto: false,
                    confirm_alert_completo: false,
                    confirm_alert_status_change_enrecoleccion: false,
                    dynamic_description: "Se registraron los cambios del estatus.",
                    redirect: true,
                    path: "/vales",
                });
            } else {
                const permiso = await seleccionarVale(valeSeleccionado.idVale, false);
                setLoading(false);               
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_alert_status_change: false,
                    confirm_alert_incompleto: false,
                    confirm_alert_completo: false,
                    confirm_alert_status_change_enrecoleccion: false,
                    dynamic_title: "Fallo",
                    dynamic_description: "Error al subir los cambios, Verifique sus datos e inténtalo nuevamente por favor.",
                });
                cleanCambioEstatusFailedUpload();
                cleanFechas();
            }
        }
    };

    const checkImprimirCotizacion = async () => {
        //imprimir la cotizacion
        let encabezado = ""
        let pieDePagina = ""
        
        if (valeSeleccionado.catTipovale.idCatTipoVale == 2 && usuario && usuario.authorities.includes("ROLE_ASEGURADORAS")) {
            const valeTablaSeleccionado = valeSeleccionado;
            let cotizaciones = valeSeleccionado.cotizaciones[valeSeleccionado.cotizaciones.length - 1]
            encabezado = cotizaciones.encabezado
            pieDePagina = cotizaciones.piePagina

            setDatosCotizacion({
                encabezado: encabezado,
                piePagina: pieDePagina,
                tratamiento: valeTablaSeleccionado && valeTablaSeleccionado.tratamiento ? valeTablaSeleccionado.tratamiento : "",
                aplicacion: valeTablaSeleccionado && valeTablaSeleccionado.aplicacion ? valeTablaSeleccionado.aplicacion : "",
            });
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_imprimir_cotizacion: true,
            });
        } else {
            setDatosCotizacion({
                encabezado: "",
                piePagina: "",
                tratamiento: "",
                aplicacion: "",
            });
            guardarCambios(true);
        }
    };

    const solicitarAutorizacion = async (exportCotizacion) => {

        let estatusTemp = valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length-1].catEstatusvale.idCatEstatusVale
        let IdValeTemp = valeSeleccionado.idVale;
        const estatusLocal = await verificarEstatus(IdValeTemp);

        if (estatusTemp != estatusLocal.catEstatusvale.idCatEstatusVale) {
            // alert("Por favor introduzca el nuevo estatus");
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "El vale ya fue actualizado por otro usuario, ya no es posible modificar este vale",
            });
            return;
        }

        const validar = await validarTablaInsumos();

        if (!validar) {
            return;
        }

        if (!hiddenFechaTratamiento && cambioEstatusData.fechaTratamiento) {
            cambioEstatusData.fechaTratamiento = null;
        }

        cambioEstatusData.idEstatusNuevo = "";
        setcambioEstatusData(cambioEstatusData);

        const listarValeProdEnData = await setListaInsumosOnCambioEstatusData();

        if (listarValeProdEnData) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_save_changes: false,
                confirm_alert_upload_vale: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                confirm_alert_status_change_enrecoleccion: false,
                confirm_alert_Solcitar_autorizacion: false,
            });
            setLoading(true);
            // const dateFechaAceptacion = cambioEstatusData.fechaAceptacion ? cambioEstatusData.fechaAceptacion.split("-") : null;
            // const fechaAceptacionFormateada = dateFechaAceptacion ? dateFechaAceptacion[2] + "/" + dateFechaAceptacion[1] + "/" + dateFechaAceptacion[0] : "";
            // const dateFechaEstimada = cambioEstatusData.fechaEstimadaPago ? cambioEstatusData.fechaEstimadaPago.split("-") : null;
            // const fechaEstimadaFormateada = dateFechaEstimada ? dateFechaEstimada[2] + "/" + dateFechaEstimada[1] + "/" + dateFechaEstimada[0] : "";
			let cadenaLogLocal = ""
            let idbol = false;
            let folio = valeSeleccionado.idVale
            if (valeSeleccionado.valeproductoservicios.length > 0) {
                valeSeleccionado.valeproductoservicios.map((element) => {
                    // console.log(element);
                    if(element.productoServicioCambiado == true){
                        if(element.catProductos || element.catServicios){

                            if(!element.activo){
                                cadenaLogLocal = cadenaLogLocal+ "Eliminado:ID[" + folio + "],C[ " + element.eliminarComentario +  "]";
                            }else  if (!idbol){
                                cadenaLogLocal = cadenaLogLocal+ "Autorización:ID[" + folio + "]";
                                idbol = true;
                            }

                            if(element.personalIdCatServicio > 0){
                                if(element.personalIdCatServicio != element.personalIdCatServicioOriginal){
                                    cadenaLogLocal = cadenaLogLocal+  ",(S[" + element.personalIdCatServicioOriginal + "->" + element.personalIdCatServicio +"]"
                                } else{
                                    cadenaLogLocal = cadenaLogLocal+  ",(S[" + element.personalIdCatServicioOriginal + "->" + element.personalIdCatServicioOriginal +"]"
                                }
                            }

                            if(element.personalIdCatProducto > 0){
                                if(element.personalIdCatProducto != element.personalIdCatProductoOriginal){
                                    cadenaLogLocal = cadenaLogLocal+  ",(P[" + element.personalIdCatProductoOriginal + "->" + element.personalIdCatProducto +"]"
                                } else{
                                    cadenaLogLocal = cadenaLogLocal+  ",(P[" + element.personalIdCatProductoOriginal + "->" + element.personalIdCatProductoOriginal +"]"
                                }
                            }
    
                            if(element.precioOriginal != element.precioCorriente){
                                cadenaLogLocal = cadenaLogLocal+  ",$[" + element.precioOriginal + "->" + element.precioCorriente +"]"
                                if(element.ivaOriginal > 0 || element.ivaCorriente > 0){
                                    cadenaLogLocal = cadenaLogLocal+  ",@[" + element.ivaOriginal + "->" + element.ivaCorriente +"]"
                                }
                            }
    
                            if(element.piezasOriginales != element.piezasCorriente){
                                cadenaLogLocal = cadenaLogLocal+ ",#[" + element.piezasOriginales + "->" + element.piezasCorriente +"]"
                            }  
                            cadenaLogLocal = cadenaLogLocal + ")  "
  
                        }     
                    }
     
                });
            }

            const res = await actualizarOCambiarEstatus({
                ...cambioEstatusData,
                valeProductoServicio: cambioEstatusData.valeProductoServicio.concat(listarValeProdEnData),
                fechaAceptacion: cambioEstatusData.fechaAceptacion,
                fechaEstimadaPago: cambioEstatusData.fechaEstimadaPago,
                necesitaAutorizacion: 1,
                logVo : cadenaLogLocal.length==0? null:{
                    idUsuario: usuario.idUsuario,
                    modulo: "Vales",
                    actividad:cadenaLogLocal,
                },
            });

            if (res) {
                setLoading(false);

                if (exportCotizacion) {
                    imprimirCotizacion();
                }

                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_alert_save_changes: false,
                    confirm_alert_upload_vale: false,
                    confirm_alert_incompleto: false,
                    confirm_alert_imprimir_cotizacion: false,
                    confirm_alert_status_change_enrecoleccion: false,
                    path: "/vales",
                    dynamic_description: "Se registraron los cambios del vale.",
                    redirect: true,
                });
            } else {
                const permiso = await seleccionarVale(valeSeleccionado.idVale, false);
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_alert_upload_vale: false,
                    confirm_alert_save_changes: false,
                    confirm_alert_imprimir_cotizacion: false,
                    confirm_alert_status_change_enrecoleccion: false,
                    confirm_alert_incompleto: false,
                    dynamic_title: "Fallo",
                    dynamic_description: "Error al subir los cambios, Verifique sus datos e inténtalo nuevamente por favor.",
                });
                cleanCambioEstatusFailedUpload();
            }
        }
    };

    const guardarCambios = async (exportCotizacion) => {
        let estatusTemp = valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length-1].catEstatusvale.idCatEstatusVale
        let IdValeTemp = valeSeleccionado.idVale;
        const estatusLocal = await verificarEstatus(IdValeTemp);

        if (estatusTemp != estatusLocal.catEstatusvale.idCatEstatusVale) {
            // alert("Por favor introduzca el nuevo estatus");
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "El vale ya fue actualizado por otro usuario, ya no es posible modificar este vale",
            });
            return;
        }

        const validar = await validarTablaInsumos();

        if (!validar) {
            return;
        }

        if (!hiddenFechaTratamiento && cambioEstatusData.fechaTratamiento) {
            cambioEstatusData.fechaTratamiento = null;
        }

        cambioEstatusData.idEstatusNuevo = "";
        setcambioEstatusData(cambioEstatusData);

        const listarValeProdEnData = await setListaInsumosOnCambioEstatusData();

        if (listarValeProdEnData) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_save_changes: false,
                confirm_alert_upload_vale: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                confirm_alert_status_change_enrecoleccion: false,
            });
            setLoading(true);
            // const dateFechaAceptacion = cambioEstatusData.fechaAceptacion ? cambioEstatusData.fechaAceptacion.split("-") : null;
            // const fechaAceptacionFormateada = dateFechaAceptacion ? dateFechaAceptacion[2] + "/" + dateFechaAceptacion[1] + "/" + dateFechaAceptacion[0] : "";
            // const dateFechaEstimada = cambioEstatusData.fechaEstimadaPago ? cambioEstatusData.fechaEstimadaPago.split("-") : null;
            // const fechaEstimadaFormateada = dateFechaEstimada ? dateFechaEstimada[2] + "/" + dateFechaEstimada[1] + "/" + dateFechaEstimada[0] : "";
            // debemos ver porque esta salvado = 1 para autorizacion
            //let cambio = false
            //if(global.cambio  = true){
            //    cambio = 1
            //
            //let estatusTemp = valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length-1].catEstatusvale.idCatEstatusVale;
            
            const res = await actualizarOCambiarEstatus({
                ...cambioEstatusData,
                valeProductoServicio: cambioEstatusData.valeProductoServicio.concat(listarValeProdEnData),
                fechaAceptacion: cambioEstatusData.fechaAceptacion,
                fechaEstimadaPago: cambioEstatusData.fechaEstimadaPago,
                necesitaAutorizacion: 0,
            });
            
            if (res) {
                //let permisotemp = global.cambio;

                cambioEstatusData.valeProductoServicio = []
                setcambioEstatusData({...cambioEstatusData,})
                //if(estatusTemp==13 || estatusTemp==3 ){
                const permiso = await seleccionarVale(valeSeleccionado.idVale, false);
                //global.cambio = permisotemp;
                //}
                setLoading(false);

                if (exportCotizacion) {
                    imprimirCotizacion();
                }
                
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_alert_save_changes: false,
                    confirm_alert_upload_vale: false,
                    confirm_alert_incompleto: false,
                    confirm_alert_imprimir_cotizacion: false,
                    confirm_alert_status_change_enrecoleccion: false,
                    //path: "/vales/" + valeSeleccionado.idVale,
                    dynamic_description: "Se registraron los cambios del estatus.",
                    redirect: false,
                });
                //this.props.history.push('/vales')
                
            } else {
                const permiso = await seleccionarVale(valeSeleccionado.idVale, false);
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_alert_upload_vale: false,
                    confirm_alert_save_changes: false,
                    confirm_alert_imprimir_cotizacion: false,
                    confirm_alert_status_change_enrecoleccion: false,
                    confirm_alert_incompleto: false,
                    dynamic_title: "Fallo",
                    dynamic_description: "Error al subir los cambios, Verifique sus datos e inténtalo nuevamente por favor.",
                });
                cleanCambioEstatusFailedUpload();
            }
        }
    };

    const validarTablaInsumos = async (esCambioDeEstatus) => {
        let readyToSend = true;
        let existeServicio = false;
        let existeMedicina = false;

        if (valeSeleccionado.valeproductoservicios.length < 1 && cambioEstatusData.valeProductoServicio.length < 1 && readyToSend) {
            // alert("Ingresa insumos")
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_save_changes: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                confirm_alert_cancelar_vale: false,
                confirm_alert_devolver_vale: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor ingrese insumos",
            });
            readyToSend = false;
            return readyToSend;
        }

        if (cambioEstatusData.valeProductoServicio.length > 0) {
            cambioEstatusData.valeProductoServicio.map((element) => {           
                
                if(element.idCatTipoProducto==3){
                    existeServicio = true;
                }

                if( element.idCatTipoProducto==2){
                        existeMedicina = true;
                }  

                if (element.idCatProducto == null && element.idCatServicio == null && readyToSend) {
                    // alert("Por favor ingrese el insumo")
                    setUISweetAlert({
                        ...UISweetAlert,
                        confirm_alert_status_change: false,
                        confirm_alert_save_changes: false,
                        confirm_alert_incompleto: false,
                        confirm_alert_completo: false,
                        confirm_alert_cancelar_vale: false,
                        confirm_alert_devolver_vale: false,
                        error_dlg: true,
                        dynamic_title: "Validación",
                        dynamic_description: "Por favor, ingrese el insumo",
                    });
                    readyToSend = false;
                    return readyToSend;
                } else if ((element.piezas == null || element.piezas < 1) && readyToSend) {
                    // alert("Por favor ingrese un numero de piezas mayor a 0")
                    setUISweetAlert({
                        ...UISweetAlert,
                        confirm_alert_status_change: false,
                        confirm_alert_save_changes: false,
                        confirm_alert_incompleto: false,
                        confirm_alert_completo: false,
                        confirm_alert_cancelar_vale: false,
                        confirm_alert_devolver_vale: false,
                        error_dlg: true,
                        dynamic_title: "Validación",
                        dynamic_description: "Por favor ingrese un numero de piezas mayor a 0",
                    });
                    readyToSend = false;
                    return readyToSend;
                }
            });
        }

        if (valeSeleccionado.valeproductoservicios.length > 0) {
            valeSeleccionado.valeproductoservicios.map((element) => {
            if(element.activo){
                if(element.activo == true && element.catServicios){
                    existeServicio = true;
                }

                if(element.activo == true && element.catProductos){
                    if(element.catProductos.catTipoproducto.idCatTipoProducto ==2){
                        existeMedicina = true;
                    }
                }  

                if(element.activo == true && element.tipoProducto){

                    if( element.tipoProducto.idCatTipoProducto==3){
                        existeServicio = true;
                    }
                    if(element.tipoProducto.idCatTipoProducto==2){
                        existeMedicina = true;
                    }
                }                
                // console.log(element);
                  if (esCambioDeEstatus) {
                    if (!element.catProductos && !element.catServicios && !element.idCatProducto && !element.idCatServicio && readyToSend) {
                        // alert("Por favor ingrese el insumo")
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_status_change: false,
                            confirm_alert_save_changes: false,
                            confirm_alert_incompleto: false,
                            confirm_alert_completo: false,
                            confirm_alert_cancelar_vale: false,
                            confirm_alert_devolver_vale: false,
                            error_dlg: true,
                            dynamic_title: "Validación",
                            dynamic_description: "Por favor, complete todos los productos",
                        });
                        readyToSend = false;
                        return readyToSend;
                    }
                }

                element.inventarioProductoServicios.map((lote) => {

                    if (!lote.inventario && (!lote.idInventario || lote.piezasAsignadas <= 0) && readyToSend) {
                        // alert(`Porfavor complete el lote del insumo ' ${element.catProductos.nombreComercial}' de manera correcta`)
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_status_change: false,
                            confirm_alert_save_changes: false,
                            confirm_alert_incompleto: false,
                            confirm_alert_completo: false,
                            confirm_alert_cancelar_vale: false,
                            confirm_alert_devolver_vale: false,
                            error_dlg: true,
                            dynamic_title: "Validación",
                            dynamic_description: `Por favor, complete el lote del producto ' ${element.catProductos.nombreComercial}' de manera correcta`,
                        });
                        readyToSend = false;
                        return readyToSend;
                    }
                });
            }
            });
        }
        //LAS BANDERAS LAS PONDREMOS EN TRUE PORQUE QUITAMOS FUNCIONALIDAD DE VERIFICAR QUE EXISTE UN MEDICAMENTE Y UN SERVIICO AL MENOS
        existeServicio  = true;
        existeMedicina = true;

         if(!existeServicio || !existeMedicina){
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change: false,
                confirm_alert_save_changes: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                confirm_alert_cancelar_vale: false,
                confirm_alert_devolver_vale: false,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "El vale debe contener al menos un servicio y un medicamento",
            });
            readyToSend = false;
            return readyToSend;
        }
        
        return readyToSend;
    };

    const setListaInsumosOnCambioEstatusData = async () => {
        let listaInsumos = [];
        // TODO GUARDADO
        valeSeleccionado.valeproductoservicios.map((valeProdServ) => {
            let data = {
                idValeProductoServicio: valeProdServ.idValeProductoServicio,
                piezas: valeProdServ.piezasVale,
                precio: valeProdServ.precio,
                iva: valeProdServ.iva,
                principioActivo: valeProdServ.principioActivo,
                concentracion: valeProdServ.concentracion,
                inventarioProductoServicio: [],
                producto: valeProdServ.producto,
                compraExterna: valeProdServ.compraExterna ? valeProdServ.compraExterna : false,
            };

            data.activo = valeProdServ.activo;
            data.comentario = valeProdServ.eliminarComentario;

            if (valeProdServ.catProductos) {
                data.idCatProducto = valeProdServ.personalIdCatProducto;
                data.descripcion = valeProdServ.catProductos.nombreComercial
                data.producto = valeProdServ.catProductos.nombreComercial
            } else if (valeProdServ.idCatProducto) {
                data.idCatProducto = valeProdServ.personalIdCatProducto;
                data.descripcion = valeProdServ.producto
                data.producto = valeProdServ.producto
            } else if (valeProdServ.catServicios) {
                data.idCatServicio = valeProdServ.personalIdCatServicio;
                data.descripcion = valeProdServ.catServicios.servicio
                data.producto = valeProdServ.catServicios.servicio
            }
            else if (valeProdServ.idCatServicio) {
                data.idCatServicio = valeProdServ.personalIdCatServicio;
                data.descripcion = valeProdServ.producto
                data.producto = valeProdServ.producto
            }

            if (valeProdServ.inventarioProductoServicios.length > 0) {
                valeProdServ.inventarioProductoServicios.map((inventarioProdServ) => {
                    let dataInvProdServ = {
                        piezasAsignadas: inventarioProdServ.piezasAsignadas,
                        piezasRecibidas: inventarioProdServ.piezasRecibidas,
                        piezasUtilizadas: inventarioProdServ.piezasUtilizadas,
                    };
                    if (inventarioProdServ.idInventarioProductoServicio) {
                        dataInvProdServ.idInventarioProductoServicio = inventarioProdServ.idInventarioProductoServicio;
                    } else if (inventarioProdServ.idInventario) {
                        dataInvProdServ.idInventario = inventarioProdServ.idInventario;
                    }
                    data.inventarioProductoServicio = data.inventarioProductoServicio.concat(dataInvProdServ);
                });
            }
            listaInsumos.push(data);
        });
        return listaInsumos;
    };

    // const imprimirCotizacionConfirm = async () => {
    //   guardarCambios(true);
    // };

    const imprimirCotizacion = async () => {
        //imprimir la cotizacion
        setLoading(true);
        const valeVo = {
            idVale: idVale,
            encabezado: datosCotizacion.encabezado,
            piePagina: datosCotizacion.piePagina,
            tratamiento: datosCotizacion.tratamiento,
            aplicacion: datosCotizacion.aplicacion,
        };
        const res = await exportValeCotizacion(valeVo);

        if (res.data.error) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_cancelar_vale: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);
        }
    };

    const cleanFileUpload = () => {
        cambioEstatusData.nombreArchivo = null;
        cambioEstatusData.mimeType = null;
        cambioEstatusData.tamano = null;
        cambioEstatusData.inputStream = null;
        setcambioEstatusData(cambioEstatusData);
    };

    const checkIfCompleted = async () => {
        let lotesDisponibles = await checarLotesDisponiblesParaProductos();
        if (!lotesDisponibles) {
            const everyInsumoCompleted = await checkEveryInsumoCompleted();
            if (everyInsumoCompleted) {
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_completo: true,
                    dynamic_title: "El Vale está completo, ¿Desea hacer el cambio de Estatus?",
                });
            } else {
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_incompleto: true,
                });
            }
        }
    };

    const checkEveryInsumoCompleted = async () => {
        let everyInsumoCompleted = true;

        valeSeleccionado.valeproductoservicios.map((element) => {
            if (!element.completed && everyInsumoCompleted) {
                if(element.catServicios){
                    everyInsumoCompleted = false;
                }
                return everyInsumoCompleted;
            }
        });

        return everyInsumoCompleted;
    };

    const entregaNotificarACompras = async () => {
        cambioEstatusData.notificarCompras = true;
        setcambioEstatusData(cambioEstatusData);
        uploadCambioEstatus();
    };

    const cleanCambioEstatusFailedUpload = async () => {
        let valeSize = valeSeleccionado.valeproductoservicios.length;
        cambioEstatusData.valeProductoServicio.splice(cambioEstatusData.valeProductoServicio.length - valeSize, valeSize);
        setcambioEstatusData(cambioEstatusData);
    };

    const habilitarCancelarDevolverVale = async (idCatEstatusValeActual) => {
        let noHayPiezasUtilizadas = true;

        valeSeleccionado.valeproductoservicios.map((valeProdServ) => {
            if (valeProdServ.inventarioProductoServicios.length > 0 && noHayPiezasUtilizadas) {
                valeProdServ.inventarioProductoServicios.map((lote) => {
                    if (lote.piezasUtilizadas > 0) {
                        noHayPiezasUtilizadas = false;
                    }
                });
            }
        });

        if (noHayPiezasUtilizadas) {
            if (usuario.authorities.includes("OP_FARMACIA_VALE_CANCELAR")) {
                setHiddenCancelarVale(false);
            }
            if (
                (idCatEstatusValeActual == 6 || idCatEstatusValeActual == 16) &&
                usuario.authorities.includes("OP_FARMACIA_VALE_DEVOLVER")
            ) {
                setHiddenDevolverVale(false);
                getEstatusDevuelto(idVale);
            }
        }
    };

    const changeEstatusDevuelto = async () => {
        if (cancelarDevolverVale.comentarioEstatus == "" || cancelarDevolverVale.fechaDevolucion == "") {
            return;
        }

        let date = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "";

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_devolver_vale: false,
        });

        setLoading(true);
        const res = await devolverVale({
            idEstatusNuevo: estatusDevuelto.idCatEstatusVale,
            idVale: idVale,
            fechaDevolucion: date,
            comentarioEstatus: cancelarDevolverVale.comentarioEstatus,
        });
        if (res) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_devolver_vale: false,
                dynamic_description: "Vale devuelto",
                redirect: true,
                path: "/vales",
            });
        } else {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_devolver_vale: false,
                dynamic_title: "Fallo",
                dynamic_description: "Error al devolver el vale, Inténtalo por favor más tarde.",
            });
        }
    };

    const onChangeCancelarDevolverVale = (e) => {
        setCancelarDevolverVale({
            ...cancelarDevolverVale,
            [e.target.name]: e.target.value,
        });
    };

    const checkIfCompletedPorAplicar = async () => {
        const everyInsumoCompleted = await checkEveryInsumoCompleted();
        const everyInsumoPiezasRecibidasCompleted = await checkEveryInsumoPiezasRecibidasCompleted();
        //const everyInsumoPiezasRecibidasCompleted = true;

        if (!everyInsumoCompleted) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Todos los insumos deben estar completo para cambiar de estatus",
            });
            return;
        }

        if (!everyInsumoPiezasRecibidasCompleted) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Los insumos tienen que haber recibido todas las piezas asignadas",
            });
            return;
        }

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_completo: true,
            dynamic_title: "El vale se recibió completamente, ¿Desea hacer el cambio de Estatus?",
        });
    };

    const checkEveryInsumoPiezasRecibidasCompleted = async () => {
        let everyInsumoPiezasRecibidasCompleted = true;

        valeSeleccionado.valeproductoservicios.map((producto) => {
            if(producto.piezasVale > 0 ){
                producto.inventarioProductoServicios.map((lote) => {
                    const esCentroMezclas = producto.catProductos && producto.catProductos.centroMezclas;
                    if (lote.piezasAsignadas > lote.piezasRecibidas && esCentroMezclas && everyInsumoPiezasRecibidasCompleted) {
                        everyInsumoPiezasRecibidasCompleted = false;
                        return everyInsumoPiezasRecibidasCompleted;
                    }
                });
            }
        });

        return everyInsumoPiezasRecibidasCompleted;
    };

    const cleanFechas = () => {
        // if (cambioEstatusData.fechaEstimadaPago) {
        //   let date = cambioEstatusData.fechaEstimadaPago.split("/");
        //   // cambioEstatusData.fechaEstimadaPago = `${date[2]}-${date[1]}-${date[0]}`;
        // }
        // if (cambioEstatusData.fechaAceptacion) {
        //   let date = cambioEstatusData.fechaAceptacion.split("/");
        //   // cambioEstatusData.fechaAceptacion = `${date[2]}-${date[1]}-${date[0]}`;
        // }

        setcambioEstatusData(cambioEstatusData);
    };

    const accessoDenegado = () => {
        setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "No tiene permiso para hacer modificaciones al estatus del vale actualmente.",
            redirect: true,
            path: "/vales",
        });
    };

    const onChangeImporteDeposito = (e) => {
        let value = e.target.value;
        value = value.replace("$", "");

        setcambioEstatusData({
            ...cambioEstatusData,
            [e.target.name]: value,
        });
    };

    const confirmUploadCambioEstatus = async () => {

        if(global.cambio == true){
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "El vale necesita ser autorizado por Quimio por cambio de producto",
            });
            return;
        }

        let idCatEstatusValeActual =
            valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale;
        console.log("idCatEstatusValeActual", idCatEstatusValeActual)

        if (usuario.authorities.includes("ROLE_ASEGURADORAS") && !usuario.authorities.includes("ROLE_CONTABILIDAD") ){
            if (!cambioEstatusData.esquemaDias || cambioEstatusData.esquemaDias == 0) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Agregue por favor el Esquema TX (días) del vale",
                });
                return;
            }   
        }

        if (idCatEstatusValeActual == 12) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_status_change_aprobado_aseguradora: true,
            });
        } else if (idCatEstatusValeActual == 18) {
            setUISweetAlert({
                ...UISweetAlert, 
                confirm_alert_status_reclamado_aseguradora: true,
            });
        } else if (idCatEstatusValeActual == 16 && cambioEstatusData.idEstatusNuevo == 23) {
            let total = (
                valeSeleccionado.importeCoaseguro +
                valeSeleccionado.importeDeducible +
                valeSeleccionado.importeCoaseguroHospitalario
            ).toFixed(2);
            dataValeAseguradora.importeTotalAPagar = total;
            dataValeAseguradora.importeDiferenciaAPagar = total;
            dataValeAseguradora.porcentajeImporte = 1;
            dataValeAseguradora.porcentajeIva = 0;
            setDataValeAseguradora(dataValeAseguradora);

            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_cobro_coaseguro_reembolso: true,
            });
        } else if (idCatEstatusValeActual == 16 && cambioEstatusData.idEstatusNuevo == 24) {
            let total = (valeSeleccionado.ventavales[0].importeTotal + valeSeleccionado.ventavales[0].ivaTotal).toFixed(2);
            dataValeAseguradora.importeTotalAPagar = total;
            dataValeAseguradora.importeDiferenciaAPagar = total;
            dataValeAseguradora.porcentajeImporte = (valeSeleccionado.ventavales[0].importeTotal / total).toFixed(2);
            dataValeAseguradora.porcentajeIva = (valeSeleccionado.ventavales[0].ivaTotal / total).toFixed(2);
            setDataValeAseguradora(dataValeAseguradora);

            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_cobro_coaseguro_reembolso: true,
            });
        } else if (cambioEstatusData.idEstatusNuevo == 4 || cambioEstatusData.idEstatusNuevo == 14) {
            let lotesDisponibles = await checarLotesDisponiblesParaProductos();
            if (!lotesDisponibles) {
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_status_change_enrecoleccion: true,
                });
            }
        } else {
            if (changedProducts) {
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_changed_product_cambio_estatus: true,
                });
            } else {
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_status_change: true,
                });
            }

        }
    };

    const onChangegarantiaOpcion = async (e) => {
        let value = !opcionGarantia;
        setOpcionGarantia(value);
    }

    const onChangeDataValeAseguradora = async (e) => {
        let value = e.target.value;

        if (e.target.name == "reembolso") {
            value = !dataValeAseguradora.reembolso;
            cleanValuesDataValeAsegurada();
        }

        if (e.target.name == "cartaAceptacion") {
            value = !dataValeAseguradora.cartaAceptacion;
            cleanValuesDataValeAsegurada();
        }

        setDataValeAseguradora({
            ...dataValeAseguradora,
            [e.target.name]: value,
        });
    };

    const cleanValuesDataValeAsegurada = async () => {
        dataValeAseguradora.nombreArchivo = null;
        dataValeAseguradora.mimeType = null;
        dataValeAseguradora.tamano = null;
        dataValeAseguradora.inputStream = null;
        dataValeAseguradora.comentarioEstatus = null;
        dataValeAseguradora.importeDeducible = 0;
        dataValeAseguradora.importeCoaseguro = 0;
        dataValeAseguradora.importeCoaseguroHospitalario = 0;
        dataValeAseguradora.folioReclamo = null;
        dataValeAseguradora.fechaReclamo = "";

        let cartaAceptacion = document.getElementById("cartaAceptacion");
        if (cartaAceptacion) {
            cartaAceptacion.value = null;
        }
        setDataValeAseguradora(dataValeAseguradora);
    };

    const changeCartaAceptacion = async (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();

        reader.addEventListener(
            "load",
            function () {
                // convert image file to base64 string
                let strFile = reader.result;
                let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
                let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);

                setDataValeAseguradora({
                    ...dataValeAseguradora,
                    nombreArchivo: file.name,
                    mimeType: type,
                    tamano: file.size,
                    inputStream: fileBase64,
                });
            },
            false
        );

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const onChangeImportesAprobarValeAseguradora = async (e) => {
        let value = e.target.value;
        value = value.replace("$", "").replace(/[^0-9\.]/g, "");
        value = value.replace(",", "");
        setDataValeAseguradora({
            ...dataValeAseguradora,
            [e.target.name]: value,
        });
    };

    const uploadAprobarValeAseguradora = async () => {
        let erroUpload = false;

        if (!dataValeAseguradora.fechaTratamiento && !erroUpload) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor introduzca la fecha de tratamiento.",
            });
            erroUpload = true;
            return;
        }

        if (!dataValeAseguradora.reembolso && !dataValeAseguradora.nombreArchivo && !erroUpload) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor introduzca la carta de aceptación o documento en garantía.",
            });
            erroUpload = true;
            return;
        }

        if (!erroUpload) {
            const listarValeProdEnData = await setListaInsumosOnCambioEstatusData();
            if (listarValeProdEnData) {
                setLoading(true);
                const res = await actualizarOCambiarEstatus({
                    ...cambioEstatusData,
                    valeProductoServicio: listarValeProdEnData,
                    importeCoaseguro: dataValeAseguradora.importeCoaseguro,
                    importeCoaseguroHospitalario: dataValeAseguradora.importeCoaseguroHospitalario,
                    importeDeducible: dataValeAseguradora.importeDeducible,
                    nombreArchivo: dataValeAseguradora.nombreArchivo,
                    mimeType: dataValeAseguradora.mimeType,
                    tamano: dataValeAseguradora.tamano,
                    inputStream: dataValeAseguradora.inputStream,
                    fechaTratamiento: dataValeAseguradora.fechaTratamiento,
                    comentarioEstatus: dataValeAseguradora.comentarioEstatus,
                    reembolso: dataValeAseguradora.reembolso,
                    necesitaAutorizacion: 0,
                    garantia: opcionGarantia,
                });

                if (res) {
                    setDataValeAseguradora(cleanDataValeAseguradora);
                    setLoading(false);
                    actualizarValeFiltrado(res.data);
                    setUISweetAlert({
                        ...UISweetAlert,
                        success_dlg: true,
                        dynamic_title: "Exito",
                        confirm_alert_status_change_aprobado_aseguradora: false,
                        dynamic_description: "Se registraron los cambios del estatus.",
                        redirect: true,
                        path: "/vales",
                    });
                } else {
                    const permiso = await seleccionarVale(valeSeleccionado.idVale, false);
                    setLoading(false);
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        confirm_alert_status_change: false,
                        confirm_alert_incompleto: false,
                        confirm_alert_completo: false,
                        dynamic_title: "Fallo",
                        dynamic_description: "AC1 - Error al subir los cambios, Verifique sus datos e inténtalo nuevamente por favor.",
                    });

                    delete cambioEstatusData.fechaTratamiento;
                    delete cambioEstatusData.comentarioEstatus;
                    delete cambioEstatusData.reembolso;
                    delete cambioEstatusData.importeCoaseguro;
                    delete cambioEstatusData.importeCoaseguroHospitalario;
                    delete cambioEstatusData.importeDeducible;
                    delete cambioEstatusData.nombreArchivo;
                    delete cambioEstatusData.mimeType;
                    delete cambioEstatusData.tamano;
                    delete cambioEstatusData.inputStream;
                }
            }
        }
    };

    const uploadReclamadoValeAseguradora = async () => {
        let erroUpload = false;

        //************************************************************************************************* 
        // ********************** SI EL VALE ES GARANTIA HJAY QUE GUARDAR LOS DATOSDE GARANTIA
        //*************************************************************************************************
        if(valeSeleccionado.garantia && cambioEstatusData.idEstatusNuevo==19 && valeSeleccionado.catTipovale.idCatTipoVale==2 ){
            let erroUpload = false;

            if (!dataValeAseguradora.reembolso && !dataValeAseguradora.nombreArchivo && !erroUpload) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Por favor introduzca la carta de aceptación o garantía.",
                });
                erroUpload = true;
                return;
            }

            if (!dataValeAseguradora.importeCoaseguro && !erroUpload) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Por favor introduzca el importe del coaseguro médico.",
                });
                erroUpload = true;
                return;
            }
            //if (valeSeleccionado.reembolso && !dataValeAseguradora.importeCoaseguroHospitalario && !erroUpload) {
            if (!dataValeAseguradora.importeCoaseguroHospitalario && !erroUpload) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Por favor introduzca el importe del coaseguro hospitalario.",
                });
                erroUpload = true;
                return;
            }
    
            if (!dataValeAseguradora.importeDeducible && !erroUpload) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Por favor introduzca el importe del deducible.",
                });
                erroUpload = true;
                return;
            }     
    
            if (!erroUpload) {
                const listarValeProdEnData = await setListaInsumosOnCambioEstatusData();
                if (listarValeProdEnData) {
                    setLoading(true);
                    const res = await actualizarOCambiarEstatus({
                        ...cambioEstatusData,
                        valeProductoServicio: listarValeProdEnData,
                        importeCoaseguro: dataValeAseguradora.importeCoaseguro,
                        importeCoaseguroHospitalario: dataValeAseguradora.importeCoaseguroHospitalario,
                        importeDeducible: dataValeAseguradora.importeDeducible,
                        nombreArchivo: dataValeAseguradora.nombreArchivo,
                        mimeType: dataValeAseguradora.mimeType,
                        tamano: dataValeAseguradora.tamano,
                        inputStream: dataValeAseguradora.inputStream,
                        fechaTratamiento: dataValeAseguradora.fechaTratamiento,
                        comentarioEstatus: dataValeAseguradora.comentarioEstatus,
                        reembolso: dataValeAseguradora.reembolso,
                        necesitaAutorizacion: 0,
                        garantia: opcionGarantia,
                        fechaReclamo: dataValeAseguradora.fechaReclamo,
                        folioReclamo: dataValeAseguradora.folioReclamo,                        
                    });
    
                    if (res) {
                        setDataValeAseguradora(cleanDataValeAseguradora);
                        setLoading(false);
                        actualizarValeFiltrado(res.data);
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: true,
                            dynamic_title: "Exito",
                            confirm_alert_status_change_aprobado_aseguradora: false,
                            dynamic_description: "Se registraron los cambios del estatus.",
                            redirect: true,
                            path: "/vales",
                        });
                    } else {
                        const permiso = await seleccionarVale(valeSeleccionado.idVale, false);
                        setLoading(false);
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            confirm_alert_status_change: false,
                            confirm_alert_incompleto: false,
                            confirm_alert_completo: false,
                            dynamic_title: "Fallo",
                            dynamic_description: "RVA - SError al subir los cambios, Verifique sus datos e inténtalo nuevamente por favor.",
                        });
    
                        delete cambioEstatusData.fechaTratamiento;
                        delete cambioEstatusData.comentarioEstatus;
                        delete cambioEstatusData.reembolso;
                        delete cambioEstatusData.importeCoaseguro;
                        delete cambioEstatusData.importeCoaseguroHospitalario;
                        delete cambioEstatusData.importeDeducible;
                        delete cambioEstatusData.nombreArchivo;
                        delete cambioEstatusData.mimeType;
                        delete cambioEstatusData.tamano;
                        delete cambioEstatusData.inputStream;
                    }
                }
            }
        }else{
            if (valeSeleccionado.reembolso && !dataValeAseguradora.nombreArchivo && !erroUpload) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Por favor introduzca la carta de acepctación.",
                });
                erroUpload = true;
                return;
            }
    
            if (valeSeleccionado.reembolso && !dataValeAseguradora.importeCoaseguro && !erroUpload) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Por favor introduzca el importe del coaseguro médico.",
                });
                erroUpload = true;
                return;
            }
            if (valeSeleccionado.reembolso && !dataValeAseguradora.importeCoaseguroHospitalario && !erroUpload) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Por favor introduzca el importe del coaseguro hospitalario.",
                });
                erroUpload = true;
                return;
            }
    
            if (valeSeleccionado.reembolso && !dataValeAseguradora.importeDeducible && !erroUpload) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Por favor introduzca el importe del deducible.",
                });
                erroUpload = true;
                return;
            }            
            if (valeSeleccionado.reembolso && !dataValeAseguradora.importeDeducible && !erroUpload) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Por favor introduzca el importe del deducible.",
                });
                erroUpload = true;
                return;
            }
    
            if (!erroUpload) {
                const listarValeProdEnData = await setListaInsumosOnCambioEstatusData();
                if (listarValeProdEnData) {
                    setLoading(true);
    
                    const res = await actualizarOCambiarEstatus({
                        ...cambioEstatusData,
                        valeProductoServicio: listarValeProdEnData,
                        importeCoaseguro:
                            valeSeleccionado.reembolso && dataValeAseguradora.cartaAceptacion ? dataValeAseguradora.importeCoaseguro : null,
                        importeCoaseguroHospitalario:
                            valeSeleccionado.reembolso && dataValeAseguradora.cartaAceptacion
                                ? dataValeAseguradora.importeCoaseguroHospitalario
                                : null,
                        importeDeducible:
                            valeSeleccionado.reembolso && dataValeAseguradora.cartaAceptacion ? dataValeAseguradora.importeDeducible : null,
                        nombreArchivo:
                            valeSeleccionado.reembolso && dataValeAseguradora.cartaAceptacion ? dataValeAseguradora.nombreArchivo : null,
                        mimeType: valeSeleccionado.reembolso && dataValeAseguradora.cartaAceptacion ? dataValeAseguradora.mimeType : null,
                        tamano: valeSeleccionado.reembolso && dataValeAseguradora.cartaAceptacion ? dataValeAseguradora.tamano : null,
                        inputStream: valeSeleccionado.reembolso && dataValeAseguradora.cartaAceptacion ? dataValeAseguradora.inputStream : null,
                        fechaReclamo: dataValeAseguradora.fechaReclamo,
                        folioReclamo: dataValeAseguradora.folioReclamo,
                        necesitaAutorizacion: 0,
                    });
                    if (res) {
                        setDataValeAseguradora(cleanDataValeAseguradora);
                        setLoading(false);
                        actualizarValeFiltrado(res.data);
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: true,
                            dynamic_title: "Exito",
                            confirm_alert_status_change_aprobado_aseguradora: false,
                            dynamic_description: "Se registraron los cambios del estatus.",
                            redirect: true,
                            path: "/vales",
                        });
                    } else {
                        const permiso = await seleccionarVale(valeSeleccionado.idVale, false);
                        setLoading(false);
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            confirm_alert_status_change: false,
                            confirm_alert_incompleto: false,
                            confirm_alert_completo: false,
                            dynamic_title: "Fallo",
                            dynamic_description: "P3 - Error al subir los cambios, Verifique sus datos e inténtalo nuevamente por favor.",
                        });
    
                        delete cambioEstatusData.importeCoaseguro;
                        delete cambioEstatusData.importeDeducible;
                        delete cambioEstatusData.nombreArchivo;
                        delete cambioEstatusData.mimeType;
                        delete cambioEstatusData.tamano;
                        delete cambioEstatusData.inputStream;
                        delete cambioEstatusData.fechaReclamo;
                        delete cambioEstatusData.cartaAceptacion;
                        delete cambioEstatusData.folioReclamo;
                        cambioEstatusData.idEstatusNuevo = 25;
                    }
                }
            }
        }
    };

    const insertarNuevoCobro = async () => {
        let nuevoCobro = {
            idCatTipoCobro: "",
            cantidadPagar: "",
            importe: "",
            iva: "",
        };

        dataValeAseguradora.cobrosAseguradora = dataValeAseguradora.cobrosAseguradora.concat(nuevoCobro);
        setDataValeAseguradora(dataValeAseguradora);
        refreshModalCobroCoaseguroReembolso();
    };

    const refreshModalCobroCoaseguroReembolso = async () => {
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_cobro_coaseguro_reembolso: false,
        });
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_cobro_coaseguro_reembolso: true,
        });
    };

    const eliminarCobroAnticipo = async () => {
        dataValeAseguradora.cobrosAseguradora = dataValeAseguradora.cobrosAseguradora.filter((cobros) => cobros != cobroEliminar);
        setDataValeAseguradora(dataValeAseguradora);
        refreshModalCobroCoaseguroReembolso();
        calcularRestantePagar();
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_eliminar_cobro: false,
            success_dlg: true,
            dynamic_title: "Exito!",
            dynamic_description: "Cobro ha sido eliminado",
        });
    };

    const calcularRestantePagar = async (cobro) => {
        let sumaTotalCobros = 0;
        dataValeAseguradora.cobrosAseguradora.map((cobroAsg) => {
            if (!cobro || cobro != cobroAsg) {
                sumaTotalCobros += Number(cobroAsg.cantidadPagar);
            }
        });

        let diferencia = (dataValeAseguradora.importeTotalAPagar - sumaTotalCobros - (cobro ? cobro.cantidadPagar : 0.0)).toFixed(2);
        if (cobro && diferencia < 0) {
            diferencia = (dataValeAseguradora.importeTotalAPagar - sumaTotalCobros).toFixed(2);
            cobro.cantidadPagar = 0;
            cobro.iva = 0;
            cobro.importe = 0;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "La cantidad introducida es mayor a la cantidad restante a pagar",
            });
        }
        dataValeAseguradora.importeDiferenciaAPagar = diferencia;

        setDataValeAseguradora(dataValeAseguradora);
    };

    const changeTipoPagoCobro = async (e, cobro) => {
        cobro.idCatTipoCobro = e.target.value;
        setDataValeAseguradora(dataValeAseguradora);
        refreshModalCobroCoaseguroReembolso();
    };

    const changeCantidadAPagarCobro = async (e, cobro) => {
        let value = e.target.value;
        value = value.replace("$", "").replace(/[^0-9\.]/g, "");

        cobro.cantidadPagar = value;
        cobro.iva = (value * dataValeAseguradora.porcentajeIva).toFixed(2);
        cobro.importe = (value * dataValeAseguradora.porcentajeImporte).toFixed(2);
        setDataValeAseguradora(dataValeAseguradora);
        refreshModalCobroCoaseguroReembolso();
        calcularRestantePagar(cobro);
    };

    const uploadCobroCoaseguroReembolso = async () => {
        let erroUpload = false;

        if (dataValeAseguradora.cobrosAseguradora.length <= 0 && !erroUpload) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, introduzca los cobros para cubrir el pago.",
            });
            erroUpload = true;
            return;
        }

        if (dataValeAseguradora.cobrosAseguradora.length > 0 && !erroUpload) {
            dataValeAseguradora.cobrosAseguradora.map((cobro) => {
                if (!cobro.idCatTipoCobro && !erroUpload) {
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Validación",
                        dynamic_description: "Por favor, introduzca la forma de pago para todos los cobros",
                    });
                    erroUpload = true;
                    return;
                }

                if (!cobro.idCatCliente && !erroUpload) {
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Validación",
                        dynamic_description: "Por favor, introduzca el cliente para todos los cobros.",
                    });
                    erroUpload = true;
                    return;
                }

                if ((!cobro.cantidadPagar || Number(cobro.cantidadPagar) <= 0) && !erroUpload) {
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Validación",
                        dynamic_description: "Por favor, introduzca la cantidad a pagar para todos los cobros.",
                    });
                    erroUpload = true;
                    return;
                }
            });
        }

        if (dataValeAseguradora.importeDiferenciaAPagar > 0 && !erroUpload) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: `El total a pagar no se ha cubierto por completo, el restante a pagar es de $${dataValeAseguradora.importeDiferenciaAPagar.toFixed(
                    2
                )}`,
            });
            erroUpload = true;
            return;
        }

        if (!erroUpload) {
            // cambioEstatusData.cobrosAseguradora = dataValeAseguradora.cobrosAseguradora;
            // setcambioEstatusData(cambioEstatusData)

            const listarValeProdEnData = await setListaInsumosOnCambioEstatusData();
            if (listarValeProdEnData) {
                setLoading(true);

                const res = await actualizarOCambiarEstatus({
                    ...cambioEstatusData,
                    valeProductoServicio: listarValeProdEnData,
                    cobrosAseguradora: dataValeAseguradora.cobrosAseguradora,
                    necesitaAutorizacion: 0,
                });
                if (res) {
                    setDataValeAseguradora(cleanDataValeAseguradora);
                    setLoading(false);
                    actualizarValeFiltrado(res.data);
                    setUISweetAlert({
                        ...UISweetAlert,
                        success_dlg: true,
                        dynamic_title: "Exito",
                        confirm_alert_status_change_aprobado_aseguradora: false,
                        dynamic_description: "Se registraron los cambios del estatus.",
                        redirect: true,
                        path: "/vales",
                    });
                } else {
                    const permiso = await seleccionarVale(valeSeleccionado.idVale, false);
                    setLoading(false);
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        confirm_alert_status_change: false,
                        confirm_alert_incompleto: false,
                        confirm_alert_completo: false,
                        dynamic_title: "Fallo",
                        dynamic_description: "Error al subir los cambios, Inténtalo por favor más tarde.",
                    });

                    delete cambioEstatusData.cobrosAseguradora;
                }
            }
        }
    };

    const changeCliente = async (e, cobro) => {
        cobro.idCatCliente = e.target.value;
        setDataValeAseguradora(dataValeAseguradora);
        refreshModalCobroCoaseguroReembolso();
    };

    const checarPermisoAcceso = (idCatEstatusValeActual) => {
        switch (idCatEstatusValeActual) {
            //Solicitado
            case 1:
            case 11:
                if (!(usuario.authorities.includes("ROLE_FARMACIA") || usuario.authorities.includes("ROLE_ASEGURADORAS"))) {
                    accessoDenegado();
                }
                break;
            case 3:
            case 4:
            case 13:
            case 14:
                if (!usuario.authorities.includes("ROLE_FARMACIA")) {
                    accessoDenegado();
                }
                break;
            //Cotizado
            case 2:
                if (!usuario.authorities.includes("ROLE_RECEPCION")) {
                    accessoDenegado();
                }
                break;
            case 12:
            case 18:
            case 19:
            case 20:
                if (!usuario.authorities.includes("ROLE_ASEGURADORAS")) {
                    accessoDenegado();
                }
                break;
            //Completo
            case 5:
            case 15:
                if (!usuario.authorities.includes("ROLE_FARMACIA") && !usuario.authorities.includes("ROLE_CENTRO_MEZCLAS")) {
                    accessoDenegado();
                }
                break;
            //Por aplicar
            case 16:
                let pagoCoaseguroReembolso = false;
                if (
                    (valeSeleccionado.importeDeducible != null && valeSeleccionado.importeDeducible > 0) ||
                    (valeSeleccionado.importeCoaseguro != null && valeSeleccionado.importeCoaseguro > 0) ||
                    (valeSeleccionado.reembolso != null && valeSeleccionado.reembolso == true)
                ) {
                    pagoCoaseguroReembolso = true;
                }

                if (!pagoCoaseguroReembolso && !usuario.authorities.includes("ROLE_QUIMIO")) {
                    accessoDenegado();
                } else if (pagoCoaseguroReembolso && !usuario.authorities.includes("ROLE_RECEPCION")) {
                    accessoDenegado();
                }
                break;
            case 6:
                //Hay que checar que tipo de medico es, para saber si ya termino el vale o vamos a cobrado
                if (
                    (valeSeleccionado.catMedicos.catTipoMedico.idCatTipoMedico == 1 && !usuario.authorities.includes("ROLE_QUIMIO")) ||
                    (valeSeleccionado.catMedicos.catTipoMedico.idCatTipoMedico == 2 && !usuario.authorities.includes("ROLE_FARMACIA"))
                ) {
                    accessoDenegado();
                }
                break;
            //Devuelto
            //Cancelado
            // Aplicado de
            case 7:
            case 17:
            case 10:
            case 22:
            case 8:
            case 21:
                accessoDenegado();
                break;
            //Cobrado
            case 9:
                //Hay que checar que tipo de medico es, para saber si el vale se acaba en cobrado o se acaba en
                if (valeSeleccionado.catMedicos.catTipoMedico.idCatTipoMedico == 2 && !usuario.authorities.includes("ROLE_QUIMIO")) {
                    accessoDenegado();
                }
                break;
            //Ya se realizaron los cobros de coaseguro o de reembolso
            case 23:
            case 24:
                if (!usuario.authorities.includes("ROLE_QUIMIO")) {
                    accessoDenegado();
                }
                break;
            default:
                break;
        }
    };

    const checarLotesDisponiblesParaProductos = () => {
        //Mientras no tengamos lotes disponibles, podriamos avanzar de en recoleccion
        let lotesDisponibles = false;
        valeSeleccionado.valeproductoservicios.map((producto) => {
            if (producto.catProductos != null && !producto.completed && producto.catProductos.inventarios.length > 0) {

                if(producto.catServicios != null){
                    producto.catProductos.inventarios.map((inventarioProducto) => {
                        if (inventarioProducto.piezas > 0 && !lotesDisponibles) {
                            lotesDisponibles = true;
                            setUISweetAlert({
                                ...UISweetAlert,
                                error_dlg: true,
                                dynamic_title: "Validación",
                                dynamic_description: `El producto ${producto.catProductos.nombreComercial}, tiene lotes aun con piezas disponibles para ser asignadas.`,
                            });
                        }
                    });
                }
            }
        });

        return lotesDisponibles;
    };

    useEffect(() => {
        getProximosEstatusVale(idVale);
        getEstatusCancelado(idVale);
        listarCatTipoCobros();
        listarClientes();
        if (valeSeleccionado) {
            if(valeSeleccionado.necesitaAutorizacion==1){
                setAutorizacionlocal(true);
                global.cambio = true;                
            }

            let idCatEstatusValeActual =
                valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale;
            checarPermisoAcceso(idCatEstatusValeActual);

            cambioEstatusData.idVale = valeSeleccionado.idVale;
            cambioEstatusData.esquemaDias = valeSeleccionado.esquemaDias != null? valeSeleccionado.esquemaDias : 0;
            setcambioEstatusData(cambioEstatusData);

            //Queremos saber si el vale tiene piezasUtilizadas, si es el caso, no se puede devolver ni, cancelar ya que se tienen que cobrar
            //las piezasUtilizadas
            habilitarCancelarDevolverVale(idCatEstatusValeActual);

            switch (idCatEstatusValeActual) {
                case 12:
                    if (usuario.authorities.includes("ROLE_FARMACIA")) {
                        //Si entra farmacia, cuando esta en SOLICITADO, es para completar los insumos de la tabla o para poder,
                        //hace modificaciones de los insumos, donde solo puede guardar los cambios y no hacer el cambio de estatus
                        setHiddenUploadEstatus(true);
                    }
                    if (usuario.authorities.includes("ROLE_ASEGURADORAS")) {
                        //Si entra farmacia, cuando esta en SOLICITADO, es para completar los insumos de la tabla o para poder,
                        //hace modificaciones de los insumos, donde solo puede guardar los cambios y no hacer el cambio de estatus
                        setHiddenFechaTratamiento(false);
                    }
                    
                    break;
                case 2:
                    setHiddenGuardarCambios(true);
                    setHiddenFechaTratamiento(false);
                    break;
                //Recoleccion
                case 4:
                case 14:
                    setHiddenUploadEstatus(true);
                    setHiddenUploadEstatusCompleto(false);
                    break;

                //Completo
                case 5:
                case 15:
                    if (usuario.authorities.includes("ROLE_FARMACIA")) {
                        //Si entra farmacia, cuando esta en COMPLETO, es solo porque se hizo una entrega parcial y solo puede agregar lotes
                        //pero no puede cambiar el estatus, solo guardar los cambios
                        setHiddenUploadEstatus(true);
                    }
                    if (usuario.authorities.includes("ROLE_CENTRO_MEZCLAS")) {
                        setHiddenUploadEstatus(true);
                        setHiddenUploadEstatusAplicar(false);
                    }
                    break;
                // case 8:
                //     setHiddenGestionCobros(false);
                //     setHiddenUploadEstatus(true);
                //     break;
                //Cobrado
                case 21:
                    setHiddenUploadEstatus(true);
                    setHiddenNuevoEstatus(true);
                    setHiddenGuardarCambios(true);
                    break;
                case 7:
                case 17:
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "El vale ha sido devuelto, no se puede cambiar el estatus.",
                        redirect: true,
                        path: "/vales",
                    });
                    break;
                case 10:
                case 22:
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "El vale ha sido cancelado, no se puede cambiar el estatus.",
                        redirect: true,
                        path: "/vales",
                    });
                    break;

                default:
                    break;
            }
        }

        if (proximosEstatusVale.length == 1) {
            let estatusValeProx = proximosEstatusVale[0];

            // if(estatusValeProx.idCatEstatusVale == 19){
            //     setHiddenFolioReclamo(false);
            // }

            if (estatusValeProx.idCatEstatusVale == 20) {
                setHiddenValeAceptado(false);
                cambioEstatusData.fechaAceptacion = valeSeleccionado.fechaAceptacion?valeSeleccionado.fechaAceptacion: new Date().toISOString().slice(0, 10);
                cambioEstatusData.fechaEstimadaPago = valeSeleccionado.fechaEstimadaPago?valeSeleccionado.fechaEstimadaPago:  new Date().toISOString().slice(0, 10);
                setcambioEstatusData(cambioEstatusData);
            }

            if (estatusValeProx.idCatEstatusVale == 21) {
                setHiddenInformacionDeposito(false);
                cambioEstatusData.idTipoCobro = 2;
                setcambioEstatusData(cambioEstatusData);
            }

            if (estatusValeProx.idCatEstatusVale == 9) {
                setHiddenGestionCobros(false);
                setHiddenUploadEstatus(true);
            }

            cambioEstatusData.idEstatusNuevo = estatusValeProx.idCatEstatusVale;
            setcambioEstatusData(cambioEstatusData);
        }
    }, [valeSeleccionado]);

    const changeCotizacion = (e) => {
        const {name, value} = e.target;
        setDatosCotizacion({
            ...datosCotizacion,
            [name]: value,
        });
    };

    const checkChanges = () => {
        if (changedProducts) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_changed_product: true,
            })
        } else {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_save_changes: true,
            })
        }
    }

    const alertaAutorizar = () => {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_Solcitar_autorizacion: true,
            })
    }
    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Cambio de Estatus</h3>
            <Card className="mt-10">
                <CardBody>
                    <Row>
                        <Col xl={6} lg={12} md={12}>
                            <Row className="form-group">
                                <label className="col-sm-4 col-form-label">Estatus Actual</label>
                                <Col sm={7}>
                                    <input
                                        disabled
                                        className="form-control"
                                        type="text"
                                        value={
                                            valeSeleccionado
                                                ? valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.estatusVale
                                                : ""
                                        }
                                    />
                                </Col>
                            </Row>
                            {usuario && usuario.authorities.includes("ROLE_ASEGURADORAS") && !usuario.authorities.includes("ROLE_CONTABILIDAD") ? (
                            <Row className="form-group">
                                <label className="col-sm-4 col-form-label">Esquema TX (días)</label>
                                <Col sm={7}>
                                    <input
                                        min="0"
                                        name = "esquemaDias"
                                        type="number"
                                        value={
                                            cambioEstatusData.esquemaDias? cambioEstatusData.esquemaDias : "0"
                                        }
                                        onChange={simpleOnChange}
                                        className="form-control-inputs-vales text-center"
                                    />
                                </Col>
                            </Row>     
                            ):null}                       
                            <Row className="form-group" hidden={hiddenFechaTratamiento}>
                                <label className="col-sm-4 col-form-label">Fecha de Tratamiento
                                <SpanError>*</SpanError></label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="fechaTratamiento"
                                        id="fechaTratamiento"
                                        onChange={simpleOnChange}
                                        value={cambioEstatusData.fechaTratamiento ? cambioEstatusData.fechaTratamiento : ""}
                                    />
                                </Col>
                            </Row>
                            {!hiddenValeAceptado ? (
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha de Factura/Tramite</label>
                                    <Col sm={7}>
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="fechaAceptacion"
                                            id="fechaAceptacion"
                                            onChange={simpleOnChange}
                                            disabled={true}
                                            value={cambioEstatusData.fechaAceptacion ? cambioEstatusData.fechaAceptacion : ""}
                                        />
                                    </Col>
                                </Row>
                            ) : null}
                        </Col>
                        <Col xl={6} lg={12} md={12}>
                            <Row className="form-group" hidden={hiddenNuevoEstatus}>
                                <label className="col-sm-4 col-form-label">Estatus Nuevo</label>
                                <Col sm={7}>
                                    <select
                                        className="form-control"
                                        name="idEstatusNuevo"
                                        id="idEstatusNuevo"
                                        onChange={changeEstatus}
                                        defaultValue={""}
                                        value={cambioEstatusData.idEstatusNuevo}>
                                        <option value="" disabled key={""}>
                                            Seleccione una opción
                                        </option>
                                        {proximosEstatusVale.map((estatus) => {
                                            return (
                                                <>
                                                    <option value={estatus.idCatEstatusVale}>{estatus.estatusVale}</option>
                                                </>
                                            );
                                        })}
                                    </select>
                                </Col>
                            </Row>
                            <Row className="form-group" hidden={hiddenFileUpload}>
                                <label className="col-sm-4 col-form-label">{nombreArchivo ? nombreArchivo : "Archivo"}</label>
                                <Col sm={7}>
                                    <input className="form-control" type="file" onChange={uploadFile}/>
                                </Col>
                            </Row>
                            {!hiddenValeAceptado ? (
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha Estimada de Pago</label>
                                    <Col sm={7}>
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="fechaEstimadaPago"
                                            id="fechaEstimadaPago"
                                            onChange={simpleOnChange}
                                            value={cambioEstatusData.fechaEstimadaPago ? cambioEstatusData.fechaEstimadaPago : ""}
                                        />
                                    </Col>
                                </Row>
                            ) : null}
                        </Col>
                    </Row>

                    {!hiddenInformacionDeposito ? (
                        <div>
                            <Row>
                                <Col style={{textAlign: "center"}}>
                                    <h4 className="flex">Información Depósito</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} lg={12} md={12} className="mt-3">
                                    <Row className="form-group">
                                        <label className="col-sm-4 col-form-label">Fecha Depósito</label>
                                        <Col sm={7}>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="fechaDeposito"
                                                onChange={simpleOnChange}
                                                value={cambioEstatusData.fechaDeposito ? cambioEstatusData.fechaDeposito : ""}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <label className="col-sm-4 col-form-label">Importe Depósito</label>
                                        <Col sm={7}>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="importeDeposito"
                                                style={{textAlign: "right"}}
                                                onChange={(e) => onChangeImporteDeposito(e)}
                                                value={cambioEstatusData.importeDeposito ? `$${cambioEstatusData.importeDeposito}` : "$"}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    <Row>
                        <Col> 
                            {!hiddenUploadEstatus && !global.cambio? (
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                    type="button"
                                    // onClick={uploadCambioEstatus}
                                    onClick={confirmUploadCambioEstatus}>
                                    Cambio de Estatus
                                </button>
                            ) : null}

                            {global.cambio?  (
                                <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                // onClick={guardarCambios}
                                onClick={(e) =>
                                    alertaAutorizar()
                                }>
                                Solicitar Autorización
                            </button>
                            ) : null}                            

                            {!hiddenUploadEstatusCompleto ? (
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                    type="button"
                                    onClick={checkIfCompleted}>
                                    Cambio de Estatus (COMPLETO)
                                </button>
                            ) : null}

                            {!hiddenUploadEstatusAplicar ? (
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                    type="button"
                                    onClick={checkIfCompletedPorAplicar}>
                                    Cambio de Estatus (POR APLICAR)
                                </button>
                            ) : null}

                            {!hiddenGuardarCambiios ? (
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                    // onClick={guardarCambios}
                                    onClick={(e) =>
                                        checkChanges()
                                    }>
                                    Guardar Cambios
                                </button>
                            ) : null}

                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                onClick={checkImprimirCotizacion}>
                                Imprimir Cotización
                            </button>
                            <button
                                hidden={hiddenCancelarVale}
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                // onClick={changeEstatusCancelado}
                                onClick={(e) =>
                                    setUISweetAlert({
                                        ...UISweetAlert,
                                        confirm_alert_cancelar_vale: true,
                                    })
                                }>
                                Cancelar Vale
                            </button>
                            {!hiddenDevolverVale ? (
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                    onClick={(e) =>
                                        setUISweetAlert({
                                            ...UISweetAlert,
                                            confirm_alert_devolver_vale: true,
                                        })
                                    }>
                                    Devolver Vale
                                </button>
                            ) : null}
                            {!hiddenGestionCobros ? (
                                <Link
                                    to={`/vales/cobro/${idVale}`}
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                    style={{
                                        background: "#f93",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}>
                                    Registrar Cobro
                                </Link>
                            ) : null}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            {/* TODO: ACA ESTA EL CAMBIO DONDE DEBEMOS VER LO DE LA FECHAS  */ }
            {UISweetAlert.confirm_alert_status_change ? (
                <SweetAlert
                    title="¿Desea hacer el cambio de estatus?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => uploadCambioEstatus()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_status_change: false,
                        })
                    }>
                    Se guardará la información y se hará el cambio de estatus
                    {valeSeleccionado &&
                    valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale == 19 ? (
                        <Row className="mt-4">
                            <label className="col-sm-3 col-form-label">Notas</label>
                            <Col sm={6}>
                            <textarea
                                className="form-control"
                                name="comentarioEstatus"
                                onChange={simpleOnChange}
                                value={cambioEstatusData.comentarioEstatus ? cambioEstatusData.comentarioEstatus : ""}
                            />
                            </Col>
                        </Row>
                    ) : null}
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_status_change_enrecoleccion ? (
                <SweetAlert
                    title="¿Desea hacer el cambio de estatus?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => uploadCambioEstatus()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_status_change_enrecoleccion: false,
                        })
                    }>
                    Se guardará la información y se hará el cambio de estatus, todos los productos que no tengan lotes asignados se le
                    notificará a compras como productos pendientes de comprar.
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_save_changes ? (
                <SweetAlert
                    title="¿Desea guardar sus cambios?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => guardarCambios()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_save_changes: false,
                        })
                    }>
                    Se guardará la información del estatus
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_Solcitar_autorizacion ? (
                <SweetAlert
                    title="¿Desea solicitar la autorización?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => solicitarAutorizacion()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_Solcitar_autorizacion: false,
                        })
                    }>
                    Se guardará la información del vale
                </SweetAlert>
            ) : null}          

            {/* TODO: ACA ETSA PARA PASAR DE COTIZADO A APROBADO */}
            {UISweetAlert.confirm_alert_status_change_aprobado_aseguradora ? (
                <SweetAlert
                    title="¿Desea hacer el cambio de estatus?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    style={{
                        width: width && width < 851 ? "100%" : "25%",
                    }}
                    closeOnClickOutside={false}
                    onConfirm={() => uploadAprobarValeAseguradora()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_status_change_aprobado_aseguradora: false,
                        });
                        setDataValeAseguradora(cleanDataValeAseguradora);
                    }}>
                    <div className="mt-2">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={dataValeAseguradora.reembolso}
                                    onChange={onChangeDataValeAseguradora}
                                    name="reembolso"
                                    color="primary"
                                />
                            }
                            label="Reembolso"
                        />
                    </div>
                    <div className="mt-2">
                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Fecha de Tratamiento</label>
                            <Col sm={7}>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={onChangeDataValeAseguradora}
                                    name="fechaTratamiento"
                                    value={dataValeAseguradora.fechaTratamiento}
                                />
                            </Col>
                        </Row>
                        {dataValeAseguradora.reembolso ? (
                            <Row>
                                <label className="col-sm-4 col-form-label">Notas</label>
                                <Col sm={7}>
                                <textarea
                                    className="form-control"
                                    name="comentarioEstatus"
                                    onChange={onChangeDataValeAseguradora}
                                    value={dataValeAseguradora.comentarioEstatus ? dataValeAseguradora.comentarioEstatus : ""}
                                />
                                </Col>
                            </Row>
                        ) : (
                            <>
                                <div hidden={!opcionGarantiaActivado} className="mt-2">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={opcionGarantia}
                                                onChange={onChangegarantiaOpcion}
                                                color="primary"
                                            />
                                        }
                                        label="Garantía"
                                    />
                                </div>  
                                    <Row className="form-group">
                                        <label className="col-sm-4 col-form-label">{opcionGarantia? "Documento en Garantía" : "Carta de Aceptación" }</label>
                                        <Col sm={7}>
                                            <input className="form-control" type="file" onChange={changeCartaAceptacion} id="cartaAceptacion"/>
                                        </Col>
                                    </Row>
                                    <Row hidden={opcionGarantia}>
                                        <label className="col-sm-4 col-form-label">Importe Coaseguro Médico</label>
                                        <Col sm={7}>
                                            <NumericFormat
                                                // disabled={true}
                                                id="importeCoaseguro"
                                                name="importeCoaseguro" 
                                                thousandSeparator={true}
                                                className="form-control"
                                                decimalScale={2}
                                                allowNegative={false}
                                                allowLeadingZeros={false}
                                                displayType="input"
                                                value={dataValeAseguradora.importeCoaseguro ? `$${dataValeAseguradora.importeCoaseguro}` : "$"}
                                                onChange={onChangeImportesAprobarValeAseguradora}
                                                // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                            />                                          
                                        </Col>
                                    </Row>
                                    <Row hidden={opcionGarantia}>
                                        <label className="col-sm-4 col-form-label">Importe Coaseguro Hospitalario</label>
                                        <Col sm={7}>
                                            <NumericFormat
                                                // disabled={true}
                                                id="importeCoaseguroHospitalario"
                                                name="importeCoaseguroHospitalario" 
                                                thousandSeparator={true}
                                                className="form-control"
                                                decimalScale={2}
                                                allowNegative={false}
                                                allowLeadingZeros={false}
                                                displayType="input"
                                                value={
                                                    dataValeAseguradora.importeCoaseguroHospitalario
                                                        ? `$${dataValeAseguradora.importeCoaseguroHospitalario}`
                                                        : "$"
                                                }
                                                onChange={onChangeImportesAprobarValeAseguradora}
                                                // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                            />      
                                        </Col>
                                    </Row>
                                    <Row hidden={opcionGarantia}>
                                        <label className="col-sm-4 col-form-label">Importe Deducible</label>
                                        <Col sm={7}>
                                            <NumericFormat
                                                // disabled={true}
                                                id="importeDeducible"
                                                name="importeDeducible" 
                                                thousandSeparator={true}
                                                className="form-control"
                                                decimalScale={2}
                                                allowNegative={false}
                                                allowLeadingZeros={false}
                                                displayType="input"
                                                value={dataValeAseguradora.importeDeducible ? `$${dataValeAseguradora.importeDeducible}` : "$"}
                                                onChange={onChangeImportesAprobarValeAseguradora}
                                                // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                            />                                           
                                        </Col>
                                    </Row>
                              
                            </>
                        )}
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_cobro_coaseguro_reembolso ? (
                <SweetAlert
                    title="¿Desea hacer el cambio de estatus?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "70%",
                    }}
                    onConfirm={() => uploadCobroCoaseguroReembolso()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_cobro_coaseguro_reembolso: false,
                        });
                        setDataValeAseguradora(cleanDataValeAseguradora);
                    }}>
                    <div className="mt-2 overflow-x-auto">
                        <Row hidden={cambioEstatusData.idEstatusNuevo == 24 ? true : false}>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-4">
                                    <label className="col-sm-4 col-form-label">Coaseguro Médico</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={valeSeleccionado.importeCoaseguro ? FormatNumber(valeSeleccionado.importeCoaseguro) : `$0`}
                                            // value={valeSeleccionado.importeCoaseguro ? `$${valeSeleccionado.importeCoaseguro}` : `$0`}
                                            disabled={true}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-4">
                                    <label className="col-sm-4 col-form-label">Restante a Pagar</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={
                                                dataValeAseguradora.importeDiferenciaAPagar
                                                    ? FormatNumber(dataValeAseguradora.importeDiferenciaAPagar)
                                                    : `$0`
                                            }
                                            // value={dataValeAseguradora.importeDiferenciaAPagar ? `$${dataValeAseguradora.importeDiferenciaAPagar}` : `$0`}
                                            disabled={true}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-4">
                                    <label className="col-sm-4 col-form-label">Deducible</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={valeSeleccionado.importeDeducible ? FormatNumber(valeSeleccionado.importeDeducible) : `$0`}
                                            // value={valeSeleccionado.importeDeducible ? `$${valeSeleccionado.importeDeducible}` : `$0`}
                                            disabled={true}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-4">
                                    <label className="col-sm-4 col-form-label">Total a pagar</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={dataValeAseguradora.importeTotalAPagar ? FormatNumber(dataValeAseguradora.importeTotalAPagar) : `$0`}
                                            // value={dataValeAseguradora.importeTotalAPagar ? `$${dataValeAseguradora.importeTotalAPagar}` : `$0`}
                                            disabled={true}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group mt-4">
                                    <label className="col-sm-4 col-form-label">Coaseguro Hospitalario</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={
                                                valeSeleccionado.importeCoaseguroHospitalario
                                                    ? FormatNumber(valeSeleccionado.importeCoaseguroHospitalario)
                                                    : `$0`
                                            }
                                            // value={valeSeleccionado.importeCoaseguro ? `$${valeSeleccionado.importeCoaseguro}` : `$0`}
                                            disabled={true}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="form-group mt-2 ml-2 mr-2">
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mb-4"
                                onClick={() => insertarNuevoCobro()}>
                                Insertar Cobro
                            </button>

                            <table className="table table-hover table-centered table-wrap mb-0">
                                <thead>
                                <tr>
                                    <th scope="col">
                                        Forma de<br></br>Pago
                                    </th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col">
                                        Cantidad a<br></br>Pagar
                                    </th>
                                    <th scope="col">Importe</th>
                                    <th scope="col">IVA</th>
                                    <th scope="col">Eliminar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dataValeAseguradora.cobrosAseguradora.map((cobro) => {
                                    return (
                                        <>
                                            <tr key={cobro}>
                                                <td>
                                                    <select
                                                        className="form-control"
                                                        name="idCatTipoCobro"
                                                        onChange={(e) => changeTipoPagoCobro(e, cobro)}
                                                        value={cobro.idCatTipoCobro}>
                                                        <option disabled selected value="">
                                                            Seleccione una opción
                                                        </option>
                                                        {tipoCobros.map((cobro) => {
                                                            return (
                                                                <>
                                                                    <option value={cobro.idCatTipoCobro}>{cobro.tipoCobro}</option>
                                                                </>
                                                            );
                                                        })}
                                                    </select>
                                                </td>
                                                <td>
                                                    <select
                                                        className="form-control-select"
                                                        name="idCatCliente"
                                                        onChange={(e) => changeCliente(e, cobro)}
                                                        value={cobro.idCatCliente}>
                                                        <option disabled selected value="">
                                                            Seleccione una opción
                                                        </option>
                                                        {clientes.map((cliente) => {
                                                            return (
                                                                <>
                                                                    <option
                                                                        value={
                                                                            cliente.idCatCliente
                                                                        }>{`${cliente.catPersona.nombres} ${cliente.catPersona.apellidoPaterno} ${cliente.catPersona.apellidoMaterno}`}</option>
                                                                </>
                                                            );
                                                        })}
                                                    </select>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="cantidadPagar"
                                                        onChange={(e) => changeCantidadAPagarCobro(e, cobro)}
                                                        value={cobro.cantidadPagar ? `$${cobro.cantidadPagar}` : `$`}
                                                        autoComplete="off"
                                                    />
                                                </td>
                                                <td>{cobro.importe ? FormatNumber(cobro.importe) : `$0`}</td>
                                                {/* <td>{cobro.importe ? `$${cobro.importe}` : `$0`}</td> */}
                                                <td>{cobro.iva ? FormatNumber(cobro.iva) : `$0`}</td>
                                                {/* <td>{cobro.iva ? `$${cobro.iva}` : `$0`}</td> */}
                                                <td>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setCobroEliminar(cobro);
                                                            setUISweetAlert({
                                                                ...UISweetAlert,
                                                                confirm_alert_eliminar_cobro: true,
                                                            });
                                                        }}
                                                        className="btn btn-primary-orange-umo w-md waves-effect waves-light flex  hover:text-white">
                                                        Eliminar
                                                    </button>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                                </tbody>
                            </table>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_eliminar_cobro ? (
                <SweetAlert
                    title="¿Desea eliminar el cobro?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarCobroAnticipo()}
                    onCancel={() => {
                        setCobroEliminar(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_eliminar_cobro: false,
                        });
                    }}>
                    Se perderán todos los cambios realizados en el lote
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_incompleto ? (
                <SweetAlert
                    title="El Vale está Incompleto"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => entregaNotificarACompras()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_incompleto: false,
                        })
                    }>
                    Se guardará la información, se hará una entrega parcial a centro de mezclas y se le notificará a compras de los
                    productos faltantes para completar el vale.
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_completo ? (
                <SweetAlert
                    title={UISweetAlert.dynamic_title}
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => uploadCambioEstatus()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_completo: false,
                        })
                    }>
                    Se guardará la información y se hará el cambio de estatus
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_cancelar_vale ? (
                <SweetAlert
                    title="¿Desea cancelar el vale?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => changeEstatusCancelado()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_cancelar_vale: false,
                        })
                    }>
                    <div>
                        <Row>
                            <Col>Llene por favor la siguiente información</Col>
                        </Row>

                        <Row className="form-group mt-2">
                            <label className="col-sm-4 col-form-label">Motivo</label>
                            <Col sm={7}>
                <textarea
                    rows={6}
                    className="form-control"
                    name="comentarioEstatus"
                    value={cancelarDevolverVale.comentarioEstatus}
                    onChange={onChangeCancelarDevolverVale}
                />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Fecha Cancelación</label>
                            <Col sm={7}>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    name="fechaCancelacion"
                                    value={fechaActual}
                                />
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_devolver_vale ? (
                <SweetAlert
                    title="¿Desea devolver el vale?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => changeEstatusDevuelto()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_devolver_vale: false,
                        })
                    }>
                    <div>
                        <Row>
                            <Col>Llene por favor la siguiente información</Col>
                        </Row>

                        <Row className="form-group mt-2">
                            <label className="col-sm-4 col-form-label">Motivo</label>
                            <Col sm={7}>
                                <textarea
                                    rows={6}
                                    className="form-control"
                                    name="comentarioEstatus"
                                    value={cancelarDevolverVale.comentarioEstatus}
                                    onChange={onChangeCancelarDevolverVale}
                                />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Fecha Devolución</label>
                            <Col sm={7}>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="fechaDevolucion"
                                    value={cancelarDevolverVale.fechaDevolucion}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}
            {/*TODO: ACA ES DOND PONEMOS LOS CAMBIOS SI ES GARANTIA*/}
            {UISweetAlert.confirm_alert_status_reclamado_aseguradora ? (
                <SweetAlert
                    title="¿Desea hacer el cambio de estatus?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => uploadReclamadoValeAseguradora()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_status_reclamado_aseguradora: false,
                        });
                        setDataValeAseguradora(cleanDataValeAseguradora);
                    }}>
                    <Row hidden={!valeSeleccionado.reembolso} className="form-group mt-2">
                        <Col>
                            Solo se cambia a reclamado el vale si le llego la carta de aceptación, de lo contrario, se factura el vale para el
                            cliente.
                        </Col>
                    </Row>
                    <Row className="form-group mt-2">
                        <label className="col-sm-4 col-form-label">Fecha Inicio de Trámite</label>
                        <Col sm={7}>
                            <input
                                type="date"
                                className="form-control"
                                onChange={onChangeDataValeAseguradora}
                                name="fechaReclamo"
                                value={dataValeAseguradora.fechaReclamo}
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <label className="col-sm-4 col-form-label">Folio Reclamo / Folio Factura</label>
                        <Col sm={7}>
                            <input
                                type="text"
                                className="form-control"
                                onChange={onChangeDataValeAseguradora}
                                name="folioReclamo"
                                value={dataValeAseguradora.folioReclamo}
                            />
                        </Col>
                    </Row>
                    {valeSeleccionado.reembolso ? (
                        <>
                            <Row className="form-group mt-2">
                                <label className="col-sm-4 col-form-label">Carta de Aceptación</label>
                                <Col sm={7}>
                                    <input className="form-control" type="file" onChange={changeCartaAceptacion} id="cartaAceptacion"/>
                                </Col>
                            </Row>
                            <Row>
                                <label className="col-sm-4 col-form-label">Importe Coaseguro Médico</label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="importeCoaseguro"
                                        onChange={onChangeImportesAprobarValeAseguradora}
                                        value={dataValeAseguradora.importeCoaseguro ? `$${dataValeAseguradora.importeCoaseguro}` : "$"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <label className="col-sm-4 col-form-label">Importe Coaseguro Hospitalario</label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="importeCoaseguroHospitalario"
                                        onChange={onChangeImportesAprobarValeAseguradora}
                                        value={
                                            dataValeAseguradora.importeCoaseguroHospitalario
                                                ? `$${dataValeAseguradora.importeCoaseguroHospitalario}`
                                                : "$"
                                        }
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <label className="col-sm-4 col-form-label">Importe Deducible</label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="importeDeducible"
                                        onChange={onChangeImportesAprobarValeAseguradora}
                                        value={dataValeAseguradora.importeDeducible ? `$${dataValeAseguradora.importeDeducible}` : "$"}
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : null}
                    {valeSeleccionado.garantia? (
                        <>
                            <div hidden={!opcionGarantiaActivado} className="mt-2">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={valeSeleccionado.garantia}
                                            color="primary"
                                        />
                                    }
                                    label="Garantía"
                                />
                            </div>  
                            <Row className="form-group">
                                <label className="col-sm-4 col-form-label">Carta de Aceptación</label>
                                <Col sm={7}>
                                    <input className="form-control" type="file" onChange={changeCartaAceptacion} id="cartaAceptacion"/>
                                </Col>
                            </Row>
                            <Row hidden={opcionGarantia}>
                                <label className="col-sm-4 col-form-label">Importe Coaseguro Médico</label>
                                <Col sm={7}>
                                    <NumericFormat
                                        // disabled={true}
                                        id="importeCoaseguro"
                                        name="importeCoaseguro" 
                                        thousandSeparator={true}
                                        className="form-control"
                                        decimalScale={2}
                                        allowNegative={false}
                                        allowLeadingZeros={false}
                                        displayType="input"
                                        value={dataValeAseguradora.importeCoaseguro ? `$${dataValeAseguradora.importeCoaseguro}` : "$"}
                                        onChange={onChangeImportesAprobarValeAseguradora}
                                        // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                    />                                          
                                </Col>
                            </Row>
                            <Row hidden={opcionGarantia}>
                                <label className="col-sm-4 col-form-label">Importe Coaseguro Hospitalario</label>
                                <Col sm={7}>
                                    <NumericFormat
                                        // disabled={true}
                                        id="importeCoaseguroHospitalario"
                                        name="importeCoaseguroHospitalario" 
                                        thousandSeparator={true}
                                        className="form-control"
                                        decimalScale={2}
                                        allowNegative={false}
                                        allowLeadingZeros={false}
                                        displayType="input"
                                        value={
                                            dataValeAseguradora.importeCoaseguroHospitalario
                                                ? `$${dataValeAseguradora.importeCoaseguroHospitalario}`
                                                : "$"
                                        }
                                        onChange={onChangeImportesAprobarValeAseguradora}
                                        // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                    />      
                                </Col>
                            </Row>
                            <Row hidden={opcionGarantia}>
                                <label className="col-sm-4 col-form-label">Importe Deducible</label>
                                <Col sm={7}>
                                    <NumericFormat
                                        // disabled={true}
                                        id="importeDeducible"
                                        name="importeDeducible" 
                                        thousandSeparator={true}
                                        className="form-control"
                                        decimalScale={2}
                                        allowNegative={false}
                                        allowLeadingZeros={false}
                                        displayType="input"
                                        value={dataValeAseguradora.importeDeducible ? `$${dataValeAseguradora.importeDeducible}` : "$"}
                                        onChange={onChangeImportesAprobarValeAseguradora}
                                        // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                                    />                                           
                                </Col>
                            </Row>
                        </>
                     ) : null}
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.reload) {
                            window.location.reload();
                        }
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);                           
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_imprimir_cotizacion ? (
                <SweetAlert
                    title="Impresión de la Cotización del Vale"
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    style={{
                        width: width && width < 851 ? "100%" : "60%",
                    }}
                    closeOnClickOutside={false}
                    onConfirm={() => guardarCambios(true)}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_imprimir_cotizacion: false,
                        });
                    }}>
                    {usuario && usuario.authorities.includes("ROLE_ASEGURADORAS") ? (
                        <div>
                            <Row>
                                <Col>Llene en caso de requerir la siguiente información</Col>
                            </Row>

                            <Row className="form-group mt-4">
                                <label className="col-sm-4 col-form-label">Tratamiento</label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        name="tratamiento"
                                        value={datosCotizacion.tratamiento ? datosCotizacion.tratamiento : ""}
                                        onChange={changeCotizacion}
                                    />
                                </Col>
                            </Row>

                            <Row className="form-group mt-2">
                                <label className="col-sm-4 col-form-label">Aplicación</label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        name="aplicacion"
                                        value={datosCotizacion.aplicacion ? datosCotizacion.aplicacion : ""}
                                        onChange={changeCotizacion}
                                    />
                                </Col>
                            </Row>                            

                            <Row className="form-group mt-4">
                                <label className="col-sm-4 col-form-label">Encabezado</label>
                                <Col sm={7}>
                                    <textarea
                                        rows={4}
                                        className="form-control"
                                        name="encabezado"
                                        value={datosCotizacion.encabezado ? datosCotizacion.encabezado : ""}
                                        onChange={changeCotizacion}
                                    />
                                </Col>
                            </Row>

                            <Row className="form-group mt-2">
                                <label className="col-sm-4 col-form-label">Pie de página</label>
                                <Col sm={7}>
                                    <textarea
                                        rows={4}
                                        className="form-control"
                                        name="piePagina"
                                        value={datosCotizacion.piePagina ? datosCotizacion.piePagina : ""}
                                        onChange={changeCotizacion}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_changed_product ? (
                <SweetAlert
                    title="Confirmación de cambios"
                    showCancel
                    warning={true}
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => setUISweetAlert({
                        ...UISweetAlert,
                        confirm_alert_save_changes: true,
                        confirm_alert_changed_product: false,
                    })}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_changed_product: false,
                        });
                    }}>
                    ¿Estás seguro que deseas hacer el cambio de piezas o precio a los productos del vale?
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_changed_product_cambio_estatus ? (
                <SweetAlert
                    title="Confirmación de cambios"
                    showCancel
                    warning={true}
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => setUISweetAlert({
                        ...UISweetAlert,
                        confirm_alert_status_change: true,
                        confirm_alert_changed_product_cambio_estatus: false,
                    })}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_changed_product_cambio_estatus: false,
                        });
                    }}>
                    ¿Estás seguro que deseas hacer el cambio de piezas o precio a los productos del vale?
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
