import React, { useContext } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col } from "reactstrap";

import WindowWarningContext from "../../../context/WindowWarning/WindowWarningContext";

export default function Resumen({ historiaClinicaInicial, nuevoResumen, setNuevoResumen }) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito } = windowWarningContext;

  const onChange = async (e) => {
    const { value } = e.target;

    if (!seEdito) {
      changeEdit(true);
    }
    setNuevoResumen(value);
  };

  return (
    <div className="mt-4 container-fluid" style={{ textAlign: "left" }}>
      <h4 className="mt-4">Resumen</h4>
      <Row className="form-group">
        <Col sm={10}>
          <textarea
            disabled={true}
            rows={10}
            name="resumen" 
            value={
              historiaClinicaInicial.resumen && historiaClinicaInicial.resumen.resumen
                ? historiaClinicaInicial.resumen.resumen
                : ""
            }
            className="form-control-disabled"
          />
        </Col>
      </Row>
      <h4 className="mt-4">Información adicional</h4>
      <span>Esta información se concatenará con la información actual del resumen</span>
      <Row className="form-group">
        <Col sm={10}>
          <textarea
            rows={10}
            // disabled={true}
            name="nuevoResumen"
            value={nuevoResumen ? nuevoResumen : ""}
            onChange={onChange}
            className="form-control"
          />
        </Col>
      </Row>
    </div>
  );
}
