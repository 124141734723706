import React, { useReducer } from "react";

import EstadosCivilesContext from "./EstadosCivilesContext";
import EstadosCivilesReducer from "./EstadosCivilesReducer";

import { LISTAR_ESTADOSCIVILES } from "../../types";

import clienteAxios from "../../config/axios";

export default function EstadosCivilesState(props) {
  const initialState = {
    estadosCiviles: [],
  };

  const [state, dispatch] = useReducer(EstadosCivilesReducer, initialState);

  const listarEstadosCiviles = async () => {
    const res = await clienteAxios.get("/estadosciviles/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_ESTADOSCIVILES,
      payload: res.data,
    });
  };

  return (
    <EstadosCivilesContext.Provider
      value={{
        estadosCiviles: state.estadosCiviles,
        listarEstadosCiviles,
      }}>
      {props.children}
    </EstadosCivilesContext.Provider>
  );
}
