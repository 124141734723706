export const formatCatPersonaFullName = (medico) => {
    if (!medico) return "";

    let firstName = "" 
    let paternalName = "" 
    let maternalName = ""

    if(medico.catPersona!==undefined) {
        firstName = medico.catPersona.nombres;
        paternalName = medico.catPersona.apellidoPaterno;
        maternalName = medico.catPersona.apellidoMaterno;
    } else if(medico!==undefined) {
        firstName = medico.nombres;
        paternalName = medico.apellidoPaterno;
        maternalName = medico.apellidoMaterno;
    }

    const fullName = `${firstName} ${paternalName} ${maternalName}`;
    
    return fullName;
}