import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
// npm install @mui/material @emotion/react @emotion/styled
import useInvoiceActions from "../../hooks/useInvoiceActions";
import {Card, CardBody, Col, Row, Spinner} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import formatNumber from "../../utils/index";

import AuthContext from "../../context/Auth/AuthContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import CatTipoVentaMostradorContext from "../../context/CatTipoVentaMostrador/CatTipoVentaMostradorContext";
import CatTipoVentaContext from "../../context/CatTipoVenta/CatTipoVentaContext";
import ClientesContext from "../../context/Clientes/ClientesContext";
import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";
// import EstatusVentaMostradorContext from "../../context/EstatusVentaMostrador/EstatusVentaMostradorContext";
import ContabilidadFacturasContext from "../../context/ContabilidadFacturas/ContabilidadFacturasContext";
import CatRegimenFiscalContext from "../../context/CatRegimenFiscal/CatRegimenFiscalContext";
import CatUsoCfdiContext from "../../context/CatUsoCfdi/CatUsoCfdiContext";
import CatPeriodicidadContext from "../../context/CatPeriodicidad/CatPeriodicidadContext";
import LugaresContext from "../../context/Lugares/LugaresContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import ModalDatosFiscales from "../ModalDatosFiscales/index";
import RespuestaFacturas from "../RespuestaFacturas";
import SelectAsyncPaginate from "../SelectAsyncPaginate";
import TipoCobroContext from "../../context/CatTipoCobro/CatTipoCobroContext";

import useWindowDimensions from "../../hooks/dimensionsHook";

import {opcionesSelectPacientes} from "../../config/configOpcionesSelect";

import {mesesFacturacionGlobal} from "../../config/variables";

import formatDateWithoutHour from "../../utils/formatDateWithoutHour";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {addYears, startOfYear} from "date-fns";
import { all } from "axios";

const NuevaFactura = () => {
    const {width} = useWindowDimensions();
    //ACCESO AL ESTADO GLOBAL - AUTENTICACION
    const ctxAuth = useContext(AuthContext);
    const {usuario} = ctxAuth;

    // const informacionAdicionalVacio = {
    //   encabezado: null,
    //   pieDePagina: null,
    // };
    // const [informacionAdicional, setInformacionAdicional] = useState(informacionAdicionalVacio);

    const filtrosVacios = {
        fechaInicial: null,
        fechaFinal: null,
        idCatMedico: null,
        idCatPaciente: null,
        folioVale: null,
        folioVentaMostrador: null,
        idCatTipoVale: null,
        idCatTipoVentaMostrador: null,
        idCatAseguradora: null,
        idCatCliente: null,
        coaseguro: false,
        // deducible: false,
        reembolso: false,
        estatusVale: null,
        idCatEstatusVentaMostrador: 5,
        usuarioSolicitante: usuario.idCatUsuario,
    };

    // const [rowsSeleccionadas, setRowsSeleccionadas] = useState(null);

    const [datosFiscales, setDatosFiscales] = useState({});
    const [idTipoDeCobroGlobal, setIdTipoDeCobroGlobal] = useState(0);
    const [idMedico, setIdMedico] = useState(null);
    const [idAseguradora, setIdAseguradora] = useState(null);
    const [idCliente, setIdCliente] = useState(null);
    const [valesSeleccionados, setValesSeleccionados] = useState([]);
    const [idValesSeleccionados, setIdValesSeleccionados] = useState([]);
    const [ventasMostradorSeleccionados, setVentasMostradorSeleccionados] = useState([]);
    const [idVentasMostradorSeleccionados, setIdVentasMostradorSeleccionados] = useState([]);
    const [idCatPeriodicidad, setIdCatPeriodicidad] = useState(null);
    const [opcionesFacturaGlobal, setOpcionesFacturaGlobal] = useState({
        meses: null,
        anio: null,
    });
    const [respuestaFacturas, setRespuestaFacturas] = useState(null);
    const [fechaFacturaGlobal, setFechaFacturaGlobal] = useState(null);
    const [isFacturaDesglosada, setIsFacturaDesglosada] = useState(false);
    const [publicoGeneral, setPublicoGeneral] = useState(null);
    const [pacienteCompleto, setPacienteCompleto] = useState(null);

    const minYear = startOfYear(addYears(new Date(), -4));
    const maxYear = startOfYear(new Date());

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_datos_fiscales: false,
        confirm_datos_fiscales_ppd: false,
        confirm_actualizar_datos_fiscales: false,
        confirm_nueva_factura_global: false,
        advierte_nueva_factura_global : false,
        confirm_incluir_global: false,
        confirm_excluir_global: false,
        select_desglose_ppd: false,
        respuestas_facturas: false,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
    });

    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const [pageCount, setPageCount] = useState(0);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
    const fetchIdRef = useRef(0);
    const [salvado, setSalvado] = useState(true);

    const {
        downloadFactura
    } = useInvoiceActions({
        setLoading,
        UISweetAlert,
        setUISweetAlert,
        setRespuestaFacturas
    });

    // Inicialización de todos los context

    const lugaresContext = useContext(LugaresContext);
    const {lugares, listarLugares} = lugaresContext;

    useEffect(() => {       
         listarLugares();       
    }, []);

    const contabilidadFacturasContext = useContext(ContabilidadFacturasContext);
    const {
        filtrosNuevaFactura,
        setFiltrosNuevaFactura,
        setVentasPaginadas,
        ventasPaginadas,
        nuevaFactura,
        setIncluirFacturaGlobal,
        actualizarDatosFiscales,
        nuevaFacturaGlobal,
    } = contabilidadFacturasContext;

    const pacientesContext = useContext(PacientesContext);
    const {listarPacientesActivos, pacientesActivos, loadPacientesSelect} = pacientesContext;

    const aseguradorasContext = useContext(AseguradorasContext);
    const {listarAseguradoras, aseguradoras, setAseguradoraWithDetalles, updateAseguradora} = aseguradorasContext;

    const medicosContext = useContext(MedicosContext);
    const {listarMedicos, medicos, setMedicoWithDetalles} = medicosContext;

    const tipoCobroContext = useContext(TipoCobroContext);
    const {listarCatTipoCobros,tipoCobros} = tipoCobroContext;

    const catTipoVentaMostradorContext = useContext(CatTipoVentaMostradorContext);
    const {listarTiposVentaMostrador, tipoVentaMostrador} = catTipoVentaMostradorContext;

    const catTipoVentaContext = useContext(CatTipoVentaContext);
    const {listarTiposVenta, tipoVentas} = catTipoVentaContext;

    const clientesContext = useContext(ClientesContext);
    const {listarClientes, clientes, setClienteWithDetalles, uploadNuevoCliente} = clientesContext;

    const estatusValeContext = useContext(EstatusValeContext);
    const {listarEstatusVale} = estatusValeContext;

    const catRegimenFiscalContext = useContext(CatRegimenFiscalContext);
    const {listarCatRegimenFiscales, catRegimenFiscales} = catRegimenFiscalContext;

    const catUsoCfdiContext = useContext(CatUsoCfdiContext);
    const {listarCatUsoCfdis, catUsoCfdis} = catUsoCfdiContext;

    const catPeriodicidadContext = useContext(CatPeriodicidadContext);
    const {listarCatPeriodicidad, catPeriodicidad} = catPeriodicidadContext;

    // const estatusVentaMostradorContext = useContext(EstatusVentaMostradorContext);
    // const { estatusVentaMostrador, listarEstatusVentaMostrador } = estatusVentaMostradorContext;

    // Fetch a todos los datos que necesitamos para las opciones de filtros
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneformat = /^\d{10}$/;
    const rfcformat = /^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?$/;
    const cpformat = /^\d{4,5}$/;

    useEffect(() => {
        const fetchAllContextData = async () => {
            listarPacientesActivos();
            listarAseguradoras();
            listarMedicos();
            listarTiposVentaMostrador();
            listarTiposVenta();
            listarClientes();
            listarEstatusVale();
            listarCatRegimenFiscales();
            listarCatUsoCfdis();
            listarCatPeriodicidad();
            listarCatTipoCobros();
        };
        fetchAllContextData();
    }, []);

    // Se modifican los datos para poder mandar al componente de filtros las opciones

    const medicosChangeId = medicos
        ? medicos.map(({idCatMedico, catPersona, ...medico}) => ({
            ...medico,
            nombre: `${catPersona.nombres} ${catPersona.apellidoPaterno} ${catPersona.apellidoMaterno}`,
            id: idCatMedico,
        }))
        : [];

    const pacientesChangeId = pacientesActivos
        ? pacientesActivos.map(({idCatPaciente, nombres, apellidoPaterno, apellidoMaterno, ...paciente}) => ({
            ...paciente,
            nombre: nombres + " " + apellidoPaterno + " " + apellidoMaterno,
            id: idCatPaciente,
        }))
        : [];
    const aseguradorasChangeId = aseguradoras
        ? aseguradoras.map(({idCatAseguradora, razonSocial, ...aseguradora}) => ({
            ...aseguradora,
            nombre: razonSocial,
            id: idCatAseguradora,
        }))
        : [];
    const clientesChangeId = clientes
        ? clientes.map(({idCatCliente, catPersona, ...cliente}) => ({
            ...cliente,
            nombre: catPersona.nombres + " " + catPersona.apellidoPaterno + " " + catPersona.apellidoMaterno,
            id: idCatCliente,
        }))
        : [];
    const tipoVentaMostradorChangeId = tipoVentaMostrador
        ? tipoVentaMostrador.map(({idCatTipoVentaMostrador, tipoVentaMostrador, ...tipoVentaMostradorRest}) => ({
            ...tipoVentaMostradorRest,
            nombre: tipoVentaMostrador,
            id: idCatTipoVentaMostrador,
        }))
        : [];

    const tipoVentasChangeId = tipoVentas
        ? tipoVentas.map(({idCatTipoVenta, tipoVenta, ...tipoVentaRest}) => ({
            ...tipoVentaRest,
            nombre: tipoVenta,
            id: idCatTipoVenta,
        }))
        : [];

    let tipoVale = tipoVentasChangeId ? tipoVentasChangeId.filter((tipoVale) => tipoVale.id == 1 || tipoVale.id == 2) : [];

    const estatusValesChangeId = [
        {
            id: "APLICADO",
            nombre: "APLICADO",
        },
        {
            id: "COBRADO",
            nombre: "COBRADO",
        },
    ];

    const isRecepcion = usuario.authorities.includes("ROLE_RECEPCION");  // TODO: SE AGREGO PERO HAY QUE VALIDAR AUN SI SE INCLUYE A RECEPCION PARA FACTURAR
    const isFarmacia = usuario.authorities.includes("ROLE_FARMACIA");
    const isAseguradora = usuario.authorities.includes("ROLE_ASEGURADORAS");
    const isContabilidad = usuario.authorities.includes("ROLE_CONTABILIDAD");
    const isAdministrador = usuario.authorities.includes("ROLE_ADMIN_SISTEMA");

    const autorizado = isRecepcion || isFarmacia || isAseguradora || isContabilidad || isAdministrador;

    const onChange = (e, nameSelect) => {
        if (nameSelect && nameSelect == "idCatPaciente") {
            setPacienteCompleto(e);
            setFiltrosNuevaFactura({
                ...filtrosNuevaFactura,
                [nameSelect]: e.idCatPaciente,
            });
        } else {
            const {name, value} = e.target;
            if (name === "fechaInicial") {
                setFiltrosNuevaFactura({
                    ...filtrosNuevaFactura,
                    [name]: value,
                    fechaFinal: value,
                });
            } else if (name === "reembolso" || name === "coaseguro" || name === "deducible") {
                setFiltrosNuevaFactura({
                    ...filtrosNuevaFactura,
                    [name]: e.target.checked,
                });
            } else {
                setFiltrosNuevaFactura({
                    ...filtrosNuevaFactura,
                    [name]: value,
                });
            }
        }
    };

    const inputCols = [
        {
            col: [
                {
                    colType: "input",
                    label: "Fecha Inicial",
                    type: "date",
                    name: "fechaInicial",
                    disabled: false,
                    value: filtrosNuevaFactura.fechaInicial,
                    onChange: onChange,
                },
                {
                    colType: "input",
                    label: "Folio Vale",
                    type: "text",
                    name: "folioVale",
                    disabled: false,
                    value: filtrosNuevaFactura.folioVale,
                    onChange: onChange,
                },
                {
                    colType: "input",
                    label: "Folio Venta Mostrador",
                    type: "text",
                    name: "folioVentaMostrador",
                    disabled: false,
                    value: filtrosNuevaFactura.folioVentaMostrador,
                    onChange: onChange,
                    hidden: isAseguradora,
                },
                {
                    colType: "select",
                    label: "Tipo Vale",
                    name: "idCatTipoVale",
                    value: filtrosNuevaFactura.idCatTipoVale,
                    opciones: tipoVale,
                    onChange: onChange,
                },
                {
                    colType: "select",
                    label: "Tipo Venta Mostrador",
                    name: "idCatTipoVentaMostrador",
                    value: filtrosNuevaFactura.idCatTipoVentaMostrador,
                    opciones: tipoVentaMostradorChangeId,
                    onChange: onChange,
                    hidden: isAseguradora,
                },
                {
                    colType: "select",
                    label: "Médico",
                    name: "idCatMedico",
                    value: filtrosNuevaFactura.idCatMedico,
                    opciones: medicosChangeId,
                    onChange: onChange,
                },
                {
                    colType: "select",
                    label: "Aseguradora",
                    name: "idCatAseguradora",
                    value: filtrosNuevaFactura.idCatAseguradora,
                    opciones: aseguradorasChangeId,
                    onChange: onChange,
                    hidden: isFarmacia,
                },
            ],
        },
        {
            col: [
                {
                    colType: "input",
                    label: "Fecha Final",
                    type: "date",
                    name: "fechaFinal",
                    disabled: !filtrosNuevaFactura.fechaInicial,
                    min: filtrosNuevaFactura.fechaInicial,
                    value: filtrosNuevaFactura.fechaFinal,
                    onChange: onChange,
                },

                {
                    colType: "select",
                    label: "Paciente",
                    name: "idCatPaciente",
                    value: filtrosNuevaFactura.idCatPaciente,
                    opciones: pacientesChangeId,
                    onChange: onChange,
                    customSelect: (
                        <SelectAsyncPaginate
                            opcionSeleccionadaCompleta={pacienteCompleto}
                            onChangeOptions={onChange}
                            fetchOptions={loadPacientesSelect}
                            opcionesPaginado={opcionesSelectPacientes.opcionesPaginado}
                            placeholderText={opcionesSelectPacientes.placeholderText}
                            optionLabel={opcionesSelectPacientes.optionLabel}
                            optionValue={opcionesSelectPacientes.optionValue}
                            name="idCatPaciente"
                        />
                    ),
                },
                {
                    colType: "select",
                    label: "Cliente",
                    name: "idCatCliente",
                    value: filtrosNuevaFactura.idCatCliente,
                    opciones: clientesChangeId,
                    onChange: onChange,
                    hidden: isAseguradora,
                },
                {
                    colType: "select",
                    label: "Estatus Vale",
                    name: "estatusVale",
                    value: filtrosNuevaFactura.estatusVale,
                    opciones: estatusValesChangeId,
                    onChange: onChange,
                },
                {
                    colType: "input",
                    label: "Coaseguro / Deducible",
                    type: "checkbox",
                    name: "coaseguro",
                    checked: filtrosNuevaFactura.coaseguro,
                    disabled: filtrosNuevaFactura.reembolso && true,
                    value: filtrosNuevaFactura.coaseguro,
                    onChange: onChange,
                    hidden: isFarmacia,
                },
                // {
                //   colType: "input",
                //   label: "Deducible",
                //   type: "checkbox",
                //   name: "deducible",
                //   checked: filtrosNuevaFactura.deducible,
                //   disabled: false,
                //   value: filtrosNuevaFactura.deducible,
                //   onChange: onChange,
                // },
                {
                    colType: "input",
                    label: "Reembolso",
                    type: "checkbox",
                    name: "reembolso",
                    checked: filtrosNuevaFactura.reembolso,
                    disabled: filtrosNuevaFactura.coaseguro && true,
                    value: filtrosNuevaFactura.reembolso,
                    onChange: onChange,
                    hidden: isFarmacia,
                },
            ],
        },
    ];

    const buscarPorFiltros = async (e) => {
        e.preventDefault();
        setFiltrosNuevaFactura(filtrosNuevaFactura);
        fetchData({pageSize: 10, pageIndex: 0});
        limpiarState(
            setValesSeleccionados,
            setVentasMostradorSeleccionados,
            setIdValesSeleccionados,
            setIdVentasMostradorSeleccionados,
            setIdMedico,
            setIdAseguradora,
            setIdCliente,
            setDatosFiscales
        );
    };

    const limpiarFiltros = () => {
        setPacienteCompleto(null);
        setFiltrosNuevaFactura(filtrosVacios);
    };

    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);
            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);
            }
            const response = await setVentasPaginadas({page, limit});

            const count = response.count;

            // setValesPaginados(response.valesPaginadoVo.valesVoList);
            // setVentasMostradorPaginadas(response.ventaMostradorPaginadoVo.ventaMostradorVoList);

            if (count % limit > 0) {
                setPageCount(Math.ceil(count / limit));
            } else {
                setPageCount(count / limit);
            }

            setLoadingData(false);
        } catch (e) {
            console.log("Error while fetching", e);
            // setLoading(false)
        }
    };

    const fetchData = async ({pageSize, pageIndex}) => {
        console.log("fetchData is being called");
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: false,
            });
        }
    };

    // Se hace el manejo de la selección de una row
    // Checa si es venta vale o venta Mostrador y luego se guarda o se quita de la lista
    const handdleCheckedRow = useCallback(
        (row) => {
            setPublicoGeneral(null);
            if (row.original.idVale) {
                const idVale = row.original.idVale;
                const selectedIndex = idValesSeleccionados.indexOf(idVale);
                let newSelected = [];
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(idValesSeleccionados, idVale);
                    setValesSeleccionados([...valesSeleccionados, row.original]);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(idValesSeleccionados.slice(1));
                    const ventasValesSeleccionadosFiltered = valesSeleccionados.filter((vale) => vale.idVale !== row.original.idVale);
                    setValesSeleccionados(ventasValesSeleccionadosFiltered);
                } else if (selectedIndex === idValesSeleccionados.length - 1) {
                    newSelected = newSelected.concat(idValesSeleccionados.slice(0, -1));
                    const ventasValesSeleccionadosFiltered = valesSeleccionados.filter((vale) => vale.idVale !== row.original.idVale);
                    setValesSeleccionados(ventasValesSeleccionadosFiltered);
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                        idValesSeleccionados.slice(0, selectedIndex),
                        idValesSeleccionados.slice(selectedIndex + 1)
                    );
                    const ventasValesSeleccionadosFiltered = valesSeleccionados.filter((vale) => vale.idVale !== row.original.idVale);
                    setValesSeleccionados(ventasValesSeleccionadosFiltered);
                }
                setIdValesSeleccionados(newSelected);
            } else if (row.original.idVentaMostrador) {
                const idVentaMostrador = row.original.idVentaMostrador;
                const selectedIndex = idVentasMostradorSeleccionados.indexOf(idVentaMostrador);
                let newSelected = [];
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(idVentasMostradorSeleccionados, idVentaMostrador);
                    setVentasMostradorSeleccionados([...ventasMostradorSeleccionados, row.original]);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(idVentasMostradorSeleccionados.slice(1));
                    const ventasMostradorSeleccionadosFiltered = ventasMostradorSeleccionados.filter(
                        (ventaMostrador) => ventaMostrador.idVentaMostrador !== row.original.idVentaMostrador
                    );
                    setVentasMostradorSeleccionados(ventasMostradorSeleccionadosFiltered);
                } else if (selectedIndex === idVentasMostradorSeleccionados.length - 1) {
                    newSelected = newSelected.concat(idVentasMostradorSeleccionados.slice(0, -1));
                    const ventasMostradorSeleccionadosFiltered = ventasMostradorSeleccionados.filter(
                        (ventaMostrador) => ventaMostrador.idVentaMostrador !== row.original.idVentaMostrador
                    );
                    setVentasMostradorSeleccionados(ventasMostradorSeleccionadosFiltered);
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                        idVentasMostradorSeleccionados.slice(0, selectedIndex),
                        idVentasMostradorSeleccionados.slice(selectedIndex + 1)
                    );
                    const ventasMostradorSeleccionadosFiltered = ventasMostradorSeleccionados.filter(
                        (ventaMostrador) => ventaMostrador.idVentaMostrador !== row.original.idVentaMostrador
                    );
                    setVentasMostradorSeleccionados(ventasMostradorSeleccionadosFiltered);
                }
                setIdVentasMostradorSeleccionados(newSelected);
            }
        },
        [idValesSeleccionados, idVentasMostradorSeleccionados, valesSeleccionados, ventasMostradorSeleccionados]
    );

    // Checa si el idVale está en el array idValesSeleccionados para ver manejar el checked de esa row
    const isSelectedVale = useCallback(
        (id) => {
            return idValesSeleccionados.indexOf(id) !== -1;
        },
        [idValesSeleccionados]
    );

    // Checa si el idVentaMostrador está en el array idValesSeleccionados para ver manejar el checked de esa row
    const isSelectedVenta = useCallback(
        (id) => {
            return idVentasMostradorSeleccionados.indexOf(id) !== -1;
        },
        [idVentasMostradorSeleccionados]
    );

    const setDatosFiscalesWithFetchedData = (datos, isDatosAseguradora) => {
        //let vales = valesSeleccionados[0]
        if (isDatosAseguradora) {
            setDatosFiscales({
                ...datos,
                nombre: datos.razonSocial ? datos.razonSocial : "",
                idCatRegimenFiscal: datos && datos.catRegimenFiscal ? datos.catRegimenFiscal.idCatRegimenFiscal : "",
                idCatUsoCfdi: datos && datos.catUsoCfdi ? datos.catUsoCfdi.idCatUsoCfdi : "", esAseguradora : true,
                idEstado: datos && datos.catEstado ? datos.catEstado.idCatOrigenResidencia : "",
                idCatTipoCobro : 6,
            });
        } else {
            const catPersona = datos.catPersona ? datos.catPersona : null;

            const datosFiscales = catPersona && catPersona.datosFiscales ? catPersona.datosFiscales : null;

            setDatosFiscales({
                ...datosFiscales,
                idCatPersona: catPersona ? catPersona.idCatPersona : "",
                nombre: catPersona ? catPersona.nombres + " " + catPersona.apellidoPaterno + " " + catPersona.apellidoMaterno : "",
                idCatRegimenFiscal:
                    datos && catPersona && datosFiscales && datos.catPersona.datosFiscales.catRegimenFiscal
                        ? datos.catPersona.datosFiscales.catRegimenFiscal.idCatRegimenFiscal
                        : "",
                idCatUsoCfdi:
                    datos && catPersona && datosFiscales && datos.catPersona.datosFiscales.catUsoCfdi
                        ? datos.catPersona.datosFiscales.catUsoCfdi.idCatUsoCfdi
                        : "",
                idEstado:
                    datos && catPersona && datosFiscales && datos.catPersona.datosFiscales.catEstado
                        ? datos.catPersona.datosFiscales.catEstado.idCatOrigenResidencia
                        : "",
                correo:
                        datos && catPersona && datosFiscales && datos.catPersona.correo
                            ? datos.catPersona.correo
                            : "",                        
            });
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "",
                // Header: (
                //   <button
                //     className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                //     type="button"
                //     style={{
                //       marginRight: "2.5rem",
                //       marginTop: "1rem",
                //       background: "#ff9933",
                //       color: "white",
                //       fontWeight: "bold",
                //     }}
                //     onClick={() => handdleCheckAllRows()}>
                //     Seleccionar todos
                //   </button>
                // ),

                accessor: "id",
                Cell: ({row}) => {
                    return (
                        <input
                            // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
                            checked={row.original.idVale ? isSelectedVale(row.original.idVale) : isSelectedVenta(row.original.idVentaMostrador)}
                            type="checkbox"
                            onChange={() => handdleCheckedRow(row)}
                        />
                    );
                },
            },
            {
                Header: () => (
                    <>
                        Folio Vale /<br></br>Venta Mostrador
                    </>
                ),
                accessor: "folio",
                Cell: ({row}) => {
                    return <>{row.original.folioVale ? row.original.folioVale : row.original.folioVentaMostrador}</>;
                },
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: "Tipo",
                accessor: "tipo",
                Cell: ({row}) => {
                    return <>{row.original.tipoVale ? row.original.tipoVale : row.original.tipoVentaMostrador}</>;
                },
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: "Fecha",
                accessor: "fecha",
                Cell: ({row}) => {
                    return (
                        <>
                            {row.original.fechaVale
                                ? formatDateWithoutHour(row.original.fechaVale)
                                : formatDateWithoutHour(row.original.fechaVenta)}
                        </>
                    );
                },
                disableFilters: true,
                disableSortBy: true,
            },

            {
                Header: "Paciente / Cliente",
                accessor: "persona",
                Cell: ({row}) => {
                    return <>{row.original.paciente && row.original.idCatTipoVale == 1 ? row.original.paciente : row.original.cliente}</>;
                },
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: "Médico",
                accessor: "medico",
                Cell: ({row}) => {
                    return <>{row.original.medico ? row.original.medico : "No Aplica"}</>;
                },
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: "Aseguradora",
                accessor: "aseguradora",
                Cell: ({row}) => {
                    return <>{row.original.aseguradora ? row.original.aseguradora : "No Aplica"}</>;
                },
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: "Estatus",
                accessor: "estatus",
                Cell: ({row}) => {
                    return <>{row.original.estatusActual ? row.original.estatusActual.estatusVale : row.original.estatusVentaMostrador}</>;
                },
                disableFilters: true,
                disableSortBy: true,
            },

            {
                Header: "Importe Total",
                accessor: "importeTotal",
                Cell: ({row}) => {
                    return <>{row.original.importeTotal ? formatNumber(row.original.importeTotal) : `$`}</>;
                },
                disableFilters: true,
                disableSortBy: true,
            },

            {
                Header: "Método Pago",
                accessor: "tipoCobro",
                Cell: ({row}) => {
                    return <>{row.original.tipoCobro ? row.original.tipoCobro : ""}</>;
                },
                disableFilters: true,
                disableSortBy: true,
            },            
            {
                Header: () => (
                    <>
                        Excluido de <br></br>Factura Global
                    </>
                ),
                accessor: "incluirGlobal",
                Cell: ({row}) => {
                    return <>{row.original.incluirGlobal ? "NO" : "SI"}</>;
                },
                disableFilters: true,
                disableSortBy: true,
            },
        ],

        [handdleCheckedRow, isSelectedVale, isSelectedVenta]
    );

    // Checa que todos los vales sean del mismo tipo
    const checkTipoVale = async (idCatTipoVale) => {
        let allEqualTipo = true;
        for (const vale of valesSeleccionados) {
            if (vale.idCatTipoVale != idCatTipoVale) {
                allEqualTipo = false;
                break;
            }
        }
        return {allEqualTipo, idCatTipoVale};
    };

    // Checa que todos los vales personales seleccionados sean del mismo médico
    const checkMedicoVale = async () => {
        const idCatMedico = valesSeleccionados[0].idCatMedico;
        setIdMedico(idCatMedico);
        setIdCliente(null);
        setIdAseguradora(null);
        let allEqualMedico = true;
        for (const vale of valesSeleccionados) {
            if (vale.idCatMedico != idCatMedico) {
                allEqualMedico = false;
                break;
            }
        }
        if (allEqualMedico) {
            const datos = await setMedicoWithDetalles(idCatMedico);
            setDatosFiscalesWithFetchedData(datos, false);
        }
        return allEqualMedico;
    };
    // Checa que todos los vales personales o de aseguradora sean del mismo cliente
    const checkClienteVale = async () => {
        const idCatCliente = valesSeleccionados[0].idCatCliente;
        setIdCliente(idCatCliente);
        setIdMedico(null);
        setIdAseguradora(null);
        let allEqualCliente = true;
        for (const vale of valesSeleccionados) {
            if (vale.idCatCliente != idCatCliente) {
                allEqualCliente = false;
                break;
            }
        }
        if (allEqualCliente && idCatCliente != null) {

            const datos = await setClienteWithDetalles(idCatCliente);

            setDatosFiscalesWithFetchedData(datos, false);
        }//else
        /*if(allEqualCliente && idCatCliente == null && valesSeleccionados[0].idCatAseguradora)
        {
            setIdAseguradora(valesSeleccionados[0].idCatAseguradora);
        }*/
        return allEqualCliente;
    };

    const checkAseguradoraVale = async () => {
        const idCatAseguradora = valesSeleccionados[0].idCatAseguradora;
        setIdAseguradora(idCatAseguradora);
        setIdMedico(null);
        setIdCliente(null);
        let allEqualAseguradora = true;
        for (const vale of valesSeleccionados) {
            if (vale.idCatAseguradora != idCatAseguradora) {
                allEqualAseguradora = false;
                break;
            }
        }
        if (allEqualAseguradora) {
            const datos = await setAseguradoraWithDetalles(idCatAseguradora);
            setDatosFiscalesWithFetchedData(datos, true);
        }

        return allEqualAseguradora;
    };

    // const checkAllValesReembolso = async () => {
    //   // const isReembolso = valesSeleccionados[0].reembolso || valesSeleccionados[0].cobroCoaseguroReembolso ? true : false;
    //   let allReembolso = true;
    //   for (const vale of valesSeleccionados) {
    //     if (!vale.reembolso && !vale.cobroCoaseguroReembolso) {
    //       allReembolso = false;
    //       break;
    //     }
    //   }
    //   return allReembolso;
    // };

    const checkEstatusVales = async () => {
        const estatusVale = valesSeleccionados[0].estatusActual;
        let allEqualEstatus = true;
        for (const vale of valesSeleccionados) {
            if (vale.estatusActual.estatusVale.toLowerCase() !== estatusVale.estatusVale.toLowerCase()) {
                allEqualEstatus = false;
                break;
            }
        }
        return allEqualEstatus;
    };

    const checkVales = async (isFacturaPPD) => {
        let validVales = true;
        const idCatTipoVale = valesSeleccionados[0].idCatTipoVale;
        const idCatTipoMedico = valesSeleccionados[0].idCatTipoMedico;
        const estatusVale = valesSeleccionados[0].estatusActual;

        const isReembolso = !!(valesSeleccionados[0].reembolso || valesSeleccionados[0].cobroCoaseguroReembolso);

        let checkVale = await checkTipoVale(idCatTipoVale);

        const allEqualEstatus = await checkEstatusVales();

        if (!checkVale.allEqualTipo) {
            validVales = false;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione vales de un solo tipo.",
            });
        }

        if (!allEqualEstatus) {
            validVales = false;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione vales con un mismo estatus.",
            });
            return;
        }

        if (idCatTipoVale == 1 && checkVale.allEqualTipo) {
            if (idCatTipoMedico == 1) {
                checkVale.allEqualMedico = await checkMedicoVale();
                if (!checkVale.allEqualMedico) {
                    validVales = false;
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "Por favor, seleccione vales tipo personal que pertenezcan al mismo médico.",
                    });
                    setDatosFiscales({});
                    setIdMedico(null);
                }
            } else {
                checkVale.allEqualCliente = await checkClienteVale();
                if (!checkVale.allEqualCliente) {
                    validVales = false;
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "Por favor, seleccione vales tipo personal que pertenezcan al mismo cliente.",
                    });
                    setDatosFiscales({});
                    setIdCliente(null);
                }
            }
        }

        if (idCatTipoVale == 2 && checkVale.allEqualTipo) {
            // const allReembolso = isReembolso ? checkAllValesReembolso() : false;
            // checkVale.allReembolso = allReembolso;
            // let alMenosUnoEsReembolso = false;
            // if (valesSeleccionados.some((vale) => vale.reembolso || vale.cobroCoaseguroReembolso)) {
            //   alMenosUnoEsReembolso = true;
            // }

            if (isFacturaPPD && isReembolso && estatusVale.estatusVale.toLowerCase() == "aplicado") {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Solo se puede generar una factura definitiva para este vale.",
                });
                return;
            }

            if (!isFacturaPPD && estatusVale.estatusVale.toLowerCase() == "cobrado") {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Solo se puede generar una factura PPD para este vale.",
                });
                return;
            }

            if (isFacturaPPD) {
                const allEqualAseguradora = await checkAseguradoraVale();
                checkVale.allEqualAseguradora = allEqualAseguradora;
                if (!checkVale.allEqualAseguradora) {
                    validVales = false;
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "Por favor, seleccione vales de tipo aseguradora que pertenezcan a la misma aseguradora.",
                    });
                    setDatosFiscales({});
                    setIdAseguradora(null);
                }
            } else {
                const idCatCliente = valesSeleccionados[0].idCatCliente;
                if (idCatCliente == 1) {
                    validVales = false;
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description:
                            "No se puede generar una factura definitiva para un vale de aseguradora con cliente VENTA MOSTRADOR",
                    });
                    setDatosFiscales({});
                    setIdCliente(null);
                }

                const allEqualCliente = await checkClienteVale();
                checkVale.allEqualCliente = allEqualCliente;
                if (!checkVale.allEqualCliente) {
                    validVales = false;
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "Por favor, seleccione vales de tipo aseguradora que pertenezcan al mismo cliente.",
                    });
                    setDatosFiscales({});
                    setIdCliente(null);
                }
            }
        }
        return validVales;
    };

    const checkClienteVentaMostrador = async () => {
        const idCatCliente = ventasMostradorSeleccionados[0].idCatCliente;
        setIdCliente(idCatCliente);
        setIdMedico(null);
        setIdAseguradora(null);
        let allEqualCliente = true;
        for (const ventaMostrador of ventasMostradorSeleccionados) {
            if (ventaMostrador.idCatCliente != idCatCliente) {
                allEqualCliente = false;
                break;
            }
        }
        if (allEqualCliente && idCatCliente) {
            const datos = await setClienteWithDetalles(idCatCliente);
            setDatosFiscalesWithFetchedData(datos, false);
        }
        return allEqualCliente;
    };

    const checkVentasMostrador = async () => {
        let validVentas = true;
        const allEqualCliente = await checkClienteVentaMostrador();

        if (!allEqualCliente) {
            validVentas = false;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione ventas mostrador que pertenezcan al mismo cliente.",
            });
            setDatosFiscales({});
            setIdCliente(null);
        }
        return validVentas;
    };

    const checkFacturaDefinitiva = async () => {
    setSalvado(true);
    setIdTipoDeCobroGlobal(0);
    const lengthVales = valesSeleccionados.length;
        const lengthVentasMostrador = ventasMostradorSeleccionados.length;
        setDatosFiscales({});
        
        if (lengthVales === 0 && lengthVentasMostrador === 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione al menos una venta de vale o venta mostrador",
            });
            return;
        }

        if(isAseguradora && !isAdministrador){
            let TodosReembolso = todosReembolso();

            if(!TodosReembolso){
                let TodosGarantia = todosGarantia()

                if(!TodosGarantia){
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "Por favor, seleccione solo vales con garantía o reembolso.",
                    }); 
                    return;
                }
            }
        }

        let TodosExcluidos = todosExcluidos()
        if(!TodosExcluidos){
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione vales o ventas excluidas de la factura global.",
            }); 
            return;
        }

        if (lengthVales > 0 && lengthVentasMostrador > 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione solamente ventas de vale o ventas mostrador, pero no los dos tipos.",
            });
            return;
        }

        /*
        if(!isAseguradora && !isAdministrador ){

            if(lengthVentasMostrador > 0 ){
                let mismoCobro = todosMismoCobroVentas();
                if(!mismoCobro){
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "Por favor, seleccione ventas que tengan el mismo tipo de cobro.",
                    }); 
                    return;
                }
            }else{
                if(lengthVales > 0){
                    let mismoCobro = todosMismoCobroVales();
                    if(!mismoCobro){
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: "Por favor, seleccione vales que tengan el mismo tipo de cobro.",
                        }); 
                        return;
                    }else {
                        setIdTipoDeCobroGlobal(6);
                    }
                }
            }
        }else{
            if(isAseguradora || isAdministrador ){
            }
        }*/
        if(valesSeleccionados[0]){
            let idTemp = 0;
            let importeTemp = 0;
            valesSeleccionados.map((vale) => {
                if(importeTemp < vale.importeTotal){
                    idTemp      = vale.idCatTipoCobro;
                    importeTemp = vale.importeTotal;
                }
            });

            setIdTipoDeCobroGlobal(idTemp);
            setDatosFiscales({...datosFiscales,idCatTipoCobro: idTemp});
        }else{
            if(ventasMostradorSeleccionados[0]){
                let idTemp = 0;
                let importeTemp = 0;
                ventasMostradorSeleccionados.map((vale) => {
                    if(importeTemp < vale.importeTotal){
                        idTemp      = vale.idCatTipoCobro;
                        importeTemp = vale.importeTotal;
                    }
                });

                setIdTipoDeCobroGlobal(idTemp);
                setDatosFiscales({...datosFiscales,idCatTipoCobro: idTemp});
            }
        }

        if (lengthVales > 0 && lengthVentasMostrador === 0) {
            const validVales = await checkVales(false);
            if (validVales) {
                const sinPublicoGeneral = valesSeleccionados[0].estatusActual.idEstatusVale == 22 && valesSeleccionados[0].reembolso;
                setPublicoGeneral(!sinPublicoGeneral);
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_datos_fiscales: true,
                });
            }
        }

        if (lengthVales === 0 && lengthVentasMostrador > 0) {
            const validVentas = await checkVentasMostrador();
            if (validVentas) {
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_datos_fiscales: true,
                });
            }
        }
    };

    function todosMismoCobroVentas(){
        const tipoCobro = ventasMostradorSeleccionados[0].tipoCobro;

        let allEqualtipoCobro = true;
        for (const ventaMostrador of ventasMostradorSeleccionados) {
            if (ventaMostrador.tipoCobro != tipoCobro) {
                allEqualtipoCobro = false;
                break;
            }
        }
        if(allEqualtipoCobro==true){
            if(tipoCobro == null){
                tipoCobro = 6;
            }

            setIdTipoDeCobroGlobal(tipoCobro);
        }
        return allEqualtipoCobro;
    }//*********************************************************************************** */

    function todosMismoCobroVales(){
        const tipoCobro = valesSeleccionados[0].tipoCobro;

        let allEqualtipoCobro = true;
        for (const ventaMostrador of valesSeleccionados) {
            if (ventaMostrador.tipoCobro != tipoCobro) {
                allEqualtipoCobro = false;
                break;
            }
        }
        if(allEqualtipoCobro==true){
            if(tipoCobro == null){
                tipoCobro = 6;
            }

            setIdTipoDeCobroGlobal(tipoCobro);
        }
        return allEqualtipoCobro;
    }//*

    function todosReembolso(){
        let retorno = true;
        valesSeleccionados.map((vale) => {
            if(vale.reembolso){
                if(vale.reembolso==false){
                    retorno = false;
                }
            }else{
                retorno = false;
            }
        });
        return retorno;
    }//*********************************************************************************** */

    function todosGarantia(){
        let retorno = true;
        valesSeleccionados.map((vale) => {
            if(vale.garantia){
                if(vale.garantia==false){
                    retorno = false;
                }
            }else{
                retorno = false;
            }
        });
        return retorno;
    }//*********************************************************************************** */

    function todosNoGarantia(){
        let retorno = true;
        valesSeleccionados.map((vale) => {
            if(vale.garantia){
                if(vale.garantia==true){
                    retorno = false;
                }
            }
        });
        return retorno;
    }//*********************************************************************************** */

    function todosExcluidos(){
        let retorno = true;
        valesSeleccionados.map((vale) => {
            if(vale.incluirGlobal){
                if(vale.incluirGlobal==true){
                    retorno = false;
                }
            };
        });
        return retorno;
    }//*********************************************************************************** */

    const confirmNuevaFacturaDefinitiva = async () => {
        let idCatClienteTemp = null;

        if(datosFiscales.idCatCliente){
            idCatClienteTemp = datosFiscales.idCatCliente ;
        }

        if(datosFiscales.tipoPersona!= 2){
            if (!datosFiscales.nombre) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, inserte la razon social.",
                });
                return;
            }

            if (!datosFiscales.correo) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, inserte el Email.",
                });
                return;
              }     
              
              if (datosFiscales.correo  && !datosFiscales.correo.match(mailformat)) {
                setSalvado(false);      
                setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, ingrese un Email de contacto válido",
                });
                return;
              }              

            if (!datosFiscales.rfc) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, inserte el RFC.",
                });
                return;
            }

            if ( datosFiscales.rfc  && !datosFiscales.rfc.match(rfcformat)) {  
                setSalvado(false);      
                setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, ingrese un RFC válido",
                });
                return;
            }


            if (!datosFiscales.colonia) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, inserte la colonia.",
                });
                return;
            }

            if (!datosFiscales.calle) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, inserte la calle.",
                });
                return;
            }

            if (!datosFiscales.numExterno) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, inserte el número exterior.",
                });
                return;
            }

            if (!datosFiscales.municipio) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, inserte el municipio.",
                });
                return;
            }

            if (!datosFiscales.idEstado && !datosFiscales.catEstado) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, seleccione el estado.",
                });
                return;
            }

            if (!datosFiscales.pais) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, inserte el país.",
                });
                return;
            }

            if (!datosFiscales.cp) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, inserte el código postal.",
                });
                return;
            }

            if (datosFiscales.cp && !datosFiscales.cp.toString().match(cpformat)) {
                setSalvado(false);
                setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, ingrese un código postal válido",
                });
                return;
            }

            if (!datosFiscales.regimenFiscal && !datosFiscales.idCatRegimenFiscal) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, seleccione el régimen fiscal.",
                });
                return;
            }

            if (!datosFiscales.catUsoCfdi && !datosFiscales.idCatUsoCfdi) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, seleccione el uso del CFDI.",
                });
                return;
            }

            if (!datosFiscales.idCatTipoCobro && !datosFiscales.idCatTipoCobro > 0) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, seleccione el tipo de cobro.",
                });
                return;
            }            


            if (datosFiscales.tipoPersona === undefined && idCliente != 1) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, seleccione con que datos fiscales quiere facturar",
                });
                return;
            }

            if (!salvado) {
                setSalvado(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, salve los datos fiscales para poder continuar.",
                });
                return;
            }
        }else{
            datosFiscales.rfc = "XAXX010101000"
            datosFiscales.regimenFiscal = "";
            datosFiscales.catEstado = null;
            datosFiscales.catUsoCfdi = null;
            datosFiscales.idDatoFiscal  = null;
            datosFiscales.nombre = "";
            datosFiscales.idCatPersona = null;
            datosFiscales.colonia = "";
            datosFiscales.municipio = "";
            datosFiscales.calle = "";
            datosFiscales.numExterno = "";
            datosFiscales.numInterno = "";
            datosFiscales.idEstado = null;
            datosFiscales.pais = "";
            datosFiscales.cp = "";
            datosFiscales.idCatCliente = null;
            datosFiscales.idCatRegimenFiscal = null;
            datosFiscales.idCatUsoCfdi = null;
            datosFiscales.idCatTipoCobro = 0;
            datosFiscales.tipoPersona = 2;
        }
        setLoading(true);

        const   res = await nuevaFactura({
                vales: idValesSeleccionados,
                ventasMostrador: idVentasMostradorSeleccionados,
                tipoGeneracionFactura: 1,
                publicoGeneral: datosFiscales.tipoPersona == 2,
                idCatMedico: idMedico,
                idCatAseguradora: idAseguradora,
                idCatCliente: idMedico? null : idCliente? idCliente : idCatClienteTemp ? idCatClienteTemp : null,
                idCatTipoCobro: datosFiscales.tipoPersona == 2? idTipoDeCobroGlobal : datosFiscales.idCatTipoCobro,
            })            

        if (res.data?.error || (res.data.length > 0 && res.data[0] === null)) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_datos_fiscales: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);
            setRespuestaFacturas(res.data);
            setUISweetAlert({
                ...UISweetAlert,
                confirm_datos_fiscales: false,
                respuestas_facturas: true,
            });

            fetchData({pageSize: 10, pageIndex: controlledPageIndex});
            limpiarState(
                setValesSeleccionados,
                setVentasMostradorSeleccionados,
                setIdValesSeleccionados,
                setIdVentasMostradorSeleccionados,
                setIdMedico,
                setIdAseguradora,
                setIdCliente,
                setDatosFiscales
            );
        }
        setPublicoGeneral(null);
    };

    const checkFacturaPPD = async () => {

        if(isAseguradora){
            let TodosNoGarantia = todosNoGarantia()

            if(!TodosNoGarantia){
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, seleccione solo vales de que no tengan garantía.",
                }); 
                return;
            }
        }

        const lengthVales = valesSeleccionados.length;
        const lengthVentasMostrador = ventasMostradorSeleccionados.length;
        if (lengthVales === 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione al menos un vale.",
            });
        }

        if (lengthVentasMostrador > 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione solamente vales.",
            });
        }

        if(valesSeleccionados.length > 0){
            if(valesSeleccionados[0].idCatTipoVale!=2){
                setIdTipoDeCobroGlobal(valesSeleccionados[0].idCatTipoCobro);
                setDatosFiscales({...datosFiscales,idCatTipoCobro: valesSeleccionados[0].idCatTipoCobro});
            }
        }


        if (lengthVales > 0 && lengthVentasMostrador === 0) {
            const isFacturaPPD = true;
            const validVales = await checkVales(isFacturaPPD);
            if (validVales) {
                // if (idAseguradora !== null) {
                //   return setUISweetAlert({
                //     ...UISweetAlert,
                //     select_desglose_ppd: false,
                //   });
                // }
                if(valesSeleccionados[0].tipoVale=="ASEGURADORA"){
                    setIdTipoDeCobroGlobal(6);
                    //setDatosFiscales({...datosFiscales,idCatTipoCobro: 6});
                }
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_datos_fiscales_ppd: true,
                });
            }
        }
    };

    const confirmNuevaFacturaPPD = async () => {
        /*if (datosFiscales.tipoPersona === undefined && idCliente != 1 && idAseguradora === null) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione con que datos fiscales quiere facturar",
            });
            return;
        }*/

        if (!datosFiscales.nombre) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, inserte la razon social.",
            });
            return;
        }

        if (!datosFiscales.correo) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, inserte el Email.",
            });
            return;
          }     
          
          if (datosFiscales.correo  && !datosFiscales.correo.match(mailformat)) {
            setSalvado(false);      
            setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Por favor, ingrese un Email de contacto válido",
            });
            return;
          }              

        if (!datosFiscales.rfc) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, inserte el RFC.",
            });
            return;
        }

        if ( datosFiscales.rfc  && !datosFiscales.rfc.match(rfcformat)) {  
            setSalvado(false);      
            setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Por favor, ingrese un RFC válido",
            });
            return;
        }


        if (!datosFiscales.colonia) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, inserte la colonia.",
            });
            return;
        }

        if (!datosFiscales.calle) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, inserte la calle.",
            });
            return;
        }

        if (!datosFiscales.numExterno) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, inserte el número exterior.",
            });
            return;
        }

        if (!datosFiscales.municipio) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, inserte el municipio.",
            });
            return;
        }

        if (!datosFiscales.idEstado && !datosFiscales.catEstado) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione el estado.",
            });
            return;
        }

        if (!datosFiscales.pais) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, inserte el país.",
            });
            return;
        }

        if (!datosFiscales.cp) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, inserte el código postal.",
            });
            return;
        }

        if (datosFiscales.cp && !datosFiscales.cp.toString().match(cpformat)) {
            setSalvado(false);
            setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Por favor, ingrese un código postal válido",
            });
            return;
        }

        if (!datosFiscales.regimenFiscal && !datosFiscales.idCatRegimenFiscal) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione el régimen fiscal.",
            });
            return;
        }

        if (!datosFiscales.catUsoCfdi && !datosFiscales.idCatUsoCfdi) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione el uso del CFDI.",
            });
            return;
        }

        if (!datosFiscales.idCatTipoCobro && !datosFiscales.idCatTipoCobro > 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione el tipo de cobro.",
            });
            return;
        }            


        /*if (datosFiscales.tipoPersona === undefined && idCliente != 1) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione con que datos fiscales quiere facturar",
            });
            return;
        }*/

        if (!salvado) {
            setSalvado(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, salve los datos fiscales para poder continuar.",
            });
            return;
        }

        setLoading(true);

        const res = await nuevaFactura({
            vales: idValesSeleccionados,
            ventasMostrador: idVentasMostradorSeleccionados,
            tipoGeneracionFactura: 2,
            publicoGeneral: datosFiscales.tipoPersona == 2,
            idCatMedico: idMedico,
            idCatAseguradora: idAseguradora,
            idCatCliente: idCliente,
            desglosada: isFacturaDesglosada,
            coaseguro: filtrosNuevaFactura.coaseguro,
            reembolso: filtrosNuevaFactura.reembolso,
        });

        if (res.data.error) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_datos_fiscales_ppd: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);
            setRespuestaFacturas(res.data);
            setUISweetAlert({
                ...UISweetAlert,
                confirm_datos_fiscales_ppd: false,
                respuestas_facturas: true,
            });
            // setInformacionAdicional(informacionAdicionalVacio);

            fetchData({pageSize: 10, pageIndex: controlledPageIndex});
            limpiarState(
                setValesSeleccionados,
                setVentasMostradorSeleccionados,
                setIdValesSeleccionados,
                setIdVentasMostradorSeleccionados,
                setIdMedico,
                setIdAseguradora,
                setIdCliente,
                setDatosFiscales
            );
        }
        setPublicoGeneral(null);
    };

    const onChangeDatosFiscales = (e) => {
        const {name, value} = e.target;
        setDatosFiscales({
            ...datosFiscales,
            [name]: value,
        });

        if(name!="tipoPersona" && name!="idCatTipoCobro"){
            setSalvado(false);
        }
    };

    const checkActualizarDatosFiscales = () => {
        setUISweetAlert({
            ...UISweetAlert,
            confirm_actualizar_datos_fiscales: true,
        });
    };

    const confirmActualizarDatosFiscalesAseguradora = async () => {
        setLoading(true);
        const datosAseguradora = {
            ...datosFiscales,
            idCatAseguradora: idAseguradora,
        };
        const res = await updateAseguradora(datosAseguradora);

        if (res.data.error) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_actualizar_datos_fiscales: false,
                dynamic_title: "Fallo",
                dynamic_description: "E",
            });
        } else if (res) {
            setLoading(false);
            setSalvado(true);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_actualizar_datos_fiscales: false,
                dynamic_description: "Se actualizaron correctamente los datos fiscales",
            });
        }
    };

    const confirmActualizarDatosFiscales = async () => {
        setLoading(true);

        const datosClienteVacio = {
            nombres: datosFiscales.nombre,
            apellidoPaterno: "",
            apellidoMaterno: "",
            rfc: datosFiscales.rfc,
            curp: "",
            telefonoFijo: "",
            telefonoCelular: "",
            correo: datosFiscales.correo,
            calle: datosFiscales.calle,
            numExterno: datosFiscales.numExterno,
            numInterno: datosFiscales.numInterno,
            colonia: datosFiscales.colonia,
            municipio: datosFiscales.municipio,
            /*estado: "",*/
            idEstado: datosFiscales.idEstado,
            pais: datosFiscales.pais,
            cp: datosFiscales.cp,
            idCatRegimenFiscal: datosFiscales.idCatRegimenFiscal,
            idCatUsoCfdi: datosFiscales.idCatUsoCfdi,
            idCatTipoCobro: datosFiscales.idCatTipoCobro,
          };     
          
        let permiso = false;  

        if(!idAseguradora && !idMedico && !idCliente && !datosFiscales.idCatPersona){
            permiso = true;
        }

        if(permiso){
            const res = await uploadNuevoCliente(datosClienteVacio);
            setIdCliente(res.data.idCatCliente);
            setDatosFiscales({...datosFiscales,idCatCliente: res.data.idCatCliente});

            if (res.data.error) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_actualizar_datos_fiscales: false,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else if (res) {
                setLoading(false);
                setSalvado(true);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_actualizar_datos_fiscales: false,
                    dynamic_description: "Se ha creado el cliente con sus datos fiscales correctamente",
                });
            }

        }else{           
            const res = await actualizarDatosFiscales({...datosFiscales, idCatEstado: datosFiscales.idEstado});

            if (res.data.error) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_actualizar_datos_fiscales: false,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else if (res) {
                setLoading(false);
                setSalvado(true);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_actualizar_datos_fiscales: false,
                    dynamic_description: "Se actualizaron correctamente los datos fiscales",
                });
            }
        }
    };

    const confirmExcluirGlobal = () => {
        if (idValesSeleccionados.length === 0 && idVentasMostradorSeleccionados.length === 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione al menos un vale o venta mostrador",
            });
            return;
        }
        setUISweetAlert({
            ...UISweetAlert,
            confirm_excluir_global: true,
        });
    };
    const confirmIncluirGlobal = () => {
        if (idValesSeleccionados.length === 0 && idVentasMostradorSeleccionados.length === 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione al menos un vale o venta mostrador",
            });
            return;
        }
        setUISweetAlert({
            ...UISweetAlert,
            confirm_incluir_global: true,
        });
    };

    const excluirDeFacturaGlobal = async () => {
        setLoading(true);

        const res = await setIncluirFacturaGlobal({
            vales: idValesSeleccionados,
            ventasMostrador: idVentasMostradorSeleccionados,
            incluirGlobal: false,
        });

        if (res.data.error) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_excluir_global: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_excluir_global: false,
                dynamic_description: "Se excluyeron correctamente de factura global",
            });
            fetchData({pageSize: 10, pageIndex: controlledPageIndex});
            setValesSeleccionados([]);
            setVentasMostradorSeleccionados([]);
            setIdValesSeleccionados([]);
            setIdVentasMostradorSeleccionados([]);
            setIdMedico(null);
            setIdAseguradora(null);
            setIdCliente(null);
        }
    };

    const incluirDeFacturaGlobal = async () => {
        setLoading(true);

        const res = await setIncluirFacturaGlobal({
            vales: idValesSeleccionados,
            ventasMostrador: idVentasMostradorSeleccionados,
            incluirGlobal: true,
        });

        if (res.data.error) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_incluir_global: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_incluir_global: false,
                dynamic_description: "Se incluyeron correctamente en factura global",
            });
            fetchData({pageSize: 10, pageIndex: controlledPageIndex});
            setValesSeleccionados([]);
            setVentasMostradorSeleccionados([]);
            setIdValesSeleccionados([]);
            setIdVentasMostradorSeleccionados([]);
            setIdMedico(null);
            setIdAseguradora(null);
            setIdCliente(null);
        }
    };


    const verificaExistenciaFacturasGlobal= async () => {
        let contador = 0;
        const prueba = ventasPaginadas.map((venta) => {
            if(venta.incluirGlobal == true){
                contador++;
            }
        })

        if (contador === 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "No hay vales de médicos externos, ni ventas mostrador que apliquen para la factura global.",
            });
            return false;
        }else{
            setUISweetAlert({
                ...UISweetAlert,
                advierte_nueva_factura_global: true,
            });
        }
    }

   /* ************************************************************************************************************ */
    const isFacturaGlobalValid = () => {
        if (idCatPeriodicidad === null) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione la periocidad.",
            });
            return false;
        }

        if (opcionesFacturaGlobal.meses === null) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione el mes o meses.",
            });
            return false;
        }

        if (opcionesFacturaGlobal.anio === null || opcionesFacturaGlobal.anio === undefined) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione el año.",
            });
            return false;
        }

        return true;
    };

    const confirmNuevaFacturaGlobal = async () => {
        const allComplete = isFacturaGlobalValid();

        if (allComplete) {
            setLoading(true);
            const res = await nuevaFacturaGlobal({...opcionesFacturaGlobal, idCatPeriodicidad: idCatPeriodicidad});
            if (res.data.error) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_nueva_factura_global: false,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
                setIdCatPeriodicidad(null);
                setOpcionesFacturaGlobal({anio: null, meses: null});
                setFechaFacturaGlobal(null);
            } else if (res) {
                setLoading(false);
                setRespuestaFacturas(res.data);
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_datos_fiscales_ppd: false,
                    respuestas_facturas: true,
                });

                fetchData({pageSize: 10, pageIndex: controlledPageIndex});
                limpiarState(
                    setValesSeleccionados,
                    setVentasMostradorSeleccionados,
                    setIdValesSeleccionados,
                    setIdVentasMostradorSeleccionados,
                    setIdMedico,
                    setIdAseguradora,
                    setIdCliente,
                    setDatosFiscales
                );
                setIdCatPeriodicidad(null);
                setOpcionesFacturaGlobal({anio: null, meses: null});
                setFechaFacturaGlobal(null);
            }
        } else return;
    };

    const changePeriodicidad = (e) => {
        setIdCatPeriodicidad(e.target.value);
    };

    const changeMesFacturaGlobal = (e) => {
        const {name, value} = e.target;
        setOpcionesFacturaGlobal({
            ...opcionesFacturaGlobal,
            [name]: value,
        });
    };
    const handleDateChange = (date) => {
        let year;
        try {
            year = date.getFullYear();
        } catch (error) {
        }
        setFechaFacturaGlobal(date);
        setOpcionesFacturaGlobal({
            ...opcionesFacturaGlobal,
            anio: year,
        });
    };

    // const descargarFacturaPDF = async (link, folio) => {
    //     setLoading(true);
    //     const res = await exportFactura(link);
    //
    //     if (res.data.error) {
    //         setLoading(false);
    //         setUISweetAlert({
    //             ...UISweetAlert,
    //             error_dlg: true,
    //             dynamic_title: "Fallo",
    //             dynamic_description: res.data.message,
    //         });
    //     } else if (res) {
    //         setLoading(false);
    //     }
    // };
    // const descargarFacturaXML = async (link, folio) => {
    //     setLoading(true);
    //     const res = await exportFacturaXML(link);
    //
    //     if (res.data.error) {
    //         setLoading(false);
    //         setUISweetAlert({
    //             ...UISweetAlert,
    //             error_dlg: true,
    //             dynamic_title: "Fallo",
    //             dynamic_description: res.data.message,
    //         });
    //     } else if (res) {
    //         setLoading(false);
    //     }
    // };

    const onChangeIsFacturaDesglosada = (e) => {
        const {value} = e.target;

        const booleanIsFactura = value === "true";
        setIsFacturaDesglosada(booleanIsFactura);
    };

    // const onChangeInformacionAdicional = (e) => {
    //   const { value, name } = e.target;

    //   setInformacionAdicional({
    //     ...informacionAdicional,
    //     [name]: value,
    //   });
    // };

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Nueva Factura</h3>
            <FiltroBusqueda
                toggleSearchBy={false}
                buscarPorFiltros={buscarPorFiltros}
                onChange={onChange}
                limpiarFiltros={limpiarFiltros}
                paramsInput={inputCols}
            />
            <h4 className="flex h4 mt-5">Lista de Vales / Ventas Mostrador</h4>
            <Row>
                <Col>
                    <Card className="mt-4">
                        <CardBody>
                            <Row>
                                <Col>
                                    {autorizado ? (
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            style={{
                                                marginRight: "2.5rem",
                                                marginTop: "1rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => checkFacturaDefinitiva()}>
                                            Generar Factura Definitiva
                                        </button>
                                    ) : null}
                                    {autorizado && !isFarmacia ? (
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            style={{
                                                marginRight: "2.5rem",
                                                marginTop: "1rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => checkFacturaPPD()}>
                                            Generar Factura PPD
                                        </button>
                                    ) : null}
                                    {isFarmacia || isContabilidad || isAdministrador ? (
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            style={{
                                                marginRight: "2.5rem",
                                                marginTop: "1rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => verificaExistenciaFacturasGlobal()}>
                                            Generar Factura Global
                                        </button>
                                    ) : null}
                                    {autorizado ? (
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            style={{
                                                marginRight: "2.5rem",
                                                marginTop: "1rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => confirmExcluirGlobal()}>
                                            Excluir de Factura Global
                                        </button>
                                    ) : null}
                                    {autorizado ? (
                                        <button
                                            className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                            type="button"
                                            style={{
                                                marginRight: "2.5rem",
                                                marginTop: "1rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() => confirmIncluirGlobal()}>
                                            Incluir en Factura Global
                                        </button>
                                    ) : null}
                                </Col>
                            </Row>
                            <TableComponent
                                pageCount={pageCount}
                                fetchData={fetchData}
                                columns={columns}
                                loading={loadingData}
                                data={ventasPaginadas}
                                indexOfPage={controlledPageIndex}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {UISweetAlert.confirm_datos_fiscales ? (
                <SweetAlert
                    title="Por favor, confirma todos los datos fiscales para la factura definitiva"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => confirmNuevaFacturaDefinitiva()}
                    onCancel={() => {
                        setDatosFiscales({})
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_datos_fiscales: false,
                        });
                    }}>
                    Complete los datos fiscales con los que se deberá facturar
                    <ModalDatosFiscales
                        catRegimenFiscales={catRegimenFiscales}
                        catUsoCfdis={catUsoCfdis}
                        tipoPersona={{idCatMedico: idMedico, idCatCliente: idCliente, idAseguradora: idAseguradora}}
                        datosFiscales={datosFiscales}
                        listaTipoCobro = {tipoCobros}
                        clientes = {clientes}
                        IdTipoDeCobroGlobal = {idTipoDeCobroGlobal}
                        onChange={onChangeDatosFiscales}
                        actualizarDatosFiscales={checkActualizarDatosFiscales}
                        lugares={lugares}
                        publicoGeneral={publicoGeneral == null}
                    />
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_datos_fiscales_ppd ? (
                <SweetAlert
                    title="Por favor, confirma todos los datos fiscales para la factura PPD"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => confirmNuevaFacturaPPD()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_datos_fiscales_ppd: false,
                        });
                        // setInformacionAdicional(informacionAdicionalVacio);
                    }}>
                    Complete los datos fiscales con los que se deberá facturar
                    <ModalDatosFiscales
                        catRegimenFiscales={catRegimenFiscales}
                        catUsoCfdis={catUsoCfdis}
                        tipoPersona={{idCatMedico: idMedico, idCatCliente: idCliente, idAseguradora: idAseguradora}}
                        datosFiscales={datosFiscales}
                        onChange={onChangeDatosFiscales}
                        listaTipoCobro = {tipoCobros}
                        IdTipoDeCobroGlobal = {idTipoDeCobroGlobal}
                        setDatosFiscales = {setDatosFiscales}
                        actualizarDatosFiscales={checkActualizarDatosFiscales}
                        confirmActualizarDatosFiscalesAseguradora={confirmActualizarDatosFiscalesAseguradora}
                        lugares={lugares}
                        publicoGeneral={publicoGeneral == null}
                        isPPD={true}
                        // informacionAdicional={informacionAdicional}
                        // onChangeInformacionAdicional={onChangeInformacionAdicional}
                    />
                </SweetAlert>
            ) : null}

            {UISweetAlert.select_desglose_ppd ? (
                <SweetAlert
                    title="¿Quieres desglosar el coaseguro y deducible en la factura?"
                    showCancel
                    showConfirm
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_datos_fiscales_ppd: true,
                            select_desglose_ppd: false,
                        });
                    }}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            select_desglose_ppd: false,
                        });
                    }}>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input h-4 w-4 mt-1 align-top float-left mr-2 cursor-pointer"
                            type="radio"
                            onChange={onChangeIsFacturaDesglosada}
                            checked={isFacturaDesglosada}
                            name="isFacturaDesglosada"
                            value={true}
                        />
                        <label className="form-check-label inline-block text-gray-800">Si</label>
                    </div>

                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input h-4 w-4 mt-1 align-top float-left mr-2 cursor-pointer"
                            type="radio"
                            onChange={onChangeIsFacturaDesglosada}
                            checked={!isFacturaDesglosada}
                            name="isFacturaDesglosada"
                            value={false}
                        />
                        <label className="form-check-label inline-block text-gray-800">No</label>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_actualizar_datos_fiscales ? (
                <SweetAlert
                    title="¿Desea actualizar los datos fiscales?"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => confirmActualizarDatosFiscales()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_actualizar_datos_fiscales: false,
                        });
                    }}
                />
            ) : null}

            {UISweetAlert.confirm_excluir_global ? (
                <SweetAlert
                    title="¿Desea excluir los vales y ventas seleccionadas?"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => excluirDeFacturaGlobal()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_excluir_global: false,
                        });
                    }}
                />
            ) : null}

            {UISweetAlert.confirm_incluir_global ? (
                <SweetAlert
                    title="¿Desea incluir los vales y ventas seleccionadas?"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => incluirDeFacturaGlobal()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_incluir_global: false,
                        });
                    }}
                />
            ) : null}
            
            {UISweetAlert.advierte_nueva_factura_global ? (
                <SweetAlert
                    title="Esta a punto de generar una factura global. ¿Desea continuar?"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    style={{
                        width:"50%",
                    }}
                    onConfirm={() =>         
                        setUISweetAlert({
                        ...UISweetAlert,
                        confirm_nueva_factura_global: true,
                        advierte_nueva_factura_global:false,
                    })}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            advierte_nueva_factura_global: false,
                        });
                    }}
                />
            ) : null}

            {UISweetAlert.confirm_nueva_factura_global ? (
                <SweetAlert
                    title="Factura global"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "800%" : "60%",
                        height: "60%",
                    }}
                    onConfirm={() => confirmNuevaFacturaGlobal()}
                    onCancel={() => {
                        setIdCatPeriodicidad(null);
                        setOpcionesFacturaGlobal({anio: null, meses: null});
                        setFechaFacturaGlobal(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_nueva_factura_global: false,
                        });
                    }}>
                    Por favor, elige la periodicidad, mes y año para la factura global
                    <CardBody className="mt-5">
                        <Row>
                            <label className="col-sm-2 col-form-label">
                                Periodicidad<span className="text-xs text-red-700">*</span>
                            </label>
                            <Col sm={5}>
                                <select className="form-control" name="idCatLaboratorio" onChange={changePeriodicidad} value={idCatPeriodicidad}>
                                    <option selected disabled value="">
                                        Seleccione una opción
                                    </option>
                                    {catPeriodicidad.map((periodicidad) => {
                                        return <option value={periodicidad.idCatPeriodicidad}>{periodicidad.periodicidad}</option>;
                                    })}
                                </select>
                                {(idCatPeriodicidad == "" || !idCatPeriodicidad) && (
                                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <label className="col-sm-2 col-form-label">
                                Mes<span className="text-xs text-red-700">*</span>
                            </label>
                            <Col sm={4}>
                                <select
                                    className="form-control"
                                    name="meses"
                                    onChange={changeMesFacturaGlobal}
                                    value={opcionesFacturaGlobal.meses ? opcionesFacturaGlobal.meses : ""}>
                                    <option selected disabled value="">
                                        Seleccione una opción
                                    </option>
                                    {mesesFacturacionGlobal.map((mes) => {
                                        return <option value={mes.meses}>{mes.descripcion}</option>;
                                    })}
                                </select>
                                {(opcionesFacturaGlobal.meses == "" || !opcionesFacturaGlobal.meses) && (
                                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                )}
                            </Col>
                            <label className="col-sm-1 col-form-label">
                                Año<span className="text-xs text-red-700">*</span>
                            </label>
                            <Col sm={2}>
                                <DatePicker
                                    className="form-control-inputs"
                                    selected={fechaFacturaGlobal}
                                    name="anio"
                                    onChange={(date) => handleDateChange(date)}
                                    dateFormat="yyyy"
                                    showYearPicker
                                    type="date"
                                    minDate={minYear}
                                    maxDate={maxYear}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                     }}
                                />
                                <br></br>
                                {(fechaFacturaGlobal == "" || !fechaFacturaGlobal) && (
                                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </SweetAlert>
            ) : null}

            {UISweetAlert.respuestas_facturas ? (
                <SweetAlert
                    title="Resultados de Facturación"
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => {
                        setRespuestaFacturas(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            respuestas_facturas: false,
                            confirm_nueva_factura_global : false,
                        });
                    }}
                    onCancel={() => {
                        setRespuestaFacturas(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            respuestas_facturas: false,
                            confirm_nueva_factura_global : false,
                        });
                    }}>
                    <RespuestaFacturas
                        respuestas={respuestaFacturas}
                        downloadFactura={downloadFactura}
                        // descargarFactura={{descargarFacturaPDF: descargarFacturaPDF, descargarFacturaXML: descargarFacturaXML}}
                    />
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.reload_page) {
                            window.location.reload();
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                            reload_page: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
};

export default NuevaFactura;

function limpiarState(
    setValesSeleccionados,
    setVentasMostradorSeleccionados,
    setIdValesSeleccionados,
    setIdVentasMostradorSeleccionados,
    setIdMedico,
    setIdAseguradora,
    setIdCliente,
    setDatosFiscales
) {
    setValesSeleccionados([]);
    setVentasMostradorSeleccionados([]);
    setIdValesSeleccionados([]);
    setIdVentasMostradorSeleccionados([]);
    setIdMedico(null);
    setIdAseguradora(null);
    setIdCliente(null);
    setDatosFiscales({});
}

// const handdleCheckAllRows = (e) => {
//   console.log(e.target.checked);
//   const allVentasCheckedTemp = !allVentasChecked;
//   setAllVentasChecked(allVentasCheckedTemp);
//   console.log(valesPaginados);
//   console.log(ventasMostradorPaginadas);
//   let todosVales = ventasPaginadas.map((vale) => (vale.idVale != null ? vale.idVale : null));
//   let todasVentas = ventasPaginadas.map((venta) => (venta.idVentaMostrador != null ? venta.idVentaMostrador : null));

//   // todosVales = todosVales.filter((idVale) => idVale !== null);
//   // todasVentas = todasVentas.filter((idVentaMostrador) => idVentaMostrador !== null);

//   console.log(todosVales);
//   console.log(todasVentas);
// };

// const handdleCheckAllRows = useCallback(
//   (e) => {
//     const allVentasCheckedTemp = !allVentasChecked;
//     setAllVentasChecked(allVentasCheckedTemp);

//     if (allVentasCheckedTemp) {
//       let todosIdVales = ventasPaginadas.map((vale) => (vale.idVale != null ? vale.idVale : null));
//       let todasIdVentas = ventasPaginadas.map((venta) => (venta.idVentaMostrador != null ? venta.idVentaMostrador : null));

//       todosIdVales = todosIdVales.filter((idVale) => idVale !== null);
//       todasIdVentas = todasIdVentas.filter((idVentaMostrador) => idVentaMostrador !== null);

//       setIdValesSeleccionados([...valesSeleccionados, ...todosIdVales]);
//       setIdVentasMostradorSeleccionados([...ventasMostradorSeleccionados, ...todasIdVentas]);
//       console.log();
//     } else {
//       setIdValesSeleccionados([]);
//       setIdVentasMostradorSeleccionados([]);
//     }
//   },
//   [valesSeleccionados, ventasMostradorSeleccionados, ventasPaginadas, allVentasChecked]
// );
