/* eslint-disable import/no-anonymous-default-export */
import { LISTAR_MEDICOS, FILTRO_MEDICOS, LISTAR_MEDICOS_ACTIVOS, ID_MEDICO_SELECCIONADO, LISTAR_MEDICOS_INTERNOS, LISTAR_MEDICOS_AGENDA, SET_MEDICO } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_MEDICOS:
      return {
        ...state,
        medicos: action.payload,
      };
    case LISTAR_MEDICOS_ACTIVOS:
      return {
        ...state,
        medicosActivos: action.payload,
      };      
    case FILTRO_MEDICOS:
       return {
         ...state,
         filtrosMedicos: action.payload,
       };  
    case ID_MEDICO_SELECCIONADO:
      localStorage.setItem('idCatMedicoSeleccionado', action.payload)
      return{
        ...state,
        idCatMedicoSeleccionado: action.payload,
        flagMedicoSeleccionado: true,
      };
    case LISTAR_MEDICOS_INTERNOS:
      return {
        ...state,
        medicosInternos: action.payload,
      };
    case LISTAR_MEDICOS_AGENDA:
      return {
        ...state,
        medicosAgenda: action.payload,
      };
    case SET_MEDICO:
      return {
        ...state,
        medico: action.payload,
      };
    default:
      return state;
  }
};
