import React, { useReducer } from "react";

import ContabilidadFacturasContext from "./ContabilidadFacturasContext";
import ContabilidadFacturasReducer from "./ContabilidadFacturasReducer";

import { FILTRO_NUEVA_FACTURA, SET_VALES_PAGINADOS } from "../../types";

import clienteAxios from "../../config/axios";

export default function ContabilidadFacturasState(props) {
  const initialState = {
    filtrosNuevaFactura: {},
    ventasPaginadas: [],
  };

  const [state, dispatch] = useReducer(ContabilidadFacturasReducer, initialState);

  const setVentasPaginadas = async ({ page, limit }) => {
    try {
      const response = await clienteAxios.post(
        "/factura/ventas/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          ...state.filtrosNuevaFactura,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const vales = response.data.valesPaginadoVo.valesVoList;
      const ventasMostrador = response.data.ventaMostradorPaginadoVo.ventaMostradorVoList;
      ventasMostrador.forEach((venta) => (venta.fechaVenta = venta.fechaVenta.split("T")[0]));

      dispatch({
        type: SET_VALES_PAGINADOS,
        payload: [...vales, ...ventasMostrador],
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const setFiltrosNuevaFactura = async (filtrosNuevaFactura) => {
    dispatch({
      type: FILTRO_NUEVA_FACTURA,
      payload: filtrosNuevaFactura,
    });
  };

  const nuevaFactura = async (datosNuevaFactura) => {
    console.log(datosNuevaFactura);
    try {
      const res = await clienteAxios.post(`/factura/nueva`, datosNuevaFactura, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  // Variables meses y año
  const nuevaFacturaGlobal = async (facturaVo) => {
    try {
      const res = await clienteAxios.post(`/factura/nueva/global`, facturaVo, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setIncluirFacturaGlobal = async (datos) => {
    try {
      const res = await clienteAxios.post(`/factura/incluir/global`, datos, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const actualizarDatosFiscales = async (datosFiscales) => {
    try {
      const res = await clienteAxios.post(`/datosfiscales/guardar`, datosFiscales, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const exportFactura = async (link) => {
    try {
      const res = await clienteAxios.post(
        `/factura/export`,
        {
          nombreArchivo: link,
        },
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Disposition": "attachment; filename=factura.pdf",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      var file = new Blob([res.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  const exportFacturaXML = async (link) => {
    try {
      const res = await clienteAxios.post(
        `/factura/export`,
        {
          nombreArchivo: link,
        },
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Disposition": "attachment; filename=factura.xml",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      var filename = "factura.xml";
      var pom = document.createElement("a");
      var bb = new Blob([res.data], { type: "text/plain" });

      pom.setAttribute("href", window.URL.createObjectURL(bb));
      pom.setAttribute("download", filename);

      pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":");
      pom.draggable = true;
      pom.classList.add("dragout");

      pom.click();

      // var file = new Blob([res.data], { type: "application/pdf" });
      // var fileURL = URL.createObjectURL(file);
      // window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <ContabilidadFacturasContext.Provider
      value={{
        filtrosNuevaFactura: state.filtrosNuevaFactura,
        ventasPaginadas: state.ventasPaginadas,
        actualizarDatosFiscales,
        setFiltrosNuevaFactura,
        nuevaFactura,
        setVentasPaginadas,
        setIncluirFacturaGlobal,
        nuevaFacturaGlobal,
        exportFactura,
        exportFacturaXML,
      }}>
      {props.children}
    </ContabilidadFacturasContext.Provider>
  );
}
