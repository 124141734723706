import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import QuimioContext from "../../context/QuimioContext/QuimioContext";
import ExpedienteMedicoContext from "../../context/ExpedienteMedico/ExpedienteMedicoContext";

import PacientesContext from "../../context/Pacientes/PacientesContext";

import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import SexosContext from "../../context/Sexos/SexosContext";

import RegistroMarcadores from "../RegistroMarcadores/index";

import DrawerExpedientePaciente from "../DrawerExpedientePaciente/index";

import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";

import TableComponent from "../TablaPaginada/index";
import { CargaArchivoModal } from "../CargaArchivoModal";

import useWindowDimensions from "../../hooks/dimensionsHook";

import { calcularSCT, calcularIMC } from "../../utils/calcularInformacionFisica";

const NotaEnfermeria = (props) => {
  const { idExpedienteMedico } = useParams();
  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const expedienteMedicoContext = useContext(ExpedienteMedicoContext);
  const { exportArchivoBack, imprimirExpedienteMedico } = expedienteMedicoContext;

  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  const { width } = useWindowDimensions();

  const marcadoresVacio = {
    talla: null,
    peso: null,
    frecuenciaCardiaca: null,
    frecuenciaRespiratoria: null,
    saturacionOxigeno: null,
    temperatura: null,
    tensionArterialDiastolica: null,
    tensionArterialSistolica: null,
    indiceMasaCorporal: null,
    superficieCorporalTotal: null,
  };

  const [marcadores, setMarcadores] = useState(marcadoresVacio);

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }, []);

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado, guardarMarcadores } = pacientesContext;

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  useEffect(() => {
    const fetchAll = () => {
      listarSexos();
      listarAseguradoras();
      listarEscolaridades();
      listarEstadosCiviles();
      listarLugares();
      listarOcupaciones();
      listarMedicos();
    };
    fetchAll();
  }, []);

  const quimioContext = useContext(QuimioContext);

  const { setDataNotaEnfermeria, uploadDocumentoFirmado, notasEnfermeriaPaginadas, eliminarNota } = quimioContext;

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_cargar_archivo: false,
    confirm_eliminar_nota: false,
    confirm_alert_registrar_marcadores: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [controlledPageIndex, setControlledPageIndex] = useState(0);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);
  const [notaSeleccionada, setNotaSeleccionada] = useState(null);

  // const [data, setData] = useState([
  //   {
  //     fechaRegistro: "",
  //     documento: "",
  //   },
  // ]);

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;

  const datosCargaArchivoVacio = {
    fecha: today,
    selectedFile: null,
    comentario: null,
  };

  const [datosCargaArchivo, setDatosCargaArchivo] = useState({
    fecha: today,
    selectedFile: null,
    comentario: null,
  });

  const onFileChange = (event) => {
    setDatosCargaArchivo({ ...datosCargaArchivo, selectedFile: event.target.files[0] });
    const file = event.target.files[0];
    const reader = new FileReader();

    // convert image file to base64 string

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string

        let strFile = reader.result;
        let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
        let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);
        setDatosCargaArchivo({
          ...datosCargaArchivo,
          selectedFile: file,
          nombreArchivo: file.name,
          mimeType: type,
          tamano: file.size,
          inputStream: fileBase64,
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit }) => {
    try {
      const response = await setDataNotaEnfermeria({ page, limit, idExpedienteMedico: idExpedienteMedico });

      // const data = response.consentimientos;
      const count = response.count;
      // setData(data);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }
      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    console.log("fetchData is being called");
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server.
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const handdleCheckedRow = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setNotaSeleccionada(row.original);
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setNotaSeleccionada(null);
      } else {
        setRowSeleccionada(row.id);
        setNotaSeleccionada(row.original);
      }
    },
    [rowSeleccionada]
  );

  const exportArchivo = async (archivo) => {
    // convert image file to base64 string

    const archivoVo = {
      rutaArchivo: archivo.rutaArchivo,
      nombreArchivo: archivo.nombreArchivo,
      mimeType: archivo.mimeType,
    };
    const res = await exportArchivoBack(archivoVo);
    return res;
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idEnfermeria",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedRow(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Fecha de Registro", accessor: "fechaRegistro", show: true },
      { Header: "Comentario", accessor: "comentario", show: true },
      {
        Header: "Documento",
        Cell: ({ row }) => {
          return (
            <button
              className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-2 mt-2"
              onClick={() => exportArchivo(row.original.archivosEnfermeria[0])}
              type="button">
              {row.original.archivosEnfermeria[0].nombreArchivo}
            </button>
          );
        },
        show: true,
      },
      {
        Header: "Imprimir",
        Cell: ({ row }) => {
          return (
            <button
              className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-2 mt-2"
              onClick={() => imprimirNotaEnfermeria(row.original)}
              type="button">
              Imprimir
            </button>
          );
        },
        show: true,
      },
    ],
    [rowSeleccionada, handdleCheckedRow]
  );
  const imprimirNotaEnfermeria = async (notaEnfermeria) => {
    const expedienteImpresionVo = {
      imprimirNotasEnfermeria: true,
      idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      idCatPaciente: historiaClinicaInicial.paciente.idCatPaciente,
      idEnfermeria: notaEnfermeria.idEnfermeria,
      idCatMedico: usuario.idCatMedico,
    };
    setLoading(true);
    try {
      const res = await imprimirExpedienteMedico(expedienteImpresionVo);
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
    // const res = await

    // return res;
  };

  const autorizado = usuario.authorities.includes("ROLE_QUIMIO");

  const changeCargaArchivo = (e) => {
    const { name, value } = e.target;
    setDatosCargaArchivo({
      ...datosCargaArchivo,
      [name]: value,
    });
  };

  const checkCargarDocumento = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_cargar_archivo: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Lo siento, no cuenta con los permisos necesarios para esta acción",
      });
    }
  };

  const validarDatosArchivo = () => {
    let errorDatosArchivo = false;
    if (!errorDatosArchivo && datosCargaArchivo.selectedFile === null) {
      errorDatosArchivo = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, cargue un archivo de evidencia",
      });
    }
    if (!errorDatosArchivo && (datosCargaArchivo.comentario === null || datosCargaArchivo.comentario === "")) {
      errorDatosArchivo = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un comentario",
      });
    }
    return errorDatosArchivo;
  };

  const onFileUpload = async () => {
    const datosValidos = validarDatosArchivo();
    if (!datosValidos) {
      delete datosCargaArchivo.selectedFile;
      const res = await uploadDocumentoFirmado({ ...datosCargaArchivo, idExpedienteMedico: idExpedienteMedico });
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_cargar_archivo: false,
        dynamic_description: "Se guardó correctamente el archivo",
      });
      fetchData({ pageSize: 10, pageIndex: 0 });
      setDatosCargaArchivo(datosCargaArchivoVacio);

      return res;
    }
  };

  const infoSweetAlert = { title: "Cargar Documento", confirmBtnText: "Guardar", cancelBtnText: "Cancelar" };

  const onCancel = () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_cargar_archivo: false,
    });
    setDatosCargaArchivo(datosCargaArchivoVacio);
  };

  // const exportFormato = () => {};

  const checkEliminarNota = () => {
    if (autorizado && notaSeleccionada) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_nota: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione una nota de enfermería",
      });
    }
  };

  const eliminarNotaSeleccionada = async () => {
    setLoading(true);
    const res = await eliminarNota(notaSeleccionada);

    if (res === undefined) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_nota: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error con la conexión al servidor",
      });
      return;
    } else if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_nota: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_nota: false,
        dynamic_description: "Se eliminó la nota correctamente",
      });

      fetchData({ pageSize: 10, pageIndex: 0, byBusquedaFiltros: true });
      setNotaSeleccionada(null);
      setRowSeleccionada(null);
    }
  };

  const redirectHistoriaClinicaInicial = async () => {
    props.history.push("/historia/clinica/inicial");
  };

  const onChangeMarcadores = (e) => {
    const { name, value } = e.target;
    if (name == "talla") {
      const sct = calcularSCT(value, marcadores.peso ? marcadores.peso : null);
      const imc = calcularIMC(value, marcadores.peso ? marcadores.peso : null);
      setMarcadores({
        ...marcadores,
        talla: value,
        superficieCorporalTotal: sct,
        indiceMasaCorporal: imc,
      });
    } else if (name == "peso") {
      const sct = calcularSCT(marcadores.talla ? marcadores.talla : null, value);
      const imc = calcularIMC(marcadores.talla ? marcadores.talla : null, value);
      setMarcadores({
        ...marcadores,
        peso: value,
        superficieCorporalTotal: sct,
        indiceMasaCorporal: imc,
      });
    } else
      setMarcadores({
        ...marcadores,
        [name]: value,
      });
  };

  const updateMarcadores = async () => {
    setLoading(true);
    try {
      const idCatPaciente =
        historiaClinicaInicial && historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idCatPaciente
          ? historiaClinicaInicial.paciente.idCatPaciente
          : null;
      const PacienteMarcadorSignosVitalesVo = {
        idCatPaciente: idCatPaciente,
        ...marcadores,
      };
      const marcadoresGuardados = await guardarMarcadores(PacienteMarcadorSignosVitalesVo);
      setMarcadores(marcadoresVacio);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_registrar_marcadores: false,
        dynamic_description: "Marcadores guardados correctamente",
      });
      setLoading(false);
      return marcadoresGuardados;
    } catch (error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Error al guardar los marcadores, intentalo más tarde.",
      });
      return error;
    }
  };

  const registrarMarcadoresConfirm = async () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_registrar_marcadores: true,
    });
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Nota de Enfermería</h3>
      <DrawerExpedientePaciente
        historiaClinicaInicial={historiaClinicaInicial}
        sexos={sexos}
        aseguradoras={aseguradoras}
        medicos={medicos}
        lugares={lugares}
        ocupaciones={ocupaciones}
        estadosCiviles={estadosCiviles}
        escolaridades={escolaridades}
      />
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    onClick={redirectHistoriaClinicaInicial}>
                    Historia Clínica Inicial
                  </button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkCargarDocumento()}>
                      Cargar Documento
                    </button>
                  ) : null}

                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarNota()}>
                      Eliminar Nota
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      hidden={
                        !(usuario.authorities.includes("ROLE_QUIMIO") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA"))
                      }
                      onClick={registrarMarcadoresConfirm}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Registrar Signos Vitales
                    </button>
                  ) : null}
                </Col>
              </Row>
              <h4 className="flex h4 mt-5">Lista de Notas de Enfermería</h4>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={notasEnfermeriaPaginadas}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_cargar_archivo ? (
        <CargaArchivoModal
          datosCargaArchivo={datosCargaArchivo}
          setDatosCargaArchivo={setDatosCargaArchivo}
          infoSweetAlert={infoSweetAlert}
          onFileUpload={onFileUpload}
          onCancel={onCancel}
          onFileChange={(e) => onFileChange(e)}
          onChange={(e) => changeCargaArchivo(e)}
          conComentario={true}
        />
      ) : null}
      {UISweetAlert.confirm_eliminar_nota ? (
        <SweetAlert
          title="¿Desea eliminar la nota seleccionada?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => eliminarNotaSeleccionada()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_nota: false,
            });
          }}></SweetAlert>
      ) : null}
      {UISweetAlert.confirm_alert_registrar_marcadores ? (
        <SweetAlert
          title="Registro de Signos Vitales"
          showCancel
          confirmBtnText="Guardar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "90%",
          }}
          onConfirm={() => updateMarcadores()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_registrar_marcadores: false,
            });
            setMarcadores(marcadoresVacio);
          }}>
          <>
            <Row className="mb-5">
              <Col>
                {historiaClinicaInicial && historiaClinicaInicial.paciente
                  ? historiaClinicaInicial.paciente.nombres +
                    " " +
                    historiaClinicaInicial.paciente.apellidoPaterno +
                    " " +
                    historiaClinicaInicial.paciente.apellidoMaterno
                  : ""}
              </Col>
            </Row>
            {<RegistroMarcadores marcadores={marcadores} onChange={onChangeMarcadores} />}
          </>
        </SweetAlert>
      ) : null}
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default NotaEnfermeria;
