import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import formatNumber from "../../../utils";

import CortesCajaContext from "../../../context/CortesCaja/CortesCajaContext";

export default function DetalleCorteCajaRecepcion(props) {
  // const [toggleSearchByVales, setToggleSearchByVales] = useState(false);
  // const [toggleSearchByVentaMostrador, setToggleSearchByVentaMostrador] = useState(false);
  const [detallesFiltradosCorteCaja, setDetallesFiltradosCorteCaja] = useState();
  const [totalesVales, setTotalesVales] = useState({
    totalEfectivoFisico: 0,
    totalEfectivoDeposito: 0,
    totalCredito: 0,
    totalDebito: 0,
    totalTransferencia: 0,
    totalImportes: 0,
  });
  const [totalesAseguradoras, setTotalesAseguradoras] = useState({
    totalEfectivoFisico: 0,
    totalEfectivoDeposito: 0,
    totalCredito: 0,
    totalDebito: 0,
    totalTransferencia: 0,
    totalImportes: 0,
  });

  const cortesCajaContext = useContext(CortesCajaContext);

  const { setDetallesCorteCaja } = cortesCajaContext;

  const setTotalesValesFiltrados = async (vales) => {
    let accEfectivo = 0;
    let accEfectivoDeposito = 0;
    let accCredito = 0;
    let accDebito = 0;
    let accTransferencia = 0;
    let accImportesTotales = 0;
    vales.map((vale) => {
      accEfectivo += vale.importeEfectivo;
      accEfectivoDeposito += vale.importeEfectivoDeposito;
      accCredito += vale.importeTarjetaCredito;
      accDebito += vale.importeTarjetaDebito;
      accTransferencia += vale.importeTransferencia;
      accImportesTotales += vale.importeTotal;
    });
    setTotalesVales({
      totalEfectivoFisico: accEfectivo,
      totalEfectivoDeposito: accEfectivoDeposito,
      totalCredito: accCredito,
      totalDebito: accDebito,
      totalTransferencia: accTransferencia,
      totalImportes: accImportesTotales,
    });
  };

  const setTotalesAseguradorasFiltradas = async (aseguradoras) => {
    let accEfectivo = 0;
    let accEfectivoDeposito = 0;
    let accCredito = 0;
    let accDebito = 0;
    let accTransferencia = 0;
    let accImportesTotales = 0;
    aseguradoras &&
      aseguradoras.map((aseguradora) => {
        accEfectivo += aseguradoras.importeEfectivo;
        accEfectivoDeposito += aseguradoras.importeEfectivoDeposito;
        accCredito += aseguradoras.importeTarjetaCredito;
        accDebito += aseguradoras.importeTarjetaDebito;
        accTransferencia += aseguradoras.importeTransferencia;
        accImportesTotales += aseguradoras.importeTotal;
      });
    setTotalesAseguradoras({
      totalEfectivoFisico: accEfectivo,
      totalEfectivoDeposito: accEfectivoDeposito,
      totalCredito: accCredito,
      totalDebito: accDebito,
      totalTransferencia: accTransferencia,
      totalImportes: accImportesTotales,
    });
  };

  useEffect(() => {
    async function getDetalles() {
      let res = await setDetallesCorteCaja(props.idCorteCaja, props.usuarios);
      setDetallesFiltradosCorteCaja(res);
      if (res.vale) {
        setTotalesValesFiltrados(res.vales);
      }

      if (res.aseguradoras) {
        setTotalesAseguradorasFiltradas(res.aseguradoras);
      }
    }

    getDetalles();
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Detalle del Corte de Caja (Recepción)</h3>

        <h4 className="flex mt-5">Detalles de Movimientos (Vales)</h4>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">
                          Fecha y<br></br>Hora
                        </th>
                        <th scope="col">Vale</th>
                        <th scope="col">Médico</th>

                        <th scope="col">Paciente</th>
                        <th scope="col">Efectivo Físico</th>
                        <th scope="col">
                          Efectivo Depósito<br></br>en Cuenta
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Crédito
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Débito
                        </th>
                        <th scope="col">Transferencia</th>
                        <th scope="col">
                          Importe<br></br>Total
                        </th>
                        <th scope="col">Comentario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.vales
                        ? detallesFiltradosCorteCaja.vales.map((vale, index) => {
                            return (
                              <>
                                <tr key={vale.idVale}>
                                  <td>{vale.fechaHora ? vale.fechaHora : ""}</td>
                                  <td>{vale.folio ? vale.folio : ""}</td>
                                  <td>{vale.medico ? vale.medico : ""}</td>

                                  <td>{vale.paciente ? vale.paciente : ""}</td>
                                  <td>{vale.importeEfectivoFisico ? formatNumber(vale.importeEfectivoFisico) : "$0"}</td>
                                  <td>{vale.importeEfectivoDeposito ? formatNumber(vale.importeEfectivoDeposito) : "$0"}</td>
                                  <td>{vale.importeTarjetaCredito ? formatNumber(vale.importeTarjetaCredito) : "$0"}</td>
                                  <td>{vale.importeTarjetaDebito ? formatNumber(vale.importeTarjetaDebito) : "$0"}</td>

                                  <td>{vale.importeTransferencia ? formatNumber(vale.importeTransferencia) : "$0"}</td>
                                  <td>{vale.importeTotal ? formatNumber(vale.importeTotal) : "$0"}</td>

                                  <td>{vale.comentario ? vale.comentario : ""}</td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                    <thead>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.vales ? (
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">TOTALES</th>
                          <th scope="col">
                            {totalesVales.totalEfectivoFisico ? formatNumber(totalesVales.totalEfectivoFisico) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVales.totalEfectivoDeposito ? formatNumber(totalesVales.totalEfectivoDeposito) : "$0"}
                          </th>
                          <th scope="col">{totalesVales.totalCredito ? formatNumber(totalesVales.totalCredito) : "$0"}</th>
                          <th scope="col">{totalesVales.totalDebito ? formatNumber(totalesVales.totalDebito) : "$0"}</th>
                          <th scope="col">
                            {totalesVales.totalTransferencia ? formatNumber(totalesVales.totalTransferencia) : "$0"}
                          </th>
                          <th scope="col">{totalesVales.totalImportes ? formatNumber(totalesVales.totalImportes) : "$0"}</th>
                        </tr>
                      ) : null}
                    </thead>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <h4 className="flex mt-5">Detalles de Movimientos (Aseguradora)</h4>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">
                          Fecha y<br></br>Hora
                        </th>
                        <th scope="col">Vale</th>
                        <th scope="col">Aseguradora</th>
                        <th scope="col">Paciente</th>
                        <th scope="col">Movimiento</th>
                        <th scope="col">
                          Efectivo<br></br>Físico
                        </th>
                        <th scope="col">
                          Efectivo Depósito<br></br>en Cuenta
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Crédito
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Débito
                        </th>
                        <th scope="col">Transferencia</th>
                        <th scope="col">
                          Importe<br></br>Total
                        </th>
                        <th scope="col">Comentario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.aseguradoras
                        ? detallesFiltradosCorteCaja.aseguradoras.map((aseguradora, index) => {
                            return (
                              <>
                                <tr key={aseguradora.idAseguradora}>
                                  <td>{aseguradora.fechaHora ? aseguradora.fechaHora : ""}</td>
                                  <td>{aseguradora.folio ? aseguradora.folio : ""}</td>
                                  <td>{aseguradora.aseguradora ? aseguradora.aseguradora : ""}</td>

                                  <td>{aseguradora.paciente ? aseguradora.paciente : ""}</td>
                                  <td>{aseguradora.movimiento ? aseguradora.movimiento : ""}</td>
                                  <td>
                                    {aseguradora.importeEfectivoFisico ? formatNumber(aseguradora.importeEfectivoFisico) : "$0"}
                                  </td>
                                  <td>
                                    {aseguradora.importeEfectivoDeposito
                                      ? formatNumber(aseguradora.importeEfectivoDeposito)
                                      : "$0"}
                                  </td>
                                  <td>
                                    {aseguradora.importeTarjetaCredito ? formatNumber(aseguradora.importeTarjetaCredito) : "$0"}
                                  </td>
                                  <td>
                                    {aseguradora.importeTarjetaDebito ? formatNumber(aseguradora.importeTarjetaDebito) : "$0"}
                                  </td>

                                  <td>
                                    {aseguradora.importeTransferencia ? formatNumber(aseguradora.importeTransferencia) : "$0"}
                                  </td>
                                  <td>{aseguradora.importeTotal ? formatNumber(aseguradora.importeTotal) : ""}</td>
                                  <td>{aseguradora.comentario ? aseguradora.comentario : ""}</td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                    <thead>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.aseguradoras ? (
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">TOTALES</th>
                          <th scope="col">
                            {totalesAseguradoras.totalEfectivoFisico
                              ? formatNumber(totalesAseguradoras.totalEfectivoFisico)
                              : "$0"}
                          </th>
                          <th scope="col">
                            {totalesAseguradoras.totalEfectivoDeposito
                              ? formatNumber(totalesAseguradoras.totalEfectivoDeposito)
                              : "$0"}
                          </th>
                          <th scope="col">
                            {totalesAseguradoras.totalCredito ? formatNumber(totalesAseguradoras.totalCredito) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesAseguradoras.totalDebito ? formatNumber(totalesAseguradoras.totalDebito) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesAseguradoras.totalTransferencia ? formatNumber(totalesAseguradoras.totalTransferencia) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesAseguradoras.totalImportes ? formatNumber(totalesAseguradoras.totalImportes) : "$0"}
                          </th>
                        </tr>
                      ) : null}
                    </thead>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
