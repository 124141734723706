import { LISTAR_UBICACIONES, LIMPIAR_UBICACIONES } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case LISTAR_UBICACIONES:
      return {
        ...state,
        ubicaciones: action.payload.ubicaciones,
        estanteSeleccionado: {
          ...action.payload,
          ubicaciones: null,
        },
      };
    case LIMPIAR_UBICACIONES:
      return {
        ...state,
        ubicaciones: [],
        estanteSeleccionado: {},
      };
    default:
      return state;
  }
};
