import React, { useContext, useEffect, useState } from "react";

import useWindowDimensions from "../../hooks/dimensionsHook";

import "react-tabs/style/react-tabs.css";
import { Row, Col, Spinner, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import AuthContext from "../../context/Auth/AuthContext";

import PacientesContext from "../../context/Pacientes/PacientesContext";
import ConsultaSeguimientoContext from "../../context/ConsultaSeguimiento/ConsultaSeguimientoContext";
import PadecimientoActualContext from "../../context/PadecimientoActual/PadecimientoActualContext";

import ExpedienteMedicoContext from "../../context/ExpedienteMedico/ExpedienteMedicoContext";

export default function TabListaConsultaSeguimiento({ props, historiaClinicaInicial, autorizado }) {
  const { width } = useWindowDimensions();

  const [padecimientoVacio, setPadecimientoVacio] = useState(true);

  const [consultaSeleccionadaModificada, setConsultaSeleccionadaModificada] = useState({});
  const [indexConsulta, setIndexConsulta] = useState();

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado } = pacientesContext;

  const ctxPadAct = useContext(PadecimientoActualContext);
  const { padecimientoActual, seleccionarPadecimientoActualPaciente, setPadecimientoActual } = ctxPadAct;

  const ctxExpedienteMedico = useContext(ExpedienteMedicoContext);
  const { imprimirExpedienteMedico } = ctxExpedienteMedico;

  const [nombrePaciente, setNombrePaciente] = useState(null);

  const consultaSeguimientoContext = useContext(ConsultaSeguimientoContext);
  const {
    consultasSeguimiento,
    uploadNuevaConsultaSeguimiento,
    uploadConsultaSeguimientoModificada,
    listarConsultasSeguimientoConMarcadores,
  } = consultaSeguimientoContext;

  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  useEffect(() => {
    if (padecimientoVacio) {
      if (idCatPacienteSeleccionado) {
        seleccionarPadecimientoActualPaciente(idCatPacienteSeleccionado);
      } else {
        setPadecimientoActual({});
      }
      setPadecimientoVacio(false);
    }
  }, []);

  const cleanNuevaConsulta = {
    idCatTipoConsultaSeguimiento: usuario.authorities.includes("ROLE_MEDICO") ? 1 : 2,
    idCatPaciente: idCatPacienteSeleccionado,
    visible: false,
  };

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_consulta_seguimiento: false,
    nuevaConsulta: true,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  const [loading, setLoading] = useState(false);

  const [consultaSeleccionada, setConsultaSeleccionada] = useState(cleanNuevaConsulta);

  const [fileList, setFileList] = useState([]);

  const onChangeNuevaConsulta = async (e) => {
    const { name, value, checked } = e.target;

    if (name == "visible") {
      setConsultaSeleccionada({
        ...consultaSeleccionada,
        [name]: checked,
      });
    } else {
      setConsultaSeleccionada({
        ...consultaSeleccionada,
        [name]: value,
      });
    }
  };

  const uploadNuevaConsultaConfirm = async () => {
    let errorUploadConsulta = false;

    if (
      !consultaSeleccionada.presentacion &&
      !consultaSeleccionada.subjetivos &&
      !consultaSeleccionada.objetivos &&
      !consultaSeleccionada.analisis &&
      !consultaSeleccionada.pronostico
    ) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, llene al menos un campo, para guardar la consulta.",
      });
      errorUploadConsulta = true;
      return;
    }

    if (!errorUploadConsulta) {
      setLoading(true);

      const res = await uploadNuevaConsultaSeguimiento({
        ...consultaSeleccionada,
        talla: padecimientoActual.expedienteMedico.talla,
        peso: padecimientoActual.expedienteMedico.peso,
        superficieCorporalTotal: padecimientoActual.expedienteMedico.superficieCorporalTotal,
        indiceMasaCorporal: padecimientoActual.expedienteMedico.indiceMasaCorporal,
        temperatura: padecimientoActual.expedienteMedico.temperatura,
        tensionArterialSistolica: padecimientoActual.expedienteMedico.tensionArterialSistolica,
        tensionArterialDiastolica: padecimientoActual.expedienteMedico.tensionArterialDiastolica,
        frecuenciaCardiaca: padecimientoActual.expedienteMedico.frecuenciaCardiaca,
        frecuenciaRespiratoria: padecimientoActual.expedienteMedico.frecuenciaRespiratoria,
        saturacionOxigeno: padecimientoActual.expedienteMedico.saturacionOxigeno,
        idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
        archivosPaciente: fileList,
      });
      if (res.data.error) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          editar: false,
          dynamic_title: "Exito",
          confirm_alert_consulta_seguimiento: false,
          dynamic_description: "Se guardó de manera correcta la información.",
        });
        listarConsultasSeguimientoConMarcadores(
          idCatPacienteSeleccionado,
          usuario.authorities.includes("ROLE_MEDICO") ? true : false,
          historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
          usuario.idCatMedico
        );
      }
    }
  };

  useEffect(() => {
    listarConsultasSeguimientoConMarcadores(
      idCatPacienteSeleccionado,
      usuario.authorities.includes("ROLE_MEDICO") ? true : false,
      historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      usuario.idCatMedico
    );
  }, []);

  useEffect(() => {
    if (historiaClinicaInicial && historiaClinicaInicial.paciente) {
      const nombres = historiaClinicaInicial.paciente.nombres ? historiaClinicaInicial.paciente.nombres : "";
      const apellidoPaterno = historiaClinicaInicial.paciente.apellidoPaterno
        ? historiaClinicaInicial.paciente.apellidoPaterno
        : "";
      const apellidoMaterno = historiaClinicaInicial.paciente.apellidoMaterno
        ? historiaClinicaInicial.paciente.apellidoMaterno
        : "";
      const nombreCompleto = `${nombres} ${apellidoPaterno} ${apellidoMaterno}`;
      setNombrePaciente(nombreCompleto);
    }
  }, []);

  const onChange = async (e) => {
    const { name, value } = e.target;

    if (name == "talla") {
      // if (!padecimientoActual.expedienteMedico) {
      //   padecimientoActual.expedienteMedico = {};
      //   padecimientoActual.expedienteMedico.talla = value;
      // } else padecimientoActual.expedienteMedico.talla = value;
      padecimientoActual.expedienteMedico.talla = value;
      setPadecimientoActual(padecimientoActual);
      calcularSCT(value, padecimientoActual.expedienteMedico.peso ? padecimientoActual.expedienteMedico.peso : null);
      calcularIMC(value, padecimientoActual.expedienteMedico.peso ? padecimientoActual.expedienteMedico.peso : null);
    } else if (name == "peso") {
      // if (!padecimientoActual.expedienteMedico) {
      //   padecimientoActual.expedienteMedico = {};
      //   padecimientoActual.expedienteMedico.peso = value;
      // } else
      padecimientoActual.expedienteMedico.peso = value;
      setPadecimientoActual(padecimientoActual);
      calcularSCT(padecimientoActual.expedienteMedico.talla ? padecimientoActual.expedienteMedico.talla : null, value);
      calcularIMC(padecimientoActual.expedienteMedico.talla ? padecimientoActual.expedienteMedico.talla : null, value);
    } else {
      setPadecimientoActual({
        ...padecimientoActual,
        expedienteMedico: {
          ...padecimientoActual.expedienteMedico,
          [name]: value,
        },
      });
    }
  };

  const calcularSCT = async (talla, peso) => {
    if (talla && peso) {
      let sct = peso ** 0.425 * talla ** 0.725 * 0.007184;
      padecimientoActual.expedienteMedico.superficieCorporalTotal = sct.toFixed(2);
      setPadecimientoActual(padecimientoActual);
    }
  };
  const calcularIMC = async (talla, peso) => {
    if (talla && peso) {
      const tallEnMetros = talla / 100;
      let imc = peso / Math.pow(tallEnMetros, 2);

      padecimientoActual.expedienteMedico.indiceMasaCorporal = imc.toFixed(1);
      setPadecimientoActual(padecimientoActual);
    }
  };

  const onChangeConsultaModificada = (e) => {
    const { name, value } = e.target;
    setConsultaSeleccionadaModificada({
      ...consultaSeleccionadaModificada,
      [name]: value,
    });
  };

  const uploadModificacionConsultaConfirm = async () => {
    // let errorUploadConsulta = false;

    let today = new Date();
    let month = today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1;
    let minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
    let date = today.getFullYear() + "-" + month + "-" + today.getDate();
    let time = today.getHours() + ":" + minutes;
    let dateTime = date + " " + time;

    for (const key in consultaSeleccionadaModificada) {
      for (const keyConsulta in consultaSeleccionada) {
        if (key == keyConsulta && key !== "idConsultaSeguimiento")
          consultaSeleccionadaModificada[key] =
            // eslint-disable-next-line no-useless-concat
            consultaSeleccionada[keyConsulta] + "\n" + " " + "\n" + dateTime + "\n" + consultaSeleccionadaModificada[key];
      }
    }

    setLoading(true);
    const res = await uploadConsultaSeguimientoModificada({
      ...consultaSeleccionadaModificada,
      visible: consultaSeleccionada.visible,
    });
    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        editar: false,
        dynamic_title: "Exito",
        confirm_alert_consulta_seguimiento: false,
        dynamic_description: "Se guardó de manera correcta la información.",
      });
      setConsultaSeleccionadaModificada({});
      listarConsultasSeguimientoConMarcadores(
        idCatPacienteSeleccionado,
        usuario.authorities.includes("ROLE_MEDICO") ? true : false,
        historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
        usuario.idCatMedico
      );
    }
  };

  const controllerPaginasConsultas = (siguiente, anterior) => {
    if (!UISweetAlert.nuevaConsulta && siguiente && indexConsulta < consultasSeguimiento.length - 1) {
      const newIndex = indexConsulta + 1;
      setIndexConsulta(newIndex);
      setConsultaSeleccionada(consultasSeguimiento[indexConsulta + 1]);
    } else if (!UISweetAlert.nuevaConsulta && anterior && indexConsulta > 0) {
      const newIndex = indexConsulta - 1;
      setIndexConsulta(newIndex);
      setConsultaSeleccionada(consultasSeguimiento[indexConsulta - 1]);
    }
  };

  const controllerPaginasConsultasKeyPress = (e) => {
    const { key } = e;

    if (!UISweetAlert.nuevaConsulta && key == "ArrowRight" && indexConsulta < consultasSeguimiento.length - 1) {
      const newIndex = indexConsulta + 1;
      setIndexConsulta(newIndex);
      setConsultaSeleccionada(consultasSeguimiento[indexConsulta + 1]);
    } else if (!UISweetAlert.nuevaConsulta && key == "ArrowLeft" && indexConsulta > 0) {
      const newIndex = indexConsulta - 1;
      setIndexConsulta(newIndex);
      setConsultaSeleccionada(consultasSeguimiento[indexConsulta - 1]);
    }
  };

  const imprimirConsulta = async () => {
    const expedienteImpresionVo = {
      imprimirNotaMedica: true,
      idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      idCatPaciente: historiaClinicaInicial.paciente.idCatPaciente,
      idConsultaSeguimiento: consultaSeleccionada.idConsultaSeguimiento,
      idCatMedico: usuario.idCatMedico,
    };
    const res = await imprimirExpedienteMedico(expedienteImpresionVo);
    return res;
  };

  const limpiarArchivos = async () => {
    setFileList([]);
  };

  const changeFiles = (e) => {
    const files = e.target.files;

    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          let strFile = reader.result;
          let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
          let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);

          const tipoArchivoImagenClinica = 2;

          let nuevoArchivo = {
            nombreArchivo: file.name,
            mimeType: type,
            tamano: file.size,
            inputStream: fileBase64,
            tipoArchivo: tipoArchivoImagenClinica,
          };
          const newFileList = fileList;
          newFileList.push(nuevoArchivo);
          setFileList(newFileList);
          // setFileList([...fileList, nuevoArchivo]);
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const datosPadecimientoActual = () => {
    return (
      <Row>
        <Col xl={6} lg={12} md={12}>
          <Row className="form-group mt-3">
            <label className="col-sm-4 col-form-label mb-3">Talla (cm)</label>
            <Col sm={8}>
              <input
                disabled={!UISweetAlert.nuevaConsulta}
                type="text"
                className="form-control mb-3"
                name="talla"
                onChange={onChange}
                value={padecimientoActual.expedienteMedico.talla ? padecimientoActual.expedienteMedico.talla : ""}
              />
            </Col>
            <label className="col-sm-4 col-form-label mb-3">Peso (kg)</label>
            <Col sm={8}>
              <input
                disabled={!UISweetAlert.nuevaConsulta}
                type="text"
                className="form-control mb-3"
                name="peso"
                onChange={onChange}
                value={padecimientoActual.expedienteMedico.peso ? padecimientoActual.expedienteMedico.peso : ""}
              />
            </Col>
            <label className="col-sm-4 col-form-label mb-3">Superficie Corporal (SCT)</label>
            <Col sm={8}>
              <input
                disabled={true}
                type="text"
                name="superficieCorporalTotal"
                className="form-control mb-3"
                onChange={onChange}
                value={
                  padecimientoActual.expedienteMedico.superficieCorporalTotal
                    ? padecimientoActual.expedienteMedico.superficieCorporalTotal
                    : ""
                }
              />
            </Col>
            <label className="col-sm-4 col-form-label mb-3">Indice Masa Corporal (IMC)</label>
            <Col sm={8}>
              <input
                disabled={true}
                type="text"
                name="indiceMasaCorporal"
                className="form-control mb-3"
                onChange={onChange}
                value={
                  padecimientoActual.expedienteMedico.indiceMasaCorporal
                    ? padecimientoActual.expedienteMedico.indiceMasaCorporal
                    : ""
                }
              />
            </Col>

            <label className="col-sm-4 col-form-label mb-3">Frecuencia cardiaca (latidos/min)</label>
            <Col sm={8}>
              <input
                disabled={!UISweetAlert.nuevaConsulta}
                type="text"
                name="frecuenciaCardiaca"
                onChange={onChange}
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.frecuenciaCardiaca
                    ? `${padecimientoActual.expedienteMedico.frecuenciaCardiaca}`
                    : ""
                }
                className="form-control mb-3"
              />
            </Col>
            <label className="col-sm-4 col-form-label mt-3">Alergias</label>
            <Col sm={8}>
              <textarea
                disabled={true}
                onChange={onChange}
                type="text"
                name="alergias"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.alergias
                    ? `${padecimientoActual.expedienteMedico.alergias}`
                    : ""
                }
                className="form-control mt-3"
              />
            </Col>
          </Row>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <Row className="form-group mt-3">
            <label className="col-sm-4 col-form-label mb-3">Temperatura (°C)</label>
            <Col sm={8}>
              <input
                disabled={!UISweetAlert.nuevaConsulta}
                type="text"
                name="temperatura"
                onChange={onChange}
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.temperatura
                    ? `${padecimientoActual.expedienteMedico.temperatura}`
                    : ""
                }
                className="form-control mb-3"
              />
            </Col>
            <label className="col-sm-4 col-form-label mb-3">Tensión Arterial Sistólica (mmHg)</label>
            <Col sm={8}>
              <input
                disabled={!UISweetAlert.nuevaConsulta}
                type="text"
                className="form-control mb-3"
                onChange={onChange}
                name="tensionArterialSistolica"
                value={
                  padecimientoActual.expedienteMedico.tensionArterialSistolica
                    ? padecimientoActual.expedienteMedico.tensionArterialSistolica
                    : ""
                }
              />
            </Col>
            <label className="col-sm-4 col-form-label mb-3">Tensión Arterial Diastólica (mmHg)</label>
            <Col sm={8}>
              <input
                type="text"
                className="form-control mb-3"
                name="tensionArterialDiastolica"
                disabled={!UISweetAlert.nuevaConsulta}
                onChange={onChange}
                value={
                  padecimientoActual.expedienteMedico.tensionArterialDiastolica
                    ? padecimientoActual.expedienteMedico.tensionArterialDiastolica
                    : ""
                }
              />
            </Col>
            <label className="col-sm-4 col-form-label mb-3">Saturación de Oxigeno (%)</label>
            <Col sm={8}>
              <input
                disabled={!UISweetAlert.nuevaConsulta}
                onChange={onChange}
                type="text"
                name="saturacionOxigeno"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.saturacionOxigeno
                    ? `${padecimientoActual.expedienteMedico.saturacionOxigeno}`
                    : ""
                }
                className="form-control mb-3"
              />
            </Col>

            <label className="col-sm-4 col-form-label mb-3">Frecuencia Respiratoria (resp/min)</label>
            <Col sm={8}>
              <input
                disabled={!UISweetAlert.nuevaConsulta}
                onChange={onChange}
                type="text"
                name="frecuenciaRespiratoria"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.frecuenciaRespiratoria
                    ? `${padecimientoActual.expedienteMedico.frecuenciaRespiratoria}`
                    : ""
                }
                className="form-control mb-3"
              />
            </Col>
            <label className="col-sm-4 col-form-label mb-3">Archivos</label>
            <Col sm={8}>
              <input
                onClick={limpiarArchivos}
                onChange={changeFiles}
                className="mt-4 block w-full text-sm text-gray-900 border border-gray-300 cursor-pointer "
                id="fileList"
                type="file"
                multiple
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const marcadoresConsulta = () => {
    if (consultaSeleccionada.marcadores && consultaSeleccionada.marcadores.length > 0) {
      const half = Math.ceil(consultaSeleccionada.marcadores.length / 2);
      const marcadoresPaciente = consultaSeleccionada.marcadores.slice(0, half);
      const marcadoresPacienteMitad = consultaSeleccionada.marcadores.slice(half);
      // Se dividen los marcadores para poder mostrarlos con un diseño mejor
      return (
        <Row>
          <Col xl={6} lg={12} md={12}>
            <Row className="form-group mt-3">
              {marcadoresPaciente &&
                marcadoresPaciente.map((marcador) => {
                  return (
                    <>
                      <label className="mt-3 col-sm-4 col-form-label">{marcador.marcador ? marcador.marcador : ""}</label>
                      <Col sm={8}>
                        <input
                          disabled={!UISweetAlert.nuevaConsulta}
                          type="text"
                          className="form-control"
                          name={marcador.marcador}
                          value={marcador.valor ? marcador.valor : ""}
                        />
                      </Col>
                    </>
                  );
                })}
            </Row>
          </Col>
          <Col xl={6} lg={12} md={12}>
            <Row className="form-group mt-3">
              {marcadoresPacienteMitad &&
                marcadoresPacienteMitad.map((marcador) => {
                  return (
                    <>
                      <label className="mt-3 col-sm-4 col-form-label">{marcador.marcador ? marcador.marcador : ""}</label>
                      <Col sm={8}>
                        <input
                          disabled={!UISweetAlert.nuevaConsulta}
                          type="text"
                          className="form-control"
                          name={marcador.marcador}
                          value={marcador.valor ? marcador.valor : ""}
                        />
                      </Col>
                    </>
                  );
                })}
              <label className="col-sm-4 col-form-label mt-3">Alergias</label>
              <Col sm={8}>
                <textarea
                  disabled={true}
                  onChange={onChange}
                  type="text"
                  name="alergias"
                  value={
                    padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.alergias
                      ? `${padecimientoActual.expedienteMedico.alergias}`
                      : ""
                  }
                  className="form-control mt-3"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <div className="mt-4" onKeyDown={controllerPaginasConsultasKeyPress}>
      <Row className="mt-4">
        <Col>
          <h4>Listado de Consultas de Seguimiento</h4>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <button
            className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
            type="button"
            disabled={!autorizado}
            onClick={() => {
              setConsultaSeleccionada(cleanNuevaConsulta);
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_consulta_seguimiento: true,
                nuevaConsulta: true,
              });
            }}>
            Nueva Consulta
          </button>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <table className="table table-hover table-centered table-wrap mb-0">
            <thead>
              <tr>
                <th scope="col">
                  Fecha de<br></br>Registro
                </th>
                <th scope="col">Médico</th>
                <th scope="col">
                  Tipo de<br></br>Consulta
                </th>
                <th scope="col">Detalles</th>
                <th scope="col">Recetas</th>
              </tr>
            </thead>
            <tbody>
              {consultasSeguimiento.map((consulta, index) => {
                return (
                  <tr key={consulta.idConsultaSeguimiento}>
                    <td>{consulta.fechaRegistro ? consulta.fechaRegistro.split("T")[0] : ""}</td>
                    <td>{consulta.nombreMedico ? consulta.nombreMedico : ""}</td>
                    {/* <td>
                      {consulta.fechaRegistro
                        ? `${consulta.fechaRegistro.split("-")[2]}/${consulta.fechaRegistro.split("-")[1]}/${
                            consulta.fechaRegistro.split("-")[0]
                          }`
                        : ""}
                    </td> */}
                    <td>
                      {consulta.catTipoConsultaSeguimiento ? consulta.catTipoConsultaSeguimiento.tipoConsultaSeguimiento : ""}
                    </td>
                    <td>
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4"
                        type="button"
                        onClick={() => {
                          setConsultaSeleccionadaModificada({
                            ...consultaSeleccionadaModificada,
                            idConsultaSeguimiento: consulta.idConsultaSeguimiento,
                          });
                          setConsultaSeleccionada(consulta);
                          setIndexConsulta(index);
                          setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_consulta_seguimiento: true,
                            nuevaConsulta: false,
                          });
                        }}>
                        Detalles
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4"
                        type="button"
                        onClick={() => {
                          props.history.push(`/consulta/recetas/${consulta.idConsultaSeguimiento}`);
                        }}>
                        Recetas
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>

      {UISweetAlert.confirm_alert_consulta_seguimiento ? (
        <SweetAlert
          title={UISweetAlert.nuevaConsulta ? "Nueva Consulta" : "Información de la consulta"}
          showCancel
          showConfirm={
            (usuario.idCatMedico && usuario.idCatMedico == consultaSeleccionada.idCatMedico) || UISweetAlert.nuevaConsulta
          }
          //   showConfirm={UISweetAlert.nuevaConsulta}
          confirmBtnText={UISweetAlert.nuevaConsulta ? "Guardar" : "Actualizar información"}
          confirmBtnBsStyle="success"
          cancelBtnText={UISweetAlert.nuevaConsulta ? "Cancelar" : "Cerrar"}
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          // onConfirm={() => uploadNuevaConsultaConfirm()}
          onConfirm={UISweetAlert.nuevaConsulta ? uploadNuevaConsultaConfirm : uploadModificacionConsultaConfirm}
          onCancel={() => {
            setConsultaSeleccionadaModificada({});
            setFileList([]);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_consulta_seguimiento: false,
            });
          }}>
          <div>
            <Row className="mb-5">
              <Col>{nombrePaciente ? nombrePaciente : ""}</Col>
            </Row>
            <CardBody className="mt-6">
              {UISweetAlert.nuevaConsulta ? datosPadecimientoActual() : marcadoresConsulta()}
              <button
                hidden={UISweetAlert.nuevaConsulta}
                disabled={indexConsulta == 0}
                className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                type="button"
                onClick={(e) => controllerPaginasConsultas(false, true, e)}>
                Anterior
              </button>
              <button
                hidden={UISweetAlert.nuevaConsulta}
                disabled={indexConsulta == consultasSeguimiento.length - 1}
                className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                type="button"
                onClick={() => controllerPaginasConsultas(true, false)}>
                Siguiente
              </button>
              <button
                hidden={UISweetAlert.nuevaConsulta}
                disabled={UISweetAlert.nuevaConsulta}
                className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                type="button"
                onClick={() => imprimirConsulta()}>
                Imprimir
              </button>
            </CardBody>
            <div className="flex items-center justify-center text-center">
              <label hidden={UISweetAlert.nuevaConsulta} className="mt-4 col-form-label">
                Fecha de Registro
              </label>
              <Col sm={4} className="mt-4 mb-3">
                <input
                  hidden={UISweetAlert.nuevaConsulta}
                  type="text"
                  className="form-control"
                  disabled={true}
                  value={consultaSeleccionada.fechaRegistro ? consultaSeleccionada.fechaRegistro.split("T")[0] : ""}
                />
              </Col>
              <label hidden={UISweetAlert.nuevaConsulta} className="mt-4 col-form-label">
                Médico
              </label>
              <Col sm={5} className="mt-4 mb-3">
                <input
                  hidden={UISweetAlert.nuevaConsulta}
                  type="text"
                  className="form-control"
                  disabled={true}
                  value={consultaSeleccionada.nombreMedico ? consultaSeleccionada.nombreMedico : ""}
                />
              </Col>
            </div>
            <CardBody>
              <Row>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group mt-3">
                    <label className="col-sm-4 col-form-label">Presentación del paciente</label>
                    <Col sm={8} className="mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        name="presentacion"
                        disabled={!UISweetAlert.nuevaConsulta}
                        onChange={onChangeNuevaConsulta}
                        value={consultaSeleccionada.presentacion ? consultaSeleccionada.presentacion : ""}
                      />
                    </Col>
                    <label hidden={UISweetAlert.nuevaConsulta} className="col-sm-4 col-form-label"></label>
                    <Col hidden={UISweetAlert.nuevaConsulta} sm={8} className="mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        name="presentacion"
                        disabled={
                          UISweetAlert.nuevaConsulta ||
                          (usuario.idCatMedico && !(usuario.idCatMedico == consultaSeleccionada.idCatMedico))
                        }
                        hidden={UISweetAlert.nuevaConsulta}
                        onChange={onChangeConsultaModificada}
                        value={consultaSeleccionadaModificada.presentacion ? consultaSeleccionadaModificada.presentacion : ""}
                      />
                    </Col>
                    <label className="col-sm-4 col-form-label">Subjetivos</label>
                    <Col sm={8} className="mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        name="subjetivos"
                        disabled={!UISweetAlert.nuevaConsulta}
                        onChange={onChangeNuevaConsulta}
                        value={consultaSeleccionada.subjetivos ? consultaSeleccionada.subjetivos : ""}
                      />
                    </Col>
                    <label hidden={UISweetAlert.nuevaConsulta} className="col-sm-4 col-form-label"></label>
                    <Col hidden={UISweetAlert.nuevaConsulta} sm={8} className="mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        name="subjetivos"
                        disabled={
                          UISweetAlert.nuevaConsulta ||
                          (usuario.idCatMedico && !(usuario.idCatMedico == consultaSeleccionada.idCatMedico))
                        }
                        onChange={onChangeConsultaModificada}
                        value={consultaSeleccionadaModificada.subjetivos ? consultaSeleccionadaModificada.subjetivos : ""}
                      />
                    </Col>
                    <label className="col-sm-4 col-form-label">Objetivos</label>
                    <Col sm={8} className="mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        name="objetivos"
                        disabled={!UISweetAlert.nuevaConsulta}
                        onChange={onChangeNuevaConsulta}
                        value={consultaSeleccionada.objetivos ? consultaSeleccionada.objetivos : ""}
                      />
                    </Col>
                    <label hidden={UISweetAlert.nuevaConsulta} className="col-sm-4 col-form-label"></label>
                    <Col hidden={UISweetAlert.nuevaConsulta} sm={8}>
                      <textarea
                        type="text"
                        className="form-control"
                        name="objetivos"
                        disabled={
                          UISweetAlert.nuevaConsulta ||
                          (usuario.idCatMedico && !(usuario.idCatMedico == consultaSeleccionada.idCatMedico))
                        }
                        onChange={onChangeConsultaModificada}
                        value={consultaSeleccionadaModificada.objetivos ? consultaSeleccionadaModificada.objetivos : ""}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group mt-3">
                    <label className="col-sm-4 col-form-label">Análisis</label>
                    <Col sm={8} className="mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        name="analisis"
                        disabled={!UISweetAlert.nuevaConsulta}
                        onChange={onChangeNuevaConsulta}
                        value={consultaSeleccionada.analisis ? consultaSeleccionada.analisis : ""}
                      />
                    </Col>
                    <label hidden={UISweetAlert.nuevaConsulta} className="col-sm-4 col-form-label"></label>
                    <Col hidden={UISweetAlert.nuevaConsulta} sm={8} className="mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        name="analisis"
                        disabled={
                          UISweetAlert.nuevaConsulta ||
                          (usuario.idCatMedico && !(usuario.idCatMedico == consultaSeleccionada.idCatMedico))
                        }
                        onChange={onChangeConsultaModificada}
                        value={consultaSeleccionadaModificada.analisis ? consultaSeleccionadaModificada.analisis : ""}
                      />
                    </Col>
                    <label className="col-sm-4 col-form-label">Plan</label>
                    <Col sm={8} className="mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        name="pronostico"
                        disabled={!UISweetAlert.nuevaConsulta}
                        onChange={onChangeNuevaConsulta}
                        value={consultaSeleccionada.pronostico ? consultaSeleccionada.pronostico : ""}
                      />
                    </Col>
                    <label hidden={UISweetAlert.nuevaConsulta} className="col-sm-4 col-form-label mb-4"></label>
                    <Col hidden={UISweetAlert.nuevaConsulta} sm={8}>
                      <textarea
                        type="text"
                        className="form-control"
                        name="pronostico"
                        disabled={
                          UISweetAlert.nuevaConsulta ||
                          (usuario.idCatMedico && !(usuario.idCatMedico == consultaSeleccionada.idCatMedico))
                        }
                        onChange={onChangeConsultaModificada}
                        value={consultaSeleccionadaModificada.pronostico ? consultaSeleccionadaModificada.pronostico : ""}
                      />
                    </Col>
                    <label
                      hidden={
                        usuario.idCatMedico &&
                        !(usuario.idCatMedico == consultaSeleccionada.idCatMedico) &&
                        !UISweetAlert.nuevaConsulta
                      }
                      className="col-sm-4 col-form-label">
                      Visible para todos los médicos
                    </label>
                    <Col sm={8} className="mb-3">
                      <input
                        hidden={
                          usuario.idCatMedico &&
                          !(usuario.idCatMedico == consultaSeleccionada.idCatMedico) &&
                          !UISweetAlert.nuevaConsulta
                        }
                        type="checkbox"
                        name="visible"
                        className="form-control"
                        disabled={
                          usuario.idCatMedico &&
                          !(usuario.idCatMedico == consultaSeleccionada.idCatMedico) &&
                          !UISweetAlert.nuevaConsulta
                        }
                        onChange={onChangeNuevaConsulta}
                        checked={consultaSeleccionada.visible}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
