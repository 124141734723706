import React, { useState, useEffect, useContext } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col, Spinner, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import HojaIndicacionContext from "../../context/HojaIndicacion/HojaIndicacionContext";
import CatViasContext from "../../context/CatVias/CatViasContext";

import useCloseWindowWarning from "../../hooks/useCloseWindowWarning";

export default function TabListaHojaIndicaciones({ props, autorizado, expedienteMedico, medico, recetaProductos }) {
  console.log("🚀 ~ file: index.js:12 ~ TabListaHojaIndicaciones ~ expedienteMedico:", expedienteMedico);
  const [Prompt, setEditTrue, setEditFalse, isEdit] = useCloseWindowWarning();

  const hojaIndicacionContext = useContext(HojaIndicacionContext);
  const { uploadHojaIndicacion, exportHojaIndicacion, setHojaIndicacion } = hojaIndicacionContext;

  const [seGuardaronCambios, setSeGuardaronCambios] = useState(false);

  const catViasContext = useContext(CatViasContext);
  const { vias, listarCatVias } = catViasContext;

  const [resHojaIndicacion, setResHojaIndicacion] = useState(null);

  useEffect(() => {
    const getVias = async () => {
      listarCatVias();
    };
    getVias();
  }, []);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_hoja_indicaciones_guardar: false,
    confirm_alert_hoja_indicaciones_imprimir: false,
    confirm_alert_hoja_indicacion_duplicar: false,
    nuevaHojaIndicaciones: true,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  const [loading, setLoading] = useState(false);

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  today = dd + "/" + mm + "/" + yyyy;

  const datosHojaIndicacionVacia = {
    fechaRegistro: today,
    idExpedienteMedico: expedienteMedico && expedienteMedico.idExpedienteMedico ? expedienteMedico.idExpedienteMedico : "",
    idCatVia: "",
    via: "",
    otra: "",
    servicio: expedienteMedico && expedienteMedico.catAseguradora ? "Aseguradora" : "Privado",
    portACath: "",
    arrow: "",
    ciclos: "",
    ciclo: "",
    fecha1: "",
    fecha2: "",
    fecha3: "",
    fecha4: "",
    fecha5: "",
    fecha6: "",
    fecha7: "",
    fecha8: "",
    fecha9: "",
    fecha10: "",

    medidasPaciente:
      expedienteMedico &&
      (expedienteMedico.peso ? expedienteMedico.peso : "") +
        "/" +
        (expedienteMedico.talla ? expedienteMedico.talla : "") +
        "/" +
        (expedienteMedico.superficieCorporalTotal ? expedienteMedico.superficieCorporalTotal : ""),
    comentarioExtra: "",
    modificaciones: "",
  };

  const [datosHojaIndicacion, setDatosHojaIndicacion] = useState(datosHojaIndicacionVacia);
  const [esquemasPremedicacion, setEsquemasPremedicacion] = useState([]);
  const [esquemasTratamiento, setEsquemasTratamiento] = useState([]);

  useEffect(() => {
    const fetchHoja = async () => {
      if (expedienteMedico) {
        setLoading(true);
        const res = await setHojaIndicacion(expedienteMedico.idExpedienteMedico);

        if (res) {
          setLoading(false);
          setResHojaIndicacion(res);
          setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_hoja_indicacion_duplicar: true,
          });
        } else {
          setLoading(false);
        }
        setLoading(false);
      }
    };
    fetchHoja();
  }, []);

  const nuevoRenglonEsquemaPremedicacion = () => {
    if (!isEdit) setEditTrue();
    const nuevoEsquemaPremedicacion = {
      idRecetaProducto: null,
      idCatTipoEsquema: 1,
      nombreComercial: "",
      diaCiclo: "",
      dosis: "",
      volumenSolucion: "",
      viaAdministracion: "",
      tiempoAdministracion: "",
    };
    setEsquemasPremedicacion([...esquemasPremedicacion, nuevoEsquemaPremedicacion]);
  };
  const nuevoRenglonEsquemaTratamiento = () => {
    if (!isEdit) setEditTrue();
    const nuevoEsquemaTratamiento = {
      idRecetaProducto: null,
      idCatTipoEsquema: 2,
      nombreComercial: "",
      diaCiclo: "",
      dosis: "",
      volumenSolucion: "",
      viaAdministracion: "",
      tiempoAdministracion: "",
    };
    setEsquemasTratamiento([...esquemasTratamiento, nuevoEsquemaTratamiento]);
  };

  const checkNombreComercial = (name, value, index, setEsquema) => {
    const med = recetaProductos.filter((pa) => {
      if (pa.nombreComercial !== null) {
        return pa.nombreComercial.toLowerCase().normalize() === value.toLowerCase().normalize();
      }
    });

    if (med.length === 1) {
      setEsquema((state) => {
        const medicamentosActualizados = state.map((medicamento, i) => {
          if (i === index) {
            return {
              ...medicamento,
              [name]: value.toUpperCase(),
              idRecetaProducto: med[0].idRecetaProducto,
            };
          } else {
            return medicamento;
          }
        });
        return medicamentosActualizados;
      });
    } else if (med.length > 1) {
      setEsquema((state) => {
        const medicamentosActualizados = state.map((medicamento, i) => {
          if (i === index) {
            return {
              ...medicamento,
              [name]: value.toUpperCase(),
            };
          } else {
            return medicamento;
          }
        });
        return medicamentosActualizados;
      });
    } else {
      setEsquema((state) => {
        const medicamentosActualizados = state.map((medicamento, i) => {
          if (i === index) {
            return {
              ...medicamento,
              [name]: value.toUpperCase(),
              idRecetaProducto: null,
            };
          } else {
            return medicamento;
          }
        });
        return medicamentosActualizados;
      });
    }
  };

  const changeEsquemaPremedicacionLista = (e, index) => {
    const { name, value } = e.target;

    if (name === "nombreComercial") {
      checkNombreComercial(name, value, index, setEsquemasPremedicacion);
      return;
    }

    setEsquemasPremedicacion((state) => {
      const esquemasPremedicacionActualizados = state.map((esquema, i) => {
        if (i === index) {
          return { ...esquema, [name]: value };
        } else {
          return esquema;
        }
      });

      return esquemasPremedicacionActualizados;
    });
  };

  const changeEsquemaTratamientoLista = (e, index) => {
    const { name, value } = e.target;

    if (name === "nombreComercial") {
      checkNombreComercial(name, value, index, setEsquemasTratamiento);
      return;
    }

    setEsquemasTratamiento((state) => {
      const esquemasTratamientoActualizados = state.map((esquema, i) => {
        if (i === index) {
          return { ...esquema, [name]: value };
        } else {
          return esquema;
        }
      });

      return esquemasTratamientoActualizados;
    });
  };

  const onChangeHojaIndicaciones = (e) => {
    if (!isEdit) setEditTrue();
    const { name, value } = e.target;
    setDatosHojaIndicacion({
      ...datosHojaIndicacion,
      [name]: value,
    });
  };

  const confirmNuevaHojaIndicaciones = () => {
    const hojaValida = validarDatosHojaIndicaciones();
    if (!hojaValida) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_hoja_indicaciones_guardar: true,
      });
    }
  };

  const confirmImpresionHojaIndicaciones = () => {
    const hojaValida = validarDatosHojaIndicaciones();
    if (!hojaValida) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_hoja_indicaciones_imprimir: true,
      });
    }
  };

  // Validar que la vía de administración lleve algo
  const validarDatosHojaIndicaciones = () => {
    let error = false;
    if (!error && (datosHojaIndicacion.idCatVia === "" || datosHojaIndicacion.idCatVia === null)) {
      error = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese una Vía.",
      });
    }
    if (!error && datosHojaIndicacion.idCatVia == 4 && datosHojaIndicacion.otra === "") {
      error = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, si seleccionó Otra Vía, especifique la Vía.",
      });
    }

    if (!error && esquemasTratamiento.length === 0) {
      error = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese al menos una entrada para el Esquema de Tratamiento.",
      });
    }
    if (!error && esquemasTratamiento.length > 0) {
      esquemasTratamiento.forEach((esquema, index) => {
        if (
          esquema.nombreComercial === "" ||
          esquema.diaCiclo === "" ||
          esquema.dosis === "" ||
          esquema.volumenSolucion === "" ||
          esquema.viaAdministracion === "" ||
          esquema.tiempoAdministracion === ""
        ) {
          error = true;
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, ingrese todos los datos en las entradas de Esquema de Tratamiento`,
          });
        }
      });
    }
    return error;
  };

  const guardarHojaIndicaciones = async () => {
    setLoading(true);
    delete datosHojaIndicacion.fechaRegistro;
    const res = await uploadHojaIndicacion({
      ...datosHojaIndicacion,
      via: datosHojaIndicacion.via ? datosHojaIndicacion.via : datosHojaIndicacion.otra,
      esquemasVo: [...esquemasPremedicacion, ...esquemasTratamiento],
    });

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_hoja_indicaciones_guardar: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
      return res;
    } else if (res) {
      setEditFalse();
      setSeGuardaronCambios(true);
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_hoja_indicaciones_guardar: false,
        dynamic_description: "Se guardó la hoja de indicaciones correctamente",
      });

      // setDatosHojaIndicacion(datosHojaIndicacionVacia);
      // setEsquemasPremedicacion([]);
      // setEsquemasTratamiento([]);
      return res;
    }
  };

  const imprimirHojaIndicaciones = async () => {
    const response = await guardarHojaIndicaciones();
    const hojaIndicacion = response.data;

    if (hojaIndicacion.idHojaIndicacion !== null) {
      setLoading(true);
      delete hojaIndicacion.via;
      const res = await exportHojaIndicacion(hojaIndicacion);
      if (res.data && res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_alert_hoja_indicaciones_imprimir: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
        return res;
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_alert_hoja_indicaciones_imprimir: false,
          dynamic_description: "Se imprimió la hoja de indicaciones correctamente",
        });

        return res;
      }
    }
  };

  const eliminarEsquemaPremedicacion = (index) => {
    const listaEsquemasPremedicacionFiltrados = esquemasPremedicacion.filter((esquema, i) => index !== i);
    setEsquemasPremedicacion(listaEsquemasPremedicacionFiltrados);
  };
  const eliminarEsquemaTratamiento = (index) => {
    const listaEsquemasTratamientoFiltrados = esquemasTratamiento.filter((esquema, i) => index !== i);
    setEsquemasTratamiento(listaEsquemasTratamientoFiltrados);
  };

  const duplicarHojaDeIndicaciones = async (res) => {
    setLoading(true);
    let esquemasPremedicacion = res.esquemas.map((esquema) => {
      if (esquema.catTipoEsquema.idCatTipoEsquema == 1)
        return {
          diaCiclo: esquema.diaCiclo,
          dosis: esquema.dosis,
          idCatTipoEsquema: 1,
          idRecetaProducto: null,
          tiempoAdministracion: esquema.tiempoAdministracion,
          viaAdministracion: esquema.viaAdministracion,
          volumenSolucion: esquema.volumenSolucion,
          nombreComercial: esquema.recetaProducto.nombreComercial,
        };
    });

    let esquemasTratamiento = res.esquemas.map((esquema) => {
      if (esquema.catTipoEsquema.idCatTipoEsquema == 2)
        return {
          diaCiclo: esquema.diaCiclo,
          dosis: esquema.dosis,
          idCatTipoEsquema: 2,
          idRecetaProducto: null,
          tiempoAdministracion: esquema.tiempoAdministracion,
          viaAdministracion: esquema.viaAdministracion,
          volumenSolucion: esquema.volumenSolucion,
          nombreComercial: esquema.recetaProducto.nombreComercial,
        };
    });
    const esquemasPremedicacionFiltrados = esquemasPremedicacion.filter((esquema) => esquema !== undefined);
    const esquemasTratamientoFiltrados = esquemasTratamiento.filter((esquema) => esquema !== undefined);

    setEsquemasPremedicacion(esquemasPremedicacionFiltrados);
    setEsquemasTratamiento(esquemasTratamientoFiltrados);
    let datosUltimaHoja = {
      idHojaIndicacion: null,
      idCatVia: res.via && res.via.idCatVia ? res.via.idCatVia : null,
      via: res.via && res.via.idCatVia ? res.via.idCatVia : null,
      otra: res.otra ? res.otra : null,
      portACath: res.portACath ? res.portACath : null,
      arrow: res.arrow ? res.arrow : null,
      ciclos: res.ciclos ? res.ciclos : null,
      ciclo: res.ciclo ? res.ciclo : null,
      fecha1: res.fecha1 ? res.fecha1 : null,
      fecha2: res.fecha2 ? res.fecha2 : null,
      fecha3: res.fecha3 ? res.fecha3 : null,
      fecha4: res.fecha4 ? res.fecha4 : null,
      fecha5: res.fecha5 ? res.fecha5 : null,
      fecha6: res.fecha6 ? res.fecha6 : null,
      fecha7: res.fecha7 ? res.fecha7 : null,
      fecha8: res.fecha8 ? res.fecha8 : null,
      fecha9: res.fecha9 ? res.fecha9 : null,
      fecha10: res.fecha10 ? res.fecha10 : null,
      comentarioExtra: res.comentarioExtra ? res.comentarioExtra : null,
      modificaciones: res.modificaciones ? res.modificaciones : null,
    };

    // let datosUltimaHoja = {
    //   idHojaIndicacion: null,
    //   idCatVia: res.via && res.via.idCatVia ? res.via.idCatVia : null,
    //   via: res.via && res.via.idCatVia ? res.via.idCatVia : null,
    //   otra: res.otra ? res.otra : null,
    //   portACath: res.portACath,
    //   arrow: res.arrow,
    //   ciclos: res.ciclos,
    //   ciclo: res.ciclo,
    //   fecha1: res.fecha1,
    //   fecha2: res.fecha2,
    //   fecha3: res.fecha3,
    //   fecha4: res.fecha4,
    //   fecha5: res.fecha5,
    //   fecha6: res.fecha6,
    //   fecha7: res.fecha7,
    //   fecha8: res.fecha8,
    //   fecha9: res.fecha9,
    //   fecha10: res.fecha10,
    //   comentarioExtra: res.comentarioExtra,
    //   modificaciones: res.modificaciones,
    // };
    let ultimaHojaIndicacion = { ...datosHojaIndicacionVacia, ...datosUltimaHoja };
    setDatosHojaIndicacion(ultimaHojaIndicacion);
    setLoading(false);
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_hoja_indicacion_duplicar: false,
    });
  };

  useEffect(() => {
    if (seGuardaronCambios) setSeGuardaronCambios(false);
  }, [seGuardaronCambios]);

  return (
    <div className="mt-4 container-fluid">
      <Row className="mt-4">
        {/* Seccion general */}
        <CardBody className="mt-4">
          <h5 className="h5 mb-4">General</h5>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group mt-3">
                <label className="col-sm-5 col-form-label">Fecha Registro</label>
                <Col sm={7}>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control"
                    name="today"
                    autoComplete="off"
                    value={today ? today : ""}
                  />
                </Col>
                <label className="col-sm-5 col-form-label">
                  Vía<span className="text-xs text-red-700">*</span>
                </label>
                <Col sm={4} className="pb-3">
                  <select
                    className="form-control"
                    name="idCatVia"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.idCatVia}>
                    <option disabled selected value="">
                      Seleccione una opción
                    </option>

                    {vias.map((opcion) => {
                      return <option value={opcion.idCatVia}>{opcion.via}</option>;
                    })}
                  </select>
                  {datosHojaIndicacion.idCatVia == "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                </Col>

                <Col sm={3} className="pb-3">
                  <input
                    disabled={datosHojaIndicacion.idCatVia != 4}
                    type="text"
                    className="form-control"
                    name="otra"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.otra ? datosHojaIndicacion.otra : ""}
                  />
                  {datosHojaIndicacion.idCatVia == 4 && datosHojaIndicacion.otra == "" && (
                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                  )}
                </Col>
                <label className="col-sm-5 col-form-label">Servicio</label>
                <Col sm={7}>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control"
                    name="concentracionAdministracion"
                    autoComplete="off"
                    value={datosHojaIndicacion.servicio ? datosHojaIndicacion.servicio : ""}
                  />
                </Col>
                <label className="col-sm-5 col-form-label">Peso/Talla/Superficie Corporal</label>
                <Col sm={7}>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control"
                    name="medidasPaciente"
                    autoComplete="off"
                    value={datosHojaIndicacion.medidasPaciente ? datosHojaIndicacion.medidasPaciente : ""}
                  />
                </Col>
                <label className="col-sm-5 col-form-label">Ciclos</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="ciclos"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.ciclos ? datosHojaIndicacion.ciclos : ""}
                  />
                </Col>
                <label className="col-sm-5 col-form-label">Ciclo (Intervalo)</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="ciclo"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.ciclo ? datosHojaIndicacion.ciclo : ""}
                  />
                </Col>
                <label className="col-sm-5 col-form-label">Fecha 1</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="fecha1"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.fecha1 ? datosHojaIndicacion.fecha1 : ""}
                  />
                </Col>
                <label className="col-sm-5 col-form-label">Fecha 2</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="fecha2"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.fecha2 ? datosHojaIndicacion.fecha2 : ""}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl={6} lg={12} md={12}>
              <Row className="form-group mt-3">
                <label className="col-sm-4 col-form-label">Fecha 3</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="fecha3"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.fecha3 ? datosHojaIndicacion.fecha3 : ""}
                  />
                </Col>
                <label className="col-sm-4 col-form-label">Fecha 4</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="fecha4"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.fecha4 ? datosHojaIndicacion.fecha4 : ""}
                  />
                </Col>
                <label className="col-sm-4 col-form-label">Fecha 5</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="fecha5"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.fecha5 ? datosHojaIndicacion.fecha5 : ""}
                  />
                </Col>
                <label className="col-sm-4 col-form-label">Fecha 6</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="fecha6"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.fecha6 ? datosHojaIndicacion.fecha6 : ""}
                  />
                </Col>
                <label className="col-sm-4 col-form-label">Fecha 7</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="fecha7"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.fecha7 ? datosHojaIndicacion.fecha7 : ""}
                  />
                </Col>
                <label className="col-sm-4 col-form-label">Fecha 8</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="fecha8"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.fecha8 ? datosHojaIndicacion.fecha8 : ""}
                  />
                </Col>
                <label className="col-sm-4 col-form-label">Fecha 9</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="fecha9"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.fecha9 ? datosHojaIndicacion.fecha9 : ""}
                  />
                </Col>
                <label className="col-sm-4 col-form-label">Fecha 10</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="fecha10"
                    autoComplete="off"
                    onChange={onChangeHojaIndicaciones}
                    value={datosHojaIndicacion.fecha10 ? datosHojaIndicacion.fecha10 : ""}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Row>

      {/* // Sección Esquema de premedicacion */}

      <Row className="mt-4">
        <CardBody className="mt-4">
          <h5 className="h5 mb-4">
            Esquema de Premedicación
            {/* <span className="text-xs text-red-700">*</span> */}
          </h5>

          <button
            className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-3"
            type="button"
            onClick={nuevoRenglonEsquemaPremedicacion}
            style={{
              marginRight: "2.5rem",
              background: "#ff9933",
              color: "white",
              fontWeight: "bold",
            }}>
            Agregar Entrada
          </button>
          <div className="table-responsive">
            <table className="table table-hover table-centered table-wrap mb-0">
              <thead>
                <tr>
                  <th style={{ width: "30%" }} scope="col">
                    Nombre<br></br>Medicamento
                    {/* <span className="text-xs text-red-700">*</span> */}
                  </th>
                  <th style={{ width: "6%" }} scope="col">
                    DIA DEL<br></br>CICLO
                    {/* <span className="text-xs text-red-700">*</span> */}
                  </th>

                  <th style={{ width: "10%" }} scope="col">
                    DOSIS
                    {/* <span className="text-xs text-red-700">*</span> */}
                  </th>
                  <th style={{ width: "25%" }} scope="col">
                    VOLUMEN Y<br></br>SOLUCIONES
                    {/* <span className="text-xs text-red-700">*</span> */}
                  </th>
                  <th style={{ width: "12%" }} scope="col">
                    VÍA DE<br></br>ADMON.
                    {/* <span className="text-xs text-red-700">*</span> */}
                  </th>
                  <th style={{ width: "10%" }} scope="col">
                    TIEMPO DE<br></br>ADMON.
                    {/* <span className="text-xs text-red-700">*</span> */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {esquemasPremedicacion.length > 0
                  ? esquemasPremedicacion.map((esquemaPremedicacion, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                autoComplete="false"
                                name="nombreComercial"
                                id="nombreComercial"
                                onChange={(e) => changeEsquemaPremedicacionLista(e, index)}
                                list={esquemaPremedicacion.nombreComercial ? `listarPrincipiosActivos${index}` : null}
                                value={
                                  esquemaPremedicacion.nombreComercial ? esquemaPremedicacion.nombreComercial.toUpperCase() : ""
                                }
                                className="form-control"
                              />
                              <datalist id={`listarPrincipiosActivos${index}`}>
                                {recetaProductos.map((med) => {
                                  return (
                                    <>
                                      <option value={med.nombreComercial ? med.nombreComercial.toUpperCase() : null} />
                                    </>
                                  );
                                })}
                              </datalist>
                              {/* {esquemaPremedicacion.nombreComercial == "" && (
                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                              )} */}
                            </td>

                            <td>
                              <input
                                type="text"
                                name="diaCiclo"
                                onChange={(e) => changeEsquemaPremedicacionLista(e, index)}
                                value={esquemaPremedicacion.diaCiclo}
                                className="form-control"
                              />
                              {/* {esquemaPremedicacion.diaCiclo == "" && (
                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                              )} */}
                            </td>
                            <td>
                              <input
                                type="text"
                                name="dosis"
                                onChange={(e) => changeEsquemaPremedicacionLista(e, index)}
                                value={esquemaPremedicacion.dosis}
                                className="form-control"
                              />
                              {/* {esquemaPremedicacion.dosis == "" && (
                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                              )} */}
                            </td>
                            <td>
                              <input
                                type="text"
                                name="volumenSolucion"
                                onChange={(e) => changeEsquemaPremedicacionLista(e, index)}
                                value={esquemaPremedicacion.volumenSolucion}
                                className="form-control"
                              />
                              {/* {esquemaPremedicacion.volumenSolucion == "" && (
                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                              )} */}
                            </td>
                            <td>
                              <input
                                type="text"
                                name="viaAdministracion"
                                onChange={(e) => changeEsquemaPremedicacionLista(e, index)}
                                value={esquemaPremedicacion.viaAdministracion}
                                className="form-control"
                              />
                              {/* {esquemaPremedicacion.viaAdministracion == "" && (
                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                              )} */}
                            </td>
                            <td>
                              <input
                                type="text"
                                name="tiempoAdministracion"
                                onChange={(e) => changeEsquemaPremedicacionLista(e, index)}
                                value={esquemaPremedicacion.tiempoAdministracion}
                                className="form-control"
                              />
                              {/* {esquemaPremedicacion.tiempoAdministracion == "" && (
                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                              )} */}
                            </td>
                            <td>
                              <button
                                className=" btn btn-primary-purple-umo w-md waves-effect waves-light"
                                type="button"
                                style={{
                                  background: "#ff9933",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                                onClick={() => eliminarEsquemaPremedicacion(index)}>
                                Eliminar
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {/* {(esquemasPremedicacion.length == 0 || esquemasPremedicacion == null) && (
              <span className="text-sm text-red-700">Este campo es obligatorio</span>
            )} */}
          </div>
        </CardBody>
      </Row>

      {/* Sección Esquema de tratamiento */}
      <Row className="mt-4">
        <CardBody className="mt-4">
          <h5 className="h5 mb-4">
            Esquema de Tratamiento<span className="text-xs text-red-700">*</span>
          </h5>

          <button
            className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-3"
            type="button"
            onClick={nuevoRenglonEsquemaTratamiento}
            style={{
              marginRight: "2.5rem",
              background: "#ff9933",
              color: "white",
              fontWeight: "bold",
            }}>
            Agregar Entrada
          </button>
          <div className="table-responsive">
            <table className="table table-hover table-centered table-wrap mb-0">
              <thead>
                <tr>
                  <th style={{ width: "30%" }} scope="col">
                    Nombre<br></br>Medicamento<span className="text-xs text-red-700">*</span>
                  </th>
                  <th style={{ width: "6%" }} scope="col">
                    Día del<br></br>Ciclo<span className="text-xs text-red-700">*</span>
                  </th>

                  <th style={{ width: "10%" }} scope="col">
                    Dosis<span className="text-xs text-red-700">*</span>
                  </th>
                  <th style={{ width: "25%" }} scope="col">
                    Volumen y<br></br>Soluciones<span className="text-xs text-red-700">*</span>
                  </th>
                  <th style={{ width: "12%" }} scope="col">
                    Vía de<br></br>Admon.<span className="text-xs text-red-700">*</span>
                  </th>
                  <th style={{ width: "10%" }} scope="col">
                    Tiempo de<br></br>Admon.<span className="text-xs text-red-700">*</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {esquemasTratamiento.length > 0
                  ? esquemasTratamiento.map((esquema, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                autoComplete="false"
                                name="nombreComercial"
                                id="nombreComercial"
                                onChange={(e) => changeEsquemaTratamientoLista(e, index)}
                                list={esquema.nombreComercial ? `listarPrincipiosActivos${index}` : null}
                                value={esquema.nombreComercial ? esquema.nombreComercial.toUpperCase() : ""}
                                className="form-control"
                              />
                              <datalist id={`listarPrincipiosActivos${index}`}>
                                {recetaProductos.map((med) => {
                                  return (
                                    <>
                                      <option value={med.nombreComercial ? med.nombreComercial.toUpperCase() : null} />
                                    </>
                                  );
                                })}
                              </datalist>
                              {esquema.nombreComercial == "" && (
                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                              )}
                            </td>

                            <td>
                              <input
                                type="text"
                                name="diaCiclo"
                                onChange={(e) => changeEsquemaTratamientoLista(e, index)}
                                value={esquema.diaCiclo}
                                className="form-control"
                              />
                              {esquema.diaCiclo == "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                            </td>
                            <td>
                              <input
                                type="text"
                                name="dosis"
                                onChange={(e) => changeEsquemaTratamientoLista(e, index)}
                                value={esquema.dosis}
                                className="form-control"
                              />
                              {esquema.dosis == "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                            </td>
                            <td>
                              <input
                                type="text"
                                name="volumenSolucion"
                                onChange={(e) => changeEsquemaTratamientoLista(e, index)}
                                value={esquema.volumenSolucion}
                                className="form-control"
                              />
                              {esquema.volumenSolucion == "" && (
                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                              )}
                            </td>
                            <td>
                              <input
                                type="text"
                                name="viaAdministracion"
                                onChange={(e) => changeEsquemaTratamientoLista(e, index)}
                                value={esquema.viaAdministracion}
                                className="form-control"
                              />
                              {esquema.viaAdministracion == "" && (
                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                              )}
                            </td>
                            <td>
                              <input
                                type="text"
                                name="tiempoAdministracion"
                                onChange={(e) => changeEsquemaTratamientoLista(e, index)}
                                value={esquema.tiempoAdministracion}
                                className="form-control"
                              />
                              {esquema.tiempoAdministracion == "" && (
                                <span className="text-sm text-red-700">Este campo es obligatorio</span>
                              )}
                            </td>
                            <td>
                              <button
                                className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                type="button"
                                style={{
                                  background: "#ff9933",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                                onClick={() => eliminarEsquemaTratamiento(index)}>
                                Eliminar
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {(esquemasTratamiento.length == 0 || esquemasTratamiento == null) && (
              <span className="text-sm text-red-700">Este campo es obligatorio</span>
            )}
          </div>
        </CardBody>
      </Row>

      <Row className="mt-4">
        {/* Seccion general */}
        <CardBody className="mt-4">
          <h5 className="h5 mb-4">Comentarios extra de las indicaciones</h5>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <textarea
                className="form-control"
                name="comentarioExtra"
                autoComplete="off"
                onChange={onChangeHojaIndicaciones}
                value={datosHojaIndicacion.comentarioExtra ? datosHojaIndicacion.comentarioExtra : ""}
              />
            </Col>
          </Row>
        </CardBody>
      </Row>
      <Row className="mt-4">
        {/* Seccion general */}
        <CardBody className="mt-4">
          <h5 className="h5 mb-4">Modificaciones a Tratamiento</h5>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <textarea
                className="form-control"
                name="modificaciones"
                autoComplete="off"
                onChange={onChangeHojaIndicaciones}
                value={datosHojaIndicacion.modificaciones ? datosHojaIndicacion.modificaciones : ""}
              />
            </Col>
          </Row>
        </CardBody>
      </Row>

      <Row className="mt-4">
        {/* Seccion general */}
        <CardBody className="mt-4">
          <h5 className="h5 mb-4">Nombre, Cédula y Firma del Médico</h5>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group mt-3">
                <label className="col-sm-5 col-form-label">Nombre Médico</label>
                <Col sm={7}>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control"
                    name="nombreMedico"
                    autoComplete="off"
                    // value={medico.catPersona && medico.catPersona.nombres ? medico.catPersona.nombres : ""}
                    value={
                      medico &&
                      medico.catPersona &&
                      (medico.catPersona.nombres ? medico.catPersona.nombres : "") +
                        " " +
                        (medico.catPersona.apellidoPaterno ? medico.catPersona.apellidoPaterno : "") +
                        " " +
                        (medico.catPersona.apellidoMaterno ? medico.catPersona.apellidoMaterno : "")
                    }
                  />
                </Col>
                <label className="col-sm-5 col-form-label">Cédula Profesional</label>
                <Col sm={7}>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control"
                    name="cedulaProfesional"
                    autoComplete="off"
                    // onChange={onChangeHojaIndicaciones}
                    value={medico && medico.cedulaProfesional ? medico.cedulaProfesional : ""}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl={6} lg={12} md={12}>
              <Row className="form-group mt-3">
                <label className="col-sm-4 col-form-label">Especialidad</label>
                <Col sm={7}>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control"
                    name="especialidad"
                    autoComplete="off"
                    // onChange={onChangeHojaIndicaciones}
                    value={medico && medico.especialidad ? medico.especialidad : ""}
                  />
                </Col>
                <label className="col-sm-4 col-form-label">Universidad Especialidad</label>
                <Col sm={7}>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control"
                    name="universidadEspecialidad"
                    autoComplete="off"
                    // onChange={onChangeHojaIndicaciones}
                    value={medico && medico.universidadEspecialidad ? medico.universidadEspecialidad : ""}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Row>

      {autorizado ? (
        <button
          className="btn btn-primary-purple-umo w-md waves-effect waves-light mr-3"
          type="button"
          style={{
            background: "#ff9933",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={() => confirmNuevaHojaIndicaciones()}>
          Guardar
        </button>
      ) : null}

      {autorizado ? (
        <button
          className="btn btn-primary-purple-umo w-md waves-effect waves-light mr-3"
          type="button"
          style={{
            background: "#ff9933",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={() => confirmImpresionHojaIndicaciones()}>
          Imprimir
        </button>
      ) : null}
      {/* <button
        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
        type="button"
        style={{
          background: "#ff9933",
          color: "white",
          fontWeight: "bold",
        }}
        // onClick={() => setDatosReporteMedicamento(datosReporteMedicamentoVacio)}
      >
        Limpiar Datos
      </button> */}

      {UISweetAlert.confirm_alert_hoja_indicaciones_guardar ? (
        <SweetAlert
          title="¿Desea guardar la hoja de indicaciones?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => guardarHojaIndicaciones()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_hoja_indicaciones_guardar: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_hoja_indicacion_duplicar ? (
        <SweetAlert
          title="¿Quieres cargar la última hoja de indicación creada?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => duplicarHojaDeIndicaciones(resHojaIndicacion)}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_hoja_indicacion_duplicar: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_hoja_indicaciones_imprimir ? (
        <SweetAlert
          title="¿Desea imprimir la hoja de indicaciones?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => imprimirHojaIndicaciones()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_hoja_indicaciones_imprimir: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
      {Prompt}
    </div>
  );
}
