import React, {useReducer} from 'react'

import EstatusVentaMostradorContext from './EstatusVentaMostradorContext'
import EstatusVentaMostradorReducer from './EstatusVentaMostradorReducer'

import {
    LISTAR_ESTATUSVENTASMOSTRADOR,
    ESTATUS_CANCELADO,
    ESTATUS_COBRO
} from '../../types'

import clienteAxios from '../../config/axios'


export default function EstatusVentaMostradorState (props) {

    const initialState = { 
        estatusVentaMostrador:[],
        estatusCancelado: [],
        estatusCobrado: [],
    }

    const [state, dispatch] = useReducer(EstatusVentaMostradorReducer,initialState)

    const listarEstatusVentaMostrador = async () => {

        const res = await clienteAxios.get("/estatusventamostrador/activos"  , {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })

        dispatch({
            type: LISTAR_ESTATUSVENTASMOSTRADOR,
            payload: res.data
        })
    }

    const getEstatusCancelado = async() => {

        const res = await clienteAxios.get(`/estatusventamostrador/cancelado`, {
            headers: {
              "Access-Control-Allow-Origin": "*"
                }
        })
        dispatch({
            type: ESTATUS_CANCELADO,
            payload: res.data
        })
    }

    const getEstatusCobrado = async() => {

        const res = await clienteAxios.get(`/estatusventamostrador/cobro`, {
            headers: {
              "Access-Control-Allow-Origin": "*"
                }
        })
        dispatch({
            type: ESTATUS_COBRO,
            payload: res.data
        })
    }

    return (

        <EstatusVentaMostradorContext.Provider value={{
            estatusVentaMostrador: state.estatusVentaMostrador,
            estatusCancelado: state.estatusCancelado,
            estatusCobrado: state.estatusCobrado,
            listarEstatusVentaMostrador,
            getEstatusCancelado,
            getEstatusCobrado
        }}>
            {props.children}
        </EstatusVentaMostradorContext.Provider>

    )


}