import React, {useReducer} from "react";

import EspaciosAtencionContext from "./EspaciosAtencionContext";
import EspaciosAtencionReducer from "./EspaciosAtencionReducer";

import {
    FILTRO_ESPACIOSATENCION,
    LISTAR_ESPACIOSATENCION,
    ERROR_UPLOAD_ESPACIOATENCION,
    LISTAR_ESPACIOSATENCION_AGENDA,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function EspaciosAtencionState(props) {
    const initialState = {
        espaciosAtencion: [],
        espaciosAtencionAgenda: [],
        filtrosEspaciosAtencion: {},
        filtrosEspaciosAtencionAgenda: {},
    };

    const [state, dispatch] = useReducer(EspaciosAtencionReducer, initialState);

    const setFiltrosEspaciosAtencionAgenda = async (filtrosEspaciosAtencionAgenda) => {
        dispatch({
            type: FILTRO_ESPACIOSATENCION,
            payload: filtrosEspaciosAtencionAgenda,
        });
    };
    
    const setFiltrosEspaciosAtencion = async (filtrosEspaciosAtencion) => {
        dispatch({
            type: FILTRO_ESPACIOSATENCION,
            payload: filtrosEspaciosAtencion,
        });
    };

    const listarEspaciosAtencion = async (activo) => {
        const res = await clienteAxios.post("/espacio/filtro/espacios",
            {...state.filtrosEspaciosAtencion, activo: activo},
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );
        if (res.data.length > 0) {
            dispatch({
                type: LISTAR_ESPACIOSATENCION,
                payload: res.data,
            });
        }
        return res.data;
    };

    const listarEspaciosAtencionSinDispatch = async (activo) => {
        const res = await clienteAxios.post("/espacio/filtro/espacios",
            {...state.filtrosEspaciosAtencion, activo: activo},
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );
        return res.data;
    };

    const listarEspaciosAtencionAgenda = async (activo) => {
        const res = await clienteAxios.post("/espacio/filtro/espacios",
            {...state.filtrosEspaciosAtencionAgenda, activo: activo, tipoEspacio: 4}, /* SIN CONSULTORIOS */
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );

        if (res.data.length > 0) {
            dispatch({
                type: LISTAR_ESPACIOSATENCION_AGENDA,
                payload: res.data,
            });
        }
        return res.data;
    };

    const setEspaciosAtencion = async (espaciosAtencion) => {
        dispatch({
            type: LISTAR_ESPACIOSATENCION,
            payload: espaciosAtencion,
        });
    };

    //************************************************************************************************************ */
    //AQUI ESTA LA LLAMADA A LA API DONDE SE MANDAN LOS DATOS DEL NUEVO ESPACIO
    //************************************************************************************************************ */
    const uploadEspacioAtencion = async (espacioAtencion) => {
        try {
            const res = await clienteAxios.post(`/espacio/agenda/guardar`, espacioAtencion, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            });
            return res;
        } catch (error) {
            dispatch({
                type: ERROR_UPLOAD_ESPACIOATENCION,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }
            return error.response;
        }
    };
    return (
        <EspaciosAtencionContext.Provider
            value={{
                espaciosAtencion: state.espaciosAtencion,
                filtrosEspaciosAtencion: state.filtrosEspaciosAtencion,
                filtrosEspaciosAtencionAgenda: state.filtrosEspaciosAtencionAgenda,
                espaciosAtencionAgenda: state.espaciosAtencionAgenda,
                setFiltrosEspaciosAtencion,
                listarEspaciosAtencion,
                listarEspaciosAtencionAgenda,
                setFiltrosEspaciosAtencionAgenda,
                setEspaciosAtencion,
                uploadEspacioAtencion,
                listarEspaciosAtencionSinDispatch,
            }}>
            {props.children}
        </EspaciosAtencionContext.Provider>
    );
}
