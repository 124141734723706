export const assignRandomColorsToDatasets = (data) => {
    const randomColors = data.map(() => {
        const randomComponent = () => Math.floor(Math.random() * 256);
        const r = randomComponent();
        const g = randomComponent();
        const b = randomComponent();
        return `rgb(${r}, ${g}, ${b})`;
    });

    return data.map((dataset, index) => ({
        ...dataset,
        borderColor: randomColors[index],
        backgroundColor: randomColors[index],
    }));
};

export const assignRandomColorsToDatasetsBar = (data) => {
    const randomColors = data.map(() => {
        const randomComponent = () => Math.floor(Math.random() * 256);
        const r = randomComponent();
        const g = randomComponent();
        const b = randomComponent();
        return `rgb(${r}, ${g}, ${b})`;
    });

    return data.map((dataset, index) => ({
        ...dataset,
        borderColor: randomColors[index],
        backgroundColor: randomColors[index],
        borderRadius: 5,
    }));
};

export const generateRandomColor = () => {
    const randomComponent = () => Math.floor(Math.random() * 256);
    const r = randomComponent();
    const g = randomComponent();
    const b = randomComponent();
    return `rgb(${r}, ${g}, ${b})`;
};