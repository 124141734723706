import React, {useState, useContext, useEffect} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {Spinner} from "reactstrap";
import clienteAxios from "../../config/axios";
import imagen from '../../assets/images/umo_logo.jpg'; // Imagen local
import linea from '../../assets/images/linea.jpg'; // Imagen local

const AgendaFlotantePantallas = (data) => {

    const [loading, setLoading] = useState(false);
    const [listaCitas, setListaCitas] = useState([]);
    const [fechaInforme, setFechaInforme] = useState("");

    const sweetAlertDefault = {
        editar: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    };

  const exportAgendaPorDia = async (filtrosImpresion) => {
      try {
        const res = await clienteAxios.post("/agenda/import/dia", filtrosImpresion, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        });          
      
        return res;
      } catch (error) {
        if (!error.response) {
          error.response = {
            data: {
              message: "Fallo en la conexión al servidor",
              error: "hubo error",
            },
          };
        } else {
          error.response = {
            data: {
              message: "Error, por favor inténtalo más tarde",
              error: "Error",
            },
          };
        }
  
        return error.response;
      }
    };

  const [UISweetAlert, setUISweetAlert] = useState(sweetAlertDefault);

  const visualizarCitas = async () => {
    setLoading(true);
    try {

      const dateString = data.data.fechaBusqueda; // Asume que esta es una cadena 'YYYY-MM-DD'

      const daysOfWeek = [
        'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
      ];
      
      // Crear un objeto Date directamente
      const date = new Date(dateString + 'T00:00:00'); // Agrega la hora para evitar ajustes de zona horaria
      
      const dayOfWeek = date.getDay(); // Obtiene el día de la semana (0 = Domingo, 1 = Lunes, ...)
      
      let cadena = daysOfWeek[dayOfWeek] + ", " + date.toLocaleDateString(); // Formatea la fecha localmente
      
      setFechaInforme(cadena); // Establece la fecha en el estado

      const res = await exportAgendaPorDia({
          fechaBusqueda: data.data.fechaBusqueda,
          todosEspacios: data.data.todosEspacios ? data.data.todosEspacios : false,
          todosPacientes: data.data.todosPacientes ? data.data.todosPacientes : false,
          porDia: data.data.porDia,
          incluirTratamientos: data.data.incluirTratamientos,
          incluirCitas: data.data.incluirCitas,
          porAseguradora: data.data.porAseguradora ? data.data.porAseguradora : false,
          espaciosVo: data.data.seleccionarTodosEspacios ? null : data.data.seleccionarTodosEspacios,
      });

      if (res.data && res.data.error) {
          setLoading(false);
          setUISweetAlert({
              ...sweetAlertDefault,
              error_dlg: true,
              dynamic_title: "Fallo",
              dynamic_description: res.data.message ? res.data.message : "",
          });
      } else {
          setLoading(false);
      }

      const currentDate = new Date();
      const horaSistema = currentDate.getHours();

      const finalPc = res.data.filter((value) => {    
          let horaTemp = value.horaInteger;

          if(horaTemp >= horaSistema-1){
            let retorno = {
              idCita : value.idCita,
              paciente : value.paciente,
              horaCita: value.horaCadena,
              consultorio: value.consultorio,
              doctor:  value.medico,
            }
            return retorno
          }
      }); 

      setListaCitas(finalPc)

    } catch (error) {
      setLoading(false);
    setUISweetAlert({
          ...sweetAlertDefault,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Error en el servidor",
      });
    }
  };
    
  useEffect(() => {    
    const fetchCitas = async () => {
          visualizarCitas();
    };

    fetchCitas(); 

    const interval = setInterval(() => {
      fetchCitas(); // Llama a la función de actualización cada 60 segundos
    }, 60000); // 60000 milisegundos = 60 segundos
  
      // Limpia el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
      
  }, []);

  return (
    
    <div style={{ backgroundColor: 'BlueViolet', color: 'white', padding: '5px'}}>
            <table   style={{ backgroundColor: 'White'}} width= "100%">
                  <tr>
                      <td width="33%" align="left">
                          <img src={imagen} alt="Descripción" width={250} height={50} className="img-estilo" />
                      </td>
                      <td width="33%" align="center">
                          <h1>Agenda del día</h1>
                      </td>
                      <td width="33%" align="center">
                         {fechaInforme}
                      </td>
                  </tr>
                  <tr>
                      <td colSpan={3}>
                      <img src={linea} alt="Descripción" width="100%" height={10} className="img-estilo" />
                      </td>
                  </tr>
            </table>        
             <table  style={{ backgroundColor: 'White'}} className="table" width= "100%">
                <thead>
                <tr style={{ backgroundColor: '#284287',  color: 'white', height:'30px' }}> 
                    <th width = {'10%'} backgroundColor="Yellow" align="center" scope="col">Hora</th>
                    <th width = {'30%'} align="left" scope="col">Paciente</th>
                    <th width = {'30%'}  align="left" scope="col">Médico</th>
                    <th width = {'30%'}  align="left" scope="col">Consultorio</th>
                </tr>
                </thead>
                <tbody>
                {listaCitas? listaCitas.map((cita,index) => {
                    return (
                              <tr
                              key={index}
                              style={{
                                backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff', // Alterna entre color par e impar
                                padding: '10px',
                                height: '50px',
                                border: '1px solid #ddd',
                              }}
                            >                       
                            <td style={{width: "10%"}} align="center">{cita.horaCadena ? cita.horaCadena : "0"}</td>
                            <td>{cita.paciente ? cita.paciente: ""}</td>
                            <td>{cita.medico ? cita.medico : ""}</td>
                            <td>{cita.consultorio ? cita.consultorio : ""}</td>
                        </tr>
                        
                    );
                }):null}                                       
                </tbody>                                                          
            </table>

        {loading ? (
           <SweetAlert title="Cargando!" onConfirm={() => {}} showConfirm={false}>
             <Spinner/>
             {/* {UISweetAlert.dynamic_description ? UISweetAlert.dynamic_description : ""} */}
           </SweetAlert>
        ) : null}      
    </div>

  );

};



export default AgendaFlotantePantallas;