import React, { useContext, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Spinner, Col } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectAsyncPaginate from "../SelectAsyncPaginate";

import PadecimientoActualContext from "../../context/PadecimientoActual/PadecimientoActualContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import CatDiagnosticosContext from "../../context/CatDiagnosticos/CatDiagnosticosContext";

import { maxCharEtapaClinica } from "../../config/variables";

import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";

import WindowWarningContext from "../../context/WindowWarning/WindowWarningContext";

import { opcionesSelectDiagnosticos } from "../../config/configOpcionesSelect";

export default function DiagnosticoPlan({
  props,
  padecimientoActual,
  historiaClinicaInicial,
  nuevoResumen,
  nuevaRevisionCaso,
  changeExtraResumen,
  changeExtraRevisionCaso,
  setNuevaRevisionCaso,
  setNuevoResumen,
}) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito, changeGuardado } = windowWarningContext;

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { seleccionarHistoriaClinicaInicialPaciente, uploadHistoriaClinicaInicial } = ctxHistClinInic;

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_guardar_padecimiento: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  // const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [padecimientoVacio, setPadecimientoVacio] = useState(true);
  // const [opcionDiagnostico, setOpcionDiagnostico] = useState("");

  // const [diagnosticosFiltered, setDiagnosticosFiltered] = useState(null);

  const ctxCatDiagnosticos = useContext(CatDiagnosticosContext);
  const { listarCatDiagnosticos, loadDiagnosticosSelect } = ctxCatDiagnosticos;

  // useEffect(() => {
  //   const fetchDiagnosticos = async () => {
  //     listarCatDiagnosticos();
  //   };
  //   fetchDiagnosticos();
  // }, []);

  const ctxPadAct = useContext(PadecimientoActualContext);
  const { setPadecimientoActual, uploadPadecimientoActual } = ctxPadAct;

  const pacientesContext = useContext(PacientesContext);
  // eslint-disable-next-line no-unused-vars
  const { idCatPacienteSeleccionado, setIdCatPacienteSeleccionado } = pacientesContext;

  const uploadPadecimientoActualConfirm = async () => {
    try {
      let revisionActualizada;
      let resumenActualizado;
      if (nuevoResumen && nuevoResumen != "") resumenActualizado = changeExtraResumen();
      setNuevoResumen("");
      if (nuevaRevisionCaso && nuevaRevisionCaso != "") revisionActualizada = changeExtraRevisionCaso();
      setNuevaRevisionCaso("");
      if (
        historiaClinicaInicial &&
        historiaClinicaInicial.expedienteMedico &&
        historiaClinicaInicial.expedienteMedico.idExpedienteMedico
      ) {
        setLoading(true);
        const res = await uploadPadecimientoActual(null, true);
        const resHistoriaClinica = await uploadHistoriaClinicaInicial(null, resumenActualizado, revisionActualizada);
        if (res.data.error || resHistoriaClinica.data.error) {
          setLoading(false);
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: res.data.message,
          });
        } else if (res) {
          // setSeGuardaronCambios(true);
          changeGuardado(true);
          setLoading(false);
          setUISweetAlert({
            ...UISweetAlert,
            success_dlg: true,
            editar: false,
            dynamic_title: "Exito",
            confirm_alert_guardar_padecimiento: false,
            dynamic_description: "Se guardó de manera correcta la información, sobre el padecimiento actual.",
          });
          setLoading(false);
          setIdCatPacienteSeleccionado(res.data.idCatPaciente);
          seleccionarHistoriaClinicaInicialPaciente(res.data.idCatPaciente);

          // setOpcionDiagnostico("");
        }
      } else {
        setLoading(true);
        const resHistoriaClinica = await uploadHistoriaClinicaInicial(null, resumenActualizado, revisionActualizada);

        if (resHistoriaClinica && resHistoriaClinica.data) {
          const res = await uploadPadecimientoActual(resHistoriaClinica.data.idCatPaciente, false);

          if (res.data.error || resHistoriaClinica.data.error) {
            setLoading(false);
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              dynamic_title: "Fallo",
              dynamic_description: res.data.message,
            });
          } else if (res) {
            // setSeGuardaronCambios(true);
            changeGuardado(true);
            setLoading(false);
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: true,
              editar: false,
              dynamic_title: "Exito",
              confirm_alert_guardar_padecimiento: false,
              dynamic_description: "Se guardó de manera correcta la información, sobre el padecimiento actual.",
            });

            setIdCatPacienteSeleccionado(res.data.idCatPaciente);
            seleccionarHistoriaClinicaInicialPaciente(res.data.idCatPaciente);
            // checkPadecimientoActualPaciente();
            // setOpcionDiagnostico("");
          }
        } else {
          setLoading(false);
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: resHistoriaClinica.data.message,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, intentalo más tarde",
      });
    }
  };

  useEffect(() => {
    listarCatDiagnosticos();
  }, []);

  // const checkDiagnostico = (name, value) => {
  //   // if (value.length > 3) listarCatDiagnosticosMatch(value);
  //   const diagnostico =
  //     catDiagnosticos &&
  //     catDiagnosticos.filter((diag) => diag.diagnostico.toLowerCase().normalize() === value.toLowerCase().normalize());
  //   // const diagnostico = catDiagnosticos
  //   //   ? catDiagnosticos.filter((diag) => {
  //   //       if (diag.diagnostico !== null) {
  //   //         return diag.diagnostico.toLowerCase().normalize() === value.toLowerCase().normalize();
  //   //       }
  //   //     })
  //   //   : [];

  //   if (diagnostico.length === 1) {
  //     setOpcionDiagnostico(value);
  //     setPadecimientoActual({
  //       ...padecimientoActual,
  //       expedienteMedico: {
  //         ...padecimientoActual.expedienteMedico,
  //         // diagnostico: padecimientoActual.expedienteMedico.diagnostico + value,
  //         diagnostico: padecimientoActual.expedienteMedico.diagnostico
  //           ? padecimientoActual.expedienteMedico.diagnostico + value
  //           : value,
  //         idCatDiagnostico: diagnostico[0].idCatDiagnostico,
  //       },
  //     });
  //     // listarCatDiagnosticosMatch(value);
  //   } else {
  //     setOpcionDiagnostico(value);
  //     setPadecimientoActual({
  //       ...padecimientoActual,
  //       expedienteMedico: {
  //         ...padecimientoActual.expedienteMedico,
  //         diagnostico: padecimientoActual.expedienteMedico.diagnostico,
  //         idCatDiagnostico: null,
  //       },
  //     });
  //     // listarCatDiagnosticosMatch(value);
  //   }
  // };

  const onChangeOpcionDiagnostico = async (e) => {
    // if (!isEdit) setEditTrue();
    if (!seEdito) {
      // changeGuardado(false);
      changeEdit(true);
    }

    if (e) {
      // const diagnosticosFiltrados =
      //   e.target.value !== "" &&
      //   catDiagnosticos &&
      //   catDiagnosticos.filter((data) => JSON.stringify(data).toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);

      // setDiagnosticosFiltered(diagnosticosFiltrados);
      // checkDiagnostico(e.target.name, e.target.value);
      setPadecimientoActual({
        ...padecimientoActual,
        expedienteMedico: {
          ...padecimientoActual.expedienteMedico,
          // diagnostico: padecimientoActual.expedienteMedico.diagnostico + value,
          diagnostico: padecimientoActual.expedienteMedico.diagnostico
            ? padecimientoActual.expedienteMedico.diagnostico + ", " + e.diagnostico
            : e.diagnostico,
          idCatDiagnostico: e.idCatDiagnostico,
        },
      });
    }
  };

  const onChange = async (e) => {
    // if (!isEdit) setEditTrue();
    if (!seEdito) {
      // changeGuardado(false);
      changeEdit(true);
    }

    setPadecimientoActual({
      ...padecimientoActual,
      expedienteMedico: {
        ...padecimientoActual.expedienteMedico,
        [e.target.name]: e.target.value,
        idCatDiagnostico: null,
      },
    });
  };

  return (
    <div className="mt-4" style={{ textAlign: "left" }}>
      <Row>
        <h4>Diagnóstico y Plan</h4>
      </Row>
      <Row className="form-group">
        <label className="col-sm-2 col-form-label">Diagnóstico</label>
        <Col sm={10}>
          <SelectAsyncPaginate
            // opcionSeleccionadaCompleta={pacienteSeleccionadoCompleto}
            onChangeOptions={onChangeOpcionDiagnostico}
            fetchOptions={loadDiagnosticosSelect}
            opcionesPaginado={opcionesSelectDiagnosticos.opcionesPaginado}
            placeholderText={opcionesSelectDiagnosticos.placeholderText}
            optionLabel={opcionesSelectDiagnosticos.optionLabel}
            optionValue={opcionesSelectDiagnosticos.optionValue}
          />
          {/* <input
            placeholder="Catálogo de diagnosticos"
            name="diagnosticoOpcion"
            id="diagnosticoOpcion"
            value={opcionDiagnostico}
            onChange={(e) => onChange(e)}
            list={"diagnosticos"}
            className="form-control w-full mb-2"
          /> */}
          {/* <datalist id={"diagnosticos"}>
            {diagnosticosFiltered &&
              diagnosticosFiltered.map((diagnostico, index) => {
                return (
                  <>
                    <option key={diagnostico.idCatDiagnostico} value={diagnostico.diagnostico}>
                      {diagnostico.diagnostico}
                    </option>
                  </>
                );
              })}
          </datalist> */}

          <textarea
            name="diagnostico"
            value={
              padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.diagnostico
                ? padecimientoActual.expedienteMedico.diagnostico
                : ""
            }
            onChange={onChange}
            className="form-control mt-2"
          />
        </Col>
      </Row>
      <Row className="form-group">
        <label className="col-sm-2 col-form-label">Etapa Clinica</label>
        <Col sm={10}>
          <input
            type="text"
            name="etapaClinica"
            maxLength={maxCharEtapaClinica}
            value={
              padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.etapaClinica
                ? padecimientoActual.expedienteMedico.etapaClinica
                : ""
            }
            onChange={onChange}
            className="form-control"
          />
        </Col>
      </Row>
      <Row className="form-group">
        <label className="col-sm-2 col-form-label">Plan</label>
        <Col sm={10}>
          <textarea
            name="plan"
            value={
              padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.plan
                ? padecimientoActual.expedienteMedico.plan
                : ""
            }
            onChange={onChange}
            className="form-control"
          />
        </Col>
      </Row>
      <Row className="form-group">
        <label className="col-sm-2 col-form-label">Esquema</label>
        <Col sm={10}>
          <textarea
            name="esquema"
            value={
              padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.esquema
                ? padecimientoActual.expedienteMedico.esquema
                : ""
            }
            onChange={onChange}
            className="form-control"
          />
        </Col>
      </Row>

      <button
        className="btn btn-primary-blue-umo w-md waves-effect waves-light"
        type="button"
        // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
        onClick={() => {
          setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_guardar_padecimiento: true,
            editar: false,
          });
        }}>
        {historiaClinicaInicial &&
        historiaClinicaInicial.expedienteMedico &&
        historiaClinicaInicial.expedienteMedico.idExpedienteMedico
          ? "Guardar"
          : "Crear expediente del paciente"}
      </button>

      {UISweetAlert.confirm_alert_guardar_padecimiento ? (
        <SweetAlert
          title="¿Desea guardar los cambios al expediente médico?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadPadecimientoActualConfirm()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar_padecimiento: false,
              editar: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
      {/* {Prompt} */}
    </div>
  );

  //   function checkPadecimientoActualPaciente() {
  //     if (padecimientoVacio) {
  //       if (idCatPacienteSeleccionado) {
  //         seleccionarPadecimientoActualPaciente(idCatPacienteSeleccionado);
  //       } else {
  //         setPadecimientoActual({});
  //       }
  //       setPadecimientoVacio(false);
  //     }
  //   }
  // }
}
