import React, { useContext, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col } from "reactstrap";
import CatDiagnosticosContext from "../../../context/CatDiagnosticos/CatDiagnosticosContext";
// import useCloseWindowWarning from "../../../hooks/useCloseWindowWarning";

import WindowWarningContext from "../../../context/WindowWarning/WindowWarningContext";

export default function DatosGenerales({
  props,
  padecimientoActual,
  setPadecimientoActual,
  setOpcionDiagnostico,
  seGuardaronCambios,
}) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito } = windowWarningContext;

  // const [Prompt, setEditTrue, setEditFalse, isEdit] = useCloseWindowWarning();

  const ctxCatDiagnosticos = useContext(CatDiagnosticosContext);
  const { catDiagnosticos } = ctxCatDiagnosticos;
  // const [options, setOptions] = useState(catDiagnosticos);

  // const [opcionDiagnostico, setOpcionDiagnostico] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [diagnosticosFiltered, setDiagnosticosFiltered] = useState(null);

  const checkDiagnostico = (name, value) => {
    // if (value.length > 3) listarCatDiagnosticosMatch(value);
    const diagnostico =
      catDiagnosticos &&
      catDiagnosticos.filter((diag) => diag.diagnostico.toLowerCase().normalize() === value.toLowerCase().normalize());

    if (diagnostico.length === 1) {
      setOpcionDiagnostico(value);
      setPadecimientoActual({
        ...padecimientoActual,
        expedienteMedico: {
          ...padecimientoActual.expedienteMedico,
          // diagnostico: padecimientoActual.expedienteMedico.diagnostico + value,
          diagnostico: padecimientoActual.expedienteMedico.diagnostico
            ? padecimientoActual.expedienteMedico.diagnostico + value
            : value,
          idCatDiagnostico: diagnostico[0].idCatDiagnostico,
        },
      });
      // listarCatDiagnosticosMatch(value);
    } else {
      setOpcionDiagnostico(value);
      setPadecimientoActual({
        ...padecimientoActual,
        expedienteMedico: {
          ...padecimientoActual.expedienteMedico,
          diagnostico: padecimientoActual.expedienteMedico.diagnostico,
          idCatDiagnostico: null,
        },
      });
      // listarCatDiagnosticosMatch(value);
    }
  };

  const onChange = async (e) => {
    // if (!isEdit) setEditTrue();
    if (!seEdito) {
      changeEdit(true);
    }
    // changeGuardado(false);
    if (e.target.name == "talla") {
      let value = e.target.value;
      if (!padecimientoActual.expedienteMedico) {
        padecimientoActual.expedienteMedico = {};
        padecimientoActual.expedienteMedico.talla = value;
      } else padecimientoActual.expedienteMedico.talla = value;
      setPadecimientoActual(padecimientoActual);

      calcularSCT(value, padecimientoActual.expedienteMedico.peso ? padecimientoActual.expedienteMedico.peso : null);
      calcularIMC(value, padecimientoActual.expedienteMedico.peso ? padecimientoActual.expedienteMedico.peso : null);
    } else if (e.target.name == "peso") {
      let value = e.target.value;
      if (!padecimientoActual.expedienteMedico) {
        padecimientoActual.expedienteMedico = {};
        padecimientoActual.expedienteMedico.peso = value;
      } else padecimientoActual.expedienteMedico.peso = value;

      setPadecimientoActual(padecimientoActual);

      calcularSCT(padecimientoActual.expedienteMedico.talla ? padecimientoActual.expedienteMedico.talla : null, value);
      calcularIMC(padecimientoActual.expedienteMedico.talla ? padecimientoActual.expedienteMedico.talla : null, value);
    } else if (e.target.name == "diagnosticoOpcion") {
      const diagnosticosFiltrados =
        e.target.value !== "" &&
        catDiagnosticos &&
        catDiagnosticos.filter((data) => JSON.stringify(data).toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);

      setDiagnosticosFiltered(diagnosticosFiltrados);
      checkDiagnostico(e.target.name, e.target.value);
    } else {
      setPadecimientoActual({
        ...padecimientoActual,
        expedienteMedico: {
          ...padecimientoActual.expedienteMedico,
          [e.target.name]: e.target.value,
          idCatDiagnostico: null,
        },
      });
    }
  };

  const calcularSCT = async (talla, peso) => {
    if (talla && peso) {
      let sct = peso ** 0.425 * talla ** 0.725 * 0.007184;
      padecimientoActual.expedienteMedico.superficieCorporalTotal = sct.toFixed(2);
      setPadecimientoActual(padecimientoActual);
    }
  };
  const calcularIMC = async (talla, peso) => {
    if (talla && peso) {
      const tallEnMetros = talla / 100;
      let imc = peso / Math.pow(tallEnMetros, 2);

      padecimientoActual.expedienteMedico.indiceMasaCorporal = imc.toFixed(1);
      setPadecimientoActual(padecimientoActual);
    }
  };

  // useEffect(() => {
  //   setEditFalse();
  // }, [seGuardaronCambios]);

  return (
    <div className="mt-4" style={{ textAlign: "center" }}>
      {/* <Row>
        <h4>Datos Generales</h4>
      </Row> */}

      <Row className="form-group">
        <label className="col-sm-2 col-form-label">Padecimiento Actual</label>
        <Col sm={10}>
          <textarea
            name="padecimiento"
            value={
              padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.padecimiento
                ? padecimientoActual.expedienteMedico.padecimiento
                : ""
            }
            onChange={onChange}
            className="form-control"
          />
        </Col>
      </Row>

      {/* <Row className="form-group">
            <label className="col-sm-2 col-form-label">Diagnóstico</label>
            <Col sm={10}>
              <input
                placeholder="Catálogo de diagnosticos"
                name="diagnosticoOpcion"
                id="diagnosticoOpcion"
                value={opcionDiagnostico}
                onChange={(e) => onChange(e)}
                list={"diagnosticos"}
                className="form-control w-full mb-2"
              />
              <datalist id={"diagnosticos"}>
                {diagnosticosFiltered &&
                  diagnosticosFiltered.map((diagnostico, index) => {
                    return (
                      <>
                        <option key={diagnostico.idCatDiagnostico} value={diagnostico.diagnostico}>
                          {diagnostico.diagnostico}
                        </option>
                      </>
                    );
                  })}
              </datalist>

              <textarea
                name="diagnostico"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.diagnostico
                    ? padecimientoActual.expedienteMedico.diagnostico
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Etapa Clinica</label>
            <Col sm={10}>
              <input
                type="text"
                name="etapaClinica"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.etapaClinica
                    ? padecimientoActual.expedienteMedico.etapaClinica
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Plan</label>
            <Col sm={10}>
              <textarea
                name="plan"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.plan
                    ? padecimientoActual.expedienteMedico.plan
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Esquema</label>
            <Col sm={10}>
              <textarea
                name="esquema"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.esquema
                    ? padecimientoActual.expedienteMedico.esquema
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row> */}
      {/* {Prompt} */}
    </div>
  );
}
