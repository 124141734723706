import React, {useReducer} from 'react'

import CatTipoEspacioContext from './CatTipoEspacioContext'
import CatTipoEspacioReducer from './CatTipoEspacioReducer'

import {
    LISTAR_CATTIPOESPACIOS
} from '../../types'

import clienteAxios from '../../config/axios'


export default function CatTipoEspacioState (props) {

    const initialState = { 
        tipoEspacios:[],
    }

    const [state, dispatch] = useReducer(CatTipoEspacioReducer,initialState)

    const listarTipoEspacios = async () => {

        const res = await clienteAxios.get("/tipoespacios/activos"  , {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
        dispatch({
            type: LISTAR_CATTIPOESPACIOS,
            payload: res.data
        })
    }

    return (

        <CatTipoEspacioContext.Provider value={{
            tipoEspacios: state.tipoEspacios,
            listarTipoEspacios,
        }}>
            {props.children}
        </CatTipoEspacioContext.Provider>

    )


}