import React, { useReducer } from "react";

import RecetaExpedienteContext from "./RecetaExpedienteContext";
import RecetaExpedienteReducer from "./RecetaExpedienteReducer";

import { SET_RECETAS_PAGINADAS } from "../../types";

import clienteAxios from "../../config/axios";

export default function RecetaExpedienteState(props) {
  const initialState = {
    recetas: [],
  };

  const [state, dispatch] = useReducer(RecetaExpedienteReducer, initialState);

  const setRecetasPaginadas = async ({ page, limit, idConsultaSeguimiento }) => {
    try {
      const response = await clienteAxios.post(
        "/receta/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          idConsultaSeguimiento: idConsultaSeguimiento,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      response.data.recetaVoList.forEach((receta) => {
        receta.fechaReceta = `${receta.fechaReceta.split("-")[2]}/${receta.fechaReceta.split("-")[1]}/${receta.fechaReceta.split("-")[0]}`;
      });

      dispatch({
        type: SET_RECETAS_PAGINADAS,
        payload: response.data.recetaVoList,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const uploadReceta = async (datosReceta) => {
    try {
      const res = await clienteAxios.post(`/receta/guardar`, datosReceta, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const getMedicoByConsultaSeguimiento = async (idConsultaSeguimiento) => {
    const res = await clienteAxios.post(
      `/receta/consulta/medico?id=${idConsultaSeguimiento}`,

      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    return res.data;
  };

  const exportReceta = async (receta) => {
    try {
      const res = await clienteAxios.post(`/receta/export`, receta, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=receta.pdf",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      var file = new Blob([res.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <RecetaExpedienteContext.Provider value={{ recetas: state.recetas, uploadReceta, getMedicoByConsultaSeguimiento, exportReceta, setRecetasPaginadas }}>
      {props.children}
    </RecetaExpedienteContext.Provider>
  );
}
