import React, {useReducer} from "react";

import MedicosContext from "./MedicosContext";
import MedicosReducer from "./MedicosReducer";

import {
    LISTAR_MEDICOS,
    FILTRO_MEDICOS,
    LISTAR_MEDICOS_ACTIVOS,
    ID_MEDICO_SELECCIONADO,
    LISTAR_MEDICOS_INTERNOS,
    LISTAR_MEDICOS_AGENDA,
    SET_MEDICO,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function MedicosState(props) {
    const initialState = {
        medicos: [],
        medicosActivos: [],
        filtrosMedicos: {},
        idCatMedicoSeleccionado: localStorage.getItem("idCatMedicoSeleccionado"),
        medicosInternos: [],
        medicosAgenda: [],
        medico: {},
    };

    const [state, dispatch] = useReducer(MedicosReducer, initialState);

    const setDataMedico = async ({page, limit}) => {
        try {
            const response = await clienteAxios.post(
                "/medicos/paginado/",
                {pageSize: limit, pageIndex: page, ...state.filtrosMedicos},
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            return response.data;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const listarMedicos = async () => {
        const res = await clienteAxios.get("/medicos/activos", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: LISTAR_MEDICOS,
            payload: res.data,
        });

        return res.data;
    };

    const listarMedicosActivos = async () => {
        const res = await clienteAxios.get("/medicos/activos", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: LISTAR_MEDICOS_ACTIVOS,
            payload: res.data,
        });
    };

    const setFiltrosMedicos = async (filtrosMedicos) => {
        dispatch({
            type: FILTRO_MEDICOS,
            payload: filtrosMedicos,
        });
    };

    const uploadNuevoMedico = async (datosMedico) => {
        // Checar que el cliente no tenga el mismo CURP que otro cliente en la base datos
        try {
            const res = await clienteAxios.post(`/medicos/guardar`, datosMedico, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const eliminarMedico = async (idCatMedico) => {
        // Sustituir delete con dirección del back
        // const res = await clienteAxios.delete(`/medicos/${idCatMedico}`)
        try {
            const response = await clienteAxios.post("/medicos/eliminar/",
                {idCatMedico: idCatMedico},
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            return response;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const updateMedico = async (clienteModificado) => {
        try {
            const res = await clienteAxios.post(`/medicos/guardar`, clienteModificado, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const setIdCatMedicoSeleccionado = async (idCatMedicoSeleccionado) => {
        dispatch({
            type: ID_MEDICO_SELECCIONADO,
            payload: idCatMedicoSeleccionado,
        });
    };

    const listarMedicosInternos = async () => {

        const res = await clienteAxios.get("/medicos/internos", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: LISTAR_MEDICOS_INTERNOS,
            payload: res.data,
        });
    };

    const listarMedicosExternos = async () => {
        
        const res = await clienteAxios.get("/medicos/externos", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: LISTAR_MEDICOS_INTERNOS,
            payload: res.data,
        });
    };

    const listarMedicosAgenda = async () => {
        const res = await clienteAxios.get("/medicos/agenda", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: LISTAR_MEDICOS_AGENDA,
            payload: res.data,
        });
    };

    const setMedicoWithDetalles = async (idCatMedico) => {
        const res = await clienteAxios.get(`/medicos/detalles?id=${idCatMedico}`, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: SET_MEDICO,
            payload: res.data,
        });

        return res.data;
    };

    const getMedicoById = async (idCatMedico) => {
        const res = await clienteAxios.get(`/medicos/detalles?id=${idCatMedico}`, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });
        return res.data;
    };

    return (
        <MedicosContext.Provider
            value={{
                medicos: state.medicos,
                medicosInternos: state.medicosInternos,
                medico: state.medico,
                medicosAgenda: state.medicosAgenda,
                medicosActivos: state.medicosActivos,
                filtrosMedicos: state.filtrosMedicos,
                idCatMedicoSeleccionado: state.idCatMedicoSeleccionado,
                listarMedicos,
                setFiltrosMedicos,
                uploadNuevoMedico,
                eliminarMedico,
                updateMedico,
                setDataMedico,
                listarMedicosActivos,
                setIdCatMedicoSeleccionado,
                listarMedicosInternos,
                listarMedicosExternos,
                listarMedicosAgenda,
                setMedicoWithDetalles,
                getMedicoById
            }}>
            {props.children}
        </MedicosContext.Provider>
    );
}
