import React from "react";

import { CargaArchivoModal } from "../CargaArchivoModal";

const MedicamentoFalsoModal = (props) => {
  //ACCESO AL ESTADO GLOBAL - AUTENCIACION

  // const autorizado = usuario.authorities.includes("OP_CARGAR_ARCHIVO_MEDICAMENTO_FALSO");

  const infoSweetAlert = { title: "Medicamento Falso", confirmBtnText: "Guardar", cancelBtnText: "Cancelar" };

  return (
    <>
      <CargaArchivoModal
        datosCargaArchivo={props.datosCargaArchivo}
        setDatosCargaArchivo={props.setDatosCargaArchivo}
        infoSweetAlert={infoSweetAlert}
        onFileUpload={props.onFileUpload}
        onCancel={props.onCancel}
        onFileChange={(e) => props.onFileChange(e)}
        onChange={(e) => props.onChange(e)}
        exportFormato={props.exportFormato}
        conComentario={true}
        // documentoPrevio={props.documentoPrevio}
        filesAccepted="image/*,.pdf"
        aviso={{
          target :"_blank",
          info: "Recuerda que debes realizar el reporte en la página de COFEPRIS",
          linkInfo: " https://www.gob.mx/cofepris", 
        }}
      />
    </>
  );
};

export default MedicamentoFalsoModal;
