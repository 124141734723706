import React, { useReducer } from "react";

import FacturaContext from "./FacturaContext";
import FacturaReducer from "./FacturaReducer";

import { LISTAR_FACTURAS, FILTROS_FACTURAS, LISTAR_MOTIVOS_CANCELACION } from "../../types";

import clienteAxios from "../../config/axios";

export default function FacturaState(props) {
  const initialState = {
    documentosContables: [],
    filtrosDocumentosContables: {},
    motivosCancelacion: [],
  };

  const [state, dispatch] = useReducer(FacturaReducer, initialState);

  const obtenerDocumentosContables = async ({ page, limit }) => {
    const res = await clienteAxios.post(
      `/factura/filtros`,
      { ...state.filtrosDocumentosContables, pageSize: limit, pageIndex: page },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    console.log(res)

    dispatch({
      type: LISTAR_FACTURAS,
      payload: res.data.facturas,
    });

    return res;
  };

  const setFiltrosDocumentosContables = async (filtrosDocumentosContables) => {
    dispatch({
      type: FILTROS_FACTURAS,
      payload: filtrosDocumentosContables,
    });
  };

  const listarMotivosCancelacion = async () => {
    const res = await clienteAxios.get(`/motivocancelacion/activos`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_MOTIVOS_CANCELACION,
      payload: res.data,
    });
  };

  const uploadCancelarFactura = async (factura) => {
    try {
      const res = await clienteAxios.post("/factura/cancelar", factura, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const timbrarFactura = async (factura) => {
    try {
      const res = await clienteAxios.post(`/factura/timbrar/factura`, factura, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <FacturaContext.Provider
      value={{
        filtrosDocumentosContables: state.filtrosDocumentosContables,
        documentosContables: state.documentosContables,
        motivosCancelacion: state.motivosCancelacion,
        obtenerDocumentosContables,
        setFiltrosDocumentosContables,
        listarMotivosCancelacion,
        uploadCancelarFactura,
        timbrarFactura,
      }}>
      {props.children}
    </FacturaContext.Provider>
  );
}
