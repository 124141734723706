import React, { useContext, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col, Card, CardBody } from "reactstrap";

import TabMarcadores from "./TabMarcadores";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import SexosContext from "../../context/Sexos/SexosContext";
import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";

import DrawerExpedientePaciente from "../DrawerExpedientePaciente/index";

export default function EstudiosLaboratorio(props) {
  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado } = pacientesContext;

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  useEffect(() => {
    const fetchAll = () => {
      listarSexos();
      listarAseguradoras();
      listarEscolaridades();
      listarEstadosCiviles();
      listarLugares();
      listarOcupaciones();
      listarMedicos();
    };
    fetchAll();
  }, []);

  const redirectHistoriaClinicaInicial = async () => {
    props.history.push("/historia/clinica/inicial");
  };

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }, []);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Marcadores del Paciente</h3>
      <DrawerExpedientePaciente
        historiaClinicaInicial={historiaClinicaInicial}
        sexos={sexos}
        aseguradoras={aseguradoras}
        medicos={medicos}
        lugares={lugares}
        ocupaciones={ocupaciones}
        estadosCiviles={estadosCiviles}
        escolaridades={escolaridades}
      />
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col>
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                type="button"
                onClick={redirectHistoriaClinicaInicial}>
                Historia Clínica Inicial
              </button>
            </Col>
          </Row>
          <Row>
            <TabMarcadores props={props} />
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
