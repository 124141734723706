import {
    LISTAR_CATTIPOMEDICO,
} from '../../types'

export default (state, action) => {
    switch (action.type){
        case LISTAR_CATTIPOMEDICO:
            return{
                ...state, 
                tiposMedico: action.payload
            }
        default:
            return state
    }
    
}