import React, { useState, useContext, useEffect } from "react";

import useWindowDimensions from "../../hooks/dimensionsHook";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import VentaMostradorContext from "../../context/VentaMostrador/VentaMostradorContext";
import AuthContext from "../../context/Auth/AuthContext";
import CatTipoVentaMostradorContext from "../../context/CatTipoVentaMostrador/CatTipoVentaMostradorContext";
import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";
import ConfiguracionContext from "../../context/Configuracion/ConfiguracionContext";
import InventarioContext from "../../context/Inventario/InventarioContext";
import CatTipoCobroContext from "../../context/CatTipoCobro/CatTipoCobroContext";
import ClientesContext from "../../context/Clientes/ClientesContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";

// import debounce from "lodash/debounce";

import formatNumber from "../../utils";

import { maxCampoPiezas } from "../../config/variables";

export default function NuevoVentaMostrador(props) {
  const { width } = useWindowDimensions();
  // eslint-disable-next-line no-unused-vars
  const [fechaDefault, setFechaDefault] = useState(new Date().toISOString().slice(0, 10));
  const [productosServiciosFilter, setProductosServiciosFilter] = useState([]);
  const [ivaConfiguracion, setIvaConfiguracion] = useState(0);
  const [clienteStringGlobal, setClienteStringGlobal] = useState(false);
  const [clienteIdGlobal, setClienteIdGlobal] = useState(0);
  const [ventaMostradorNuevo, setVentaMostradorNuevo] = useState({
    ventaMostradorProducto: [],
  });
  const [detallesPrecio, setDetallesPrecio] = useState({
    total: 0,
    subtotal: 0,
    iva: 0,
    porcentajeIva: 0,
    porcentajeImporte: 0,
  });
  const [loading, setLoading] = useState(false);
  const [indexProductoBorrado, setIndexProductoBorrado] = useState(null);
  const [indexLoteBorrado, setIndexLoteBorrado] = useState(null);
  const [cobroEliminar, setCobroEliminar] = useState(null);

  const [cotizacion, setCotizacion] = useState(false);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_delete_producto: false,
    confirm_alert_delete_lote: false,
    confirm_nueva_venta_completa: false,
    confirm_nueva_venta_apartado: false,
    confirm_nueva_cotizacion: false,
    error_dlg: false,
    redirect: false,
    success_dlg: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxVentaMostrador = useContext(VentaMostradorContext);
  const { crearNuevaVentaMostrador, limpiarVentaMostradorSeleccionada } = ctxVentaMostrador;

  const ctxClientes = useContext(ClientesContext);
  const { clientes, listarClientes } = ctxClientes;

  //ACCESO AL ESTADO GLOBAL - Pacientes
  const ctxTipoVentaMostrador = useContext(CatTipoVentaMostradorContext);
  const { listarTiposVentaMostrador } = ctxTipoVentaMostrador;

  //ACCESO AL ESTADO GLOBAL - ProductosServicios
  const ctxProductosServicios = useContext(ProductosServiciosContext);
  const { productosServicios, listarProductosPersonal,listarProductosServiciosPersonal } = ctxProductosServicios;

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  //ACCESO AL ESTADO GLOBAL - Inventario
  const ctxInventario = useContext(InventarioContext);
  const { obtenerInvetariosProducto } = ctxInventario;

  // ACCESO A ESTADO GLOBAL - Configuracion
  const ctxConfiguracion = useContext(ConfiguracionContext);
  const { listarConfiguracion, configuracion } = ctxConfiguracion;

  const ctxTipoCobro = useContext(CatTipoCobroContext);
  const { tipoCobros, listarCatTipoCobros } = ctxTipoCobro;

  const pacientesContext = useContext(PacientesContext);
  const {pacientes, listarPacientesActivos} = pacientesContext;

  // MANEJO DE CRUD LOCAL
  const onChange = (e) => {
    const { name, value, checked } = e.target;

    if(e.target.name=="porNombre"){

        if(checked== true){
          ventaMostradorNuevo.idCatCliente = null;
        }else{
          ventaMostradorNuevo.cliente = null;
          setVentaMostradorNuevo({
            cliente: null,
          })
        }

        setVentaMostradorNuevo({
          ...ventaMostradorNuevo,
          [name]: checked,
        });

    }else{
      setVentaMostradorNuevo({
        ...ventaMostradorNuevo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const nuevoRenglon = () => {
    const nuevoInsumo = {
      idCatProducto: null,
      piezas: 0,
      precio: null,
      iva: 0,
      catTipoProducto: null,
      descripcion: "",
      inventarios: [],
      inventarioProductoServicio: [],
      tipoProducto: "",
      idCatTipoVentaMostrador:0,
    };
    setVentaMostradorNuevo({
      ...ventaMostradorNuevo,
      ventaMostradorProducto: ventaMostradorNuevo.ventaMostradorProducto.concat(nuevoInsumo),
    });
  };

  const changeProducto = async (e, index) => {
    let value = e.target.value;
    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let insumo = productosVentaMostrador[index];
    insumo.descripcion = value;
    insumo.completed = false;

    if (insumo.inventarioProductoServicio.length > 0) {
      insumo.inventarioProductoServicio = [];
    }

    //Hacemos un filtrado de los insumos, en base a 5 campos, el nombreComercial del producto, el principioActivo del producto, el numIdentificacion del producto
    //el codigoEAN del producto y por ultimo el nombre del servicio. Todo lo hacemos lowerCase para la comparacion.
    const productosServiciosFiltrados = await productosServicios.filter((prodServ) => {
      if(prodServ.servicio==null){

        if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0)) {
          return true;
        } else if (prodServ.principioActivo != null && prodServ.principioActivo.toLowerCase().startsWith(value.toLowerCase(), 0)) {
          return true;
        } else if (prodServ.numIdentificacion != null && prodServ.numIdentificacion.startsWith(value, 0)) {
          return true;
        } else if (prodServ.codigoEAN != null && prodServ.codigoEAN.startsWith(value, 0)) {
          return true;
        }
      }else{
        if (prodServ.servicio.toLowerCase().startsWith(value.toLowerCase(), 0)){
          return true;
        }
      }
    });

    //Una vez obtenido el arreglo de los insumos filtrados, los guardamos el State
    setProductosServiciosFilter(productosServiciosFiltrados);

    //Si el input, contiene un "-", significa, que ya escogimos un producto/servicio. Hay que obtener ese producto/servicio seleccionado de nuestra lista
    //pero los producto se puede dar el caso de tener el mismo nombreComercial, entonces se separa por el "-", y lo que esta despues del "-", es el numIdentificacion
    //que es unico para los productos
    if (value.includes("-") || productosServiciosFiltrados.length == 1 ) {
      var lastIndex = value.lastIndexOf("-");
      const productoServicioSeleccionado = await productosServicios.filter((prodServ) => {
        if(prodServ.idCatTipoproducto==3){
          if ( prodServ.servicio.toLowerCase() === value.toLowerCase()) {
            return true;
          }
        }else{
          if (
            prodServ.numIdentificacion != null &&
            prodServ.numIdentificacion === value.slice(lastIndex + 1, value.length).replace(" ", "")
          ) {
            return true;
          }          
        }
      });

      if (productoServicioSeleccionado.length == 1) {
        let producto = productoServicioSeleccionado[0];

        const productoRepetido = await checarProductoRepetido(producto, index);
        if (productoRepetido) {
          insumo.idCatProducto = null;
          insumo.precio = null;
          insumo.precioMinimo = null;
          insumo.iva = 0;
          insumo.principioActivo = "";
          insumo.catTipoProducto = null;
          insumo.inventarios = [];
          insumo.descripcion = "";

          setVentaMostradorNuevo({
            ...ventaMostradorNuevo,
            ventaMostradorProducto: productosVentaMostrador,
          });

          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Producto repetido",
          });

          setPrecioTotal();

          return false;
        }

        if(producto.idCatProducto){
          const res = await obtenerInvetariosProducto(producto.idCatProducto);
          insumo.inventarios = res.data;
        }
        //Cuando se pide el filtrado de un arreglo siempre se debe de referenciar con el [0]

        //Una vez obtenido el producto/servicio seleccionado, se debe de asignar el insumo, el idCatProducto y principioActivo en caso de que sea un producto
        //y en el caso de que sea un servicio, solo se le asignado el idCatServicio
        if (producto.idCatProducto != null) {
          insumo.idCatProducto = producto.idCatProducto;
          insumo.principioActivo = producto.principioActivo;
        } else if (producto.idCatServicio != null) {
          insumo.idCatServicio = producto.idCatServicio;
        }

        if( insumo.piezas == 0){
          insumo.piezas = 1;
        }

        //Ademos debemos revisar el tipoVale, para saber si usaremos los precios de la aseguradora o de ventamostrador
        insumo.precio = producto.precioProductoServicio[0].precioMostrador;
        insumo.precioMinimo = producto.precioProductoServicio[0].precioEntrada;

        if (producto.causaIva) {
          insumo.iva = insumo.precio * ivaConfiguracion;
        }

        if(producto.idCatTipoproducto == 3){
          insumo.tipoProducto = "SERVICIO" 
          insumo.completed = true;
        }else if(producto.idCatTipoproducto == 1){ 
          insumo.tipoProducto = "INSUMO" 
        }else if(producto.idCatTipoproducto == 2){ 
          insumo.tipoProducto = "MEDICAMENTO" 
        }else if(producto.idCatTipoproducto == 4){ 
          insumo.tipoProducto = "SOLUCION"                     
        }

        //Despues de hacer todas las modificaciones, hacemos un setData con todos los cambios
        setVentaMostradorNuevo({
          ...ventaMostradorNuevo,
          ventaMostradorProducto: productosVentaMostrador,
        });
        setPrecioTotal();
      } 
      else 
      {
        if(productoServicioSeleccionado.length > 1)
        {
          const productoTemp = await productosServicios.filter((prodServ) => {
            if (
              prodServ.numIdentificacion != null &&
              prodServ.nombreComercial.toLowerCase() + " - " + prodServ.numIdentificacion.toLowerCase() === value.toLowerCase()
            ) {
              return true;
            }
          });
          let producto = productoTemp[0];
          const productoRepetido = await checarProductoRepetido(producto, index);
  
          if (productoRepetido) {
            insumo.idCatProducto = null;
            insumo.precio = null;
            insumo.precioMinimo = null;
            insumo.iva = 0;
            insumo.principioActivo = "";
            insumo.catTipoProducto = null;
            insumo.inventarios = [];
            insumo.descripcion = "";

            setVentaMostradorNuevo({
              ...ventaMostradorNuevo,
              ventaMostradorProducto: productosVentaMostrador,
            });
  
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              dynamic_title: "Fallo",
              dynamic_description: "Producto repetido",
            });
  
            setPrecioTotal();
  
            return false;
          }
  
          const res = await obtenerInvetariosProducto(producto.idCatProducto);
          insumo.inventarios = res.data;
  
          //Cuando se pide el filtrado de un arreglo siempre se debe de referenciar con el [0]
  
          //Una vez obtenido el producto/servicio seleccionado, se debe de asignar el insumo, el idCatProducto y principioActivo en caso de que sea un producto
          //y en el caso de que sea un servicio, solo se le asignado el idCatServicio
          if (producto.idCatProducto != null) {
            insumo.idCatProducto = producto.idCatProducto;
            insumo.principioActivo = producto.principioActivo;
          } else if (producto.idCatServicio != null) {
            insumo.idCatServicio = producto.idCatServicio;
          }

          if( insumo.piezas == 0){
            insumo.piezas = 1;
          }
          //Ademos debemos revisar el tipoVale, para saber si usaremos los precios de la aseguradora o de ventamostrador
          insumo.precio = producto.precioProductoServicio[0].precioMostrador;
          insumo.precioMinimo = producto.precioProductoServicio[0].precioEntrada;

          if (producto.causaIva) {
            insumo.iva = insumo.precio * ivaConfiguracion;
          }
  
          //Despues de hacer todas las modificaciones, hacemos un setData con todos los cambios
          setVentaMostradorNuevo({
            ...ventaMostradorNuevo,
            ventaMostradorProducto: productosVentaMostrador,
          });
          setPrecioTotal();
        }else
        {
            //Mientras no se encuentre un solo producto/servicio, el insumo se mentiene limpio
            insumo.idCatProducto = null;
            insumo.precio = null;
            insumo.precioMinimo = null;
            insumo.iva = 0;
            insumo.principioActivo = "";
            insumo.catTipoProducto = null;
            insumo.inventarios = [];

            setVentaMostradorNuevo({
              ...ventaMostradorNuevo,
              ventaMostradorProducto: productosVentaMostrador,
            });
            setPrecioTotal();
        }
      }
    } else {
      //Mientras no nos mande, el "-", se debe de limpiar el insumo
      insumo.idCatProducto = null;
      insumo.precio = null;
      insumo.precioMinimo = null;
      insumo.iva = 0;
      insumo.principioActivo = "";
      insumo.catTipoProducto = null;
      insumo.inventarios = [];

      setVentaMostradorNuevo({
        ...ventaMostradorNuevo,
        ventaMostradorProducto: productosVentaMostrador,
      });
      
      setPrecioTotal();
    }
  };

  const changePiezasProducto = async (e, index) => {
    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let insumo = productosVentaMostrador[index];
    let value = e.target.value;
    if (value < 0) {
      value = value * -1;
    }
    if (value?.length <= maxCampoPiezas) {
      insumo.piezas = parseInt(value, 10);

      setVentaMostradorNuevo({
        ...ventaMostradorNuevo,
        ventaMostradorProducto: productosVentaMostrador,
      });
      setPrecioTotal();
    }
  };

  const changePrecioProducto = async (e, index) => {
    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let insumo = productosVentaMostrador[index];
    let value = e.target.value;

    if (value < 0) {
      value = value * -1;
    }

    insumo.precio = value;
    
    const producto = await productosServicios.filter((prodServ) => {
      if (
           insumo.idCatProducto === prodServ.idCatProducto
      ) {
        return true;
      }
    });

    if (producto[0].causaIva) {
      insumo.iva = insumo.precio * ivaConfiguracion;
    }

    setVentaMostradorNuevo({
      ...ventaMostradorNuevo,
      ventaMostradorProducto: productosVentaMostrador,
    });
    setPrecioTotal();
  };

  const changePrecio = async (value, index) => {
    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let insumo = productosVentaMostrador[index];

    if (value < 0) {
      value = value * -1;
    }

    insumo.precio = value;

    setVentaMostradorNuevo({
      ...ventaMostradorNuevo,
      ventaMostradorProducto: productosVentaMostrador,
    });
    setPrecioTotal();
  };

  const checkPrecioMin = (e, index, precio, precioMinimo, descripcion) => {
    if (Number(precio) < precioMinimo) {
      changePrecio(precioMinimo, index);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "El precio unitario no puede ser menor al precio de compra",
        dynamic_description: "El precio de compra es de: " + formatNumber(precioMinimo) + " para el producto " + descripcion,
      });
    }
  };

  const toggleExpanded = async (index) => {
    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let producto = productosVentaMostrador[index];
    producto.expanded = !producto.expanded;

    setVentaMostradorNuevo({
      ...ventaMostradorNuevo,
      ventaMostradorProducto: productosVentaMostrador,
    });
  };

  const agregarLote = (index) => {
    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let producto = productosVentaMostrador[index];

    let nuevoLote = {
      idInventarioProductoServicio: null,
      idInventario: null,
      lote: "",
      caducidad: "",
      completed: false,
      piezasDisponibles: 0,
      piezasAsignadas: 0,
      piezasRecibidas: 0,
      piezasUtilizadas: 0,
    };

    // console.log(insumo);

    producto.inventarioProductoServicio = producto.inventarioProductoServicio.concat(nuevoLote);

    setVentaMostradorNuevo({
      ...ventaMostradorNuevo,
      ventaMostradorProducto: productosVentaMostrador,
    });
  };

  const eliminarProducto = async () => {
    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let producto = productosVentaMostrador[indexProductoBorrado];
    ventaMostradorNuevo.ventaMostradorProducto = ventaMostradorNuevo.ventaMostradorProducto.filter((item) => item != producto);

    setVentaMostradorNuevo(ventaMostradorNuevo);

    setPrecioTotal();

    setIndexProductoBorrado(null);

    setUISweetAlert({
      ...UISweetAlert,
      success_dlg: true,
      dynamic_title: "Borrado",
      confirm_alert_delete_producto: false,
      dynamic_description: "El producto ha sido borrado correctamente.",
    });
  };

  const changeLoteProducto = async (e, index, indexLote) => {
    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let producto = productosVentaMostrador[index];
    let lote = producto.inventarioProductoServicio[indexLote];
    let inventario = producto.inventarios.filter((inv) => inv.idInventario == e.target.value)[0];
    let loteRepetido = false;

    producto.inventarioProductoServicio.map((element) => {
      if (!element.idInventarioProductoServicio && element.idInventario == e.target.value) {
        loteRepetido = true;
      } else if (element.idInventarioProductoServicio && element.inventario.idInventario == e.target.value) {
        loteRepetido = true;
      }
    });

    //Checamos si hay existe otro caso con el mismo lote, para no permitir tener 2 del mismo lote
    if (loteRepetido) {
      lote.caducidad = "";
      lote.lote = "";
      lote.idInventario = "";

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Lote repetido",
      });
    } else {
      lote.caducidad = inventario.caducidad;
      lote.lote = inventario.lote;
      lote.idInventario = inventario.idInventario;
      lote.piezasDisponibles = inventario.piezas;
    }

    lote.piezasAsignadas = 0;
    lote.piezasRecibidas = 0;
    lote.piezasUtilizadas = 0;

    setVentaMostradorNuevo({
      ...ventaMostradorNuevo,
      ventaMostradorProducto: productosVentaMostrador,
    });
  };

  const checarProductoCompleto = async () => {
    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let producto = productosVentaMostrador[indexProductoBorrado];

    //Checamos de todos los lotes, si ya tenemos piezasAsignadas, para sumarlos, y que no podamos escoger mas piezas que nos indica el insumo
    let sumaPiezasAsignadas = 0;
    producto.inventarioProductoServicio.map((element) => {
      if (element.piezasAsignadas > 0) {
        sumaPiezasAsignadas += parseInt(element.piezasAsignadas, 10);
      }
    });

    if (producto.piezas == sumaPiezasAsignadas) {
      producto.completed = true;
    } else {
      producto.completed = false;
    }

    setVentaMostradorNuevo(ventaMostradorNuevo);
  };

  const changePienzasAsginadas = async (e, index, indexLote) => {
    let value = e.target.value;
    if (value < 0) {
      value = value * -1;
    }

    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let producto = productosVentaMostrador[index];
    let lote = producto.inventarioProductoServicio[indexLote];
    let inventario = producto.inventarios.filter((inv) => inv.idInventario == lote.idInventario)[0];

    //Checamos de todos los lotes, si ya tenemos piezasAsignadas, para sumarlos, y que no podamos escoger mas piezas que nos indica el insumo
    let sumaPiezasAsignadas = 0;
    producto.inventarioProductoServicio.map((element, i) => {
      if (element.piezasAsignadas > 0 && i != indexLote) {
        sumaPiezasAsignadas += parseInt(element.piezasAsignadas, 10);
      }
    });

    //Si ya tenemos piezas asignadas, y le hacemos un cambio, tenemos que regresar lo que teniamos en piezasAsignadas el inventario
    //para mas adelante hacer el analisis de si existen piezas disponibles
    if (lote.piezasAsignadas > 0) {
      inventario.piezas = parseInt(inventario.piezas, 10) + parseInt(lote.piezasAsignadas, 10);
      lote.piezasAsignadas = value;
    } else {
      lote.piezasAsignadas = value;
    }

    //Si tenemos ese numero de piezas o mas en el inventario, se guarda, pero si no, guardamos un 0 y enseñanmos un mensaje para mostar las piezas disponibles
    //Tambien debemos checar que las piezas asignadas, no sean mayores a la piezas que se indican en el valeproductoservicio
    if (inventario.piezas >= lote.piezasAsignadas && producto.piezas - sumaPiezasAsignadas >= lote.piezasAsignadas) {
      inventario.piezas = inventario.piezas - lote.piezasAsignadas;

      if (producto.piezas == sumaPiezasAsignadas + parseInt(lote.piezasAsignadas, 10)) {
        producto.completed = true;
      } else {
        producto.completed = false;
      }
    } else if (inventario.piezas < lote.piezasAsignadas) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description:
          "No hay suficientes piezas, el numero de piezas disponibles para '" +
          producto.descripcion +
          "' es de : " +
          inventario.piezas,
      });
      lote.piezasAsignadas = 0;
      producto.completed = false;
    } else if (producto.piezas - sumaPiezasAsignadas < lote.piezasAsignadas) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "El número de piezas asignadas no puede ser mayor al número de piezas indicadas por el producto",
      });
      lote.piezasAsignadas = 0;
      producto.completed = false;
    }

    setVentaMostradorNuevo({
      ...ventaMostradorNuevo,
      ventaMostradorProducto: productosVentaMostrador,
    });
  };

  const checarProductoRepetido = async (producto, index) => {
    let productoRepetido = false;

    ventaMostradorNuevo.ventaMostradorProducto.map((element, i) => {
      if ((index != i && element.idCatProducto == producto.idCatProducto && !productoRepetido && !producto.idCatServicio) ||
      (producto.idCatServicio && producto.idCatServicio==element.idCatServicio)) {
        productoRepetido = true;
      }
    });
    return productoRepetido;
  };

  const setPrecioTotal = async () => {
    let subtotal = 0;
    let iva = 0;
    let total = 0;

    setDetallesPrecio({
      subtotal: 0,
      iva: 0,
      total: 0,
      porcentajeIva: 0,
      porcentajeImporte: 0,
    });

    if (ventaMostradorNuevo.ventaMostradorProducto.length > 0) {
      ventaMostradorNuevo.ventaMostradorProducto.map((producto) => {
        subtotal += producto.precio * producto.piezas;
        if (iva != null) {
          iva += producto.iva * producto.piezas;
        }
      });
    }

    total = subtotal + iva;

    setDetallesPrecio({
      subtotal: (Math.round(subtotal * 100) / 100).toFixed(2),
      iva: (Math.round(iva * 100) / 100).toFixed(2),
      total: (Math.round(total * 100) / 100).toFixed(2),
      porcentajeImporte: (subtotal / total).toFixed(2),
      porcentajeIva: (iva / total).toFixed(2),
    });
  };

  const confirmNuevaVentaMostrador = async (e) => {
    e.preventDefault();
    if (!ventaMostradorNuevo.idCatCliente && (!ventaMostradorNuevo.cliente || ventaMostradorNuevo.cliente == "")) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, seleccione un cliente de la lista de clientes o escriba el nombre del cliente.`,
      });
      return;
    }

    if (ventaMostradorNuevo.ventaMostradorProducto.length < 1) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca productos a la venta`,
      });
      return;
    }

    const errorProductosYLotes = await validateProductosLotes();

    if (errorProductosYLotes) {
      return;
    }

    let productosCompletos = true;

    ventaMostradorNuevo.ventaMostradorProducto.map((producto) => {
      if (productosCompletos && !producto.completed) {
        productosCompletos = false;
      }
    });

    ventaMostradorNuevo.importeTotal = detallesPrecio.subtotal;
    ventaMostradorNuevo.ivaTotal = detallesPrecio.iva;

    // console.log(productosCompletos);
    if (productosCompletos) {
      //Si todos los productos estan completos, la venta es de tipo completa
      ventaMostradorNuevo.idCatTipoVentaMostrador = 2;
      ventaMostradorNuevo.ventaMostradorProducto.map((producto) => {
        producto.inventarioProductoServicio.map((inv) => {
          inv.piezasRecibidas = inv.piezasAsignadas;
          inv.piezasUtilizadas = inv.piezasAsignadas;
        });
      });
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nueva_venta_completa: true,
      });
    } else if (!productosCompletos) {
      //La venta de tipo apartado
      ventaMostradorNuevo.idCatTipoVentaMostrador = 1;
      ventaMostradorNuevo.anticipo = (detallesPrecio.total / 2).toFixed(2);
      ventaMostradorNuevo.restantePagarAnticipo = (detallesPrecio.total / 2).toFixed(2);
      ventaMostradorNuevo.importeTotal = detallesPrecio.total;

      setUISweetAlert({
        ...UISweetAlert,
        confirm_nueva_venta_apartado: true,
      });
      
    }
  };

  const confirmNuevaCotizacionMostrador = async (e) => {
    e.preventDefault();
    if (!ventaMostradorNuevo.idCatCliente && (!ventaMostradorNuevo.cliente || ventaMostradorNuevo.cliente == "")) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, seleccione un cliente de la lista de clientes o escriba el nombre del cliente.`,
      });
      return;
    }

    if (ventaMostradorNuevo.ventaMostradorProducto.length < 1) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca productos a la venta`,
      });
      return;
    }

    const errorProductosYLotes = await validateProductosLotes();

    if (errorProductosYLotes) {
      return;
    }

    let productosCompletos = true;

    ventaMostradorNuevo.ventaMostradorProducto.map((producto) => {
      if (productosCompletos && !producto.completed) {
        productosCompletos = false;
      }
    });

    ventaMostradorNuevo.importeTotal = detallesPrecio.subtotal;
    ventaMostradorNuevo.ivaTotal = detallesPrecio.iva;

    // console.log(productosCompletos);
    if (productosCompletos) {
      //Si todos los productos estan completos, la venta es de tipo completa
      ventaMostradorNuevo.idCatTipoVentaMostrador = 2;
      ventaMostradorNuevo.ventaMostradorProducto.map((producto) => {
        producto.inventarioProductoServicio.map((inv) => {
          inv.piezasRecibidas = inv.piezasAsignadas;
          inv.piezasUtilizadas = inv.piezasAsignadas;
        });
      });
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nueva_venta_completa: true,
      });
    } else if (!productosCompletos) {
      //La venta de tipo apartado
      ventaMostradorNuevo.idCatTipoVentaMostrador = 1;
      ventaMostradorNuevo.anticipo = (detallesPrecio.total / 2).toFixed(2);
      ventaMostradorNuevo.restantePagarAnticipo = (detallesPrecio.total / 2).toFixed(2);
      ventaMostradorNuevo.importeTotal = detallesPrecio.total;

      setUISweetAlert({
        ...UISweetAlert,
        confirm_nueva_cotizacion : true,
      });     
    }
  };

  const validateProductosLotes = async () => {
    let error = false;

    ventaMostradorNuevo.ventaMostradorProducto.map((producto, index) => {
      if (!error && !producto.idCatProducto && !producto.idCatServicio) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nueva_venta_apartado: false,
          confirm_nueva_venta_completa: false,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, introduzca el producto",
        });
        error = true;
      }
      if (!error && producto.piezas <= 0) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nueva_venta_apartado: false,
          confirm_nueva_venta_completa: false,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, la cantidad de pieza debe ser mayor a 0",
        });
        error = true;
      }

      if (!error && producto.inventarioProductoServicio.length > 0) {
        producto.inventarioProductoServicio.map((inv, indexInv) => {
          if (!error && !inv.idInventario) {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              confirm_nueva_venta_apartado: false,
              confirm_nueva_venta_completa: false,
              dynamic_title: "Fallo",
              dynamic_description: "Por favor, introduzca el inventario al lote",
            });
            error = true;
          }

          if (!error && inv.piezasAsignadas <= 0) {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              confirm_nueva_venta_apartado: false,
              confirm_nueva_venta_completa: false,
              dynamic_title: "Fallo",
              dynamic_description: "Por favor, la cantidad de pieza asignadas debe ser mayor a 0",
            });
            error = true;
          }
        });
      }
    });

    return error;
  };

  const eliminarLote = async () => {
    // console.log("El index : " +index +", y el indexLote : " +indexLote);

    let productosVentaMostrador = ventaMostradorNuevo.ventaMostradorProducto;
    let producto = productosVentaMostrador[indexProductoBorrado];
    let loteEliminado = producto.inventarioProductoServicio[indexLoteBorrado];
    let inventario = producto.inventarios.filter((inv) => inv.idInventario == loteEliminado.idInventario)[0];

    //Se le regresan al inventario las piezas tomadas, (esto es de manera local)
    if (loteEliminado.piezasAsignadas > 0) {
      inventario.piezas = parseInt(inventario.piezas, 10) + parseInt(loteEliminado.piezasAsignadas, 10);
    }

    producto.completed = false;
    producto.inventarioProductoServicio = producto.inventarioProductoServicio.filter(
      (lotesProducto) => lotesProducto != loteEliminado
    );

    setVentaMostradorNuevo({
      ...ventaMostradorNuevo,
      ventaMostradorProducto: productosVentaMostrador,
    });
    const productoCompleto = await checarProductoCompleto();

    setIndexProductoBorrado(null);
    setIndexLoteBorrado(null);

    setUISweetAlert({
      ...UISweetAlert,
      success_dlg: true,
      dynamic_title: "Borrado",
      confirm_alert_delete_lote: false,
      dynamic_description: "El lote ha sido borrado correctamente.",
    });
    return productoCompleto;
  };

  const submitNuevaCotizacionMostrador = async () => {
    setLoading(true);

    ventaMostradorNuevo.importeTotal = detallesPrecio.subtotal;
    ventaMostradorNuevo.ivaTotal = detallesPrecio.iva;

    const res = await crearNuevaVentaMostrador({
      ...ventaMostradorNuevo,
      idCatTipoVentaMostrador:3,
      importeTotal: ventaMostradorNuevo.idCatTipoVentaMostrador == 1 ? detallesPrecio.total : ventaMostradorNuevo.importeTotal,
      porcentajeImporte: detallesPrecio.porcentajeImporte,
      porcentajeIva: detallesPrecio.porcentajeIva,
    });

    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_nueva_cotizacion: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      // console.log(res);

      setLoading(false);
      let path = "";

      path = "/ventasmostrador";


      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_nueva_cotizacion: false,
        redirect: true,
        path: path,
        dynamic_description: "Se generó una nueva venta mostrador",
      });
    }
  };

  const submitNuevaVentaMostrador = async () => {
    if (ventaMostradorNuevo.idCatTipoVentaMostrador == 1) {
      let cobrosCompletos = true;

      if (ventaMostradorNuevo.cobrosAnticipo && ventaMostradorNuevo.cobrosAnticipo.length > 0) {
        ventaMostradorNuevo.cobrosAnticipo.map((cobro) => {
          if (!cobro.idCatTipoCobro && cobrosCompletos) {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              dynamic_title: "Fallo",
              dynamic_description: `Por favor, introduzca la forma de pago para el cobro`,
            });
            cobrosCompletos = false;
          }

          if (!cobro.cantidadPagar && cobrosCompletos) {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              dynamic_title: "Fallo",
              dynamic_description: `Por favor, introduzca la cantidad a pagar para el cobro`,
            });
            cobrosCompletos = false;
          }
        });
      } else {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: `Por favor, introduzca la menos un cobro`,
        });
        cobrosCompletos = false;
      }

      if (!cobrosCompletos) {
        return;
      }
    }

    if (ventaMostradorNuevo.idCatTipoVentaMostrador == 1 && ventaMostradorNuevo.restantePagarAnticipo > 0) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `La cantidad minima del anticipo es de  $${ventaMostradorNuevo.anticipo}, 
                el restante por pagar es $${ventaMostradorNuevo.restantePagarAnticipo}`,
      });
      return;
    }

    setLoading(true);

    const res = await crearNuevaVentaMostrador({
      ...ventaMostradorNuevo,
      importeTotal: ventaMostradorNuevo.idCatTipoVentaMostrador == 1 ? detallesPrecio.total : ventaMostradorNuevo.importeTotal,
      porcentajeImporte: detallesPrecio.porcentajeImporte,
      porcentajeIva: detallesPrecio.porcentajeIva,
    });

    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_nueva_venta_apartado: false,
        confirm_nueva_venta_completa: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      // console.log(res);

      setLoading(false);
      let path = "";

      //Si es una venta de tipo apartado, lo redirigimos a la vista del listado de ventas mostrador
      if (ventaMostradorNuevo.idCatTipoVentaMostrador == 1) {
        path = "/ventasmostrador";
      }

      //Si estamos haciendo una venta con productos completos, inmediatamente, los redireccionamos
      // a la pantalla de pago
      if (ventaMostradorNuevo.idCatTipoVentaMostrador == 2) {
        path = `/ventasmostrador/cobro/${res.data.idVentaMostrador}`;
      }

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_nueva_venta_apartado: false,
        confirm_nueva_venta_completa: false,
        redirect: true,
        path: path,
        dynamic_description: "Se generó una nueva venta mostrador",
      });
    }
  };

  const insertarNuevoCobro = async () => {
    if (!ventaMostradorNuevo.cobrosAnticipo) {
      ventaMostradorNuevo.cobrosAnticipo = [];
    }

    let nuevoCobro = {
      idCatTipoCobro: "",
      cantidadPagar: "",
      importe: "",
      iva: "",
    };

    ventaMostradorNuevo.cobrosAnticipo = ventaMostradorNuevo.cobrosAnticipo.concat(nuevoCobro);
    setVentaMostradorNuevo(ventaMostradorNuevo);
    refreshModalCobroAnticipo();
  };

  const refreshModalCobroAnticipo = async () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_nueva_venta_apartado: false,
    });
    setUISweetAlert({
      ...UISweetAlert,
      confirm_nueva_venta_apartado: true,
    });
  };

  const changeTipoPagoCobroAnticipo = async (e, cobro) => {
    cobro.idCatTipoCobro = e.target.value;
    setVentaMostradorNuevo(ventaMostradorNuevo);
    refreshModalCobroAnticipo();
  };

  const changeCantidadAPagarCobroAnticipo = async (e, cobro) => {
    let value = e.target.value;
    value = value.replace("$", "");

    cobro.cantidadPagar = value;
    cobro.iva = (value * detallesPrecio.porcentajeIva).toFixed(2);
    cobro.importe = (value * detallesPrecio.porcentajeImporte).toFixed(2);
    setVentaMostradorNuevo(ventaMostradorNuevo);
    refreshModalCobroAnticipo();
    calcularRestantePagar(cobro);
  };

  const calcularRestantePagar = async (cobro) => {
    let sumaTotalCobrosAnticipo = 0;
    ventaMostradorNuevo.cobrosAnticipo.map((cobroAnt) => {
      if (!cobro || cobro != cobroAnt) {
        sumaTotalCobrosAnticipo += Number(cobroAnt.cantidadPagar);
      }
    });

    let diferencia = (detallesPrecio.total - sumaTotalCobrosAnticipo - (cobro ? cobro.cantidadPagar : 0.0)).toFixed(2);
    if (cobro && diferencia < 0) {
      diferencia = (detallesPrecio.total - sumaTotalCobrosAnticipo).toFixed(2);
      cobro.cantidadPagar = 0;
      cobro.iva = 0;
      cobro.importe = 0;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "La cantidad introducida es mayor a la cantidad restante a pagar",
      });
    }
    ventaMostradorNuevo.importeTotal = diferencia <= 0 ? 0.0 : diferencia;

    if (sumaTotalCobrosAnticipo + Number(cobro ? cobro.cantidadPagar : 0.0) < ventaMostradorNuevo.anticipo) {
      ventaMostradorNuevo.restantePagarAnticipo = (
        ventaMostradorNuevo.anticipo -
        (sumaTotalCobrosAnticipo + Number(cobro ? cobro.cantidadPagar : 0.0))
      ).toFixed(2);
    } else if (sumaTotalCobrosAnticipo + Number(cobro ? cobro.cantidadPagar : 0.0) >= ventaMostradorNuevo.anticipo) {
      ventaMostradorNuevo.restantePagarAnticipo = 0;
    }
    setVentaMostradorNuevo(ventaMostradorNuevo);
  };

  const eliminarCobroAnticipo = async () => {
    ventaMostradorNuevo.cobrosAnticipo = ventaMostradorNuevo.cobrosAnticipo.filter(
      (cobroAnticipo) => cobroAnticipo != cobroEliminar
    );
    setVentaMostradorNuevo(ventaMostradorNuevo);
    refreshModalCobroAnticipo();
    calcularRestantePagar();
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_eliminar_cobro: false,
      success_dlg: true,
      dynamic_title: "Exito!",
      dynamic_description: "Cobro ha sido eliminado",
    });
  };

  useEffect(() => {
    const fetchPacientesActivos = async () => {
      try {
          const pacientesA = await listarPacientesActivos();
          return pacientesA;
        } catch (error) {
        }
    };

    fetchPacientesActivos();
    limpiarVentaMostradorSeleccionada();
    listarClientes();
    listarTiposVentaMostrador();
    //listarProductosPersonal();
    listarProductosServiciosPersonal();
    listarCatTipoCobros();
    if (configuracion.length < 1) {
      listarConfiguracion();
    }
    // if (tipoCobros.length <= 0) {

    // }

    if (usuario) {
      setVentaMostradorNuevo({
        ...ventaMostradorNuevo,
        idUsuarioVendedor: usuario.idCatUsuario,
      });
    }

    configuracion.map((config) => {
      if (config.clave == "IVA") {
        setIvaConfiguracion(parseFloat(config.valor) / 100);
      }
    });
  }, [configuracion]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Nueva Venta Mostrador</h3>

      <Card
        hidden=""
        className="mt-10 card"
        style={{
          padding: "1.5rem 1.5rem 1.5rem",
        }}>
        <form onSubmit="">
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">Fecha de Venta</label>
                  <Col sm={7}>
                    <input autoComplete="off"  type="date" value={fechaDefault} className="form-control" disabled={true} />
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">Cliente</label>
                  <Col sm={7}>
                    <select className="form-control" 
                            autoComplete="off" 
                            name="idCatCliente" 
                            disabled={ventaMostradorNuevo.porNombre}
                            onChange={onChange}
                            value = {ventaMostradorNuevo.idCatCliente? ventaMostradorNuevo.idCatCliente : ""}>
                      <option selected value= "">
                        Seleccione una opción
                      </option>
                      {clientes.map((cliente) => {
                        return (
                          <>
                            <option
                              value={
                                cliente.idCatCliente
                              }>{`${cliente.catPersona.nombres} ${cliente.catPersona.apellidoPaterno} ${cliente.catPersona.apellidoMaterno}`}</option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
                <Row  className="form-group">
                <Col sm={5}>
                  <input
                      type="checkbox"
                      autoComplete="off" 
                      name="porNombre"
                      width={30}
                      onChange={onChange}
                      checked={ventaMostradorNuevo.porNombre}
                  />      
                  <label className="col-sm-7 col-form-label">Nombre del Cliente</label>
                  </Col>  

                  <Col sm={7}>
                    <input
                      type="text"
                      autoComplete="off" 
                      name="cliente"
                      value={ventaMostradorNuevo.cliente? ventaMostradorNuevo.cliente : ""}
                      className="form-control"
                      onChange={onChange}
                      disabled={!ventaMostradorNuevo.porNombre}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">Paciente</label>
                  <Col sm={7}>
                    <select autoComplete="off"  className="form-control" name="idCatPaciente" onChange={onChange}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {pacientes.map((paciente) => {
                        return (
                          <>
                            <option
                              value={
                                paciente.idCatPaciente
                              }>{`${paciente.nombres} ${paciente.apellidoPaterno}  ${paciente.apellidoMaterno}`}</option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>                
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">Vendedor</label>
                  <Col sm={7}>
                    <input type="text" autoComplete="off"  value={usuario.nombre} className="form-control" disabled={true} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                  type="button"
                  onClick={nuevoRenglon}
                  style={{
                    marginRight: "2.5rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}>
                  Agregar Producto / Servicio
                </button>
              </Col>
            </Row>
          </CardBody>
        </form>
      </Card>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-wrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Insumo</th>
                      <th scope="col">Tipo Producto</th>
                      <th scope="col">Piezas</th>
                      <th scope="col">
                        Precio<br></br>Unitario
                      </th>
                      <th scope="col">IVA</th>
                      <th scope="col">Total</th>
                      <th scope="col">Completo</th>
                      <th scope="col">Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ventaMostradorNuevo.ventaMostradorProducto.map((producto, index) => {
                      return (
                        <>
                          <tr key={producto}>
                            <td onClick={() => toggleExpanded(index)}>
                              {producto.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>}
                            </td>
                            <td>
                              <input
                                style={{ width: "50rem" }}
                                autoComplete="off" 
                                value={producto.descripcion}
                                type="text"
                                id={`productoservicio${index}`}
                                onChange={(e) => changeProducto(e, index)}
                                list={`listaInsumos${index}`}
                                className="form-control"
                              />
                              <datalist autoComplete="off"  id={`listaInsumos${index}`}>
                                {productosServiciosFilter.map((prodServ) => {
                                  return (
                                    <>
                                    {prodServ.idCatProducto != null ? (
                                        <option value={`${prodServ.nombreComercial} - ${prodServ.numIdentificacion}`}>
                                            {prodServ.nombreComercial} - {prodServ.numIdentificacion} -
                                            {prodServ.codigoEAN} - {prodServ.principioActivo}
                                        </option>
                                    ) : (
                                        <option value={`${prodServ.servicio}`}>
                                            {prodServ.servicio}
                                        </option>
                                    )}
                                    </>
                                  );
                                })}
                              </datalist>
                            </td>
                            <td>
                              {producto.tipoProducto}
                            </td>
                            <td>
                              <input
                                style={{ width: "70px" }}
                                autoComplete="off" 
                                value={producto.piezas}
                                type="number"
                                disabled={producto.inventarioProductoServicio.length > 0 ? true : false}
                                min="0"
                                onChange={(e) => changePiezasProducto(e, index)}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <input
                                style={{ width: "100px" }}
                                autoComplete="off" 
                                value={producto.precio}
                                type="number"
                                onBlur={(e) =>
                                  checkPrecioMin(e, index, producto.precio, producto.precioMinimo, producto.descripcion)
                                }
                                // disabled={producto.inventarioProductoServicio.length > 0 ? true : false}
                                // min={producto.precioMinimo}
                                onChange={(e) => changePrecioProducto(e, index)}
                                className="form-control"
                              />
                              {/* {producto.precio && formatNumber(producto.precio)} */}
                              {/* {producto.precio && `$${producto.precio.toFixed(2)}`}  */}
                            </td>
                            <td>
                              {/* {" "}
                              ${(producto.iva * producto.piezas).toFixed(2)} */}
                              {formatNumber(producto.iva * producto.piezas)}
                            </td>
                            <td>
                              {/* {" "} */}
                              {producto.precio * producto.piezas
                                ? formatNumber(producto.precio * producto.piezas + producto.iva * producto.piezas)
                                : ""}
                              {/* {producto.precio * producto.piezas ? `$${(producto.precio * producto.piezas + producto.iva * producto.piezas).toFixed(2)}` : ""}{" "} */}
                            </td>
                            <td>
                              <span
                                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                  producto.completed ? "bg-green-100" : "bg-red-100"
                                }  capitalize`}>
                                {producto.completed ? "SI" : "NO"}
                              </span>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                onClick={() => {
                                  setIndexProductoBorrado(index);
                                  setUISweetAlert({
                                    ...UISweetAlert,
                                    confirm_alert_delete_producto: true,
                                  });
                                }}>
                                Eliminar
                              </button>
                            </td>
                          </tr>
                          {producto.expanded && (
                            <tr>
                              <td colSpan={10}>
                                {producto.idCatProducto && producto.piezas > 0 && !producto.completed ? (
                                  <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                                    type="button"
                                    onClick={() => agregarLote(index)}>
                                    Agregar Lote
                                  </button>
                                ) : null}
                                {producto.inventarioProductoServicio.length > 0 && (
                                  <div className="table-responsive">
                                    <table className="table table-hover table-centered table-nowrap mb-10">
                                      <thead>
                                        <tr>
                                          <th scope="col">Lote</th>
                                          <th scope="col">Caducidad</th>
                                          <th scope="col">Piezas Disponibles</th>
                                          <th scope="col">Piezas Asignadas</th>
                                          <th scope="col">Eliminar</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {producto.inventarioProductoServicio.map((lote, indexLote) => {
                                          return (
                                            <>
                                              <tr key={lote}>
                                                <td>
                                                  {lote.inventario ? (
                                                    lote.inventario.lote
                                                  ) : (
                                                    <div>
                                                      <select
                                                        className="form-control"
                                                        autoComplete="off" 
                                                        style={{
                                                          padding: "0 2rem 0 0",
                                                        }}
                                                        value={lote.idInventario}
                                                        onChange={(e) => changeLoteProducto(e, index, indexLote)}>
                                                        <option disabled selected value="">
                                                          Seleccione un lote
                                                        </option>
                                                        {producto.inventarios.map((inventarios) => {
                                                          return (
                                                            <>
                                                              {
                                                                <option value={inventarios.idInventario}>
                                                                  {inventarios.lote}
                                                                </option>
                                                              }
                                                            </>
                                                          );
                                                        })}
                                                      </select>
                                                    </div>
                                                  )}
                                                </td>
                                                <td>{lote.inventario ? lote.inventario.caducidad : lote.caducidad}</td>
                                                <td>{lote.piezasDisponibles}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    autoComplete="off" 
                                                    min="0"
                                                    value={lote.piezasAsignadas}
                                                    disabled={lote.idInventario ? false : true}
                                                    onChange={(e) => changePienzasAsginadas(e, index, indexLote)}
                                                    className="form-control"
                                                  />
                                                </td>
                                                <td>
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                                    onClick={() => {
                                                      setIndexProductoBorrado(index);
                                                      setIndexLoteBorrado(indexLote);
                                                      setUISweetAlert({
                                                        ...UISweetAlert,
                                                        confirm_alert_delete_lote: true,
                                                      });
                                                    }}>
                                                    Eliminar
                                                  </button>
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div>
                <button
                  type="submit"
                  onClick={(e) => confirmNuevaVentaMostrador(e)}
                  // onClick={(e) => {
                  //     e.preventDefault();
                  //     setUISweetAlert({
                  //         ...UISweetAlert,
                  //         confirm_alert_upload_vale: true
                  // })}}
                  className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                  Venta
                </button>
                <button
                  type="submit"
                  onClick={(e) => confirmNuevaCotizacionMostrador(e)}
                  // onClick={(e) => {
                  //     e.preventDefault();
                  //     setUISweetAlert({
                  //         ...UISweetAlert,
                  //         confirm_alert_upload_vale: true
                  // })}}
                  className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                  Cotización
                </button>                
              </div>             
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card className="mt-4 w-full">
        <Card>
          <Row>
            <Col md="12">
              <table
                className="w-2/6"
                style={{
                  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  backgroundColor: "inherit",
                  margin: "0",
                  float: "right",
                }}
                bgcolor="#f6f6f6">
                <tbody>
                  <tr
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                        textAlign: "left",
                      }}
                      valign="top">
                      Sub-Total
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(detallesPrecio.subtotal)}
                      {/* ${detallesPrecio.subtotal} */}
                    </td>
                  </tr>
                  <tr
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                        textAlign: "left",
                      }}
                      valign="top">
                      IVA
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(detallesPrecio.iva)}
                      {/* ${detallesPrecio.iva} */}
                    </td>
                  </tr>
                  <tr
                    className="total"
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      width="80%"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "left",
                        borderTopWidth: "5px",
                        borderTopColor: "#333",
                        borderTopStyle: "double",
                        borderBottomColor: "#333",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        fontWeight: "700",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="left"
                      valign="top">
                      Total
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "5px",
                        borderTopColor: "#333",
                        borderTopStyle: "double",
                        borderBottomColor: "#333",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        fontWeight: "700",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(detallesPrecio.total)}
                      {/* ${detallesPrecio.total} */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Card>
      </Card>

      {UISweetAlert.confirm_nueva_cotizacion ? (
        <SweetAlert
          title="¿Desea solicitar una nueva cotización?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => submitNuevaCotizacionMostrador()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nueva_cotizacion: false,
            })
          }>
          Se solicitará una nueva cotización con la información
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_nueva_venta_completa ? (
        <SweetAlert
          title="¿Desea solicitar una nueva venta?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => submitNuevaVentaMostrador()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nueva_venta_completa: false,
            })
          }>
          Se solicitará una nueva venta con la información
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_nueva_venta_apartado ? (
        <SweetAlert
          title="¿Desea solicitar una nueva venta?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          onConfirm={() => submitNuevaVentaMostrador()}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nueva_venta_apartado: false,
            });
            setVentaMostradorNuevo({
              ...ventaMostradorNuevo,
              anticipo: 0,
              cobrosAnticipo: [],
            });
          }}>
          <div>
            Indique la cantidad de anticipo, para registrar la nueva venta
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-4">
                  <label className="col-sm-4 col-form-label">Total a pagar</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      autoComplete="off" 
                      className="form-control"
                      id="totalPagar"
                      // value={detallesPrecio.total ? `$${detallesPrecio.total}` : `$0`}
                      value={detallesPrecio.total ? formatNumber(detallesPrecio.total) : `$0`}
                      disabled={true}
                      style={{
                        textAlign: "right",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-4">
                  <label className="col-sm-4 col-form-label">Restante para cubrir Total</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      autoComplete="off" 
                      className="form-control"
                      value={ventaMostradorNuevo.importeTotal ? formatNumber(ventaMostradorNuevo.importeTotal) : `$0`}
                      // value={ventaMostradorNuevo.importeTotal ? `$${ventaMostradorNuevo.importeTotal}` : `$0`}
                      disabled={true}
                      style={{
                        textAlign: "right",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-4">
                  <label className="col-sm-4 col-form-label">Mínimo de Anticipo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      autoComplete="off" 
                      className="form-control"
                      disabled
                      value={ventaMostradorNuevo.anticipo ? formatNumber(ventaMostradorNuevo.anticipo) : `$0`}
                      // value={ventaMostradorNuevo.anticipo ? `$${ventaMostradorNuevo.anticipo}` : `$0`}
                      style={{
                        textAlign: "right",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-4">
                  <label className="col-sm-4 col-form-label">
                    Restante para cubrir anticipo
                    {/* Restante para cubrir mínimo de Anticipo */}
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      autoComplete="off" 
                      className="form-control"
                      value={
                        ventaMostradorNuevo.restantePagarAnticipo ? formatNumber(ventaMostradorNuevo.restantePagarAnticipo) : `$0`
                      }
                      // value={ventaMostradorNuevo.restantePagarAnticipo ? `$${ventaMostradorNuevo.restantePagarAnticipo}` : `$0`}
                      disabled={true}
                      style={{
                        textAlign: "right",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="form-group mt-2 ml-2 mr-2">
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mb-4"
                onClick={() => insertarNuevoCobro()}>
                Insertar Cobro
              </button>

              <table className="table table-hover table-centered table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">
                      Forma de<br></br>Pago
                    </th>
                    <th scope="col">
                      Cantidad a<br></br>Pagar
                    </th>
                    <th scope="col">Importe</th>
                    <th scope="col">IVA</th>
                    <th scope="col">Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {ventaMostradorNuevo.cobrosAnticipo &&
                    ventaMostradorNuevo.cobrosAnticipo.map((cobro) => {
                      return (
                        <tr key={cobro}>
                          <td>
                            <select
                              className="form-control"
                              autoComplete="off" 
                              name="idCatTipoCobro"
                              onChange={(e) => changeTipoPagoCobroAnticipo(e, cobro)}
                              value={cobro.idCatTipoCobro}>
                              <option disabled selected value="">
                                Seleccione una opción
                              </option>
                              {tipoCobros.map((cobro) => {
                                return (
                                  <>
                                    <option value={cobro.idCatTipoCobro}>{cobro.tipoCobro}</option>
                                  </>
                                );
                              })}
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              autoComplete="off" 
                              className="form-control"
                              name="cantidadPagar"
                              onChange={(e) => changeCantidadAPagarCobroAnticipo(e, cobro)}
                              value={cobro.cantidadPagar ? `$${cobro.cantidadPagar}` : `$`}
                            />
                          </td>
                          <td>{cobro.importe ? `$${cobro.importe}` : `$0`}</td>
                          <td>{cobro.iva ? `$${cobro.iva}` : `$0`}</td>
                          <td>
                            <button
                              type="button"
                              onClick={() => {
                                setCobroEliminar(cobro);
                                setUISweetAlert({
                                  ...UISweetAlert,
                                  confirm_alert_eliminar_cobro: true,
                                });
                              }}
                              className="btn btn-primary-orange-umo w-md waves-effect waves-light flex  hover:text-white">
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

{UISweetAlert.confirm_nueva_venta_completa ? (
        <SweetAlert
          title="¿Desea solicitar una nueva venta?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => submitNuevaVentaMostrador()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nueva_venta_completa: false,
            })
          }>
          Se solicitará una nueva venta con la información
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_delete_producto ? (
        <SweetAlert
          title="¿Desea eliminar el insumo?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarProducto()}
          onCancel={() => {
            setIndexProductoBorrado(null);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_delete_producto: false,
            });
          }}>
          Se perderá la información
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_delete_lote ? (
        <SweetAlert
          title="¿Desea eliminar el insumo?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarLote()}
          onCancel={() => {
            setIndexProductoBorrado(null);
            setIndexLoteBorrado(null);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_delete_lote: false,
            });
          }}>
          Se perderá la información
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_eliminar_cobro ? (
        <SweetAlert
          title="¿Desea eliminar el cobro?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarCobroAnticipo()}
          onCancel={() => {
            setCobroEliminar(null);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_eliminar_cobro: false,
            });
          }}>
          Se perderán todos los cambios realizados en el lote
        </SweetAlert>
      ) : null}
      {/* /** 
    Dejar al final los mensajes de loading, success, y error
*/}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}
    </div>
  );
}
