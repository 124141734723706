import {useContext} from "react";
import ContabilidadFacturasContext from "../context/ContabilidadFacturas/ContabilidadFacturasContext";


const useInvoiceActions = ({setLoading, UISweetAlert, setUISweetAlert, setDatosFiscales, datosFiscales, setClienteWithDetalles, setRespuestaFacturas}) => {
    const contabilidadFacturasContext = useContext(ContabilidadFacturasContext);
    const {
        nuevaFactura,
        actualizarDatosFiscales,
        exportFactura,
        exportFacturaXML,
    } = contabilidadFacturasContext;
    const setFiscalDataWithFetchedData = (datos, isDatosAseguradora) => {
        if (isDatosAseguradora) {
            setDatosFiscales({
                ...datos,
                nombre: datos.razonSocial ? datos.razonSocial : "",
                idCatRegimenFiscal: datos && datos.catRegimenFiscal ? datos.catRegimenFiscal.idCatRegimenFiscal : "",
                idCatUsoCfdi: datos && datos.catUsoCfdi ? datos.catUsoCfdi.idCatUsoCfdi : "",
                idEstado: datos && datos.catEstado ? datos.catEstado.idCatOrigenResidencia : "",
            });
        } else {
            const catPersona = datos.catPersona ? datos.catPersona : null;

            const datosFiscales = catPersona && catPersona.datosFiscales ? catPersona.datosFiscales : null;

            setDatosFiscales({
                ...datosFiscales,
                idCatPersona: catPersona ? catPersona.idCatPersona : "",
                nombre: catPersona ? catPersona.nombres + " " + catPersona.apellidoPaterno + " " + catPersona.apellidoMaterno : "",
                idCatRegimenFiscal:
                    datos && catPersona && datosFiscales && datos.catPersona.datosFiscales.catRegimenFiscal
                        ? datos.catPersona.datosFiscales.catRegimenFiscal.idCatRegimenFiscal
                        : "",
                idCatUsoCfdi:
                    datos && catPersona && datosFiscales && datos.catPersona.datosFiscales.catUsoCfdi
                        ? datos.catPersona.datosFiscales.catUsoCfdi.idCatUsoCfdi
                        : "",
                idEstado:
                    datos && catPersona && datosFiscales && datos.catPersona.datosFiscales.catEstado
                        ? datos.catPersona.datosFiscales.catEstado.idCatOrigenResidencia
                        : "",
            });
        }
    };

    const checkFinalInvoice = async (selectedData, hasInsurance = false) => {
        if (!selectedData) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione al menos un cobro para facturar.",
            });
            return;
        }
        const datos = await setClienteWithDetalles(selectedData.catCliente?.idCatCliente);
        setFiscalDataWithFetchedData(datos, hasInsurance)
        setUISweetAlert({
            ...UISweetAlert,
            confirm_datos_fiscales: true,
        });
    };

    const onChangeFiscalData = (e) => {
        const {name, value} = e.target;
        setDatosFiscales({
            ...datosFiscales,
            [name]: value,
        });
    };

    const checkUpdateFiscalData = (modalKeyState) => {
        setUISweetAlert({
            ...UISweetAlert,
            [modalKeyState]: true,
        });
    };

    const confirmUpdateFiscalData = async () => {
        setLoading(true);

        const res = await actualizarDatosFiscales({...datosFiscales, idCatEstado: datosFiscales.idEstado});

        if (res.data.error) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_actualizar_datos_fiscales: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_actualizar_datos_fiscales: false,
                dynamic_description: "Se actualizaron correctamente los datos fiscales",
            });
        }
    };

    const confirmNewFinalInvoice = async ({selectedData, valesSeleccionados = [], ventasMostradorSeleccionadas = []}) => {
        const idCliente = selectedData.catCliente?.idCatCliente;
        if (datosFiscales.tipoPersona === undefined && idCliente != 1) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione con que datos fiscales quiere facturar",
            });
            return;
        }

        setLoading(true);
        try {
            const res = await nuevaFactura({
                vales: valesSeleccionados,
                ventasMostrador: ventasMostradorSeleccionadas,
                tipoGeneracionFactura: 1,
                publicoGeneral: datosFiscales.tipoPersona == 2 || idCliente == 1,
                idCatMedico: null,
                idCatAseguradora: null,
                idCatCliente: idCliente,
                idVentaValeCobro: selectedData.idVentaValeCobro ? selectedData.idVentaValeCobro : null
            });
            if (res.data != null && res.data[0] == null) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_datos_fiscales: false,
                    dynamic_title: "Fallo",
                    dynamic_description: "Ocurrió un error, por favor inténtalo más tarde.",
                });
                return;
            }
            setLoading(false);
            setRespuestaFacturas(res.data);
            setUISweetAlert({
                ...UISweetAlert,
                confirm_datos_fiscales: false,
                respuestas_facturas: true,
            });
        } catch (e) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_datos_fiscales: false,
                dynamic_title: "Fallo",
                dynamic_description: "Ocurrió un error, por favor inténtalo más tarde.",
            });
        }
    };

    const downloadFactura = async (link, fileType) => {
        setLoading(true);
        let res;
        if (fileType === "PDF") {
            res = await exportFactura(link);
        } else if (fileType === "XML") {
            res = await exportFacturaXML(link);
        }

        if (res?.data?.error) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else {
            setLoading(false);
        }
    };


    return {
        checkFinalInvoice,
        onChangeFiscalData,
        checkUpdateFiscalData,
        confirmUpdateFiscalData,
        confirmNewFinalInvoice,
        downloadFactura
    };
};

export default useInvoiceActions;
