import React, { useState, useMemo, useRef, useContext, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col, Spinner } from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import AuthContext from "../../context/Auth/AuthContext";

// import PacientesContext from "../../context/Pacientes/PacientesContext";
import HojaIndicacionContext from "../../context/HojaIndicacion/HojaIndicacionContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";

import MedicosContext from "../../context/Medicos/MedicosContext";

import TableComponent from "../TablaPaginada/index";

import { CargaArchivoModal } from "../CargaArchivoModal";

export default function TabListaHojaIndicaciones({ props, expedienteMedico }) {
  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const hojaIndicacionContext = useContext(HojaIndicacionContext);
  const { setHojasPaginadas, hojasIndicacion, exportHojaIndicacion, uploadArchivoHojaIndicacion } = hojaIndicacionContext;

  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const pacientesContext = useContext(PacientesContext);
  const { exportArchivoBack } = pacientesContext;

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_subir_hoja: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
  const fetchIdRef = useRef(0);

  const datosExtraHojaVacio = {
    fecha: "",

    comentarios: "",
  };

  const datosCargaArchivoVacio = {
    fecha: "",
    selectedFile: null,
    comentario: null,
  };

  const [datosExtraHoja, setDatosExtraHoja] = useState(datosExtraHojaVacio);

  const [datosCargaArchivo, setDatosCargaArchivo] = useState(datosCargaArchivoVacio);

  useEffect(() => {
    const fetchAll = async () => {
      listarMedicos();
    };

    fetchAll();
  }, []);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      // const response = await setHojasPaginadas({ page, limit, expedienteMedico.idExpedienteMedico });
      const idExpedienteMedico = expedienteMedico.idExpedienteMedico;
      const response = await setHojasPaginadas({ page, limit, idExpedienteMedico });

      // Cantidad de datos disponibles en la tabla pacientes de la base de datos
      const count = response.count;

      setRowSeleccionada(null);
      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }
      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    console.log("fetchData is being called");
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server.
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const handdleCheckedHojaIndicaciones = async (row) => {
    if (row.archivosVo?.length > 0) {
      const archivo = row.archivosVo[0];
      const res = await exportArchivo(archivo);
      return res;
    } else if (row.idHojaIndicacion !== null) {
      setLoading(true);
      const res = await exportHojaIndicacion({ idHojaIndicacion: row.idHojaIndicacion });
      if (res.data && res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
        return res;
      } else if (res) {
        setLoading(false);
        return res;
      }
    }
  };

  const exportArchivo = async (archivo) => {
    // convert image file to base64 string

    const archivoVo = {
      rutaArchivo: archivo.rutaArchivo,
      nombreArchivo: archivo.nombreArchivo,
      mimeType: archivo.mimeType,
    };
    const res = await exportArchivoBack(archivoVo);
    return res;
  };

  const columns = useMemo(
    () => [
      { Header: "Fecha de Registro", accessor: "fechaCreacion" },
      {
        Header: "Comentarios",
        Cell: ({ row }) => {
          return <textarea disabled={true} cols={40} value={row.original.comentarioExtra ?? ""} />;
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Detalles",
        Cell: ({ row }) => {
          return (
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              onClick={() => handdleCheckedHojaIndicaciones(row.original)}>
              Detalles
            </button>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const autorizadoSubirHoja = usuario && usuario.authorities.includes("ROLE_QUIMIO");

  const confirmSubirHoja = () => {
    if (autorizadoSubirHoja) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_subir_hoja: true,
      });
      return;
    }
    return;
  };

  const infoSweetAlert = { title: "Cargar Hoja de Indicación", confirmBtnText: "Guardar", cancelBtnText: "Cancelar" };

  const validarDatosArchivo = () => {
    if (datosCargaArchivo.selectedFile === null) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, cargue un archivo de Hoja de Indicación",
      });
      return true;
    }

    if (datosCargaArchivo.fecha === null || datosCargaArchivo.fecha == "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la fecha de registro",
      });
      return true;
    }
    if (datosCargaArchivo.comentario === null || datosCargaArchivo.comentario === "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un comentario",
      });
      return true;
    }
    if (datosExtraHoja.medico == null || datosExtraHoja.medico == "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un médico",
      });
      return true;
    }
    return false;
  };

  const onFileUpload = async () => {
    const datosValidos = validarDatosArchivo();
    if (!datosValidos) {
      delete datosCargaArchivo.selectedFile;
      setLoading(true);
      try {
        const res = await uploadArchivoHojaIndicacion({
          archivosVo: [
            {
              ...datosCargaArchivo,
            },
          ],
          fechaRegistro: datosCargaArchivo.fecha,
          idCatMedico: datosExtraHoja.medico,
          comentarioExtra: datosCargaArchivo.comentario,
          idExpedienteMedico: expedienteMedico.idExpedienteMedico,
        });
        if (res.data && res.data.error) {
          setLoading(false);
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Ocurrió un error al cargar el archivo, por favor inténtalo más tarde.",
          });
        } else if (res) {
          setLoading(false);
          setUISweetAlert({
            ...UISweetAlert,
            success_dlg: true,
            dynamic_title: "Exito",
            confirm_subir_hoja: false,
            dynamic_description: "Se guardó correctamente el archivo",
          });
          fetchData({ pageSize: 10, pageIndex: 0 });
          setDatosCargaArchivo(datosCargaArchivoVacio);
          setDatosExtraHoja(datosExtraHojaVacio);
        }

        setLoading(false);
      } catch (error) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Ocurrió un error al cargar el archivo, por favor inténtalo más tarde.",
        });
        setLoading(false);
      }
    }
  };

  const onCancel = () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_subir_hoja: false,
    });
    setDatosCargaArchivo(datosCargaArchivoVacio);
  };

  const onFileChange = (event) => {
    setDatosCargaArchivo({ ...datosCargaArchivo, selectedFile: event.target.files[0] });
    const file = event.target.files[0];
    const reader = new FileReader();

    // convert image file to base64 string

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string

        let strFile = reader.result;
        let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
        let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);
        setDatosCargaArchivo({
          ...datosCargaArchivo,
          selectedFile: file,
          nombreArchivo: file.name,
          mimeType: type,
          tamano: file.size,
          inputStream: fileBase64,
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const changeCargaArchivo = (e) => {
    const { name, value } = e.target;
    setDatosCargaArchivo({
      ...datosCargaArchivo,
      [name]: value,
    });
  };

  const changeDatosExtraHoja = (e) => {
    const { name, value } = e.target;

    setDatosExtraHoja({
      ...datosExtraHoja,
      [name]: value,
    });
  };

  return (
    <div className="mt-4 container-fluid ">
      <Row className="mt-4">
        <Col>
          <h4 className="mb-4">Listado de Hojas de Indicaciones</h4>
          <button
            hidden={!autorizadoSubirHoja}
            className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
            type="button"
            onClick={confirmSubirHoja}>
            Cargar Hoja de Indicación
          </button>
          <TableComponent
            pageCount={pageCount}
            fetchData={fetchData}
            columns={columns}
            loading={loadingData}
            data={hojasIndicacion}
            indexOfPage={controlledPageIndex}
          />
        </Col>
      </Row>
      {UISweetAlert.confirm_subir_hoja ? (
        <CargaArchivoModal
          disabledFecha={false}
          datosCargaArchivo={datosCargaArchivo}
          setDatosCargaArchivo={setDatosCargaArchivo}
          infoSweetAlert={infoSweetAlert}
          onFileUpload={onFileUpload}
          onCancel={onCancel}
          conComentario={true}
          onFileChange={(e) => onFileChange(e)}
          onChange={(e) => changeCargaArchivo(e)}
          filesAccepted="application/pdf">
          <label className="col-sm-5 col-form-label">
            Médico<span className="text-xs text-red-700">*</span>
          </label>
          <Col sm={7}>
            <select
              className="form-control"
              name="medico"
              id="medico"
              value={datosExtraHoja.medico ? datosExtraHoja.medico : ""}
              onChange={changeDatosExtraHoja}>
              <option value="">Seleccione un médico</option>
              {medicos.map((medico) => {
                return (
                  <>
                    <option key={medico.idCatMedico} value={medico.idCatMedico}>{`${medico.prefijo ? medico.prefijo : ""} ${
                      medico.catPersona.nombres
                    } ${medico.catPersona.apellidoPaterno} ${medico.catPersona.apellidoMaterno}`}</option>
                  </>
                );
              })}
            </select>
            {(datosExtraHoja.medico === "" || !datosExtraHoja.medico) && (
              <span className="text-sm text-red-700">Este campo es obligatorio</span>
            )}
          </Col>
        </CargaArchivoModal>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
