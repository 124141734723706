import React, { useContext } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col } from "reactstrap";
// import useCloseWindowWarning from "../../../hooks/useCloseWindowWarning";

import WindowWarningContext from "../../../context/WindowWarning/WindowWarningContext";

export default function RevisionCaso({ props, historiaClinicaInicial, nuevaRevisionCaso, setNuevaRevisionCaso }) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito } = windowWarningContext;

  // const [Prompt, setEditTrue, setEditFalse, isEdit] = useCloseWindowWarning();

  const onChange = async (e) => {
    const { value } = e.target;
    // if (!isEdit) setEditTrue();
    if (!seEdito) {
      changeEdit(true);
    }
    setNuevaRevisionCaso(value);
  };

  return (
    <div className="mt-4 container-fluid" style={{ textAlign: "left" }}>
      <h4 className="mt-4">Revisión del Caso</h4>
      <Row className="form-group">
        {/* <label className="col-sm-2 col-form-label">Estapa Clinica</label> */}
        <Col sm={10}>
          <textarea 
            rows={10}
            disabled={true}
            name="revisionCaso"
            value={
              historiaClinicaInicial.revisionCaso && historiaClinicaInicial.revisionCaso.revisionCaso
                ? historiaClinicaInicial.revisionCaso.revisionCaso
                : ""
            }
            className="form-control-disabled"
          />
        </Col>
      </Row>
      <h4 className="mt-4">Información adicional</h4>
      <span>Esta información se concatenará con la información actual de la revisión del caso</span>
      <Row className="form-group">
        <Col sm={10}>
          <textarea
            rows={10}
            name="nuevaRevisionCaso"
            value={nuevaRevisionCaso ? nuevaRevisionCaso : ""}
            onChange={onChange}
            className="form-control"
          />
        </Col>
      </Row>
    </div>
  );
}
