import React, {useState, useContext, useEffect, useRef, useCallback, useMemo} from "react";
import {Row, Col, Card, CardBody, Spinner} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";


import ValesContext from "../../context/Vales/ValesContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";
import TipoValeContext from "../../context/TipoVale/TipoValeContext";
import AuthContext from "../../context/Auth/AuthContext";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import SelectPacientesPaginado from "../SelectAsyncPaginate";

import {opcionesSelectPacientes} from "../../config/configOpcionesSelect";


// import useWindowDimensions from "../../hooks/dimensionsHook";

import {formatCatPersonaFullName} from "../../utils/formatCatPersona";
import {Link} from "react-router-dom";

export default function ValesMedico(props) {
    const fetchIdRef = useRef(0);

    //Context necesario para obtener información del back
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    const medicosContext = useContext(MedicosContext);
    const {getMedicoById} = medicosContext;

    const ctxVales = useContext(ValesContext);
    const {
        listarValesPaginadosMedico,
        valesFiltradosMedico
    } = ctxVales;

    const ctxPacientes = useContext(PacientesContext);
    const {listarPacientes, loadPacientesSelect} = ctxPacientes;

    const ctxTiposVale = useContext(TipoValeContext);
    const {tiposVale, listarTiposVale} = ctxTiposVale;

    const ctxAseguradoras = useContext(AseguradorasContext);
    const {aseguradoras, listarAseguradoras} = ctxAseguradoras;

    const ctxEstatusVale = useContext(EstatusValeContext);
    const {
        estatusvale,
        listarEstatusVale,
    } = ctxEstatusVale;

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_imprimir_vale: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [rowsSeleccionadas, setRowsSeleccionadas] = useState([]);
    // State del componente
    const [medicoAuthenticated, setMedicoAuthenticated] = useState(null);
    const [toggleSearchBy, setToggleSearchBy] = useState(false);

    const [pacienteCompleto, setPacienteCompleto] = useState(null);

    const filtrosVacios = {
        fechaInicial: "",
        paciente: "",
        aseguradora: "",
        estatusVale: "",
        estatusValeOpciones: [],
        fechaFinal: "",
        tipoVale: "",
        folioVale: null,
        usuarioSolicitante: "",
    }

    const [filtrosVales, setFiltrosVales] = useState(filtrosVacios)
    const [valesTablaSeleccionado, setValesTablaSeleccionado] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);


    useEffect(() => {
        const fetchMedico = async () => {
            const res = await getMedicoById(usuario.idCatMedico);
            setMedicoAuthenticated(res);
        }
        fetchMedico();
    }, [])

    useEffect(() => {
        listarEstatusVale(true);
        listarAseguradoras();
        listarPacientes();
        listarTiposVale();
    }, []);

    const limpiarFiltros = () => {
        setPacienteCompleto(null)
        setFiltrosVales(filtrosVacios
        );
    };
    const toggleSearch = () => {
        setToggleSearchBy(!toggleSearchBy);
        limpiarFiltros();
    };

    const onChangeFolio = (e) => {
        setFiltrosVales({
            ...filtrosVales,
            [e.target.name]: e.target.value,
        });
    };

    const handleCheckedVale = useCallback(
        (row) => {
            if (rowsSeleccionadas === null) {
                const rows = [row.id];
                setRowsSeleccionadas(rows);
                const vales = [{...row.original}];
                setValesTablaSeleccionado(vales);
            } else if (rowsSeleccionadas.length === 0) {
                const rows = [row.id];
                setRowsSeleccionadas(rows);
                const vales = [{...row.original}];
                setValesTablaSeleccionado(vales);
            } else if (rowsSeleccionadas.includes(row.id)) {
                const tempRows = rowsSeleccionadas.filter((r) => r !== row.id);
                setRowsSeleccionadas(tempRows);
                const tempVales = valesTablaSeleccionado.filter((v) => v.idVale !== row.original.idVale);
                setValesTablaSeleccionado(tempVales);
            } else {
                const rows = [...rowsSeleccionadas, row.id];
                setRowsSeleccionadas(rows);
                const vales = [...valesTablaSeleccionado, {...row.original}];
                setValesTablaSeleccionado(vales);
            }
        },
        [rowsSeleccionadas]
    );

    const estatusActual = (vale) => {
            switch (vale.estatusActual.catEstatusvale.idCatEstatusVale) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 18:
                case 19:
                case 20:
                case 23:
                case 24:
                    return (
                        <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "black",
                        fontWeight: "bold",
                        //verde
                        backgroundColor: "#C1E05C",
                        borderRadius: "5px",
                    }}>
                {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                        </>
                    );

                case 7:
                case 10:
                case 17:
                case 21:
                case 22:
                case 25:
                    return (
                        <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "white",
                        fontWeight: "bold",
                        //morado
                        backgroundColor: "#602a96",
                        borderRadius: "5px",
                    }}>
                {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                        </>
                    );

                //Debemos checar los estatus aplicado y cobrado, para saber cuando se acaba el ciclo de vida de un vale personal
                case 8:
                    if (vale.idCatTipoMedico == 2) {
                        return (
                            <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "white",
                        fontWeight: "bold",
                        //morado
                        backgroundColor: "#602a96",
                        borderRadius: "5px",
                    }}>
                    {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                            </>
                        );
                    } else {
                        return (
                            <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        //verde
                        backgroundColor: "#C1E05C",
                        borderRadius: "5px",
                    }}>
                    {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                            </>
                        );
                    }
                case 9:
                    if (vale.idCatTipoMedico == 1) {
                        return (
                            <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "white",
                        fontWeight: "bold",
                        //morado
                        backgroundColor: "#602a96",
                        borderRadius: "5px",
                    }}>
                    {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                            </>
                        );
                    } else {
                        return (
                            <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        //verde
                        color: "black",
                        fontWeight: "bold",
                        backgroundColor: "#C1E05C",
                        borderRadius: "5px",
                    }}>
                    {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                            </>
                        );
                    }
                default:
                    return <></>;
            }
    };

    const columns = useMemo(
        () => [
            // {
            //     Header: "",
            //     accessor: "idVale",
            //     Cell: ({row}) => {
            //         return (
            //             <input
            //                 // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
            //                 checked={rowsSeleccionadas != null ? rowsSeleccionadas.includes(row.id) : false}
            //                 type="checkbox"
            //                 onChange={() => handleCheckedVale(row)}
            //             />
            //         );
            //     },
            //
            //     disableFilters: true,
            //     disableSortBy: true,
            // },
            {Header: "Folio", accessor: "folioVale"},
            {
                Header: () => (
                    <>
                        Fecha del<br></br>Vale
                    </>
                ),
                accessor: "fechaVale",
                Cell: ({row}) => {
                    return <>{row.original.fechaVale ? row.original.fechaVale.split("T")[0] : ""}</>;
                },
            },
            {
                Header: () => (
                    <>
                        Fecha <br></br>Tratamiento
                    </>
                ),
                accessor: "fechaTratamiento",
                Cell: ({row}) => {
                    return <>{row.original.fechaTratamiento ? row.original.fechaTratamiento.split("T")[0] : ""}</>;
                },
            },                  
            {Header: "Paciente", accessor: "paciente"},
            // {Header: "Médico", accessor: "medico"},
            {
                Header: "Aseguradora",
                accessor: "aseguradora",
                Cell: ({row}) => {
                    return <>{row.original.aseguradora ? row.original.aseguradora : "N/A"}</>;
                },
            },
            {
                Header: "Solicitante",
                accessor: "usuarioSolicitante",
                Cell: ({row}) => {
                    return <>{row.original.usuarioSolicitante ? row.original.usuarioSolicitante : ""}</>;
                },
            },
            {
                Header: "Estatus",
                accessor: "estatusActual",
                Cell: ({row}) => {
                    return <>{estatusActual(row.original)}</>;
                },
            },
            {
                Header: "Histórico",
                accessor: "historico",
                Cell: ({row}) => {
                    return (
                        <Link
                            to={`/vales/historico/${row.original.idVale}`}
                            className="btn btn-sm btn-primary-orange-umo"
                            style={{
                                background: "#f93",
                                color: "white",
                                fontWeight: "bold",
                            }}>
                            Histórico
                        </Link>
                    );
                },

                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [rowsSeleccionadas, handleCheckedVale]
    );
    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);

            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);
            }
            const response = await listarValesPaginadosMedico({page, limit}, filtrosVales, usuario.idCatMedico);

            // Cantidad de datos disponibles en la tabla pacientes de la base de datos
            const count = response.count;
            setRowsSeleccionadas(null);
            setValesTablaSeleccionado(null);

            if (count % limit > 0) {
                setPageCount(Math.ceil(count / limit));
            } else {
                setPageCount(count / limit);
            }
            setLoadingData(false);
        } catch (e) {
            console.log("Error while fetching", e);
            // setLoading(false)
        }
    };

    const fetchData = async ({pageSize, pageIndex}) => {
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: false,
            });
        }
    };

    const buscarPorFolio = (e) => {
        e.preventDefault();
        fetchData({pageSize: 10, pageIndex: 0});
    };

    const enviarValesFiltrados = (e) => {
        e.preventDefault();
        if (filtrosVales.fechaInicial && !filtrosVales.fechaFinal) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Introduzca una fecha inicial y una final",
            });
            return;
        }

        if (filtrosVales.fechaFinal) {
            filtrosVales.fechaFinal = filtrosVales.fechaFinal + "T23:59:59";
        }

        fetchData({pageSize: 10, pageIndex: 0});


        if (filtrosVales.fechaFinal) {
            filtrosVales.fechaFinal = filtrosVales.fechaFinal.split("T")[0];
        }
    };

    const onChangeFecha = (e) => {
        const {name, value} = e.target;
        if (name === "fechaInicial") {
            setFiltrosVales({
                ...filtrosVales,
                [name]: value,
                fechaFinal: value,
            });
        } else {
            setFiltrosVales({
                ...filtrosVales,
                [name]: value,
            });
        }
    };

    const onChange = (e, name = null) => {
        if (name && name === "paciente") {
            setPacienteCompleto(e);
            setFiltrosVales({
                ...filtrosVales,
                [name]: e.idCatPaciente,
            });
        } else {
            setFiltrosVales({
                ...filtrosVales,
                [e.target.name]: e.target.value,
            });
        }
    };


    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Vales
                de {medicoAuthenticated ? medicoAuthenticated.prefijo ? medicoAuthenticated.prefijo + " " + formatCatPersonaFullName(medicoAuthenticated.catPersona) : formatCatPersonaFullName(medicoAuthenticated.catPersona) : ""}</h3>
            <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
                type="button"
                onClick={toggleSearch}>
                {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
            </button>
            <Card
                hidden={!toggleSearchBy}
                className="mt-10 card"
                style={{
                    padding: "1.5rem 1.5rem 1.5rem",
                }}>
                <form onSubmit={(e) => buscarPorFolio(e)}>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                                <Row
                                    className="form-group"
                                    style={{
                                        marginBottom: "0",
                                    }}>
                                    <Col sm={10} className="flex col-sm-10">
                                        <input
                                            className="form-control mr-2.5"
                                            type="text"
                                            name="folioVale"
                                            placeholder="Escribe tu folio"
                                            onChange={onChangeFolio}
                                            value={filtrosVales.folioVale ? filtrosVales.folioVale : ""}
                                        />
                                        <button
                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                            style={{
                                                marginRight: "2.5rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            type="submit">
                                            Buscar
                                        </button>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </CardBody>
                </form>
            </Card>
            <Card
                hidden={toggleSearchBy}
                className="mt-10 card"
                style={{
                    padding: "1.5rem 1.5rem 1.5rem",
                }}>
                <form onSubmit={(e) => enviarValesFiltrados(e)}>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                                    <Col sm={7}>
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="fechaInicial"
                                            onChange={onChangeFecha}
                                            value={filtrosVales.fechaInicial}
                                        />
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Lista de pacientes</label>
                                    <Col sm={7}>
                                        <SelectPacientesPaginado
                                            opcionSeleccionadaCompleta={pacienteCompleto}
                                            onChangeOptions={onChange}
                                            fetchOptions={loadPacientesSelect}
                                            opcionesPaginado={opcionesSelectPacientes.opcionesPaginado}
                                            placeholderText={opcionesSelectPacientes.placeholderText}
                                            optionLabel={opcionesSelectPacientes.optionLabel}
                                            optionValue={opcionesSelectPacientes.optionValue}
                                            name="paciente"
                                        />
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Aseguradora</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="aseguradora"
                                            id="aseguradora"
                                            onChange={onChange}
                                            value={filtrosVales.aseguradora}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {aseguradoras.map((aseguradora) => {
                                                return (
                                                    <>
                                                        <option value={aseguradora.idCatAseguradora}>{aseguradora.razonSocial}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>


                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha Final</label>
                                    <Col sm={7}>
                                        <input
                                            className="form-control"
                                            type="date"
                                            // type="datetime-local"
                                            name="fechaFinal"
                                            disabled={!filtrosVales.fechaInicial ? true : false}
                                            min={filtrosVales.fechaInicial}
                                            onChange={onChange}
                                            value={filtrosVales.fechaFinal}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Estatus Actual</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="estatusVale"
                                            id="estatusVale"
                                            onChange={onChange}
                                            value={filtrosVales.estatusVale ? filtrosVales.estatusVale : ""}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>

                                            {estatusvale.map((estatus) => {
                                                return (
                                                    <>
                                                        <option value={estatus.idCatEstatusVale ? estatus.idCatEstatusVale : estatus}>
                                                            {estatus.estatusVale ? estatus.estatusVale : estatus}
                                                        </option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Tipo Vale</label>
                                    <Col sm={7}>
                                        <select
                                            name="tipoVale"
                                            id="tipoVale"
                                            className="form-control"
                                            onChange={onChange}
                                            value={filtrosVales.tipoVale}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {tiposVale.map((tipoVale) => {
                                                return (
                                                    <>
                                                        <option value={tipoVale.idCatTipoVale}>{tipoVale.tipoVale}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                    type="submit"
                                    style={{
                                        marginRight: "2.5rem",
                                        background: "#ff9933",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}>
                                    Aplicar Filtros
                                </button>
                                <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick={limpiarFiltros}
                                    style={{
                                        marginRight: "2.5rem",
                                        background: "#ff9933",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}>
                                    Limpiar Filtros
                                </button>
                            </Col>
                        </Row>
                    </CardBody>
                </form>
            </Card>
            <Row>
                <Col>
                    <Card className="mt-4">
                        <CardBody>
                            {medicoAuthenticated?.idCatMedico ?
                                <TableComponent
                                    pageCount={pageCount}
                                    fetchData={fetchData}
                                    columns={columns}
                                    loading={loadingData}
                                    data={valesFiltradosMedico}
                                    indexOfPage={controlledPageIndex}
                                /> : null}
                        </CardBody>
                    </Card>
                </Col>
            </Row>


            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}
            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
