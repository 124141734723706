import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import { Link } from "react-router-dom";
import AuthContext from "../../context/Auth/AuthContext";
import AnaquelesContext from "../../context/Anaqueles/AnaquelesContext";
import EstantesContext from "../../context/Estantes/EstantesContext";

import useWindowDimensions from "../../hooks/dimensionsHook";

export default function Anaqueles(props) {
  const { width } = useWindowDimensions();
  const { idCatAlmacen } = useParams();

  const cleanAnaquel = {
    activo: true,
  };

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_anaquel: false,
    editar: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  const [loading, setLoading] = useState(false);

  const [anaquelNuevoEditar, setAnaquelNuevoEditar] = useState(cleanAnaquel);

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const anaquelesCtx = useContext(AnaquelesContext);
  const { almacenSeleccionado, anaqueles, seleccionarAlmacen, uploadAnaquel, uploadDeleteAnaquel } = anaquelesCtx;

  const estantesCtx = useContext(EstantesContext);
  const { limpiarEstantes } = estantesCtx;

  const checkUploadAnaquel = async () => {
    let errorAnaquel = false;

    if (!anaquelNuevoEditar.anaquel && !errorAnaquel) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el nombre del anaquel",
      });
      errorAnaquel = true;
      return;
    }

    if (!errorAnaquel) {
      setLoading(true);
      const res = await uploadAnaquel(anaquelNuevoEditar);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          editar: false,
          dynamic_title: "Exito",
          confirm_alert_anaquel: false,
          dynamic_description: "Se generó un nuevo anaquel",
        });
        setAnaquelNuevoEditar(cleanAnaquel);
      }
    }
  };

  const onChangeAnaquel = async (e) => {
    let value = e.target.value;

    if (e.target.name == "activo") {
      value = !anaquelNuevoEditar.activo;
    }

    setAnaquelNuevoEditar({
      ...anaquelNuevoEditar,
      [e.target.name]: value,
    });
  };

  const eliminarAnaquel = async () => {
    if (anaquelNuevoEditar.estantes && anaquelNuevoEditar.estantes.length > 0) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_anaquel_eliminar: false,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se pueden eliminar anaqueles que tengan estantes asociados",
      });
      return;
    }

    setLoading(true);
    const res = await uploadDeleteAnaquel(anaquelNuevoEditar);
    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_anaquel_eliminar: false,
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_anaquel_eliminar: false,
        dynamic_description: "Se eliminó el anaquel de manera correcta.",
      });
      setAnaquelNuevoEditar(cleanAnaquel);
    }
  };

  useEffect(() => {
    limpiarEstantes();
    seleccionarAlmacen(idCatAlmacen);
  }, []);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Lista de Anaqueles</h3>

      <Card className="mt-10 card" style={{ padding: "1.5rem 1.5rem 1.5rem" }}>
        <CardBody>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Almacén Seleccionado</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={almacenSeleccionado ? almacenSeleccionado.almacen : ""}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Responsable</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={almacenSeleccionado.responsable ? almacenSeleccionado.responsable.usuario : ""}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col>
              <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mr-6 hover:text-white"
                type="button"
                hidden={usuario.authorities.includes("OP_ALMACEN_CREAR") ? false : true}
                onClick={() => {
                  setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_anaquel: true,
                    editar: false,
                  });
                }}>
                Nuevo Anaquel
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-responsive mt-4">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        Nombre<br></br>Anaquel
                      </th>
                      <th scope="col">Activo</th>
                      <th scope="col" colSpan={2}>Estantes</th>
                      <th scope="col">Editar</th>
                      <th scope="col">Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {anaqueles.map((anaquel) => {
                      return (
                        <tr key={anaquel}>
                          <td>{anaquel.anaquel ? anaquel.anaquel : ""}</td>
                          <td>{anaquel.activo ? "SI" : "NO"} </td>
                          <td>{anaquel.estantes ? anaquel.estantes.length : ""}</td>
                          <td>
                            <button
                              className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                              type="button"
                              hidden={usuario.authorities.includes("OP_ALMACEN_EDITAR") ? false : true}
                              onClick={() => props.history.push(`/estantes/${anaquel.idCatAnaquel}`)}>
                              Detalles
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-primary-purple-umo w-md waves-effect waves-light mr-4"
                              type="button"
                              hidden={usuario.authorities.includes("OP_ALMACEN_EDITAR") ? false : true}
                              onClick={() => {
                                setUISweetAlert({
                                  ...UISweetAlert,
                                  confirm_alert_anaquel: true,
                                  editar: true,
                                });
                                setAnaquelNuevoEditar(anaquel);
                              }}>
                              Editar
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-primary-purple-umo w-md waves-effect waves-light mr-4"
                              type="button"
                              hidden={usuario.authorities.includes("OP_ALMACEN_BORRAR") ? false : true}
                              onClick={() => {
                                setUISweetAlert({
                                  ...UISweetAlert,
                                  confirm_alert_anaquel_eliminar: true,
                                });
                                setAnaquelNuevoEditar(anaquel);
                              }}>
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_anaquel ? (
        <SweetAlert
          title={UISweetAlert.editar ? "Editar Anaquel" : "Nuevo Anaquel"}
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => checkUploadAnaquel()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_anaquel: false,
              editar: false,
            });
            setAnaquelNuevoEditar(cleanAnaquel);
          }}>
          <div className="mt-2">
            <FormControlLabel
              control={
                <Switch
                  disabled={!UISweetAlert.editar}
                  checked={anaquelNuevoEditar.activo}
                  onChange={onChangeAnaquel}
                  name="activo"
                  color="primary"
                />
              }
              label="Activo"
            />
          </div>
          <div className="mt-2">
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">
                Nombre<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  onChange={onChangeAnaquel}
                  name="anaquel"
                  value={anaquelNuevoEditar.anaquel}
                />
                {(anaquelNuevoEditar.anaquel === "" || !anaquelNuevoEditar.anaquel) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_anaquel_eliminar ? (
        <SweetAlert
          title="¿Desea eliminar el anaquel?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarAnaquel()}
          onCancel={() => {
            setAnaquelNuevoEditar(cleanAnaquel);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_anaquel_eliminar: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
