import {
    LISTAR_CATTIPOVENTAMOSTRADOR
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_CATTIPOVENTAMOSTRADOR: 
            return{
                ...state,
                tipoVentaMostrador: action.payload
            }
        default:
            return state
    }
        
}