import React, {useReducer} from 'react'

import CatTipoRecepcionContext from './CatTipoRecepcionContext'
import CatTipoRecepcionReducer from './CatTipoRecepcionReducer'

import {
    LISTAR_TIPORECEPCIONES
} from '../../types'

import clienteAxios from '../../config/axios'


export default function CatTipoRecepcionState (props) {

    const initialState = { 
        tiposRecepecion:[],
    }

    const [state, dispatch] = useReducer(CatTipoRecepcionReducer,initialState)

    const listarTipoRecepcion = async () => {
        const res = await clienteAxios.get("/tiporecepciones/activos", {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })

        dispatch({
            type: LISTAR_TIPORECEPCIONES,
            payload: res.data
        })
    }

    return (

        <CatTipoRecepcionContext.Provider value={{
            tiposRecepecion: state.tiposRecepecion,
            listarTipoRecepcion,
        }}>
            {props.children}
        </CatTipoRecepcionContext.Provider>

    )


}