import React, {useState, useEffect, useCallback, useMemo, useRef, useContext} from "react";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SweetAlert from "react-bootstrap-sweetalert";
import TableComponent from "../TablaPaginada/index";
import InventarioContext from "../../context/Inventario/InventarioContext";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import CatTipoProductoContext from "../../context/CatTipoProducto/CatTipoProductoContext";
import TrasladoProductoInventarioContext from "../../context/TrasladoProductoInventario/TrasladoProductoInventarioContext";
import AuthContext from "../../context/Auth/AuthContext";
import CatEstatusTrasladoContext from "../../context/CatEstatusTraslado/CatEstatusTrasladoContext";
import formatDateWithoutHour from "../../utils/formatDateWithoutHour";

export default function ListadoTrasladoProductoInventario(props) {
    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_aprobar_rechazar: false,
        confirm_alert_cancelar_traslado: false,
        confirm_alert_decision: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [rowSeleccionada, setRowSeleccionada] = useState(null);
    const fetchIdRef = useRef(0);
    const [trasladoSeleccionado, setTrasladoSeleccionado] = useState(null);
    const [inventarioUbicacionSeleccionada, setInventarioUbicacionSeleccionada] = useState(null);
    const [toggleSearchBy, setToggleSearchBy] = useState(false);
    const [anaqueles, setAnaqueles] = useState([]);

    const tipoProductoCtx = useContext(CatTipoProductoContext);
    const {tiposProductos, listarProductoTiposProductos} = tipoProductoCtx;

    const almacenesCtx = useContext(AlmacenesContext);
    const {almacenes, listarAlmacenes} = almacenesCtx;

    const catEstTrasCtx = useContext(CatEstatusTrasladoContext);
    const {catEstatusTraslado, listarCatEstatusTraslado} = catEstTrasCtx;

    const inventarioCtx = useContext(InventarioContext);
    const {cleanInventarioSeleccionado} = inventarioCtx;

    const trasladoCtx = useContext(TrasladoProductoInventarioContext);
    const {
        trasladosProductoInventario,
        listarTrasladosProductosInventario,
        filtrosTraslados,
        setFiltrosTraslados,
        uploadActualizarTraslado,
    } = trasladoCtx;

    const authContext = useContext(AuthContext);
    const {usuario} = authContext;    
    
    const administrador =
        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
        usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
        usuario.authorities.includes("ROLE_DIRECCION") ||
        usuario.authorities.includes("ROLE_CONTABILIDAD");

    const AlmacenCMZ = usuario.authorities.includes("ROLE_ADMIN_ALMACEN");
    const perfilCompras = usuario.authorities.includes("ROLE_COMPRAS");    

    const buscarPorFiltros = async (e) => {
        e.preventDefault();
        fetchData({pageSize: 10, pageIndex: 0, byBusquedaFiltros: true});
    };

    const onChange = (e) => {
        setFiltrosTraslados({
            ...filtrosTraslados,
            [e.target.name]: e.target.value,
        });
    };

    const limpiarFiltros = () => {
        setFiltrosTraslados({
            folioTraslado: "",
            idCatTipoProducto: "",
            numIdentificacion: "",
            codigoEAN: "",
            nombreComercial: "",
            lote: "",
            idCatAlmacenOrigen: "",
            idCatAlmacenDestino: "",
            idCatEstatusTraslado: "",
        });
    };

    const toggleSearch = () => {
        limpiarFiltros();
        setToggleSearchBy(!toggleSearchBy);
    };

    const handdleCheckedTraslado = useCallback(
        (row) => {
            if (rowSeleccionada === null) {
                setTrasladoSeleccionado(row.original);
                setRowSeleccionada(row.id);
            } else if (rowSeleccionada === row.id) {
                setRowSeleccionada(null);
                setTrasladoSeleccionado(null);
            }
        },
        [rowSeleccionada]
    );

    const fetchData = async ({pageSize, pageIndex}) => {
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: false,
            });
        }
    };

    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);
            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);
            }
            const response = await listarTrasladosProductosInventario({page, limit});
            const count = response.data.count;
            setRowSeleccionada(null);
            if (count % limit > 0) {
                setPageCount(Math.ceil(count / limit));
            } else {
                setPageCount(count / limit);
            }

            setLoadingData(false);
        } catch (e) {
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "idTraslado",
                Cell: ({row}) => {
                    return (
                        <input
                            disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
                            checked={rowSeleccionada === row.id ? true : false}
                            type="checkbox"
                            autoComplete="off" 
                            onChange={() => handdleCheckedTraslado(row)}
                        />
                    );
                },
                show: true,
                disableFilters: true,
                disableSortBy: true,
            },

            {Header: "Folio", accessor: "folioTraslado", show: true},
            {
                Header: "Estatus",
                accessor: "estatusTraslado",
                Cell: ({row}) => {
                    if (row.values.estatusTraslado[row.values.estatusTraslado.length - 1].catEstatustraslado.idCatEstatusTraslado == 1) {
                        return (
                            <span
                                className="badge badge-info"
                                style={{
                                    height: "1.9rem",
                                    paddingTop: "0.6rem",
                                    border: "1px solid #dfdada",
                                    color: "black",
                                    fontWeight: "bold",
                                    //verde
                                    backgroundColor: "#C1E05C",
                                    borderRadius: "5px",
                                }}>
                {row.values.estatusTraslado[row.values.estatusTraslado.length - 1].catEstatustraslado.estatusTraslado}
              </span>
                        );
                    } else {
                        return (
                            <span
                                className="badge badge-info"
                                style={{
                                    height: "1.9rem",
                                    paddingTop: "0.6rem",
                                    border: "1px solid #dfdada",
                                    color: "white",
                                    fontWeight: "bold",
                                    //morado
                                    backgroundColor: "#602a96",
                                    borderRadius: "5px",
                                }}>
                {row.values.estatusTraslado[row.values.estatusTraslado.length - 1].catEstatustraslado.estatusTraslado}
              </span>
                        );
                    }
                },
                show: true,
            },

            {Header: "Tipo", accessor: "tipoProducto", show: true},
            {Header: "Identificación", accessor: "numIdentificacion", show: true},
            {Header: "Código EAN", accessor: "codigoEAN", show: true},
            {Header: "Descripción", accessor: "nombreComercial", show: true},
            {Header: "Lote", accessor: "lote", show: true},
            {
                Header: "Caducidad",
                accessor: "caducidad",
                Cell: ({row}) => {
                    return row.original.caducidad ? formatDateWithoutHour(row.original.caducidad) : "SIN CADUCIDAD";
                },
                show: true,
            },
            {Header: "Piezas", accessor: "piezas", show: true},
            {Header: "Almacén Origen", accessor: "almacenOrigen", show: true},
            {Header: "Almacén Destino", accessor: "almacenDestino", show: true},

            {
                accessor: "idCatAlmacenOrigen",
                show: false,
                Cell: ({row}) => {
                    return "";
                },
            },
            {
                accessor: "idCatAlmacenDestino",
                show: false,
                Cell: ({row}) => {
                    return "";
                },
            },
        ],
        [rowSeleccionada, handdleCheckedTraslado]
    );

    const confirmCheckDecisionTraslado = () => {
        if (!trasladoSeleccionado) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione un traslado",
            });
            return;
        } else {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_decision: true,
            });
        }
    };

    const confirmAprobarRechazarTraslado = async () => {
        if (!trasladoSeleccionado) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione un traslado",
            });
            return;
        }

        if (
            trasladoSeleccionado.estatusTraslado[trasladoSeleccionado.estatusTraslado.length - 1].catEstatustraslado
                .idCatEstatusTraslado != 1
        ) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "No se puede aceptar o rechazar el traslado",
            });
            return;
        }

        // let trasSelec = trasladosProductoInventario.filter((trsslec) => trsslec.idTraslado == trasladoSeleccionado.idTraslado)[0];
        // if (trasSelec.idResponsableAlmacenOrigen != usuario.idCatUsuario) {
        //   setUISweetAlert({
        //     ...UISweetAlert,
        //     error_dlg: true,
        //     dynamic_title: "Validación",
        //     dynamic_description: "Solo el responsable del almacén puede aprobar o rechazar el traslado del producto.",
        //   });
        //   return;
        // }

        calcularPiezasRestantes();
        obtenerAnaqueles(trasladoSeleccionado.idCatAlmacenDestino);
        trasladoSeleccionado.idEstatusNuevo = 3;
        setTrasladoSeleccionado(trasladoSeleccionado);

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_aprobar_rechazar: true,
        });
    };

    const obtenerAnaqueles = async (idCatAlmacen) => {
        if (idCatAlmacen == "") {
            setAnaqueles([]);
        } else {
            const almacen = almacenes.filter((almc) => almc.idCatAlmacen == idCatAlmacen)[0];

            if (almacen.anaqueles != null) {
                setAnaqueles(almacen.anaqueles);
            } else {
                setAnaqueles([]);
            }
        }
        refreshModalAprobacionRechazo();
    };

    const onChangeTraslado = async (e) => {
        let value = e.target.value;

        if (e.target.name == "aprobado") {
            value = !trasladoSeleccionado.aprobado;
            setTrasladoSeleccionado({
                ...trasladoSeleccionado,
                motivo: "",
                ubicaciones: [],
                idEstatusNuevo: value ? 2 : 3,
                [e.target.name]: value,
            });
        } else {
            setTrasladoSeleccionado({
                ...trasladoSeleccionado,
                [e.target.name]: value,
            });
        }
        refreshModalAprobacionRechazo();
    };

    const nuevaUbicacion = async () => {
        if (!trasladoSeleccionado.ubicaciones) {
            trasladoSeleccionado.ubicaciones = [];
        }

        let nuevaUbicacionObj = {
            idCatAnaquel: "",
            idCatEstante: "",
            idCatUbicacion: "",
            estantes: [],
            ubicaciones: [],
            piezas: 0,
        };
        trasladoSeleccionado.ubicaciones = trasladoSeleccionado.ubicaciones.concat(nuevaUbicacionObj);
        setTrasladoSeleccionado(trasladoSeleccionado);

        refreshModalAprobacionRechazo();
    };

    const changePiezasUbicacion = async (e, ubicacion) => {
        let value = e.target.value;

        if (value < 0) {
            value = value * -1;
        }
        ubicacion.piezas = value;
        setTrasladoSeleccionado(trasladoSeleccionado);
        refreshModalAprobacionRechazo();
        calcularPiezasRestantes(ubicacion);
    };

    const eliminarInventarioUbicacion = async () => {
        trasladoSeleccionado.ubicaciones = trasladoSeleccionado.ubicaciones.filter((ubic) => ubic != inventarioUbicacionSeleccionada);
        setTrasladoSeleccionado(trasladoSeleccionado);
        calcularPiezasRestantes();
        refreshModalAprobacionRechazo();
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_eliminar_inventarioubicacion: false,
        });
    };

    const calcularPiezasRestantes = async (ubicacion) => {
        let piezasRestantes = trasladoSeleccionado.piezas;
        if (trasladoSeleccionado.ubicaciones) {
            trasladoSeleccionado.ubicaciones.map((ubc) => {
                if (ubc.piezas > 0) {
                    piezasRestantes -= ubc.piezas;
                }
            });
        }

        if (piezasRestantes < 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: `La suma de piezas ubicadas es mayor al número de piezas totales`,
            });
            piezasRestantes += Number(ubicacion.piezas);
            ubicacion.piezas = 0;
        }

        trasladoSeleccionado.piezasRestantes = piezasRestantes;
        setTrasladoSeleccionado(trasladoSeleccionado);
    };

    const changeAnaquel = async (e, ubicacion) => {
        let value = e.target.value;
        ubicacion.idCatAnaquel = value;
        ubicacion.idCatEstante = "";
        ubicacion.idCatUbicacion = "";

        if (value == "") {
            ubicacion.estantes = [];
        } else {
            const anaquel = anaqueles.filter((anaquel) => anaquel.idCatAnaquel == value)[0];
            if (anaquel.estantes != null) {
                ubicacion.estantes = anaquel.estantes;
            } else {
                ubicacion.estantes = [];
            }
        }

        setTrasladoSeleccionado(trasladoSeleccionado);
        refreshModalAprobacionRechazo();
    };

    const changeEstante = async (e, ubicacion) => {
        let value = e.target.value;
        ubicacion.idCatEstante = value;
        ubicacion.idCatUbicacion = "";

        if (value == "") {
            ubicacion.ubicaciones = [];
        } else {
            const estante = ubicacion.estantes.filter((estante) => estante.idCatEstante == value)[0];
            if (estante.ubicaciones != null) {
                ubicacion.ubicaciones = estante.ubicaciones;
            } else {
                ubicacion.ubicaciones = [];
            }
        }

        setTrasladoSeleccionado(trasladoSeleccionado);
        refreshModalAprobacionRechazo();
    };

    const changeUbicacion = async (e, ubicacion) => {
        let value = e.target.value;

        let error = false;

        trasladoSeleccionado.ubicaciones.map((ubc) => {
            if (value != "" && ubc != ubicacion && ubc.idCatUbicacion == value && !error) {
                error = true;
                value = "";
            }
        });

        ubicacion.idCatUbicacion = value;
        setTrasladoSeleccionado(trasladoSeleccionado);
        refreshModalAprobacionRechazo();

        if (error) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "No se pueden repetir las ubicaciones.",
            });
        }
    };

    const refreshModalAprobacionRechazo = async () => {
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_aprobar_rechazar: false,
        });
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_aprobar_rechazar: true,
        });
    };

    const checkUploadAprobarRechazarTraslado = async () => {
        let errorUploadAceptarRechazar = false;

        if (!trasladoSeleccionado.aprobado) {
            if (!trasladoSeleccionado.motivo && !errorUploadAceptarRechazar) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: `Por favor, introduzca el motivo de rechazo`,
                });
                errorUploadAceptarRechazar = true;
                return;
            }
        } else 
        {

            if (trasladoSeleccionado.ubicaciones && trasladoSeleccionado.ubicaciones.length > 0) {
                trasladoSeleccionado.ubicaciones.map((ubicacion, index) => 
                {
                    if (!ubicacion.idCatAnaquel && !errorUploadAceptarRechazar) {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: `Por favor, introduzca el anaquel para al renglon ${index + 1}`,
                        });
                        errorUploadAceptarRechazar = true;
                        return;
                    }

                    if (!ubicacion.idCatEstante && !errorUploadAceptarRechazar) {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: `Por favor, introduzca el estante para al renglon ${index + 1}`,
                        });
                        errorUploadAceptarRechazar = true;
                        return;
                    }

                    if (!ubicacion.idCatUbicacion && !errorUploadAceptarRechazar) {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: `Por favor, introduzca la ubicación para al renglon ${index + 1}`,
                        });
                        errorUploadAceptarRechazar = true;
                        return;
                    }

                    if ((!ubicacion.piezas || ubicacion.piezas <= 0) && !errorUploadAceptarRechazar) {
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: true,
                            dynamic_title: "Fallo",
                            dynamic_description: `Por favor, introduzca el número de piezas para al renglon ${index + 1}`,
                        });
                        errorUploadAceptarRechazar = true;
                        return;
                    }
                });
            }

            if (trasladoSeleccionado.piezasRestantes > 0 && !errorUploadAceptarRechazar) {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: `No se han asignado todas las piezas del traslado a algún anaquel`,
                });
                errorUploadAceptarRechazar = true;
                return;
            }
        }

        if (!errorUploadAceptarRechazar) {
            setLoading(true);
            const res = await uploadActualizarTraslado(trasladoSeleccionado);
            if (res.data.error) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else if (res) {
                cleanTrasladoSeleccionado();

                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_alert_aprobar_rechazar: false,
                    dynamic_description: "Se guardaron los cambios de manera exitosa.",
                });

                fetchData({pageSize: 10, pageIndex: 0, byBusquedaFiltros: true});
            }
        }
    };

    const cleanTrasladoSeleccionado = async () => {
        delete trasladoSeleccionado.aprobado;
        delete trasladoSeleccionado.ubicaciones;
        delete trasladoSeleccionado.motivo;
        delete trasladoSeleccionado.idEstatusNuevo;
        setTrasladoSeleccionado(trasladoSeleccionado);
    };

    const confirmCancelarTraslado = async () => {
        if (!trasladoSeleccionado) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione un traslado",
            });
            return;
        }

        if (
            trasladoSeleccionado.estatusTraslado[trasladoSeleccionado.estatusTraslado.length - 1].catEstatustraslado
                .idCatEstatusTraslado != 1
        ) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "No se puede cancelar el traslado",
            });
            return;
        }

        let trasladoAux = trasladosProductoInventario.filter((tras) => tras.idTraslado == trasladoSeleccionado.idTraslado)[0];

       /* if (trasladoAux.idResponsableAlmacenDestino != usuario.idCatUsuario) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Solo el usuario responsable del almacén de destino puede cancelar el traslado",
            });
            return;
        }*/

        trasladoSeleccionado.idEstatusNuevo = 4;
        setTrasladoSeleccionado(trasladoSeleccionado);

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_cancelar_traslado: true,
        });
    };

    const onChangeCancelacion = async (e) => {
        let value = e.target.value;
        setTrasladoSeleccionado({
            ...trasladoSeleccionado,
            [e.target.name]: value,
        });
    };

    const checkUploadCancelacionTraslado = async () => {
        let errorCancelacionTraslado = false;

        if (!trasladoSeleccionado.motivo) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: `Por favor, introduzca el motivo de la cancelación`,
            });
            errorCancelacionTraslado = true;
            return;
        }

        if (!errorCancelacionTraslado) {
            setLoading(true);
            const res = await uploadActualizarTraslado(trasladoSeleccionado);
            if (res.data.error) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else if (res) {
                cleanTrasladoSeleccionado();

                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_alert_cancelar_traslado: false,
                    dynamic_description: "Se guardaron los cambios de manera exitosa.",
                });

                fetchData({pageSize: 10, pageIndex: 0, byBusquedaFiltros: true});
            }
        }
    };

    useEffect(() => {
        listarProductoTiposProductos();
        listarCatEstatusTraslado();
        listarAlmacenes(false,administrador);
        cleanInventarioSeleccionado();
    }, []);

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Traslado entre Almacenes</h3>

            <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mb-4 mt-6 mr-6 hover:text-white"
                type="button"
                onClick={toggleSearch}>
                {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
            </button>

            <Card hidden={!toggleSearchBy}>
                <form onSubmit={(e) => buscarPorFiltros(e)}>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                                <Row
                                    className="form-group"
                                    style={{
                                        marginBottom: "0",
                                    }}>
                                    <Col sm={10} className="flex col-sm-10">
                                        <input
                                            className="form-control mr-2.5"
                                            type="text"
                                            autoComplete="off" 
                                            name="folioTraslado"
                                            placeholder="Escribe tu folio"
                                            onChange={onChange}
                                            value={filtrosTraslados.folioTraslado}
                                        />
                                        <button
                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                            style={{
                                                marginRight: "2.5rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            type="submit">
                                            Buscar
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </form>
            </Card>

            <Card hidden={toggleSearchBy}>
                <CardBody>
                    <form onSubmit={(e) => buscarPorFiltros(e)}>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Tipo Producto</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatTipoProducto"
                                            autoComplete="off" 
                                            onChange={onChange}
                                            value={filtrosTraslados.idCatTipoProducto}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {tiposProductos.map((tipoProducto) => {
                                                return (
                                                    <>
                                                        <option value={tipoProducto.idCatTipoProducto}>{tipoProducto.tipoProducto}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Identificación</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off" 
                                            name="numIdentificacion"
                                            onChange={onChange}
                                            value={filtrosTraslados.numIdentificacion}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Código EAN</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off" 
                                            name="codigoEAN"
                                            onChange={onChange}
                                            value={filtrosTraslados.codigoEAN}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Descripción</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off" 
                                            name="nombreComercial"
                                            onChange={onChange}
                                            value={filtrosTraslados.nombreComercial}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Lote</label>
                                    <Col sm={7}>
                                        <input className="form-control" autoComplete="off"  name="lote" onChange={onChange} value={filtrosTraslados.lote}/>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Almacén de Origen</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            autoComplete="off" 
                                            name="idCatAlmacenOrigen"
                                            onChange={onChange}
                                            value={filtrosTraslados.idCatAlmacenOrigen}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {almacenes.map((almacen) => {
                                                return (
                                                    <>
                                                        <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Almacén de Destino</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatAlmacenDestino"
                                            autoComplete="off" 
                                            onChange={onChange}
                                            value={filtrosTraslados.idCatAlmacenDestino}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {almacenes.map((almacen) => {
                                                return (
                                                    <>
                                                        <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Estatus Traslado</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatEstatusTraslado"
                                            autoComplete="off" 
                                            onChange={onChange}
                                            value={filtrosTraslados.idCatEstatusTraslado}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {catEstatusTraslado.map((estatus) => {
                                                return (
                                                    <>
                                                        <option value={estatus.idCatEstatusTraslado}>{estatus.estatusTraslado}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4" type="submit">
                                    Aplicar Filtros
                                </button>
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick={limpiarFiltros}>
                                    Limpiar Filtros
                                </button>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>

            <Card className="mt-4">
                <CardBody>
                    <Row>
                        <Col>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                type="button"
                                hidden={
                                    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                    usuario.authorities.includes("ROLE_ALMACEN") ||
                                    usuario.authorities.includes("ROLE_CONTABILIDAD")
                                        ? false
                                        : true
                                }
                                onClick={() => confirmCheckDecisionTraslado()}>
                                Ver Decisión
                            </button>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                type="button"
                                hidden={usuario.authorities.includes("ROLE_ADMIN_SISTEMA") || usuario.authorities.includes("ROLE_ADMIN_ALMACEN") ? false : true}
                                onClick={() => confirmAprobarRechazarTraslado()}>
                                Aprobar / Cancelar
                            </button>

                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                type="button"
                                hidden={usuario.authorities.includes("ROLE_ADMIN_SISTEMA") || usuario.authorities.includes("ROLE_ADMIN_ALMACEN") ? false : true}
                                onClick={() => confirmCancelarTraslado()}>
                                Cancelar
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TableComponent
                                pageCount={pageCount}
                                fetchData={fetchData}
                                columns={columns}
                                loading={loadingData}
                                data={
                                    trasladosProductoInventario.filter((producto) => 
                                    {
                                        let UuarioIdLogueado = usuario.idCatUsuario
                                        let IdAlmacenOrigentemporal = 0

                                        function EsValido(AlmacenCadena){
                                            let ResponsableId = 0;
                                            let Regreso =  false;
                                    
                                            almacenes.map((almacen) => {

                                                if (almacen.almacen == AlmacenCadena){
                                                    IdAlmacenOrigentemporal = almacen.idCatAlmacen;
                                                    ResponsableId =almacen.idResponsable

                                                     if (!administrador &&  AlmacenCMZ && ResponsableId == producto.idResponsableAlmacenOrigen 
                                                         && UuarioIdLogueado == ResponsableId && IdAlmacenOrigentemporal != 3 ){
                                                        Regreso = true;
                                                     }else{
                                                        if (administrador && IdAlmacenOrigentemporal != 1  ){
                                                            Regreso = true;
                                                         }else{
                                                            if (perfilCompras && IdAlmacenOrigentemporal == 1  ){
                                                                Regreso = true;
                                                             }    
                                                         }  
                                                     } 
                                                }
                                            })
                                            return Regreso;
                                        }
                                        if(administrador){
                                            return true;
                                        }else{
                                            let TransferenciaValida = EsValido(producto.almacenOrigen)

                                            if (TransferenciaValida) 
                                            {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                    })
                                    
                                }
                                indexOfPage={controlledPageIndex}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {UISweetAlert.confirm_alert_aprobar_rechazar ? (
                <SweetAlert
                    title="Aprobación / Rechazo de Traslado"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => checkUploadAprobarRechazarTraslado()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_aprobar_rechazar: false,
                        });
                        cleanTrasladoSeleccionado();
                    }}
                    style={{
                        width: "50%",
                    }}>
                    <div className="mt-2">
                        <FormControlLabel
                            control={
                                <Switch checked={trasladoSeleccionado.aprobado} onChange={onChangeTraslado} name="aprobado" color="primary"/>
                            }
                            label={trasladoSeleccionado.aprobado ? "Aprobado" : "Rechazado"}
                        />
                        {trasladoSeleccionado.aprobado ? (
                            <>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Almacén Destino</label>
                                    <Col sm={7}>
                                        <input type="text" autoComplete="off"  className="form-control" disabled={true} value={trasladoSeleccionado.almacenDestino}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6} lg={12} md={12}>
                                        <Row className="form-group">
                                            <label className="col-sm-4 col-form-label">Piezas</label>
                                            <Col sm={7}>
                                                <input type="text" autoComplete="off"  className="form-control" disabled={true} value={trasladoSeleccionado.piezas}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl={6} lg={12} md={12}>
                                        <Row className="form-group">
                                            <label className="col-sm-4 col-form-label">Piezas Restantes</label>
                                            <Col sm={7}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    disabled={true}
                                                    value={trasladoSeleccionado.piezasRestantes ? trasladoSeleccionado.piezasRestantes : "0"}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <button
                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4"
                                            type="button"
                                            onClick={nuevaUbicacion}>
                                            Nueva Ubicación
                                        </button>
                                    </Col>
                                </Row>
                                <table className="table table-hover table-centered table-nowrap mb-0">
                                    <thead>
                                    <tr>
                                        <th scope="col">Anaquel</th>
                                        <th scope="col">Estante</th>
                                        <th scope="col">Ubicación</th>
                                        <th scope="col">Piezas</th>
                                        <th scope="col">Eliminar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {trasladoSeleccionado.ubicaciones &&
                                        trasladoSeleccionado.ubicaciones.map((ubicacion) => {
                                            return (
                                                <tr key={ubicacion}>
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            name="idCatAnaquel"
                                                            value={ubicacion.idCatAnaquel}
                                                            autoComplete="off" 
                                                            onChange={(e) => changeAnaquel(e, ubicacion)}>
                                                            <option selected value="">
                                                                Seleccione una opción
                                                            </option>
                                                            {anaqueles.map((anaquel) => {
                                                                return (
                                                                    <>
                                                                        <option value={anaquel.idCatAnaquel}>{anaquel.anaquel}</option>
                                                                    </>
                                                                );
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            name="idCatEstante"
                                                            autoComplete="off" 
                                                            value={ubicacion.idCatEstante}
                                                            disabled={!ubicacion.idCatAnaquel}
                                                            onChange={(e) => changeEstante(e, ubicacion)}>
                                                            <option selected value="">
                                                                Seleccione una opción
                                                            </option>
                                                            {ubicacion.estantes.map((estante) => {
                                                                return (
                                                                    <>
                                                                        <option value={estante.idCatEstante}>{estante.estante}</option>
                                                                    </>
                                                                );
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="form-control"
                                                            name="idCatUbicacion"
                                                            value={ubicacion.idCatUbicacion}
                                                            disabled={!ubicacion.idCatEstante}
                                                            autoComplete="off" 
                                                            onChange={(e) => changeUbicacion(e, ubicacion)}>
                                                            <option selected value="">
                                                                Seleccione una opción
                                                            </option>
                                                            {ubicacion.ubicaciones.map((ubc) => {
                                                                return (
                                                                    <>
                                                                        <option value={ubc.idCatUbicacion}>{ubc.ubicacion}</option>
                                                                    </>
                                                                );
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            name="piezas"
                                                            autoComplete="off" 
                                                            value={ubicacion.piezas}
                                                            onChange={(e) => changePiezasUbicacion(e, ubicacion)}
                                                            onWheel={(e) => e.target.blur()}
                                                        />
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                                            type="button"
                                                            onClick={() => {
                                                                setInventarioUbicacionSeleccionada(ubicacion);
                                                                setUISweetAlert({
                                                                    ...UISweetAlert,
                                                                    confirm_alert_eliminar_inventarioubicacion: true,
                                                                });
                                                            }}>
                                                            Eliminar
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Motivo</label>
                                    <Col sm={7}>
                    <textarea
                        type="text"
                        className="form-control"
                        onChange={onChangeTraslado}
                        name="motivo"
                        value={trasladoSeleccionado.motivo}
                    />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_cancelar_traslado ? (
                <SweetAlert
                    title="Cancelación de traslado"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => checkUploadCancelacionTraslado()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_cancelar_traslado: false,
                        });
                        cleanTrasladoSeleccionado();
                    }}
                    style={{
                        width: "40%",
                    }}>
                    <div className="mt-4">
                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Motivo</label>
                            <Col sm={7}>
                <textarea
                    type="text"
                    className="form-control"
                    onChange={onChangeCancelacion}
                    name="motivo"
                    value={trasladoSeleccionado.motivo}
                />
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_decision ? (
                <SweetAlert
                    title="Decisión del traslado"
                    closeOnClickOutside={false}
                    onConfirm={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_decision: false,
                        });
                    }}
                    style={{
                        width: "40%",
                    }}>
                    <div className="mt-4">
                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Motivo</label>
                            <Col sm={7}>
                <textarea
                    disabled={true}
                    type="text"
                    className="form-control"
                    name="motivo"
                    value={trasladoSeleccionado.motivo}
                />
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_eliminar_inventarioubicacion ? (
                <SweetAlert
                    title="¿Desea eliminar la ubicación?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Rechazar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarInventarioUbicacion()}
                    onCancel={() => {
                        setInventarioUbicacionSeleccionada({});
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_eliminar_inventarioubicacion: false,
                        });
                    }}></SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
