import React from "react";
// import { Row, Col } from "reactstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import {Line} from "react-chartjs-2";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function TabGrafica({props, marcador, displayLegend = false}) {
    return (
        <div className="h-screen">
            <Line
                datasetIdKey={marcador.marcador}
                data={{
                    labels: marcador.fechasRegistro,
                    datasets: [
                        {
                            label: marcador.marcador,
                            fill: false,
                            lineTension: 0.5,
                            backgroundColor: marcador.color,
                            borderColor: marcador.color,
                            borderWidth: 2,
                            data: marcador.valores,
                        },
                    ],
                }}
                options={{
                    title: {
                        display: true,
                        text: marcador.marcador,
                        fontSize: 20,
                    },
                    legend: {
                        display: false,
                        position: "right",
                    },
                    responsive: true, // enable responsiveness
                    maintainAspectRatio: false, // fill the entire container
                }}
            />
        </div>
    );
}
