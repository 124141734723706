import React, { useReducer } from "react";

import CatTipoVentaContext from "./CatTipoVentaContext";
import CatTipoVentaReducer from "./CatTipoVentaReducer";

import { LISTAR_CATTIPOVENTA } from "../../types";

import clienteAxios from "../../config/axios";

export default function CatTipoVentaState(props) {
  const initialState = {
    tipoVentas: [],
  };

  const [state, dispatch] = useReducer(CatTipoVentaReducer, initialState);

  const listarTiposVenta = async () => {
    const res = await clienteAxios.get("/tipoventas/activas", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CATTIPOVENTA,
      payload: res.data,
    });
  };

  return (
    <CatTipoVentaContext.Provider
      value={{
        tipoVentas: state.tipoVentas,
        listarTiposVenta,
      }}>
      {props.children}
    </CatTipoVentaContext.Provider>
  );
}
