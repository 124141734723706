import React, { useReducer } from "react";

import ExpedienteMedicoContext from "./ExpedienteMedicoContext";

import ExpedienteMedicoReducer from "./ExpedienteMedicoReducer";

import { SET_CONSENTIMIENTOS_PAGINADOS, SET_DATOS_EXPEDIENTE_MEDICO, SET_ID_EXPEDIENTE_MEDICO } from "../../types";

import clienteAxios from "../../config/axios";

export default function ConsentimientoClienteState(props) {
  const initialState = {
    consentimientosPaginados: [],
    datosExpedienteMedico: null,
    idExpedienteMedico: null,
  };

  const [state, dispatch] = useReducer(ExpedienteMedicoReducer, initialState);

  const setDataConsentimientoCliente = async ({ page, limit, idExpedienteMedico }) => {
    try {
      const response = await clienteAxios.post(
        "expedientemedico/consentimiento/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          idExpedienteMedico: idExpedienteMedico,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const consentimientosModificados = response.data.consentimiento.map((consentimiento) => {
        let fechaFormato = consentimiento.fechaCreacion.split("T")[0];
        return { ...consentimiento, fechaCreacion: fechaFormato };
      });

      dispatch({
        type: SET_CONSENTIMIENTOS_PAGINADOS,
        payload: consentimientosModificados,
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const uploadNuevaImpresion = async (datosImpresion) => {};

  const setIdExpedienteMedico = (idExpedienteMedico) => {
    dispatch({
      type: SET_ID_EXPEDIENTE_MEDICO,
      payload: idExpedienteMedico,
    });
  };

  const uploadDocumentoFirmado = async (datosImpresion) => {
    try {
      const response = await clienteAxios.post(
        "/expedientemedico/agregar/consentimiento",
        {
          ...datosImpresion,
          archivosConsentimiento: [
            {
              inputStream: datosImpresion.inputStream,
              mimeType: datosImpresion.mimeType,
              nombreArchivo: datosImpresion.nombreArchivo,
              tamano: datosImpresion.tamano,
            },
          ],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const exportConsentimientoClienteReport = async (datosImpresion) => {
    try {
      const res = await clienteAxios.post(`/expedientemedico/export/consentimiento`, datosImpresion, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=nota.pdf",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      var file = new Blob([res.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  const setDatosExpedienteMedico = async (idExpedienteMedico, idCatPaciente) => {
    try {
      const response = await clienteAxios.post(
        `/expedientemedico/filtro/json/detalle`,
        { idExpedienteMedico: idExpedienteMedico, idCatPaciente: idCatPaciente },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      dispatch({
        type: SET_DATOS_EXPEDIENTE_MEDICO,
        payload: response.data[0],
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  // const getExpedienteMedicoByPaciente = async (idCatPaciente) => {
  //   const res = await clienteAxios.post(`/expedientemedico/paciente?id=${idCatPaciente}`, {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   });

  //   return res.data;
  // };

  const getExpedienteMedicoByPaciente = async (idCatMedico, idCatPaciente) => {
    const res = await clienteAxios.post(
      "/expedientemedico/detalles",
      { idCatMedico: idCatMedico, idCatPaciente: idCatPaciente },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    return res.data;
  };
  const getExpedienteMedicoMasReciente = async (idCatPaciente) => {
    try {
      const res = await clienteAxios.post(
        "/expedientemedico/ultimo/detalles/",
        { idCatPaciente: idCatPaciente },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return res.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const exportArchivoBack = async (archivoVo) => {
    const mimeType = archivoVo.mimeType ? archivoVo.mimeType : "application/pdf";

    try {
      const res = await clienteAxios.post(`/expedientemedico/export/archivo`, archivoVo, {
        responseType: "arraybuffer",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      var file = new Blob([res.data], { type: mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }
      return error.response;
    }
  };

  const imprimirExpedienteMedico = async (impresionExpedienteVo) => {
    try {
      const res = await clienteAxios.post(`/expedientemedico/export/varios`, impresionExpedienteVo, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=nota.pdf",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      if (res.data.byteLength > 2000 ){
        var file = new Blob([res.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }
      return error.response;
    }
  };

  return (
    <ExpedienteMedicoContext.Provider
      value={{
        consentimientosPaginados: state.consentimientosPaginados,
        datosExpedienteMedico: state.datosExpedienteMedico,
        idExpedienteMedico: state.idExpedienteMedico,

        uploadNuevaImpresion,
        setDataConsentimientoCliente,
        uploadDocumentoFirmado,
        exportConsentimientoClienteReport,
        setDatosExpedienteMedico,
        setIdExpedienteMedico,
        getExpedienteMedicoByPaciente,
        exportArchivoBack,
        imprimirExpedienteMedico,
        getExpedienteMedicoMasReciente,
      }}>
      {props.children}
    </ExpedienteMedicoContext.Provider>
  );
}
