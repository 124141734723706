import React from 'react';
import formatNumber from "../../utils";
import {pieChartOptions} from "../../config/chartOptions";

import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Pie, Doughnut} from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = (props) => {
    const shouldDisplayDataLabels = window.innerWidth > 768; // Adjust the breakpoint as needed
    

    // Extract data and properties from the new data structure
    const data = props.data.datasets[0].data.map(item => item);
    const labels = props.data.labels.map(item => item);
    const backgroundColors = props.data.datasets[0].backgroundColor.map(item => item);
    const borderColors = props.data.datasets[0].data.map(item => item.borderColor);

    const updatedOptions = {
        ...pieChartOptions,
        plugins: {
            ...pieChartOptions.plugins,
            legend: {
                display: true, // Display the legend
                position: shouldDisplayDataLabels ? 'left' : 'top', // Adjust the position as needed
                labels: {
                    usePointStyle: true, // Display colored boxes as point style
                    padding: 10, // Adjust padding as needed
                },
            },
            title: {
                ...pieChartOptions.plugins.title,
                text: props.title,

                padding: {
                    bottom: 10, // Adjust the bottom padding value as needed
                },
                font: {
                    size: 16, // Adjust the font size as needed
                    weight: 'bold',
                },
            },
            datalabels: {
                anchor: 'center', // Position the label in the center of the segment
                align: 'center', // Align the label text to the center
                display: 'auto',
                formatter: (value, context) => {
                    const percentage = ((value * 100) / context.dataset.data.reduce((a, b) => a + b, 0)).toFixed(2);
                    return props.onlyShowPercentage ? `${percentage}%` : (props.requiresMoneyFormat ? `${formatNumber(value)} (${percentage}%)` : `${value} (${percentage}%)`);
                },
                color: 'rgb(40, 40, 40)',
                font: {
                    size: 12,
                    weight: 'bold',
                },
                clip: true,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        const dataset = tooltipItem.dataset;
                        const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                        const percentage = ((value * 100) / total).toFixed(2);
                        return props.onlyShowPercentage ? `${percentage}%` : (props.requiresMoneyFormat ? `${formatNumber(value)} (${percentage}%)` : `${value} (${percentage}%)`);
                    },
                },
            },
        },
        maintainAspectRatio: false, // Allow the chart to dynamically resize
        responsive: true, // Enable responsiveness
    };

    return (
        <>
        {
            props.pie?(
                < Pie options={updatedOptions} ref={props.ref} data={{
                    labels: labels,
                    font:{size:20},
                    datasets: [{
                        data: data,
                        backgroundColor: backgroundColors,
                        borderColor: borderColors,
                        borderWidth: 2,
                    }],
                }} style={{height: '800px'}}/>
            ):(
                < Doughnut options={updatedOptions}  data={{
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: backgroundColors,
                        borderColor: borderColors,
                        borderWidth: 2,
                    }],
                }} style={{height: '800px'}}/>
            )
        }</>
        
    )
}

export default PieChart;