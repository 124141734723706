export const LISTAR_VALE = "LISTAR_VALE";
export const TODOS_VALES = "TODOS_VALES";
export const LISTAR_MEDICOS = "LISTAR_MEDICOS";
export const LISTAR_PACIENTES = "LISTAR_PACIENTES";
export const LISTAR_ASEGURADORAS = "LISTAR_ASEGURADORAS";
export const LISTAR_ESTATUSVALE = "LISTAR_ESTATUSVALE";
export const VALE_SELECCIONADO = "VALE_SELECCIONADO";
export const PROXIMOS_ESTATUS = "PROXIMOS_ESTATUS";
export const DATA_CAMBIO_ESTATUS = "DATA_CAMBIO_ESTATUS";
export const ESTATUS_CANCELADO = "ESTATUS_CANCELADO";
export const LISTAR_USUARIOS = "LISTAR_USUARIOS";
export const LISTAR_TIPOS_VALES = "LISTAR_TIPOS_VALES";
export const LISTAR_CATPRODUCTOS = "LISTAR_CATPRODUCTOS";
export const LISTAR_CATSERVICIOS = "LISTAR_CATSERVICIOS";
export const LISTAR_CATTIPOMEDICO = "LISTAR_CATTIPOMEDICO";
export const NUEVO_VALE = "NUEVO_VALE";
export const LISTAR_PRODUCTOSSERVICIOS = "LISTAR_PRODUCTOSSERVICIOS";
export const LISTAR_CONFIGURACION = "LISTAR_CONFIGURACION";
export const UPLOAD_CAMBIO_ESTATUS = "UPLOAD_CAMBIO_ESTATUS";
export const ERROR_VALE_NUEVO = "ERROR_VALE_NUEVO";
export const ERROR_CAMBIO_ESTATUS = "ERROR_CAMBIO_ESTATUS";
export const CAMBIOS_VALE_SELECCIONADO = "CAMBIOS_VALE_SELECCIONADO";
export const LIMPIAR_VALE_SELECCIONADO = "LIMPIAR_VALE_SELECCIONADO";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SET_VALES_FILTRADOS = "SET_VALES_FILTRADOS";
export const CLEAN_PROXIMOS_ESTATUS = "CLEAN_PROXIMOS_ESTATUS";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";
export const ESTATUS_DEVUELTO = "ESTATUS_DEVUELTO";
export const ESTATUS_CANCELAR_VALE = "ESTATUS_CANCELAR_VALE";
export const ERROR_ESTATUS_CANCELAR_VALE = "ERROR_ESTATUS_CANCELAR_VALE";
export const ESTATUS_DEVOLVER_VALE = "ESTATUS_DEVOLVER_VALE";
export const ERROR_ESTATUS_DEVOLVER_VALE = "ERROR_ESTATUS_DEVOLVER_VALE";
export const CAMBIOS_FILTROS_VALE = "CAMBIOS_FILTROS_VALE";
export const CAMBIOS_FOLIO_FILTRO = "CAMBIOS_FOLIO_FILTRO";
export const CAMBIO_VENTAVALE_COBRO = "CAMBIO_VENTAVALE_COBRO";
export const LISTAR_TIPOCOBROS = "LISTAR_TIPOCOBROS";
export const TOKEN_EXPIRADO = "TOKEN_EXPIRADO";
export const NUEVO_COBRO_VALE = "NUEVO_COBRO_VALE";
export const ERROR_NUEVO_COBRO_VALE = "ERROR_NUEVO_COBRO_VALE";
export const ELIMINAR_COBRO_VALE = "ELIMINAR_COBRO_VALE";
export const ERROR_ELIMINAR_COBRO_VALE = "ERROR_ELIMINAR_COBRO_VALE";
export const ELIMINAR_PROGRESO_COBRO_VALE = "ELIMINAR_PROGRESO_COBRO_VALE";
export const ERROR_ELIMINAR_PROGRESO_COBRO_VALE = "ERROR_ELIMINAR_PROGRESO_COBRO_VALE";
export const CAMBIO_CONCENTRACION_VALE = "CAMBIO_CONCENTRACION_VALE";
export const ERROR_CAMBIO_CONCENTRACION_VALE = "ERROR_CAMBIO_CONCENTRACION_VALE";
export const ACTUALIZAR_VALE_FILTRADO = "ACTUALIZAR_VALE_FILTRADO";
export const ELIMINAR_VALE_PRODUCTOSERVICO = "ELIMINAR_VALE_PRODUCTOSERVICO";
export const ERROR_ELIMINAR_VALE_PRODUCTOSERVICO = "ERROR_ELIMINAR_VALE_PRODUCTOSERVICO";
export const LISTAR_VENTASMOSTRADOR = "LISTAR_VENTASMOSTRADOR";
export const LISTAR_CATTIPOVENTAMOSTRADOR = "LISTAR_CATTIPOVENTAMOSTRADOR";
export const LISTAR_PRODUCTOS = "LISTAR_PRODUCTOS";
export const OBTENER_INVETARIOS_PRODUCTOS = "OBTENER_INVETARIOS_PRODUCTOS";
export const CAMBIOS_FILTRO_VENTA_MOSTRADOR = "CAMBIOS_FILTRO_VENTA_MOSTRADOR";
export const LISTAR_ESTATUSVENTASMOSTRADOR = "LISTAR_ESTATUSVENTASMOSTRADOR";
export const LISTAR_VENTASMOSTRADOR_FILTRADO = "LISTAR_VENTASMOSTRADOR_FILTRADO";
export const CREAR_NUEVA_VENTA_MOSTRADOR = "CREAR_NUEVA_VENTA_MOSTRADOR";
export const ERROR_CREAR_NUEVA_VENTA_MOSTRADOR = "ERROR_CREAR_NUEVA_VENTA_MOSTRADOR";
export const VENTA_MOSTRADOR_SELECCIONADO = "VENTA_MOSTRADOR_SELECCIONADO";
export const ERROR_VENTA_MOSTRADOR_SELECCIONADO = "ERROR_VENTA_MOSTRADOR_SELECCIONADO";
export const LIMPIAR_VENTA_MOSTRADOR_SELECCIONADO = "LIMPIAR_VENTA_MOSTRADOR_SELECCIONADO";
export const CANCELAR_VENTA = "CANCELAR_VENTA";
export const ERROR_CANCELAR_VENTA = "ERROR_CANCELAR_VENTA";
export const ACTUALIZAR_ESTATUS_VENTAMOSTRADOR = "ACTUALIZAR_ESTATUS_VENTAMOSTRADOR";
export const ERROR_ACTUALIZAR_ESTATUS_VENTAMOSTRADOR = "ERROR_ACTUALIZAR_ESTATUS_VENTAMOSTRADOR";
export const NUEVO_COBRO_VENTAMOSTRADOR = "NUEVO_COBRO_VENTAMOSTRADOR";
export const ERROR_NUEVO_COBRO_VENTAMOSTRADOR = "ERROR_NUEVO_COBRO_VENTAMOSTRADOR";
export const ELIMINAR_COBRO_VENTAMOSTRADOR = "ELIMINAR_COBRO_VENTAMOSTRADOR";
export const ERROR_ELIMINAR_COBRO_VENTAMOSTRADOR = "ERROR_ELIMINAR_COBRO_VENTAMOSTRADOR";
export const ESTATUS_COBRO = "ESTATUS_COBRO";
export const MODIFICAR_VALE_PRODUCTOSERVICO = "MODIFICAR_VALE_PRODUCTOSERVICO";
export const ERROR_MODIFICAR_VALE_PRODUCTOSERVICO = "ERROR_MODIFICAR_VALE_PRODUCTOSERVICO";
export const CAMBIOS_FILTRO_SOLICITUDES_COMPRAS = "CAMBIOS_FILTRO_SOLICITUDES_COMPRAS";
export const LIMPIAR_SOLICITUD_COMPRA_SELECCIONADA = "LIMPIAR_SOLICITUD_COMPRA_SELECCIONADA";
export const LISTAR_CATTIPOSOLICITUDCOMPRAS = "LISTAR_CATTIPOSOLICITUDCOMPRAS";
export const LISTAR_CATTIPOCOMPRAS = "LISTAR_CATTIPOCOMPRAS";
export const LISTAR_CATTIPOORDENCOMPRAS = "LISTAR_CATTIPOORDENCOMPRAS";
export const LISTAR_ESTATUSSOLICITUDCOMPRAS = "LISTAR_ESTATUSSOLICITUDCOMPRAS";
export const LISTAR_CATTIPOPRODCUTOS = "LISTAR_CATTIPOPRODCUTOS";
export const NUEVA_SOLICITUD_COMPRA = "NUEVA_SOLICITUD_COMPRA";
export const LISTAR_PROVEEDORES = "LISTAR_PROVEEDORES";
export const LISTAR_SOLICITUDESCOMPRA_FILTRADO = "LISTAR_SOLICITUDESCOMPRA_FILTRADO";
export const CREAR_NUEVA_SOLICITUDCOMPRA = "CREAR_NUEVA_SOLICITUDCOMPRA";
export const ERROR_CREAR_NUEVA_SOLICITUDCOMPRA = "ERROR_CREAR_NUEVA_SOLICITUDCOMPRA";
export const SET_USUARIO_EDITAR = "SET_USUARIO_EDITAR";
export const LISTAR_ROLES = "LISTAR_ROLES";
export const EDITAR_USUARIO = "EDITAR_USUARIO";
export const ERROR_EDITAR_USUARIO = "ERROR_EDITAR_USUARIO";
export const SET_NUEVO_USUARIO = "SET_NUEVO_USUARIO";
export const NUEVO_USUARIO = "NUEVO_USUARIO";
export const ERROR_NUEVO_USUARIO = "ERROR_NUEVO_USUARIO";
export const REINICIARPASSWORD_USUARIO = "REINICIARPASSWORD_USUARIO";
export const ERROR_REINICIARPASSWORD_USUARIO = "ERROR_REINICIARPASSWORD_USUARIO";
export const LISTAR_DEVOLUCIONESCANCELACIONES = "LISTAR_DEVOLUCIONESCANCELACIONES";
export const SET_FILTROS_RECEPCIONGENERICA = "SET_FILTROS_RECEPCIONGENERICA";
export const SET_RECEPCIONGENERICAPRODUCTO = "SET_RECEPCIONGENERICAPRODUCTO";
export const FILTRO_REQUERIMIENTOSCOMPRASPENDIENTES = "FILTRO_REQUERIMIENTOSCOMPRASPENDIENTES";
export const LISTAR_REQUERIMIENTOSCOMPRAPENDIENTES_FILTRADO = "LISTAR_REQUERIMIENTOSCOMPRAPENDIENTES_FILTRADO";
export const LISTAR_TIPORECEPCIONES = "LISTAR_TIPORECEPCIONES";
export const NUEVA_SOLICITUD_COMPRA_PENDIENTE = "NUEVA_SOLICITUD_COMPRA_PENDIENTE";
export const SOLICITUD_COMPRA_SELECCIONADA = "SOLICITUD_COMPRA_SELECCIONADA";
export const SOLICITUD_COMPRA_SHOWEDIT = "SOLICITUD_COMPRA_SHOWEDIT";
export const ERROR_SOLICITUD_COMPRA_SELECCIONADA = "ERROR_SOLICITUD_COMPRA_SELECCIONADA";
export const UPLOAD_SOLICITUD_COMPRA = "UPLOAD_SOLICITUD_COMPRA";
export const ERROR_UPLOAD_SOLICITUD_COMPRA = "ERROR_UPLOAD_SOLICITUD_COMPRA";
export const FILTRO_ORDENESCOMPRA = "FILTRO_ORDENESCOMPRA";
export const LISTAR_ORDENESCOMPRA = "LISTAR_ORDENESCOMPRA";
export const ORDEN_COMPRA_SELECCIONADA = "ORDEN_COMPRA_SELECCIONADA";
export const ERROR_ORDEN_COMPRA_SELECCIONADA = "ERROR_ORDEN_COMPRA_SELECCIONADA";
export const LISTAR_ESTATUSORDENCOMPRAS = "LISTAR_ESTATUSORDENCOMPRAS";
export const ORDEN_COMPRA_SHOWEDIT = "ORDEN_COMPRA_SHOWEDIT";
export const UPLOAD_ORDEN_COMPRA = "UPLOAD_ORDEN_COMPRA";
export const ERROR_UPLOAD_ORDEN_COMPRA = "ERROR_UPLOAD_ORDEN_COMPRA";
export const SOLICITUDES_ORDENESCOMPRA_PROVEEDOR = "SOLICITUDES_ORDENESCOMPRA_PROVEEDOR";
export const ERROR_SOLICITUDES_ORDENESCOMPRA_PROVEEDOR = "ERROR_SOLICITUDES_ORDENESCOMPRA_PROVEEDOR";
export const LISTAR_ALMACENES = "LISTAR_ALMACENES";
export const NUEVA_ORDEN_COMPRA = "NUEVA_ORDEN_COMPRA";
export const ERROR_NUEVA_ORDEN_COMPRA = "ERROR_NUEVA_ORDEN_COMPRA";
export const CANCELAR_SOLICITUD_COMPRA = "CANCELAR_SOLICITUD_COMPRA";
export const CANCELAR_ORDEN_COMPRA = "CANCELAR_ORDEN_COMPRA";
export const FILTRO_CONSULTACOMPRASPRODUCTO = "FILTRO_CONSULTACOMPRASPRODUCTO";
export const LISTAR_CONSULTACOMPRASPRODUCTO = "LISTAR_CONSULTACOMPRASPRODUCTO";
export const LISTAR_CATLABORATORIOS = "LISTAR_CATLABORATORIOS";
export const LISTAR_NUMIDENTIFICACION = "LISTAR_NUMIDENTIFICACION";
export const LISTAR_PRINCIPIOSACTIVOS = "LISTAR_PRINCIPIOSACTIVOS";
export const LISTAR_NOMBRECOMERCIALES = "LISTAR_NOMBRECOMERCIALES";
export const FILTRO_REPORTEDESPLAZAMIENTOPRODUCTO = "FILTRO_REPORTEDESPLAZAMIENTOPRODUCTO";
export const LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO = "LISTAR_REPORTEDESPLAZAMIENTOPRODUCTO";
export const LISTAR_CATTIPOVENTA = "LISTAR_CATTIPOVENTA";
export const FILTRO_DEVOLUCIONCANCELACION_VENTA = "FILTRO_DEVOLUCIONCANCELACION_VENTA";
export const RECEPCIONPRODUCTOS_VENTA_SELECCIONADA = "RECEPCIONPRODUCTOS_VENTA_SELECCIONADA";
export const ERROR_RECEPCIONPRODUCTOS_VENTA_SELECCIONADA = "ERROR_RECEPCIONPRODUCTOS_VENTA_SELECCIONADA";
export const LISTAR_NOMBRECOMERCIALES_NUMIDENTIFICACION = "LISTAR_NOMBRECOMERCIALES_NUMIDENTIFICACION";
export const ERROR_UPLOAD_RECEPCCIONPRODUCTOS_VENTA_SELECCIONADA = "ERROR_UPLOAD_RECEPCCIONPRODUCTOS_VENTA_SELECCIONADA";
export const FILTRO_PAGOORDENCOMPRA = "FILTRO_PAGOORDENCOMPRA";
export const LISTAR_PAGOORDENESCOMPRA = "LISTAR_PAGOORDENESCOMPRA";
export const REGISTRAR_ORDENES_PAGADAS = "REGISTRAR_ORDENES_PAGADAS";
export const ERROR_REGISTRAR_ORDENES_PAGADAS = "ERROR_REGISTRAR_ORDENES_PAGADAS";
export const REGISTRAR_ORDENES_ACTUALIZADAS = "REGISTRAR_ORDENES_ACTUALIZADAS";
export const ERROR_REGISTRAR_ORDENES_ACTUALIZADAS = "ERROR_REGISTRAR_ORDENES_ACTUALIZADAS";
export const FILTRO_ESPACIOSATENCION = "FILTRO_ESPACIOSATENCION";
export const LISTAR_ESPACIOSATENCION = "LISTAR_ESPACIOSATENCION";
export const UPLOAD_NUEVO_ESPACIOATENCION = "UPLOAD_NUEVO_ESPACIOATENCION";
export const ERROR_UPLOAD_NUEVO_ESPACIOATENCION = "ERROR_UPLOAD_NUEVO_ESPACIOATENCION";
export const UPLOAD_ESPACIOATENCION = "UPLOAD_ESPACIOATENCION";
export const ERROR_UPLOAD_ESPACIOATENCION = "ERROR_UPLOAD_ESPACIOATENCION";
export const DELETE_ESPACIOATENCION = "DELETE_ESPACIOATENCION";
export const ERROR_DELETE_ESPACIOATENCION = "ERROR_DELETE_ESPACIOATENCION";
export const LISTAR_CITAS = "LISTAR_CITAS";
export const FILTRO_CITAS_ESPACIOS = "FILTRO_CITAS_ESPACIOS";
export const LISTAR_CATTIPOESPACIOS = "LISTAR_CATTIPOESPACIOS";
export const LISTAR_MEDICOS_AGENDA = "LISTAR_MEDICOS_AGENDA";
export const FILTRO_PAGOMEDICO = "FILTRO_PAGOMEDICO";
export const LISTAR_PAGOSMEDICO = "LISTAR_PAGOSMEDICO";
export const ESTATUS_CANCELAR_PAGO = "ESTATUS_CANCELAR_PAGO";
export const ERROR_ESTATUS_CANCELAR_PAGO = "ERROR_ESTATUS_CANCELAR_PAGO";
export const PAGO_MEDICO_SELECCIONADO = "PAGO_MEDICO_SELECCIONADO";
export const LISTAR_MEDICOS_INTERNOS = "LISTAR_MEDICOS_INTERNOS";
export const FILTRO_VALESMEDICO = "FILTRO_VALESMEDICO";
export const LISTAR_VALESMEDICO = "LISTAR_VALESMEDICO";
export const REGISTRAR_NUEVO_PAGO_MEDICO = "REGISTRAR_NUEVO_PAGO_MEDICO";
export const ERROR_REGISTRAR_NUEVO_PAGO_MEDICO = "ERROR_REGISTRAR_NUEVO_PAGO_MEDICO";
export const FILTRO_CORTESCAJA = "FILTRO_CORTESCAJA";
export const LISTAR_CORTESCAJA = "LISTAR_CORTESCAJA";
export const FILTRO_VALESCORTECAJA = "FILTRO_VALESCORTECAJA";
export const FILTRO_VENTAMOSTRADOR_CORTESCAJA = "FILTRO_VENTAMOSTRADOR_CORTESCAJA";
export const FILTRO_ASEGURADORA_CORTESCAJA = "FILTRO_ASEGURADORA_CORTESCAJA";
export const LISTAR_DETALLES_CORTECAJA = "LISTAR_DETALLES_CORTECAJA";
export const ERROR_CERRAR_CORTE_CAJA = "ERROR_CERRAR_CORTE_CAJA";
export const LISTAR_TIPOPAGOS = "LISTAR_TIPOPAGOS";
export const LISTAR_CLIENTES = "LISTAR_CLIENTES";
export const FILTRO_CLIENTES = "FILTRO_CLIENTES";
export const FILTRO_MEDICOS = "FILTRO_MEDICOS";
export const FILTRO_PACIENTES = "FILTRO_PACIENTES";
export const FILTRO_ASEGURADORAS = "FILTRO_ASEGURADORAS";
export const LISTAR_LUGARES = "LISTAR_LUGARES";
export const LISTAR_OCUPACIONES = "LISTAR_OCUPACIONES";
export const LISTAR_ESCOLARIDADES = "LISTAR_ESCOLARIDADES";
export const LISTAR_ESTADOSCIVILES = "LISTAR_ESTADOSCIVILES";
export const LISTAR_SEXOS = "LISTAR_SEXOS";
export const LISTAR_ANAQUELES = "LISTAR_ANAQUELES";
export const LISTAR_ESTANTES = "LISTAR_ESTANTES";
export const LISTAR_UBICACIONES = "LISTAR_UBICACIONES";
export const LIMPIAR_ANAQUELES = "LIMPIAR_ANAQUELES";
export const LIMPIAR_ESTANTES = "LIMPIAR_ESTANTES";
export const LIMPIAR_UBICACIONES = "LIMPIAR_UBICACIONES";
export const FILTROS_PRODUCTO_INVENTARIO = "FILTROS_PRODUCTO_INVENTARIO";
export const PRODUCTO_INVENTARIO = "PRODUCTO_INVENTARIO";
export const SET_ASEGURADORAS_PAGINADAS = "SET_ASEGURADORAS_PAGINADAS";
export const FILTROS_PRODUCTO_UBICACION = "FILTROS_PRODUCTO_UBICACION";
export const PRODUCTO_UBICACION = "PRODUCTO_UBICACION";
export const LISTAR_CATTIPOCORTECAJA = "LISTAR_CATTIPOCORTECAJA";
export const SET_PROVEEDORES_PAGINADAS = "SET_PROVEEDORES_PAGINADAS";
export const FILTRO_PROVEEDORES = "FILTRO_PROVEEDORES";
export const LISTAR_FABRICANTES = "LISTAR_FABRICANTES";
export const SET_FABRICANTES_PAGINADAS = "SET_FABRICANTES_PAGINADAS";
export const FILTRO_FABRICANTES = "FILTRO_FABRICANTES";
export const INVENTARIO_SELECCIONADO = "INVENTARIO_SELECCIONADO";
export const LISTAR_TRASLADO_PRODUCTOSINVENTARIOS = "LISTAR_TRASLADO_PRODUCTOSINVENTARIOS";
export const FILTROS_TRASLADOS = "FILTROS_TRASLADOS";
export const LISTAR_CATESTATUSTRASLADO = "LISTAR_CATESTATUSTRASLADO";
export const SET_CONSENTIMIENTOS_PAGINADOS = "SET_CONSENTIMIENTOS_PAGINADOS";
export const SET_PRODUCTOS_PAGINADOS = "SET_PRODUCTOS_PAGINADOS";
export const FILTRO_PRODUCTOS = "FILTRO_PRODUCTOS";
export const LISTAR_PRECIOS_PRODUCTO = "LISTAR_PRECIOS_PRODUCTO";
export const FILTROS_INVENTARIO_MEDICAMENTOS = "FILTROS_INVENTARIO_MEDICAMENTOS";
export const LISTAR_BAJA_INVENTARIOS = "LISTAR_BAJA_INVENTARIOS";
export const FILTROS_BAJAINVENTARIO = "FILTROS_BAJAINVENTARIO";
export const LISTAR_CATESTATUSBAJAINVENTARIO = "LISTAR_CATESTATUSBAJAINVENTARIO";
export const SET_DATOS_EXPEDIENTE_MEDICO = "SET_DATOS_EXPEDIENTE_MEDICO";
export const SET_DOCUMENTO_PREVIO = "SET_DOCUMENTO_PREVIO";
export const SET_NOTAS_ENFERMERIA_PAGINADOS = "SET_NOTAS_ENFERMERIA_PAGINADOS";
export const FILTRO_BITACORAS = "FILTRO_BITACORAS";
export const SET_BITACORAS_PAGINADAS = "SET_BITACORAS_PAGINADAS";
export const FILTRO_REPORTE_REACCIONES = "FILTRO_REPORTE_REACCIONES";
export const SET_REPORTES_REACCIONES_PAGINADOS = "SET_REPORTES_REACCIONES_PAGINADOS";
export const LISTAR_SOLUCIONES = "LISTAR_SOLUCIONES";
export const LISTAR_PRODUCTOSCOMPRAEXTERNA = "LISTAR_PRODUCTOSCOMPRAEXTERNA";
export const SET_PRODUCTOSCOMPRAEXTERNA_PAGINADOS = "SET_PRODUCTOSCOMPRAEXTERNA_PAGINADOS";
export const FILTRO_PRODUCTOSCOMPRAEXTERNA = "FILTRO_PRODUCTOSCOMPRAEXTERNA";
export const LISTAR_PACIENTES_ACTIVOS = "LISTAR_PACIENTES_ACTIVOS";
export const LISTAR_MEDICOS_ACTIVOS = "LISTAR_MEDICOS_ACTIVOS";
export const SET_VALE_SELECCIONADO = "SET_VALE_SELECCIONADO";
export const SET_MEDICAMENTOS_CM_RF = "SET_MEDICAMENTOS_CM_RF";
export const SET_MEDICAMENTOS_CM = "SET_MEDICAMENTOS_CM";
export const SET_REGISTROS_CM_RF = "SET_REGISTROS_CM_RF";
export const SET_REGISTROS_CM = "SET_REGISTROS_CM";
export const LISTAR_TIPOS_ADMINISTRACION = "LISTAR_TIPOS_ADMINISTRACION";
export const LISTAR_RESULTADOS_REA = "LISTAR_RESULTADOS_REA";
export const HISTORIA_CLINICA_INICIAL = "HISTORIA_CLINICA_INICIAL";
export const PADECIMIENTO_ACTUAL = "PADECIMIENTO_ACTUAL";
export const ID_PACIENTE_SELECCIONADO = "ID_PACIENTE_SELECCIONADO";
export const ID_MEDICO_SELECCIONADO = "ID_MEDICO_SELECCIONADO";
export const SET_ID_EXPEDIENTE_MEDICO = "SET_ID_EXPEDIENTE_MEDICO";
export const LISTAR_TIPOPRECIOS = "LISTAR_TIPOPRECIOS";
export const LISTAR_CONSULTAS_SEGUIMIENTO = "LISTAR_CONSULTAS_SEGUIMIENTO";
export const LISTAR_CATTIPORECETAS = "LISTAR_CATTIPORECETAS";
export const LISTAR_RECETA_PRODUCTOS = "LISTAR_RECETA_PRODUCTOS";
export const LISTAR_CATTIPOINTERCONSULTA = "LISTAR_CATTIPOINTERCONSULTA";
export const LISTAR_NOTAS_INTERCONSULTA = "LISTAR_NOTAS_INTERCONSULTA";
export const LISTAR_ESTUDIOS_LABORATORIO = "LISTAR_ESTUDIOS_LABORATORIO";
export const LISTAR_MARCADORES = "LISTAR_MARCADORES";
export const SET_MEDICO = "SET_MEDICO";
export const SET_PACIENTE_SELECCIONADO = "SET_PACIENTE_SELECCIONADO";
export const SET_HOJAS_INDICACION_PAGINADAS = "SET_HOJAS_INDICACION_PAGINADAS";
export const FILTRO_REPORTE_VENTAS = "FILTRO_REPORTE_VENTAS";
export const FILTRO_REPORTE_UTILIDADES = "FILTRO_REPORTE_UTILIDADES";
export const LISTAR_CAT_VIAS = "LISTAR_CAT_VIAS";
export const SET_RECETAS_PAGINADAS = "SET_RECETAS_PAGINADAS";
export const FILTRO_NUEVA_FACTURA = "FILTRO_NUEVA_FACTURA";
export const SET_VALES_PAGINADOS = "SET_VALES_PAGINADOS";
export const LISTAR_CATUSOCFDI = "LISTAR_CATUSOCFDI";
export const LISTAR_CATREGIMENFISCAL = "LISTAR_CATREGIMENFISCAL";
export const SET_CLIENTE = "SET_CLIENTE";
export const SET_ASEGURADORA = "SET_ASEGURADORA";
export const LISTAR_NOTIFICACIONES = "LISTAR_NOTIFICACIONES";
export const FILTROS_NOTIFICACIONES = "FILTROS_NOTIFICACIONES";
export const LISTAR_FACTURAS = "LISTAR_FACTURAS";
export const FILTROS_FACTURAS = "FILTROS_FACTURAS";
export const NUMERO_NOTIFICACIONES = "NUMERO_NOTIFICACIONES";
export const WIFI_EN_LINEA = "WIFI_EN_LINEA";
export const LISTAR_CATPERIODICIDAD = "LISTAR_CATPERIODICIDAD";
export const FILTRO_SALDOS_COBRAR_CONTABILIDAD = "FILTRO_SALDOS_COBRAR_CONTABILIDAD";
export const LISTAR_ESTATUSVALE_SALDOS_ASEGURADORA = "LISTAR_ESTATUSVALE_SALDOS_ASEGURADORA";
export const LISTAR_MOTIVOS_CANCELACION = "LISTAR_MOTIVOS_CANCELACION";
export const LISTAR_CATDIAGNOSTICOS = "LISTAR_CATDIAGNOSTICOS";
export const LISTAR_TIPOCITAS = "LISTAR_TIPOCITAS";
export const LISTAR_MARCADORES_PACIENTE = "LISTAR_MARCADORES_PACIENTE";
export const LISTAR_USUARIOS_SIN_MEDICO = "LISTAR_USUARIOS_SIN_MEDICO";
export const LISTAR_ESPACIOSATENCION_AGENDA = "LISTAR_ESPACIOSATENCION_AGENDA";
export const FILTRO_CITAS_PACIENTES = "FILTRO_CITAS_PACIENTES";
export const FILTRO_CITAS_ASEGURADORAS = "FILTRO_CITAS_ASEGURADORAS";
export const LISTAR_ARCHIVOS_IMAGEN = "LISTAR_ARCHIVOS_IMAGEN";
export const LISTAR_ARCHIVOS_NO_IMAGEN = "LISTAR_ARCHIVOS_NO_IMAGEN";
export const SET_EXPEDIENTE_MEDICO_PACIENTE = "SET_EXPEDIENTE_MEDICO_PACIENTE";
export const SET_EDITADO = "SET_EDITADO";
export const SET_GUARDADO = "SET_GUARDADO";
export const SET_NOTAS_EVOLUCION = "SET_NOTAS_EVOLUCION";
export const LISTAR_SERVICIOS_PAGINADOS = "LISTAR_SERVICIOS_PAGINADOS";
export const CAMBIOS_FILTROS_SERVICIOS = "CAMBIOS_FILTROS_SERVICIOS";
export const LISTAR_CAT_TIPO_SERVICIOS = "LISTAR_CAT_TIPO_SERVICIOS";
export const LISTAR_PRECIOS_SERVICIOS = "LISTAR_PRECIOS_SERVICIOS";
export const LISTAR_CATTIPOPRODCUTOS_ALL = "LISTAR_CATTIPOPRODCUTOS_ALL";
export const LISTAR_PACIENTES_SELECT = "LISTAR_PACIENTES_SELECT";
export const LISTAR_PRODUCTOS_SELECT = "LISTAR_PRODUCTOS_SELECT";
export const VALES_MEDICO = "VALES_MEDICO"
