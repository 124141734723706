import React, {useReducer} from "react";

import BajaInventarioContext from "./BajaInventarioContext";
import BajaInventarioReducer from "./BajaInventarioReducer";

import {LISTAR_BAJA_INVENTARIOS, FILTROS_BAJAINVENTARIO} from "../../types";

import clienteAxios from "../../config/axios";

export default function BajaInventarioState(props) {
    const initialState = {
        inventarioBajaSeleccionado: {},
        bajaInventarios: [],
        filtrosBajaInventario: {},
    };

    const [state, dispatch] = useReducer(BajaInventarioReducer, initialState);

    const listarBajasInventario = async ({page, limit}) => {
        const res = await clienteAxios.post(
            `/bajainventario`,
            {...state.filtrosBajaInventario, pageSize: limit, pageIndex: page},
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );

        dispatch({
            type: LISTAR_BAJA_INVENTARIOS,
            payload: res.data.bajasInventario,
        });

        return res;
    };

    const setFiltrosBajaInventario = async (filtrosBajaInventario) => {
        dispatch({
            type: FILTROS_BAJAINVENTARIO,
            payload: filtrosBajaInventario,
        });
    };

    const uploadActualizarBajaInventario = async (bajaInventario) => {
        try {
            const res = await clienteAxios.post(`/bajainventario/actualizar`, bajaInventario, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            });

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const uploadBajaInventario = async (data) => {
        try {
            const res = await clienteAxios.post(`/bajainventario/nueva`, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            });

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const exportArchivoBack = async (archivoVo) => {
        try {
            const res = await clienteAxios.post(`/bajainventario/export/archivo`, archivoVo, {
                responseType: "arraybuffer",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            });
            var file = new Blob([res.data], {type: archivoVo.mimeType});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            } else {
                error.response = {
                    data: {
                        message: "Error, por favor inténtalo más tarde",
                        error: "Error",
                    },
                };
            }
            return error.response;
        }
    };

    return (
        <BajaInventarioContext.Provider
            value={{
                bajaInventarios: state.bajaInventarios,
                filtrosBajaInventario: state.filtrosBajaInventario,
                listarBajasInventario,
                setFiltrosBajaInventario,
                uploadActualizarBajaInventario,
                uploadBajaInventario,
                exportArchivoBack
            }}>
            {props.children}
        </BajaInventarioContext.Provider>
    );
}
