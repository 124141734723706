export const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // layout: {
    //     padding: 100
    // },
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            font: {
                size: 18
            }

        },
    },
    layout: {
        padding: {
            top: 30,
            bottom: 30,
            left: 20,
            right: 20,
        },
    },
    // animation: {
    //     duration: 1000, // Animation duration in milliseconds
    //     easing: 'easeOutQuart', // Easing function for animation
    // },
};
export const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // layout: {
    //     padding: 100
    // },
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            font: {
                size: 18
            }

        },
    },
    layout: {
        padding: {
            top: 30,
            bottom: 30,
            left: 20,
            right: 20,
        },
    },
    // animation: {
    //     duration: 1000, // Animation duration in milliseconds
    //     easing: 'easeOutQuart', // Easing function for animation
    // },
};

export const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // layout: {
    //     padding: 100
    // },
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            font: {
                size: 18
            }

        },
    },
    layout: {
        padding: {
            top: 30,
            bottom: 30,
            left: 20,
            right: 20,
        },
    },
    // animation: {
    //     duration: 1000, // Animation duration in milliseconds
    //     easing: 'easeOutQuart', // Easing function for animation
    // },
};