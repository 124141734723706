import React, { useReducer } from "react";

import WindowWarningContext from "./WindowWarningContext";
import WindowWarningReducer from "./WindowWarningReducer";

import { SET_EDITADO, SET_GUARDADO } from "../../types";

// import clienteAxios from "../../config/axios";

export default function WindowWarningState(props) {
  const initialState = {
    seEdito: false,
    seGuardaronCambiosContext: false,
  };

  const [state, dispatch] = useReducer(WindowWarningReducer, initialState);

  const changeEdit = (editado) => {
    dispatch({
      type: SET_EDITADO,
      payload: editado,
    });
    if (editado) {
      dispatch({
        type: SET_GUARDADO,
        payload: false,
      });
    }
  };
  const changeGuardado = (guardado) => {
    dispatch({
      type: SET_GUARDADO,
      payload: guardado,
    });
    if (guardado) {
      dispatch({
        type: SET_EDITADO,
        payload: false,
      });
    }
  };

  return (
    <WindowWarningContext.Provider
      value={{
        seEdito: state.seEdito,
        seGuardaronCambiosContext: state.seGuardaronCambiosContext,
        changeEdit,
        changeGuardado,
      }}>
      {props.children}
    </WindowWarningContext.Provider>
  );
}
