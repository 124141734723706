import React, { useReducer } from "react";

import HojaIndicacionContext from "./HojaIndicacionContext";
import HojaIndicacionReducer from "./HojaIndicacionReducer";

import { SET_HOJAS_INDICACION_PAGINADAS } from "../../types";

import clienteAxios from "../../config/axios";

export default function HojaIndicacionState(props) {
  const initialState = {
    hojasIndicacion: [],
  };

  const [state, dispatch] = useReducer(HojaIndicacionReducer, initialState);

  const setHojasPaginadas = async ({ page, limit, idExpedienteMedico }) => {
    try {
      const response = await clienteAxios.post(
        "/hoja/indicacion/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          idExpedienteMedico: idExpedienteMedico,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      response.data.hojaIndicacionVos.forEach((hoja) => {
        const fecha = hoja.fechaCreacion.split("T")[0];
        let horaFormat = hoja.fechaCreacion.split("T")[1];
        horaFormat = horaFormat.split(".")[0];
        const hora = horaFormat.split(":")[0];
        const minutos = horaFormat.split(":")[1];

        const fechaModificada = `${fecha.split("-")[0]}-${fecha.split("-")[1]}-${fecha.split("-")[2]}`;
        const horaModificada = `${hora}:${minutos}`;
        hoja.fechaCreacion = `${fechaModificada} - ${horaModificada}`;
      });

      dispatch({
        type: SET_HOJAS_INDICACION_PAGINADAS,
        payload: response.data.hojaIndicacionVos,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setHojaIndicacion = async (idExpedienteMedico) => {
    try {
      const response = await clienteAxios.post(
        `/hoja/indicacion/expediente?id=${idExpedienteMedico}`,

        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const uploadHojaIndicacion = async (datosHojaIndicacion) => {
    try {
      const res = await clienteAxios.post(`/hoja/indicacion/guardar`, datosHojaIndicacion, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {  
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const uploadArchivoHojaIndicacion = async (hojaIndicacionVo) => {
    console.log("🚀 ~ file: HojaIndicacionState.js:116 ~ uploadArchivoHojaIndicacion ~ hojaIndicacionVo:", hojaIndicacionVo);
    try {
      const res = await clienteAxios.post(`/hoja/indicacion/agregar/indicacion`, hojaIndicacionVo, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const exportHojaIndicacion = async (hojaIndicacion) => {
    try {
      const res = await clienteAxios.post(`/hoja/indicacion/export`, hojaIndicacion, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=hojaIndicacion.pdf",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      var file = new Blob([res.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <HojaIndicacionContext.Provider
      value={{
        hojasIndicacion: state.hojasIndicacion,
        setHojasPaginadas,
        uploadHojaIndicacion,
        exportHojaIndicacion,
        setHojaIndicacion,
        uploadArchivoHojaIndicacion,
      }}>
      {props.children}
    </HojaIndicacionContext.Provider>
  );
}
