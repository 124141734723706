import {
    LISTAR_PRODUCTOSSERVICIOS,
    LISTAR_PRODUCTOS,
    LISTAR_NUMIDENTIFICACION,
    LISTAR_PRINCIPIOSACTIVOS,
    LISTAR_NOMBRECOMERCIALES,
    LISTAR_NOMBRECOMERCIALES_NUMIDENTIFICACION,
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_PRODUCTOSSERVICIOS:
        case LISTAR_PRODUCTOS:
            return{
                ...state,
                productosServicios: action.payload
            };
        case LISTAR_NUMIDENTIFICACION:
            return{
                ...state,
                numerosIdentificacion: action.payload
            };
        case LISTAR_PRINCIPIOSACTIVOS:
            return{
                ...state,
                principiosActivos: action.payload
            };
        case LISTAR_NOMBRECOMERCIALES:
            return{
                ...state,
                nombresComerciales: action.payload
            };
        case LISTAR_NOMBRECOMERCIALES_NUMIDENTIFICACION:
            return{
                ...state,
                nombreComercialNumeroIdentificacion: action.payload
            };
        default:
            return state
    }
        
}