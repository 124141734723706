/* eslint-disable import/no-anonymous-default-export */
import { LISTAR_TIPOS_ADMINISTRACION } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_TIPOS_ADMINISTRACION:
      return {
        ...state,
        tiposAdministracion: action.payload,
      };

    default:
      return state;
  }
};
