import React, { useContext } from "react";

import CerrarCorteCajaFarmacia from "./CerrarCorteCajaFarmacia";
import CerrarCorteCajaRecepcion from "./CerrarCorteCajaRecepcion";

import AuthContext from "../../context/Auth/AuthContext";

export default function CerrarCorteCaja() {
  // const { idCorteCaja } = useParams();

  // ACCESO AL ESTADO GLOBAL - AUTH
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        {usuario.authorities.includes("ROLE_RECEPCION") ? (
          // <CerrarCorteCajaRecepcion idCorteCaja={idCorteCaja} />
          <CerrarCorteCajaRecepcion usuarios={{ farmacia: 0, recepcion: 1 }} />
        ) : null}
        {usuario.authorities.includes("ROLE_FARMACIA") ? (
          // <CerrarCorteCajaFarmacia idCorteCaja={idCorteCaja} />
          <CerrarCorteCajaFarmacia usuarios={{ farmacia: 1, recepcion: 0 }} />
        ) : null}
      </div>
    </>
  );
}
