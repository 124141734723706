import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import ClientesContext from "../../context/Clientes/ClientesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import CatRegimenFiscalContext from "../../context/CatRegimenFiscal/CatRegimenFiscalContext";
import CatUsoCfdiContext from "../../context/CatUsoCfdi/CatUsoCfdiContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";

import useWindowDimensions from "../../hooks/dimensionsHook";

import {
  maxCharTelefono,
  maxCharNombres,
  maxCharApellidoPaterno,
  maxCharApellidoMaterno,
  maxCharRfc,
  maxCharCurp,
  maxCharCorreo,
  maxCharCalle,
  maxCharNumExterno,
  maxCharNumInterno,
  maxCharColonia,
  maxCharMunicipio,
  maxCharPais,
  maxCharCP,
} from "../../config/variables";

const Clientes = () => {
  const { width } = useWindowDimensions();
  const filtrosVacios = {
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    fechaNacimiento: "",
    rfc: "",
    curp: "",
    telefonoFijo: "",
    telefonoCelular: "",
    correo: "",
    calle: "",
    numExterno: "",
    numInterno: "",
    colonia: "",
    municipio: "",
    estado: "",
    pais: "",
    cp: "",
  };

  const datosClienteVacio = {
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    fechaNacimiento: "",
    rfc: "",
    curp: "",
    telefonoFijo: "",
    telefonoCelular: "",
    correo: "",
    calle: "",
    numExterno: "",
    numInterno: "",
    colonia: "",
    municipio: "",
    /*estado: "",*/
    idEstado: "",
    pais: "",
    cp: "",
    idCatRegimenFiscal: "",
    idCatUsoCfdi: "",
  };


  const [datosNuevoCliente, setDatosNuevoCliente] = useState(datosClienteVacio);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [clientelog, setClientelog] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_cliente: false,
    confirm_nuevo_cliente: false,
    confirm_eliminar_cliente: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
  const [data, setData] = useState([
    {
      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      fechaNacimiento: "",
      rfc: "",
      curp: "",
      telefonoFijo: "",
      telefonoCelular: "",
      correo: "",
      calle: "",
      numExterno: "",
      numInterno: "",
      colonia: "",
      municipio: "",
      /*estado: "",*/
      idEstado: "",
      pais: "",
      cp: "",
    },
  ]);
  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setDataCliente({ page, limit });

      const data = response.clientes.map((cliente) => {
        if (cliente.fechaNacimiento)
          return {
            ...cliente,
            fechaNacimiento: cliente.fechaNacimiento.split("T")[0],
          };
        return cliente;
      });

      const dataFiltered = data.filter((cliente) => cliente.idCatCliente != 1);

      // Cantidad de datos disponibles en la tabla clientes de la base de datos
      const count = response.count;

      setData(dataFiltered);
      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);

    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
    // setLoadingData(true);
    // fetchAPIData({
    //   page: pageIndex + 1,
    //   limit: pageSize,
    //   byBusquedaFiltros: false,
    // });
  };

  const handdleCheckedCliente = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setClienteSeleccionado({
          ...row.original,
        });
        setClientelog({
          ...row.original,
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setClienteSeleccionado(null);
      } else {
        setRowSeleccionada(row.id);
        setClienteSeleccionado({
          ...row.original,
        });
        setClientelog({
          ...row.original,
        });        
      }
    },
    [rowSeleccionada]
  );

  // Se definen las columnas para la tabla de react-tabe
  // Se debe usar useMemo para optimización y porque lo pide la librería
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCatCliente",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={
              //   rowSeleccionada === null || rowSeleccionada === row.id
              //     ? false
              //     : true
              // }
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedCliente(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Nombres", accessor: "nombres" },
      { Header: "Apellido Paterno", accessor: "apellidoPaterno" },
      { Header: "Apellido Materno", accessor: "apellidoMaterno" },
      { Header: "Fecha Nacimiento", accessor: "fechaNacimiento" },
      { Header: "RFC", accessor: "rfc" },
      { Header: "CURP", accessor: "curp" },
      { Header: "Regimen Fiscal", accessor: "regimenFiscal" },
      { Header: "Uso Cfdi", accessor: "usoCfdi" },
      { Header: "Tel Fijo", accessor: "telefonoFijo" },
      { Header: "Tel Celular", accessor: "telefonoCelular" },
      { Header: "Correo", accessor: "correo" },
      { Header: "Calle", accessor: "calle" },
      { Header: "Num Externo", accessor: "numExterno" },
      { Header: "Num Interno", accessor: "numInterno" },
      { Header: "Colonia", accessor: "colonia" },
      { Header: "Municipio", accessor: "municipio" },
      { Header: "Estado", accessor: "estado" },
      { Header: "País", accessor: "pais" },
      { Header: "CP", accessor: "cp" },
    ],
    [rowSeleccionada, handdleCheckedCliente]
  );

  const clientesContext = useContext(ClientesContext);
  const lugaresContext = useContext(LugaresContext);

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const { lugares, listarLugares } = lugaresContext;
  const { setFiltrosClientes, filtrosClientes, uploadNuevoCliente, eliminarClienteLog, eliminarCliente, setDataCliente, updateCliente } =
    clientesContext;

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  const autorizado =
    usuario.authorities.includes("OP_CATALOGO_CLIENTE_CREAR") ||
    usuario.authorities.includes("OP_CATALOGO_CLIENTE_MODIFICAR") ||
    usuario.authorities.includes("OP_CATALOGO_CLIENTE_ELIMINAR");

  const aseguradora = usuario.authorities.includes("ROLE_ASEGURADORAS");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function setDataCliente
     * @param {object} filtrosClientes Filtros enviados al back para filtrar los clientes
     * @param {int} page Página a regresar de clientes
     * @param {int} limit Número de clientes que se van a regresar por llamada al back
     */

    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosClientes(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltrosClientes({
      ...filtrosClientes,
      [name]: value,
    });
  };

  const changeNuevoCliente = (e) => {
    const { name, value } = e.target;
    setDatosNuevoCliente({
      ...datosNuevoCliente,
      [name]: value,
    });
  };

  const changeModificarCliente = (e) => {
    const { name, value } = e.target;
    setClienteSeleccionado({
      ...clienteSeleccionado,
      [name]: value,
    });
  };

  const lugaresOpciones = lugares.map(({ idCatOrigenResidencia, lugar, ...origenResidencia }) => ({
    ...origenResidencia,
    id: idCatOrigenResidencia,
    nombre: lugar,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Nombre",
          type: "text",
          name: "nombres",
          value: filtrosClientes.nombres,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Apellido Paterno",
          type: "text",
          name: "apellidoPaterno",
          value: filtrosClientes.apellidoPaterno,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Apellido Materno",
          type: "text",
          name: "apellidoMaterno",
          value: filtrosClientes.apellidoMaterno,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Fecha de Nacimiento",
          type: "date",
          name: "fechaNacimiento",
          value: filtrosClientes.fechaNacimiento,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "RFC",
          type: "text",
          name: "rfc",
          value: filtrosClientes.rfc,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "CURP",
          type: "text",
          name: "curp",
          value: filtrosClientes.curp,
          onChange: onChange,
        },
      ],
    },
  ];

  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneformat = /^\d{10}$/;
  const rfcformat = /^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?$/;
  const cpformat = /^\d{4,5}$/;

  const validarDatosCliente = (cliente) => {
    let errorNuevoCliente = false;
    if (!errorNuevoCliente && (cliente.nombres === "" || cliente.nombres === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el nombre o razón social del cliente",
      });
    }

    if (!errorNuevoCliente && cliente.rfc !== "" && cliente.rfc !== null && !cliente.rfc.match(rfcformat)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un RFC válido",
      });
    }

    if (!errorNuevoCliente && (cliente.calle === "" || cliente.calle === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la calle del cliente",
      });
    }
    if (!errorNuevoCliente && (cliente.numExterno === "" || cliente.numExterno === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el número externo del cliente",
      });
    }
    if (!errorNuevoCliente && (cliente.colonia === "" || cliente.colonia === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la colonia del cliente",
      });
    }
    if (!errorNuevoCliente && (cliente.municipio === "" || cliente.municipio === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el municipio del cliente",
      });
    }
    if (!errorNuevoCliente && (cliente.estado === "" || cliente.estado === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el estado del cliente",
      });
    }
    if (!errorNuevoCliente && (cliente.lugarOrigen === "" || cliente.lugarOrigen === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el estado del paciente",
      });
    }
    if (!errorNuevoCliente && (cliente.pais === "" || cliente.pais === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el pais del cliente",
      });
    }
    if (!errorNuevoCliente && (cliente.cp === "" || cliente.cp === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el CP del cliente",
      });
    }
    if (!errorNuevoCliente && !cliente.cp.toString().match(cpformat)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un código postal válido",
      });
    }

    if (!errorNuevoCliente && cliente.telefonoFijo !== "" && !cliente.telefonoFijo.match(phoneformat)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un teléfono fijo de contacto válido",
      });
    }
    if (!errorNuevoCliente && (cliente.telefonoCelular === "" || cliente.telefonoCelular === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el teléfono celular del cliente",
      });
    }
    if (!errorNuevoCliente && !cliente.telefonoCelular.match(phoneformat)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un teléfono celular de contacto válido",
      });
    }
    if (!errorNuevoCliente && (cliente.correo === "" || cliente.correo === null)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el correo del cliente",
      });
    }
    if (!errorNuevoCliente && !cliente.correo.match(mailformat)) {
      errorNuevoCliente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un correo de contacto válido",
      });
    }

    return errorNuevoCliente;
  };

  const confirmNuevoCliente = () => {
    if (autorizado || aseguradora) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nuevo_cliente: true,
      });
    }
  };

  const agregarNuevoCliente = async () => {
    const clienteValido = validarDatosCliente(datosNuevoCliente);

    if (!clienteValido) {
      setLoading(true);

      let cadenaLogLocal = ""
      cadenaLogLocal = cadenaLogLocal+ "Nuevo:ID[@@],Nombre[" + datosNuevoCliente.nombres + "]";

	    datosNuevoCliente.logVo = cadenaLogLocal.length==0? null:{
        idUsuario: usuario.idUsuario,
        modulo: "Clientes",
        actividad:cadenaLogLocal,
      };

      const res = await uploadNuevoCliente(datosNuevoCliente);

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nuevo_cliente: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nuevo_cliente: false,
          dynamic_description: "Se guardó el nuevo cliente correctamente",
        });
        // setData([...data, res.data]);
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setDatosNuevoCliente(datosClienteVacio);
      }
    }
  };

  // const updateDataOnDeleteCliente = async (idCatCliente) => {
  //   const updatedData = data.filter((cliente) => cliente.idCatCliente !== idCatCliente);
  //   setData(updatedData);
  // };

  const checkEliminarCliente = () => {
    if (autorizado && clienteSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_cliente: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un cliente",
      });
    }
  };

  const confirmEliminarCliente = async () => {
    setLoading(true);

    let cadenaLogLocal = ""
    cadenaLogLocal = cadenaLogLocal+ "Eliminar:ID["+ clienteSeleccionado.idCatCliente +"],Nombre[" + clienteSeleccionado.nombres + " " + clienteSeleccionado.apellidoPaterno + " " + clienteSeleccionado.apellidoMaterno +"]";

    clienteSeleccionado.logVo = cadenaLogLocal.length==0? null:{
      idUsuario: usuario.idUsuario,
      modulo: "Clientes",
      actividad:cadenaLogLocal,
    };

    const res = await eliminarCliente(clienteSeleccionado.idCatCliente);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_cliente: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      const res2 = await eliminarClienteLog(clienteSeleccionado.logVo);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_cliente: false,
        dynamic_description: "Se eliminó el cliente correctamente",
      });
      // updateDataOnDeleteCliente(clienteSeleccionado.idCatCliente);
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setClienteSeleccionado(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarCliente = () => {
    if ((autorizado || aseguradora) && clienteSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_cliente: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un cliente",
      });
    }
  };

  const confirmModicarCliente = async () => {
    const clienteValido = validarDatosCliente(clienteSeleccionado);

    if (!clienteValido) {
      setLoading(true);

      let cadenaLogLocal = ""
      let folio = clienteSeleccionado.idCatCliente;
      cadenaLogLocal = cadenaLogLocal+ "Actualización:ID[" + folio + "]";

      if(clienteSeleccionado.nombre != clientelog.nombre){
        cadenaLogLocal = cadenaLogLocal+ ",N[" + clientelog.nombres + "->" + clienteSeleccionado.nombres +"]";
      }

      if(clienteSeleccionado.apellidoPaterno != clientelog.apellidoPaterno){
        cadenaLogLocal = cadenaLogLocal+ ",AP[" + clientelog.apellidoPaterno + "->" + clienteSeleccionado.apellidoPaterno +"]";
      }

      if(clienteSeleccionado.apellidoMaterno != clientelog.apellidoMaterno){
        cadenaLogLocal = cadenaLogLocal+ ",AM[" + clientelog.apellidoMaterno + "->" + clienteSeleccionado.apellidoMaterno +"]";
      }

      if(clienteSeleccionado.fechaNacimiento != clientelog.fechaNacimiento){
        cadenaLogLocal = cadenaLogLocal+ ",FN[" + clientelog.fechaNacimiento + "->" + clienteSeleccionado.fechaNacimiento +"]";
      }

      if(clienteSeleccionado.rfc != clientelog.rfc){
        cadenaLogLocal = cadenaLogLocal+ ",RFC[" + clientelog.rfc + "->" + clienteSeleccionado.rfc +"]";
      }

      if(clienteSeleccionado.curp != clientelog.curp){
        cadenaLogLocal = cadenaLogLocal+ ",CURP[" + clientelog.curp + "->" + clienteSeleccionado.curp +"]";
      }

      if(clienteSeleccionado.telefonoFijo != clientelog.telefonoFijo){
        cadenaLogLocal = cadenaLogLocal+ ",TF[" + clientelog.telefonoFijo + "->" + clienteSeleccionado.telefonoFijo +"]";
      }

      if(clienteSeleccionado.telefonoCelular != clientelog.telefonoCelular){
        cadenaLogLocal = cadenaLogLocal+ ",TC[" + clientelog.telefonoCelular + "->" + clienteSeleccionado.telefonoCelular +"]";
      }

      if(clienteSeleccionado.correo != clientelog.correo){
        cadenaLogLocal = cadenaLogLocal+ ",CORR[" + clientelog.correo + "->" + clienteSeleccionado.correo +"]";
      }

      if(clienteSeleccionado.nombre != clientelog.nombre){
        cadenaLogLocal = cadenaLogLocal+ ",X[" + clientelog.nombres + "->" + clienteSeleccionado.nombres +"]";
      }

      if(clienteSeleccionado.calle != clientelog.calle){
        cadenaLogLocal = cadenaLogLocal+ ",CALLE[" + clientelog.calle + "->" + clienteSeleccionado.calle +"]";
      }

      if(clienteSeleccionado.numExterno != clientelog.numExterno){
        cadenaLogLocal = cadenaLogLocal+ ",NE[" + clientelog.numExterno + "->" + clienteSeleccionado.numExterno +"]";
      }

      if(clienteSeleccionado.colonia != clientelog.colonia){
        cadenaLogLocal = cadenaLogLocal+ ",COL[" + clientelog.colonia + "->" + clienteSeleccionado.colonia +"]";
      }

      if(clienteSeleccionado.municipio != clientelog.municipio){
        cadenaLogLocal = cadenaLogLocal+ ",MUN[" + clientelog.municipio + "->" + clienteSeleccionado.municipio +"]";
      }

      if(clienteSeleccionado.idEstado != clientelog.idEstado){
        cadenaLogLocal = cadenaLogLocal+ ",EST[" + clientelog.idEstado + "->" + clienteSeleccionado.idEstado +"]";
      }

      if(clienteSeleccionado.cp != clientelog.cp){
        cadenaLogLocal = cadenaLogLocal+ ",CP[" + clientelog.cp + "->" + clienteSeleccionado.cp +"]";
      }

      if(clienteSeleccionado.idCatRegimenFiscal != clientelog.idCatRegimenFiscal){
        cadenaLogLocal = cadenaLogLocal+ ",RF[" + clientelog.idCatRegimenFiscal + "->" + clienteSeleccionado.idCatRegimenFiscal +"]";
      }

      if(clienteSeleccionado.idCatUsoCfdi != clientelog.idCatUsoCfdi){
        cadenaLogLocal = cadenaLogLocal+ ",UCFDI[" + clientelog.idCatUsoCfdi + "->" + clienteSeleccionado.idCatUsoCfdi +"]";
      }

      clienteSeleccionado.logVo = cadenaLogLocal.length==0? null:{
        idUsuario: usuario.idUsuario,
        modulo: "Clientes",
        actividad:cadenaLogLocal,
      };

      const res = await updateCliente(clienteSeleccionado);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_cliente: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_cliente: false,
          dynamic_description: "Se modificó el cliente correctamente",
        });
        // const clientesActualizados = await data.map((cliente) => {
        //   if (res.data.idCatCliente === cliente.idCatCliente) {
        //     return (cliente = res.data);
        //   } else {
        //     return cliente;
        //   }
        // });

        // const dataActualizada = await clientesActualizados.map((cliente) => {
        //   return {
        //     ...cliente,
        //     //, fechaNacimiento: cliente.fechaNacimiento.split("T")[0]
        //   };
        // });
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setClienteSeleccionado(null);
        setRowSeleccionada(null);
      }
    }
  };

  const catUsoCfdiContext = useContext(CatUsoCfdiContext);
  const { listarCatUsoCfdis, catUsoCfdis } = catUsoCfdiContext;

  const catRegimenFiscalContext = useContext(CatRegimenFiscalContext);
  const { listarCatRegimenFiscales, catRegimenFiscales } = catRegimenFiscalContext;

  useEffect(() => {
    const fetchAllContextData = async () => {
      listarCatRegimenFiscales();
      listarCatUsoCfdis();
      listarLugares();
    };
    fetchAllContextData();
  }, []);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Catálogo de Clientes</h3>
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Clientes</h4>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado || aseguradora? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevoCliente()}>
                      Nuevo Cliente
                    </button>
                  ) : null}
                  {autorizado || aseguradora? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarCliente()}>
                      Modificar Cliente
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarCliente()}>
                      Eliminar Cliente
                    </button>
                  ) : null}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={data}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nuevo_cliente ? (
        <SweetAlert
          title="Nuevo Cliente"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            agregarNuevoCliente();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nuevo_cliente: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">
                    Nombre o Razón Social<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombres"
                      autoComplete="off"
                      maxLength={maxCharNombres}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.nombres ? datosNuevoCliente.nombres : ""}
                    />
                    {datosNuevoCliente.nombres === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Apellido Paterno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidoPaterno"
                      autoComplete="off"
                      maxLength={maxCharApellidoPaterno}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.apellidoPaterno ? datosNuevoCliente.apellidoPaterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Apellido Materno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidoMaterno"
                      maxLength={maxCharApellidoMaterno}
                      autoComplete="off"
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.apellidoMaterno ? datosNuevoCliente.apellidoMaterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Fecha Nacimiento</label>
                  <Col sm={7}>
                    <input
                      type="date"
                      className="form-control"
                      name="fechaNacimiento"
                      autoComplete="off"
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.fechaNacimiento ? datosNuevoCliente.fechaNacimiento : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    RFC<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="rfc"
                      autoComplete="off"
                      maxLength={maxCharRfc}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.rfc ? datosNuevoCliente.rfc : ""}
                    />
                    {datosNuevoCliente.rfc === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    {!datosNuevoCliente.rfc.match(rfcformat) && datosNuevoCliente.rfc !== "" && (
                      <span className="text-sm text-red-700">RFC inválido</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">CURP</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="curp"
                      autoComplete="off"
                      maxLength={maxCharCurp}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.curp ? datosNuevoCliente.curp : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Teléfono Fijo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoFijo"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.telefonoFijo ? datosNuevoCliente.telefonoFijo : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Teléfono Celular<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoCelular"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.telefonoCelular ? datosNuevoCliente.telefonoCelular : ""}
                    />
                    {datosNuevoCliente.telefonoCelular === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                    {!datosNuevoCliente.telefonoCelular.match(phoneformat) && datosNuevoCliente.telefonoCelular !== "" && (
                      <span className="text-sm text-red-700">Teléfono inválido</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Correo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="correo"
                      autoComplete="off"
                      maxLength={maxCharCorreo}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.correo ? datosNuevoCliente.correo : ""}
                    />
                    {datosNuevoCliente.correo === "" && 
                    (<span className="text-sm text-red-700">Este campo es obligatorio</span>)}
                    {!datosNuevoCliente.correo.match(mailformat) && datosNuevoCliente.correo !== "" && (
                      <span className="text-sm text-red-700">Correo inválido</span>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Calle<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="calle"
                      autoComplete="off"
                      maxLength={maxCharCalle}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.calle ? datosNuevoCliente.calle : ""}
                    />
                    {datosNuevoCliente.calle === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Num Externo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numExterno"
                      autoComplete="off"
                      maxLength={maxCharNumExterno}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.numExterno ? datosNuevoCliente.numExterno : ""}
                    />
                    {datosNuevoCliente.numExterno === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Num Interno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numInterno"
                      autoComplete="off"
                      maxLength={maxCharNumInterno}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.numInterno ? datosNuevoCliente.numInterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Colonia<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="colonia"
                      autoComplete="off"
                      maxLength={maxCharColonia}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.colonia ? datosNuevoCliente.colonia : ""}
                    />
                    {datosNuevoCliente.colonia === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Municipio<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="municipio"
                      autoComplete="off"
                      maxLength={maxCharMunicipio}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.municipio ? datosNuevoCliente.municipio : ""}
                    />
                    {datosNuevoCliente.municipio === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Estado</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idEstado"
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.idEstado}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {lugaresOpciones.map((lugar) => {
                        return <option value={lugar.id}>{lugar.nombre}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    País<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="pais"
                      autoComplete="off"
                      maxLength={maxCharPais}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.pais ? datosNuevoCliente.pais : ""}
                    />
                    {datosNuevoCliente.pais === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    CP<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cp"
                      autoComplete="off"
                      maxLength={maxCharCP}
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.cp ? datosNuevoCliente.cp : ""}
                    />
                    {datosNuevoCliente.cp === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    {!datosNuevoCliente.cp.match(cpformat) && datosNuevoCliente.cp !== "" && (
                      <span className="text-sm text-red-700">Código Postal inválido</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Régimen Fiscal<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatRegimenFiscal"
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.idCatRegimenFiscal}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catRegimenFiscales.map((regimen) => {
                        return (
                          <option value={regimen.idCatRegimenFiscal}>{`${regimen.clave} - ${regimen.regimenFiscal}`}</option>
                        );
                      })}
                    </select>
                    {datosNuevoCliente.idCatRegimenFiscal === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Uso CFDi<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatUsoCfdi"
                      onChange={changeNuevoCliente}
                      value={datosNuevoCliente.idCatUsoCfdi}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catUsoCfdis.map((usoCfdi) => {
                        return <option value={usoCfdi.idCatUsoCfdi}>{`${usoCfdi.clave} - ${usoCfdi.usoCfdi}`}</option>;
                      })}
                    </select>
                    {datosNuevoCliente.idCatUsoCfdi === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>

                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label"></label>
                  <Col sm={7}>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDatosNuevoCliente(datosClienteVacio)}>
                      Limpiar Datos
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_eliminar_cliente ? (
        <SweetAlert
          title="¿Desea eliminar al cliente seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => confirmEliminarCliente()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_cliente: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_cliente ? (
        <SweetAlert
          title="Modificar Datos del Cliente"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          closeOnClickOutside={false}
          cancelBtnBsStyle="danger"
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            confirmModicarCliente();
          }}
          onCancel={() => {
            setClienteSeleccionado(datosClienteVacio);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_cliente: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">
                    Nombre o Razón Social<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombres"
                      autoComplete="off"
                      maxLength={maxCharNombres}
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.nombres ? clienteSeleccionado.nombres : ""}
                    />
                    {clienteSeleccionado.nombres === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Apellido Paterno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidoPaterno"
                      maxLength={maxCharApellidoPaterno}
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.apellidoPaterno ? clienteSeleccionado.apellidoPaterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Apellido Materno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="apellidoMaterno"
                      maxLength={maxCharApellidoMaterno}
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.apellidoMaterno ? clienteSeleccionado.apellidoMaterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Fecha Nacimiento</label>
                  <Col sm={7}>
                    <input
                      type="date"
                      className="form-control"
                      name="fechaNacimiento"
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.fechaNacimiento ? clienteSeleccionado.fechaNacimiento : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    RFC<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      disabled={false}
                      type="text"
                      onChange={changeModificarCliente}
                      maxLength={maxCharRfc}
                      className="form-control"
                      name="rfc"
                      autoComplete="off"
                      value={clienteSeleccionado.rfc ? clienteSeleccionado.rfc : ""}
                    />
                    {clienteSeleccionado.rfc === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    {!clienteSeleccionado.rfc.match(rfcformat) && clienteSeleccionado.rfc !== "" && (
                      <span className="text-sm text-red-700">RFC inválido</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">CURP</label>
                  <Col sm={7}>
                    <input
                      disabled={false}
                      type="text"
                      className="form-control"
                      name="curp"
                      maxLength={maxCharCurp}
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.curp ? clienteSeleccionado.curp : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Teléfono Fijo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoFijo"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.telefonoFijo ? clienteSeleccionado.telefonoFijo : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Teléfono Celular<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoCelular"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.telefonoCelular ? clienteSeleccionado.telefonoCelular : ""}
                    />
                    {clienteSeleccionado.telefonoCelular === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Correo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="correo"
                      maxLength={maxCharCorreo}
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.correo ? clienteSeleccionado.correo : ""}
                    />
                    {clienteSeleccionado.correo == "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}                    
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Calle<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="calle"
                      autoComplete="off"
                      maxLength={maxCharCalle}
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.calle ? clienteSeleccionado.calle : ""}
                    />
                    {clienteSeleccionado.calle === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Num Externo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numExterno"
                      autoComplete="off"
                      maxLength={maxCharNumExterno}
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.numExterno ? clienteSeleccionado.numExterno : ""}
                    />
                    {clienteSeleccionado.numExterno === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Num Interno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numInterno"
                      maxLength={maxCharNumInterno}
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.numInterno ? clienteSeleccionado.numInterno : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Colonia<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="colonia"
                      autoComplete="off"
                      maxLength={maxCharColonia}
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.colonia ? clienteSeleccionado.colonia : ""}
                    />
                    {clienteSeleccionado.colonia === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Municipio<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="municipio"
                      maxLength={maxCharMunicipio}
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.municipio ? clienteSeleccionado.municipio : ""}
                    />
                    {clienteSeleccionado.municipio === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">Estado</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idEstado"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.idEstado}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {lugaresOpciones.map((lugar) => {
                        return <option value={lugar.id}>{lugar.nombre}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    País<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="pais"
                      maxLength={maxCharPais}
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.pais ? clienteSeleccionado.pais : ""}
                    />
                    {clienteSeleccionado.pais === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    CP<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cp"
                      maxLength={maxCharCP}
                      autoComplete="off"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.cp ? clienteSeleccionado.cp : ""}
                    />
                    {clienteSeleccionado.cp === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Régimen Fiscal<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatRegimenFiscal"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.idCatRegimenFiscal ? clienteSeleccionado.idCatRegimenFiscal : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catRegimenFiscales.map((regimen) => {
                        return (
                          <option value={regimen.idCatRegimenFiscal}>{`${regimen.clave} - ${regimen.regimenFiscal}`}</option>
                        );
                      })}
                    </select>
                    {clienteSeleccionado.idCatRegimenFiscal === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label">
                    Uso CFDi<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatUsoCfdi"
                      onChange={changeModificarCliente}
                      value={clienteSeleccionado.idCatUsoCfdi ? clienteSeleccionado.idCatUsoCfdi : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {catUsoCfdis.map((usoCfdi) => {
                        return <option value={usoCfdi.idCatUsoCfdi}>{`${usoCfdi.clave} - ${usoCfdi.usoCfdi}`}</option>;
                      })}
                    </select>
                    {clienteSeleccionado.idCatUsoCfdi === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default Clientes;
