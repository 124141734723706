import React, { useReducer } from "react";

import SexosContext from "./SexosContext";
import SexosReducer from "./SexosReducer";

import { LISTAR_SEXOS } from "../../types";

import clienteAxios from "../../config/axios";

export default function SexosState(props) {
  const initialState = {
    sexos: [],
  };

  const [state, dispatch] = useReducer(SexosReducer, initialState);

  const listarSexos = async () => {
    const res = await clienteAxios.get("/sexos/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_SEXOS,
      payload: res.data,
    });
  };

  return (
    <SexosContext.Provider
      value={{
        sexos: state.sexos,
        listarSexos,
      }}>
      {props.children}
    </SexosContext.Provider>
  );
}
