import {
    LISTAR_CATESTATUSBAJAINVENTARIO
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_CATESTATUSBAJAINVENTARIO:
            return{
                ...state,
                catEstatusBajaInventario: action.payload
            };
        default:
            return state
    }
        
}