import {
    LISTAR_CATESTATUSTRASLADO
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_CATESTATUSTRASLADO:
            return{
                ...state,
                catEstatusTraslado: action.payload
            };
        default:
            return state
    }
        
}