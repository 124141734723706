import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";

import InformacionGeneralSolicitudCompra from "../InformacionGeneralSolicitudCompra/index";
import TabOrdenCompraProveedor from "../TabOrdenCompraProveedor/index";
import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import OrdenesCompraContext from "../../context/OrdenesCompra/OrdenesCompraContext";
import AuthContext from "../../context/Auth/AuthContext";

export default function SolicitarOrdenCompraProveedor(props) {
  const { idSolicitudCompra } = useParams();

  const [loading, setLoading] = useState(false);
  const [UISweetAlert, setUISweetAlert] = useState({
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
    confirm_alert_generar_ordencompra: false,
  });

  const [tabIndex, setTabIndex] = useState(0);
  const [flagSeleccionarCompra, setFlagSeleccionarCompra] = useState(false);

  // ACCESO A ESTADO GLOBAL - VALES
  const ctxSolicCompra = useContext(SolicitudesComprasContext);
  const {
    seleccionarSolicitudCompra,
    solicitudShowEdit,
    setSolicitudShowEdit,
    errorSolicitudCompraSeleccionada,
    solicitudCompraSeleccionada,
    solicitudOrdenesCompraProveedores,
    seleccionarOrdenesCompraProveedores,
    setSolicitudesOrdenesCompraProveedores,
    uploadCancelarSolicitudCompra,
    cancelarSolicitudCompra,
    setCancelarSolicitudCompra,
  } = ctxSolicCompra;

  // ACCESO A ESTADO GLOBAL - VALES
  const ctxAlmacenes = useContext(AlmacenesContext);
  const { listarAlmacenes } = ctxAlmacenes;

  // ACCESO A ESTADO GLOBAL - VALES
  const ctxOrdenCompra = useContext(OrdenesCompraContext);
  const { solicitudNuevaOrdenCompra } = ctxOrdenCompra;

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const administrador =
    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
    usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
    usuario.authorities.includes("ROLE_DIRECCION");   

  const validarSolicitarOrdenCompra = async () => {
    const estatusActual =
      solicitudCompraSeleccionada.estatussolicitudcompra[solicitudCompraSeleccionada.estatussolicitudcompra.length - 1]
        .catEstatussolicitudcompra;

    if (estatusActual.idCatEstatusSolicitudCompra != 5) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se puede autorizar la solicitud de compra ya que esta " + estatusActual.estatusSolicitudcompra,
      });
    }
  };

  const confirmSolicitarOC = async () => {
    let nuevaOrdenCompra = solicitudOrdenesCompraProveedores[tabIndex];

    if (!nuevaOrdenCompra.idCatAlmacen) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_generar_ordencompra: false,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione el lugar de entrega.",
      });
      return;
    }

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_generar_ordencompra: true,
    });
  };

  const solicitarOrdenCompraProveedor = async () => {
    let nuevaOrdenCompra = solicitudOrdenesCompraProveedores[tabIndex];

    if (nuevaOrdenCompra.idOrdenCompra) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_generar_ordencompra: false,
        dynamic_title: "Fallo",
        dynamic_description: "Ya se generó la orden de compra para este proveedor.",
      });
      return;
    }
  
    setLoading(true);

    nuevaOrdenCompra.idCatTipoSolicitudCompra = solicitudCompraSeleccionada.catTiposolicitudcompra.idCatTipoSolicitudCompra
    nuevaOrdenCompra.idCatTipoOrdenCompra= solicitudCompraSeleccionada.catTipoordencompra.idCatTipoOrdenCompra;
    const res = await solicitudNuevaOrdenCompra(nuevaOrdenCompra);

    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_generar_ordencompra: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (!res.data.error) {
      setLoading(false);

      solicitudOrdenesCompraProveedores[tabIndex].idOrdenCompra = res.data.idOrdenCompra;
      solicitudOrdenesCompraProveedores[tabIndex].folioOrdenCompra = res.data.folioOrdenCompra;
      setSolicitudesOrdenesCompraProveedores(solicitudOrdenesCompraProveedores);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_generar_ordencompra: false,
        dynamic_description: "Se registraron los cambios de manera exitosa!",
      });
    }
  };

  const setSolicitudCompraOrdenNueva = async () => {
    solicitudOrdenesCompraProveedores.map((ordenNueva) => {
      if (!ordenNueva.idOrdenCompra) {
        ordenNueva.idSolicitudCompra = solicitudCompraSeleccionada.idSolicitudCompra;
      }
    });
    setSolicitudesOrdenesCompraProveedores(solicitudOrdenesCompraProveedores);
  };

  const cancelarSolicitudCompraConfirm = async () => {
    let confirmCacelar = true;

    solicitudOrdenesCompraProveedores.map((orden) => {
      if (orden.idOrdenCompra) {
        confirmCacelar = false;
      }
    });

    if (!confirmCacelar) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se puede cancelar la cotización, debido a que se generó al menos una orden de compra.",
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_cancelar_solicitud: true,
      });
    }
  };

  const solicitudCompraCancelar = async () => {
    //servicio para cancelar la solicitud

    if (cancelarSolicitudCompra.comentarioEstatus == "" || cancelarSolicitudCompra.fechaCancelacion == "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor llene la información necesaria.",
      });
      return;
    }

    setLoading(true);

    const res = await uploadCancelarSolicitudCompra({
      idEstatusNuevo: 8,
      idSolicitudCompra: solicitudCompraSeleccionada.idSolicitudCompra,
      comentarioEstatus: cancelarSolicitudCompra.comentarioEstatus,
    });

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_cancelar_solicitud: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error al cancelar la solicitud de compra. Inténtalo por favor más tarde.",
      });
    } else if (!res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_cancelar_solicitud: false,
        redirect: true,
        path: "/solicitudescompra",
        dynamic_description: "Solicitud de compra cancelada",
      });

      setCancelarSolicitudCompra({
        fechaCancelacion: new Date().toISOString().slice(0, 10),
        comentarioEstatus: "",
      });
    }
  };

  const onChangeCancelarSolicitudCompra = async (e) => {
    setCancelarSolicitudCompra({
      ...cancelarSolicitudCompra,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    listarAlmacenes(false, administrador);
  }, []);

  useEffect(() => {
    setSolicitudShowEdit({
      ...solicitudShowEdit,
      hiddenCotizacion: false,
      disabledCotizacion: true,
      guardarSolicitud: true,
      finalizarAutorizacion: true,
      solicitarAutorizacion: false,
      hiddenArchivosCotizacion: true,
      disabledArchivosCotizacion: true,
      hiddenDecision: false,
      disabledDecision: false,
      cancelarSolicitud: true,
    });

    if (!errorSolicitudCompraSeleccionada && !flagSeleccionarCompra) {
      seleccionarSolicitudCompra(idSolicitudCompra);
      seleccionarOrdenesCompraProveedores(idSolicitudCompra);
      setFlagSeleccionarCompra(true);
    }

    if (!errorSolicitudCompraSeleccionada && flagSeleccionarCompra && Object.entries(solicitudCompraSeleccionada).length !== 0) {
      validarSolicitarOrdenCompra();
      setSolicitudCompraOrdenNueva();
    }

    if (errorSolicitudCompraSeleccionada) {
      props.history.push("/solicitudescompra");
    }
  }, [errorSolicitudCompraSeleccionada, solicitudCompraSeleccionada, solicitudOrdenesCompraProveedores]);

  return (
    <div className="mt-4 container-fluid">
      <InformacionGeneralSolicitudCompra props={props} />

      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <Card className="mt-4">
          <CardBody>
            <Row hidden={solicitudShowEdit.cancelarSolicitud ? false : true}>
              <Col>
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4"
                  type="button"
                  hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                  onClick={cancelarSolicitudCompraConfirm}>
                  Cancelar Solicitud
                </button>
              </Col>
            </Row>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
              <TabList>
                {Object.entries(solicitudOrdenesCompraProveedores).length !== 0 &&
                  solicitudOrdenesCompraProveedores.map((ordencompra) => {
                    return (
                      <>
                        <Tab>{ordencompra.proveedor}</Tab>
                      </>
                    );
                  })}
              </TabList>

              {Object.entries(solicitudOrdenesCompraProveedores).length !== 0 &&
                solicitudOrdenesCompraProveedores.map((ordencompra) => {
                  return (
                    <>
                      <TabPanel>
                        <TabOrdenCompraProveedor props={props} ordencompra={ordencompra} solicitarOC={confirmSolicitarOC} />
                      </TabPanel>
                    </>
                  );
                })}
            </Tabs>
          </CardBody>
        </Card>
      </div>

      {UISweetAlert.confirm_alert_generar_ordencompra ? (
        <SweetAlert
          title="¿Desea generar la orden de compra al proveedor"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => solicitarOrdenCompraProveedor()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_generar_ordencompra: false,
            })
          }>
          Deberá enviar la orden de compra al proveedor
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_cancelar_solicitud ? (
        <SweetAlert
          title="¿Desea cancelar la orden de compra?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          onConfirm={() => solicitudCompraCancelar()}
          onCancel={() => {
            setCancelarSolicitudCompra({
              ...cancelarSolicitudCompra,
              comentarioEstatus: "",
            });
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_cancelar_solicitud: false,
            });
          }}>
          <div>
            <Row>
              <Col>Llene por favor la siguiente información</Col>
            </Row>

            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Motivo</label>
              <Col sm={7}>
                <textarea
                  rows={6}
                  className="form-control"
                  name="comentarioEstatus"
                  value={cancelarSolicitudCompra.comentarioEstatus}
                  onChange={onChangeCancelarSolicitudCompra}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Fecha Cancelación</label>
              <Col sm={7}>
                <input
                  type="date"
                  className="form-control"
                  disabled={true}
                  name="fechaCancelacion"
                  value={cancelarSolicitudCompra.fechaCancelacion}
                />
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
