import React, { useReducer } from "react";

import HistoriaClinicaInicialContext from "./HistoriaClinicaInicialContext";
import HistoriaClinicaInicialReducer from "./HistoriaClinicaInicialReducer";

import { HISTORIA_CLINICA_INICIAL } from "../../types";

import clienteAxios from "../../config/axios";

export default function HistoriaClinicaInicialState(props) {
  const initialState = {
    historiaClinicaInicial: {},
  };

  const [state, dispatch] = useReducer(HistoriaClinicaInicialReducer, initialState);

  const seleccionarHistoriaClinicaInicialPaciente = async (idCatPaciente) => {
    const res = await clienteAxios.post(
      `/expedientemedico/inicial/detalle`,
      { idCatPaciente: idCatPaciente },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (res.data.paciente.fechaNacimiento) {
      var ageDifMs = Date.now() - new Date(res.data.paciente.fechaNacimiento).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      let edad = Math.abs(ageDate.getUTCFullYear() - 1970);
      res.data.paciente.edad = edad;
      res.data.paciente.fechaNacimiento = res.data.paciente.fechaNacimiento.split("T")[0];
    }

    dispatch({
      type: HISTORIA_CLINICA_INICIAL,
      payload: res.data,
    });

    return res.data;
  };

  const seleccionarHistoriaClinicaOriginalPaciente = async (idCatPaciente) => {
    const res = await clienteAxios.post(
      `/expedientemedico/inicial/detalle`,
      { idCatPaciente: idCatPaciente },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (res.data.paciente.fechaNacimiento) {
      var ageDifMs = Date.now() - new Date(res.data.paciente.fechaNacimiento).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      let edad = Math.abs(ageDate.getUTCFullYear() - 1970);
      res.data.paciente.edad = edad;
      res.data.paciente.fechaNacimiento = res.data.paciente.fechaNacimiento.split("T")[0];
    }
    return res.data;
  };

  const setHistoriaClinicaInicial = async (historiaClinicaInicial) => {
    dispatch({
      type: HISTORIA_CLINICA_INICIAL,
      payload: historiaClinicaInicial,
    });
  };

  const uploadHistoriaClinicaInicial = async (idCatMedico, resumen, revision) => {
    try {
      const res = await clienteAxios.post(
        `/expedientemedico/clinica/inicial/guardar`,
        {
          ...state.historiaClinicaInicial,
          idCatMedico: idCatMedico,
          resumen:
            resumen && resumen != ""
              ? { ...state.historiaClinicaInicial.resumen, resumen: resumen }
              : { ...state.historiaClinicaInicial.resumen },
          revisionCaso:
            revision && revision != ""
              ? { ...state.historiaClinicaInicial.revisionCaso, revisionCaso: revision }
              : { ...state.historiaClinicaInicial.revisionCaso },
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <HistoriaClinicaInicialContext.Provider
      value={{
        historiaClinicaInicial: state.historiaClinicaInicial,
        seleccionarHistoriaClinicaInicialPaciente,
        seleccionarHistoriaClinicaOriginalPaciente,
        uploadHistoriaClinicaInicial,
        setHistoriaClinicaInicial,
      }}>
      {props.children}
    </HistoriaClinicaInicialContext.Provider>
  );
}
