import {useContext} from "react";
import AuthContext from "../../context/Auth/AuthContext";
import EstadisticasAseguradora from "./EstadisticasAseguradora";
import InformeMezclas from "../Estadisticas/InformeMezclas"
import InformeFarmacia from "../Estadisticas/InformeFarmacia"
import InformeCompras from "../Estadisticas/InformeCompras"


const Estadisticas = () => {
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;
    const authorizedUserEstadisticasAseguradora = usuario && (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") 
        || usuario.authorities.includes("ROLE_ADMIN") || usuario.authorities.includes("ROLE_ASEGURADORAS") 
        || usuario.authorities.includes("ROLE_CONTABILIDAD"))

    const authorizedUserEstadisticasFarmacia = usuario && (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") 
        || usuario.authorities.includes("ROLE_ADMIN") || usuario.authorities.includes("ROLE_FARMACIA") 
        || usuario.authorities.includes("ROLE_CONTABILIDAD"))   
        
        const authorizedUserEstadisticasCentroMezclas = usuario && (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") 
        || usuario.authorities.includes("ROLE_ADMIN") || usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") 
        || usuario.authorities.includes("ROLE_CONTABILIDAD"))   
        
        const authorizedUserEstadisticasCompras = usuario && (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") 
        || usuario.authorities.includes("ROLE_ADMIN") || usuario.authorities.includes("ROLE_COMPRAS") 
        || usuario.authorities.includes("ROLE_CONTABILIDAD"))         

    return (
        <div className="mt-3 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-3">
            {authorizedUserEstadisticasAseguradora ?
                <EstadisticasAseguradora/>
                : null}

            {authorizedUserEstadisticasFarmacia ?
                <InformeFarmacia/>
                : null}     

            {authorizedUserEstadisticasCentroMezclas ?
                <InformeMezclas/>
                : null}    

            {authorizedUserEstadisticasCompras ?
                <InformeCompras/>
                : null}                                                                
        </div>
    )
}

export default Estadisticas