import React, { useReducer } from "react";

import RegistroPagosOrdenCompraContext from "./RegistroPagosOrdenCompraContext";
import RegistroPagosOrdenCompraReducer from "./RegistroPagosOrdenCompraReducer";

import {
  FILTRO_PAGOORDENCOMPRA,
  LISTAR_PAGOORDENESCOMPRA,
  REGISTRAR_ORDENES_PAGADAS,
  ERROR_REGISTRAR_ORDENES_PAGADAS,
  REGISTRAR_ORDENES_ACTUALIZADAS,
  ERROR_REGISTRAR_ORDENES_ACTUALIZADAS,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function RegistroPagosOrdenCompraState(props) {
  const initialState = {
    pagosOrdenCompraFiltradas: [],
    filtrosPagosOrdenCompra: {},
  };

  const [state, dispatch] = useReducer(RegistroPagosOrdenCompraReducer, initialState);

  const setFiltrosPagoOrdenCompra = async (filtrosPagosOrdenCompra) => {
    dispatch({
      type: FILTRO_PAGOORDENCOMPRA,
      payload: filtrosPagosOrdenCompra,
    });
  };

  const listarPagoOrdenesCompras = async (filtrosPagosOrdenCompra) => {
    const res = await clienteAxios.post(
      "/ordencompra/filtro/planeacionpago",
      filtrosPagosOrdenCompra,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
      // Se debe camabiar la ruta
    );

    res.data.map((ordenCompra) => {
      const diasPago = ordenCompra.catProveedores.diasPago;
      let dateUltimaFecha = ordenCompra.fechaRecepcion && ordenCompra.fechaRecepcion.split("T")[0];
      let fechaOrdenCompra = ordenCompra.fechaOrdenCompra.split("T")[0].split("-");

      let newDate = new Date(dateUltimaFecha);
      newDate.setDate(newDate.getDate() + diasPago);
      const fechaLimitePago = newDate.toLocaleString().split(" ")[0].split("/");

      if (fechaLimitePago[0] < 10 && fechaLimitePago[1] < 10) {
        const fechaFormateadaLimite = `0${fechaLimitePago[0]}/0${fechaLimitePago[1]}/${fechaLimitePago[2]}`;
        ordenCompra.fechaLimitePago = fechaFormateadaLimite;
      } else if (fechaLimitePago[0] < 10) {
        const fechaFormateadaLimite = `0${fechaLimitePago[0]}/${fechaLimitePago[1]}/${fechaLimitePago[2]}`;
        ordenCompra.fechaLimitePago = fechaFormateadaLimite;
      } else if (fechaLimitePago[1] < 10) {
        const fechaFormateadaLimite = `${fechaLimitePago[0]}/0${fechaLimitePago[1]}/${fechaLimitePago[2]}`;
        ordenCompra.fechaLimitePago = fechaFormateadaLimite;
      } else {
        ordenCompra.fechaLimitePago = fechaLimitePago.join("/");
      }

      dateUltimaFecha = dateUltimaFecha && dateUltimaFecha.split("-");

      if (dateUltimaFecha !== null) ordenCompra.fechaRecepcion = `${dateUltimaFecha[2]}/${dateUltimaFecha[1]}/${dateUltimaFecha[0]}`;

      ordenCompra.fechaOrdenCompra = `${fechaOrdenCompra[2]}/${fechaOrdenCompra[1]}/${fechaOrdenCompra[0]}`;

      if (ordenCompra.fechaPlaneadaPago) {
        let dateFechaPlaneada = ordenCompra.fechaPlaneadaPago.split("T")[0].split("-");
        ordenCompra.fechaPlaneadaPago = `${dateFechaPlaneada[2]}/${dateFechaPlaneada[1]}/${dateFechaPlaneada[0]}`;
      }

      if (diasPago == 0 || diasPago == null) ordenCompra.fechaLimitePago = ordenCompra.fechaRecepcion;

      return ordenCompra;
    });

    dispatch({
      type: LISTAR_PAGOORDENESCOMPRA,
      payload: res.data,
    });
  };

  const uploadNuevoRegistroPago = async (nuevoRegistroPago) => {
    try {
      const res = await clienteAxios.post(`/ordencompra/nuevopago`, nuevoRegistroPago, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      dispatch({
        type: REGISTRAR_ORDENES_PAGADAS,
        payload: state.pagoOrdenesCompraFiltradas,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_REGISTRAR_ORDENES_PAGADAS,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const updateFechaPlaneadaPago = async (ordenCompraSeleccionada) => {
    try {
      const res = await clienteAxios.post(`/ordencompra/modificar/fechaplaneadapago`, ordenCompraSeleccionada, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      dispatch({
        type: REGISTRAR_ORDENES_ACTUALIZADAS,
        payload: state.pagoOrdenesCompraFiltradas,
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_REGISTRAR_ORDENES_ACTUALIZADAS,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setPagosOrdenesCompraFiltradas = async (pagoOrdenesCompraFiltradas) => {
    dispatch({
      type: LISTAR_PAGOORDENESCOMPRA,
      payload: pagoOrdenesCompraFiltradas,
    });
  };

  return (
    <RegistroPagosOrdenCompraContext.Provider
      value={{
        pagosOrdenCompraFiltradas: state.pagosOrdenCompraFiltradas,
        filtrosPagosOrdenCompra: state.filtrosPagosOrdenCompra,

        setFiltrosPagoOrdenCompra,
        listarPagoOrdenesCompras,
        setPagosOrdenesCompraFiltradas,
        uploadNuevoRegistroPago,
        updateFechaPlaneadaPago,
      }}>
      {props.children}
    </RegistroPagosOrdenCompraContext.Provider>
  );
}
