import React, {useContext, useEffect} from "react";

import Sidebar from "./layout/Sidebar";
import Nav from "./layout/Nav";
import SidebarContext from "../context/Sidebar/SidebarContext";

import SimpleBar from "simplebar-react";
import AuthContext from "../context/Auth/AuthContext";
import NotificacionesContext from "../context/Notificaciones/NotificacionesContext";

export default function Dashboard({children, computedMatch, location}) {
    // ACCESO A ESTADO GLOBAL - SideBar
    const ctxSidebar = useContext(SidebarContext);
    const {showSidebar} = ctxSidebar;

    const authContext = useContext(AuthContext);
    const {usuarioAutenticado} = authContext;

    const notfContext = useContext(NotificacionesContext);
    // eslint-disable-next-line no-unused-vars
    const {numeroDeNotificaciones, obtenerNumeroNotificaciones} = notfContext;
    const {usuarios} = authContext;
    
    function obtenerNotifRecursivo() {
        /*let usuariotemp = usuarios;*/
        obtenerNumeroNotificaciones();
    }

    useEffect(() => {
        usuarioAutenticado(); // INCLUYE LA VERIFICACIÓN DEL USUARIO EN CADA COMPONENTE
        //obtenerNotifRecursivo();
    }, []);

    return (
        <div className="App">
            <div className="h-screen flex overflow-hidden bg-white">
                {/* Barra Lateral */}
                {!showSidebar ? (
                     
                    <SimpleBar style={{maxHeight: "100%", height: "100vh" }}>
                        <Sidebar/>
                    </SimpleBar>
                ) :   null}
                <div className="flex-1 overflow-auto focus:outline-none bg-app" tabIndex="0">
                    {/* Área de navegación superior */}
                    <Nav props={location}/>
                    <main
                        className="flex-1 relative pb-8 z-0 overflow-y-auto custom-padding"
                    >
                        {children}
                    </main>
                </div>
            </div>
        </div>
    );
}
