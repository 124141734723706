import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import FabricantesContext from "../../context/Fabricantes/FabricantesContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import LugaresContext from "../../context/Lugares/LugaresContext";

import { maxCharTelefono } from "../../config/variables";

import useWindowDimensions from "../../hooks/dimensionsHook";

const Fabricantes = () => {
  const { width } = useWindowDimensions();

  const filtrosVacios = {
    razonSocial: "",
    contacto: "",
    rfc: "",
  };

  const datosFabricanteVacio = {
    razonSocial: "",
    laboratorio: "",
    contacto: "",
    correo: "",
    telefono: "",
    rfc: "",
    calle: "",
    colonia: "",
    idEstado: "",
    municipio: "",
    numExterno: "",
    numInterno: "",
    cp: "",
    telefonoFijo: "",
    telefonoCelular: "",
  };

  const [datosNuevoFabricante, setdatosNuevoFabricante] = useState(datosFabricanteVacio);
  const [fabricanteSeleccionado, setFabricanteSeleccionado] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_fabricante: false,
    confirm_nuevo_fabricante: false,
    confirm_eliminar_fabricante: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = useState(0);
  const [data, setData] = useState([
    {
      razonSocial: "",
      laboratorio: "",
      contacto: "",
      correo: "",
      telefono: "",
      rfc: "",
      calle: "",
      colonia: "",
      idEstado: "",
      municipio: "",
      numExterno: "",
      numInterno: "",
      cp: "",
      telefonoFijo: "",
      telefonoCelular: "",
    },
  ]);

  const fetchIdRef = useRef(0);

  const lugaresContext = useContext(LugaresContext);
  const { lugares, listarLugares } = lugaresContext;

  useEffect(() => {
    listarLugares();
  }, []);

  const fabricantesContext = useContext(FabricantesContext);

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const {
    setFiltrosFabricantes,
    filtrosFabricantes,
    uploadNuevoFabricante,
    eliminarFabricante,
    setDataFabricante,
    updateFabricante,
  } = fabricantesContext;

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  const autorizado =
    usuario.authorities.includes("OP_CATALOGO_FABRICANTE_CREAR") ||
    usuario.authorities.includes("OP_CATALOGO_FABRICANTE_MODIFICAR") ||
    usuario.authorities.includes("OP_CATALOGO_FABRICANTE_ELIMINAR");

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      if (!byBusquedaFiltros) {
        setControlledPageIndex(page - 1);
      } else {
        setControlledPageIndex(0);
      }
      const response = await setDataFabricante({ page, limit });

      const data = response.laboratorios;

      const count = response.count;

      setData(data);

      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const handdleCheckedFabricante = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setFabricanteSeleccionado(row.original);
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setFabricanteSeleccionado(null);
      } else {
        setRowSeleccionada(row.id);
        setFabricanteSeleccionado(row.original);
      }
    },
    [rowSeleccionada]
  );

  // Se definen las columnas para la tabla de react-tabe
  // Se debe usar useMemo para optimización y porque lo pide la librería

  const columns = useMemo(
    () => [ 
      {
        Header: "",
        accessor: "idCatLaboratorio",
        Cell: ({ row }) => {
          return (
            <input
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
           onChange={() => handdleCheckedFabricante(row)}
            />
          ); 
        },
        show: true, 
        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Razón Social", accessor: "razonSocial", show: true },
      { Header: "Nombre", accessor: "laboratorio", show: true },
      { Header: "Contacto", accessor: "contacto", show: true },
      { Header: "Correo de Contacto", accessor: "correo", show: true },
      { Header: "Teléfono Contacto", accessor: "telefono", show: true },
      { Header: "RFC", accessor: "rfc", show: true },
      { Header: "Calle", accessor: "calle", show: true },
      { Header: "Colonia", accessor: "colonia", show: true },
      { Header: "Estado", accessor: "estado", show: true },
      { Header: "Municipio", accessor: "municipio", show: true },
      { Header: "Num. Externo", accessor: "numExterno", show: true },
      { Header: "Num. Interno", accessor: "numInterno", show: true },
      { Header: "Código Postal", accessor: "cp", show: true },
      { Header: "Teléfono Fijo", accessor: "telefonoFijo", show: true },
      { Header: "Teléfono Celular", accessor: "telefonoCelular", show: true },
    ],
    [rowSeleccionada, handdleCheckedFabricante]
  );

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function fetchData
     * @param {object} filtrosFabricantes Filtros enviados al back para filtrar los pacientes
     * @param {int} page Página a regresar de pacientes
     * @param {int} limit Número de pacientes que se van a regresar por llamada al back
     */

    setFiltrosFabricantes(filtrosFabricantes);
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosFabricantes(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltrosFabricantes({
      ...filtrosFabricantes,
      [name]: value,
    });
  };

  const changeNuevoFabricante = (e) => {
    const { name, value } = e.target;
    setdatosNuevoFabricante({
      ...datosNuevoFabricante,
      [name]: value,
    });
  };

  const changeModificarFabricante = (e) => {
    const { name, value } = e.target;
    setFabricanteSeleccionado({
      ...fabricanteSeleccionado,
      [name]: value,
    });
  };

  // Cambiar al servicio catTiposCompras

  // const tiposCompras = [
  //   {
  //     id: 1,
  //     nombre: "Crédito",
  //   },
  //   {
  //     id: 2,
  //     nombre: "Contado",
  //   },
  // ];

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Nombre Fabricante",
          type: "text",
          name: "razonSocial",
          value: filtrosFabricantes.razonSocial,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Nombre Contacto",
          type: "text",
          name: "contacto",
          value: filtrosFabricantes.contacto,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "RFC",
          type: "text",
          name: "rfc",
          value: filtrosFabricantes.rfc,
          onChange: onChange,
        },
      ],
    },
  ];

  const confirmNuevoFabricante = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nuevo_fabricante: true,
      });
    }
  };

  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneformat = /^\d{10}$/;
  // const rfcformat = /^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?$/;
  const cpformat = /^\d{4,5}$/;

  const validarDatosFabricante = (fabricante) => {
    let errorNuevoFabricante = false;
    if (!errorNuevoFabricante && (fabricante.laboratorio === "" || fabricante.laboratorio === null)) {
      errorNuevoFabricante = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el nombre del fabricante",
      });
    }
    if (!errorNuevoFabricante && (fabricante.razonSocial === "" || fabricante.razonSocial === null)) {
      errorNuevoFabricante = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la razón social del fabricante",
      });
    }
    // if (!errorNuevoFabricante && (fabricante.contacto === "" || fabricante.contacto === null)) {
    //   errorNuevoFabricante = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el nombre del contacto",
    //   });
    // }
    // if (!errorNuevoFabricante && (fabricante.correo === "" || fabricante.correo === null)) {
    //   errorNuevoFabricante = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el correo del contacto",
    //   });
    // }
    if (fabricante.correo)
      if (!errorNuevoFabricante && fabricante.correo !== "" && !fabricante.correo.match(mailformat)) {
        errorNuevoFabricante = true;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, ingrese un correo de contacto válido",
        });
      }
    // if (!errorNuevoFabricante && (fabricante.telefono === "" || fabricante.telefono === null)) {
    //   errorNuevoFabricante = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el teléfono del contacto",
    //   });
    // }
    if (fabricante.telefono)
      if (!errorNuevoFabricante && fabricante.telefono !== "" && !fabricante.telefono.match(phoneformat)) {
        errorNuevoFabricante = true;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, ingrese un teléfono de contacto válido",
        });
      }
    // if (!errorNuevoFabricante && (fabricante.rfc === "" || fabricante.rfc === null)) {
    //   errorNuevoFabricante = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el RFC de la fabricante",
    //   });
    // }
    // if (!errorNuevoFabricante && !fabricante.rfc.match(rfcformat)) {
    //   errorNuevoFabricante = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese un RFC válido",
    //   });
    // }

    // if (!errorNuevoFabricante && (fabricante.cp === "" || fabricante.cp === null)) {
    //   errorNuevoFabricante = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el código postal del fabricante",
    //   });
    // }
    if (fabricante.cp)
      if (!errorNuevoFabricante && fabricante.cp !== "" && !fabricante.cp.toString().match(cpformat)) {
        errorNuevoFabricante = true;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, ingrese un código postal válido",
        });
      }
    // if (!errorNuevoFabricante && (fabricante.telefonoFijo === "" || fabricante.telefonoFijo === null)) {
    //   errorNuevoFabricante = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el teléfono fijo",
    //   });
    // }
    if (fabricante.telefonoFijo)
      if (!errorNuevoFabricante && fabricante.telefonoFijo !== "" && !fabricante.telefonoFijo.match(phoneformat)) {
        errorNuevoFabricante = true;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, ingrese un teléfono fijo válido",
        });
      }
    // if (!errorNuevoFabricante && (fabricante.telefonoCelular === "" || fabricante.telefonoCelular === null)) {
    //   errorNuevoFabricante = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el teléfono celular",
    //   });
    // }
    if (fabricante.telefonoCelular)
      if (!errorNuevoFabricante && fabricante.telefonoCelular !== "" && !fabricante.telefonoCelular.match(phoneformat)) {
        errorNuevoFabricante = true;
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor, ingrese un teléfono celular válido",
        });
      }

    if (!errorNuevoFabricante && (fabricante.diasPago === "" || fabricante.diasPago === null)) {
      errorNuevoFabricante = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese los días de pago",
      });
    }
    if (!errorNuevoFabricante && (fabricante.idCatTipoCompra === "" || fabricante.idCatTipoCompra === null)) {
      errorNuevoFabricante = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el tipo de compra",
      });
    }
    // if (!errorNuevoFabricante && (fabricante.idCatAlmacen === "" || fabricante.idCatAlmacen === null)) {
    //   errorNuevoFabricante = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el almacen",
    //   });
    // }

    return errorNuevoFabricante;
  };

  const agregarNuevoFabricante = async () => {
    const fabricanteValido = validarDatosFabricante(datosNuevoFabricante);
    if (!fabricanteValido) {
      setLoading(true);

      const res = await uploadNuevoFabricante(datosNuevoFabricante);

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nuevo_fabricante: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nuevo_fabricante: false,
          dynamic_description: "Se guardó el nuevo fabricante correctamente",
        });
        setdatosNuevoFabricante(datosFabricanteVacio);
      }
    }
  };

  const updateDataOnDeleteFabricante = async (idCatLaboratorio) => {
    const updatedData = data.filter((fabricante) => fabricante.idCatLaboratorio != idCatLaboratorio);
    setData(updatedData);
  };

  const checkEliminarFabricante = () => {
    if (autorizado && fabricanteSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_fabricante: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un fabricante",
      });
    }
  };

  const confirmEliminarFabricante = async () => {
    setLoading(true);
    const res = await eliminarFabricante(fabricanteSeleccionado.idCatLaboratorio);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_fabricante: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_fabricante: false,
        dynamic_description: "Se eliminó el fabricante correctamente",
      });
      updateDataOnDeleteFabricante(fabricanteSeleccionado.idCatLaboratorio);
      setFabricanteSeleccionado(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarFabricante = () => {
    if (autorizado && fabricanteSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_fabricante: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un fabricante",
      });
    }
  };

  const confirmModicarFabricante = async () => {
    const fabricanteValido = validarDatosFabricante(fabricanteSeleccionado);
    if (!fabricanteValido) {
      setLoading(true);
      const res = await updateFabricante(fabricanteSeleccionado);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_fabricante: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_fabricante: false,
          dynamic_description: "Se modificó el fabricante correctamente",
        });

        const fabricantesActualizados = await data.map((fabricante) => {
          if (res.data.idCatLaboratorio == fabricante.idCatLaboratorio) {
            return (fabricante = res.data);
          } else {
            return fabricante;
          }
        });

        setData(fabricantesActualizados);
        setFabricanteSeleccionado(null);
        setRowSeleccionada(null);
      }
    }
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Catálogo de Fabricantes</h3>
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de fabricantes</h4>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevoFabricante()}>
                      Nueva fabricante
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarFabricante()}>
                      Modificar fabricante
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarFabricante()}>
                      Eliminar fabricante
                    </button>
                  ) : null}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={data}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      
      {UISweetAlert.confirm_nuevo_fabricante ? (
        <SweetAlert
          title="Nuevo fabricante"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            agregarNuevoFabricante();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nuevo_fabricante: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">
                    Nombre fabricante<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="laboratorio"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.laboratorio ? datosNuevoFabricante.laboratorio : ""}
                    />
                    {datosNuevoFabricante.laboratorio === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-4 col-form-label">
                    Razón Social<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="razonSocial"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.razonSocial ? datosNuevoFabricante.razonSocial : ""}
                    />
                    {datosNuevoFabricante.razonSocial === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-4 col-form-label">Nombre Contacto</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="contacto"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.contacto ? datosNuevoFabricante.contacto : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Correo Contacto</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="correo"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.correo ? datosNuevoFabricante.correo : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Contacto</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="telefono"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.telefono ? datosNuevoFabricante.telefono : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">RFC</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="rfc"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.rfc ? datosNuevoFabricante.rfc : ""}
                    />
                    {/* {datosNuevoFabricante.rfc === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    {datosNuevoFabricante.rfc &&
                      !datosNuevoFabricante.rfc.match(rfcformat) &&
                      datosNuevoFabricante.rfc !== "" && <span className="text-sm text-red-700">RFC inválido</span>} */}
                  </Col>

                  <label className="col-sm-4 col-form-label">Calle</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="calle"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.calle ? datosNuevoFabricante.calle : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Colonia</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="colonia"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.colonia ? datosNuevoFabricante.colonia : ""}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">Estado</label>
                  <Col sm={7} className="pb-3">
                    <select
                      className="form-control"
                      onChange={changeNuevoFabricante}
                      name="idEstado"
                      value={datosNuevoFabricante.idEstado}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {lugares.map((lugar) => {
                        return <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>;
                      })}
                    </select>
                  </Col>

                  <label className="col-sm-4 col-form-label">Municipio</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="municipio"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.municipio ? datosNuevoFabricante.municipio : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Num. Externo</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="numExterno"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.numExterno ? datosNuevoFabricante.numExterno : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Num. Interno</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="numInterno"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.numInterno ? datosNuevoFabricante.numInterno : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Código Postal</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="cp"
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.cp ? datosNuevoFabricante.cp : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Fijo</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoFijo"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.telefonoFijo ? datosNuevoFabricante.telefonoFijo : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Celular</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoCelular"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevoFabricante}
                      value={datosNuevoFabricante.telefonoCelular ? datosNuevoFabricante.telefonoCelular : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label"></label>
                  <Col sm={7} className="pb-3">
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => setdatosNuevoFabricante(datosFabricanteVacio)}>
                      Limpiar Datos
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}
      {UISweetAlert.confirm_eliminar_fabricante ? (
        <SweetAlert
          title="¿Desea eliminar al fabricante seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: width && width < 851 ? "100%" : "50%",
          }}
          onConfirm={() => confirmEliminarFabricante()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_fabricante: false,
            });
          }}></SweetAlert>
      ) : null}
      {UISweetAlert.confirm_modificar_fabricante ? (
        <SweetAlert
          title="Modificar Datos del fabricante"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          showCancel
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            confirmModicarFabricante();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_fabricante: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">Nombre fabricante</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="laboratorio"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.laboratorio ? fabricanteSeleccionado.laboratorio : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Razón Social</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="razonSocial"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.razonSocial ? fabricanteSeleccionado.razonSocial : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Nombre Contacto</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="contacto"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.contacto ? fabricanteSeleccionado.contacto : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Correo Contacto</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="correo"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.correo ? fabricanteSeleccionado.correo : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Contacto</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefono"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.telefono ? fabricanteSeleccionado.telefono : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">RFC</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="rfc"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.rfc ? fabricanteSeleccionado.rfc : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Calle</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="calle"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.calle ? fabricanteSeleccionado.calle : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Colonia</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="colonia"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.colonia ? fabricanteSeleccionado.colonia : ""}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">Estado</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      onChange={changeModificarFabricante}
                      name="idEstado"
                      value={fabricanteSeleccionado.idEstado}>
                      <option selected disabled>
                        Seleccione una opción
                      </option>
                      {lugares.map((lugar) => {
                        return <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>;
                      })}
                    </select>
                  </Col>

                  <label className="col-sm-4 col-form-label">Municipio</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="municipio"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.municipio ? fabricanteSeleccionado.municipio : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Num. Externo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numExterno"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.numExterno ? fabricanteSeleccionado.numExterno : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Num. Interno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numInterno"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.numInterno ? fabricanteSeleccionado.numInterno : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Código Postal</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cp"
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.cp ? fabricanteSeleccionado.cp : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Fijo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoFijo"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.telefonoFijo ? fabricanteSeleccionado.telefonoFijo : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Celular</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoCelular"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarFabricante}
                      value={fabricanteSeleccionado.telefonoCelular ? fabricanteSeleccionado.telefonoCelular : ""}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default Fabricantes;
