import React, { useContext, useEffect , useState} from "react";
import { useParams } from "react-router-dom";

import ValesContext from "../../context/Vales/ValesContext";
import InfoGeneral from "../InformacionGeneralVale/index";
import ListaInsumos from "../ListadoInsumos/index";
import ListadoEstatusVale from "../ListadoEstatusVale/index";
import EstatusHistorico from "../EstatusValeAutorizaciones/index";

export default function HistoricoVale(props) {
  const { idVale } = useParams();
  const [requiereAutorizacion, setRequiereAutorizacion] = useState(false);
  // ACCESO A ESTADO GLOBAL - VALES
  const ctxVales = useContext(ValesContext);
  const { seleccionarVale } = ctxVales;

  useEffect(() => {
    seleccionarVale(idVale, true);
  }, []);

  return (
    <div className="mt-4">
      <InfoGeneral props={props} />
      <EstatusHistorico props={props} />
      <ListaInsumos props={props} setRequiereAutorizacion={setRequiereAutorizacion} requiereAutorizacion={requiereAutorizacion} />
      <ListadoEstatusVale props={props} />
    </div>
  );
}
