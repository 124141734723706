import React, {useReducer} from 'react'

import CatEstatusBajaInventarioContext from './CatEstatusBajaInventarioContext'
import CatEstatusBajaInventarioReducer from './CatEstatusBajaInventarioReducer'

import {
    LISTAR_CATESTATUSBAJAINVENTARIO,
} from '../../types'

import clienteAxios from '../../config/axios'


export default function CatEstatusBajaInventarioState (props) {

    const initialState = { 
        catEstatusBajaInventario:[],
    }

    const [state, dispatch] = useReducer(CatEstatusBajaInventarioReducer,initialState)

    const listarCatEstatusBajaInventario = async () => {

        const res = await clienteAxios.get(`/estatusbajainventario/activos`, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })


        dispatch({
            type: LISTAR_CATESTATUSBAJAINVENTARIO,
            payload: res.data
        })

        
    }

    return (

        <CatEstatusBajaInventarioContext.Provider value={{
            catEstatusBajaInventario: state.catEstatusBajaInventario,
            listarCatEstatusBajaInventario,
        }}>
            {props.children}
        </CatEstatusBajaInventarioContext.Provider>

    )


}