// THEME SETTINGS: vertical / fluid / dark / left sidebar type default / left sidebar theme dark

import "./assets/scss/theme.scss";
import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";

import AppStateManager from "./AppStateManager";

function App() {
  return (
    <Router>
      <AppStateManager />
    </Router>
  );
}

export default App;
