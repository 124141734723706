import React, {useReducer} from 'react'

import CatTipoInterconsultaContext from './CatTipoInterconsultaContext'
import CatTipoInterconsultaReducer from './CatTipoInterconsultaReducer'

import {
    LISTAR_CATTIPOINTERCONSULTA
} from '../../types'

import clienteAxios from '../../config/axios'


export default function CatTipoInterconsultaState (props) {

    const initialState = { 
        tipoInterconsultas:[],
    }

    const [state, dispatch] = useReducer(CatTipoInterconsultaReducer,initialState)

    const listarTipoInterconsultas = async () => {

        const res = await clienteAxios.get("/tipointerconsulta/activos"  , {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
        dispatch({
            type: LISTAR_CATTIPOINTERCONSULTA,
            payload: res.data
        })
    }

    return (

        <CatTipoInterconsultaContext.Provider value={{
            tipoInterconsultas: state.tipoInterconsultas,
            listarTipoInterconsultas,
        }}>
            {props.children}
        </CatTipoInterconsultaContext.Provider>

    )


}