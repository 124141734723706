import React, { useReducer } from "react";
import { LISTAR_CONFIGURACION } from "../../types";

import clienteAxios from "../../config/axios";
import ConfiguracionReducer from "./ConfiguracionReducer";
import ConfiguracionContext from "./ConfiguracionContext";

export default function ConfiguracionState(props) {
  const initialState = {
    configuracion: [],
  };

  const [state, dispatch] = useReducer(ConfiguracionReducer, initialState);

  const listarConfiguracion = async () => {
    const res = await clienteAxios.get(`/configuracion/activos`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_CONFIGURACION,
      payload: res.data,
    });
  };

  const getIvaConfiguracion = async () => {
    const res = await clienteAxios.get(`/configuracion/activos`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });
    let iva;
    res.data.map((config) => {
      if (config.clave == "IVA") {
        iva = parseFloat(config.valor) / 100;
      }
    });

    return iva;
  };

  return (
    <ConfiguracionContext.Provider
      value={{
        configuracion: state.configuracion,
        listarConfiguracion,
        getIvaConfiguracion,
      }}>
      {props.children}
    </ConfiguracionContext.Provider>
  );
}
