import {
    LISTAR_ESTATUSVENTASMOSTRADOR,
    ESTATUS_CANCELADO,
    ESTATUS_COBRO
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_ESTATUSVENTASMOSTRADOR: 
            return{
                ...state,
                estatusVentaMostrador: action.payload
            }
        case ESTATUS_CANCELADO:
            return{
                ...state,
                estatusCancelado: action.payload
            }
        case ESTATUS_COBRO:
            return{
                ...state,
                estatusCobrado: action.payload
            }
        default:
            return state
    }
        
}