import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import formatNumber from "../../../utils";

import CortesCajaContext from "../../../context/CortesCaja/CortesCajaContext";

export default function DetalleCorteCajaFarmacia(props) {
  const [detallesFiltradosCorteCaja, setDetallesFiltradosCorteCaja] = useState();
  const [totalesVales, setTotalesVales] = useState({
    totalEfectivoFisico: 0,
    totalEfectivoDeposito: 0,
    totalCredito: 0,
    totalDebito: 0,
    totalTransferencia: 0,
    totalImportes: 0,
  });
  const [totalesVentasMostrador, setTotalesVentasMostrador] = useState({
    totalEfectivoFisico: 0,
    totalEfectivoDeposito: 0,
    totalCredito: 0,
    totalDebito: 0,
    totalTransferencia: 0,
    totalImportes: 0,
  });

  const cortesCajaContext = useContext(CortesCajaContext);

  const { setDetallesCorteCaja } = cortesCajaContext;

  const setTotalesValesFiltrados = async (vales) => {
    let accEfectivo = 0;
    let accEfectivoDeposito = 0;
    let accCredito = 0;
    let accDebito = 0;
    let accTransferencia = 0;
    let accImportesTotales = 0;
    vales &&
      vales.map((vale) => {
        accEfectivo += vale.importeEfectivo;
        accEfectivoDeposito += vale.importeEfectivoDeposito;
        accCredito += vale.importeTarjetaCredito;
        accDebito += vale.importeTarjetaDebito;
        accTransferencia += vale.importeTransferencia;
        accImportesTotales += vale.importeTotal;
      });
    setTotalesVales({
      totalEfectivoFisico: accEfectivo,
      totalEfectivoDeposito: accEfectivoDeposito,
      totalCredito: accCredito,
      totalDebito: accDebito,
      totalTransferencia: accTransferencia,
      totalImportes: accImportesTotales,
    });
  };
  const setTotalesVentasFiltradas = async (ventasMostrador) => {
    let accEfectivo = 0;
    let accEfectivoDeposito = 0;
    let accCredito = 0;
    let accDebito = 0;
    let accTransferencia = 0;
    let accImportesTotales = 0;
    ventasMostrador &&
      ventasMostrador.map((ventaMostrador) => {
        accEfectivo += ventaMostrador.importeEfectivo;
        accEfectivoDeposito += ventaMostrador.importeEfectivoDeposito;
        accCredito += ventaMostrador.importeTarjetaCredito;
        accDebito += ventaMostrador.importeTarjetaDebito;
        accTransferencia += ventaMostrador.importeTransferencia;
        accImportesTotales += ventaMostrador.importeTotal;
      });
    setTotalesVentasMostrador({
      totalEfectivo: accEfectivo,
      totalEfectivoDeposito: accEfectivoDeposito,
      totalCredito: accCredito,
      totalDebito: accDebito,
      totalTransferencia: accTransferencia,
      totalImportes: accImportesTotales,
    });
  };
  useEffect(() => {
    async function getDetalles() {
      let res = await setDetallesCorteCaja(props);
      setDetallesFiltradosCorteCaja(res);
      if (res.vales) setTotalesValesFiltrados(res.vales);
      if (res.ventasMostrador) setTotalesVentasFiltradas(res.ventasMostrador);
    }

    getDetalles();
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Detalle del Corte de Caja (Farmacia)</h3>

        <h4 className="flex mt-5">Detalles de Movimientos (Vales)</h4>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">
                          Fecha y<br></br>Hora
                        </th>
                        <th scope="col">Vale</th>
                        <th scope="col">Médico</th>
                        <th scope="col">Paciente</th>
                        <th scope="col">Efectivo Físico</th>
                        <th scope="col">
                          Efectivo Depósito<br></br>en Cuenta
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Crédito
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Débito
                        </th>
                        <th scope="col">Transferencia</th>
                        <th scope="col">
                          Importe<br></br>Total
                        </th>
                        <th scope="col">Comentario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.vales
                        ? detallesFiltradosCorteCaja.vales.map((vale, index) => {
                            return (
                              <>
                                <tr key={vale.idVale}>
                                  <td>{vale.fechaHora ? vale.fechaHora : ""}</td>
                                  <td>{vale.folio ? vale.folio : ""}</td>
                                  <td>{vale.medico ? vale.medico : ""}</td>

                                  <td>{vale.paciente ? vale.paciente : ""}</td>
                                  <td>{vale.importeEfectivoFisico ? formatNumber(vale.importeEfectivoFisico) : "$0"}</td>
                                  <td>{vale.importeEfectivoDeposito ? formatNumber(vale.importeEfectivoDeposito) : "$0"}</td>
                                  <td>{vale.importeTarjetaCredito ? formatNumber(vale.importeTarjetaCredito) : "$0"}</td>
                                  <td>{vale.importeTarjetaDebito ? formatNumber(vale.importeTarjetaDebito) : "$0"}</td>

                                  <td>{vale.importeTransferencia ? formatNumber(vale.importeTransferencia) : "$0"}</td>
                                  <td>{vale.importeTotal ? formatNumber(vale.importeTotal) : "$0"}</td>

                                  <td>{vale.comentario ? vale.comentario : ""}</td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                    <thead>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.vales ? (
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">TOTALES</th>
                          <th scope="col">{totalesVales.totalEfectivo ? formatNumber(totalesVales.totalEfectivo) : "$0"}</th>
                          <th scope="col">
                            {totalesVales.totalEfectivoDeposito ? formatNumber(totalesVales.totalEfectivoDeposito) : "$0"}
                          </th>
                          <th scope="col">{totalesVales.totalCredito ? formatNumber(totalesVales.totalCredito) : "$0"}</th>
                          <th scope="col">{totalesVales.totalDebito ? formatNumber(totalesVales.totalDebito) : "$0"}</th>
                          <th scope="col">
                            {totalesVales.totalTransferencia ? formatNumber(totalesVales.totalTransferencia) : "$0"}
                          </th>
                          <th scope="col">{totalesVales.totalImportes ? formatNumber(totalesVales.totalImportes) : "$0"}</th>
                        </tr>
                      ) : null}
                    </thead>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <h4 className="flex mt-5">Detalles de Movimientos (Venta Mostrador)</h4>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">
                          Fecha y<br></br>Hora
                        </th>
                        <th scope="col">
                          Venta<br></br>Mostrador
                        </th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">
                          Efectivo<br></br>Físico
                        </th>
                        <th scope="col">
                          Efectivo Depósito<br></br>en Cuenta
                        </th>
                        <th scope="col">
                          Tarjta<br></br>Crédito
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Débito
                        </th>
                        <th scope="col">Transferencia</th>
                        <th scope="col">
                          Importe<br></br>Total
                        </th>
                        <th scope="col">Comentario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.ventasMostrador
                        ? detallesFiltradosCorteCaja.ventasMostrador.map((ventaMostrador, index) => {
                            return (
                              <>
                                <tr key={ventaMostrador.idVentaMostrador}>
                                  <td>{ventaMostrador.fechaHora ? ventaMostrador.fechaHora : ""}</td>
                                  <td>{ventaMostrador.folio ? ventaMostrador.folio : ""}</td>
                                  <td>{ventaMostrador.movimiento ? ventaMostrador.movimiento : ""}</td>
                                  <td>{ventaMostrador.cliente ? ventaMostrador.cliente : ""}</td>
                                  <td>
                                    {ventaMostrador.importeEfectivoFisico
                                      ? formatNumber(ventaMostrador.importeEfectivoFisico)
                                      : "$0"}
                                  </td>
                                  <td>
                                    {ventaMostrador.importeEfectivoDeposito
                                      ? formatNumber(ventaMostrador.importeEfectivoDeposito)
                                      : "$0"}
                                  </td>
                                  <td>
                                    {ventaMostrador.importeTarjetaCredito
                                      ? formatNumber(ventaMostrador.importeTarjetaCredito)
                                      : "$0"}
                                  </td>
                                  <td>
                                    {ventaMostrador.importeTarjetaDebito
                                      ? formatNumber(ventaMostrador.importeTarjetaDebito)
                                      : "$0"}
                                  </td>

                                  <td>
                                    {ventaMostrador.importeTransferencia
                                      ? formatNumber(ventaMostrador.importeTransferencia)
                                      : "$0"}
                                  </td>
                                  <td>{ventaMostrador.importeTotal ? formatNumber(ventaMostrador.importeTotal) : ""}</td>
                                  <td>{ventaMostrador.comentario ? ventaMostrador.comentario : ""}</td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                    <thead>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.ventasMostrador ? (
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">TOTALES</th>
                          <th scope="col">
                            {totalesVentasMostrador.totalEfectivoFisico
                              ? formatNumber(totalesVentasMostrador.totalEfectivoFisico)
                              : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVentasMostrador.totalEfectivoDeposito
                              ? formatNumber(totalesVentasMostrador.totalEfectivoDeposito)
                              : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVentasMostrador.totalCredito ? formatNumber(totalesVentasMostrador.totalCredito) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVentasMostrador.totalDebito ? formatNumber(totalesVentasMostrador.totalDebito) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVentasMostrador.totalTransferencia
                              ? formatNumber(totalesVentasMostrador.totalTransferencia)
                              : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVentasMostrador.totalImportes ? formatNumber(totalesVentasMostrador.totalImportes) : "$0"}
                          </th>
                        </tr>
                      ) : null}
                    </thead>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
