import React, {useReducer} from 'react'

import CatTipoVentaMostradorContext from './CatTipoVentaMostradorContext'
import CatTipoVentaMostradorReducer from './CatTipoVentaMostradorReducer'

import {
    LISTAR_CATTIPOVENTAMOSTRADOR,
} from '../../types'

import clienteAxios from '../../config/axios'


export default function CatTipoVentaMostradorState (props) {

    const initialState = { 
        tipoVentaMostrador:[],
    }

    const [state, dispatch] = useReducer(CatTipoVentaMostradorReducer,initialState)

    const listarTiposVentaMostrador = async () => {

        const res = await clienteAxios.get("/tipoventamostrador/activos"  , {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })

        dispatch({
            type: LISTAR_CATTIPOVENTAMOSTRADOR,
            payload: res.data
        })
    }


    return (

        <CatTipoVentaMostradorContext.Provider value={{
            tipoVentaMostrador: state.tipoVentaMostrador,
            listarTiposVentaMostrador,
        }}>
            {props.children}
        </CatTipoVentaMostradorContext.Provider>

    )


}