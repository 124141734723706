/* eslint-disable import/no-anonymous-default-export */
import {
    LISTAR_ROLES,
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_ROLES:
            return{
                ...state,
                roles: action.payload
            };
        default:
            return state
    }
        
}