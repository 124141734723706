import React, { useContext } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col } from "reactstrap";
// import useCloseWindowWarning from "../../../hooks/useCloseWindowWarning";

import WindowWarningContext from "../../../context/WindowWarning/WindowWarningContext";

import { maxCamposAntecedentesPatologicos } from "../../../config/variables";

export default function AntecedentesPersonalesPatologicos({ props, historiaClinicaInicial, setHistoriaClinicaInicial }) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito } = windowWarningContext;

  // const [Prompt, setEditTrue, setEditFalse, isEdit] = useCloseWindowWarning();

  const onChange = async (e) => {
    // if (!isEdit) setEditTrue();
    if (!seEdito) changeEdit(true);
    setHistoriaClinicaInicial({
      ...historiaClinicaInicial,
      antecedentesPersonalesPatologicos: {
        ...historiaClinicaInicial.antecedentesPersonalesPatologicos,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <div className="mt-4 container-fluid" style={{ textAlign: "center" }}>
      <Row>
        <h4>Antecedentes Personales Patológicos</h4>
      </Row>
      <Row>
        <Col>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Cronicodegenerativos</label>
            <Col sm={10}>
              <textarea
                name="cronicodegenerativos"
                value={
                  historiaClinicaInicial.antecedentesPersonalesPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesPatologicos.cronicodegenerativos
                    ? historiaClinicaInicial.antecedentesPersonalesPatologicos.cronicodegenerativos
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Traumatismos</label>
            <Col sm={10}>
              <textarea
                name="traumatismos"
                value={
                  historiaClinicaInicial.antecedentesPersonalesPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesPatologicos.traumatismos
                    ? historiaClinicaInicial.antecedentesPersonalesPatologicos.traumatismos
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Transfusiones</label>
            <Col sm={10}>
              <textarea
                type="text"
                name="transfusiones"
                maxLength={maxCamposAntecedentesPatologicos}
                value={
                  historiaClinicaInicial.antecedentesPersonalesPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesPatologicos.transfusiones
                    ? historiaClinicaInicial.antecedentesPersonalesPatologicos.transfusiones
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Quirúrgicos</label>
            <Col sm={10}>
              <textarea
                name="quirurgicos"
                value={
                  historiaClinicaInicial.antecedentesPersonalesPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesPatologicos.quirurgicos
                    ? historiaClinicaInicial.antecedentesPersonalesPatologicos.quirurgicos
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Alergias</label>
            <Col sm={10}>
              <textarea
                type="text"
                name="alergias"
                maxLength={maxCamposAntecedentesPatologicos}
                value={
                  historiaClinicaInicial.antecedentesPersonalesPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesPatologicos.alergias
                    ? historiaClinicaInicial.antecedentesPersonalesPatologicos.alergias
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          {/* <Row className="form-group">
            <label className="col-sm-2 col-form-label">Consumo de Drogas</label>
            <Col sm={10}>
              <input
                type="text"
                name="consumoDrogas"
                value={
                  historiaClinicaInicial.antecedentesPersonalesPatologicos && historiaClinicaInicial.antecedentesPersonalesPatologicos.consumoDrogas
                    ? historiaClinicaInicial.antecedentesPersonalesPatologicos.consumoDrogas
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row> */}
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Otros</label>
            <Col sm={10}>
              <textarea
                name="otros"
                value={
                  historiaClinicaInicial.antecedentesPersonalesPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesPatologicos.otros
                    ? historiaClinicaInicial.antecedentesPersonalesPatologicos.otros
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* {Prompt} */}
    </div>
  );
}
