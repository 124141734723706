import React, { useState, useEffect, useContext } from "react";
import { Row, Col, CardBody } from "reactstrap";

import CatProductosContext from "../../context/CatProductos/CatProductosContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import CatTipoProductoContext from "../../context/CatTipoProducto/CatTipoProductoContext";
import CatTipoPrecioContext from "../../context/CatTipoPrecio/CatTipoPrecioContext";
import "antd/dist/antd.min.css";
import { Select, Progress } from "antd";

const { Option } = Select;

const CargaMasivaPrecios = () => {
  const [datosCargaArchivo, setDatosCargaArchivo] = useState({
    selectedFile: null,
    aseguradoras: null,
  });

  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState(null);

  const catProductosContext = useContext(CatProductosContext);

  const { uploadPrecios, descargarFormatoPrecios } = catProductosContext;

  const aseguradorasContext = useContext(AseguradorasContext);

  const { aseguradoras, listarAseguradoras } = aseguradorasContext;

  useEffect(() => {
    // if (aseguradoras.length <= 0) return
    listarAseguradoras();
  }, []);

  const catTipoProductoContext = useContext(CatTipoProductoContext);

  const { tiposProductos, listarProductoTiposProductos } = catTipoProductoContext;

  useEffect(() => {
    // if (tiposProductos.length <= 0) return
    listarProductoTiposProductos();
  }, []);

  const catTipoPrecioContext = useContext(CatTipoPrecioContext);
  const { tipoPrecios, listarCatTipoPrecios } = catTipoPrecioContext;

  useEffect(() => {
    // if (tipoPrecios.length <= 0) return
    listarCatTipoPrecios();
  }, []);

  const onFileChange = (event) => {
    setDatosCargaArchivo({ ...datosCargaArchivo, selectedFile: event.target.files[0] });
    const file = event.target.files[0];
    const reader = new FileReader();

    // convert image file to base64 string

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string

        let strFile = reader.result;
        let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
        let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);
        setDatosCargaArchivo({
          ...datosCargaArchivo,
          selectedFile: file,
          nombreArchivo: file.name,
          mimeType: type,
          tamano: file.size,
          inputStream: fileBase64,
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const changeCargaArchivo = (e) => {
    const { name, value } = e.target;
    setDatosCargaArchivo({
      ...datosCargaArchivo,
      [name]: value,
    });
  };

  const children = [];
  aseguradoras.forEach((element) => {
    children.push(<Option key={element.idCatAseguradora}>{element.razonSocial}</Option>);
  });

  function handleChange(value) {
    setDatosCargaArchivo({ ...datosCargaArchivo, aseguradoras: value });
  }

  const fileData = () => {
    if (datosCargaArchivo.selectedFile) {
      return (
        <>
          <label className="col-sm-5 col-form-label">Nombre del Archivo</label>
          <Col sm={7}>
            <input
              disabled={true}
              type="text"
              className="form-control"
              autoComplete="off"
              value={datosCargaArchivo.selectedFile.name}
            />
          </Col>
          <label className="col-sm-5 col-form-label">Tipo de Archivo</label>
          <Col sm={7}>
            <input
              disabled={true}
              type="text"
              className="form-control"
              autoComplete="off"
              value={datosCargaArchivo.selectedFile.type}
            />
          </Col>
        </>
      );
    } else {
      return (
        <>
          <br />
          <label className="col-sm-5 col-form-label">Elige un archivo antes de confirmar</label>
        </>
      );
    }
  };

  const cargaPrecios = () => {
    const catAseguradoras =
      datosCargaArchivo.aseguradoras != null && datosCargaArchivo.aseguradoras.length > 0
        ? datosCargaArchivo.aseguradoras.map((aseguradora) => {
            return { idCatAseguradora: aseguradora };
          })
        : [];
    if (!datosCargaArchivo.selectedFile && !datosCargaArchivo.idCatTipoProducto && !datosCargaArchivo.idCatTipoPrecio) return;
    uploadPrecios(
      { ...datosCargaArchivo, catAseguradoras: catAseguradoras, idCatTipoProducto: datosCargaArchivo.idCatTipoProducto },
      (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      }
    )
      .then((response) => {
        setMessage("Archivo Cargado Correctamente");
      })
      .catch(() => {
        setProgress(0);
        setMessage("Error en la carga de archivo");
      });
  };

  const descargarFormato = async () => {
    descargarFormatoPrecios();
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3 mb-5">Carga Masiva de Precios</h3>
      <CardBody>
        <Row>
          <Col xl={6} lg={12} md={12}>
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">Archivo de Carga</label>
              <Col sm={7}>
                <input type="file" onChange={onFileChange} />
              </Col>
              {fileData()}
              {message}
              <Progress percent={progress} />
            </Row>
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              type="button"
              style={{
                marginRight: "2.5rem",
                marginTop: "1rem",
                background: "#ff9933",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() => descargarFormato()}>
              Descarga de Formato
              {/* <i className="ti-download pl-2"></i> */}
            </button>
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              type="button"
              style={{
                marginRight: "2.5rem",
                marginTop: "1rem",
                background: "#ff9933",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() => cargaPrecios()}>
              Cargar Precios
              {/* <i className="ti-upload pl-2"></i> */}
            </button>
          </Col>

          <Col xl={6} lg={12} md={12}>
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">Tipo de Producto</label>
              <Col sm={7}>
                <select className="form-control" name="idCatTipoProducto" id="idCatTipoProducto" onChange={changeCargaArchivo}>
                  <option disabled selected value="">
                    Seleccione una opción
                  </option>
                  {tiposProductos.map((tipoProducto) => {
                    return (
                      <>
                        <option value={tipoProducto.idCatTipoProducto}>{tipoProducto.tipoProducto}</option>
                      </>
                    );
                  })}
                </select>
              </Col>
              <label className="col-sm-4 col-form-label">Tipo de Precio</label>
              <Col sm={7}>
                <select className="form-control" name="idCatTipoPrecio" id="idCatTipoPrecio" onChange={changeCargaArchivo}>
                  <option disabled selected value="">
                    Seleccione una opción
                  </option>
                  {tipoPrecios.map((tipoPrecio) => {
                    return (
                      <>
                        <option value={tipoPrecio.idCatTipoPrecio}>{tipoPrecio.tipoPrecio}</option>
                      </>
                    );
                  })}
                </select>
              </Col>
              {datosCargaArchivo.idCatTipoPrecio && datosCargaArchivo.idCatTipoPrecio == 1 ? (
                <>
                  <label className="col-sm-4 col-form-label">Aseguradoras</label>
                  <Col sm={7}>
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      allowClear={true}
                      onChange={handleChange}
                      tokenSeparators={[","]}>
                      {children}
                    </Select>
                  </Col>
                </>
              ) : null}
            </Row>
          </Col>
        </Row>
      </CardBody>
      <h4 className="flex h4 mt-5">Resultado de la Carga de Precios</h4>
    </div>
  );
};

export default CargaMasivaPrecios;
