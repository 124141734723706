import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AuthContext from "../../context/Auth/AuthContext";
import EstantesContext from "../../context/Estantes/EstantesContext";
import AnaquelesContext from "../../context/Anaqueles/AnaquelesContext";
import UbicacionesContext from "../../context/Ubicaciones/UbicacionesContext";

import useWindowDimensions from "../../hooks/dimensionsHook";

export default function Estantes(props) {
  const { width } = useWindowDimensions();

  const { idCatAnaquel } = useParams();

  const cleanEstante = {
    activo: true,
  };

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_estante: false,
    editar: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  const [loading, setLoading] = useState(false);

  const [estanteNuevoEditar, setEstanteNuevoEditar] = useState(cleanEstante);

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const estantesCtx = useContext(EstantesContext);
  const { anaquelSeleccionado, estantes, seleccionarAnaquel, uploadEstante, uploadDeleteEstante } = estantesCtx;

  const anaquelesCtx = useContext(AnaquelesContext);
  const { almacenSeleccionado, seleccionarAlmacenByAnaquel } = anaquelesCtx;

  const ubicacionesCtx = useContext(UbicacionesContext);
  const { limpiarUbicaciones } = ubicacionesCtx;

  const checkUploadEstante = async () => {
    let errorEstante = false;

    if (!estanteNuevoEditar.estante && !errorEstante) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el nombre del estante",
      });
      errorEstante = true;
      return;
    }

    if (!errorEstante) {
      setLoading(true);
      const res = await uploadEstante(estanteNuevoEditar);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          editar: false,
          dynamic_title: "Exito",
          confirm_alert_estante: false,
          dynamic_description: "Se generó un nuevo anaquel",
        });
        setEstanteNuevoEditar(cleanEstante);
      }
    }
  };

  const onChangeEstante = async (e) => {
    let value = e.target.value;

    if (e.target.name == "activo") {
      value = !estanteNuevoEditar.activo;
    }

    setEstanteNuevoEditar({
      ...estanteNuevoEditar,
      [e.target.name]: value,
    });
  };

  const eliminarEstante = async () => {
    if (estanteNuevoEditar.ubicaciones && estanteNuevoEditar.ubicaciones.length > 0) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_estante_eliminar: false,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se pueden eliminar estantes que tengan ubicaciones asociados",
      });
      return;
    }

    setLoading(true);
    const res = await uploadDeleteEstante(estanteNuevoEditar);
    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_estante_eliminar: false,
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_estante_eliminar: false,
        dynamic_description: "Se eliminó el estante de manera correcta.",
      });
      setEstanteNuevoEditar(cleanEstante);
    }
  };

  useEffect(() => {
    limpiarUbicaciones();

    if (Object.entries(anaquelSeleccionado).length === 0) {
      seleccionarAnaquel(idCatAnaquel);
    }

    if (Object.entries(anaquelSeleccionado).length !== 0 && Object.entries(almacenSeleccionado).length === 0) {
      seleccionarAlmacenByAnaquel(anaquelSeleccionado.idCatAnaquel);
    }
  }, [anaquelSeleccionado]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Lista de Estantes</h3>

      <Card className="mt-10 card" style={{ padding: "1.5rem 1.5rem 1.5rem" }}>
        <CardBody>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Almacén Seleccionado</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={almacenSeleccionado ? almacenSeleccionado.almacen : ""}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Anaquel Seleccionado</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={anaquelSeleccionado ? anaquelSeleccionado.anaquel : ""}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Responsable</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={almacenSeleccionado.responsable ? almacenSeleccionado.responsable.usuario : ""}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col>
              <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mr-6 hover:text-white"
                type="button"
                hidden={usuario.authorities.includes("OP_ALMACEN_CREAR") ? false : true}
                onClick={() => {
                  setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_estante: true,
                    editar: false,
                  });
                }}>
                Nuevo Estante
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="table-responsive mt-4">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        Nombre<br></br>Estante
                      </th>
                      <th scope="col">Activo</th>
                      <th scope="col" colSpan={2}>
                        Ubicaciones
                      </th>
                      <th scope="col">Editar</th>
                      <th scope="col">Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {estantes.map((estante) => {
                      return (
                        <tr key={estante}>
                          <td>{estante.estante ? estante.estante : ""}</td>
                          <td>{estante.activo ? "SI" : "NO"} </td>
                          <td>{estante.ubicaciones ? estante.ubicaciones.length : ""}</td>
                          <td>
                            <button
                              className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                              type="button"
                              hidden={usuario.authorities.includes("OP_ALMACEN_EDITAR") ? false : true}
                              onClick={() => props.history.push(`/ubicaciones/${estante.idCatEstante}`)}>
                              Detalles
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-primary-purple-umo w-md waves-effect waves-light mr-4"
                              type="button"
                              hidden={usuario.authorities.includes("OP_ALMACEN_EDITAR") ? false : true}
                              onClick={() => {
                                setUISweetAlert({
                                  ...UISweetAlert,
                                  confirm_alert_estante: true,
                                  editar: true,
                                });
                                setEstanteNuevoEditar(estante);
                              }}>
                              Editar
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-primary-purple-umo w-md waves-effect waves-light mr-4"
                              type="button"
                              hidden={usuario.authorities.includes("OP_ALMACEN_BORRAR") ? false : true}
                              onClick={() => {
                                setUISweetAlert({
                                  ...UISweetAlert,
                                  confirm_alert_estante_eliminar: true,
                                });
                                setEstanteNuevoEditar(estante);
                              }}>
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_estante ? (
        <SweetAlert
          title={UISweetAlert.editar ? "Editar Estante" : "Nuevo Estante"}
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "70%",
          }}
          onConfirm={() => checkUploadEstante()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_estante: false,
              editar: false,
            });
            setEstanteNuevoEditar(cleanEstante);
          }}>
          <div className="mt-2">
            <FormControlLabel
              control={
                <Switch
                  disabled={!UISweetAlert.editar}
                  checked={estanteNuevoEditar.activo}
                  onChange={onChangeEstante}
                  name="activo"
                  color="primary"
                />
              }
              label="Activo"
            />
          </div>
          <div className="mt-2">
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">
                Nombre<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  onChange={onChangeEstante}
                  name="estante"
                  value={estanteNuevoEditar.estante}
                />
                {(estanteNuevoEditar.estante === "" || !estanteNuevoEditar.estante) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_estante_eliminar ? (
        <SweetAlert
          title="¿Desea eliminar el estante?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarEstante()}
          onCancel={() => {
            setEstanteNuevoEditar(cleanEstante);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_estante_eliminar: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
