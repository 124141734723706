import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import FormatNumber from "../../utils/index";

import {Link} from "react-router-dom";

import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";
import ValesContext from "../../context/Vales/ValesContext";
import AuthContext from "../../context/Auth/AuthContext";
import ClientesContext from "../../context/Clientes/ClientesContext";

import CatTipoCobroContext from "../../context/CatTipoCobro/CatTipoCobroContext";

import useWindowDimensions from "../../hooks/dimensionsHook";
import SpanError from "../UI/spanError";
//import SpanError from "../../UI/spanError";

export default function EstatusHistorico({props}) {
    const {width} = useWindowDimensions();

    const {idVale} = useParams();

    const [pivoteCambio, setPivoteCambio] = useState(false);
    const[comentariosGlobal,setComentariosGlobal] = useState("");
    const [UISweetAlert, setUISweetAlert] = useState({
        success_dlg: false,
        confirm_alert_status_change: false,
        confirm_alert_save_changes: false,
        confirm_alert_incompleto: false,
        confirm_alert_completo: false,
        confirm_alert_cancelar_vale: false,
        confirm_alert_Solcitar_autorizacion: false,
        confirm_alert_imprimir_cotizacion: false,
        confirm_alert_devolver_vale: false,
        confirm_alert_status_change_aprobado_aseguradora: false,
        confirm_alert_status_reclamado_aseguradora: false,
        confirm_alert_changed_product: false,
        confirm_alert_changed_product_cambio_estatus: false,
        error_dlg: false,
        reload: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });
    const [loading, setLoading] = useState(false);

    // ACCESO A ESTADO GLOBAL - VALES
    const ctxVales = useContext(ValesContext);
    const {devolverVale, cancelarVale, setChangesValeSeleccionado,valeSeleccionado, exportValeCotizacion, actualizarValeFiltrado} = ctxVales;

    // ACCESO A ESTADO GLOBAL - VALES
    const ctxEstatusVale = useContext(EstatusValeContext);
    const {
        getProximosEstatusVale,
        setcambioEstatusData,
        cambioEstatusData,
        getEstatusCancelado,
        actualizarOCambiarEstatus,
        AutorizarVale,
    } = ctxEstatusVale;

    //ACCESO AL ESTADO GLOBAL - AUTENCIACION
    const ctxAuth = useContext(AuthContext);
    const {usuario} = ctxAuth;

    const ctxTipoCobro = useContext(CatTipoCobroContext);

    const setListaInsumosOnCambioEstatusData = async () => {
        let listaInsumos = [];
        // TODO GUARDADO
        valeSeleccionado.valeproductoservicios.map((valeProdServ) => {
            let data = {
                idValeProductoServicio: valeProdServ.idValeProductoServicio,
                piezas: valeProdServ.piezasVale,
                precio: valeProdServ.precio,
                iva: valeProdServ.iva,
                principioActivo: valeProdServ.principioActivo,
                concentracion: valeProdServ.concentracion,
                inventarioProductoServicio: [],
                producto: valeProdServ.producto,
                compraExterna: valeProdServ.compraExterna ? valeProdServ.compraExterna : false,
            };
            if (valeProdServ.idCatProducto) {
                data.idCatProducto = valeProdServ.idCatProducto;
            } else if (valeProdServ.idCatServicio) {
                data.idCatServicio = valeProdServ.idCatServicio;
            }

            if (valeProdServ.inventarioProductoServicios.length > 0) {
                valeProdServ.inventarioProductoServicios.map((inventarioProdServ) => {
                    let dataInvProdServ = {
                        piezasAsignadas: inventarioProdServ.piezasAsignadas,
                        piezasRecibidas: inventarioProdServ.piezasRecibidas,
                        piezasUtilizadas: inventarioProdServ.piezasUtilizadas,
                    };
                    if (inventarioProdServ.idInventarioProductoServicio) {
                        dataInvProdServ.idInventarioProductoServicio = inventarioProdServ.idInventarioProductoServicio;
                    } else if (inventarioProdServ.idInventario) {
                        dataInvProdServ.idInventario = inventarioProdServ.idInventario;
                    }
                    data.inventarioProductoServicio = data.inventarioProductoServicio.concat(dataInvProdServ);
                });
            }
            listaInsumos.push(data);
        });
        return listaInsumos;
    };


    const solicitarAutorizacion = async (exportCotizacion) => {
        if(comentariosGlobal.length > 0)
        {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_save_changes: false,
                confirm_alert_upload_vale: false,
                confirm_alert_incompleto: false,
                confirm_alert_completo: false,
                confirm_alert_status_change_enrecoleccion: false,
                confirm_alert_Solcitar_autorizacion: false,
            });
            setLoading(true);

            const res = await AutorizarVale({
                idVale: valeSeleccionado.idVale,
                autorizar: pivoteCambio,
                comentario:  comentariosGlobal        });
            if (res) {
                setLoading(false);

                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_alert_save_changes: false,
                    path: "/autorizaciones",
                    dynamic_description: "Se registraron los cambios del vale.",
                    redirect: true,
                });
            } else {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_alert_upload_vale: false,
                    confirm_alert_save_changes: false,
                    confirm_alert_imprimir_cotizacion: false,
                    confirm_alert_status_change_enrecoleccion: false,
                    confirm_alert_incompleto: false,
                    dynamic_title: "Fallo",
                    dynamic_description: "Error al subir los cambios, Inténtalo por favor más tarde.",
                });
            }
        }else{
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_upload_vale: false,
                confirm_alert_save_changes: false,
                confirm_alert_imprimir_cotizacion: false,
                confirm_alert_status_change_enrecoleccion: false,
                confirm_alert_incompleto: false,
                dynamic_title: "Fallo",
                dynamic_description: "Se necesita ingresar un comentario.",
            });
        }
    };

    const onChangePivoteCambio = async (e) => {
        let temp ;
        if(pivoteCambio== false){
            temp = true;
        } else{
            temp = false;
        }
        setPivoteCambio(temp)
    };

    const changeComentario = (e) => {
        const value = e.target.value
        setComentariosGlobal(value);
    };

    useEffect(() => {
        getProximosEstatusVale(idVale);
        getEstatusCancelado(idVale);
        if (valeSeleccionado) {
            let idCatEstatusValeActual =
                valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale;

            cambioEstatusData.idVale = valeSeleccionado.idVale;
            setcambioEstatusData(cambioEstatusData);

            //Queremos saber si el vale tiene piezasUtilizadas, si es el caso, no se puede devolver ni, cancelar ya que se tienen que cobrar
            //las piezasUtilizadas

            switch (idCatEstatusValeActual) {
                case 7:
                case 17:
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "El vale ha sido devuelto, no se puede cambiar el estatus.",
                        redirect: true,
                        path: "/vales",
                    });
                    break;
                case 10:
                case 22:
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "El vale ha sido cancelado, no se puede cambiar el estatus.",
                        redirect: true,
                        path: "/vales",
                    });
                    break;

                default:
                    break;
            }
        }

    }, [valeSeleccionado]);


    const alertaAutorizar = () => {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_Solcitar_autorizacion: true,
            })
    }
    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Cambio de Estatus</h3>
            <Card className="mt-10">
                <CardBody>
                    <Row>
                        <Col xl={6} lg={12} md={12}>
                            <Row className="form-group">
                                <label className="col-sm-4 col-form-label">Estatus Actual</label>
                                <Col sm={7}>
                                    <input
                                        disabled
                                        className="form-control"
                                        type="text"
                                        value={
                                            valeSeleccionado
                                                ? valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.estatusVale
                                                : ""
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col> 
                            {valeSeleccionado? valeSeleccionado.necesitaAutorizacion == 1 && usuario.authorities.includes("ROLE_QUIMIO")?(
                                <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                // onClick={guardarCambios}
                                onClick={(e) =>
                                    alertaAutorizar()
                                }>
                                Autorización del Vale
                            </button>
                            ) : null:null}   
                        </Col>
                    </Row>
                </CardBody>
            </Card>


            {UISweetAlert.confirm_alert_Solcitar_autorizacion ? (
                <SweetAlert
                    title="¿Desea autorizar los cambios realizados al vale?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => solicitarAutorizacion()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_Solcitar_autorizacion: false,
                        })
                    }>
                    <div className="mt-2">
                        <FormControlLabel
                            control={
                                <Switch checked={pivoteCambio} onChange={onChangePivoteCambio} name="decision" color="primary"/>
                            }
                            label={pivoteCambio ? "Aprobado" : "Rechazado"}
                        />
                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Comentarios</label>
                            <Col sm={7}>
                            <textarea
                                rows={5}
                                type="text"
                                className="form-control"
                                onChange={changeComentario}
                                value={comentariosGlobal}
                            />
                            </Col>
                        </Row>

                    </div>
                </SweetAlert>
            ) : null}            

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.reload) {
                            window.location.reload();
                        }
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
