import React, {useReducer} from 'react'

import NotaInterconsultaContext from './NotaInterconsultaContext'
import NotaInterconsultaReducer from './NotaInterconsultaReducer'

import {
    LISTAR_NOTAS_INTERCONSULTA
} from '../../types'

import clienteAxios from '../../config/axios'


export default function NotaInterconsultState (props) {

    const initialState = { 
        notasInterconsulta:[],
    }

    const [state, dispatch] = useReducer(NotaInterconsultaReducer,initialState)

    const listarNotasInterconsulta = async (idCatPaciente) => {

        const res = await clienteAxios.post("/interconsulta/detalles" , {idCatPaciente: idCatPaciente}, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })

        dispatch({
            type: LISTAR_NOTAS_INTERCONSULTA,
            payload: res.data
        })
    }

    const uploadNuevaNotaInterconsulta = async (nuevaNotaInterconsulta) => {
        try {
            const res = await clienteAxios.post(`/interconsulta/nueva`, nuevaNotaInterconsulta, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            });
      
            return res;
            } catch (error) {
                if (!error.response) {
                    error.response = {
                        data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }
      
            return error.response;
          }
    }

    return (

        <NotaInterconsultaContext.Provider value={{
            notasInterconsulta: state.notasInterconsulta,
            listarNotasInterconsulta,
            uploadNuevaNotaInterconsulta
        }}>
            {props.children}
        </NotaInterconsultaContext.Provider>

    )


}