import React, {useReducer} from 'react'

import CatTipoMedicoContext from './CatTipoMedicoContext'
import CatTipoMedicoReducer from './CatTipoMedicoReducer'

import {
    LISTAR_CATTIPOMEDICO,
} from '../../types'

import clienteAxios from '../../config/axios'

export default function CatTipoMedicoState (props) {

    const initialState = { 
        tiposMedico:[],
    }

    const [state, dispatch] = useReducer(CatTipoMedicoReducer,initialState)

    const listarTiposMedico = async () => {

        const res = await clienteAxios.get("/tipomedico/activos"  , {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
        dispatch({
            type: LISTAR_CATTIPOMEDICO,
            payload: res.data
        })
    };

    return (
        <CatTipoMedicoContext.Provider value={{
            tiposMedico: state.tiposMedico,
            listarTiposMedico,
        }}>
            {props.children}
        </CatTipoMedicoContext.Provider>
    );

}