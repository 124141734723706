import React, {useState, useContext, useEffect, useRef} from "react";

import ChartLayout from "../ChartLayout/ChartLayout";
import PieChart from "../Charts/PieChart";
import LineChart from "../Charts/LineChart";
import BarChart from "../Charts/BarChart";
import HorizontalStackedBarChart from "../Charts/HorizontalStackedBarChart";
import {assignRandomColorsToDatasets} from "../../utils/assingRandomColorsToDatasets";
import DashboardContext from "../../context/Dashboard/DashboardContext";
import { lightGreen } from "@material-ui/core/colors";
import { Bar, Title } from "react-chartjs-2";
import CustomSpinner from "../UI/customSpinner";
import { BorderColor } from "@material-ui/icons";
import formatNumber from "../../utils";
import {barChartOptions} from "../../config/chartOptions";
import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";

const InformeCompras = () => {

        const dashboardContext = useContext(DashboardContext);
        const { 
            getFacturadoMensual,getTotalTipoCompra,getComprasPagadas,getConsumoMensual,getComprasAcumulados,getOrdenesCompra,getPendientesPago,
            getComprasProveedor
        } = dashboardContext;

        const ctxProductosServicios = useContext(ProductosServiciosContext);
        const { productosServicios, listarProductosPersonal } = ctxProductosServicios;

        const [productosFilter, setProductosFilter] = useState([]);
    
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
    
        const tituloLayoutMensualGlobal= "Facturado - ";
        const [titulofacturadoMensual, setTituloFacturadoMensual] = useState(tituloLayoutMensualGlobal + currentYear);

        const tituloTotalTipoCompraGlobal= "Total Tipo Compra - ";
        const [tituloTotalTipoCompra, setTituloTotalTipoCompra] = useState(tituloTotalTipoCompraGlobal + currentYear);

        const tituloComprasPagadasGlobal= "Facturación - ";
        const [tituloComprasPagadas, setTituloComprasPagadas] = useState(tituloComprasPagadasGlobal + currentYear);  
        
        const tituloLayoutConsumoMensualGlobal= "Consumo Mensual - ";
        const [tituloConsumoMensual, setTituloConsumoMensual] = useState(tituloLayoutConsumoMensualGlobal + currentYear);

        const tituloLayoutOrdenesCompra= "Ordenes de Compra - ";
        const [tituloOrdenesCompra, setTituloOrdenesCompra] = useState(tituloLayoutOrdenesCompra + currentYear);

        const tituloLayoutPendientesPago= "Pendientes de pago - ";
        const [tituloPendientesPago, setTituloPendientesPago] = useState(tituloLayoutPendientesPago + currentYear);  
        
        const tituloLayoutComprasProveedor= "Pendientes de pago - ";
        const [tituloComprasProveedor, setTituloComprasProveedor] = useState(tituloLayoutPendientesPago + currentYear);         
        
        const tituloLayoutComprasAcumuladoMedicamentos = "Acumulado compra Medicamentos - ";
        const [tituloComprasAcumuladoMedicamentos, setTituloComprasAcumuladoMedicamentos] = useState(tituloLayoutComprasAcumuladoMedicamentos + currentYear);        

        const tituloLayoutComprasAcumuladoSoluciones = "Acumulado compra Soluciones - ";
        const [tituloComprasAcumuladoSoluciones, setTituloComprasAcumuladoSoluciones] = useState(tituloLayoutComprasAcumuladoSoluciones + currentYear);        

        const tituloLayoutComprasAcumuladoInsumos = "Acumulado compra Insumos - ";
        const [tituloComprasAcumuladoInsumos, setTituloComprasAcumuladoInsumos] = useState(tituloLayoutComprasAcumuladoInsumos + currentYear);        
        //*************************************************************************** */

        const [dataFacturadoMensual, setDataFacturadoMensual] = useState({datasets: []});
        const [dataTotalTipoCompra, setDataTotalTipoCompra] = useState({datasets: []});
        const [dataComprasPagadas, setDataComprasPagadas] = useState({datasets: []});
        const [dataConsumoMensual, setDataConsumoMensual] = useState({datasets: []});
        const [dataOrdenesCompra, setDataOrdenesCompra] = useState({datasets: []});
        const [dataPendientesPago, setDataPendientesPago] = useState({datasets: []});
        const [dataComprasProveedor, setDataComprasProveedor] = useState({datasets: []});        
        const [dataComprasAcumuladoMedicamentos, setDataComprasAcumuladoMedicamentos] = useState({datasets: []});
        const [dataComprasAcumuladoSoluciones, setDataComprasAcumuladoSoluciones] = useState({datasets: []});
        const [dataComprasAcumuladoInsumos, setDataComprasAcumuladoInsumos] = useState({datasets: []});


        const [filtrosFacturadoMensual, setFiltrosFacturadoMensual] = useState({anio: currentYear, fullDate: new Date(), mes: null})
        const [filtrosTotalTipoCompra, setFiltrosTotalTipoCompra] = useState({anio: currentYear, fullDate: new Date(), mes: null})
        const [filtrosComprasPagadas, setFiltrosComprasPagadas] = useState({anio: currentYear, fullDate: new Date(), mes: null})
        const [filtrosConsumoMensual, setFiltrosConsumoMensual] = useState({anio: currentYear, fullDate: new Date(), mes: null})
        const [filtrosOrdenesCompra, setFiltrosOrdenesCompra] = useState({anio: currentYear, fullDate: new Date(), mes: null})
        const [filtrosPendientesPago, setFiltrosPendientesPago] = useState({anio: currentYear, fullDate: new Date(), mes: null})
        const [filtrosComprasProveedor, setFiltrosComprasProveedor] = useState({anio: currentYear, fullDate: new Date(), mes: null})
        const [filtrosComprasAcumuladoMedicamentos, setFiltrosComprasAcumuladoMedicamentos] = useState({anio: currentYear, fullDate: new Date(), mes: null})
        const [filtrosComprasAcumuladoSoluciones, setFiltrosComprasAcumuladoSoluciones] = useState({anio: currentYear, fullDate: new Date(), mes: null})
        const [filtrosComprasAcumuladoInsumos, setFiltrosComprasAcumuladoInsumos] = useState({anio: currentYear, fullDate: new Date(), mes: null})
    
        const refGraficaFacturadoMensual = useRef(null);
        const refGraficaTotalTipoCompra = useRef(null);
        const refGraficaComprasPagadas = useRef(null);
        const refGraficaConsumoMensual = useRef(null);
        const refGraficaOrdenesCompra = useRef(null);
        const refGraficaPendientesPago  = useRef(null);  
        const refGraficaComprasProveedor  = useRef(null);       
        const refGraficaComprasAcumuladoMedicamentos = useRef(null);
        const refGraficaComprasAcumuladoSoluciones = useRef(null);        
        const refGraficaComprasAcumuladoInsumos = useRef(null);        
    
        const chartLayoutFacturadoMensual = {extraInfo: true,withFilters: true};
        const chartLayoutTotalTipoCompra = {extraInfo: true,withFilters: true};
        const chartLayoutComprasPagadas = {extraInfo: true,withFilters: true};
        const chartLayoutConsumoMensual = {extraInfo: true,withFilters: true};
        const chartLayoutOrdenesCompra = {extraInfo: true,withFilters: true};
        const chartLayoutPendientesPago = {extraInfo: true,withFilters: true};
        const chartLayoutComprasProveedor = {extraInfo: true,withFilters: true};        
        const chartLayoutComprasAcumuladoMedicamentos = {extraInfo: true,withFilters: true};
        const chartLayoutComprasAcumuladoSoluciones = {extraInfo: true,withFilters: true};
        const chartLayoutComprasAcumuladoInsumos = {extraInfo: true,withFilters: true};        
            //PendientesPago
        const [loadingFacturadoMensual, setLoadingFacturadoMensual] = useState(false);
        const [loadingTotalTipoCompra, setLoadingTotalTipoCompra] = useState(false);
        const [loadingComprasPagadas, setLoadingComprasPagadas] = useState(false);
        const [loadingConsumoMensual, setLoadingConsumoMensual] = useState(false);
        const [loadingOrdenesCompra, setLoadingOrdenesCompra] = useState(false);
        const [loadingPendientesPago, setLoadingPendientesPago] = useState(false);    
        const [loadingComprasProveedor, setLoadingComprasProveedor] = useState(false);             
        const [loadingComprasAcumuladoMedicamentos, setLoadingComprasAcumuladoMedicamentos] = useState(false);
        const [loadingComprasAcumuladoSoluciones, setLoadingComprasAcumuladoSoluciones] = useState(false);
        const [loadingComprasAcumuladoInsumos, setLoadingComprasAcumuladoInsumos] = useState(false);
            //
        const [cantidadProveedores, setCantidadProveedores] = useState(0);
        const [cantidadProveedoresCompra, setCantidadProveedoresCompra] = useState(0);
    const exportChart = (chartRef) => {
        if (chartRef.current) {
            const canvas = chartRef.current.canvas; // Access the canvas directly
            canvas.toBlob((blob) => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'grafica.png'; // You can specify the filename here
                link.click();
            });
        } else {
            console.error("Chart ref is null or undefined.");
        }
    };

    function assignRandomColorsToDatasetsLocal(){
        const randomComponent = () => Math.floor(Math.random() * 256);
        const r = randomComponent();
        const g = randomComponent();
        const b = randomComponent();
        return `rgb(${r}, ${g}, ${b})`;
    }

    const filtrarProductosPorTipo = async (idCatTipoProducto) => {
        //Filtramos todos los productos para que solo esten los que sean del
        const productosFiltrados = await productosServicios.filter((producto) => {
          if (producto.idCatTipoproducto == 2) {
            //Esto es para el label del select, que pueda buscar por todas las caracteristicas
            // producto.label = `${producto.nombreComercial} ${producto.numIdentificacion} ${producto.principioActivo} ${producto.codigoEAN}`
            return true;
          }
        });
    
        setProductosFilter(productosFiltrados);
      };

      useEffect(() => {
        const fetchAllContextData = () => {
          listarProductosPersonal();
        };
        fetchAllContextData();
      }, []);
    /* **************************************************************************************************************** */
    /*                      SOLICITUD PARA FACTURADO MENSUAL Y EL ONCHANGE DE LA GRAFICA                                */
    /* **************************************************************************************************************** */     
    useEffect(() => {
        const fetchFacturadoMensual = async () => {
            setLoadingFacturadoMensual(true)
            try{            
                const resPc = await getFacturadoMensual(filtrosFacturadoMensual);
                const finalPc = resPc.data.map((value,index) => {
                    const labelTemp = value.x;
                    let retorno = {
                        label : "",
                        barPercentage:1,
                        backgroundColor: assignRandomColorsToDatasetsLocal(), 
                        borderWidth: 1,
                        height:50,
                        width : 50,            
                        data : [{ x : labelTemp, y : parseFloat(value.y, 10)}]
                    }
                    return retorno
                });                
                setDataFacturadoMensual({datasets: finalPc});
            }catch (e) {
                setDataFacturadoMensual({datasets: []})
                console.log(e);
            }
            setLoadingFacturadoMensual(false)
        }   
        fetchFacturadoMensual();
    }, [filtrosFacturadoMensual]);
    /* *************************************************************************************************************** */

    const onChangeFacturadoMensual = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloFacturadoMensual(tituloLayoutMensualGlobal + year)
            } catch (error) {
            }
            setFiltrosFacturadoMensual({
                ...filtrosFacturadoMensual,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosFacturadoMensual({
                ...filtrosFacturadoMensual,
                [name]: value==""? "0" : value
            });
        }
    }
    /* **************************************************************************************************************** */
    /*                      SOLICITUD PARA TOTAL TIPO DE COMPRA Y EL ONCHANGE DE LA GRAFICA                                */
    /* **************************************************************************************************************** */     
    useEffect(() => {
        const fetchTotalTipoCompra = async () => {
            setLoadingTotalTipoCompra(true)
            try{        
                const resPc = await getTotalTipoCompra(filtrosTotalTipoCompra);
                /* ACA LLENAMOS LOS DATOS CON LOS CUALES SE REPARTIRA LOS ESPACIO DEL PASTEL*/ 
                let dataTemp = [];
                let labelsTemp = [];
                let backgroundColorTemp = [];
                let granTotal = 0;

                resPc.data.map((value) => {
                    granTotal = granTotal + parseFloat(value.y, 10);
                });  

                resPc.data.map((value) => {
                    dataTemp.push(parseInt(value.y, 10));
                    let porcentaje = ((100 / granTotal) * parseFloat(value.y, 10)).toFixed(2);
                    labelsTemp.push(value.x + "  (" + formatNumber(parseFloat(value.y, 10)) +  " =  " +  porcentaje +"%)");
                    backgroundColorTemp.push(assignRandomColorsToDatasetsLocal());
                });   
                const dataset = [{data: dataTemp, backgroundColor: backgroundColorTemp}]
                setDataTotalTipoCompra({datasets: dataset, labels : labelsTemp})
            }catch (e) {
                setDataTotalTipoCompra({datasets: []})
                console.log(e);
            }
            setLoadingTotalTipoCompra(false)
        }   
        fetchTotalTipoCompra();
    }, [filtrosTotalTipoCompra]);
    /* *************************************************************************************************************** */

    const onChangeTotalTipoCompra = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloTotalTipoCompra(tituloTotalTipoCompraGlobal + year)
            } catch (error) {
            }
            setFiltrosTotalTipoCompra({
                ...filtrosTotalTipoCompra,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosTotalTipoCompra({
                ...filtrosTotalTipoCompra,
                [name]: value
            });
        }
    }
    /* **************************************************************************************************************** */
    /*                      SOLICITUD PARA COMPRAS PAGADAS Y EL ONCHANGE DE LA GRAFICA                                */
    /* **************************************************************************************************************** */     
    useEffect(() => {
        const fetchtComprasPagadas = async () => {
            setLoadingComprasPagadas(true)
            try{        
                const resPc = await getComprasPagadas(filtrosComprasPagadas);
                /* ACA LLENAMOS LOS DATOS CON LOS CUALES SE REPARTIRA LOS ESPACIO DEL PASTEL*/ 
                let dataTemp = [];
                let labelsTemp = [];
                let backgroundColorTemp = [];
                let granTotal = 0;

                resPc.data.map((value) => {
                    granTotal = granTotal + parseFloat(value.y, 10);
                });

                resPc.data.map((value) => {
                    dataTemp.push(parseInt(value.y, 10));
                    let porcentaje = ((100 / granTotal) * parseFloat(value.y, 10)).toFixed(2);
                    labelsTemp.push(value.x + "  (" + formatNumber(parseFloat(value.y, 10)) +  " =  " +  porcentaje +"%)");
                    backgroundColorTemp.push(assignRandomColorsToDatasetsLocal());
                });   
                const dataset = [{data: dataTemp, backgroundColor: backgroundColorTemp}]
                setDataComprasPagadas({datasets: dataset, labels : labelsTemp})
            }catch (e) {
                setDataComprasPagadas({datasets: []})
                console.log(e);
            }
            setLoadingComprasPagadas(false)
        }   
        fetchtComprasPagadas();
    }, [filtrosComprasPagadas]);
    /* *************************************************************************************************************** */

    const onChangeComprasPagadas = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloComprasPagadas(tituloComprasPagadasGlobal + year)
            } catch (error) {
            }
            setFiltrosComprasPagadas({
                ...filtrosComprasPagadas,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosComprasPagadas({
                ...filtrosComprasPagadas,
                [name]: value
            });
        }
    }
    /* **************************************************************************************************************** */
    /*                      SOLICITUD PARA CONSUMO MENSUAL Y EL ONCHANGE DE LA GRAFICA                                */
    /* **************************************************************************************************************** */     
    useEffect(() => {
        const fetchConsumoMensual = async () => {
            setLoadingConsumoMensual(true)
            try{            
                const resPc = await getConsumoMensual(filtrosConsumoMensual);
                const finalPc = resPc.data.map((value,index) => {
                    const labelTemp = value.x;
                    let retorno = {
                        label : parseFloat(value.y, 10),
                        barPercentage:1,
                        backgroundColor: assignRandomColorsToDatasetsLocal(), 
                        borderWidth: 1,
                        height:50,
                        width : 50,            
                        data : [{ x : labelTemp, y : parseFloat(value.y, 10)}]
                    }
                    return retorno
                });                
                setDataConsumoMensual({datasets: finalPc});
            }catch (e) {
                setDataConsumoMensual({datasets: []})
                console.log(e);
            }
            setLoadingConsumoMensual(false)
        }   
        fetchConsumoMensual();
    }, [filtrosConsumoMensual]);
    /* *************************************************************************************************************** */

    const onChangeConsumoMensual = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloConsumoMensual(tituloLayoutMensualGlobal + year)
            } catch (error) {
            }
            setFiltrosConsumoMensual({
                ...filtrosFacturadoMensual,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosConsumoMensual({
                ...filtrosFacturadoMensual,
                [name]: value==""? "0" : value
            });
        }
    }

    /* **************************************************************************************************************** */
    /*                      SOLICITUD PARA CONSUMO MENSUAL Y EL ONCHANGE DE LA GRAFICA                                */
    /* **************************************************************************************************************** */     
    useEffect(() => {
        const fetchOrdenesCompra = async () => {
            setLoadingOrdenesCompra(true)
            try{            
                const resPc = await getOrdenesCompra(filtrosOrdenesCompra);
                const finalPc = resPc.data.map((value,index) => {
                    const labelTemp = value.x;
                    let retorno = {
                        label : parseFloat(value.y, 10),
                        barPercentage:1,
                        backgroundColor: assignRandomColorsToDatasetsLocal(), 
                        borderWidth: 1,
                        height:50,
                        width : 50,            
                        data : [{ x : labelTemp, y : parseFloat(value.y, 10)}]
                    }
                    return retorno
                });                
                setDataOrdenesCompra({datasets: finalPc});
            }catch (e) {
                setDataOrdenesCompra({datasets: []})
                console.log(e);
            }
            setLoadingOrdenesCompra(false)
        }   
        fetchOrdenesCompra();
    }, [filtrosOrdenesCompra]);
    /* *************************************************************************************************************** */

    const onChangeOrdenesCompra = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloOrdenesCompra(tituloLayoutOrdenesCompra + year)
            } catch (error) {
            }
            setFiltrosOrdenesCompra({
                ...filtrosOrdenesCompra,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosOrdenesCompra({
                ...filtrosOrdenesCompra,
                [name]: value==""? "0" : value
            });
        }
    }    

    /* **************************************************************************************************************** */
    /*                      SOLICITUD PARA PENDIENTES PAGO Y EL ONCHANGE DE LA GRAFICA                                */
    /* **************************************************************************************************************** */     
    useEffect(() => {
        const fetchPendientesPago = async () => {
            setLoadingPendientesPago(true)
            try{            
                let labelsTemp = [];
                let porPagarTemp = [];
                let pagadosTemp = [];
                let contador = 1;
                const resPc = await getPendientesPago(filtrosPendientesPago);
                resPc.data.map((value,index) => {

                    labelsTemp.push(value.label);
                    pagadosTemp.push(parseFloat(value.dato1.toFixed(2), 10));
                    porPagarTemp.push(parseFloat(value.dato2.toFixed(2), 10));                    
                    contador = contador + 1;
                });                
                const dataset = [{labels : labelsTemp, pagados : pagadosTemp, porPagar : porPagarTemp }]
                
                if(contador < 10){
                    setCantidadProveedores(contador*70);
                }else{
                    setCantidadProveedores(contador*35);
                }

                setDataPendientesPago(dataset);
            }catch (e) {
                setDataPendientesPago({datasets: []})
                console.log(e);
            }
            setLoadingPendientesPago(false)
        }   
        fetchPendientesPago();
    }, [filtrosPendientesPago]);
    /* *************************************************************************************************************** */

    const onChangePendientesPago = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloPendientesPago(tituloLayoutPendientesPago + year)
            } catch (error) {
            }
            setFiltrosPendientesPago({
                ...filtrosPendientesPago,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosPendientesPago({
                ...filtrosPendientesPago,
                [name]: value==""? "0" : value
            });
        }
    }        
        /* **************************************************************************************************************** */
    /*                      SOLICITUD PARA Compras Proveedor Y EL ONCHANGE DE LA GRAFICA                                */
    /* **************************************************************************************************************** */     
    useEffect(() => {
        const fetchComprasProveedor = async () => {
            setLoadingComprasProveedor(true)
            try{            
                let labelsTemp = [];
                let comprasTemp = [];
                let coloresTemp = [];
                let contador = 1;
                const resPc = await getComprasProveedor(filtrosComprasProveedor);
                resPc.data.map((value,index) => {

                    labelsTemp.push(value.label1);
                    comprasTemp.push(value.cantidadInteger);   
                    coloresTemp.push(value.color);        
                    contador = contador + 1;
                });                
                const dataset = [{labels : labelsTemp, compras : comprasTemp, colores : coloresTemp}]
                if(contador < 10){
                    setCantidadProveedoresCompra(contador*50);
                }else{
                    setCantidadProveedoresCompra(contador*25);
                }
                
                setDataComprasProveedor(dataset);
                filtrarProductosPorTipo();
            }catch (e) {
                setDataComprasProveedor({datasets: []})
                console.log(e);
            }
            setLoadingComprasProveedor(false)
        }   
        fetchComprasProveedor();
    }, [filtrosComprasProveedor]);
    /* *************************************************************************************************************** */

    const onChangeComprasProveedor = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloComprasProveedor(tituloLayoutPendientesPago + year)
            } catch (error) {
            }
            setFiltrosComprasProveedor({
                ...filtrosPendientesPago,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosComprasProveedor({
                ...filtrosPendientesPago,
                [name]: value==""? "0" : value
            });
        }
    }       
    
    /* **************************************************************************************************************** */
    /*                      SOLICITUD PARA ACUMULADO COMPRAS MEDICAMENTOSL Y EL ONCHANGE DE LA GRAFICA      
    /* 1 = INSUMO    2 MEDICAMENTO    4 SOLUCION       5 VACUNAS */                          
    /* **************************************************************************************************************** */     
    useEffect(() => {
        const fetchComprasAcumuladoMedicamentos = async () => {
            setLoadingComprasAcumuladoMedicamentos(true)
            try{           
                filtrosComprasAcumuladoMedicamentos.opcion = 2; 
                const resPc = await getComprasAcumulados(filtrosComprasAcumuladoMedicamentos);
                const finalPc = resPc.data.map((value,index) => {
                    const labelTemp = value.x;
                    let retorno = {
                        label : parseFloat(value.y, 10),
                        barPercentage:1,
                        backgroundColor: assignRandomColorsToDatasetsLocal(), 
                        borderWidth: 1,
                        height:50,
                        width : 50,            
                        data : [{ x : labelTemp, y : parseFloat(value.y, 10)}]
                    }
                    return retorno
                });                
                setDataComprasAcumuladoMedicamentos({datasets: finalPc});
            }catch (e) {
                setDataComprasAcumuladoMedicamentos({datasets: []})
                console.log(e);
            }
            setLoadingComprasAcumuladoMedicamentos(false)
        }   
        fetchComprasAcumuladoMedicamentos();
    }, [filtrosComprasAcumuladoMedicamentos]);
    /* *************************************************************************************************************** */

    const onChangeComprasAcumuladoMedicamentos = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloComprasAcumuladoMedicamentos(tituloLayoutComprasAcumuladoMedicamentos + year)
            } catch (error) {
            }
            setFiltrosComprasAcumuladoMedicamentos({
                ...filtrosComprasAcumuladoMedicamentos,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosComprasAcumuladoMedicamentos({
                ...filtrosComprasAcumuladoMedicamentos,
                [name]: value==""? "0" : value
            });
        }
    }
        /* **************************************************************************************************************** */
    /*                      SOLICITUD PARA ACUMULADO COMPRAS SolucionesL Y EL ONCHANGE DE LA GRAFICA      
    /* 1 = INSUMO    2 MEDICAMENTO    4 SOLUCION       5 VACUNAS */                          
    /* **************************************************************************************************************** */     
    useEffect(() => {
        const fetchComprasAcumuladoSoluciones = async () => {
            setLoadingComprasAcumuladoSoluciones(true)
            try{           
                filtrosComprasAcumuladoSoluciones.opcion = 4; 
                const resPc = await getComprasAcumulados(filtrosComprasAcumuladoSoluciones);
                const finalPc = resPc.data.map((value,index) => {
                    const labelTemp = value.x;
                    let retorno = {
                        label : parseFloat(value.y, 10),
                        barPercentage:1,
                        backgroundColor: assignRandomColorsToDatasetsLocal(), 
                        borderWidth: 1,
                        height:50,
                        width : 50,            
                        data : [{ x : labelTemp, y : parseFloat(value.y, 10)}]
                    }
                    return retorno
                });                
                setDataComprasAcumuladoSoluciones({datasets: finalPc});
            }catch (e) {
                setDataComprasAcumuladoSoluciones({datasets: []})
                console.log(e);
            }
            setLoadingComprasAcumuladoSoluciones(false)
        }   
        fetchComprasAcumuladoSoluciones();
    }, [filtrosComprasAcumuladoSoluciones]);
    /* *************************************************************************************************************** */

    const onChangeComprasAcumuladoSoluciones = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloComprasAcumuladoSoluciones(tituloLayoutComprasAcumuladoSoluciones + year)
            } catch (error) {
            }
            setFiltrosComprasAcumuladoSoluciones({
                ...filtrosComprasAcumuladoSoluciones,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosComprasAcumuladoSoluciones({
                ...filtrosComprasAcumuladoSoluciones,
                [name]: value==""? "0" : value
            });
        }
    }
    // ************************************************************************************************************************
        /* **************************************************************************************************************** */
    /*                      SOLICITUD PARA ACUMULADO COMPRAS InsumosL Y EL ONCHANGE DE LA GRAFICA      
    /* 1 = INSUMO    2 MEDICAMENTO    4 SOLUCION       5 VACUNAS */                          
    /* **************************************************************************************************************** */     
    useEffect(() => {
        const fetchComprasAcumuladoInsumos = async () => {
            setLoadingComprasAcumuladoInsumos(true)
            try{           
                filtrosComprasAcumuladoInsumos.opcion = 1; 
                const resPc = await getComprasAcumulados(filtrosComprasAcumuladoInsumos);
                const finalPc = resPc.data.map((value,index) => {
                    const labelTemp = value.x;
                    let retorno = {
                        label : parseFloat(value.y, 10),
                        barPercentage:1,
                        backgroundColor: assignRandomColorsToDatasetsLocal(), 
                        borderWidth: 1,
                        height:50,
                        width : 50,            
                        data : [{ x : labelTemp, y : parseFloat(value.y, 10)}]
                    }
                    return retorno
                });                
                setDataComprasAcumuladoInsumos({datasets: finalPc});
            }catch (e) {
                setDataComprasAcumuladoInsumos({datasets: []})
                console.log(e);
            }
            setLoadingComprasAcumuladoInsumos(false)
        }   
        fetchComprasAcumuladoInsumos();
    }, [filtrosComprasAcumuladoInsumos]);
    /* *************************************************************************************************************** */

    const onChangeComprasAcumuladoInsumos = (e) => {
        const {name, value} = e.target;
        
        if (name === "anio") {
            let year;
            try {
                year = value.getFullYear();
                setTituloComprasAcumuladoInsumos(tituloLayoutComprasAcumuladoInsumos + year)
            } catch (error) {
            }
            setFiltrosComprasAcumuladoInsumos({
                ...filtrosComprasAcumuladoInsumos,
                fullDate: value,
                [name]: year
            });
        } else {
            setFiltrosComprasAcumuladoInsumos({
                ...filtrosComprasAcumuladoInsumos,
                [name]: value==""? "0" : value
            });
        }
    }
    return (
        <>
            <div backgroundColor = {lightGreen}>
                <h1> Informes de Compras</h1>         

                    <ChartLayout aseguradoras={null} 
                                    selectAseguradoras={false} 
                                    filtros={filtrosFacturadoMensual} 
                                    conAnio={true}
                                    conMes ={true}
                                    onChange={onChangeFacturadoMensual} 
                                    exportChart={exportChart}
                                    refGrafica={refGraficaFacturadoMensual} {...chartLayoutFacturadoMensual}>
                                    {loadingFacturadoMensual ? 
                                        (
                                            <CustomSpinner height={"700px"} infoText={"Facturación por aseguradora"}/>
                                        ) 
                                        :(dataFacturadoMensual.datasets[0]?.data?.length > 0 ? 
                                            (<BarChart  ref={refGraficaFacturadoMensual} 
                                                        title={titulofacturadoMensual} 
                                                        data={dataFacturadoMensual} 
                                                        requiresMoneyFormat={true}/>) 
                                            : (<h3>{`${titulofacturadoMensual} SIN DATOS`}</h3>)
                                        ) 
                                    }
                    </ChartLayout>

                    <ChartLayout aseguradoras={null} 
                             selectAseguradoras={false} 
                             filtros={filtrosTotalTipoCompra} 
                             conAnio={true}
                             conMes ={true}
                             onChange={onChangeTotalTipoCompra} 
                             exportChart={exportChart} 
                             refGrafica={refGraficaTotalTipoCompra} {...chartLayoutTotalTipoCompra}>
                            {loadingTotalTipoCompra ? 
                                (
                                  <CustomSpinner height={"700px"} infoText={"Total TipoCompra"}/>
                                ) 
                               :(dataTotalTipoCompra.datasets[0]?.data?.length > 0 ? 
                                    (<PieChart pie={true}  
                                               ref={refGraficaTotalTipoCompra} 
                                               data={dataTotalTipoCompra} 
                                               displayDataLabels={true}
                                               title={tituloTotalTipoCompra}  
                                               requiresMoneyFormat={true} 
                                               onlyShowPercentage={false} 
                                               displayYAxis={false} 
                                               displayLabel={false}/>) 
                                    : (<h3>{`${tituloTotalTipoCompra} SIN DATOS`}</h3>)
                                ) 
                            }
                    </ChartLayout>   

                    <ChartLayout aseguradoras={null} 
                             selectAseguradoras={false} 
                             filtros={filtrosComprasPagadas} 
                             conAnio={true}
                             conMes ={true}
                             onChange={onChangeComprasPagadas} 
                             exportChart={exportChart} 
                             refGrafica={refGraficaComprasPagadas} {...chartLayoutComprasPagadas}>
                            {loadingComprasPagadas ? 
                                (
                                  <CustomSpinner height={"700px"} infoText={"Total TipoCompra"}/>
                                ) 
                               :(dataComprasPagadas.datasets[0]?.data?.length > 0 ? 
                                    (<PieChart pie={true}  
                                               ref={refGraficaComprasPagadas} 
                                               data={dataComprasPagadas} 
                                               displayDataLabels={true}
                                               title={tituloComprasPagadas}  
                                               requiresMoneyFormat={true} 
                                               onlyShowPercentage={false} 
                                               displayYAxis={false} 
                                               displayLabel={false}/>) 
                                    : (<h3>{`${tituloComprasPagadas} SIN DATOS`}</h3>)
                                ) 
                            }
                    </ChartLayout>     
                    
                    { /*  **************************************** CNSUMO MENSUAL ********************************************/ }
                    <ChartLayout aseguradoras={null} 
                                    selectAseguradoras={false} 
                                    filtros={filtrosConsumoMensual} 
                                    conAnio={true}
                                    conMes ={true}
                                    onChange={onChangeConsumoMensual} 
                                    exportChart={exportChart}
                                    refGrafica={refGraficaConsumoMensual} {...chartLayoutConsumoMensual}>
                                    {loadingConsumoMensual ? 
                                        (
                                            <CustomSpinner height={"700px"} infoText={"Facturación por aseguradora"}/>
                                        ) 
                                        :(dataConsumoMensual.datasets[0]?.data?.length > 0 ? 
                                            (<BarChart  ref={refGraficaConsumoMensual} 
                                                        title={tituloConsumoMensual} 
                                                        displayDataLabels={true}
                                                        data={dataConsumoMensual} 
                                                        displayLabel={true}
                                                        requiresMoneyFormat={false} 
                                                        displayYAxis={true}                                                        
                                                        />) 
                                            : (<h3>{`${tituloConsumoMensual} SIN DATOS`}</h3>)
                                        ) 
                                    }
                    </ChartLayout>

                    <ChartLayout aseguradoras={null} 
                                    selectAseguradoras={false} 
                                    filtros={filtrosOrdenesCompra} 
                                    conAnio={true}
                                    conMes ={true}
                                    onChange={onChangeOrdenesCompra} 
                                    exportChart={exportChart}
                                    refGrafica={refGraficaOrdenesCompra} {...chartLayoutOrdenesCompra}>
                                    {loadingOrdenesCompra ? 
                                        (
                                            <CustomSpinner height={"700px"} infoText={"Facturación por aseguradora"}/>
                                        ) 
                                        :(dataOrdenesCompra.datasets[0]?.data?.length > 0 ? 
                                            (<BarChart  ref={refGraficaOrdenesCompra} 
                                                        title={tituloOrdenesCompra} 
                                                        data={dataOrdenesCompra} 
                                                        requiresMoneyFormat={false}/>) 
                                            : (<h3>{`${tituloOrdenesCompra} SIN DATOS`}</h3>)
                                        ) 
                                    }
                    </ChartLayout>   

                    {/* ******************* ACA LA GRAFICA COMBINADA PARA PENDIENTES Y ORDENES PAAGAS ********** */}
                    
                    <ChartLayout aseguradoras={null} 
                                    selectAseguradoras={false} 
                                    filtros={filtrosPendientesPago} 
                                    conAnio={true}
                                    conMes ={true}
                                    onChange={onChangePendientesPago} 
                                    exportChart={exportChart}
                                    refGrafica={refGraficaPendientesPago} {...chartLayoutPendientesPago}>
                                    {loadingPendientesPago ? 
                                        (
                                            <CustomSpinner height={"700px"} infoText={"Facturación por aseguradora"}/>
                                        ) 
                                        :(dataPendientesPago[0]?.labels?.length > 0 ? 
                                            (   
                                                <Bar
                                                    ref={refGraficaPendientesPago} 
                                                    requiresMoneyFormat = {true}
                                                    data={{
                                                        // Name of the variables on x-axies for each bar
                                                        labels: dataPendientesPago[0].labels,
                                                        datasets: [
                                                            {
                                                                // Label for bars
                                                                label: "Pagado",
                                                                // Data or value of your each variable
                                                                data: dataPendientesPago[0].pagados,
                                                                // Color of each bar
                                                                backgroundColor: 
                                                                    ["#aed6f1", "#aed6f1", "#aed6f1", "#aed6f1"],
                                                                // Border color of each bar
                                                                borderColor: ["85c1e9", "85c1e9", "85c1e9", "85c1e9"],
                                                                borderWidth: 0.5,
                                                            },                                     
                                                            {
                                                                // Label for bars
                                                                label: "Por pagar",
                                                                // Data or value of your each variable
                                                                data: dataPendientesPago[0].porPagar,
                                                                // Color of each bar
                                                                backgroundColor: 
                                                                    ["#f0b27a", "#f0b27a", "#f0b27a", "#f0b27a"],
                                                                // Border color of each bar
                                                                borderColor: ["orange", "orange", "orange", "orange"],
                                                                borderWidth: 0.5,
                                                            },                                   
                                                        ],
                                                    }}
                                                    // Height of graph
                                                    height={cantidadProveedores}                                                
                                                    options={{ 
                                                        maintainAspectRatio: false,
                                                        horizontal : true,
                                                        indexAxis: 'y',
                                                        plugins: {
                                                            legend: {
                                                                display: true,
                                                                position: "bottom",
                                                
                                                            },
                                                            title: {
                                                                ...barChartOptions.plugins.title,
                                                                text: "Pendientes de pago",
                                                                padding: {
                                                                    bottom: 50,
                                                                }
                                                            },
                                                            datalabels: {
                                                                anchor: 'end',                
                                                                display: true,
                                                                align: 'right',
                                                                formatter: (value) => value !== null ? value > 0 ?("     "  + formatNumber(value) ) : "$0.0" : "$0.0",
                                                                color: 'black',
                                                                font: {
                                                                    size: 14,
                                                                    //weight: 'bold'
                                                                }
                                                            },
        
                                                        },
                                                        bar: {
                                                            borderRadius: 40,
                                                            borderWidth: 0.7,
                                                        },
                                                        ticks: {
                                                            padding: 15
                                                        },
                                                        scales: {
                                                            yAxes: [
                                                                {
                                                                    ticks: {
                                                                // The y-axis value will start from zero
                                                                        beginAtZero: false,
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                        legend: {
                                                            labels: {
                                                                fontSize: 15,
                                                            },
                                                        },
                                                        responsive: true,
                                                        scales: {
                                                        x: {
                                                            stacked: true,
                                                        },
                                                        y: {
                                                            stacked: true
                                                        }
                                                        }                                
                                                    }}
                                                />
                                                ) 
                                            : (<h3>{`${tituloPendientesPago} SIN DATOS`}</h3>)
                                        ) 
                                    }
                    </ChartLayout>   

                    {/* ******************* ACA LA GRAFICA COMBINADA PARA COMPRAS POR NMERO DE COMPRAS POR PROVEEDOR ********** */}
                    <ChartLayout aseguradoras={null} 
                                    selectAseguradoras={false} 
                                    filtros={filtrosComprasProveedor} 
                                    medicamentos = {true}
                                    conAnio={true}
                                    conMes ={true}
                                    productosServicios = {productosServicios}
                                    onChange={onChangeComprasProveedor} 
                                    exportChart={exportChart}
                                    refGrafica={refGraficaComprasProveedor} {...chartLayoutComprasProveedor}>
                                    {loadingComprasProveedor ? 
                                        (
                                            <CustomSpinner height={"700px"} infoText={"Facturación por aseguradora"}/>
                                        ) 
                                        :(dataComprasProveedor[0]?.labels?.length > 0 ? 
                                            (
                                                <Bar
                                                    ref={refGraficaComprasProveedor} 
                                                    data={{
                                                        // Name of the variables on x-axies for each bar
                                                        labels: dataComprasProveedor[0].labels,
                                                        datasets: [
                                                            {
                                                                // Label for bars
                                                                label: "Todos los medicamentos",
                                                                // Data or value of your each variable
                                                                data: dataComprasProveedor[0].compras,
                                                                // Color of each bar
                                                                backgroundColor: dataComprasProveedor[0].colores,
                                                                    
                                                                // Border color of each bar
                                                                borderColor: dataComprasProveedor[0].colores,
                                                                borderWidth: 0.5,
                                                            },                                                                       
                                                        ],
                                                    }}
                                                    // Height of graph
                                                    height={cantidadProveedoresCompra}
                                                    title={tituloComprasProveedor} 
                                                    options={{
                                                        maintainAspectRatio: false,
                                                        horizontal : true,
                                                        indexAxis: 'y',
                                                        plugins: {
                                                            legend: {
                                                                display: true,
                                                                position: "bottom",
                                                
                                                            },
                                                            title: {
                                                                ...barChartOptions.plugins.title,
                                                                text: "Compras",
                                                                padding: {
                                                                    bottom: 50,
                                                                }
                                                            },                                                            
                                                            datalabels: {
                                                                anchor: 'end',                
                                                                display: true,
                                                                align: 'right',
                                                                //formatter: (value) => value.y !== null ? (requiresMoneyFormat ? formatNumber(value.y) : value.y) : null,
                                                                color: 'black',
                                                                font: {
                                                                    size: 12,
                                                                    //weight: 'bold'
                                                                }
                                                            },        
                                                        },
                                                        bar: {
                                                            borderRadius: 40,
                                                            borderWidth: 0.7,
                                                        },
                                                        ticks: {
                                                            padding: 15
                                                        },
                                                        scales: {
                                                            yAxes: [
                                                                {
                                                                    ticks: {
                                                                // The y-axis value will start from zero
                                                                        beginAtZero: false,
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                        legend: {
                                                            labels: {
                                                                fontSize: 13,
                                                            },
                                                        },                             
                                                    }}
                                                />
                                                ) 
                                            : (<h3>{`${tituloComprasProveedor} SIN DATOS`}</h3>)
                                        ) 
                                    }
                    </ChartLayout>                                 

                    { /*  **************************************** CNSUMO MENSUAL ACUMULADO MEDICAMENTOS ********************************/ }
                    <ChartLayout aseguradoras={null} 
                                    selectAseguradoras={false} 
                                    filtros={filtrosComprasAcumuladoMedicamentos} 
                                    conAnio={true}
                                    conMes ={true}
                                    onChange={onChangeComprasAcumuladoMedicamentos} 
                                    exportChart={exportChart}
                                    refGrafica={refGraficaComprasAcumuladoMedicamentos} {...chartLayoutComprasAcumuladoMedicamentos}>
                                    {loadingComprasAcumuladoMedicamentos ? 
                                        (
                                            <CustomSpinner height={"700px"} infoText={"Facturación por aseguradora"}/>
                                        ) 
                                        :(dataComprasAcumuladoMedicamentos.datasets[0]?.data?.length > 0 ? 
                                            (<LineChart 
                                                ref={refGraficaComprasAcumuladoMedicamentos} 
                                                title={tituloComprasAcumuladoMedicamentos} 
                                                data={dataComprasAcumuladoMedicamentos}
                                                displayDataLabels={false} 
                                                requiresMoneyFormat={false} 
                                                displayYAxis={true} 
                                                displayLabel={true}/>) 
                                            : (<h3>{`${tituloComprasAcumuladoMedicamentos} SIN DATOS`}</h3>)
                                        ) 
                                    }
                    </ChartLayout>      
                    { /*  **************************************** CNSUMO MENSUAL ACUMULADO Soluciones ********************************/ }
                    <ChartLayout aseguradoras={null} 
                                    selectAseguradoras={false} 
                                    filtros={filtrosComprasAcumuladoSoluciones} 
                                    conAnio={true}
                                    conMes ={true}
                                    onChange={onChangeComprasAcumuladoSoluciones} 
                                    exportChart={exportChart}
                                    refGrafica={refGraficaComprasAcumuladoSoluciones} {...chartLayoutComprasAcumuladoSoluciones}>
                                    {loadingComprasAcumuladoSoluciones ? 
                                        (
                                            <CustomSpinner height={"700px"} infoText={"Soluciones"}/>
                                        ) 
                                        :(dataComprasAcumuladoSoluciones.datasets[0]?.data?.length > 0 ? 
                                            (<LineChart 
                                                ref={refGraficaComprasAcumuladoSoluciones} 
                                                title={tituloComprasAcumuladoSoluciones} 
                                                data={dataComprasAcumuladoSoluciones}
                                                displayDataLabels={false} 
                                                requiresMoneyFormat={false} 
                                                displayYAxis={true} 
                                                displayLabel={true}/>) 
                                            : (<h3>{`${tituloComprasAcumuladoSoluciones} SIN DATOS`}</h3>)
                                        ) 
                                    }
                    </ChartLayout>

                    { /*  **************************************** CNSUMO MENSUAL ACUMULADO Insumos ********************************/ }
                    <ChartLayout aseguradoras={null} 
                                    selectAseguradoras={false} 
                                    filtros={filtrosComprasAcumuladoInsumos} 
                                    conAnio={true}
                                    conMes ={true}
                                    onChange={onChangeComprasAcumuladoInsumos} 
                                    exportChart={exportChart}
                                    refGrafica={refGraficaComprasAcumuladoInsumos} {...chartLayoutComprasAcumuladoInsumos}>
                                    {loadingComprasAcumuladoInsumos ? 
                                        (
                                            <CustomSpinner height={"700px"} infoText={"Insumos"}/>
                                        ) 
                                        :(dataComprasAcumuladoInsumos.datasets[0]?.data?.length > 0 ? 
                                            (<LineChart 
                                                ref={refGraficaComprasAcumuladoInsumos} 
                                                title={tituloComprasAcumuladoInsumos} 
                                                data={dataComprasAcumuladoInsumos}
                                                displayDataLabels={false} 
                                                requiresMoneyFormat={false} 
                                                displayYAxis={true} 
                                                displayLabel={true}/>) 
                                            : (<h3>{`${tituloComprasAcumuladoInsumos} SIN DATOS`}</h3>)
                                        ) 
                                    }
                    </ChartLayout>                                                       
            </div>  
        </>    
    )
}

export default InformeCompras;