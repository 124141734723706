import "antd/dist/antd.min.css";
import { Col, Drawer, List, Row, Form } from "antd";
import { useState } from "react";

// const { Option } = Select;

// const DescriptionItem = ({ title, content, historiaClinicaInicial }) => (
//   <div className="site-description-item-profile-wrapper">
//     <p className="site-description-item-profile-p-label">{title}:</p>
//     {content}
//   </div>
// );

const DrawerExpedientePaciente = ({
  historiaClinicaInicial,
  sexos,
  aseguradoras,
  medicos,
  lugares,
  ocupaciones,
  estadosCiviles,
  escolaridades,
}) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handdleCloseDrawer = () => {
    setVisible(false);
  };

  return (
    <>
      <List
        dataSource={[historiaClinicaInicial]}
        bordered
        renderItem={(item) => (
          <List.Item
            key={item.paciente && item.paciente.idCatPaciente ? item.paciente.idCatPaciente : ""}
            actions={[
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                type="button"
                onClick={showDrawer}
                key={`a-${item.paciente && item.paciente.idCatPaciente}`}>
                Ver Datos Generales
              </button>,
            ]}>
            <List.Item.Meta
              // avatar={<Avatar src="https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png" />}
              title={
                <p className="text-lg">
                  {item.paciente
                    ? item.paciente.nombres + " " + item.paciente.apellidoPaterno + " " + item.paciente.apellidoMaterno
                    : ""}
                </p>
              }
              // description={"Expediente: " + item.expedienteMedico.idExpedienteMedico}
            />
          </List.Item>
        )}
      />
      <Drawer
        // width={800}
        width={"80%"}
        // width={window.innerWidth > 900 ? 800 : window.innerWidth - 100}
        zIndex={200}
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}>
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={24}>
              <label className="col-form-label">Nombre Completo</label>
              {/* <DescriptionItem
                title="Nombre Completo"
                content={historiaClinicaInicial.paciente.nombres + " " + historiaClinicaInicial.paciente.apellidoPaterno + " " + historiaClinicaInicial.paciente.apellidoMaterno}
              /> */}
              <input
                name="nombres"
                className="form-control"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente &&
                  historiaClinicaInicial.paciente.nombres +
                    " " +
                    historiaClinicaInicial.paciente.apellidoPaterno +
                    " " +
                    historiaClinicaInicial.paciente.apellidoMaterno
                }
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <label className="col-form-label">Sexo</label>
              <select
                className="form-control"
                disabled={true}
                name="idSexo"
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idSexo
                    ? historiaClinicaInicial.paciente.idSexo
                    : ""
                }>
                <option selected disabled value=""></option>
                {sexos.map((sexo) => {
                  return (
                    <>
                      <option value={sexo.idCatSexo}>{sexo.sexo}</option>
                    </>
                  );
                })}
              </select>
              {/* <input name="nombres" className="form-control" disabled={true} value={"MASCULINO"} /> */}
            </Col>
            <Col span={12}>
              <label className="col-form-label">Teléfono</label>

              <input
                name="nombres"
                className="form-control"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.telefono
                    ? historiaClinicaInicial.paciente.telefono
                    : ""
                }
              />
            </Col>
            <Col span={12}>
              <label className="col-form-label">Estado de Origen</label>

              <select
                className="form-control"
                name="idLugarOrigen"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idLugarOrigen
                    ? historiaClinicaInicial.paciente.idLugarOrigen
                    : ""
                }>
                <option selected value=""></option>
                {lugares.map((lugar) => {
                  return (
                    <>
                      <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>
                    </>
                  );
                })}
              </select>
            </Col>
            <Col span={12}>
              <label className="col-form-label">Escolaridad</label>

              <select
                className="form-control"
                name="idEscolaridad"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idEscolaridad
                    ? historiaClinicaInicial.paciente.idEscolaridad
                    : ""
                }>
                <option selected value=""></option>
                {escolaridades.map((escolaridad) => {
                  return (
                    <>
                      <option value={escolaridad.idCatEscolaridad}>{escolaridad.escolaridad}</option>
                    </>
                  );
                })}
              </select>
            </Col>
            <Col span={12}>
              <label className="col-form-label">Ciudad de Origen</label>

              <input
                name="nombres"
                className="form-control"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.origen
                    ? historiaClinicaInicial.paciente.origen
                    : ""
                }
              />
            </Col>
            <Col span={12}>
              <label className="col-form-label">Ocupación</label>

              <select
                className="form-control"
                name="idOcupacion"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idOcupacion
                    ? historiaClinicaInicial.paciente.idOcupacion
                    : ""
                }>
                <option selected value=""></option>
                {ocupaciones.map((ocupacion) => {
                  return (
                    <>
                      <option value={ocupacion.idCatOcupacion}>{ocupacion.ocupacion}</option>
                    </>
                  );
                })}
              </select>
            </Col>
            <Col span={12}>
              <label className="col-form-label">Estado de Residencia</label>

              <select
                className="form-control"
                name="idLugarResidencia"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idLugarResidencia
                    ? historiaClinicaInicial.paciente.idLugarResidencia
                    : ""
                }>
                <option selected value=""></option>
                {lugares.map((lugar) => {
                  return (
                    <>
                      <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>
                    </>
                  );
                })}
              </select>
            </Col>
            <Col span={12}>
              <label className="col-form-label">Estado Civil</label>

              <select
                className="form-control"
                name="idEstadoCivil"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idEstadoCivil
                    ? historiaClinicaInicial.paciente.idEstadoCivil
                    : ""
                }>
                <option selected value=""></option>
                {estadosCiviles.map((estadoCivil) => {
                  return (
                    <>
                      <option value={estadoCivil.idCatEstadoCivil}>{estadoCivil.estadoCivil}</option>
                    </>
                  );
                })}
              </select>
            </Col>
            <Col span={12}>
              <label className="col-form-label">Ciudad de Residencia</label>

              <input
                name="nombres"
                className="form-control"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.residencia
                    ? historiaClinicaInicial.paciente.residencia
                    : ""
                }
              />
            </Col>
            <Col span={12}>
              <label className="col-form-label">Referencia</label>

              <input
                name="referencia"
                className="form-control"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.referencia
                    ? historiaClinicaInicial.paciente.referencia
                    : ""
                }
              />
            </Col>
            <Col span={12}>
              <label className="col-form-label">Fecha de Nacimiento</label>

              <input
                type="date"
                name="fechaNacimiento"
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.fechaNacimiento
                    ? historiaClinicaInicial.paciente.fechaNacimiento
                    : ""
                }
                disabled={true}
                className="form-control"
              />
            </Col>
            <Col span={12}>
              <label className="col-form-label">CURP</label>

              <input
                name="nombres"
                className="form-control"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.curp
                    ? historiaClinicaInicial.paciente.curp
                    : ""
                }
              />
            </Col>
            <Col span={12}>
              <label className="col-form-label">Edad</label>

              <input
                name="nombres"
                className="form-control"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.edad
                    ? historiaClinicaInicial.paciente.edad
                    : ""
                }
              />
            </Col>
            <Col span={12}>
              <label className="col-form-label">Religión</label>

              <input
                name="nombres"
                className="form-control"
                disabled={true}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.religion
                    ? historiaClinicaInicial.paciente.religion
                    : ""
                }
              />
            </Col>
            <Col span={24}>
              <label className="col-form-label">Aseguradora</label>

              <select
                className="form-control"
                name="idCatAseguradora"
                disabled={true}
                value={
                  historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.idCatAseguradora
                    ? historiaClinicaInicial.expedienteMedico.idCatAseguradora
                    : ""
                }>
                <option selected value="">
                  SIN ASEGURADORA
                </option>
                {aseguradoras.map((aseguradora) => {
                  return (
                    <>
                      <option value={aseguradora.idCatAseguradora}>{aseguradora.razonSocial}</option>
                    </>
                  );
                })}
              </select>
            </Col>
            {/* <Col span={12}>
              <label className="col-form-label">Médico Tratante</label>
              <select
                className="form-control"
                // disabled={true}
                name="idCatMedico"
                disabled={true}
                value={historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.idCatMedico ? historiaClinicaInicial.expedienteMedico.idCatMedico : ""}>
                <option selected value=""></option>
                {medicos.map((medico) => {
                  return (
                    <>
                      <option value={medico.idCatMedico}>{`${medico.catPersona.nombres} ${medico.catPersona.apellidoPaterno} ${medico.catPersona.apellidoMaterno}`}</option>
                    </>
                  );
                })}
              </select>
            </Col> */}
          </Row>
          {/* <Row gutter={16}>
            <Col span={24}>
              <label className="col-form-label">Diagnóstico</label>
              <textarea name="nombres" className="form-control" disabled={true} value={"Diagnostico de prueba para el paciente"} />
            </Col>
          </Row> */}
        </Form>
        <button className="mt-4 btn btn-primary-purple-umo w-md waves-effect waves-light" onClick={() => handdleCloseDrawer()}>
          Cerrar
        </button>
      </Drawer>
    </>
  );
};

export default DrawerExpedientePaciente;
