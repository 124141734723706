import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import InformacionGeneralRecepcionVenta from "../InformacionGeneralRecepcionVenta/index";
import ListadoProductoRecepcionVenta from "../ListadoProductoRecepcionVenta/index";
import RecepcionGenericaProductosContext from "../../context/RecepcionGenericaProductos/RecepcionGenericaProductosContext";

export default function RecepcionVentaProductos(props) {
  // eslint-disable-next-line no-unused-vars
  const [UISweetAlert, setUISweetAlert] = useState({
    error_dlg: false,
  });

  const recepGenProdContext = useContext(RecepcionGenericaProductosContext);
  const {
    filtroDevolucionCancelacionVenta,
    setFiltroDevolucionCancelacionVenta,
    recepcionVentaProductosSeleccionada,
    seleccionarVenta,
    errorRecepcionVentaProducto,
  } = recepGenProdContext;

  useEffect(() => {
    if (Object.entries(filtroDevolucionCancelacionVenta).length === 0) {
      const filtroDevolucionCancelacionVenta = JSON.parse(localStorage.getItem("filtroDevolucionCancelacionVenta"));
      if (filtroDevolucionCancelacionVenta == null) {
        props.history.push(`/recepcion/generica/producto`);
      } else {
        setFiltroDevolucionCancelacionVenta(filtroDevolucionCancelacionVenta);
      }
    }

    if (
      Object.entries(filtroDevolucionCancelacionVenta).length !== 0 &&
      Object.entries(recepcionVentaProductosSeleccionada).length === 0
    ) {
      seleccionarVenta();
    }

    if (errorRecepcionVentaProducto) {
      props.history.push("/recepcion/generica/producto");
    }
  }, [filtroDevolucionCancelacionVenta, errorRecepcionVentaProducto]);

  return (
    <div className="mt-4">
      <InformacionGeneralRecepcionVenta props={props} />
      <ListadoProductoRecepcionVenta props={props} />

      {UISweetAlert.error_dlg ? (
        <SweetAlert error title="Fallo" onConfirm={() => props.history.push("/ordenescompra")}>
          No se puede registar la recepción de la orden de compra
        </SweetAlert>
      ) : null}
    </div>
  );
}
