import React, { useReducer } from "react";
import AlmacenesContext from "./AlmacenesContext";
import AlmacenesReducer from "./AlmacenesReducer";
import { LISTAR_ALMACENES, LISTAR_ANAQUELES } from "../../types";
import clienteAxios from "../../config/axios";

export default function AlmacenesState(props) {
  const initialState = {
    almacenes: [],
  };

  const [state, dispatch] = useReducer(AlmacenesReducer, initialState);
 
  const listarAlmacenes = async (personales, administrador) => {
    const res = await clienteAxios.get(`/almacen/activos?personal=${personales}&administrador=${administrador}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_ALMACENES,
      payload: res.data,
    });
  };

  const seleccionarAlmacen = async (idCatAlmacen) => {
    const res = await clienteAxios.get(`/almacen/detalles?id=${idCatAlmacen}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_ANAQUELES,
      payload: res.data,
    });
  };

  const uploadAlmacen = async (almacen, administrador) => {
    try {
      const res = await clienteAxios.post("/almacen/guardar", almacen, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      listarAlmacenes(true, administrador);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const uploadDeleteAlmacen = async (almacen, administrador) => {
    try {
      const res = await clienteAxios.post("/almacen/eliminar", almacen, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      listarAlmacenes(true, administrador);

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <AlmacenesContext.Provider
      value={{
        almacenes: state.almacenes,
        listarAlmacenes,
        seleccionarAlmacen,
        uploadAlmacen,
        uploadDeleteAlmacen,
      }}>
      {props.children}
    </AlmacenesContext.Provider>
  );
}
