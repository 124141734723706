import TableComponent from "../../TablaPaginada/index";
import ListaRegistros from "../ListaRegistros";

const ListaMedicamentos = ({ pageCount, fetchData, columns, loadingData, data, controlledPageIndex, entradas, salidas }) => {
  return (
    <div>
      <TableComponent hideControls={true} pageCount={pageCount} fetchData={fetchData} columns={columns} loading={loadingData} data={data} indexOfPage={controlledPageIndex} />
      <ListaRegistros entradas={entradas} salidas={salidas} />
    </div>
  );
};

export default ListaMedicamentos;
