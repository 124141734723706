import React, {useContext, useEffect} from "react";
// import { useParams } from "react-router-dom";
import {Row, Col, Card, CardBody} from "reactstrap";

import ValesContext from "../../context/Vales/ValesContext";

import TimePassed from "../TimePassed/TimePassed";

import formatNumber from "../../utils";

export default function ListadoEstatusVale({props}) {
    // const { idVale } = useParams();
    // const [checkAllCobros, setCheckAllCobros] = useState(false);

    // ACCESO A ESTADO GLOBAL - VALES
    const ctxVales = useContext(ValesContext);
    const {valeSeleccionado, setChangesValeSeleccionado, exportCarta} = ctxVales;

    // const mostrarArchivos = (archivos, estatus) => {
    //   return (
    //     <>
    //       <div>
    //         {estatus.estatusVale == "APROBADO" && (
    //           <div>
    //             <button
    //               type="button"
    //               className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
    //               onClick={() => window.open(`${archivos[0].archivosproceso.rutaArchivo}/${archivos[0].archivosproceso.nombreArchivo}`, "_blank")}>
    //               Carta de Aceptación
    //             </button>
    //           </div>
    //         )}
    //       </div>
    //     </>
    //   );
    // };

    const formatFecha = (fecha) => {
        if (fecha) {
            const fechaFormateada = `${fecha.split("T")[0].split("-")[2]}/${fecha.split("T")[0].split("-")[1]}/${
                fecha.split("T")[0].split("-")[0]
            }`;
            return fechaFormateada;
        } else return "";
    };
    const formatHora = (fecha) => {
        if (fecha) {
            const hora = fecha.split("T")[1];
            const fechaFormateada = `${hora.split(":")[0]}:${hora.split(":")[1]}`;
            return fechaFormateada;
        } else return "";
    };

    const formatDate = (date) => {
        const fechaformateada = formatFecha(date);
        const horaFormateada = formatHora(date);

        const fechaYHoraFormateada = fechaformateada + " - " + horaFormateada;

        // console.log("🚀 ~ file: index.js:37 ~ formatDate ~ date", date);
        // let dateAux = date.split("T")[0].split("-");
        // date = `${dateAux[2]}/${dateAux[1]}/${dateAux[0]}`;
        return (
            <>
                <div>{fechaYHoraFormateada}</div>
            </>
        );
    };

    const toggleExpanded = async (index) => {
        let estatus = valeSeleccionado.estatusvales[index];
        estatus.expanded = !estatus.expanded;
        setChangesValeSeleccionado(valeSeleccionado);
    };

    // const handdleChecked = async (id) => {
    //   setCheckAllCobros(false);
    //   valeSeleccionado.ventavales[0].ventavalecobro.map((cobros) => {
    //     if (cobros.idVentaValeCobro === id) {
    //       cobros.checked = !cobros.checked;
    //       setChangesValeSeleccionado(valeSeleccionado);
    //     }
    //   });
    // };

    // const checkedAllCobros = () => {
    //   setCheckAllCobros(!checkAllCobros);

    //   valeSeleccionado.ventavales[0].ventavalecobro.map((cobros) => {
    //     cobros.checked = !checkAllCobros;
    //   });
    //   setChangesValeSeleccionado(valeSeleccionado);
    // };

    // const handdleCheckedCobroAseguradora = async (cobro) => {
    //   cobro.checked = !cobro.checked;
    //   valeSeleccionado.valeaseguradoracobros.map((cobroAseg) => {
    //     if (cobroAseg != cobro) {
    //       cobroAseg.checked = false;
    //     }
    //   });
    //   setChangesValeSeleccionado(valeSeleccionado);
    // };

    const exportCartaAceptacion = async (estatus) => {
        // convert image file to base64 string

        const archivo = estatus.archivovales[0].archivosproceso;

        const archivoVo = {
            rutaArchivo: archivo.rutaArchivo,
            nombreArchivo: archivo.nombreArchivo,
            mimeType: archivo.mimeType,
        };
        const res = await exportCarta(archivoVo);
        return res;
    };

    const formatDropDown = (estatus) => {
        switch (estatus.catEstatusvale.idCatEstatusVale) {
            case 9:
            case 23:
            case 24:
                return (
                    <>
                        <div>
                            <div
                                style={{
                                    textAlign: "center",
                                }}>
                                {/* <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                  type="button"
                  // onClick={() => agregarLote(index)}
                >
                  Recibo de Pago
                </button> */}
                                {/* <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                  type="button"
                  // onClick={() => agregarLote(index)}
                >
                  Factura
                </button> */}
                                {/* <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                  type="button"
                  // onClick={() => agregarLote(index)}
                >
                  Complemento de Pago
                </button> */}
                            </div>

                            <div className="table-responsive mt-4">
                                <table className="table table-hover table-centered table-nowrap mb-0">
                                    <thead>
                                    <tr>
                                        {/* <th scope="col">
                        <input type="checkbox" onClick={() => checkedAllCobros()} checked={checkAllCobros} />
                      </th> */}
                                        <th scope="col">
                                            Num.<br></br>Cobro
                                        </th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Subtotal</th>
                                        <th scope="col">IVA</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">
                                            Folio de<br></br>Pago
                                        </th>
                                        <th scope="col">
                                            Concepto<br></br>de Pago
                                        </th>
                                        {/* <th scope="col">
                        FOLIO <br></br>FACTURA
                      </th> */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {valeSeleccionado &&
                                        valeSeleccionado.ventavales[0].ventavalecobro.map((cobros, index) => {
                                            return (
                                                <>
                                                    <tr key={cobros.idVentaValeCobro}>
                                                        {/* <td>{<input type="checkbox" onClick={() => handdleChecked(cobros.idVentaValeCobro)} checked={cobros.checked} />}</td> */}
                                                        <td>{cobros.idVentaValeCobro}</td>
                                                        <td>{cobros.catTipoCobro.tipoCobro}</td>
                                                        <td
                                                            style={{
                                                                textAlign: "right",
                                                            }}>
                                                            {/* ${cobros.importe.toFixed(2)} */}
                                                            {formatNumber(cobros.importe)}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "right",
                                                            }}>
                                                            {/* ${cobros.iva.toFixed(2)} */}
                                                            {formatNumber(cobros.iva)}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "right",
                                                            }}>
                                                            {formatNumber(cobros.importe)}
                                                            {/* ${(cobros.importe + cobros.iva).toFixed(2)} */}
                                                        </td>
                                                        <td>{cobros.folioDeposito ? formatNumber(cobros.folioDeposito) : ""}</td>
                                                        <td>{cobros.conceptoPago ? formatNumber(cobros.conceptoPago) : ""}</td>
                                                        <td>{/* {cobros.factura.folioFactura} */}</td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    {valeSeleccionado.valeaseguradoracobros.length > 0 &&
                                        valeSeleccionado.valeaseguradoracobros.map((cobro) => {
                                            return (
                                                <>
                                                    <tr key={cobro}>
                                                        {/* <td>{<input type="checkbox" onClick={() => handdleCheckedCobroAseguradora(cobro)} checked={cobro.checked} />}</td> */}
                                                        <td>{cobro.idValeAseguradoraCobro}</td>
                                                        <td>{cobro.catTipoCobro ? cobro.catTipoCobro.tipoCobro : ""}</td>
                                                        <td
                                                            style={{
                                                                textAlign: "right",
                                                            }}>
                                                            {formatNumber(cobro.importe)}
                                                            {/* ${cobro.importe.toFixed(2)} */}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "right",
                                                            }}>
                                                            {formatNumber(cobro.iva)}
                                                            {/* ${cobro.iva.toFixed(2)} */}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "right",
                                                            }}>
                                                            {formatNumber(cobro.importe + cobro.iva)}
                                                            {/* ${(cobro.importe + cobro.iva).toFixed(2)} */}
                                                        </td>
                                                        <td>{cobro.folioDeposito ? cobro.folioDeposito : ""}</td>
                                                        <td>{cobro.conceptoPago ? cobro.conceptoPago : ""}</td>
                                                        <td>{/* {cobro.factura.folioFactura} */}</td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                );
            case 13:
                return (
                    <>
                        <div>
                            <Row className="mb-2" hidden={estatus.archivovales.length > 0 ? false : true}>
                                <Col sm={7}>
                                    <button
                                        type="button"
                                        className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                        onClick={() => exportCartaAceptacion(estatus)}>
                                        Carta de Aceptación
                                    </button>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <label className="col-sm-3 col-form-label">Reembolso</label>
                                <Col sm={7} className="mt-1">
                                    {valeSeleccionado.reembolso ? <i className="ti-check"></i> : <i className="ti-close"></i>}
                                </Col>
                            </Row>
                            <Row hidden={valeSeleccionado.reembolso}>
                                <Col md={4}>
                                    <Row className="form-group">
                                        <label className="col-sm-6 col-form-label">Coaseguro Médico</label>
                                        <Col sm={3} className="mt-1">
                                            {valeSeleccionado.importeCoaseguro ? formatNumber(valeSeleccionado.importeCoaseguro) : "$0"}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <Row className="form-group">
                                        <label className="col-sm-6 col-form-label">Coaseguro Hospitalario</label>
                                        <Col sm={3} className="mt-1">
                                            {valeSeleccionado.importeCoaseguroHospitalario
                                                ? formatNumber(valeSeleccionado.importeCoaseguroHospitalario)
                                                : "$0"}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <Row className="form-group">
                                        <label className="col-sm-5 col-form-label">Deducible</label>
                                        <Col sm={3} className="mt-1">
                                            {valeSeleccionado.importeDeducible ? formatNumber(valeSeleccionado.importeDeducible) : "$0"}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </>
                );
            case 19:
                return (
                    <>
                        <Row>
                            <Col md={6}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Folio Reclamo / Folio Factura</label>
                                    <Col sm={7} className="mt-1">
                                        {valeSeleccionado.folioReclamo ? valeSeleccionado.folioReclamo : ""}
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha de Reclamo</label>
                                    <Col sm={7} className="mt-1">
                                        {valeSeleccionado.fechaReclamo ? valeSeleccionado.fechaReclamo : ""}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {valeSeleccionado.reembolso && estatus.archivovales.length > 0 ? (
                            <Row>
                                <Col md={6}>
                                    <Row className="form-group">
                                        <label className="col-sm-6 col-form-label">Coaseguro</label>
                                        <Col sm={3} className="mt-1">
                                            {valeSeleccionado.importeCoaseguro ? `$${valeSeleccionado.importeCoaseguro}` : "$0"}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row className="form-group">
                                        <label className="col-sm-5 col-form-label">Deducible</label>
                                        <Col sm={3} className="mt-1">
                                            {valeSeleccionado.importeDeducible ? `$${valeSeleccionado.importeDeducible}` : "$0"}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ) : null}
                        <Row>
                            {/* <Col md={6}>
                <button type="button" className="btn btn-primary-orange-umo w-md waves-effect waves-light flex">
                  Factura PPD
                </button>
              </Col> */}
                            <Col sm={6} hidden={estatus.archivovales.length > 0 ? false : true}>
                                <button
                                    type="button"
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                    onClick={() => exportCartaAceptacion(estatus)}>
                                    Carta de Aceptación
                                </button>
                            </Col>
                        </Row>
                    </>
                );
            case 20:
                return (
                    <>
                        <Row>
                            <Col md={6}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha Aceptación</label>
                                    <Col sm={7} className="mt-1">
                                        {valeSeleccionado.fechaAceptacion ? valeSeleccionado.fechaAceptacion : ""}
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha Estimada de Pago</label>
                                    <Col sm={7} className="mt-1">
                                        {valeSeleccionado.fechaEstimadaPago ? valeSeleccionado.fechaEstimadaPago : ""}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-2 col-form-label">Notas</label>
                                    <Col sm={10} className="mt-1">
                                        {estatus.comentarios && estatus.comentarios.length > 0
                                            ? estatus.comentarios[0].comentario
                                                ? estatus.comentarios[0].comentario
                                                : "Sin notas"
                                            : "Sin notas"}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                );
            case 21:
                return (
                    <>
                        <Row>
                            <Col md={6}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Banco Depósito</label>
                                    <Col sm={7} className="mt-1">
                                        {valeSeleccionado.ventavales[0].ventavalecobro.length > 0
                                            ? valeSeleccionado.ventavales[0].ventavalecobro[0].bancoDeposito
                                            : "$0"}
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Folio Depósito</label>
                                    <Col sm={7} className="mt-1">
                                        {valeSeleccionado.ventavales[0].ventavalecobro.length > 0
                                            ? valeSeleccionado.ventavales[0].ventavalecobro[0].folioDeposito
                                            : ""}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* <Row>
              <Col sm={7}>
                <button type="button" className="btn btn-primary-orange-umo w-md waves-effect waves-light flex">
                  Complemento Pago
                </button>
              </Col>
            </Row> */}
                    </>
                );
            default:
                return <></>;
        }
    };

    useEffect(() => {
    }, [valeSeleccionado]);

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Lista de Estatus Vale</h3>

            <Card className="mt-10">

                {valeSeleccionado?.fechaCotizacion ?
                    <TimePassed start={valeSeleccionado?.fechaCreacion} end={valeSeleccionado?.fechaCotizacion} title={"Tiempo transcurrido hasta la cotización"}/>
                    : null}
                <CardBody>
                    <Row className="mt-4">
                        <div className="table-responsive">
                            <table className="table table-hover table-centered table-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col">Fecha Estatus</th>
                                    <th scope="col">Estatus</th>
                                    <th scope="col">Comentarios</th>
                                </tr>
                                </thead>
                                <tbody>
                                {valeSeleccionado &&
                                    valeSeleccionado.estatusvales.map((estatus, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    {estatus.catEstatusvale.idCatEstatusVale == 9 ||
                                                    estatus.catEstatusvale.idCatEstatusVale == 13 ||
                                                    estatus.catEstatusvale.idCatEstatusVale == 19 ||
                                                    estatus.catEstatusvale.idCatEstatusVale == 20 ||
                                                    estatus.catEstatusvale.idCatEstatusVale == 21 ||
                                                    estatus.catEstatusvale.idCatEstatusVale == 23 ||
                                                    estatus.catEstatusvale.idCatEstatusVale == 24 ? (
                                                        <td key={estatus.idEstatusVale} onClick={() => toggleExpanded(index)}>
                                                            {estatus.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>}
                                                        </td>
                                                    ) : (
                                                        <td></td>
                                                    )}

                                                    <td>{estatus.fechaEstatus ? formatDate(estatus.fechaEstatus) : ""}</td>
                                                    <td>{estatus.catEstatusvale.estatusVale}</td>
                                                    <td>{estatus.comentarios[0]?.comentario ? estatus.comentarios[0].comentario : ""}</td>
                                                    <td></td>
                                                </tr>
                                                {estatus.expanded && (
                                                    <tr>
                                                        <td colSpan={9}>{formatDropDown(estatus)}</td>
                                                    </tr>
                                                )}
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}
