import {
    LISTAR_ESTANTES,
    LIMPIAR_ESTANTES
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_ESTANTES: 
            return{
                ...state, 
                estantes: action.payload.estantes,
                anaquelSeleccionado: {
                    ...action.payload,
                    estantes: null
                }
            }
        case LIMPIAR_ESTANTES: 
            return{
                ...state, 
                estantes: [],
                anaquelSeleccionado:{}
            }
        default:
            return state
    }
        
}