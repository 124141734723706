import React, { useReducer } from "react";

import CatRegimenFiscalContext from "./CatRegimenFiscalContext";
import CatRegimenFiscalReducer from "./CatRegimenFiscalReducer";

import { LISTAR_CATREGIMENFISCAL } from "../../types";

import clienteAxios from "../../config/axios";

export default function CatRegimenFiscalState(props) {
  const initialState = {
    catRegimenFiscales: [],
  };

  const [state, dispatch] = useReducer(CatRegimenFiscalReducer, initialState);

  const listarCatRegimenFiscales = async () => {
    const res = await clienteAxios.get("/catregimenfiscal/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CATREGIMENFISCAL,
      payload: res.data,
    });
    return res;
  };

  return (
    <CatRegimenFiscalContext.Provider
      value={{
        catRegimenFiscales: state.catRegimenFiscales,
        listarCatRegimenFiscales,
      }}>
      {props.children}
    </CatRegimenFiscalContext.Provider>
  );
}
