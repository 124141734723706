import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import formatNumber from "../../utils/index";

import ValesContext from "../../context/Vales/ValesContext";
import CatTipoCobroContext from "../../context/CatTipoCobro/CatTipoCobroContext";
import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";
import ClientesContext from "../../context/Clientes/ClientesContext";
import CatRegimenFiscalContext from "../../context/CatRegimenFiscal/CatRegimenFiscalContext";
import CatUsoCfdiContext from "../../context/CatUsoCfdi/CatUsoCfdiContext";
import LugaresContext from "../../context/Lugares/LugaresContext";

import useWindowDimensions from "../../hooks/dimensionsHook";
import useInvoiceActions from "../../hooks/useInvoiceActions";

import ModalDatosFiscales from "../ModalDatosFiscales";
import RespuestaFacturas from "../RespuestaFacturas";


export default function CobroVale(props) {


    const {width} = useWindowDimensions();
    const {idVale} = useParams();
    const [nuevoCobro, setNuevoCobro] = useState({});
    const [loading, setLoading] = useState(false);


    // eslint-disable-next-line no-unused-vars
    const [hiddenReciboPago, setHiddenReciboPago] = useState(false);
    const [hiddenGenerarFactura, setHiddenGenerarFactura] = useState(true);
    const [hiddenComplementoPago, setHiddenComplementoPago] = useState(true);
    const [hiddenInsertarCobro, setHiddenInsertarCobro] = useState(false);
    const [hiddenCatCobroEfectivo, setHiddenCatCobroEfectivo] = useState(true);
    const [hiddenBancoYFolio, setHiddenBancoYFolio] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [hiddenConfirmarContabilidad, setHiddenConfirmarContabilidad] = useState(true);
    const [hiddenConceptoDePago, setHiddenConceptoDePago] = useState(true);
    // const [publicoGeneral, setPublicoGeneral] = useState(null);
    const [respuestaFacturas, setRespuestaFacturas] = useState(null);

    const [datosFiscales, setDatosFiscales] = useState({});

    const catRegimenFiscalContext = useContext(CatRegimenFiscalContext);
    const {listarCatRegimenFiscales, catRegimenFiscales} = catRegimenFiscalContext;

    const catUsoCfdiContext = useContext(CatUsoCfdiContext);
    const {listarCatUsoCfdis, catUsoCfdis} = catUsoCfdiContext;

    const lugaresContext = useContext(LugaresContext);
    const {lugares, listarLugares} = lugaresContext;


    useEffect(() => {
        const fetchAllContextData = async () => {
            listarCatRegimenFiscales();
            listarCatUsoCfdis();
            listarLugares();

        };
        fetchAllContextData();
    }, []);

    const [porcentajeIVAImporte, setPorcentajeIVAImporte] = useState({
        procentajeIVA: 0,
        procentajeImporte: 0,
    });

    const [totalDiferencia, setTotalDiferencia] = useState({
        total: 0,
        diferencia: 0,
        importeCobro: 0,
        ivaCobro: 0,
        importeVale: 0,
        ivaVale: 0,
    });

    const [cobroEliminar, setCobroEliminar] = useState({});

    const [UISweetAlert, setUISweetAlert] = useState({
        success_dlg: false,
        confirm_alert_insertar_cobro: false,
        confirm_alert_eliminar_cobro: false,
        confirm_alert_generar_complemento: false,
        confirm_eliminar_progreso_cobro: false,
        confirm_alert_cambiar_estatus_cobrado: false,
        error_dlg: false,
        reload: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });


    // ACCESO A ESTADO GLOBAL - VALES
    const ctxVales = useContext(ValesContext);
    const {
        valeSeleccionado,
        seleccionarVale,
        generarNuevoCobro,
        eliminarNuevoCobro,
        eliminarProgresoCobroValePersonal,
        actualizarValeFiltrado,
        exportReciboPagoVale,
    } = ctxVales;

    const ctxTipoCobro = useContext(CatTipoCobroContext);
    const {tipoCobros, listarCatTipoCobros} = ctxTipoCobro;

    // ACCESO A ESTADO GLOBAL - Estatus
    const ctxEstatusVale = useContext(EstatusValeContext);
    const {proximosEstatusVale, getProximosEstatusVale, setcambioEstatusData, cambioEstatusData, actualizarOCambiarEstatus} =
        ctxEstatusVale;

    const ctxClientes = useContext(ClientesContext);
    const {clientes, listarClientes, setClienteWithDetalles} = ctxClientes;


    const [selectedCobro, setSelectedCobro] = useState(null);

    // Se utiliza hook con los métodos necesarios para crear una factura y actualizar datos fiscales
    const {
        checkFinalInvoice,
        onChangeFiscalData,
        checkUpdateFiscalData,
        confirmUpdateFiscalData,
        confirmNewFinalInvoice,
        downloadFactura
    } = useInvoiceActions({
        setLoading,
        UISweetAlert,
        setUISweetAlert,
        datosFiscales,
        setDatosFiscales,
        setClienteWithDetalles,
        setRespuestaFacturas
    });

    const handdleChecked = (cobro) => {
        if (selectedCobro?.idVentaValeCobro === cobro?.idVentaValeCobro) {
            setSelectedCobro(null);
        } else {
            setSelectedCobro(cobro);
        }
    };

    const onChangeTipoCobro = (e) => {
        setNuevoCobro({
            ...nuevoCobro,
            [e.target.name]: e.target.value,
            folioDeposito: null,
            bancoDeposito: null,
            cambio: null,
            recibido: null,
        });

        if (e.target.value == 1) {
            setHiddenCatCobroEfectivo(false);
            setHiddenBancoYFolio(true);
            setHiddenConfirmarContabilidad(true);
            setHiddenConceptoDePago(true);
        } else if (e.target.value == 4 || e.target.value == 3) {
            setHiddenBancoYFolio(false);
            setHiddenCatCobroEfectivo(true);
            setHiddenConfirmarContabilidad(true);
            setHiddenConceptoDePago(true);
        } else if (e.target.value == 2) {
            setHiddenBancoYFolio(false);
            setHiddenCatCobroEfectivo(true);
            setHiddenConceptoDePago(false);
            setHiddenConfirmarContabilidad(false);
        } else {
            setHiddenConfirmarContabilidad(true);
            setHiddenConceptoDePago(true);
            setHiddenCatCobroEfectivo(true);
            setHiddenBancoYFolio(true);
        }
    };

    const onchange = async (e) => {
        setNuevoCobro({
            ...nuevoCobro,
            [e.target.name]: e.target.value,
        });
    };

    const onchangeEfectivo = (e) => {
        let total = 0;
        let value = Number(e.target.value.replace("$", ""));

        //Eliminamos valores negativos
        if (value < 0) {
            value = value * -1;
        }

        //Obtenemos el total del cobro sumando el importa y el iva
        if (nuevoCobro.importe > 0) {
            total += Number(nuevoCobro.importe);
        }

        if (nuevoCobro.iva > 0) {
            total += Number(nuevoCobro.iva);
        }

        //Si lo que recibimos el mayor o igual que el total, le indicamos el cambio que se debe regresar
        if (total <= value) {
            setNuevoCobro({
                ...nuevoCobro,
                recibido: value,
                cambio: (value - total).toFixed(2),
            });
        } else {
            setNuevoCobro({
                ...nuevoCobro,
                recibido: value,
                cambio: 0,
            });
        }
    };

    const submitCobroVale = async () => {
        if (!nuevoCobro.idCatCliente) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor ingrese el cliente responsable del cobro.",
            });
            return;
        }

        if (!nuevoCobro.idCatTipoCobro) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor ingrese la forma de pago.",
            });
            return;
        }

        if (!nuevoCobro.importe) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor ingrese el subtotal",
            });
            return;
        }

        setLoading(true);

        const res = await generarNuevoCobro(nuevoCobro);

        if (res.data.error) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_cancelar_vale: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_insertar_cobro: false,
                dynamic_description: "Se generó un nuevo cobro",
            });
        }
    };

    const eliminarCobro = async () => {
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_eliminar_cobro: false,
        });

        setLoading(true);

        const res = await eliminarNuevoCobro(cobroEliminar.idVentaValeCobro);

        if (res.data.error) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_eliminar_cobro: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_eliminar_cobro: false,
                dynamic_description: "Se eliminó el cobro",
            });
            calcularCantidadRestante();
        }

        setCobroEliminar({});
    };

    const eliminarProgresoCobroVale = async () => {
        setUISweetAlert({
            ...UISweetAlert,
            confirm_eliminar_progreso_cobro: false,
        });

        setLoading(true);

        const res = await eliminarProgresoCobroValePersonal(idVale);

        if (res.data.error) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_eliminar_progreso_cobro: false,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);

            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_eliminar_progreso_cobro: false,
                dynamic_description: "Se generó un nuevo cobro",
                redirect: true,
                path: "/vales",
            });
        }
    };

    const calcularPorcentajes = () => {
        const importe = valeSeleccionado.ventavales[0].importeTotal;
        const iva = valeSeleccionado.ventavales[0].ivaTotal;

        const total = importe + iva;
        let procentajeIVA = ((iva * 100) / total).toFixed(2);
        let procentajeImporte = ((importe * 100) / total).toFixed(2);
        // if( (Number(procentajeImporte) + Number(procentajeIVA)) != 100){
        //     procentajeImporte = Number(procentajeImporte) + (Number(procentajeImporte) + Number(procentajeIVA));
        // }

        setPorcentajeIVAImporte({
            procentajeIVA: procentajeIVA,
            procentajeImporte: procentajeImporte,
        });
    };

    const calcularCantidadRestante = () => {
        const importeVale = valeSeleccionado.ventavales[0].importeTotal;
        const ivaVale = valeSeleccionado.ventavales[0].ivaTotal;

        let ivaCobros = 0;
        let importeCobros = 0;

        valeSeleccionado.ventavales[0].ventavalecobro.map((cobro) => {
            ivaCobros += Number(cobro.iva);
            importeCobros += Number(cobro.importe);
        });


        let diferencia = (importeVale + ivaVale - ivaCobros - importeCobros).toFixed(2);


        setTotalDiferencia({
            total: (importeVale + ivaVale).toFixed(2),
            diferencia: diferencia,
            importeCobro: importeCobros.toFixed(2),
            ivaCobro: ivaCobros.toFixed(2),
            importeVale: importeVale.toFixed(2),
            ivaVale: ivaVale.toFixed(2),
        });

        //Si la diferencia llega 0, ya se cobro todo
        if (diferencia <= 0) {
            setHiddenInsertarCobro(true);
            diferencia = 0;
        } else {
            setHiddenInsertarCobro(false);
        }
    };

    const onchangeCantidadPagar = (e) => {
        let value = e.target.value;
        value = Number(value.replace("$", ""));
        if (value > totalDiferencia.diferencia) {
            setNuevoCobro({
                ...nuevoCobro,
                cantidadPagar: 0,
                iva: 0,
                importe: 0,
                recibido: null,
                cambio: null,
            });

            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: `El restante es $${parseFloat(totalDiferencia.diferencia).toFixed(
                    2
                )}, la cantidad introducida no debe ser mayor`,
            });
        } else {
            setNuevoCobro({
                ...nuevoCobro,
                cantidadPagar: value,
                iva: (value * porcentajeIVAImporte.procentajeIVA) / 100,
                importe: (value * porcentajeIVAImporte.procentajeImporte) / 100,
                recibido: null,
                cambio: null,
            });
        }
    };

    const pagarDiferencia = () => {
        setNuevoCobro({
            ...nuevoCobro,
            cantidadPagar: totalDiferencia.diferencia,
            importe: totalDiferencia.importeVale - totalDiferencia.importeCobro,
            iva: totalDiferencia.ivaVale - totalDiferencia.ivaCobro,
            recibido: null,
            cambio: null,
        });
    };

    const cambiarEstatusCobrado = async () => {
        if (totalDiferencia.diferencia > 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                confirm_alert_cambiar_estatus_cobrado: false,
                dynamic_description: "No se ha pagado toda la cantidad restante",
            });
            return;
        }

        const res = await actualizarOCambiarEstatus(cambioEstatusData);

        if (res) {
            setLoading(false);
            actualizarValeFiltrado(res.data);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_cambiar_estatus_cobrado: false,
                dynamic_description: "Se registraron los cambios del estatus.",
                redirect: true,
                path: "/vales",
            });
        } else {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_cambiar_estatus_cobrado: false,
                dynamic_title: "Fallo",
                dynamic_description: "Error al subir los cambios, Inténtalo por favor más tarde.",
            });
        }
    };

    const isCobroChecked = async () => {
        // let cobroChecked = false;

        // valeSeleccionado.ventavales[0].ventavalecobro.map((cobros) => {
        //   if (cobros.checked) {
        //     cobroChecked = cobros;
        //   }
        // });

        if (!selectedCobro) {
            //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor seleccione un cobro",
            });
            return false;
        }

        return true;
    };

    const imprimirReciboPago = async () => {
        const cobroChecked = await isCobroChecked();

        if (cobroChecked) {
            setLoading(true);
            const res = await exportReciboPagoVale(selectedCobro.idVentaValeCobro);

            if (res.data.error) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: res.data.message,
                });
            } else if (res) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        listarCatTipoCobros();
        listarClientes();
        getProximosEstatusVale(idVale);
        if (!valeSeleccionado) {
            seleccionarVale(idVale, false);
        }

        if (valeSeleccionado) {
            //Checamos que tipo de medico es, para saber si tienen permitido entrar a la vista
            //Si es medico interno, tiene que estar en aplicado
            //Si es medico externo, tiene que estar en por aplicar
            if (
                valeSeleccionado.catMedicos.catTipoMedico.idCatTipoMedico == 1 &&
                valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale != 8
            ) {
                props.history.push("/vales");
            } else if (
                valeSeleccionado.catMedicos.catTipoMedico.idCatTipoMedico == 2 &&
                valeSeleccionado.estatusvales[valeSeleccionado.estatusvales.length - 1].catEstatusvale.idCatEstatusVale != 6
            ) {
            }

            setNuevoCobro({
                idVale: idVale,
            });

            setHiddenCatCobroEfectivo(true);
            setHiddenBancoYFolio(true);
            calcularPorcentajes();
            calcularCantidadRestante();

            if (valeSeleccionado.catMedicos.catTipoMedico.idCatTipoMedico == 1) {
                setHiddenComplementoPago(false);
            } else {
                setHiddenGenerarFactura(false);
            }

            if (proximosEstatusVale.length > 0) {
                setcambioEstatusData({
                    idVale: idVale,
                    idEstatusNuevo: proximosEstatusVale[0].idCatEstatusVale,
                });
            }
        }
    }, [valeSeleccionado]);

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            {UISweetAlert.confirm_eliminar_progreso_cobro ? (
                <SweetAlert
                    title="¿Desea salir, borrando todos los cobros?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarProgresoCobroVale()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_eliminar_progreso_cobro: false,
                        })
                    }></SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_cambiar_estatus_cobrado ? (
                <SweetAlert
                    title="¿Cambiar el estatus a cobrado?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => cambiarEstatusCobrado()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_cambiar_estatus_cobrado: false,
                        })
                    }></SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_insertar_cobro ? (
                <SweetAlert
                    title="Nuevo cobro"
                    showCancel
                    confirmBtnText="Cobrar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "60%",
                    }}
                    onConfirm={() => submitCobroVale()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_insertar_cobro: false,
                        });
                        setNuevoCobro({
                            idVale: idVale,
                        });
                    }}>
                    <div>
                        <Row className="form-group mt-2">
                            <Col sm={4}></Col>
                            <Col sm={7}>
                                <button
                                    className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-2 mb-2 flex hover:text-white"
                                    type="button"
                                    onClick={pagarDiferencia}>
                                    Pagar la diferencia
                                </button>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Restante por pagar:</label>
                            <Col sm={7}>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                    value={totalDiferencia.diferencia ? `$${totalDiferencia.diferencia}` : `$0`}
                                    style={{
                                        textAlign: "right",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group mt-2">
                            <label className="col-sm-4 col-form-label">Cliente</label>
                            <Col sm={7}>
                                <select className="form-control" name="idCatCliente" onChange={onchange} value={nuevoCobro.idCatCliente}>
                                    <option selected value="">
                                        Seleccione una opción
                                    </option>
                                    {clientes.map((cliente) => {
                                        return (
                                            <>
                                                <option
                                                    value={
                                                        cliente.idCatCliente
                                                    }>{`${cliente.catPersona.nombres} ${cliente.catPersona.apellidoPaterno} ${cliente.catPersona.apellidoMaterno}`}</option>
                                            </>
                                        );
                                    })}
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-group mt-2">
                            <label className="col-sm-4 col-form-label">Forma de Pago</label>
                            <Col sm={7}>
                                <select
                                    className="form-control"
                                    name="idCatTipoCobro"
                                    onChange={onChangeTipoCobro}
                                    value={nuevoCobro.idCatTipoCobro}>
                                    <option selected value="">
                                        Seleccione una opción
                                    </option>
                                    {tipoCobros.map((cobro) => {
                                        return (
                                            <>
                                                <option value={cobro.idCatTipoCobro}>{cobro.tipoCobro}</option>
                                            </>
                                        );
                                    })}
                                </select>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Cantidad a pagar:</label>
                            <Col sm={7}>
                                <input
                                    type="text"
                                    onWheel={(event) => event.currentTarget.blur()}
                                    className="form-control"
                                    // value={nuevoCobro.cantidadPagar ? formatNumber(nuevoCobro.cantidadPagar) : `$0`}
                                    value={nuevoCobro.cantidadPagar ? `$${nuevoCobro.cantidadPagar}` : `$0`}
                                    onChange={onchangeCantidadPagar}
                                    style={{
                                        textAlign: "right",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Importe:</label>
                            <Col sm={7}>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={nuevoCobro.importe ? formatNumber(nuevoCobro.importe) : `$0`}
                                    //   value={nuevoCobro.importe ? `$${nuevoCobro.importe.toFixed(2)}` : `$0`}
                                    disabled={true}
                                    style={{
                                        textAlign: "right",
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">IVA:</label>
                            <Col sm={7}>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={nuevoCobro.iva ? formatNumber(nuevoCobro.iva) : `$0`}
                                    //   value={nuevoCobro.iva ? `$${nuevoCobro.iva.toFixed(2)}` : `$0`}
                                    disabled={true}
                                    style={{
                                        textAlign: "right",
                                    }}
                                />
                            </Col>
                        </Row>

                        {!hiddenCatCobroEfectivo ? (
                            <div>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Recibido</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control outline-orange-300"
                                            onChange={onchangeEfectivo}
                                            // value={nuevoCobro.recibido ? formatNumber(nuevoCobro.recibido) : `$0`}
                                            value={nuevoCobro.recibido ? `${nuevoCobro.recibido}` : `$0`}
                                            disabled={nuevoCobro.cantidadPagar ? false : true}
                                            style={{textAlign: "right"}}
                                        />
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Cambio</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={nuevoCobro.cambio ? formatNumber(nuevoCobro.cambio) : `$0`}
                                            // value={nuevoCobro.cambio ? `$${nuevoCobro.cambio}` : `$0`}
                                            disabled={true}
                                            style={{textAlign: "right"}}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        {!hiddenConceptoDePago ? (
                            <Row className="form-group">
                                <label className="col-sm-4 col-form-label">Concepto de Pago</label>
                                <Col sm={7}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={nuevoCobro.conceptoDePago}
                                        name="conceptoDePago"
                                        onChange={onchange}
                                    />
                                </Col>
                            </Row>
                        ) : null}
                        {!hiddenBancoYFolio ? (
                            <div>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Número de Referencia</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={nuevoCobro.folioDeposito}
                                            name="folioDeposito"
                                            onChange={onchange}
                                        />
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Banco</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={nuevoCobro.bancoDeposito}
                                            name="bancoDeposito"
                                            onChange={onchange}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        {/* {
                        !hiddenConfirmarContabilidad ? (
                            <div>
                                <Row className="form-group">
                                    <Col sm={4}>
                                    </Col>
                                    <Col sm={7}>
                                    <button 
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                    >
                                        Solicitar Confirmación
                                    </button>
                                    </Col>
                                </Row>
                                
                            </div>
                        ): null
                    } */}
                    </div>
                </SweetAlert>
            ) : null}

            <h3 className="flex h3">Gestión de Cobro</h3>

            <Card className="mt-10">
                <CardBody>
                    <Row>
                        <Col xl={6} lg={12} md={12}>
                            <Row className="form-group">
                                <label className="col-sm-4 col-form-label">Subtotal</label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        disabled={true}
                                        value={
                                            valeSeleccionado && valeSeleccionado.ventavales[0].importeTotal
                                                ? formatNumber(valeSeleccionado.ventavales[0].importeTotal)
                                                : "$0"
                                        }
                                        // value={valeSeleccionado && valeSeleccionado.ventavales[0].importeTotal ? `$${valeSeleccionado.ventavales[0].importeTotal.toFixed(2)}`  : "$0"}
                                        type="text"
                                        style={{
                                            textAlign: "right",
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <label className="col-sm-4 col-form-label">IVA</label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={
                                            valeSeleccionado && valeSeleccionado.ventavales[0].ivaTotal
                                                ? `$${valeSeleccionado.ventavales[0].ivaTotal.toFixed(2)}`
                                                : "$0"
                                        }
                                        disabled={true}
                                        style={{
                                            textAlign: "right",
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <label className="col-sm-4 col-form-label">Total</label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        disabled={true}
                                        value={
                                            valeSeleccionado
                                                ? [
                                                    valeSeleccionado.ventavales[0].ivaTotal
                                                        ? formatNumber(
                                                            valeSeleccionado.ventavales[0].importeTotal + valeSeleccionado.ventavales[0].ivaTotal
                                                        )
                                                        : formatNumber(valeSeleccionado.ventavales[0].importeTotal),
                                                ]
                                                : "$0"
                                        }
                                        // value={
                                        //   valeSeleccionado
                                        //     ? [
                                        //         valeSeleccionado.ventavales[0].ivaTotal
                                        //           ? `$${(valeSeleccionado.ventavales[0].importeTotal + valeSeleccionado.ventavales[0].ivaTotal).toFixed(2)}`
                                        //           : `$${valeSeleccionado.ventavales[0].importeTotal.toFixed(2)}`,
                                        //       ]
                                        //     : "$0"
                                        // }
                                        type="text"
                                        style={{
                                            textAlign: "right",
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col xl={6} lg={12} md={12}>
                            <Row className="form-group">
                                <label className="col-sm-4 col-form-label">Fecha</label>
                                <Col sm={7}>
                                    <input disabled value={new Date().toISOString().slice(0, 10)} className="form-control" type="date"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card className="mt-8">
                <CardBody>
                    <Row>
                        <Col>
                            {!hiddenReciboPago ? (
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                    onClick={imprimirReciboPago}>
                                    Generar Recibo de Pago
                                </button>
                            ) : null}
                            {!hiddenGenerarFactura ? (
                                <button className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6" onClick={() => checkFinalInvoice(selectedCobro)}>
                                    Generar Factura
                                </button>
                            ) : null}
                            {!hiddenComplementoPago ? (
                                <button className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6">
                                    Generar Complemento de Pago
                                </button>
                            ) : null}
                            {!hiddenInsertarCobro ? (
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                    onClick={() => {
                                        setUISweetAlert({
                                            ...UISweetAlert,
                                            confirm_alert_insertar_cobro: true,
                                        });
                                    }}>
                                    Insertar Cobro
                                </button>
                            ) : null}
                            {hiddenInsertarCobro ? (
                                <button
                                    className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6"
                                    onClick={() => {
                                        setUISweetAlert({
                                            ...UISweetAlert,
                                            confirm_alert_cambiar_estatus_cobrado: true,
                                        });
                                    }}>
                                    Cambio Estatus Cobrado
                                </button>
                            ) : null}
                        </Col>
                    </Row>

                    <div className="table-responsive mt-4">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">
                                    {/* <input type="checkbox" onClick={() => checkedAllCobros()} checked={checkAllCobros} />  */}
                                </th>
                                <th scope="col">
                                    Num.<br></br>Cobro
                                </th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Cliente</th>
                                <th scope="col">Subtotal</th>
                                <th scope="col">Iva</th>
                                <th scope="col">Total</th>
                                <th scope="col">
                                    Folio<br></br>de Pago
                                </th>
                                <th scope="col">
                                    Concepto<br></br>de Pago
                                </th>
                                <th scope="col">
                                    Folio<br></br>Factura
                                </th>
                                <th scope="col">Eliminar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {valeSeleccionado &&
                                valeSeleccionado.ventavales[0].ventavalecobro.map((cobros, index) => {
                                    return (
                                        <>
                                            <tr key={cobros.idVentaValeCobro}>
                                                <td>
                                                    {
                                                        <input
                                                            type="checkbox"
                                                            onClick={() => handdleChecked(cobros)}
                                                            checked={selectedCobro != null ? cobros?.idVentaValeCobro === selectedCobro?.idVentaValeCobro : false}
                                                            // value={cobros.idVentaValeCobro}
                                                        />
                                                    }
                                                </td>
                                                <td>{cobros.idVentaValeCobro}</td>
                                                <td>{cobros.catTipoCobro.tipoCobro}</td>
                                                <td>{`${cobros.catCliente.catPersona.nombres} ${cobros.catCliente.catPersona.apellidoPaterno} ${cobros.catCliente.catPersona.apellidoMaterno}`}</td>
                                                <td
                                                    style={{
                                                        textAlign: "right",
                                                    }}>
                                                    {formatNumber(cobros.importe)}
                                                    {/* ${cobros.importe.toFixed(2)} */}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "right",
                                                    }}>
                                                    {formatNumber(cobros.iva)}
                                                    {/* ${cobros.iva.toFixed(2)} */}
                                                </td>
                                                <td
                                                    style={{
                                                        textAlign: "right",
                                                    }}>
                                                    {formatNumber(cobros.importe + cobros.iva)}
                                                    {/* ${(cobros.importe + cobros.iva).toFixed(2)} */}
                                                </td>
                                                <td>{cobros.folioDeposito ? cobros.folioDeposito : ""}</td>
                                                <td>{cobros.conceptoPago ? cobros.conceptoPago : ""}</td>
                                                <td>{/* {cobros.factura.folioFactura} */}</td>
                                                <td>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                                            onClick={() => {
                                                                setCobroEliminar(cobros);
                                                                setUISweetAlert({
                                                                    ...UISweetAlert,
                                                                    confirm_alert_eliminar_cobro: true,
                                                                });
                                                            }}>
                                                            Eliminar
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>

            <Card className="mt-8">
                <CardBody>
                    <Row>
                        <Col md="12">
                            <table
                                className="w-2/6"
                                style={{
                                    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                    boxSizing: "border-box",
                                    fontSize: "14px",
                                    backgroundColor: "inherit",
                                    margin: "0",
                                    float: "right",
                                }}
                                bgcolor="#f6f6f6">
                                <tbody>
                                <tr
                                    style={{
                                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                    }}>
                                    <td
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            borderTopWidth: "1px",
                                            borderTopColor: "#eee",
                                            borderTopStyle: "solid",
                                            margin: "0",
                                            padding: "5px 0",
                                            textAlign: "left",
                                        }}
                                        valign="top">
                                        Total
                                    </td>
                                    <td
                                        className="alignright"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "right",
                                            borderTopWidth: "1px",
                                            borderTopColor: "#eee",
                                            borderTopStyle: "solid",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="right"
                                        valign="top">
                                        {formatNumber(totalDiferencia.total)}
                                        {/* ${totalDiferencia.total} */}
                                    </td>
                                </tr>
                                <tr
                                    className="total"
                                    style={{
                                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                        boxSizing: "border-box",
                                        fontSize: "14px",
                                        margin: "0",
                                    }}>
                                    <td
                                        width="80%"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "left",
                                            borderTopWidth: "5px",
                                            borderTopColor: "#333",
                                            borderTopStyle: "double",
                                            borderBottomColor: "#333",
                                            borderBottomWidth: "2px",
                                            borderBottomStyle: "solid",
                                            fontWeight: "700",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="left"
                                        valign="top">
                                        Diferencia
                                    </td>
                                    <td
                                        className="alignright"
                                        style={{
                                            fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                                            boxSizing: "border-box",
                                            fontSize: "14px",
                                            verticalAlign: "top",
                                            textAlign: "right",
                                            borderTopWidth: "5px",
                                            borderTopColor: "#333",
                                            borderTopStyle: "double",
                                            borderBottomColor: "#333",
                                            borderBottomWidth: "2px",
                                            borderBottomStyle: "solid",
                                            fontWeight: "700",
                                            margin: "0",
                                            padding: "5px 0",
                                        }}
                                        align="right"
                                        valign="top">
                                        {formatNumber(totalDiferencia.diferencia)}
                                        {/* ${totalDiferencia.diferencia} */}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {UISweetAlert.confirm_alert_eliminar_cobro ? (
                <SweetAlert
                    title="¿Desea eliminar el cobro?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => eliminarCobro()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_eliminar_cobro: false,
                        });
                        setCobroEliminar({});
                    }}>
                    Se perderá la información
                </SweetAlert>
            ) : null}


            {UISweetAlert.confirm_datos_fiscales ? (
                <SweetAlert
                    title="Por favor, confirma todos los datos fiscales para la factura definitiva"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => confirmNewFinalInvoice({selectedData: selectedCobro, valesSeleccionados: [valeSeleccionado.idVale]})}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_datos_fiscales: false,
                        });
                    }}>
                    Complete los datos fiscales con los que se deberá facturar
                    <ModalDatosFiscales
                        catRegimenFiscales={catRegimenFiscales}
                        catUsoCfdis={catUsoCfdis}
                        tipoPersona={{idCatMedico: null, idCatCliente: selectedCobro.catCliente?.idCatCliente, idAseguradora: null}}
                        datosFiscales={datosFiscales}
                        onChange={onChangeFiscalData}
                        actualizarDatosFiscales={() => checkUpdateFiscalData("confirm_actualizar_datos_fiscales")}
                        lugares={lugares}
                        publicoGeneral={true}
                    />
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_actualizar_datos_fiscales ? (
                <SweetAlert
                    title="¿Desea actualizar los datos fiscales?"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => confirmUpdateFiscalData()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_actualizar_datos_fiscales: false,
                        });
                    }}
                />
            ) : null}

            {UISweetAlert.respuestas_facturas ? (
                <SweetAlert
                    title="Resultados de Facturación"
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "80%",
                    }}
                    onConfirm={() => {
                        setRespuestaFacturas(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            respuestas_facturas: false,
                        });
                    }}
                    onCancel={() => {
                        setRespuestaFacturas(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            respuestas_facturas: false,
                        });
                    }}>
                    <RespuestaFacturas
                        respuestas={respuestaFacturas}
                        downloadFactura={downloadFactura}
                    />
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.reload_page) {
                            window.location.reload();
                        }
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                            reload_page: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}


            {loading ? (
                <SweetAlert title="Cargando!" onConfirm={() => {
                }} showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
