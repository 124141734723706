import {
    FILTRO_PAGOORDENCOMPRA,
    LISTAR_PAGOORDENESCOMPRA,
    REGISTRAR_ORDENES_PAGADAS,
    ERROR_REGISTRAR_ORDENES_PAGADAS,
    REGISTRAR_ORDENES_ACTUALIZADAS,
    ERROR_REGISTRAR_ORDENES_ACTUALIZADAS
} from '../../types'

export default (state, action) => {
    switch (action.type){
        case FILTRO_PAGOORDENCOMPRA:
            return{
                ...state, 
                filtrosPagosOrdenCompra: action.payload
            }
        case LISTAR_PAGOORDENESCOMPRA:
            case REGISTRAR_ORDENES_PAGADAS:
                case REGISTRAR_ORDENES_ACTUALIZADAS:
                    return{
                        ...state,
                        pagosOrdenCompraFiltradas: action.payload
                    } 
        case ERROR_REGISTRAR_ORDENES_PAGADAS:
            case ERROR_REGISTRAR_ORDENES_ACTUALIZADAS:
                return{
                    ...state
                }
        default:
            return state
            
    }
}