import React, { useContext, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import RecepcionGenericaProductosContext from "../../context/RecepcionGenericaProductos/RecepcionGenericaProductosContext";

export default function InformacionGeneralRecepcionVenta(props) {
  const recepGenProdContext = useContext(RecepcionGenericaProductosContext);
  const { recepcionVentaProductosSeleccionada } = recepGenProdContext;

  const formatDate = async () => {
    if (!recepcionVentaProductosSeleccionada.fechaVenta.includes("T")) {
      return;
    }

    let splitDate = recepcionVentaProductosSeleccionada.fechaVenta.split("T")[0].split("-");
    splitDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];

    recepcionVentaProductosSeleccionada.fechaVenta = splitDate;
  };

  useEffect(() => {
    if (recepcionVentaProductosSeleccionada && recepcionVentaProductosSeleccionada.fechaVenta) {
      formatDate();
    }
  }, [recepcionVentaProductosSeleccionada]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Información General</h3>

      {recepcionVentaProductosSeleccionada.idVale ? (
        <Card className="mt-10 card" style={{ padding: "1.5rem 1.5rem 1.5rem" }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Fecha</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.fechaVenta : ""}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Tipo Vale</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.tipoVale : ""}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Aseguradora</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={
                        recepcionVentaProductosSeleccionada && recepcionVentaProductosSeleccionada.aseguradora
                          ? recepcionVentaProductosSeleccionada.aseguradora
                          : "N/A"
                      }
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Tipo Recepción</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.tipoRecepcion : ""}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Folio Vale</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.folioVenta : ""}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Paciente</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.cliente : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Médico Tratante</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.medico : ""}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Usuario Solicitante</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.usuarioSolicitante : ""}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : null}

      {recepcionVentaProductosSeleccionada.idVentaMostrador ? (
        <Card className="mt-10 card" style={{ padding: "1.5rem 1.5rem 1.5rem" }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Folio Venta Mostrador</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.folioVenta : ""}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Fecha</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.fechaVenta : ""}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Tipo Recepción</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.tipoRecepcion : ""}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Cliente</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.cliente : ""}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Vendedor</label>
                  <Col sm={7}>
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      value={recepcionVentaProductosSeleccionada ? recepcionVentaProductosSeleccionada.vendedor : ""}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : null}
    </div>
  );
}
