import React, { useReducer } from "react";
import { LISTAR_CATSERVICIOS, LISTAR_SERVICIOS_PAGINADOS, CAMBIOS_FILTROS_SERVICIOS } from "../../types";

import clienteAxios from "../../config/axios";
import CatServiciosReducer from "./CatServiciosReducer";
import CatServiciosContext from "./CatServiciosContext";

export default function CatServiciosState(props) {
  const initialState = {
    catServicios: [],
    filtrosServicios: {},
    serviciosPaginados: [],
  };

  const [state, dispatch] = useReducer(CatServiciosReducer, initialState);

  const listarCatServicios = async () => {
    const res = await clienteAxios.get(`/servicios/activos`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_CATSERVICIOS,
      payload: res.data,
    });
  };

  const setServiciosPaginados = async ({ page, limit }) => {
    try {
      const response = await clienteAxios.post(
        "/servicios/paginado",
        {
          pageSize: limit,
          pageIndex: page,
          ...state.filtrosServicios,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      dispatch({
        type: LISTAR_SERVICIOS_PAGINADOS,
        payload: response.data.servicios,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const updateOrCreateServicio = async (datosServicio) => {
    // Checar que el paciente no tenga el mismo CURP que otro paciente en la base datos

    try {
      const res = await clienteAxios.post(`/servicios/guardar`, datosServicio, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const setChangesFiltrosServicios = async (filtrosServicios) => {
    dispatch({
      type: CAMBIOS_FILTROS_SERVICIOS,
      payload: filtrosServicios,
    });
  };

  const eliminarServicio = async (idCatServicio) => {
    // Sustituir delete con dirección del back
    // const res = await clienteAxios.post(`/pacientes/${idCatAseguradora}`)
    try {
      const response = await clienteAxios.post(
        "/servicios/eliminar/",
        { idCatServicio: idCatServicio },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const actualizarPreciosProducto = async (productosActualizado) => {
    // Si se actualizaron precios de aseguradora y de producto servicio
    if (productosActualizado.preciosAseguradora.length > 0 && productosActualizado.preciosProductoServicio !== undefined) {
      try {
            const responsePreciosAseguradora = await clienteAxios.post(
              `productoservicio/actualizar/precios/aseguradoras`,
              productosActualizado.preciosAseguradora,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  'Cache-Control': 'no-store', // Evita el caché del navegador
                },
              }
            );
            
            const responsePreciosProductoServicio = await clienteAxios.post(
              `productoservicio/actualizar/precios/productoservicio`,
              productosActualizado.preciosProductoServicio,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  'Cache-Control': 'no-store', // Evita el caché del navegador
                },
              }
            );

          return { preciosAseguradora: responsePreciosAseguradora, preciosProductoServicio: responsePreciosProductoServicio };
      } catch (error) {
        if (!error.response) {
          error.response = {
            data: {
              message: "Fallo en la conexión al servidor",
              error: "hubo error",
            },
          };
        }
        return error.response;
      }
    }
    // Si solo se actualizaron precios de aseguradora
    if (productosActualizado.preciosAseguradora.length > 0 && productosActualizado.preciosProductoServicio === undefined) {
      try {
        const res = await clienteAxios.post(
          `productoservicio/actualizar/precios/aseguradoras`,
          productosActualizado.preciosAseguradora,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Cache-Control': 'no-store', // Evita el caché del navegador
            },
          }
        );

        return res;
      } catch (error) {
        if (!error.response) {
          error.response = {
            data: {
              message: "Fallo en la conexión al servidor",
              error: "hubo error",
            },
          };
        }

        return error.response;
      }
    }
    // Si solo se actualizaron precios de producto servicio
    if (productosActualizado.preciosAseguradora.length <= 0 && productosActualizado.preciosProductoServicio !== undefined) {
      try {
        const res = await clienteAxios.post(
          `productoservicio/actualizar/precios/productoservicio`,
          productosActualizado.preciosProductoServicio,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Cache-Control': 'no-store', // Evita el caché del navegador
            },
          }
        );

        return res;
      } catch (error) {
        if (!error.response) {
          error.response = {
            data: {
              message: "Fallo en la conexión al servidor",
              error: "hubo error",
            },
          };
        }

        return error.response;
      }
    }
  };

  return (
    <CatServiciosContext.Provider
      value={{
        catServicios: state.catServicios,
        filtrosServicios: state.filtrosServicios,
        serviciosPaginados: state.serviciosPaginados,
        listarCatServicios,
        setServiciosPaginados,
        setChangesFiltrosServicios,
        updateOrCreateServicio,
        eliminarServicio,
        actualizarPreciosProducto,
      }}>
      {props.children}
    </CatServiciosContext.Provider>
  );
}
