import React, { useReducer } from "react";

import CortesCajaContext from "./CortesCajaContext";
import CortesCajaReducer from "./CortesCajaReducer";

import clienteAxios from "../../config/axios";

import {
  FILTRO_CORTESCAJA,
  LISTAR_CORTESCAJA,
  FILTRO_VALESCORTECAJA,
  FILTRO_VENTAMOSTRADOR_CORTESCAJA,
  FILTRO_ASEGURADORA_CORTESCAJA,
  LISTAR_DETALLES_CORTECAJA,
  ERROR_CERRAR_CORTE_CAJA,
} from "../../types";

export default function CortesCajaState(props) {
  const initialState = {
    cortesCajaFiltrados: [],
    filtrosCortesCaja: {},
    filtrosVales: {},
    filtrosVentaMostrador: {},
    filtrosAseguradora: {},
    detallesCorteCaja: {},
  };

  const [state, dispatch] = useReducer(CortesCajaReducer, initialState);

  const setFiltrosCortesCaja = async (filtrosCortesCaja) => {
    dispatch({
      type: FILTRO_CORTESCAJA,
      payload: filtrosCortesCaja,
    });
  };

  const listarCortesCaja = async (filtrosCortesCaja) => {
    const res = await clienteAxios.post("/cortecaja/filtro/detalle", filtrosCortesCaja, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    res.data.map((corte) => {
      let fechaCorte = corte.fechaCorteCaja.split("T")[0];
      fechaCorte = fechaCorte.split("-");
      return (corte.fechaCorteCaja = `${fechaCorte[2]}/${fechaCorte[1]}/${fechaCorte[0]}`);
    });

    dispatch({
      type: LISTAR_CORTESCAJA,
      payload: res.data,
    });
  };

  const setCortesCajaFiltrados = async (cortesCajaFiltrados) => {
    dispatch({
      type: LISTAR_CORTESCAJA,
      payload: cortesCajaFiltrados,
    });
  };

  const cambiarStatusCorteCaja = async (corteCajaSeleccionado) => {
    try {
      const res = await clienteAxios.post(`/cortecaja/cierre/guardar`, corteCajaSeleccionado, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_CERRAR_CORTE_CAJA,
        payload: null,
      });
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const setFiltrosVales = async (filtrosVales) => {
    dispatch({
      type: FILTRO_VALESCORTECAJA,
      payload: filtrosVales,
    });
  };

  const setFiltrosVentaMostrador = async (filtrosVentaMostrador) => {
    dispatch({
      type: FILTRO_VENTAMOSTRADOR_CORTESCAJA,
      payload: filtrosVentaMostrador,
    });
  };

  const setFiltrosAseguradoras = async (filtrosAseguradora) => {
    dispatch({
      type: FILTRO_ASEGURADORA_CORTESCAJA,
      payload: filtrosAseguradora,
    });
  };

  const setDetallesCorteCaja = async ({ usuarios, idCorteCaja }) => {
    const responseVentaVale = await clienteAxios.post(
      `/cortecaja/filtro/cierre`,
      { ...usuarios, ventaMostrador: 0, idCorteCaja: idCorteCaja },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    const responseVentaMostrador = await clienteAxios.post(
      `/cortecaja/filtro/cierre`,
      { ...usuarios, ventaMostrador: 1, idCorteCaja: idCorteCaja },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    responseVentaVale.data.map((obj) => {
      let fecha = obj.fecha.split("T")[0];
      let hora = obj.fecha.split("T")[1];
      hora = hora.split(":");
      hora = `${hora[0]}:${hora[1]}`;
      fecha = fecha.split("-");
      fecha = `${fecha[2]}/${fecha[1]}/${fecha[0]}`;
      return (obj.fechaHora = fecha + " - " + hora);
    });

    responseVentaMostrador.data.map((obj) => {
      let fecha = obj.fecha.split("T")[0];
      let hora = obj.fecha.split("T")[1];
      hora = hora.split(":");
      hora = `${hora[0]}:${hora[1]}`;
      fecha = fecha.split("-");
      fecha = `${fecha[2]}/${fecha[1]}/${fecha[0]}`;
      return (obj.fechaHora = fecha + " - " + hora);
    });

    const vales = responseVentaVale.data ? responseVentaVale.data.filter((vale) => vale.idCatAseguradora == null) : [];
    const ventasMostrador = responseVentaMostrador.data ? responseVentaMostrador.data : [];
    const aseguradoras = responseVentaMostrador.data ? responseVentaMostrador.data.filter((vale) => vale.idCatAseguradora !== null && vale.idValeAseguradoraCobro !== null) : [];

    // console.log(vales);
    // console.log(ventasMostrador);
    // console.log(aseguradoras);

    // const vales = responseVentaVale.data;

    // const ventasMostrador = responseVentaMostrador.data;

    const data = { vales: vales, ventasMostrador: ventasMostrador, aseguradoras: aseguradoras };

    dispatch({
      type: LISTAR_DETALLES_CORTECAJA,
      payload: data,
    });

    return data;
  };

  const cerrarCorteCajaSeleccionado = async ({ vales, ventasMostrador }) => {
    try {
      const res = await clienteAxios.post(
        `/cortecaja/cierre/guardar`,
        { movimientos: [...vales, ...ventasMostrador] },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return res;
    } catch (error) {
      dispatch({
        type: ERROR_CERRAR_CORTE_CAJA,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const updateCortesCaja = async (cortesCaja) => {
    dispatch({
      type: LISTAR_DETALLES_CORTECAJA,
      payload: cortesCaja,
    });
  };

  return (
    <CortesCajaContext.Provider
      value={{
        cortesCajaFiltrados: state.cortesCajaFiltrados,
        valesCorteCajaFiltrados: state.valesCorteCajaFiltrados,
        filtrosCortesCaja: state.filtrosCortesCaja,
        filtrosVales: state.filtrosVales,
        filtrosVentaMostrador: state.filtrosVentaMostrador,
        filtrosAseguradora: state.filtrosAseguradora,
        detallesCorteCaja: state.detallesCorteCaja,

        listarCortesCaja,
        setFiltrosCortesCaja,
        setCortesCajaFiltrados,
        cambiarStatusCorteCaja,
        setFiltrosVales,
        setFiltrosVentaMostrador,
        setFiltrosAseguradoras,
        setDetallesCorteCaja,
        cerrarCorteCajaSeleccionado,
        updateCortesCaja,
      }}>
      {props.children}
    </CortesCajaContext.Provider>
  );
}

// const actualizarComentarioVale = async (valeActualizado) => {
//   // try {
//   //   const res = await clienteAxios.post(
//   //     `/cortecaja/vale/modificar`,
//   //     valeActualizado,
//   //     {
//   //       headers: {
//   //         "Access-Control-Allow-Origin": "*",
//   //       },
//   //     }
//   //   );

//   //   dispatch({
//   //     type: MODIFICAR_COMENTARIO_CORTECAJA,
//   //     payload: res.data,
//   //   });

//   //   return res;
//   // } catch (error) {
//   //   dispatch({
//   //     type: ERROR_MODIFICAR_COMENTARIO_CORTECAJA,
//   //     payload: null,
//   //   });

//   //   if (!error.response) {
//   //     error.response = {
//   //       data: {
//   //         message: "Fallo en la conexión al servidor",
//   //         error: "hubo error",
//   //       },
//   //     };
//   //   }

//   //   return error.response;
//   // }
//   let detallesActualizados = state.detallesCorteCaja.vales.map((vale) => {
//     if (vale.idVale === valeActualizado.idVale) {
//       return { ...vale, comentario: valeActualizado.nuevoComentario };
//     } else {
//       return vale;
//     }
//   });
//   detallesActualizados = {
//     ...state.detallesCorteCaja,
//     vales: detallesActualizados,
//   };

//   console.log(detallesActualizados);
//   dispatch({
//     type: LISTAR_DETALLES_CORTECAJA,
//     payload: detallesActualizados,
//   });
// };

// const actualizarComentarioVentaMostrador = async (ventaActualizada) => {
//   // try {
//   //   const res = await clienteAxios.post(
//   //     `/cortecaja/ventamostrador/modificar`,
//   //     valeActualizado,
//   //     {
//   //       headers: {
//   //         "Access-Control-Allow-Origin": "*",
//   //       },
//   //     }
//   //   );

//   //   dispatch({
//   //     type: MODIFICAR_COMENTARIO_CORTECAJA,
//   //     payload: res.data,
//   //   });

//   //   return res;
//   // } catch (error) {
//   //   dispatch({
//   //     type: ERROR_MODIFICAR_COMENTARIO_CORTECAJA,
//   //     payload: null,
//   //   });

//   //   if (!error.response) {
//   //     error.response = {
//   //       data: {
//   //         message: "Fallo en la conexión al servidor",
//   //         error: "hubo error",
//   //       },
//   //     };
//   //   }

//   //   return error.response;
//   // }
//   let detallesActualizados = state.detallesCorteCaja.ventasMostrador.map((ventaMostrador) => {
//     if (ventaMostrador.idVentaMostrador === ventaActualizada.idVentaMostrador) {
//       return {
//         ...ventaMostrador,
//         comentario: ventaActualizada.nuevoComentario,
//       };
//     } else {
//       return ventaMostrador;
//     }
//   });

//   detallesActualizados = {
//     ...state.detallesCorteCaja,
//     ventasMostrador: detallesActualizados,
//   };

//   console.log(detallesActualizados);
//   dispatch({
//     type: LISTAR_DETALLES_CORTECAJA,
//     payload: detallesActualizados,
//   });
// };
// const actualizarComentarioAseguradora = async (movimientoAseguradoraActualizado) => {
//   // try {
//   //   const res = await clienteAxios.post(
//   //     `/cortecaja/ventamostrador/aseguradora`,
//   //     valeActualizado,
//   //     {
//   //       headers: {
//   //         "Access-Control-Allow-Origin": "*",
//   //       },
//   //     }
//   //   );

//   //   dispatch({
//   //     type: MODIFICAR_COMENTARIO_CORTECAJA,
//   //     payload: res.data,
//   //   });

//   //   return res;
//   // } catch (error) {
//   //   dispatch({
//   //     type: ERROR_MODIFICAR_COMENTARIO_CORTECAJA,
//   //     payload: null,
//   //   });

//   //   if (!error.response) {
//   //     error.response = {
//   //       data: {
//   //         message: "Fallo en la conexión al servidor",
//   //         error: "hubo error",
//   //       },
//   //     };
//   //   }

//   //   return error.response;
//   // }
//   let detallesActualizados = state.detallesCorteCaja.aseguradoras.map((aseguradora) => {
//     if (aseguradora.idMovimientoAseguradora === movimientoAseguradoraActualizado.idMovimientoAseguradora) {
//       return {
//         ...aseguradora,
//         comentario: movimientoAseguradoraActualizado.nuevoComentario,
//       };
//     } else {
//       return aseguradora;
//     }
//   });

//   detallesActualizados = {
//     ...state.detallesCorteCaja,
//     aseguradoras: detallesActualizados,
//   };

//   console.log(detallesActualizados);
//   dispatch({
//     type: LISTAR_DETALLES_CORTECAJA,
//     payload: detallesActualizados,
//   });
// };
