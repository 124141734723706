import React, { useReducer } from "react";

import FabricantesContext from "./FabricantesContext";
import FabricantesReducer from "./FabricantesReducer";

import { LISTAR_FABRICANTES, SET_FABRICANTES_PAGINADAS, FILTRO_FABRICANTES } from "../../types";

import clienteAxios from "../../config/axios";

export default function FabricantesState(props) {
  const initialState = {
    fabricantes: [],
    fabricantesPaginados: [],
    filtrosFabricantes: {
      razonSocial: "",
      contacto: "",
      rfc: "",
    },
  };

  const [state, dispatch] = useReducer(FabricantesReducer, initialState);

  const setDataFabricante = async ({ page, limit }) => {
    try {
      const response = await clienteAxios.post(
        "/laboratorios/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          ...state.filtrosFabricantes,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      dispatch({
        type: SET_FABRICANTES_PAGINADAS,
        payload: response.data.fabricantes,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const listarFabricantes = async () => {
    const res = await clienteAxios.get("/laboratorios/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: LISTAR_FABRICANTES,
      payload: res.data,
    });
  };

  const setFiltrosFabricantes = async (filtrosFabricantes) => {
    dispatch({
      type: FILTRO_FABRICANTES,
      payload: filtrosFabricantes,
    });
  };

  const uploadNuevoFabricante = async (datosFabricante) => {
    // Checar que el fabricante no exista en la base de datos con el mismo nombre

    try {
      const res = await clienteAxios.post(`/laboratorios/guardar`, datosFabricante, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarFabricante = async (idCatLaboratorio) => {
    // Sustituir delete con dirección del back
    // const res = await clienteAxios.post(`/pacientes/${idCatAseguradora}`)
    try {
      const response = await clienteAxios.post(
        "/laboratorios/eliminar/",
        { idCatLaboratorio: idCatLaboratorio },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const updateFabricante = async (fabricanteModificado) => {
    try {
      const res = await clienteAxios.post(`/laboratorios/guardar`, fabricanteModificado, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <FabricantesContext.Provider
      value={{
        fabricantes: state.fabricantes,
        fabricantesPaginados: state.fabricantesPaginados,
        filtrosFabricantes: state.filtrosFabricantes,
        listarFabricantes,
        setDataFabricante,
        setFiltrosFabricantes,
        updateFabricante,
        eliminarFabricante,
        uploadNuevoFabricante,
      }}>
      {props.children}
    </FabricantesContext.Provider>
  );
}
