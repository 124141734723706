import { Row, Col, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import useWindowDimensions from "../../hooks/dimensionsHook";

import SpanError from "../UI/spanError";

export const CargaArchivoModal = (props) => {
  const { width } = useWindowDimensions();
  const { datosCargaArchivo, infoSweetAlert } = props;
  const fileData = () => {
    if (datosCargaArchivo.selectedFile) {
      return (
        <>
          <label className="col-sm-5 col-form-label">Nombre del Archivo</label>
          <Col sm={7}>
            <input
              disabled={true}
              type="text"
              className="form-control"
              autoComplete="off"
              value={datosCargaArchivo.selectedFile.name}
            />
          </Col>
          <label className="col-sm-5 col-form-label">Tipo de Archivo</label>
          <Col sm={7}>
            <input
              disabled={true}
              type="text"
              className="form-control"
              autoComplete="off"
              value={datosCargaArchivo.selectedFile.type}
            />
          </Col>
        </>
      );
    } else {
      return (
        <>
          <br />
          <label className="col-sm-5 col-form-label">Elige un archivo antes de guardar</label>
        </>
      );
    }
  };
  return (
    <>
      <SweetAlert
        title={infoSweetAlert.title}
        confirmBtnText={infoSweetAlert.confirmBtnText}
        confirmBtnBsStyle="success"
        cancelBtnText= "Regresar"
        cancelBtnBsStyle="danger"
        showCancel
        closeOnClickOutside={false}
        style={{
          width: width && width < 851 ? "100%" : "80%",
        }}
        onConfirm={() => {
          props.onFileUpload();
        }}
        onCancel={() => {
          props.onCancel();
        }}>
        {!props.documentoPrevio ? (
          <CardBody>
            <Row className="mt-3">
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">
                    Archivo<SpanError>*</SpanError>
                  </label>
                  <Col sm={8}>
                    <input type="file" onChange={(e) => props.onFileChange(e)} accept={props.filesAccepted} />
                  </Col>
                  {fileData()}
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">
                    Fecha de Registro <SpanError>*</SpanError>
                  </label>
                  <Col sm={7}>
                    <input
                      disabled={props.disabledFecha != null && props.disabledFecha != undefined ? props.disabledFecha : false}
                      type="date"
                      className="form-control"
                      name="fecha"
                      autoComplete="off"
                      onChange={(e) => props.onChange(e)}
                      value={datosCargaArchivo.fecha ? datosCargaArchivo.fecha : ""}
                    />
                    {datosCargaArchivo.fecha == null || datosCargaArchivo.fecha == "" ? <SpanError></SpanError> : null}
                  </Col>
                  {props.conComentario ? (
                    <>
                      <label className="col-sm-5 col-form-label">
                        Comentarios<SpanError>*</SpanError>
                      </label>
                      <Col sm={7}>
                        <textarea
                          className="form-control mb-2"
                          name="comentario"
                          autoComplete="off"
                          onChange={(e) => props.onChange(e)}
                          value={datosCargaArchivo.comentario ? datosCargaArchivo.comentario : ""}
                        />
                        {datosCargaArchivo.comentario == null || datosCargaArchivo.comentario == "" ? (
                          <SpanError></SpanError>
                        ) : null}
                      </Col>
                    </>
                  ) : null}
                  {props.children}
                  {/*props.exportFormato ? (
                    <>
                      <label className="col-sm-5 col-form-label">Formato</label>
                      <Col sm={7}>
                        <input
                          type="button"
                          onClick={() => props.exportFormato()}
                          style={{ background: "#ff9933", color: "white", fontWeight: "bold" }}
                          className="form-control btn btn-primary-purple-umo w-md waves-effect waves-light"
                          value="Descargar Formato"
                        />
                      </Col>
                    </>
                  ) : null*/}
                </Row>
              </Col>
            </Row>
            {props.aviso ? (
              <span className="font-bold">
                {props.aviso.info}
                <a href={props.aviso.linkInfo} target="_blank">{props.aviso.linkInfo} </a>
              </span>
            ) : null}
          </CardBody>
        ) : (
          <CardBody>
            <div className="grid grid-cols-1 divide-y divide-yellow-500">
              <Row className="mb-4 mt-4">
                <Col xl={12} lg={12} md={12}>
                  <label className="col-sm-6 col-form-label">Documento Cargado Previamente</label>
                  <input
                    disabled={true}
                    type="text"
                    value={props.documentoPrevio ? props.documentoPrevio.nombreArchivo : "No hay Documento Previamente Cargado"}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6} lg={12} md={12} className="mt-4">
                  <Row className="form-group mt-3">
                    <label className="col-sm-5 col-form-label">Archivo de Carga</label>
                    <Col sm={7}>
                      <input
                        type="file"
                        onChange={(e) => props.onFileChange(e)}
                        accept={props.filesAccepted ? props.filesAccepted : "*"}
                      />
                    </Col>
                    {fileData()}
                  </Row>
                </Col>

                <Col xl={6} lg={12} md={12} className="mt-4">
                  <Row className="form-group mt-3">
                    <label className="col-sm-5 col-form-label">Fecha de Registro</label>
                    <Col sm={7}>
                      <input
                        type="date"
                        disabled={props.disabledFecha != null && props.disabledFecha != undefined ? props.disabledFecha : false}
                        name="fecha"
                        className="form-control"
                        value={datosCargaArchivo.fecha}
                      />
                      {datosCargaArchivo.fecha == null || datosCargaArchivo.fecha == "" ? <SpanError></SpanError> : null}
                    </Col>
                    <label className="col-sm-5 col-form-label">Comentario</label>
                    <Col sm={7}>
                      <textarea
                        name="comentario"
                        className="form-control mb-2"
                        value={datosCargaArchivo.comentario ? datosCargaArchivo.comentario : ""}
                        onChange={(e) => props.onChange(e)}
                      />
                    </Col>

                    {/*props.exportFormato ? (
                      <>
                        <label className="col-sm-5 col-form-label">Formato</label>
                        <Col sm={7}>
                          <input
                            type="button"
                            onClick={() => props.exportFormato()}
                            style={{ background: "#ff9933", color: "white", fontWeight: "bold" }}
                            className="form-control btn btn-primary-purple-umo w-md waves-effect waves-light"
                            value="Descargar Formato"
                          />
                        </Col>
                      </>
                    ) : null*/}

                  </Row>
                </Col>
              </Row>
            </div>
            {props.aviso ? (
              <span className="font-bold">
                {props.aviso.info}
                {""}
                <a href={props.aviso.linkInfo} target="_blank">{props.aviso.linkInfo}</a>
              </span>
            ) : null}
          </CardBody>
        )}
      </SweetAlert>
    </>
  );
};
