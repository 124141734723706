/* eslint-disable import/no-anonymous-default-export */
import {
  FILTRO_CORTESCAJA,
  LISTAR_CORTESCAJA,
  FILTRO_VALESCORTECAJA,
  FILTRO_VENTAMOSTRADOR_CORTESCAJA,
  FILTRO_ASEGURADORA_CORTESCAJA,
  LISTAR_DETALLES_CORTECAJA,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case FILTRO_CORTESCAJA:
      return {
        ...state,
        filtrosCortesCaja: action.payload,
      };
    case LISTAR_CORTESCAJA:
      return {
        ...state,
        cortesCajaFiltrados: action.payload,
      };
    case FILTRO_VALESCORTECAJA:
      return {
        ...state,
        filtrosVales: action.payload,
      };
    case FILTRO_VENTAMOSTRADOR_CORTESCAJA:
      return {
        ...state,
        filtrosVentaMostrador: action.payload,
      };
    case FILTRO_ASEGURADORA_CORTESCAJA:
      return {
        ...state,
        filtrosAseguradora: action.payload,
      };
    case LISTAR_DETALLES_CORTECAJA:
      return {
        ...state,
        detallesCorteCaja: action.payload,
      };

    default:
      return state;
  }
};
