import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../../context/Auth/AuthContext";
import InformacionGeneralSolicitudCompra from "../InformacionGeneralSolicitudCompra/index";
import ListadoProductosSolicitudCompra from "../ListadoProductosSolicitudCompra/index";
import ListadoEstatusSolicitudCompra from "../ListadoEstatusSolicitudCompra/index";
import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";

export default function HistoricoSolicitudCompra(props) {
  const { idSolicitudCompra } = useParams();

  // ACCESO A ESTADO GLOBAL - VALES
  const ctxSolicCompra = useContext(SolicitudesComprasContext);
  const { seleccionarSolicitudCompra, solicitudShowEdit, setSolicitudShowEdit, errorSolicitudCompraSeleccionada } =
    ctxSolicCompra;

    const authContext = useContext(AuthContext);
    
    const {usuario} = authContext;
  
  
      const administrador =
          usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
          usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
          usuario.authorities.includes("ROLE_DIRECCION");

  // ACCESO A ESTADO GLOBAL - Almacenes
  const ctxAlmacenes = useContext(AlmacenesContext);
  const { listarAlmacenes } = ctxAlmacenes;

  useEffect(() => {
    listarAlmacenes(false,administrador);
  }, []);

  useEffect(() => {
    if (!errorSolicitudCompraSeleccionada) {
      seleccionarSolicitudCompra(idSolicitudCompra);
      setSolicitudShowEdit({
        ...solicitudShowEdit,
        hiddenCotizacion: false,
        disabledCotizacion: true,
        hiddenArchivosCotizacion: false,
        guardarSolicitud: false,
        finalizarAutorizacion: false,
        solicitarAutorizacion: false,
        disabledArchivosCotizacion: true,
        hiddenDecision: false,
        disabledDecision: true,
        cancelarSolicitud: false,
        disabledProductoSustituto: true,
      });
    }

    // if (almacenes.length <= 0) {
    // }

    if (errorSolicitudCompraSeleccionada) {
      props.history.push("/solicitudescompra");
    }
  }, [errorSolicitudCompraSeleccionada]);

  return (
    <div className="mt-4">
      <InformacionGeneralSolicitudCompra props={props} />
      <ListadoProductosSolicitudCompra props={props} />
      <ListadoEstatusSolicitudCompra props={props} idSolicitudCompra={idSolicitudCompra} />
    </div>
  );
}
