import {
    LISTAR_NOTIFICACIONES,
    FILTROS_NOTIFICACIONES,
    NUMERO_NOTIFICACIONES,
    WIFI_EN_LINEA
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_NOTIFICACIONES:
            return{
                ...state, 
                notificaciones: action.payload
            }
        case FILTROS_NOTIFICACIONES:
            return{
                ...state, 
                filtrosNotificacion: action.payload
            }
        case NUMERO_NOTIFICACIONES:
            return{
                ...state, 
                numeroDeNotificaciones: action.payload,
            }
            case WIFI_EN_LINEA:
                return{
                    ...state, 
                    WifiActivo: action.payload
                }            
            
        default:
            return state
    }
        
}