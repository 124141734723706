export const opcionesSelectPacientes = {
  placeholderText: "Selecciona un paciente",
  optionLabel: (option) => `${option.nombres} ${option.apellidoPaterno} ${option.apellidoMaterno}`,
  optionValue: (option) => option.idCatPaciente,
  opcionesPaginado: {
    PAGE_SIZE: 500,
  },
};

export const opcionesSelectProductos = {
  placeholderText: "Selecciona un producto",
  optionLabel: (option) => `${option.nombreComercial} - ${option.numIdentificacion}`,
  optionValue: (option) => option.idCatProducto,
  opcionesPaginado: {
    PAGE_SIZE: 150,
  },
};

export const opcionesSelectDiagnosticos = {
  placeholderText: "Catálogo de Diagnósticos",
  optionLabel: (option) => `${option.diagnostico}`,
  optionValue: (option) => option.idCatDiagnostico,
  opcionesPaginado: {
    PAGE_SIZE: 150,
  },
};
