import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import ValesContext from "../../context/Vales/ValesContext";
import CentroMezclasContext from "../../context/CentroMezclas/CentroMezclasContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";

import useWindowDimensions from "../../hooks/dimensionsHook";

const BitacoraMezclas = () => {
  const { width } = useWindowDimensions();

  const { idVale } = useParams();

  const filtrosVacios = {
    fechaInicial: "",
    fechaFinal: "",
    idCatMedico: "",
    idCatProducto: "",
  };

  const centroMezclasContext = useContext(CentroMezclasContext);
  const { filtrosBitacoras, bitacorasPaginadas, setFiltrosBitacoras, setDataBitacoras, eliminarBitacora, uploadNuevaBitacora } =
    centroMezclasContext;

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  useEffect(() => {
    // if (usuarios.length <= 0)
    const fetchtComprasPagadas = async () => {
      const usuariosTemp =     await listarUsuarios();

      const medicamentosCETemp = await MedicamentosCompraExterna(1);
      setMedicamentosCEGlobal(medicamentosCETemp);
      

      const medicamentosVMTemp = await MedicamentosVentaMostradorPaciente(0);    
      setMedicamentosVMGlobal(medicamentosVMTemp);

      let usuariosFiltradosTemp = usuariosTemp.filter((element) => {

        let encontrado = false;
        if(element.catRoles &&  element.catRoles.length > 0){
          element.catRoles.map((subelement) => {
            if(subelement.idCatRol === 8 || subelement.idCatRol === 9){
              encontrado = true;
            }
          })
        }

        if(encontrado ==  true){
          return element;
        }

      });

      let usuariosFiltradosRR = usuariosFiltradosTemp.filter((element) => {
        let encontrado = false;

          element.catRoles.map((subelement) => {
            if(subelement.idCatRol === 20){
              encontrado = true;
            }
          })
        if(encontrado ==  true){
          return element;
        }

      });
      setListaUsuariosResponsablesRevision(usuariosFiltradosRR);

      let usuariosFiltradosRP = usuariosFiltradosTemp.filter((element) => {
        let encontrado = false;

          element.catRoles.map((subelement) => {
            if(subelement.idCatRol === 21){
              encontrado = true;
            }
          })
        if(encontrado ==  true){
          return element;
        }

      });
      setListaUsuariosResponsablesPreparacion(usuariosFiltradosRP);

      listarMedicos();
      listarMedicamentos();
    }
    listarUsuarios();
    fetchtComprasPagadas();
    // fetchVale();
  }, []);

  const ctxProductos = useContext(CatProductosContext);
  const { catProductos, listarMedicamentos , MedicamentosVentaMostradorPaciente , MedicamentosCompraExterna} = ctxProductos;

  const [idMedico, setIdMedico] = useState(null);

  useEffect(() => {
    listarMedicamentos();
    // if (catProductos.length <= 0)
  }, []);

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  today = mm + "/" + dd + "/" + yyyy;

  const ctxVales = useContext(ValesContext);
  const { seleccionarVale, valeSeleccionado } = ctxVales;

  const [datosNuevaBitacora, setDatosNuevaBitacora] = useState({
    fechaRegistro: today,
    responsableRevision: "",
    responsablePreparacion: "",
    idCatProducto: "",      
    idCatProducto: "",
    idCatProductoVentaMostrador: "",
    idProductoCompraExterna: "",    
    ordenPreparacion: "",
    calculos: "",
    horaEntrega: "",
    opcion : 0,
    cheked : true,
  });

  const datosBitacoraVacia = {
    ...datosNuevaBitacora,
    fechaRegistro: today,
    responsableRevision: "",
    responsablePreparacion: "",
    idCatProducto: "",
    idCatProductoVentaMostrador: "",
    idProductoCompraExterna: "",
    ordenPreparacion: "",
    calculos: "",
    horaEntrega: "",
    opcion : 0,
    cheked : true,
  };

  const [bitacoraSeleccionada, setBitacoraSeleccionada] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);
  const [medicamentosCEGlobal, setMedicamentosCEGlobal] = useState([]);
  const [medicamentosVMGlobal, setMedicamentosVMGlobal] = useState([]);
  // const [isFirstSearch, setIsFirstSearch] = useState(true);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_bitacora: false,
    confirm_nueva_bitacora: false,
    confirm_eliminar_bitacora: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [filtrosLocales, setFiltrosLocales] = useState({
    fechaFinal: "",
    fechaInicial: "",
    idCatPaciente: null,
    idCatProducto: null,
    idExpedienteMedico: null,
    idReaccionAdversa: null,    
});

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = useState(0);
  const [medicamentos, setMedicamentos] = useState([]);
  const[folioValeGlobal,setFolioValeGlobal] = useState("")
  const [listaMedicamentosVentaMostrador, setListaMedicamentosVentaMostrador] = useState([]);
  const[existeProductoVentaMostrador,setExisteProductoVentaMostrador] = useState(true)
  const [listaMedicamentosCompraExterna, setListaMedicamentosCompraExterna] = useState([]);
  const[existeProductoCompraExterna,setExisteProductoCompraExterna] = useState(true)
  const [listaMedicamentosSeleccionado, setListaMedicamentosSeleccionado] = useState([]);
  const [listaUsuariosResponsablesRevision, setListaUsuariosResponsablesRevision] = useState([]);
  const [listaUsuariosResponsablesPreparacion, setListaUsuariosResponsablesPreparacion] = useState([]);
  // eslint-disable-next-line
  const [data, setData] = useState([
    {
      idBitacora: "",
      fechaRegistro: "",
      prescripcionMedica: "",
      responsableRevision: "",
      responsablePreparacion: "",
      idCatProducto: "",
      idCatProductoVentaMostrador: "",
      idProductoCompraExterna: "",
      medicamento: "",
      ordenPreparacion: "",
      calculos: "",
      horaEntrega: "",
    },
  ]);

  const listaEstatus = [
    { id: 1, value: 'ENTREGADO', label: 'ENTREGADO' },
    { id: 2, value: 'DEVOLUCION', label: 'DEVOLUCION' }    
  ];

  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      if(filtrosBitacoras.idCatMedico > 0){
        filtrosLocales.idCatMedico = filtrosBitacoras.idCatMedico 
      }
      const response = await setDataBitacoras(page, limit,{filtrosLocales});

      const count = response.count;
      
      setData(response.centromezclasbitacoraVos);
      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);

      if(count == 0){
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Los criterios no mostraron resultados",
        });
      }

    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex, byBusquedaFiltros }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: byBusquedaFiltros ? byBusquedaFiltros : false,
      });
    }
  };

  const CargaMedicamentos = async (vale) => {  
    const medicamentosVMTemp = await MedicamentosVentaMostradorPaciente(vale.catPacientes.idCatPaciente);      
    const medicamentosCETemp = await MedicamentosCompraExterna(vale.catPacientes.idCatPaciente);
    
    if(medicamentosCETemp.length > 0){
        setExisteProductoCompraExterna(true);
    }else{
        setExisteProductoCompraExterna(false);
    }

    setListaMedicamentosCompraExterna(medicamentosCETemp);

    if(vale.catPacientes){
      const tempVales = medicamentosVMTemp.filter((med) =>{ 
          let idTemp = med.id
          let encontrado = true;
          
          vale.valeproductoservicios.map((filtro) =>{
              if(filtro.catProductos){
                  if(filtro.catProductos.idCatProducto == idTemp){
                      encontrado = false;
                  }
              }
          })

          if(encontrado == true ){
              return med;
          }              
      });
    
      if(tempVales.length > 0){
          setExisteProductoVentaMostrador(true);
      }else{
          setExisteProductoVentaMostrador(false);
      }
      setListaMedicamentosVentaMostrador(tempVales);
    }
  
  }

  const handdleCheckedBitacora = useCallback(
    (row) => {
      if (rowSeleccionada === null || rowSeleccionada !== row.id) {
        setRowSeleccionada(row.id);
        
        let opcionTemp = 0;
        if(row.original.catProducto.idCatProducto){
          opcionTemp = 1;
        }

        if(row.original.idCatProductoVentaMostrador){
          opcionTemp = 2;
        }

        if(row.original.idProductoCompraExterna){
          opcionTemp = 3;
        }

        setBitacoraSeleccionada({
          ...row.original,
          idCatMedico: row.original.expedienteMedico.idCatMedico,
          idCatProducto: row.original.catProducto.idCatProducto,
          idCatProductoVentaMostrador: row.original.idCatProductoVentaMostrador,
          idProductoCompraExterna: row.original.idProductoCompraExterna,
          nombreMedicamento: row.original.catProducto.nombreComercial,
          opcion : opcionTemp,
        });
      } else {
        setRowSeleccionada(null);
        setBitacoraSeleccionada(null);
      }
    },
    [rowSeleccionada]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCentroMezclaBitacora",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedBitacora(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Fecha de Registro", accessor: "fechaRegistro" },
      { Header: "Prescripción Médica", accessor: "nombreMedico" },
      { Header: "idCatMedico", accessor: "medico.idCatMedico" },
      { Header: "Orden de Preparación", accessor: "ordenPreparacion" },
      { Header: "P. Responsable de Revisión de Orden", accessor: "nombreResponsableRevision" },
      { Header: "Cálculos", accessor: "calculos" },
      { Header: "Q. Responsable de Preparación", accessor: "nombreResponsablePreparacion" },
      { Header: "Hora de Entrega", accessor: "horaEntrega" },
      { Header: "Medicamento", accessor: "nombreComercialMostrar" },
      { Header: "Medicamento", accessor: "catProducto.idCatProducto" },
    ],
    [rowSeleccionada, handdleCheckedBitacora]
  );

  const medicosChangeId = medicos.map(({ idCatMedico, catPersona, ...medico }) => ({
    ...medico,
    nombre: `${catPersona.nombres} ${catPersona.apellidoPaterno} ${catPersona.apellidoMaterno}`,
    id: idCatMedico,
  }));

  const medicamentosActivos = catProductos.map(({ idCatProducto, nombreComercial, ...medicamento }) => ({
    ...medicamento,
    id: idCatProducto,
    nombre: nombreComercial,
  }));

  const getProductosVale = async () => {
    if (valeSeleccionado !== null) {
      let productosServiciosVale = valeSeleccionado.valeproductoservicios.map((producto) => {
        return producto.catProductos;
      });
      productosServiciosVale = productosServiciosVale.filter((producto) => {
        return producto !== null;
      });

      // Productos tipo medicamento y que pertenezca a centro de mezclas
      let productosFiltradosMed = productosServiciosVale.filter(
        (producto) => producto.catTipoproducto.idCatTipoProducto == 2 && producto.centroMezclas
      );
      // let productosFiltradosMed = productosServiciosVale.filter((producto) => producto.catTipoproducto.idCatTipoProducto == 2 && !productosUsados.includes(producto.idCatProducto));

      productosFiltradosMed = productosFiltradosMed.map(({ idCatProducto, nombreComercial, ...medicamento }) => ({
        ...medicamento,
        id: idCatProducto,
        nombre: nombreComercial,
      }));

      return [productosFiltradosMed];
    }
  };

  const onChange = (e) => {
    if (e.target.name === "fechaInicial") {
      setFiltrosLocales({
        ...filtrosLocales,
        [e.target.name]: e.target.value,
        fechaFinal: e.target.value,
      });
    } else {
      setFiltrosLocales({
        ...filtrosLocales,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "opcion") {

      if(e.target.value == 1){
        setListaMedicamentosSeleccionado(medicamentosActivos);
      }

      if(e.target.value == 2){
        setListaMedicamentosSeleccionado(medicamentosVMGlobal);
      }

      if(e.target.value == 3){
        setListaMedicamentosSeleccionado(medicamentosCEGlobal);
      }

      setFiltrosLocales({
        ...filtrosLocales,
        [e.target.name]: e.target.value,
        tipoListaMedicamento : e.target.value,
      });
    } 
  };

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Fecha Inicial",
          type: "date",
          name: "fechaInicial",
          disabled: false,
          value: filtrosLocales.fechaInicial,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Médico",
          name: "idCatMedico",
          disabled: true,
          value: filtrosBitacoras.idCatMedico,
          opciones: medicosChangeId,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Fecha Final",
          type: "date",
          name: "fechaFinal",
          disabled: !filtrosLocales.fechaInicial ? true : false,
          min: filtrosLocales.fechaInicial,
          value: filtrosLocales.fechaFinal,
          onChange: onChange,
        },
        {
          colType: "radio",
          label: "",
          name: "listaSeleccionada",
          value: filtrosLocales.listaSeleccionada,
          onChange: onChange,
        },  
        filtrosLocales.opcion == 3?
        {
          colType: "selectExterno",
          label: "Medicamento",
          name: "idCatProducto",
          value: filtrosLocales.idCatProducto,
          opciones: listaMedicamentosSeleccionado,
          onChange: onChange,
        }:
        {
          colType: "select",
          label: "Medicamento",
          name: "idCatProducto",
          value: filtrosLocales.idCatProducto,
          opciones: listaMedicamentosSeleccionado,
          onChange: onChange,
        }
        ,
      ],
    },
  ];
  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const ctxUsuarios = useContext(UsuariosContext);
  const { listarUsuarios, usuarios } = ctxUsuarios;

  useEffect(() => {
    // if (usuarios.length <= 0)
    fetchVale();
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      setLoading(false);
      fetchData({ pageSize: 10, pageIndex: 0 });
    } catch (error) {
      setLoading(false);
    }
  }, [idMedico]);

  const fetchVale = async () => {
    const vale = await seleccionarVale(idVale);
    setFolioValeGlobal(vale.folioVale);
    setFiltrosBitacoras({ ...filtrosBitacoras, idCatMedico: vale.catMedicos.idCatMedico });
    setIdMedico(vale.catMedicos.idCatMedico);
    return vale.catMedicos.idCatMedico;
  };

  // Filtrar usuarios que tengan ROLE_CENTRO_MEZCLAS y ROLE_QUIMIO id_Cat_Rol 8 y 9

  let usuariosFiltrados = usuarios.map((element) => {
    return {
      ...element,
      catRoles: element.catRoles.filter((subElement) => subElement.idCatRol === 8 || subElement.idCatRol === 9),
    };
  });

  usuariosFiltrados = usuariosFiltrados.filter((el) => el.catRoles.length > 0);

  const usuariosResponsables = usuariosFiltrados.map(({ idCatUsuario, ...usuario }) => ({
    ...usuario,
    id: idCatUsuario,
  }));

  // const autorizado = usuario.authorities.includes("ROLE_CENTRO_MEZCLAS");

  const autorizado =
    usuario.authorities.includes("OP_BITACORA_MEZCLA_CREAR") ||
    usuario.authorities.includes("OP_BITACORA_MEZCLA_MODIFICAR") ||
    usuario.authorities.includes("OP_BITACORA_MEZCLA_ELIMINAR") ||
    usuario.authorities.includes("ROLE_CENTRO_MEZCLAS");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function fetchData
     * @param {object} filtrosBitacoras Filtros enviados al back para filtrar las bitácoras
     * @param {int} page Página a regresar de bitácoras
     * @param {int} limit Número de bitácoras que se van a regresar por llamada al back
     */

    setFiltrosBitacoras(filtrosBitacoras);
    fetchData({ pageSize: 10, pageIndex: 0 });
    // setIsFirstSearch(false);
  };

  const limpiarFiltros = () => {
    
    setFiltrosLocales({
      ...filtrosLocales,
      fechaInicial:  "",
      fechaFinal : "",
      idCatProducto : null,
      idCatMedico : null,
    });

    if(filtrosLocales.tipoListaMedicamento == 1){
      setListaMedicamentosSeleccionado(medicamentosActivos);
    }

    if(filtrosLocales.tipoListaMedicamento == 2){
      setListaMedicamentosSeleccionado(medicamentosVMGlobal);
    }

    if(filtrosLocales.tipoListaMedicamento  == 3){
      setListaMedicamentosSeleccionado(medicamentosCEGlobal);
    }

  };

  const confirmNuevaBitacora = async () => {
    if (autorizado) {
      const productos = await getProductosVale();
      CargaMedicamentos(valeSeleccionado);
      if (productos) {
        setMedicamentos(productos[0]);
      }

      const nombreMedico = valeSeleccionado.catMedicos.catPersona
        ? (valeSeleccionado.catMedicos.prefijo ? valeSeleccionado.catMedicos.prefijo : "") +
          " " +
          (valeSeleccionado.catMedicos.catPersona.nombres ? valeSeleccionado.catMedicos.catPersona.nombres : "") +
          " " +
          (valeSeleccionado.catMedicos.catPersona.apellidoPaterno ? valeSeleccionado.catMedicos.catPersona.apellidoPaterno : "") +
          " " +
          (valeSeleccionado.catMedicos.catPersona.apellidoMaterno ? valeSeleccionado.catMedicos.catPersona.apellidoMaterno : "")
        : "";
      setDatosNuevaBitacora({
        ...datosNuevaBitacora,
        idCatMedico: valeSeleccionado.catMedicos.idCatMedico,
        medico: nombreMedico,
      });
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nueva_bitacora: true,
      });
    }
  };

  const checkModificarBitacora = () => {
    if (autorizado && bitacoraSeleccionada) {
      CargaMedicamentos(valeSeleccionado);

      let productosFiltradosMed = catProductos.filter((filtro) =>{
        if(filtro.catTipoproducto){
          if(filtro.catTipoproducto.idCatTipoProducto == 2){
            return filtro;
          }
        }
      });

      setMedicamentos(productosFiltradosMed);

      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_bitacora: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione una mezcla",
      });
    }
  };

  const checkEliminarBitacora = () => {
    if (autorizado && bitacoraSeleccionada) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_bitacora: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione una mezcla",
      });
    }
  };

  const confirmEliminarBitacora = async () => {
    setLoading(true);
    const res = await eliminarBitacora(bitacoraSeleccionada.idCentroMezclaBitacora);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_bitacora: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_bitacora: false,
        dynamic_description: "Se eliminó la bitácora correctamente",
      });

      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setBitacoraSeleccionada(null);
      setRowSeleccionada(null);
      fetchVale();
    }
  };

  const changeNuevaBitacora = (e) => {
    const { name, value } = e.target;

    if(e.target.name=="opcion"){
      setDatosNuevaBitacora({
        ...datosNuevaBitacora,
        [name]: value,
        idCatProducto : null,
        idCatProductoVentaMostrador : null,
        idProductoCompraExterna : null,
      });

    }else{
      setDatosNuevaBitacora({
        ...datosNuevaBitacora,
        [name]: value,
      });
    }

  };

  const changeModificarBitacora = (e) => {
    const { name, value } = e.target;
    setBitacoraSeleccionada({
      ...bitacoraSeleccionada,
      [name]: value,
    });
  };

  const validarDatosBitacora = (bitacora) => {
    let errorNuevaBitacora = false;
    if (!errorNuevaBitacora && (bitacora.idCatMedico === "" || bitacora.idCatMedico === null)) {
      errorNuevaBitacora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la prescripción médica",
      });
      return errorNuevaBitacora;
    }
    if (!errorNuevaBitacora && (bitacora.responsablePreparacion === "" || bitacora.responsablePreparacion === null)) {
      errorNuevaBitacora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el responsable de preparación",
      });
      return errorNuevaBitacora;
    }
    if (!errorNuevaBitacora && (bitacora.responsableRevision === "" || bitacora.responsableRevision === null)) {
      errorNuevaBitacora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el responsable de revisión",
      });
      return errorNuevaBitacora;
    }

    if (!errorNuevaBitacora && ((bitacora.idCatProducto === "" || bitacora.idCatProducto === null) 
                               && (bitacora.idCatProductoVentaMostrador === "" || bitacora.idCatProductoVentaMostrador === null)
                               && (bitacora.idProductoCompraExterna === "" || bitacora.idProductoCompraExterna === null))) {
      errorNuevaBitacora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el medicamento",
      });
      return errorNuevaBitacora;
    }
    if (!errorNuevaBitacora && (bitacora.ordenPreparacion === "" || bitacora.ordenPreparacion === null)) {
      errorNuevaBitacora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la orden de preparación",
      });
      return errorNuevaBitacora;
    }
    if (!errorNuevaBitacora && (bitacora.calculos === "" || bitacora.calculos === null)) {
      errorNuevaBitacora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese los cálculos",
      });
      return errorNuevaBitacora;
    }
    if (!errorNuevaBitacora && (bitacora.horaEntrega === "" || bitacora.horaEntrega === null)) {
      errorNuevaBitacora = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la hora de entrega",
      });
      return errorNuevaBitacora;
    }

    return errorNuevaBitacora;
  };

  const agregarNuevaBitacora = async () => {
    const bitacoraValida = validarDatosBitacora(datosNuevaBitacora);

    if (!bitacoraValida) {
      setLoading(true);
      delete datosNuevaBitacora.fechaRegistro;

      const res = await uploadNuevaBitacora({ ...datosNuevaBitacora, idExpedienteMedico: 1, idVale: idVale });
      
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nueva_bitacora: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nueva_bitacora: false,
          dynamic_description: "Se guardó la nueva mezcla correctamente",
        });
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setDatosNuevaBitacora(datosBitacoraVacia);
        fetchVale();
      }
    }
  };

  const confirmModificarBitacora = async () => {
    const bitacoraValida = validarDatosBitacora(bitacoraSeleccionada);
    if (!bitacoraValida) {
      setLoading(true);

      const res = await uploadNuevaBitacora(bitacoraSeleccionada);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_bitacora: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_bitacora: false,
          dynamic_description: "Se guardó la mezcla correctamente",
        });
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setDatosNuevaBitacora(datosBitacoraVacia);
        fetchVale();
      }
    }
  };
{/* ( {folioValeGlobal} ) */ }
  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Bitácora de Mezclas</h3>
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Bitácoras de Mezclas</h4>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevaBitacora()}>
                      Nueva Mezcla
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarBitacora()}>
                      Modificar Mezcla
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarBitacora()}>
                      Eliminar Mezcla
                    </button>
                  ) : null}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={bitacorasPaginadas}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nueva_bitacora ? (
        <SweetAlert
          title="Nueva Mezcla"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            agregarNuevaBitacora();
          }}
          onCancel={() => {
            setDatosNuevaBitacora(datosBitacoraVacia);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nueva_bitacora: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">Fecha Registro</label>
                  <Col sm={8} className="pb-3">
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="fechaRegistro"
                      autoComplete="off"
                      onChange={changeNuevaBitacora}
                      value={datosNuevaBitacora.fechaRegistro ? datosNuevaBitacora.fechaRegistro : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Prescripción Médica</label>
                  <Col sm={8} className="pb-3">
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="medico"
                      autoComplete="off"
                      value={datosNuevaBitacora.medico ? datosNuevaBitacora.medico : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label mb-2">
                    P. Responsable de Revisión de Orden<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={8} className="pb-3">
                    <select
                      className="form-control"
                      name="responsableRevision"
                      onChange={changeNuevaBitacora}
                      value={datosNuevaBitacora.responsableRevision}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {listaUsuariosResponsablesRevision.map((usuario) => {
                        return <option value={usuario.idCatUsuario}>{usuario.nombre}</option>;
                      })}
                    </select>
                    {(datosNuevaBitacora.responsableRevision === "" || !datosNuevaBitacora.responsableRevision) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-4 col-form-label">
                    Q. Responsable de Preparación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={8} className="pb-3">
                    <select
                      className="form-control"
                      name="responsablePreparacion"
                      onChange={changeNuevaBitacora}
                      value={datosNuevaBitacora.responsablePreparacion}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {listaUsuariosResponsablesPreparacion.map((usuario) => {
                        return <option value={usuario.idCatUsuario}>{usuario.nombre}</option>;
                      })}
                    </select>
                    {(datosNuevaBitacora.responsablePreparacion === "" || !datosNuevaBitacora.responsablePreparacion) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  {/* TODO */}
                  <label className="col-sm-4 col-form-label">
                    
                  </label>                  
                  <Col sm={8} className="pb8-3">
                  <input
                      type="radio"
                      name="opcion"
                      width={30}
                      //disabled = {!existeProductoVentaMostrador}
                      onChange={changeNuevaBitacora}
                      checked={changeNuevaBitacora.cheked}
                      value = {1}
                  />    
                  <label className="col-sm-3 col-form-label" >Vale</label>
                  
                  <input
                      type="radio"
                      name="opcion"
                      width={30}
                      //disabled = {!existeProductoVentaMostrador}
                      onChange={changeNuevaBitacora}
                      checked={changeNuevaBitacora.cheked}
                      value = {2}
                  />    
                  <label className="col-sm-3 col-form-label">Venta Mostrador   </label>                 

                  <input
                      type="radio"
                      name="opcion"
                      width={30}
                      //disabled = {!existeProductoVentaMostrador}
                      onChange={changeNuevaBitacora}
                      checked={changeNuevaBitacora.cheked}
                      value = {3}
                  />    
                  <label className="col-sm-3 col-form-label">Compra Externa   </label>                  
                  </Col>                  

                  {/* TODO */}
                  <label className="col-sm-4 col-form-label">
                    Medicamento<span className="text-xs text-red-700">*</span>
                  </label>

                  {datosNuevaBitacora.opcion==1 || datosNuevaBitacora.opcion==0?(
                    <Col sm={8} className="pb-3">                    
                        <select
                          className="form-control"
                          name="idCatProducto"
                          onChange={changeNuevaBitacora}
                          value={datosNuevaBitacora.idCatProducto}>
                          <option selected disabled value="">
                            Seleccione una opción
                            </option>
                            {datosNuevaBitacora.opcion==1? medicamentos.map((medicamento) => {
                              return <option value={medicamento.id}>{medicamento.nombre}</option>;
                            }):null}
                        </select>
                        {(datosNuevaBitacora.idCatProducto === "" || !datosNuevaBitacora.idCatProducto) && (
                          <span className="text-sm text-red-700">Este campo es obligatorio</span>
                        )}
                    </Col>
                  ):null}

                  {datosNuevaBitacora.opcion==2?(
                    <Col sm={8} className="pb-3">                    
                        <select
                          className="form-control"
                          name="idCatProductoVentaMostrador"
                          onChange={changeNuevaBitacora}
                          value={datosNuevaBitacora.idCatProductoVentaMostrador}>
                          <option selected disabled value="">
                            Seleccione una opción
                            </option>
                            {listaMedicamentosVentaMostrador.map((medicamento) => {
                              return <option value={medicamento.id}>{medicamento.nombre}</option>;
                            })}
                        </select>
                        {(datosNuevaBitacora.idCatProductoVentaMostrador === "" || !datosNuevaBitacora.idCatProductoVentaMostrador) && (
                          <span className="text-sm text-red-700">Este campo es obligatorio</span>
                        )}
                    </Col>
                  ):null}

                  {datosNuevaBitacora.opcion==3?(
                    <Col sm={8} className="pb-3">                    
                        <select
                          className="form-control"
                          name="idProductoCompraExterna"
                          onChange={changeNuevaBitacora}
                          value={datosNuevaBitacora.idProductoCompraExterna}>
                          <option selected disabled value="">
                            Seleccione una opción
                            </option>
                            {listaMedicamentosCompraExterna.map((medicamento) => {
                              return <option value={medicamento.idProductoCompraExterna}>{`${medicamento.nombreComercial}  ${medicamento.concentracion}  ${medicamento.presentacion} - L[${medicamento.lote?medicamento.lote:""}]  - p[${medicamento.piezas?medicamento.piezas:0}]`}</option>;
                            })}
                        </select>
                        {(datosNuevaBitacora.idProductoCompraExterna === "" || !datosNuevaBitacora.idProductoCompraExterna) && (
                          <span className="text-sm text-red-700">Este campo es obligatorio</span>
                        )}
                    </Col> 
                  ):null}  

                  <label className="col-sm-4 col-form-label">Num. Id</label>
                  <Col sm={8}>
                    <input
                      disabled={false}
                      type="text"
                      className="form-control"
                      onChange={changeNuevaBitacora}
                      name="numId"
                      autoComplete="off"
                      value={datosNuevaBitacora.numId ? datosNuevaBitacora.numId : ""}
                    />
                  </Col>

                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">
                    Orden de Preparación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <textarea
                      className="form-control mb-2"
                      name="ordenPreparacion"
                      autoComplete="off"
                      onChange={changeNuevaBitacora}
                      value={datosNuevaBitacora.ordenPreparacion ? datosNuevaBitacora.ordenPreparacion : ""}
                    />
                    {(datosNuevaBitacora.ordenPreparacion === "" || !datosNuevaBitacora.ordenPreparacion) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Cálculos<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <textarea
                      className="form-control mb-2"
                      name="calculos"
                      autoComplete="off"
                      onChange={changeNuevaBitacora}
                      value={datosNuevaBitacora.calculos ? datosNuevaBitacora.calculos : ""}
                    />
                    {(datosNuevaBitacora.calculos === "" || !datosNuevaBitacora.calculos) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Hora de Entrega<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="time"
                      className="form-control"
                      name="horaEntrega"
                      autoComplete="off"
                      onChange={changeNuevaBitacora}
                      value={datosNuevaBitacora.horaEntrega ? datosNuevaBitacora.horaEntrega : ""}
                    />
                    {(datosNuevaBitacora.horaEntrega === "" || !datosNuevaBitacora.horaEntrega) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-5 col-form-label">Fecha Preparacion</label>
                  <Col sm={7}>
                    <input
                      disabled={false}
                      type="date"
                      onChange={changeNuevaBitacora}
                      className="form-control"
                      name="fechaPreparacion"
                      autoComplete="off"
                      value={datosNuevaBitacora.fechaPreparacion ? datosNuevaBitacora.fechaPreparacion : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Fecha Utilización</label>
                  <Col sm={7}>
                    <input
                      disabled={false}
                      type="date"
                      onChange={changeNuevaBitacora}
                      className="form-control"
                      name="fechaUtilizacion"
                      autoComplete="off"
                      value={datosNuevaBitacora.fechaUtilizacion ? datosNuevaBitacora.fechaUtilizacion : ""}
                    />
                  </Col>        

                  <label className="col-sm-5 col-form-label mb-2">Estatus</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="estatus"
                      onChange={changeNuevaBitacora}
                      value={datosNuevaBitacora.estatus}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {listaEstatus.map((estatus) => {
                        return <option key={estatus.id} value={estatus.id}>
                          {estatus.label}
                        </option>;
                      })}
                    </select>                  
                   </Col>

                  <label className="col-sm-5 col-form-label"></label>
                  <Col sm={7}>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDatosNuevaBitacora(datosBitacoraVacia)}>
                      Limpiar Datos
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_bitacora ? (
        <SweetAlert
          title="Modificar Datos de la Mezcla"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            confirmModificarBitacora();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_bitacora: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">Fecha Registro</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="fechaRegistro"
                      autoComplete="off"
                      value={bitacoraSeleccionada.fechaRegistro ? bitacoraSeleccionada.fechaRegistro : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Prescripción Médica</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="nombreMedico"
                      autoComplete="off"
                      value={bitacoraSeleccionada.nombreMedico ? bitacoraSeleccionada.nombreMedico : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label mb-2">P. Responsable de Revisión de Orden</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="responsableRevision"
                      onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.responsableRevision}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {listaUsuariosResponsablesRevision.map((usuario) => {
                        return <option value={usuario.idCatUsuario}>{usuario.nombre}</option>;
                      })}
                    </select>
                  </Col>

                  <label className="col-sm-5 col-form-label">Q. Responsable de Preparación</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="responsablePreparacion"
                      onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.responsablePreparacion}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {listaUsuariosResponsablesPreparacion.map((usuario) => {
                        return <option value={usuario.idCatUsuario}>{usuario.nombre}</option>;
                      })}
                    </select>
                  </Col>

                  {/* TODO */}
                  <label className="col-sm-5 col-form-label">
                    Medicamento{bitacoraSeleccionada.opcion==1? " (Vale)" : bitacoraSeleccionada.opcion==2? " (Venta Mostrador)" : " (Compra Externa)"}<span className="text-xs text-red-700"></span>
                  </label>

                  {bitacoraSeleccionada.opcion==1?(
                    <Col sm={7} className="pb-3">                    
                        <select
                          className="form-control"
                          name="idCatProducto"
                          disabled = {true}
                          value={bitacoraSeleccionada.idCatProducto}>
                          <option selected disabled value="">
                            Seleccione una opción
                            </option>
                            {medicamentos.map((medicamento) => {
                              return <option value={medicamento.idCatProducto}>{medicamento.nombreComercial}</option>;
                            })}
                        </select>
                    </Col>
                  ):null}

                  {bitacoraSeleccionada.opcion==2?(
                    <Col sm={7} className="pb-3">                    
                        <select
                          className="form-control"
                          name="idCatProductoVentaMostrador"
                          disabled = {true}
                          value={bitacoraSeleccionada.idCatProductoVentaMostrador}>
                          <option selected disabled value="">
                            Seleccione una opción
                            </option>
                            {listaMedicamentosVentaMostrador.map((medicamento) => {
                              return <option value={medicamento.id}>{medicamento.nombre}</option>;
                            })}
                        </select>
                    </Col>
                  ):null}

                  {bitacoraSeleccionada.opcion==3?(
                    <Col sm={7} className="pb-3">                    
                        <select
                          className="form-control"
                          name="idProductoCompraExterna"
                          disabled = {true}
                          value={bitacoraSeleccionada.idProductoCompraExterna}>
                          <option selected disabled value="">
                            Seleccione una opción
                            </option>
                            {listaMedicamentosCompraExterna.map((medicamento) => {
                              return <option value={medicamento.idProductoCompraExterna}>{`${medicamento.nombreComercial}  ${medicamento.concentracion}  ${medicamento.presentacion}`}</option>;
                            })}
                        </select>
                    </Col>
                  ):null}  

                  <label className="col-sm-5 col-form-label">Num. Id</label>
                  <Col sm={7}>
                    <input
                      disabled={false}
                      type="text"
                      className="form-control"
                      onChange={changeModificarBitacora}
                      name="numId"
                      autoComplete="off"
                      value={bitacoraSeleccionada.numId ? bitacoraSeleccionada.numId : ""}
                    />
                  </Col>

                </Row>
              </Col>
                     
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">Orden de Preparación</label>
                  <Col sm={7}>
                    <textarea
                      className="form-control mb-2"
                      name="ordenPreparacion"
                      autoComplete="off"
                      onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.ordenPreparacion ? bitacoraSeleccionada.ordenPreparacion : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Cálculos</label>
                  <Col sm={7}>
                    <textarea
                      className="form-control mb-2"
                      name="calculos"
                      autoComplete="off"
                      onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.calculos ? bitacoraSeleccionada.calculos : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Hora de Entrega</label>
                  <Col sm={7}>
                    <input
                      type="time"
                      className="form-control"
                      name="horaEntrega"
                      autoComplete="off"
                      onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.horaEntrega ? bitacoraSeleccionada.horaEntrega : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Fecha Preparacion</label>
                  <Col sm={7}>
                    <input
                      disabled={false}
                      type="date"
                      onChange={changeModificarBitacora}
                      className="form-control"
                      name="fechaPreparacion"
                      autoComplete="off"
                      value={bitacoraSeleccionada.fechaPreparacion ? bitacoraSeleccionada.fechaPreparacion : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Fecha Utilización</label>
                  <Col sm={7}>
                    <input
                      disabled={false}
                      type="date"
                      onChange={changeModificarBitacora}
                      className="form-control"
                      name="fechaUtilizacion"
                      autoComplete="off"
                      value={bitacoraSeleccionada.fechaUtilizacion ? bitacoraSeleccionada.fechaUtilizacion : ""}
                    />
                  </Col>        

                  <label className="col-sm-5 col-form-label mb-2">Estatus</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="estatus"
                      onChange={changeModificarBitacora}
                      value={bitacoraSeleccionada.estatus}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {listaEstatus.map((estatus) => {
                        return <option key={estatus.id} value={estatus.id}>
                          {estatus.label}
                        </option>;
                      })}
                    </select>
                  </Col>                            

                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}
      {UISweetAlert.confirm_eliminar_bitacora ? (
        <SweetAlert
          title="¿Desea eliminar la mezcla seleccionada?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => confirmEliminarBitacora()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_bitacora: false,
            });
          }}></SweetAlert>
      ) : null}
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default BitacoraMezclas;
