import React, {useReducer} from 'react'

import ReporteContabilidadContext from './ReporteContabilidadContext'
import ReporteContabilidadReducer from './ReporteContabilidadReducer'

import {
    FILTRO_SALDOS_COBRAR_CONTABILIDAD,
} from '../../types'

import clienteAxios from '../../config/axios'

 
export default function ReporteContabilidadState (props) {

    const initialState = { 
        filtroSaldosPorCobrarContabilidad:[],
    }

    const [state, dispatch] = useReducer(ReporteContabilidadReducer,initialState)

    const setFiltroSaldosPorCobrarContabilidad = async (filtroSaldosPorCobrarContabilidad) => {
  
        dispatch({
            type: FILTRO_SALDOS_COBRAR_CONTABILIDAD,
            payload: filtroSaldosPorCobrarContabilidad
        })
    }

    const exportReporteSaldosPorCobrarAseguradora = async () => {
  
          try{
            const res = await clienteAxios.post(`/reporte/saldo/aseguradora`,
            state.filtroSaldosPorCobrarContabilidad,
            {
                responseType: 'arraybuffer',
                headers:{
                    'Content-Disposition': "attachment; filename=saldoaseguradora.xlsx",
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                },
            })
  
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'saldoaseguradora.xlsx');
            document.body.appendChild(link);
            link.click();
            
            return res;
  
        }catch (error){

            if(!error.response){
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error:"hubo error"
                    }
                }
            }else{
                error.response = {
                    data: {
                        message: "No hay vales, con saldos por cobrar",
                        error:"Error"
                    }
                }
            }
  
            return error.response;
        }
  
    }

    return (

        <ReporteContabilidadContext.Provider value={{
            filtroSaldosPorCobrarContabilidad: state.filtroSaldosPorCobrarContabilidad,
            setFiltroSaldosPorCobrarContabilidad,
            exportReporteSaldosPorCobrarAseguradora,
        }}>
            {props.children}
        </ReporteContabilidadContext.Provider>

    )


}