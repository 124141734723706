import React, { useReducer } from "react";
import { Buffer } from "buffer";

import EstudiosLaboratorioContext from "./EstudiosLaboratorioContext";
import EstudiosLaboratorioReducer from "./EstudiosLaboratorioReducer";

import { LISTAR_ESTUDIOS_LABORATORIO, LISTAR_MARCADORES, LISTAR_ARCHIVOS_IMAGEN } from "../../types";

import { getImageDimensionsFromStream } from "../../hooks/getImageDimensionsFromStream";

import clienteAxios from "../../config/axios";

export default function EstudiosLaboratorioState(props) {
  const initialState = {
    estudiosLaboratorio: [],
    marcadores: [],
    archivosImagen: [],
  };

  const [state, dispatch] = useReducer(EstudiosLaboratorioReducer, initialState);

  const listarEstudiosLaboratorio = async (idCatPaciente, idExpedienteMedico) => {
    const res = await clienteAxios.post(
      "/laboratorio/estudios/detalles",
      { idCatPaciente: idCatPaciente },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch({
      type: LISTAR_ESTUDIOS_LABORATORIO,
      payload: res.data,
    });
    // const idExpedienteMedico = historiaClinicaInicial.expedienteMedico.idExpedienteMedico;
    if (idExpedienteMedico) {
      filterEstudiosImagen(res.data, idExpedienteMedico);
    }

    return res.data;
  };

  const filterEstudiosImagen = async (estudios, idExpedienteMedico) => {
    const estudiosImagen = await estudios.filter((estudio) => {
      return estudio.idCatTipoEstudioLaboratorio == 2;
    });

    const archivosImagen = [];
    estudiosImagen.forEach((estudio) => {
      estudio.archivos.forEach((archivo) => {
        // eslint-disable-next-line no-useless-concat
        const re = new RegExp("\\b" + "image" + "\\b");
        if (re.test(archivo.mimeType)) archivosImagen.push({ ...archivo });
      });
    });

    archivosImagen.map(async (archivo) => {
      const res = await clienteAxios.get(`/expedientemedico/view/archivo/estudio/${idExpedienteMedico}/${archivo.idArchivo}`, {
        responseType: "arraybuffer",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      const dataToBase64 = Buffer.from(await res.data).toString("base64");

      archivo.base64S = dataToBase64;

      if (!archivo.width || !archivo.height) {
        await getImageDimensionsFromStream(res.data)
          .then((dimensions) => {
            archivo.width = dimensions.width;
            archivo.height = dimensions.height;
          })
          .catch((error) => {
            console.error(error);
          });
      }

      archivo.inputStream = await res.data;
    });

    dispatch({
      type: LISTAR_ARCHIVOS_IMAGEN,
      payload: archivosImagen,
    });

    return archivosImagen;
  };

  const listarMarcadores = async (idCatPaciente) => {
    const res = await clienteAxios.post(
      "/laboratorio/marcadores/detalles",
      { idCatPaciente: idCatPaciente },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    const arr = res.data.map((marc) => {
      marc.color = `rgba(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(
        Math.random() * 256
      )},1)`;
      marc.fechasRegistro = marc.fechasRegistro.map((fecha) => {
        let fecSplit = fecha.split("T")[0];
        return `${fecSplit.split("-")[2]}/${fecSplit.split("-")[1]}/${fecSplit.split("-")[0]}`;
      });
      return marc;
    });

    dispatch({
      type: LISTAR_MARCADORES,
      payload: arr,
    });
  };

  const uploadNuevoEstudioLaboratorio = async (estudioLaboratorio) => {
    try {
      const res = await clienteAxios.post(`/laboratorio/agregar/estudio`, estudioLaboratorio, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <EstudiosLaboratorioContext.Provider
      value={{
        estudiosLaboratorio: state.estudiosLaboratorio,
        marcadores: state.marcadores,
        archivosImagen: state.archivosImagen,
        listarEstudiosLaboratorio,
        uploadNuevoEstudioLaboratorio,
        listarMarcadores,
      }}>
      {props.children}
    </EstudiosLaboratorioContext.Provider>
  );
}
