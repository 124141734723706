import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";

export default function useCloseWindowWarning(
  message = "¿Esta seguro que deseas salir de la ventana? Las modificaciones no se guardarán."
) {
  // const windowWarningContext = useContext(WindowWarningContext);
  // const { changeGuardado, seEdito } = windowWarningContext;

  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    //Detecta si la ventana se va a cerrar
    window.onbeforeunload = isEdit && (() => message);

    return () => {
      window.onbeforeunload = null;
    };
  }, [isEdit]);

  const routerPrompt = <Prompt when={isEdit} message={message} />;

  return [routerPrompt, () => setEdit(true), () => setEdit(false), isEdit];
}
