import React, { useState, useEffect } from "react";
import { Row, Col, CardBody } from "reactstrap";
// import { AutoComplete } from "antd";

function PresentaDAtos(dato1,dato2){

  alert ("Principio" + dato1 + "   producto:" + dato2);
  return 0;
}



const ProductosVale = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [productos, setProductos] = useState(props);

  // const options =
  //   props.productosServicios &&
  //   props.productosServicios.map((producto) => {
  //     const label = producto.nombreComercial + " - " + producto.principioActivo;
  //     const value = producto.nombreComercial + " - " + producto.numIdentificacion;

  //     return {
  //       label: label,
  //       value: value,
  //     };
  //   });

  useEffect(() => {
    setProductos(props);
  }, [props]);

  return (
    <CardBody autoComplete="off"  key={props.tipoProducto} className={props.tipoProducto !== "Servicio" ? "mt-4" : ""}>
      <Row className="mb-3">
        <Col>
          <button
            name={props.tipoProducto}
            id={props.tipoProducto}
            className="btn btn-primary-orange-umo w-md waves-effect waves-light"
            type="button"
            disabled={props.disabledInsumo}
            onClick={props.nuevoRenglon}
            style={{
              marginRight: "2.5rem",
              background: "#ff9933",
              color: "white",
              fontWeight: "bold",
            }}>
            Agregar {props.tipoProducto}
          </button>
        </Col>
      </Row>
      <div className="table-responsive">
        <table id={`tabla-${props.tipoProducto}`} className="table table-hover table-centered table-nowrap mb-0">
          <thead>
            <tr>
              <th
                style={{ width: "25%" }}
                hidden={props.tipoProducto == "Servicio" || props.tipoProducto == "Insumo" || props.tipoProducto == "Solución"}
                scope="col">
                PRINCIPIO<br></br>ACTIVO
              </th>
              <th
                style={{ width: "6%" }}
                hidden={props.tipoProducto == "Servicio" || props.tipoProducto == "Insumo" || props.tipoProducto == "Solución"}
                scope="col">
                CONCENTRACION
              </th>
              {props.tipoProducto == "Servicio" || props.tipoProducto == "Insumo" || props.tipoProducto == "Solución" ? (
                <th style={{ width: "80%" }} scope="col">
                  {props.tipoProducto.toUpperCase()}
                </th>
              ) : (
                <th style={{ width: "47%" }} scope="col">
                  {props.tipoProducto.toUpperCase()}
                </th>
              )}
              <th style={{ width: "15%" }} scope="col">
                {props.tipoProducto != "Servicio" ? "PIEZAS" : "CICLO"}
              </th>
              {/* <th scope="col">
                Tipo de<br></br>
                {props.tipoProducto == "Servicio" ? "Servicio" : "Producto"}
              </th> */}
              <th scope="col">ELIMINAR</th>
            </tr>
          </thead>
          <tbody>
            {props.productos &&
              props.productos.map((producto, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td
                        hidden={
                          props.tipoProducto == "Servicio" || props.tipoProducto == "Insumo" || props.tipoProducto == "Solución"
                        }>
                        <input
                          autoComplete="off"
                          // disabled={!props.disabledInsumo && !producto.disabledPrincipioActivo ? false : true}
                          type="text"
                          //onClick={eliminarPrincipioActivosRepetidos}
                          onChange={(e) => props.changePrincipioActivo(e, props.tipoProducto, index)}
                          list={`listaPrincipiosActivos${index + props.tipoProducto}`}
                          value={producto.principioActivo}
                          className="form-control"
                        />

                        <datalist autoComplete="off" id={`listaPrincipiosActivos${index + props.tipoProducto}`}>
                          {props.principioActivoFilter.map((principioActivo) => {
                            return <>{<option value={principioActivo}>{principioActivo}</option>}</>;
                          })}
                        </datalist>
                      </td>
                      <td
                        hidden={
                          props.tipoProducto == "Servicio" || props.tipoProducto == "Insumo" || props.tipoProducto == "Solución"
                        }>
                        <input
                          // disabled={!props.disabledInsumo && !producto.disabledPrincipioActivo && producto.principioActivo ? false : true}
                          type="text"
                          autoComplete="off"
                          onChange={(e) => props.changeConcentracion(e, index)}
                          list={`listaConcentraciones${index + props.tipoProducto}`}
                          value={producto.concentracion}
                          className="form-control"
                        />
                        <datalist autoComplete="off" id={`listaConcentraciones${index + props.tipoProducto}`}>
                          {props.concentracionesFilter.map((concentracion) => {
                            return <>{<option value={concentracion}>{concentracion}</option>}</>;
                          })}
                        </datalist>
                      </td>
                      <td>
                        <input
                          value={producto.descripcion}
                          autoComplete="off"
                          // disabled={!props.disabledInsumo && !producto.disabledInsumo ? false : true}
                          type="text"
                          id={`productoservicio${index + props.tipoProducto}`}
                          onChange={(e) => props.changeInsumoCat(e, index)}
                          list={`listaInsumos${index + props.tipoProducto}`}
                          className="form-control"
                        />
                        {/* Si se eligió un tipo de servicio, se usan los servicios filtrados */}
                        {props.tipoProducto == "Servicio" ? (
                          <datalist autoComplete="off" id={`listaInsumos${index + props.tipoProducto}`}>
                            {props.serviciosFiltradosPorTipoServicio
                              ? props.serviciosFiltradosPorTipoServicio.map((prodServ) => {
                                  return (
                                    <>
                                      <option className="Option" value={`${prodServ.servicio}`}>
                                        {prodServ.servicio}
                                      </option>
                                    </>
                                  );
                                })
                              : props.productosServicios &&
                                props.productosServicios.map((prodServ) => {
                                  return (
                                    <>
                                      <option className="Option" value={`${prodServ.servicio}`}>
                                        {prodServ.servicio}
                                      </option>
                                    </>
                                  );
                                })}
                          </datalist>
                        ) : (
                          <datalist autoComplete="off" id={`listaInsumos${index + props.tipoProducto}`}>
                            {props.productosServicios &&
                              props.productosServicios.map((prodServ) => {
                                if(prodServ.idCatTipoproducto == 2)
                                {
                                  if(producto.principioActivo!= ""){
                                      if(producto.principioActivo==prodServ.principioActivo && prodServ.nombreComercial != "undefined" && prodServ.idCatTipoproducto == 2)
                                      {
                                        //PresentaDAtos(producto.principioActivo,prodServ.nombreComercial);
                                        return (
                                          <>
                                              <option
                                                className="Option"
                                                value={`${prodServ.nombreComercial} - ${prodServ.numIdentificacion}`}>
                                                {prodServ.nombreComercial} - {prodServ.principioActivo}
                                              </option>
                                          </>
                                        );                                      
                                      }
                                    }else{
                                      return (
                                        <>
                                            <option
                                              className="Option"
                                              value={`${prodServ.nombreComercial} - ${prodServ.numIdentificacion}`}>
                                              {prodServ.nombreComercial} - {prodServ.principioActivo}
                                            </option>
                                        </>
                                      );                                        
                                    }
                                  }
                                  else{
                                    return (
                                      <>
                                          <option
                                            className="Option"
                                            value={`${prodServ.nombreComercial} - ${prodServ.numIdentificacion}`}>
                                            {prodServ.nombreComercial} - {prodServ.principioActivo}
                                          </option>
                                      </>
                                    );                                     
                                  }
                              })}
                          </datalist>
                        )}
                      </td>
                      <td>
                        <input
                          value={producto.piezas}
                          autoComplete="off"
                          disabled={props.disabledInsumo}
                          type="number"
                          min="0"
                          onChange={(e) => props.changeInsumoPiezas(e, props.tipoProducto, index)}
                          className="form-control"
                        />
                      </td>

                      <td>
                        <button
                          type="button"
                          className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                          onClick={() => {
                            props.setTipoProductoEliminar(props.tipoProducto);
                            props.setIndexInsumoBorrado(index);
                            props.setUISweetAlert({
                              ...props.UISweetAlert,
                              confirm_alert_delete_insumo: true,
                            });
                          }}>
                          ELIMINAR
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </CardBody>
  );
};

export default ProductosVale;

// {prodServ.nombreComercial} - {prodServ.numIdentificacion} -{prodServ.codigoEAN}-
//                                       {prodServ.principioActivo}
