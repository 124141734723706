import { Col, Drawer, Row, Form } from "antd";
import { useState } from "react";

const DrawerHistoricoCitas = ({ citasPaciente, paciente }) => {
  const formatFecha = (fecha) => {
    if (fecha) {
      const fechaFormateada = `${fecha.split("T")[0].split("-")[2]}/${fecha.split("T")[0].split("-")[1]}/${
        fecha.split("T")[0].split("-")[0]
      }`;
      return fechaFormateada;
    } else return "";
  };
  
  const formatHora = (fecha) => {
    if (fecha) {
      const hora = fecha.split("T")[1];
      const fechaFormateada = `${hora.split(":")[0]}:${hora.split(":")[1]}`;
      return fechaFormateada;
    } else return "";
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handdleCloseDrawer = () => {
    setVisible(false);
  };

  return (
    <>
      <button className="mb-4 btn btn-primary-orange-umo w-md waves-effect waves-light" type="button" onClick={showDrawer}>
        Histórico del paciente
      </button>

      <Drawer
        width={"80%"}
        // width={window.innerWidth > 900 ? 800 : window.innerWidth - 100}
        zIndex={200}
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}>
        {citasPaciente && citasPaciente.length > 0 ? (
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={24}>
                <h2>{paciente && paciente.nombres + " " + paciente.apellidoPaterno + " " + paciente.apellidoMaterno}</h2>
              </Col>
            </Row>
            <table className="table table-hover table-centered table-wrap mb-0">
              <thead>
                <tr>
                  <th scope="col">Médico</th>
                  <th scope="col">Espacio</th>
                  <th scope="col">Aseguradora</th>
                  <th scope="col">Fecha</th>
                  <th scope="col">Inicio</th>
                  <th scope="col">Termino</th>
                  <th scope="col">Tipo de Cita</th>
                  <th scope="col">Comentarios</th>
                </tr>
              </thead>
              <tbody>
                {citasPaciente &&
                  citasPaciente.map((cita) => {
                    return (
                      <tr key={cita.id}>
                        <td>{cita.extendedProps.nombreMedico ? cita.extendedProps.nombreMedico : ""}</td>
                        <td>
                          {cita.extendedProps.nombreEspacio && cita.extendedProps.tipoEspacio == 1
                            ? "MÉDICO"
                            : cita.extendedProps.nombreEspacio}
                        </td>
                        <td>{cita.extendedProps.aseguradora ? cita.extendedProps.aseguradora : "SIN ASEGURADORA"}</td>
                        <td>{cita.start ? formatFecha(cita.start) : ""}</td>
                        <td>{cita.start ? formatHora(cita.start) : ""}</td>
                        <td>{cita.end ? formatHora(cita.end) : ""}</td>
                        <td>{cita.description ? cita.description : ""}</td>
                        <td>{(cita.extendedProps.comentarioRecepcion ? cita.extendedProps.comentarioRecepcion : "") + " " +
                             (cita.extendedProps.comentarioAseguradora ? cita.extendedProps.comentarioAseguradora : "") + " " +
                             (cita.extendedProps.comentarioFarmacia ? cita.extendedProps.comentarioFarmacia : "") + " " +
                             (cita.extendedProps.comentarioTratamiento ? cita.extendedProps.comentarioTratamiento : "")}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <button
              className="mt-4 btn btn-primary-purple-umo w-md waves-effect waves-light"
              onClick={() => handdleCloseDrawer()}>
              Cerrar
            </button>
          </Form>
        ) : (
          <>
            <h2>El paciente no tiene citas</h2>
            <button className="btn btn-primary-purple-umo w-md waves-effect waves-light" onClick={() => handdleCloseDrawer()}>
              Cerrar
            </button>
          </>
        )}
      </Drawer>
    </>
  );
};

export default DrawerHistoricoCitas;
