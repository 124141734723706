import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import formatNumber from "../../utils/index";

// import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";
// import ConfiguracionContext from "../../context/Configuracion/ConfiguracionContext";
// import AuthContext from "../../context/Auth/AuthContext";
import VentaMostradorContext from "../../context/VentaMostrador/VentaMostradorContext";

export default function ListadoProductosVentaMostrador({ props }) {
  const [detallesPrecio, setDetallesPrecio] = useState({
    total: 0,
    subtotal: 0,
    iva: 0,
  });
  const [loading, setLoading] = useState(false);
  const [indexProductoBorrado, setIndexProductoBorrado] = useState(null);
  const [indexLoteBorrado, setIndexLoteBorrado] = useState(null);
  const [notaVenta, setNotaVenta] = useState({});
  const [ocultarBoton, setOcultarBoton] = useState(true);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_delete_producto: false,
    confirm_alert_delete_lote: false,
    confirm_nueva_venta_completa: false,
    confirm_nueva_venta_apartado: false,
    error_dlg: false,
    redirect: false,
    success_dlg: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  // ACCESO A ESTADO GLOBAL - VENTA MOSTRADOR
  const ctxVentaMostrador = useContext(VentaMostradorContext);
  const { ventaMostradorSeleccionada, setVentaMostradorSeleccionada, actualizarEstatusVentaMostrador,exportNotaDeVenta } = ctxVentaMostrador;

  //ACCESO AL ESTADO GLOBAL - ProductosServicios
  // const ctxProductosServicios = useContext(ProductosServiciosContext);
  // const { productosServicios, listarProductosServiciosAseguradora, listarProductosServiciosPersonal } = ctxProductosServicios;

  // ACCESO A ESTADO GLOBAL - Configuracion
  // const ctxConfiguracion = useContext(ConfiguracionContext);
  // const { configuracion } = ctxConfiguracion;

  // //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  // const ctxAuth = useContext(AuthContext);
  // const { usuario } = ctxAuth;

  const toggleExpanded = async (index) => {
    let productosVentaMostrador = ventaMostradorSeleccionada.ventaMostradorProducto;
    let producto = productosVentaMostrador[index];
    producto.expanded = !producto.expanded;

    setVentaMostradorSeleccionada({
      ...ventaMostradorSeleccionada,
      ventaMostradorProducto: productosVentaMostrador,
    });
  };

  const agregarLote = (index) => {
    let productosVentaMostrador = ventaMostradorSeleccionada.ventaMostradorProducto;
    let producto = productosVentaMostrador[index];

    let nuevoLote = {
      idInventarioProductoServicio: null,
      idInventario: null,
      lote: "",
      caducidad: "",
      completed: false,
      piezasDisponibles: 0,
      piezasAsignadas: 0,
      piezasRecibidas: 0,
      piezasUtilizadas: 0,
    };

    // console.log(insumo);

    producto.inventarioProductoServicios = producto.inventarioProductoServicios.concat(nuevoLote);

    setVentaMostradorSeleccionada({
      ...ventaMostradorSeleccionada,
      ventaMostradorProducto: productosVentaMostrador,
    });
  };

  const changeLoteProducto = async (e, index, indexLote) => {
    let productosVentaMostrador = ventaMostradorSeleccionada.ventaMostradorProducto;
    let producto = productosVentaMostrador[index];
    let lote = producto.inventarioProductoServicios[indexLote];
    let inventario = producto.catProductos.inventarios.filter((inv) => inv.idInventario == e.target.value)[0];
    let loteRepetido = false;

    producto.inventarioProductoServicios.map((element) => {
      if (!element.idInventarioProductoServicio && element.idInventario == e.target.value) {
        loteRepetido = true;
      } else if (element.idInventarioProductoServicio && element.inventario.idInventario == e.target.value) {
        loteRepetido = true;
      }
    });

    //Checamos si hay existe otro caso con el mismo lote, para no permitir tener 2 del mismo lote
    if (loteRepetido) {
      lote.caducidad = "";
      lote.lote = "";
      lote.idInventario = "";

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Lote repetido",
      });
    } else {
      lote.caducidad = inventario.caducidad;
      lote.lote = inventario.lote;
      lote.idInventario = inventario.idInventario;
      lote.piezasDisponibles = inventario.piezas;
    }

    lote.piezasAsignadas = 0;
    lote.piezasRecibidas = 0;
    lote.piezasUtilizadas = 0;

    setVentaMostradorSeleccionada({
      ...ventaMostradorSeleccionada,
      ventaMostradorProducto: productosVentaMostrador,
    });
  };

  const changePienzasAsginadas = async (e, index, indexLote) => {
    let value = e.target.value;
    if (value < 0) {
      value = value * -1;
    }

    let productosVentaMostrador = ventaMostradorSeleccionada.ventaMostradorProducto;
    let producto = productosVentaMostrador[index];
    let lote = producto.inventarioProductoServicios[indexLote];
    let inventario = producto.catProductos.inventarios.filter((inv) => inv.idInventario == lote.idInventario)[0];

    //Checamos de todos los lotes, si ya tenemos piezasAsignadas, para sumarlos, y que no podamos escoger mas piezas que nos indica el insumo
    let sumaPiezasAsignadas = 0;
    producto.inventarioProductoServicios.map((element) => {
      if (element.piezasAsignadas > 0 && element != lote) {
        sumaPiezasAsignadas += parseInt(element.piezasAsignadas, 10);
      }
    });

    //Si ya tenemos piezas asignadas, y le hacemos un cambio, tenemos que regresar lo que teniamos en piezasAsignadas el inventario
    //para mas adelante hacer el analisis de si existen piezas disponibles
    if (lote.piezasAsignadas > 0) {
      inventario.piezas = parseInt(inventario.piezas, 10) + parseInt(lote.piezasAsignadas, 10);
      lote.piezasAsignadas = value;
    } else {
      lote.piezasAsignadas = value;
    }

    //Si tenemos ese numero de piezas o mas en el inventario, se guarda, pero si no, guardamos un 0 y enseñanmos un mensaje para mostar las piezas disponibles
    //Tambien debemos checar que las piezas asignadas, no sean mayores a la piezas que se indican en el valeproductoservicio
    if (inventario.piezas >= lote.piezasAsignadas && producto.piezas - sumaPiezasAsignadas >= lote.piezasAsignadas) {
      inventario.piezas = inventario.piezas - lote.piezasAsignadas;

      if (producto.piezas == sumaPiezasAsignadas + parseInt(lote.piezasAsignadas, 10)) {
        producto.completed = true;
      } else {
        producto.completed = false;
      }
    } else if (inventario.piezas < lote.piezasAsignadas) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description:
          "No hay suficientes piezas, el numero de piezas disponibles para '" +
          producto.catProductos.nombreComercial +
          "' es de : " +
          inventario.piezas,
      });
      lote.piezasAsignadas = 0;
      producto.completed = false;
    } else if (producto.piezas - sumaPiezasAsignadas < lote.piezasAsignadas) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "El número de piezas asignadas no puede ser mayor al número de piezas indicadas por el producto",
      });
      lote.piezasAsignadas = 0;
      producto.completed = false;
    }

    setVentaMostradorSeleccionada({
      ...ventaMostradorSeleccionada,
      ventaMostradorProducto: productosVentaMostrador,
    });
  };

  const checarPorductosCompletos = async () => {
    ventaMostradorSeleccionada.ventaMostradorProducto.map((producto) => {
      //Checamos de todos los lotes, si ya tenemos piezasAsignadas, para sumarlos, y que no podamos escoger mas piezas que nos indica el insumo
      let sumaPiezasAsignadas = 0;
      producto.inventarioProductoServicios.map((element) => {
        if (element.piezasAsignadas > 0) {
          sumaPiezasAsignadas += parseInt(element.piezasAsignadas, 10);
        }
      });

      if(producto.catServicios){
        if(producto.catServicios.catTipoproducto.idCatTipoProducto==3){
          producto.completed = true;
        }
      }else{
        if (producto.piezas == sumaPiezasAsignadas) {
          producto.completed = true;
        } else {
          producto.completed = false;
        }
      }


    });

    setVentaMostradorSeleccionada(ventaMostradorSeleccionada);
  };

  const setPrecioTotal = async () => {
    let subtotal = 0;
    let iva = 0;
    let total = 0;

    setDetallesPrecio({
      subtotal: 0,
      iva: 0,
      total: 0,
    });

    if (ventaMostradorSeleccionada.ventaMostradorProducto.length > 0) {
      ventaMostradorSeleccionada.ventaMostradorProducto.map((producto) => {
        subtotal += producto.precio * producto.piezas;
        if (iva != null) {
          iva += producto.iva * producto.piezas;
        }
      });
    }

    total = subtotal + iva;

    setDetallesPrecio({
      subtotal: (Math.round(subtotal * 100) / 100).toFixed(2),
      iva: (Math.round(iva * 100) / 100).toFixed(2),
      total: (Math.round(total * 100) / 100).toFixed(2),
    });
  };

  const confirmGuardarCambiosVentaMostrador = async (e) => {
    e.preventDefault();

    const errorEnValidarLotes = await validacionDeLotes();
    if (errorEnValidarLotes) {
      return;
    }

    let productosCompletos = true;
    ventaMostradorSeleccionada.ventaMostradorProducto.map((producto) => {
      if (!producto.completed && productosCompletos) {
        productosCompletos = false;
      }
    });

    if (!productosCompletos) {
      ventaMostradorSeleccionada.completa = false;
      setVentaMostradorSeleccionada(ventaMostradorSeleccionada);
      setUISweetAlert({
        ...UISweetAlert,
        confirm_guardar_venta: true,
        dynamic_title: "¿La venta aún no esta completa, desea guardar los cambios?",
      });
      return;
    }

    ventaMostradorSeleccionada.completa = true;
    setVentaMostradorSeleccionada(ventaMostradorSeleccionada);

    setUISweetAlert({
      ...UISweetAlert,
      confirm_guardar_venta: true,
      dynamic_title: "¿La venta está completa, desea guardar los cambios?",
    });
  };

  const validacionDeLotes = async () => {
    let error = false;

    ventaMostradorSeleccionada.ventaMostradorProducto.map((producto, index) => {
      producto.inventarioProductoServicios.map((inv, indexInv) => {
        if (inv.inventario) {
          return;
        }

        if (!error && !inv.idInventario) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            confirm_guardar_venta: false,
            dynamic_title: "Fallo",
            dynamic_description: "Por favor, introduzca el inventario al lote",
          });
          error = true;
        }

        if (!error && inv.piezasAsignadas <= 0) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            confirm_guardar_venta: false,
            dynamic_title: "Fallo",
            dynamic_description: "Por favor, la cantidad de pieza asignadas debe ser mayor a 0",
          });
          error = true;
        }
      });
    });

    return error;
  };

  const submitVentaMostrador = async () => {
    let data = await formatVentaMostrador();
    if (ventaMostradorSeleccionada.completa) {
      data.idEstatusNuevo = 4;
    }

    if(ventaMostradorSeleccionada.catTipoVentaMostrador.idCatTipoVentaMostrador==3){
      if (ventaMostradorSeleccionada.completa) {
        data.idEstatusNuevo = 4;
      }else{
        data.idEstatusNuevo = 3;
      }
    }

    setUISweetAlert({
      ...UISweetAlert,
      confirm_guardar_venta: false,
    });

    setLoading(true);

    const res = await actualizarEstatusVentaMostrador(data);

    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_guardar_venta: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_guardar_venta: false,
        redirect: true,
        path: "/ventasmostrador",
        dynamic_description: "Se guardaron los cambios",
      });
    }
  };

  const formatVentaMostrador = async () => {
    let valorServicios = null;
    let valorPoroducto = null;

    let data = {
      idVentaMostrador: ventaMostradorSeleccionada.idVentaMostrador,
      ventaMostradorProducto: [],
    };

    ventaMostradorSeleccionada.ventaMostradorProducto.map((producto) => {
      valorServicios = null;
      valorPoroducto = null;

      if(producto.catServicios){
         valorServicios = producto.catServicios.idCatServicio
      }else
      {
        valorPoroducto = producto.catProductos.idCatProducto
      }

      let productoData = {
        idMostradorProductoServicio: producto.idMostradorProductoServicio,
        idCatServicio : valorServicios,
        idCatProducto : valorPoroducto,
        inventarioProductoServicio: [],
      };
      //ventaMostradorSeleccionada.catTipoVentaMostrador.idCatTipoVentaMostrador==3

   if (producto.inventarioProductoServicios.length > 0) {
        producto.inventarioProductoServicios.map((lote) => {
          let loteData = {
            piezasAsignadas: lote.piezasAsignadas,
            piezasRecibidas: lote.piezasAsignadas,
            piezasUtilizadas: lote.piezasAsignadas,
          };

          if (lote.idInventarioProductoServicio) {
            loteData.idInventarioProductoServicio = lote.idInventarioProductoServicio;
          } else if (lote.idInventario) {
            loteData.idInventario = lote.idInventario;
          }

          productoData.inventarioProductoServicio = productoData.inventarioProductoServicio.concat(loteData);
        });
      }

      data.ventaMostradorProducto = data.ventaMostradorProducto.concat(productoData);
    });

    return data;
  };

  const eliminarLote = async () => {
    let productosVentaMostrador = ventaMostradorSeleccionada.ventaMostradorProducto;
    let producto = productosVentaMostrador[indexProductoBorrado];
    let loteEliminado = producto.inventarioProductoServicios[indexLoteBorrado];

    if (loteEliminado.idInventario != null && loteEliminado.piezasAsignadas > 0) {
      //Se le regresan al inventario las piezas tomadas, (esto es de manera local)
      let inventario = producto.catProductos.inventarios.filter((inv) => inv.idInventario == loteEliminado.idInventario)[0];
      inventario.piezas = parseInt(inventario.piezas, 10) + parseInt(loteEliminado.piezasAsignadas, 10);
    }

    producto.completed = false;
    producto.inventarioProductoServicios = producto.inventarioProductoServicios.filter(
      (lotesProducto) => lotesProducto != loteEliminado
    );

    setVentaMostradorSeleccionada({
      ...ventaMostradorSeleccionada,
      ventaMostradorProducto: productosVentaMostrador,
    });

    const productoCompleto = await checarProductoCompleto();

    setIndexProductoBorrado(null);
    setIndexLoteBorrado(null);

    setUISweetAlert({
      ...UISweetAlert,
      success_dlg: true,
      dynamic_title: "Borrado",
      confirm_alert_delete_lote: false,
      dynamic_description: "El lote ha sido borrado correctamente.",
    });
    return productoCompleto;
  };

  const checarProductoCompleto = async () => {
    let productosVentaMostrador = ventaMostradorSeleccionada.ventaMostradorProducto;
    let producto = productosVentaMostrador[indexProductoBorrado];

    //Checamos de todos los lotes, si ya tenemos piezasAsignadas, para sumarlos, y que no podamos escoger mas piezas que nos indica el insumo
    let sumaPiezasAsignadas = 0;
    producto.inventarioProductoServicios.map((element) => {
      if (element.piezasAsignadas > 0) {
        sumaPiezasAsignadas += parseInt(element.piezasAsignadas, 10);
      }
    });

    if (producto.piezas == sumaPiezasAsignadas) {
      producto.completed = true;
    } else {
      producto.completed = false;
    }

    setVentaMostradorSeleccionada(ventaMostradorSeleccionada);
  };

  const imprimirNotaDeVentaCancelada = async () => {
    setLoading(true);
    notaVenta.idEstatusNuevo = 3;

    let status = 0;
    if(ventaMostradorSeleccionada){
      status = ventaMostradorSeleccionada.estatusventamostrador[ventaMostradorSeleccionada.estatusventamostrador.length - 1].catEstatusventamostrador.idCatEstatusVentaMostrador
    }

    if(status ==7 ){
      notaVenta.idEstatusNuevo = 13;
    }else{
      notaVenta.idEstatusNuevo = 11;
    }
    
    const res = await exportNotaDeVenta(notaVenta);

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
    }

    if( notaVenta.idEstatusNuevo == 11){
      setLoading(true);
      notaVenta.idEstatusNuevo = 12;
      const resCopia = await exportNotaDeVenta(notaVenta);
  
      if (resCopia.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: resCopia.data.message,
        });
      } else if (resCopia) {
        setLoading(false);
      }
    }

  };

  useEffect(() => {

    //Una vez que obtengamos la venta mostrador, checamos si estan completos sus productos, y obtenemos el total
    if (Object.keys(ventaMostradorSeleccionada).length !== 0) {
      //Si el vale esta cancelado o ya esta completo no podemos hacerle modificaciones
      let status = ventaMostradorSeleccionada.estatusventamostrador[ventaMostradorSeleccionada.estatusventamostrador.length - 1].catEstatusventamostrador.idCatEstatusVentaMostrador
      if(status>= 5){
        setOcultarBoton(true);
      }else{
        setOcultarBoton(false);
      }

      const estatusActual =
        ventaMostradorSeleccionada.estatusventamostrador[ventaMostradorSeleccionada.estatusventamostrador.length - 1]
          .catEstatusventamostrador;
      if (
        (estatusActual.idCatEstatusVentaMostrador == 5 || estatusActual.idCatEstatusVentaMostrador == 7) &&
        !ventaMostradorSeleccionada.historicoVentaMostrador
      ) {
        props.history.push("/ventasmostrador");
      }
      checarPorductosCompletos();
      setPrecioTotal();
    }

    if (Object.entries(notaVenta).length === 0 && Object.entries(ventaMostradorSeleccionada).length !== 0) {
      setNotaVenta({
        ...notaVenta,
        idVentaMostrador: ventaMostradorSeleccionada.idVentaMostrador,
        cobrosAnticipo: ventaMostradorSeleccionada.ventaMostradorcobro,
      });
    }

  }, [ventaMostradorSeleccionada]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Lista de Productos</h3>

      <Card className="mt-10">
        <CardBody>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    {ocultarBoton? 
                    <button
                      className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                      onClick={imprimirNotaDeVentaCancelada}>
                      Nota de Venta
                    </button>  
                    :null}
                    <table className="table table-hover table-centered table-wrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Insumo</th>
                          <th scope="col">Piezas</th>
                          <th scope="col">
                            Precio<br></br>Unitario
                          </th>
                          <th scope="col">IVA</th>
                          <th scope="col">Total</th>
                          <th scope="col">Completo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ventaMostradorSeleccionada.ventaMostradorProducto &&
                          ventaMostradorSeleccionada.ventaMostradorProducto.map((producto, index) => {
                            return (
                              <>
                                <tr key={producto}>
                                  <td name="toggleExpand" onClick={() => toggleExpanded(index)}>
                                    {producto.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>}
                                  </td>
                                  <td width={"50%"}>
                                    <input
                                      value={producto.catProductos?producto.catProductos.nombreComercial:producto.catServicios.servicio}
                                      type="text"
                                      className="form-control"
                                      disabled={true}
                                    />
                                  </td>
                                  <td width={"7%"}>
                                    <input value={producto.piezas} type="number" disabled={true} className="form-control" />
                                  </td>
                                  <td> {producto.precio && formatNumber(producto.precio)}</td>
                                  {/* <td> {producto.precio && `$${producto.precio.toFixed(2)}`} </td> */}
                                  <td> {formatNumber(producto.iva * producto.piezas)}</td>
                                  {/* <td> ${(producto.iva * producto.piezas).toFixed(2)}</td> */}
                                  <td>
                                    {" "}
                                    {producto.precio * producto.piezas
                                      ? formatNumber(producto.precio * producto.piezas + producto.iva * producto.piezas)
                                      : ""}{" "}
                                  </td>
                                  {/* <td> {producto.precio * producto.piezas ? `$${(producto.precio * producto.piezas + producto.iva * producto.piezas).toFixed(2)}` : ""} </td> */}
                                  <td>
                                    <div>
                                      <span
                                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                          producto.completed ? "bg-green-100" : "bg-red-100"
                                        }  capitalize`}>
                                        {producto.completed ? "SI" : "NO"}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                {producto.expanded && (
                                  <tr>
                                    <td colSpan={10}>
                                      {producto.catProductos &&
                                      producto.piezas > 0 &&
                                      !producto.completed &&
                                      !ventaMostradorSeleccionada.historicoVentaMostrador ? (
                                        <button
                                          name="agregarLote"
                                          className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                                          type="button"
                                          onClick={() => agregarLote(index)}>
                                          Agregar Lote
                                        </button>
                                      ) : null}
                                      {producto.inventarioProductoServicios.length > 0 && (
                                        <div className="table-responsive">
                                          <table className="table table-hover table-centered table-nowrap mb-10">
                                            <thead>
                                              <tr>
                                                <th scope="col">Lote</th>
                                                <th scope="col">Caducidad</th>
                                                {!ventaMostradorSeleccionada.historicoVentaMostrador ? (
                                                  <th scope="col">Piezas Disponibles</th>
                                                ) : null}
                                                <th scope="col">Piezas Asignadas</th>
                                                {!ventaMostradorSeleccionada.historicoVentaMostrador ? (
                                                  <th scope="col">Eliminar</th>
                                                ) : null}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {producto.inventarioProductoServicios.map((lote, indexLote) => {
                                                return (
                                                  <>
                                                    <tr key={lote}>
                                                      <td>
                                                        {lote.inventario ? (
                                                          lote.inventario.lote
                                                        ) : (
                                                          <div>
                                                            <select
                                                              name="lotes"
                                                              className="form-control"
                                                              style={{
                                                                padding: "0 2rem 0 0",
                                                              }}
                                                              value={lote.idInventario}
                                                              onChange={(e) => changeLoteProducto(e, index, indexLote)}>
                                                              <option disabled selected value="">
                                                                Seleccione un lote
                                                              </option>
                                                              {producto.catProductos.inventarios.map((inventarios) => {
                                                                return (
                                                                  <>
                                                                    {
                                                                      <option value={inventarios.idInventario}>
                                                                        {inventarios.lote}
                                                                      </option>
                                                                    }
                                                                  </>
                                                                );
                                                              })}
                                                            </select>
                                                          </div>
                                                        )}
                                                      </td>
                                                      <td>{lote.inventario ? lote.inventario.caducidad : lote.caducidad}</td>

                                                      {!ventaMostradorSeleccionada.historicoVentaMostrador ? (
                                                        <td>
                                                          {lote.inventario ? lote.inventario.piezas : lote.piezasDisponibles}
                                                        </td>
                                                      ) : null}
                                                      <td>
                                                        <input
                                                          type="number"
                                                          name="piezas"
                                                          min="0"
                                                          value={lote.piezasAsignadas}
                                                          disabled={lote.idInventario ? false : true}
                                                          onChange={(e) => changePienzasAsginadas(e, index, indexLote)}
                                                          className="form-control"
                                                        />
                                                      </td>
                                                      <td>
                                                        {!lote.inventario ? (
                                                          <button
                                                            type="button"
                                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                                            onClick={() => {
                                                              setIndexProductoBorrado(index);
                                                              setIndexLoteBorrado(indexLote);
                                                              setUISweetAlert({
                                                                ...UISweetAlert,
                                                                confirm_alert_delete_lote: true,
                                                              });
                                                            }}>
                                                            Eliminar
                                                          </button>
                                                        ) : null}
                                                      </td>
                                                    </tr>
                                                  </>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {!ventaMostradorSeleccionada.historicoVentaMostrador ? (
                    <div>
                      <button
                        type="submit"
                        onClick={(e) => confirmGuardarCambiosVentaMostrador(e)}
                        className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                        Completar
                      </button>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mt-4 w-full">
        <Card>
          <Row>
            <Col md="12">
              <table
                className="w-2/6"
                style={{
                  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  backgroundColor: "inherit",
                  margin: "0",
                  float: "right",
                }}
                bgcolor="#f6f6f6">
                <tbody>
                  <tr
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                        textAlign: "left",
                      }}
                      valign="top">
                      Sub-Total
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(parseFloat(detallesPrecio.subtotal))}
                      {/* ${detallesPrecio.subtotal} */}
                    </td>
                  </tr>
                  <tr
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                        textAlign: "left",
                      }}
                      valign="top">
                      IVA
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(parseFloat(detallesPrecio.iva))}
                      {/* ${detallesPrecio.iva} */}
                    </td>
                  </tr>
                  <tr
                    className="total"
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      width="80%"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "left",
                        borderTopWidth: "5px",
                        borderTopColor: "#333",
                        borderTopStyle: "double",
                        borderBottomColor: "#333",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        fontWeight: "700",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="left"
                      valign="top">
                      Total
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "5px",
                        borderTopColor: "#333",
                        borderTopStyle: "double",
                        borderBottomColor: "#333",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        fontWeight: "700",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(parseFloat(detallesPrecio.total))}
                      {/* ${detallesPrecio.total} */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Card>
      </Card>

      {UISweetAlert.confirm_guardar_venta ? (
        <SweetAlert
          title={UISweetAlert.dynamic_title}
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => submitVentaMostrador()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_guardar_venta: false,
            })
          }>
          Se guardará toda la información proporcionada
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_delete_lote ? (
        <SweetAlert
          title="¿Desea eliminar el insumo?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarLote()}
          onCancel={() => {
            setIndexProductoBorrado(null);
            setIndexLoteBorrado(null);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_delete_lote: false,
            });
          }}>
          Se perderá la información
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}
    </div>
  );
}
