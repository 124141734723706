import React, { useReducer , useContext} from "react";

import InventarioContext from "./InventarioContext";
import InventarioReducer from "./InventarioReducer";
import AuthContext from "../../context/Auth/AuthContext";
import {
  OBTENER_INVETARIOS_PRODUCTOS,
  FILTROS_PRODUCTO_INVENTARIO,
  PRODUCTO_INVENTARIO,
  FILTROS_PRODUCTO_UBICACION,
  PRODUCTO_UBICACION,
  INVENTARIO_SELECCIONADO,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function InventarioState(props) {
  const initialState = {
    //producto para ventamostrado
    invetarioProducto: [],

    //producto para lista de inventario
    productosInventario: [],
    filtrosProductosInventario: {},

    //producto para lista por ubicacion
    productosUbicacion: [],
    filtrosProductosUbicacion: {},

    //asignar ubicacion
    inventarioSeleccionado: {},
  };

  const authContext = useContext(AuthContext);
  const {usuario} = authContext;

  const [state, dispatch] = useReducer(InventarioReducer, initialState);

  const obtenerInvetariosProducto = async (idCatProducto) => {
    const res = await clienteAxios.get(`/inventario/disponible/producto?id=${idCatProducto}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: OBTENER_INVETARIOS_PRODUCTOS,
      payload: res.data,
    });

    return res;
  };

  const obtenerProductosInventario = async ({ page, limit }) => {
    const res = await clienteAxios.post(
      `/inventario/producto`,
      { ...state.filtrosProductosInventario, pageSize: limit, pageIndex: page },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      }
    );

    dispatch({
      type: PRODUCTO_INVENTARIO,
      payload: res.data.inventario,
    });

    return res;
  };

  const setFiltrosProductosIventario = async (filtrosProductosInventario) => {
    dispatch({
      type: FILTROS_PRODUCTO_INVENTARIO,
      payload: filtrosProductosInventario,
    });
  };

  const setProductosInventario = async (productosInventario) => {
    dispatch({
      type: PRODUCTO_INVENTARIO,
      payload: productosInventario,
    });
  };

  const setFiltrosProductosUbicacion = async (filtrosProductosUbicacion) => {
    dispatch({
      type: FILTROS_PRODUCTO_UBICACION,
      payload: filtrosProductosUbicacion,
    });
  };

  const setProductosUbicacion = async (productosUbicacion) => {
    dispatch({
      type: PRODUCTO_UBICACION,
      payload: productosUbicacion,
    });
  };

  const obtenerProductosUbicacion = async ({ page, limit }) => {
    const res = await clienteAxios.post(
      `/productos/ubicacion`,
      { ...state.filtrosProductosUbicacion, pageSize: limit, pageIndex: page },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      }
    );

    dispatch({
      type: PRODUCTO_UBICACION,
      payload: res.data.inventario,
    });

    return res;
  };

  const crearInventario = async (inventarioVo) => {
    console.log("🚀 ~ file: InventarioState.js:118 ~ crearInventario ~ inventarioVo:", inventarioVo);
    try {
      const res = await clienteAxios.post(`/inventario/guardar`, inventarioVo, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });
      console.log("🚀 ~ file: InventarioState.js:121 ~ crearInventario ~ res:", res);

      return res;
    } catch (error) {
      console.log("🚀 ~ file: InventarioState.js:127 ~ crearInventario ~ error:", error);
      return error;
    }
  };

  const seleccionarInventario = async (idInventario) => {
    const res = await clienteAxios.post(`/inventario/detalles?id=${idInventario}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: INVENTARIO_SELECCIONADO,
      payload: res.data,
    });
    // let x = {"idInventario":46,"idCatProducto":null,"numIdentificacion":"LEU10000PROBE","codigoEAN":"75005023","nombreComercial":"LEUNASE 10 000  UI  CON 1 FRASCO AMPULA LIOFILIZADO","principioActivo":"L-ASPARAGINASA","presentacion":null,"concentracion":null,"idCatTipoProducto":null,"tipoProducto":"MEDICAMENTO","idCatTiporecepcion":null,"tipoRecepcion":null,"idOrdenCompraProducto":null,"folioOrdenCompra":null,"lote":"018LIH02","caducidad":"2021-07-01","fechaRecepcion":null,"piezas":3,"idCatLaboratorio":null,"laboratorio":"SANFER","idCatproveedor":null,"proveedor":"PROBEMEDIC","idCatAlmacen":2,"idCatAnaquel":null,"idCatEstante":null,"idCatUbicacion":null,"almacen":"ALMACEN UMO","pageSize":null,"pageIndex":null,"precio":0,"iva":0,
    // "ubicaciones":[{"idInventarioUbicacion":1,"inventario":null,"ubicacion":{"idCatUbicacion":1,"ubicacion":"Ubicacion 1","fechaCreacion":null,"fechaModificacion":null,"idUsuario":null,"activo":true,"estante":{"idCatEstante":1, "estante":"Estante 1", "activo": true, "anaquel":{ "idCatEstante":1, "anaquel":"Anaquel 1"} }},"piezas":1}]}
    // dispatch({
    //     type: INVENTARIO_SELECCIONADO,
    //     payload: x
    // })
  };

  const setInventarioSeleccionado = async (inventarioSeleccionado) => {
    dispatch({
      type: INVENTARIO_SELECCIONADO,
      payload: inventarioSeleccionado,
    });
  };

  const cleanInventarioSeleccionado = async () => {
    dispatch({
      type: INVENTARIO_SELECCIONADO,
      payload: {},
    });
  };

  const uploadEliminarInventarioUbicacion = async (idInventarioUbicacion) => {
    try {
      const res = await clienteAxios.post(`/inventario/ubicacion/eliminar?id=${idInventarioUbicacion}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const uploadAsignarUbicaciones = async () => {
    try {

      let cadena = "";
      cadena = cadena + "Asignación Ubicación:Lote[" + state.inventarioSeleccionado.lote  + " - M:" + state.inventarioSeleccionado.nombreComercial + "]";
      state.inventarioSeleccionado.ubicaciones.map((ubicacion) =>{
        //IdIU = Id inventario ubicación
        let anaquelTemp = ubicacion.ubicacion? ubicacion.ubicacion.estante.anaquel.anaquel : ubicacion.idCatAnaquel;
        let estanteTemp =  ubicacion.ubicacion? ubicacion.ubicacion.estante.anaquel.anaquel : ubicacion.idCatEstante;
        let ubicacionTemp = ubicacion.ubicacion? ubicacion.ubicacion.ubicacion : ubicacion.idCatUbicacion;
        cadena = cadena + ",[Al:" + state.inventarioSeleccionado.almacen + " - IdAn:" + anaquelTemp + " IdEst:" + estanteTemp + " IdUb:" +  ubicacionTemp + "]";
      });      
      state.inventarioSeleccionado.logVo ={
                                              idUsuario: usuario.idUsuario,
                                              modulo: "Inventario",
                                              actividad: cadena  
                                          };

      const res = await clienteAxios.post(`/inventario/asignar/ubicacion`, state.inventarioSeleccionado, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const seleccionarInventarioTraslado = async (idInventario) => {
    const res = await clienteAxios.post(`/inventario/detalles/traslado/baja?id=${idInventario}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });

    dispatch({
      type: INVENTARIO_SELECCIONADO,
      payload: res.data,
    });
    // let x = {"idInventario":46,"idCatProducto":null,"numIdentificacion":"LEU10000PROBE","codigoEAN":"75005023","nombreComercial":"LEUNASE 10 000  UI  CON 1 FRASCO AMPULA LIOFILIZADO","principioActivo":"L-ASPARAGINASA","presentacion":null,"concentracion":null,"idCatTipoProducto":null,"tipoProducto":"MEDICAMENTO","idCatTiporecepcion":null,"tipoRecepcion":null,"idOrdenCompraProducto":null,"folioOrdenCompra":null,"lote":"018LIH02","caducidad":"2021-07-01","fechaRecepcion":null,"piezas":3,"idCatLaboratorio":null,"laboratorio":"SANFER","idCatproveedor":null,"proveedor":"PROBEMEDIC","idCatAlmacen":2,"idCatAnaquel":null,"idCatEstante":null,"idCatUbicacion":null,"almacen":"ALMACEN UMO","pageSize":null,"pageIndex":null,"precio":0,"iva":0,
    // "ubicaciones":[{"idInventarioUbicacion":1,"inventario":null,"ubicacion":{"idCatUbicacion":1,"ubicacion":"Ubicacion 1","fechaCreacion":null,"fechaModificacion":null,"idUsuario":null,"activo":true,"estante":{"idCatEstante":1, "estante":"Estante 1", "activo": true, "anaquel":{ "idCatEstante":1, "anaquel":"Anaquel 1"} }},"piezas":1},
    // {"idInventarioUbicacion":2,"inventario":null,"ubicacion":{"idCatUbicacion":2,"ubicacion":"Ubicacion 2","fechaCreacion":null,"fechaModificacion":null,"idUsuario":null,"activo":true,"estante":{"idCatEstante":2, "estante":"Estante 2", "activo": true, "anaquel":{ "idCatEstante":2, "anaquel":"Anaquel 2"} }},"piezas":2}]}
    // dispatch({
    //     type: INVENTARIO_SELECCIONADO,
    //     payload: x
    // })
  };

  const uploadTrasladoProductoInventario = async (data) => {
    try {
      const res = await clienteAxios.post(`/traslado/inventario/nuevo`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const exportListaProductosInventario = async (filtrosProductosIventario) => {
    try {
      const res = await clienteAxios.post(`/inventario/export/productos`, state.filtrosProductosInventario, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=inventario.xlsx",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "inventario.xlsx");
      document.body.appendChild(link);
      link.click();

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <InventarioContext.Provider
      value={{
        invetarioProducto: state.invetarioProducto,
        productosInventario: state.productosInventario,
        filtrosProductosInventario: state.filtrosProductosInventario,
        productosUbicacion: state.productosUbicacion,
        filtrosProductosUbicacion: state.filtrosProductosUbicacion,
        inventarioSeleccionado: state.inventarioSeleccionado,

        obtenerInvetariosProducto,
        obtenerProductosInventario,
        setFiltrosProductosIventario,
        setProductosInventario,
        setFiltrosProductosUbicacion,
        setProductosUbicacion,
        obtenerProductosUbicacion,
        seleccionarInventario,
        setInventarioSeleccionado,
        cleanInventarioSeleccionado,
        uploadEliminarInventarioUbicacion,
        uploadAsignarUbicaciones,
        seleccionarInventarioTraslado,
        uploadTrasladoProductoInventario,
        crearInventario,
        exportListaProductosInventario,
      }}>
      {props.children}
    </InventarioContext.Provider>
  );
}
