import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import RegistroPagosOrdenCompraContext from "../../context/RegistroPagosOrdenCompra/RegistroPagosOrdenCompraContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import CatTipoSolicitudCompraContext from "../../context/CatTipoSolicitudCompra/CatTipoSolicitudCompraContext";
import CatTipoOrdenCompraContext from "../../context/CatTipoOrdenCompra/CatTipoOrdenCompraContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
// import EstatusOrdenCompraContext from '../../context/EstatusOrdenCompra/EstatusOrdenCompraContext';

import formatNumber from "../../utils/index";

import AuthContext from "../../context/Auth/AuthContext";

export default function RegistroOrdenCompra(props) {
  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_registro_pago: false,
    confirm_alert_actualizar_fecha: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const [toggleSearchBy, setToggleSearchBy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledCheckOrdenesCompra, setDisabledCheckOrdenesCompra] = useState(false);
  const [nuevoRegistroPago, setNuevoRegistroPago] = useState({
    fechaDeposito: new Date().toISOString().slice(0, 10),
  });
  const [nuevaFechaPlaneadaPago, setNuevaFechaPlaneadaPago] = useState();
  const [ordenCompraSeleccionada, setOrdenCompraSeleccionada] = useState({});

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  //ACCESO AL ESTADO GLOBAL - Usuarios
  const ctxUsuarios = useContext(UsuariosContext);
  const { usuarios, listarUsuarios } = ctxUsuarios;

  //ACCESO AL ESTADO GLOBAL - Tipos de Solicitud de compra
  const ctxTiposSolicitudCompra = useContext(CatTipoSolicitudCompraContext);
  const { tiposSolicitudCompra, listarTiposSolicitudCompra } = ctxTiposSolicitudCompra;

  //ACCESO AL ESTADO GLOBAL - Tipos de compra
  const ctxTiposOrdenCompra = useContext(CatTipoOrdenCompraContext);
  const { tiposOrdenCompra, listarTiposOrdenCompra } = ctxTiposOrdenCompra;

  //ACCESO AL ESTADO GLOBAL - Proveedores
  const ctxProveedores = useContext(ProveedoresContext);
  const { proveedores, listarProveedores } = ctxProveedores;

  //ACCESO AL ESTADO GLOBAL - Proveedores
  // const ctxEstatusOrdenCompra = useContext(EstatusOrdenCompraContext);
  // const { estatusOrdenCompra, listarEstatusOrdenCompra } = ctxEstatusOrdenCompra;

  const registroPagosOrdenCompraContext = useContext(RegistroPagosOrdenCompraContext);

  const {
    filtrosPagosOrdenCompra,
    setFiltrosPagoOrdenCompra,
    pagosOrdenCompraFiltradas,
    listarPagoOrdenesCompras,
    setPagosOrdenesCompraFiltradas,
    uploadNuevoRegistroPago,
    updateFechaPlaneadaPago,
  } = registroPagosOrdenCompraContext;

  const toggleSearch = () => {
    setToggleSearchBy(!toggleSearchBy);
    limpiarFiltros();
  };

  const limpiarFiltros = () => {
    setFiltrosPagoOrdenCompra({
      fechaInicial: "",
      tipoSolicitudCompra: "",
      tipoOrdenCompra: "",
      fechaFinal: "",
      usuarioComprador: "",
      folioOrdenCompra: null,
      // estatusOrdenCompra: "",
      importeMaximo: null,
      proveedor: "",
    });
  };

  const onChangeImporteMaximo = (e) => {
    let value = e.target.value;
    value = Number(value.replace("$", ""));

    setFiltrosPagoOrdenCompra({
      ...filtrosPagosOrdenCompra,
      importeMaximo: value,
    });
  };

  const buscarPorFolio = (e) => {
    e.preventDefault();
    listarPagoOrdenesCompras({
      folioOrdenCompra: filtrosPagosOrdenCompra.folioOrdenCompra,
    });
  };

  const buscarPorFiltros = (e) => {
    e.preventDefault();
    if (filtrosPagosOrdenCompra.fechaInicial && !filtrosPagosOrdenCompra.fechaFinal) {
      return;
    }

    if (filtrosPagosOrdenCompra.fechaFinal) {
      filtrosPagosOrdenCompra.fechaFinal = filtrosPagosOrdenCompra.fechaFinal + "T23:59:59";
    }

    listarPagoOrdenesCompras(filtrosPagosOrdenCompra);

    if (filtrosPagosOrdenCompra.fechaFinal) {
      filtrosPagosOrdenCompra.fechaFinal = filtrosPagosOrdenCompra.fechaFinal.split("T")[0];
    }
  };

  const onChange = (e) => {
    if (e.target.name === "fechaInicial") {
      setFiltrosPagoOrdenCompra({
        ...filtrosPagosOrdenCompra,
        [e.target.name]: e.target.value,
        fechaFinal: e.target.value,
      });
    } else {
      setFiltrosPagoOrdenCompra({
        ...filtrosPagosOrdenCompra,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handdleCheckedOrdenCompra = async (ordenCompra, value) => {
    if (!value) {
      ordenCompra.checked = value;
      setPagosOrdenesCompraFiltradas(pagosOrdenCompraFiltradas);
      const ordenesCompraChecadas = await isOrdenCompraChecked();

      if (!ordenesCompraChecadas) {
        habilitarOrdenesCompraChecked();
      }
    } else {
      ordenCompra.checked = value;
      setPagosOrdenesCompraFiltradas(pagosOrdenCompraFiltradas);
      if (!disabledCheckOrdenesCompra) {
        deshabilitarOrdenesCompraProveedor(ordenCompra.idCatProveedor);
      }
    }
  };

  const deshabilitarOrdenesCompraProveedor = async (idCatProveedor) => {
    // eslint-disable-next-line
    pagosOrdenCompraFiltradas.map((ordenCompra) => {
      if (ordenCompra.idCatProveedor !== idCatProveedor) {
        ordenCompra.disabledCheck = true;
      }
    });
    setPagosOrdenesCompraFiltradas(pagosOrdenCompraFiltradas);
    setDisabledCheckOrdenesCompra(true);
  };

  const habilitarOrdenesCompraChecked = async () => {
    // eslint-disable-next-line
    pagosOrdenCompraFiltradas.map((ordenCompra) => {
      ordenCompra.disabledCheck = false;
      ordenCompra.checked = false;
    });
    setPagosOrdenesCompraFiltradas(pagosOrdenCompraFiltradas);
    setDisabledCheckOrdenesCompra(false);
  };

  const isOrdenCompraChecked = async () => {
    let ordenCompraChecked = false;

    pagosOrdenCompraFiltradas.map((ordenCompra) => {
      // eslint-disable-next-line
      if (ordenCompra.checked) {
        if (!ordenCompraChecked) {
          ordenCompraChecked = [];
        }
        ordenCompraChecked = ordenCompraChecked.concat(ordenCompra);
      }
    });

    return ordenCompraChecked;
  };

  const confirmRegistrarPago = async () => {
    const ordenesCompraChecadas = await isOrdenCompraChecked();

    if (!ordenesCompraChecadas) {
      //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione una orden de compra",
      });
      return;
    }

    //todo
    let registros = 0;
    const respuesta2 =  await pagosOrdenCompraFiltradas.map((ordenCompra, index) => {
      if(ordenCompra.checked){
        if(ordenCompra.checked==true){
            registros++;
          }
        }
      }        
    );

    if(registros > 1){
        //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Por favor seleccione solo una orden de compra",
        });
        return;
    }

    let idOrdenesCompra = [];
    let totalOrdenesCompra = 0;

    ordenesCompraChecadas.map((orden) => {
      idOrdenesCompra = idOrdenesCompra.concat(orden.idOrdenCompra);
      totalOrdenesCompra += Number(orden.importeTotal);
    });

    // ordenesCompraChecadas.map((orden) => {
    //   idOrdenesCompra = idOrdenesCompra.concat({
    //     idOrdenCompra: orden.idOrdenCompra,
    //   });
    //   totalOrdenesCompra += Number(orden.importeTotal);
    // });

    setNuevoRegistroPago({
      ...nuevoRegistroPago,
      ordenesCompra: idOrdenesCompra,
      importeTotal: totalOrdenesCompra,
    });

    // Mandar a Oscar nombres

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_registro_pago: true,
    });
  };

  const confirmActualizarFechaPago = async () => {
    const ordenesCompraChecadas = await isOrdenCompraChecked();
    const ordenSeleccionada = ordenesCompraChecadas[0];

    if (!ordenesCompraChecadas) {
      //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor seleccione una orden de compra",
      });
      return;
    }
    if (ordenesCompraChecadas.length == 0) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Seleccione una orden de compra para actualizar su fecha de pago",
      });
      return;
    }

    let fechaLimiteOrdenSeleccionada = ordenSeleccionada.fechaLimitePago ? ordenSeleccionada.fechaLimitePago.split("/") : "";

    const fechaFormateada = `${fechaLimiteOrdenSeleccionada[2]}-${fechaLimiteOrdenSeleccionada[1]}-${fechaLimiteOrdenSeleccionada[0]}`;
    setOrdenCompraSeleccionada({
      idOrdenCompra: ordenSeleccionada.idOrdenCompra,
      fechaLimitePago: fechaFormateada,
    });

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_actualizar_fecha: true,
    });
  };

  const registrarNuevoPago = async () => {
    let errorRegistroNuevoPago = false;
    if (!errorRegistroNuevoPago && !nuevoRegistroPago.fechaDeposito) {
      errorRegistroNuevoPago = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la fecha de pago",
      });
    }
    if (!errorRegistroNuevoPago && !nuevoRegistroPago.importe) {
      errorRegistroNuevoPago = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el importe",
      });
    }
    if (!errorRegistroNuevoPago && nuevoRegistroPago.importe < nuevoRegistroPago.importeTotal) {
      errorRegistroNuevoPago = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, el importe no debe ser menor al importe total a pagar",
      });
    }

    if (!errorRegistroNuevoPago && !nuevoRegistroPago.bancoDeposito) {
      errorRegistroNuevoPago = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el banco",
      });
    }
    if (!errorRegistroNuevoPago && !nuevoRegistroPago.folioDeposito) {
      errorRegistroNuevoPago = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la referencia",
      });
    }

    if (!errorRegistroNuevoPago) {
      delete nuevoRegistroPago.importe;
      const res = await uploadNuevoRegistroPago({
        ...nuevoRegistroPago,
        importe: nuevoRegistroPago.importeTotal,
      });
      setLoading(true);

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_alert_registro_pago: false,
          dynamic_description: "Se registró el pago",
        });
        setNuevoRegistroPago({
          fechaDeposito: new Date().toISOString().slice(0, 10),
        });
        habilitarOrdenesCompraChecked();
        listarPagoOrdenesCompras(filtrosPagosOrdenCompra);
      }
    }
  };

  const changeNuevoRegistroPago = async (e) => {
    setNuevoRegistroPago({
      ...nuevoRegistroPago,
      [e.target.name]: e.target.value,
    });
  };
  const changeNuevoRegistroPagoImporte = async (e) => {
    let value = e.target.value;
    value = value.replaceAll("$", "").replace(/(?!-)[^0-9.]/g, "");
    setNuevoRegistroPago({
      ...nuevoRegistroPago,
      [e.target.name]: value,
    });
  };

  const registrarfechPago = async (ordenCompra) => {
    const res = await updateFechaPlaneadaPago({
      idOrdenCompra: ordenCompra.idOrdenCompra,
      fechaPlaneadaPago: ordenCompraSeleccionada.fechaPlaneadaPago,
      idEstatusNuevo: 5,
    });

    if (res.data.error) {
      return true;
    } else if (res) {
      return false;
    }
  }

  const actualizarFechaPago = async () => {
    let errorRegistroNuevaFechaPlaneada = false;
    if (!errorRegistroNuevaFechaPlaneada && !ordenCompraSeleccionada.fechaPlaneadaPago) {
      errorRegistroNuevaFechaPlaneada = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_registro_pago: false,
        dynamic_description: "Por favor, ingrese la fecha planeada de pago",
      });
    }

    if (!errorRegistroNuevaFechaPlaneada) {
      let error =  false;
      setLoading(true);
      const respuesta2 =  await pagosOrdenCompraFiltradas.map((ordenCompra, index) => {
        if(ordenCompra.checked){
          if(ordenCompra.checked==true){
            const regreso = registrarfechPago(ordenCompra);
            if(regreso == true && error ==  false){
              error = true;
            }
          }
        }        
      })

      setLoading(false);
      if(error==true){
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Hubo un error al salvar los datos",
        });
      }else{
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_alert_actualizar_fecha: false,
          dynamic_description: "Se actualizó la fecha planeada de pago",
        });
        
        const fetchCargaOrdenesCompras = async () => {
          const respuesta =await  listarPagoOrdenesCompras(filtrosPagosOrdenCompra);
        }
        fetchCargaOrdenesCompras();

        setNuevaFechaPlaneadaPago({});
        habilitarOrdenesCompraChecked();

      }
    }
  };

  useEffect(() => {
    listarUsuarios();
    listarTiposSolicitudCompra();
    listarTiposOrdenCompra();
    listarProveedores();
  }, []);

  useEffect(() => {
    // if (usuarios.length <= 0) {
    //   listarUsuarios();
    // }
    // if (tiposSolicitudCompra.length <= 0) {
    //   listarTiposSolicitudCompra();
    // }
    // if (tiposOrdenCompra.length <= 0) {
    //   listarTiposOrdenCompra();
    // }
    // if (proveedores.length <= 0) {
    //   listarProveedores();
    // }
    listarPagoOrdenesCompras(filtrosPagosOrdenCompra);
    // if(estatusOrdenCompra.length <= 0 ){ listarEstatusOrdenCompra(); }
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Planeación y Registro de Pagos</h3>
        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearch}>
          {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
        </button>

        <Card
          hidden={!toggleSearchBy}
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFolio(e)}>
            <CardBody>
              <Row>
                <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                  <Row
                    className="form-group"
                    style={{
                      marginBottom: "0",
                    }}>
                    <Col sm={10} className="flex col-sm-10">
                      <input
                        className="form-control mr-2.5"
                        type="text"
                        name="folioOrdenCompra"
                        placeholder="Escribe tu folio"
                        onChange={onChange}
                        value={filtrosPagosOrdenCompra.folioOrdenCompra ? filtrosPagosOrdenCompra.folioOrdenCompra : ""}
                      />
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                        style={{
                          marginRight: "2.5rem",
                          background: "#ff9933",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        type="submit">
                        Buscar
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>

        <Card
          hidden={toggleSearchBy}
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit={(e) => buscarPorFiltros(e)}>
            <CardBody>
              <Row>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaInicial"
                        onChange={onChange}
                        value={filtrosPagosOrdenCompra.fechaInicial}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Tipo OC</label>
                    <Col sm={7}>
                      <select
                        className="form-control"
                        name="tipoSolicitudCompra"
                        onChange={onChange}
                        value={filtrosPagosOrdenCompra.tipoSolicitudCompra}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tiposSolicitudCompra.map((tiposSolCom) => {
                          return (
                            <>
                              <option value={tiposSolCom.idCatTipoSolicitudCompra}>{tiposSolCom.tipoSolicitudCompra}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Tipo Compra</label>
                    <Col sm={7}>
                      <select
                        className="form-control"
                        name="tipoOrdenCompra"
                        onChange={onChange}
                        value={filtrosPagosOrdenCompra.tipoOrdenCompra}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tiposOrdenCompra.map((tipoOrdenCompra) => {
                          return (
                            <>
                              <option value={tipoOrdenCompra.idCatTipoOrdenCompra}>{tipoOrdenCompra.tipoOrdenCompra}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Proveedor</label>
                    <Col sm={7}>
                      <select
                        name="proveedor"
                        className="form-control"
                        onChange={onChange}
                        value={filtrosPagosOrdenCompra.proveedor}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {proveedores.map((prov) => {
                          return (
                            <>
                              <option value={prov.idCatProveedor}>{prov.proveedor}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  {/* <Row className="form-group">
                                        <label className="col-sm-4 col-form-label">
                                            Estatus OC
                                        </label>
                                        <Col sm={7}>
                                            <select
                                            className="form-control"
                                            name="estatusOrdenCompra"
                                            onChange={onChange}
                                            value={filtrosPagosOrdenCompra.estatusOrdenCompra}
                                            >
                                                <option selected value="">
                                                    Seleccione una opción
                                                </option>
                                                {estatusOrdenCompra.map((estatusOrdnCompra) => {
                                                    return (
                                                        <>
                                                        <option value={estatusOrdnCompra.idCatEstatusOrdenCompra}>
                                                            {estatusOrdnCompra.estatusOrdencompra}
                                                        </option>
                                                        </>
                                                    )
                                                    })
                                                }
                                            </select>
                                        </Col>
                                    </Row> */}
                </Col>

                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Fecha Final</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="date"
                        name="fechaFinal"
                        disabled={!filtrosPagosOrdenCompra.fechaInicial ? true : false}
                        min={filtrosPagosOrdenCompra.fechaInicial}
                        onChange={onChange}
                        value={filtrosPagosOrdenCompra.fechaFinal}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Comprador</label>
                    <Col sm={7}>
                      <select
                        name="usuarioComprador"
                        className="form-control"
                        onChange={onChange}
                        value={filtrosPagosOrdenCompra.usuarioComprador}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {usuarios.map((usuario) => {
                          return (
                            <>
                              <option value={usuario.idCatUsuario}>{usuario.nombre}</option>
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Importe Máximo</label>
                    <Col sm={7}>
                      <input
                        type="text"
                        className="form-control"
                        name="importeMaximo"
                        value={filtrosPagosOrdenCompra.importeMaximo ? `$${filtrosPagosOrdenCompra.importeMaximo}` : `$0`}
                        onChange={onChangeImporteMaximo}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="submit"
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Aplicar Filtros
                  </button>
                  <button
                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                    type="button"
                    onClick={limpiarFiltros}
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Limpiar Filtros
                  </button>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <Row>
                  <Col>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      onClick={() => confirmActualizarFechaPago()}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}>
                      Actualizar Fecha Planeada de Pago
                    </button>

                    <button
                      hidden={
                        (usuario.authorities.includes("ROLE_COMPRAS") || usuario.authorities.includes("ROL_AUXILIAR_ADMINISTRACION")) &&
                          ( !usuario.authorities.includes("ROLE_CONTABILIDAD") || 
                           !usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                          )
                      }
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmRegistrarPago()}>
                      Registrar Pago
                    </button>
                  </Col>
                </Row>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Folio OC</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Proveedor</th>
                        <th scope="col">Tipo OC</th>
                        <th scope="col">Comprador</th>
                        <th scope="col">Estatus</th>
                        <th scope="col">
                          Importe<br></br>Total
                        </th>
                        <th scope="col">
                          Ultima<br></br>Fecha<br></br>Recepcion
                        </th>
                        <th scope="col">
                          Días de<br></br>Pago
                        </th>
                        <th scope="col">
                          Fecha<br></br>Límite de<br></br>Pago
                        </th>
                        <th scope="col">
                          Fecha<br></br>Planeada de<br></br>Pago
                        </th>
                        <th scope="col">
                          Días para<br></br>Vencimiento
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pagosOrdenCompraFiltradas
                        ? pagosOrdenCompraFiltradas.map((ordenCompra, index) => {
                            return (
                              <>
                                <tr key={ordenCompra}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      //disabled={ordenCompra.disabledCheck}
                                      onClick={() => handdleCheckedOrdenCompra(ordenCompra, !ordenCompra.checked)}
                                      checked={ordenCompra.checked}
                                    />
                                  </td>
                                  <td>{ordenCompra.folioOrdenCompra ? ordenCompra.folioOrdenCompra : ""}</td>
                                  <td>{ordenCompra.fechaOrdenCompra ? ordenCompra.fechaOrdenCompra : ""}</td>
                                  <td>{ordenCompra.catProveedores.proveedor ? ordenCompra.catProveedores.proveedor : ""}</td>
                                  <td>{ordenCompra.catTipocompra.tipoCompra ? ordenCompra.catTipocompra.tipoCompra : ""}</td>
                                  <td>{ordenCompra.usuarioComprador ? ordenCompra.usuarioComprador : ""}</td>
                                  <td>
                                    {ordenCompra.estatusordencompra ? (
                                      <div>
                                        {ordenCompra.estatusordencompra[ordenCompra.estatusordencompra.length - 1]
                                          .catEstatusordencompra.idCatEstatusOrdenCompra == 6 ||
                                        ordenCompra.estatusordencompra[ordenCompra.estatusordencompra.length - 1]
                                          .catEstatusordencompra.idCatEstatusOrdenCompra == 7 ||
                                        ordenCompra.estatusordencompra[ordenCompra.estatusordencompra.length - 1]
                                          .catEstatusordencompra.idCatEstatusOrdenCompra == 8 ? (
                                          <span
                                            className="badge badge-info"
                                            style={{
                                              height: "1.9rem",
                                              paddingTop: "0.6rem",
                                              border: "1px solid #dfdada",
                                              color: "white",
                                              fontWeight: "bold",
                                              //morado
                                              backgroundColor: "#602a96",
                                              borderRadius: "5px",
                                            }}>
                                            {
                                              ordenCompra.estatusordencompra[ordenCompra.estatusordencompra.length - 1]
                                                .catEstatusordencompra.estatusOrdencompra
                                            }
                                          </span>
                                        ) : (
                                          <span
                                            className="badge badge-info"
                                            style={{
                                              height: "1.9rem",
                                              paddingTop: "0.6rem",
                                              border: "1px solid #dfdada",
                                              color: "black",
                                              fontWeight: "bold",
                                              //verde
                                              backgroundColor: "#C1E05C",
                                              borderRadius: "5px",
                                            }}>
                                            {
                                              ordenCompra.estatusordencompra[ordenCompra.estatusordencompra.length - 1]
                                                .catEstatusordencompra.estatusOrdencompra
                                            }
                                          </span>
                                        )}
                                      </div>
                                    ) : null}
                                  </td>

                                  <td>{ordenCompra.importeTotal ? formatNumber(ordenCompra.importeTotal) : ""}</td>
                                  <td>{ordenCompra.fechaRecepcion ? ordenCompra.fechaRecepcion : ""}</td>
                                  <td>{ordenCompra.catProveedores.diasPago ? ordenCompra.catProveedores.diasPago : "0"}</td>
                                  <td>{ordenCompra.fechaLimitePago ? ordenCompra.fechaLimitePago : ""}</td>
                                  <td>{ordenCompra.fechaPlaneadaPago ? ordenCompra.fechaPlaneadaPago : "No planeada"}</td>
                                  <td>{ordenCompra.diasVencimiento ? ordenCompra.diasVencimiento : ""}</td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {UISweetAlert.confirm_alert_registro_pago ? (
          <SweetAlert
            title="Registro de Pago"
            showCancel
            showConfirm
            confirmBtnText="Confirmar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            style={{
              width: "45%",
            }}
            onConfirm={() => {
              registrarNuevoPago();
            }}
            onCancel={() => {
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_registro_pago: false,
              });
              setNuevoRegistroPago({
                fechaDeposito: new Date().toISOString().slice(0, 10),
              });
            }}>
            <div>
              <Row className="form-group mt-4">
                <label className="col-sm-4 col-form-label">Fecha Pago</label>
                <Col sm={7}>
                  <input
                    type="date"
                    className="form-control"
                    name="fechaDeposito"
                    onChange={changeNuevoRegistroPago}
                    value={nuevoRegistroPago.fechaDeposito ? nuevoRegistroPago.fechaDeposito : ""}
                  />
                </Col>
              </Row>
              <Row className="form-group mt-2">
                <label className="col-sm-4 col-form-label">Importe Total a Pagar</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    disabled={true}
                    value={nuevoRegistroPago.importeTotal ? formatNumber(nuevoRegistroPago.importeTotal) : "$"}
                  />
                </Col>
              </Row>
              <Row className="form-group mt-2">
                <label className="col-sm-4 col-form-label">
                  Importe<span className="text-xs text-red-700">*</span>
                </label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="importe"
                    // disabled={!UISweetAlert.editar_usuario}
                    onChange={changeNuevoRegistroPagoImporte}
                    value={nuevoRegistroPago.importe ? `$${nuevoRegistroPago.importe}` : "$"}
                  />
                  {(nuevoRegistroPago.importe === "" || nuevoRegistroPago.importe == null) && (
                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                  )}
                </Col>
              </Row>
              <Row className="form-group mt-2">
                <label className="col-sm-4 col-form-label">
                  Banco<span className="text-xs text-red-700">*</span>
                </label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="bancoDeposito"
                    // disabled={!UISweetAlert.editar_usuario}
                    onChange={changeNuevoRegistroPago}
                    value={nuevoRegistroPago.bancoDeposito ? nuevoRegistroPago.bancoDeposito : ""}
                  />
                  {(nuevoRegistroPago.bancoDeposito === "" || nuevoRegistroPago.bancoDeposito == null) && (
                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                  )}
                </Col>
              </Row>
              <Row className="form-group mt-2">
                <label className="col-sm-4 col-form-label">
                  Referencia<span className="text-xs text-red-700">*</span>
                </label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="folioDeposito"
                    // disabled={!UISweetAlert.editar_usuario}
                    onChange={changeNuevoRegistroPago}
                    value={nuevoRegistroPago.folioDeposito ? nuevoRegistroPago.folioDeposito : ""}
                  />
                  {(nuevoRegistroPago.folioDeposito === "" || nuevoRegistroPago.folioDeposito == null) && (
                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                  )}
                </Col>
              </Row>
            </div>
          </SweetAlert>
        ) : null}

        {UISweetAlert.confirm_alert_actualizar_fecha ? (
          <SweetAlert
            title="Actualizar Fecha Planeada de Pago"
            showCancel
            showConfirm
            confirmBtnText="Confirmar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            style={{
              width: "45%",
            }}
            onConfirm={() => {
              ordenCompraSeleccionada.fechaPlaneadaPago = nuevaFechaPlaneadaPago;
              actualizarFechaPago();
            }}
            onCancel={() => {
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_actualizar_fecha: false,
              });
              setNuevaFechaPlaneadaPago("");
            }}>
            <div>
              <Row className="form-group mt-4">
                <label className="col-sm-4 col-form-label">Fecha Planeada de Pago</label>
                <Col sm={7}>
                  <input
                    type="date"
                    className="form-control"
                    name="fechaPago"
                    onChange={(e) => setNuevaFechaPlaneadaPago(e.target.value)}
                    value={nuevaFechaPlaneadaPago ? nuevaFechaPlaneadaPago : ""}
                    max={ordenCompraSeleccionada.fechaLimitePago}
                  />
                </Col>
              </Row>
            </div>
          </SweetAlert>
        ) : null}

        {UISweetAlert.success_dlg ? (
          <SweetAlert
            success
            title={UISweetAlert.dynamic_title}
            onConfirm={() => {
              if (UISweetAlert.redirect) {
                props.history.push(UISweetAlert.path);
              }
              setUISweetAlert({
                ...UISweetAlert,
                success_dlg: false,
              });
            }}>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {UISweetAlert.error_dlg ? (
          <SweetAlert
            error
            title={UISweetAlert.dynamic_title}
            onConfirm={() =>
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: false,
              })
            }>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {loading ? (
          <SweetAlert title="Cargando!" showConfirm={false}>
            <Spinner />
          </SweetAlert>
        ) : null}
      </div>
    </>
  );
}
