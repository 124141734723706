import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

const RecetaMedicamento = ({
  nuevoRenglon,
  medicamentosReceta,
  changeMedicamentoLista,
  eliminarMedicamento,
  medicamentos,
  principiosActivos,
  presentaciones,
  checkImprimirReceta,
  checkGuardarReceta,
  nuevaRecetaConMedicamentos,
  changeIndicaciones,
}) => {
  return (
    <>
      <Row>
        <Col>
          <button
            className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-3"
            type="button"
            onClick={nuevoRenglon}
            style={{
              marginRight: "2.5rem",
              background: "#ff9933",
              color: "white",
              fontWeight: "bold",
            }}>
            Agregar Medicamento
          </button>

          <Card>
            <CardBody>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        Nombre<br></br>Sal<span className="text-xs text-red-700">*</span>
                      </th>
                      <th scope="col">
                        Nombre<br></br>Comercial
                        {/* <span className="text-xs text-red-700">*</span> */}
                      </th>
                      <th scope="col">
                        Presentación<span className="text-xs text-red-700">*</span>
                      </th>
                      <th scope="col">
                        Indicaciones<span className="text-xs text-red-700">*</span>
                      </th>
                      <th scope="col">Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medicamentosReceta.length > 0
                      ? medicamentosReceta.map((medicamento, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>
                                  <input
                                    type="text"
                                    autoComplete="false"
                                    name="principioActivo"
                                    id="principioActivo"
                                    onChange={(e) => changeMedicamentoLista(e, index)}
                                    list={
                                      !medicamento.paDisabled && medicamento.principioActivo
                                        ? `listaPrincipiosActivos${index}`
                                        : null
                                    }
                                    value={medicamento.principioActivo ? medicamento.principioActivo.toUpperCase() : ""}
                                    className="form-control"
                                  />
                                  <datalist id={`listaPrincipiosActivos${index}`}>
                                    {medicamentos.map((sal, index) => {
                                      return (
                                        <>
                                          <option
                                            value={sal.principioActivo ? index + " " + sal.principioActivo.toUpperCase() : null}>
                                            {sal.principioActivo.toUpperCase() +
                                              " " +
                                              sal.nombreComercial.toUpperCase() +
                                              " " +
                                              sal.presentacion.toUpperCase()}
                                          </option>
                                        </>
                                      );
                                    })}
                                  </datalist>
                                  {(medicamento.principioActivo == "" || !medicamento.principioActivo) && (
                                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    autoComplete="false"
                                    name="nombreComercial"
                                    id="nombreComercial"
                                    onChange={(e) => changeMedicamentoLista(e, index)}
                                    list={
                                      !medicamento.ncDisabled && medicamento.nombreComercial
                                        ? `listarNombresComerciales${index}`
                                        : null
                                    }
                                    value={medicamento.nombreComercial ? medicamento.nombreComercial.toUpperCase() : ""}
                                    className="form-control"
                                  />
                                  <datalist id={`listarNombresComerciales${index}`}>
                                    {medicamentos.map((med, index) => {
                                      return (
                                        <>
                                          <option
                                            value={med.nombreComercial ? index + " " + med.nombreComercial.toUpperCase() : null}>
                                            {med.nombreComercial.toUpperCase() +
                                              " " +
                                              med.principioActivo.toUpperCase() +
                                              " " +
                                              med.presentacion.toUpperCase()}
                                          </option>
                                        </>
                                      );
                                    })}
                                  </datalist>
                                  {/* {(medicamento.nombreComercial == "" || !medicamento.nombreComercial) && (
                                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                  )} */}
                                </td>

                                <td>
                                  <input
                                    type="text"
                                    autoComplete="false"
                                    name="presentacion"
                                    id="presentacion"
                                    onChange={(e) => changeMedicamentoLista(e, index)}
                                    list={
                                      !medicamento.presentacionDisabled && medicamento.presentacion
                                        ? `listarPresentaciones${index}`
                                        : null
                                    }
                                    value={medicamento.presentacion ? medicamento.presentacion.toUpperCase() : ""}
                                    className="form-control"
                                  />
                                  <datalist id={`listarPresentaciones${index}`}>
                                    {presentaciones.map((presentacion) => {
                                      return (
                                        <>
                                          {/* <input value={med.idRecetaProducto} onChange={() => console.log(med.idRecetaProducto)} /> */}
                                          <option value={presentacion ? presentacion.toUpperCase() : null}>
                                            {/* {med.nombreComercial.toUpperCase() +
                                              " " +
                                              med.principioActivo.toUpperCase() +
                                              " " +
                                              med.presentacion.toUpperCase()} */}
                                          </option>
                                        </>
                                      );
                                    })}
                                  </datalist>
                                  {(medicamento.presentacion == "" || !medicamento.presentacion) && (
                                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                  )}
                                </td>
                                <td>
                                  <textarea
                                    name="indicaciones"
                                    onChange={(e) => changeMedicamentoLista(e, index)}
                                    value={medicamento.indicaciones}
                                    className="form-control"
                                  />
                                  {(medicamento.indicaciones == "" || !medicamento.indicaciones) && (
                                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                                  )}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                    type="button"
                                    style={{
                                      background: "#ff9933",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => eliminarMedicamento(index)}>
                                    Eliminar
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : null}
                  </tbody>
                  {(medicamentosReceta === null || medicamentosReceta.length === 0) && (
                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                  )}
                </table>
                <label className="col-sm-5 col-form-label mt-3">
                  Indicaciones Adicionales<span className="text-xs text-red-700">*</span>
                </label>
                <Col sm={7}>
                  <textarea
                    className="form-control"
                    name="indicaciones"
                    onChange={(e) => changeIndicaciones(e)}
                    value={nuevaRecetaConMedicamentos.indicaciones ? nuevaRecetaConMedicamentos.indicaciones : ""}
                  />
                  {(nuevaRecetaConMedicamentos.indicaciones == "" || !nuevaRecetaConMedicamentos.indicaciones) && (
                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                  )}
                </Col>
              </div>
              <Col sm={7}>
                <button
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="button"
                  style={{
                    marginRight: "2.5rem",
                    marginTop: "1rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  onClick={checkGuardarReceta}>
                  Guardar
                </button>
                <button
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="button"
                  style={{
                    marginRight: "2.5rem",
                    marginTop: "1rem",
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  onClick={checkImprimirReceta}>
                  Imprimir
                </button>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
// onChange,
// onClick,
// onKeyDown,
// showSuggestions,
// filteredSuggestions,
// nombreComercial,
// activeSuggestion,
// let suggestionsListComponent;

// if (showSuggestions && nombreComercial) {
//   if (filteredSuggestions.length) {
//     suggestionsListComponent = (
//       <ul class="suggestions">
//         {filteredSuggestions.map((suggestion, index) => {
//           let className;

//           // Flag the active suggestion with a class
//           if (index === activeSuggestion) {
//             className = "suggestion-active";
//           }

//           return (
//             <li className={className} key={suggestion.idRecetaProducto} onClick={(e) => onClick(e, suggestion.idRecetaProducto)}>
//               {suggestion.nombreComercial}
//             </li>
//           );
//         })}
//       </ul>
//     );
//   }
// }

/* <td>
                                  <input
                                    // disabled={medicamento.ncDisabled}
                                    type="text"
                                    name="nombreComercial"
                                    id="nombreComercial"
                                    onChange={onChange}
                                    onKeyDown={onKeyDown}
                                    // list={!medicamento.ncDisabled ? `listarNombresComerciales${index}` : null}
                                    value={nombreComercial}
                                    className="form-control"
                                  />
                                  {suggestionsListComponent}
                                </td> */

export default RecetaMedicamento;
