import React, { useReducer } from "react";

import CatTipoServiciosContext from "./CatTipoServiciosContext";
import CatTiposServiciosReducer from "./CatTiposServiciosReducer";

import { LISTAR_CAT_TIPO_SERVICIOS, LISTAR_PRECIOS_SERVICIOS } from "../../types";

import clienteAxios from "../../config/axios";

export default function CatTipoServiciosState(props) {
  const initialState = {
    tipoServicios: [],
    preciosServicio: null,
  };

  const [state, dispatch] = useReducer(CatTiposServiciosReducer, initialState);

  const listarCatTipoServicios = async () => {
    const res = await clienteAxios.get("/tiposervicios/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CAT_TIPO_SERVICIOS,
      payload: res.data,
    });
  };

  const listarPreciosServicio = async (idCatServicio) => {
    try {
      const responsePreciosAseguradora = await clienteAxios.get(
        `/productoservicio/precios/servicio/aseguradora?id=${idCatServicio}`,

        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const responsePreciosProductoServicio = await clienteAxios.get(
        `/productoservicio/precios/servicio?id=${idCatServicio}`,

        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const precios = {
        preciosAseguradora: responsePreciosAseguradora.data,
        preciosProductoServicio: responsePreciosProductoServicio.data[0],
      };

      dispatch({
        type: LISTAR_PRECIOS_SERVICIOS,
        payload: precios,
      });

      return precios;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <CatTipoServiciosContext.Provider
      value={{
        tipoServicios: state.tipoServicios,
        preciosServicio: state.preciosServicio,
        listarCatTipoServicios,
        listarPreciosServicio,
      }}>
      {props.children}
    </CatTipoServiciosContext.Provider>
  );
}
