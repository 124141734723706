import {
    HISTORIA_CLINICA_INICIAL,
} from "../../types";
  
export default (state, action) => {
    switch (action.type) {
        case HISTORIA_CLINICA_INICIAL:
            return {
                ...state,
                historiaClinicaInicial: action.payload,
            };
        default:
            return state;
    }
};
  