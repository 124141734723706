/* eslint-disable import/no-anonymous-default-export */
import { LISTAR_CAT_TIPO_SERVICIOS, LISTAR_PRECIOS_SERVICIOS } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_CAT_TIPO_SERVICIOS:
      return {
        ...state,
        tipoServicios: action.payload,
      };
    case LISTAR_PRECIOS_SERVICIOS:
      return {
        ...state,
        preciosServicio: action.payload,
      };

    default:
      return state;
  }
};
