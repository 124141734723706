import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Card, CardBody } from "reactstrap";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// import AuthContext from "../../context/Auth/AuthContext";
import CentroMezclasContext from "../../context/CentroMezclas/CentroMezclasContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import ListaMedicamentos from "./ListaMedicamentos";

const InventarioCentroMezclas = () => {
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [pageCountRedFria, setPageCountRedFria] = useState(0);

  const [controlledPageIndex, setControlledPageIndex] = useState(0);

  const fetchIdRef = useRef(0);

  const [tabIndex, setTabIndex] = useState(0);

  const centroMezclasContext = useContext(CentroMezclasContext);
  const {
    filtrosMedicamentosCM,
    medicamentosInventarioCMRedFria,
    medicamentosInventarioCM,
    movimientosCM,
    movimientosRedFria,
    setFiltrosMedicamentosCM,
    setDataMedicamentosInventario,
  } = centroMezclasContext;

  const catProductosContext = useContext(CatProductosContext);
  const { listarMedicamentos, catProductos } = catProductosContext;

  function handleDates(e, setMaxDateFechaFinal, setFiltrosMedicamentosCM, filtrosMedicamentosCM) {
    let dateFecha = e.target.value;
    let date = new Date(dateFecha);
    date.setDate(date.getDate() + 7);
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let mm = date.getMonth() + 1;
    mm = mm < 10 ? "0" + mm : mm;
    let y = date.getFullYear();
    const formatDate = y + "-" + mm + "-" + dd;

    setMaxDateFechaFinal(formatDate);
    setFiltrosMedicamentosCM({
      ...filtrosMedicamentosCM,
      [e.target.name]: e.target.value,
      fechaFinal: e.target.value,
    });
  }

  useEffect(() => {
    // if (catProductos.length <= 0) return listarMedicamentos();

    const fetchMedicamentos = () => {
      listarMedicamentos();
    };

    // const today = getDate();
    // handleDates({ target: { value: today, name: "Fecha Inicial" } }, setMaxDateFechaFinal, setFiltrosMedicamentosCM, filtrosMedicamentosCM);

    fetchMedicamentos();
  }, []);

  const [maxDateFechaFinal, setMaxDateFechaFinal] = useState(null);

  // const getTodayDate = () => {
  //   const newDate = new Date();
  //   const year = newDate.getFullYear();
  //   const month = newDate.getMonth() + 1;
  //   const d = newDate.getDate();

  //   // return `${month.toString().padStart(2, "0")}/${d.toString().padStart(2, "0")}/${year}`;
  //   return `${year}-${month.toString().padStart(2, "0")}-${d.toString().padStart(2, "0")}`;
  // };

  // const maxDate = () => {
  //   let dateFecha = getTodayDate();
  //   let date = new Date(dateFecha);
  //   date.setDate(date.getDate() + 7);
  //   let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  //   let mm = date.getMonth() + 1;
  //   mm = mm < 10 ? "0" + mm : mm;
  //   let y = date.getFullYear();
  //   const formatDate = y + "-" + mm + "-" + dd;
  //   return formatDate;
  // };

  const filtrosVacios = {
    // fechaInicial: "",
    // fechaFinal: "",
    ...filtrosMedicamentosCM,
    idCatProducto: "",
    principioActivo: null,
    caducidad: null,
    lote: null,
  };

  const today = new Date();
  const tomorrow = new Date();

  // Add 1 Day
  tomorrow.setDate(today.getDate() + 1);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      if (!byBusquedaFiltros) {
        setControlledPageIndex(page - 1);
      } else {
        setControlledPageIndex(0);
      }
      const response = await setDataMedicamentosInventario({ page, limit });

      const countRedFria = response.countRedFria;
      const count = response.countCM;

      // setMovimientosCM({ entradas: response.movimientosCM.entradas, salidas: response.movimientosCM.salidas });
      // setMovimientosRedFria({ entradas: response.movimientosRedFria.entradas, salidas: response.movimientosRedFria.salidas });

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }
      if (countRedFria % limit > 0) {
        setPageCountRedFria(Math.ceil(countRedFria / limit));
      } else {
        setPageCountRedFria(countRedFria / limit);
      }
      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const columns = useMemo(
    () => [
      { Header: "Medicamento", accessor: "inventarioVo.nombreComercial", show: true },
      {
        Header: () => (
          <>
            Principio<br></br>Activo
          </>
        ),
        accessor: "inventarioVo.principioActivo",
        show: true,
      },
      // { Header: "Caducidad", accessor: "inventarioVo.caducidad", show: true },
      // { Header: "Lote", accessor: "inventarioVo.lote", show: true },
      { Header: "Reconstituido", accessor: "inventarioVo.reconstituido", show: true },
      { Header: "Diluido", accessor: "inventarioVo.diluido", show: true },
      {
        Header: "Fotosensible",
        accessor: "fotosensible",
        Cell: ({ row }) => {
          return (
            <input
              disabled={true}
              checked={row.original.inventarioVo.fotosensible ? row.original.inventarioVo.fotosensible : false}
              type="checkbox"
            />
          );
        },
      },
      {
        Header: () => (
          <>
            Fotosensible<br></br>Almacenamiento
          </>
        ),
        accessor: "fotosensibleAlmacenamiento",
        Cell: ({ row }) => {
          return (
            <input
              disabled={true}
              checked={
                row.original.inventarioVo.fotosensibleAlmacenamiento
                  ? row.original.inventarioVo.fotosensibleAlmacenamiento
                  : false
              }
              type="checkbox"
            />
          );
        },
      },
      {
        Header: () => (
          <>
            Fotosensible<br></br>Aplicación
          </>
        ),
        accessor: "fotosensibleAplicacion",
        Cell: ({ row }) => {
          return (
            <input
              disabled={true}
              checked={
                row.original.inventarioVo.fotosensibleAplicacion ? row.original.inventarioVo.fotosensibleAplicacion : false
              }
              type="checkbox"
            />
          );
        },
      },
      // {
      //   Header: () => (
      //     <>
      //       Alto<br></br>Riesgo
      //     </>
      //   ),
      //   accessor: "altoRiesgo",
      //   Cell: ({ row }) => {
      //     return (
      //       <input
      //         disabled={true}
      //         checked={row.original.inventarioVo.altoRiesgo ? row.original.inventarioVo.altoRiesgo : false}
      //         type="checkbox"
      //       />
      //     );
      //   },
      // },
      // {
      //   Header: "Controlado",
      //   accessor: "controlado",
      //   Cell: ({ row }) => {
      //     return (
      //       <input
      //         disabled={true}
      //         checked={row.original.inventarioVo.controlado ? row.original.inventarioVo.controlado : false}
      //         type="checkbox"
      //       />
      //     );
      //   },
      // },
      {
        Header: () => (
          <>
            No<br></br>Agitar
          </>
        ),
        accessor: "noAgitar",
        Cell: ({ row }) => {
          return (
            <input
              disabled={true}
              checked={row.original.inventarioVo.noAgitar ? row.original.inventarioVo.noAgitar : false}
              type="checkbox"
            />
          );
        },
      },
      // {
      //   Header: "Refrigerar",
      //   accessor: "refrigerar",
      //   Cell: ({ row }) => {
      //     return (
      //       <input
      //         disabled={true}
      //         checked={row.original.inventarioVo.refrigerar ? row.original.inventarioVo.refrigerar : false}
      //         type="checkbox"
      //       />
      //     );
      //   },
      // },
      { Header: "Entradas", accessor: "entradas", show: true },
      { Header: "Salidas", accessor: "salidas", show: true },
      { Header: "Total", accessor: "total", show: true },
    ],
    []
  );

  const limpiarFiltros = () => {
    setFiltrosMedicamentosCM(filtrosVacios);
  };

  const buscarPorFiltros = (e) => {
    e.preventDefault();
    setFiltrosMedicamentosCM(filtrosMedicamentosCM);
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const onChange = (e) => {
    if (e.target.name === "fechaInicial") {
      handleDates(e, setMaxDateFechaFinal, setFiltrosMedicamentosCM, filtrosMedicamentosCM);
    } else {
      setFiltrosMedicamentosCM({
        ...filtrosMedicamentosCM,
        [e.target.name]: e.target.value,
      });
    }
  };

  const medicamentos = catProductos.map(({ idCatProducto, nombreComercial, ...medicamento }) => ({
    ...medicamento,
    id: idCatProducto,
    nombre: nombreComercial,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Fecha Inicial",
          type: "date",
          name: "fechaInicial",
          disabled: false,
          value: filtrosMedicamentosCM.fechaInicial,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Medicamento",
          name: "idCatProducto",
          value: filtrosMedicamentosCM.idCatProducto,
          opciones: medicamentos,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Principio Activo",
          type: "text",
          name: "principioActivo",
          disabled: false,
          value: filtrosMedicamentosCM.principioActivo ? filtrosMedicamentosCM.principioActivo : "",
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Fecha Final",
          type: "date",
          name: "fechaFinal",
          disabled: !filtrosMedicamentosCM.fechaInicial ? true : false,
          min: filtrosMedicamentosCM.fechaInicial,
          max: maxDateFechaFinal,
          value: filtrosMedicamentosCM.fechaFinal,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Caducidad",
          type: "date",
          name: "caducidad",
          disabled: false,
          value: filtrosMedicamentosCM.caducidad,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Lote",
          type: "text",
          name: "lote",
          disabled: false,
          value: filtrosMedicamentosCM.lote ? filtrosMedicamentosCM.lote : "",
          onChange: onChange,
        },
      ],
    },
  ];
  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Inventario de Centro de Mezclas</h3>

      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Medicamentos</h4>
      <Card className="mt-4">
        <CardBody>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              {
                <>
                  <Tab>{"Temperatura Ambiente"}</Tab>
                  <Tab>{"Red Fría"}</Tab>
                </>
              }
            </TabList>
            <>
              <TabPanel>
                {medicamentosInventarioCM ? (
                  <ListaMedicamentos
                    pageCount={pageCount}
                    fetchData={medicamentosInventarioCM.length === 0 ? fetchData : null}
                    columns={columns}
                    loading={loadingData}
                    data={medicamentosInventarioCM}
                    indexOfPage={controlledPageIndex}
                    entradas={movimientosCM.entradas}
                    salidas={movimientosCM.salidas}
                  />
                ) : (
                  <h1>No hay medicamentos a Temperatura Ambiente</h1>
                )}
              </TabPanel>
              <TabPanel>
                {medicamentosInventarioCMRedFria ? (
                  <ListaMedicamentos
                    pageCount={pageCountRedFria}
                    fetchData={medicamentosInventarioCMRedFria.length === 0 ? fetchData : null}
                    columns={columns}
                    loading={loadingData}
                    data={medicamentosInventarioCMRedFria}
                    indexOfPage={controlledPageIndex}
                    entradas={movimientosRedFria.entradas}
                    salidas={movimientosRedFria.salidas}
                  />
                ) : (
                  <h1>No hay medicamentos de Red Fría</h1>
                )}
              </TabPanel>
            </>
          </Tabs>
        </CardBody>
      </Card>
    </div>
  );
};

export default InventarioCentroMezclas;
