/* eslint-disable import/no-anonymous-default-export */
import { LISTAR_SEXOS } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_SEXOS:
      return {
        ...state,
        sexos: action.payload,
      };

    default:
      return state;
  }
};
