import React, { useState,useContext, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import InventarioContext from "../../context/Inventario/InventarioContext";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import AuthContext from "../../context/Auth/AuthContext";
import formatDateWithoutHour from "../../utils/formatDateWithoutHour";

export default function InformacionGeneralProductoInventario(props) {

  const inventarioCtx = useContext(InventarioContext);
  const almacenesCtx = useContext(AlmacenesContext);
  const {almacenes, listarAlmacenes} = almacenesCtx;
  const authContext = useContext(AuthContext);
  const {usuario} = authContext;
  const { inventarioSeleccionado } = inventarioCtx;

  const administrador =
  usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
  usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
  usuario.authorities.includes("ROLE_DIRECCION");  
 


  const AlmacenCMZ = usuario.authorities.includes("ROLE_ADMIN_ALMACEN");

  const [almacenOrigen, setAlmacenOrigen] = useState("")
  const [almacenOrigenId, setAlmacenOrigenId] = useState("")
 
  const habilitarCombo  = props.opcion

  const onChange = (e) => {
    inventarioSeleccionado.idCatAlmacenDestino = parseInt(e.target.value, 10);
    inventarioSeleccionado.destino =  e.target.value
    //almacenes.map((almacen) => 
    /*{
        if( almacen.idCatAlmacen == e.target.value ) //COMPARA EL NOMBRE SELECCIONADO CON LA LISTA DE ALMACENES
        {
          inventarioSeleccionado.almacen = almacen.almacen;// LO ENCONTRO Y ASIGNA EL ID DEL RESPONSABLE
          inventarioSeleccionado.idCatAlmacen = e.target.value
          return;
        }
    })*/
    
    //alert(inventarioSeleccionado.almacen);
  }
  
  useEffect(() => {
                   setAlmacenOrigen(inventarioSeleccionado.almacen);
                   setAlmacenOrigenId(inventarioSeleccionado.idCatAlmacen);
                  }
                  ,[inventarioSeleccionado]
           );

   return (
    <Card>
      <CardBody>
        <Row>
          <Col xl={6} lg={12} md={12}>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Tipo Producto</label>
              <Col sm={7}>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={inventarioSeleccionado.tipoProducto ? inventarioSeleccionado.tipoProducto : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Identificación</label>
              <Col sm={7}>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={inventarioSeleccionado.numIdentificacion ? inventarioSeleccionado.numIdentificacion : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Código EAN</label>
              <Col sm={7}>
                <input
                  disabled={true}
                  type="text"
                  className="form-control"
                  value={inventarioSeleccionado.codigoEAN ? inventarioSeleccionado.codigoEAN : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Descripción</label>
              <Col sm={7}>
                <input
                  disabled={true}
                  type="text"
                  className="form-control"
                  value={inventarioSeleccionado.nombreComercial ? inventarioSeleccionado.nombreComercial : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Principio Activo</label>
              <Col sm={7}>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={inventarioSeleccionado.principioActivo ? inventarioSeleccionado.principioActivo : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Piezas Totales</label>
              <Col sm={7}>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={inventarioSeleccionado.piezas ? inventarioSeleccionado.piezas : ""}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={6} lg={12} md={12}>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Almacén Origen</label>
              <Col sm={7}>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={almacenOrigen}
                />                
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Almacén Destino</label>
              <Col sm={7}>
                <select
                    className="form-control"
                    name="idCatAlmacen"
                    disabled= {habilitarCombo}
                    onChange={onChange}
                    //value={inventarioSeleccionado.idCatAlmacen}
                    //value={inventarioSeleccionado.idCatAlmacen}
                    //onChange={ (almacen) => inventarioSeleccionado.almacen =almacen.almacen}
                >
                    <option value="">
                      Seleccione una opción
                    </option>                  
                    {almacenes.map((almacen) => { 
                        //alert(almacenOrigen + "   id: " + almacenOrigenId)
                        let IdAlmacenTemp = almacen.idCatAlmacen; //TRAEMOS EL NOMBRE DEL ALMACEN

                        if(!administrador && IdAlmacenTemp != 3 &&  IdAlmacenTemp != almacenOrigenId 
                          && !habilitarCombo){
                        return (
                            <>
                                <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                            </>
                        );
                        }else
                        {  
                           if(administrador && IdAlmacenTemp != almacenOrigenId)
                           {
                              return (
                                <>
                                    <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                                </>
                              );
                           }
                        }
                    })}
                </select>

              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Proveedor</label>
              <Col sm={7}>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={inventarioSeleccionado.proveedor ? inventarioSeleccionado.proveedor : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Fabricante</label>
              <Col sm={7}>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={inventarioSeleccionado.laboratorio ? inventarioSeleccionado.laboratorio : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Lote</label>
              <Col sm={7}>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={inventarioSeleccionado.lote ? inventarioSeleccionado.lote : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Caducidad</label>
              <Col sm={7}>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={inventarioSeleccionado.caducidad ? formatDateWithoutHour(inventarioSeleccionado.caducidad) : ""}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
                        //let IdResponsableTemp; // VARIABLE DONDE GUARDAREMOS EL ID DEL USUARIO RESPONSABLE DEL ALMACEN SELECCIONADO
                        //let idCatUsuarioTemp; //VARIABLE DONDE GUADAREMOS EL ID DEL USUARIO LOGUEADO ANTE EL PORTAL
                        //idCatUsuarioTemp = usuario.idCatUsuario;
              
                        // YA QUE SABEMOS EL NOMBRE DEL ALMACEN VAMOS A TRAER EL ID DEL ALMACEN Y SU RESPONSABLE
                        // almacenes.map((almacen2) => 
                        //{
                        //   if( almacen2.idCatAlmacen == IdAlmacenTemp ) //COMPARA EL NOMBRE SELECCIONADO CON LA LISTA DE ALMACENES
                        //   {
                        //       IdResponsableTemp = almacen2.idResponsable;// LO ENCONTRO Y ASIGNA EL ID DEL RESPONSABLE
                        //   }
                        //})
