import React, {useState, Fragment} from "react";
import "react-tabs/style/react-tabs.css";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import {useTable, usePagination} from "react-table";
import formatDateWithoutHour from "../../../utils/formatDateWithoutHour";

function Table({columns, data}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0},
        },
        usePagination
    );

    // Render the UI for your table
    return (
        <Fragment>
            <div>
                <div className="table-responsive mt-4">
                    <select
                        className="form-control mt-3 mb-4"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        style={{width: "120px", height: "35px"}}>
                        {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Mostrar {pageSize}
                            </option>
                        ))}
                    </select>
                    <table className="table table-hover table-centered table-nowrap mb-0 " {...getTableProps()}>
                        <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                ))}
                            </tr>
                        ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                    <ul className="pagination mt-4">
                        <li className="page-item">
                            <button className="border text-grey-300 font-semibold py-2 px-4 rounded-left disabled:opacity-50" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                Primera
                            </button>
                        </li>
                        <li className="page-item">
                            <button className="border text-grey-300 font-semibold py-2 px-4 disabled:opacity-50" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {"<"}
                            </button>
                        </li>
                        <li className="page-item">
                            <button className="border text-grey-300 font-semibold py-2 px-4 disabled:opacity-50" onClick={() => nextPage()} disabled={!canNextPage}>
                                {">"}
                            </button>
                        </li>
                        <li className="page-item">
                            <button className="border text-grey-300 font-semibold py-2 px-4 disabled:opacity-50" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                Última
                            </button>
                        </li>
                        <li>
                            <button className="border text-grey-300 font-semibold py-2 px-4 rounded-right disabled:opacity-50">
                                Página{" "}
                                <strong>
                                    {pageIndex + 1} de {pageOptions.length}
                                </strong>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
}

function ListaRegistros(props) {
    const [tabIndex, setTabIndex] = useState(0);
    const columnsEntradas = React.useMemo(
        () => [
            {Header: "Medicamento", accessor: "inventarioVo.nombreComercial", show: true},
            {
                Header: () => (
                    <>
                        Principio<br></br>Activo
                    </>
                ),
                accessor: "inventarioVo.principioActivo",
                show: true,
            },
            {
                Header: () => (
                    <>
                        Caducidad
                    </>
                ),
                accessor: "inventarioVo.caducidad",
                Cell: ({row}) => {
                    return <>{row.original.inventarioVo.caducidad ? formatDateWithoutHour(row.original.inventarioVo.caducidad) : ""}</>;
                },
            },
            // { Header: "Caducidad", accessor: "inventarioVo.caducidad", show: true },
            {Header: "Lote", accessor: "inventarioVo.lote", show: true},
            {Header: "Fecha", accessor: "fechaMovimiento", show: true},
            {Header: "Vale", accessor: "valesVo.folioVale", show: true},
            {Header: "Cantidad", accessor: "piezas", show: true},
        ],
        []
    );
    const columnsSalidas = React.useMemo(
        () => [
            {Header: "Medicamento", accessor: "inventarioVo.nombreComercial", show: true},
            {
                Header: () => (
                    <>
                        Principio<br></br>Activo
                    </>
                ),
                accessor: "inventarioVo.principioActivo",
                show: true,
            },
            {
                Header: () => (
                    <>
                        Caducidad
                    </>
                ),
                accessor: "inventarioVo.caducidad",
                Cell: ({row}) => {
                    return <>{row.original.inventarioVo.caducidad ? formatDateWithoutHour(row.original.inventarioVo.caducidad) : ""}</>;
                },
            },
            // {Header: "Caducidad", accessor: "inventarioVo.caducidad", show: true},
            {Header: "Lote", accessor: "inventarioVo.lote", show: true},
            {Header: "Fecha", accessor: "fechaMovimiento", show: true},
            {Header: "Vale", accessor: "valesVo.folioVale", show: true},
            {Header: "Movimiento", accessor: "catTipomovimientoVo.clave", show: true},
            {Header: "Cantidad", accessor: "piezas", show: true},
        ],
        []
    );

    return (
        <div className="pt-5">
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                    {
                        <>
                            <Tab>{"Entradas"}</Tab>
                            <Tab>{"Salidas"}</Tab>
                        </>
                    }
                </TabList>
                <>
                    <TabPanel>{props.entradas ? <Table columns={columnsEntradas} data={props.entradas}/> : <h1>No hay Entradas</h1>}</TabPanel>
                    <TabPanel>{props.salidas ? <Table columns={columnsSalidas} data={props.salidas}/> : <h1>No hay salidas</h1>}</TabPanel>
                </>
            </Tabs>
        </div>
    );
}

export default ListaRegistros;
