import {
    LISTAR_ALMACENES,
    LISTAR_ANAQUELES,
    LISTAR_ESTANTES,
    LISTAR_UBICACIONES,

} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_ALMACENES:
            return{
                ...state, 
                almacenes: action.payload
            }
        case LISTAR_ANAQUELES: 
            return{
                ...state, 
                anaqueles: action.payload.anaqueles,
                almacenSeleccionado: {
                    ...action.payload,
                    anaqueles: null
                }
            }
        case LISTAR_ESTANTES: 
            return{
                ...state, 
                estantes: action.payload.estantes,
                anaquelSeleccionado: {
                    ...action.payload,
                    estantes: null
                }
            }
        case LISTAR_UBICACIONES: 
            return{
                ...state, 
                ubicaciones: action.payload.ubicaciones,
                estanteSeleccionado: {
                    ...action.payload,
                    ubicaciones: null
                }
            }
        default:
            return state
    }
        
}