import React, {useState, useContext, useEffect, useRef} from "react";
import ChartLayout from "../ChartLayout/ChartLayout";
import DashboardContext from "../../context/Dashboard/DashboardContext";
import { lightGreen } from "@material-ui/core/colors";
import { Bar, Title } from "react-chartjs-2";
import CustomSpinner from "../UI/customSpinner";
import {barChartOptions} from "../../config/chartOptions";
import BarChart from "../Charts/BarChart";
import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";
import formatNumber from "../../utils";

const InformeFarmacia = () => {

    const dashboardContext = useContext(DashboardContext);
    const { 
            getVentasMedicamentos,obtenerProductosInventario, obtenerProductosTotales
    } = dashboardContext;

    const ctxProductosServicios = useContext(ProductosServiciosContext);
    const { productosServicios, listarProductosPersonal } = ctxProductosServicios;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    //TotalGenerales
    const tituloLayoutCaducidadMedicamentos= "Tabla de caducidades próximas a vencer MEDICAMENTOS - ";
    const [tituloCaducidadMedicamentos, setTituloCaducidadMedicamentos] = useState(tituloLayoutCaducidadMedicamentos + currentYear);  

    const tituloLayoutCaducidadInsumos= "Tabla de caducidades próximas a vencer INSUMOS - ";
    const [tituloCaducidadInsumos, setTituloCaducidadInsumos] = useState(tituloLayoutCaducidadInsumos + currentYear);     
    
    const tituloLayoutVentasMostrador= "Ventas Mostrador - ";
    const [tituloVentasMostrador, setTituloVentasMostrador] = useState(tituloLayoutVentasMostrador + currentYear); 
    
    const tituloLayoutTotalGenerales= "Total Inventarios";
    const [tituloTotalGenerales, setTituloTotalGenerales] = useState(tituloLayoutTotalGenerales); 
    //*************************************************************************** */
    
    const [dataVentasMostrador, setDataVentasMostrador] = useState({datasets: []});
    const [dataCaducidadMedicamentos, setDataCaducidadMedicamentos] = useState({datasets: []});
    const [dataCaducidadInsumos, setDataCaducidadInsumos] = useState({datasets: []});  
    const [dataTotalGenerales, setDataTotalGenerales] = useState({datasets: []});    

    const [filtrosVentasMostrador, setFiltrosVentasMostrador] = useState({anio: currentYear, fullDate: new Date(), mes: null})
    const [filtrosCaducidadMedicamentos, setFiltrosCaducidadMedicamentos] = useState({anio: currentYear, fullDate: new Date(), mes: null})
    const [filtrosCaducidadInsumos, setFiltrosCaducidadInsumos] = useState({anio: currentYear, fullDate: new Date(), mes: null})   
    const [filtrosTotalGenerales, setFiltrosTotalGenerales] = useState({anio: currentYear, fullDate: new Date(), mes: null})       

    const refGraficaVentasMostrador  = useRef(null);  
    const refGraficaCaducidadMedicamentos  = useRef(null);  
    const refGraficaCaducidadInsumos  = useRef(null);    
    const refGraficaTotalGenerales  = useRef(null);        
    
    const chartLayoutVentasMostrador = {extraInfo: true,withFilters: true};
    const chartLayoutCaducidadMedicamentos = {extraInfo: true,withFilters: true};
    const chartLayoutCaducidadInsumos = {extraInfo: true,withFilters: true};    
    const chartLayoutTotalGenerales = {extraInfo: true,withFilters: true};      

    const [loadingVentasMostrador, setLoadingVentasMostrador] = useState(false);  
    const [loadingCaducidadMedicamentos, setLoadingCaducidadMedicamentos] = useState(false);   
    const [loadingCaducidadInsumos, setLoadingCaducidadInsumos] = useState(false);   
    const [loadingTotalGenerales, setLoadingTotalGenerales] = useState(false);                     
        //
    const [cantidadProveedores, setCantidadProveedores] = useState(0);
    const [listaMedicinasImprimir, setListaMedicinasImprimir] = useState([]);
    const [listaMedicinasImprimir2, setListaMedicinasImprimir2] = useState([]);

    const [listaTotalGenerales, setListaTotalGenerales] = useState([]);

const exportChart = (chartRef) => {
    if (chartRef.current) {
        const canvas = chartRef.current.canvas; // Access the canvas directly
        canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'grafica.png'; // You can specify the filename here
            link.click();
        });
    } else {
        console.error("Chart ref is null or undefined.");
    }
};

function assignRandomColorsToDatasetsLocal(){
    const randomComponent = () => Math.floor(Math.random() * 256);
    const r = randomComponent();
    const g = randomComponent();
    const b = randomComponent();
    return `rgb(${r}, ${g}, ${b})`;
}

const fetchAPIData = async () => {
    try {
        const response = await obtenerProductosInventario();
        let datasets = [];
        let datasets2 = [];
        response.data.inventario.map((value,index) => {

            if(value.idCatTipoProducto == 2){
                datasets.push({
                nombreComercial : value.nombreComercial,
                principoActivo : value.principioActivo,
                fechaCaducidad : value.caducidad.split("T")[0],
                            lote : value.lote,
                        piezas : value.piezas
                })
            }


            if(value.idCatTipoProducto == 1){
                datasets2.push({
                nombreComercial : value.nombreComercial,
                principoActivo : value.principioActivo,
                fechaCaducidad : value.caducidad.split("T")[0],
                            lote : value.lote,
                        piezas : value.piezas
                })
            }            
        
        });

        const response2 = await obtenerProductosTotales();

        const final = response2.data.map((value) => {
            const labelTemp = value.x;
            let retorno = {
                label : formatNumber(value.y),
                barPercentage:1,
                backgroundColor: assignRandomColorsToDatasetsLocal(), 
                borderWidth: 1,
                height:50,
                width : 50,            
                data : [{ x : value.x  , y : value.y }]
            }
            return retorno
        });
        setDataTotalGenerales({datasets: final});
        setListaMedicinasImprimir({datasets: datasets});
        setListaMedicinasImprimir2({datasets: datasets2});
        
        setDataCaducidadMedicamentos({datasets});
        setDataCaducidadInsumos({datasets : datasets2 });

        const count = response.data.count;
    } catch (e) {
        console.log("Error while fetching", e);
    }
};

  useEffect(() => {
    const fetchAllContextData = () => {
      fetchAPIData();
      //listarProductosPersonal();

    };
    fetchAllContextData();
  }, []);

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = 'Nombre Comercial,Principio Activo,Caducidad,Lote,Piezas' + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const exportTable  = () => {
        const cadena = convertToCSV(listaMedicinasImprimir.datasets);
        const csvData = new Blob([cadena], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        //link.download = `${fileName}.csv`;
        link.download = `Medicamentos.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
};

const exportTable2  = () => {
    const cadena = convertToCSV(listaMedicinasImprimir2.datasets);
    const csvData = new Blob([cadena], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    //link.download = `${fileName}.csv`;
    link.download = `Medicamentos.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


/* **************************************************************************************************************** */
/*                      SOLICITUD PARA PENDIENTES PAGO Y EL ONCHANGE DE LA GRAFICA                                */
/* **************************************************************************************************************** */     
useEffect(() => {
    const fetchVentasMostrador = async () => {
        setLoadingVentasMostrador(true)
        try{            
            let labelsTemp = [];
            let porPagarTemp = [];
            let pagadosTemp = [];
            let contador = 1;
            const resPc = await getVentasMedicamentos(filtrosVentasMostrador);
            resPc.data.map((value,index) => {

                labelsTemp.push(value.label);
                pagadosTemp.push(parseFloat(value.dato1.toFixed(2), 10));
                porPagarTemp.push(parseFloat(value.dato2.toFixed(2), 10));                    
                contador = contador + 1;
            });                
            const dataset = [{labels : labelsTemp, pagados : pagadosTemp, porPagar : porPagarTemp }]
            
            if(contador < 10){
                setCantidadProveedores(contador*70);
            }else{
                setCantidadProveedores(contador*35);
            }

            setDataVentasMostrador(dataset);
        }catch (e) {
            setDataVentasMostrador({datasets: []})
            console.log(e);
        }
        setLoadingVentasMostrador(false)
    }   
    fetchVentasMostrador();
}, [filtrosVentasMostrador]);
/* *************************************************************************************************************** */

const onChangeVentasMostrador = (e) => {
    const {name, value} = e.target;
    
    if (name === "anio") {
        let year;
        try {
            year = value.getFullYear();
            setTituloVentasMostrador(tituloLayoutVentasMostrador + year)
        } catch (error) {
        }
        setFiltrosVentasMostrador({
            ...filtrosVentasMostrador,
            fullDate: value,
            [name]: year
        });
    } else {
        setFiltrosVentasMostrador({
            ...filtrosVentasMostrador,
            [name]: value==""? "0" : value
        });
    }
}        
  
/* ********************************************************************************************
                    FUNCIONES PARA INFORMES POR DETALLES DE  MEDICINAS POR MES
******************************************************************************************** */
useEffect(() => {
    const fetchCaducidadMedicamentos = async () => {
        let eneroTemp = 0;
        let febreroTemp = 0;
        let marzoTemp = 0;
        let abrilTemp = 0;
        let mayoTemp = 0;
        setLoadingCaducidadMedicamentos(false)
        try {
            setLoadingCaducidadMedicamentos(true);
            //ESTE ES EL PROCESO PARA LLENAR LA GRAFICA DE PREPARADOS ANUAL
           // const res = await graficaCaducidadproductos(filtrosCaducidadMedicamentos);
            //setDataCaducidadMedicamentos({datasets: res.data});
           // setListaMedicinasImprimir(res.data);         
        } catch (e) {
            console.log(e);
        }  
        setLoadingCaducidadMedicamentos(false);               
    };
    fetchCaducidadMedicamentos();    
}, [filtrosCaducidadMedicamentos]);
/* *************************************************************************************************************** */
const divStyle = {
    backgroundColor: lightGreen,
    justifyContent: "center", // Centra horizontalmente
    alignItems: "center", // Centra verticalmente
  };

return (
    <>
        <div  style={divStyle}>
            <h1> Informes de Farmacia</h1>     

                <ChartLayout aseguradoras={null} 
                            selectAseguradoras={false} 
                            filtros={filtrosTotalGenerales} 
                            conAnio={false}
                            exportChart={exportChart}
                            refGrafica={refGraficaTotalGenerales} {...chartLayoutTotalGenerales}>
                            {loadingTotalGenerales ? 
                                (
                                    <CustomSpinner height={"700px"} infoText={" total General"}/>
                                ) 
                                :(dataTotalGenerales.datasets[0]?.data?.length > 0 ? 
                                    (<BarChart  ref={refGraficaTotalGenerales} 
                                        title={tituloTotalGenerales} 
                                        data={dataTotalGenerales} 
                                        requiresMoneyFormat={true}/>) 
                                    : (<h3>{`${tituloTotalGenerales} SIN DATOS`}</h3>)
                                ) 
                            }
                </ChartLayout>                

                {/* ******************* ACA LA GRAFICA COMBINADA PARA PENDIENTES Y ORDENES PAAGAS ********** */}
                
                <ChartLayout aseguradoras={null} 
                                selectAseguradoras={false} 
                                filtros={filtrosVentasMostrador} 
                                conAnio={true}
                                conMes ={true}
                                onChange={onChangeVentasMostrador} 
                                exportChart={exportChart}
                                refGrafica={refGraficaVentasMostrador} {...chartLayoutVentasMostrador}>
                                {loadingVentasMostrador ? 
                                    (
                                        <CustomSpinner height={"700px"} infoText={"Facturación por aseguradora"}/>
                                    ) 
                                    :(dataVentasMostrador[0]?.labels?.length > 0 ? 
                                        (   
                                            <Bar
                                                ref={refGraficaVentasMostrador} 
                                                requiresMoneyFormat = {true}
                                                data={{
                                                    // Name of the variables on x-axies for each bar
                                                    labels: dataVentasMostrador[0].labels,
                                                    datasets: [
                                                        {
                                                            // Label for bars
                                                            label: "Pagado",
                                                            // Data or value of your each variable
                                                            data: dataVentasMostrador[0].pagados,
                                                            // Color of each bar
                                                            backgroundColor: 
                                                                ["#aed6f1", "#aed6f1", "#aed6f1", "#aed6f1"],
                                                            // Border color of each bar
                                                            borderColor: ["85c1e9", "85c1e9", "85c1e9", "85c1e9"],
                                                            borderWidth: 0.5,
                                                        },                                                                     
                                                    ],
                                                }}
                                                // Height of graph
                                                height={cantidadProveedores}                                                
                                                options={{ 
                                                    maintainAspectRatio: false,
                                                    horizontal : true,
                                                    indexAxis: 'y',
                                                    plugins: {
                                                        legend: {
                                                            display: true,
                                                            position: "bottom",
                                            
                                                        },
                                                        title: {
                                                            ...barChartOptions.plugins.title,
                                                            text: tituloVentasMostrador,
                                                            padding: {
                                                                bottom: 50,
                                                            }
                                                        },
                                                        datalabels: {
                                                            anchor: 'end',                
                                                            display: true,
                                                            align: 'right',
                                                            formatter: (value) => value !== null ? value : 0,
                                                            color: 'black',
                                                            font: {
                                                                size: 14,
                                                                //weight: 'bold'
                                                            }
                                                        },
    
                                                    },
                                                    bar: {
                                                        borderRadius: 40,
                                                        borderWidth: 0.7,
                                                    },
                                                    ticks: {
                                                        padding: 15
                                                    },
                                                    scales: {
                                                        yAxes: [
                                                            {
                                                                ticks: {
                                                            // The y-axis value will start from zero
                                                                    beginAtZero: false,
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    legend: {
                                                        labels: {
                                                            fontSize: 15,
                                                        },
                                                    },
                                                    responsive: true,
                                                    scales: {
                                                    x: {
                                                        stacked: true,
                                                    },
                                                    y: {
                                                        stacked: true
                                                    }
                                                    }                                
                                                }}
                                            />
                                            ) 
                                        : (<h3>{`${tituloVentasMostrador} SIN DATOS`}</h3>)
                                    ) 
                                }
                </ChartLayout>   

                <ChartLayout aseguradoras={null} 
                            selectAseguradoras={false} 
                            filtros={filtrosCaducidadMedicamentos} 
                            conAnio={false}
                            title={tituloCaducidadMedicamentos}
                            exportChart={exportTable}
                            refGrafica={refGraficaCaducidadMedicamentos} {...chartLayoutCaducidadMedicamentos}>
                            {loadingCaducidadMedicamentos ? 
                                (
                                    <CustomSpinner height={"700px"} infoText={" total General"}/>
                                ) 
                                :(dataCaducidadMedicamentos.datasets.length > 0 ? 
                                    (   
                                        <div align = "center" style={{width: "100%"}}>
                                            <table style={{width: "70%"}}>
                                                <tr>
                                                    <td align="center" >
                                                        <label style={{ fontSize: "20px" }} className="col-sm-15 col-form-label">
                                                        {tituloCaducidadMedicamentos}
                                                        </label>                                                       
                                                        <br></br>
                                                        <br></br>
                                                    </td>
                                                </tr>
                                            </table>
                                            <table  className="table table-hover table-centered table-nowrap mb-0">
                                                <thead>

                                                <tr>
                                                    <th scope="col">Nombre Comercial</th>
                                                    <th scope="col">Principio Activo</th>
                                                    <th scope="col">Fecha de Caducidad</th>
                                                    <th scope="col">Lote</th>
                                                    <th scope="col">Piezas</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {dataCaducidadMedicamentos? dataCaducidadMedicamentos.datasets.map((medicina) => {
                                                    return (
                                                        <tr key={medicina.medicina}>
                                                            <td style={{width: "20%"}} align="left">{medicina.nombreComercial ? medicina.nombreComercial : "0"}</td>
                                                            <td>{medicina.principoActivo ? medicina.principoActivo : "0"}</td>
                                                            <td>{medicina.fechaCaducidad ? medicina.fechaCaducidad.split("T")[0] : "0"}</td>
                                                            <td>{medicina.lote ? medicina.lote : "0"}</td>
                                                            <td>{medicina.piezas ? medicina.piezas : "0"}</td>
                                                        </tr>
                                                    );
                                                }):null}                                        
                                                </tbody>                                         
                                            </table>
                                        </div>
                                    ) 
                                    : (<h3>{`${tituloCaducidadMedicamentos} SIN DATOS`}</h3>)
                                ) 
                            }
                </ChartLayout>    

                <ChartLayout aseguradoras={null} 
                            selectAseguradoras={false} 
                            filtros={filtrosCaducidadInsumos} 
                            conAnio={false}
                            title={tituloCaducidadInsumos}
                            exportChart={exportTable2}
                            refGrafica={refGraficaCaducidadInsumos} {...chartLayoutCaducidadInsumos}>
                            {loadingCaducidadInsumos ? 
                                (
                                    <CustomSpinner height={"700px"} infoText={" total General"}/>
                                ) 
                                :(dataCaducidadInsumos.datasets.length > 0 ? 
                                    (   
                                        <div align = "center" style={{width: "100%"}}>
                                            <table style={{width: "70%"}}>
                                                <tr>
                                                    <td align="center" >
                                                        <label style={{ fontSize: "20px" }} className="col-sm-15 col-form-label">
                                                        {tituloCaducidadInsumos}
                                                        </label>                                                       
                                                        <br></br>
                                                        <br></br>
                                                    </td>
                                                </tr>
                                            </table>
                                            <table  className="table table-hover table-centered table-nowrap mb-0">
                                                <thead>

                                                <tr>
                                                    <th scope="col">Nombre Comercial</th>
                                                    <th scope="col">Fecha de Caducidad</th>
                                                    <th scope="col">Lote</th>
                                                    <th scope="col">Piezas</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {dataCaducidadInsumos? dataCaducidadInsumos.datasets.map((medicina) => {
                                                    return (
                                                        <tr key={medicina.medicina}>
                                                            <td style={{width: "20%"}} align="left">{medicina.nombreComercial ? medicina.nombreComercial : "0"}</td>
                                                            <td>{medicina.fechaCaducidad ? medicina.fechaCaducidad.split("T")[0] : "0"}</td>
                                                            <td>{medicina.lote ? medicina.lote : "0"}</td>
                                                            <td>{medicina.piezas ? medicina.piezas : "0"}</td>
                                                        </tr>
                                                    );
                                                }):null}                                        
                                                </tbody>                                         
                                            </table>
                                        </div>
                                    ) 
                                    : (<h3>{`${tituloCaducidadInsumos} SIN DATOS`}</h3>)
                                ) 
                            }
                </ChartLayout>          

                                                      
        </div>  
    </>    
)
}

export default InformeFarmacia;