import {
    LISTAR_FACTURAS,
    FILTROS_FACTURAS,
    LISTAR_MOTIVOS_CANCELACION,
} from "../../types";
  
export default (state, action) => {
    switch (action.type) {
        case LISTAR_FACTURAS:
            return {
                ...state,
                documentosContables: action.payload,
            };
        case FILTROS_FACTURAS:
            return {
                ...state,
                filtrosDocumentosContables: action.payload,
            };
        case LISTAR_MOTIVOS_CANCELACION: 
            return{
                ...state,
                motivosCancelacion: action.payload
            }
        default:
            return state;
    }
};
  