import {
  LISTAR_VENTASMOSTRADOR,
  CAMBIOS_FILTRO_VENTA_MOSTRADOR,
  LISTAR_VENTASMOSTRADOR_FILTRADO,
  CREAR_NUEVA_VENTA_MOSTRADOR,
  ERROR_CREAR_NUEVA_VENTA_MOSTRADOR,
  VENTA_MOSTRADOR_SELECCIONADO,
  ERROR_VENTA_MOSTRADOR_SELECCIONADO,
  LIMPIAR_VENTA_MOSTRADOR_SELECCIONADO,
  CANCELAR_VENTA,
  ERROR_CANCELAR_VENTA,
  ACTUALIZAR_ESTATUS_VENTAMOSTRADOR,
  NUEVO_COBRO_VENTAMOSTRADOR,
  ERROR_NUEVO_COBRO_VENTAMOSTRADOR,
  ELIMINAR_COBRO_VENTAMOSTRADOR,
  ERROR_ELIMINAR_COBRO_VENTAMOSTRADOR,
} from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case LISTAR_VENTASMOSTRADOR:
    case LISTAR_VENTASMOSTRADOR_FILTRADO:
      return {
        ...state,
        ventasMostradorFiltradas: action.payload,
      };
    case CAMBIOS_FILTRO_VENTA_MOSTRADOR:
      return {
        ...state,
        filtrosVentaMostrador: action.payload,
      };
    case CREAR_NUEVA_VENTA_MOSTRADOR:
      return {
        ...state,
        ventasMostradorFiltradas: state.ventasMostradorFiltradas.concat(action.payload),
      };
    case ERROR_CREAR_NUEVA_VENTA_MOSTRADOR:
    case ERROR_CANCELAR_VENTA:
    case ERROR_NUEVO_COBRO_VENTAMOSTRADOR:
    case ERROR_ELIMINAR_COBRO_VENTAMOSTRADOR:
      return {
        ...state,
      };
    case ERROR_VENTA_MOSTRADOR_SELECCIONADO:
      return {
        ...state,
        ventaMostradorSeleccionada: false,
      };
    case VENTA_MOSTRADOR_SELECCIONADO:
    case NUEVO_COBRO_VENTAMOSTRADOR:
    case ELIMINAR_COBRO_VENTAMOSTRADOR:
      return {
        ...state,
        ventaMostradorSeleccionada: action.payload,
      };
    case LIMPIAR_VENTA_MOSTRADOR_SELECCIONADO:
      return {
        ...state,
        ventaMostradorSeleccionada: {},
      };
    case CANCELAR_VENTA:
    case ACTUALIZAR_ESTATUS_VENTAMOSTRADOR:
      return {
        ...state,
        ventasMostradorFiltradas: state.ventasMostradorFiltradas.filter((venta) => {
          if (venta.idVentaMostrador == action.payload.idVentaMostrador) {
            venta.estatusventamostrador = action.payload.estatusventamostrador;
          }
          return true;
        }),
      };
    default:
      return state;
  }
};
