import React, { useReducer } from "react";

import RecetaProductoContext from "./RecetaProductoContext";
import RecetaProductoReducer from "./RecetaProductoReducer";

import { LISTAR_RECETA_PRODUCTOS } from "../../types";

import clienteAxios from "../../config/axios";

export default function RecetaProductoState(props) {
  const initialState = {
    recetaProductos: [],
  };

  const [state, dispatch] = useReducer(RecetaProductoReducer, initialState);

  const listarRecetaProductos = async (idCatMedico) => {
    const res = await clienteAxios.post(
      `/recetaproducto/activos/medico?id=${idCatMedico}`,

      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch({
      type: LISTAR_RECETA_PRODUCTOS,
      payload: res.data,
    });

    return res;
  };

  return (
    <RecetaProductoContext.Provider
      value={{
        recetaProductos: state.recetaProductos,
        listarRecetaProductos,
      }}>
      {props.children}
    </RecetaProductoContext.Provider>
  );
}
