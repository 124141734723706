import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import RecepcionGenericaProductosContext from "../../context/RecepcionGenericaProductos/RecepcionGenericaProductosContext";

export default function ListadoProductoRecepcionVenta({ props }) {
  const [loading, setLoading] = useState(false);

  const [formatFechaCaducidad, setFormatFechaCaducidad] = useState(false);
  const [diferenciaProductosInicial, setDiferenciaProductosInicial] = useState(false);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_guardar: false,
    confirm_alert_terminiar_recepcion_completa: false,
    confirm_alert_terminiar_recepcion_incompleta: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const recepGenProdContext = useContext(RecepcionGenericaProductosContext);
  const { recepcionVentaProductosSeleccionada, setRecepcionVentaProductosSeleccionada, recepcionProductosVentaUpload } =
    recepGenProdContext;

  const checkFechaCaducidad = async () => {
    recepcionVentaProductosSeleccionada.productos.map((producto) => {
      if (producto.lotes && producto.lotes.length > 0) {
        producto.lotes.map((lote) => {
          let loteFormat = lote.caducidad.split("-");
          loteFormat = `${loteFormat[2]}/${loteFormat[1]}/${loteFormat[0]}`;
          lote.caducidad = loteFormat;
        });
      }
    });

    setRecepcionVentaProductosSeleccionada(recepcionVentaProductosSeleccionada);

    setFormatFechaCaducidad(true);
  };

  const calcularDiferenciaTodosProductos = async () => {
    recepcionVentaProductosSeleccionada.productos.map((producto) => {
      calcularDiferencia(producto);
    });
    setDiferenciaProductosInicial(true);
  };

  const calcularDiferencia = async (producto) => {
    let diferencia = producto.piezas;

    if (producto.lotes && producto.lotes.length > 0) {
      let sumaPiezasLote = 0;
      producto.lotes.map((loteProducto) => {
        if (loteProducto.piezasRecibidas) {
          sumaPiezasLote += Number(loteProducto.piezasRecibidas);
        }
      });
      diferencia = diferencia - sumaPiezasLote;
    }

    producto.diferencia = diferencia;

    if (diferencia == 0) {
      producto.justificacion = "";
    }

    setRecepcionVentaProductosSeleccionada(recepcionVentaProductosSeleccionada);
  };

  const toggleExpanded = async (producto) => {
    producto.expanded = !producto.expanded;
    setRecepcionVentaProductosSeleccionada(recepcionVentaProductosSeleccionada);
  };

  const changeJustificacionProducto = async (e, producto) => {
    producto.justificacion = e.target.value;
    setRecepcionVentaProductosSeleccionada(recepcionVentaProductosSeleccionada);
  };

  const changePiezasRecibidasLote = async (e, producto, lote) => {
    let value = e.target.value;

    if (lote.piezasDevueltas < value) {
      value = 0;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Las piezas a recibir no deben ser mayores a las piezas devueltas por el lote",
      });
    }
    lote.piezasRecibidas = value;
    setRecepcionVentaProductosSeleccionada(recepcionVentaProductosSeleccionada);

    calcularDiferencia(producto);
  };

  const uploadRecepcionProductoVenta = async () => {
    setLoading(true);
    const res = await recepcionProductosVentaUpload();

    if (res.data.error) {
      setLoading(false);

      recepcionVentaProductosSeleccionada.idEstatusNuevo = null;
      setRecepcionVentaProductosSeleccionada(recepcionVentaProductosSeleccionada);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_guardar: false,
        confirm_alert_terminiar_recepcion_completa: false,
        confirm_alert_terminiar_recepcion_incompleta: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (!res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_guardar: false,
        confirm_alert_terminiar_recepcion_completa: false,
        confirm_alert_terminiar_recepcion_incompleta: false,
        redirect: true,
        path: "/ordenescompra",
        dynamic_description: "Se registraron los cambios de manera exitosa!",
      });
    }
  };

  const confirmTerminarRecepcion = async () => {
    let recepcionCompleta = await checkRecepcionCompletaOIncompleta();

    if (recepcionCompleta) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_terminiar_recepcion_completa: true,
      });
    } else {
      validateRecepcionIncompleta();
    }
  };

  const checkRecepcionCompletaOIncompleta = async () => {
    let recepcionCompleta = true;

    recepcionVentaProductosSeleccionada.productos.map((producto) => {
      if (producto.diferencia > 0) {
        recepcionCompleta = false;
      }
    });

    return recepcionCompleta;
  };

  const validateRecepcionIncompleta = async () => {
    let errorDeRecepcion = false;

    recepcionVentaProductosSeleccionada.productos.map((producto) => {
      producto.lotes.map((lote) => {
        if (!lote.piezasRecibidas || (lote.piezasRecibidas == "" && !errorDeRecepcion)) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, ingrese el número de piezas recibidas para el lote ${lote.lote} del producto ${producto.nombreComercial}`,
          });
          errorDeRecepcion = true;
        }
      });

      if (producto.diferencia > 0 && !producto.justificacion && !errorDeRecepcion) {
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: `El producto ${producto.nombreComercial} tiene una diferencia mayor a 0, por favor escribe la justificación`,
        });
        errorDeRecepcion = true;
      }
    });

    if (!errorDeRecepcion) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_terminiar_recepcion_incompleta: true,
      });
    }
  };

  const uploadFinalizarRecepcionProductoVenta = async () => {
    recepcionVentaProductosSeleccionada.terminarRecepcion = true;
    delete recepcionVentaProductosSeleccionada.fechaVenta;
    console.log(recepcionVentaProductosSeleccionada);
    setRecepcionVentaProductosSeleccionada(recepcionVentaProductosSeleccionada);
    uploadRecepcionProductoVenta();
  };

  useEffect(() => {
    if (Object.entries(recepcionVentaProductosSeleccionada).length !== 0 && !diferenciaProductosInicial) {
      calcularDiferenciaTodosProductos();
    }

    if (Object.entries(recepcionVentaProductosSeleccionada).length !== 0 && !formatFechaCaducidad) {
      checkFechaCaducidad();
    }
  }, [recepcionVentaProductosSeleccionada]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Lista de Productos</h3>

      <Card className="mt-10">
        <CardBody>
          <Row>
            <Col>
              <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Descripción</th>
                      <th scope="col">
                        Piezas Totales<br></br>a Devolver
                      </th>
                      <th scope="col">Diferencia</th>
                      <th scope="col">Justificación</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recepcionVentaProductosSeleccionada && recepcionVentaProductosSeleccionada.productos
                      ? recepcionVentaProductosSeleccionada.productos.map((producto, index) => {
                          return (
                            <>
                              <tr key={producto}>
                                <td onClick={() => toggleExpanded(producto)}>
                                  {producto.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>}
                                </td>
                                <td>{producto.nombreComercial ? producto.nombreComercial : ""}</td>
                                <td>{producto.piezas ? producto.piezas : ""}</td>
                                <td>{producto.diferencia >= 0 ? producto.diferencia : ""}</td>
                                <td>
                                  <textarea
                                    rows={1}
                                    className="form-control"
                                    disabled={producto.diferencia == 0 ? true : false}
                                    value={producto.justificacion ? producto.justificacion : ""}
                                    onChange={(e) => changeJustificacionProducto(e, producto)}
                                  />
                                </td>
                              </tr>

                              {producto.expanded ? (
                                <tr>
                                  <td colSpan={10}>
                                    <table className="table table-hover table-centered table-nowrap mt-4">
                                      <thead>
                                        <tr>
                                          <th scope="col">Lote</th>
                                          <th scope="col">Caducidad</th>
                                          <th scope="col">
                                            Piezas a<br></br>Devolver
                                          </th>
                                          <th scope="col">
                                            Piezas<br></br>Recibidas
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {producto.lotes
                                          ? producto.lotes.map((lote, indexLote) => {
                                              return (
                                                <>
                                                  <tr key={lote}>
                                                    <td>{lote.lote ? lote.lote : ""}</td>
                                                    <td>{lote.caducidad ? lote.caducidad : ""}</td>
                                                    <td>{lote.piezasDevueltas ? lote.piezasDevueltas : ""}</td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        className="form-control"
                                                        min={1}
                                                        onWheel={(e) => e.target.blur()}
                                                        value={lote.piezasRecibidas ? lote.piezasRecibidas : ""}
                                                        onChange={(e) => changePiezasRecibidasLote(e, producto, lote)}
                                                      />
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            })
                                          : null}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              ) : null}
                            </>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                type="button"
                onClick={() =>
                  setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_guardar: true,
                  })
                }
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                Guardar
              </button>
              <button
                type="button"
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
                onClick={() => confirmTerminarRecepcion()}>
                Terminar Recepción
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_guardar ? (
        <SweetAlert
          title="¿Desea guardar sus cambios?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadRecepcionProductoVenta()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar: false,
            })
          }>
          Se guardarán todos los cambios realizados.
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_terminiar_recepcion_completa ? (
        <SweetAlert
          title="Se recibieron todos los insumos, desea guardar sus cambios?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadFinalizarRecepcionProductoVenta()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_terminiar_recepcion_completa: false,
            })
          }>
          Se guardarán todos los cambios realizados y se terminará la recepción.
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_terminiar_recepcion_incompleta ? (
        <SweetAlert
          title="Faltan insumos por recibir, por favor asegúrese de registrar las piezas recibidas o justifique todas las diferencias, desea guardar sus cambios?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadFinalizarRecepcionProductoVenta()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_terminiar_recepcion_incompleta: false,
            })
          }>
          Se guardarán todos los cambios realizados y se terminará la recepción.
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
