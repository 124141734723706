import React, { useReducer } from "react";

import SidebarContext from "./SidebarContext";
import SidebarReducer from "./SidebarReducer";

import useWindowDimensions from "../../hooks/dimensionsHook";

import { TOGGLE_SIDEBAR } from "../../types";

export default function SidebarState(props) {
  const { width } = useWindowDimensions();
  const initialState = {
    showSidebar: width < 500 ? true : false,
  };

  const [state, dispatch] = useReducer(SidebarReducer, initialState);

  const toggleSidebar = async () => {
    dispatch({
      type: TOGGLE_SIDEBAR,
      payload: !state.showSidebar,
    });
  };

  return (
    <SidebarContext.Provider
      value={{
        showSidebar: state.showSidebar,
        toggleSidebar,
      }}>
      {props.children}
    </SidebarContext.Provider>
  );
}
