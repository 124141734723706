import React, { useReducer } from "react";

import ConsultaCompraProductoContext from "./ConsultaCompraProductoContext";
import ConsultaCompraProductoReducer from "./ConsultaCompraProductoReducer";

import { FILTRO_CONSULTACOMPRASPRODUCTO, LISTAR_CONSULTACOMPRASPRODUCTO } from "../../types";

import clienteAxios from "../../config/axios";

export default function ConsultaCompraProductoState(props) {
  const initialState = {
    consultaComprasProductoFiltradas: [],
    filtrosConsultaComprasProducto: {},
  };

  const [state, dispatch] = useReducer(ConsultaCompraProductoReducer, initialState);

  const setFiltrosConsultaComprasProducto = async (filtrosConsultaComprasProducto) => {
    dispatch({
      type: FILTRO_CONSULTACOMPRASPRODUCTO,
      payload: filtrosConsultaComprasProducto,
    });
  };

  const listarConsultaComprasProducto = async (data) => {
    const res = await clienteAxios.post("/ordencompra/detalle/producto/compras", data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CONSULTACOMPRASPRODUCTO,
      payload: res.data,
    });
  };
  
  const listarConsultaComprasProductoPaginado = async ({ page, limit }) => {
    const res = await clienteAxios.post("/ordencompra/detalle/producto/compras/paginado",
      {
        pageSize: limit,
        pageIndex: page,
        ...state.filtrosConsultaComprasProducto,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch({
      type: LISTAR_CONSULTACOMPRASPRODUCTO,
      payload: res.data.inventario,
    });
    return res.data;
  };

  const exportConsultaComprasProducto = async () => {
    try {
      const res = await clienteAxios.post(`/ordencompra/export/producto/compras`, state.filtrosConsultaComprasProducto, {
        responseType: "arraybuffer",
        headers: {
          "Content-Disposition": "attachment; filename=productos.xlsx",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "productos.xlsx");
      document.body.appendChild(link);
      link.click();

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      } else {
        error.response = {
          data: {
            message: "Error, por favor inténtalo más tarde",
            error: "Error",
          },
        };
      }

      return error.response;
    }
  };

  const limpiarConsultas = async () => {
    dispatch({
      type: LISTAR_CONSULTACOMPRASPRODUCTO,
      payload: [],
    });
  };

  return (
    <ConsultaCompraProductoContext.Provider
      value={{
        consultaComprasProductoFiltradas: state.consultaComprasProductoFiltradas,
        filtrosConsultaComprasProducto: state.filtrosConsultaComprasProducto,
        setFiltrosConsultaComprasProducto,
        listarConsultaComprasProducto,
        exportConsultaComprasProducto,
        limpiarConsultas,
        listarConsultaComprasProductoPaginado,
      }}>
      {props.children}
    </ConsultaCompraProductoContext.Provider>
  );
}
