/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */
import React, { useContext, useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import AuthContext from "../../context/Auth/AuthContext";

import useWindowDimensions from "../../hooks/dimensionsHook";

import FichaIdentificacion from "../TabsHistorialClinicaInicial/FichaIdentificacion/index";
import AntecedentesHeredofamiliares from "../TabsHistorialClinicaInicial/AntecedentesHeredofamiliares/index";
import AntecedentesPersonalesNoPatologicos from "../TabsHistorialClinicaInicial/AntecedentesPersonalesNoPatologicos/index";
import AntecedentesPersonalesPatologicos from "../TabsHistorialClinicaInicial/AntecedentesPersonalesPatologicos/index";
import AntecedentesGinecoobstetricos from "../TabsHistorialClinicaInicial/AntecedentesGinecoobstetricos/index";
import Resumen from "../TabsHistorialClinicaInicial/Resumen";
import RevisionCaso from "../TabsHistorialClinicaInicial/RevisionCaso";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import PadecimientoActualContext from "../../context/PadecimientoActual/PadecimientoActualContext";
import PadecimientoActual from "../PadecimientoActual";
import DiagnosticoPlan from "../DiagnosticoPlan";
import ExpedienteMedicoContext from "../../context/ExpedienteMedico/ExpedienteMedicoContext";
import CalendarioContext from "../../context/Calendario/CalendarioContext";
import SexosContext from "../../context/Sexos/SexosContext";
import WindowWarningContext from "../../context/WindowWarning/WindowWarningContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import DrawerHistoricoCitas from "../DrawerHistoricoCitas";

import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import "antd/dist/antd.min.css";
import { Select } from "antd";
import useCloseWindowWarning from "../../hooks/useCloseWindowWarning";
const { Option } = Select;

export default function HistoriaClinicaInicial(props) {
  const children = [
    <Option key={"imprimirHistoriaClinicaInicial"}>{"Historia Clínica Inicial"}</Option>,
    <Option key={"imprimirEstudiosLaboratorio"}>{"Estudios de Laboratorio"}</Option>,
    <Option key={"imprimirEstudiosImagen"}>{"Estudios de Imagen"}</Option>,
    <Option key={"imprimirHojasIndicaciones"}>{"Hojas de Indicaciones"}</Option>,
    <Option key={"imprimirRecetas"}>{"Recetas"}</Option>,
    <Option key={"imprimirNotasEnfermeria"}>{"Notas de Enfermería"}</Option>,
    <Option key={"imprimirNotasInterconsultas"}>{"Notas de Interconsultas"}</Option>,
    <Option key={"imprimirNotaMedica"}>{"Nota Médica"}</Option>,
    <Option key={"imprimirNotasEvolucion"}>{"Notas de Evolución"}</Option>,
  ];

  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito, changeGuardado, seGuardaronCambiosContext } = windowWarningContext;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  // useEffect(() => {
  //   changeEdit(false);
  //   if (seGuardaronCambiosContext) {
  //     setSeGuardaronCambios(true);
  //   } else {
  //     setSeGuardaronCambios(false);
  //   }
  // }, []);

  const [Prompt, setEditTrue, setEditFalse, isEdit] = useCloseWindowWarning();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (!seEdito) {
      setEditFalse();
    } else {
      if (seGuardaronCambiosContext) {
        setEditFalse();
      } else {
        setEditTrue();
      }
    }

    // if (seGuardaronCambiosContext && !seEdito) {
    //   setEditFalse();
    // } else {
    //   setEditTrue();
    // }
  }, [seGuardaronCambiosContext, seEdito]);

  const ctxPadAct = useContext(PadecimientoActualContext);
  const { padecimientoActual, seleccionarPadecimientoActualPaciente, setPadecimientoActual, uploadPadecimientoActual } =
    ctxPadAct;

  const ctxCalendario = useContext(CalendarioContext);
  const { listarCitasPaciente } = ctxCalendario;

  const [citasPaciente, setCitasPaciente] = useState(null);
  const [seGuardaronCambios, setSeGuardaronCambios] = useState(false);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_guardar_expediente: false,
    confirm_alert_imprimir_expediente: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "", 
    dynamic_title: "",
    dynamic_description: "",
  });

  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const [nuevoResumen, setNuevoResumen] = useState(null);
  const [nuevaRevisionCaso, setNuevaRevisionCaso] = useState(null);

  const [impresionExpedienteVo, setImpresionExpedienteVo] = useState([]);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const [cadenaLogGlobal, setCadenaLogGlobal] = useState("");
 
  const {
    historiaClinicaInicial, 
    seleccionarHistoriaClinicaInicialPaciente,
    seleccionarHistoriaClinicaOriginalPaciente,
    setHistoriaClinicaInicial,
    uploadHistoriaClinicaInicial,
  } = ctxHistClinInic;
 
  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado, setIdCatPacienteSeleccionado, setExpedienteDelPaciente } = pacientesContext;

  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const ctxExpedienteMedico = useContext(ExpedienteMedicoContext);
  const { imprimirExpedienteMedico } = ctxExpedienteMedico;

  /*     ****************************************************************************************************** */
  const TraeCadenaLogs = async () => {
  
    let cadena = "";
    let nuevoCadena;
    let originalCadenas;

    if(idCatPacienteSeleccionado > 0){
      cadena = "Actualización:ID[" + idCatPacienteSeleccionado + "]"
    }
      else{
        cadena = "Nuevo:ID[@@]"
    }
 
    let historiaClinicaInicialOriginal = await seleccionarHistoriaClinicaOriginalPaciente(idCatPacienteSeleccionado)
 
     if(historiaClinicaInicial.paciente.nombres != historiaClinicaInicialOriginal.paciente.nombres){
      if(idCatPacienteSeleccionado> 0){
        cadena = cadena + ",N[" + historiaClinicaInicialOriginal.paciente.nombres + "->" + historiaClinicaInicial.paciente.nombres + "]";
      }else{
        cadena = cadena + ",N[->" + historiaClinicaInicial.paciente.nombres + "]";
      }
     }
 
     if(historiaClinicaInicial.paciente.apellidoPaterno != historiaClinicaInicialOriginal.paciente.apellidoPaterno){
      if(idCatPacienteSeleccionado> 0)
      {
        cadena = cadena + ",AP[" + historiaClinicaInicialOriginal.paciente.apellidoPaterno + "->" + historiaClinicaInicial.paciente.apellidoPaterno + "]";
      }else{
        cadena = cadena + ",AP[->" + historiaClinicaInicial.paciente.apellidoPaterno + "]";
      }
     }
 
     if(historiaClinicaInicial.paciente.apellidoMaterno != historiaClinicaInicialOriginal.paciente.apellidoMaterno){
      if(idCatPacienteSeleccionado> 0)
      {
        cadena = cadena + ",AM[" + historiaClinicaInicialOriginal.paciente.apellidoMaterno + "->" + historiaClinicaInicial.paciente.apellidoMaterno + "]";
      }else{
        cadena = cadena + ",AM[->" + historiaClinicaInicial.paciente.apellidoMaterno + "]";
      }
     }
 
     if(historiaClinicaInicial.paciente.origen != historiaClinicaInicialOriginal.paciente.origen){
      if(idCatPacienteSeleccionado> 0)
      {
        cadena = cadena + ",RO[" + historiaClinicaInicialOriginal.paciente.origen + "->" + historiaClinicaInicial.paciente.origen + "]";
      }else{
        cadena = cadena + ",RO[->" + historiaClinicaInicial.paciente.origen + "]";
      }
     }
 
     if(historiaClinicaInicial.paciente.residencia != historiaClinicaInicialOriginal.paciente.residencia){
      if(idCatPacienteSeleccionado> 0)
      {
        cadena = cadena + ",RA[" + historiaClinicaInicialOriginal.paciente.residencia + "->" + historiaClinicaInicial.paciente.residencia + "]";
      }else{
        cadena = cadena + ",RA[->" + historiaClinicaInicial.paciente.residencia + "]";
      }
     }

     if(historiaClinicaInicial.paciente.fechaNacimiento != historiaClinicaInicialOriginal.paciente.fechaNacimiento){
      if(idCatPacienteSeleccionado> 0)
      {
        cadena = cadena + ",FN[" + historiaClinicaInicialOriginal.paciente.fechaNacimiento + "->" + historiaClinicaInicial.paciente.fechaNacimiento + "]";
      }else{
        cadena = cadena + ",FN[->" + historiaClinicaInicial.paciente.fechaNacimiento + "]";
      }
     }
 
     if(historiaClinicaInicial.paciente.telefono != historiaClinicaInicialOriginal.paciente.telefono){
      if(idCatPacienteSeleccionado> 0)
      {
        cadena = cadena + ",T[" + historiaClinicaInicialOriginal.paciente.telefono + "->" + historiaClinicaInicial.paciente.telefono + "]";
      }else{
        cadena = cadena + ",T[->" + historiaClinicaInicial.paciente.telefono + "]";
      }
     }
 
     if(historiaClinicaInicial.paciente.curp != historiaClinicaInicialOriginal.paciente.curp){
      if(idCatPacienteSeleccionado> 0)
      {
        cadena = cadena + ",C[" + historiaClinicaInicialOriginal.paciente.curp + "->" + historiaClinicaInicial.paciente.curp + "]";
      }else{
        cadena = cadena + ",C[->" + historiaClinicaInicial.paciente.curp + "]";
      }
     }
 
     if(historiaClinicaInicial.paciente.nacionalidad != historiaClinicaInicialOriginal.paciente.nacionalidad){
      if(idCatPacienteSeleccionado> 0)
      {
        cadena = cadena + ",Nc[" + historiaClinicaInicialOriginal.paciente.nacionalidad + "->" + historiaClinicaInicial.paciente.nacionalidad + "]";
      }else{
        cadena = cadena + ",Nc[->" + historiaClinicaInicial.paciente.nacionalidad + "]";
      }
     }
 
     if(historiaClinicaInicial.paciente.idSexo != historiaClinicaInicialOriginal.paciente.idSexo){
       nuevoCadena = "";
       originalCadenas = "" 
       sexos.filter((sexF) => 
       {
               if (historiaClinicaInicial.paciente.idSexo == sexF.idCatSexo){
                   nuevoCadena = sexF.sexo;
               }
               if (historiaClinicaInicialOriginal.paciente.idSexo == sexF.idCatSexo){
                   originalCadenas= sexF.sexo;
               } 
       })   
       if(idCatPacienteSeleccionado> 0)
       {
         cadena = cadena + ",S[" + originalCadenas + "->" + nuevoCadena + "]";
       }else{
         cadena = cadena + ",S[->" + nuevoCadena + "]";
       }
     }
 
     if(historiaClinicaInicial.paciente.idLugarOrigen != historiaClinicaInicialOriginal.paciente.idLugarOrigen){
       nuevoCadena = "";
       originalCadenas = "" 
       lugares.filter((lugaresFiltro) => 
       {
               if (historiaClinicaInicial.paciente.idLugarOrigen == lugaresFiltro.idCatOrigenResidencia){
                   nuevoCadena = lugaresFiltro.lugar;
               }
               if (historiaClinicaInicialOriginal.paciente.idLugarOrigen == lugaresFiltro.idCatOrigenResidencia){
                   originalCadenas= lugaresFiltro.lugar;
               } 
       })   

       if(idCatPacienteSeleccionado> 0)
       {
          cadena = cadena + ",EO[" + originalCadenas + "->" + nuevoCadena + "]";
       }else{
          cadena = cadena + ",EO[->" + nuevoCadena + "]";
       }
     }
 
     if(historiaClinicaInicial.paciente.idLugarResidencia != historiaClinicaInicialOriginal.paciente.idLugarResidencia){
       nuevoCadena = "";
       originalCadenas = "" 
       lugares.filter((lugaresFiltro) => 
       {
               if (historiaClinicaInicial.paciente.idLugarResidencia == lugaresFiltro.idCatOrigenResidencia){
                   nuevoCadena = lugaresFiltro.lugar;
               }
               if (historiaClinicaInicialOriginal.paciente.idLugarResidencia == lugaresFiltro.idCatOrigenResidencia){
                   originalCadenas= lugaresFiltro.lugar;
               } 
       })   

       if(idCatPacienteSeleccionado> 0)
       {
         cadena = cadena + ",EA[" + originalCadenas + "->" + nuevoCadena + "]";
       }else{
         cadena = cadena + ",EA[->" + nuevoCadena + "]";
       }
     } 
     if(historiaClinicaInicial.expedienteMedico){
      if(historiaClinicaInicial.expedienteMedico.idCatAseguradora != historiaClinicaInicialOriginal.expedienteMedico.idCatAseguradora){
        nuevoCadena = "";
        originalCadenas = "" 
        aseguradoras.filter((aseguradoraFiltro) => 
        {
                if (historiaClinicaInicial.expedienteMedico.idCatAseguradora == aseguradoraFiltro.idCatAseguradora){
                    nuevoCadena = aseguradoraFiltro.razonSocial;
                }
                if (historiaClinicaInicialOriginal.expedienteMedico.idCatAseguradora == aseguradoraFiltro.idCatAseguradora){
                    originalCadenas= aseguradoraFiltro.razonSocial;
                } 
        })  

        if(idCatPacienteSeleccionado> 0)
        {
          cadena = cadena + ",A[" + originalCadenas + "->" + nuevoCadena + "]";
        }else{
          cadena = cadena + ",A[->" + nuevoCadena + "]";
        }
      }
    }
     return cadena;
  }

  /*     ****************************************************************************************************** */
  const checkIfDatosCompletos = async () => {
    if (!historiaClinicaInicial || !historiaClinicaInicial.paciente) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca los datos del paciente",
      });
      return true;
    } 

    const { paciente } = historiaClinicaInicial;
    if (!paciente.nombres) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el nombre del paciente",
      });
      return true; 
    }

    if (!paciente.apellidoPaterno) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el apellido paterno del paciente",
      });
      return true;
    }

    if (!paciente.apellidoMaterno) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el apellido materno del paciente",
      });
      return true;
    }

    if (!paciente.idLugarOrigen && !paciente.origen && 
         (usuario.authorities.includes("ROLE_MEDICO") || usuario.authorities.includes("ROLE_EXP_MEDICO"))){
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el estado o ciudad de origen del paciente",
      });
      return true;
    }
    // if (!paciente.origen) {
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, introduzca la ciudad de origen del paciente",
    //   });
    //   return true;
    // }
    if (!paciente.idLugarResidencia && !paciente.residencia && 
        (usuario.authorities.includes("ROLE_MEDICO") || usuario.authorities.includes("ROLE_EXP_MEDICO"))) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca el estado o la ciudad de residencia del paciente",
      });
      return true;
    }
    // if (!paciente.residencia) {
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, introduzca la ciudad de residencia del paciente",
    //   });
    //   return true;
    // }

    if (!paciente.fechaNacimiento && 
        (usuario.authorities.includes("ROLE_MEDICO") || usuario.authorities.includes("ROLE_EXP_MEDICO"))) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca la fecha de nacimiento del paciente",
      });
      return true;
    } 

    return false;
  };

  // useEffect(() => {}, [seGuardaronCambios]);

  const uploadExpedienteMedicoConfirm = async ({ sinAseguradora }) => {

    let error = await checkIfDatosCompletos();
    if (!error) { 
     let cadenatemp = await TraeCadenaLogs();
      historiaClinicaInicial.logVo = cadenatemp.length> 1?
      {
        idUsuario: usuario.idUsuario,
        modulo: "Paciente datos generales",
        actividad: cadenatemp
      }:null;

      setLoading(true);
      let revisionActualizada;
      let resumenActualizado;
      if (nuevoResumen && nuevoResumen != "") resumenActualizado = changeExtraResumen();
      setNuevoResumen("");
      if (nuevaRevisionCaso && nuevaRevisionCaso != "") revisionActualizada = changeExtraRevisionCaso();
      setNuevaRevisionCaso("");

      const res = await uploadHistoriaClinicaInicial(usuario.idCatMedico, resumenActualizado, revisionActualizada);

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setSeGuardaronCambios(true);
        changeGuardado(true);
        changeEdit(false);
        if ((historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.idExpedienteMedico) || res.data) {
          // eslint-disable-next-line no-unused-vars
          const idCatPaciente =
            historiaClinicaInicial && historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idCatPaciente
              ? historiaClinicaInicial.paciente.idCatPaciente
              : res.data && res.data.idCatPaciente
              ? res.data.idCatPaciente
              : null;
          const tieneExpedienteMedico =
            historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.idExpedienteMedico;
          const resPadecimientoActual = await uploadPadecimientoActual(idCatPaciente, tieneExpedienteMedico);
        }
        setLoading(false);
        if (sinAseguradora) {
          setUISweetAlert({
            ...UISweetAlert,
            success_dlg: true,
            editar: false,
            dynamic_title: "Exito",
            confirm_alert_guardar_expediente_sin_aseguradora: false,
            dynamic_description: "Se guardó de manera correcta la información de la historia clínica inicial.",
          });      
        } else {
          setUISweetAlert({
            ...UISweetAlert,
            success_dlg: true,
            editar: false,
            dynamic_title: "Exito",
            confirm_alert_guardar_expediente: false,
            dynamic_description: "Se guardó de manera correcta la información de la historia clínica inicial.",
          });
        }
        setIdCatPacienteSeleccionado(res.data.idCatPaciente);
        // checkHistorialClinicaPaciente();
        if (historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.idExpedienteMedico) {
          seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
        } else {
          seleccionarHistoriaClinicaInicialPaciente(res.data.idCatPaciente);
        }
        setCadenaLogGlobal("")
      }
    } else {
      return;
    }
  };

  const consultaSeguimiento = async () => {
    if (!idCatPacienteSeleccionado || !historiaClinicaInicial.expedienteMedico.idExpedienteMedico) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se ha creado el expediente del paciente, por favor guarde sus cambios",
      });
      return;
    }
    props.history.push("/consulta/seguimiento");
  };

  const notasInterconsultasRedirect = async () => {
    if (!idCatPacienteSeleccionado || !historiaClinicaInicial.expedienteMedico.idExpedienteMedico) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se ha creado el expediente del paciente, por favor guarde sus cambios",
      });
      return;
    }
    props.history.push("/nota/interconsulta");
  };
  const archivosPacienteRedirect = async () => {
    if (!idCatPacienteSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se ha creado el expediente del paciente, por favor guarde sus cambios",
      });
      return;
    }
    props.history.push("/archivos/paciente", { historiaClinicaInicialProps: historiaClinicaInicial });
  };

  const hojaIndicacionesRedirect = async () => {
    if (!idCatPacienteSeleccionado || !historiaClinicaInicial.expedienteMedico.idExpedienteMedico) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se ha creado el expediente del paciente, por favor guarde sus cambios",
      });
      return;
    }
    props.history.push("/hoja/indicacion");
  };
  const notasRedirect = async () => {
    if (!idCatPacienteSeleccionado || !historiaClinicaInicial.expedienteMedico.idExpedienteMedico) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se ha creado el expediente del paciente, por favor guarde sus cambios",
      });
      return;
    }

    props.history.push(`/quimio/nota/enfermeria/${historiaClinicaInicial.expedienteMedico.idExpedienteMedico}`);
  };
  const notasEvolucionRedirect = async () => {
    if (!idCatPacienteSeleccionado || !historiaClinicaInicial.expedienteMedico.idExpedienteMedico) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se ha creado el expediente del paciente, por favor guarde sus cambios",
      });
      return;
    }

    props.history.push(`/quimio/nota/evolucion/${historiaClinicaInicial.expedienteMedico.idExpedienteMedico}`);
  };

  const consentimientoClienteRedirect = async () => {
    if (!idCatPacienteSeleccionado || !historiaClinicaInicial.expedienteMedico.idExpedienteMedico) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se ha creado el expediente del paciente, por favor guarde sus cambios",
      });
      return;
    }

    props.history.push("/consentimiento");
  };

  const estudiosLaboratorioRedirect = async () => {
    if (!idCatPacienteSeleccionado || !historiaClinicaInicial.expedienteMedico.idExpedienteMedico) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se ha creado el expediente del paciente, por favor guarde sus cambios",
      });
      return;
    }
    setExpedienteDelPaciente(historiaClinicaInicial.expedienteMedico.idExpedienteMedico);
    props.history.push("/estudios/laboratorio", { historiaClinicaInicialProps: historiaClinicaInicial });
  };

  const marcadoresPacienteRedirect = async () => {
    if (!idCatPacienteSeleccionado || !historiaClinicaInicial.expedienteMedico.idExpedienteMedico) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se ha creado el expediente del paciente, por favor guarde sus cambios",
      });
      return;
    }
    props.history.push("/marcadores/paciente");
  };

  useEffect(() => {
    checkHistorialClinicaPaciente();
  }, []);

  useEffect(() => {
     const fetchCitasPaciente = async () => {
      if (idCatPacienteSeleccionado) {
        const res = await listarCitasPaciente(idCatPacienteSeleccionado);

        setCitasPaciente(res.data);
        return res.data;
      } else {
        setCitasPaciente([]);
      }
    };
    fetchCitasPaciente();
  }, []);

  const exportExpedienteMedico = async () => {
    const partesExpediente = [...impresionExpedienteVo];
    let contador = 0;
    let banError = false;
    let cadenaErrores = "";
    
    partesExpediente.forEach(async (parte) => {
      const expedienteImpresionVo = {
        [parte]: true,
        idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
        idCatPaciente: historiaClinicaInicial.paciente.idCatPaciente,
        idCatMedico: usuario.idCatMedico,
      };
      
      contador = contador + 1;
      const res = await imprimirExpedienteMedico(expedienteImpresionVo);
      if (res.data.byteLength < 2000 || res.data.byteLength == undefined){

        if (parte == "imprimirHojasIndicaciones"){
          cadenaErrores = cadenaErrores + "Hoja de Indicaciones, "
          banError = true;
         }
         if (parte == "imprimirRecetas"){
          cadenaErrores = cadenaErrores + "Recetas, "
           banError = true;
         }
         if (parte == "imprimirEstudiosImagen"){
          cadenaErrores = cadenaErrores + "Estudios de Imagen, "
           banError = true;
         }
         if (parte == "imprimirEstudiosLaboratorio"){
          cadenaErrores = cadenaErrores +  "Estudios de Laboratorio, "
           banError = true;
         }
         if (parte == "imprimirNotasInterconsultas"){
          cadenaErrores = cadenaErrores + "Notas de Interconsultas, "
           banError = true;
         }
         if (parte == "imprimirNotasEnfermeria"){
          cadenaErrores = cadenaErrores +  "Notas de Enfermeria, "
           banError = true;
         }
         if (parte == "imprimirNotaMedica"){
          cadenaErrores = cadenaErrores + "Nota Médica, "
           banError = true;
         }
         if (parte == "imprimirHistoriaClinicaInicial"){
          cadenaErrores = cadenaErrores +  "Historia Clinica Inicial, "
           banError = true;
         }

         /* VERIFICAMOS QUE EL CONTADOR SEA IGUAL A LA CANTIDAD DE REGISTROS A IMPRIMIR, SI YA ES EL MISMO SE MANDA MENSAJE*/ 
         if (contador >= partesExpediente.length)
         {
          if (banError)
          {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              dynamic_title: "Resultados de impresión",
              dynamic_description: "No se pudieron imprimir los siguientes documentos por falta de información: " + cadenaErrores ,
            });
          }
        }

      }
      return res;
    });

  };

  const handleChange = (value) => {
    setImpresionExpedienteVo(value);
  };

  const checkGuardarExpediente = () => {
    if (historiaClinicaInicial && historiaClinicaInicial && Object.keys(historiaClinicaInicial).length == 0) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, introduzca los datos del paciente",
      });
      return;
    } else if (historiaClinicaInicial && historiaClinicaInicial.paciente && !historiaClinicaInicial.expedienteMedico) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_guardar_expediente_sin_aseguradora: true,
        editar: false,
      });
    } else if (
      historiaClinicaInicial &&
      historiaClinicaInicial.expedienteMedico &&
      (!historiaClinicaInicial.expedienteMedico.idCatAseguradora ||
        (historiaClinicaInicial.expedienteMedico.idCatAseguradora &&
          historiaClinicaInicial.expedienteMedico.idCatAseguradora == ""))
    ) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_guardar_expediente_sin_aseguradora: true,
        editar: false,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_guardar_expediente: true,
        editar: false,
      });
    }
  };

  const changeExtraResumen = () => {
    let today = new Date();
    let month = today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1;
    let minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
    let date = today.getFullYear() + "-" + month + "-" + today.getDate();
    let time = today.getHours() + ":" + minutes;
    let dateTime = date + " " + time;

    const textNombreUsuario = usuario && usuario.nombre ? "Agregado por: " + usuario.nombre : "";
    const resumenPrevio =
      historiaClinicaInicial && historiaClinicaInicial.resumen && historiaClinicaInicial.resumen.resumen
        ? historiaClinicaInicial.resumen.resumen
        : "";

    const infoModificada = 
      resumenPrevio + "\n" + " " + "\n" + dateTime + "\n" + textNombreUsuario + "\n" + nuevoResumen;

    setHistoriaClinicaInicial({
      ...historiaClinicaInicial,
      resumen: {
        ...historiaClinicaInicial.resumen,
        resumen: infoModificada,
      },
    });

    return infoModificada;
    // consultaSeleccionada[keyConsulta] + "\n" + " " + "\n" + dateTime + "\n" + consultaSeleccionadaModificada[key];
  };

  const changeExtraRevisionCaso = () => {
    let today = new Date();
    let month = today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1;
    let minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
    let date = today.getFullYear() + "-" + month + "-" + today.getDate();
    let time = today.getHours() + ":" + minutes;

    const textNombreUsuario = usuario && usuario.nombre ? "Agregado por: " + usuario.nombre : "";
    let dateTime = date + " " + time;

    const revisionPrevia =
      historiaClinicaInicial && historiaClinicaInicial.revisionCaso && historiaClinicaInicial.revisionCaso.revisionCaso
        ? historiaClinicaInicial.revisionCaso.revisionCaso
        : "";

    const infoModificada = 
      revisionPrevia + "\n" + " " + "\n" + dateTime + "\n" + textNombreUsuario + "\n" + nuevaRevisionCaso;

    setHistoriaClinicaInicial({
      ...historiaClinicaInicial,
      revisionCaso: {
        ...historiaClinicaInicial.revisionCaso,
        revisionCaso: infoModificada,
      },
    });
    
    return infoModificada;
  };

  const checkImpresionExpediente = () => {
    if (!idCatPacienteSeleccionado || !historiaClinicaInicial.expedienteMedico.idExpedienteMedico) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se ha creado el expediente del paciente, por favor guarde sus cambios",
      });
      return;
    }

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_imprimir_expediente: true,
    });
  };

  return (
    <div className="mt-8" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Expediente Médico (Historia Clínica Inicial) 
        {
          (historiaClinicaInicial.paciente ? 
            ": " +
            historiaClinicaInicial.paciente.nombres +
            " " +
            historiaClinicaInicial.paciente.apellidoPaterno +
            " " +
            historiaClinicaInicial.paciente.apellidoMaterno : "")
        }
      </h3>
      <Card className="mt-4">
        <CardBody>
          {usuario.authorities.includes("ROLE_MEDICO") ||
           usuario.authorities.includes("ROLE_EXP_MEDICO") ||
           usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ? (
            <>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                    onClick={consultaSeguimiento}>
                    Consultas de Seguimiento o Evolución
                  </button>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                    onClick={estudiosLaboratorioRedirect}>
                    Estudios
                  </button>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                    onClick={hojaIndicacionesRedirect}>
                    Hojas de Indicaciones
                  </button>
                  <button
                    hidden={!usuario.authorities.includes("ROLE_ADMIN_SISTEMA")}
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                    onClick={consentimientoClienteRedirect}>
                    Consentimiento del Cliente
                  </button>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                    onClick={notasRedirect}>
                    Notas de Enfermería
                  </button>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                    onClick={notasEvolucionRedirect}>
                    Notas de Evolución
                  </button>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                    onClick={notasInterconsultasRedirect}>
                    Notas de Interconsultas
                  </button>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                    onClick={() => checkImpresionExpediente()}>
                    Imprimir Expediente
                  </button>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                    onClick={archivosPacienteRedirect}>
                    Archivos del Paciente
                  </button>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                    type="button"
                    // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                    onClick={marcadoresPacienteRedirect}>
                    Marcadores del Paciente
                  </button>
                  {historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idCatPaciente ? (
                    <DrawerHistoricoCitas citasPaciente={citasPaciente} paciente={historiaClinicaInicial.paciente} />
                  ) : null}
                </Col>
              </Row>
            </>
          ) : null}

          {usuario.authorities.includes("ROLE_QUIMIO") && !usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ? (
            <Row>
              <Col>
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                  type="button"
                  // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                  onClick={hojaIndicacionesRedirect}>
                  Hojas de Indicaciones
                </button>
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                  type="button"
                  // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                  onClick={notasRedirect}>
                  Notas de Enfermería
                </button>
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                  type="button"
                  // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                  onClick={notasEvolucionRedirect}>
                  Notas de Evolución
                </button>
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                  type="button"
                  // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                  onClick={consentimientoClienteRedirect}>
                  Consentimiento del Cliente
                </button>
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                  type="button"
                  // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                  onClick={marcadoresPacienteRedirect}>
                  Marcadores del Paciente
                </button>
              </Col>
            </Row>
          ) : null}

          {usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") && !usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ? (
            <Row>
              <Col>
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                  type="button"
                  // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
                  onClick={hojaIndicacionesRedirect}>
                  Hojas de Indicaciones
                </button>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col>
              <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                  <Tab>Ficha de Identificación</Tab>
                  {usuario.authorities.includes("ROLE_MEDICO") ||
                   usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                   usuario.authorities.includes("ROLE_QUIMIO") ? (
                    <>
                      <Tab>Antecedentes Heredofamiliares</Tab>
                      <Tab>Antecedentes Personales No Patológicos</Tab>
                      <Tab>Antecedentes Personales Patológicos</Tab>
                      {historiaClinicaInicial.paciente &&
                       historiaClinicaInicial.paciente.idSexo &&
                       (historiaClinicaInicial.paciente.idSexo == 1 || historiaClinicaInicial.paciente.idSexo == 3) ? (
                          <Tab>Antecedentes Ginecoobstétricos</Tab>
                      ) : null}
                      <Tab>Padecimiento Actual</Tab>
                      <Tab>Diagnóstico y Plan</Tab>
                      {usuario.authorities.includes("ROLE_MEDICO") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ? (
                        <>
                          <Tab>Resumen</Tab>
                          <Tab>Revisión del Caso</Tab>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </TabList>
                <TabPanel>
                  <FichaIdentificacion
                    props={props}
                    usuario={usuario}
                    historiaClinicaInicial={historiaClinicaInicial}
                    setHistoriaClinicaInicial={setHistoriaClinicaInicial}
                    disabled={
                      usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") && !usuario.authorities.includes("ROLE_MEDICO")
                        ? true
                        : false
                    }
                    width={width}
                    seGuardaronCambios={seGuardaronCambios}
                  />
                </TabPanel>
                <TabPanel>
                  <AntecedentesHeredofamiliares
                    props={props}
                    historiaClinicaInicial={historiaClinicaInicial}
                    setHistoriaClinicaInicial={setHistoriaClinicaInicial}
                    seGuardaronCambios={seGuardaronCambios}
                  />
                </TabPanel>
                <TabPanel>
                  <AntecedentesPersonalesNoPatologicos
                    props={props}
                    historiaClinicaInicial={historiaClinicaInicial}
                    setHistoriaClinicaInicial={setHistoriaClinicaInicial}
                    seGuardaronCambios={seGuardaronCambios}
                  />
                </TabPanel>
                <TabPanel>
                  <AntecedentesPersonalesPatologicos
                    props={props}
                    historiaClinicaInicial={historiaClinicaInicial}
                    setHistoriaClinicaInicial={setHistoriaClinicaInicial}
                    seGuardaronCambios={seGuardaronCambios}
                  />
                </TabPanel>
                {historiaClinicaInicial.paciente &&
                 historiaClinicaInicial.paciente.idSexo &&
                 (historiaClinicaInicial.paciente.idSexo == 1 || historiaClinicaInicial.paciente.idSexo == 3) ? (
                  <TabPanel>
                    <AntecedentesGinecoobstetricos
                      props={props}
                      historiaClinicaInicial={historiaClinicaInicial}
                      setHistoriaClinicaInicial={setHistoriaClinicaInicial}
                      seGuardaronCambios={seGuardaronCambios}
                    />
                  </TabPanel>
                ) : null}
                {/* <TabPanel>
                  <AntecedentesGinecoobstetricos props={props} historiaClinicaInicial={historiaClinicaInicial} setHistoriaClinicaInicial={setHistoriaClinicaInicial} />
                </TabPanel> */}
                <TabPanel>
                  <PadecimientoActual
                    props={props}
                    padecimientoActual={padecimientoActual}
                    historiaClinicaInicial={historiaClinicaInicial}
                    setHistoriaClinicaInicial={setHistoriaClinicaInicial}
                    setNuevoResumen={setNuevoResumen}
                    changeExtraResumen={changeExtraResumen}
                    nuevoResumen={nuevoResumen}
                    setNuevaRevisionCaso={setNuevaRevisionCaso}
                    changeExtraRevisionCaso={changeExtraRevisionCaso}
                    nuevaRevisionCaso={nuevaRevisionCaso}
                    seGuardaronCambios={seGuardaronCambios}
                  />
                </TabPanel>
                <TabPanel>
                  <DiagnosticoPlan
                    props={props}
                    padecimientoActual={padecimientoActual}
                    historiaClinicaInicial={historiaClinicaInicial}
                    setHistoriaClinicaInicial={setHistoriaClinicaInicial}
                    setNuevoResumen={setNuevoResumen}
                    changeExtraResumen={changeExtraResumen}
                    nuevoResumen={nuevoResumen}
                    setNuevaRevisionCaso={setNuevaRevisionCaso}
                    changeExtraRevisionCaso={changeExtraRevisionCaso}
                    nuevaRevisionCaso={nuevaRevisionCaso}
                    seGuardaronCambios={seGuardaronCambios}
                  />
                </TabPanel>
                <TabPanel>
                  <Resumen
                    props={props}
                    historiaClinicaInicial={historiaClinicaInicial}
                    setHistoriaClinicaInicial={setHistoriaClinicaInicial}
                    seGuardaronCambios={seGuardaronCambios}
                    nuevoResumen={nuevoResumen}
                    setNuevoResumen={setNuevoResumen}
                  />
                </TabPanel>
                <TabPanel>
                  <RevisionCaso
                    props={props}
                    historiaClinicaInicial={historiaClinicaInicial}
                    setHistoriaClinicaInicial={setHistoriaClinicaInicial}
                    seGuardaronCambios={seGuardaronCambios}
                    nuevaRevisionCaso={nuevaRevisionCaso}
                    setNuevaRevisionCaso={setNuevaRevisionCaso}
                  />
                </TabPanel>
              </Tabs>
            </Col>
          </Row>

          <Row>
            <Col>
              <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light"
                type="button"
                hidden={
                  ((tabIndex == 4 || tabIndex == 5) &&
                    historiaClinicaInicial.paciente &&
                    historiaClinicaInicial.paciente.idSexo &&
                    historiaClinicaInicial.paciente.idSexo == 2) ||
                  ((tabIndex == 4 || tabIndex == 5) &&
                    historiaClinicaInicial.paciente &&
                    !historiaClinicaInicial.paciente.idSexo) ||
                  ((tabIndex == 4 || tabIndex == 5) && !historiaClinicaInicial) ||
                  (historiaClinicaInicial.paciente &&
                    historiaClinicaInicial.paciente.idSexo &&
                    (historiaClinicaInicial.paciente.idSexo == 1 || historiaClinicaInicial.paciente.idSexo == 3) &&
                    (tabIndex == 5 || tabIndex == 6))
                }
                onClick={() => {
                  checkGuardarExpediente();
                }}>
                {historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.idExpedienteMedico
                  ? "Guardar"
                  : "Crear expediente del paciente"}
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_guardar_expediente ? (
        <SweetAlert
          title="¿Desea guardar los cambios al expediente médico?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{ width: width && width < 851 ? "100%" : "65%" }}
          onConfirm={() => uploadExpedienteMedicoConfirm({ sinAseguradora: false })}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar_expediente: false,
              editar: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_guardar_expediente_sin_aseguradora ? (
        <SweetAlert
          title="El paciente no tiene aseguradora seleccionada"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{ width: width && width < 851 ? "100%" : "65%" }}
          onConfirm={() => uploadExpedienteMedicoConfirm({ sinAseguradora: true })}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar_expediente_sin_aseguradora: false,
              editar: false,
            });
          }}>
          ¿Desea guardar los cambios al expediente médico?
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_imprimir_expediente ? (
        <SweetAlert
          title="Por favor, seleccione las partes del expediente a imprimir"
          showCancel
          confirmBtnText="Imprimir"
          confirmBtnBsStyle="success"
          cancelBtnText="Regresar"
          cancelBtnBsStyle="danger"
          onConfirm={() => exportExpedienteMedico()}
          closeOnClickOutside={false}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_imprimir_expediente: false,
            });
          }}
          style={{ width: width && width < 851 ? "100%" : "65%" }}>
          <React.Fragment>
            <button
              className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
              onClick={exportExpedienteMedico}>
              Imprimir
            </button>
        </React.Fragment>
          <CardBody className="mt-6">
            <Select
              allowClear
              mode="multiple"
              size={"medium"}
              placeholder="Seleccione las partes a imprimir"
              onChange={handleChange}
              value={impresionExpedienteVo}
              style={{
                width: "100%",
              }}>
              {children}
            </Select>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
      {Prompt}
    </div>
  );

  function checkHistorialClinicaPaciente() {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
        seleccionarPadecimientoActualPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
        setPadecimientoActual({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }
}

// const checkGuardarExpediente = () => {
//   if (!historiaClinicaInicial || (historiaClinicaInicial && Object.keys(historiaClinicaInicial).length == 0)) {
//     setUISweetAlert({
//       ...UISweetAlert,
//       confirm_alert_guardar_expediente_sin_aseguradora: true,
//       editar: false,
//     });
//   } else if (
//     historiaClinicaInicial &&
//     historiaClinicaInicial.expedienteMedico &&
//     !historiaClinicaInicial.idCatAseguradora &&
//     historiaClinicaInicial == ""
//   ) {
//     setUISweetAlert({
//       ...UISweetAlert,
//       confirm_alert_guardar_expediente_sin_aseguradora: true,
//       editar: false,
//     });
//   } else if (
//     historiaClinicaInicial.paciente &&
//     historiaClinicaInicial.expedienteMedico &&
//     historiaClinicaInicial.expedienteMedico.idCatAseguradora &&
//     historiaClinicaInicial.expedienteMedico.idCatAseguradora != ""
//   ) {
//     setUISweetAlert({
//       ...UISweetAlert,
//       confirm_alert_guardar_expediente: true,
//       editar: false,
//     });
//   } else if (
//     !historiaClinicaInicial.paciente &&
//     historiaClinicaInicial.expedienteMedico &&
//     historiaClinicaInicial.expedienteMedico.idCatAseguradora &&
//     historiaClinicaInicial.expedienteMedico.idCatAseguradora != ""
//   ) {
//     setUISweetAlert({
//       ...UISweetAlert,
//       confirm_alert_guardar_expediente: true,
//       editar: false,
//     });
//   } else if (
//     historiaClinicaInicial &&
//     historiaClinicaInicial.expedienteMedico &&
//     !historiaClinicaInicial.expedienteMedico.idCatAseguradora &&
//     historiaClinicaInicial.expedienteMedico.idCatAseguradora == ""
//   ) {
//     setUISweetAlert({
//       ...UISweetAlert,
//       confirm_alert_guardar_expediente_sin_aseguradora: true,
//       editar: false,
//     });
//   } else if (historiaClinicaInicial && historiaClinicaInicial.paciente && !historiaClinicaInicial.expedienteMedico) {
//     setUISweetAlert({
//       ...UISweetAlert,
//       confirm_alert_guardar_expediente_sin_aseguradora: true,
//       editar: false,
//     });
//   } else if (
//     historiaClinicaInicial &&
//     historiaClinicaInicial.expedienteMedico &&
//     !historiaClinicaInicial.expedienteMedico.idExpedienteMedico &&
//     !historiaClinicaInicial.expedienteMedico.idCatAseguradora
//   ) {
//     setUISweetAlert({
//       ...UISweetAlert,
//       confirm_alert_guardar_expediente_sin_aseguradora: true,
//       editar: false,
//     });
//   } else {
//     setUISweetAlert({
//       ...UISweetAlert,
//       confirm_alert_guardar_expediente: true,
//       editar: false,
//     });
//   }
// };
