import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// import SelectPacientesPaginado from "../SelectPacientesPaginado";
import SelectPacientesPaginado from "../SelectAsyncPaginate";

import MedicosContext from "../../context/Medicos/MedicosContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import TipoValeContext from "../../context/TipoVale/TipoValeContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import ValesContext from "../../context/Vales/ValesContext";
import ConfiguracionContext from "../../context/Configuracion/ConfiguracionContext";
import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";
import AuthContext from "../../context/Auth/AuthContext";
import ExpedienteMedicoContext from "../../context/ExpedienteMedico/ExpedienteMedicoContext";
import HojaIndicacionContext from "../../context/HojaIndicacion/HojaIndicacionContext";
import CatTipoServiciosContext from "../../context/CatTipoServicios/CatTipoServiciosContext";

import DrawerHojaIndicacion from "../DrawerHojaIndicacion";
import ProductosVale from "../ProductosVale";

import { opcionesSelectPacientes } from "../../config/configOpcionesSelect";

import { maxCampoPiezas } from "../../config/variables";

export default function NuevoVale(props) {
  //No se debe permitiar la busqueda de los insumos hasta declarar si es una aseguradora, o es de tipo personal
  const [disabledInsumo, setDisableInsumo] = useState(true);
  // const [disabledAseguradora, setDisabledAseguradora] = useState(true);
  const [productosServiciosFilter, setProductosServiciosFilter] = useState([]);
  const [principioActivo, setPrincipioActivo] = useState([]);
  const [principioActivoFilter, setPrincipioActivoFilter] = useState([]);
  const [concentraciones, setConcentraciones] = useState([]);
  const [concentracionesFilter, setConcentracionesFilter] = useState([]);
  const [ivaConfiguracion, setIvaConfiguracion] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [indexInsumoBorrado, setIndexInsumoBorrado] = useState(null);
  const [indexInsumoBorradoServicios, setIndexInsumoBorradoServicios] = useState(null);
  const [indexInsumoBorradoMedicamentos, setIndexInsumoBorradoMedicamentos] = useState(null);
  const [indexInsumoBorradoSoluciones, setIndexInsumoBorradoSoluciones] = useState(null);
  const [indexInsumoBorradoInsumos, setIndexInsumoBorradoInsumos] = useState(null);
  const [changedProductos, setChangedProductos] = useState(false);

  const [idCatTipoServicio, setIdCatTipoServicio] = useState(null);
  const [serviciosFiltradosPorTipoServicio, setServiciosFiltradosPorTipoServicio] = useState(null);

  const [tipoProductoEliminar, setTipoProductoEliminar] = useState("");

  const [pacienteSeleccionadoCompleto, setPacienteSeleccionadoCompleto] = useState(null);

  const [hojaIndicacionPaciente, setHojaIndicacionPaciente] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [tieneExpediente, setTieneExpediente] = useState(false);

  const [servicios, setServicios] = useState([]);
  const [medicamentos, setMedicamentos] = useState([]);
  const [soluciones, setSoluciones] = useState([]);
  const [insumos, setInsumos] = useState([]);

  const [UISweetAlert, setUISweetAlert] = useState({
    success_dlg: false,
    confirm_alert_delete_insumo: false,
    confirm_alert_upload_vale: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  const [loading, setLoading] = useState(false);

  const [archivosEsquema, setArchivosEsquema] = useState(null);

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxVales = useContext(ValesContext);
  const { crearNuevoVale, setNuevoVale, nuevoVale } = ctxVales;

  const catTipoServiciosContext = useContext(CatTipoServiciosContext);
  const { tipoServicios, listarCatTipoServicios } = catTipoServiciosContext;

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  //ACCESO AL ESTADO GLOBAL - Pacientes
  const ctxPacientes = useContext(PacientesContext);
  const { listarPacientes, loadPacientesSelect } = ctxPacientes;

  //ACCESO AL ESTADO GLOBAL - Aseguradoras
  const ctxAseguradoras = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradoras;

  //ACCESO AL ESTADO GLOBAL - Ususarios
  const ctxUsuarios = useContext(UsuariosContext);
  const { listarUsuarios } = ctxUsuarios;

  //ACCESO AL ESTADO GLOBAL - Tipo Vale
  const ctxTiposVale = useContext(TipoValeContext);
  const { tiposVale, listarTiposVale } = ctxTiposVale;

  //ACCESO AL ESTADO GLOBAL - ProductosServicios
  const ctxProductosServicios = useContext(ProductosServiciosContext);
  const { productosServicios, listarProductosServiciosPersonalDivididos, listarProductosServiciosAseguradoraDivididos } =
    ctxProductosServicios;

  // ACCESO A ESTADO GLOBAL - Configuracion
  const ctxConfiguracion = useContext(ConfiguracionContext);
  const { listarConfiguracion, configuracion } = ctxConfiguracion;

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const ctxExpedienteMedico = useContext(ExpedienteMedicoContext);
  const { getExpedienteMedicoMasReciente } = ctxExpedienteMedico;

  const ctxHojaIndicacion = useContext(HojaIndicacionContext);
  const { setHojaIndicacion } = ctxHojaIndicacion;

  const submitNuevoVale = async () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_upload_vale: false,
    });
    let productos = [...servicios, ...medicamentos, ...insumos, ...soluciones];
    let productosSinServicios = [...medicamentos, ...insumos, ...soluciones];

    setNuevoVale({
      ...nuevoVale,
      valeproductoservicios: productos,
    });
    const readyToSend = await validateForm(productos, productosSinServicios);

    if (readyToSend) {
      //Si pasa la validacion para crear un nuevo vale, se hace el POST
      setLoading(true);
      const res = await crearNuevoVale({ ...nuevoVale, valeproductoservicios: productos, ...archivosEsquema });

      if (res) {
        global.comentario = ""
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_alert_upload_vale: false,
          dynamic_description: "Se ha registrado un nuevo vale.",
          redirect: true,
          path: "/vales",
        });
      } else {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_alert_upload_vale: false,
          dynamic_title: "Fallo",
          dynamic_description: "Error al crear nuevo vale, Inténtalo por favor más tarde.",
        });
      }
    }
  };

  const validateForm = async (productos, productosSinServicios) => {
    let readyToSend = true;

    if (!nuevoVale.paciente) {
      // alert("Por favor, introduzca un paciente")
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_upload_vale: false,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "Por favor, introduzca un paciente",
      });
      readyToSend = false;
      return readyToSend;
    }

    if (!nuevoVale.medico) {
      // alert("Por favor, introduzca un medico")
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_upload_vale: false,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "Por favor, introduzca un médico",
      });
      readyToSend = false;
      return readyToSend;
    }

    if (!nuevoVale.esquemaTratamiento) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_upload_vale: false,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "Por favor, introduzca un esquema de tratamiento",
      });
      readyToSend = false;
      return readyToSend;
    }

    if (!nuevoVale.tipovale) {
      // alert("Por favor, introduzca un tipoVale")
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_upload_vale: false,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "Por favor, introduzca un tipo de vale",
      });
      readyToSend = false;
      return readyToSend;
    } else {
      if (nuevoVale.tipovale == 2 && !nuevoVale.aseguradora) {
        // alert("Por favor, introduzca un aseguradora")
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_upload_vale: false,
          error_dlg: true,
          dynamic_title: "Validación",
          dynamic_description: "Por favor, introduzca una aseguradora",
        });
        readyToSend = false;
        return readyToSend;
      }
    }

    if (!nuevoVale.usuarioSolicitante) {
      // alert("Por favor, introduzca un ususario")
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_upload_vale: false,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "Por favor, introduzca un usuario",
      });
      readyToSend = false;
      return readyToSend;
    }

    if (productos.length > 0) {
      let checarPorAlmenosUnServicio = false;
      let alMenosUnProducto = false;

      if (servicios && servicios.length > 0) {
        servicios.map((servicio) => {
          if (servicio.idCatServicio || servicio.descripcion) checarPorAlmenosUnServicio = true;

          if ((servicio.piezas == null || servicio.piezas < 1 || servicio.piezas == "") && readyToSend) {
            // alert("Por favor, complete la tabla")
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_upload_vale: false,
              error_dlg: true,
              dynamic_title: "Validación",
              dynamic_description: "Por favor complete el número de piezas, tiene que ser mayor a 0",
            });
            readyToSend = false;
            return readyToSend;
          }
        });
      }

      if (productosSinServicios && productosSinServicios.length > 0) {
        productosSinServicios.map((producto) => {
          if (producto.idCatProducto || producto.descripcion) {
            alMenosUnProducto = true;
          } else if (producto.concentracion && producto.principioActivo) {
            alMenosUnProducto = true;
          }

          if ((producto.piezas == null || producto.piezas < 1 || producto.piezas == "") && readyToSend) {
            // alert("Por favor, complete la tabla")
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_upload_vale: false,
              error_dlg: true,
              dynamic_title: "Validación",
              dynamic_description: "Por favor complete el número de piezas, tiene que ser mayor a 0",
            });
            readyToSend = false;
            return readyToSend;
          }
        });
      }

    } else {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_upload_vale: false,
        error_dlg: true,
        dynamic_title: "Validación",
        dynamic_description: "Por favor, introduzca al menos un servicio y un medicamento, insumo o solución",
      });
      readyToSend = false;
      return readyToSend;
    }

    return readyToSend;
  };

  function BuscaInsumoRepetido (descripcion, indexpar) {
        
    let repetido = false;
    insumos.map((producto, index) => {
        if(descripcion  == producto.descripcion && indexpar!=index){
            repetido = true;
        }
    })
    return repetido
  }

  const changeInsumoCat = async (e, index) => {
    let value = e.target.value;
    let meds = insumos;
    let insumo = insumos[index];
    insumo.descripcion = value;

    if (value.length > 0) {
      insumo.disabledPrincipioActivo = true;
    } else {
      insumo.disabledPrincipioActivo = false;
    }

    //Hacemos un filtrado de los insumos, en base a 5 campos, el nombreComercial del producto, el principioActivo del producto, el numIdentificacion del producto
    //el codigoEAN del producto y por ultimo el nombre del servicio. Todo lo hacemos lowerCase para la comparacion.
    const productosServiciosFiltrados = await productosServicios.insumos.filter((prodServ) => {
      if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.servicio != null && prodServ.servicio.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.principioActivo != null && prodServ.principioActivo.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.numIdentificacion != null && prodServ.numIdentificacion.startsWith(value, 0)) {
        return true;
      } else if (prodServ.codigoEAN != null && prodServ.codigoEAN.startsWith(value, 0)) {
        return true;
      }
    });

    //Una vez obtenido el arreglo de los insumos filtrados, los guardamos el State
    setProductosServiciosFilter(productosServiciosFiltrados);

    //Si el input, contiene un "-", significa, que ya escogimos un producto/servicio. Hay que obtener ese producto/servicio seleccionado de nuestra lista
    //pero los producto se puede dar el caso de tener el mismo nombreComercial, entonces se separa por el "-", y lo que esta despues del "-", es el numIdentificacion
    //que es unico para los productos
    if (value.includes("-")) {
      var lastIndex = value.lastIndexOf("-");

      const productoServicioSeleccionado = await productosServicios.insumos.filter((prodServ) => {
        //Si la busqueda incluye la palabra "SERVICIO", se selecciono un servicio
        //Para la busqueda del servicio, pedimos la cadena antes del "-"
        if (value.includes("SERVICIO") && prodServ.servicio != null && prodServ.servicio === value.slice(0, lastIndex - 1)) {
          return true;
        } else if ( 
          prodServ.numIdentificacion != null &&
          prodServ.numIdentificacion === value.slice(lastIndex + 1, value.length).replace(" ", "")
        ) {
          //prodServ.numIdentificacion.startsWith(value.slice(lastIndex+1, value.length).replace(" ", ""), 0))
          return true;
        }
      });

      if (productoServicioSeleccionado.length == 1) {
        //Cuando se pide el filtrado de un arreglo siempre se debe de referenciar con el [0]

        const productoRepetido = BuscaInsumoRepetido(value,index);
        if (productoRepetido) {
          insumo.idCatServicio = null;
          insumo.idCatProducto = null;
          insumo.precio = null;
          insumo.iva = 0;
          insumo.principioActivo = "";
          insumo.concentracion = "";
          // insumo.catTipoProducto = null;
          insumo.descripcion = "";

          setInsumos(meds);

          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Insumo repetido",
          });
        } else {
          //Una vez obtenido el producto/servicio seleccionado, se debe de asignar el insumo, el idCatProducto y principioActivo en caso de que sea un producto
          //y en el caso de que sea un servicio, solo se le asignado el idCatServicio
          if (productoServicioSeleccionado[0].idCatProducto != null) {
            insumo.idCatProducto = productoServicioSeleccionado[0].idCatProducto;
            insumo.principioActivo = productoServicioSeleccionado[0].principioActivo;
            insumo.concentracion = productoServicioSeleccionado[0].concentracion;
          } else if (productoServicioSeleccionado[0].idCatServicio != null) {
            insumo.idCatServicio = productoServicioSeleccionado[0].idCatServicio;
          }

          //Ademos debemos revisar el tipoVale, para saber si usaremos los precios de la aseguradora o de ventamostrador
          if (nuevoVale.tipovale == 1) {
            insumo.precio = productoServicioSeleccionado[0].precioProductoServicio[0].precioMostrador;
            if (productoServicioSeleccionado[0].causaIva) {
              insumo.iva = insumo.precio * ivaConfiguracion;
              //alert(insumo.iva);
            }
          } else if (nuevoVale.tipovale == 2) {
            insumo.precio = productoServicioSeleccionado[0].precioAseguradora[0].precioUnitario;
            if (productoServicioSeleccionado[0].causaIva) {
              insumo.iva = insumo.precio * ivaConfiguracion;
              //alert(insumo.iva);
            }
          }
          setInsumos(meds);
        }
      } else {
        //Mientras no se encuentre un solo producto/servicio, el insumo se mentiene limpio
        insumo.idCatServicio = null;
        insumo.idCatProducto = null;
        insumo.precio = null;
        insumo.iva = 0;
        insumo.principioActivo = "";
        insumo.concentracion = "";
        // insumo.catTipoProducto = null;

        setInsumos(meds);
      }
    } else {
      //Mientras no nos mande, el "-", se debe de limpiar el insumo
      insumo.idCatServicio = null;
      insumo.idCatProducto = null;
      insumo.precio = null;
      insumo.iva = 0;
      insumo.principioActivo = "";
      insumo.concentracion = "";
      // insumo.catTipoProducto = null;

      setInsumos(meds);
    }
  };

  function BuscaMedicamentoRepetido (descripcion, indexpar) {
        
    let repetido = false;
    medicamentos.map((producto, index) => {
        if(descripcion  == producto.descripcion  && indexpar!=index){
            repetido = true;
        }
    })
    return repetido
  }

  const changeInsumoCatMed = async (e, index) => {
    let value = e.target.value;
    let meds = medicamentos;
    let insumo = medicamentos[index];
    var lastIndextemp = value.lastIndexOf("-");
    let value2 =  value.slice(0,lastIndextemp - 1)
    insumo.descripcion = value;

    // if (value.length > 0) {
    //   insumo.disabledPrincipioActivo = true;
    // } else {
    //   insumo.disabledPrincipioActivo = false;
    // }
    //if(insumo.idCatTipoProducto!=2){
      //Hacemos un filtrado de los insumos, en base a 5 campos, el nombreComercial del producto, el principioActivo del producto, el numIdentificacion del producto
      //el codigoEAN del producto y por ultimo el nombre del servicio. Todo lo hacemos lowerCase para la comparacion.
      let encontrado = false;

      const productosServiciosFiltrados = await productosServicios.medicamentos.filter((prodServ) => {
        if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase() == value2.toLowerCase()) {
          encontrado = true;
          return true;
        }else  if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0)) {
          return true;
        } else if (prodServ.servicio != null && prodServ.servicio.toLowerCase().startsWith(value.toLowerCase(), 0)) {
          return true;
        } else if (prodServ.principioActivo != null && prodServ.principioActivo.toLowerCase().startsWith(value2.toLowerCase(), 0)) {
          return true;
        } else if (prodServ.numIdentificacion != null && prodServ.numIdentificacion.startsWith(value, 0)) {
          return true;
        } else if (prodServ.codigoEAN != null && prodServ.codigoEAN.startsWith(value, 0)) {
          return true;
        }
      });

      //Una vez obtenido el arreglo de los insumos filtrados, los guardamos el State
      setProductosServiciosFilter(productosServiciosFiltrados);
    //}


    if (encontrado) {
      var lastIndex = value.lastIndexOf("-");

      const productoServicioSeleccionado = await productosServicios.medicamentos.filter((prodServ) => {
        //Si la busqueda incluye la palabra "SERVICIO", se selecciono un servicio
        //Para la busqueda del servicio, pedimos la cadena antes del "-"
        if (value.includes("SERVICIO") && prodServ.servicio != null && prodServ.servicio === value.slice(0, lastIndex - 1)) {
          return true;
        } else if (
          prodServ.numIdentificacion != null &&
          prodServ.numIdentificacion === value.slice(lastIndex + 1, value.length).replace(" ", "")
        ) {
          //prodServ.numIdentificacion.startsWith(value.slice(lastIndex+1, value.length).replace(" ", ""), 0))
          return true;
        }
      });

      if (productoServicioSeleccionado.length == 1) {
        //Cuando se pide el filtrado de un arreglo siempre se debe de referenciar con el [0]

        const productoRepetido =  BuscaMedicamentoRepetido(value,index);
        if (productoRepetido) {
          insumo.idCatServicio = null;
          insumo.idCatProducto = null;
          insumo.precio = null;
          insumo.iva = 0;
          insumo.principioActivo = "";
          insumo.concentracion = "";
          // insumo.catTipoProducto = null;
          insumo.descripcion = "";

          setMedicamentos(meds);

          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Medicamento repetido",
          });
        } else {
          //Una vez obtenido el producto/servicio seleccionado, se debe de asignar el insumo, el idCatProducto y principioActivo en caso de que sea un producto
          //y en el caso de que sea un servicio, solo se le asignado el idCatServicio
          if (productoServicioSeleccionado[0].idCatProducto != null) {
            insumo.idCatProducto = productoServicioSeleccionado[0].idCatProducto;
            insumo.principioActivo = productoServicioSeleccionado[0].principioActivo;
            insumo.concentracion = productoServicioSeleccionado[0].concentracion;
          } else if (productoServicioSeleccionado[0].idCatServicio != null) {
            insumo.idCatServicio = productoServicioSeleccionado[0].idCatServicio;
          }

          //Ademos debemos revisar el tipoVale, para saber si usaremos los precios de la aseguradora o de ventamostrador
          if (nuevoVale.tipovale == 1) {
            insumo.precio = productoServicioSeleccionado[0].precioProductoServicio[0].precioMostrador;
            if (productoServicioSeleccionado[0].causaIva) {
              insumo.iva = insumo.precio * ivaConfiguracion;
              //alert(insumo.iva);
            }
          } else if (nuevoVale.tipovale == 2) {
            insumo.precio = productoServicioSeleccionado[0].precioAseguradora[0].precioUnitario;
            if (productoServicioSeleccionado[0].causaIva) {
              insumo.iva = insumo.precio * ivaConfiguracion;
              //alert(insumo.iva);
            }
          }
          setMedicamentos(meds);
          //llenarPrincipioActivos(prod.medicamentos);
        }
      } else {
        //Mientras no se encuentre un solo producto/servicio, el insumo se mentiene limpio
        insumo.idCatServicio = null;
        insumo.idCatProducto = null;
        insumo.precio = null;
        insumo.iva = 0;
        //insumo.principioActivo = "";
        //insumo.concentracion = "";
        // insumo.catTipoProducto = null;
        setMedicamentos(meds);
      }
    } else {
      //Mientras no nos mande, el "-", se debe de limpiar el insumo
      insumo.idCatServicio = null;
      insumo.idCatProducto = null;
      insumo.precio = null;
      insumo.iva = 0;
      //insumo.principioActivo = "";
      //insumo.concentracion = "";
      // insumo.catTipoProducto = null;
      setMedicamentos(meds);
    }
  };

  const changeInsumoCatSol = async (e, index) => {
    let value = e.target.value;
    let meds = soluciones;
    let insumo = soluciones[index];
    insumo.descripcion = value;

    if (value.length > 0) {
      insumo.disabledPrincipioActivo = true;
    } else {
      insumo.disabledPrincipioActivo = false;
    }

    //Hacemos un filtrado de los insumos, en base a 5 campos, el nombreComercial del producto, el principioActivo del producto, el numIdentificacion del producto
    //el codigoEAN del producto y por ultimo el nombre del servicio. Todo lo hacemos lowerCase para la comparacion.
    const productosServiciosFiltrados = await productosServicios.soluciones.filter((prodServ) => {
      if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.servicio != null && prodServ.servicio.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.principioActivo != null && prodServ.principioActivo.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.numIdentificacion != null && prodServ.numIdentificacion.startsWith(value, 0)) {
        return true;
      } else if (prodServ.codigoEAN != null && prodServ.codigoEAN.startsWith(value, 0)) {
        return true;
      }
    });

    //Una vez obtenido el arreglo de los insumos filtrados, los guardamos el State
    setProductosServiciosFilter(productosServiciosFiltrados);

    //Si el input, contiene un "-", significa, que ya escogimos un producto/servicio. Hay que obtener ese producto/servicio seleccionado de nuestra lista
    //pero los producto se puede dar el caso de tener el mismo nombreComercial, entonces se separa por el "-", y lo que esta despues del "-", es el numIdentificacion
    //que es unico para los productos
    if (value.includes("-")) {
      var lastIndex = value.lastIndexOf("-");

      const productoServicioSeleccionado = await productosServicios.soluciones.filter((prodServ) => {
        //Si la busqueda incluye la palabra "SERVICIO", se selecciono un servicio
        //Para la busqueda del servicio, pedimos la cadena antes del "-"
        if (value.includes("SERVICIO") && prodServ.servicio != null && prodServ.servicio === value.slice(0, lastIndex - 1)) {
          return true;
        } else if (
          prodServ.numIdentificacion != null &&
          prodServ.numIdentificacion === value.slice(lastIndex + 1, value.length).replace(" ", "")
        ) {
          //prodServ.numIdentificacion.startsWith(value.slice(lastIndex+1, value.length).replace(" ", ""), 0))
          return true;
        }
      });

      if (productoServicioSeleccionado.length == 1) {
        //Cuando se pide el filtrado de un arreglo siempre se debe de referenciar con el [0]

        const productoRepetido =  BuscaSolucionesRepetido(value,index);
        if (productoRepetido) {
          insumo.idCatServicio = null;
          insumo.idCatProducto = null;
          insumo.precio = null;
          insumo.iva = 0;
          insumo.principioActivo = "";
          insumo.concentracion = "";
          // insumo.catTipoProducto = null;
          insumo.descripcion = "";

          setSoluciones(meds);

          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Solución repetida",
          });
        } else {
          //Una vez obtenido el producto/servicio seleccionado, se debe de asignar el insumo, el idCatProducto y principioActivo en caso de que sea un producto
          //y en el caso de que sea un servicio, solo se le asignado el idCatServicio
          if (productoServicioSeleccionado[0].idCatProducto != null) {
            insumo.idCatProducto = productoServicioSeleccionado[0].idCatProducto;
            insumo.principioActivo = productoServicioSeleccionado[0].principioActivo;
            insumo.concentracion = productoServicioSeleccionado[0].concentracion;
          } else if (productoServicioSeleccionado[0].idCatServicio != null) {
            insumo.idCatServicio = productoServicioSeleccionado[0].idCatServicio;
          }
          insumo.nuevo =  false;
          //Ademos debemos revisar el tipoVale, para saber si usaremos los precios de la aseguradora o de ventamostrador
          if (nuevoVale.tipovale == 1) {
            insumo.precio = productoServicioSeleccionado[0].precioProductoServicio[0].precioMostrador;
            if (productoServicioSeleccionado[0].causaIva) {
              insumo.iva = insumo.precio * ivaConfiguracion;
              //alert(insumo.iva);
            }
          } else if (nuevoVale.tipovale == 2) {
            insumo.precio = productoServicioSeleccionado[0].precioAseguradora[0].precioUnitario;
            if (productoServicioSeleccionado[0].causaIva) {
              insumo.iva = insumo.precio * ivaConfiguracion;
             // alert(insumo.iva);
            }
          }
          setSoluciones(meds);
        }
      } else {
        //Mientras no se encuentre un solo producto/servicio, el insumo se mentiene limpio
        insumo.idCatServicio = null;
        insumo.idCatProducto = null;
        insumo.precio = null;
        insumo.iva = 0;
        insumo.principioActivo = "";
        insumo.concentracion = "";
        // insumo.catTipoProducto = null;

        setSoluciones(meds);
      }
    } else {
      //Mientras no nos mande, el "-", se debe de limpiar el insumo
      insumo.idCatServicio = null;
      insumo.idCatProducto = null;
      insumo.precio = null;
      insumo.iva = 0;
      insumo.principioActivo = "";
      insumo.concentracion = "";
      // insumo.catTipoProducto = null;

      setSoluciones(meds);
    }
  };

  function BuscaSolucionesRepetido (descripcion, indexpar) {
        
    let repetido = false;
    soluciones.map((producto, index) => {
        if(descripcion  == producto.descripcion && !producto.nuevo && indexpar!=index){
            repetido = true;
        }
    })
    return repetido
  }

  function BuscaServicioRepetido (descripcion, indexpar) {
        
    let repetido = false;
    servicios.map((producto, index) => {
        if(descripcion  == producto.descripcion  && indexpar!=index){
            repetido = true;
        }
    })
    return repetido
  }

  const changeInsumoServicio = async (e, index) => {
    let value = e.target.value;
    let meds = servicios;
    let insumo = servicios[index];
    insumo.descripcion = value;

    if (value.length > 0) {
      insumo.disabledPrincipioActivo = true;
    } else {
      insumo.disabledPrincipioActivo = false;
    }

    //Hacemos un filtrado de los insumos, en base a 5 campos, el nombreComercial del producto, el principioActivo del producto, el numIdentificacion del producto
    //el codigoEAN del producto y por ultimo el nombre del servicio. Todo lo hacemos lowerCase para la comparacion.
    let encontrado = false;
    const productosServiciosFiltrados = await productosServicios.servicios.filter((prodServ) => {
      if (prodServ.nombreComercial == null && prodServ.servicio.toLowerCase() == value.toLowerCase()) {
        encontrado = true;
        return true;
      } else       
      if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.servicio != null && prodServ.servicio.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.principioActivo != null && prodServ.principioActivo.toLowerCase().startsWith(value.toLowerCase(), 0)) {
        return true;
      } else if (prodServ.numIdentificacion != null && prodServ.numIdentificacion.startsWith(value, 0)) {
        return true;
      } else if (prodServ.codigoEAN != null && prodServ.codigoEAN.startsWith(value, 0)) {
        return true;
      }
    });

    //Una vez obtenido el arreglo de los insumos filtrados, los guardamos el State
    setProductosServiciosFilter(productosServiciosFiltrados);

    //Si el input, contiene un "-", significa, que ya escogimos un producto/servicio. Hay que obtener ese producto/servicio seleccionado de nuestra lista
    //pero los producto se puede dar el caso de tener el mismo nombreComercial, entonces se separa por el "-", y lo que esta despues del "-", es el numIdentificacion
    //que es unico para los productos
    if (value.includes("-") || encontrado) {
      var lastIndex = value.lastIndexOf("-");

      const productoServicioSeleccionado = await productosServicios.servicios.filter((prodServ) => {
        //Si la busqueda incluye la palabra "SERVICIO", se selecciono un servicio
        //Para la busqueda del servicio, pedimos la cadena antes del "-"
        if (prodServ.servicio != null && prodServ.servicio === value) {
          return true;
        } else if (
          prodServ.numIdentificacion != null &&
          prodServ.numIdentificacion === value.slice(lastIndex + 1, value.length).replace(" ", "")
        ) {
          //prodServ.numIdentificacion.startsWith(value.slice(lastIndex+1, value.length).replace(" ", ""), 0))
          return true;
        }
      });

      if (productoServicioSeleccionado.length == 1) {
        //Cuando se pide el filtrado de un arreglo siempre se debe de referenciar con el [0]

        const productoRepetido = BuscaServicioRepetido(value,index);
        
        if (productoRepetido) {
          insumo.idCatServicio = null;
          insumo.idCatProducto = null;
          insumo.precio = null;
          insumo.iva = 0;
          insumo.principioActivo = "";
          insumo.concentracion = "";
          // insumo.catTipoProducto = null;
          insumo.descripcion = "";

          setServicios(meds);

          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Servicio repetido",
          });
        } else {
          //Una vez obtenido el producto/servicio seleccionado, se debe de asignar el insumo, el idCatProducto y principioActivo en caso de que sea un producto
          //y en el caso de que sea un servicio, solo se le asignado el idCatServicio
          if (productoServicioSeleccionado[0].idCatProducto != null) {
            insumo.idCatProducto = productoServicioSeleccionado[0].idCatProducto;
            insumo.principioActivo = productoServicioSeleccionado[0].principioActivo;
            insumo.concentracion = productoServicioSeleccionado[0].concentracion;
          } else if (productoServicioSeleccionado[0].idCatServicio != null) {
            insumo.idCatServicio = productoServicioSeleccionado[0].idCatServicio;
          }

          //Ademos debemos revisar el tipoVale, para saber si usaremos los precios de la aseguradora o de ventamostrador
          if (nuevoVale.tipovale == 1) {
            insumo.precio = productoServicioSeleccionado[0].precioProductoServicio[0].precioMostrador;
            if (productoServicioSeleccionado[0].causaIva) {
              insumo.iva = insumo.precio * ivaConfiguracion;
             // alert(insumo.iva)
            }
          } else if (nuevoVale.tipovale == 2) {
            insumo.precio = productoServicioSeleccionado[0].precioAseguradora[0].precioUnitario;
            if (productoServicioSeleccionado[0].causaIva) {
              insumo.iva = insumo.precio * ivaConfiguracion;
             // alert(insumo.iva)
            }
          }
          setServicios(meds);
        }
      } else {
        //Mientras no se encuentre un solo producto/servicio, el insumo se mentiene limpio
        insumo.idCatServicio = null;
        insumo.idCatProducto = null;
        insumo.precio = null;
        insumo.iva = 0;
        insumo.principioActivo = "";
        insumo.concentracion = "";
        // insumo.catTipoProducto = null;

        setServicios(meds);
      }
    } else {
      //Mientras no nos mande, el "-", se debe de limpiar el insumo
      insumo.idCatServicio = null;
      insumo.idCatProducto = null;
      insumo.precio = null;
      insumo.iva = 0;
      insumo.principioActivo = "";
      insumo.concentracion = "";
      // insumo.catTipoProducto = null;

      setServicios(meds);
    }
  };

  const changePrincipioActivo = async (e, tipoProducto, index) => {
    let value = e.target.value;
    /* ****************************************************************************************************** */
    /* ****************************************************************************************************** */
    /* ********************* ACA VA DOND E SE MODIFICA EL PRINCIPIO ACTIVO ********************************** */
    /* ****************************************************************************************************** */
    /* ****************************************************************************************************** */
    switch (tipoProducto.toLowerCase().normalize()) {
      case "medicamento":
        let medTmp = medicamentos;
        let medicamentoSeleccionado = medTmp[index];
        let encontrado = false;

        medicamentoSeleccionado.principioActivo = value;

        if(value==''){
          setPrincipioActivoFilter(principioActivo);
        }else{
          const principioActivoFilterMed = await principioActivo.filter((principioActivoInterior) =>
          principioActivoInterior.toLowerCase().startsWith(value.toLowerCase(), 0)
          );       
          setPrincipioActivoFilter(principioActivoFilterMed);
        }

        const foundMed = await productosServicios.medicamentos.find((element) => {
          if(value.toLowerCase() ==element.principioActivo.toLowerCase()){
              encontrado = true;
              return true;             
          }
          //return element.principioActivo.toLowerCase().startsWith(value, 0);
          //if(value.toLowerCase() ==element.principioActivo.toLowerCase().startsWith(value, 0)){
        });

        /*const foundMed = await productosServicios.medicamentos.find((element) => {
          return element.principioActivo === value;
        });*/

        if (foundMed && encontrado) {
          var concentraciones = [];
          productosServicios.medicamentos.map((prodServ) => {
            if (prodServ.principioActivo == value) {
              concentraciones.push(prodServ.concentracion);
            }
            concentraciones = [...new Set(concentraciones)];
          });
          const nuevaLista = [...concentraciones].sort();  // Copiar y ordenar la lista

          setConcentraciones(nuevaLista);
          setConcentracionesFilter(nuevaLista);
        }else{
          medicamentoSeleccionado.descripcion = "";
          medicamentoSeleccionado.concentracion = "";
          medicamentoSeleccionado.idCatProducto = 0;
        }
        setMedicamentos(medTmp);
        break;

      default:
    }
  };

  const changeInsumoPiezas = async (e, tipoProducto, index) => {
    let value = e.target.value;
    if (value?.length <= maxCampoPiezas) {
      switch (tipoProducto.toLowerCase().normalize()) {
        case "servicio":
          let svcs = servicios;
          let servicio = svcs[index];
          // if (value < 0) value = value * -1;
          servicio.piezas = value;
          setServicios(svcs);
          setChangedProductos(!changedProductos);
          break;
        case "medicamento":
          let meds = medicamentos;
          let med = meds[index];
          // if (value < 0) value = value * -1;
          med.piezas = value;
          setMedicamentos(meds);
          setChangedProductos(!changedProductos);
          break;
        case "solución":
          let sols = soluciones;
          let sol = sols[index];
          // if (value < 0) value = value * -1;
          sol.piezas = value;
          setSoluciones(sols);
          setChangedProductos(!changedProductos);
          break;
        case "insumo":
          let ins = insumos;
          let insumo = ins[index];
          // if (value < 0) value = value * -1;
          insumo.piezas = value;
          setInsumos(ins);

          setChangedProductos(!changedProductos);
          break;
        default:
      }
    }
  };

  const eliminarInsumo = async () => {
    switch (tipoProductoEliminar.toLowerCase().normalize()) {
      case "servicio":
        let serviciosActualizados = servicios;
        if (indexInsumoBorradoServicios > -1) {
          serviciosActualizados.splice(indexInsumoBorradoServicios, 1);
          setServicios(serviciosActualizados);
        }
        setIndexInsumoBorradoServicios(null);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Borrado",
          confirm_alert_delete_insumo: false,
          dynamic_description: "El servicio ha sido borrado correctamente.",
        });
        setTipoProductoEliminar("");
        break;
      case "medicamento":
        let medicamentosActualizados = medicamentos;
        if (indexInsumoBorradoMedicamentos > -1) {
          medicamentosActualizados.splice(indexInsumoBorradoMedicamentos, 1);
          setMedicamentos(medicamentosActualizados);
        }
        setIndexInsumoBorradoMedicamentos(null);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Borrado",
          confirm_alert_delete_insumo: false,
          dynamic_description: "El medicamento ha sido borrado correctamente.",
        });
        setTipoProductoEliminar("");
        break;
      case "solución":
        let solucionesActualizadas = soluciones;
        if (indexInsumoBorradoSoluciones > -1) {
          solucionesActualizadas.splice(indexInsumoBorradoSoluciones, 1);
          setSoluciones(solucionesActualizadas);
        }
        setIndexInsumoBorradoSoluciones(null);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Borrado",
          confirm_alert_delete_insumo: false,
          dynamic_description: "La solución ha sido borrada correctamente.",
        });
        setTipoProductoEliminar("");
        break;
      case "insumo":
        let insumosActualizados = insumos;
        if (indexInsumoBorradoInsumos > -1) {
          insumosActualizados.splice(indexInsumoBorradoInsumos, 1);
          setInsumos(insumosActualizados);
        }
        setIndexInsumoBorradoInsumos(null);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Borrado",
          confirm_alert_delete_insumo: false,
          dynamic_description: "El insumo ha sido borrado correctamente.",
        });
        setTipoProductoEliminar("");
        break;
      default:
    }
  };

  const nuevoRenglonServicios = () => {
    const nuevoServicios = {
      idCatProducto: null,
      idCatServicio: null,
      piezas: 0,
      precio: null,
      iva: 0,
      principioActivo: "",
      concentracion: "",
      idCatTipoProducto: 3,
      descripcion: "",
      disabledPrincipioActivo: false,
      disabledInsumo: false,
    };
    setServicios([...servicios, nuevoServicios]);
  };

  const nuevoRenglonMedicamentos = () => {
    const nuevoMedicamento = {
      idCatProducto: null,
      idCatServicio: null,
      piezas: 0,
      precio: null,
      iva: 0,
      principioActivo: "",
      concentracion: "",
      idCatTipoProducto: 2,
      descripcion: "",
      disabledPrincipioActivo: false,
      disabledInsumo: false,
    };
    setMedicamentos([...medicamentos, nuevoMedicamento]);
    // setNuevoVale({
    //   ...nuevoVale,
    //   valeproductoservicios: nuevoVale.valeproductoservicios.concat(nuevoInsumo),
    // });
  };

  const nuevoRenglonSoluciones = () => {
    const nuevaSolucion = {
      idCatProducto: null,
      idCatServicio: null,
      piezas: 0,
      precio: null,
      iva: 0,
      principioActivo: "",
      concentracion: "",
      idCatTipoProducto: 4,
      descripcion: "",
      disabledPrincipioActivo: false,
      disabledInsumo: false,
      nuevo:true,
    };
    setSoluciones([...soluciones, nuevaSolucion]);
  };

  const nuevoRenglonInsumos = () => {
    const nuevoInsumo = {
      idCatProducto: null,
      idCatServicio: null,
      piezas: 0,
      precio: null,
      iva: 0,
      principioActivo: "",
      concentracion: "",
      idCatTipoProducto: 1,
      descripcion: "",
      disabledPrincipioActivo: false,
      disabledInsumo: false,
    };
    setInsumos([...insumos, nuevoInsumo]);
  };

  const onChange = async (e) => {
    setNuevoVale({
      ...nuevoVale,
      [e.target.name]: e.target.value,
    });
  };

  const onChangePaciente = async (e) => {
    if (ivaConfiguracion == 0 ){
       listarConfiguracion();

      let ivaTemp =0.0;
      // alert("ANTES DE BUSCARLO: " + ivaTemp + "  cantidad de cnfiguraciones: " + configuracion.length);
      if (configuracion.length >= 1 ) {
        configuracion.map((config) => {
          
          if (config.clave == "IVA") {
            ivaTemp = parseFloat(config.valor / 100);
            //alert("IVA ENCONTRADO: " + config.clave);
          }
        });
      }
      //alert("DESPUES DE BUSCARLO: " + ivaTemp);
      setIvaConfiguracion(ivaTemp);
    }

    setLoading(true);
    setHojaIndicacionPaciente(null);
    setTieneExpediente(false);
    cleanValeProductoServicio();
    let value;
    setPacienteSeleccionadoCompleto(e);
    if (e && e.idCatPaciente) {
      value = e.idCatPaciente;

      if (value != "") {
        setDisableInsumo(false);
        // let paciente;
        // try {
        //   paciente = await pacientes.filter((pac) => pac.idCatPaciente == value)[0];
        // } catch (error) {
        //   console.log(error);
        // }

        let expedienteMedicoPaciente = null;
        expedienteMedicoPaciente = await getExpedienteMedicoMasReciente(value);

        try {
          if (
            expedienteMedicoPaciente &&
            expedienteMedicoPaciente.catAseguradora &&
            expedienteMedicoPaciente.catAseguradora.idCatAseguradora
          ) {
            // listarProductosServiciosAseguradora(paciente.expedienteMedico.idCatAseguradora);
            try {
              const prod = await listarProductosServiciosAseguradoraDivididos(
                expedienteMedicoPaciente.catAseguradora.idCatAseguradora
              );

              llenarPrincipioActivos(prod.medicamentos);
            } catch (error) {
              setLoading(false);
            }
          } else {
            // const prod = await listarProductosServiciosPersonal();
            try {
              const prod = await listarProductosServiciosPersonalDivididos();

              llenarPrincipioActivos(prod.medicamentos);
            } catch (error) {
              setLoading(false);
            }
          }
        } catch (error) {
          setLoading(false);
        }

        setLoading(false);
        let hojaIndicacion = null;
        let esquemaTratamiento = "";

        if (
          expedienteMedicoPaciente &&
          expedienteMedicoPaciente.idExpedienteMedico &&
          expedienteMedicoPaciente.hojaIndicacion.length > 0
        ) {
          const res = await setHojaIndicacion(expedienteMedicoPaciente.idExpedienteMedico);

          hojaIndicacion = res;
        }

        if (hojaIndicacion) {
          setHojaIndicacionPaciente(hojaIndicacion);

          esquemaTratamiento =
            "Vía:  " +
            (hojaIndicacion.otra ? hojaIndicacion.otra : "") +
            "  Ciclo:  " +
            (hojaIndicacion.ciclo ? hojaIndicacion.ciclo : "") +
            "  Ciclos:  " +
            (hojaIndicacion.ciclos ? hojaIndicacion.ciclos : "") +
            "  fecha 1:  " +
            (hojaIndicacion.fecha1 ? hojaIndicacion.fecha1 : "") +
            "  fecha 2:  " +
            (hojaIndicacion.fecha2 ? hojaIndicacion.fecha2 : "") +
            "  fecha 3:  " +
            (hojaIndicacion.fecha3 ? hojaIndicacion.fecha3 : "") +
            "  fecha 4:  " +
            (hojaIndicacion.fecha4 ? hojaIndicacion.fecha4 : "") +
            "  fecha 5:  " +
            (hojaIndicacion.fecha5 ? hojaIndicacion.fecha5 : "") +
            "  fecha 6:  " +
            (hojaIndicacion.fecha6 ? hojaIndicacion.fecha6 : "") +
            "  fecha 7:  " +
            (hojaIndicacion.fecha7 ? hojaIndicacion.fecha7 : "") +
            "  fecha 8:  " +
            (hojaIndicacion.fecha8 ? hojaIndicacion.fecha8 : "") +
            "  fecha 9:  " +
            (hojaIndicacion.fecha9 ? hojaIndicacion.fecha9 : "") +
            "  fecha 10:  " +
            (hojaIndicacion.fecha10 ? hojaIndicacion.fecha10 : "");
        }

        setNuevoVale({
          ...nuevoVale,
          esquemaTratamiento: esquemaTratamiento,
          paciente: value,
          tipovale: expedienteMedicoPaciente.catAseguradora && expedienteMedicoPaciente.catAseguradora.idCatAseguradora ? 2 : 1,
          aseguradora:
            expedienteMedicoPaciente.catAseguradora && expedienteMedicoPaciente.catAseguradora.idCatAseguradora
              ? expedienteMedicoPaciente.catAseguradora.idCatAseguradora
              : "",
          medico:
            hojaIndicacion && hojaIndicacion.catMedico && hojaIndicacion.catMedico.idCatMedico
              ? hojaIndicacion.catMedico.idCatMedico
              : "",
        });
      } else {
        setLoading(false);
        setDisableInsumo(true);
        setNuevoVale({
          ...nuevoVale,
          tipovale: "",
          aseguradora: "",
          medico: "",
          paciente: value,
          esquemaTratamiento: "",
        });
      }
    } else {
      setLoading(false);
      setNuevoVale({
        ...nuevoVale,
        tipovale: "",
        aseguradora: "",
        medico: "",
        paciente: "",
        esquemaTratamiento: "",
      });
      setPacienteSeleccionadoCompleto(null);
    }
  };

  const cleanValeProductoServicio = async () => {
    setNuevoVale({
      ...nuevoVale,
      valeproductoservicios: [],
      comentario:"",
    });
  };

  const changeConcentracion = async (e, index) => {
    let value = e.target.value;

    let valeproductoservicios = medicamentos;
    let insumo = valeproductoservicios[index];
    insumo.concentracion = value;

    //Para la busqueda del principio activo, buscamos del listado de principioActivo, y todo lo hacemos con LowerCase
    const concentracionFilter = await concentraciones.filter((concentracion) => {
      if (concentracion) {
        return concentracion.startsWith(value, 0);
      }
    });
    // const concentracionFilter = await concentraciones.filter((concentracion) =>  concentracion.startsWith(value, 0));

    setConcentracionesFilter(concentracionFilter);

    setMedicamentos(valeproductoservicios);
  };

  const llenarPrincipioActivos = async (medicamentos) => {
    let principiosactivos = [];

    medicamentos &&
      medicamentos.map((prodServ) => {
        if (prodServ.principioActivo != null) {
          principiosactivos.push(prodServ.principioActivo);
        }
        principiosactivos = [...new Set(principiosactivos)];
      });

    const nuevaLista = [...principiosactivos].sort();  // Copiar y ordenar la lista
     
    setPrincipioActivo(nuevaLista);
    setPrincipioActivoFilter(nuevaLista);
  };

  useEffect(() => {
    global.comentarioGlobal  = "";
    cleanValeProductoServicio();

    const fetchAll = async () => {
      listarConfiguracion();
      listarMedicos();
      listarPacientes();
      listarAseguradoras();
      listarUsuarios();
      listarTiposVale();
      listarCatTipoServicios();
    };

    fetchAll();  

    if (usuario && !nuevoVale.usuarioSolicitante) {
      setNuevoVale({
        ...nuevoVale,
        usuarioSolicitante: usuario.idCatUsuario,
      });
    }
    let ivaTemp =0.0;
    //alert("ANTES DE BUSCARLO: " + ivaTemp + "  cantidad de cnfiguraciones: " + configuracion.length);
    if (configuracion.length >= 1 && ivaConfiguracion == 0) {
      configuracion.map((config) => {
        if (config.clave == "IVA") {
          ivaTemp = parseFloat(config.valor / 100);
          //alert("IVA ENCONTRADO: " + config.clave);
        }
      });
    }
    //alert("DESPUES DE BUSCARLO: " + ivaTemp);
    setIvaConfiguracion(ivaTemp);
  }, []);

  // useEffect(() => {
  //   loadPacientesSelect(0, 30);
  // }, []);

  const uploadFile = async (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        let strFile = reader.result;
        let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
        let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);

        let nuevoArchivo = {
          nombreArchivo: file.name,
          mimeType: type,
          tamano: file.size,
          inputStream: fileBase64,
        };
        // let archivosTemp = archivosEsquema;
        // archivosTemp.concat(nuevoArchivo);
        setArchivosEsquema(nuevoArchivo);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const limpiarArchivos = async () => {
    setArchivosEsquema([]);
  };

  const changeTipoServicio = (e) => {
    const { value } = e.target;
    setIdCatTipoServicio(value);
    filterServicios(value);
  };

  const filterServicios = (value) => {
    if (value == null || value == "") {
      setServiciosFiltradosPorTipoServicio(null);
      return;
    }
    const serviciosFiltrados =
      productosServicios && productosServicios.servicios.filter((servicio) => value == servicio.idCatTipoServicio);

    setServiciosFiltradosPorTipoServicio(serviciosFiltrados);
    return serviciosFiltrados;
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Solicitud de vales</h3>

      <Card
        hidden=""
        className="mt-10 card"
        style={{
          padding: "1.5rem 1.5rem 1.5rem",
        }}>
        <form>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">
                    Paciente<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <SelectPacientesPaginado
                      opcionSeleccionadaCompleta={pacienteSeleccionadoCompleto}
                      onChangeOptions={onChangePaciente}
                      fetchOptions={loadPacientesSelect}
                      opcionesPaginado={opcionesSelectPacientes.opcionesPaginado}
                      placeholderText={opcionesSelectPacientes.placeholderText}
                      optionLabel={opcionesSelectPacientes.optionLabel}
                      optionValue={opcionesSelectPacientes.optionValue}
                    />
                    {/* <select className="form-control" name="paciente" id="paciente" onChange={onChange} value={nuevoVale.paciente}>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {pacientes.map((paciente) => {
                        return (
                          <>
                            <option
                              value={
                                paciente.idCatPaciente
                              }>{`${paciente.nombres} ${paciente.apellidoPaterno} ${paciente.apellidoMaterno}`}</option>
                          </>
                        );
                      })}
                    </select> */}
                    {(nuevoVale.paciente === "" || !nuevoVale.paciente) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">Tipo Vale</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="tipovale"
                      id="tipovale"
                      onChange={onChange}
                      disabled={true}
                      value={nuevoVale.tipovale ? nuevoVale.tipovale : ""}>
                      <option value="">Seleccione una opción</option>
                      {tiposVale.map((tipoVale) => {
                        return (
                          <>
                            <option key={tipoVale.idCatTipoVale} value={tipoVale.idCatTipoVale}>
                              {tipoVale.tipoVale}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">Aseguradora</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      disabled={true}
                      value={nuevoVale.aseguradora ? nuevoVale.aseguradora : ""}
                      name="aseguradora"
                      id="aseguradora"
                      onChange={onChange}>
                      <option value="">SIN ASEGURADORA</option>
                      {aseguradoras.map((aseguradora) => {
                        return (
                          <>
                            <option key={aseguradora.idCatAseguradora} value={aseguradora.idCatAseguradora}>
                              {aseguradora.razonSocial}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">Usuario Solicitante</label>
                  <Col sm={7}>
                    <input type="text" value={usuario.nombre} className="form-control" disabled={true} />
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">
                    Médico Tratante<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="medico"
                      id="medico"
                      value={nuevoVale.medico ? nuevoVale.medico : ""}
                      onChange={onChange}>
                      <option value="">Seleccione una opción</option>
                      {medicos.map((medico) => {
                        return (
                          <>
                            <option key={medico.idCatMedico} value={medico.idCatMedico}>{`${
                              medico.prefijo ? medico.prefijo : ""
                            } ${medico.catPersona.nombres} ${medico.catPersona.apellidoPaterno} ${
                              medico.catPersona.apellidoMaterno
                            }`}</option>
                          </>
                        );
                      })}
                    </select>
                    {(nuevoVale.medico === "" || !nuevoVale.medico) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>                
              </Col>
              <Col xl={6} lg={12} md={12}>

                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">
                    Esquema de Tratamiento<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <textarea
                      autoComplete="off" 
                      className="form-control"
                      name="esquemaTratamiento"
                      onChange={onChange}
                      value={nuevoVale.esquemaTratamiento}
                    />
                    {(nuevoVale.esquemaTratamiento === "" || !nuevoVale.esquemaTratamiento) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">Subir evidencia esquema</label>
                  <Col sm={7}>
                    <input
                      className="form-control"
                      type="file"
                      onClick={limpiarArchivos}
                      onChange={(e) => uploadFile(e)}
                      multiple={false}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <label className="col-sm-5 col-form-label"></label>
                  <Col sm={7}>
                    {nuevoVale.paciente && hojaIndicacionPaciente ? (
                      <DrawerHojaIndicacion hojaIndicacion={hojaIndicacionPaciente} paciente={nuevoVale.paciente} />
                    ) : (
                      "El paciente seleccionado no tiene hoja de indicacón"
                    )}
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-5 col-form-label">
                    Comentarios<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <textarea
                      autoComplete="off" 
                      className="form-control"
                      name="comentario"
                      onChange={onChange}
                      value={nuevoVale.comentario}
                    />
                  </Col>
                </Row>

              </Col>
            </Row>
          </CardBody>
        </form>
      </Card>
      <h4 className="h3 mt-4 mb-4">Servicios</h4>
      <Row className="form-group mt-4">
        <label className="col-sm-2 col-form-label">Tipo de Servicio</label>
        <Col sm={5}>
          <select
            className="form-control"
            disabled={disabledInsumo}
            name="idCatTipoServicio"
            onChange={changeTipoServicio}
            value={idCatTipoServicio ? idCatTipoServicio : ""}>
            <option value="">Sin Tipo de Servicio</option>
            {tipoServicios.map((tipoServicio) => {
              return (
                <option
                  key={tipoServicio.idCatTipoServicio}
                  value={tipoServicio.idCatTipoServicio}>{`${tipoServicio.tipoServicio}`}</option>
              );
            })}
          </select>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            {/* ---------- Servicios ---------- */}
            <ProductosVale
              changedProductos={changedProductos}
              productosServicios={productosServicios.servicios}
              productos={servicios}
              disabledInsumo={disabledInsumo}
              changePrincipioActivo={changePrincipioActivo}
              principioActivoFilter={principioActivoFilter}
              changeConcentracion={changeConcentracion}
              concentracionesFilter={concentracionesFilter}
              changeInsumoCat={changeInsumoServicio}
              productosServiciosFilter={productosServiciosFilter}
              changeInsumoPiezas={changeInsumoPiezas}
              setIndexInsumoBorrado={setIndexInsumoBorradoServicios}
              setUISweetAlert={setUISweetAlert}
              UISweetAlert={UISweetAlert}
              tipoProducto={"Servicio"}
              nuevoRenglon={nuevoRenglonServicios}
              setTipoProductoEliminar={setTipoProductoEliminar}
              serviciosFiltradosPorTipoServicio={serviciosFiltradosPorTipoServicio}
            />
          </Card>
        </Col>
      </Row>
      <h4 className="h3 mt-4 mb-4">Medicamentos</h4>
      <Row className="mt-3">
        <Col>
          <Card>
            {/* ---------- Medicamentos ---------- */}
            <ProductosVale
              changedProductos={changedProductos}
              productosServicios={productosServicios.medicamentos}
              productos={medicamentos}
              disabledInsumo={disabledInsumo}
              changePrincipioActivo={changePrincipioActivo}
              principioActivoFilter={principioActivoFilter}
              changeConcentracion={changeConcentracion}
              concentracionesFilter={concentracionesFilter}
              changeInsumoCat={changeInsumoCatMed}
              productosServiciosFilter={productosServiciosFilter}
              changeInsumoPiezas={changeInsumoPiezas}
              setIndexInsumoBorrado={setIndexInsumoBorradoMedicamentos}
              setUISweetAlert={setUISweetAlert}
              UISweetAlert={UISweetAlert}
              tipoProducto={"Medicamento"}
              nuevoRenglon={nuevoRenglonMedicamentos}
              setTipoProductoEliminar={setTipoProductoEliminar}
            />
          </Card>
        </Col>
      </Row>
      <h4 className="h3 mt-4 mb-4">Soluciones</h4>
      <Row className="mt-3">
        <Col>
          <Card>
            {/* ---------- Soluciones ---------- */}
            <ProductosVale
              changedProductos={changedProductos}
              productosServicios={productosServicios.soluciones}
              productos={soluciones}
              disabledInsumo={disabledInsumo}
              changePrincipioActivo={changePrincipioActivo}
              principioActivoFilter={principioActivoFilter}
              changeConcentracion={changeConcentracion}
              concentracionesFilter={concentracionesFilter}
              changeInsumoCat={changeInsumoCatSol}
              productosServiciosFilter={productosServiciosFilter}
              changeInsumoPiezas={changeInsumoPiezas}
              setIndexInsumoBorrado={setIndexInsumoBorradoSoluciones}
              setUISweetAlert={setUISweetAlert}
              UISweetAlert={UISweetAlert}
              tipoProducto={"Solución"}
              nuevoRenglon={nuevoRenglonSoluciones}
              setTipoProductoEliminar={setTipoProductoEliminar}
            />
          </Card>
        </Col>
      </Row>
      <h4 className="h3 mt-4 mb-4">Insumos</h4>
      <Row className="mt-3">
        <Col>
          <Card>
            {/* ---------- Insumos ---------- */}
            <ProductosVale
              changedProductos={changedProductos}
              productosServicios={productosServicios.insumos}
              productos={insumos}
              disabledInsumo={disabledInsumo}
              changePrincipioActivo={changePrincipioActivo}
              principioActivoFilter={principioActivoFilter}
              changeConcentracion={changeConcentracion}
              concentracionesFilter={concentracionesFilter}
              changeInsumoCat={changeInsumoCat}
              productosServiciosFilter={productosServiciosFilter}
              changeInsumoPiezas={changeInsumoPiezas}
              setIndexInsumoBorrado={setIndexInsumoBorradoInsumos}
              setUISweetAlert={setUISweetAlert}
              UISweetAlert={UISweetAlert}
              tipoProducto={"Insumo"}
              nuevoRenglon={nuevoRenglonInsumos}
              setTipoProductoEliminar={setTipoProductoEliminar}
            />
          </Card>

          <div>
            <button
              // disabled={!tieneExpediente}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setUISweetAlert({
                  ...UISweetAlert,
                  confirm_alert_upload_vale: true,
                });
              }}
              className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
              Solicitar
            </button>
          </div>
        </Col>
      </Row>

      {UISweetAlert.confirm_alert_upload_vale ? (
        <SweetAlert
          title="¿Desea solicitar un nuevo vale?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => submitNuevoVale()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_upload_vale: false,
            })
          }>
          Se solicitará un nuevo vale con la información proporcionada
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_delete_insumo ? (
        <SweetAlert
          title={`¿Desea eliminar el ${tipoProductoEliminar}?`}
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarInsumo()}
          onCancel={() => {
            setIndexInsumoBorrado(null);
            setIndexInsumoBorradoInsumos(null);
            setIndexInsumoBorradoServicios(null);
            setIndexInsumoBorradoMedicamentos(null);
            setIndexInsumoBorradoSoluciones(null);
            setTipoProductoEliminar("");
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_delete_insumo: false,
            });
          }}>
          Se perderá la información
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              setNuevoVale({ valeproductoservicios: [] });
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
