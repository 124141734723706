import React, {forwardRef} from 'react';
import formatNumber from "../../utils";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import {Line} from 'react-chartjs-2';
import {lineChartOptions} from "../../config/chartOptions";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChart = forwardRef((props, ref) => {
    const shouldDisplayDataLabels = window.innerWidth > 768; // Adjust the breakpoint as needed

    const updatedOptions = {
        ...lineChartOptions,
        plugins: {
            ...lineChartOptions.plugins,
            title: {
                ...lineChartOptions.plugins.title,
                text: props.title,
                padding: {
                    bottom: 20,
                },
                backgroundColor: 'white',
            },
            legend: {
                display: props.displayLabel,
                position: "bottom",
            },
            datalabels: {
                anchor: 'end',
                display: shouldDisplayDataLabels && props.displayDataLabels ? 'auto' : false,
                align: 'top',
                formatter: (value) => props.requiresMoneyFormat ? formatNumber(value.y) : value.y,
                color: 'rgb(40,40,40)',
                font: {
                    size: shouldDisplayDataLabels ? 14 : 10,
                    weight: 'bold'
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return props.requiresMoneyFormat ? tooltipItem.dataset.label + ": " + formatNumber(tooltipItem.raw.y) : tooltipItem.raw.y
                    }
                }
            }
        },
        scales: {
            y: {
                display: props.displayYAxis,
                ticks: {
                    callback: function (val, index) {
                        return props.requiresMoneyFormat ? formatNumber(val) : val
                    },
                },
            },
        },
    };

    return (

        <Line ref={ref} options={updatedOptions} data={props.data} style={{height: '700px'}}/>
    )
})

export default LineChart;