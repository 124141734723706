import React, { useContext, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import useWindowDimensions from "../../hooks/dimensionsHook";

import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import CatTipoInterconsultaContext from "../../context/CatTipoInterconsulta/CatTipoInterconsultaContext";
import NotaInterconsultaContext from "../../context/NotaInterconsulta/NotaInterconsultaContext";
import ExpedienteMedicoContext from "../../context/ExpedienteMedico/ExpedienteMedicoContext";

import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import SexosContext from "../../context/Sexos/SexosContext";

import AuthContext from "../../context/Auth/AuthContext";

import DrawerExpedientePaciente from "../DrawerExpedientePaciente/index";

export default function ConsultaSeguimiento(props) {
  const { width } = useWindowDimensions();

  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const expedienteMedicoContext = useContext(ExpedienteMedicoContext);
  const { exportArchivoBack, imprimirExpedienteMedico } = expedienteMedicoContext;

  const [historiaClinicaInicialVacia, setHistoriaClinicaInicialVacia] = useState(true);

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { historiaClinicaInicial, seleccionarHistoriaClinicaInicialPaciente, setHistoriaClinicaInicial } = ctxHistClinInic;

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        seleccionarHistoriaClinicaInicialPaciente(idCatPacienteSeleccionado);
      } else {
        setHistoriaClinicaInicial({});
      }
      setHistoriaClinicaInicialVacia(false);
    }
  }, []);

  useEffect(() => {
    if (historiaClinicaInicialVacia) {
      if (idCatPacienteSeleccionado) {
        listarNotasInterconsulta(idCatPacienteSeleccionado);
      } else {
        //redirect no se puede crear una nota para un paciente nuevo
      }
      setHistoriaClinicaInicialVacia(false);
    }
    if (tipoInterconsultas.length < 1) {
      listarTipoInterconsultas();
    }
  }, []);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_nueva_consulta: false,
    confirm_alert_documentos: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });
  const [loading, setLoading] = useState(false);

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { medicos, listarMedicos } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  useEffect(() => {
    const fetchAll = () => {
      listarSexos();
      listarAseguradoras();
      listarEscolaridades();
      listarEstadosCiviles();
      listarLugares();
      listarOcupaciones();
      listarMedicos();
    };
    fetchAll();
  }, []);

  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado } = pacientesContext;

  const notaInterContext = useContext(NotaInterconsultaContext);
  const { notasInterconsulta, listarNotasInterconsulta, uploadNuevaNotaInterconsulta } = notaInterContext;

  const catTipoInterContext = useContext(CatTipoInterconsultaContext);
  const { tipoInterconsultas, listarTipoInterconsultas } = catTipoInterContext;

  const cleanNuevaNotaInter = {
    idCatPaciente: idCatPacienteSeleccionado,
  };
  const [nuevaNotaInterconsulta, setNuevaNotaInterconsulta] = useState(cleanNuevaNotaInter);
  const [idInterconsultaSeleccionada, setIdInterconsultaSeleccionada] = useState(null);

  const redirectHistoriaClinicaInicial = async () => {
    props.history.push("/historia/clinica/inicial");
  };

  const onChangeNuevaNota = async (e) => {
    setNuevaNotaInterconsulta({
      ...nuevaNotaInterconsulta,
      [e.target.name]: e.target.value,
    });
  };

  const uploadFile = async (e) => {
    const files = e.target.files;
    if (!nuevaNotaInterconsulta.archivos) {
      nuevaNotaInterconsulta.archivos = [];
    }

    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          let strFile = reader.result;
          let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
          let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);

          let nuevoArchivo = {
            nombreArchivo: file.name,
            mimeType: type,
            tamano: file.size,
            inputStream: fileBase64,
          };

          nuevaNotaInterconsulta.archivos = nuevaNotaInterconsulta.archivos.concat(nuevoArchivo);
          setNuevaNotaInterconsulta(nuevaNotaInterconsulta);
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const limpiarArchivos = async () => {
    nuevaNotaInterconsulta.archivos = [];
    setNuevaNotaInterconsulta(nuevaNotaInterconsulta);
  };

  const uploadNuevaNotaConfirm = async () => {
    let errorNuevaNota = false;

    if (!nuevaNotaInterconsulta.comentario && !errorNuevaNota) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Falla",
        dynamic_description: `Por favor, introduzca el comentario de la nota de interconsulta.`,
      });
      errorNuevaNota = true;
    }

    if (!nuevaNotaInterconsulta.idCatTipoInterconsulta && !errorNuevaNota) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Falla",
        dynamic_description: `Por favor, introduzca la especialidad de la nota de interconsulta.`,
      });
      errorNuevaNota = true;
    }

    if (!errorNuevaNota) {
      setLoading(true);
      const res = await uploadNuevaNotaInterconsulta(nuevaNotaInterconsulta);
      if (res.data.error) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_alert_nueva_consulta: false,
          dynamic_description: "Se guardaron los cambios de manera exitosa.",
        });
        setNuevaNotaInterconsulta(cleanNuevaNotaInter);
        listarNotasInterconsulta(idCatPacienteSeleccionado);
      }
    }
  };

  const exportArchivo = async (archivoProveedor) => {
    // convert image file to base64 string

    const archivo = archivoProveedor;

    const archivoVo = {
      rutaArchivo: archivo.rutaArchivo,
      nombreArchivo: archivo.nombreArchivo,
      mimeType: archivo.mimeType,
    };
    const res = await exportArchivoBack(archivoVo);
    return res;
  };

  const imprimirNotaInterconsulta = async (notaInterconsulta) => {
    const expedienteImpresionVo = {
      imprimirNotasInterconsultas: true,
      idExpedienteMedico: historiaClinicaInicial.expedienteMedico.idExpedienteMedico,
      idCatPaciente: historiaClinicaInicial.paciente.idCatPaciente,
      idInterconsulta: notaInterconsulta.idInterconsulta,
      idCatMedico: usuario.idCatMedico,
    };
    setLoading(true);
    try {
      const res = await imprimirExpedienteMedico(expedienteImpresionVo);
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
    // const res = await

    // return res;
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Notas de Interconsultas</h3>
      <DrawerExpedientePaciente
        historiaClinicaInicial={historiaClinicaInicial}
        sexos={sexos}
        aseguradoras={aseguradoras}
        medicos={medicos}
        lugares={lugares}
        ocupaciones={ocupaciones}
        estadosCiviles={estadosCiviles}
        escolaridades={escolaridades}
      />
      <Card className="mt-4">
        <CardBody>
          <Row className="mb-4">
            <Col>
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                type="button"
                onClick={redirectHistoriaClinicaInicial}>
                Historia Clínica Inicial
              </button>
              <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light mr-4"
                type="button"
                onClick={() => {
                  setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_nueva_consulta: true,
                  });
                }}>
                Nueva Nota
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <table className="table table-hover table-centered table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">
                      Fecha de<br></br>Registro
                    </th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Comentarios</th>
                    <th scope="col">Documentos</th>
                  </tr>
                </thead>
                <tbody>
                  {notasInterconsulta.map((interconsulta) => {
                    return (
                      <tr key={interconsulta.idInterconsulta}>
                        {console.log(interconsulta)}
                        <td>
                          {interconsulta.fechaRegistro
                            ? `${interconsulta.fechaRegistro.split("-")[2]}/${interconsulta.fechaRegistro.split("-")[1]}/${
                                interconsulta.fechaRegistro.split("-")[0]
                              }`
                            : ""}
                        </td>
                        <td>
                          {interconsulta.catTipoInterconsulta
                            ? interconsulta.catTipoInterconsulta.idCatTipoInterconsulta != 4
                              ? interconsulta.catTipoInterconsulta.tipoInterconsulta
                              : interconsulta.especialidad
                            : ""}
                        </td>
                        <td>
                          <textarea
                            disabled
                            className="form-control"
                            value={interconsulta.comentario ? interconsulta.comentario : ""}></textarea>
                        </td>
                        <td>
                          {interconsulta.archivos.length > 0 ? (
                            <button
                              className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                              type="button"
                              onClick={() => {
                                setIdInterconsultaSeleccionada(interconsulta.idInterconsulta);
                                setUISweetAlert({
                                  ...UISweetAlert,
                                  confirm_alert_documentos: true,
                                });
                              }}>
                              Documentos
                            </button>
                          ) : null}
                        </td>
                        <td>
                          <button
                            className="btn btn-primary-orange-umo w-md waves-effect waves-light mb-4 mr-4"
                            type="button"
                            onClick={() => imprimirNotaInterconsulta(interconsulta)}>
                            Imprimir
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_nueva_consulta ? (
        <SweetAlert
          title="Nueva Nota de Interconsulta"
          warning
          showCancel
          showConfirm
          confirmBtnText="Guardar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "70%",
          }}
          onConfirm={() => uploadNuevaNotaConfirm()}
          onCancel={() => {
            setNuevaNotaInterconsulta(cleanNuevaNotaInter);
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_nueva_consulta: false,
            });
          }}>
          <div>
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">
                Especialidad<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7}>
                <select
                  type="text"
                  className="form-control"
                  name="idCatTipoInterconsulta"
                  onChange={onChangeNuevaNota}
                  value={nuevaNotaInterconsulta.idCatTipoInterconsulta ? nuevaNotaInterconsulta.idCatTipoInterconsulta : ""}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {tipoInterconsultas.map((tipoInterconsulta) => {
                    return (
                      <>
                        <option value={tipoInterconsulta.idCatTipoInterconsulta}>{tipoInterconsulta.tipoInterconsulta}</option>
                      </>
                    );
                  })}
                </select>
                {nuevaNotaInterconsulta.idCatTipoInterconsulta == "" && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
            </Row>
            {nuevaNotaInterconsulta && nuevaNotaInterconsulta.idCatTipoInterconsulta == 4 ? (
              <Row className="form-group mt-3">
                <label className="col-sm-4 col-form-label">
                  Especialidad<span className="text-xs text-red-700">*</span>
                </label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    name="especialidad"
                    onChange={onChangeNuevaNota}
                    value={nuevaNotaInterconsulta.especialidad ? nuevaNotaInterconsulta.especialidad : ""}
                  />
                  {(nuevaNotaInterconsulta.especialidad == "" || !nuevaNotaInterconsulta.especialidad) && (
                    <span className="text-sm text-red-700">Este campo es obligatorio</span>
                  )}
                </Col>
              </Row>
            ) : null}
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">
                Comentarios<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7}>
                <textarea
                  className="form-control"
                  name="comentario"
                  onChange={onChangeNuevaNota}
                  value={nuevaNotaInterconsulta.comentario ? nuevaNotaInterconsulta.comentario : ""}
                />
                {(nuevaNotaInterconsulta.comentario == "" || !nuevaNotaInterconsulta.comentario) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
            </Row>
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">Documentos</label>
              <Col sm={7}>
                <input className="form-control" type="file" onClick={limpiarArchivos} onChange={(e) => uploadFile(e)} multiple />
              </Col>
            </Row>
            {/* <Row className="form-group">
                            <label className="col-12">
                            {nuevaNotaInterconsulta.archivosLength ? `${nuevaNotaInterconsulta.archivosLength} Documentos` : "0 Documentos"}
                            </label>
                        </Row> */}
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_documentos ? (
        <SweetAlert
          title="Documentos"
          warning
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_documentos: false,
            });
            setIdInterconsultaSeleccionada(null);
          }}
          style={{
            width: "40%",
          }}>
          <div className="mt-2">
            <Row>
              <Col>
                {notasInterconsulta
                  .filter((inter) => inter.idInterconsulta == idInterconsultaSeleccionada)[0]
                  .archivos.map((archivo) => {
                    return (
                      <button
                        className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-2 mt-2"
                        onClick={() => exportArchivo(archivo)}
                        type="button">
                        {archivo.nombreArchivo}
                      </button>
                    );
                  })}
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
