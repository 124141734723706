import { Row, Col, CardBody } from "reactstrap";
import React, {useState,useEffect} from "react";

//TODO: nos quedamos de mostrar el combo de clientes si la bandera es igual a true y lo que le sigue
const ModalDatosFiscales = (props) => {
  let entidad;
  let datosNombre;
  let comboCliente = false;
  let mostrarTipoCobro =  true;
  
  const[clienteGlobal,setclienteGlobal] = useState("")
  const[habilitaNuevoCliente,setHabilitaNuevoCliente] = useState(false)
  const[habilitarBotonNuevo,setHabilitarBotonNuevo] = useState(true)

  const onChangeModal = (e) => {
    
    setHabilitaNuevoCliente(false);
    const clienteFiltrado = props.clientes.filter((cliente) => e.target.value == cliente.idCatCliente);
    if(clienteFiltrado.length > 0 && clienteFiltrado[0].catPersona.datosFiscales){
      const temporal = clienteFiltrado[0].catPersona.datosFiscales;
      props.datosFiscales.regimenFiscal = temporal.catRegimenFiscal
      props.datosFiscales.catEstado = temporal.catEstado
      props.datosFiscales.catUsoCfdi = temporal.catUsoCfdi
      props.datosFiscales.correo = clienteFiltrado[0].catPersona.correo
      props.datosFiscales.idDatoFiscal  = temporal.idDatoFiscal 
      props.datosFiscales.nombre = clienteFiltrado[0].catPersona.nombres + ' ' +clienteFiltrado[0].catPersona.apellidoPaterno + ' ' + clienteFiltrado[0].catPersona.apellidoMaterno + ' ';
      props.datosFiscales.idCatPersona = clienteFiltrado[0].catPersona.idCatPersona
      props.datosFiscales.colonia = temporal.colonia;
      props.datosFiscales.municipio = temporal.municipio;
      props.datosFiscales.rfc = temporal.rfc;
      props.datosFiscales.calle = temporal.calle
      props.datosFiscales.numExterno = temporal.numExterno;
      props.datosFiscales.numInterno = temporal.numInterno;
      props.datosFiscales.idEstado = temporal.catEstado?temporal.catEstado.idCatOrigenResidencia:null;
      props.datosFiscales.pais = temporal.pais;
      props.datosFiscales.cp = temporal.cp
      props.datosFiscales.cp = temporal.cp
      props.datosFiscales.idCatCliente = clienteFiltrado[0].idCatCliente
      props.datosFiscales.idCatRegimenFiscal = temporal.catRegimenFiscal?temporal.catRegimenFiscal.idCatRegimenFiscal:"";
      props.datosFiscales.idCatUsoCfdi = temporal.catUsoCfdi? temporal.catUsoCfdi.idCatUsoCfdi:"";
      setclienteGlobal(props.datosFiscales.idCatCliente )
      setHabilitarBotonNuevo(false);
    }else{
      props.datosFiscales.tipoPersona = null;
      setHabilitarBotonNuevo(true);
    }
   /* props.setDatosFiscales({
      ...props.datosFiscales,
      idCatPersona: 0,
      nombre:  "",
      idCatRegimenFiscal:"",
      idCatUsoCfdi: "",
      idEstado: "",
  });*/
  };

  const checkUsuario = () => {
    if (props.tipoPersona && props.tipoPersona.idCatMedico) {
      datosNombre = "Médico";
      return (entidad = "Nombre del Médico");
    }
    if (props.tipoPersona && props.tipoPersona.idCatCliente) {
      if (props.tipoPersona.idCatCliente != 1) {
        datosNombre = "Cliente";
        return (entidad = "Nombre del Cliente");
      } else {
        datosNombre = "";
      }
    }
    if (props.tipoPersona && props.tipoPersona.idAseguradora) {
      datosNombre = "Aseguradora";

      return (entidad = "Razón Social de la Aseguradora");
    }

    if (props.tipoPersona && !props.tipoPersona.idAseguradora && !props.tipoPersona.idCatCliente && !props.tipoPersona.idCatMedico) {
      datosNombre = "Cliente";
      comboCliente = true;
      if(props.IdTipoDeCobroGlobal==6){
        mostrarTipoCobro = false;
      }
      return (entidad = "Nombre del cliente");
    }    
  };

  const nuevoCliente = (index) => {
    setHabilitaNuevoCliente(true);
    props.datosFiscales.regimenFiscal = "";
    props.datosFiscales.catEstado = null;
    props.datosFiscales.catUsoCfdi = null;
    props.datosFiscales.idDatoFiscal  = null;
    props.datosFiscales.nombre = "";
    props.datosFiscales.idCatPersona = null;
    props.datosFiscales.colonia = "";
    props.datosFiscales.municipio = "";
    props.datosFiscales.correo = "";
    props.datosFiscales.rfc = "";
    props.datosFiscales.calle = "";
    props.datosFiscales.numExterno = "";
    props.datosFiscales.numInterno = "";
    props.datosFiscales.idEstado = 0;
    props.datosFiscales.pais = "";
    props.datosFiscales.cp = "";
    props.datosFiscales.idCatCliente = 0;
    props.datosFiscales.idCatRegimenFiscal = 0;
    props.datosFiscales.idCatUsoCfdi = 0;
    setclienteGlobal(0);
  };

  useEffect(() => {
    setHabilitaNuevoCliente(false);
    setHabilitarBotonNuevo(true);
}, []);

  checkUsuario();
  return (
    <CardBody className="mt-4">
      {!props.tipoPersona.idAseguradora ? (
        <div className="flex justify-center mb-3">
          {props.tipoPersona.idCatCliente != 1 ? (
            <div className="form-check form-check-inline">
              <input
                disabled={props.tipoPersona.idCatCliente == 1}
                className="form-check-input h-4 w-4 mt-1 align-top float-left mr-2 cursor-pointer"
                type="radio"
                hidden={props.tipoPersona.idCatCliente == 1}
                onChange={props.onChange}
                checked={props.datosFiscales.tipoPersona == 1}
                name="tipoPersona"
                value={1}
              />
              <label className="form-check-label inline-block text-gray-800">{datosNombre}</label>
            </div>
          ) : null}

          {props.publicoGeneral ? (
            <div className="form-check form-check-inline" hidden={props.isPPD}>
              <input
                className="form-check-input h-4 w-4 mt-1 align-top float-left mr-2 cursor-pointer"
                type="radio"
                onChange={props.onChange}
                checked={props.datosFiscales.tipoPersona == 2 || props.tipoPersona.idCatCliente == 1}
                name="tipoPersona"
                value={2}
              />
              <label className="form-check-label inline-block text-gray-800">Venta Mostrador</label>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="flex justify-center mb-1">
        <table width={600}>
            <tr>
                <td>
                {comboCliente && props.datosFiscales.tipoPersona == 1?(
                  <select
                      className="form-control"
                      name="cliente"
                      id="cliente"
                      onChange={onChangeModal}
                      maxmenuheight={190}
                      value={clienteGlobal}
                      >
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {props.clientes.map((cliente) => {
                        return (
                          <>
                            <option
                              value={
                                cliente.idCatCliente
                              }>{`${cliente.catPersona.nombres} ${cliente.catPersona.apellidoPaterno} ${cliente.catPersona.apellidoMaterno}`}</option>
                          </>
                        );
                      })}
                  </select> 
                ):null}
                {comboCliente && props.datosFiscales.tipoPersona == 1?(
                  <button
                      className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                      type="button"
                      disabled = {false}
                      onClick={() => nuevoCliente()}>
                      Nuevo Cliente
                  </button>
                ):null}
                </td>
            </tr>
        </table>

      </div>

      {(props.datosFiscales && props.datosFiscales.tipoPersona == 1 && props.tipoPersona.idCatCliente != 1) ||
      props.tipoPersona.idAseguradora ? (
        <Row>
          <Col xl={6} lg={12} md={12}>
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">{entidad ? entidad : ""}</label>
              <Col sm={8}>
                <input
                  type="text"
                  disabled={!habilitaNuevoCliente}
                  className="form-control"
                  name="nombre"
                  autoComplete="off"
                  onChange={props.onChange}
                  value={props.datosFiscales.nombre ? props.datosFiscales.nombre : ""}
                />
               
              </Col>
              <label className="col-sm-4 col-form-label">
                Correo<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={8}>
                <input
                  type="text"
                  className="form-control"
                  name="correo"
                  autoComplete="off"
                  onChange={props.onChange}
                  value={props.datosFiscales.correo ? props.datosFiscales.correo : ""}
                />
                {(props.datosFiscales.correo === "" || props.datosFiscales.correo == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
              <label className="col-sm-4 col-form-label">
                RFC<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={8}>
                <input
                  type="text"
                  className="form-control"
                  name="rfc"
                  autoComplete="off"
                  onChange={props.onChange}
                  value={props.datosFiscales.rfc ? props.datosFiscales.rfc : ""}
                />
                {(props.datosFiscales.rfc === "" || props.datosFiscales.rfc == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>

              <label className="col-sm-4 col-form-label">
                Colonia<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={8}>
                <input
                  type="text"
                  className="form-control"
                  name="colonia"
                  autoComplete="off"
                  onChange={props.onChange}
                  value={props.datosFiscales.colonia ? props.datosFiscales.colonia : ""}
                />
                {(props.datosFiscales.colonia === "" || props.datosFiscales.colonia == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>

              <label className="col-sm-4 col-form-label">
                Calle<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={8}>
                <input
                  type="text"
                  className="form-control"
                  name="calle"
                  autoComplete="off"
                  onChange={props.onChange}
                  value={props.datosFiscales.calle ? props.datosFiscales.calle : ""}
                />
                {(props.datosFiscales.calle === "" || props.datosFiscales.calle == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>

              <label className="col-sm-4 col-form-label">
                Número Externo<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={8}>
                <input
                  type="text"
                  className="form-control"
                  name="numExterno"
                  autoComplete="off"
                  onChange={props.onChange}
                  value={props.datosFiscales.numExterno ? props.datosFiscales.numExterno : ""}
                />
                {(props.datosFiscales.numExterno === "" || props.datosFiscales.numExterno == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>

              <label className="col-sm-4 col-form-label">Número Interno</label>
              <Col sm={8}>
                <input
                  type="text"
                  className="form-control"
                  name="numInterno"
                  autoComplete="off"
                  onChange={props.onChange}
                  value={props.datosFiscales.numInterno ? props.datosFiscales.numInterno : ""}
                />
              </Col>
              {/* {props.tipoPersona.idAseguradora ? (
                <>
                  <label className="col-sm-12 col-form-label">Datos adicionales de factura</label>
                  <label className="col-sm-4 col-form-label">Encabezado de la factura</label>
                  <Col sm={8}>
                    <textarea
                      className="form-control"
                      name="encabezado"
                      autoComplete="off"
                      maxLength={250}
                      onChange={props.onChangeInformacionAdicional}
                      value={props.informacionAdicional.encabezado ? props.informacionAdicional.encabezado : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label mt-3">Pie de página de la factura</label>
                  <Col sm={8} className="mt-3">
                    <textarea
                      className="form-control"
                      name="pieDePagina"
                      autoComplete="off"
                      maxLength={250}
                      onChange={props.onChangeInformacionAdicional}
                      value={props.informacionAdicional.pieDePagina ? props.informacionAdicional.pieDePagina : ""}
                    />
                  </Col>
                </>
              ) : null} */}
            </Row>
          </Col>

          <Col xl={6} lg={12} md={12}>
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">
                Municipio<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={8}>
                <input
                  type="text"
                  className="form-control"
                  name="municipio"
                  autoComplete="off"
                  onChange={props.onChange}
                  value={props.datosFiscales.municipio ? props.datosFiscales.municipio : ""}
                />
                {(props.datosFiscales.municipio === "" || props.datosFiscales.municipio == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>

              <label className="col-sm-4 col-form-label">
                Estado<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={8}>
                <select
                  className="form-control"
                  onChange={props.onChange}
                  name="idEstado"
                  value={props.datosFiscales.idEstado ? props.datosFiscales.idEstado : ""}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {props.lugares.map((lugar) => {
                    return <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>;
                  })}
                </select>
                {(props.datosFiscales.idEstado === "" || props.datosFiscales.idEstado == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>

              <label className="col-sm-4 col-form-label">País</label>
              <Col sm={8}>
                <input
                  type="text"
                  className="form-control"
                  name="pais"
                  autoComplete="off"
                  onChange={props.onChange}
                  value={props.datosFiscales.pais ? props.datosFiscales.pais : ""}
                />
              </Col>

              <label className="col-sm-4 col-form-label">
                Código Postal<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={8}>
                <input
                  type="text"
                  className="form-control"
                  name="cp"
                  autoComplete="off"
                  onChange={props.onChange}
                  value={props.datosFiscales.cp ? props.datosFiscales.cp : ""}
                />
                {(props.datosFiscales.cp === "" || props.datosFiscales.cp == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
              <label className="col-sm-4 col-form-label">
                Régimen Fiscal<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={8}>
                <select
                  className="form-control"
                  name="idCatRegimenFiscal"
                  onChange={props.onChange}
                  value={props.datosFiscales.idCatRegimenFiscal}>
                  <option selected value="">
                    Seleccione una opción
                  </option>
                  {props.catRegimenFiscales.map((regimen) => {
                    return <option value={regimen.idCatRegimenFiscal}>{`${regimen.clave} - ${regimen.regimenFiscal}`}</option>;
                  })}
                </select>
                {(props.datosFiscales.idCatRegimenFiscal === "" || props.datosFiscales.idCatRegimenFiscal == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
              <label className="col-sm-4 col-form-label">
                Uso CFDi<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={8}>
                <select
                  className="form-control"
                  name="idCatUsoCfdi"
                  onChange={props.onChange}
                  value={props.datosFiscales.idCatUsoCfdi}>
                  <option selected value="">
                    Seleccione una opción
                  </option>
                  {props.catUsoCfdis.map((usoCfdi) => {
                    return <option value={usoCfdi.idCatUsoCfdi}>{`${usoCfdi.clave} - ${usoCfdi.usoCfdi}`}</option>;
                  })}
                </select>
                {(props.datosFiscales.idCatUsoCfdi === "" || props.datosFiscales.idCatUsoCfdi == null) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>

              {mostrarTipoCobro==true ? (
                <>
                  <label className="col-sm-4 col-form-label">
                    Método de pago<span className="text-xs text-red-700">*</span>
                  </label>              
                  <Col sm={8}>
                    <select
                      className="form-control"
                      name="idCatTipoCobro"
                      onChange={props.onChange}
                      disabled = {props.IdTipoDeCobroGlobal != 6? false:true}
                      value={props.datosFiscales.idCatTipoCobro? props.datosFiscales.idCatTipoCobro : props.IdTipoDeCobroGlobal }>
                      <option selected value="">
                        Seleccione una opción
                      </option>
                      {props.listaTipoCobro.map((tipoCobro) => {
                        return (
                          <>
                            { (tipoCobro.idCatTipoCobro && props.IdTipoDeCobroGlobal !=6) != 6?
                              <option value={tipoCobro.idCatTipoCobro}>{`${tipoCobro.clave} - ${tipoCobro.tipoCobro}`}</option>
                              :<option value={tipoCobro.idCatTipoCobro}>{`${tipoCobro.clave} - ${tipoCobro.tipoCobro}`}</option>
                            }
                          </>
                        )
                      })}
                    </select>
                    {(props.datosFiscales.idCatUsoCfdi === "" || props.datosFiscales.idCatUsoCfdi == null) && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>    
                </>
              ): null}

              <label className="col-sm-4 col-form-label"></label>
              <Col sm={8}>
                <button
                  className="btn btn-primary-blue-umo w-md waves-effect waves-light mb-4 mr-4"
                  type="button"
                  onClick={
                    props.tipoPersona.idAseguradora
                      ? props.confirmActualizarDatosFiscalesAseguradora
                      : props.actualizarDatosFiscales
                  }>
                  Guardar cambios de datos fiscales
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </CardBody>
  );
};

export default ModalDatosFiscales;
