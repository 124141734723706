import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

import formatNumber from "../../utils/index";

import AuthContext from "../../context/Auth/AuthContext";
import CatTipoSolicitudCompraContext from "../../context/CatTipoSolicitudCompra/CatTipoSolicitudCompraContext";
import CatTipoOrdenCompraContext from "../../context/CatTipoOrdenCompra/CatTipoOrdenCompraContext";
import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";
import CatTipoProductoContext from "../../context/CatTipoProducto/CatTipoProductoContext";
import ProductosServiciosContext from "../../context/ProductosServicios/ProductosServiciosContext";
import ConfiguracionContext from "../../context/Configuracion/ConfiguracionContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";

import { maxCampoPiezas } from "../../config/variables";

export default function NuevaSolicitudCompra(props) {
  // eslint-disable-next-line no-unused-vars
  const [fechaDefault, setFechaDefault] = useState(new Date().toISOString().slice(0, 10));
  const [productosFilter, setProductosFilter] = useState([]);
  const [productosFormatted, setProductosFormatted] = useState(false);
  const [indexProductoBorrado, setIndexProductoBorrado] = useState(null);
  const [indexProveedorBorrado, setIndexProveedorBorrado] = useState(null);
  const [ivaConfiguracion, setIvaConfiguracion] = useState(0);
  const [detallesPrecio, setDetallesPrecio] = useState({
    total: 0,
    subtotal: 0,
    iva: 0,
  });
  const [loading, setLoading] = useState(false);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_delete_producto: false,
    confirm_alert_delete_proveedor: false,
    confirm_alert_solicitar: false,
    confirm_alert_guardar: false,
    error_dlg: false,
    redirect: false,
    success_dlg: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  //ACCESO AL ESTADO GLOBAL - Tipos de Solicitud de compra
  const ctxTiposSolicitudCompra = useContext(CatTipoSolicitudCompraContext);
  const { tiposSolicitudCompra, listarTiposSolicitudCompra } = ctxTiposSolicitudCompra;

  //ACCESO AL ESTADO GLOBAL - Tipos de compra
  const ctxTiposOrdenCompra = useContext(CatTipoOrdenCompraContext);
  const { tiposOrdenCompra, listarTiposOrdenCompra } = ctxTiposOrdenCompra;

  //ACCESO AL ESTADO GLOBAL - Tipos de producto
  const ctxTiposProducto = useContext(CatTipoProductoContext);
  const { tiposProductos, listarProductoTiposProductos } = ctxTiposProducto;

  const solicitudesComprasContext = useContext(SolicitudesComprasContext);
  const { nuevaSolicitudCompra, setNuevaSolicitudCompra, limpiarSolicitudCompraSeleccionada, guardarNuevaSolicitudCompra } =
    solicitudesComprasContext;

  //ACCESO AL ESTADO GLOBAL - ProductosServicios
  const ctxProductosServicios = useContext(ProductosServiciosContext);
  const { productosServicios, listarProductosPersonal, setProductosServicios } = ctxProductosServicios;

  // ACCESO A ESTADO GLOBAL - Configuracion
  const ctxConfiguracion = useContext(ConfiguracionContext);
  const { listarConfiguracion, configuracion } = ctxConfiguracion;

  //ACCESO AL ESTADO GLOBAL - Proovedores
  const ctxProveedores = useContext(ProveedoresContext);
  const { proveedores, listarProveedores } = ctxProveedores;

  const usuarioCompras =  usuario.authorities.includes("ROLE_COMPRAS") 

  // MANEJO DE CRUD LOCAL
  const onChange = (e) => {
    setNuevaSolicitudCompra({
      ...nuevaSolicitudCompra,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeTipoProducto = async (e) => {
    //Si cambia el tipo de Producto, entonces borramos todos los productos
    let idCatTipoProducto = e.target.value;
    setNuevaSolicitudCompra({
      ...nuevaSolicitudCompra,
      solicitudcompraproducto: [],
      [e.target.name]: idCatTipoProducto,
    });

    // setPrecioTotal();
    setDetallesPrecio({
      total: 0,
      subtotal: 0,
      iva: 0,
    });
    filtrarProductosPorTipo(idCatTipoProducto);
  };

  const filtrarProductosPorTipo = async (idCatTipoProducto) => {
    //Filtramos todos los productos para que solo esten los que sean del
    const productosFiltrados = await productosServicios.filter((producto) => {
      if (producto.idCatTipoproducto == idCatTipoProducto) {
        //Esto es para el label del select, que pueda buscar por todas las caracteristicas
        // producto.label = `${producto.nombreComercial} ${producto.numIdentificacion} ${producto.principioActivo} ${producto.codigoEAN}`
        return true;
      }
    });

    setProductosFilter(productosFiltrados);
  };

  const nuevoProducto = async () => {
    if (!productosFormatted) {
      replaceAcentos();
    }

    let tipoProd = tiposProductos.filter(
      (tipoProducto) => tipoProducto.idCatTipoProducto == nuevaSolicitudCompra.idCatTipoProducto
    )[0];
    const nuevoProd = {
      idCatProducto: null,
      piezas: 0,
      iva: 0,
      precio: null,
      principioActivo: "",
      catTipoproducto: tipoProd,
      descripcion: "",
      solicitudcompraproveedor: [],
      fechaRequisicion : null,
    };
    nuevaSolicitudCompra.solicitudcompraproducto = nuevaSolicitudCompra.solicitudcompraproducto.concat(nuevoProd);
    setNuevaSolicitudCompra(nuevaSolicitudCompra);
  };

  const changePiezasProducto = async (e, index) => {
    let productos = nuevaSolicitudCompra.solicitudcompraproducto;
    let producto = productos[index];
    let value = e.target.value;
    if (value < 0) {
      value = value * -1;
    }
    if (value?.length <= maxCampoPiezas) {
      producto.piezas = parseInt(value, 10);

      setNuevaSolicitudCompra(nuevaSolicitudCompra);
      setPrecioTotal();
    }
  };

  const changeProducto = async (e, index) => {
    let value = e.target.value;
    let productos = nuevaSolicitudCompra.solicitudcompraproducto;
    let producto = productos[index];
    let encontrado = false;
    producto.descripcion = value;

    //Hacemos un filtrado de los insumos, en base a 5 campos, el nombreComercial del producto, el principioActivo del producto, el numIdentificacion del producto
    //el codigoEAN del producto y por ultimo el nombre del servicio. Todo lo hacemos lowerCase para la comparacion.
    const productosFiltrados = await productosServicios.filter((prodServ) => {
      if (prodServ.idCatTipoproducto == nuevaSolicitudCompra.idCatTipoProducto && !encontrado) {
        if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase() == value.toLowerCase()) {
          encontrado = true
          return true;
        } else
        if (prodServ.nombreComercial != null && prodServ.nombreComercial.toLowerCase().startsWith(value.toLowerCase(), 0)) {
          return true;
        } else if (
          prodServ.principioActivo != null &&
          prodServ.principioActivo.toLowerCase().startsWith(value.toLowerCase(), 0)
        ) 
        {
          return true;
        } else if (prodServ.numIdentificacion != null && prodServ.numIdentificacion.startsWith(value, 0)) {
          return true;
        } else if (prodServ.codigoEAN != null && prodServ.codigoEAN.startsWith(value, 0)) {
          return true;
        }

      } else {
        return false;
      }
    });

    //Una vez obtenido el arreglo de los insumos filtrados, los guardamos el State
    setProductosFilter(productosFiltrados);

    //Si el input, contiene un "-", significa, que ya escogimos un producto/servicio. Hay que obtener ese producto/servicio seleccionado de nuestra lista
    //pero los producto se puede dar el caso de tener el mismo nombreComercial, entonces se separa por el "-", y lo que esta despues del "-", es el numIdentificacion
    //que es unico para los productos
    
    if (encontrado) {
      
     
        const productosFiltrados2 = await productosFiltrados.filter((prodServ) => {
        if ( prodServ.nombreComercial.toLowerCase()== value.toLowerCase()
        ) {
          return true;
        }
      });

      let cadenaBuscar = productosFiltrados2[0].numIdentificacion;
      const productoServicioSeleccionado = await productosServicios.filter((prodServ) => {
        if (
          prodServ.numIdentificacion != null &&
          prodServ.idCatTipoproducto == nuevaSolicitudCompra.idCatTipoProducto &&
          prodServ.numIdentificacion.startsWith(cadenaBuscar, 0) && prodServ.nombreComercial.toLowerCase()== productosFiltrados2[0].nombreComercial.toLowerCase()
        ) {
          return true;
        }
      });

      if (productoServicioSeleccionado.length > 0) {
        let productoSeleccionado = productoServicioSeleccionado[0];
        filtrarProductosPorTipo(nuevaSolicitudCompra.idCatTipoProducto);

        const productoRepetido = await checarProductoRepetido(productoSeleccionado, index);

        if (productoRepetido) {
          producto.idCatProducto = null;
          producto.precio = null;
          producto.iva = 0;
          producto.catTipoProducto = null;
          producto.descripcion = "";
          producto.principioActivo = "";

          setNuevaSolicitudCompra(nuevaSolicitudCompra);

          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: "Producto repetido",
          });

          setPrecioTotal();

          return false;
        }

        //Una vez obtenido el producto/servicio seleccionado, se debe de asignar el producto, el idCatProducto y principioActivo en caso de que sea un producto
        //y en el caso de que sea un servicio, solo se le asignado el idCatServicio
        producto.idCatProducto = productoSeleccionado.idCatProducto;
        producto.precio = productoSeleccionado.precioProductoServicio[0].precioEntrada;
        producto.principioActivo = productoSeleccionado.principioActivo;
        if (productoSeleccionado.causaIva) {
          producto.iva = producto.precio * ivaConfiguracion;
        }

        //Despues de hacer todas las modificaciones, hacemos un setData con todos los cambios
        setNuevaSolicitudCompra(nuevaSolicitudCompra);

        setPrecioTotal();
      } else {
        //Mientras no se encuentre un solo producto/servicio, el producto se mentiene limpio
        producto.idCatProducto = null;
        producto.precio = null;
        producto.iva = 0;
        producto.catTipoProducto = null;
        producto.principioActivo = "";

        setNuevaSolicitudCompra(nuevaSolicitudCompra);

        setPrecioTotal();
      }
    } else {
      //Mientras no nos mande, el "-", se debe de limpiar el producto
      producto.idCatProducto = null;
      producto.precio = null;
      producto.iva = 0;
      producto.catTipoProducto = null;
      producto.principioActivo = "";

      setNuevaSolicitudCompra(nuevaSolicitudCompra);

      setPrecioTotal();
    }
  };

  const checarProductoRepetido = async (producto, index) => {
    let productoRepetido = false;

    nuevaSolicitudCompra.solicitudcompraproducto.map((element, i) => {
      if (index != i && element.idCatProducto == producto.idCatProducto && !productoRepetido) {
        productoRepetido = true;
      }
    });
    return productoRepetido;
  };

  const eliminarProducto = async () => {
    let productos = nuevaSolicitudCompra.solicitudcompraproducto;
    let producto = productos[indexProductoBorrado];
    nuevaSolicitudCompra.solicitudcompraproducto = nuevaSolicitudCompra.solicitudcompraproducto.filter(
      (item) => item != producto
    );

    setNuevaSolicitudCompra(nuevaSolicitudCompra);

    setPrecioTotal();

    setIndexProductoBorrado(null);

    setUISweetAlert({
      ...UISweetAlert,
      success_dlg: true,
      dynamic_title: "Borrado",
      confirm_alert_delete_producto: false,
      dynamic_description: "El producto ha sido borrado correctamente.",
    });
  };

  const setPrecioTotal = async () => {
    let subtotal = 0;
    let iva = 0;
    let total = 0;

    setDetallesPrecio({
      subtotal: 0,
      iva: 0,
      total: 0,
    });

    if (nuevaSolicitudCompra.solicitudcompraproducto.length > 0) {
      nuevaSolicitudCompra.solicitudcompraproducto.map((producto) => {
        subtotal += Number(producto.precio) * Number(producto.piezas);
        if (Number(producto.iva) != null) {
          iva += Number(producto.iva) * Number(producto.piezas);
        }
      });
    }

    total = subtotal + iva;

    setDetallesPrecio({
      subtotal: (Math.round(subtotal * 100) / 100).toFixed(2),
      iva: (Math.round(iva * 100) / 100).toFixed(2),
      total: (Math.round(total * 100) / 100).toFixed(2),
    });
  };

  const replaceAcentos = async () => {
    setProductosFormatted(true);

    const productosServiciosFormatted = await productosServicios.filter((prodServ) => {
      if (prodServ.principioActivo != null) {
        prodServ.principioActivo = prodServ.principioActivo
          .normalize("NFD")
          .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1")
          .normalize();
      }
      return true;
    });

    setProductosServicios(productosServiciosFormatted);
  };

  const nuevoProveedor = async (index) => {
    const nuevoProv = {
      idCatProveedor: null,
      proveedor: "",
      contacto: "",
      correo: "",
    };

    nuevaSolicitudCompra.solicitudcompraproducto[index].solicitudcompraproveedor =
      nuevaSolicitudCompra.solicitudcompraproducto[index].solicitudcompraproveedor.concat(nuevoProv);
    setNuevaSolicitudCompra(nuevaSolicitudCompra);
  };

  const setProveedor = async (proveedor, indexProducto, indexProveedor) => {
    let proveedorSol = nuevaSolicitudCompra.solicitudcompraproducto[indexProducto].solicitudcompraproveedor[indexProveedor];
    const proveedorRepetido = await checarProveedorRepetido(proveedor, indexProducto, indexProveedor);

    if (proveedorRepetido) {
      proveedorSol.idCatProveedor = "";
      proveedorSol.proveedor = "";
      proveedorSol.contacto = "";
      proveedorSol.correo = "";

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Proveedor repetido",
      });
    } else {
      proveedorSol.idCatProveedor = proveedor.idCatProveedor;
      proveedorSol.proveedor = proveedor.proveedor;
      proveedorSol.contacto = proveedor.contacto;
      proveedorSol.correo = proveedor.correo;
    }

    setNuevaSolicitudCompra(nuevaSolicitudCompra);
  };

  const eliminarProveedor = async () => {
    let proveedor =
      nuevaSolicitudCompra.solicitudcompraproducto[indexProductoBorrado].solicitudcompraproveedor[indexProveedorBorrado];
    nuevaSolicitudCompra.solicitudcompraproducto[indexProductoBorrado].solicitudcompraproveedor =
      nuevaSolicitudCompra.solicitudcompraproducto[indexProductoBorrado].solicitudcompraproveedor.filter(
        (item) => item != proveedor
      );

    setNuevaSolicitudCompra(nuevaSolicitudCompra);

    setIndexProveedorBorrado(null);
    setIndexProductoBorrado(null);

    setUISweetAlert({
      ...UISweetAlert,
      success_dlg: true,
      dynamic_title: "Borrado",
      confirm_alert_delete_proveedor: false,
      dynamic_description: "El producto ha sido borrado correctamente.",
    });
  };

  
  const checarProveedorRepetido = async (proveedor, indexProducto, indexProveedor) => {
    let proveedorRepetido = false;

    nuevaSolicitudCompra.solicitudcompraproducto[indexProducto].solicitudcompraproveedor.map((element, i) => {
      if (indexProveedor != i && element.idCatProveedor == proveedor.idCatProveedor && !proveedorRepetido) {
        proveedorRepetido = true;
      }
    });
    return proveedorRepetido;
  };

  const confirmSolicitudCompra = async (e, solicitar) => {
    e.preventDefault();

    const errorEnValidacion = await validateFormSolicitudCompra();

    if (errorEnValidacion) {
      return;
    }

    if (solicitar) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_solicitar: true,
      });
    }
  };

  const validateFormSolicitudCompra = async () => {
    let errorEnValidacion = false;

    /*/if (!nuevaSolicitudCompra.idCatTipoSolicitudCompra && !errorEnValidacion) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca el tipo de solicitud de compra`,
      });
      errorEnValidacion = true;
    }*/

    if (!nuevaSolicitudCompra.idCatTipoOrdenCompra && !errorEnValidacion) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca el tipo de compra`,
      });
      errorEnValidacion = true;
    }

    if (!nuevaSolicitudCompra.idCatTipoProducto && !errorEnValidacion) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca el tipo de producto`,
      });
      errorEnValidacion = true;
    }

    if (!nuevaSolicitudCompra.fechaRequisicion && !errorEnValidacion) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca la fecha de requisición`,
      });
      errorEnValidacion = true;
    }

    if (nuevaSolicitudCompra.solicitudcompraproducto.length < 1 && !errorEnValidacion) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `Por favor, introduzca productos a la solicitud`,
      });
      errorEnValidacion = true;
    } else if (!errorEnValidacion) {
      nuevaSolicitudCompra.solicitudcompraproducto.map((producto, index) => {
        if (!producto.idCatProducto && !errorEnValidacion) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca el producto en el renglon ${index + 1}`,
          });
          errorEnValidacion = true;
        }

        if (!producto.piezas && !errorEnValidacion) {
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: `Por favor, introduzca las piezas en el renglon ${index + 1}`,
          });
          errorEnValidacion = true;
        }

        if(usuario.authorities.includes("ROLE_COMPRAS")){
          if (producto.solicitudcompraproveedor.length < 1 && !errorEnValidacion) {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: true,
              dynamic_title: "Fallo",
              dynamic_description: `Por favor, introduzca al menos un proveedor para el producto en el renglon ${index + 1}`,
            });
            errorEnValidacion = true;
          } else if (!errorEnValidacion) {
            producto.solicitudcompraproveedor.map((proveedor, index) => {
              if (!proveedor.idCatProveedor && !errorEnValidacion) {
                setUISweetAlert({
                  ...UISweetAlert,
                  error_dlg: true,
                  dynamic_title: "Fallo",
                  dynamic_description: `Por favor, introduzca el proveedor en el renglon ${index + 1}`,
                });
                errorEnValidacion = true;
              }
            });
          }
        }


      });
    }

    return errorEnValidacion;
  };

  const guardarSolicitudCompra = async () => {
    nuevaSolicitudCompra.importeTotal = detallesPrecio.subtotal;
    nuevaSolicitudCompra.ivaTotal = detallesPrecio.iva;
    nuevaSolicitudCompra.idUsuarioComprador = usuario.idCatUsuario;
    nuevaSolicitudCompra.solicitarCotizacion = true;
    setNuevaSolicitudCompra(nuevaSolicitudCompra);

    setLoading(true);
    const res = await guardarNuevaSolicitudCompra(nuevaSolicitudCompra);
    if (res) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_guardar: false,
        confirm_alert_solicitar: false,
        dynamic_description: "Se guardó con éxito la solicitud de compra.",
        redirect: true,
        path: "/solicitudescompra",
      });
    } else {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_guardar: false,
        confirm_alert_solicitar: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error al subir los cambios, Inténtalo por favor más tarde.",
      });
    }
  };

  const toggleExpanded = async (index) => {
    let productosNuevaSolicitudCompra = nuevaSolicitudCompra.solicitudcompraproducto;
    let producto = productosNuevaSolicitudCompra[index];
    producto.expanded = !producto.expanded;

    setNuevaSolicitudCompra({
      ...nuevaSolicitudCompra,
      solicitudcompraproducto: productosNuevaSolicitudCompra,
    });
  };

  useEffect(() => {
    const fetchAllContextData = () => {
      listarProductosPersonal();
      listarTiposSolicitudCompra();
      listarTiposOrdenCompra();
      listarProductoTiposProductos();
      listarProveedores();
      listarConfiguracion();
    };
    fetchAllContextData();
  }, []);

  useEffect(() => {
    limpiarSolicitudCompraSeleccionada();

    configuracion.map((config) => {
      if (config.clave == "IVA") {
        setIvaConfiguracion(parseFloat(config.valor) / 100);
      }
    });
  }, [configuracion]);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Nueva Solicitud de Compra</h3>

        <Card
          hidden=""
          className="mt-10 card"
          style={{
            padding: "1.5rem 1.5rem 1.5rem",
          }}>
          <form onSubmit="">
            <CardBody>
              <Row>
                <Col xl={6} lg={12} md={12}>
                  <Row className="form-group">
                    <label className="col-sm-5 col-form-label">Fecha de Solicitud</label>
                    <Col sm={7}>
                      <input type="date" value={fechaDefault} className="form-control" disabled={true} />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-5 col-form-label">Fecha de Requisición</label>
                    <Col sm={7}>
                      <input type="date" 
                             value={nuevaSolicitudCompra.fechaRequisicion} 
                             className="form-control" 
                             name="fechaRequisicion"
                             onChange={onChange}
                             />
                    </Col>
                  </Row>                  
                  {usuarioCompras?
                    <Row className="form-group">
                      <label className="col-sm-5 col-form-label">Tipo Solcitud de Compra</label>
                      <Col sm={7}>
                        <select autoComplete="off"  className="form-control" name="idCatTipoSolicitudCompra" onChange={onChange}>
                          <option selected value="">
                            Seleccione una opción
                          </option>
                          {tiposSolicitudCompra.map((tipoSolCom) => {
                            return (
                              <>
                                <option value={tipoSolCom.idCatTipoSolicitudCompra}>{tipoSolCom.tipoSolicitudCompra}</option>
                              </>
                            );
                          })}
                        </select>
                      </Col>
                    </Row>
                  :null}
                  <Row className="form-group">
                    <label className="col-sm-5 col-form-label">Tipo de Compra</label>
                    <Col sm={7}>
                      <select autoComplete="off"  className="form-control" name="idCatTipoOrdenCompra" onChange={onChange}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tiposOrdenCompra.map((tipo) => {
                          return (
                            <>
                              {!usuarioCompras && tipo.idCatTipoOrdenCompra == 3?
                              <option value={tipo.idCatTipoOrdenCompra}>{tipo.tipoOrdenCompra}</option>
                              :null}
                              |{usuarioCompras?
                              <option value={tipo.idCatTipoOrdenCompra}>{tipo.tipoOrdenCompra}</option>
                              :null}

                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-5 col-form-label">Tipo de Producto</label>
                    <Col sm={7}>
                      <select autoComplete="off"  className="form-control" name="idCatTipoProducto" onChange={onChangeTipoProducto}>
                        <option selected value="">
                          Seleccione una opción
                        </option>
                        {tiposProductos.map((tipoProd) => {
                          return (
                            <>
                              {!tipoProd.idCatTipoProducto != 5?
                              <option value={tipoProd.idCatTipoProducto}>{tipoProd.tipoProducto}</option>
                              :null}
                            </>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>

                  <Row className="form-group">
                    {!usuario.authorities.includes("ROLE_COMPRAS")?
                     <label className="col-sm-5 col-form-label">Solictante</label>
                    : <label className="col-sm-5 col-form-label">Comprador</label>}

                    <Col sm={7}>
                      <input type="text" value={usuario.nombre} className="form-control" disabled={true} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                    type="button"
                    onClick={nuevoProducto}
                    disabled={nuevaSolicitudCompra.idCatTipoProducto ? false : true}
                    style={{
                      marginRight: "2.5rem",
                      background: "#ff9933",
                      color: "white",
                      fontWeight: "bold",
                    }}>
                    Agregar Producto
                  </button>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>

        <Row>
          <Col>
            <Card>
              <CardBody>
                {/* <div className="table-responsive"> */}
                <table className="table table-hover table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Descripción</th>
                      <th scope="col">
                        Principio<br></br>Activo
                      </th>
                      <th scope="col" width = "10%">Piezas</th>
                      <th scope="col">
                        Precio<br></br>Unitario<br></br>(Estimado)
                      </th>
                      <th scope="col">Total</th>
                      <th scope="col">Tipo</th>
                      <th scope="col">Eliminar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nuevaSolicitudCompra.solicitudcompraproducto.map((producto, index) => {
                      return (
                        <>
                          <tr key={producto}>
                            <td onClick={() => toggleExpanded(index)}>
                              {producto.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>}
                            </td>
                            <td style={{ width: "30%" }}>
                              {producto.catProductos ? (
                                <div>{producto.catProductos.nombreComercial}</div>
                              ) : (
                                <>
                                  <input
                                    value={producto.descripcion}
                                    autoComplete="off" 
                                    type="text"
                                    onChange={(e) => changeProducto(e, index)}
                                    list={`listaInsumos${index}`}
                                    className="form-control"
                                  />
                                  <datalist autoComplete="off"  id={`listaInsumos${index}`}>
                                    {productosFilter.map((prodServ) => {
                                      return (
                                        <>
                                          {
                                            <option value={`${prodServ.nombreComercial}`}>
                                              {prodServ.nombreComercial} - {prodServ.numIdentificacion} -{prodServ.codigoEAN} -{" "}
                                              {prodServ.principioActivo}
                                            </option>
                                          }
                                        </>
                                      );
                                    })}
                                  </datalist>
                                </>
                              )}
                            </td>
                            <td>{producto.principioActivo != "X" ? producto.principioActivo : ""}</td>
                            <td style={{ width: "9%" }}>
                              {producto.catProductos ? (
                                <div>{producto.piezas}</div>
                              ) : (
                                <input
                                  value={producto.piezas}
                                  autoComplete="off" 
                                  onWheel={(e) => e.target.blur()}
                                  type="number"
                                  min="0"
                                  onChange={(e) => changePiezasProducto(e, index)}
                                  className="form-control"
                                />
                              )}
                            </td>
                            <td>{producto.precio ? formatNumber(producto.precio) : ""}</td>
                            <td>{producto.piezas * producto.precio ? formatNumber(producto.piezas * producto.precio) : ""}</td>
                            <td>{producto.catTipoproducto.tipoProducto}</td>
                            <td>
                              {!producto.catProductos ? (
                                <button
                                  type="button"
                                  className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                  onClick={() => {
                                    setIndexProductoBorrado(index);
                                    setUISweetAlert({
                                      ...UISweetAlert,
                                      confirm_alert_delete_producto: true,
                                    });
                                  }}>
                                  Eliminar
                                </button>
                              ) : null}
                            </td>
                          </tr>
                          {producto.expanded && (
                            <tr>
                              <td colSpan={10}>
                                {(producto.idCatProducto || producto.catProductos) && usuarioCompras? (
                                  <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                                    type="button"
                                    onClick={() => nuevoProveedor(index)}>
                                    Agregar Proveedor
                                  </button>
                                ) : null}
                                {producto.solicitudcompraproveedor.length > 0 && (
                                  <table className="table table-hover table-centered table-nowrap mb-10">
                                    <thead>
                                      <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Contacto</th>
                                        <th scope="col">Correo</th>
                                        <th scope="col">Eliminar</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {producto.solicitudcompraproveedor.map((proveedor, indexProveedor) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                <Select
                                                  isSearchable
                                                  autoComplete="off" 
                                                  options={proveedores}
                                                  getOptionLabel={(option) => option.proveedor}
                                                  value={proveedores.filter((prov) => prov.proveedor == proveedor.proveedor)}
                                                  onChange={(option) => setProveedor(option, index, indexProveedor)}
                                                  noOptionsMessage={() => "No hay opciones"}
                                                  placeholder="Seleccione una opción"
                                                />
                                              </td>
                                              <td>{proveedor.contacto}</td>
                                              <td>{proveedor.correo}</td>
                                              <td>
                                                <button
                                                  type="button"
                                                  className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                                  onClick={() => {
                                                    setIndexProveedorBorrado(indexProveedor);
                                                    setIndexProductoBorrado(index);
                                                    setUISweetAlert({
                                                      ...UISweetAlert,
                                                      confirm_alert_delete_proveedor: true,
                                                    });
                                                  }}>
                                                  Eliminar
                                                </button>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                )}
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {/* </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card className="mt-4 w-full">
          <Card>
            <Row>
              <Col md="12">
                <table
                  className="w-2/6"
                  style={{
                    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                    boxSizing: "border-box",
                    fontSize: "14px",
                    backgroundColor: "inherit",
                    margin: "0",
                    float: "right",
                  }}
                  bgcolor="#f6f6f6">
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        margin: "0",
                      }}>
                      <td
                        style={{
                          fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          verticalAlign: "top",
                          borderTopWidth: "1px",
                          borderTopColor: "#eee",
                          borderTopStyle: "solid",
                          margin: "0",
                          padding: "5px 0",
                          textAlign: "left",
                        }}
                        valign="top">
                        Sub-Total
                      </td>
                      <td
                        className="alignright"
                        style={{
                          fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          verticalAlign: "top",
                          textAlign: "right",
                          borderTopWidth: "1px",
                          borderTopColor: "#eee",
                          borderTopStyle: "solid",
                          margin: "0",
                          padding: "5px 0",
                        }}
                        align="right"
                        valign="top">
                        {formatNumber(detallesPrecio.subtotal)}
                        {/* ${detallesPrecio.subtotal} */}
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        margin: "0",
                      }}>
                      <td
                        style={{
                          fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          verticalAlign: "top",
                          borderTopWidth: "1px",
                          borderTopColor: "#eee",
                          borderTopStyle: "solid",
                          margin: "0",
                          padding: "5px 0",
                          textAlign: "left",
                        }}
                        valign="top">
                        IVA
                      </td>
                      <td
                        className="alignright"
                        style={{
                          fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          verticalAlign: "top",
                          textAlign: "right",
                          borderTopWidth: "1px",
                          borderTopColor: "#eee",
                          borderTopStyle: "solid",
                          margin: "0",
                          padding: "5px 0",
                        }}
                        align="right"
                        valign="top">
                        {formatNumber(detallesPrecio.iva)}
                        {/* ${detallesPrecio.iva} */}
                      </td>
                    </tr>
                    <tr
                      className="total"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        margin: "0",
                      }}>
                      <td
                        width="80%"
                        style={{
                          fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          verticalAlign: "top",
                          textAlign: "left",
                          borderTopWidth: "5px",
                          borderTopColor: "#333",
                          borderTopStyle: "double",
                          borderBottomColor: "#333",
                          borderBottomWidth: "2px",
                          borderBottomStyle: "solid",
                          fontWeight: "700",
                          margin: "0",
                          padding: "5px 0",
                        }}
                        align="left"
                        valign="top">
                        Total
                      </td>
                      <td
                        className="alignright"
                        style={{
                          fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                          boxSizing: "border-box",
                          fontSize: "14px",
                          verticalAlign: "top",
                          textAlign: "right",
                          borderTopWidth: "5px",
                          borderTopColor: "#333",
                          borderTopStyle: "double",
                          borderBottomColor: "#333",
                          borderBottomWidth: "2px",
                          borderBottomStyle: "solid",
                          fontWeight: "700",
                          margin: "0",
                          padding: "5px 0",
                        }}
                        align="right"
                        valign="top">
                        {formatNumber(detallesPrecio.total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card>
        </Card>

        <Card className="w-full">
          <CardBody>
            <div>
              <button
                type="submit"
                onClick={(e) => confirmSolicitudCompra(e, true)}
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                Solicitar
              </button>
            </div>
          </CardBody>
        </Card>

        {UISweetAlert.confirm_alert_solicitar ? (
          <SweetAlert
            title="Se generará la solicitud de compra"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              guardarSolicitudCompra();
            }}
            onCancel={() =>
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_solicitar: false,
              })
            }></SweetAlert>
        ) : null}

        {UISweetAlert.confirm_alert_delete_producto ? (
          <SweetAlert
            title="¿Desea eliminar el insumo?"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => eliminarProducto()}
            onCancel={() => {
              setIndexProductoBorrado(null);
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_delete_producto: false,
              });
            }}>
            Se perderá la información
          </SweetAlert>
        ) : null}

        {UISweetAlert.confirm_alert_delete_proveedor ? (
          <SweetAlert
            title="¿Desea eliminar el proveedor?"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => eliminarProveedor()}
            onCancel={() => {
              setIndexProveedorBorrado(null);
              setIndexProductoBorrado(null);
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_delete_proveedor: false,
              });
            }}>
            Se perderá la información
          </SweetAlert>
        ) : null}

        {/* Dejar al final los mensajes de loading, success, y error */}

        {loading ? (
          <SweetAlert title="Cargando!" showConfirm={false}>
            <Spinner />
          </SweetAlert>
        ) : null}

        {UISweetAlert.success_dlg ? (
          <SweetAlert
            success
            title={UISweetAlert.dynamic_title}
            onConfirm={() => {
              if (UISweetAlert.redirect) {
                props.history.push(UISweetAlert.path);
              }
              setUISweetAlert({
                ...UISweetAlert,
                success_dlg: false,
              });
            }}>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {UISweetAlert.error_dlg ? (
          <SweetAlert
            error
            title={UISweetAlert.dynamic_title}
            onConfirm={() =>
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: false,
              })
            }>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}
      </div>
    </>
  );
}
