import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";
import TabOrdenCompraProveedor from "../TabOrdenCompraProveedor/index";
import formatDateWithoutHour from "../../utils/formatDateWithoutHour";

export default function ListadoEstatusSolicitudCompra({ props, idSolicitudCompra }) {
  // const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  // const [UISweetAlert, setUISweetAlert] = useState({
  //   success_dlg: false,
  //   error_dlg: false,
  //   reload: false,
  //   redirect: false,
  //   path: "",
  //   dynamic_title: "",
  //   dynamic_description: "",
  // });

  const solicitudesComprasContext = useContext(SolicitudesComprasContext);
  const {
    solicitudCompraSeleccionada,
    setSolicitudCompraSeleccionada,
    seleccionarOrdenesCompraProveedores,
    solicitudOrdenesCompraProveedores,
    exportArchivoBack,
  } = solicitudesComprasContext;

  const formatDate = (date) => {
    let dateAux = date.split("T")[0].split("-");
    date = `${dateAux[2]}/${dateAux[1]}/${dateAux[0]}`;
    return (
      <>
        <div>{date}</div>
      </>
    );
  };

  const toggleExpanded = async (estatus) => {
    estatus.expanded = !estatus.expanded;
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
  };

  const obtenerOrdenesCompra = async () => {
    seleccionarOrdenesCompraProveedores(idSolicitudCompra);
  };

  useEffect(() => {
    if (Object.entries(solicitudCompraSeleccionada).length !== 0) {
      let estatus =
        solicitudCompraSeleccionada.estatussolicitudcompra[solicitudCompraSeleccionada.estatussolicitudcompra.length - 1]
          .catEstatussolicitudcompra;
      if (estatus.idCatEstatusSolicitudCompra == 6 && Object.entries(solicitudOrdenesCompraProveedores).length === 0) {
        obtenerOrdenesCompra();
      }
    }
  }, [solicitudCompraSeleccionada]);

  const exportArchivo = async (archivoProveedor) => {
    // convert image file to base64 string

    const archivo = archivoProveedor.archivosproceso;

    const archivoVo = {
      rutaArchivo: archivo.rutaArchivo,
      nombreArchivo: archivo.nombreArchivo,
      mimeType: archivo.mimeType,
    };
    const res = await exportArchivoBack(archivoVo);
    return res;
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Lista de Estatus Solicitud Compra</h3>
      {console.log(solicitudCompraSeleccionada)}
      <Card className="mt-10">
        <CardBody>
          <Row className="mt-4">
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th scope="col">Fecha Estatus</th>
                    <th scope="col">Estatus</th>
                    <th scope="col">Comentario</th>
                  </tr>
                </thead>
                <tbody>
                  {solicitudCompraSeleccionada.estatussolicitudcompra
                    ? solicitudCompraSeleccionada.estatussolicitudcompra.map((estatus, index) => {
                        return (
                          <>
                            <tr>
                              <td key={estatus.idEstatusSolicitudCompra} onClick={() => toggleExpanded(estatus)}>
                                {
                                  //Estatus 3 es cotizado, se deben de mostrar los documentos?
                                  estatus.catEstatussolicitudcompra.idCatEstatusSolicitudCompra == 3 ||
                                  estatus.catEstatussolicitudcompra.idCatEstatusSolicitudCompra == 6
                                    ? [estatus.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>]
                                    : ""
                                }
                              </td>
                              <td>
                                {estatus.fechaEstatus ? formatDateWithoutHour(estatus.fechaEstatus) : ""}
                              </td>
                              <td>{estatus.catEstatussolicitudcompra.estatusSolicitudcompra}</td>
                              {estatus.catEstatussolicitudcompra.idCatEstatusSolicitudCompra == 8 &&
                              estatus.comentarios &&
                              estatus.comentarios.length > 0 ? (
                                <td>{estatus.comentarios[estatus.comentarios.length - 1].comentario}</td>
                              ) : (
                                <td></td>
                              )}
                            </tr>
                            {estatus.expanded
                              ? [
                                  estatus.catEstatussolicitudcompra.idCatEstatusSolicitudCompra == 6
                                    ? [
                                        <tr>
                                          <td colSpan={9}>
                                            <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
                                              <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                                                <TabList>
                                                  {Object.entries(solicitudOrdenesCompraProveedores).length !== 0 &&
                                                    solicitudOrdenesCompraProveedores.map((ordencompra) => {
                                                      return (
                                                        <>
                                                          <Tab>{ordencompra.proveedor}</Tab>
                                                        </>
                                                      );
                                                    })}
                                                </TabList>

                                                {Object.entries(solicitudOrdenesCompraProveedores).length !== 0 &&
                                                  solicitudOrdenesCompraProveedores.map((ordencompra) => {
                                                    return (
                                                      <>
                                                        <TabPanel>
                                                          <TabOrdenCompraProveedor props={props} ordencompra={ordencompra} />
                                                        </TabPanel>
                                                      </>
                                                    );
                                                  })}
                                              </Tabs>
                                            </div>
                                          </td>
                                        </tr>,
                                      ]
                                    : [
                                        estatus.catEstatussolicitudcompra.idCatEstatusSolicitudCompra == 3
                                          ? [
                                              <tr>
                                                <td colSpan={9}>
                                                  <Row>
                                                    <Col>
                                                      <h3 className="flex h3 mt-6">Documentos del Proveedor</h3>
                                                      <Row className="form-group">
                                                        <Col>
                                                          {estatus.archivosolicitudcompras &&
                                                            estatus.archivosolicitudcompras.map((archivo) => {
                                                              return (
                                                                <>
                                                                  <button
                                                                    type="button"
                                                                    onClick={() => exportArchivo(archivo)}
                                                                    className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
                                                                    {archivo.archivosproceso.nombreArchivo}
                                                                  </button>
                                                                </>
                                                              );
                                                            })}
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                  </Row>
                                                </td>
                                              </tr>,
                                            ]
                                          : null,
                                      ],
                                ]
                              : null}
                          </>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
