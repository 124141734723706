import React, { useReducer } from "react";
import CatTipoCitaContext from "./CatTipoCitaContext";
import CatTipoCitaReducer from "./CatTipoCitaReducer";

import clienteAxios from "../../config/axios";

import { LISTAR_TIPOCITAS } from "../../types";

export default function CatTipoCobroState(props) {
  const initialState = {
    tipoCitas: [],
  };

  const [state, dispatch] = useReducer(CatTipoCitaReducer, initialState);

  // Retorna el usuario autenticado
  const listarCatTipoCitas = async () => {
    const res = await clienteAxios.get("/tipocitas/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_TIPOCITAS,
      payload: res.data,
    });
  };

  return (
    <CatTipoCitaContext.Provider
      value={{
        tipoCitas: state.tipoCitas,
        listarCatTipoCitas,
      }}>
      {props.children}
    </CatTipoCitaContext.Provider>
  );
}
