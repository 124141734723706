import {
  FILTRO_BITACORAS,
  SET_BITACORAS_PAGINADAS,
  FILTROS_INVENTARIO_MEDICAMENTOS,
  FILTRO_REPORTE_REACCIONES,
  SET_REPORTES_REACCIONES_PAGINADOS,
  SET_VALE_SELECCIONADO,
  SET_MEDICAMENTOS_CM_RF,
  SET_MEDICAMENTOS_CM,
  SET_REGISTROS_CM,
  SET_REGISTROS_CM_RF,
} from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case FILTRO_BITACORAS:
      return {
        ...state,
        filtrosBitacoras: action.payload,
      };
    case SET_BITACORAS_PAGINADAS:
      return {
        ...state,
        bitacorasPaginadas: action.payload,
      };
    case FILTRO_REPORTE_REACCIONES:
      return {
        ...state,
        filtrosReporteReacciones: action.payload,
      };
    case SET_REPORTES_REACCIONES_PAGINADOS:
      return {
        ...state,
        reportesReacciones: action.payload,
      };
    case FILTROS_INVENTARIO_MEDICAMENTOS:
      return {
        ...state,
        filtrosMedicamentosCM: action.payload,
      };
    case SET_MEDICAMENTOS_CM_RF:
      return {
        ...state,
        medicamentosInventarioCMRedFria: action.payload,
      };
    case SET_MEDICAMENTOS_CM:
      return {
        ...state,
        medicamentosInventarioCM: action.payload,
      };
    case SET_REGISTROS_CM:
      return {
        ...state,
        movimientosCM: action.payload,
      };
    case SET_REGISTROS_CM_RF:
      return {
        ...state,
        movimientosRedFria: action.payload,
      };
    case SET_VALE_SELECCIONADO:
      return {
        ...state,
        idValeSeleccionado: action.payload,
      };
    default:
      return state;
  }
};
