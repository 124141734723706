import React, {useReducer} from 'react'

import EstatusSolicitudCompraContext from './EstatusSolicitudCompraContext'
import EstatusSolicitudCompraReducer from './EstatusSolicitudCompraReducer'

import {
    LISTAR_ESTATUSSOLICITUDCOMPRAS,
} from '../../types'

import clienteAxios from '../../config/axios'


export default function EstatusSolicitudCompraState (props) {

    const initialState = { 
        estatusSolicitudCompra:[],
    }

    const [state, dispatch] = useReducer(EstatusSolicitudCompraReducer,initialState)

    const listarEstatusSolicitudCompra = async () => {

        const res = await clienteAxios.get("/estatussolicitudcompra/activos"  , {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
            dispatch({
                type: LISTAR_ESTATUSSOLICITUDCOMPRAS,
                payload: res.data
            })
    }

    return (

        <EstatusSolicitudCompraContext.Provider value={{
            estatusSolicitudCompra: state.estatusSolicitudCompra,
            listarEstatusSolicitudCompra,
        }}>
            {props.children}
        </EstatusSolicitudCompraContext.Provider>

    )


}