import React, { useContext, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";

export default function InformacionGeneralSolicitudCompra(props) {
  const solicitudesComprasContext = useContext(SolicitudesComprasContext);
  const { solicitudCompraSeleccionada, setSolicitudCompraSeleccionada } = solicitudesComprasContext;

  const formatDate = async () => {
    if (!solicitudCompraSeleccionada.fechaSolicitud.includes("T")) {
      return;
    }

    let splitDate = solicitudCompraSeleccionada.fechaSolicitud.split("T")[0].split("-");
    splitDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];

    solicitudCompraSeleccionada.fechaSolicitud = splitDate;
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
  };

  useEffect(() => {
    if (solicitudCompraSeleccionada && solicitudCompraSeleccionada.fechaSolicitud) {
      formatDate();
    }
  }, [solicitudCompraSeleccionada]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Información General</h3>

      <Card
        className="mt-10 card"
        style={{
          padding: "1.5rem 1.5rem 1.5rem",
        }}>
        <CardBody>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Fecha</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={solicitudCompraSeleccionada ? solicitudCompraSeleccionada.fechaSolicitud : ""}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Fecha Requisición</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={solicitudCompraSeleccionada.fechaRequisicion ? solicitudCompraSeleccionada.fechaRequisicion.split("T")[0] : ""}
                  />
                </Col>
              </Row>              

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Tipo Solicitud de Compra</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      solicitudCompraSeleccionada && solicitudCompraSeleccionada.catTiposolicitudcompra
                        ? solicitudCompraSeleccionada.catTiposolicitudcompra.tipoSolicitudCompra
                        : ""
                    }
                  />
                </Col>
              </Row>

              {/* <Row className="form-group"
                    hidden={solicitudCompraSeleccionada && solicitudCompraSeleccionada.catTipoordencompra ? false : true}>
                      <label className="col-sm-4 col-form-label">
                        Tipo Venta
                      </label>
                      <Col sm={7}>
                        <input
                        disabled
                        className="form-control"
                        type="text"
                        value={solicitudCompraSeleccionada ? solicitudCompraSeleccionada.catTipoordencompra.tipoOrdenCompra : "" }
                        />
                      </Col>
                    </Row> */}

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Estatus</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      solicitudCompraSeleccionada && solicitudCompraSeleccionada.estatussolicitudcompra
                        ? solicitudCompraSeleccionada.estatussolicitudcompra[
                            solicitudCompraSeleccionada.estatussolicitudcompra.length - 1
                          ].catEstatussolicitudcompra.estatusSolicitudcompra
                        : ""
                    }
                  />
                </Col>
              </Row>
            </Col>

            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Folio SC</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={solicitudCompraSeleccionada ? solicitudCompraSeleccionada.folioSolicitudCompra : ""}
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Tipo Compra</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      solicitudCompraSeleccionada && solicitudCompraSeleccionada.catTipoordencompra
                        ? solicitudCompraSeleccionada.catTipoordencompra.tipoOrdenCompra
                        : ""
                    }
                  />
                </Col>
              </Row>

              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Comprador</label>
                <Col sm={7}>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={
                      solicitudCompraSeleccionada && solicitudCompraSeleccionada.usuarioComprador
                        ? solicitudCompraSeleccionada.usuarioComprador.nombre
                        : ""
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
