import React, { useContext, useEffect } from "react";
import { Row, Card, CardBody } from "reactstrap";

import OrdenesCompraContext from "../../context/OrdenesCompra/OrdenesCompraContext";

export default function ListadoEstatusOrdenCompra({ props }) {
  // const [UISweetAlert, setUISweetAlert] = useState({
  //     success_dlg: false,
  //     error_dlg: false,
  //     reload: false,
  //     redirect: false,
  //     path: "",
  //     dynamic_title: "",
  //     dynamic_description: "",
  // });

  // ACCESO A ESTADO GLOBAL - VALES
  const ctxOrdenCompra = useContext(OrdenesCompraContext);
  const { ordenCompraSeleccionada, setOrdenCompraSeleccionada } = ctxOrdenCompra;

  const formatDate = (date) => {
    let dateAux = date.split("T")[0].split("-");
    date = `${dateAux[2]}/${dateAux[1]}/${dateAux[0]}`;
    return (
      <>
        <div>{date}</div>
      </>
    );
  };

  const toggleExpanded = async (estatus) => {
    estatus.expanded = !estatus.expanded;
    setOrdenCompraSeleccionada(ordenCompraSeleccionada);
  };

  useEffect(() => {}, [ordenCompraSeleccionada]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Lista de Estatus Orden Compra</h3>

      <Card className="mt-10">
        <CardBody>
          <Row className="mt-4">
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th scope="col">Fecha Estatus</th>
                    <th scope="col">Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  {ordenCompraSeleccionada.estatusordencompra
                    ? ordenCompraSeleccionada.estatusordencompra.map((estatus, index) => {
                        return (
                          <>
                            <tr>
                              <td key={estatus.idOrdenCompra} onClick={() => toggleExpanded(estatus)}>
                                {/* {
                                                                    estatus.catEstatusordencompra.idCatEstatusOrdenCompra == 3 ||
                                                                    estatus.catEstatusordencompra.idCatEstatusOrdenCompra == 6  ? [
                                                                        estatus.expanded ?  (
                                                                            <i className="ti-angle-up"></i>
                                                                        ):(
                                                                            <i className="ti-angle-down"></i>
                                                                        ) 
                                                                    ]  : ""     
                                                                } */}
                              </td>
                              <td>{estatus.fechaEstatus ? formatDate(estatus.fechaEstatus) : ""}</td>
                              <td>{estatus.catEstatusordencompra.estatusOrdencompra}</td>
                            </tr>
                            {/* {
                                                            estatus.expanded ? [
                                                            ]: null 
                                                        } */}
                          </>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}
