import React, { useContext, useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Row, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// import AuthContext from "../../context/Auth/AuthContext";

import ExploracionFisica from "../TabsPadecimientoActual/ExploracionFisica/index";
import DatosGenerales from "../TabsPadecimientoActual/DatosGenerales/index";
import PadecimientoActualContext from "../../context/PadecimientoActual/PadecimientoActualContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import CatDiagnosticosContext from "../../context/CatDiagnosticos/CatDiagnosticosContext";
import WindowWarningContext from "../../context/WindowWarning/WindowWarningContext";

import HistoriaClinicaInicialContext from "../../context/HistoriaClinicaInicial/HistoriaClinicaInicialContext";

export default function PadecimientoActual(props) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeGuardado, seGuardaronCambiosContext } = windowWarningContext;

  const ctxHistClinInic = useContext(HistoriaClinicaInicialContext);
  const { seleccionarHistoriaClinicaInicialPaciente, uploadHistoriaClinicaInicial } = ctxHistClinInic;

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_guardar_padecimiento: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const [seGuardaronCambios, setSeGuardaronCambios] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [padecimientoVacio, setPadecimientoVacio] = useState(true);
  const [opcionDiagnostico, setOpcionDiagnostico] = useState("");

  const ctxCatDiagnosticos = useContext(CatDiagnosticosContext);
  const { listarCatDiagnosticos } = ctxCatDiagnosticos;

  // useEffect(() => {
  //   const fetchDiagnosticos = async () => {
  //     listarCatDiagnosticos();
  //   };
  //   fetchDiagnosticos();
  // }, []);

  const ctxPadAct = useContext(PadecimientoActualContext);
  const { setPadecimientoActual, uploadPadecimientoActual } = ctxPadAct;

  const pacientesContext = useContext(PacientesContext);
  // eslint-disable-next-line no-unused-vars
  const { idCatPacienteSeleccionado, setIdCatPacienteSeleccionado } = pacientesContext;

  const uploadPadecimientoActualConfirm = async () => {
    let revisionActualizada;
    let resumenActualizado;
    if (props.nuevoResumen && props.nuevoResumen != "") resumenActualizado = props.changeExtraResumen();
    props.setNuevoResumen("");
    if (props.nuevaRevisionCaso && props.nuevaRevisionCaso != "") revisionActualizada = props.changeExtraRevisionCaso();
    props.setNuevaRevisionCaso("");
    if (
      props.historiaClinicaInicial &&
      props.historiaClinicaInicial.expedienteMedico &&
      props.historiaClinicaInicial.expedienteMedico.idExpedienteMedico
    ) {
      setLoading(true);
      const res = await uploadPadecimientoActual(null, true);
      const resHistoriaClinica = await uploadHistoriaClinicaInicial(null, resumenActualizado, revisionActualizada);
      if (res.data.error || resHistoriaClinica.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          editar: false,
          dynamic_title: "Exito",
          confirm_alert_guardar_padecimiento: false,
          dynamic_description: "Se guardó de manera correcta la información, sobre el padecimiento actual.",
        });
        setSeGuardaronCambios(true);
        changeGuardado(true);
        setIdCatPacienteSeleccionado(res.data.idCatPaciente);
        seleccionarHistoriaClinicaInicialPaciente(res.data.idCatPaciente);
        // checkPadecimientoActualPaciente();
        setOpcionDiagnostico("");
      }
    } else {
      setLoading(true);
      const resHistoriaClinica = await uploadHistoriaClinicaInicial(null, resumenActualizado, revisionActualizada);

      if (resHistoriaClinica && resHistoriaClinica.data) {
        const res = await uploadPadecimientoActual(resHistoriaClinica.data.idCatPaciente, false);
        setLoading(false);

        if (res.data.error || resHistoriaClinica.data.error) {
          setLoading(false);
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: res.data.message,
          });
        } else if (res) {
          setSeGuardaronCambios(true);
          setLoading(false);
          setUISweetAlert({
            ...UISweetAlert,
            success_dlg: true,
            editar: false,
            dynamic_title: "Exito",
            confirm_alert_guardar_padecimiento: false,
            dynamic_description: "Se guardó de manera correcta la información, sobre el padecimiento actual.",
          });

          setIdCatPacienteSeleccionado(res.data.idCatPaciente);
          seleccionarHistoriaClinicaInicialPaciente(res.data.idCatPaciente);
          // checkPadecimientoActualPaciente();
          setOpcionDiagnostico("");
        }
      } else {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: resHistoriaClinica.data.message,
        });
      }
      setLoading(false);
    }

    // const res = await uploadHistoriaClinicaInicial(usuario.idCatMedico, resumenActualizado, revisionActualizada);
  };

  // const historiaClinicaInicialRedirect = async () => {
  //   props.history.push("/historia/clinica/inicial");
  // };

  // useEffect(() => {
  //   checkPadecimientoActualPaciente();
  // }, [padecimientoVacio]);

  useEffect(() => {
    if (seGuardaronCambiosContext) setSeGuardaronCambios(true);
  }, []);

  useEffect(() => {
    listarCatDiagnosticos();
    // if (!catDiagnosticos)
  }, []);

  return (
    <div className="mt-4" style={{ textAlign: "left" }}>
      <Row>
        <h4>Padecimiento Actual</h4>
      </Row>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Datos Generales</Tab>
          <Tab>Exploración Física</Tab>
        </TabList>
        <TabPanel>
          <DatosGenerales
            props={props}
            opcionDiagnostico={opcionDiagnostico}
            setOpcionDiagnostico={setOpcionDiagnostico}
            // listarCatDiagnosticosMatch={listarCatDiagnosticos}
            // catDiagnosticos={catDiagnosticos}
            padecimientoActual={props.padecimientoActual}
            setPadecimientoActual={setPadecimientoActual}
            seGuardaronCambios={seGuardaronCambios}
          />
        </TabPanel>
        <TabPanel>
          <ExploracionFisica
            props={props}
            padecimientoActual={props.padecimientoActual}
            setPadecimientoActual={setPadecimientoActual}
            seGuardaronCambios={seGuardaronCambios}
          />
        </TabPanel>
      </Tabs>
      <button
        className="btn btn-primary-blue-umo w-md waves-effect waves-light"
        type="button"
        // hidden={usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CANCELAR") ? false : true}
        onClick={() => {
          setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_guardar_padecimiento: true,
            editar: false,
          });
        }}>
        {props.historiaClinicaInicial &&
        props.historiaClinicaInicial.expedienteMedico &&
        props.historiaClinicaInicial.expedienteMedico.idExpedienteMedico
          ? "Guardar"
          : "Crear expediente del paciente"}
      </button>
      {/* <Card>
        <CardBody>
          <Row>
            <Col>
              
            </Col>
          </Row> 

       <Row>
            <Col>
              
            </Col>
          </Row> 
        </CardBody>
      </Card> */}

      {UISweetAlert.confirm_alert_guardar_padecimiento ? (
        <SweetAlert
          title="¿Desea guardar los cambios al expediente médico?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => uploadPadecimientoActualConfirm()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_guardar_padecimiento: false,
              editar: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );

  //   function checkPadecimientoActualPaciente() {
  //     if (padecimientoVacio) {
  //       if (idCatPacienteSeleccionado) {
  //         seleccionarPadecimientoActualPaciente(idCatPacienteSeleccionado);
  //       } else {
  //         setPadecimientoActual({});
  //       }
  //       setPadecimientoVacio(false);
  //     }
  //   }
  // }
}
