import React, { useContext ,useEffect} from "react";
import "react-tabs/style/react-tabs.css";
import SolicitudesComprasContext from "../../context/SolicitudesCompras/SolicitudesComprasContext";
import { Row, Col, Card } from "reactstrap";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import formatNumber from "../../utils/index";

export default function TabOrdenCompraProveedor({ props, ordencompra, solicitarOC }) {
  // ACCESO A ESTADO GLOBAL - VALES
  const ctxSolicCompra = useContext(SolicitudesComprasContext);
  const { setSolicitudCompraSeleccionada, solicitudCompraSeleccionada } = ctxSolicCompra;

  const ctxAlmacenes = useContext(AlmacenesContext);
  const { almacenes} = ctxAlmacenes;

  const onChange = async (e) => {
    ordencompra.idCatAlmacen = e.target.value;
    setSolicitudCompraSeleccionada(solicitudCompraSeleccionada);
  };

  return (
    <div className="mt-4 container-fluid" style={{ textAlign: "center" }}>
      <Row>
        <Col>
          <Row className="form-group">
            <label className="col-sm-4 col-form-label">Proveedor</label>
            <Col sm={7}>
              <input className="form-control" disabled={true} value={ordencompra.proveedor} />
            </Col>
          </Row>

          <Row className="form-group">
            <label className="col-sm-4 col-form-label">Lugar de Entrega</label>
            <Col sm={7}>
              <select
                className="form-control"
                onChange={onChange}
                value={ordencompra.idCatAlmacen != null ? ordencompra.idCatAlmacen : ""}
                disabled={ordencompra.idOrdenCompra ? true : false}
                defaultValue={""}>
                <option disabled value="">
                  Seleccione una opción
                </option>
                {almacenes.map((almacen) => {
                  return (
                    <>
                      <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                    </>
                  );
                })}
              </select>
            </Col>
          </Row>

          <Row className="form-group" hidden={ordencompra.idOrdenCompra ? false : true}>
            <label className="col-sm-4 col-form-label">Folio Orden de Compra</label>
            <Col sm={7}>
              <input className="form-control" disabled={true} value={ordencompra.folioOrdenCompra} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <h4>Lista de Productos</h4>
        <div className="table-responsive mt-4">
          <table className="table table-hover table-centered table-nowrap mb-0">
            <thead>
              <tr>
                <th scope="col">Descripción</th>
                <th scope="col">Piezas</th>
                <th scope="col">
                  Precio Unitario<br></br>(Cotizado)
                </th>
                <th scope="col">Total</th>
                <th scope="col">Tipo</th>
              </tr>
            </thead>
            <tbody>
              {ordencompra.ordencompraproducto && ordencompra.ordencompraproducto.length > 0
                ? ordencompra.ordencompraproducto.map((producto) => {
                    return (
                      <>
                        <tr key={producto}>
                          <td>{producto.producto ? producto.producto : ""}</td>

                          <td>{producto.piezasCompradas ? producto.piezasCompradas : ""}</td>

                          <td>{producto.precio ? formatNumber(producto.precio) : ""}</td>
                          {/* <td>{producto.precio ? `$${producto.precio.toFixed(2)}` : ""}</td> */}

                          <td>
                            {producto.piezasCompradas && producto.precio
                              ? formatNumber(producto.piezasCompradas * producto.precio)
                              : "$0"}
                          </td>
                          {/* <td>{producto.piezasCompradas && producto.precio ? `$${(producto.piezasCompradas * producto.precio).toFixed(2)}` : "$0"}</td> */}

                          <td>{producto.tipoProducto ? producto.tipoProducto : ""}</td>
                        </tr>
                      </>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </Row>
      <Row hidden={ordencompra.idOrdenCompra ? true : false}>
        <button
          type="submit"
          onClick={solicitarOC}
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white">
          Generar Orden Compra
        </button>
      </Row>

      <Card className="w-full">
        <Card>
          <Row>
            <Col md="12">
              <table
                className="w-2/6"
                style={{
                  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  backgroundColor: "inherit",
                  margin: "0",
                  float: "right",
                }}
                bgcolor="#f6f6f6">
                <tbody>
                  <tr
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                        textAlign: "left",
                      }}
                      valign="top">
                      Sub-Total
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(parseFloat(ordencompra.importeTotal))}
                      {/* ${ordencompra.importeTotal.toFixed(2)} */}
                    </td>
                  </tr>
                  <tr
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                        textAlign: "left",
                      }}
                      valign="top">
                      IVA
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(parseFloat(ordencompra.ivaTotal))}
                      {/* ${ordencompra.ivaTotal.toFixed(2)} */}
                    </td>
                  </tr>
                  <tr
                    className="total"
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      width="80%"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "left",
                        borderTopWidth: "5px",
                        borderTopColor: "#333",
                        borderTopStyle: "double",
                        borderBottomColor: "#333",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        fontWeight: "700",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="left"
                      valign="top">
                      Total
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "5px",
                        borderTopColor: "#333",
                        borderTopStyle: "double",
                        borderBottomColor: "#333",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        fontWeight: "700",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(parseFloat(ordencompra.ivaTotal + ordencompra.importeTotal))}
                      {/* ${(ordencompra.ivaTotal + ordencompra.importeTotal).toFixed(2)} */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Card>
      </Card>
    </div>
  );
}
