import { LISTAR_CATSERVICIOS, LISTAR_SERVICIOS_PAGINADOS, CAMBIOS_FILTROS_SERVICIOS } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_CATSERVICIOS:
      return {
        ...state,
        catServicios: action.payload,
      };
    case LISTAR_SERVICIOS_PAGINADOS:
      return {
        ...state,
        serviciosPaginados: action.payload,
      };
    case CAMBIOS_FILTROS_SERVICIOS:
      return {
        ...state,
        filtrosServicios: action.payload,
      };
    default:
      return state;
  }
};
