import React, {useReducer} from 'react'

import UbicacionesContext from './UbicacionesContext'
import UbicacionesReducer from './UbicacionesReducer'

import {
    LISTAR_UBICACIONES,
    LIMPIAR_UBICACIONES
} from '../../types'

import clienteAxios from '../../config/axios'


export default function UbicacionesState (props) {

    const initialState = { 
        ubicaciones: [],
        estanteSeleccionado:{},
    }

    const [state, dispatch] = useReducer(UbicacionesReducer,initialState)

    
    const seleccionarEstante = async (idCatEstante) =>{
        const res = await clienteAxios.get(`/estante/detalles?id=${idCatEstante}`, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            }
        })
  
        dispatch({
            type: LISTAR_UBICACIONES,
            payload: res.data
        })
    }

    const uploadUbicacion = async (ubicacion) => {

        try{

            const res = await clienteAxios.post("/ubicacion/guardar", {...ubicacion, estante: state.estanteSeleccionado}, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  'Cache-Control': 'no-store', // Evita el caché del navegador
                    }
            })
    
            seleccionarEstante(state.estanteSeleccionado.idCatEstante);
            
            return res;

        }catch (error){

            if(!error.response){
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error:"hubo error"
                    }
                }
            }

            return error.response;
        }

    }

    const limpiarUbicaciones = async () => {
        dispatch({
            type: LIMPIAR_UBICACIONES,
            payload: null
        })
    }

    const uploadDeleteUbicacion = async (ubicacion) => {

        try{

            const res = await clienteAxios.post("/ubicacion/eliminar", ubicacion, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  'Cache-Control': 'no-store', // Evita el caché del navegador
                    }
            })
    
            seleccionarEstante(state.estanteSeleccionado.idCatEstante);
            
            return res;

        }catch (error){

            if(!error.response){
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error:"hubo error"
                    }
                }
            }

            return error.response;
        }
    }

    return (

        <UbicacionesContext.Provider value={{
            ubicaciones: state.ubicaciones,
            estanteSeleccionado: state.estanteSeleccionado,
            seleccionarEstante,
            uploadUbicacion,
            limpiarUbicaciones,
            uploadDeleteUbicacion
        }}>
            {props.children}
        </UbicacionesContext.Provider>

    )


}