import { LISTAR_TIPOPRECIOS } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case LISTAR_TIPOPRECIOS:
      return {
        ...state,
        tipoPrecios: action.payload,
      };

    default:
      return state;
  }
};
