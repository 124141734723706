import axios from "axios";

const clienteAxios = axios.create({
  //baseURL: "http://localhost:8080"            // DEV
  //baseURL: "http://54.166.2.134:8080"         // QA
  //baseURL: "https://qa.appback.zoico.com.mx"  // QA
  //baseURL: "http://appback.zoico.com.mx:8080" // PRD
  baseURL: "https://appback.zoico.com.mx" // PRD
});

export default clienteAxios;
