import React, { useContext } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col } from "reactstrap";
// import useCloseWindowWarning from "../../../hooks/useCloseWindowWarning";

import { maxCamposAntecedentesNoPatologicos } from "../../../config/variables";

import WindowWarningContext from "../../../context/WindowWarning/WindowWarningContext";

export default function AntecedentesPersonalesNoPatologicos({ props, historiaClinicaInicial, setHistoriaClinicaInicial }) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito } = windowWarningContext;
  // const [Prompt, setEditTrue, setEditFalse, isEdit] = useCloseWindowWarning();
  const onChange = async (e) => {
    // if (!isEdit) setEditTrue();
    if (!seEdito) changeEdit(true);
    setHistoriaClinicaInicial({
      ...historiaClinicaInicial,
      antecedentesPersonalesNoPatologicos: {
        ...historiaClinicaInicial.antecedentesPersonalesNoPatologicos,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <div className="mt-4 container-fluid" style={{ textAlign: "center" }}>
      <Row>
        <h4>Antecedentes Personales No Patológicos</h4>
      </Row>
      <Row>
        <Col>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Biomasa</label>

            <Col sm={10}>
              <input
                type="text"
                maxLength={maxCamposAntecedentesNoPatologicos}
                name="biomasa"
                value={
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos.biomasa
                    ? historiaClinicaInicial.antecedentesPersonalesNoPatologicos.biomasa
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Zoonosis</label>
            <Col sm={10}>
              <input
                type="text"
                name="zoonosis"
                maxLength={maxCamposAntecedentesNoPatologicos}
                value={
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos.zoonosis
                    ? historiaClinicaInicial.antecedentesPersonalesNoPatologicos.zoonosis
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Inmunizaciones</label>
            <Col sm={10}>
              <input
                type="text"
                name="inmunizaciones"
                maxLength={maxCamposAntecedentesNoPatologicos}
                value={
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos.inmunizaciones
                    ? historiaClinicaInicial.antecedentesPersonalesNoPatologicos.inmunizaciones
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Alcoholismo</label>
            <Col sm={10}>
              <textarea
                name="alcoholismo"
                value={
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos.alcoholismo
                    ? historiaClinicaInicial.antecedentesPersonalesNoPatologicos.alcoholismo
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Tabaquismo</label>
            <Col sm={10}>
              <textarea
                name="tabaquismo"
                value={
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos.tabaquismo
                    ? historiaClinicaInicial.antecedentesPersonalesNoPatologicos.tabaquismo
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Consumo de Drogas</label>
            <Col sm={10}>
              <textarea
                name="consumoDrogas"
                maxLength={maxCamposAntecedentesNoPatologicos}
                value={
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos.consumoDrogas
                    ? historiaClinicaInicial.antecedentesPersonalesNoPatologicos.consumoDrogas
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Hábitos Alimenticios</label>
            <Col sm={10}>
              <textarea
                name="habitosAlimenticios"
                value={
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos.habitosAlimenticios
                    ? historiaClinicaInicial.antecedentesPersonalesNoPatologicos.habitosAlimenticios
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Hábitos Higiénicos</label>
            <Col sm={10}>
              <textarea
                name="habitosHigienicos"
                value={
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos.habitosHigienicos
                    ? historiaClinicaInicial.antecedentesPersonalesNoPatologicos.habitosHigienicos
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Habitación</label>
            <Col sm={10}>
              <textarea
                name="habitacion"
                value={
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos &&
                  historiaClinicaInicial.antecedentesPersonalesNoPatologicos.habitacion
                    ? historiaClinicaInicial.antecedentesPersonalesNoPatologicos.habitacion
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* {Prompt} */}
    </div>
  );
}
