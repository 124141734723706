import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import VentaMostradorContext from "../../context/VentaMostrador/VentaMostradorContext";

export default function ListadoEstatusVentaMostrador({ props }) {
  // eslint-disable-next-line no-unused-vars
  const [checkAllCobros, setCheckAllCobros] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notaVenta, setNotaVenta] = useState({});

  const [UISweetAlert, setUISweetAlert] = useState({
    success_dlg: false,
    error_dlg: false,
    reload: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  // ACCESO A ESTADO GLOBAL - VENTA MOSTRADOR
  const ctxVentaMostrador = useContext(VentaMostradorContext);
  const { ventaMostradorSeleccionada, setVentaMostradorSeleccionada, exportNotaDeVenta } = ctxVentaMostrador;

  const formatDate = (date) => {
    let dateAux = date.split("T")[0].split("-");
    date = `${dateAux[2]}/${dateAux[1]}/${dateAux[0]}`;
    return (
      <>
        <div>{date}</div>
      </>
    );
  };

  const toggleExpanded = async (index) => {
    let estatus = ventaMostradorSeleccionada.estatusventamostrador[index];
    if (
      estatus.catEstatusventamostrador.idCatEstatusVentaMostrador == 5 ||
      estatus.catEstatusventamostrador.idCatEstatusVentaMostrador == 7
    ) {
      estatus.expanded = !estatus.expanded;
    }

    setVentaMostradorSeleccionada(ventaMostradorSeleccionada);
  };

  const handdleChecked = async (id) => {
    setCheckAllCobros(false);
    ventaMostradorSeleccionada.ventaMostradorcobro.map((cobros) => {
      if (cobros.idVentaMostradorCobro === id) {
        cobros.checked = !cobros.checked;

        if (cobros.checked) {
          notaVenta.cobrosAnticipo = notaVenta.cobrosAnticipo.concat({ idVentaMostradorCobro: id });
        } else {
          notaVenta.cobrosAnticipo = notaVenta.cobrosAnticipo.filter((pago) => pago.idVentaMostradorCobro != id);
        }
      }
    });
    setVentaMostradorSeleccionada(ventaMostradorSeleccionada);
  };

  // const isCobroChecked = async () => {
  //   let cobroChecked = false;

  //   ventaMostradorSeleccionada.ventaMostradorcobro.map((cobros) => {
  //     if (cobros.checked) {
  //       cobroChecked = cobros;
  //     }
  //   });

  //   if (!cobroChecked) {
  //     //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       dynamic_title: "Fallo",
  //       dynamic_description: "Por favor seleccione un cobro",
  //     });
  //   }

  //   return cobroChecked;
  // };

  const imprimirNotaDeVenta = async () => {
    setLoading(true);
    const res = await exportNotaDeVenta(notaVenta);

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.entries(notaVenta).length === 0 && Object.entries(ventaMostradorSeleccionada).length !== 0) {
      setNotaVenta({
        ...notaVenta,
        idVentaMostrador: ventaMostradorSeleccionada.idVentaMostrador,
        cobrosAnticipo: [],
      });
    }
  }, [ventaMostradorSeleccionada]);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Lista de Estatus Venta Mostrador</h3>

      <Card className="mt-10">
        <CardBody>
          <Row className="mt-4">
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th scope="col">Fecha Estatus</th>
                    <th scope="col">Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  {ventaMostradorSeleccionada.estatusventamostrador
                    ? ventaMostradorSeleccionada.estatusventamostrador.map((estatus, index) => {
                        return (
                          <>
                            <tr key={estatus.idEstatusVale} onClick={() => toggleExpanded(index)}>
                              <td>
                                {estatus.catEstatusventamostrador.idCatEstatusVentaMostrador == 5 ||
                                estatus.catEstatusventamostrador.idCatEstatusVentaMostrador == 7
                                  ? [estatus.expanded ? <i className="ti-angle-up"></i> : <i className="ti-angle-down"></i>]
                                  : ""}
                              </td>
                              <td>{estatus.fechaEstatus ? formatDate(estatus.fechaEstatus) : ""}</td>
                              <td>{estatus.catEstatusventamostrador.estatusVentaMostrador}</td>
                            </tr>
                            {estatus.expanded
                              ? [
                                  estatus.catEstatusventamostrador.idCatEstatusVentaMostrador == 5
                                    ? [
                                        <tr>
                                          <td colSpan={9}>  
                                            <div>
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                }}>
                                                <button
                                                  className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                                                  type="button"
                                                  onClick={imprimirNotaDeVenta}>
                                                  Nota de Venta
                                                </button>
                                                {/* <button
                                                  className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mr-6 my-6"
                                                  type="button"
                                                  // onClick={() => agregarLote(index)}
                                                >
                                                  Factura
                                                </button> */}
                                              </div>

                                              <div className="table-responsive mt-4">
                                                <table className="table table-hover table-centered table-nowrap mb-0">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col"></th>
                                                      <th scope="col">
                                                        Num.<br></br>Cobro
                                                      </th>
                                                      <th scope="col">
                                                        Forma de<br></br>Pago
                                                      </th>
                                                      <th scope="col">Subtotal</th>
                                                      <th scope="col">IVA</th>
                                                      <th scope="col">Total</th>
                                                      <th scope="col">
                                                        Folio de<br></br>Pago
                                                      </th>
                                                      <th scope="col">
                                                        Concepto<br></br>de Pago
                                                      </th>
                                                      <th scope="col">
                                                        Folio<br></br>Factura
                                                      </th>
                                                      <th scope="col">Tipo</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {ventaMostradorSeleccionada.ventaMostradorcobro &&
                                                      ventaMostradorSeleccionada.ventaMostradorcobro.map((cobros, index) => {
                                                        return (
                                                          <>
                                                            <tr key={cobros.idVentaMostradorCobro}>
                                                              <td>
                                                                {
                                                                  <input
                                                                    type="checkbox"
                                                                    onClick={() => handdleChecked(cobros.idVentaMostradorCobro)}
                                                                    checked={cobros.checked}
                                                                    value={cobros.idVentaMostradorCobro}
                                                                  />
                                                                }
                                                              </td>
                                                              <td>{cobros.idVentaMostradorCobro}</td>
                                                              <td>{cobros.catTipoCobro.tipoCobro}</td>
                                                              <td
                                                                style={{
                                                                  textAlign: "right",
                                                                }}>
                                                                ${cobros.importe.toFixed(2)}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  textAlign: "right",
                                                                }}>
                                                                ${cobros.iva.toFixed(2)}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  textAlign: "right",
                                                                }}>
                                                                ${(cobros.importe + cobros.iva).toFixed(2)}
                                                              </td>
                                                              <td>{cobros.folioDeposito ? cobros.folioDeposito : ""}</td>
                                                              <td>{cobros.conceptoPago ? cobros.conceptoPago : ""}</td>
                                                              <td>{/* {cobros.factura.folioFactura} */}</td>
                                                              <td>{cobros.anticipo ? "Anticipo" : "Saldo Restante"}</td>
                                                            </tr>
                                                          </>
                                                        );
                                                      })}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>,
                                      ]
                                    : [
                                        estatus.catEstatusventamostrador.idCatEstatusVentaMostrador == 7 ? (
                                          <tr>
                                            <td colSpan={9}>
                                              <div>
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                  }}>
                                                  <Row className="form-group">
                                                    <label className="col-sm-4 col-form-label">Motivo</label>
                                                    <Col sm={6}>
                                                      <textarea
                                                        rows={4}
                                                        className="form-control"
                                                        disabled
                                                        value={estatus.comentarios[0] ? estatus.comentarios[0].comentario : ""}
                                                      />
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        ) : null,
                                      ],
                                ]
                              : null}
                          </>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });

            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
