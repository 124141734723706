import React, { Fragment } from "react";
import { useTable, usePagination, useExpanded, useSortBy, useFilters } from "react-table";

// import { css } from "@emotion/react";

// import { Filter, DefaultColumnFilter } from "../FiltrosTabla";

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: orange;
// `;

const TableComponent = ({
  columns,
  data,
  fetchData,
  pageCount: controlledPageCount,
  indexOfPage: controlledIndex,
  loading,
  onPageChange,
  isPaginated = true,
  hideControls,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    pageCount,
    indexOfPage,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: [
          "sexo.idCatSexo",
          "lugarOrigen.idCatOrigenResidencia",
          "lugarResidencia.idCatOrigenResidencia",
          "escolaridad.idCatEscolaridad",
          "ocupacion.idCatOcupacion",
          "estadoCivil.idCatEstadoCivil",
          "tipoCompra.idCatTipoCompra",
          "almacen.idCatAlmacen",
          "catProveedorVo.idCatProveedor",
          "catLaboratoriosVo.idCatLaboratorio",
          "catProductos.idCatProducto",
          "catProducto.idCatProducto",
          "medico.idCatMedico",
          "catPaciente.idCatPaciente",
        ],
      },

      manualPagination: true,
      autoResetPage: false,
      pageCount: controlledPageCount,
      indexOfPage: controlledIndex,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  // Debounce our onFetchData call for 100ms
  // const onFetchDataDebounced = useAsyncDebounce(fetchData, 100);

  const tableStateUpdateRef = React.useRef(false);

  React.useEffect(() => {
    tableStateUpdateRef.current = true;
    // onFetchDataDebounced && onFetchDataDebounced({ pageIndex, pageSize });
    fetchData && fetchData({ pageIndex, pageSize });
  }, [pageIndex, pageSize]);

  React.useEffect(() => {
    gotoPage(0);
  }, [gotoPage]);

  React.useEffect(() => {
    if (!tableStateUpdateRef.current) {
      gotoPage(indexOfPage);
    }
  }, [data, gotoPage]);

  // clear our ref when the data is loaded, after we perform any side effects
  React.useEffect(() => {
    tableStateUpdateRef.current = false;
  }, [data]);

  return (
    <Fragment>
      <div>
        <div className="table-responsive mt-4">
          <select
            hidden={hideControls}
            className="form-control mt-3 mb-4"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ width: "120px", height: "35px" }}>
            {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
          <table className="table table-hover table-centered table-nowrap mb-0 " {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
            {/* {loading ? (
              <tbody>
                <tr>
                  <td style={{ position: "absolute", left: "80%", top: "70%", margin: "0px" }}>
                    <MoonLoader color={"#ff9933"} loading={loading} css={override} size={20} />
                    Cargando...
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )} */}
          </table>
          {Boolean(isPaginated) && !hideControls && (
            <ul className="pagination mt-4">
              <li className="page-item">
                <button
                  className="border text-grey-300 font-semibold py-2 px-4 rounded-left disabled:opacity-50"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}>
                  Primera
                </button>
              </li>
              <li className="page-item">
                <button
                  className="border text-grey-300 font-semibold py-2 px-4 disabled:opacity-50"
                  onClick={() => gotoPage(indexOfPage - 1)}
                  disabled={!canPreviousPage}>
                  {"<"}
                </button>
              </li>
              <li className="page-item">
                <button
                  className="border text-grey-300 font-semibold py-2 px-4 disabled:opacity-50"
                  onClick={() => gotoPage(indexOfPage + 1)}
                  disabled={!canNextPage}>
                  {">"}
                </button>
              </li>
              <li className="page-item">
                <button
                  className="border text-grey-300 font-semibold py-2 px-4 disabled:opacity-50"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}>
                  Última
                </button>
              </li>
              <li>
                <button className="border text-grey-300 font-semibold py-2 px-4 rounded-right disabled:opacity-50">
                  Página{" "}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default TableComponent;
