import React, {useReducer} from 'react'

import CatRolesContext from './CatRolesContext'
import CatRolesReducer from './CatRolesReducer'

import {
    LISTAR_ROLES,
} from '../../types'

import clienteAxios from '../../config/axios'


export default function CatRolesState (props) {

    const initialState = { 
        roles:[],
    } 

    const [state, dispatch] = useReducer(CatRolesReducer,initialState)

    const listarRoles = async () => {
        const res = await clienteAxios.get("/roles/activos", {
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
        })

        dispatch({
            type: LISTAR_ROLES,
            payload: res.data
        })
        
    }

    return (

        <CatRolesContext.Provider value={{
            roles: state.roles,
            listarRoles
        }}>
            {props.children}
        </CatRolesContext.Provider>

    )


}