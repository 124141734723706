import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import formatNumber from "../../../utils";

import CortesCajaContext from "../../../context/CortesCaja/CortesCajaContext";
import MedicosContext from "../../../context/Medicos/MedicosContext";
import CatTipoVentaMostradorContext from "../../../context/CatTipoVentaMostrador/CatTipoVentaMostradorContext";

import FiltroFolio from "../../Filtros/FiltroFolio/FiltroFolio";
import FiltroBusqueda from "../../Filtros/FiltroBusqueda/FiltroBusqueda";

// import AuthContext from "../../../context/Auth/AuthContext";

export default function CerrarCorteCajaFarmacia(props) {
  const history = useHistory();
  const [toggleSearchByVales, setToggleSearchByVales] = useState(false);
  const [toggleSearchByVentaMostrador, setToggleSearchByVentaMostrador] = useState(false);
  const [detallesFiltradosCorteCaja, setDetallesFiltradosCorteCaja] = useState();
  const [totalesVales, setTotalesVales] = useState({
    totalEfectivoFisico: 0,
    totalEfectivoDeposito: 0,
    totalCredito: 0,
    totalDebito: 0,
    totalTransferencia: 0,
    totalImportes: 0,
  });
  const [totalesVentasMostrador, setTotalesVentasMostrador] = useState({
    totalEfectivoFisico: 0,
    totalEfectivoDeposito: 0,
    totalCredito: 0,
    totalDebito: 0,
    totalTransferencia: 0,
    totalImportes: 0,
  });
  const [loading, setLoading] = useState(false);

  // const [inEditMode, setInEditMode] = useState({
  //   status: false,
  //   rowKey: null,
  // });

  // const [comentarioRow, setComentarioRow] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_alert_cerrar_corte: false,
    success_dlg: false,
    error_dlg: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const [comentarioNuevoVale, setComentarioNuevoVale] = useState(null);
  const [comentarioNuevoVentaMostrador, setComentarioNuevoVentaMostrador] = useState(null);

  // ACCESO AL ESTADO GLOBAL - AUTH
  // const authContext = useContext(AuthContext);
  // const { usuario } = authContext;

  //ACCESO AL ESTADO GLOBAL - MEDICOS
  const ctxMedicos = useContext(MedicosContext);
  const { medicosInternos, listarMedicosInternos } = ctxMedicos;

  const ctxTipoVentaMostrador = useContext(CatTipoVentaMostradorContext);
  const { listarTiposVentaMostrador, tipoVentaMostrador } = ctxTipoVentaMostrador;

  const cortesCajaContext = useContext(CortesCajaContext);

  const {
    setFiltrosVales,
    setFiltrosVentaMostrador,
    filtrosVentaMostrador,
    filtrosVales,
    detallesCorteCaja,
    setDetallesCorteCaja,
    updateCortesCaja,
    cerrarCorteCajaSeleccionado,
  } = cortesCajaContext;

  const setComentarioVale = (vale) => {
    const updatedCortesCaja = detallesCorteCaja.vales.map((corteCaja) => {
      if (corteCaja.idVentaVale == vale.idVentaVale) {
        return { ...corteCaja, comentario: comentarioNuevoVale };
      } else {
        return { ...corteCaja };
      }
    });
    updateCortesCaja({ ...detallesCorteCaja, vales: updatedCortesCaja });
    setComentarioNuevoVale(null);
  };
  const setComentarioVentaMostrador = (ventaMostrador) => {
    const updatedCortesCaja = detallesCorteCaja.ventasMostrador.map((corteCaja) => {
      if (corteCaja.idVentaMostrador == ventaMostrador.idVentaMostrador) {
        return { ...corteCaja, comentario: comentarioNuevoVentaMostrador };
      } else {
        return { ...corteCaja };
      }
    });
    updateCortesCaja({ ...detallesCorteCaja, ventasMostrador: updatedCortesCaja });
    setComentarioNuevoVentaMostrador(null);
  };

  const limpiarFiltrosVales = () => {
    setFiltrosVales({
      medico: "",
      folioVale: "",
      importe: "",
    });
  };

  const limpiarFiltrosVentaMostrador = () => {
    setFiltrosVentaMostrador({
      idCatTipoVentaMostrador: "",
      folioVentaMostrador: "",
      importe: "",
    });
  };

  const toggleSearchVales = () => {
    setToggleSearchByVales(!toggleSearchByVales);
    limpiarFiltrosVales();
    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      vales: detallesCorteCaja.vales,
    });
    setTotalesValesFiltrados(detallesCorteCaja.vales);
  };

  const toggleSearchVentaMostrador = () => {
    setToggleSearchByVentaMostrador(!toggleSearchByVentaMostrador);
    limpiarFiltrosVentaMostrador();
    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      ventasMostrador: detallesCorteCaja.ventasMostrador,
    });
    setTotalesVentasFiltradas(detallesCorteCaja.ventasMostrador);
  };

  const buscarPorFolioVales = async (e) => {
    e.preventDefault();
    const valeFiltradoFolio =
      (await detallesCorteCaja.vales) &&
      detallesCorteCaja.vales.filter((vale) => {
        if (!filtrosVales.folioVale) {
          return vale;
        } else {
          return filtrosVales.folioVale.toLowerCase() === vale.folio.toLowerCase();
        }
      });

    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      vales: valeFiltradoFolio,
    });
    setTotalesValesFiltrados(valeFiltradoFolio);
    return valeFiltradoFolio;
  };

  const buscarPorFiltrosVales = async (e) => {
    e.preventDefault();
    let valesFiltrados = [];

    if (detallesCorteCaja.vales) {
      valesFiltrados = await detallesCorteCaja.vales.filter((vale) => {
        if (!filtrosVales.medico && !filtrosVales.importe) {
          return vale;
        }

        if (!filtrosVales.medico) {
          return vale.importeTotal <= filtrosVales.importe;
        }

        if (!filtrosVales.importe) {
          return vale.idCatMedico == filtrosVales.medico;
        }

        if (filtrosVales.medico && filtrosVales.importe) {
          return vale.idCatMedico == filtrosVales.medico && vale.importeTotal <= filtrosVales.importe;
        }
        return vale;
      });
    }

    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      vales: valesFiltrados,
    });
    setTotalesValesFiltrados(valesFiltrados);
    return valesFiltrados;
  };

  const buscarPorFolioVentasMostrador = async (e) => {
    e.preventDefault();
    const ventaMostradorFiltrada =
      (await detallesCorteCaja.ventasMostrador) &&
      detallesCorteCaja.ventasMostrador.filter((ventaMostrador) => {
        if (!filtrosVentaMostrador.folioVentaMostrador) {
          return ventaMostrador;
        } else {
          return filtrosVentaMostrador.folioVentaMostrador.toLowerCase() === ventaMostrador.folio.toLowerCase();
        }
      });

    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      ventasMostrador: ventaMostradorFiltrada,
    });
    setTotalesVentasFiltradas(ventaMostradorFiltrada);
    return ventaMostradorFiltrada;
  };

  const buscarPorFiltrosVentasMostrador = async (e) => {
    e.preventDefault();

    // Se filtran los filtros que no estan vacios o nulos
    const filtrosSeleccionados = {};
    Object.entries(filtrosVentaMostrador)
      .filter(([, value]) => value !== null && value !== "")
      .forEach(([key, value]) => (filtrosSeleccionados[key] = value));

    let ventasFiltradas = [];

    // Si no hay filtros, regresar todas las aseguradoras

    // Si hay filtros recorrer las aseguradoras y checar por cada filtro si cumple con la condición

    if (Object.entries(filtrosSeleccionados).length === 0) {
      ventasFiltradas = detallesCorteCaja.ventasMostrador;
    } else {
      detallesCorteCaja.ventasMostrador.forEach((ventaMostrador) => {
        let ventaMostradorFiltrada = { ...ventaMostrador };
        for (let key of Object.keys(filtrosSeleccionados)) {
          if (key === "importe") {
            if (ventaMostrador["importeTotal"] > filtrosSeleccionados[key]) {
              ventaMostradorFiltrada = {};
              break;
            }
          } else {
            if (ventaMostrador[key] != filtrosSeleccionados[key]) {
              ventaMostradorFiltrada = {};
              break;
            }
          }
        }
        if (Object.entries(ventaMostradorFiltrada).length > 0) ventasFiltradas.push(ventaMostradorFiltrada);
      });
    }

    setDetallesFiltradosCorteCaja({
      ...detallesFiltradosCorteCaja,
      ventasMostrador: ventasFiltradas,
    });
    setTotalesVentasFiltradas(ventasFiltradas);
    return ventasFiltradas;
  };

  const onChangeVales = (e) => {
    setFiltrosVales({
      ...filtrosVales,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeImporteVales = (e) => {
    let value = e.target.value;
    value = Number(value.replace("$", ""));

    setFiltrosVales({
      ...filtrosVales,
      importe: value,
    });
  };
  const onChangeVentaMostrador = (e) => {
    setFiltrosVentaMostrador({
      ...filtrosVentaMostrador,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeImporteVentaMostrador = (e) => {
    let value = e.target.value;
    value = Number(value.replace("$", ""));

    setFiltrosVentaMostrador({
      ...filtrosVentaMostrador,
      importe: value,
    });
  };

  const medicosInternosChangeId = medicosInternos.map(({ idCatMedico, prefijo, catPersona, ...medico }) => ({
    ...medico,
    id: idCatMedico,
    nombre: catPersona
      ? (prefijo ? prefijo : "") + " " + catPersona.nombres + " " + catPersona.apellidoPaterno + " " + catPersona.apellidoMaterno
      : "",
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "select",
          label: "Médico Tratante",
          name: "medico",
          value: filtrosVales.medico,
          opciones: medicosInternosChangeId,
          onChange: onChangeVales,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Importe Máximo",
          type: "text",
          name: "importe",
          disabled: false,
          value: filtrosVales.importe ? `$${filtrosVales.importe}` : `$0`,
          onChange: onChangeImporteVales,
        },
      ],
    },
  ];

  const tiposVentaMostrador = tipoVentaMostrador.map(({ idCatTipoVentaMostrador, tipoVentaMostrador, ...tipoVenta }) => ({
    ...tipoVenta,
    id: idCatTipoVentaMostrador,
    nombre: tipoVentaMostrador,
  }));

  const inputColsVentasMostrador = [
    {
      col: [
        {
          colType: "select",
          label: "Tipo Venta Mostrador",
          name: "idCatTipoVentaMostrador",
          value: filtrosVentaMostrador.idCatTipoVentaMostrador,
          opciones: tiposVentaMostrador,
          onChange: onChangeVentaMostrador,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Importe Máximo",
          type: "text",
          name: "importe",
          disabled: false,
          value: filtrosVentaMostrador.importe ? `$${filtrosVentaMostrador.importe}` : `$0`,
          onChange: onChangeImporteVentaMostrador,
        },
      ],
    },
  ];

  const setTotalesValesFiltrados = async (vales) => {
    let accEfectivo = 0;
    let accEfectivoDeposito = 0;
    let accCredito = 0;
    let accDebito = 0;
    let accTransferencia = 0;
    let accImportesTotales = 0;
    vales &&
      vales.map((vale) => {
        accEfectivo += vale.importeEfectivoFisico;
        accEfectivoDeposito += vale.importeEfectivoDeposito;
        accCredito += vale.importeTarjetaCredito;
        accDebito += vale.importeTarjetaDebito;
        accTransferencia += vale.importeTransferencia;
        accImportesTotales += vale.importeTotal;
      });
    setTotalesVales({
      totalEfectivoFisico: accEfectivo,
      totalEfectivoDeposito: accEfectivoDeposito,
      totalCredito: accCredito,
      totalDebito: accDebito,
      totalTransferencia: accTransferencia,
      totalImportes: accImportesTotales,
    });
  };
  const setTotalesVentasFiltradas = async (ventasMostrador) => {
    let accEfectivo = 0;
    let accEfectivoDeposito = 0;
    let accCredito = 0;
    let accDebito = 0;
    let accTransferencia = 0;
    let accImportesTotales = 0;

    ventasMostrador &&
      ventasMostrador.map((ventaMostrador) => {
        console.log(ventaMostrador);
        accEfectivo += ventaMostrador.importeEfectivoFisico;
        accEfectivoDeposito += ventaMostrador.importeEfectivoDeposito;
        accCredito += ventaMostrador.importeTarjetaCredito;
        accDebito += ventaMostrador.importeTarjetaDebito;
        accTransferencia += ventaMostrador.importeTransferencia;
        accImportesTotales += ventaMostrador.importeTotal;
      });
    setTotalesVentasMostrador({
      totalEfectivo: accEfectivo,
      totalEfectivoDeposito: accEfectivoDeposito,
      totalCredito: accCredito,
      totalDebito: accDebito,
      totalTransferencia: accTransferencia,
      totalImportes: accImportesTotales,
    });
  };

  const checkMovimientosCorte = () => {
    if (!detallesFiltradosCorteCaja) return false;
    if (
      (detallesFiltradosCorteCaja.vales.length === 0 || detallesFiltradosCorteCaja.vales === undefined) &&
      (detallesFiltradosCorteCaja.ventasMostrador.length === 0 || detallesFiltradosCorteCaja.ventasMostrador === undefined)
    )
      return false;

    return true;
  };

  const cerrarCorteCaja = async () => {
    const hayMovimientos = checkMovimientosCorte();
    if (hayMovimientos) {
      setLoading(true);
      const res = await cerrarCorteCajaSeleccionado(detallesCorteCaja);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_alert_cerrar_corte: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          confirm_alert_cerrar_corte: false,
          success_dlg: true,
          dynamic_title: "Exito",
          dynamic_description: "El Corte de Caja fue cerrado correctamente",
        });
        setTimeout(() => history.push(`/cortescaja/`), 500);
      }
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_cerrar_corte: false,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "No se puede cerrar un corte de caja sin movimientos",
      });
    }
  };

  // const guardarCorte = async () => {
  //   setLoading(true);
  //   const res = await cerrarCorteCajaSeleccionado(detallesCorteCaja);
  //   if (res.data.error) {
  //     setLoading(false);
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       confirm_alert_cerrar_corte: false,
  //       dynamic_title: "Fallo",
  //       dynamic_description: res.data.message,
  //     });
  //   } else if (res) {
  //     setLoading(false);
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       confirm_alert_cerrar_corte: false,
  //       success_dlg: true,
  //       dynamic_title: "Exito",
  //       dynamic_description: "El Corte de Caja fue guardado correctamente",
  //     });
  //     history.push(`/cortescaja/`);
  //   }
  // };

  useEffect(() => {
    listarTiposVentaMostrador();
    listarMedicosInternos();
  }, []);

  useEffect(() => {
    async function getDetalles() {
      let res = await setDetallesCorteCaja(props);
      // let res = await setDetallesCorteCaja(props.idCorteCaja);
      setDetallesFiltradosCorteCaja(res);
      setTotalesValesFiltrados(res.vales);
      setTotalesVentasFiltradas(res.ventasMostrador);
    }

    getDetalles();
  }, []);

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        <h3 className="flex h3">Cerrar Corte de Caja (Farmacia)</h3>

        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearchVales}>
          {toggleSearchByVales ? "Buscar por filtros" : "Buscar por folio"}
        </button>

        {/* Filtros Vales */}
        <FiltroFolio
          toggleSearchBy={toggleSearchByVales}
          buscarPorFolio={buscarPorFolioVales}
          onChange={onChangeVales}
          value={filtrosVales.folioVale}
          params={{
            name: "folioVale",
            placeholder: "Escribe tu Folio de Vale",
          }}
        />

        <FiltroBusqueda
          toggleSearchBy={toggleSearchByVales}
          buscarPorFiltros={buscarPorFiltrosVales}
          onChange={onChangeVales}
          limpiarFiltros={limpiarFiltrosVales}
          paramsInput={inputCols}
        />

        <h4 className="flex mt-5">Detalles de Movimientos (Vales)</h4>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">
                          Fecha y <br></br>Hora
                        </th>
                        <th scope="col">Vale</th>
                        <th scope="col">Médico</th>
                        <th scope="col">Paciente</th>
                        <th scope="col">Efectivo Físico</th>
                        <th scope="col">
                          Efectivo Depósito<br></br>en Cuenta
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Crédito
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Débito
                        </th>
                        <th scope="col">Transferencia</th>
                        <th scope="col">
                          Importe<br></br>Total
                        </th>
                        <th scope="col">Comentario</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {detallesFiltradosCorteCaja && detallesFiltradosCorteCaja.vales
                        ? detallesFiltradosCorteCaja.vales.map((vale, index) => {
                            return (
                              <>
                                <tr key={vale.idVentaVale}>
                                  <td>{vale.fechaHora ? vale.fechaHora : ""}</td>
                                  <td>{vale.folio ? vale.folio : ""}</td>
                                  <td>{vale.medico ? vale.medico : ""}</td>

                                  <td>{vale.paciente ? vale.paciente : ""}</td>
                                  <td>{vale.importeEfectivoFisico ? formatNumber(vale.importeEfectivoFisico) : "$0"}</td>
                                  <td>{vale.importeEfectivoDeposito ? formatNumber(vale.importeEfectivoDeposito) : "$0"}</td>
                                  <td>{vale.importeTarjetaCredito ? formatNumber(vale.importeTarjetaCredito) : "$0"}</td>
                                  <td>{vale.importeTarjetaDebito ? formatNumber(vale.importeTarjetaDebito) : "$0"}</td>

                                  <td>{vale.importeTransferencia ? formatNumber(vale.importeTransferencia) : "$0"}</td>
                                  <td>{vale.importeTotal ? formatNumber(vale.importeTotal) : "$0"}</td>
                                  <td>
                                    <textarea
                                      value={vale.comentario}
                                      name="comentario"
                                      onChange={(e) => setComentarioNuevoVale(e.target.value)}
                                      onBlur={() => setComentarioVale(vale)}
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                    <thead>
                      {detallesFiltradosCorteCaja ? (
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">TOTALES</th>
                          <th scope="col">{totalesVales.totalEfectivo ? formatNumber(totalesVales.totalEfectivo) : "$0"}</th>
                          <th scope="col">
                            {totalesVales.totalEfectivoDeposito ? formatNumber(totalesVales.totalEfectivoDeposito) : "$0"}
                          </th>
                          <th scope="col">{totalesVales.totalCredito ? formatNumber(totalesVales.totalCredito) : "$0"}</th>
                          <th scope="col">{totalesVales.totalDebito ? formatNumber(totalesVales.totalDebito) : "$0"}</th>
                          <th scope="col">
                            {totalesVales.totalTransferencia ? formatNumber(totalesVales.totalTransferencia) : "$0"}
                          </th>
                          <th scope="col">{totalesVales.totalImportes ? formatNumber(totalesVales.totalImportes) : "$0"}</th>
                        </tr>
                      ) : null}
                    </thead>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={toggleSearchVentaMostrador}>
          {toggleSearchByVentaMostrador ? "Buscar por filtros" : "Buscar por folio"}
        </button>

        {/* Filtros Venta Mostrador */}
        <FiltroFolio
          toggleSearchBy={toggleSearchByVentaMostrador}
          buscarPorFolio={buscarPorFolioVentasMostrador}
          onChange={onChangeVentaMostrador}
          value={filtrosVentaMostrador.folioVentaMostrador}
          params={{
            name: "folioVentaMostrador",
            placeholder: "Escribe tu Folio de Venta Mostrador",
          }}
        />
        <FiltroBusqueda
          toggleSearchBy={toggleSearchByVentaMostrador}
          buscarPorFiltros={buscarPorFiltrosVentasMostrador}
          onChange={onChangeVentaMostrador}
          limpiarFiltros={limpiarFiltrosVentaMostrador}
          paramsInput={inputColsVentasMostrador}
        />

        <h4 className="flex mt-5">Detalles de Movimientos (Venta Mostrador)</h4>

        <Row>
          <Col>
            <Card className="mt-4">
              <CardBody>
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th scope="col">
                          Fecha y<br></br>Hora
                        </th>
                        <th scope="col">
                          Venta<br></br>Mostrador
                        </th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">
                          Efectivo<br></br>Físico
                        </th>
                        <th scope="col">
                          Efectivo Depósito<br></br>en Cuenta
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Crédito
                        </th>
                        <th scope="col">
                          Tarjeta<br></br>Débito
                        </th>
                        <th scope="col">TRANSFERENCIA</th>
                        <th scope="col">
                          IMPORTE<br></br>TOTAL
                        </th>
                        <th scope="col">COMENTARIO</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detallesFiltradosCorteCaja && detallesCorteCaja.ventasMostrador
                        ? detallesFiltradosCorteCaja.ventasMostrador.map((ventaMostrador, index) => {
                            return (
                              <>
                                <tr key={ventaMostrador.idVentaMostrador}>
                                  <td>{ventaMostrador.fechaHora ? ventaMostrador.fechaHora : ""}</td>
                                  <td>{ventaMostrador.folio ? ventaMostrador.folio : ""}</td>
                                  <td>{ventaMostrador.movimiento ? ventaMostrador.movimiento : ""}</td>
                                  <td>{ventaMostrador.cliente ? ventaMostrador.cliente : ""}</td>
                                  <td>
                                    {ventaMostrador.importeEfectivoFisico
                                      ? formatNumber(ventaMostrador.importeEfectivoFisico)
                                      : "$0"}
                                  </td>
                                  <td>
                                    {ventaMostrador.importeEfectivoDeposito
                                      ? formatNumber(ventaMostrador.importeEfectivoDeposito)
                                      : "$0"}
                                  </td>
                                  <td>
                                    {ventaMostrador.importeTarjetaCredito
                                      ? formatNumber(ventaMostrador.importeTarjetaCredito)
                                      : "$0"}
                                  </td>
                                  <td>
                                    {ventaMostrador.importeTarjetaDebito
                                      ? formatNumber(ventaMostrador.importeTarjetaDebito)
                                      : "$0"}
                                  </td>

                                  <td>
                                    {ventaMostrador.importeTransferencia
                                      ? formatNumber(ventaMostrador.importeTransferencia)
                                      : "$0"}
                                  </td>
                                  <td>{ventaMostrador.importeTotal ? formatNumber(ventaMostrador.importeTotal) : ""}</td>
                                  <td>
                                    <textarea
                                      value={ventaMostrador.comentario}
                                      name="comentario"
                                      onChange={(e) => setComentarioNuevoVentaMostrador(e.target.value)}
                                      onBlur={() => setComentarioVentaMostrador(ventaMostrador)}
                                    />
                                  </td>
                                  {/* <td>
                                    {inEditMode.status && inEditMode.rowKey === ventaMostrador.idVentaMostrador ? (
                                      <textarea value={comentarioRow} onChange={(event) => setComentarioRow(event.target.value)} />
                                    ) : (
                                      ventaMostrador.comentario
                                    )}
                                  </td> */}
                                  {/* <td>
                                    {inEditMode.status && inEditMode.rowKey === ventaMostrador.idVentaMostrador ? (
                                      <React.Fragment>
                                        <button
                                          className={
                                            "bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 border border-red-700 rounded mr-3"
                                          }
                                          onClick={() =>
                                            onSaveVentaMostrador({
                                              id: ventaMostrador.idVentaMostrador,
                                              nuevoComentario: comentarioRow,
                                            })
                                          }>
                                          Guardar
                                        </button>

                                        <button
                                          className={"bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded"}
                                          onClick={() => onCancel()}>
                                          Cancelar
                                        </button>
                                      </React.Fragment>
                                    ) : (
                                      <button
                                        className={"btn btn-primary-orange-umo"}
                                        onClick={() =>
                                          onEdit({
                                            id: ventaMostrador.idVentaMostrador,
                                            commentarioActual: ventaMostrador.comentario,
                                          })
                                        }>
                                        Modificar Comentario
                                      </button>
                                    )}
                                  </td> */}
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                    <thead>
                      {detallesFiltradosCorteCaja ? (
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">Totales</th>
                          {console.log(totalesVentasMostrador)}
                          <th scope="col">
                            {totalesVentasMostrador.totalEfectivo ? formatNumber(totalesVentasMostrador.totalEfectivo) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVentasMostrador.totalEfectivoDeposito
                              ? formatNumber(totalesVentasMostrador.totalEfectivoDeposito)
                              : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVentasMostrador.totalCredito ? formatNumber(totalesVentasMostrador.totalCredito) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVentasMostrador.totalDebito ? formatNumber(totalesVentasMostrador.totalDebito) : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVentasMostrador.totalTransferencia
                              ? formatNumber(totalesVentasMostrador.totalTransferencia)
                              : "$0"}
                          </th>
                          <th scope="col">
                            {totalesVentasMostrador.totalImportes ? formatNumber(totalesVentasMostrador.totalImportes) : "$0"}
                          </th>
                        </tr>
                      ) : null}
                    </thead>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              type="button"
              style={{
                marginRight: "2.5rem",
                marginTop: "1rem",
                background: "#ff9933",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() => guardarCorte()}>
              Guardar
            </button> */}
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              type="button"
              style={{
                marginRight: "2.5rem",
                marginTop: "1rem",
                background: "#ff9933",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() =>
                setUISweetAlert({
                  ...UISweetAlert,
                  confirm_alert_cerrar_corte: true,
                })
              }>
              Nuevo Corte de Caja
            </button>
          </Col>
        </Row>
        {UISweetAlert.confirm_alert_cerrar_corte ? (
          <SweetAlert
            title="¿Desea cerrar el Corte de Caja?"
            warning
            showCancel
            confirmBtnText="Continuar"
            confirmBtnBsStyle="success"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            onConfirm={() => cerrarCorteCaja()}
            onCancel={() => {
              setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_cerrar_corte: false,
              });
            }}></SweetAlert>
        ) : null}

        {UISweetAlert.success_dlg ? (
          <SweetAlert
            success
            title={UISweetAlert.dynamic_title}
            onConfirm={() => {
              if (UISweetAlert.redirect) {
                props.history.push(UISweetAlert.path);
              }
              setUISweetAlert({
                ...UISweetAlert,
                success_dlg: false,
              });
            }}>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {UISweetAlert.error_dlg ? (
          <SweetAlert
            error
            title={UISweetAlert.dynamic_title}
            onConfirm={() =>
              setUISweetAlert({
                ...UISweetAlert,
                error_dlg: false,
              })
            }>
            {UISweetAlert.dynamic_description}
          </SweetAlert>
        ) : null}

        {loading ? (
          <SweetAlert title="Cargando!" showConfirm={false}>
            <Spinner />
          </SweetAlert>
        ) : null}
      </div>
    </>
  );
}
