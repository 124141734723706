import {
    LISTAR_CATTIPOSOLICITUDCOMPRAS,
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_CATTIPOSOLICITUDCOMPRAS:
            return{
                ...state, 
                tiposSolicitudCompra: action.payload
            }
        default:
            return state
    }
        
}