import React, {useReducer} from "react";
import {Buffer} from "buffer";
import PacientesContext from "./PacientesContext";
import PacientesReducer from "./PacientesReducer";

import {
    LISTAR_PACIENTES,
    FILTRO_PACIENTES,
    ID_PACIENTE_SELECCIONADO,
    LISTAR_MARCADORES_PACIENTE,
    LISTAR_ARCHIVOS_IMAGEN,
    LISTAR_ARCHIVOS_NO_IMAGEN,
    SET_EXPEDIENTE_MEDICO_PACIENTE,
    LISTAR_PACIENTES_SELECT,
} from "../../types";

import {getImageDimensionsFromStream} from "../../hooks/getImageDimensionsFromStream";

import clienteAxios from "../../config/axios";

export default function PacientesState(props) {
    const initialState = {
        pacientes: [],
        pacientesSelect: [],
        pacientesActivos: [],
        filtrosPacientes: {},
        archivosPacienteImagen: [],
        archivosPacienteNoImagen: [],
        idCatPacienteSeleccionado: localStorage.getItem("idCatPacienteSeleccionado"),
        marcadores: [],
        idExpedienteMedicoDelPaciente: localStorage.getItem("idExpedienteMedicoDelPaciente"),
    };

    const [state, dispatch] = useReducer(PacientesReducer, initialState);

    const setExpedienteDelPaciente = async (idExpedienteMedico) => {
        dispatch({
            type: SET_EXPEDIENTE_MEDICO_PACIENTE,
            payload: idExpedienteMedico,
        });
    };

    const setDataPaciente = async ({page, limit, idCatMedico, esMedico}) => {
        try {
            const response = await clienteAxios.post("/pacientes/paginado/",
                {pageSize: limit, pageIndex: page, idCatMedico: idCatMedico, esMedico: esMedico, ...state.filtrosPacientes},
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            return response.data;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const loadPacientesSelect = async (page, limit, searchQuery) => {
        try {
            const response = await clienteAxios.post("/pacientes/paginado/select/",
                {pageSize: limit, pageIndex: page, nombres: searchQuery},
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            console.log("response", response)

            // const opcionesPacientes = response.data.pacientes.map((paciente) => ({
            //   value: paciente.idCatPaciente,
            //   label: `${paciente.nombres} ${paciente.apellidoPaterno} ${paciente.apellidoMaterno}`,
            // }));

            // const pacientesTemp = [...state.pacientesSelect, ...opcionesPacientes];

            const pacientesTemp = [...state.pacientesSelect, ...response.data.pacientes];
            const hasMore =
                response && response.data && response.data.pacientes ? response.data.pacientes.length < response.data.count : false;

            const newRes = {data: response.data.pacientes, hasMore: hasMore};

            dispatch({
                type: LISTAR_PACIENTES_SELECT,
                payload: pacientesTemp,
            });
            return newRes;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    // const loadPacientesInput = async (inputValue) => {
    //   try {
    //     const response = await clienteAxios.get(`/pacientes/search?q=${inputValue}`, {
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //     });

    //     const data = await response.data.pacientes;
    //     return data;
    //   } catch (error) {
    //     console.error(error);
    //     return [];
    //   }
    // };

    const listarPacientes = async () => {
        const res = await clienteAxios.get("/pacientes/activos", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: LISTAR_PACIENTES,
            payload: res.data,
        });

        return res;
    };

    const listarPacientesActivos = async () => {
        const res = await clienteAxios.get("/pacientes/activos", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: LISTAR_PACIENTES,
            payload: res.data,
        });

        return res;
    };

    const setFiltrosPacientes = async (filtrosPacientes) => {
        dispatch({
            type: FILTRO_PACIENTES,
            payload: filtrosPacientes,
        });
    };

    const uploadNuevoPaciente = async (datosPaciente) => {
        // Checar que el paciente no tenga el mismo CURP que otro paciente en la base datos

        try {
            const res = await clienteAxios.post(`/pacientes/guardar`, datosPaciente, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };
    const guardarArchivosPaciente = async (datosPaciente) => {
        // Checar que el paciente no tenga el mismo CURP que otro paciente en la base datos

        try {
            const res = await clienteAxios.post(`/pacientes/guardar/archivos`, datosPaciente, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const eliminarPacienteLog = async (logVo) => {
        // Checar que el paciente no tenga el mismo CURP que otro paciente en la base datos
        try {
          const res = await clienteAxios.post(`/pacientes/eliminar/log`, logVo, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              'Cache-Control': 'no-store', // Evita el caché del navegador
            },
          });
    
          return res;
        } catch (error) {
          if (!error.response) {
            error.response = {
              data: {
                message: "Fallo en la conexión al servidor",
                error: "hubo error",
              },
            };
          }
    
          return error.response;
        }
      };

    const eliminarPaciente = async (idCatPaciente) => {
        // Sustituir delete con dirección del back
        // const res = await clienteAxios.delete(`/pacientes/${idCatPaciente}`)
        try {
            const response = await clienteAxios.post(
                "/pacientes/eliminar/",
                {idCatPaciente: idCatPaciente},
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            return response;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const guardarMarcadores = async (catPacienteVo) => {
        const response = await clienteAxios.post("/pacientes/guardar/marcadores", catPacienteVo, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });
        if (response.error) {
            return response.error;
        } else {
            return response;
        }
    };

    const updatePaciente = async (pacienteModificado) => {
        try {
            const res = await clienteAxios.post(`/pacientes/guardar`, pacienteModificado, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const setIdCatPacienteSeleccionado = async (idCatPacienteSeleccionado) => {
        dispatch({
            type: ID_PACIENTE_SELECCIONADO,
            payload: idCatPacienteSeleccionado,
        });
    };

    const getArchivosPaciente = async (idCatPaciente) => {
        try {
            const response = await clienteAxios.post(
                "/pacientes/archivos",
                {
                    idCatPaciente: idCatPaciente ? idCatPaciente : state.idCatPacienteSeleccionado,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );
            if (idCatPaciente) {
                filterArchivosImagen(response.data, idCatPaciente);
            }
            const archivosPersonales = await response.data.filter((archivo) => {
                return archivo.tipoArchivo == 1;
            });

            return archivosPersonales;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }
            return error.response;
        }
    };

    const filterArchivosImagen = async (archivos, idCatPaciente) => {
        const archivosImagenClinica = await archivos.filter((archivo) => {
            return archivo.tipoArchivo == 2;
        });

        const archivosImagen = [];
        const archivosNoImagen = [];

        archivosImagenClinica.forEach((archivo) => {
            // eslint-disable-next-line no-useless-concat
            const re = new RegExp("\\b" + "image" + "\\b");
            if (re.test(archivo.mimeType)) {
                archivosImagen.push({...archivo});
            } else {
                archivosNoImagen.push({...archivo});
            }
        });

        archivosImagen.map(async (archivo) => {
            const res = await clienteAxios.get(`/pacientes/view/archivo/${idCatPaciente}/${archivo.idArchivo}`, {
                responseType: "arraybuffer",

                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            });

            const dataToBase64 = Buffer.from(res.data).toString("base64");

            if (!archivo.width || !archivo.height) {
                await getImageDimensionsFromStream(res.data)
                .then((dimensions) => {
                    archivo.width = dimensions.width;
                    archivo.height = dimensions.height;
                })
                .catch((error) => {
                    console.error(error);
                });
            }

            archivo.base64S = dataToBase64;
            archivo.inputStream = res.data;
        });

        dispatch({
            type: LISTAR_ARCHIVOS_IMAGEN,
            payload: archivosImagen,
        });
        dispatch({
            type: LISTAR_ARCHIVOS_NO_IMAGEN,
            payload: archivosNoImagen,
        });
        return archivosImagen;
    };

    const exportArchivoBack = async (archivoVo) => {
        try {
            const res = await clienteAxios.post(`/pacientes/export/archivo`, archivoVo, {
                responseType: "arraybuffer",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });
            var file = new Blob([res.data], {type: archivoVo.mimeType});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            } else {
                error.response = {
                    data: {
                        message: "Error, por favor inténtalo más tarde",
                        error: "Error",
                    },
                };
            }
            return error.response;
        }
    };

    const getPacienteByIdVale = async (idVale) => {
        const res = await clienteAxios.get(`/pacientes/detalles?id=${idVale}`, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });
        return res.data;
    };

    const listarMarcadores = async (idCatPaciente) => {
        const res = await clienteAxios.post(
            "/pacientes/marcadores/detalles",
            {idCatPaciente: idCatPaciente},
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            }
        );

        const arr = res.data.map((marc) => {
            marc.color = `rgba(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(
                Math.random() * 256
            )},1)`;
            marc.fechasRegistro = marc.fechasRegistro.map((fecha) => {
                let fecSplit = fecha.split("T")[0];
                return `${fecSplit.split("-")[2]}/${fecSplit.split("-")[1]}/${fecSplit.split("-")[0]}`;
            });
            return marc;
        });

        dispatch({
            type: LISTAR_MARCADORES_PACIENTE,
            payload: arr,
        });
    };

    const listarMarcadoresEvolucion = async (pacienteVo) => {
        try {
            const res = await clienteAxios.post("/pacientes/marcadores/detalles", pacienteVo, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            return res;
        } catch (error) {
            return error;
        }
    };

    return (
        <PacientesContext.Provider
            value={{
                pacientes: state.pacientes,
                pacientesSelect: state.pacientesSelect,
                pacientesActivos: state.pacientesActivos,
                filtrosPacientes: state.filtrosPacientes,
                idCatPacienteSeleccionado: state.idCatPacienteSeleccionado,
                marcadores: state.marcadores,
                archivosPacienteImagen: state.archivosPacienteImagen,
                archivosPacienteNoImagen: state.archivosPacienteNoImagen,
                idExpedienteMedicoDelPaciente: state.idExpedienteMedicoDelPaciente,
                setExpedienteDelPaciente,
                setFiltrosPacientes,
                uploadNuevoPaciente,
                eliminarPacienteLog,
                eliminarPaciente,
                updatePaciente,
                setDataPaciente,
                listarPacientesActivos,
                setIdCatPacienteSeleccionado,
                guardarArchivosPaciente,
                getArchivosPaciente,
                exportArchivoBack,
                listarMarcadores,
                listarPacientes,
                getPacienteByIdVale,
                guardarMarcadores,
                loadPacientesSelect,
                listarMarcadoresEvolucion,
            }}>
            {props.children}
        </PacientesContext.Provider>
    );
}
