import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import { useParams } from "react-router-dom";

import InformacionGeneralOrdenCompra from "../InformacionGeneralOrdenCompra/index";
import ListadoProductosOrdenCompra from "../ListadoProductosOrdenCompra/index";
import OrdenesCompraContext from "../../context/OrdenesCompra/OrdenesCompraContext";

export default function RecepcionOrdenCompraProductos(props) {
  const { idOrdenCompra } = useParams();

  const [UISweetAlert, setUISweetAlert] = useState({
    error_dlg: false,
  });

  const [flagSeleccionarOrdenCompra, setFlagSeleccionarOrdenCompra] = useState(false);

  // ACCESO A ESTADO GLOBAL - VALES
  const ctxOrdenCompra = useContext(OrdenesCompraContext);
  const { seleccionarOrdenCompra, ordenCompraShowEdit, setOrdenCompraShowEdit, errorOrdenCompraSeleccionada, ordenCompraSeleccionada } = ctxOrdenCompra;

  const validarOrdenCompra = async () => {
    const estatusActual = ordenCompraSeleccionada.estatusordencompra[ordenCompraSeleccionada.estatusordencompra.length - 1].catEstatusordencompra;

    if (!(estatusActual.idCatEstatusOrdenCompra != 1 || (estatusActual.idCatEstatusOrdenCompra != 6 && ordenCompraSeleccionada.fechaRececpion == null))) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
      });
    }
  };

  useEffect(() => {
    setOrdenCompraShowEdit({
      ...ordenCompraShowEdit,
      hiddenRecepcion: false,
      disabledRecepcion: false,
      guardarOrdenCompra: true,
      guardarRecepcionOrdenCompra: true,
      aprobarPagoOrdenCompra: false,
      hiddenArchivos: false,
      disabledArchivos: false,
      hiddenRecibida: true,
      cancelarOrdenCompra: true,
    });

    if (!errorOrdenCompraSeleccionada && !flagSeleccionarOrdenCompra) {
      seleccionarOrdenCompra(idOrdenCompra);
      setFlagSeleccionarOrdenCompra(true);
    }

    if (!errorOrdenCompraSeleccionada && flagSeleccionarOrdenCompra && Object.entries(ordenCompraSeleccionada).length !== 0) {
      validarOrdenCompra();
    }

    if (errorOrdenCompraSeleccionada) {
      props.history.push("/recepcion/generica/producto");
    }
  }, [errorOrdenCompraSeleccionada, ordenCompraSeleccionada]);

  return (
    <div className="mt-4">
      <InformacionGeneralOrdenCompra props={props} />
      <ListadoProductosOrdenCompra props={props} isHistorico={false} isRecepcionFarmacia={true} />

      {UISweetAlert.error_dlg ? (
        <SweetAlert error title="Fallo" onConfirm={() => props.history.push("/recepcion/generica/producto")}>
          Ya se registro la recepción de producto.
        </SweetAlert>
      ) : null}
    </div>
  );
}
