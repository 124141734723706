import {Route, Switch} from "react-router-dom";

// import ErrorBoundary from "./components/ErrorBoundary";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Vales from "./components/Vales";
import DetallesVale from "./components/DetallesVale";
import HistoricoVale from "./components/HistoricoVale";
import NuevoVale from "./components/NuevoVale";
import Autorizaciones from "./components/ValesAutorizaciones"
import CobroVale from "./components/CobroVale";
import VentaMostrador from "./components/VentaMostrador";
import NuevaVentaMostrador from "./components/NuevaVentaMostrador";
import CobroVentaMostrador from "./components/CobroVentaMostrador";
import CompletarVentaMostrador from "./components/CompletarVentaMostrador";
import HistoricoVentaMostrador from "./components/HistoricoVentaMostrador";
import RecepcionGenericaProductos from "./components/RecepcionGenericaProductos";
import Usuarios from "./components/Usuarios";
import RequerimientosPendientes from "./components/RequerimientosCompraPendientes";
import NuevaSolicitudCompraPendientes from "./components/NuevaSolicitudCompraPendientes";
import HistoricoSolicitudCompra from "./components/HistoricoSolicitudCompra";
import SolicitudCompraRegistroCotizacion from "./components/SolicitudCompraRegistroCotizacion";
import SolicitudCompraAutorizacion from "./components/SolicitudCompraAutorizacion";
import SolicitarOrdenCompra from "./components/SolicitarOrdenCompraProveedor";
import OrdenesDeCompra from "./components/OrdenesDeCompra";
import RecepcionOrdenCompraProductos from "./components/RecepcionOrdenCompraProductos";
import RecibidaOrdenCompra from "./components/RecibidaOrdenCompra";
import HistoricoOrdenCompra from "./components/HistoricoOrdenCompra";
import ConsultaCompraProducto from "./components/ConsultaCompraProducto";
import ReporteDesplazamientoProducto from "./components/ReporteDesplazamientoProducto";
import RecepcionVentaProductos from "./components/RecepcionVentaProductos";
import RegistroPagosOrdenCompra from "./components/RegistroPagosOrdenCompra";
import ListadoPagosMedicos from "./components/ListadoPagosMedicos";
import NuevoPagoMedico from "./components/NuevoPagoMedico";
import ListadoCortesCaja from "./components/ListadoCortesCaja";
import CerrarCorteCaja from "./components/CerrarCorteCaja";
import DetalleCorteCaja from "./components/DetalleCorteCaja";
import Calendario from "./components/Calendario";
import EspaciosAtencion from "./components/EspaciosAtencion";
import DetallePagoMedico from "./components/DetallePagoMedico";
import Clientes from "./components/Clientes";
import Medicos from "./components/Medicos";
import Pacientes from "./components/Pacientes";
import Aseguradoras from "./components/Aseguradoras";
import Almacenes from "./components/Almacenes";
import Anaqueles from "./components/Anaqueles";
import Estantes from "./components/Estantes";
import Ubicaciones from "./components/Ubicaciones";
import ListaProductosInventario from "./components/ListaProductosInventario";
import ListaProductosUbicacion from "./components/ListaProductosUbicacion";
import Proveedores from "./components/Proveedores";
import Fabricantes from "./components/Fabricantes";
import AsignarUbicacionInventario from "./components/AsignarUbicacionInventario";
import TrasladoProductoInventario from "./components/TrasladoProductoInventario";
import ListadoTrasladoProductoInventario from "./components/ListadoTrasladoProductoInventario";
import ConsentimientoCliente from "./components/ConsentimientoCliente";
import Medicamentos from "./components/Medicamentos";
import Insumos from "./components/Insumos";
import Soluciones from "./components/Soluciones";
import MedicamentosCompraExterna from "./components/MedicamentosCompraExterna";
import CargaMasivaPrecios from "./components/CargaMasivaPrecios";
import BajasInventario from "./components/BajasInventario";
import NuevaBajaInvetario from "./components/NuevaBajaInventario";
import InventarioCentroMezclas from "./components/InventarioCentroMezclas";
import PruebaModalMedicamentoFalso from "./components/PruebaModalMedicamentoFalso";
import NotaEnfermeria from "./components/NotaEnfermeria";
import BitacoraMezclas from "./components/BitacoraMezclas";
import ReporteReacciones from "./components/ReporteReacciones";
import HistoriaClinicaInicial from "./components/HistoriaClinicaInicial";
// import PadecimientoActual from "./components/PadecimientoActual";
import ConsultaSeguimiento from "./components/ConsultaSeguimiento";
import NotasInterconsultas from "./components/NotasInterconsultas";
import RecetaExpediente from "./components/RecetasExpediente";
import EstudiosLaboratorio from "./components/EstudiosLaboratorio";
import HojaIndicaciones from "./components/HojaIndicaciones";
import ReporteVentas from "./components/ReporteVentas";
import ReporteUtilidades from "./components/ReporteUtilidades";
import NuevaFactura from "./components/NuevaFactura";
import MedicamentoFalso from "./components/MedicamentoFalso";
import Notificaciones from "./components/Notificaciones";
import ReporteSaldosAseguradora from "./components/ReporteSaldosAseguradora";
import ArchivosPaciente from "./components/ArchivosPaciente";
import MarcadoresPaciente from "./components/MarcadoresPaciente";
import NotaEvolucion from "./components/NotaEvolucion";
import CatServicios from "./components/CatServicios";
import BitacoraMezclasPaginadas from "./components/BitacoraMezclasPaginadas";
import ReporteReaccionesPaginadas from "./components/ReporteReaccionesPaginadas";
import Usuario from "./components/Usuario";
import RutaPrivada from "./components/ruta/RutaPrivada";
import NuevaSolicitudCompra from "./components/NuevaSolicitudCompra";
import SolicitudesCompras from "./components/SolicitudesCompras";
import DocumentosContables from "./components/DocumentosContables";
import Estadisticas from "./components/Estadisticas/Estadisticas";
import ValesMedico from "./components/ValesMedico";
import InformeMezclas from "./components/Estadisticas/InformeMezclas"
import InformeCompras from "./components/Estadisticas/InformeCompras";
import AseguradorasMenu from "./components/Estadisticas/EstadisticasAseguradora";
import InformeFarmacia from "./components/Estadisticas/InformeFarmacia"

const Routes = () => {
    return (
        <Switch>
            <Route key={1} exact path="/login" component={Login}/>
            <Route
                key={2}
                path={"/"}
                render={() => (
                    <Dashboard>
                        <Switch>
                            <RutaPrivada key={0} path={`/`} component={Estadisticas} exact/>
                            <RutaPrivada key={1} path={`/agenda`} component={Calendario} exact/>
                            {/* Rutas para vales */}

                            <RutaPrivada key={80} path={`/vales/medico`} component={ValesMedico} exact/>
                            <RutaPrivada key={3} path={`/vales/nuevo`} component={NuevoVale} exact/>
                            <RutaPrivada key={4} path={`/vales/:idVale`} component={DetallesVale} exact/>
                            <RutaPrivada key={5} path={`/vales/historico/:idVale`} component={HistoricoVale} exact/>
                            <RutaPrivada key={6} path={`/vales/cobro/:idVale`} component={CobroVale} exact/>
                            <RutaPrivada key={2} path={`/vales`} component={Vales} exact/>
                            <RutaPrivada key={2} path={`/autorizaciones`} component={Autorizaciones} exact/>
                            {/* Rutas para venta mostrador */}
                            <RutaPrivada key={7} path={`/ventasmostrador`} component={VentaMostrador} exact/>
                            <RutaPrivada key={8} path={`/ventasmostrador/nuevo`} component={NuevaVentaMostrador} exact/>
                            <RutaPrivada key={9} path={`/ventasmostrador/cobro/:idVentaMostrador`} component={CobroVentaMostrador} exact/>
                            <RutaPrivada
                                key={10}
                                path={`/ventasmostrador/historico/:idVentaMostrador`}
                                component={HistoricoVentaMostrador}
                                exact
                            />
                            <RutaPrivada
                                key={11}
                                path={`/ventasmostrador/completar/:idVentaMostrador`}
                                component={CompletarVentaMostrador}
                                exact
                            />
                            {/* Rutas para compras y Rutas para solicitudes de compra */}
                            <RutaPrivada key={12} path={`/solicitudescompra`} component={SolicitudesCompras} exact/>
                            <RutaPrivada key={13} path={`/solicitudescompra/nuevo`} component={NuevaSolicitudCompra} exact/>
                            <RutaPrivada
                                key={14}
                                path={`/solicitudescompra/historico/:idSolicitudCompra`}
                                component={HistoricoSolicitudCompra}
                                exact
                            />
                            <RutaPrivada
                                key={15}
                                path={`/solicitudescompra/cotizacion/:idSolicitudCompra`}
                                component={SolicitudCompraRegistroCotizacion}
                                exact
                            />
                            <RutaPrivada
                                key={16}
                                path={`/solicitudescompra/autorizacion/:idSolicitudCompra`}
                                component={SolicitudCompraAutorizacion}
                                exact
                            />
                            <RutaPrivada
                                key={17}
                                path={`/solicitudescompra/ordencompra/:idSolicitudCompra`}
                                component={SolicitarOrdenCompra}
                                exact
                            />
                            {/* Requerimientos pendientes */}
                            <RutaPrivada key={18} path={`/requerimientos/pendientes`} component={RequerimientosPendientes} exact/>
                            <RutaPrivada
                                key={19}
                                path={`/solicitudescompra/nuevo/pendientes`}
                                component={NuevaSolicitudCompraPendientes}
                                exact
                            />
                            {/* Recepcion generica producto */}
                            <RutaPrivada key={20} path={`/recepcion/generica/producto`} component={RecepcionGenericaProductos} exact/>
                            {/* Recepcion de productos ya sea de venta vale o de venta mostrador */}
                            <RutaPrivada key={21} path={`/recepcionproducto/venta`} component={RecepcionVentaProductos} exact/>
                            {/* Ordenes de Compra*/}
                            <RutaPrivada key={22} path={`/ordenescompra`} component={OrdenesDeCompra} exact/>
                            <RutaPrivada
                                key={23}
                                path={`/recepcionproducto/ordenescompra/:idOrdenCompra`}
                                component={RecepcionOrdenCompraProductos}
                                exact
                            />
                            <RutaPrivada key={24} path={`/ordenescompra/recibida/:idOrdenCompra`} component={RecibidaOrdenCompra} exact/>
                            <RutaPrivada key={25} path={`/ordenescompra/historico/:idOrdenCompra`} component={HistoricoOrdenCompra} exact/>
                            <RutaPrivada key={26} path={`/comprasproducto`} component={ConsultaCompraProducto} exact/>
                            <RutaPrivada key={27} path={`/desplazamientoproducto`} component={ReporteDesplazamientoProducto} exact/>
                            {/* Espacios de Atencion */}
                            <RutaPrivada key={28} path={`/espacios`} component={EspaciosAtencion} exact/>
                            <RutaPrivada key={29} path={`/ordenescompra/pago`} component={RegistroPagosOrdenCompra} exact/>
                            {/*PagosMedicos*/}
                            <RutaPrivada key={30} path={`/pagos/medico`} component={ListadoPagosMedicos} exact/>
                            <RutaPrivada key={31} path={`/pagos/medico/nuevo`} component={NuevoPagoMedico} exact/>
                            <RutaPrivada key={32} path={`/pagos/medico/detalle/:folioValePago`} component={DetallePagoMedico} exact/>
                            {/* Cortes Caja */}
                            <RutaPrivada key={33} path={`/cortescaja`} component={ListadoCortesCaja} exact/>
                            <RutaPrivada key={34} path={`/cortescaja/cerrar`} component={CerrarCorteCaja} exact/>
                            <RutaPrivada key={35} path={`/cortescaja/detalle/:idCorteCaja`} component={DetalleCorteCaja} exact/>
                            {/* Administración Usuarios */}
                            <RutaPrivada key={36} path={`/usuarios`} component={Usuarios} exact/>
                            <RutaPrivada key={100} path={`/usuario/:nombreUsuario`} component={Usuario} exact/>
                            {/* Administracion Pacientes */}
                            <RutaPrivada key={37} path={"/pacientes"} component={Pacientes} exact/>
                            {/* Administracion Clientes */}
                            <RutaPrivada key={38} path={"/clientes"} component={Clientes} exact/>
                            {/* Administracion Medicos */}
                            <RutaPrivada key={39} path={"/medicos"} component={Medicos} exact/>
                            {/* Administracion Aseguradoras */}
                            <RutaPrivada key={40} path={"/aseguradoras"} component={Aseguradoras} exact/>
                            {/* Administracion de almacenes */}
                            <RutaPrivada key={41} path={`/almacenes`} component={Almacenes} exact/>
                            <RutaPrivada key={42} path={`/proveedores`} component={Proveedores} exact/>
                            <RutaPrivada key={43} path={`/fabricantes`} component={Fabricantes} exact/>
                            <RutaPrivada key={44} path={`/medicamentos`} component={Medicamentos} exact/>
                            <RutaPrivada key={45} path={`/insumos`} component={Insumos} exact/>
                            <RutaPrivada key={45} path={`/servicios`} component={CatServicios} exact/>
                            <RutaPrivada key={46} path={`/soluciones`} component={Soluciones} exact/>
                            <RutaPrivada key={47} path={`/anqueles/:idCatAlmacen`} component={Anaqueles} exact/>
                            <RutaPrivada key={48} path={`/estantes/:idCatAnaquel`} component={Estantes} exact/>
                            <RutaPrivada key={49} path={`/ubicaciones/:idCatEstante`} component={Ubicaciones} exact/>
                            <RutaPrivada key={50} path={`/carga/precios`} component={CargaMasivaPrecios} exact/>
                            <RutaPrivada key={51} path={`/productos/inventario`} component={ListaProductosInventario} exact/>
                            <RutaPrivada key={52} path={`/productos/ubicacion`} component={ListaProductosUbicacion} exact/>
                            <RutaPrivada
                                key={53}
                                path={`/inventario/asignar/ubicacion/:idInventario`}
                                component={AsignarUbicacionInventario}
                                exact
                            />
                            <RutaPrivada key={54} path={`/inventario/traslado/:idInventario`} component={TrasladoProductoInventario} exact/>
                            <RutaPrivada key={55} path={`/traslados`} component={ListadoTrasladoProductoInventario} exact/>
                            <RutaPrivada key={56} path={`/consentimiento`} component={ConsentimientoCliente} exact/>
                            <RutaPrivada key={57} path={`/bajainventarios`} component={BajasInventario} exact/>
                            <RutaPrivada key={58} path={`/inventario/baja/:idInventario`} component={NuevaBajaInvetario} exact/>
                            {/* Centro de mezclas */}
                            <RutaPrivada key={59} path={`/centromezclas/inventario`} component={InventarioCentroMezclas} exact/>
                            <RutaPrivada key={60} path={`/centromezclas/medicamento/falso`} component={PruebaModalMedicamentoFalso} exact/>
                            <RutaPrivada key={61} path={`/centromezclas/bitacoras/:idVale`} component={BitacoraMezclas} exact/>
                            <RutaPrivada key={81} path={`/centromezclas/bitacoras`} component={BitacoraMezclasPaginadas} exact/>
                            <RutaPrivada key={62} path={`/centromezclas/reacciones/:idVale`} component={ReporteReacciones} exact/>
                            <RutaPrivada key={62} path={`/centromezclas/reacciones`} component={ReporteReaccionesPaginadas} exact/>
                            <RutaPrivada key={63} path={`/medicamentoscompraexterna`} component={MedicamentosCompraExterna} exact/>
                            <RutaPrivada key={64} path={`/medicamentofalso/`} component={MedicamentoFalso} exact/>
                            {/* Quimio */}
                            <RutaPrivada key={65} path={`/quimio/nota/enfermeria/:idExpedienteMedico`} component={NotaEnfermeria} exact/>
                            <RutaPrivada key={80} path={`/quimio/nota/evolucion/:idExpedienteMedico`} component={NotaEvolucion} exact/>
                            {/* Expediente medico*/}
                            <RutaPrivada key={66} path={`/historia/clinica/inicial`} component={HistoriaClinicaInicial} exact/>
                            {/* <RutaPrivada path={`/padecimiento/actual`} component={PadecimientoActual} exact /> */}
                            <RutaPrivada key={67} path={`/consulta/seguimiento`} component={ConsultaSeguimiento} exact/>
                            <RutaPrivada key={68} path={`/nota/interconsulta`} component={NotasInterconsultas} exact/>
                            <RutaPrivada key={69} path={`/archivos/paciente`} component={ArchivosPaciente} exact/>
                            <RutaPrivada key={70} path={`/estudios/laboratorio`} component={EstudiosLaboratorio} exact/>
                            <RutaPrivada key={71} path={`/hoja/indicacion`} component={HojaIndicaciones} exact/>
                            <RutaPrivada key={72} path={`/consulta/recetas/:idConsultaSeguimiento`} component={RecetaExpediente} exact/>
                            <RutaPrivada key={73} path={`/marcadores/paciente`} component={MarcadoresPaciente} exact/>
                            {/* Contabilidad */}
                            <RutaPrivada key={74} path={`/contabilidad/reporte/ventas`} component={ReporteVentas} exact/>
                            <RutaPrivada key={75} path={`/contabilidad/reporte/utilidades`} component={ReporteUtilidades} exact/>
                            <RutaPrivada key={76} path={`/nueva/factura`} component={NuevaFactura} exact/>
                            <RutaPrivada key={77} path={`/documentos/contables`} component={DocumentosContables} exact/>
                            <RutaPrivada key={78} path={`/contabilidad/reporte/aseguradora`} component={ReporteSaldosAseguradora} exact/>
                            {/* Notificaciones*/}
                            <RutaPrivada key={79} path={`/notificaciones`} component={Notificaciones} exact/>
                            {/* Informes*/}
                            <RutaPrivada key={90} path={`/estadisticas/informemezclas`} component={InformeMezclas} exact/>       
                            <RutaPrivada key={91} path={`/estadisticas/informecompras`} component={InformeCompras} exact/> 
                            <RutaPrivada key={92} path={`/estadisticas/informefarmacia`} component={InformeFarmacia} exact/>                                                                                                     
                            <RutaPrivada key={93} path={`/estadisticas/estadisticaseseguradora`} component={AseguradorasMenu} exact/>                                                                                                     
                        </Switch>
                    </Dashboard>
                )}
            />
        </Switch>
    );
};

export default Routes;
