import React, {useReducer} from 'react'
import CatTipoCobroContext from './CatTipoCobroContext'
import CatTipoCobroReducer from './CatTipoCobroReducer'

import clienteAxios from '../../config/axios'

import {
    LISTAR_TIPOCOBROS
} from '../../types'


export default function CatTipoCobroState  (props) {

    const initialState = {
        tipoCobros: []
    }

    const [state, dispatch] = useReducer(CatTipoCobroReducer, initialState)
    

    // Retorna el usuario autenticado
    const listarCatTipoCobros = async () => {

        const res = await clienteAxios.get("/tipocobro" , {
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        })        
           
        dispatch({
            type: LISTAR_TIPOCOBROS,
            payload: res.data
        })
    }   

    return (
        <CatTipoCobroContext.Provider value={{
            tipoCobros: state.tipoCobros,
            listarCatTipoCobros,
        }}>
            {props.children}
        </CatTipoCobroContext.Provider>
    )
}
