import React, {forwardRef} from 'react';
import formatNumber from "../../utils";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import {Bar} from 'react-chartjs-2'; // Use the Bar component
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {barChartOptions} from "../../config/chartOptions";

ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const HorizontalStackedBarChart = (props) => {
    const shouldDisplayDataLabels = window.innerWidth > 768;
    
    const updatedOptions = {
        ...barChartOptions,
        
        indexAxis: 'y', // Use 'y' for horizontal bars
        plugins: {
            ...barChartOptions.plugins,
            legend: {
                display: true,
                position: 'bottom',
            },
            title: {
                ...barChartOptions.plugins.title,
                text: props.title,
                padding: {
                    bottom: 30,
                },
            },
            datalabels: {
                anchor: 'center',
                display: shouldDisplayDataLabels,
                align: 'right',
                // padding: {
                //     right: 20,
                //     left: 20,
                // },
                formatter: (value) => props.requiresMoneyFormat ? formatNumber(value) : value,
                color: 'rgb(40,40,40)',
                font: {
                    size: shouldDisplayDataLabels ? 14 : 10,
                    weight: 'bold',
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return props.requiresMoneyFormat ? formatNumber(tooltipItem.raw) : tooltipItem.raw;
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    display: false,
                    callback: function (val, index) {
                        return props.requiresMoneyFormat ? formatNumber(val) : val;
                    },
                },
            },
            y: {
                stacked: true,
            },
        },
    };

    return (
        <Bar options={updatedOptions} data={props.data} style={{height: '700px'}}/>
    );
};

export default HorizontalStackedBarChart;