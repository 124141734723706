import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CatTipoCobroContext from "../../context/CatTipoCobro/CatTipoCobroContext";
import VentaMostradorContext from "../../context/VentaMostrador/VentaMostradorContext";
import EstatusVentaMostradorContext from "../../context/EstatusVentaMostrador/EstatusVentaMostradorContext";

import formatNumber from "../../utils/index";

import useWindowDimensions from "../../hooks/dimensionsHook";

export default function CobroVentaMostrador(props) {
  const { width } = useWindowDimensions();
  const { idVentaMostrador } = useParams();

  // eslint-disable-next-line no-unused-vars
  const [hiddenGenerarFactura, setHiddenGenerarFactura] = useState(true);
  const [nuevoCobro, setNuevoCobro] = useState({});
  const [hiddenCatCobroEfectivo, setHiddenCatCobroEfectivo] = useState(true);
  const [hiddenBancoYFolio, setHiddenBancoYFolio] = useState(true);
  const [hiddenInsertarCobro, setHiddenInsertarCobro] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [checkAllCobros, setCheckAllCobros] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [hiddenConfirmarContabilidad, setHiddenConfirmarContabilidad] = useState(true);
  const [hiddenConceptoDePago, setHiddenConceptoDePago] = useState(true);

  const [notaVenta, setNotaVenta] = useState({});
  const [precioCubiertoGlobal, setPrecioCubiertoGlobal] = useState(false);

  const [UISweetAlert, setUISweetAlert] = useState({
    success_dlg: false,
    confirm_alert_insertar_cobro: false,
    confirm_alert_eliminar_cobro: false,
    confirm_alert_generar_complemento: false,
    confirm_eliminar_progreso_cobro: false,
    confirm_alert_cambiar_estatus_cobrado: false,
    error_dlg: false,
    reload: false,
    redirect: false,
    path: "",
    dynamic_title: "",
    dynamic_description: "",
  });

  const [totalDiferencia, setTotalDiferencia] = useState({
    total: 0,
    diferencia: 0,
    importeCobro: 0,
    ivaCobro: 0,
    importeVenta: 0,
    ivaVenta: 0,
  });

  const [porcentajeIVAImporte, setPorcentajeIVAImporte] = useState({
    procentajeIVA: 0,
    procentajeImporte: 0,
  });

  const fechaActual = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "";

  const [cancelarVenta, setCancelarVenta] = useState({
    fechaCancelacion: new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "",
    comentarioEstatus: "",
  });

  const [loading, setLoading] = useState(false);

  //Contexto de Tipos de cobro
  const ctxTipoCobro = useContext(CatTipoCobroContext);
  const { tipoCobros, listarCatTipoCobros } = ctxTipoCobro;

  const ctxVentaMostrador = useContext(VentaMostradorContext);
  const {
    ventaMostradorSeleccionada,
    seleccionarVentaMostrador,
    setVentaMostradorSeleccionada,
    exportNotaDeVenta,
    cancelarVentaMostrador,
    registarCobroVentaMostrador,
    eliminarCobroVentaMostrador,
    actualizarEstatusVentaMostrador,
  } = ctxVentaMostrador;

  //ACCESO AL ESTADO GLOBAL - Estatus Vale
  const ctxEstatusVentaMostrador = useContext(EstatusVentaMostradorContext);
  const { getEstatusCancelado, estatusCancelado, getEstatusCobrado, estatusCobrado } = ctxEstatusVentaMostrador;

  const onchange = async (e) => {
    setNuevoCobro({
      ...nuevoCobro,
      [e.target.name]: e.target.value,
    });
  };

  const calcularPorcentajes = () => {
    const importe = ventaMostradorSeleccionada.importeTotal;
    const iva = ventaMostradorSeleccionada.ivaTotal;

    const total = importe + iva;
    let procentajeIVA = ((iva * 100) / total).toFixed(2);
    let procentajeImporte = ((importe * 100) / total).toFixed(2);
    // if( (Number(procentajeImporte) + Number(procentajeIVA)) != 100){
    //     procentajeImporte = Number(procentajeImporte) + (Number(procentajeImporte) + Number(procentajeIVA));
    // }

    setPorcentajeIVAImporte({
      procentajeIVA: procentajeIVA,
      procentajeImporte: procentajeImporte,
    });
  };

  const calcularCantidadRestante = () => {
    const importeVenta = ventaMostradorSeleccionada.importeTotal;
    const ivaVenta = ventaMostradorSeleccionada.ivaTotal;
    let ivaCobros = 0;
    let importeCobros = 0;

    ventaMostradorSeleccionada.ventaMostradorcobro.map((cobro) => {
      if (!cobro.anticipo) {
        ivaCobros += Number(cobro.iva);
        importeCobros += Number(cobro.importe);
      }
    });

    let diferencia = (
      importeVenta +
      ivaVenta -
      ivaCobros -
      importeCobros -
      (ventaMostradorSeleccionada.anticipo ? ventaMostradorSeleccionada.anticipo : 0.0)
    ).toFixed(2);

    //Si la diferencia llega 0, ya se cobro todo
    if (diferencia <= 0) {
      setHiddenInsertarCobro(true);
      diferencia = 0;
    } else {
      setHiddenInsertarCobro(false);
    }

    setTotalDiferencia({
      total: importeVenta + ivaVenta,
      diferencia: diferencia,
      importeCobro: importeCobros,
      ivaCobro: ivaCobros,
      importeVenta: importeVenta,
      ivaVenta: ivaVenta,
      anticipo: ventaMostradorSeleccionada.anticipo ? ventaMostradorSeleccionada.anticipo : null,
    });
  };

  const handdleChecked = async (id) => {
    setCheckAllCobros(false);
    ventaMostradorSeleccionada.ventaMostradorcobro.map((cobros) => {
      if (cobros.idVentaMostradorCobro === id) {
        cobros.checked = !cobros.checked;

        if (cobros.checked) {
          notaVenta.cobrosAnticipo = notaVenta.cobrosAnticipo.concat({ idVentaMostradorCobro: id });
        } else {
          notaVenta.cobrosAnticipo = notaVenta.cobrosAnticipo.filter((pago) => pago.idVentaMostradorCobro != id);
        }
      }
    });
    setVentaMostradorSeleccionada(ventaMostradorSeleccionada);
  };

  const eliminarCobro = async () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_eliminar_cobro: false,
    });

    setLoading(true);

    const res = await eliminarCobroVentaMostrador(UISweetAlert.idCobroDelete);

    if (res) {
      ventaMostradorSeleccionada.ventaMostradorcobro = ventaMostradorSeleccionada.ventaMostradorcobro.filter(
        (cobro) => cobro.idVentaMostradorCobro != UISweetAlert.idCobroDelete
      );
      setVentaMostradorSeleccionada(ventaMostradorSeleccionada);

      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_eliminar_cobro: false,
        dynamic_description: "Se eliminó el cobro",
      });
      calcularCantidadRestante();
    }
  };

  const pagarDiferencia = () => {
    setNuevoCobro({
      ...nuevoCobro,
      cantidadPagar: formatNumber(totalDiferencia.diferencia),
      importe: (totalDiferencia.diferencia * porcentajeIVAImporte.procentajeImporte) / 100,
      iva: (totalDiferencia.diferencia * porcentajeIVAImporte.procentajeIVA) / 100,
      recibido: null,
      cambio: null,
    });
  };

  const onChangeTipoCobro = (e) => {
    setNuevoCobro({
      ...nuevoCobro,
      [e.target.name]: e.target.value,
      folioDeposito: null,
      bancoDeposito: null,
      cambio: null,
      recibido: null,
    });

    if (e.target.value == 1 || e.target.value == 2) {
      setHiddenCatCobroEfectivo(false);
      setHiddenBancoYFolio(true);
      setHiddenConfirmarContabilidad(true);
      setHiddenConceptoDePago(true);
    } else if (e.target.value == 4 || e.target.value == 3) {
      setHiddenBancoYFolio(false);
      setHiddenCatCobroEfectivo(true);
      setHiddenConfirmarContabilidad(true);
      setHiddenConceptoDePago(true);
    } else if (e.target.value == 2) {
      setHiddenBancoYFolio(false);
      setHiddenCatCobroEfectivo(true);
      setHiddenConceptoDePago(false);
      setHiddenConfirmarContabilidad(false);
    } else {
      setHiddenConfirmarContabilidad(true);
      setHiddenConceptoDePago(true);
      setHiddenCatCobroEfectivo(true);
      setHiddenBancoYFolio(true);
    }
  };

  const onchangeEfectivo = (e) => {
    console.log(e.target.value);
    let total = 0;
    let value = e.target.value;

    if (value.startsWith("$")){
      value = value.replace("$", "");
      if(value.startsWith("0")){
        const newStr = value.slice(1)
        value = newStr;
      }
    }else{
      value = value.replace("$", "");
      if(value.startsWith("0")){
        const newStr = value.slice(1)
        value = newStr;
      }
    }

    //let value = Number(e.target.value.replace("$", ""));
    //const newStr = str.slice(1)

    //Eliminamos valores negativos
    if (value < 0) {
      value = value * -1;
    }

    //Obtenemos el total del cobro sumando el importa y el iva
    if (nuevoCobro.importe > 0) {
      total += Number(nuevoCobro.importe);
    }

    if (nuevoCobro.iva > 0) {
      total += Number(nuevoCobro.iva);
    }

    //Si lo que recibimos el mayor o igual que el total, le indicamos el cambio que se debe regresar
    let valorCadena = value;

    const valueSinSigno = parseInt(value);
    if (total <= valueSinSigno) {
      if(precioCubiertoGlobal==false){
        setNuevoCobro({
          ...nuevoCobro,
          recibido: valorCadena,
          cambio: (valueSinSigno - total).toFixed(2),
        });
        setPrecioCubiertoGlobal(true);
      }else{
        setPrecioCubiertoGlobal(true);
      }
    } else {
      setPrecioCubiertoGlobal(false);
      setNuevoCobro({
        ...nuevoCobro,
        recibido: value,
        cambio: 0,
      });
    }
  };

  const onchangeCantidadPagar = (e) => {
    let value = e.target.value;
    value = value.replace("$", "");

    if (Number(value) > totalDiferencia.diferencia) {
      setNuevoCobro({
        ...nuevoCobro,
        cantidadPagar: 0,
        iva: 0,
        importe: 0,
        recibido: null,
        cambio: null,
      });

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: `El restante es ${formatNumber(
          totalDiferencia.diferencia
        )}, la cantidad introducida no debe ser mayor`,
      });
    } else {
      setNuevoCobro({
        ...nuevoCobro,
        cantidadPagar: value,
        iva: (value * porcentajeIVAImporte.procentajeIVA) / 100,
        importe: (value * porcentajeIVAImporte.procentajeImporte) / 100,
        recibido: null,
        cambio: null,
      });
    }
  };

  const submitCobroVale = async () => {
    if (!nuevoCobro.idCatTipoCobro) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor ingrese la forma de pago",
      });
      return;
    }

    if (!nuevoCobro.cantidadPagar) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor ingrese la cantidad a pagar.",
      });
      return;
    }

    setLoading(true);

    const res = await registarCobroVentaMostrador(nuevoCobro);

    if (res.data.error) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_cancelar_vale: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);

      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_insertar_cobro: false,
        dynamic_description: "Se generó un nuevo cobro",
      });
    }
  };

  const imprimirNotaDeVenta = async () => {
    setLoading(true);
    notaVenta.idEstatusNuevo = 11;

    const res = await exportNotaDeVenta(notaVenta);

    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
    }

    setLoading(true);
    notaVenta.idEstatusNuevo = 12;
    const resCopia = await exportNotaDeVenta(notaVenta);

    if (resCopia.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: resCopia.data.message,
      });
    } else if (resCopia) {
      setLoading(false);
    }
  };

  const cancelarVentaMostradorConfirm = async () => {
    //Checamos que no haya sido previamente cancelada
    if (
      ventaMostradorSeleccionada.estatusventamostrador[ventaMostradorSeleccionada.estatusventamostrador.length - 1]
        .catEstatusventamostrador.idCatEstatusVentaMostrador == 7
    ) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "La venta ya ha sido cancelada",
      });
    } else {
      //Si la venta no se ha cancelado, les permitimos cancelar la venta

      setUISweetAlert({
        ...UISweetAlert,
        confirm_alert_cancelar_venta: true,
      });
    }
  };

  const onChangeCancelarVenta = async (e) => {
    setCancelarVenta({
      ...cancelarVenta,
      [e.target.name]: e.target.value,
    });
  };

  const changeEstatusCancelado = async () => {
    if (cancelarVenta.comentarioEstatus == "" || cancelarVenta.fechaCancelacion == "") {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor llene la información necesaria.",
      });
      return;
    }

    setUISweetAlert({
      ...UISweetAlert,
      confirm_alert_cancelar_vale: false,
    });

    setLoading(true);
    const res = await cancelarVentaMostrador({
      idEstatusNuevo: estatusCancelado.idCatEstatusVentaMostrador,
      idVentaMostrador: ventaMostradorSeleccionada.idVentaMostrador,
      comentarioEstatus: cancelarVenta.comentarioEstatus,
    });
    if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_cancelar_venta: false,
        dynamic_description: "Venta cancelada",
        redirect: true,
        path: "/ventasmostrador",
      });
    } else {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_cancelar_venta: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error al cancelar la venta, Inténtalo por favor más tarde.",
      });
    }
  };

  const cambiarEstatusCobrado = async () => {
    if (totalDiferencia.diferencia > 0) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_cambiar_estatus_cobrado: false,
        dynamic_description: "No se ha pagado toda la cantidad restante",
      });
      return;
    }

    setLoading(true);

    const res = await actualizarEstatusVentaMostrador({
      idVentaMostrador: ventaMostradorSeleccionada.idVentaMostrador,
      idEstatusNuevo: estatusCobrado.idCatEstatusVentaMostrador,
    });

    if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_alert_cambiar_estatus_cobrado: false,
        dynamic_description: "Se registraron los cambios del estatus.",
        redirect: true,
        path: "/ventasmostrador",
      });
    } else {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_alert_cambiar_estatus_cobrado: false,
        dynamic_title: "Fallo",
        dynamic_description: "Error al subir los cambios, Inténtalo por favor más tarde.",
      });
    }
  };

  useEffect(() => {
    listarCatTipoCobros();
    getEstatusCancelado();
    getEstatusCobrado();
    if (Object.keys(ventaMostradorSeleccionada).length === 0 && ventaMostradorSeleccionada.constructor === Object) {
      seleccionarVentaMostrador(idVentaMostrador, false);
    }

    if (ventaMostradorSeleccionada == false) {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_nueva_venta_apartado: false,
        confirm_nueva_venta_completa: false,
        dynamic_title: "Fallo",
        dynamic_description: "Venta mostrador no encontrado",
        redirect: true,
        path: "/ventasmostrador",
      });
      return;
    }

    if (Object.keys(ventaMostradorSeleccionada).length !== 0) {
      setNuevoCobro({
        idVentaMostrador: idVentaMostrador,
      });
      setHiddenCatCobroEfectivo(true);
      setHiddenBancoYFolio(true);
      calcularPorcentajes();
      calcularCantidadRestante();
    }

    if (Object.entries(notaVenta).length === 0 && Object.entries(ventaMostradorSeleccionada).length !== 0) {
      setNotaVenta({
        ...notaVenta,
        idVentaMostrador: ventaMostradorSeleccionada.idVentaMostrador,
        cobrosAnticipo: ventaMostradorSeleccionada.ventaMostradorcobro,
      });
    }
  }, [ventaMostradorSeleccionada]);

  const formatNumberOnBlur = (key) => {
    setNuevoCobro({
      ...nuevoCobro,
      [key]: formatNumber(nuevoCobro[key]),
    });
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Gestión de Cobro </h3>

      <Card className="mt-10">
        <CardBody>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Subtotal</label>
                <Col sm={7}>
                  <input
                    className="form-control"
                    disabled={true}
                    value={ventaMostradorSeleccionada.importeTotal ? formatNumber(ventaMostradorSeleccionada.importeTotal) : "$0"}
                    // value={ventaMostradorSeleccionada.importeTotal ? `$${(ventaMostradorSeleccionada.importeTotal).toFixed(2)}` : "$0"}
                    type="text"
                    style={{
                      textAlign: "right",
                    }}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">IVA</label>
                <Col sm={7}>
                  <input
                    className="form-control"
                    type="text"
                    value={ventaMostradorSeleccionada.ivaTotal ? formatNumber(ventaMostradorSeleccionada.ivaTotal) : "$0"}
                    // value={ventaMostradorSeleccionada.ivaTotal ? `$${ventaMostradorSeleccionada.ivaTotal.toFixed(2)}` : "$0"}
                    disabled={true}
                    style={{
                      textAlign: "right",
                    }}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Total</label>
                <Col sm={7}>
                  <input
                    className="form-control"
                    disabled={true}
                    value={
                      ventaMostradorSeleccionada.importeTotal
                        ? [
                            ventaMostradorSeleccionada.ivaTotal
                              ? formatNumber(ventaMostradorSeleccionada.importeTotal + ventaMostradorSeleccionada.ivaTotal)
                              : formatNumber(ventaMostradorSeleccionada.importeTotal),
                          ]
                        : "$0"
                    }
                    // value={
                    //   ventaMostradorSeleccionada.importeTotal
                    //     ? [
                    //         ventaMostradorSeleccionada.ivaTotal
                    //           ? `$${(ventaMostradorSeleccionada.importeTotal + ventaMostradorSeleccionada.ivaTotal).toFixed(2)}`
                    //           : `$${ventaMostradorSeleccionada.importeTotal.toFixed(2)}`,
                    //       ]
                    //     : "$0"
                    // }
                    type="text"
                    style={{
                      textAlign: "right",
                    }}
                  />
                </Col>
              </Row>
              {ventaMostradorSeleccionada.catTipoVentaMostrador &&
              ventaMostradorSeleccionada.catTipoVentaMostrador.idCatTipoVentaMostrador == 1 ? (
                <div>
                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Anticipo</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="text"
                        value={ventaMostradorSeleccionada.anticipo ? formatNumber(ventaMostradorSeleccionada.anticipo) : "$0"}
                        // value={ventaMostradorSeleccionada.anticipo ? `$${ventaMostradorSeleccionada.anticipo.toFixed(2)}` : "$0"}
                        disabled={true}
                        style={{
                          textAlign: "right",
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="form-group">
                    <label className="col-sm-4 col-form-label">Saldo Restante</label>
                    <Col sm={7}>
                      <input
                        className="form-control"
                        type="text"
                        value={
                          ventaMostradorSeleccionada.anticipo
                            ? formatNumber(
                                ventaMostradorSeleccionada.importeTotal +
                                  ventaMostradorSeleccionada.ivaTotal -
                                  ventaMostradorSeleccionada.anticipo
                              )
                            : "$0"
                        }
                        // value={
                        //   ventaMostradorSeleccionada.anticipo
                        //     ? `$${(ventaMostradorSeleccionada.importeTotal + ventaMostradorSeleccionada.ivaTotal - ventaMostradorSeleccionada.anticipo).toFixed(2)}`
                        //     : "$0"
                        // }
                        disabled={true}
                        style={{
                          textAlign: "right",
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
            </Col>

            <Col xl={6} lg={12} md={12}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Fecha</label>
                <Col sm={7}>
                  <input disabled value={new Date().toISOString().slice(0, 10)} className="form-control" type="date" />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mt-8">
        <CardBody>
          <Row>
            <Col>
              {!hiddenGenerarFactura ? (
                <button className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6">
                  Generar Factura
                </button>
              ) : null}
              {!hiddenInsertarCobro ? (
                <button
                  className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                  onClick={() => {
                    setUISweetAlert({
                      ...UISweetAlert,
                      confirm_alert_insertar_cobro: true,
                    });
                  }}>
                  Insertar Cobro
                </button>
              ) : null}
              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                onClick={imprimirNotaDeVenta}>
                Nota de Venta
              </button>           
              {hiddenInsertarCobro ? (
                <button
                  className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6"
                  onClick={() => {
                    setUISweetAlert({
                      ...UISweetAlert,
                      confirm_alert_cambiar_estatus_cobrado: true,
                    });
                  }}>
                  Cambio Estatus Cobrado
                </button>
              ) : null}

              <button
                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex mt-6 mr-6"
                type="button"
                // hidden={usuario.authorities.includes("OP_FARMACIA_VALE_CANCELAR") ? false : true}
                onClick={cancelarVentaMostradorConfirm}>
                Cancelar Venta
              </button>
            </Col>
          </Row>

          <div className="table-responsive mt-4">
            <table className="table table-hover table-centered table-nowrap mb-0">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    Num<br></br>Cobro
                  </th>
                  <th scope="col">
                    Forma de Pago
                  </th>
                  <th scope="col">Subtotal</th>
                  <th scope="col">Iva</th>
                  <th scope="col">Total</th>
                  <th scope="col">
                    Folio de Pago
                  </th>
                  <th scope="col">
                    Concepto<br></br>de Pago
                  </th>
                  <th scope="col">
                    Folio<br></br>Factura
                  </th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {ventaMostradorSeleccionada.ventaMostradorcobro &&
                  ventaMostradorSeleccionada.ventaMostradorcobro.map((cobros, index) => {
                    return (
                      <>
                        <tr key={cobros.idVentaMostradorCobro}>
                          <td>
                            {
                              <input
                                type="checkbox"
                                onClick={() => handdleChecked(cobros.idVentaMostradorCobro)}
                                checked={cobros.checked}
                                value={cobros.idVentaMostradorCobro}
                              />
                            }
                          </td>
                          <td>{cobros.idVentaMostradorCobro}</td>
                          <td>{cobros.catTipoCobro.tipoCobro}</td>
                          <td
                            style={{
                              textAlign: "right",
                            }}>
                            {formatNumber(cobros.importe)}
                            {/* ${cobros.importe.toFixed(2)} */}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}>
                            {formatNumber(cobros.iva)}
                            {/* ${cobros.iva.toFixed(2)} */}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                            }}>
                            {formatNumber(cobros.importe + cobros.iva)}
                            {/* ${(cobros.importe + cobros.iva).toFixed(2)} */}
                          </td>
                          <td>{cobros.folioDeposito ? cobros.folioDeposito : ""}</td>
                          <td>{cobros.conceptoPago ? cobros.conceptoPago : ""}</td>
                          <td>{/* {cobros.factura.folioFactura} */}</td>
                          <td>{cobros.anticipo ? "Anticipo" : "Saldo Restante"}</td>
                          <td>
                            <div hidden={cobros.anticipo}>
                              <button
                                type="button"
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                onClick={() => {
                                  setUISweetAlert({
                                    ...UISweetAlert,
                                    confirm_alert_eliminar_cobro: true,
                                    idCobroDelete: cobros.idVentaMostradorCobro,
                                  });
                                }}>
                                Eliminar
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>

      <Card className="mt-8">
        <CardBody>
          <Row>
            <Col md="12">
              <table
                className="w-2/6"
                style={{
                  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  backgroundColor: "inherit",
                  margin: "0",
                  float: "right",
                }}
                bgcolor="#f6f6f6">
                <tbody>
                  <tr
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                        textAlign: "left",
                      }}
                      valign="top">
                      Saldo a pagar
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "1px",
                        borderTopColor: "#eee",
                        borderTopStyle: "solid",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(totalDiferencia.total)}
                      {/* ${totalDiferencia.total.toFixed(2)} */}
                    </td>
                  </tr>
                  <tr
                    className="total"
                    style={{
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      boxSizing: "border-box",
                      fontSize: "14px",
                      margin: "0",
                    }}>
                    <td
                      width="80%"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "left",
                        borderTopWidth: "5px",
                        borderTopColor: "#333",
                        borderTopStyle: "double",
                        borderBottomColor: "#333",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        fontWeight: "700",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="left"
                      valign="top">
                      Diferencia
                    </td>
                    <td
                      className="alignright"
                      style={{
                        fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                        boxSizing: "border-box",
                        fontSize: "14px",
                        verticalAlign: "top",
                        textAlign: "right",
                        borderTopWidth: "5px",
                        borderTopColor: "#333",
                        borderTopStyle: "double",
                        borderBottomColor: "#333",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        fontWeight: "700",
                        margin: "0",
                        padding: "5px 0",
                      }}
                      align="right"
                      valign="top">
                      {formatNumber(totalDiferencia.diferencia)}
                      {/* ${totalDiferencia.diferencia} */}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {UISweetAlert.confirm_alert_cancelar_venta ? (
        <SweetAlert
          title="¿Desea cancelar la venta?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          onConfirm={() => changeEstatusCancelado()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_cancelar_venta: false,
            })
          }>
          <div>
            <Row>
              <Col>Llene por favor la siguiente información</Col>
            </Row>

            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Motivo</label>
              <Col sm={7}>
                <textarea
                  rows={6}
                  className="form-control"
                  name="comentarioEstatus"
                  value={cancelarVenta.comentarioEstatus}
                  onChange={onChangeCancelarVenta}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Fecha Cancelación</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  name="fechaCancelacion"
                  value={fechaActual}
                />
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_insertar_cobro ? (
        <SweetAlert
          title="Nuevo cobro"
          showCancel
          confirmBtnText="Cobrar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "60%",
          }}
          onConfirm={() => submitCobroVale()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_insertar_cobro: false,
            });
            setNuevoCobro({
              idVentaMostrador: idVentaMostrador,
            });
          }}>
          <div>
            <Row className="form-group mt-2">
              <Col sm={4}></Col>
              <Col sm={7}>
                <button
                  className="btn btn-primary-blue-umo w-md waves-effect waves-light mt-2 mb-2 flex hover:text-white"
                  type="button"
                  onClick={pagarDiferencia}>
                  Pagar la diferencia
                </button>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">
                Forma de Pago<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idCatTipoCobro"
                  onChange={onChangeTipoCobro}
                  value={nuevoCobro.idCatTipoCobro}>
                  <option selected value="">
                    Seleccione una opción
                  </option>
                  {tipoCobros.map((cobro) => {
                    return (
                      <>
                        <option value={cobro.idCatTipoCobro}>{cobro.tipoCobro}</option>
                      </>
                    );
                  })}
                </select>
                {(nuevoCobro.idCatTipoCobro === "" || !nuevoCobro.idCatTipoCobro) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
            </Row>

            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Restante por pagar</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={totalDiferencia.diferencia ? formatNumber(totalDiferencia.diferencia) : `$0`}
                  //   value={totalDiferencia.diferencia ? `$${totalDiferencia.diferencia}` : `$0`}
                  style={{
                    textAlign: "right",
                  }}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <label className="col-sm-4 col-form-label">
                Cantidad a pagar<span className="text-xs text-red-700">*</span>
              </label>
              <Col sm={7}>
                <input
                  type="text"
                  autoFocus
                  className="form-control"
                  onBlur={() => formatNumberOnBlur("cantidadPagar")}
                  // value={nuevoCobro.cantidadPagar ? nuevoCobro.cantidadPagar : `$`}
                  value={nuevoCobro.cantidadPagar ? nuevoCobro.cantidadPagar : `$`}
                  onChange={onchangeCantidadPagar}
                  style={{
                    textAlign: "right",
                  }}
                />
                {(nuevoCobro.cantidadPagar === "" || !nuevoCobro.cantidadPagar) && (
                  <span className="text-sm text-red-700">Este campo es obligatorio</span>
                )}
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-4 col-form-label">Importe</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  value={nuevoCobro.importe ? formatNumber(nuevoCobro.importe) : `$0`}
                  //   value={nuevoCobro.importe ? `$${nuevoCobro.importe.toFixed(2)}` : `$0`}
                  disabled={true}
                  style={{
                    textAlign: "right",
                  }}
                />
              </Col>
            </Row>

            <Row className="form-group">
              <label className="col-sm-4 col-form-label">IVA</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  value={nuevoCobro.iva ? formatNumber(nuevoCobro.iva) : `$0`}
                  //   value={nuevoCobro.iva ? `$${nuevoCobro.iva.toFixed(2)}` : `$0`}
                  disabled={true}
                  style={{
                    textAlign: "right",
                  }}
                />
              </Col>
            </Row>

            {!hiddenCatCobroEfectivo ? (
              <div>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Recibido</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      onChange={onchangeEfectivo}
                      // value={nuevoCobro.recibido}
                      onBlur={() => formatNumberOnBlur("recibido")}
                      value={nuevoCobro.recibido ? `${nuevoCobro.recibido}` : `$0`}
                      disabled={nuevoCobro.cantidadPagar ? false : true}
                      style={{ textAlign: "right" }}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Cambio</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      value={nuevoCobro.cambio ? formatNumber(nuevoCobro.cambio) : `$0`}
                      // value={nuevoCobro.cambio ? `$${nuevoCobro.cambio}` : `$0`}
                      disabled={true}
                      style={{ textAlign: "right" }}
                    />
                  </Col>
                </Row>
              </div>
            ) : null}
            {!hiddenConceptoDePago ? (
              <Row className="form-group">
                <label className="col-sm-4 col-form-label">Concepto de Pago</label>
                <Col sm={7}>
                  <input
                    type="text"
                    className="form-control"
                    value={nuevoCobro.conceptoDePago}
                    name="conceptoDePago"
                    onChange={onchange}
                  />
                </Col>
              </Row>
            ) : null}
            {!hiddenBancoYFolio ? (
              <div>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Número de Referencia</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      value={nuevoCobro.folioDeposito}
                      name="folioDeposito"
                      onChange={onchange}
                    />
                  </Col>
                </Row>

                <Row className="form-group">
                  <label className="col-sm-4 col-form-label">Banco</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      value={nuevoCobro.bancoDeposito}
                      name="bancoDeposito"
                      onChange={onchange}
                    />
                  </Col>
                </Row>
              </div>
            ) : null}
            {/* {
                        !hiddenConfirmarContabilidad ? (
                            <div>
                                <Row className="form-group">
                                    <Col sm={4}>
                                    </Col>
                                    <Col sm={7}>
                                    <button 
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light flex"
                                    >
                                        Solicitar Confirmación
                                    </button>
                                    </Col>
                                </Row>
                                
                            </div>
                        ): null
                    } */}
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_eliminar_cobro ? (
        <SweetAlert
          title="¿Desea eliminar el cobro?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => eliminarCobro()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_eliminar_cobro: false,
            })
          }>
          Se perderá la información
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_alert_cambiar_estatus_cobrado ? (
        <SweetAlert
          title="¿Cambiar el estatus a cobrado?"
          warning
          showCancel
          confirmBtnText="Continuar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          onConfirm={() => cambiarEstatusCobrado()}
          onCancel={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_alert_cambiar_estatus_cobrado: false,
            })
          }></SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });

            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
}
