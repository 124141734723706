import React, {useReducer} from 'react'

import TipoValeContext from './TipoValeContext'
import TipoValeReducer from './TipoValeReducer'

import {
    LISTAR_TIPOS_VALES
} from '../../types'

import clienteAxios from '../../config/axios'


export default function TipoValeState (props) {

    const initialState = { 
        tiposVale:[]
    }

    const [state, dispatch] = useReducer(TipoValeReducer,initialState)

    const listarTiposVale = async () => {

        const res = await clienteAxios.get("/tipovales/activos", {
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
        })

        dispatch({
            type: LISTAR_TIPOS_VALES,
            payload: res.data
        })
        
    }


    return (

        <TipoValeContext.Provider value={{
            tiposVale: state.tiposVale,
            listarTiposVale
        }}>
            {props.children}
        </TipoValeContext.Provider>

    )


}