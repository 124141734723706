import React, {useEffect, useContext, useState} from "react";
import UMOlogo from "../../assets/images/logo-umo-dashboard.png";
import {GiChemicalTank} from "react-icons/gi";

import {Link} from "react-router-dom";

import MetisMenu from "metismenujs"; // COLLAPSIBLE LIBRARY
// import SimpleBar from "simplebar-react"; // SCROLLABLE
import AuthContext from "../../context/Auth/AuthContext";

export default function Sidebar() {
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    const [startMenu, setStartMenu] = useState(false);

    useEffect(() => {
        if (usuario != null && !startMenu) {
            setStartMenu(true);
            new MetisMenu("#side-menu");
        }
    }, [usuario]);

    return (
        <>
            <div className="vertical-menu">
                <div id="sidebar-menu">
                    <Link to="/">
                        <div className="navbar-brand-box flex justify-center pb-10">
                            <img alt="Logo" className="w-1/2" src={UMOlogo}/>
                        </div>
                    </Link>
                    <ul className="metismenu list-unstyled flex flex-col" style={{paddingTop: "20px"}} id="side-menu">
                        {/* Calendario */}
                        {usuario &&
                        (usuario.authorities.includes("OP_CONSULTA_CALENDARIO") ||
                            usuario.authorities.includes("ROLE_CONTABILIDAD") ||
                            usuario.authorities.includes("ROLE_FARMACIA") ||
                            usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                            usuario.authorities.includes("ROLE_MEDICO") ||
                            usuario.authorities.includes("ROLE_AGENDA") ||
                            usuario.authorities.includes("ROLE_ASISTENTE") ||
                            usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                            usuario.authorities.includes("ROLE_COMPRAS")) ? (
                            <li>
                                <Link to="/agenda" className="waves-effect font-extrabold">
                                    <i className="ti-home"></i>
                                    <span>Agenda</span>
                                </Link>
                            </li>
                        ) : null}

                        {/* Farmacia */}
                        {usuario && (usuario.authorities.includes("OP_FARMACIA_VALE_LISTA") || usuario.authorities.includes("ROLE_MEDICO")) ? (
                            <li>
                                <Link to={"/vales"} className="has-arrow waves-effect font-extrabold">
                                    <i className="ti-support"></i>
                                    <span>Farmacia</span>
                                </Link>
                                <ul className="sub-menu">
                                    {usuario && usuario.authorities.includes("OP_FARMACIA_VALE_CREAR") ? (
                                        <li>
                                            <Link to="/vales/nuevo">
                                                <span>Nuevo Vale</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                    {usuario && usuario.authorities.includes("OP_FARMACIA_VALE_LISTA") ? (
                                        <li>
                                            <Link to="/vales">
                                                <span>Vales</span>
                                            </Link>
                                        </li>
                                    ) : null}

                                    {usuario && usuario.authorities.includes("ROLE_MEDICO") ? (
                                        <li>
                                            <Link to="/vales/medico">
                                                <span>Mis Vales</span>
                                            </Link>
                                        </li>
                                    ) : null}

                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_FARMACIA") ||
                                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <>
                                            <li>
                                                <Link to="/recepcion/generica/producto">
                                                    <span>Recepción de Productos</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_FARMACIA") ||
                                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_RECEPCION") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <>
                                            <li>
                                                <Link to="/pagos/medico">
                                                    <span>Pagos Médico</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_FARMACIA") ||
                                        usuario.authorities.includes("ROLE_RECEPCION") ||
                                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <>
                                            <li>
                                                <Link to="/cortescaja">
                                                    <span>Corte de Caja</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("OP_FARMACIA_VALE_CREAR")) ? (
                                        <>
                                            <li>
                                                <Link to="/autorizaciones">
                                                    <span>Autorizaciones</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}                                    
                                </ul>
                            </li>
                        ) : null}

                        {/* Centro mezclas */}
                        {usuario &&
                        (usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
                            usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                            usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                            <li>
                                <Link to={"/centromezclas/inventario"} className="has-arrow waves-effect font-extrabold">
                                    <i>
                                        <GiChemicalTank/>
                                    </i>
                                    <span>Centro de Mezclas</span>
                                </Link>
                                <ul className="sub-menu">
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
                                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <>
                                            <li>
                                                <Link to="/centromezclas/inventario">
                                                    <span>Inventario</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/centromezclas/bitacoras">
                                                    <span>Bitácora de Mezclas</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/centromezclas/reacciones">
                                                    <span>Reacciones Adversas</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}
                                </ul>
                            </li>
                        ) : null}

                        {/* Venta Mostrador */}
                        {usuario &&
                        (usuario.authorities.includes("OP_MOSTRADOR_VENTA_CREAR") || usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                            <li>
                                <Link to={"/ventasmostrador"} className="has-arrow waves-effect font-extrabold">
                                    <i className="ti-desktop"></i>
                                    <span>Mostrador</span>
                                </Link>
                                <ul className="sub-menu">
                                    {usuario && !usuario.authorities.includes("ROLE_CONTABILIDAD") ? (
                                        <li>
                                            <Link to="/ventasmostrador/nuevo">
                                                <span>Nueva Venta Mostrador</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                    <li>
                                        <Link to="/ventasmostrador">
                                            <span>Ventas</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        ) : null}

                        {/* Compras */}
                        {usuario &&
                        (usuario.authorities.includes("ROLE_COMPRAS") ||
                            usuario.authorities.includes("ROLE_DIRECCION") ||
                            usuario.authorities.includes("ROLE_CONTABILIDAD") ||
                            usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                            usuario.authorities.includes("ROLE_FARMACIA") ||
                            usuario.authorities.includes("ROL_AUXILIAR_ADMINISTRACION") ||
                            usuario.authorities.includes("ROLE_ADMIN_SISTEMA")) ? (
                            <li>
                                <Link to="/ordenescompra" className="has-arrow waves-effect font-extrabold">
                                    <i className="ti-credit-card"></i>
                                    <span>Compras</span>
                                </Link>
                                <ul className="sub-menu">
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_COMPRAS") ||
                                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <>
                                            <li>
                                                <Link to="/requerimientos/pendientes">
                                                    <span>Requerimientos Pendientes</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("OP_COMPRAS_SOLICITUD_COMPRA_CREAR") ||
                                        usuario.authorities.includes("ROLE_FARMACIA") ||
                                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA")) ? (
                                        <li>
                                            <Link to="/solicitudescompra/nuevo">
                                                <span>Nueva Solicitud de Compra</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_COMPRAS") ||
                                        usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_FARMACIA") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <li>
                                            <Link to="/solicitudescompra">
                                                <span>Solicitudes de Compra</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_COMPRAS") ||
                                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                                        usuario.authorities.includes("ROL_AUXILIAR_ADMINISTRACION") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <>
                                            <li>
                                                <Link to="/ordenescompra">
                                                    <span>Ordenes de Compra</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_COMPRAS") ||
                                        usuario.authorities.includes("ROLE_DIRECCION") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD") ||
                                        usuario.authorities.includes("ROLE_FARMACIA") ||
                                        usuario.authorities.includes("ROLE_ADMIN_SISTEMA")) ? (
                                        <>
                                            <li>
                                                <Link to="/comprasproducto">
                                                    <span>Consulta de Compras por Producto</span>
                                                </Link>
                                            </li>
                                            <li>
                                                {!(usuario.authorities.includes("ROLE_FARMACIA"))?(
                                                <Link to="/desplazamientoproducto">
                                                    <span>Desplazamiento de Productos</span>
                                                </Link>
                                                ):null}
                                            </li>
                                        </>
                                    ) : null}
                                </ul>
                            </li>
                        ) : null}

                        {/* Administracion */}
                        {usuario &&
                        (usuario.authorities.includes("OP_ADM_USR_CREAR") ||
                            usuario.authorities.includes("OP_ADM_USR_BORRAR") ||
                            usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                            usuario.authorities.includes("OP_ADM_USR_EDITAR")) ? (
                            <li>
                                <Link to="/usuarios" className="has-arrow waves-effect font-extrabold">
                                    <i className="ti-settings"></i>

                                    <span>Administración</span>
                                </Link>
                                <ul className="sub-menu">
                                    {usuario &&
                                    (usuario.authorities.includes("OP_ADM_USR_CREAR") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA")) ? (
                                        <li>
                                            <Link to="/usuarios">
                                                <span>Usuarios</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                        ) : null}

                        {/* Catalogos */}
                        {usuario &&
                        (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                            usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                            usuario.authorities.includes("ROLE_DIRECCION") ||
                            usuario.authorities.includes("ROLE_COMPRAS") ||
                            usuario.authorities.includes("ROLE_MEDICO") ||
                            usuario.authorities.includes("ROLE_EXP_MEDICO") ||
                            usuario.authorities.includes("ROLE_FARMACIA") ||
                            usuario.authorities.includes("ROLE_RECEPCION") ||
                            usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                            usuario.authorities.includes("ROLE_QUIMIO") ||
                            usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
                            usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                            <li>
                                <Link to="/pacientes" className="has-arrow waves-effect font-extrabold">
                                    <i className="ti-book"></i>
                                    <span>Catálogos</span>
                                </Link>
                                <ul className="sub-menu">
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                                        usuario.authorities.includes("ROLE_DIRECCION") ||
                                        usuario.authorities.includes("ROLE_MEDICO") ||
                                        usuario.authorities.includes("ROLE_EXP_MEDICO") ||
                                        usuario.authorities.includes("ROLE_QUIMIO") ||
                                        usuario.authorities.includes("ROLE_RECEPCION") ||
                                        usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                                        usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <li>
                                            <Link to="/pacientes">
                                                <span>Pacientes</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                                        usuario.authorities.includes("ROLE_DIRECCION") ||
                                        usuario.authorities.includes("ROLE_COMPRAS") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <>
                                            <li>
                                                <Link to="/proveedores">
                                                    <span>Proveedores</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/fabricantes">
                                                    <span>Fabricantes</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                                        usuario.authorities.includes("ROLE_DIRECCION") ||
                                        usuario.authorities.includes("ROLE_FARMACIA") ||
                                        usuario.authorities.includes("ROLE_RECEPCION") ||
                                        usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <li>
                                            <Link to="/clientes">
                                                <span>Clientes</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                                        usuario.authorities.includes("ROLE_DIRECCION") ||
                                        usuario.authorities.includes("ROLE_QUIMIO") ||
                                        usuario.authorities.includes("ROLE_RECEPCION") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <li>
                                            <Link to="/medicos">
                                                <span>Médicos</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                                        usuario.authorities.includes("ROLE_DIRECCION") ||
                                        usuario.authorities.includes("ROLE_RECEPCION") ||
                                        usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <li>
                                            <Link to="/aseguradoras">
                                                <span>Aseguradoras</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                                        usuario.authorities.includes("ROLE_DIRECCION") ||
                                        usuario.authorities.includes("ROLE_FARMACIA") ||
                                        usuario.authorities.includes("ROLE_COMPRAS") ||
                                        usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
                                        usuario.authorities.includes("ROLE_QUIMIO") ||
                                        usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <>
                                            <li>
                                                <Link to="/medicamentos">
                                                    <span>Medicamentos</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/insumos">
                                                    <span>Insumos</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/soluciones">
                                                    <span>Soluciones</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/servicios">
                                                    <span>Servicios</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
                                        usuario.authorities.includes("ROLE_FARMACIA") ||
                                        usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <li>
                                            <Link to="/medicamentoscompraexterna">
                                                <span>Medicamentos Compra Externa </span>
                                            </Link>
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                        ) : null}

                        {/* Consulta Medica */}
                        {usuario &&
                        (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                            usuario.authorities.includes("ROLE_DIRECCION") ||
                            usuario.authorities.includes("ROLE_RECEPCION") ||
                            usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                            <li>
                                <Link to="/espacios" className="has-arrow waves-effect font-extrabold">
                                    <i className="ti-calendar"></i>
                                    <span>Consulta Médica</span>
                                </Link>
                                <ul className="sub-menu">
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_ADMINISTRACION") ||
                                        usuario.authorities.includes("ROLE_DIRECCION") ||
                                        usuario.authorities.includes("ROLE_RECEPCION") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <li>
                                            <Link to="/espacios">
                                                <span>Espacio de Atención</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                    {/* {usuario && (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") || usuario.authorities.includes("ROLE_EXP_MEDICO") || usuario.authorities.includes("ROLE_QUIMIO")) ? (
                    <li>
                      <Link to="/consentimiento">
                        <span>Consentimiento Cliente</span>
                      </Link>
                    </li>
                  ) : null} */}
                                </ul>
                            </li>
                        ) : null}

                        {/* Almacen */}
                        {usuario &&
                        (usuario.authorities.includes("OP_ALMACEN_CREAR") ||
                            usuario.authorities.includes("OP_ALMACEN_EDITAR") ||
                            usuario.authorities.includes("OP_ALMACEN_BORRAR") ||
                            usuario.authorities.includes("ROLE_COMPRAS") ||
                            usuario.authorities.includes("ROLE_FARMACIA") ||
                            usuario.authorities.includes("OP_ALMACEN_APROBAR_BAJA_INVENTARIO") ||
                            (usuario.authorities.includes("ROLE_CONTABILIDAD") && !usuario.authorities.includes("ROLE_ADMINISTRACION"))) ? (
                            <li>
                                <Link to="/almacenes" className="has-arrow waves-effect font-extrabold">
                                    <i className="ti-truck"></i>
                                    <span>Almacén</span>
                                </Link>
                                <ul className="sub-menu">
                                    {(usuario && usuario.authorities.includes("OP_ALMACEN_CREAR")) ||
                                    usuario.authorities.includes("ROLE_CONTABILIDAD") ? (
                                        <>
                                            <li>
                                                <Link to="/almacenes">
                                                    <span>Almacenes</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_ALMACEN") ||
                                        usuario.authorities.includes("ROLE_FARMACIA") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD") ||
                                        usuario.authorities.includes("ROLE_COMPRAS")) ? (
                                        <>
                                            <li>
                                                <Link to="/productos/inventario">
                                                    <span>Productos Inventario</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}

                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                        usuario.authorities.includes("ROLE_ALMACEN") ||
                                        usuario.authorities.includes("ROLE_CONTABILIDAD")) ? (
                                        <>
                                            <li>
                                                <Link to="/productos/ubicacion">
                                                    <span>Productos Por Ubicación</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}
                                    {( usuario && usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                    usuario.authorities.includes("ROLE_CONTABILIDAD") ||
                                    usuario.authorities.includes("ROLE_ADMIN_ALMACEN"))? (
                                        <>
                                            <li>
                                                <Link to="/traslados">
                                                    <span>Traslados</span>
                                                </Link>
                                            </li>
                                        </>
                                    ) : null}

                                    {(usuario && usuario.authorities.includes("OP_ALMACEN_APROBAR_BAJA_INVENTARIO") ||
                                    usuario.authorities.includes("ROLE_CONTABILIDAD") ||
                                    usuario.authorities.includes("ROLE_ADMIN_ALMACEN"))? (
                                        <li>
                                            <Link to="/bajainventarios">
                                                <span>Bajas Inventario</span>
                                            </Link>
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                        ) : null}

                        {/* Contabilidad */}
                        {usuario &&
                        (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                            usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                            usuario.authorities.includes("ROLE_CONTABILIDAD") ||
                            usuario.authorities.includes("ROLE_FARMACIA")) ? (
                            <li>
                                <Link to="/documentos/contables" className="has-arrow waves-effect font-extrabold">
                                    <i className="ti-write"></i>
                                    <span>Contabilidad</span>
                                </Link>
                                <ul className="sub-menu">
                                    {usuario &&
                                    (usuario.authorities.includes("ROLE_FARMACIA") || usuario.authorities.includes("ROLE_ASEGURADORAS")) &&
                                    !usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ? (
                                        <>
                                            <li>
                                                <Link to="/documentos/contables">
                                                    <span>Documentos Contables</span>
                                                </Link>
                                            </li>
                                            {/* <li>
                        <Link to="/contabilidad/reporte/ventas">
                          <span>Roporte Ventas</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contabilidad/reporte/utilidades">
                          <span>Roporte Utilidades</span>
                        </Link>
                      </li> */}
                                        </>
                                    ) : (
                                        <>
                                            <li>
                                                <Link to="/documentos/contables">
                                                    <span>Documentos Contables</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/contabilidad/reporte/aseguradora">
                                                    <span>Reporte Saldos Aseguradora</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/contabilidad/reporte/ventas">
                                                    <span>Roporte Ventas</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/contabilidad/reporte/utilidades">
                                                    <span>Roporte Utilidades</span>
                                                </Link>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </li>
                        ) : null}

                        {/* Informes */}
                        {usuario &&
                        (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                            usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                            usuario.authorities.includes("ROLE_COMPRAS") ||
                            usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
                            usuario.authorities.includes("ROLE_CONTABILIDAD") ||
                            usuario.authorities.includes("ROLE_FARMACIA")) ? (
                            <li>
                                <Link to="/estadisticas" className="has-arrow waves-effect font-extrabold">
                                    <i className="ti-write"></i>
                                    <span>Informes</span>
                                </Link>
                                <ul className="sub-menu">
                                        <>
                                            {(usuario && (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") || usuario.authorities.includes("ROLE_ASEGURADORAS")))? (
                                                <li>
                                                    <Link to="/estadisticas/estadisticaseseguradora">
                                                        <span>Aseguradoras</span>
                                                    </Link>
                                                </li>
                                            ) : null}

                                            {(usuario && (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") || usuario.authorities.includes("ROLE_COMPRAS")))? (
                                                <li>
                                                    <Link to="/estadisticas/informecompras">
                                                        <span>Compras</span>
                                                    </Link>
                                                </li> 
                                            ) : null}   

                                            {(usuario && (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") || usuario.authorities.includes("ROLE_CENTRO_MEZCLAS")))? (
                                                <li>
                                                    <Link to="/estadisticas/informemezclas">
                                                        <span>Centro de Mezclas</span>
                                                    </Link>
                                                </li>   
                                            ) : null}                                               

                                            {(usuario && (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") || usuario.authorities.includes("ROLE_FARMACIA")))? (
                                                <li>
                                                    <Link to="/estadisticas/informefarmacia">
                                                        <span>Farmacia</span>
                                                    </Link>
                                                </li>  
                                            ) : null}                                            
                                        </>
                                </ul>
                            </li>
                        ) : null}                        
                    </ul>
                </div>
            </div>
        </>
    );
}