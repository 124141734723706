import React, { useReducer } from "react";

import CatPeriodicidadContext from "./CatPeriodicidadContext";
import CatPeriodicidadReducer from "./CatPeriodicidadReducer";

import { LISTAR_CATPERIODICIDAD } from "../../types";

import clienteAxios from "../../config/axios";

export default function CatPeriodicidadState(props) {
  const initialState = {
    catPeriodicidad: [],
  };

  const [state, dispatch] = useReducer(CatPeriodicidadReducer, initialState);

  const listarCatPeriodicidad = async () => {
    const res = await clienteAxios.get("/catperiodicidad/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_CATPERIODICIDAD,
      payload: res.data,
    });
    return res;
  };

  return (
    <CatPeriodicidadContext.Provider
      value={{
        catPeriodicidad: state.catPeriodicidad,
        listarCatPeriodicidad,
      }}>
      {props.children}
    </CatPeriodicidadContext.Provider>
  );
}
