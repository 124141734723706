import React, { useReducer } from "react";

import OcupacionesContext from "./OcupacionesContext";
import OcupacionesReducer from "./OcupacionesReducer";

import { LISTAR_OCUPACIONES } from "../../types";

import clienteAxios from "../../config/axios";

export default function OcupacionesState(props) {
  const initialState = {
    ocupaciones: [],
  };

  const [state, dispatch] = useReducer(OcupacionesReducer, initialState);

  const listarOcupaciones = async () => {
    const res = await clienteAxios.get("/ocupaciones/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_OCUPACIONES,
      payload: res.data,
    });
  };

  return (
    <OcupacionesContext.Provider
      value={{
        ocupaciones: state.ocupaciones,
        listarOcupaciones,
      }}>
      {props.children}
    </OcupacionesContext.Provider>
  );
}
