/* eslint-disable import/no-anonymous-default-export */
import { FILTRO_NUEVA_FACTURA, SET_VALES_PAGINADOS } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case FILTRO_NUEVA_FACTURA:
      return {
        ...state,
        filtrosNuevaFactura: action.payload,
      };
    case SET_VALES_PAGINADOS:
      return {
        ...state,
        ventasPaginadas: action.payload,
      };
    default:
      return state;
  }
};
