import React, {forwardRef} from 'react';
import formatNumber from "../../utils";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import {Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {barChartOptions} from "../../config/chartOptions";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const BarChart = forwardRef((props, ref) => {
    const shouldDisplayDataLabels = window.innerWidth > 768; // Adjust the breakpoint as needed
    const mostrarTitulosglobal = props.mostrarTitulos=="undefined"? true : props.mostrarTitulos ; // Adjust the breakpoint as needed
    
    const updatedOptions = {
        ...barChartOptions,
        layout: {
            padding: {
              left: 5,
              right: 5,
              top: 10,
              bottom: 5,
            },
            margin: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5
              },
          },
        scales: {
            y: {
              beginAtZero: true,
              border: { dash: [6, 6], display: true },
              grid: {
                display: true // Display grid lines for the y-axis
              },
              ticks: {
                padding: 15
              }
            },
            x: {
              beginAtZero: true,
              border: { display: true },
              grid: {
                display: true // No display of grid lines for the x-axis
              },
              ticks: {
                padding: 7
              }
            }
          },
          elements: {
            bar: {
              borderRadius: 40,
              borderWidth: 0.7,
            },
          },
        indexAxis: props.horizontal?'y':'x',
        plugins: {
            ...barChartOptions.plugins,
            legend: {
                display: mostrarTitulosglobal,
                position: "bottom",

            },
            title: {
                ...barChartOptions.plugins.title,
                text: props.title,
                padding: {
                    bottom: 50,
                }
            },
            datalabels: {
                anchor: 'end',                
                display: shouldDisplayDataLabels,
                align: 'top',
                formatter: (value) => value.y !== null ? (props.requiresMoneyFormat ? formatNumber(value.y) : value.y) : null,
                color: 'black',
                font: {
                    size: shouldDisplayDataLabels ? 14 : 10,
                    weight: 'bold'
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return props.requiresMoneyFormat ? formatNumber(tooltipItem.raw.y) : tooltipItem.raw.y
                    }
                }
            }
        },
        scales: {
            y: {
                stacked: props.stacked?true:false,
                ticks: {
                    callback: function (val, index) {
                        return props.requiresMoneyFormat ? formatNumber(val) : val;
                    },
                },
                x: {
                    stacked: props.stacked?true:false,
                },
            },
        },

    };

    return (
        <Bar ref={ref}  options={updatedOptions} data={props.data} width={300} height={500} />
    )
})

export default BarChart;