import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import { useParams } from "react-router-dom";

import InformacionGeneralOrdenCompra from "../InformacionGeneralOrdenCompra/index";
import ListadoProductosOrdenCompra from "../ListadoProductosOrdenCompra/index";
import OrdenesCompraContext from "../../context/OrdenesCompra/OrdenesCompraContext";

export default function RecibidaOrdenCompra(props) {
  const { idOrdenCompra } = useParams();

  // eslint-disable-next-line
  const [UISweetAlert, setUISweetAlert] = useState({
    error_dlg: false,
  });

  // ACCESO A ESTADO GLOBAL - VALES
  const ctxOrdenCompra = useContext(OrdenesCompraContext);
  const {
    seleccionarOrdenCompra,
    ordenCompraShowEdit,
    setOrdenCompraShowEdit,
    errorOrdenCompraSeleccionada,
    ordenCompraSeleccionada,
  } = ctxOrdenCompra;

  useEffect(() => {
    if (!errorOrdenCompraSeleccionada && Object.entries(ordenCompraSeleccionada).length === 0) {
      seleccionarOrdenCompra(idOrdenCompra);
      setOrdenCompraShowEdit({
        ...ordenCompraShowEdit,
        hiddenRecepcion: false,
        disabledRecepcion: true,
        guardarOrdenCompra: true,
        guardarRecepcionOrdenCompra: false,
        aprobarPagoOrdenCompra: true,
        hiddenArchivos: false,
        disabledArchivos: false,
        hiddenRecibida: false,
      });
    }

    // if(errorOrdenCompraSeleccionada){
    //     props.history.push("/ordenescompra")
    // }
    // eslint-disable-next-line
  }, [errorOrdenCompraSeleccionada, ordenCompraSeleccionada]);

  return (
    <div className="mt-4">
      <InformacionGeneralOrdenCompra props={props} />
      <ListadoProductosOrdenCompra props={props} />

      {UISweetAlert.error_dlg ? (
        <SweetAlert error title="Fallo" onConfirm={() => props.history.push("/ordenescompra")}>
          No se puede registar la recepción de la orden de compra
        </SweetAlert>
      ) : null}
    </div>
  );
}
