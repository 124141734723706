import React, { useReducer } from "react";
import { LISTAR_PRODUCTOSCOMPRAEXTERNA, SET_PRODUCTOSCOMPRAEXTERNA_PAGINADOS, FILTRO_PRODUCTOSCOMPRAEXTERNA } from "../../types";

import clienteAxios from "../../config/axios";
import ProductosCompraExternaReducer from "./ProductosCompraExternaReducer";
import ProductosCompraExternaContext from "./ProductosCompraExternaContext";

export default function ProductosCompraExternaState(props) {
  const initialState = {
    productosCompraExterna: [],
    productosCompraExternaPaginados: [],
    filtrosProductosCompraExterna: {},
  };

  const [state, dispatch] = useReducer(ProductosCompraExternaReducer, initialState);

  const listarProductosCompraExterna = async () => {
    const res = await clienteAxios.get(`/medicamentocompraexterna/activos`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_PRODUCTOSCOMPRAEXTERNA,
      payload: res.data,
    });
  };

  const setDataProductosCompraExterna = async ({ page, limit, idProductoCompraExterna }) => {
    try {
      const response = await clienteAxios.post(
        "/medicamentocompraexterna/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          idProductoCompraExterna: idProductoCompraExterna,
          ...state.filtrosProductosCompraExterna,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      dispatch({
        type: SET_PRODUCTOSCOMPRAEXTERNA_PAGINADOS,
        payload: response.data.productoCompraExternaVos,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const actualizarProductosCompraExternaPaginados = async (productosCompraExternaActualizados) => {
    dispatch({
      type: SET_PRODUCTOSCOMPRAEXTERNA_PAGINADOS,
      payload: productosCompraExternaActualizados,
    });
  };

  const setFiltrosProductosCompraExterna = async (filtrosProductosCompraExterna) => {
    dispatch({
      type: FILTRO_PRODUCTOSCOMPRAEXTERNA,
      payload: filtrosProductosCompraExterna,
    });
  };

  const uploadNuevoProducto = async (datosProducto) => {
    try {
      const res = await clienteAxios.post(`/medicamentocompraexterna/guardar`, datosProducto, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarProducto = async (idProductoCompraExterna) => {
    try {
      const response = await clienteAxios.post(
        "/medicamentocompraexterna/eliminar/",
        { idProductoCompraExterna: idProductoCompraExterna },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const updateProducto = async (productoModificado) => {
    try {
      const res = await clienteAxios.post(`/medicamentocompraexterna/guardar`, productoModificado, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <ProductosCompraExternaContext.Provider
      value={{
        productosCompraExterna: state.productosCompraExterna,
        filtrosProductosCompraExterna: state.filtrosProductosCompraExterna,
        productosCompraExternaPaginados: state.productosCompraExternaPaginados,
        listarProductosCompraExterna,
        setDataProductosCompraExterna,
        setFiltrosProductosCompraExterna,
        uploadNuevoProducto,
        eliminarProducto,
        updateProducto,
        actualizarProductosCompraExternaPaginados,
      }}>
      {props.children}
    </ProductosCompraExternaContext.Provider>
  );
}
