import React, {useReducer} from "react";

import SolicitudesComprasContext from "./SolicitudesComprasContext";
import SolicitudesComprasReducer from "./SolicitudesComprasReducer";

import {
    CAMBIOS_FILTRO_SOLICITUDES_COMPRAS,
    NUEVA_SOLICITUD_COMPRA,
    LISTAR_SOLICITUDESCOMPRA_FILTRADO,
    CREAR_NUEVA_SOLICITUDCOMPRA,
    ERROR_CREAR_NUEVA_SOLICITUDCOMPRA,
    NUEVA_SOLICITUD_COMPRA_PENDIENTE,
    SOLICITUD_COMPRA_SELECCIONADA,
    SOLICITUD_COMPRA_SHOWEDIT,
    ERROR_SOLICITUD_COMPRA_SELECCIONADA,
    UPLOAD_SOLICITUD_COMPRA,
    ERROR_UPLOAD_SOLICITUD_COMPRA,
    SOLICITUDES_ORDENESCOMPRA_PROVEEDOR,
    ERROR_SOLICITUDES_ORDENESCOMPRA_PROVEEDOR,
    CANCELAR_SOLICITUD_COMPRA,
} from "../../types";

import clienteAxios from "../../config/axios";

export default function SolicitudesComprasState(props) {
    const initialState = {
        solicitudesComprasFiltradas: [],
        filtrosSolicitudesCompras: {},
        solicitudCompraSeleccionada: {},
        solicitudOrdenesCompraProveedores: [],
        nuevaSolicitudCompra: {
            solicitudcompraproducto: [],
        },
        nuevaSolicitudCompraPendiente: null,
        solicitudShowEdit: {
            hiddenCotizacion: true,
            disabledCotizacion: true,
            guardarSolicitud: true,
            solicitarAutorizacion: true,
            finalizarAutorizacion: true,
            hiddenArchivosCotizacion: true,
            disabledArchivosCotizacion: true,
            hiddenDecision: true,
            disabledDecision: true,
            cancelarSolicitud: false,
            disabledProductoSustituto: true,
            cotizarProductoSustituto: false,
        },
        errorSolicitudCompraSeleccionada: false,
        cancelarSolicitudCompra: {
            fechaCancelacion: new Date().toISOString().slice(0, 10),
            comentarioEstatus: "",
        },
    };

    const [state, dispatch] = useReducer(SolicitudesComprasReducer, initialState);

    const setFiltrosSolicitudesCompras = async (filtrosSolicitudesCompras) => {
        dispatch({
            type: CAMBIOS_FILTRO_SOLICITUDES_COMPRAS,
            payload: filtrosSolicitudesCompras,
        });
    };

    const limpiarSolicitudCompraSeleccionada = async () => {
        dispatch({
            type: SOLICITUDES_ORDENESCOMPRA_PROVEEDOR,
            payload: [],
        });
    };

    const setNuevaSolicitudCompra = async (nuevaSolicitudCompra) => {
        dispatch({
            type: NUEVA_SOLICITUD_COMPRA,
            payload: nuevaSolicitudCompra,
        });
    };

    const listarSolicitudesCompras = async (data) => {
        const res = await clienteAxios.post("/solicitudcompra/filtro/json/detalle", data, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: LISTAR_SOLICITUDESCOMPRA_FILTRADO,
            payload: res.data,
        });
    };
    const listarSolicitudesComprasPaginadas = async ({page, limit}) => {
        try {
            const response = await clienteAxios.post(
                "/solicitudcompra/paginado/",
                {
                    pageSize: limit,
                    pageIndex: page,
                    ...state.filtrosSolicitudesCompras,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            dispatch({
                type: LISTAR_SOLICITUDESCOMPRA_FILTRADO,
                payload: response.data.solicitudCompraVoList,
            });

            return response.data;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const setSolicitudedComprasFiltradas = async (solicitudesComprasFiltradas) => {
        dispatch({
            type: LISTAR_SOLICITUDESCOMPRA_FILTRADO,
            payload: solicitudesComprasFiltradas,
        });
    };

    const guardarNuevaSolicitudCompra = async (nuevaSolicitudCompra) => {
        try {
            const res = await clienteAxios.post(`/solicitudcompra/nueva`, nuevaSolicitudCompra, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            dispatch({
                type: CREAR_NUEVA_SOLICITUDCOMPRA,
                payload: res.data,
            });

            return res;
        } catch (error) {
            dispatch({
                type: ERROR_CREAR_NUEVA_SOLICITUDCOMPRA,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }
            return error.response;
        }
    };

    const limpiarNuevaSolicitudCompra = async () => {
        dispatch({
            type: NUEVA_SOLICITUD_COMPRA,
            payload: {
                solicitudcompraproducto: [],
            },
        });
    };

    const seleccionarNuevaSolicitudCompraPendiente = async (requerimientoPendiente) => {
        const idVale = requerimientoPendiente?.idVale ? requerimientoPendiente?.idVale : null;
        const tipoProducto = requerimientoPendiente?.idCatTipoProducto ? requerimientoPendiente?.idCatTipoProducto : null;
        const idVentaMostrador = requerimientoPendiente?.idVentaMostrador ? requerimientoPendiente?.idVentaMostrador : null;

        //hacemos un fecth con los filtros para obtener la solicitud
        const res = await clienteAxios.post("/solicitudcompra/nueva/pendientes", {
            idVale: idVale, tipoProducto: tipoProducto, idVentaMostrador: idVentaMostrador}, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        setNuevaSolicitudCompraPendiente({
            solicitudcompraproducto: res.data,
        });
    };

    const setNuevaSolicitudCompraPendiente = async (nuevaSolicitudCompraPendiente) => {
        dispatch({
            type: NUEVA_SOLICITUD_COMPRA_PENDIENTE,
            payload: nuevaSolicitudCompraPendiente,
        });
    };

    const limpiarNuevaSolicitudCompraPendiente = async () => {
        dispatch({
            type: NUEVA_SOLICITUD_COMPRA_PENDIENTE,
            payload: null,
        });
    };

    const seleccionarSolicitudCompra = async (idSolicitudCompra) => {
        try {
            const res = await clienteAxios.get(`/solicitudcompra/detalles?id=${idSolicitudCompra}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            dispatch({
                type: SOLICITUD_COMPRA_SELECCIONADA,
                payload: res.data,
            });

            return res;
        } catch (error) {
            dispatch({
                type: ERROR_SOLICITUD_COMPRA_SELECCIONADA,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const setSolicitudCompraSeleccionada = async (solicitudCompraSeleccionada) => {
        dispatch({
            type: SOLICITUD_COMPRA_SELECCIONADA,
            payload: solicitudCompraSeleccionada,
        });
    };

    const setSolicitudShowEdit = async (solicitudShowEdit) => {
        dispatch({
            type: SOLICITUD_COMPRA_SHOWEDIT,
            payload: solicitudShowEdit,
        });
    };

    const guardarSolicitudCompra = async () => {
        try {
            const res = await clienteAxios.post(
                `/solicitudcompra/modificar/solicitudcompraproducto`,
                state.solicitudCompraSeleccionada,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            dispatch({
                type: UPLOAD_SOLICITUD_COMPRA,
                payload: res.data,
            });

            return res;
        } catch (error) {
            dispatch({
                type: ERROR_UPLOAD_SOLICITUD_COMPRA,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const seleccionarOrdenesCompraProveedores = async (idSolicitudCompra) => {
        try {
            const res = await clienteAxios.post(
                `/ordencompra/nueva/solicitud`,
                {idSolicitudCompra: idSolicitudCompra},
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            dispatch({
                type: SOLICITUDES_ORDENESCOMPRA_PROVEEDOR,
                payload: res.data,
            });

            return res;
        } catch (error) {
            dispatch({
                type: ERROR_SOLICITUDES_ORDENESCOMPRA_PROVEEDOR,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const setSolicitudesOrdenesCompraProveedores = async (solicitudOrdenesCompraProveedores) => {
        dispatch({
            type: SOLICITUDES_ORDENESCOMPRA_PROVEEDOR,
            payload: solicitudOrdenesCompraProveedores,
        });
    };

    const uploadCancelarSolicitudCompra = async (data) => {
        try {
            const res = await clienteAxios.post(`/solicitudcompra/modificar/estatus`, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            dispatch({
                type: UPLOAD_SOLICITUD_COMPRA,
                payload: res.data,
            });

            return res;
        } catch (error) {
            dispatch({
                type: ERROR_UPLOAD_SOLICITUD_COMPRA,
                payload: null,
            });

            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const setCancelarSolicitudCompra = async (cancelarSolicitudCompra) => {
        dispatch({
            type: CANCELAR_SOLICITUD_COMPRA,
            payload: cancelarSolicitudCompra,
        });
    };

    const exportArchivoBack = async (archivoVo) => {
        try {
            const res = await clienteAxios.post(`/solicitudcompra/export/archivo`, archivoVo, {
                responseType: "arraybuffer",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });
            var file = new Blob([res.data], {type: archivoVo.mimeType});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            } else {
                error.response = {
                    data: {
                        message: "Error, por favor inténtalo más tarde",
                        error: "Error",
                    },
                };
            }
            return error.response;
        }
    };

    return (
        <SolicitudesComprasContext.Provider
            value={{
                solicitudesComprasFiltradas: state.solicitudesComprasFiltradas,
                filtrosSolicitudesCompras: state.filtrosSolicitudesCompras,
                solicitudCompraSeleccionada: state.solicitudCompraSeleccionada,
                nuevaSolicitudCompra: state.nuevaSolicitudCompra,
                nuevaSolicitudCompraPendiente: state.nuevaSolicitudCompraPendiente,
                solicitudShowEdit: state.solicitudShowEdit,
                errorSolicitudCompraSeleccionada: state.errorSolicitudCompraSeleccionada,
                solicitudOrdenesCompraProveedores: state.solicitudOrdenesCompraProveedores,
                cancelarSolicitudCompra: state.cancelarSolicitudCompra,
                setFiltrosSolicitudesCompras,
                limpiarSolicitudCompraSeleccionada,
                setNuevaSolicitudCompra,
                listarSolicitudesCompras,
                setSolicitudedComprasFiltradas,
                guardarNuevaSolicitudCompra,
                limpiarNuevaSolicitudCompra,
                seleccionarNuevaSolicitudCompraPendiente,
                setNuevaSolicitudCompraPendiente,
                limpiarNuevaSolicitudCompraPendiente,
                seleccionarSolicitudCompra,
                setSolicitudCompraSeleccionada,
                setSolicitudShowEdit,
                guardarSolicitudCompra,
                seleccionarOrdenesCompraProveedores,
                setSolicitudesOrdenesCompraProveedores,
                uploadCancelarSolicitudCompra,
                setCancelarSolicitudCompra,
                listarSolicitudesComprasPaginadas,
                exportArchivoBack,
            }}>
            {props.children}
        </SolicitudesComprasContext.Provider>
    );
}
