import React, { useContext, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TabGrafica from "./TabGrafica";
import PacientesContext from "../../../context/Pacientes/PacientesContext";

export default function TabMarcadores({ props }) {
  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado, listarMarcadores, marcadores } = pacientesContext;

  const [tabIndex, setTabIndex] = useState(0);
  // const [listado, setListado] = useState(false);

  useEffect(() => {
    listarMarcadores(idCatPacienteSeleccionado);
    // if (!listado) {
    //   listarMarcadores(idCatPacienteSeleccionado);
    //   setListado(true);
    // }
  }, []);

  return (
    <div className="mt-4 container-fluid">
      <Row>
        <Col>
          <h4>Marcadores</h4>
        </Col>
      </Row>

      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {marcadores &&
            marcadores.map((marcador) => {
              return <Tab key={marcador.marcador}>{marcador.marcador}</Tab>;
            })}
        </TabList>

        {marcadores &&
          marcadores.map((marcador) => {
            return (
              <TabPanel key={marcador.marcador}>
                <TabGrafica key={marcador.marcador} props={props} marcador={marcador} displayLegend={false} />
              </TabPanel>
            );
          })}
      </Tabs>
    </div>
  );
}
