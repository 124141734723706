import React, { useReducer } from "react";

import HistoriaClinicaInicialContext from "./PadecimientoActualContext";
import PadecimientoActualReducer from "./PadecimientoActualReducer";

import { PADECIMIENTO_ACTUAL } from "../../types";

import clienteAxios from "../../config/axios";

export default function PadecimientoActualState(props) {
  const initialState = {
    padecimientoActual: {},
  };

  const [state, dispatch] = useReducer(PadecimientoActualReducer, initialState);

  const seleccionarPadecimientoActualPaciente = async (idCatPaciente) => {
    const res = await clienteAxios.post(
      `/expedientemedico/padecimiento/actual`,
      { idCatPaciente: idCatPaciente },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    dispatch({
      type: PADECIMIENTO_ACTUAL,
      payload: res.data,
    });
  };

  const setPadecimientoActual = async (padecimientoActual) => {
    dispatch({
      type: PADECIMIENTO_ACTUAL,
      payload: padecimientoActual,
    });
  };

  const uploadPadecimientoActual = async (idCatPaciente, tieneExpedienteMedico) => {
    if (tieneExpedienteMedico) {
      try {
        const res = await clienteAxios.post(`/expedientemedico/padecimiento/actual/guardar`, state.padecimientoActual, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        });

        return res;
      } catch (error) {
        if (!error.response) {
          error.response = {
            data: {
              message: "Fallo en la conexión al servidor",
              error: "hubo error",
            },
          };
        }

        return error.response;
      }
    } else {
      try {
        const res = await clienteAxios.post(
          `/expedientemedico/padecimiento/actual/guardar`,
          { ...state.padecimientoActual, paciente: { idCatPaciente: idCatPaciente } },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        return res;
      } catch (error) {
        if (!error.response) {
          error.response = {
            data: {
              message: "Fallo en la conexión al servidor",
              error: "hubo error",
            },
          };
        }

        return error.response;
      }
    }
  };

  return (
    <HistoriaClinicaInicialContext.Provider
      value={{
        padecimientoActual: state.padecimientoActual,
        seleccionarPadecimientoActualPaciente,
        uploadPadecimientoActual,
        setPadecimientoActual,
      }}>
      {props.children}
    </HistoriaClinicaInicialContext.Provider>
  );
}
