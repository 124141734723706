import React, {useState, useContext, useEffect, useRef, useCallback, useMemo} from "react";
import {Row, Col, Card, CardBody, Spinner} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {Link} from "react-router-dom";

import ValesContext from "../../context/Vales/ValesContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import EstatusValeContext from "../../context/EstatusVale/EstatusValeContext";
import UsuariosContext from "../../context/Usuarios/UsuariosContext";
import TipoValeContext from "../../context/TipoVale/TipoValeContext";
import AuthContext from "../../context/Auth/AuthContext";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import SelectPacientesPaginado from "../SelectAsyncPaginate";
import formatDate from "../../utils/formatDate";
import {opcionesSelectPacientes} from "../../config/configOpcionesSelect";
import useWindowDimensions from "../../hooks/dimensionsHook";
import { format } from 'date-fns'

export default function Vales(props) {
    const [toggleSearchBy, setToggleSearchBy] = useState(false);

    const [rowsSeleccionadas, setRowsSeleccionadas] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const [pageCount, setPageCount] = useState(0);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);

    const [pacienteCompleto, setPacienteCompleto] = useState(null);

    const [valesTablaSeleccionado, setValesTablaSeleccionado] = useState(null);

    const fetchIdRef = useRef(0);

    const {width} = useWindowDimensions();

    const valeFechaTratamientoTemporalVo = {
        fechaTratamiento: "",
        fechaTratamientoOriginal: "",
        idVale: 0,
    };
    const [valeFechaTratamientoTemporal, setValeFechaTratamientoTemporal] = useState(valeFechaTratamientoTemporalVo);

    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);
            global.cambio = false;
  
            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);
            }

            let necesitaAutorizacionTemp = 0;
            const response = await listarValesPaginados({page, limit, necesitaAutorizacionTemp});

            // Cantidad de datos disponibles en la tabla pacientes de la base de datos
            const count = response.count;
            setRowsSeleccionadas(null);
            // setRowSeleccionada(null);
            // setValeTablaSeleccionado(null);
            setValesTablaSeleccionado(null);

            if (count % limit > 0) {
                setPageCount(Math.ceil(count / limit));
            } else {
                setPageCount(count / limit);
            }

            setLoadingData(false);
        } catch (e) {
            console.log("Error while fetching", e);
            // setLoading(false)
        }
    };

    const fetchData = async ({pageSize, pageIndex}) => {
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: false,
            });
        }
    };

    const handleCheckedVale = useCallback(
        (row) => {
            if (rowsSeleccionadas === null) {
                const rows = [row.id];
                setRowsSeleccionadas(rows);
                const vales = [{...row.original}];
                setValesTablaSeleccionado(vales);
                setDatosCotizacion({
                    encabezado: row.original.encabezado,
                    piePagina: row.original.piePagina,
                    tratamiento: "",
                    aplicacion: "",                
                });
            } else if (rowsSeleccionadas.length === 0) {//aca entra cuando se selecciona 1 dato
                const rows = [row.id];
                setRowsSeleccionadas(rows);
                const vales = [{...row.original}];
                setValesTablaSeleccionado(vales);
                setDatosCotizacion({
                    encabezado: row.original.encabezado,
                    piePagina: row.original.piePagina,
                    tratamiento: "",
                    aplicacion: "",                
                });
            } else if (rowsSeleccionadas.includes(row.id)) {
                const tempRows = rowsSeleccionadas.filter((r) => r !== row.id);
                setRowsSeleccionadas(tempRows);
                const tempVales = valesTablaSeleccionado.filter((v) => v.idVale !== row.original.idVale);
                setValesTablaSeleccionado(tempVales);
                setDatosCotizacion({
                    encabezado: row.original.encabezado,
                    piePagina: row.original.piePagina,
                    tratamiento: "",
                    aplicacion: "",                
                });
            } else {
                const rows = [...rowsSeleccionadas, row.id];
                setRowsSeleccionadas(rows);
                const vales = [...valesTablaSeleccionado, {...row.original}];
                setValesTablaSeleccionado(vales);
                setDatosCotizacion({
                    encabezado: row.original.encabezado,
                    piePagina: row.original.piePagina,
                    tratamiento: "",
                    aplicacion: "",                
                });
            }
        },
        [rowsSeleccionadas]
    );

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "idVale",
                Cell: ({row}) => {
                    return (
                        <input
                            // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
                            checked={rowsSeleccionadas != null ? rowsSeleccionadas.includes(row.id) : false}
                            type="checkbox"
                            onChange={() => handleCheckedVale(row)}
                        />
                    );
                },

                disableFilters: true,
                disableSortBy: true,
            },
            {Header: "Folio", accessor: "folioVale"},
            {
                Header: () => (
                    <>
                        Fecha del<br></br>Vale
                    </>
                ),
                accessor: "fechaVale",
                Cell: ({row}) => {
                    return <>{row.original.fechaVale ? row.original.fechaVale.split("T")[0] : ""}</>;
                },
            },
            {
                Header: () => (
                    <>
                        Fecha de<br></br>Tratamiento
                    </>
                ),
                accessor: "fechaTratamiento",
                Cell: ({row}) => {
                    return <>{row.original.fechaTratamiento ? row.original.fechaTratamiento.split("T")[0] : ""}</>;
                },
            },            
            {Header: "Paciente", accessor: "paciente"},
            {Header: "Médico", accessor: "medico"},
            {
                Header: "Aseguradora",
                accessor: "aseguradora",
                Cell: ({row}) => {
                    return <>{row.original.aseguradora ? row.original.aseguradora : "N/A"}</>;
                },
            },
            {
                Header: "Reembolso",
                accessor: "reembolso",
                Cell: ({row}) => {
                    return (
                        <div className="text-center">
                            {" "}
                            {row.original.reembolso ? <i className="ti-check"></i> : <i className="ti-close"></i>}{" "}
                        </div>
                    );
                },
            },
            {
                Header: "Cotización Impresa",
                accessor: "impreso",
                Cell: ({row}) => {
                    return (
                        <div className="text-center">
                            {" "}
                            {row.original.impreso ? <i className="ti-check"></i> : <i className="ti-close"></i>}{" "}
                        </div>
                    );
                },
            },            
            {
                Header: "Solicitante",
                accessor: "usuarioSolicitante",
                Cell: ({row}) => {
                    return <>{row.original.usuarioSolicitante ? row.original.usuarioSolicitante : ""}</>;
                },
            },

            {
                Header: "Estatus",
                accessor: "estatusActual",
                Cell: ({row}) => {
                    return <>{estatusActual(row.original)}</>;
                },
            },
            {
                Header: () => (
                    <>
                        Cambio<br></br>Estatus
                    </>
                ),
                accessor: "estatus",
                Cell: ({row}) => {
                    return (
                        <>
                            <div hidden={row.original.necesitaAutorizacion!=1 && accesoACambioEstatus(row.original) ? false : true}>
                                <Link
                                    name="cambioEstatus"
                                    to={`/vales/${row.original.idVale}`}
                                    className="btn btn-sm btn-primary-orange-umo"
                                    style={{
                                        background: "#f93",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}>
                                    Cambio
                                </Link>
                            </div>
                        </>
                    );
                },
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: "Histórico",
                accessor: "historico",
                Cell: ({row}) => {
                    return (
                        <Link
                            to={`/vales/historico/${row.original.idVale}`}
                            className="btn btn-sm btn-primary-orange-umo"
                            style={{
                                background: "#f93",
                                color: "white",
                                fontWeight: "bold",
                            }}>
                            Histórico
                        </Link>
                    );
                },

                disableFilters: true,
                disableSortBy: true,
            },
        ],
        [rowsSeleccionadas, handleCheckedVale]
    );

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_devolver_vale: false,
        confirm_alert_cancelar_vale: false,
        confirm_alert_revivir_vale: false,
        confirm_alert_cambiar_concentracion_vale: false,
        confirm_alert_imprimir_vale: false,
        confirm_alert_imprimir_cotizacion: false,
        confirm_alert_cambiar_FechaTratamiento: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    const [loading, setLoading] = useState(false);

    const [datosCotizacion, setDatosCotizacion] = useState({
        encabezado: "",
        piePagina: "",
        tratamiento: "",
        aplicacion: "",
    });
     const fechaActual = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + ""
     
    const cleanCancelarDevolverVale = {
        fechaDevolucion: new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "",
        fechaCancelacion: new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "",
        //fechaDevolucion: format(new Date(), 'dd-MM-yyyy'),
        //fechaCancelacion: format(new Date(), 'dd-MM-yyyy'),
        comentarioEstatus: "",
    };
    const [cancelarDevolverVale, setCancelarDevolverVale] = useState(cleanCancelarDevolverVale);

    // ACCESO A ESTADO GLOBAL - VALES
    const ctxVales = useContext(ValesContext);
    const {
        valesFiltrados,

        valeSeleccionado,
        cleanValeSeleccionado,
        filtrosVale,
        setChangesFiltrosVale,
        devolverVale,
        cancelarVale,
        cambiarFechaTratamiento,
        cambiarConcentracionVale,
        exportValeReport,
        revivirVale,
        listarValesPaginados,
        exportValeCotizacion,
        SalvarPorImpresion,
        downloadValeReport,
    } = ctxVales;

    //ACCESO AL ESTADO GLOBAL - MEDICOS
    const ctxMedicos = useContext(MedicosContext);
    const {medicos, listarMedicos} = ctxMedicos;

    //ACCESO AL ESTADO GLOBAL - MEDICOS
    const ctxPacientes = useContext(PacientesContext);
    const {listarPacientes, loadPacientesSelect} = ctxPacientes;

    //ACCESO AL ESTADO GLOBAL - Aseguradoras
    const ctxAseguradoras = useContext(AseguradorasContext);
    const {aseguradoras, listarAseguradoras} = ctxAseguradoras;

    //ACCESO AL ESTADO GLOBAL - Estatus Vale
    const ctxEstatusVale = useContext(EstatusValeContext);
    const {
        estatusvale,
        listarEstatusVale,
        setcambioEstatusData,
        proximosEstatusVale,
        cleanProximosEstatusVale,
        estatusCancelado,
        getEstatusCancelado,
        getEstatusDevuelto,
        estatusDevuelto,
        actualizarOCambiarEstatus
    } = ctxEstatusVale;

    //ACCESO AL ESTADO GLOBAL - Estatus Vale
    const ctxUsuarios = useContext(UsuariosContext);
    const {usuarios, listarUsuarios} = ctxUsuarios;

    //ACCESO AL ESTADO GLOBAL - Estatus Vale
    const ctxTiposVale = useContext(TipoValeContext);
    const {tiposVale, listarTiposVale} = ctxTiposVale;

    //ACCESO AL ESTADO GLOBAL - AUTENCIACION
    const ctxAuth = useContext(AuthContext);
    const {usuario} = ctxAuth;

    // MANEJO DE CRUD LOCAL
    const onChange = (e, name = null) => {
        if (name && name == "paciente") {
            setPacienteCompleto(e);
            setChangesFiltrosVale({
                ...filtrosVale,
                [name]: e.idCatPaciente,
            });
        } else {
            setChangesFiltrosVale({
                ...filtrosVale,
                [e.target.name]: e.target.value,
            });
        }
    };

    const onChangeFecha = (e) => {
        if (e.target.name == "fechaInicial") {
            setChangesFiltrosVale({
                ...filtrosVale,
                [e.target.name]: e.target.value,
                fechaFinal: e.target.value,
            });
        } else {
            setChangesFiltrosVale({
                ...filtrosVale,
                [e.target.name]: e.target.value,
            });
        }
    };

    const onChangeFolio = (e) => {
        setChangesFiltrosVale({
            ...filtrosVale,
            [e.target.name]: e.target.value,
        });
    };

    const enviarValesFiltrados = (e) => {
        e.preventDefault();
        if (filtrosVale.fechaInicial && !filtrosVale.fechaFinal) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Introduzca una fecha inicial y una final",
            });
            return;
        }

        if (filtrosVale.fechaFinal) {
            filtrosVale.fechaFinal = filtrosVale.fechaFinal + "T23:59:59";
        }

        fetchData({pageSize: 10, pageIndex: 0});

        if (filtrosVale.fechaFinal) {
            filtrosVale.fechaFinal = filtrosVale.fechaFinal.split("T")[0];
        }
    };

    const buscarPorFolio = (e) => {
        e.preventDefault();
        fetchData({pageSize: 10, pageIndex: 0});
    };

    const toggleSearch = () => {
        setToggleSearchBy(!toggleSearchBy);
        limpiarFiltros();
    };

    const limpiarFiltros = () => {
        setPacienteCompleto(null);
        setChangesFiltrosVale({
            fechaInicial: "",
            paciente: "",
            aseguradora: "",
            estatusVale: "",
            // estatusVale: [],
            estatusValeOpciones: [],
            fechaFinal: "",
            medico: "",
            tipoVale: "",
            folioVale: null,
            usuarioSolicitante: "",
        });
    };

    const isValeChecked = async () => {
        let unValeChecked = valesTablaSeleccionado !== null && valesTablaSeleccionado.length === 1;

        if (!unValeChecked) {
            //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione solo un vale para esta acción",
            });
        }

        return unValeChecked;
    };

    const isMultiplesValesChecked = async () => {
        let isValeChecked = valesTablaSeleccionado !== null && valesTablaSeleccionado.length > 0;

        if (!isValeChecked) {
            //Si no lo tenemos mostramos un mensaje de error, que pida seleccionar un vale
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione al menos un vale para esta acción",
            });
        }

        return isValeChecked;
    };

    const onChangeCancelarDevolverVale = (e) => {
        setCancelarDevolverVale({
            ...cancelarDevolverVale,
            [e.target.name]: e.target.value,
        });
    };

    const cancelarValeConfirm = async () => {
        const valeChecked = await isValeChecked();

        //Al principio preguntamos si tenemos un vale seleccionado
        if (valeChecked) {
            const valeTablaSeleccionado = valesTablaSeleccionado[0];
            const estatus = valeTablaSeleccionado.estatusActual.catEstatusvale.idCatEstatusVale;

            if(usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") && (estatus != 6 && estatus != 16)){
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Este vale no se puede cancelar",
                });
                return;
            }
            //Despues tenemos que preguntar si el vale tiene permitido hacer esa accion
            if (valeTablaSeleccionado.cancelarVale) {
                //Si el vale permite hacer la accion, mostramos la confirmacion de la accion
                getEstatusCancelado(valeTablaSeleccionado.idVale);

                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_cancelar_vale: true,
                });
            } else {
                //Si no tiene permitod la accion, le mostramos un mensaje de que no es permitido
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Este vale no se puede cancelar",
                });
            }
        }
    };

    const changeFechaTratamiento = async () => {
        // eslint-disable-next-line
        const valeChecked = await isValeChecked();
        const valeTablaSeleccionado = valeChecked ? valesTablaSeleccionado[0] : null;

        if (valeFechaTratamientoTemporal.fechaTratamiento == "" ) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Capture la nueva fecha de tratamiento.",
            });
            return;
        }

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_cambiar_FechaTratamiento: false,
        });
        let cadenaLogLocal = ""
        let folio = valeTablaSeleccionado.idVale
        cadenaLogLocal = cadenaLogLocal+ "CambioFechaTratamiento:ID[" + folio + "],FT[" + valesTablaSeleccionado[0].fechaTratamiento + "->" + valeFechaTratamientoTemporal.fechaTratamiento  + "]";

        setLoading(true);
        const res = await cambiarFechaTratamiento({
            idVale: valeFechaTratamientoTemporal.idVale,
            fechaTratamiento: valeFechaTratamientoTemporal.fechaTratamiento ,
            fechaTratamientoOriginal: valesTablaSeleccionado[0].fechaTratamiento ,
            logVo : cadenaLogLocal.length==0? null:{
                idUsuario: usuario.idUsuario,
                modulo: "Vales",
                actividad:cadenaLogLocal,
            },
        });
        
        setValeFechaTratamientoTemporal(valeFechaTratamientoTemporalVo);

        if (res.data == true) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_cancelar_vale: false,
                confirm_alert_cambiar_FechaTratamiento:false,
                dynamic_description: "Se ha cambiado la fecha de tratamiento",
            });
            fetchData({pageSize: 10, pageIndex: controlledPageIndex});
            // setValeTablaSeleccionado(null);
            setValesTablaSeleccionado(null);
        } else {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_cancelar_vale: false,
                dynamic_title: "Fallo",
                dynamic_description: "Error al cambiar la fecha de tratamiento, Inténtalo por favor más tarde.",
            });
        }

        setCancelarDevolverVale(cleanCancelarDevolverVale);
    };

    const changeEstatusCancelado = async () => {
        // eslint-disable-next-line
        const valeChecked = await isValeChecked();
        const valeTablaSeleccionado = valeChecked ? valesTablaSeleccionado[0] : null;

        if (cancelarDevolverVale.comentarioEstatus == "" || cancelarDevolverVale.fechaCancelacion == "") {
            return;
        }

        let date = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "";

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_cancelar_vale: false,
        });
        let cadenaLogLocal = ""
        let folio = valeTablaSeleccionado.idVale
        cadenaLogLocal = cadenaLogLocal+ "Cancelación:ID[" + folio + "]";

        setLoading(true);
        const res = await cancelarVale({
            idEstatusNuevo: estatusCancelado.idCatEstatusVale,
            idVale: valeTablaSeleccionado.idVale,
            fechaCancelacion: date,
            logVo : cadenaLogLocal.length==0? null:{
                idUsuario: usuario.idUsuario,
                modulo: "Vales",
                actividad:cadenaLogLocal,
            },
            comentarioEstatus: cancelarDevolverVale.comentarioEstatus,
        });
        if (res) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_cancelar_vale: false,
                dynamic_description: "Vale cancelado",
            });
            fetchData({pageSize: 10, pageIndex: controlledPageIndex});
            // setValeTablaSeleccionado(null);
            setValesTablaSeleccionado(null);
        } else {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_cancelar_vale: false,
                dynamic_title: "Fallo",
                dynamic_description: "Error al cancelar vale, Inténtalo por favor más tarde.",
            });
        }

        setCancelarDevolverVale(cleanCancelarDevolverVale);
    };

    const devolverValeConfirm = async () => {
        let valeChecked = await isValeChecked();
        
        const estatusTemp = valesTablaSeleccionado[0].estatusActual.idCatEstatusVale
        if(usuario.authorities.includes("ROLE_QUIMIO") && ( estatusTemp!=6 && estatusTemp!=16 )){
            valeChecked = false;
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Solo puede devolver vales en estatus POR APLICAR.",
            });
            return;
        }

        //Al principio preguntamos si tenemos un vale seleccionado
        if (valeChecked) {
            const valeTablaSeleccionado = valesTablaSeleccionado[0];
            //Despues tenemos que preguntar si el vale tiene permitido hacer esa accion
            if (valeTablaSeleccionado.devolverVale) {
                //Si el vale permite hacer la accion, mostramos la confirmacion de la accion
                getEstatusDevuelto(valeTablaSeleccionado.idVale);

                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_devolver_vale: true,
                });
            } else {
                //Si no tiene permitod la accion, le mostramos un mensaje de que no es permitido
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Este vale no se puede devolver",
                });
            }
        }
    };

    const changeEstatusDevuelto = async () => {
        // eslint-disable-next-line
        const valeChecked = await isValeChecked();
        const valeTablaSeleccionado = valeChecked ? valesTablaSeleccionado[0] : null;

        if (cancelarDevolverVale.comentarioEstatus == "" || cancelarDevolverVale.fechaDevolucion == "") {
            return;
        }

        let date = new Date(new Date().getTime() + 3600 * -6).toLocaleString().slice(0, 9) + "";

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_devolver_vale: false,
        });

        setLoading(true);
        let cadenaLogLocal = ""
        let folio = valeTablaSeleccionado.idVale
        cadenaLogLocal = cadenaLogLocal+ "DevolverVale:ID[" + folio + "]";
        const res = await devolverVale({
            idEstatusNuevo: estatusDevuelto.idCatEstatusVale,
            idVale: valeTablaSeleccionado.idVale,
            fechaDevolucion: date,
            logVo : cadenaLogLocal.length==0? null:{
                idUsuario: usuario.idUsuario,
                modulo: "Vales",
                actividad:cadenaLogLocal,
            },
            comentarioEstatus: cancelarDevolverVale.comentarioEstatus,
        });
        if (res) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_devolver_vale: false,
                dynamic_description: "Vale devuelto",
            });
            fetchData({pageSize: 10, pageIndex: controlledPageIndex});
            // setValeTablaSeleccionado(null);
            setValesTablaSeleccionado(null);
        } else {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_devolver_vale: false,
                dynamic_title: "Fallo",
                dynamic_description: "Error al devolver el vale, Inténtalo por favor más tarde.",
            });
        }
        setCancelarDevolverVale(cleanCancelarDevolverVale);
    };

    const revivirValeConfirm = async () => {
        const valeChecked = await isValeChecked();

        //Al principio preguntamos si tenemos un vale seleccionado
        if (valeChecked) {
            const valeTablaSeleccionado = valesTablaSeleccionado[0];
            //Despues tenemos que preguntar si el vale tiene permitido hacer esa accion
            if (valeTablaSeleccionado.revivirVale) {
                //Si el vale permite hacer la accion, mostramos la confirmacion de la accion

                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_revivir_vale: true,
                });
            } else {
                //Si no tiene permitod la accion, le mostramos un mensaje de que no es permitido
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Este vale no se puede revivir",
                });
            }
        }
    };

    const cambiarConcentracionValeConfirm = async () => {
        const valeChecked = await isValeChecked();

        //Al principio preguntamos si tenemos un vale seleccionado
        if (valeChecked) {
            const valeTablaSeleccionado = valesTablaSeleccionado[0];
            //Despues tenemos que preguntar si el vale tiene permitido hacer esa accion
            if (valeTablaSeleccionado.cambioConcentracion) {
                //Si el vale permite hacer la accion, mostramos la confirmacion de la accion

                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_cambiar_concentracion_vale: true,
                });
            } else {
                //Si no tiene permitod la accion, le mostramos un mensaje de que no es permitido
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "A este vale no se le puede cambiar la concentración",
                });
            }
        }
    };

    const cambioDeConcentracion = async () => {
        // eslint-disable-next-line
        const valeChecked = await isValeChecked();
        const valeTablaSeleccionado = valeChecked ? valesTablaSeleccionado[0] : null;

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_cambiar_concentracion_vale: false,
        });

        setLoading(true);
        const res = await cambiarConcentracionVale({
            idEstatusNuevo: estatusCancelado.idCatEstatusVale,
            idVale: valeTablaSeleccionado.idVale,
            comentarioEstatus: "Cambio de Concentración",
        });

        if (res) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_cambiar_concentracion_vale: false,
                redirect: true,
                path: `/vales/${res.data[1].idVale}`,
                dynamic_description: "Se ha creado un vale idéntico para el cambio de concentración",
            });
            fetchData({pageSize: 10, pageIndex: controlledPageIndex});
            setValesTablaSeleccionado(null);
            // setValeTablaSeleccionado(null);
        } else {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_cambiar_concentracion_vale: false,
                dynamic_title: "Fallo",
                dynamic_description: "Error al cambiar la concentración del vale, Inténtalo por favor más tarde.",
            });
        }
    };

    const estatusActual = (vale) => {
            switch (vale.estatusActual.catEstatusvale.idCatEstatusVale) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 18:
                case 19:
                case 20:
                case 23:
                case 24:
                    return (
                        <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "black",
                        fontWeight: "bold",
                        //verde
                        backgroundColor: "#C1E05C",
                        borderRadius: "5px",
                    }}>
                {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                        </>
                    );

                case 7:
                case 10:
                case 17:
                case 21:
                case 22:
                case 25:
                    return (
                        <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "white",
                        fontWeight: "bold",
                        //morado
                        backgroundColor: "#602a96",
                        borderRadius: "5px",
                    }}>
                {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                        </>
                    );

                //Debemos checar los estatus aplicado y cobrado, para saber cuando se acaba el ciclo de vida de un vale personal
                case 8:
                    if (vale.idCatTipoMedico == 2) {
                        return (
                            <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "white",
                        fontWeight: "bold",
                        //morado
                        backgroundColor: "#602a96",
                        borderRadius: "5px",
                    }}>
                    {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                            </>
                        );
                    } else {
                        return (
                            <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "black",
                        fontWeight: "bold",
                        //verde
                        backgroundColor: "#C1E05C",
                        borderRadius: "5px",
                    }}>
                    {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                            </>
                        );
                    }
                case 9:
                    if (vale.idCatTipoMedico == 1) {
                        return (
                            <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "white",
                        fontWeight: "bold",
                        //morado
                        backgroundColor: "#602a96",
                        borderRadius: "5px",
                    }}>
                    {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                            </>
                        );
                    } else {
                        return (
                            <>
                <span
                    className="badge badge-info"
                    style={{
                        height: "1.9rem",
                        paddingTop: "0.6rem",
                        border: "1px solid #dfdada",
                        color: "black",
                        fontWeight: "bold",
                        //verde
                        backgroundColor: "#C1E05C",
                        borderRadius: "5px",
                    }}>
                    {vale.estatusActual.catEstatusvale.estatusVale}
                </span>
                            </>
                        );
                    }
                default:
                    return <></>;
            }
    };

    const accesoACambioEstatus = (vale) => {
        if(vale.estatusActual.catEstatusvale.idCatEstatusVale){
            switch (vale.estatusActual.catEstatusvale.idCatEstatusVale) {
                //Solicitado
                case 1:
                    if (usuario.authorities.includes("ROLE_FARMACIA")) return true;
                    return false;
                case 11:
                    if (usuario.authorities.includes("ROLE_FARMACIA")) return true;
                    return false;
                case 3:
                case 4:
                case 13:
                case 14:
                    if (!usuario.authorities.includes("ROLE_FARMACIA")) {
                        return false;
                    } else {
                        return true;
                    }

                //Cotizado
                case 2:
                    if (!usuario.authorities.includes("ROLE_RECEPCION")) {
                        return false;
                    } else {
                        return true;
                    }
                case 12:
                case 18:
                case 19:
                case 20:
                    if (!usuario.authorities.includes("ROLE_ASEGURADORAS")) {
                        return false;
                    } else {
                        return true;
                    }
                //Completo
                case 5:
                case 15:
                    if (usuario.authorities.includes("ROLE_CENTRO_MEZCLAS")) {
                        return true;
                    } else {
                        return false;
                    }
                //Por aplicar
                case 16:
                    if (!vale.cobroCoaseguroReembolso && !usuario.authorities.includes("ROLE_QUIMIO")) {
                        return false;
                    } else if (vale.cobroCoaseguroReembolso && !usuario.authorities.includes("ROLE_RECEPCION")) {
                        return false;
                    } else {
                        return true;
                    }
                case 6:
                    //Hay que checar que tipo de medico es, para saber si ya termino el vale o vamos a cobrado
                    if (vale.idCatTipoMedico == 1 && usuario.authorities.includes("ROLE_QUIMIO")) {
                        return true;
                    } else if (vale.idCatTipoMedico == 2 && usuario.authorities.includes("ROLE_FARMACIA")) {
                        return false;
                    } else {
                        return false;
                    }

                //Devuelto
                //Cancelado
                // case 7:
                // case 17:
                // case 10:
                // case 22:
                // Aplicado de
                case 8:
                    return false;

                //Cobrado
                case 9:
                    //Hay que checar que tipo de medico es, para saber si el vale se acaba en cobrado o se acaba en
                    if (vale.idCatTipoMedico == 2 && usuario.authorities.includes("ROLE_QUIMIO")) {
                        return true;
                    } else {
                        return false;
                    }

                //Ya se realizaron los cobros de coaseguro o de reembolso
                case 23:
                case 24:
                    if (usuario.authorities.includes("ROLE_QUIMIO")) {
                        return true;
                    } else {
                        return false;
                    }
                case 21:
                    return false;
                default:
                    return false;
            }
        }
    };

    const checkImprimirVale = async () => {
        const valeChecked = await isMultiplesValesChecked();

        if (valeChecked) {
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_imprimir_vale: true,
            });
        } else {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Seleccione al menos un vale",
            });
        }
    };

    const imprimirVale = async () => {
        const valeChecked = await isMultiplesValesChecked();
        const handleExportValeReports = async () => {
            try {
                for (const vale of valesTablaSeleccionado) {
                    await exportValeReport(vale.idVale);
                }
                setLoading(false);
                console.log("Todos los vales se exportaron correctamente");
            } catch (error) {
                setLoading(false);
                console.error("Error al exportar algún vale:", error);
            }
        };
        //Al principio preguntamos si tenemos un vale seleccionado
        if (valeChecked) {
            setLoading(true);
            handleExportValeReports();
        }
    };

    const downloadVales = async () => {
        const valeChecked = await isMultiplesValesChecked();
        const handleExportValeReports = async () => {
            try {
                for (const vale of valesTablaSeleccionado) {
                    await downloadValeReport(vale.idVale, vale.folioVale);
                }
                setLoading(false);
                console.log("Todos los vales se descargaron correctamente");
            } catch (error) {
                setLoading(false);
                console.error("Error al exportar algún vale:", error);
            }
        };
        //Al principio preguntamos si tenemos un vale seleccionado
        if (valeChecked) {
            setLoading(true);
            handleExportValeReports();
        }
    };

    const revivirValeUpload = async (bandera) => {
        const valeChecked = await isValeChecked();

        if (valeChecked) {
            const valeTablaSeleccionado = valesTablaSeleccionado[0];

            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_revivir_vale: false,
            });

            let cadenaLogLocal = ""
            let folio = valeTablaSeleccionado.idVale
            if (bandera){
                cadenaLogLocal = cadenaLogLocal+ "RevivirVale-CrearFolio:ID[" + folio + "]";
            }else{
                cadenaLogLocal = cadenaLogLocal+ "RevivirVale-MismoFolio:ID[" + folio + "]";
            }
           

            let logVo = cadenaLogLocal.length==0? null:{
                idUsuario: usuario.idUsuario,
                modulo: "Vales",
                actividad:cadenaLogLocal,
            };

            setLoading(true);
            let VoRevivir = {
                idVale: valeTablaSeleccionado.idVale,
                nuevo: bandera,
                logVo:logVo,
            };

            const res = await revivirVale(VoRevivir);
            if (res) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    success_dlg: true,
                    dynamic_title: "Exito",
                    confirm_alert_revivir_vale: false,
                    dynamic_description: "Vale Revivido",
                });
                fetchData({pageSize: 10, pageIndex: controlledPageIndex});
                // setValeTablaSeleccionado(null);
                setValesTablaSeleccionado(null);
                // listarValesFiltrados(filtrosVale);
            } else {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_alert_revivir_vale: false,
                    dynamic_title: "Fallo",
                    dynamic_description: "Error al revivir el vale, Inténtalo por favor más tarde.",
                });
            }
        }
    };
     // 
    useEffect(() => {
        listarEstatusVale(true);
        listarAseguradoras();
        listarMedicos();
        listarPacientes();
        listarUsuarios();
        listarTiposVale();

        setcambioEstatusData({
            valeProductoServicio: [],
        });

        if (valeSeleccionado) {
            cleanValeSeleccionado();
        }
        if (proximosEstatusVale) {
            cleanProximosEstatusVale();
        }
    }, []);

    const confirmRedirectReporteReacciones = async () => {
        const valeChecked = await isValeChecked();
        //imprimir la cotizacion
        if (valeChecked) {
            const valeTablaSeleccionado = valesTablaSeleccionado[0];
            const estatusVale = valeTablaSeleccionado.estatusActual
                ? valeTablaSeleccionado.estatusActual.catEstatusvale.estatusVale
                : "";
            if (estatusVale === "POR APLICAR" || estatusVale === "APLICADO" ) {
                props.history.push(`/centromezclas/reacciones/${valeTablaSeleccionado.idVale}`);
            } else {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, seleccione un vale en estatus POR APLICAR o APLICADO",
                });
            }
        }
    };

    const confirmRedirectBitacora = async () => {
        const valeChecked = await isValeChecked();
        //imprimir la cotizacion
        if (valeChecked) {
            const valeTablaSeleccionado = valesTablaSeleccionado[0];
            const estatusVale = valeTablaSeleccionado.estatusActual
                ? valeTablaSeleccionado.estatusActual.catEstatusvale.estatusVale
                : "";
            if (estatusVale === "POR APLICAR" || estatusVale === "APLICADO" || estatusVale === "COBRADO") {
                props.history.push(`/centromezclas/bitacoras/${valeTablaSeleccionado.idVale}`);
            } else {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "Por favor, seleccione un vale en estatus POR APLICAR o APLICADO",
                });
            }
        }
    };

    const checkFechaTrataminto = async () => {
        const valeChecked = await isValeChecked();

        if (valeChecked) {
            const valeTablaSeleccionado = valesTablaSeleccionado[0];
            if(valeTablaSeleccionado.estatusActual ){
                let estatusActual = valeTablaSeleccionado.estatusActual.idCatEstatusVale;
                //4,5,6,14,15,16
                if(estatusActual==3 || estatusActual==4 || estatusActual==5  ||estatusActual==6  ||  estatusActual==13 || estatusActual==14 || estatusActual==15  || estatusActual==16){
                    setValeFechaTratamientoTemporal({...valeFechaTratamientoTemporal, fechaTratamientoOriginal : valeTablaSeleccionado.fechaTratamiento, idVale: valeTablaSeleccionado.idVale})
                    setUISweetAlert({
                        ...UISweetAlert,
                        confirm_alert_cambiar_FechaTratamiento: true,
                    });
                }else{
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        dynamic_title: "Fallo",
                        dynamic_description: "El vale seleccionado no está en estatus: APROBADO, EN RECOLECCIÓN, COMPLETO o POR APLICAR.",
                    });
                }
            }else{
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Fallo",
                    dynamic_description: "El vale seleccionado no tiene un estatus para cambio de fecha de tratamiento",
                });
            }


        } else {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione un vale",
            });
        }
    };

    const checkImprimirCotizacion = async () => {
        const valeChecked = await isValeChecked();

        //imprimir la cotizacion
        if (valeChecked) {
            const valeTablaSeleccionado = valesTablaSeleccionado[0];
            if (valesTablaSeleccionado && valesTablaSeleccionado.idCatTipoVale && valesTablaSeleccionado.idCatTipoVale == 2) {
                if (usuario && usuario.authorities.includes("ROLE_ASEGURADORAS")) {
                    setDatosCotizacion({
                        encabezado: valeTablaSeleccionado && valeTablaSeleccionado.encabezado ? valeTablaSeleccionado.encabezado : "",
                        piePagina: valeTablaSeleccionado && valeTablaSeleccionado.piePagina ? valeTablaSeleccionado.piePagina : "",
                        tratamiento: valeTablaSeleccionado && valeTablaSeleccionado.tratamiento ? valeTablaSeleccionado.tratamiento : "",
                        aplicacion: valeTablaSeleccionado && valeTablaSeleccionado.aplicacion ? valeTablaSeleccionado.aplicacion : "",
                    });
                } else {
                    setDatosCotizacion({
                        encabezado: "",
                        piePagina: "",
                        tratamiento: "",
                        aplicacion: "",                        
                    });
                }
            }
            setUISweetAlert({
                ...UISweetAlert, 
                dynamic_title: "Exito",
                dynamic_description: "El documento se ha mandado a imprimir",                
                confirm_alert_imprimir_cotizacion: true, 
            });
        } else {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: "Por favor, seleccione un vale",
            });
        }
    };

    const onChangeDataValeTratamiento = async (e) => {
        let value = e.target.value;

        setValeFechaTratamientoTemporal({
            ...valeFechaTratamientoTemporal,
            [e.target.name]: value,
        });
    };

    const imprimirCotizacion = async () => {
        const valeTablaSeleccionado = valesTablaSeleccionado[0];
        console.log("🚀 ~ file: index.js:1257 ~ imprimirCotizacion ~ valeTablaSeleccionado:", valeTablaSeleccionado);
        setLoading(true);
        try {
            const valeVo = {
                idVale: valeTablaSeleccionado.idVale,
                encabezado: datosCotizacion.encabezado,
                piePagina: datosCotizacion.piePagina,
                tratamiento: datosCotizacion.tratamiento,
                aplicacion: datosCotizacion.aplicacion,
            };
            const res = await exportValeCotizacion(valeVo);
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                success_dlg: true,
                dynamic_title: "Exito",
                confirm_alert_imprimir_cotizacion: false,
            });
            setDatosCotizacion({
                encabezado: "",
                piePagina: "",
                tratamiento: "",
                aplicacion: "",                
            });

            const res2 = await actualizarOCambiarEstatus({
                idVale: valeTablaSeleccionado.idVale,
                impreso: true,
            });

            fetchData({pageSize: 10, pageIndex: controlledPageIndex});

            return res;
        } catch (error) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                confirm_alert_imprimir_cotizacion: false,
                dynamic_title: "Fallo",
                dynamic_description: error,
            });
        }
    };

    const changeCotizacion = (e) => {
        const {name, value} = e.target;
        setDatosCotizacion({
            ...datosCotizacion,
            [name]: value,
        });
    };

    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3">Listado de Vales</h3>
            <button
                className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
                type="button"
                onClick={toggleSearch}>
                {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
            </button>
            {usuario.authorities.includes("OP_FARMACIA_VALE_CREAR") ? (
                <Link to={`/vales/nuevo`}>
                    <button className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6">Nuevo vale</button>
                </Link>
            ) : null}

            <Card
                hidden={!toggleSearchBy}
                className="mt-10 card"
                style={{
                    padding: "1.5rem 1.5rem 1.5rem",
                }}>
                <form onSubmit={(e) => buscarPorFolio(e)}>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                                <Row
                                    className="form-group"
                                    style={{
                                        marginBottom: "0",
                                    }}>
                                    <Col sm={10} className="flex col-sm-10">
                                        <input
                                            className="form-control mr-2.5"
                                            type="text"
                                            name="folioVale"
                                            placeholder="Escribe tu folio"
                                            onChange={onChangeFolio}
                                            value={filtrosVale.folioVale ? filtrosVale.folioVale : ""}
                                        />
                                        <button
                                            className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                            style={{
                                                marginRight: "2.5rem",
                                                background: "#ff9933",
                                                color: "white",
                                                fontWeight: "bold",
                                            }}
                                            type="submit">
                                            Buscar
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                            {}
                        </Row>
                    </CardBody>
                </form>
            </Card>

            <Card
                hidden={toggleSearchBy}
                className="mt-10 card"
                style={{
                    padding: "1.5rem 1.5rem 1.5rem",
                }}>
                <form onSubmit={(e) => enviarValesFiltrados(e)}>
                    <CardBody>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                                    <Col sm={7}>
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="fechaInicial"
                                            onChange={onChangeFecha}
                                            value={filtrosVale.fechaInicial}
                                        />
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Lista de pacientes</label>
                                    <Col sm={7}>
                                        <SelectPacientesPaginado
                                            opcionSeleccionadaCompleta={pacienteCompleto}
                                            onChangeOptions={onChange}
                                            fetchOptions={loadPacientesSelect}
                                            opcionesPaginado={opcionesSelectPacientes.opcionesPaginado}
                                            placeholderText={opcionesSelectPacientes.placeholderText}
                                            optionLabel={opcionesSelectPacientes.optionLabel}
                                            optionValue={opcionesSelectPacientes.optionValue}
                                            name="paciente"
                                        />
                                        {}
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Aseguradora</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="aseguradora"
                                            id="aseguradora"
                                            onChange={onChange}
                                            value={filtrosVale.aseguradora}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {aseguradoras.map((aseguradora) => {
                                                return (
                                                    <>
                                                        <option value={aseguradora.idCatAseguradora}>{aseguradora.razonSocial}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Estatus Actual</label>
                                    <Col sm={7}>
                                        {}
                                        <select
                                            className="form-control"
                                            name="estatusVale"
                                            id="estatusVale"
                                            onChange={onChange}
                                            value={filtrosVale.estatusVale ? filtrosVale.estatusVale : ""}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>

                                            {estatusvale.map((estatus) => {
                                                return (
                                                    <>
                                                        <option value={estatus.idCatEstatusVale ? estatus.idCatEstatusVale : estatus}>
                                                            {estatus.estatusVale ? estatus.estatusVale : estatus}
                                                        </option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha Final</label>
                                    <Col sm={7}>
                                        <input
                                            className="form-control"
                                            type="date"
                                            // type="datetime-local"
                                            name="fechaFinal"
                                            disabled={!filtrosVale.fechaInicial ? true : false}
                                            min={filtrosVale.fechaInicial}
                                            onChange={onChange}
                                            value={filtrosVale.fechaFinal}
                                        />
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Médico tratante</label>
                                    <Col sm={7}>
                                        <select name="medico" id="medico" className="form-control" onChange={onChange} value={filtrosVale.medico}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {medicos.map((medico) => {
                                                return (
                                                    <>
                                                        <option value={medico.idCatMedico}>{`${medico.prefijo ? medico.prefijo : ""} ${
                                                            medico.catPersona.nombres
                                                        } ${medico.catPersona.apellidoPaterno} ${medico.catPersona.apellidoMaterno}`}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Tipo Vale</label>
                                    <Col sm={7}>
                                        <select
                                            name="tipoVale"
                                            id="tipoVale"
                                            className="form-control"
                                            onChange={onChange}
                                            value={filtrosVale.tipoVale}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {tiposVale.map((tipoVale) => {
                                                return (
                                                    <>
                                                        <option value={tipoVale.idCatTipoVale}>{tipoVale.tipoVale}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Usuario Solicitante</label>
                                    <Col sm={7}>
                                        <select
                                            name="usuarioSolicitante"
                                            id="usuarioSolicitante"
                                            className="form-control"
                                            onChange={onChange}
                                            value={filtrosVale.usuarioSolicitante}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {usuarios.map((usuario) => {
                                                return (
                                                    <>
                                                        <option value={usuario.idCatUsuario}>{usuario.nombre}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                    type="submit"
                                    style={{
                                        marginRight: "2.5rem",
                                        background: "#ff9933",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}>
                                    Aplicar Filtros
                                </button>
                                <button
                                    className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick={limpiarFiltros}
                                    style={{
                                        marginRight: "2.5rem",
                                        background: "#ff9933",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}>
                                    Limpiar Filtros
                                </button>
                            </Col>
                        </Row>
                    </CardBody>
                </form>
            </Card>

            <Row>
                <Col>
                    <Card className="mt-4">
                        <CardBody>
                            <Row>
                                <Col>
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="button"
                                        hidden={
                                            (usuario.authorities.includes("OP_FARMACIA_VALE_CANCELAR")) ||
                                            usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                            usuario.authorities.includes("ROLE_ASEGURADORAS")
                                                ? false
                                                : true
                                        }
                                        onClick={cancelarValeConfirm}
                                        style={{
                                            marginRight: "2.5rem",
                                            marginTop: "1rem",
                                            background: "#ff9933",
                                            color: "white",
                                            fontWeight: "bold",
                                        }}>
                                        Cancelar Vale
                                    </button>
                                    {}
                                    {}
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="button"
                                        hidden={
                                            usuario.authorities.includes("OP_FARMACIA_VALE_DEVOLVER") ||
                                            usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
                                            usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                                            usuario.authorities.includes("ROLE_QUIMIO")
                                                ? false
                                                : true
                                        }
                                        onClick={devolverValeConfirm}
                                        style={{
                                            marginRight: "2.5rem",
                                            marginTop: "1rem",
                                            background: "#ff9933",
                                            color: "white",
                                            fontWeight: "bold",
                                        }}>
                                        Devolver Vale
                                    </button>
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="button"
                                        hidden={
                                            usuario.authorities.includes("ROLE_FARMACIA") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                                                ? false
                                                : true
                                        }
                                        onClick={revivirValeConfirm}
                                        style={{
                                            marginRight: "2.5rem",
                                            background: "#ff9933",
                                            marginTop: "1rem",
                                            color: "white",
                                            fontWeight: "bold",
                                        }}>
                                        Revivir Vale
                                    </button>
                                    {}
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="button"
                                        onClick={checkImprimirVale}
                                        style={{
                                            marginRight: "2.5rem",
                                            background: "#ff9933",
                                            color: "white",
                                            marginTop: "1rem",
                                            fontWeight: "bold",
                                        }}>
                                        Imprimir Vale
                                    </button>
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        hidden={
                                            usuario.authorities.includes("ROLE_RECEPCION") ||
                                            usuario.authorities.includes("ROLE_COMPRAS") ||
                                            usuario.authorities.includes("ROLE_FARMACIA") ||
                                            usuario.authorities.includes("ROLE_ASEGURADORAS") ||
                                            usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                                                ? false
                                                : true
                                        }
                                        style={{
                                            marginRight: "2.5rem",
                                            background: "#ff9933",
                                            color: "white",
                                            marginTop: "1rem",
                                            fontWeight: "bold",
                                        }}
                                        onClick={checkImprimirCotizacion}>
                                        Imprimir Cotización
                                    </button>
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="button"
                                        hidden={
                                            usuario.authorities.includes("ROLE_QUIMIO") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                                                ? false
                                                : true
                                        }
                                        onClick={cambiarConcentracionValeConfirm}
                                        style={{
                                            marginRight: "2.5rem",
                                            background: "#ff9933",
                                            color: "white",
                                            marginTop: "1rem",
                                            fontWeight: "bold",
                                        }}>
                                        Cambio de Concentración
                                    </button>
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="button"
                                        hidden={
                                            usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                                                ? false
                                                : true
                                        }
                                        onClick={confirmRedirectBitacora}
                                        style={{
                                            marginRight: "2.5rem",
                                            background: "#ff9933",
                                            color: "white",
                                            marginTop: "1rem",
                                            fontWeight: "bold",
                                        }}>
                                        Bitácora de Mezclas
                                    </button>
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        type="button"
                                        hidden={
                                            usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") || usuario.authorities.includes("ROLE_ADMIN_SISTEMA")
                                                ? false
                                                : true
                                        }
                                        onClick={confirmRedirectReporteReacciones}
                                        style={{
                                            marginRight: "2.5rem",
                                            background: "#ff9933",
                                            color: "white",
                                            marginTop: "1rem",
                                            fontWeight: "bold",
                                        }}>
                                        Reacciones Adversas
                                    </button>                                    
                                    <button
                                        className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                        hidden={
                                            usuario.authorities.includes("ROLE_RECEPCION") ? false : true
                                        }
                                        style={{
                                            marginRight: "2.5rem",
                                            background: "#ff9933",
                                            color: "white",
                                            marginTop: "1rem",
                                            fontWeight: "bold",
                                        }}
                                        onClick={checkFechaTrataminto}>
                                        Cambiar Fecha Tratamiento
                                    </button>

                                </Col>
                            </Row>
                            <TableComponent
                                pageCount={pageCount}
                                fetchData={fetchData}
                                columns={columns}
                                loading={loadingData}
                                data={valesFiltrados}
                                indexOfPage={controlledPageIndex}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {UISweetAlert.confirm_alert_cambiar_FechaTratamiento ? (
                <SweetAlert
                    title="Cambiar la fecha de tratamiento"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => changeFechaTratamiento()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_cambiar_FechaTratamiento: false,
                        });
                        setValeFechaTratamientoTemporal(valeFechaTratamientoTemporalVo);
                    }}>
                    <div>
                        <Row>
                            <Col>Capture la nueva fecha de tratamiento</Col>
                        </Row>

                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Fecha de tratamiento</label>
                            <Col sm={7}>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={onChangeDataValeTratamiento}
                                    name="fechaTratamiento"
                                    value={valeFechaTratamientoTemporal.fechaTratamiento}
                                />
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_cancelar_vale ? (
                <SweetAlert
                    title="¿Desea cancelar el vale?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => changeEstatusCancelado()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_cancelar_vale: false,
                        });
                        setCancelarDevolverVale(cleanCancelarDevolverVale);
                    }}>
                    <div>
                        <Row>
                            <Col>Llene por favor la siguiente información</Col>
                        </Row>

                        <Row className="form-group mt-2">
                            <label className="col-sm-4 col-form-label">Motivo</label>
                            <Col sm={7}>
                                <textarea
                                    rows={6}
                                    className="form-control"
                                    name="comentarioEstatus"
                                    value={cancelarDevolverVale.comentarioEstatus}
                                    onChange={onChangeCancelarDevolverVale}
                                />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Fecha Cancelación</label>
                            <Col sm={7}>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    name="fechaCancelacion"
                                    value={fechaActual}
                                />
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}
            {UISweetAlert.confirm_alert_imprimir_vale ? (
                <SweetAlert
                    title="¿Desea visualizar los vales o descargarlos?"
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "50%",
                    }}
                    onConfirm={() => imprimirVale()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_imprimir_vale: false,
                        });
                    }}
                    customButtons={
                        <React.Fragment>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                style={{
                                    marginRight: "1rem",
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                                onClick={() => imprimirVale()}>
                                Visualizar
                            </button>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                style={{
                                    marginRight: "1rem",
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                                onClick={() => downloadVales()}>
                                Descargar
                            </button>
                            <button
                                className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                style={{
                                    marginRight: "1rem",
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                                onClick={() => {
                                    setUISweetAlert({
                                        ...UISweetAlert,
                                        confirm_alert_imprimir_vale: false,
                                    });
                                }}>
                                Cerrar
                            </button>
                        </React.Fragment>
                    }>
                    <div></div>
                </SweetAlert>
            ) : null}
            {}

            {UISweetAlert.confirm_alert_devolver_vale ? (
                <SweetAlert
                    title="¿Desea devolver el vale?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onConfirm={() => changeEstatusDevuelto()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_devolver_vale: false,
                        });
                        setCancelarDevolverVale(cleanCancelarDevolverVale);
                    }}>
                    <div>
                        <Row>
                            <Col>Llene por favor la siguiente información</Col>
                        </Row>

                        <Row className="form-group mt-2">
                            <label className="col-sm-4 col-form-label">Motivo</label>
                            <Col sm={7}>
                                <textarea
                                    rows={6}
                                    className="form-control"
                                    name="comentarioEstatus"
                                    value={cancelarDevolverVale.comentarioEstatus}
                                    onChange={onChangeCancelarDevolverVale}/>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <label className="col-sm-4 col-form-label">Fecha Devolución</label>
                            <Col sm={7}>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="fechaDevolucion"
                                    disabled={true}
                                    value={cancelarDevolverVale.fechaDevolucion}
                                />
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_imprimir_cotizacion ? (
                <SweetAlert
                    title="Impresión de la Cotización del Vale"
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    style={{
                        width: width && width < 851 ? "100%" : "60%",
                    }}
                    closeOnClickOutside={false}
                    onConfirm={() => imprimirCotizacion()}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_imprimir_cotizacion: false,
                        });
                    }}>
                    {valesTablaSeleccionado &&
                    valesTablaSeleccionado[0] &&
                    valesTablaSeleccionado[0].idCatTipoVale &&
                    valesTablaSeleccionado[0].idCatTipoVale == 2 &&
                    usuario &&
                    usuario.authorities.includes("ROLE_ASEGURADORAS") ? (
                        <div>
                            <Row>
                                <Col>Llene por favor la siguiente información</Col>
                            </Row>

                            <Row className="form-group mt-4">
                                <label className="col-sm-4 col-form-label">Tratamiento</label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        name="tratamiento"
                                        value={datosCotizacion.tratamiento ? datosCotizacion.tratamiento : ""}
                                        onChange={changeCotizacion}
                                    />
                                </Col>
                            </Row>

                            <Row className="form-group mt-2">
                                <label className="col-sm-4 col-form-label">Aplicación</label>
                                <Col sm={7}>
                                    <input
                                        className="form-control"
                                        name="aplicacion"
                                        value={datosCotizacion.aplicacion ? datosCotizacion.aplicacion : ""}
                                        onChange={changeCotizacion}
                                    />
                                </Col>
                            </Row>

                            <Row className="form-group mt-4">
                                <label className="col-sm-4 col-form-label">Encabezado</label>
                                <Col sm={7}>
                            <textarea
                                rows={4}
                                className="form-control"
                                name="encabezado"
                                value={datosCotizacion.encabezado ? datosCotizacion.encabezado : ""}
                                onChange={changeCotizacion}/>
                                </Col>
                            </Row>

                            <Row className="form-group mt-2">
                                <label className="col-sm-4 col-form-label">Pie de página</label>
                                <Col sm={7}>
                                <textarea
                                    rows={4}
                                    className="form-control"
                                    name="piePagina"
                                    value={datosCotizacion.piePagina ? datosCotizacion.piePagina : ""}
                                    onChange={changeCotizacion}/>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div>
                        <Row>
                            <Col>Presione Continuar para imprimir o Cancelar para regresar</Col>
                        </Row>
                    </div>
                    )}
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_revivir_vale ? (
                <SweetAlert
                    title="Opciones para revivir el vale"
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "50%",
                    }}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_imprimir_vale: false,
                        });
                    }}
                    customButtons={
                        <React.Fragment>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                style={{
                                    marginRight: "1rem",
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                                onClick={() => revivirValeUpload(false)}
                                >
                                Mismo Folio
                            </button>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                style={{
                                    marginRight: "1rem",
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                                onClick={() => revivirValeUpload(true)}>
                                Crear Nuevo Folio
                            </button>

                            <button
                                className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                                style={{
                                    marginRight: "1rem",
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                                onClick={() => {
                                    setUISweetAlert({
                                        ...UISweetAlert,
                                        confirm_alert_revivir_vale: false,
                                    });
                                }}>
                                Cerrar
                            </button>
                        </React.Fragment>
                    }>                    
                    </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_cambiar_concentracion_vale ? (
                <SweetAlert
                    title="¿Desea cambiar la concentración del vale?"
                    warning
                    showCancel
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => cambioDeConcentracion()}
                    onCancel={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_cambiar_concentracion_vale: false,
                        })
                    }></SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}
            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
