import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import FabricantesContext from "../../context/Fabricantes/FabricantesContext";
import ConfiguracionContext from "../../context/Configuracion/ConfiguracionContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import CargaMasivaModal from "../CargaMasivaModal/index";

import formatNumber from "../../utils/index";

import useWindowDimensions from "../../hooks/dimensionsHook";

const Insumos = () => {
  const { width } = useWindowDimensions();
  const filtrosVacios = {
    numIdentificacion: "",
    codigoEAN: "",
    nombreComercial: "",
    proveedor: "",
    laboratorio: "",
  };

  const datosInsumoVacio = {
    numIdentificacion: "",
    codigoEAN: "",
    nombreComercial: "",
    idCatProveedor: "",
    idCatLaboratorio: "",
    precioMostrador: null,
    precioAseguradoras: null,
    principioActivo:"X",
    presentacion:"X",
    concentracion:"X",
  };

  const [datosNuevoInsumo, setDatosNuevoInsumo] = useState(datosInsumoVacio);
  const [insumoSeleccionado, setInsumoSeleccionado] = useState(null);
  const [insumoPreciosActualizados, setInsumoPreciosActualizados] = useState({ preciosAseguradora: [] });
  const [nuevoPrecio, setNuevoPrecio] = useState(null);
  const [insumoSeleccionadoPrecios, setInsumoSeleccionadoPrecios] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [ivaConfig, setIvaConfig] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_insumo: false,
    confirm_nuevo_insumo: false,
    confirm_eliminar_insumo: false,
    confirm_modificar_precios: false,
    confirm_modificar_precios_final: false,
    confirm_carga_masiva: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);

  // const [data, setData] = useState([
  //   {
  //     idCatProducto: "",
  //     numIdentificacion: "",
  //     codigoEAN: "",
  //     nombreComercial: "",
  //     proveedor: { idCatProveedor: "", proveedor: "" },
  //     laboratorio: { idCatLaboratorio: "", laboratorio: "" },
  //     precios: [
  //       {
  //         tipoPrecio: "Precio Publico",
  //         precio: 2300,
  //       },
  //     ],
  //   },
  // ]);
  const fetchIdRef = useRef(0);

  const catProductosContext = useContext(CatProductosContext);
  const proveedoresContext = useContext(ProveedoresContext);
  const fabricantesContext = useContext(FabricantesContext);

  const {
    filtrosProductos,
    productosPaginados,
    setDataProductos,
    setFiltrosProductos,
    uploadNuevoProducto,
    eliminarProducto,
    updateProducto,
    actualizarPreciosProducto,
    listarPreciosProducto,
    preciosProducto,
  } = catProductosContext;

  const { listarProveedores, proveedores } = proveedoresContext;

  const { listarFabricantes, fabricantes } = fabricantesContext;

  const ctxConfiguracion = useContext(ConfiguracionContext);
  const { getIvaConfiguracion } = ctxConfiguracion;

  useEffect(() => {
    const fetchContextData = async () => {
      listarProveedores();
      listarFabricantes();
      const iva = await getIvaConfiguracion();
      setIvaConfig(iva);
    };
    fetchContextData();
  }, []);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setDataProductos({ page, limit, idCatTipoProducto: 1 });

      // Cantidad de datos disponibles en la tabla pacientes de la base de datos
      const count = response.count;

      // setData(response.productos);

      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {}
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
    // setLoadingData(true);
    // fetchAPIData({
    //   page: pageIndex + 1,
    //   limit: pageSize,
    //   byBusquedaFiltros: false,
    // });
  };

  const handdleCheckedInsumo = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setInsumoSeleccionado({
          ...row.original,
          idCatProveedor: row.values["catProveedorVo.idCatProveedor"],
          idCatLaboratorio: row.values["catLaboratoriosVo.idCatLaboratorio"],
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setInsumoSeleccionado(null);
      } else {
        setRowSeleccionada(row.id);
        setInsumoSeleccionado({
          ...row.original,
          idCatProveedor: row.values["catProveedorVo.idCatProveedor"],
          idCatLaboratorio: row.values["catLaboratoriosVo.idCatLaboratorio"],
        });
      }
    },
    [rowSeleccionada]
  );

  // Se definen las columnas para la tabla de react-tabe
  // Se debe usar useMemo para optimización y porque lo pide la librería

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCatProducto",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedInsumo(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Identificación", accessor: "numIdentificacion" },
      { Header: "Código EAN", accessor: "codigoEAN" },
      { Header: "Descripción", accessor: "nombreComercial" },
      { Header: "idProveedor", accessor: "catProveedorVo.idCatProveedor" },
      { Header: "Proveedor", accessor: "catProveedorVo.proveedor" },
      { Header: "idCatFabricante", accessor: "catLaboratoriosVo.idCatLaboratorio" },
      { Header: "Fabricante", accessor: "catLaboratoriosVo.laboratorio" },
      {
        Header: "Precios",
        accessor: "precios",
        Cell: ({ row }) => {
          return autorizado || autorizadoAseguradora ? (
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              onClick={() => handdlePrecios(row.original)}>
              Precios
            </button>
          ) : null;
        },

        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [rowSeleccionada, handdleCheckedInsumo]
  );

  const handdlePrecios = async (insumo) => {
    const response = await listarPreciosProducto(insumo.idCatProducto);

    setInsumoSeleccionadoPrecios({
      ...response,
      numIdentificacion: insumo.numIdentificacion,
      nombreComercial: insumo.nombreComercial,
      principioActivo: insumo.principioActivo,
    });

    setInsumoPreciosActualizados({
      ...response,
      numIdentificacion: insumo.numIdentificacion,
      nombreComercial: insumo.nombreComercial,
      principioActivo: insumo.principioActivo,
    });

    if (response.preciosProductoServicio || response.preciosAseguradora.length > 0) {
      setUISweetAlert({
        confirm_modificar_insumo: false,
        confirm_nuevo_insumo: false,
        confirm_eliminar_insumo: false,
        confirm_modificar_precios: true,
        confirm_carga_masiva: false,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
      });
      return;
    } else {
      setUISweetAlert({
        confirm_modificar_insumo: false,
        confirm_nuevo_insumo: false,
        confirm_eliminar_insumo: false,
        confirm_modificar_precios: false,
        confirm_carga_masiva: true,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
      });
      return;
    }
  };

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  //   const autorizado =
  //     usuario.authorities.includes("OP_CATALOGO_INSUMO_CREAR") ||
  //     usuario.authorities.includes("OP_CATALOGO_INSUMO_MODIFICAR") ||
  //     usuario.authorities.includes("OP_CATALOGO_INSUMO_MODIFICAR_PRECIO") ||
  //     usuario.authorities.includes("OP_CATALOGO_INSUMO_ELIMINAR");

  const autorizado =
    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
    usuario.authorities.includes("ROLE_FARMACIA") ||
    usuario.authorities.includes("ROLE_COMPRAS");

    const editarIdentificacion =  usuario.authorities.includes("ROLE_COMPRAS");
    
    const autorizadoAseguradora = usuario.authorities.includes("ROLE_ASEGURADORAS");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function setDataProductos
     * @param {object} filtrosProductos Filtros enviados al back para filtrar los productos
     * @param {int} page Página a regresar de productos
     * @param {int} limit Número de productos que se van a regresar por llamada al back
     */

    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosProductos(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltrosProductos({
      ...filtrosProductos,
      [name]: value,
    });
  };

  const changeNuevoInsumo = (e) => {
    const { name, value } = e.target;
    datosNuevoInsumo.principioActivo = "X"
    setDatosNuevoInsumo({
      ...datosNuevoInsumo,
      [name]: value,
    });
  };

  const changeModificarInsumo = (e) => {
    const { name, value } = e.target;
    insumoSeleccionado.principioActivo = "X"
    setInsumoSeleccionado({
      ...insumoSeleccionado,
      [name]: value,
    });
  };

  const opcionesProveedor = proveedores.map(({ idCatProveedor, proveedor, ...rest }) => ({
    ...rest,
    id: idCatProveedor,
    nombre: proveedor,
  }));

  const opcionesFabricante = fabricantes.map(({ idCatLaboratorio, laboratorio, ...rest }) => ({
    ...rest,
    id: idCatLaboratorio,
    nombre: laboratorio,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Identificacion",
          type: "text",
          name: "numIdentificacion",
          value: filtrosProductos.numIdentificacion,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Código EAN",
          type: "text",
          name: "codigoEAN",
          value: filtrosProductos.codigoEAN,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Nombre Comercial",
          type: "text",
          name: "nombreComercial",
          value: filtrosProductos.nombreComercial,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "select",
          label: "Proveedor",
          name: "proveedor",
          value: filtrosProductos.proveedor,
          opciones: opcionesProveedor,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Fabricante",
          name: "laboratorio",
          value: filtrosProductos.laboratorio,
          opciones: opcionesFabricante,
          onChange: onChange,
        },
      ],
    },
  ];

  const validarDatosInsumo = (insumo) => {
    let errorNuevoMedicamento = false;
    if (!errorNuevoMedicamento && (insumo.numIdentificacion === "" || insumo.numIdentificacion === null)) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el número de identificación",
      });
    }
    // if (!errorNuevoMedicamento && (insumo.codigoEAN === "" || insumo.codigoEAN === null)) {
    //   errorNuevoMedicamento = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el código EAN",
    //   });
    // }
    if (!errorNuevoMedicamento && (insumo.nombreComercial === "" || insumo.nombreComercial === null)) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la descripción",
      });
    }

    // if (!errorNuevoMedicamento && (insumo.idCatProveedor === "" || insumo.idCatProveedor === null)) {
    //   errorNuevoMedicamento = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el proveedor",
    //   });
    // }
    // if (!errorNuevoMedicamento && (insumo.idCatLaboratorio === "" || insumo.idCatLaboratorio === null)) {
    //   errorNuevoMedicamento = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el fabricante",
    //   });
    // }

    return errorNuevoMedicamento;
  };

  const validarPreciosProducto = (producto) => {
    let errorNuevoMedicamento = false;

    if (!errorNuevoMedicamento && producto.precioMostrador === null) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el precio mostrador y médico del insumo",
      });
    }
    if (!errorNuevoMedicamento && producto.precioAseguradoras === null) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el precio para aseguradoras del insumo",
      });
    }
    return errorNuevoMedicamento;
  };

  const confirmNuevoInsumo = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nuevo_insumo: true,
      });
    }
  };

  const agregarNuevoInsumo = async () => {
    const insumoValido = validarDatosInsumo(datosNuevoInsumo);
    let preciosValidos = true;
    if (!insumoValido) preciosValidos = validarPreciosProducto(datosNuevoInsumo);

    if (!insumoValido && !preciosValidos) {
      setLoading(true);

      const res = await uploadNuevoProducto({ ...datosNuevoInsumo, idCatTipoProducto: 1 });

      if (res.data && res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nuevo_insumo: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nuevo_insumo: false,
          dynamic_description: "Se guardó el nuevo insumo correctamente",
        });
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        // setData([...data, res.data]);
        setDatosNuevoInsumo(datosInsumoVacio);
      }
    }
  };

  // const updateDataOnDeleteInsumo = async (idCatProducto) => {
  //   const updatedData = data.filter((insumo) => insumo.idCatProducto != idCatProducto);
  //   actualizarProductosPaginados(updatedData);
  // };

  const checkEliminarInsumo = () => {
    if (autorizado && insumoSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_insumo: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un insumo",
      });
    }
  };

  const confirmEliminarInsumo = async () => {
    setLoading(true);
    const res = await eliminarProducto(insumoSeleccionado.idCatProducto);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_insumo: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_insumo: false,
        dynamic_description: "Se eliminó el insumo correctamente",
      });
      // updateDataOnDeleteInsumo(insumoSeleccionado.idCatProducto);
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      setInsumoSeleccionado(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarInsumo = () => {
    if ((autorizado || autorizadoAseguradora) && insumoSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_insumo: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un insumo",
      });
    }
  };

  const confirmModicarInsumo = async () => {
    const insumoValido = validarDatosInsumo(insumoSeleccionado);

    if (!insumoValido) {
      setLoading(true);
      const res = await updateProducto(insumoSeleccionado);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_insumo: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_insumo: false,
          dynamic_description: "Se modificó el insumo correctamente",
        });
        // const insumosActualizados = await data.map((insumo) => {
        //   if (res.data.idCatProducto == insumo.idCatProducto) {
        //     return (insumo = res.data);
        //   } else {
        //     return insumo;
        //   }
        // });
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        // setData(insumosActualizados);
        setInsumoSeleccionado(null);
        setRowSeleccionada(null);
      }
    }
  };

  const changeNuevoPrecio = (e) => {
    const { value } = e.target;
    setNuevoPrecio(value);
  };

  const setNuevoPrecioInsumo = (precioActual) => {
    if (nuevoPrecio === null || nuevoPrecio == "") return;

    let aseguradoraModificada = preciosProducto.preciosAseguradora.filter((aseguradora) => {
      return aseguradora.idPrecioAseguradora == precioActual.idPrecioAseguradora;
    });

    aseguradoraModificada = aseguradoraModificada[0];

    aseguradoraModificada = { ...aseguradoraModificada, precioUnitario: nuevoPrecio };

    let arrayPreciosAseguradora = insumoPreciosActualizados.preciosAseguradora;
    arrayPreciosAseguradora = arrayPreciosAseguradora.map((aseguradora) => {
      if (aseguradora.idPrecioAseguradora == aseguradoraModificada.idPrecioAseguradora) {
        aseguradora = aseguradoraModificada;
        return aseguradora;
      } else {
        return aseguradora;
      }
    });

    setInsumoPreciosActualizados({
      ...insumoPreciosActualizados,
      preciosAseguradora: arrayPreciosAseguradora,
    });
    setNuevoPrecio(null);
  };

  const setNuevoPrecioProductoServicio = ({ tipo }) => {
    if (nuevoPrecio === null || nuevoPrecio == "") return;
    const updatedPrecios = {
      ...insumoPreciosActualizados.preciosProductoServicio,
      [tipo]: nuevoPrecio,
    };

    setInsumoPreciosActualizados({ ...insumoPreciosActualizados, preciosProductoServicio: updatedPrecios });

    setNuevoPrecio(null);
  };

  const guardarPreciosInsumo = async () => {
    if (
      insumoPreciosActualizados.preciosAseguradora.length < 1 &&
      insumoPreciosActualizados.preciosProductoServicio === undefined
    )
      return;

    setLoading(true);

    const res = await actualizarPreciosProducto(insumoPreciosActualizados);

    if (res.data && res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_modificar_precios: false,
        confirm_modificar_precios_final: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
      setInsumoSeleccionadoPrecios(null);
      setInsumoPreciosActualizados({ preciosAseguradora: [] });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_modificar_precios: false,
        confirm_modificar_precios_final: false,
        dynamic_description: "Se guardaron los precios correctamente",
      });
      // const productosFiltrados = productosPaginados.map((producto) => {
      //   if (producto.idCatProducto != medicamentoPreciosActualizados.idCatProducto) return producto;
      //   return medicamentoPreciosActualizados;
      // });
      // actualizarProductosPaginados(productosFiltrados);

      setInsumoSeleccionadoPrecios(null);
      setInsumoPreciosActualizados({ preciosAseguradora: [] });
    }
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Catálogo de Insumos</h3>
      {autorizado ? (
        // <Link to={`/carga/precios`}>
        //   <button className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white" type="button">
        //     Carga Masiva de Precios
        //   </button>
        // </Link>
        <button
          onClick={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_carga_masiva: true,
            });
          }}
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button">
          Carga Masiva de Precios
        </button>
      ) : null}
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Insumos</h4>

      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevoInsumo()}>
                      Nuevo Insumo
                    </button>
                  ) : null}
                  {autorizado || autorizadoAseguradora? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarInsumo()}>
                      Modificar Insumo
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarInsumo()}>
                      Eliminar Insumo
                    </button>
                  ) : null}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={productosPaginados}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nuevo_insumo ? (
        <SweetAlert
          title="Nuevo insumo"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            agregarNuevoInsumo();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nuevo_insumo: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">
                    Número de Identificación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numIdentificacion"
                      autoComplete="off"
                      onChange={changeNuevoInsumo}
                      value={datosNuevoInsumo.numIdentificacion ? datosNuevoInsumo.numIdentificacion : ""}
                    />
                    {datosNuevoInsumo.numIdentificacion === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-5 col-form-label">Código EAN</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="codigoEAN"
                      autoComplete="off"
                      onChange={changeNuevoInsumo}
                      value={datosNuevoInsumo.codigoEAN ? datosNuevoInsumo.codigoEAN : ""}
                    />
                    {/* {datosNuevoInsumo.codigoEAN === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>} */}
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Descripción<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombreComercial"
                      autoComplete="off"
                      onChange={changeNuevoInsumo}
                      value={datosNuevoInsumo.nombreComercial ? datosNuevoInsumo.nombreComercial : ""}
                    />
                    {datosNuevoInsumo.nombreComercial === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Principio Activo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="principioActivo"
                      autoComplete="off"
                      disabled = {true}
                      onChange={changeNuevoInsumo}
                      value={datosNuevoInsumo.principioActivo ? datosNuevoInsumo.principioActivo : "X"}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Presentación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="presentacion"
                      autoComplete="off"
                      onChange={changeNuevoInsumo}
                      value={datosNuevoInsumo.presentacion ? datosNuevoInsumo.presentacion : "X"}
                    />
                  </Col>

                                                     
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">

                <label className="col-sm-4 col-form-label">
                    Concentración<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="concentracion"
                      autoComplete="off"
                      onChange={changeNuevoInsumo}
                      value={datosNuevoInsumo.concentracion ? datosNuevoInsumo.concentracion : "X"}
                    />
                  </Col> 

                  <label className="col-sm-4 col-form-label">Proveedor</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatProveedor"
                      onChange={changeNuevoInsumo}
                      value={datosNuevoInsumo.idCatProveedor}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesProveedor.map((proveedor) => {
                        return <option value={proveedor.id}>{proveedor.nombre}</option>;
                      })}
                    </select>
                    {/* {datosNuevoInsumo.idCatProveedor === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )} */}
                  </Col>
                  <label className="col-sm-4 col-form-label">Fabricante</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatLaboratorio"
                      onChange={changeNuevoInsumo}
                      value={datosNuevoInsumo.idCatLaboratorio}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesFabricante.map((fabricante) => {
                        return <option value={fabricante.id}>{fabricante.nombre}</option>;
                      })}
                    </select>
                    {/* {datosNuevoInsumo.idCatLaboratorio === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )} */}
                  </Col>

                  <label className="col-sm-4 col-form-label">
                    Precio Aseguradoras<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="number"
                      className="form-control"
                      name="precioAseguradoras"
                      autoComplete="off"
                      onChange={changeNuevoInsumo}
                      value={datosNuevoInsumo.precioAseguradoras ? datosNuevoInsumo.precioAseguradoras : null}
                    />
                    {datosNuevoInsumo.precioAseguradoras === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-4 col-form-label">
                    Precio Mostrador y Médico<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="number"
                      className="form-control"
                      name="precioMostrador"
                      autoComplete="off"
                      onChange={changeNuevoInsumo}
                      value={datosNuevoInsumo.precioMostrador ? datosNuevoInsumo.precioMostrador : null}
                    />
                    {datosNuevoInsumo.precioMostrador === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                </Row>
                
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label"></label>
                  <Col sm={7}>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDatosNuevoInsumo(datosInsumoVacio)}>
                      Limpiar Datos
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_eliminar_insumo ? (
        <SweetAlert
          title="¿Desea eliminar el insumo seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: width && width < 851 ? "100%" : "50%",
          }}
          onConfirm={() => confirmEliminarInsumo()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_insumo: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_insumo ? (
        <SweetAlert
          title="Modificar Datos del insumo"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          showCancel
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            confirmModicarInsumo();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_insumo: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">
                    Número de Identificación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      disabled={editarIdentificacion? false: true}
                      type="text"
                      className="form-control"
                      name="numIdentificacion"
                      autoComplete="off"
                      onChange={changeModificarInsumo}
                      value={insumoSeleccionado.numIdentificacion ? insumoSeleccionado.numIdentificacion : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Código EAN</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="codigoEAN"
                      autoComplete="off"
                      onChange={changeModificarInsumo}
                      value={insumoSeleccionado.codigoEAN ? insumoSeleccionado.codigoEAN : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Descripción<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombreComercial"
                      autoComplete="off"
                      onChange={changeModificarInsumo}
                      value={insumoSeleccionado.nombreComercial ? insumoSeleccionado.nombreComercial : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Principio Activo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      disabled = {true}
                      name="principioActivo"
                      autoComplete="off"
                      onChange={changeModificarInsumo}
                      value={insumoSeleccionado.principioActivo ? insumoSeleccionado.principioActivo : "X"}
                    />
                  </Col>                  
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">

                <label className="col-sm-4 col-form-label">
                    Presentación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="presentacion"
                      autoComplete="off"
                      onChange={changeModificarInsumo}
                      value={insumoSeleccionado.presentacion ? insumoSeleccionado.presentacion : "X"}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">
                    Concentración<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="concentracion"
                      autoComplete="off"
                      onChange={changeModificarInsumo}
                      value={insumoSeleccionado.concentracion ? insumoSeleccionado.concentracion : "X"}
                    />
                  </Col>                  


                  <label className="col-sm-4 col-form-label">Proveedor</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatProveedor"
                      onChange={changeModificarInsumo}
                      value={insumoSeleccionado.idCatProveedor}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesProveedor.map((proveedor) => {
                        return <option value={proveedor.id}>{proveedor.nombre}</option>;
                      })}
                    </select>
                  </Col>
                  <label className="col-sm-4 col-form-label">Fabricante</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatLaboratorio"
                      onChange={changeModificarInsumo}
                      value={insumoSeleccionado.idCatLaboratorio}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesFabricante.map((fabricante) => {
                        return <option value={fabricante.id}>{fabricante.nombre}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_carga_masiva ? (
        <SweetAlert
          title={"Carga Masiva de Precios"}
          showConfirm
          confirmBtnText="Salir"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_carga_masiva: false,
            });
          }}>
          <CargaMasivaModal disabled={true} idCatTipoProducto={1} />
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_precios ? (
        <SweetAlert
          title={""}
          showCancel
          showConfirm
          confirmBtnText="Guardar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios_final: true,
            });
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios: false,
            });
            setInsumoPreciosActualizados({ preciosAseguradora: [] });
            setNuevoPrecio(null);
          }}>
          {preciosProducto.preciosProductoServicio || preciosProducto.preciosAseguradora.length > 0 ? (
            <>
              <h5 className="h5 mb-4">
                Número de Identificación
                <p className="text-gray-600">{`${insumoSeleccionadoPrecios.numIdentificacion} - ${insumoSeleccionadoPrecios.nombreComercial}`}</p>
                {/* <p className="text-gray-600">{insumoSeleccionadoPrecios.numIdentificacion ? insumoSeleccionadoPrecios.numIdentificacion : ""}</p> */}
              </h5>
              {/* <h5 className="h5 mb-4">
                Principio Activo
                <p className="text-gray-600">{insumoSeleccionadoPrecios.principioActivo ? insumoSeleccionadoPrecios.principioActivo : "X"}</p>
              </h5> */}

              <table className="table table-hover table-centered mb-0">
                <thead>
                  <tr>
                    <th scope="col">Precio Definido para</th>
                    <th scope="col">Precio Actual</th>
                    <th scope="col">IVA</th>
                    <th scope="col">Nuevo Precio</th>
                  </tr>
                </thead>
                <tbody>
                  {preciosProducto.preciosProductoServicio ? (
                    <>
                      <tr key={preciosProducto.preciosProductoServicio.idPrecioProductoServicio}>
                        <td style={{ width: "40%" }}>Precio Máximo al Público</td>
                        <td style={{ width: "25%" }}>
                          <input
                            value={formatNumber(preciosProducto.preciosProductoServicio.precioPublico)}
                            disabled={true}
                            type="text"
                            name="precioPublico"
                          />
                        </td>
                        <td style={{ width: "25%" }}>
                          <input
                            // value={`$${formatoMexico(preciosProducto.preciosProductoServicio.precioPublico * 0.16)}`}
                            value={formatNumber(preciosProducto.preciosProductoServicio.precioPublico * ivaConfig)}
                            disabled={true}
                            type="text"
                            name="iva"
                          />
                        </td>
                        <td>
                          <input
                            disabled={false}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="precioPublico"
                            onChange={(e) => changeNuevoPrecio(e)}
                            onBlur={() => setNuevoPrecioProductoServicio({ tipo: "precioPublico" })}
                          />
                        </td>
                      </tr>
                      <tr key={preciosProducto.preciosProductoServicio.idPrecioProductoServicio}>
                        <td>Precio Mostrador y Médico</td>
                        <td>
                          <input
                            // value={`$${formatoMexico(preciosProducto.preciosProductoServicio.precioMostrador)}`}
                            value={formatNumber(preciosProducto.preciosProductoServicio.precioMostrador)}
                            disabled={true}
                            type="text"
                            name="precioMostrador"
                          />
                        </td>
                        <td>
                          <input
                            // value={`$${formatoMexico(preciosProducto.preciosProductoServicio.precioMostrador * 0.16)}`}
                            value={formatNumber(preciosProducto.preciosProductoServicio.precioMostrador * ivaConfig)}
                            disabled={true}
                            type="text"
                            name="iva"
                          />
                        </td>
                        <td>
                          <input
                            disabled={false}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="precioMostrador"
                            onChange={(e) => changeNuevoPrecio(e)}
                            onBlur={() => setNuevoPrecioProductoServicio({ tipo: "precioMostrador" })}
                          />
                        </td>
                      </tr>
                    </>
                  ) : null}

                  {preciosProducto.preciosAseguradora.length > 0 &&
                    preciosProducto.preciosAseguradora.map((precio, index) => {
                      return (
                        <>
                          <tr key={precio.idPrecioAseguradora}>
                            <td style={{ width: "40%" }}>{precio.aseguradora ? precio.aseguradora : ""}</td>
                            <td style={{ width: "20%" }}>
                              <input
                                // value={`$${formatoMexico(precio.precioUnitario)}`}
                                value={formatNumber(precio.precioUnitario)}
                                disabled={true}
                                type="text"
                                name="precioUnitario"
                              />
                            </td>
                            <td style={{ width: "20%" }}>
                              <input
                                // value={`$${formatoMexico(precio.precioUnitario * 0.16)}`}
                                value={formatNumber(precio.precioUnitario * ivaConfig)}
                                disabled={true}
                                type="text"
                                name="iva"
                              />
                            </td>
                            <td style={{ width: "20%" }}>
                              <input
                                disabled={false}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                name="precio"
                                onChange={(e) => changeNuevoPrecio(e)}
                                onBlur={() => setNuevoPrecioInsumo(precio)}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </>
          ) : (
            // <Link to={`/carga/precios`}>
            //   <button className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white" type="button">
            //     Carga Masiva de Precios
            //   </button>
            // </Link>
            <button
              onClick={() => {
                setUISweetAlert({
                  ...UISweetAlert,
                  confirm_carga_masiva: true,
                });
              }}
              className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
              type="button">
              Carga Masiva de Precios
            </button>
          )}
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_precios_final ? (
        <SweetAlert
          title={"¿Desea modificar los precios del insumo?"}
          showConfirm
          showCancel
          confirmBtnText="SI"
          confirmBtnBsStyle="success"
          cancelBtnText="NO"
          cancelBtnBsStyle="danger"
          style={{
            width: width && width < 851 ? "100%" : "50%",
          }}
          onConfirm={() => guardarPreciosInsumo()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios_final: false,
            });
          }}></SweetAlert>
      ) : null}
      
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default Insumos;
