import React, { useContext, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TabGrafica from "./TabGrafica";
import EstudiosLaboratorioContext from "../../../context/EstudiosLaboratorio/EstudiosLaboratorioContext";
import PacientesContext from "../../../context/Pacientes/PacientesContext";

export default function TabMarcadores({ props }) {
  const pacientesContext = useContext(PacientesContext);
  const { idCatPacienteSeleccionado } = pacientesContext;

  const ctxEstLab = useContext(EstudiosLaboratorioContext);
  const { listarMarcadores, marcadores } = ctxEstLab;

  const [tabIndex, setTabIndex] = useState(0);
  // const [listado, setListado] = useState(false);

  useEffect(() => {
    listarMarcadores(idCatPacienteSeleccionado);
    // if (!listado) {

    //   setListado(true);
    // }
  }, []);

  return (
    <div className="mt-4 container-fluid">
      <Row>
        <Col>
          <h4>Marcadores</h4>
        </Col>
      </Row>

      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {marcadores.map((marcador) => {
            return <Tab>{marcador.marcador}</Tab>;
          })}
        </TabList>
        {marcadores.map((marcador) => {
          return (
            <TabPanel>
              <TabGrafica props={props} marcador={marcador} />
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
}
