import React,{ useEffect,useContext } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { NumericFormat } from "react-number-format";

// import formatDateWithoutHour from "../../../utils/formatDateWithoutHour";

import SelectAsyncPaginate from "../../SelectAsyncPaginate";
import AuthContext from "../../../context/Auth/AuthContext"
import { opcionesSelectProductos } from "../../../config/configOpcionesSelect";
import AlmacenesContext from "../../../context/Almacenes/AlmacenesContext";
import SpanError from "../../UI/spanError";

import { maxCampoPiezas } from "../../../config/variables";

export default function AltaProductoInventario(props) {

  const authContext = useContext(AuthContext);
  const {usuario} = authContext;

  const almacenesCtx = useContext(AlmacenesContext);
  const {almacenes, listarAlmacenes} = almacenesCtx;

  const administrador =
    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
    usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
    usuario.authorities.includes("ROLE_COMPRAS") ||
    usuario.authorities.includes("ROLE_DIRECCION");  
  
  const AlmacenCMZ = usuario.authorities.includes("ROLE_ADMIN_ALMACEN");

  useEffect(() => {
    listarAlmacenes(false,administrador);
  }, []);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col xl={6} lg={12} md={12}>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">
                Producto <SpanError>*</SpanError>
              </label>
              <Col sm={9} className="text-left">
                <SelectAsyncPaginate
                  opcionSeleccionadaCompleta={props.productoCompletoSeleccionado}
                  onChangeOptions={props.onChangeProducto}
                  fetchOptions={props.loadProductosSelect}
                  opcionesPaginado={opcionesSelectProductos.opcionesPaginado}
                  placeholderText={opcionesSelectProductos.placeholderText}
                  optionLabel={opcionesSelectProductos.optionLabel}
                  optionValue={opcionesSelectProductos.optionValue}
                />
                {props.inventario.idCatProducto == null || props.inventario.idCatProducto == "" ? <SpanError /> : null}
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">Tipo de Producto</label>
              <Col sm={9}>
                <select
                  disabled={true}
                  className="form-control"
                  name="idCatTipoProducto"
                  onChange={props.onChange}
                  value={props.inventario.idCatTipoProducto ? props.inventario.idCatTipoProducto : ""}>
                  <option selected value=""></option>
                  {props.tiposProductos &&
                    props.tiposProductos.map((tipoProducto) => {
                      return (
                        <>
                          <option key={tipoProducto.idCatTipoProducto} value={tipoProducto.idCatTipoProducto}>
                            {tipoProducto.tipoProducto}
                          </option>
                        </>
                      );
                    })}
                </select>
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">Identificación</label>
              <Col sm={9}>
                <input
                  type="text"
                  disabled={true}
                  onChange={props.onChange}
                  className="form-control"
                  value={props.inventario.numIdentificacion ? props.inventario.numIdentificacion : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">Código EAN</label>
              <Col sm={9}>
                <input
                  disabled={true}
                  onChange={props.onChange}
                  type="text"
                  className="form-control"
                  value={props.inventario.codigoEAN ? props.inventario.codigoEAN : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">Principio Activo</label>
              <Col sm={9}>
                <input
                  type="text"
                  disabled={true}
                  onChange={props.onChange}
                  className="form-control"
                  value={props.inventario.principioActivo ? props.inventario.principioActivo : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">Proveedor</label>
              <Col sm={9}>
                <input
                  type="text"
                  disabled={true}
                  onChange={props.onChange}
                  className="form-control"
                  value={props.inventario.proveedor ? props.inventario.proveedor : ""}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={6} lg={12} md={12}>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">Fabricante</label>
              <Col sm={9}>
                <input
                  type="text"
                  disabled={true}
                  onChange={props.onChange}
                  className="form-control"
                  value={props.inventario.fabricante ? props.inventario.fabricante : ""}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">
                Almacén <SpanError>*</SpanError>
              </label>
              <Col sm={9}>
                <select
                  placeholder="Seleccione un almacén"
                  className="form-control"
                  name="idCatAlmacen"
                  onChange={props.onChange}
                  value={props.inventario.idCatAlmacen ? props.inventario.idCatAlmacen : ""}>
                  <option selected value="">
                    Seleccione un almacén
                  </option>
                  {props.almacenes &&
                    props.almacenes.map((almacen) => {
                      let AlmacenTemp = almacen.almacen; //TRAEMOS EL NOMBRE DEL ALMACEN
                      let IdResponsableTemp; // VARIABLE DONDE GUARDAREMOS EL ID DEL USUARIO RESPONSABLE DEL ALMACEN SELECCIONADO
                      let idCatUsuarioTemp; //VARIABLE DONDE GUADAREMOS EL ID DEL USUARIO LOGUEADO ANTE EL PORTAL
              
                     //TRAEMOS EL ID DEL USUARIO QUE ESTA LOGUEADO ANTE EL SISTEMA
                      idCatUsuarioTemp = usuario.idCatUsuario;
              
                       // YA QUE SABEMOS EL NOMBRE DEL ALMACEN VAMOS A TRAER EL ID DEL ALMACEN Y SU RESPONSABLE
                       almacenes.map((almacen2) => 
                      {
                          if( almacen2.almacen.toLowerCase() == AlmacenTemp.toLowerCase() ) //COMPARA EL NOMBRE SELECCIONADO CON LA LISTA DE ALMACENES
                          {
                              IdResponsableTemp = almacen2.idResponsable;// LO ENCONTRO Y ASIGNA EL ID DEL RESPONSABLE
                          }
                      })

                      if( ( !administrador && AlmacenCMZ ) && IdResponsableTemp == idCatUsuarioTemp ){
                        return (
                            <>
                              <option key={almacen.idCatAlmacen} value={almacen.idCatAlmacen}>
                                {almacen.almacen}
                              </option>
                            </>
                        );
                        }else
                        {
                           if(administrador)
                           {
                              return (
                                <>
                                  <option key={almacen.idCatAlmacen} value={almacen.idCatAlmacen}>
                                    {almacen.almacen}
                                  </option>
                                </>
                              );
                           }
                        }
                    })}
                </select>
                {props.inventario.idCatAlmacen == null || props.inventario.idCatAlmacen == "" ? <SpanError /> : null}
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">
                Lote <SpanError>*</SpanError>
              </label>
              <Col sm={9}>
                <input
                  type="text"
                  name="lote"
                  className="form-control"
                  onChange={props.onChange}
                  value={props.inventario.lote ? props.inventario.lote : ""}
                />
                {props.inventario.lote == null || props.inventario.lote == "" ? <SpanError /> : null}
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">
                Precio de Compra <SpanError>*</SpanError>
              </label>
              <Col sm={9}>
                <NumericFormat
                  disabled={props.inventario.idCatProducto == null || props.inventario.idCatProducto == ""}
                  id="precioCompra"
                  value={props.inventario.precioCompra}
                  className="form-control"
                  thousandSeparator={true}
                  prefix="$"
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  onValueChange={(values) => {
                    props.onChange({
                      target: {
                        value: values.value,
                        name: "precioCompra",
                      },
                    });
                  }}
                />
                {props.inventario.precioCompra == null || props.inventario.precioCompra == "" ? <SpanError /> : null}
                {/* <input
                  type="text"
                  name="precioCompra"
                  className="form-control"
                  onChange={props.onChange}
                  value={props.inventario.precioCompra ? props.inventario.precioCompra : ""}
                /> */}
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">IVA </label>
              <Col sm={9}>
                <NumericFormat
                  disabled={true}
                  id="precioCompra"
                  className="form-control"
                  thousandSeparator={true}
                  prefix="$"
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  value={props.inventario.iva ? props.inventario.iva : "$0"}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">
                Caducidad <SpanError>*</SpanError>
              </label>
              <Col sm={9}>
                <input
                  type="date"
                  name="caducidad"
                  onChange={props.onChange}
                  className="form-control"
                  value={props.inventario.caducidad ? props.inventario.caducidad : ""}
                />
                {props.inventario.caducidad == null || props.inventario.caducidad == "" ? <SpanError /> : null}
              </Col>
            </Row>
            <Row className="form-group">
              <label className="col-sm-3 col-form-label">
                Piezas Totales <SpanError>*</SpanError>
              </label>
              <Col sm={9}>
                <input
                  type="number"
                  name="piezas"
                  minLength={0}
                  maxLength={maxCampoPiezas}
                  onChange={props.onChange}
                  className="form-control"
                  value={props.inventario.piezas ? props.inventario.piezas : ""}
                />
                {props.inventario.piezas == null || props.inventario.piezas == "" ? <SpanError /> : null}
                {props.inventario.piezas != null && props.inventario.piezas != "" && props.inventario.piezas <= 0 ? (
                  <SpanError>El número de piezas debe ser mayor a 0</SpanError>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
