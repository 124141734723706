import React, { useReducer } from "react";

import QuimioContext from "./QuimioContext";

import QuimioReducer from "./QuimioReducer";

import { SET_NOTAS_ENFERMERIA_PAGINADOS, SET_NOTAS_EVOLUCION } from "../../types";

import clienteAxios from "../../config/axios";

export default function QuimioState(props) {
  const initialState = {
    notasEnfermeriaPaginadas: [],
    notasEvolucionPaginadas: [],
  };

  const [state, dispatch] = useReducer(QuimioReducer, initialState);

  const setDataNotaEnfermeria = async ({ page, limit, idExpedienteMedico }) => {
    try {
      const response = await clienteAxios.post(
        "/enfermeria/notas/paginado",
        {
          pageSize: limit,
          pageIndex: page,
          idExpedienteMedico: idExpedienteMedico,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      dispatch({
        type: SET_NOTAS_ENFERMERIA_PAGINADOS,
        payload: response.data.notasEnfermeria,
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };
  const setNotasEvolucion = async ({ page, limit, idExpedienteMedico }) => {
    try {
      const response = await clienteAxios.post(
        "/evolucion/notas/paginado",
        {
          pageSize: limit,
          pageIndex: page,
          idExpedienteMedico: idExpedienteMedico,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      dispatch({
        type: SET_NOTAS_EVOLUCION,
        payload: response.data.notasEvolucion,
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const uploadDocumentoFirmado = async (datosImpresion) => {
    try {
      const response = await clienteAxios.post(
        "/enfermeria/agregar/nota",
        {
          ...datosImpresion,
          archivosEnfermeria: [
            {
              inputStream: datosImpresion.inputStream,
              mimeType: datosImpresion.mimeType,
              nombreArchivo: datosImpresion.nombreArchivo,
              tamano: datosImpresion.tamano,
            },
          ],
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      // dispatch({
      //   type: SET_NOTAS_ENFERMERIA_PAGINADOS,
      //   payload: response.data.notasEnfermeria,
      // });
      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const uploadNuevaNotaEvolucion = async (evolucionVo) => {
    console.log("🚀 ~ file: QuimioState.js:127 ~ uploadNuevaNotaEvolucion ~ evolucionVo:", evolucionVo);
    try {
      const response = await clienteAxios.post("/evolucion/agregar/nota", evolucionVo, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      // dispatch({
      //   type: SET_NOTAS_ENFERMERIA_PAGINADOS,
      //   payload: response.data.notasEnfermeria,
      // });
      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }
      return error.response;
    }
  };

  const eliminarNota = async ({ idEnfermeria }) => {
    try {
      const response = await clienteAxios.post(
        "/enfermeria/eliminar/",
        { idEnfermeria: idEnfermeria },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarNotaEvolucion = async ({ idEvolucion }) => {
    try {
      const response = await clienteAxios.post(
        "/evolucion/eliminar/",
        { idEvolucion: idEvolucion },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <QuimioContext.Provider
      value={{
        notasEnfermeriaPaginadas: state.notasEnfermeriaPaginadas,
        notasEvolucionPaginadas: state.notasEvolucionPaginadas,
        setDataNotaEnfermeria,
        uploadDocumentoFirmado,
        setNotasEvolucion,
        eliminarNota,
        eliminarNotaEvolucion,
        uploadNuevaNotaEvolucion,
      }}>
      {props.children}
    </QuimioContext.Provider>
  );
}
