import { LISTAR_ASEGURADORAS, FILTRO_ASEGURADORAS, SET_ASEGURADORAS_PAGINADAS, SET_ASEGURADORA } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case LISTAR_ASEGURADORAS:
      return {
        ...state,
        aseguradoras: action.payload,
      };
    case FILTRO_ASEGURADORAS:
      return {
        ...state,
        filtrosAseguradoras: action.payload,
      };
    case SET_ASEGURADORAS_PAGINADAS:
      return {
        ...state,
        aseguradorasPaginadas: action.payload,
      };
    case SET_ASEGURADORA:
      return {
        ...state,
        aseguradora: action.payload,
      };
    default:
      return state;
  }
};
