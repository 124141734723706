import {
    LISTAR_ORDENESCOMPRA,
    LISTAR_DEVOLUCIONESCANCELACIONES,
    SET_FILTROS_RECEPCIONGENERICA,
    SET_RECEPCIONGENERICAPRODUCTO,
    FILTRO_DEVOLUCIONCANCELACION_VENTA,
    RECEPCIONPRODUCTOS_VENTA_SELECCIONADA,
    ERROR_RECEPCIONPRODUCTOS_VENTA_SELECCIONADA,
    ERROR_UPLOAD_RECEPCCIONPRODUCTOS_VENTA_SELECCIONADA
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case LISTAR_ORDENESCOMPRA:
            return{
                ...state,
                recepcionOrdenesCompra: action.payload
            };
        case LISTAR_DEVOLUCIONESCANCELACIONES:
            return{
                ...state,
                recepcionDevolucionesOCancelaciones: action.payload
            };
        case SET_FILTROS_RECEPCIONGENERICA:
            return{
                ...state,
                recepcionGenericaProductoFiltro: action.payload
            };
        case SET_RECEPCIONGENERICAPRODUCTO:
            return{
                ...state,
                recepcionGenericaProducto: action.payload
            };
        case FILTRO_DEVOLUCIONCANCELACION_VENTA:
            return{
                ...state,
                filtroDevolucionCancelacionVenta: action.payload
            }
        case RECEPCIONPRODUCTOS_VENTA_SELECCIONADA:
            return{
                ...state,
                recepcionVentaProductosSeleccionada: action.payload,
                errorRecepcionVentaProducto: false,
            }
        case ERROR_RECEPCIONPRODUCTOS_VENTA_SELECCIONADA:
            return{
                ...state,
                recepcionVentaProductosSeleccionada: {},
                errorRecepcionVentaProducto: true,
            }
        case ERROR_UPLOAD_RECEPCCIONPRODUCTOS_VENTA_SELECCIONADA: 
            return{
                ...state
            }
        default:
            return state
    }
        
}