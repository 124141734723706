import React, {useState, useEffect, useCallback, useMemo, useRef, useContext} from "react";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import TableComponent from "../TablaPaginadaSinUpdate/index";

import "antd/dist/antd.min.css";
import {Cascader} from "antd";

import CatTipoProductoContext from "../../context/CatTipoProducto/CatTipoProductoContext";
import InventarioContext from "../../context/Inventario/InventarioContext";
import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import CatLaboratoriosContext from "../../context/CatLaboratorios/CatLaboratoriosContext";
import AuthContext from "../../context/Auth/AuthContext";
import ConfiguracionContext from "../../context/Configuracion/ConfiguracionContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";

import useWindowDimensions from "../../hooks/dimensionsHook";

import formatDateWithoutHour from "../../utils/formatDateWithoutHour";

import AltaProductoInventario from "../InformacionGeneralProductoInventario/AltaInventario";

import {NumericFormat} from "react-number-format";
import {maxCampoPiezas} from "../../config/variables";

export default function ListaProductosInventario(props) {
    const {width} = useWindowDimensions();

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_ubicaciones: false,
        confirm_alert_alta_inventario: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
    const [rowSeleccionada, setRowSeleccionada] = useState(null);
    const fetchIdRef = useRef(0);

    const [ivaConfig, setIvaConfig] = useState(null);

    const [opcionesUbicacion, setOpcionesUbicaciones] = useState([]);
    const [productoSeleccionado, setProductoSeleccionado] = useState({});

    const [productoCompletoSeleccionado, setProductoCompletoSeleccionado] = useState(null);
    const[productosFiltrados,setProductosFiltrados] = useState([])
    // eslint-disable-next-line

    const nuevoInventarioVacio = {
        idCatTipoProducto: null,
        idCatProducto: null,
        numIdentificacion: null,
        codigoEAN: null,
        descripcion: null,
        principioActivo: null,
        proveedor: null,
        fabricante: null,
        precioCompra: null,
        iva: null,
        lote: null,
        caducidad: null,
        piezas: null,
        idCatAlmacen: null,
    };

    const [nuevoInventario, setNuevoInventario] = useState(nuevoInventarioVacio);

    const catProductosContext = useContext(CatProductosContext);
    const {loadProductosSelect} = catProductosContext;

    const tipoProductoCtx = useContext(CatTipoProductoContext);
    const {tiposProductos, listarProductoTiposProductos} = tipoProductoCtx;

    const almacenesCtx = useContext(AlmacenesContext);
    const {almacenes, listarAlmacenes} = almacenesCtx;

    const provCtx = useContext(ProveedoresContext);
    const {proveedores, listarProveedores} = provCtx;

    const catLabCtx = useContext(CatLaboratoriosContext);
    const {catLaboratorios, listarLaboratorios} = catLabCtx;

    const inventarioCtx = useContext(InventarioContext);
    const {
        productosInventario,
        obtenerProductosInventario,
        filtrosProductosInventario,
        setFiltrosProductosIventario,
        cleanInventarioSeleccionado,
        crearInventario,
        exportListaProductosInventario,
    } = inventarioCtx;

    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    const ctxConfiguracion = useContext(ConfiguracionContext);
    const {getIvaConfiguracion} = ctxConfiguracion;

    const administrador =
        usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
        usuario.authorities.includes("ROLE_ADMINISTRADOR") ||
        usuario.authorities.includes("ROLE_DIRECCION");  
    
    const AlmacenCMZ = usuario.authorities.includes("ROLE_ADMIN_ALMACEN");

    const autorizado =
        usuario && (usuario.authorities.includes("ROLE_ALMACEN") || 
            usuario.authorities.includes("ROLE_ADMIN_SISTEMA"));

    const autorizadoAltaInventario =
        usuario &&
        (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
            usuario.authorities.includes("ROLE_ADMIN_ALMACEN") ||
            usuario.authorities.includes("ROLE_COMPRAS"));

    const autorizadoExcel =
            usuario &&
            (usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
                usuario.authorities.includes("ROLE_ADMIN_ALMACEN") ||
                usuario.authorities.includes("ROLE_FARMACIA") ||
                usuario.authorities.includes("ROLE_COMPRAS"));            

    const buscarPorFiltros = async (e) => {
        e.preventDefault();
        // obtenerProductosInventario();
        fetchData({pageSize: 10, pageIndex: 0});
    };

    const onChange = (e) => {
        setFiltrosProductosIventario({
            ...filtrosProductosInventario,
            [e.target.name]: e.target.value,
        });

        if (e.target.name == "idCatAlmacenUbicacion") {
            obtenerUbicacionAlmacen(e.target.value);

            setFiltrosProductosIventario({
                ...filtrosProductosInventario,
                idCatAnaquel: null,
                idCatEstante: null,
                idCatUbicacion: null,
                [e.target.name]: e.target.value,
            });
        } else {
            setFiltrosProductosIventario({
                ...filtrosProductosInventario,
                [e.target.name]: e.target.value,
            });
        }
    };

    const limpiarFiltros = () => {
        setFiltrosProductosIventario({
            idCatAlmacen: "",
            idCatAlmacenUbicacion: "",
            idCatAnaquel: null,
            idCatEstante: null,
            idCatUbicacion: null,
            idCatTipoProducto: "",
            numIdentificacion: "",
            codigoEAN: "",
            nombreComercial: "",
            principioActivo: "",
            idCatproveedor: "",
            idCatLaboratorio: "",
            lote: "",
        });
    };

    const onChangeCascada = (cascada) => {
        if (cascada) {
            filtrosProductosInventario.idCatAnaquel = cascada[0];
            filtrosProductosInventario.idCatEstante = cascada[1];
            filtrosProductosInventario.idCatUbicacion = cascada[2];
            setFiltrosProductosIventario(filtrosProductosInventario);
        } else {
            filtrosProductosInventario.idCatAnaquel = null;
            filtrosProductosInventario.idCatEstante = null;
            filtrosProductosInventario.idCatUbicacion = null;
            setFiltrosProductosIventario(filtrosProductosInventario);
        }

    };

    const obtenerUbicacionAlmacen = async (idCatAlmacen) => {
        if (idCatAlmacen == "") {
            setOpcionesUbicaciones([]);
        } else {
            let opcionesUbc = [];

            const almacen = almacenes.filter((almc) => almc.idCatAlmacen == idCatAlmacen)[0];

            if (almacen.anaqueles && almacen.anaqueles.length > 0) {
                almacen.anaqueles.map((anaquel) => {

                    let opcAnaquel = {
                        value: anaquel.idCatAnaquel,
                        label: anaquel.anaquel,
                        children: [],
                    };
                    if (anaquel.estantes && anaquel.estantes.length > 0) {
                        anaquel.estantes.map((estante) => {

                            let opcEstante = {
                                value: estante.idCatEstante,
                                label: estante.estante,
                                children: [],
                            };
                            if (estante.ubicaciones && estante.ubicaciones.length > 0) {
                                estante.ubicaciones.map((ubicacion) => {

                                    let opcUbicacion = {
                                        value: ubicacion.idCatUbicacion,
                                        label: ubicacion.ubicacion,
                                    };
                                    opcEstante.children = opcEstante.children.concat(opcUbicacion);
                                });
                            }
                            opcAnaquel.children = opcAnaquel.children.concat(opcEstante);
                        });
                    }
                    opcionesUbc = [...opcionesUbc, opcAnaquel];
                });
            }
            setOpcionesUbicaciones(opcionesUbc);
        }
    };
     
    const handdleCheckedProductoInventario = useCallback(
        (row) => {
            if (rowSeleccionada === null) {
                setRowSeleccionada(row.id);
                setProductoSeleccionado(row.values);
            } else if (rowSeleccionada === row.id) {
                setRowSeleccionada(null);
                setProductoSeleccionado(null);
            }
        },
        [rowSeleccionada]
    );

    const fetchData = async ({pageSize, pageIndex}) => {
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: false,
            });
        }
    };

    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);
            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);
            }
            const response = await obtenerProductosInventario({page, limit});

            const count = response.data.count;
            setRowSeleccionada(null);

            if (count % limit > 0) {
                setPageCount(Math.ceil(count / limit));
            } else {
                setPageCount(count / limit);
            }

            setLoadingData(false);
        } catch (e) {
            console.log("Error while fetching", e);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "idInventario",
                Cell: ({row}) => {
                    return (
                        <input
                            disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
                            checked={rowSeleccionada === row.id ? true : false}
                            type="checkbox"
                            onChange={() => handdleCheckedProductoInventario(row)}
                        />
                    );
                },
                show: true,
                disableFilters: true,
                disableSortBy: true,
            },
            {Header: "Tipo", accessor: "tipoProducto", show: true},
            {Header: "Identificación", accessor: "numIdentificacion", show: true},
            {Header: "Código EAN", accessor: "codigoEAN", show: true},
            {Header: "Descripción", accessor: "nombreComercial", show: true},
            {Header: "Principio Activo", accessor: "principioActivo", show: true},
            {Header: "Lote", accessor: "lote", show: true},
            {Header: "Almacén", accessor: "almacen", show: true},
            {Header: "Proveedor", accessor: "proveedor", show: true}, 
            {Header: "Fabricante", accessor: "laboratorio", show: true},
            {Header: "Precio Compra",
                accessor: "precioEntrada",
                Cell: ({row}) => {
                    return (
                        <NumericFormat
                            disabled={true}
                            id="precio"
                            thousandSeparator={true}
                            prefix="$"
                            decimalScale={2}
                            allowNegative={false}
                            allowLeadingZeros={false}
                            displayType="text"
                            value={row.original.precio ? row.original.precio : "$0"}
                        />
                    );
                },
                show: true,
            },            
            {Header: "Caducidad",
                Cell: ({row}) => {
                    return <>{row.original.caducidad ? formatDateWithoutHour(row.original.caducidad) : ""}</>;
                },
                show: true,
            },
            {Header: "IVA",
                accessor: "iva",
                Cell: ({row}) => {
                    // return `$${(row.values.iva).toFixed(2)}`;
                    return (
                        <NumericFormat
                            disabled={true}
                            id="iva"
                            thousandSeparator={true}
                            prefix="$"
                            decimalScale={2}
                            allowNegative={false}
                            allowLeadingZeros={false}
                            displayType="text"
                            value={row.original.iva ? row.original.iva : "$0"}
                        />
                    );
                },
                show: true,
            },
            {Header: "Piezas", accessor: "piezas", show: true},
            {Header: "Ubicación",
                accessor: "ubicaciones",
                Cell: ({row}) =>
                    row.original.ubicaciones == null || row.original.ubicaciones.length < 1 ? (
                        "Sin Ubicación"
                    ) : (
                        <button
                            hidden={row.original.ubicaciones == null || row.original.ubicaciones.length < 1}
                            onClick={() => {
                                setProductoSeleccionado(row.original);
                                setUISweetAlert({
                                    ...UISweetAlert,
                                    confirm_alert_ubicaciones: true,
                                });
                            }}
                            className="btn btn-primary-orange-umo w-md waves-effect waves-light">
                            Ubicaciones
                        </button>
                    ),
            }, 
        ],
        [rowSeleccionada, handdleCheckedProductoInventario]
    );
    //******************************************************************************************************************************* */
    //****************************       OPCION PARA ASIGNAR UBICACION DE PRODUCTO                          ***************************
    const asignarUbiacion = async () => {
        if (productoSeleccionado == null || Object.keys(productoSeleccionado).length === 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione un producto para asignarle ubicación",
            });
            return;
        }
        let AlmacenTemp = productoSeleccionado.almacen; //TRAEMOS EL NOMBRE DEL ALMACEN
        let IdResponsableTemp; // VARIABLE DONDE GUARDAREMOS EL ID DEL USUARIO RESPONSABLE DEL ALMACEN SELECCIONADO
        let idCatUsuarioTemp; //VARIABLE DONDE GUADAREMOS EL ID DEL USUARIO LOGUEADO ANTE EL PORTAL

       //TRAEMOS EL ID DEL USUARIO QUE ESTA LOGUEADO ANTE EL SISTEMA
        idCatUsuarioTemp = usuario.idCatUsuario;

         // YA QUE SABEMOS EL NOMBRE DEL ALMACEN VAMOS A TRAER EL ID DEL ALMACEN Y SU RESPONSABLE
        almacenes.map((almacen) => 
        {
            if( almacen.almacen.toLowerCase() == AlmacenTemp.toLowerCase() ) //COMPARA EL NOMBRE SELECCIONADO CON LA LISTA DE ALMACENES
            {
                IdResponsableTemp = almacen.idResponsable;// LO ENCONTRO Y ASIGNA EL ID DEL RESPONSABLE
            }
        })
        //PARA ESTE MOMENTO YA SABEMOS EL ID DEL RESPOMSABLE DEL ALMACEN SELECCIONADO Y EL ID DEL USUARIO LOGUEADO
        // POR LO CUAL PROCEDEMOS A VERIFICAR SI PUEDE REALIZAR EL PROCESO DE MANDARLO A LA SECCION DE BORRADO
        if ((IdResponsableTemp==idCatUsuarioTemp) || administrador){
            props.history.push(`/inventario/asignar/ubicacion/${productoSeleccionado.idInventario}`);
        }else{
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Atención",
                dynamic_description: "No cuenta con los privilegios para asignarle ubicación a este producto",
                
            });
        }
    };
    //******************************************************************************************************************************* */
    //****************************       OPCION PARA BOTON DE TRASLADO DE PRODUCTO                          ***************************
    const trasladoProductoInventario = async () => {
        if (productoSeleccionado == null || Object.keys(productoSeleccionado).length === 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione un producto para realizar el traslado",
            });
            return;
        }

    //    if (AlmacenCMZ && productoSeleccionado.almacen!="ALMACEN CMZ"){
    //        setUISweetAlert({
    //            ...UISweetAlert,
    //            error_dlg: true,
    //            dynamic_title: "Atención",
    //            dynamic_description: "No cuenta con los privilegios para realizar transferencia de este almacén",
    //        });
    //        return;
    //    }

    //    if (!administrador && !AlmacenCMZ){
    //       setUISweetAlert({
    //            ...UISweetAlert,
    //            error_dlg: true,
    //            dynamic_title: "Atención",
    //            dynamic_description: "No cuenta con los privilegios para realizar transferencia de este almacén",
    //        });
    //        return;
    //  }else{
    //       props.history.push(`/inventario/traslado/${productoSeleccionado.idInventario}`);
    //    }

        props.history.push(`/inventario/traslado/${productoSeleccionado.idInventario}`);
    };

    const bajaInventario = async () => {
        if (productoSeleccionado == null || Object.keys(productoSeleccionado).length === 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione un producto para dar de baja el inventario",
            });
            return;
        }
        let AlmacenTemp = productoSeleccionado.almacen; //TRAEMOS EL NOMBRE DEL ALMACEN
        let IdResponsableTemp; // VARIABLE DONDE GUARDAREMOS EL ID DEL USUARIO RESPONSABLE DEL ALMACEN SELECCIONADO
        let idCatUsuarioTemp; //VARIABLE DONDE GUADAREMOS EL ID DEL USUARIO LOGUEADO ANTE EL PORTAL

       //TRAEMOS EL ID DEL USUARIO QUE ESTA LOGUEADO ANTE EL SISTEMA
        idCatUsuarioTemp = usuario.idCatUsuario;

         // YA QUE SABEMOS EL NOMBRE DEL ALMACEN VAMOS A TRAER EL ID DEL ALMACEN Y SU RESPONSABLE
        almacenes.map((almacen) => 
        {
            if( almacen.almacen.toLowerCase() == AlmacenTemp.toLowerCase() ) //COMPARA EL NOMBRE SELECCIONADO CON LA LISTA DE ALMACENES
            {
                IdResponsableTemp = almacen.idResponsable;// LO ENCONTRO Y ASIGNA EL ID DEL RESPONSABLE
            }
        })
        //PARA ESTE MOMENTO YA SABEMOS EL ID DEL RESPOMSABLE DEL ALMACEN SELECCIONADO Y EL ID DEL USUARIO LOGUEADO
        // POR LO CUAL PROCEDEMOS A VERIFICAR SI PUEDE REALIZAR EL PROCESO DE MANDARLO A LA SECCION DE BORRADO
        if ((IdResponsableTemp==idCatUsuarioTemp) || administrador){
            props.history.push(`/inventario/baja/${productoSeleccionado.idInventario}`);
        }else{
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Atención",
                dynamic_description: "No cuenta con los privilegios para asignarle ubicación a este producto",
                
            });
        }
    };
    const productoVacio = {
        idCatProducto: "",
        numIdentificacion: null,
        codigoEAN: null,
        principioActivo: null,
        proveedor: null,
        fabricante: null,
    };

    // const filterProductosPorTipo = (idCatTipoProducto) => {
    //   // Insumo es 1, med es 2 y solucion es 4
    //   if (idCatTipoProducto == "" || idCatTipoProducto == null) {
    //     return allProductos;
    //   }
    //   const filteredProductos = allProductos.filter((producto) => producto.idCatTipoProducto == idCatTipoProducto);
    //   return filteredProductos;
    // };

    const handleSelectedProducto = (producto) => {
        // const filteredProducto = allProductos.find((producto) => producto.idCatProducto == idCatProducto);

        const proveedor = producto.catProveedorVo && producto.catProveedorVo.proveedor ? producto.catProveedorVo.proveedor : null;
        const fabricante =
            producto.catLaboratoriosVo && producto.catLaboratoriosVo.laboratorio ? producto.catLaboratoriosVo.laboratorio : null;
        const objetoProducto = {proveedor: proveedor, fabricante: fabricante};
        return objetoProducto;
    };

    const handleAltaInventarioChange = ({target}) => {
        const {name, value} = target;
        if (name == "precioCompra") {
            // Insumo es 1, med es 2 y solucion es 4
            // Insumo causa iva
            // Med y sol no causan iva

            const esInsumo = nuevoInventario.idCatTipoProducto == 1;

            const iva = esInsumo ? value * ivaConfig : 0;
            setNuevoInventario({
                ...nuevoInventario,
                [name]: value,
                iva: iva,
            });
            return;
        } else if (name == "idCatTipoProducto") {
            // const filteredProductos = filterProductosPorTipo(value);
            setNuevoInventario({
                ...nuevoInventario,
                ...productoVacio,
                [name]: value,
            });
            // setProductosFiltrados(filteredProductos);
        } else if (name == "piezas") {
            if (value?.length <= maxCampoPiezas) {
                setNuevoInventario({
                    ...nuevoInventario,
                    [name]: value,
                });
            }
        } else {
            setNuevoInventario({
                ...nuevoInventario,
                [name]: value,
            });
        }
    };

    const handleAltaInventarioChangeProducto = (e) => {
        if (e) {
            const propiedadesAdicionales = handleSelectedProducto(e);
            const esInsumo = e.idCatTipoProducto == 1;
            let iva = 0;
            if (nuevoInventario.precioCompra != null && nuevoInventario.precioCompra != "") {
                iva = esInsumo ? nuevoInventario.precioCompra * ivaConfig : 0;
            }
            setProductoCompletoSeleccionado(e);
            setNuevoInventario({...nuevoInventario, ...e, ...propiedadesAdicionales, iva: iva});
        }
    };

    const handleClickAltaInventario = async () => {
        if (autorizadoAltaInventario)
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_alta_inventario: true,
            });
    };

    const validarCamposInventario = () => {
        if (nuevoInventario.idCatProducto == null || nuevoInventario.idCatProducto == "") {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, selecciona un producto.",
            });
            return false;
        }
        if (nuevoInventario.idCatAlmacen == null || nuevoInventario.idCatAlmacen == "") {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione el almacén.",
            });
            return false;
        }
        if (nuevoInventario.lote == null || nuevoInventario.lote == "") {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, agrega el nombre del lote.",
            });
            return false;
        }
        if (nuevoInventario.piezas == null || nuevoInventario.piezas == "") {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, agrega el número de piezas.",
            });
            return false;
        }
        if (nuevoInventario.piezas != null && nuevoInventario.piezas <= 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, el número de piezas debe ser mayor a 0",
            });
            return false;
        }
        if (nuevoInventario.precioCompra == null || nuevoInventario.precioCompra == "") {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, agrega el precio de compra.",
            });
            return false;
        }
        if (nuevoInventario.caducidad == null || nuevoInventario.caducidad == "") {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, agrega la caducidad.",
            });
            return false;
        }

        return true;
    };

    const altaInventario = async () => {
        const validInventario = validarCamposInventario();
        if (validInventario) {
            setLoading(true);
            const inventarioVo = {
                idCatProducto: nuevoInventario.idCatProducto,
                lote: nuevoInventario.lote,
                piezas: nuevoInventario.piezas,
                precio: nuevoInventario.precioCompra,
                caducidad: nuevoInventario.caducidad,
                idCatAlmacen: nuevoInventario.idCatAlmacen,
            };
            try {
                const res = await crearInventario(inventarioVo);
                if (res && res.status == 200) {
                    setUISweetAlert({
                        ...UISweetAlert,
                        success_dlg: true,
                        dynamic_title: "Exito",
                        confirm_alert_alta_inventario: false,
                        dynamic_description: "Se hizo la alta de inventario correctamente",
                    });
                    setNuevoInventario(nuevoInventarioVacio);
                    setProductoCompletoSeleccionado(null);
                    fetchData({pageSize: 10, pageIndex: controlledPageIndex});
                } else if (res && res.data && res.data.error != null) {
                    setUISweetAlert({
                        ...UISweetAlert,
                        error_dlg: true,
                        confirm_alert_alta_inventario: false,
                        dynamic_title: "Fallo",
                        dynamic_description: "Hubo un error, por favor intentalo más tarde.",
                    });
                }
                setLoading(false);
                return res;
            } catch (error) {
                setLoading(false);
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    confirm_alert_alta_inventario: false,
                    dynamic_title: "Fallo",
                    dynamic_description: "Hubo un error, por favor intentalo más tarde.",
                });
            }
        }
        return;
    };

    function TraeIdAlmacen(AlmacenCadena){
        let idCatAlmacenTemp = 0;

        {almacenes.map((almacen) => {
            if (almacen.almacen == AlmacenCadena){
                idCatAlmacenTemp = almacen.idCatAlmacen;
            }
           })}

        return idCatAlmacenTemp
    }

    const exportarTablaExcel = async () => {
        setLoading(true);
        const res = await exportListaProductosInventario();
    
        if (res.data.error) {
          setLoading(false);
          setUISweetAlert({
            ...UISweetAlert,
            error_dlg: true,
            dynamic_title: "Fallo",
            dynamic_description: res.data.message,
          });
        } else if (res) {
          setLoading(false);
        }
    };

    useEffect(() => {
        listarProductoTiposProductos();
        cleanInventarioSeleccionado();
        listarAlmacenes(false,administrador);
        listarProveedores();
        listarLaboratorios();
    }, []);

    useEffect(() => {
        const fetchAllContextData = async () => {
            // listarConfiguracion();
            const iva = await getIvaConfiguracion();
            setIvaConfig(iva);
        };
        fetchAllContextData();
    }, []);



    return (
        <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
            <h3 className="flex h3 mb-4">Lista de Productos en Inventario</h3>

            <Card>
                <CardBody>
                    <form onSubmit={(e) => buscarPorFiltros(e)}>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Tipo Producto</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatTipoProducto"
                                            onChange={onChange}
                                            value={filtrosProductosInventario.idCatTipoProducto}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {tiposProductos.map((tipoProducto) => {
                                                return (
                                                    <>
                                                        <option value={tipoProducto.idCatTipoProducto}>{tipoProducto.tipoProducto}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Identificación</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="numIdentificacion"
                                            onChange={onChange}
                                            value={filtrosProductosInventario.numIdentificacion}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Código EAN</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="codigoEAN"
                                            onChange={onChange}
                                            value={filtrosProductosInventario.codigoEAN}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Descripción</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nombreComercial"
                                            onChange={onChange}
                                            value={filtrosProductosInventario.nombreComercial}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Principio activo</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="principioActivo"
                                            onChange={onChange}
                                            value={filtrosProductosInventario.principioActivo}
                                        />
                                    </Col>
                                </Row>                          
                            </Col>

                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Lote</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="lote"
                                            onChange={onChange}
                                            value={filtrosProductosInventario.lote}
                                        />
                                    </Col>
                                </Row>                                
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Almacén</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatAlmacen"
                                            onChange={onChange}
                                            value={filtrosProductosInventario.idCatAlmacen}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {almacenes.map((almacen) => {
                                                return (
                                                    <>
                                                        <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Proveedor</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatproveedor"
                                            onChange={onChange}
                                            value={filtrosProductosInventario.idCatproveedor}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {proveedores.map((proveedor) => {
                                                return (
                                                    <>
                                                        <option value={proveedor.idCatProveedor}>{proveedor.proveedor}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fabricante</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatLaboratorio"
                                            onChange={onChange}
                                            value={filtrosProductosInventario.idCatLaboratorio}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {catLaboratorios.map((laboratorio) => {
                                                return (
                                                    <>
                                                        <option value={laboratorio.idCatLaboratorio}>{laboratorio.laboratorio}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                {/*<Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Almacén de Ubicación</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            name="idCatAlmacenUbicacion"
                                            onChange={onChange}
                                            value={filtrosProductosInventario.idCatAlmacenUbicacion}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {almacenes.map((almacen) => {
                                                return (
                                                    <>
                                                        <option value={almacen.idCatAlmacen}>{almacen.almacen}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>*/}
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Ubicación</label>
                                    <Col sm={8}>
                                        <Cascader
                                            disabled={filtrosProductosInventario.idCatAlmacenUbicacion ? false : true}
                                            className="form-control-selects"
                                            notFoundContent="Sin ubicaciones"
                                            options={opcionesUbicacion}
                                            onChange={onChangeCascada}
                                            value={
                                                filtrosProductosInventario.idCatAnaquel
                                                    ? [
                                                        filtrosProductosInventario.idCatAnaquel,
                                                        filtrosProductosInventario.idCatEstante,
                                                        filtrosProductosInventario.idCatUbicacion,
                                                    ]
                                                    : null
                                            }
                                            placeholder="Seleccione una ubicación"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4" type="submit">
                                    Aplicar Filtros
                                </button>
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                    type="button"
                                    onClick={limpiarFiltros}>
                                    Limpiar Filtros
                                </button>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>

            <Card className="mt-4">
                <CardBody>
                    <Row>
                        <Col>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                type="button"
                                hidden={!(usuario.authorities.includes("ROLE_ALMACEN") || usuario.authorities.includes("ROLE_COMPRAS") || 
                                    usuario.authorities.includes("ROLE_ADMIN_SISTEMA"))}
                                onClick={asignarUbiacion}>
                                Asignar Ubicación
                            </button>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                type="button"
                                hidden={usuario.authorities.includes("ROLE_FARMACIA") || !(usuario.authorities.includes("ROLE_ALMACEN") || usuario.authorities.includes("ROLE_COMPRAS") || 
                                    usuario.authorities.includes("ROLE_ADMIN_SISTEMA"))}
                                onClick={trasladoProductoInventario}>
                                Traslado
                            </button>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                type="button"
                                hidden={!autorizado}
                                onClick={bajaInventario}>
                                Baja Inventario
                            </button>
                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                type="button"
                                hidden={!autorizadoAltaInventario}
                                onClick={handleClickAltaInventario}>
                                Alta de Inventario
                            </button>

                            <button
                                className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                type="button"
                                hidden={!autorizadoExcel}
                                onClick={exportarTablaExcel}>
                                Exportar (Excel)
                            </button>                            
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <TableComponent
                                pageCount={pageCount}
                                fetchData={fetchData}
                                columns={columns}
                                loading={loadingData}
                                data={(administrador?productosInventario: 
                                     productosInventario.filter((producto) => 
                                        {
                                            let Idtemporal = TraeIdAlmacen(producto.almacen)
                                            
                                            if (Idtemporal!= 3 && Idtemporal > 0) 
                                            {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        })
                                    )}
                                indexOfPage={controlledPageIndex}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {UISweetAlert.confirm_alert_ubicaciones ? (
                <SweetAlert
                    title="Ubicaciones del lote"
                    confirmBtnText="Cerrar"
                    confirmBtnBsStyle="success"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "70%",
                    }}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_ubicaciones: false,
                        })
                    }>
                    <div className="mt-2">
                        <Row>
                            <Col>
                                <table className="table table-hover table-centered table-nowrap mb-0">
                                    <thead>
                                    <tr>
                                        <th scope="col">Ubicación</th>
                                        <th scope="col">Piezas</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {productoSeleccionado.ubicaciones.map((ubicacion) => {
                                        return (
                                            <tr key={ubicacion.idInventarioUbicacion}>
                                                <td>
                                                    {ubicacion.ubicacion.ubicacion
                                                        ? ubicacion.ubicacion.nombreAlmacen +
                                                        " / " +
                                                        ubicacion.ubicacion.nombreAnaquel +
                                                        " / " +
                                                        ubicacion.ubicacion.nombreEstante +
                                                        " / " +
                                                        ubicacion.ubicacion.ubicacion
                                                        : ""}
                                                </td>
                                                <td>{ubicacion.piezas != "" || ubicacion.piezas != null ? ubicacion.piezas : ""}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_alta_inventario ? (
                <SweetAlert
                    title="Alta de Inventario"
                    showCancel
                    confirmBtnText="Guardar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "90%",
                    }}
                    onConfirm={altaInventario}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_alta_inventario: false,
                        });
                        setNuevoInventario(nuevoInventarioVacio);
                        setProductoCompletoSeleccionado(null);
                    }}>
                    <AltaProductoInventario
                        loadProductosSelect={loadProductosSelect}
                        tiposProductos={tiposProductos}
                        onChange={handleAltaInventarioChange}
                        onChangeProducto={handleAltaInventarioChangeProducto}
                        inventario={nuevoInventario}
                        productoCompletoSeleccionado={productoCompletoSeleccionado}
                        almacenes={almacenes}
                    />
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </div>
    );
}
