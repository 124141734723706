import React, { useReducer } from "react";
import CatTipoPrecioContext from "./CatTipoPrecioContext";
import CatTipoPrecioReducer from "./CatTipoPrecioReducer";

import clienteAxios from "../../config/axios";

import { LISTAR_TIPOPRECIOS } from "../../types";

export default function CatTipoPrecioState(props) {
  const initialState = {
    tipoPrecios: [],
  };

  const [state, dispatch] = useReducer(CatTipoPrecioReducer, initialState);

  // Retorna el usuario autenticado
  const listarCatTipoPrecios = async () => {
    const res = await clienteAxios.get("/tipoprecio", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_TIPOPRECIOS,
      payload: res.data,
    });
  };

  return (
    <CatTipoPrecioContext.Provider
      value={{
        tipoPrecios: state.tipoPrecios,
        listarCatTipoPrecios,
      }}>
      {props.children}
    </CatTipoPrecioContext.Provider>
  );
}
