import React, {useReducer} from "react";

import AseguradorasContext from "./AseguradorasContext";
import AseguradorasReducer from "./AseguradorasReducer";

import {LISTAR_ASEGURADORAS, FILTRO_ASEGURADORAS, SET_ASEGURADORAS_PAGINADAS, SET_ASEGURADORA} from "../../types";

import clienteAxios from "../../config/axios";

export default function AseguradorasState(props) {
    const initialState = {
        aseguradoras: [],
        filtrosAseguradoras: {},
        aseguradorasPaginadas: [],
        aseguradora: {},
    };

    const [state, dispatch] = useReducer(AseguradorasReducer, initialState);

    const setDataAseguradora = async ({page, limit, filtrosAseguradoras}) => {
        try {
            const response = await clienteAxios.post(
                "/aseguradoras/paginado/",
                {
                    pageSize: limit,
                    pageIndex: page,
                    ...state.filtrosAseguradoras,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            dispatch({
                type: SET_ASEGURADORAS_PAGINADAS,
                payload: response.data.aseguradoras,
            });

            return response.data;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const listarAseguradoras = async () => {
        const res = await clienteAxios.get(`/aseguradoras/activos`, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: LISTAR_ASEGURADORAS,
            payload: res.data,
        });


    };

    const getAllAseguradoras = async () => {
        try {
            const res = await clienteAxios.get(`/aseguradoras/activos`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });
            return res.data;
        } catch (e) {
            return null;
        }
    };

    const setFiltrosAseguradoras = async (filtrosAseguradoras) => {
        dispatch({
            type: FILTRO_ASEGURADORAS,
            payload: filtrosAseguradoras,
        });
    };

    const uploadNuevaAseguradora = async (datosAseguradora) => {
        // Checar que no la aseguradora no exista en la base de datos

        try {
            const res = await clienteAxios.post(`/aseguradoras/guardar`, datosAseguradora, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const eliminarAseguradora = async (idCatAseguradora) => {
        // Sustituir delete con dirección del back
        // const res = await clienteAxios.post(`/pacientes/${idCatAseguradora}`)
        try {
            const response = await clienteAxios.post(
                "/aseguradoras/eliminar/",
                {idCatAseguradora: idCatAseguradora},
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Cache-Control': 'no-store', // Evita el caché del navegador
                    },
                }
            );

            return response;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const updateAseguradora = async (aseguradoraModificada) => {
        try {
            const res = await clienteAxios.post(`/aseguradoras/guardar`, aseguradoraModificada, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Cache-Control': 'no-store', // Evita el caché del navegador
                },
            });

            return res;
        } catch (error) {
            if (!error.response) {
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error: "hubo error",
                    },
                };
            }

            return error.response;
        }
    };

    const setAseguradoraWithDetalles = async (idCatAseguradora) => {
        const res = await clienteAxios.get(`/aseguradoras/detalles?id=${idCatAseguradora}`, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Cache-Control': 'no-store', // Evita el caché del navegador
            },
        });

        dispatch({
            type: SET_ASEGURADORA,
            payload: res.data,
        });

        return res.data;
    };

    return (
        <AseguradorasContext.Provider
            value={{
                aseguradoras: state.aseguradoras,
                filtrosAseguradoras: state.filtrosAseguradoras,
                aseguradorasPaginadas: state.aseguradorasPaginadas,
                aseguradora: state.aseguradora,
                setAseguradoraWithDetalles,
                listarAseguradoras,
                setFiltrosAseguradoras,
                setDataAseguradora,
                uploadNuevaAseguradora,
                eliminarAseguradora,
                updateAseguradora,
                getAllAseguradoras
            }}>
            {props.children}
        </AseguradorasContext.Provider>
    );
}
