import React, { useReducer } from "react";

import TiposAdministracionContext from "./TiposAdministracionContext";
import TiposAdministracionReducer from "./TiposAdministracionReducer";

import { LISTAR_TIPOS_ADMINISTRACION } from "../../types";

import clienteAxios from "../../config/axios";

export default function TiposAdministracionState(props) {
  const initialState = {
    tiposAdministracion: [],
  };

  const [state, dispatch] = useReducer(TiposAdministracionReducer, initialState);

  const listarTiposAdministracion = async () => {
    const res = await clienteAxios.get("/tiposadministracion/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_TIPOS_ADMINISTRACION,
      payload: res.data,
    });
  };

  return (
    <TiposAdministracionContext.Provider
      value={{
        tiposAdministracion: state.tiposAdministracion,
        listarTiposAdministracion,
      }}>
      {props.children}
    </TiposAdministracionContext.Provider>
  );
}
