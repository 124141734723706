import {
    FILTRO_ORDENESCOMPRA,
    LISTAR_ORDENESCOMPRA,
    ORDEN_COMPRA_SELECCIONADA,
    ERROR_ORDEN_COMPRA_SELECCIONADA,
    ORDEN_COMPRA_SHOWEDIT,
    UPLOAD_ORDEN_COMPRA,
    ERROR_UPLOAD_ORDEN_COMPRA,
    NUEVA_ORDEN_COMPRA,
    ERROR_NUEVA_ORDEN_COMPRA,
    CANCELAR_ORDEN_COMPRA
} from '../../types'


export default (state, action) => {
    switch (action.type){
        case FILTRO_ORDENESCOMPRA:
            return{
                ...state, 
                filtrosOrdenesCompra: action.payload
            }
        case LISTAR_ORDENESCOMPRA:
            return{
                ...state,
                ordenesCompraFiltradas: action.payload
            }
        case ORDEN_COMPRA_SELECCIONADA:
            return{
                ...state,
                ordenCompraSeleccionada: action.payload,
                errorOrdenCompraSeleccionada: false
            }
        case ERROR_ORDEN_COMPRA_SELECCIONADA:
            return{
                ...state,
                ordenCompraSeleccionada: {},
                errorOrdenCompraSeleccionada: true
            }
        case ORDEN_COMPRA_SHOWEDIT:
            return{
                ...state,
                ordenCompraShowEdit: action.payload,
            }
        case ERROR_UPLOAD_ORDEN_COMPRA:
        case NUEVA_ORDEN_COMPRA:
        case ERROR_NUEVA_ORDEN_COMPRA:
            return{
                ...state,
            }
        case UPLOAD_ORDEN_COMPRA:
            return{
                ordenesCompraFiltradas: state.ordenesCompraFiltradas.filter( orden => {
                    if(orden.idOrdenCompra == action.payload.idOrdenCompra){
                        orden.estatusordencompra = action.payload.estatusordencompra
                    }
                    return true;
                }),
                ...state,
            }
        case CANCELAR_ORDEN_COMPRA:
            return{
                ...state,
                cancelarOrdenCompra: action.payload,
            }
        default:
            return state
    }
        
}