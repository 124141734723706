import React, {useReducer} from 'react'

import CatTipoSolicitudCompraContext from './CatTipoSolicitudCompraContext'
import CatTipoSolicitudCompraReducer from './CatTipoSolicitudCompraReducer'

import {
    LISTAR_CATTIPOSOLICITUDCOMPRAS,
} from '../../types'

import clienteAxios from '../../config/axios'


export default function CatTipoSolicitudCompraState (props) {

    const initialState = { 
        tiposSolicitudCompra:[],
    }

    const [state, dispatch] = useReducer(CatTipoSolicitudCompraReducer,initialState)

    const listarTiposSolicitudCompra = async () => {

        const res = await clienteAxios.get("/tiposolicitudcompra/activos"  , {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
            dispatch({
                type: LISTAR_CATTIPOSOLICITUDCOMPRAS,
                payload: res.data
            })
    }

    return (

        <CatTipoSolicitudCompraContext.Provider value={{
            tiposSolicitudCompra: state.tiposSolicitudCompra,
            listarTiposSolicitudCompra,
        }}>
            {props.children}
        </CatTipoSolicitudCompraContext.Provider>

    )


}