import React, { useContext } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col } from "reactstrap";
// import useCloseWindowWarning from "../../../hooks/useCloseWindowWarning";
import WindowWarningContext from "../../../context/WindowWarning/WindowWarningContext";

import { maxCharHabitusExterior } from "../../../config/variables";

export default function ExploracionFisica({ props, padecimientoActual, setPadecimientoActual }) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito } = windowWarningContext;

  // const [Prompt, setEditTrue, setEditFalse, isEdit] = useCloseWindowWarning();
  const onChange = (e) => {
    if (!seEdito) {
      // changeGuardado(false);
      changeEdit(true);
    }
    const datosDePadecimiento = [
      "tensionArterialSistolica",
      "habitusExterior",
      "saturacionOxigeno",
      "frecuenciaRespiratoria",
      "temperatura",
      "frecuenciaCardiaca",
      "tensionArterialDiastolica",
    ];

    const { name } = e.target;
    if (name == "talla") {
      let value = e.target.value;
      if (!padecimientoActual.expedienteMedico) {
        padecimientoActual.expedienteMedico = {};
        padecimientoActual.expedienteMedico.talla = value;
      } else padecimientoActual.expedienteMedico.talla = value;
      setPadecimientoActual(padecimientoActual);
      calcularSCT(value, padecimientoActual.expedienteMedico.peso ? padecimientoActual.expedienteMedico.peso : null);
      calcularIMC(value, padecimientoActual.expedienteMedico.peso ? padecimientoActual.expedienteMedico.peso : null);
    } else if (name == "peso") {
      let value = e.target.value;
      if (!padecimientoActual.expedienteMedico) {
        padecimientoActual.expedienteMedico = {};
        padecimientoActual.expedienteMedico.peso = value;
      } else padecimientoActual.expedienteMedico.peso = value;

      setPadecimientoActual(padecimientoActual);

      calcularSCT(padecimientoActual.expedienteMedico.talla ? padecimientoActual.expedienteMedico.talla : null, value);
      calcularIMC(padecimientoActual.expedienteMedico.talla ? padecimientoActual.expedienteMedico.talla : null, value);
    } else if (datosDePadecimiento.includes(name))
      setPadecimientoActual({
        ...padecimientoActual,
        expedienteMedico: {
          ...padecimientoActual.expedienteMedico,
          [e.target.name]: e.target.value,
        },
      });
    else {
      setPadecimientoActual({
        ...padecimientoActual,
        exploracionFisica: {
          ...padecimientoActual.exploracionFisica,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const calcularSCT = (talla, peso) => {
    if (talla && peso) {
      let sct = peso ** 0.425 * talla ** 0.725 * 0.007184;
      padecimientoActual.expedienteMedico.superficieCorporalTotal = sct.toFixed(2);
      setPadecimientoActual(padecimientoActual);
    }
  };
  const calcularIMC = (talla, peso) => {
    if (talla && peso) {
      const tallEnMetros = talla / 100;
      let imc = peso / Math.pow(tallEnMetros, 2);

      padecimientoActual.expedienteMedico.indiceMasaCorporal = imc.toFixed(1);
      setPadecimientoActual(padecimientoActual);
    }
  };

  return (
    <div className="mt-4" style={{ textAlign: "center" }}>
      {/* <Row>
                <h4>Exploración Física</h4>
            </Row> */}
      <Row>
        <Col xl={6} lg={12} md={12}>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Talla (cm)</label>
            <Col sm={7}>
              <input
                type="text"
                name="talla"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.talla
                    ? padecimientoActual.expedienteMedico.talla
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Peso (kg)</label>
            <Col sm={7}>
              <input
                type="text"
                name="peso"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.peso
                    ? padecimientoActual.expedienteMedico.peso
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Indice de Masa Corporal (IMC)</label>
            <Col sm={7}>
              <input
                type="text"
                disabled={true}
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.indiceMasaCorporal
                    ? padecimientoActual.expedienteMedico.indiceMasaCorporal
                    : ""
                }
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Superficie Corporal Total (SCT)</label>
            <Col sm={7}>
              <input
                type="text"
                disabled={true}
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.superficieCorporalTotal
                    ? padecimientoActual.expedienteMedico.superficieCorporalTotal
                    : ""
                }
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Hábitus Exterior</label>
            <Col sm={7}>
              <input
                type="text"
                maxLength={maxCharHabitusExterior}
                name="habitusExterior"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.habitusExterior
                    ? padecimientoActual.expedienteMedico.habitusExterior
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
        </Col>
        <Col xl={6} lg={12} md={12}>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Tensión Arterial Sistólica (mmHg)</label>
            <Col sm={7}>
              <input
                type="text"
                name="tensionArterialSistolica"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.tensionArterialSistolica
                    ? `${padecimientoActual.expedienteMedico.tensionArterialSistolica}`
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Tensión Arterial Diastólica (mmHg)</label>
            <Col sm={7}>
              <input
                type="text"
                name="tensionArterialDiastolica"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.tensionArterialDiastolica
                    ? `${padecimientoActual.expedienteMedico.tensionArterialDiastolica}`
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Frecuencia cardiaca (latidos/min)</label>
            <Col sm={7}>
              <input
                type="text"
                name="frecuenciaCardiaca"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.frecuenciaCardiaca
                    ? `${padecimientoActual.expedienteMedico.frecuenciaCardiaca}`
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>

          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Frecuencia Respiratoria (resp/min)</label>
            <Col sm={7}>
              <input
                type="text"
                name="frecuenciaRespiratoria"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.frecuenciaRespiratoria
                    ? `${padecimientoActual.expedienteMedico.frecuenciaRespiratoria}`
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Temperatura (°C)</label>
            <Col sm={7}>
              <input
                type="text"
                name="temperatura"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.temperatura
                    ? `${padecimientoActual.expedienteMedico.temperatura}`
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Saturación de Oxigeno (%)</label>
            <Col sm={7}>
              <input
                type="text"
                name="saturacionOxigeno"
                value={
                  padecimientoActual.expedienteMedico && padecimientoActual.expedienteMedico.saturacionOxigeno
                    ? `${padecimientoActual.expedienteMedico.saturacionOxigeno}`
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              <a href="https://www.nccn.org/guidelines/category_1" target="_blank" rel="noreferrer">
                Guía NCCN
              </a>
            </label>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Estado Neurológico</label>
            <Col sm={10}>
              <textarea
                name="estadoNeurologico"
                value={
                  padecimientoActual.exploracionFisica && padecimientoActual.exploracionFisica.estadoNeurologico
                    ? padecimientoActual.exploracionFisica.estadoNeurologico
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Cabeza</label>
            <Col sm={10}>
              <textarea
                name="cabeza"
                value={
                  padecimientoActual.exploracionFisica && padecimientoActual.exploracionFisica.cabeza
                    ? padecimientoActual.exploracionFisica.cabeza
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Mucosa y Tegumentos</label>
            <Col sm={10}>
              <textarea
                name="mucosaTegumentos"
                value={
                  padecimientoActual.exploracionFisica && padecimientoActual.exploracionFisica.mucosaTegumentos
                    ? padecimientoActual.exploracionFisica.mucosaTegumentos
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Cuello</label>
            <Col sm={10}>
              <textarea
                name="cuello"
                value={
                  padecimientoActual.exploracionFisica && padecimientoActual.exploracionFisica.cuello
                    ? padecimientoActual.exploracionFisica.cuello
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Torax</label>
            <Col sm={10}>
              <textarea
                name="torax"
                value={
                  padecimientoActual.exploracionFisica && padecimientoActual.exploracionFisica.torax
                    ? padecimientoActual.exploracionFisica.torax
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Abdomen</label>
            <Col sm={10}>
              <textarea
                name="abdomen"
                value={
                  padecimientoActual.exploracionFisica && padecimientoActual.exploracionFisica.abdomen
                    ? padecimientoActual.exploracionFisica.abdomen
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Extremidades</label>
            <Col sm={10}>
              <textarea
                name="extremidades"
                value={
                  padecimientoActual.exploracionFisica && padecimientoActual.exploracionFisica.extremidades
                    ? padecimientoActual.exploracionFisica.extremidades
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-2 col-form-label">Genitales</label>
            <Col sm={10}>
              <textarea
                name="genitales"
                value={
                  padecimientoActual.exploracionFisica && padecimientoActual.exploracionFisica.genitales
                    ? padecimientoActual.exploracionFisica.genitales
                    : ""
                }
                onChange={onChange}
                className="form-control"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* {Prompt} */}
    </div>
  );
}
