import { useState } from "react";

export const maxCharTelefono = 50;
export const maxCharTelefonoAlmacen = 10;
export const maxCharNombres = 100;
export const maxCharApellidoPaterno = 100;
export const maxCharApellidoMaterno = 100;
export const maxCharCurp = 20;
export const maxCharCorreo = 50;
export const maxCharReferencia = 50;
export const maxCharOrigen = 50;
export const maxCharResidencia = 50;
export const maxCharReligion = 50;
export const maxCharOtraOcupacion = 100;

// Max de caracteres para proveedores

export const maxCharProveedor = 100;
export const maxCharRazonSocial = 100;
export const maxCharRfc = 15;
export const maxCharColonia = 100;
export const maxCharCalle = 50;
export const maxCharNumExterno = 50;
export const maxCharNumInterno = 50;
export const maxCharMunicipio = 45;
export const maxCharPais = 20;
export const maxCharCP = 5;
export const maxCharContacto = 100;

// Max de caracteres para fabricantes

export const maxCharLaboratorio = 100;
export const maxCharCalleFabricante = 150;

// Max de caracteres para medicos

export const maxCharPrefijo = 5;
export const maxCharEspecialidad = 100;
export const maxCharCedulaProfesional = 15;
export const maxCharUniversidadProfesional = 50;
export const maxCharCedulaEspecialidad = 15;
export const maxCharUniversidadEspecialidad = 50;

// Max de caracteres expediente médico

export const maxCamposAntecedentesGineco = 100;
export const maxCamposAntecedentesNoPatologicos = 100;
export const maxCamposAntecedentesPatologicos = 100;
export const maxCharEtapaClinica = 100;
export const maxCharHabitusExterior = 50;

export const maxCampoServicio = 100;

export const maxCampoNumIdentificacion = 50;

export const maxCampoPiezas = 9;
export const maxClaveSat = 10;

export const mesesFacturacionGlobal = [
    {
        meses: 1,
        descripcion: "01 - Enero",
    },
    {
        meses: 2,
        descripcion: "02 - Febrero",
    },
    {
        meses: 3,
        descripcion: "03 - Marzo",
    },
    {
        meses: 4,
        descripcion: "04 - Abril",
    },
    {
        meses: 5,
        descripcion: "05 - Mayo",
    },
    {
        meses: 6,
        descripcion: "06 - Junio",
    },
    {
        meses: 7,
        descripcion: "07 - Julio",
    },
    {
        meses: 8,
        descripcion: "08 - Agosto",
    },
    {
        meses: 9,
        descripcion: "09 - Septiembre",
    },
    {
        meses: 10,
        descripcion: "10 - Octubre",
    },
    {
        meses: 11,
        descripcion: "11 - Noviembre",
    },
    {
        meses: 12,
        descripcion: "12 - Diciembre",
    },
    {
        meses: 13,
        descripcion: "13 - Enero-Febrero",
    },
    {
        meses: 14,
        descripcion: "14 - Marzo-Abril",
    },
    {
        meses: 15,
        descripcion: "15 - Mayo-Junio",
    },
    {
        meses: 16,
        descripcion: "16 - Julio-Agosto",
    },
    {
        meses: 17,
        descripcion: "17 - Septiembre-Octubre",
    },
    {
        meses: 18,
        descripcion: "18 - Noviembre-Diciembre",
    },
];

export const meses = [
    {
        meses: 1,
        descripcion: "Enero",
    },
    {
        meses: 2,
        descripcion: "Febrero",
    },
    {
        meses: 3,
        descripcion: "Marzo",
    },
    {
        meses: 4,
        descripcion: "Abril",
    },
    {
        meses: 5,
        descripcion: "Mayo",
    },
    {
        meses: 6,
        descripcion: "Junio",
    },
    {
        meses: 7,
        descripcion: "Julio",
    },
    {
        meses: 8,
        descripcion: "Agosto",
    },
    {
        meses: 9,
        descripcion: "Septiembre",
    },
    {
        meses: 10,
        descripcion: "Octubre",
    },
    {
        meses: 11,
        descripcion: "Noviembre",
    },
    {
        meses: 12,
        descripcion: "Diciembre",
    },
];
