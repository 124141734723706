import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import FabricantesContext from "../../context/Fabricantes/FabricantesContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import CargaMasivaModal from "../CargaMasivaModal/index";

import formatNumber from "../../utils/index";

import useWindowDimensions from "../../hooks/dimensionsHook";

const Soluciones = () => {
  const { width } = useWindowDimensions();

  const filtrosVacios = {
    numIdentificacion: "",
    codigoEAN: "",
    nombreComercial: "",
    proveedor: "",
    laboratorio: "",
  };

  const datosSolucionVacio = {
    numIdentificacion: "",
    codigoEAN: "",
    concentracion: "",
    nombreComercial: "",
    idCatProveedor: "",
    idCatLaboratorio: "",
    precioPublico: null,
    precioMostrador: null,
    precioAseguradoras: null,
    principioActivo:"X",
    presentacion:"X",
    concentracion:"X",    
  };

  const [datosNuevaSolucion, setDatosNuevaSolucion] = useState(datosSolucionVacio);
  const [solucionSeleccionada, setSolucionSeleccionada] = useState(null);

  const [solucionPreciosActualizados, setSolucionPreciosActualizados] = useState({ preciosAseguradora: [] });
  const [nuevoPrecio, setNuevoPrecio] = useState(null);
  const [solucionSeleccionadaPrecios, setSolucionSeleccionadaPrecios] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_solucion: false,
    confirm_nueva_solucion: false,
    confirm_eliminar_solucion: false,
    confirm_modificar_precios: false,
    confirm_modificar_precios_final: false,
    confirm_carga_masiva: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
  const [data, setData] = useState([
    {
      idCatProducto: "",
      numIdentificacion: "",
      codigoEAN: "",
      nombreComercial: "",

      proveedor: { idCatProveedor: "", proveedor: "" },
      laboratorio: { idCatLaboratorio: "", laboratorio: "" },
      precios: [
        {
          tipoPrecio: "Precio Publico",
          precio: 2300,
        },
      ],
    },
  ]);
  const fetchIdRef = useRef(0);

  const catProductosContext = useContext(CatProductosContext);
  const proveedoresContext = useContext(ProveedoresContext);
  const fabricantesContext = useContext(FabricantesContext);

  const {
    filtrosProductos,
    productosPaginados,
    setDataProductos,
    setFiltrosProductos,
    uploadNuevoProducto,
    eliminarProducto,
    updateProducto,
    actualizarPreciosProducto,

    listarPreciosProducto,
    preciosProducto,
  } = catProductosContext;

  const { listarProveedores, proveedores } = proveedoresContext;

  const { listarFabricantes, fabricantes } = fabricantesContext;

  useEffect(() => {
    const fetchContextData = () => {
      listarProveedores();
      listarFabricantes();
    };
    fetchContextData();
  }, []);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setDataProductos({ page, limit, idCatTipoProducto: 4 });

      // Cantidad de datos disponibles en la tabla pacientes de la base de datos
      const count = response.count;

      setData(response.productos);

      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
    // setLoadingData(true);
    // fetchAPIData({
    //   page: pageIndex + 1,
    //   limit: pageSize,
    //   byBusquedaFiltros: false,
    // });
  };

  const handdleCheckedSolucion = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setSolucionSeleccionada({
          ...row.original,
          idCatProveedor: row.values["catProveedorVo.idCatProveedor"],
          idCatLaboratorio: row.values["catLaboratoriosVo.idCatLaboratorio"],
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setSolucionSeleccionada(null);
      } else {
        setRowSeleccionada(row.id);
        setSolucionSeleccionada({
          ...row.original,
          idCatProveedor: row.values["catProveedorVo.idCatProveedor"],
          idCatLaboratorio: row.values["catLaboratoriosVo.idCatLaboratorio"],
        });
      }
    },
    [rowSeleccionada]
  );

  // Se definen las columnas para la tabla de react-tabe
  // Se debe usar useMemo para optimización y porque lo pide la librería

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCatProducto",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedSolucion(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Identificación", accessor: "numIdentificacion" },
      { Header: "Código EAN", accessor: "codigoEAN" },
      { Header: "Nombre Comercial", accessor: "nombreComercial" },
      { Header: "Concentración", accessor: "concentracion" },
      { Header: "idProveedor", accessor: "catProveedorVo.idCatProveedor" },
      { Header: "Proveedor", accessor: "catProveedorVo.proveedor" },
      { Header: "idCatFabricante", accessor: "catLaboratoriosVo.idCatLaboratorio" },
      { Header: "Fabricante", accessor: "catLaboratoriosVo.laboratorio" },
      {
        Header: "Precios",
        accessor: "precios",
        Cell: ({ row }) => {
          return autorizado || autorizadoAseguradora ? (
            <button
              className="btn btn-primary-purple-umo w-md waves-effect waves-light"
              onClick={() => handdlePrecios(row.original)}>
              Precios
            </button>
          ) : null;
        },

        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [rowSeleccionada, handdleCheckedSolucion]
  );

  const handdlePrecios = async (solucion) => {
    const response = await listarPreciosProducto(solucion.idCatProducto);

    setSolucionSeleccionadaPrecios({
      ...response,
      numIdentificacion: solucion.numIdentificacion,
      nombreComercial: solucion.nombreComercial,
      principioActivo: solucion.principioActivo,
    });

    setSolucionPreciosActualizados({
      ...response,
      numIdentificacion: solucion.numIdentificacion,
      nombreComercial: solucion.nombreComercial,
      principioActivo: solucion.principioActivo,
    });

    if (response.preciosProductoServicio || response.preciosAseguradora.length > 0) {
      setUISweetAlert({
        confirm_modificar_solucion: false,
        confirm_nueva_solucion: false,
        confirm_eliminar_solucion: false,
        confirm_modificar_precios: true,
        confirm_carga_masiva: false,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
      });
    } else {
      setUISweetAlert({
        confirm_modificar_solucion: false,
        confirm_nueva_solucion: false,
        confirm_eliminar_solucion: false,
        confirm_modificar_precios: false,
        confirm_carga_masiva: true,
        success_dlg: false,
        reload_page: false,
        error_dlg: false,
        dynamic_title: "",
        dynamic_description: "",
      });
    }
  };

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  //   const autorizado =
  //     usuario.authorities.includes("OP_CATALOGO_SOLUCION_CREAR") ||
  //     usuario.authorities.includes("OP_CATALOGO_SOLUCION_MODIFICAR") ||
  //     usuario.authorities.includes("OP_CATALOGO_SOLUCION_MODIFICAR_PRECIO") ||
  //     usuario.authorities.includes("OP_CATALOGO_SOLUCION_ELIMINAR");

  const autorizado =
    usuario.authorities.includes("ROLE_ADMIN_SISTEMA") ||
    usuario.authorities.includes("ROLE_FARMACIA") ||
    usuario.authorities.includes("ROLE_COMPRAS");

    const editarIdentificacion =  usuario.authorities.includes("ROLE_COMPRAS");
    const autorizadoAseguradora = usuario.authorities.includes("ROLE_ASEGURADORAS");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function setDataProductos
     * @param {object} filtrosProductos Filtros enviados al back para filtrar los productos
     * @param {int} page Página a regresar de pacientes
     * @param {int} limit Número de pacientes que se van a regresar por llamada al back
     */

    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosProductos(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltrosProductos({
      ...filtrosProductos,
      [name]: value,
    });
  };

  const changeNuevaSolucion = (e) => {
    const { name, value } = e.target;
    setDatosNuevaSolucion({
      ...datosNuevaSolucion,
      [name]: value,
    });
  };

  const changeModificarSolucion = (e) => {
    const { name, value } = e.target;
    setSolucionSeleccionada({
      ...solucionSeleccionada,
      [name]: value,
    });
  };

  const opcionesProveedor = proveedores.map(({ idCatProveedor, proveedor, ...rest }) => ({
    ...rest,
    id: idCatProveedor,
    nombre: proveedor,
  }));

  const opcionesFabricante = fabricantes.map(({ idCatLaboratorio, laboratorio, ...rest }) => ({
    ...rest,
    id: idCatLaboratorio,
    nombre: laboratorio,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Identificación",
          type: "text",
          name: "numIdentificacion",
          value: filtrosProductos.numIdentificacion,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Código EAN",
          type: "text",
          name: "codigoEAN",
          value: filtrosProductos.codigoEAN,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Nombre Comercial",
          type: "text",
          name: "nombreComercial",
          value: filtrosProductos.nombreComercial,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "select",
          label: "Proveedor",
          name: "proveedor",
          value: filtrosProductos.proveedor,
          opciones: opcionesProveedor,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Fabricante",
          name: "laboratorio",
          value: filtrosProductos.laboratorio,
          opciones: opcionesFabricante,
          onChange: onChange,
        },
      ],
    },
  ];

  const validarDatosSolucion = (solucion) => {
    let errorNuevaSolucion = false;
    if (!errorNuevaSolucion && (solucion.numIdentificacion === "" || solucion.numIdentificacion === null)) {
      errorNuevaSolucion = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el número de identificación",
      });
    }
    // if (!errorNuevaSolucion && (solucion.codigoEAN === "" || solucion.codigoEAN === null)) {
    //   errorNuevaSolucion = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el código EAN",
    //   });
    // }
    if (!errorNuevaSolucion && (solucion.nombreComercial === "" || solucion.nombreComercial === null)) {
      errorNuevaSolucion = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la descripción",
      });
    }

    // if (!errorNuevaSolucion && (solucion.idCatProveedor === "" || solucion.idCatProveedor === null)) {
    //   errorNuevaSolucion = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el proveedor",
    //   });
    // }
    // if (!errorNuevaSolucion && (solucion.idCatLaboratorio === "" || solucion.idCatLaboratorio === null)) {
    //   errorNuevaSolucion = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el fabricante",
    //   });
    // }

    return errorNuevaSolucion;
  };

  const validarPreciosProducto = (producto) => {
    let errorNuevoMedicamento = false;

    if (!errorNuevoMedicamento && producto.precioMostrador === null) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el precio mostrador y médico de la solución",
      });
    }
    if (!errorNuevoMedicamento && producto.precioAseguradoras === null) {
      errorNuevoMedicamento = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el precio para aseguradoras de la solución",
      });
    }
    return errorNuevoMedicamento;
  };

  const confirmNuevaSolucion = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nueva_solucion: true,
      });
    }
  };

  const agregarNuevaSolucion = async () => {
    const solucionValida = validarDatosSolucion(datosNuevaSolucion);
    let preciosValidos = true;
    if (!solucionValida) preciosValidos = validarPreciosProducto(datosNuevaSolucion);

    if (!solucionValida && !preciosValidos) {
      setLoading(true);

      const res = await uploadNuevoProducto({ ...datosNuevaSolucion, idCatTipoProducto: 4 });

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nueva_solucion: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
        setDatosNuevaSolucion(datosSolucionVacio);
      } else if (res) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nueva_solucion: false,
          dynamic_description: "Se guardó la nueva solución correctamente",
        });
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        setData([...data, res.data]);
        setDatosNuevaSolucion(datosSolucionVacio);
      }
    }
  };

  // const updateDataOnDeleteSolucion = async (idCatProducto) => {
  //   const updatedData = data.filter((solucion) => solucion.idCatProducto != idCatProducto);
  //   actualizarProductosPaginados(updatedData);
  // };

  const checkEliminarSolucion = () => {
    if (autorizado && solucionSeleccionada) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_solucion: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione una solución",
      });
    }
  };

  const confirmEliminarSolucion = async () => {
    setLoading(true);
    const res = await eliminarProducto(solucionSeleccionada.idCatProducto);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_solucion: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_solucion: false,
        dynamic_description: "Se eliminó la solución correctamente",
      });
      fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
      // updateDataOnDeleteSolucion(solucionSeleccionada.idCatProducto);
      setSolucionSeleccionada(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarSolucion = () => {
    if ((autorizado || autorizadoAseguradora ) && solucionSeleccionada) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_solucion: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione una solución",
      });
    }
  };

  const confirmModicarSolucion = async () => {
    const solucionValida = validarDatosSolucion(solucionSeleccionada);

    if (!solucionValida) {
      setLoading(true);
      const res = await updateProducto(solucionSeleccionada);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_solucion: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_solucion: false,
          dynamic_description: "Se modificó la solución correctamente",
        });
        // const solucionesActualizadas = await data.map((solucion) => {
        //   if (res.data.idCatProducto == solucion.idCatProducto) {
        //     return (solucion = res.data);
        //   } else {
        //     return solucion;
        //   }
        // });
        fetchData({ pageSize: 10, pageIndex: controlledPageIndex });
        // actualizarProductosPaginados(solucionesActualizadas);
        setSolucionSeleccionada(null);
        setRowSeleccionada(null);
      }
    }
  };

  const changeNuevoPrecio = (e) => {
    const { value } = e.target;
    setNuevoPrecio(value);
  };

  const setNuevoPrecioSolucion = (precioActual) => {
    if (nuevoPrecio === null || nuevoPrecio == "") return;

    let aseguradoraModificada = preciosProducto.preciosAseguradora.filter(
      (aseguradora) => aseguradora.idPrecioAseguradora == precioActual.idPrecioAseguradora
    );

    aseguradoraModificada = aseguradoraModificada[0];
    aseguradoraModificada = { ...aseguradoraModificada, precioUnitario: nuevoPrecio };

    let arrayPreciosAseguradora = solucionPreciosActualizados.preciosAseguradora;
    arrayPreciosAseguradora = arrayPreciosAseguradora.map((aseguradora) => {
      if (aseguradora.idPrecioAseguradora == aseguradoraModificada.idPrecioAseguradora) {
        aseguradora = aseguradoraModificada;
        return aseguradora;
      } else {
        return aseguradora;
      }
    });

    setSolucionPreciosActualizados({
      ...solucionPreciosActualizados,
      preciosAseguradora: arrayPreciosAseguradora,
    });

    arrayPreciosAseguradora.push(aseguradoraModificada);

    setSolucionPreciosActualizados({
      ...solucionPreciosActualizados,
      preciosAseguradora: arrayPreciosAseguradora,
    });
    setNuevoPrecio(null);
  };
  const setNuevoPrecioMedicamentoProductoServicio = ({ tipo }) => {
    if (nuevoPrecio === null || nuevoPrecio == "") return;
    const updatedPrecios = {
      ...solucionPreciosActualizados.preciosProductoServicio,
      [tipo]: nuevoPrecio,
    };

    setSolucionPreciosActualizados({ ...solucionPreciosActualizados, preciosProductoServicio: updatedPrecios });
    // setSolucionSeleccionadaPrecios({ ...solucionSeleccionadaPrecios, precioProductoServicio: updatedPrecios });
    setNuevoPrecio(null);
  };

  const guardarPreciosSolucion = async () => {
    if (
      solucionPreciosActualizados.preciosAseguradora.length < 1 &&
      solucionPreciosActualizados.preciosProductoServicio === undefined
    )
      return;

    setLoading(true);

    const res = await actualizarPreciosProducto(solucionPreciosActualizados);
    if (res.data && res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_modificar_precios: false,
        confirm_modificar_precios_final: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
      setSolucionSeleccionadaPrecios(null);
      setSolucionPreciosActualizados({ preciosAseguradora: [] });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_modificar_precios: false,
        confirm_modificar_precios_final: false,
        dynamic_description: "Se guardaron los precios correctamente",
      });
      // const productosFiltrados = productosPaginados.map((producto) => {
      //   if (producto.idCatProducto != medicamentoPreciosActualizados.idCatProducto) return producto;
      //   return medicamentoPreciosActualizados;
      // });
      // actualizarProductosPaginados(productosFiltrados);

      setSolucionSeleccionadaPrecios(null);
      setSolucionPreciosActualizados({ preciosAseguradora: [] });
    }
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Catálogo de Soluciones</h3>
      {autorizado ? (
        // <Link to={`/carga/precios`}>
        //   <button className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white" type="button">
        //     Carga Masiva de Precios
        //   </button>
        // </Link>
        <button
          className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
          type="button"
          onClick={() =>
            setUISweetAlert({
              ...UISweetAlert,
              confirm_carga_masiva: true,
            })
          }>
          Carga Masiva de Precios
        </button>
      ) : null}
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Soluciones</h4>

      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevaSolucion()}>
                      Nueva Solución
                    </button>
                  ) : null}
                  {autorizado || autorizadoAseguradora ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarSolucion()}>
                      Modificar Solución
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarSolucion()}>
                      Eliminar Solución
                    </button>
                  ) : null}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={productosPaginados}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nueva_solucion ? (
        <SweetAlert
          title="Nueva Solución"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            agregarNuevaSolucion();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nueva_solucion: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">
                    Número de Identificación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numIdentificacion"
                      autoComplete="off"
                      onChange={changeNuevaSolucion}
                      value={datosNuevaSolucion.numIdentificacion ? datosNuevaSolucion.numIdentificacion : ""}
                    />
                    {datosNuevaSolucion.numIdentificacion === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-5 col-form-label">Código EAN</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="codigoEAN"
                      autoComplete="off"
                      onChange={changeNuevaSolucion}
                      value={datosNuevaSolucion.codigoEAN ? datosNuevaSolucion.codigoEAN : ""}
                    />
                    {/* {datosNuevaSolucion.codigoEAN === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )} */}
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Descripción<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombreComercial"
                      autoComplete="off"
                      onChange={changeNuevaSolucion}
                      value={datosNuevaSolucion.nombreComercial ? datosNuevaSolucion.nombreComercial : ""}
                    />
                    {datosNuevaSolucion.nombreComercial === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  
                  <label className="col-sm-5 col-form-label">
                  Principio Activo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="principioActivo"
                      autoComplete="off"
                      onChange={changeNuevaSolucion}
                      value={datosNuevaSolucion.principioActivo ? datosNuevaSolucion.principioActivo : ""}
                    />
                    {datosNuevaSolucion.principioActivo === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-5 col-form-label">
                  Presentación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="presentacion"
                      autoComplete="off"
                      onChange={changeNuevaSolucion}
                      value={datosNuevaSolucion.presentacion ? datosNuevaSolucion.presentacion : ""}
                    />
                    {datosNuevaSolucion.presentacion === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>                  

                  <label className="col-sm-5 col-form-label">
                    Concentración<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="concentracion"
                      autoComplete="off"
                      onChange={changeNuevaSolucion}
                      value={datosNuevaSolucion.concentracion ? datosNuevaSolucion.concentracion : ""}
                    />
                    {datosNuevaSolucion.concentracion === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">

                <label className="col-sm-4 col-form-label">
                    Precio Aseguradoras<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="number"
                      className="form-control"
                      name="precioAseguradoras"
                      autoComplete="off"
                      onChange={changeNuevaSolucion}
                      value={datosNuevaSolucion.precioAseguradoras ? datosNuevaSolucion.precioAseguradoras : null}
                    />
                    {datosNuevaSolucion.precioAseguradoras === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>
                  <label className="col-sm-4 col-form-label">
                    Precio Mostrador y Médico<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="number"
                      className="form-control"
                      name="precioMostrador"
                      autoComplete="off"
                      onChange={changeNuevaSolucion}
                      value={datosNuevaSolucion.precioMostrador ? datosNuevaSolucion.precioMostrador : null}
                    />
                    {datosNuevaSolucion.precioMostrador === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-4 col-form-label">Proveedor</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatProveedor"
                      onChange={changeNuevaSolucion}
                      value={datosNuevaSolucion.idCatProveedor}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesProveedor.map((proveedor) => {
                        return <option value={proveedor.id}>{proveedor.nombre}</option>;
                      })}
                    </select>
                    {/* {datosNuevaSolucion.idCatProveedor === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )} */}
                  </Col>
                  <label className="col-sm-4 col-form-label">Fabricante</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatLaboratorio"
                      onChange={changeNuevaSolucion}
                      value={datosNuevaSolucion.idCatLaboratorio}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesFabricante.map((fabricante) => {
                        return <option value={fabricante.id}>{fabricante.nombre}</option>;
                      })}
                    </select>
                    {/* {datosNuevaSolucion.idCatLaboratorio === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )} */}
                  </Col>
                </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-4 col-form-label"></label>
                  <Col sm={7}>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDatosNuevaSolucion(datosSolucionVacio)}>
                      Limpiar Datos
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_eliminar_solucion ? (
        <SweetAlert
          title="¿Desea eliminar la solución seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: width && width < 851 ? "100%" : "50%",
          }}
          onConfirm={() => confirmEliminarSolucion()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_solucion: false,
            });
          }}></SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_solucion ? (
        <SweetAlert
          title="Modificar Datos de la solución"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            confirmModicarSolucion();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_solucion: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">
                    Número de Identificación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      disabled={editarIdentificacion? false: true}
                      type="text"
                      className="form-control"
                      name="numIdentificacion"
                      autoComplete="off"
                      onChange={changeModificarSolucion}
                      value={solucionSeleccionada.numIdentificacion ? solucionSeleccionada.numIdentificacion : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Código EAN</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="codigoEAN"
                      autoComplete="off"
                      onChange={changeModificarSolucion}
                      value={solucionSeleccionada.codigoEAN ? solucionSeleccionada.codigoEAN : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Descripción<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="nombreComercial"
                      autoComplete="off"
                      onChange={changeModificarSolucion}
                      value={solucionSeleccionada.nombreComercial ? solucionSeleccionada.nombreComercial : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Principio Activo<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="principioActivo"
                      autoComplete="off"
                      onChange={changeModificarSolucion}
                      value={solucionSeleccionada.principioActivo ? solucionSeleccionada.principioActivo : ""}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                <label className="col-sm-4 col-form-label">
                    Presentación<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="presentacion"
                      autoComplete="off"
                      onChange={changeModificarSolucion}
                      value={solucionSeleccionada.presentacion ? solucionSeleccionada.presentacion : ""}
                    />
                  </Col>                  

                  <label className="col-sm-4 col-form-label">
                    Concentración<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="concentracion"
                      autoComplete="off"
                      onChange={changeModificarSolucion}
                      value={solucionSeleccionada.concentracion ? solucionSeleccionada.concentracion : ""}
                    />
                  </Col>
                  <label className="col-sm-4 col-form-label">Proveedor</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatProveedor"
                      onChange={changeModificarSolucion}
                      value={solucionSeleccionada.idCatProveedor}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesProveedor.map((proveedor) => {
                        return <option value={proveedor.id}>{proveedor.nombre}</option>;
                      })}
                    </select>
                  </Col>
                  <label className="col-sm-4 col-form-label">Fabricante</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idCatLaboratorio"
                      onChange={changeModificarSolucion}
                      value={solucionSeleccionada.idCatLaboratorio}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {opcionesFabricante.map((fabricante) => {
                        return <option value={fabricante.id}>{fabricante.nombre}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_precios ? (
        <SweetAlert
          title={""}
          showCancel
          showConfirm
          confirmBtnText="Guardar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios_final: true,
            });
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios: false,
            });
            setSolucionPreciosActualizados({ preciosAseguradora: [] });
            setNuevoPrecio(null);
          }}>
          {preciosProducto.preciosProductoServicio || preciosProducto.preciosAseguradora.length > 0 ? (
            <>
              <h5 className="h5 mb-4">
                Nombre Comercial
                <p className="text-gray-600">{`${solucionSeleccionadaPrecios.numIdentificacion} - ${solucionSeleccionadaPrecios.nombreComercial}`}</p>
              </h5>
              {/* <h5 className="h5 mb-4">
                Principio Activo
                <p className="text-gray-600">{`${solucionSeleccionadaPrecios.principioActivo}`}</p>
              </h5> */}
              <table className="table table-hover table-centered mb-0">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Precio Actual</th>
                    <th scope="col">Nuevo Precio</th>
                  </tr>
                </thead>
                <tbody>
                  {preciosProducto.preciosProductoServicio ? (
                    <>
                      <tr key={preciosProducto.preciosProductoServicio.idPrecioProductoServicio}>
                        <td style={{ width: "40%" }}>Precio Máximo al Público</td>
                        <td style={{ width: "25%" }}>
                          <input
                            // value={`$${formatoMexico(preciosProducto.preciosProductoServicio.precioMostrador)}`}
                            value={formatNumber(preciosProducto.preciosProductoServicio.precioPublico)}
                            disabled={true}
                            type="text"
                            name="precioPublico"
                          />
                        </td>

                        <td>
                          <input
                            disabled={false}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="precioPublico"
                            onChange={(e) => changeNuevoPrecio(e)}
                            onBlur={() => setNuevoPrecioMedicamentoProductoServicio({ tipo: "precioPublico" })}
                          />
                        </td>
                      </tr>
                      <tr key={preciosProducto.preciosProductoServicio.idPrecioProductoServicio}>
                        <td>Precio Mostrador y Médico</td>
                        <td>
                          <input
                            // value={`$${formatoMexico(parseFloat(preciosProducto.preciosProductoServicio.precioMostrador))}`}
                            value={formatNumber(preciosProducto.preciosProductoServicio.precioMostrador)}
                            disabled={true}
                            type="text"
                            name="precioMostrador"
                          />
                        </td>
                        <td>
                          <input
                            disabled={false}
                            type="number"
                            name="precioMostrador"
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => changeNuevoPrecio(e)}
                            onBlur={() => setNuevoPrecioMedicamentoProductoServicio({ tipo: "precioMostrador" })}
                          />
                        </td>
                      </tr>
                    </>
                  ) : null}

                  {preciosProducto.preciosAseguradora.length > 0 &&
                    preciosProducto.preciosAseguradora.map((precio, index) => {
                      return (
                        <>
                          <tr key={precio.idPrecioAseguradora}>
                            <td style={{ width: "40%" }}>{precio.aseguradora ? precio.aseguradora : ""}</td>
                            <td style={{ width: "20%" }}>
                              <input
                                // value={`$${formatoMexico(precio.precioUnitario)}`}
                                value={formatNumber(precio.precioUnitario)}
                                disabled={true}
                                onWheel={(e) => e.target.blur()}
                                type="text"
                                name="precio"
                              />
                            </td>
                            <td style={{ width: "20%" }}>
                              <input
                                disabled={false}
                                type="number"
                                name="precio"
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => changeNuevoPrecio(e)}
                                onBlur={() => setNuevoPrecioSolucion(precio)}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </>
          ) : (
            // <Link to={`/carga/precios`}>
            //   <button className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white" type="button">
            //     Carga Masiva de Precios
            //   </button>
            // </Link>
            <button
              onClick={() => {
                setUISweetAlert({
                  ...UISweetAlert,
                  confirm_carga_masiva: true,
                });
              }}
              className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
              type="button">
              Carga Masiva de Precios
            </button>
          )}
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_carga_masiva ? (
        <SweetAlert
          title={"Carga Masiva de Precios"}
          showConfirm
          confirmBtnText="Salir"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_carga_masiva: false,
            });
          }}>
          <CargaMasivaModal disabled={true} idCatTipoProducto={4} />
        </SweetAlert>
      ) : null}

      {UISweetAlert.confirm_modificar_precios_final ? (
        <SweetAlert
          title={"¿Desea modificar los precios de la solución?"}
          showConfirm
          showCancel
          confirmBtnText="SI"
          confirmBtnBsStyle="success"
          cancelBtnText="NO"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => guardarPreciosSolucion()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_precios_final: false,
            });
          }}></SweetAlert>
      ) : null}
      
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default Soluciones;
