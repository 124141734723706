import React, { useReducer } from "react";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";

import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/tokenAuth";
import jwt from "jwt-decode";

import { OBTENER_USUARIO, LOGIN_EXITOSO, LOGIN_ERROR, CERRAR_SESION, TOKEN_EXPIRADO } from "../../types";

export default function AuthState(props) {
  // DATOS INICIALES CON LOS CUALES EMPEZAMOS EL ESTADO GLOBAL
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: null,
    usuario: null, // información del usuario
    mensaje: null, // relacionado con las alertas
    cargando: true,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Retorna el usuario autenticado
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token"); // LEER EL TOKEN DE LOCAL STORAGE
    if (token) {
      const decodeToken = jwt(token);
      // console.log(decodeToken)

      var dateNow = new Date();
      //Checamos si el token ya expiro, si ya expiro le pedimos al usuario que reingrese
      if (decodeToken.exp * 1000 < dateNow.getTime()) {
        //Mando la funcion sin el token, para que lo elimine del header del axios
        tokenAuth();

        dispatch({
          type: TOKEN_EXPIRADO,
          payload: "Token expirado",
        });
      } else {
        //Mando el token para que lo agregue al header
        tokenAuth(token);

        const res = await clienteAxios.get("/obtener/usuario", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        });

        res.data.authorities = decodeToken.authorities;

        //Aqui en el dispatch es donde guardaria el usuario decodificado del token
        dispatch({
          type: OBTENER_USUARIO,
          payload: res.data,
        });
      }
    } else {
      dispatch({
        type: LOGIN_ERROR,
      });
    }
  };

  // Cuando el usuario inicia sesión
  const iniciarSesion = async (datos) => {
    try {
      const res = await clienteAxios.post("/login", datos, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });
      // console.log(res)

      dispatch({
        type: LOGIN_EXITOSO,
        payload: res.data,
      });

      usuarioAutenticado();
      return res;
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: null,
      });

      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  // Cierra la sesión del usuario
  const cerrarSesion = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };

  const checkReCaptchaToken = async (value) => {
    try {
      const res = await clienteAxios.post(
        "https://www.google.com/recaptcha/api/siteverify",
        { secret: state.key, response: value },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        mensaje: state.mensaje,
        cargando: state.cargando,
        iniciarSesion,
        usuarioAutenticado,
        cerrarSesion,
        checkReCaptchaToken,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
}
