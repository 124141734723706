import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import LugaresContext from "../../context/Lugares/LugaresContext";
import OcupacionesContext from "../../context/Ocupaciones/OcupacionesContext";
import EscolaridadesContext from "../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../context/EstadosCiviles/EstadosCivilesContext";
import SexosContext from "../../context/Sexos/SexosContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";

const Pacientes = (props) => {
  const filtrosVacios = {
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    sexo: "",
    lugarOrigen: "",
    lugarResidencia: "",
    escolaridad: "",
    ocupacion: "",
    estadoCivil: "",
    fechaNacimiento: "",
    referencia: "",
    curp: "",
  };

  const datosPacienteVacio = {
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    idSexo: "",
    idLugarOrigen: "",
    idLugarResidencia: "",
    idEscolaridad: "",
    idOcupacion: "",
    idEstadoCivil: "",
    fechaNacimiento: "",
    referencia: "",
    curp: "",
  };

  const [datosNuevoPaciente, setDatosNuevoPaciente] = useState(datosPacienteVacio);
  const [pacienteSeleccionado, setPacienteSeleccionado] = useState(null);
  const [pacienteLog, setPacienteLog] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_paciente: false,
    confirm_nuevo_paciente: false,
    confirm_eliminar_paciente: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
  const [data, setData] = useState([
    {
      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      idSexo: "",
      idLugarOrigen: "",
      idLugarResidencia: "",
      idEscolaridad: "",
      idOcupacion: "",
      idEstadoCivil: "",
      fechaNacimiento: "",
      referencia: "",
      curp: "",
    },
  ]);
  const fetchIdRef = useRef(0);

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      setLoadingData(true);
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      // const esMedico = usuario.authorities.includes("ROLE_MEDICO") && !usuario.authorities.includes("ROLE_ADMIN_SISTEMA");

      const response = await setDataPaciente({ page, limit, idCatMedico: null, esMedico: false });

      const data = response.pacientes.map((paciente) => {
        if (paciente.fechaNacimiento) return { ...paciente, fechaNacimiento: paciente.fechaNacimiento.split("T")[0] };
        return paciente;
      });

      // Cantidad de datos disponibles en la tabla pacientes de la base de datos
      const count = response.count;

      setData(data);
      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server.
    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);

    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const handdleCheckedPaciente = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setPacienteSeleccionado({
          ...row.original,
          idSexo: row.original["sexo.idCatSexo"],
          idLugarOrigen: row.original["lugarOrigen.idCatOrigenResidencia"],
          idLugarResidencia: row.original["lugarResidencia.idCatOrigenResidencia"],
          idEscolaridad: row.original["escolaridad.idCatEscolaridad"],
          idOcupacion: row.original["ocupacion.idCatOcupacion"],
          idEstadoCivil: row.original["estadoCivil.idCatEstadoCivil"],
        });
        setPacienteLog({
          ...row.original,
          idSexo: row.original["sexo.idCatSexo"],
          idLugarOrigen: row.original["lugarOrigen.idCatOrigenResidencia"],
          idLugarResidencia: row.original["lugarResidencia.idCatOrigenResidencia"],
          idEscolaridad: row.original["escolaridad.idCatEscolaridad"],
          idOcupacion: row.original["ocupacion.idCatOcupacion"],
          idEstadoCivil: row.original["estadoCivil.idCatEstadoCivil"],
        });
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setPacienteSeleccionado(null);
      } else {
        setRowSeleccionada(row.id);
        setPacienteSeleccionado({
          ...row.original,
          idSexo: row.original["sexo.idCatSexo"],
          idLugarOrigen: row.original["lugarOrigen.idCatOrigenResidencia"],
          idLugarResidencia: row.original["lugarResidencia.idCatOrigenResidencia"],
          idEscolaridad: row.original["escolaridad.idCatEscolaridad"],
          idOcupacion: row.original["ocupacion.idCatOcupacion"],
          idEstadoCivil: row.original["estadoCivil.idCatEstadoCivil"],
        });
        setPacienteLog({
          ...row.original,
          idSexo: row.original["sexo.idCatSexo"],
          idLugarOrigen: row.original["lugarOrigen.idCatOrigenResidencia"],
          idLugarResidencia: row.original["lugarResidencia.idCatOrigenResidencia"],
          idEscolaridad: row.original["escolaridad.idCatEscolaridad"],
          idOcupacion: row.original["ocupacion.idCatOcupacion"],
          idEstadoCivil: row.original["estadoCivil.idCatEstadoCivil"],
        });        
      }
    },
    [rowSeleccionada]
  );

  // Se definen las columnas para la tabla de react-tabe
  // Se debe usar useMemo para optimización y porque lo pide la librería

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCatPaciente",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedPaciente(row)}
            />
          );
        },

        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Nombres", accessor: "nombres" },
      { Header: "Apellido Paterno", accessor: "apellidoPaterno" },
      { Header: "Apellido Materno", accessor: "apellidoMaterno" },
      { Header: "Sexo", accessor: "sexo.sexo" },
      { Header: "idSexo", accessor: "sexo.idCatSexo" },
      { Header: "Lugar Origen", accessor: "lugarOrigen.lugar" },
      { Header: "Id Lugar Origen", accessor: "lugarOrigen.idCatOrigenResidencia" },
      { Header: "Lugar Residencia", accessor: "lugarResidencia.lugar" },
      { Header: "Id Lugar Residencia", accessor: "lugarResidencia.idCatOrigenResidencia" },
      { Header: "Escolaridad", accessor: "escolaridad.escolaridad" },
      { Header: "Escolaridad", accessor: "escolaridad.idCatEscolaridad" },
      { Header: "Ocupación", accessor: "ocupacion.ocupacion" },
      { Header: "Ocupación", accessor: "ocupacion.idCatOcupacion" },
      { Header: "Estado Civil", accessor: "estadoCivil.estadoCivil" },
      { Header: "Estado Civil", accessor: "estadoCivil.idCatEstadoCivil" },
      { Header: "Fecha Nacimiento", accessor: "fechaNacimiento" },
      { Header: "Referencia", accessor: "referencia" },
      { Header: "CURP", accessor: "curp" },
    ],
    [rowSeleccionada, handdleCheckedPaciente]
  );

  const pacientesContext = useContext(PacientesContext);
  const lugaresContext = useContext(LugaresContext);
  const ocupacionesContext = useContext(OcupacionesContext);
  const escolaridadesContext = useContext(EscolaridadesContext);
  const estadosCivilesContext = useContext(EstadosCivilesContext);
  const sexosContext = useContext(SexosContext);

  //ACCESO AL ESTADO GLOBAL - AUTENTICACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const { lugares, listarLugares } = lugaresContext;
  const { ocupaciones, listarOcupaciones } = ocupacionesContext;
  const { escolaridades, listarEscolaridades } = escolaridadesContext;
  const { estadosCiviles, listarEstadosCiviles } = estadosCivilesContext;
  const { sexos, listarSexos } = sexosContext;

  const {
    setFiltrosPacientes,
    filtrosPacientes,
    uploadNuevoPaciente,
    eliminarPacienteLog,
    eliminarPaciente,
    setDataPaciente,
    updatePaciente,
    setIdCatPacienteSeleccionado,
  } = pacientesContext;

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  const autorizado =
    usuario.authorities.includes("OP_CATALOGO_PACIENTE_CREAR") ||
    usuario.authorities.includes("OP_CATALOGO_PACIENTE_MODIFICAR") ||
    usuario.authorities.includes("ROLE_MEDICO") ||
    usuario.authorities.includes("ROLE_QUIMIO") ||
    usuario.authorities.includes("OP_CATALOGO_PACIENTE_ELIMINAR");

  const autorizadoVerPaciente =
    usuario.authorities.includes("OP_CATALOGO_PACIENTE_MODIFICAR") ||
    usuario.authorities.includes("ROLE_MEDICO") ||
    usuario.authorities.includes("ROLE_CENTRO_MEZCLAS") ||
    usuario.authorities.includes("ROLE_QUIMIO");

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function setDataPaciente
     * @param {object} filtrosPacientes Filtros enviados al back para filtrar los pacientes
     * @param {int} page Página a regresar de pacientes
     * @param {int} limit Número de pacientes que se van a regresar por llamada al back
     */

    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosPacientes(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltrosPacientes({
      ...filtrosPacientes,
      [name]: value,
    });
  };

  const changeNuevoPaciente = (e) => {
    const { name, value } = e.target;
    setDatosNuevoPaciente({
      ...datosNuevoPaciente,
      [name]: value,
    });
  };

  const changeModificarPaciente = (e) => {
    const { name, value } = e.target;
    setPacienteSeleccionado({
      ...pacienteSeleccionado,
      [name]: value,
    });
  };

  const lugaresOpciones = lugares.map(({ idCatOrigenResidencia, lugar, ...origenResidencia }) => ({
    ...origenResidencia,
    id: idCatOrigenResidencia,
    nombre: lugar,
  }));

  const opcionesOcupacion = ocupaciones.map(({ idCatOcupacion, ocupacion, ...ocupaciones }) => ({
    ...ocupaciones,
    id: idCatOcupacion,
    nombre: ocupacion,
  }));

  const opcionesEscolaridad = escolaridades.map(({ idCatEscolaridad, escolaridad, ...escolaridades }) => ({
    ...escolaridades,
    id: idCatEscolaridad,
    nombre: escolaridad,
  }));

  const opcionesCivil = estadosCiviles.map(({ idCatEstadoCivil, estadoCivil, ...estadosCiviles }) => ({
    ...estadosCiviles,
    id: idCatEstadoCivil,
    nombre: estadoCivil,
  }));

  const opcionesSexo = sexos.map(({ idCatSexo, sexo, ...rest }) => ({
    ...rest,
    id: idCatSexo,
    nombre: sexo,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Nombre",
          type: "text",
          name: "nombres",
          value: filtrosPacientes.nombres,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Apellido Paterno",
          type: "text",
          name: "apellidoPaterno",
          value: filtrosPacientes.apellidoPaterno,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Apellido Materno",
          type: "text",
          name: "apellidoMaterno",
          value: filtrosPacientes.apellidoMaterno,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Sexo",
          name: "sexo",
          value: filtrosPacientes.sexo,
          opciones: opcionesSexo,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Lugar Origen",
          name: "lugarOrigen",
          value: filtrosPacientes.lugarOrigen,
          opciones: lugaresOpciones,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Lugar Residencia",
          name: "lugarResidencia",
          value: filtrosPacientes.lugarResidencia,
          opciones: lugaresOpciones,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "select",
          label: "Escolaridad",
          name: "escolaridad",
          value: filtrosPacientes.escolaridad,
          opciones: opcionesEscolaridad,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Ocupación",
          name: "ocupacion",
          value: filtrosPacientes.ocupacion,
          opciones: opcionesOcupacion,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Estado Civil",
          name: "estadoCivil",
          value: filtrosPacientes.estadoCivil,
          opciones: opcionesCivil,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Fecha de Nacimiento",
          type: "date",
          name: "fechaNacimiento",
          value: filtrosPacientes.fechaNacimiento,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Referencia",
          type: "text",
          name: "referencia",
          value: filtrosPacientes.referencia,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "CURP",
          type: "text",
          name: "curp",
          value: filtrosPacientes.curp,
          onChange: onChange,
        },
      ],
    },
  ];

  const validarDatosPaciente = (paciente) => {
    let errorNuevoPaciente = false;
    if (!errorNuevoPaciente && (paciente.nombres === "" || paciente.nombres === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el nombre del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.apellidoPaterno === "" || paciente.apellidoPaterno === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el apellido paterno del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.apellidoMaterno === "" || paciente.apellidoMaterno === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el apellido materno del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.sexo === "" || paciente.sexo === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el sexo del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.lugarOrigen === "" || paciente.lugarOrigen === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el lugar de origen del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.lugarResidencia === "" || paciente.lugarResidencia === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el lugar de residencia del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.escolaridad === "" || paciente.escolaridad === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la escolaridad del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.ocupacion === "" || paciente.ocupacion === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la ocupacion del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.estadoCivil === "" || paciente.estadoCivil === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la estado civil del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.fechaNacimiento === "" || paciente.fechaNacimiento === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la fecha de nacimiento del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.referencia === "" || paciente.referencia === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese la referencia del paciente",
      });
    }
    if (!errorNuevoPaciente && (paciente.curp === "" || paciente.curp === null)) {
      errorNuevoPaciente = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        confirm_alert_actualizar_fecha: false,
        dynamic_description: "Por favor, ingrese el CURP del paciente",
      });
    }

    return errorNuevoPaciente;
  };

  const confirmNuevoPaciente = () => {
    if (autorizado) {
      // setUISweetAlert({
      //   ...UISweetAlert,
      //   confirm_nuevo_paciente: true,
      // });
      setIdCatPacienteSeleccionado(null);
      props.history.push("/historia/clinica/inicial");
    }
  };

  const agregarNuevoPaciente = async () => {
    const pacienteValido = validarDatosPaciente(datosNuevoPaciente);

    if (!pacienteValido) {
      setLoading(true);

      const res = await uploadNuevoPaciente(datosNuevoPaciente);

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nuevo_paciente: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nuevo_paciente: false,
          dynamic_description: "Se guardó el nuevo paciente correctamente",
        });
        setData([...data, res.data]);
        setDatosNuevoPaciente(datosPacienteVacio);
      }
    }
  };

  const updateDataOnDeletePaciente = async (idCatPaciente) => {
    const updatedData = data.filter((paciente) => paciente.idCatPaciente !== idCatPaciente);
    setData(updatedData);
  };

  const checkEliminarPaciente = () => {
    if (autorizado && pacienteSeleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_paciente: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un paciente",
      });
    }
  };

  const confirmEliminarPaciente = async () => {
    setLoading(true);

    let cadenaLogLocal = ""
    let folio = pacienteSeleccionado.idCatPaciente;
    cadenaLogLocal = cadenaLogLocal+ "Eliminart:ID[" +  folio +"],Paciente[" + pacienteSeleccionado.nombres + " " + pacienteSeleccionado.apellidoPaterno +  " " + pacienteSeleccionado.apellidoMaterno + "]";

    pacienteSeleccionado.logVo = cadenaLogLocal.length==0? null:{
      idUsuario: usuario.idUsuario,
      modulo: "Pacientes",
      actividad:cadenaLogLocal,
    };

    const res = await eliminarPaciente(pacienteSeleccionado.idCatPaciente);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_paciente: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      eliminarPacienteLog(pacienteSeleccionado.logVo);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_paciente: false,
        dynamic_description: "Se eliminó el paciente correctamente",
      });
      updateDataOnDeletePaciente(pacienteSeleccionado.idCatPaciente);
      setPacienteSeleccionado(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarPaciente = () => {
    if (autorizadoVerPaciente && pacienteSeleccionado) {
      // setUISweetAlert({
      //   ...UISweetAlert,
      //   confirm_modificar_paciente: true,
      // });
      setIdCatPacienteSeleccionado(pacienteSeleccionado.idCatPaciente);
      props.history.push("/historia/clinica/inicial");
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un paciente",
      });
    }
  };

  const confirmModicarPaciente = async () => {
    const pacienteValido = validarDatosPaciente(pacienteSeleccionado);

    if (!pacienteValido) {
      setLoading(true);
      const res = await updatePaciente(pacienteSeleccionado);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_paciente: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_paciente: false,
          dynamic_description: "Se modificó el paciente correctamente",
        });
        const pacientesActualizados = await data.map((paciente) => {
          if (res.data.idCatPaciente == paciente.idCatPaciente) {
            return (paciente = res.data);
          } else {
            return paciente;
          }
        });

        const dataActualizada = await pacientesActualizados.map((paciente) => {
          return { ...paciente, fechaNacimiento: paciente.fechaNacimiento.split("T")[0] };
        });

        setData(dataActualizada);
        setPacienteSeleccionado(null);
        setRowSeleccionada(null);
      }
    }
  };

  // const checkPadecimientoActual = async () => {
  //   if (autorizado && pacienteSeleccionado) {
  //     setIdCatPacienteSeleccionado(pacienteSeleccionado.idCatPaciente);
  //     props.history.push("/padecimiento/actual");
  //   } else {
  //     setUISweetAlert({
  //       ...UISweetAlert,
  //       error_dlg: true,
  //       dynamic_title: "Fallo",
  //       dynamic_description: "Por favor, seleccione un paciente",
  //     });
  //   }
  // };

  useEffect(() => {
    const fetchAllData = () => {
      listarLugares();
      listarOcupaciones();
      listarEscolaridades();
      listarEstadosCiviles();
      listarSexos();
    };
    fetchAllData();
  }, []);

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Catálogo de Pacientes</h3>
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de Pacientes</h4>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {usuario.authorities.includes("OP_CATALOGO_PACIENTE_CREAR") || usuario.authorities.includes("ROLE_QUIMIO") ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevoPaciente()}>
                      Nuevo Paciente
                    </button>
                  ) : null}
                  {autorizadoVerPaciente ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarPaciente()}>
                      Modificar Paciente
                    </button>
                  ) : null}
                  {usuario.authorities.includes("OP_CATALOGO_PACIENTE_ELIMINAR") ||
                  usuario.authorities.includes("ROLE_QUIMIO") ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkEliminarPaciente()}>
                      Eliminar Paciente
                    </button>
                  ) : null}
                  {/* {usuario.authorities.includes("OP_CATALOGO_PACIENTE_MODIFICAR") ||
                  usuario.authorities.includes("ROLE_QUIMIO") ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      hidden={!usuario.authorities.includes("ROLE_MEDICO")}
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkPadecimientoActual()}>
                      Padecimiento Actual
                    </button>
                  ) : null} */}
                </Col>
              </Row>
              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={data}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nuevo_paciente ? (
        <SweetAlert
          title="Nuevo Paciente"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          showCancel
          style={{
            width: "60%",
          }}
          onConfirm={() => {
            agregarNuevoPaciente();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nuevo_paciente: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <div>
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">Nombre</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="nombres"
                  autoComplete="off"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.nombres ? datosNuevoPaciente.nombres : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Apellido Paterno</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="apellidoPaterno"
                  autoComplete="off"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.apellidoPaterno ? datosNuevoPaciente.apellidoPaterno : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Apellido Materno</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="apellidoMaterno"
                  autoComplete="off"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.apellidoMaterno ? datosNuevoPaciente.apellidoMaterno : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Sexo</label>
              <Col sm={7}>
                <select className="form-control" name="idSexo" onChange={changeNuevoPaciente} value={datosNuevoPaciente.idSexo}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {opcionesSexo.map((sexo) => {
                    return <option value={sexo.id}>{sexo.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Lugar Origen</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idLugarOrigen"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.idLugarOrigen}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {lugaresOpciones.map((lugar) => {
                    return <option value={lugar.id}>{lugar.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Lugar Residencia</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idLugarResidencia"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.idLugarResidencia}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {lugaresOpciones.map((lugar) => {
                    return <option value={lugar.id}>{lugar.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Escolaridad</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idEscolaridad"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.idEscolaridad}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {opcionesEscolaridad.map((escolaridad) => {
                    return <option value={escolaridad.id}>{escolaridad.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Ocupación</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idOcupacion"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.idOcupacion}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {opcionesOcupacion.map((ocupacion) => {
                    return <option value={ocupacion.id}>{ocupacion.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Estado Civil</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idEstadoCivil"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.idEstadoCivil}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {opcionesCivil.map((estadoCivil) => {
                    return <option value={estadoCivil.id}>{estadoCivil.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Fecha Nacimiento</label>
              <Col sm={7}>
                <input
                  type="date"
                  className="form-control"
                  name="fechaNacimiento"
                  autoComplete="off"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.fechaNacimiento ? datosNuevoPaciente.fechaNacimiento : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Referencia</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="referencia"
                  autoComplete="off"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.referencia ? datosNuevoPaciente.referencia : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">CURP</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="curp"
                  autoComplete="off"
                  onChange={changeNuevoPaciente}
                  value={datosNuevoPaciente.curp ? datosNuevoPaciente.curp : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label"></label>
              <Col sm={7}>
                <button
                  className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                  type="button"
                  style={{
                    background: "#ff9933",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  onClick={() => setDatosNuevoPaciente(datosPacienteVacio)}>
                  Limpiar Datos
                </button>
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}
      
      {UISweetAlert.confirm_eliminar_paciente ? (
        <SweetAlert
          title="¿Desea eliminar al paciente seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => confirmEliminarPaciente()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_paciente: false,
            });
          }}
        />
      ) : null}

      {UISweetAlert.confirm_modificar_paciente ? (
        <SweetAlert
          title="Modificar Datos del Paciente"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          showCancel
          style={{
            width: "45%",
          }}
          onConfirm={() => {
            confirmModicarPaciente();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_paciente: false,
            });
          }}>
          <div>
            <Row className="form-group mt-3">
              <label className="col-sm-4 col-form-label">Nombre</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="nombres"
                  autoComplete="off"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.nombres ? pacienteSeleccionado.nombres : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Apellido Paterno</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="apellidoPaterno"
                  autoComplete="off"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.apellidoPaterno ? pacienteSeleccionado.apellidoPaterno : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Apellido Materno</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="apellidoMaterno"
                  autoComplete="off"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.apellidoMaterno ? pacienteSeleccionado.apellidoMaterno : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Sexo</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="sexo"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.idSexo}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {opcionesSexo.map((sexo) => {
                    return <option value={sexo.id}>{sexo.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Lugar Origen</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idLugarOrigen"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.idLugarOrigen}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {lugaresOpciones.map((lugar) => {
                    return <option value={lugar.id}>{lugar.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Lugar Residencia</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idLugarResidencia"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.idLugarResidencia}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {lugaresOpciones.map((lugar) => {
                    return <option value={lugar.id}>{lugar.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Escolaridad</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idEscolaridad"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.idEscolaridad}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {opcionesEscolaridad.map((escolaridad) => {
                    return <option value={escolaridad.id}>{escolaridad.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Ocupación</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idOcupacion"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.idOcupacion}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {opcionesOcupacion.map((ocupacion) => {
                    return <option value={ocupacion.id}>{ocupacion.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Estado Civil</label>
              <Col sm={7}>
                <select
                  className="form-control"
                  name="idEstadoCivil"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.idEstadoCivil}>
                  <option selected disabled value="">
                    Seleccione una opción
                  </option>
                  {opcionesCivil.map((estadoCivil) => {
                    return <option value={estadoCivil.id}>{estadoCivil.nombre}</option>;
                  })}
                </select>
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Fecha Nacimiento</label>
              <Col sm={7}>
                <input
                  type="date"
                  className="form-control"
                  name="fechaNacimiento"
                  autoComplete="off"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.fechaNacimiento ? pacienteSeleccionado.fechaNacimiento : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">Referencia</label>
              <Col sm={7}>
                <input
                  type="text"
                  className="form-control"
                  name="referencia"
                  autoComplete="off"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.referencia ? pacienteSeleccionado.referencia : ""}
                />
              </Col>
            </Row>
            <Row className="form-group mt-2">
              <label className="col-sm-4 col-form-label">CURP</label>
              <Col sm={7}>
                <input
                  disabled={true}
                  type="text"
                  className="form-control"
                  name="curp"
                  autoComplete="off"
                  onChange={changeModificarPaciente}
                  value={pacienteSeleccionado.curp ? pacienteSeleccionado.curp : ""}
                />
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : null}

      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default Pacientes;
