import React, { useReducer } from "react";

import ProveedoresContext from "./ProveedoresContext";
import ProveedoresReducer from "./ProveedoresReducer";

import { LISTAR_PROVEEDORES, SET_PROVEEDORES_PAGINADAS, FILTRO_PROVEEDORES } from "../../types";

import clienteAxios from "../../config/axios";

export default function ProveedoresState(props) {
  const initialState = {
    proveedores: [],
    proveedoresPaginados: [],
    filtrosProveedores: {
      razonSocial: "",
      contacto: "",
      idCatTipoCompra: "",
    },
  };

  const [state, dispatch] = useReducer(ProveedoresReducer, initialState);

  const setDataProveedor = async ({ page, limit }) => {
    try {
      const response = await clienteAxios.post(
        "/proveedores/paginado/",
        {
          pageSize: limit,
          pageIndex: page,
          ...state.filtrosProveedores,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      dispatch({
        type: SET_PROVEEDORES_PAGINADAS,
        payload: response.data.proveedores,
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const listarProveedores = async () => {
    const res = await clienteAxios.get("/proveedores/activos", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({
      type: LISTAR_PROVEEDORES,
      payload: res.data,
    });
  };

  const listarProveedoresSimple = async () => {
    const res = await clienteAxios.get("/proveedores/activos/ordenes/compra", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Cache-Control': 'no-store', // Evita el caché del navegador
      },
    });
    return res.data;
  };

  const setFiltrosProveedores = async (filtrosProveedores) => {
    dispatch({
      type: FILTRO_PROVEEDORES,
      payload: filtrosProveedores,
    });
  };

  const uploadNuevoProveedor = async (datosProveedor) => {
    // Checar que no la aseguradora no exista en la base de datos

    try {
      const res = await clienteAxios.post(`/proveedores/guardar`, datosProveedor, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const eliminarProveedor = async (idCatProveedor) => {
    try {
      const response = await clienteAxios.post(
        "/proveedores/eliminar/",
        { idCatProveedor: idCatProveedor },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Cache-Control': 'no-store', // Evita el caché del navegador
          },
        }
      );

      return response;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  const updateProveedor = async (proveedorModificado) => {
    try {
      const res = await clienteAxios.post(`/proveedores/guardar`, proveedorModificado, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          'Cache-Control': 'no-store', // Evita el caché del navegador
        },
      });

      return res;
    } catch (error) {
      if (!error.response) {
        error.response = {
          data: {
            message: "Fallo en la conexión al servidor",
            error: "hubo error",
          },
        };
      }

      return error.response;
    }
  };

  return (
    <ProveedoresContext.Provider
      value={{
        proveedores: state.proveedores,
        proveedoresPaginados: state.proveedoresPaginados,
        filtrosProveedores: state.filtrosProveedores,
        listarProveedores,
        setDataProveedor,
        setFiltrosProveedores,
        updateProveedor,
        eliminarProveedor,
        uploadNuevoProveedor,
        listarProveedoresSimple,
      }}>
      {props.children}
    </ProveedoresContext.Provider>
  );
}
