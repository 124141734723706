import React, {useState, useEffect, useCallback, useMemo, useRef, useContext} from "react";
import {Row, Col, Spinner, Card, CardBody} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import {Alert, Button, Space} from "antd";
import formatNumber from "../../utils/index";

import AuthContext from "../../context/Auth/AuthContext";
import ClientesContext from "../../context/Clientes/ClientesContext";
import PacientesContext from "../../context/Pacientes/PacientesContext";
import AseguradorasContext from "../../context/Aseguradoras/AseguradorasContext";
import MedicosContext from "../../context/Medicos/MedicosContext";
import CatTipoPagoContext from "../../context/CatTipoPago/CatTipoPagoContext";
import FacturaContext from "../../context/Factura/FacturaContext";


import DayjsUtils from "@date-io/dayjs";
import "dayjs/locale/es";

import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import RespuestaFacturas from "../RespuestaFacturas";
import SelectAsyncPaginate from "../SelectAsyncPaginate";

import useWindowDimensions from "../../hooks/dimensionsHook";
import {opcionesSelectPacientes} from "../../config/configOpcionesSelect";
import useInvoiceActions from "../../hooks/useInvoiceActions";

export default function DocumentosContables(props) {
    const {width} = useWindowDimensions();

    const [UISweetAlert, setUISweetAlert] = useState({
        confirm_alert_cancelar_complemento_pago: false,
        confirm_alert_cancelar_factura: false,
        confirm_alert_complementos_pago: false,
        confirm_alert_timbrar_factura: false,
        confirm_alert_nuevo_complemento_pago: false,
        respuestas_cancelar_facturas: false,
        respuestas_facturas: false,
        errorTimbrado: false,
        editar_espacios: false,
        success_dlg: false,
        error_dlg: false,
        redirect: false,
        path: "",
        dynamic_title: "",
        dynamic_description: "",
    });

    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [controlledPageIndex, setControlledPageIndex] = React.useState(0);
    const [rowSeleccionada, setRowSeleccionada] = useState(null);
    const fetchIdRef = useRef(0);

    const [toggleSearchBy, setToggleSearchBy] = useState(false);
    const [flagTipoVentaFiltradas, setFlagTipoVentaFiltradas] = useState(false);
    const [tipoVentas, setTipoVentas] = useState([]);
    const [complementosPagoSeleccionados, setComplementosPagoSeleccionados] = useState([]);
    const [complementoPagoSeleccionado, setComplementoPagoSeleccionado] = useState({});
    const [facturaSeleccionada, setFacturaSeleccionada] = useState(null);
    const [respuestaFacturas, setRespuestaFacturas] = useState(null);
    const [pacienteCompleto, setPacienteCompleto] = useState(null);
    const [respuestaCancelar, setRespuestaCancelar] = useState(null);
    const [errorCancelar, setErrorCancelar] = useState(false);
    const [errorTimbrado,setErrorTimbrado] = useState("");

    const {
        downloadFactura
    } = useInvoiceActions({
        setLoading,
        UISweetAlert,
        setUISweetAlert,
        setRespuestaFacturas
    });

    const tipoDeVentasClean = [
        {
            tipoVenta: "Vales Particulares",
        },
        {
            tipoVenta: "Vales Aseguradora",
        },
        {
            tipoVenta: "Venta Mostrador Apartado",
        },
        {
            tipoVenta: "Venta Mostrador Completa",
        },
    ];

    const filtrosVacios = {
        complementoPago: "",
        fechaInicial: "",
        fechaFinal: "",
        metodoPago: "",
        tipoVenta: "",
        cliente: "",
        paciente: "",
        aseguradora: "",
        medico: "",
        folioVale: "",
        folioVentaMostrador: "",
        folioFactura: "",
    };

    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    const clientesContext = useContext(ClientesContext);
    const {clientes, listarClientes} = clientesContext;

    const pacientesContext = useContext(PacientesContext);
    const {listarPacientes, loadPacientesSelect} = pacientesContext;

    const asgContext = useContext(AseguradorasContext);
    const {aseguradoras, listarAseguradoras} = asgContext;

    const medicosContext = useContext(MedicosContext);
    const {medicos, listarMedicos} = medicosContext;

    const tipoPagoContext = useContext(CatTipoPagoContext);
    const {tipoPagos, listarCatTipoPagos} = tipoPagoContext;
    const esAseguradora =  usuario.authorities.includes("ROLE_ASEGURADORAS")?true:false;
    const esAdminitrador = usuario.authorities.includes("ROLE_ADMIN_SISTEMA")?true:false;

    const facturasContext = useContext(FacturaContext);
    const {
        documentosContables,
        filtrosDocumentosContables,
        obtenerDocumentosContables,
        setFiltrosDocumentosContables,
        listarMotivosCancelacion,
        motivosCancelacion,
        uploadCancelarFactura,
        timbrarFactura,
    } = facturasContext;

    const handdleCheckedRow = useCallback(
        (row) => {
            if (rowSeleccionada === null) {
                setRowSeleccionada(row.id);
                setFacturaSeleccionada({
                    ...row.original,
                });
            } else if (rowSeleccionada === row.id) {
                setRowSeleccionada(null);
                setFacturaSeleccionada(null);
            } else {
                setRowSeleccionada(row.id);
                setFacturaSeleccionada({
                    ...row.original,
                });
            }
        },
        [rowSeleccionada]
    );

    const fetchData = async ({pageSize, pageIndex}) => {
        const fetchId = ++fetchIdRef.current;
        setLoadingData(true);
        if (fetchId === fetchIdRef.current) {
            fetchAPIData({
                page: pageIndex + 1,
                limit: pageSize,
                byBusquedaFiltros: false,
            });
        }
    };

    const fetchAPIData = async ({page, limit, byBusquedaFiltros}) => {
        try {
            setLoadingData(true);
            if (byBusquedaFiltros) {
                setControlledPageIndex(0);
            } else {
                setControlledPageIndex(page - 1);
            }
            const response = await obtenerDocumentosContables({page, limit});

            // console.log(response);
            const count = response.data.count;
            setRowSeleccionada(null);
            setFacturaSeleccionada(null);

            if (count % limit > 0) {
                setPageCount(Math.ceil(count / limit));
            } else {
                setPageCount(count / limit);
            }
            setLoadingData(false);
        } catch (e) {
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "idFactura",
                Cell: ({row}) => {
                    return (
                        <input
                            // disabled={rowSeleccionada === null || rowSeleccionada === row.original.idFactura ? false : true}
                            checked={rowSeleccionada === row.id ? true : false}
                            type="checkbox"
                            onChange={() => handdleCheckedRow(row)}
                        />
                    );
                },
                disableFilters: true,
                disableSortBy: true,
            },
            {Header: "Folio", accessor: "folioFactura"},
            {Header: "Serie", accessor: "serie"},
            {Header: "Folio Fiscal", accessor: "folioFiscal"},
            {
                Header: "Fecha",
                accessor: "fechaFactura",
                Cell: ({row}) => {
                    return `${row.original.fechaFactura.split("T")[0].split("-")[2]}/${
                        row.original.fechaFactura.split("T")[0].split("-")[1]
                    }/${row.original.fechaFactura.split("T")[0].split("-")[0]}`;
                },
            },
            {Header: "Responsable", accessor: "usuario"},
            {Header: "Documento", accessor: "catMetodoPago.metodoPago"},
            {Header: "Tipo Venta", accessor: "tipoVenta"},
            {
                Header: "Monto",
                accessor: "monto",
                Cell: ({row}) => {
                    return <>{row.original.monto ? formatNumber(row.original.monto) : ""}</>;
                },
            },
            {
                Header: "Iva",
                accessor: "iva",
                Cell: ({row}) => {
                    return <>{row.original.iva ? formatNumber(row.original.iva) : ""}</>;
                },
            },
            {
                Header: "Paciente / Cliente",
                accessor: "catClientes.nombres",
                Cell: ({row}) => {
                    return (
                        <>
                            {row.original.catClientes &&
                                `${row.original.catClientes.nombres ?? ""} ${row.original.catClientes.apellidoPaterno ?? ""} ${
                                    row.original.catClientes.apellidoMaterno ?? ""
                                }`}
                        </>
                    );
                },
            },
            // { Header: "Paciente / Cliente", accessor: "catClientes.nombres" },
            {Header: "Aseguradora", accessor: "catAseguradora.razonSocial"},
            {
                Header: "Médico",
                accessor: "catMedico.nombres",
                Cell: ({row}) => {
                    return (
                        <>
                            {row.original.catMedico &&
                                `${row.original.catMedico.nombres ?? ""} ${row.original.catMedico.apellidoPaterno ?? ""} ${
                                    row.original.catMedico.apellidoMaterno ?? ""
                                }`}
                        </>
                    );
                },
            },
            {
                Header: "Ver errores",
                accessor: "erroresTimbrado",
                Cell: ({row}) => {
                    return (
                        <button
                            hidden={row.original.folioFiscal !=null}
                            onClick={() => {
                                setErrorTimbrado(row.original.mensaje);
                                setUISweetAlert({
                                    ...UISweetAlert,
                                    errorTimbrado: true,
                                });
                            }}
                            className="btn btn-primary-orange-umo w-md waves-effect waves-light">
                            Ver Errores
                        </button>
                    );
                },
            },            
            {
                Header: "Complemento de Pago",
                accessor: "complementosPago",
                Cell: ({row}) => {
                    return (
                        <button
                            hidden={row.original.complementosPago.length < 1}
                            onClick={() => {
                                setComplementosPagoSeleccionados(row.original.complementosPago);
                                setUISweetAlert({
                                    ...UISweetAlert,
                                    confirm_alert_complementos_pago: true,
                                });
                            }}
                            className="btn btn-primary-orange-umo w-md waves-effect waves-light">
                            Complementos
                        </button>
                    );
                },
            },
        ],
        [rowSeleccionada, handdleCheckedRow]
    );

    const onChange = async (e, name) => {
        if (name && name == "paciente") {
            setPacienteCompleto(e);
            setFiltrosDocumentosContables({
                ...filtrosDocumentosContables,
                [name]: e.idCatPaciente,
            });
        } else {
            let value = e.target.value;
            if (e.target.name == "complementoPago") {
                setFiltrosDocumentosContables({
                    ...filtrosDocumentosContables,
                    complementoPago: !filtrosDocumentosContables.complementoPago,
                });
            } else if (e.target.name == "folioFactura") {
                if (value < 0) {
                    value = value * -1;
                }
                setFiltrosDocumentosContables({
                    ...filtrosDocumentosContables,
                    [e.target.name]: value,
                });
            } else {
                setFiltrosDocumentosContables({
                    ...filtrosDocumentosContables,
                    [e.target.name]: value,
                });
            }
        }
    };

    const buscarPorFiltros = async (e) => {
        e.preventDefault();
        fetchData({pageSize: 10, pageIndex: 0});
    };

    const limpiarFiltros = () => {
        setPacienteCompleto(null);
        setFiltrosDocumentosContables(filtrosVacios);
    };

    const toggleSearch = () => {
        limpiarFiltros();
        setToggleSearchBy(!toggleSearchBy);
    };

    const buscarPorFolio = (e) => {
        e.preventDefault();
        fetchData({pageSize: 10, pageIndex: 0});
    };

    const pruebas = async () => {
        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_nuevo_complemento_pago: true,
        });
    }

    const nuevoComplementoPago = async () => {
        if (!rowSeleccionada) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione la factura",
            });
            return;
        }
        let documentoContable = documentosContables.filter((doc) => doc.idFactura == facturaSeleccionada.idFactura)[0];

        if (documentoContable.catMetodoPago.idCatMetodoPago == 1) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "La factura seleccionada es de tipo PUE y no se pueden generar complementos de pago",
            });
            return;
        }

        let sumaMontosComplementos = 0;

        documentoContable.complementosPago.map((complemento) => {
            sumaMontosComplementos += complemento.monto;
        });

        if (sumaMontosComplementos >= documentoContable.monto + documentoContable.iva) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Ya se completo el total de la factura con los complementos de pago.",
            });
            return;
        }

        let porcentajeIva = (documentoContable.iva / (documentoContable.monto + documentoContable.iva)).toFixed(2);
        let porcentajeMonto = (1 - porcentajeIva).toFixed(2);

        setComplementoPagoSeleccionado({
            fechaEmision: new Date().toISOString().slice(0, 10),
            montoRestante: documentoContable.monto + documentoContable.iva - sumaMontosComplementos,
            porcentajeIva: porcentajeIva,
            porcentajeMonto: porcentajeMonto,
        });

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_nuevo_complemento_pago: true,
        });
    };

    const onchangeCancelacionComplemento = async (e) => {
        setComplementoPagoSeleccionado({
            ...complementoPagoSeleccionado,
            [e.target.name]: e.target.value,
        });
    };

    const redirectNuevaFactura = async () => {
        props.history.push("/nueva/factura");
    };

    const checkTimbrarFactura = async () => {
        if (!rowSeleccionada) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione una factura",
            });
            return;
        } else {

            if(!esAseguradora && facturaSeleccionada.tipoVenta == 'Vale Aseguradora' && !esAdminitrador){
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Su perfil no puede timbrar un vale de aseguradora.",
                });
                return;
            }

            if(esAseguradora && facturaSeleccionada.tipoVenta != 'Vale Aseguradora' && !esAdminitrador){
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "Su perfil solo puede timbrar vales de aseguradora.",
                });
                return;
            }

            if (facturaSeleccionada && !facturaSeleccionada.folioFiscal) {
                setUISweetAlert({
                    ...UISweetAlert,
                    confirm_alert_timbrar_factura: true,
                });
            } else {
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "La factura seleccionada no puede ser timbrada nuevamente.",
                });
            }
        }
    };

    const cancelarFactura = async () => {
        if (!rowSeleccionada) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione una factura",
            });
            return;
        }

        if(facturaSeleccionada.folioFiscal ==null){
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description:
                    "La factura seleccionada no ha sido timbrada, por lo cual no puede ser cancelada.",
            });
            return;
        }


        let documentoContable = documentosContables.filter((doc) => doc.idFactura == facturaSeleccionada.idFactura)[0];

        if (documentoContable.complementosPago.length > 0) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description:
                    "La factura seleccionada tiene complementos de pagos, por favor, cancele individualmente los complementos",
            });
            return;
        }

        setUISweetAlert({
            ...UISweetAlert,
            confirm_alert_cancelar_factura: true,
        });
    };

    const onchangeCancelacionFactura = async (e) => {
        setFacturaSeleccionada({
            ...facturaSeleccionada,
            [e.target.name]: e.target.value,
        });
    };

    /*const changeFechaPagoComplemento = async (date) => {
        setComplementoPagoSeleccionado({
            ...complementoPagoSeleccionado,
            fechaPago: date.$d,
        });
    };*/

    const changeFechaPagoComplemento = async (date) => {
        setComplementoPagoSeleccionado({
            ...complementoPagoSeleccionado,
            fechaPago :  date.target.value,
        });
    };

    const onchangeComplemento = async (e) => {
        if (e.target.name == "montoRecibido") {
            let value = e.target.value.replace("$", "");
            if (value < 0) {
                value = value * -1;
            }

            if (value > complementoPagoSeleccionado.montoRestante) {
                value = 0;
                setUISweetAlert({
                    ...UISweetAlert,
                    error_dlg: true,
                    dynamic_title: "Validación",
                    dynamic_description: "El monto del complemento de pago no puede ser mayor a la cantidad restante de la factura.",
                });
            }

            setComplementoPagoSeleccionado({
                ...complementoPagoSeleccionado,
                [e.target.name]: value,
                monto: (value * complementoPagoSeleccionado.porcentajeMonto).toFixed(2),
                iva: (value * complementoPagoSeleccionado.porcentajeIva).toFixed(2),
            });
        } else {
            setComplementoPagoSeleccionado({
                ...complementoPagoSeleccionado,
                [e.target.name]: e.target.value,
            });
        }
    };

    const checkNuevoComplementoPago = async () => {
        let errorNuevoComplemento = false;

        if (!complementoPagoSeleccionado.fechaPago) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, introduzca la fecha del pago.",
            });
            return;
        }

        if ((!complementoPagoSeleccionado.monto || complementoPagoSeleccionado.monto <= 0) && !errorNuevoComplemento) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, introduzca el monto recibido.",
            });
            return;
        }

        if (!complementoPagoSeleccionado.tipoPago && !errorNuevoComplemento) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, introduzca la forma de pago.",
            });
            return;
        }

        if (!errorNuevoComplemento) {
            //upload
        }
    };

    const redirectDetallesFactura = async () => {
        if (!rowSeleccionada) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione la factura",
            });
            return;
        }

        let documentoContable = documentosContables.filter((doc) => doc.idFactura == facturaSeleccionada.idFactura)[0];
        let nombrePdf = documentoContable.rutaPdf ? documentoContable.rutaPdf.split("/") : null;
        nombrePdf = nombrePdf ? nombrePdf[nombrePdf.length - 1] : null;
        let nombreXml = documentoContable.rutaXml ? documentoContable.rutaXml.split("/") : null;
        nombreXml = nombreXml ? nombreXml[nombreXml.length - 1] : null;
        const error =
            nombrePdf && nombreXml
                ? null
                : "El PDF y XML no se generaron correctamente, por favor intente timbrar la factura nuevamente.";
        setRespuestaFacturas([
            {detalleFactura: true, error: error, pdf: nombrePdf, xml: nombreXml, folio: documentoContable.folioFactura},
        ]);
        setUISweetAlert({
            ...UISweetAlert,
            respuestas_facturas: true,
        });
        // props.history.push(`/detalles/factura/${documentoContable.idFactura}`);
    };

    const checkCancelarComplementoPago = async () => {
        let errorCancelarComplemento = false;

        let yearEmision = complementoPagoSeleccionado.fechaEmision.split("T")[0].split("-")[0];
        let yearActual = new Date().toISOString().slice(0, 10).split("-")[0];

        if (!complementoPagoSeleccionado.motivo) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, introduzca el motivo de la cancelación del complemento de pago.",
            });
            return;
        }

        if (yearActual != yearEmision && !errorCancelarComplemento) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "No se pueden cancelar complementos de pagos que no sean del año actual.",
            });
            return;
        }
    };

    const checkCancelarFactura = async () => {
        if (!facturaSeleccionada.idCatMotivoCancelacion) {
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Validación",
                dynamic_description: "Por favor, seleccione el motivo de cancelación",
            });
            return;
        }

        setLoading(true);

        const res = await uploadCancelarFactura({
            idFactura: facturaSeleccionada.idFactura,
            idCatMotivoCancelacion: facturaSeleccionada.idCatMotivoCancelacion,
        });
        
        if (res.data.error) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: res.data.message,
            });
        } else if (res) {
            setLoading(false);
            
            if(res.data[0].error != null){
                setErrorCancelar(true);
                setRespuestaCancelar(res.data[0].error);
            }else{
                setErrorCancelar(false);
                setRespuestaCancelar(res.data[0].estatus);
            }
            
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_cancelar_factura: false,
                respuestas_cancelar_facturas: true,
            })

            fetchData({pageSize: 10, pageIndex: 0, byBusquedaFiltros: true});
        }
    };

    const confirmTimbrarFactura = async () => {
        setLoading(true);

        const res = await timbrarFactura({
            idFactura: facturaSeleccionada.idFactura,
        });

        console.log("res timbrar", res)
        if (res.data.error || (res.data.length > 0 && res.data[0] == null)) {
            setLoading(false);
            setUISweetAlert({
                ...UISweetAlert,
                error_dlg: true,
                dynamic_title: "Fallo",
                dynamic_description: res.data?.message ? res.data.message : "Ocurrió un error al timbrar la factura",
            });
        } else if (res) {
            setLoading(false);
            setRespuestaFacturas(res.data);
            setUISweetAlert({
                ...UISweetAlert,
                confirm_alert_timbrar_factura: false,
                respuestas_facturas: true,
            });

            fetchData({pageSize: 10, pageIndex: controlledPageIndex});
        }
    };

    useEffect(() => {
        listarClientes();
        listarPacientes();
        listarAseguradoras();
        listarMedicos();
        listarCatTipoPagos();
        listarMotivosCancelacion();
    }, []);

    useEffect(() => {
        if (!flagTipoVentaFiltradas) {
            setFlagTipoVentaFiltradas(true);
            
            if (usuario.authorities.includes("ROLE_CONTABILIDAD")) {
                setTipoVentas(tipoDeVentasClean);
            } else if (usuario.authorities.includes("ROLE_FARMACIA")) {
                setTipoVentas(tipoDeVentasClean.filter((tipo) => tipo.tipoVenta != "Vales Aseguradora"));
            } else {
                setTipoVentas(tipoDeVentasClean.filter((tipo) => tipo.tipoVenta == "Vales Aseguradora"));
            }
        }
    }, [tipoVentas]);

    return (
        <>
            <div className="mt-8 container-fluid" style={{textAlign: "left"}}>
                <h3 className="flex h3">Lista de Documentos Contables</h3>

                <button
                    className="btn btn-primary-blue-umo w-md waves-effect waves-light flex mt-6 mr-6 hover:text-white"
                    type="button"
                    onClick={toggleSearch}>
                    {toggleSearchBy ? "Buscar por filtros" : "Buscar por folio"}
                </button>

                <Card
                    hidden={!toggleSearchBy}
                    className="mt-10 card"
                    style={{
                        padding: "1.5rem 1.5rem 1.5rem",
                    }}>
                    <form onSubmit={(e) => buscarPorFolio(e)}>
                        <CardBody>
                            <Row>
                                <Col xl={8} lg={8} md={8} className="col-md-8 col-lg-8 col-xl-8">
                                    <Row
                                        className="form-group"
                                        style={{
                                            marginBottom: "0",
                                        }}>
                                        <Col sm={10} className="flex col-sm-10">
                                            <input
                                                className="form-control mr-2.5"
                                                type="number"
                                                name="folioFactura"
                                                placeholder="Escribe tu folio"
                                                onChange={onChange}
                                                value={filtrosDocumentosContables.folioFactura}
                                            />
                                            <button
                                                className="btn btn-primary-orange-umo w-md waves-effect waves-light"
                                                style={{
                                                    marginRight: "2.5rem",
                                                    background: "#ff9933",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                }}
                                                type="submit">
                                                Buscar
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </form>
                </Card>

                <Card className="mt-10" hidden={toggleSearchBy}>
                    <form onSubmit={(e) => buscarPorFiltros(e)}>
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha Inicial</label>
                                    <Col sm={7}>
                                        <input
                                            type="date"
                                            className="form-control"
                                            onChange={onChange}
                                            name="fechaInicial"
                                            value={filtrosDocumentosContables.fechaInicial}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Tipo Documento</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            onChange={onChange}
                                            name="metodoPago"
                                            value={filtrosDocumentosContables.metodoPago}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            <option value={1}>Factura PUE</option>
                                            <option value={2}>Factura PPD</option>
                                            {}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Tipo Venta</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            onChange={onChange}
                                            name="tipoVenta"
                                            value={filtrosDocumentosContables.tipoVenta}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {tipoVentas.map((tipoVenta) => {
                                                return (
                                                    <>
                                                        <option value={tipoVenta.tipoVenta}>{tipoVenta.tipoVenta}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Cliente</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            onChange={onChange}
                                            name="cliente"
                                            value={filtrosDocumentosContables.cliente}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {clientes.map((cliente) => {
                                                return (
                                                    <>
                                                        <option
                                                            value={
                                                                cliente.idCatCliente
                                                            }>{`${cliente.catPersona.nombres} ${cliente.catPersona.apellidoPaterno} ${cliente.catPersona.apellidoMaterno}`}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Paciente</label>
                                    <Col sm={7}>
                                        <SelectAsyncPaginate
                                            opcionSeleccionadaCompleta={pacienteCompleto}
                                            onChangeOptions={onChange}
                                            fetchOptions={loadPacientesSelect}
                                            opcionesPaginado={opcionesSelectPacientes.opcionesPaginado}
                                            placeholderText={opcionesSelectPacientes.placeholderText}
                                            optionLabel={opcionesSelectPacientes.optionLabel}
                                            optionValue={opcionesSelectPacientes.optionValue}
                                            name="paciente"
                                        />
                                        {}
                                    </Col>
                                </Row>

                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Médico</label>
                                    <Col sm={7}>
                                        <select className="form-control" onChange={onChange} name="medico" value={filtrosDocumentosContables.medico}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {medicos.map((medico) => {
                                                return (
                                                    <>
                                                        <option
                                                            value={
                                                                medico.idCatMedico
                                                            }>{`${medico.catPersona.nombres} ${medico.catPersona.apellidoPaterno} ${medico.catPersona.apellidoMaterno}`}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Fecha Final</label>
                                    <Col sm={7}>
                                        <input
                                            type="date"
                                            className="form-control"
                                            onChange={onChange}
                                            name="fechaFinal"
                                            value={filtrosDocumentosContables.fechaFinal}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Con Complemento de Pago</label>
                                    <Col sm={7}>
                                        <input
                                            type="radio"
                                            onClick={onChange}
                                            name="complementoPago"
                                            checked={filtrosDocumentosContables.complementoPago}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Folio Vale</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChange}
                                            name="folioVale"
                                            value={filtrosDocumentosContables.folioVale}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Folio Venta Mostrador</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChange}
                                            name="folioVentaMostrador"
                                            value={filtrosDocumentosContables.folioVentaMostrador}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label className="col-sm-4 col-form-label">Aseguradora</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            onChange={onChange}
                                            name="aseguradora"
                                            value={filtrosDocumentosContables.aseguradora}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {aseguradoras.map((aseguradora) => {
                                                return (
                                                    <>
                                                        <option value={aseguradora.idCatAseguradora}>{aseguradora.razonSocial}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button className="btn btn-primary-orange-umo w-md waves-effect waves-light" type="submit">
                                    Aplicar Filtro
                                </button>

                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light ml-4"
                                    type="button"
                                    onClick={limpiarFiltros}>
                                    Limpiar Filtro
                                </button>
                            </Col>
                        </Row>
                    </form>
                </Card>

                <Card className="mt-10">
                    <CardBody>
                        <Row>
                            <Col>
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                    type="button"
                                    onClick={redirectNuevaFactura}>
                                    Nueva Factura
                                </button>
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                    type="button"
                                    onClick={checkTimbrarFactura}>
                                    Timbrar Factura
                                </button>
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                    type="button"
                                    onClick={redirectDetallesFactura}>
                                    Detalles
                                </button>
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                    type="button"
                                    onClick={cancelarFactura}>
                                    Cancelar Factura
                                </button>
                                <button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                    type="button"
                                    onClick={nuevoComplementoPago}>
                                    Complemento de Pago
                                </button>

                                {/*<button
                                    className="btn btn-primary-orange-umo w-md waves-effect waves-light mr-4"
                                    type="button"
                                    onClick={pruebas}>
                                    Pruebas
                                        </button>  */}                              
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TableComponent
                                    pageCount={pageCount}
                                    fetchData={fetchData}
                                    columns={columns}
                                    loading={loadingData}
                                    data={documentosContables}
                                    indexOfPage={controlledPageIndex}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>

            {UISweetAlert.confirm_alert_complementos_pago ? (
                <SweetAlert
                    title="Complementos de pago"
                    warning
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "70%",
                    }}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_complementos_pago: false,
                        })
                    }>
                    <div className="mt-2">
                        <Row>
                            <Col>
                                <table className="table table-hover table-centered table-nowrap">
                                    <thead>
                                    <tr>
                                        <th scope="col">Fecha de Emision</th>
                                        <th scope="col">Fecha de Pago</th>
                                        <th scope="col">Folio</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Iva</th>
                                        <th scope="col">Forma de Pago</th>
                                        <th scope="col">Cancelar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {complementosPagoSeleccionados.map((complemento, index) => {
                                        return (
                                            <tr key={complemento.idFacturaRelacionada}>
                                                <td>
                                                    {complemento.fechaEmision
                                                        ? `${complemento.fechaEmision.split("T")[0].split("-")[2]}/${
                                                            complemento.fechaEmision.split("T")[0].split("-")[1]
                                                        }/${complemento.fechaEmision.split("T")[0].split("-")[0]}`
                                                        : ""}
                                                </td>
                                                <td>
                                                    {complemento.fechaPago
                                                        ? `${complemento.fechaPago.split("T")[0].split("-")[2]}/${
                                                            complemento.fechaPago.split("T")[0].split("-")[1]
                                                        }/${complemento.fechaPago.split("T")[0].split("-")[0]}`
                                                        : ""}
                                                </td>
                                                <td>{complemento.folioFiscalRelacionado ? complemento.folioFiscalRelacionado : ""}</td>
                                                <td>{complemento.monto ? formatNumber() : ""}</td>
                                                <td>{complemento.iva ? `$${complemento.iva.toFixed(2)}` : ""}</td>
                                                <td>{complemento.formaPago ? complemento.formaPago : ""}</td>
                                                <td>
                                                    <button
                                                        hidden={index + 1 != complementosPagoSeleccionados.length}
                                                        onClick={() => {
                                                            setComplementoPagoSeleccionado(complemento);
                                                            setUISweetAlert({
                                                                ...UISweetAlert,
                                                                confirm_alert_cancelar_complemento_pago: true,
                                                            });
                                                        }}
                                                        className="btn btn-primary-blue-umo w-md waves-effect waves-light">
                                                        Cancelar
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_cancelar_complemento_pago ? (
                <SweetAlert
                    title="Cancelar complemento de pago"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    onCancel={() => {
                        setComplementoPagoSeleccionado({});
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_cancelar_complemento_pago: false,
                        });
                    }}
                    onConfirm={() => {
                        checkCancelarComplementoPago();
                    }}>
                    <div className="mt-2">
                        <Row>
                            <Col>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Motivo</label>
                                    <Col sm={7}>
                    <textarea
                        type="text"
                        className="form-control"
                        name="motivo"
                        onChange={onchangeCancelacionComplemento}
                        value={complementoPagoSeleccionado.motivo}
                    />
                                    </Col>
                                </Row>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Complemento</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            disabled={true}
                                            className="form-control"
                                            value={complementoPagoSeleccionado.folioComplementoPago}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_nuevo_complemento_pago ? (
                <SweetAlert
                    title="Complemento de pago"
                    showCancel
                    showConfirm
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "50%",
                    }}
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    onCancel={() => {
                        setComplementoPagoSeleccionado({});
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_nuevo_complemento_pago: false,
                        });
                    }}
                    onConfirm={() => {
                        checkNuevoComplementoPago();
                    }}>
                    <div className="mt-2">
                        <Row>
                            <Col>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Fecha de Emisión</label>
                                    <Col sm={7}>
                                        <input
                                            type="date"
                                            disabled={true}
                                            className="form-control"
                                            value={complementoPagoSeleccionado.fechaEmision}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Fecha de Pago</label>
                                    <Col sm={7}>
                                        {/*<MuiPickersUtilsProvider locale="es" utils={DayjsUtils} className="form-control">
                                            <DateTimePicker
                                                fullWidth
                                                value={complementoPagoSeleccionado.fechaPago ? complementoPagoSeleccionado.fechaPago : null}
                                                onChange={changeFechaPagoComplemento}
                                            />
                </MuiPickersUtilsProvider>*/}
                                        <input
                                        type="datetime-local"
                                        className="form-control"
                                        name="fecha2"
                                        onChange={changeFechaPagoComplemento}
                                        value={complementoPagoSeleccionado.fechaPago2 ? complementoPagoSeleccionado.fechaPago2 : null}
                                    />                                        
                                    </Col>
                                </Row>

                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Monto Restante</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={true}
                                            value={
                                                complementoPagoSeleccionado.montoRestante
                                                    ? `$${complementoPagoSeleccionado.montoRestante.toFixed(2)}`
                                                    : `$`
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Monto Recibido</label>
                                    <Col sm={7}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="montoRecibido"
                                            onChange={onchangeComplemento}
                                            autoComplete="off"
                                            value={complementoPagoSeleccionado.montoRecibido ? `$${complementoPagoSeleccionado.montoRecibido}` : `$`}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Forma de Pago</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            onChange={onchangeComplemento}
                                            name="tipoPago"
                                            value={complementoPagoSeleccionado.tipoPago}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {tipoPagos.map((tipoPago) => {
                                                return (
                                                    <>
                                                        <option value={tipoPago.idCatTipoPago}>{tipoPago.tipoPago}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_cancelar_factura ? (
                <SweetAlert
                    title="Cancelar factura"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "60%",
                    }}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_cancelar_factura: false,
                        });
                    }}
                    onConfirm={() => {
                        checkCancelarFactura();
                    }}>
                    <div className="mt-2">
                        <Row>
                            <Col>
                                <Row className="form-group mt-3">
                                    <label className="col-sm-4 col-form-label">Motivo</label>
                                    <Col sm={7}>
                                        <select
                                            className="form-control"
                                            onChange={onchangeCancelacionFactura}
                                            name="idCatMotivoCancelacion"
                                            value={facturaSeleccionada.idCatMotivoCancelacion}>
                                            <option selected value="">
                                                Seleccione una opción
                                            </option>
                                            {motivosCancelacion.map((motivo) => {
                                                return (
                                                    <>
                                                        <option
                                                            value={motivo.idCatMotivoCancelacion}>{`${motivo.clave} - ${motivo.motivoCancelacion}`}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </SweetAlert>
            ) : null}

            {UISweetAlert.confirm_alert_timbrar_factura ? (
                <SweetAlert
                    title="Timbrar factura"
                    showCancel
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    cancelBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "60%",
                    }}
                    onCancel={() => {
                        setUISweetAlert({
                            ...UISweetAlert,
                            confirm_alert_timbrar_factura: false,
                        });
                    }}
                    onConfirm={() => {
                        confirmTimbrarFactura();
                    }}></SweetAlert>
            ) : null}

            {UISweetAlert.respuestas_cancelar_facturas ? (
                <SweetAlert
                    title="Detalles de la cancelación"
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "70%",
                    }}
                    onConfirm={() => {
                        setRespuestaCancelar(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            respuestas_cancelar_facturas: false,
                        });
                    }}
                    onCancel={() => {
                        setRespuestaCancelar(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            respuestas_cancelar_facturas: false,
                        });
                    }}>
                    <Alert
                        className="m-2 text-left"
                        message={<span className="h5">{errorCancelar? "Error en la cancelación":"Cancelación exitosa"}</span>}
                        showIcon
                        description={respuestaCancelar}
                        type={errorCancelar? "error" : "success"}
                    />
                </SweetAlert>
            ) : null}

            {UISweetAlert.respuestas_facturas ? (
                <SweetAlert
                    title="Detalles de Facturación"
                    showConfirm
                    confirmBtnText="Confirmar"
                    confirmBtnBsStyle="success"
                    closeOnClickOutside={false}
                    style={{
                        width: width && width < 851 ? "100%" : "70%",
                    }}
                    onConfirm={() => {
                        setRespuestaFacturas(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            respuestas_facturas: false,
                        });
                    }}
                    onCancel={() => {
                        setRespuestaFacturas(null);
                        setUISweetAlert({
                            ...UISweetAlert,
                            respuestas_facturas: false,
                        });
                    }}>
                    <RespuestaFacturas
                        respuestas={respuestaFacturas}
                        downloadFactura={downloadFactura}
                    />
                </SweetAlert>
            ) : null}

            {UISweetAlert.success_dlg ? (
                <SweetAlert
                    success
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() => {
                        if (UISweetAlert.redirect) {
                            props.history.push(UISweetAlert.path);
                        }
                        setUISweetAlert({
                            ...UISweetAlert,
                            success_dlg: false,
                        });
                    }}>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.error_dlg ? (
                <SweetAlert
                    error
                    title={UISweetAlert.dynamic_title}
                    onConfirm={() =>
                        setUISweetAlert({
                            ...UISweetAlert,
                            error_dlg: false,
                        })
                    }>
                    {UISweetAlert.dynamic_description}
                </SweetAlert>
            ) : null}

            {UISweetAlert.errorTimbrado ? (
                <SweetAlert
                title="Detalles del error en la facturación"
                confirmBtnText="Confirmar"
                confirmBtnBsStyle="success"
                closeOnClickOutside={false}
                showConfirm
                style={{
                    width: width && width < 851 ? "100%" : "70%",
                }}
                onConfirm={() => {
                    setRespuestaFacturas(null);
                    setErrorTimbrado("");
                    setUISweetAlert({
                        ...UISweetAlert,
                        errorTimbrado: false,
                        respuestas_facturas :  false,
                    });
                }}>
                <Alert
                    className="m-2 text-left"
                    message={<span className="h5">Error en la Facturación</span>}
                    showIcon
                    description={errorTimbrado}
                    type="error"
                />
            </SweetAlert>
            ) : null}

            {loading ? (
                <SweetAlert title="Cargando!" showConfirm={false}>
                    <Spinner/>
                </SweetAlert>
            ) : null}
        </>
    );
}
