import React, { useContext, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import { Row, Col, Spinner } from "reactstrap";

import AseguradorasContext from "../../../context/Aseguradoras/AseguradorasContext";
import EscolaridadesContext from "../../../context/Escolaridades/EscolaridadesContext";
import EstadosCivilesContext from "../../../context/EstadosCiviles/EstadosCivilesContext";
import LugaresContext from "../../../context/Lugares/LugaresContext";
import MedicosContext from "../../../context/Medicos/MedicosContext";
import OcupacionesContext from "../../../context/Ocupaciones/OcupacionesContext";
import SexosContext from "../../../context/Sexos/SexosContext";
import SweetAlert from "react-bootstrap-sweetalert";

import WindowWarningContext from "../../../context/WindowWarning/WindowWarningContext";
import PacientesContext from "../../../context/Pacientes/PacientesContext";
import SpanError from "../../UI/spanError";

//import AuthContext from "../../../context/Auth/AuthContext";
// import useCloseWindowWarning from "../../../hooks/useCloseWindowWarning";

import {
  maxCharTelefono,
  maxCharNombres,
  maxCharApellidoPaterno,
  maxCharApellidoMaterno,
  maxCharCurp,
  maxCharResidencia,
  maxCharOrigen,
  maxCharOtraOcupacion,
  maxCharReferencia,
  maxCharReligion,
} from "../../../config/variables";

export default function FichaIdentificacion({ props, historiaClinicaInicial, setHistoriaClinicaInicial, disabled, width }) {
  const windowWarningContext = useContext(WindowWarningContext);
  const { changeEdit, seEdito } = windowWarningContext;

  //const variable = historiaClinicaInicial.paciente.nombres;

  // const [Prompt, setEditTrue, setEditFalse, isEdit] = useCloseWindowWarning();
  const [loading, setLoading] = useState(false);

  // const [seGuardaronCambios, setSeGuardaronCambios] = useState(false);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_cargar_archivo: false,
    success_dlg: false, 
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  //const ctxAuth = useContext(AuthContext);
  //const { usuario } = ctxAuth;

  const pacienteCxt = useContext(PacientesContext);
  const { guardarArchivosPaciente } = pacienteCxt;

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;

  const [fileList, setFileList] = useState([]);

  const onFileUpload = async () => {
    // const datosValidos = validarDatosArchivo();

    if (fileList.length > 0) {
      const pacienteVo = { archivosPaciente: fileList, idCatPaciente: historiaClinicaInicial.paciente.idCatPaciente };
      setLoading(true);
      const res = await guardarArchivosPaciente(pacienteVo);
      if (res.data && res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          dynamic_title: "Fallo",
          dynamic_description: "Ocurrió un error al cargar el archivo, por favor inténtalo más tarde.",
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_cargar_archivo: false,
          dynamic_description: "Se guardó correctamente el archivo",
        });
      }

      // fetchData({ pageSize: 10, pageIndex: 0 });
      setFileList([]);
      return res;
    }
  };

  const infoSweetAlert = { title: "Cargar Archivos", confirmBtnText: "Guardar", cancelBtnText: "Cancelar" };

  const ctxEscolaridad = useContext(EscolaridadesContext);
  const { escolaridades, listarEscolaridades } = ctxEscolaridad;

  const ctxOcupacion = useContext(OcupacionesContext);
  const { ocupaciones, listarOcupaciones } = ctxOcupacion;

  const ctxEstcivil = useContext(EstadosCivilesContext);
  const { estadosCiviles, listarEstadosCiviles } = ctxEstcivil;

  const ctxMedicos = useContext(MedicosContext);
  const { listarMedicos } = ctxMedicos;

  const ctxAseguradora = useContext(AseguradorasContext);
  const { aseguradoras, listarAseguradoras } = ctxAseguradora;

  const ctxSexos = useContext(SexosContext);
  const { sexos, listarSexos } = ctxSexos;

  const ctxLugares = useContext(LugaresContext);
  const { lugares, listarLugares } = ctxLugares;

  // eslint-disable-next-line no-unused-vars
  const [medicoInicial, setMedicoInicial] = useState(null);

  useEffect(() => {
    if (historiaClinicaInicial && historiaClinicaInicial.expedienteMedico 
        && historiaClinicaInicial.expedienteMedico.idCatMedico)
      setMedicoInicial(historiaClinicaInicial.expedienteMedico.idCatMedico);
  }, []);

  const onChangePaciente = async (e) => {
    // if (!isEdit) setEditTrue(); 
    if (!seEdito) {
      // changeGuardado(false);
      changeEdit(true);
    }

    if (e.target.name == "fechaNacimiento") {
      var ageDifMs = Date.now() - new Date(e.target.value).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      let edad = Math.abs(ageDate.getUTCFullYear() - 1970);
      setHistoriaClinicaInicial({
        ...historiaClinicaInicial,
        paciente: {
          ...historiaClinicaInicial.paciente,
          [e.target.name]: e.target.value,
          edad: edad,
        },
      });
    } else if (e.target.name == "idSexo") {
      if (e.target.value == "" || (e.target.value == 2 && historiaClinicaInicial.antecedentesGinecoobstetricos)) {
        delete historiaClinicaInicial.antecedentesGinecoobstetricos.menarca;
        delete historiaClinicaInicial.antecedentesGinecoobstetricos.fum;
        delete historiaClinicaInicial.antecedentesGinecoobstetricos.g;
        delete historiaClinicaInicial.antecedentesGinecoobstetricos.p;
        delete historiaClinicaInicial.antecedentesGinecoobstetricos.c;
        delete historiaClinicaInicial.antecedentesGinecoobstetricos.a;
      }

      setHistoriaClinicaInicial({
        ...historiaClinicaInicial,
        paciente: {
          ...historiaClinicaInicial.paciente,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name == "riesgoExtravasacion") {
      setHistoriaClinicaInicial({
        ...historiaClinicaInicial,
        paciente: {
          ...historiaClinicaInicial.paciente,
          [e.target.name]: e.target.checked,
        },
      });
    } else {
      setHistoriaClinicaInicial({
        ...historiaClinicaInicial,
        paciente: {
          ...historiaClinicaInicial.paciente,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const onChangeExpediente = async (e) => {
    // const { name, value } = e.target;
    // if (name == "idCatMedico" && medicoInicial) {
    //   if (value != medicoInicial) {
    //     setSeCambioMedico(true);
    //   } else {
    //     setSeCambioMedico(false);
    //   }
    // }
    
    if (!seEdito) changeEdit(true);
    setHistoriaClinicaInicial({
      ...historiaClinicaInicial,
      expedienteMedico: {
        ...historiaClinicaInicial.expedienteMedico,
        [e.target.name]: e.target.value,
      },
    });
  };

  useEffect(() => {
    listarEscolaridades();
    listarOcupaciones();
    listarEstadosCiviles();
    listarMedicos();
    listarAseguradoras();
    listarSexos();
    listarLugares();
  }, []);

  const onCancel = () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_cargar_archivo: false,
    });
    // setDatosCargaArchivo(datosCargaArchivoVacio);
    setFileList([]);
  };

  const changeFiles = (e) => {
    const files = e.target.files;

    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          let strFile = reader.result;
          let type = strFile.substring(strFile.lastIndexOf(":") + 1, strFile.lastIndexOf(";"));
          let fileBase64 = strFile.substring(strFile.lastIndexOf(",") + 1, strFile.length);

          const tipoArchivoDatosPersonales = 1;

          let nuevoArchivo = {
            nombreArchivo: file.name,
            mimeType: type,
            tamano: file.size,
            inputStream: fileBase64,
            tipoArchivo: tipoArchivoDatosPersonales,
          };
          const newFileList = fileList;
          newFileList.push(nuevoArchivo);
          setFileList(newFileList);
          // setFileList([...fileList, nuevoArchivo]);
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const limpiarArchivos = async () => {
    setFileList([]);
  };

  const checkCargarDocumento = () => {
    setUISweetAlert({
      ...UISweetAlert,
      confirm_cargar_archivo: true,
    });
  };

  return (
    <div className="mt-4 container-fluid" style={{ textAlign: "center" }}>
      <Row>
        <h4>Datos Generales</h4>
      </Row>

      <Row>
        <Col xl={4} lg={12} md={12}>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              Nombre<SpanError>*</SpanError>
            </label>
            <Col sm={7}>
              <input
                type="text"
                name="nombres"
                maxLength={maxCharNombres}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.nombres
                    ? historiaClinicaInicial.paciente.nombres
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4} lg={12} md={12}>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              Apellido Paterno<SpanError>*</SpanError>
            </label>
            <Col sm={7}>
              <input
                type="text"
                name="apellidoPaterno"
                maxLength={maxCharApellidoPaterno}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.apellidoPaterno
                    ? historiaClinicaInicial.paciente.apellidoPaterno
                    : ""
                }
                onChange={onChangePaciente}
                disabled={disabled}
                className="form-control"
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4} lg={12} md={12}>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              Apellido Materno<SpanError>*</SpanError>
            </label>
            <Col sm={7}>
              <input
                type="text"
                name="apellidoMaterno"
                maxLength={maxCharApellidoMaterno}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.apellidoMaterno
                    ? historiaClinicaInicial.paciente.apellidoMaterno
                    : ""
                }
                onChange={onChangePaciente}
                disabled={disabled}
                className="form-control"
              />
            </Col>
          </Row>
        </Col>

        <Col xl={6} lg={12} md={12}>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Sexo</label>
            <Col sm={7}>
              <select
                className="form-control"
                disabled={disabled}
                name="idSexo"
                onChange={onChangePaciente}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idSexo
                    ? historiaClinicaInicial.paciente.idSexo
                    : ""
                }>
                <option selected value="">
                  Seleccione una opción
                </option>
                {sexos.map((sexo) => {
                  return (
                    <>
                      <option value={sexo.idCatSexo}>{sexo.sexo}</option>
                    </>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Nacionalidad</label>
            <Col sm={7}>
              <input
                type="text"
                maxLength={maxCharOtraOcupacion}
                name="nacionalidad"
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.nacionalidad
                    ? historiaClinicaInicial.paciente.nacionalidad
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>

          <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              Estado de Origen<SpanError>*</SpanError>
            </label>
            <Col sm={7}>
              <select
                className="form-control"
                name="idLugarOrigen"
                disabled={disabled}
                onChange={onChangePaciente}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idLugarOrigen
                    ? historiaClinicaInicial.paciente.idLugarOrigen
                    : ""
                }>
                <option selected value="">
                  Seleccione una opción
                </option>
                {lugares.map((lugar) => {
                  return (
                    <>
                      <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>
                    </>
                  );
                })}
              </select>
            </Col>
          </Row>

          <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              Ciudad de Origen<SpanError>*</SpanError>
            </label>
            <Col sm={7}>
              <input
                type="text"
                name="origen"
                maxLength={maxCharOrigen}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.origen
                    ? historiaClinicaInicial.paciente.origen
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>

          <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              Estado de Residencia<SpanError>*</SpanError>
            </label>
            <Col sm={7}>
              <select
                className="form-control"
                name="idLugarResidencia"
                disabled={disabled}
                onChange={onChangePaciente}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idLugarResidencia
                    ? historiaClinicaInicial.paciente.idLugarResidencia
                    : ""
                }>
                <option selected value="">
                  Seleccione una opción
                </option>
                {lugares.map((lugar) => {
                  return (
                    <>
                      <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>
                    </>
                  );
                })}
              </select>
            </Col>
          </Row>

          <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              Ciudad de Residencia<SpanError>*</SpanError>
            </label>
            <Col sm={7}>
              <input
                type="text"
                name="residencia"
                maxLength={maxCharResidencia}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.residencia
                    ? historiaClinicaInicial.paciente.residencia
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              Fecha de Nacimiento<SpanError>*</SpanError>
            </label>
            <Col sm={7}>
              <input
                type="date"
                name="fechaNacimiento"
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.fechaNacimiento
                    ? historiaClinicaInicial.paciente.fechaNacimiento
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Edad</label>
            <Col sm={7}>
              <input
                type="text"
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.edad
                    ? historiaClinicaInicial.paciente.edad
                    : ""
                }
                className="form-control"
                disabled={true}
              />
            </Col>
          </Row>
          {/* <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              Médico Tratante<span className="text-xs text-red-700">*</span>
            </label>
            <Col sm={7}>
              <select
                className="form-control"
                // disabled={true}
                name="idCatMedico"
                disabled={
                  disabled || (usuario.authorities.includes("ROLE_MEDICO") && !usuario.authorities.includes("ROLE_ADMIN_SISTEMA"))
                }
                onChange={onChangeExpediente}
                value={
                  historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.idCatMedico
                    ? historiaClinicaInicial.expedienteMedico.idCatMedico
                    : ""
                }>
                <option selected value="">
                  Seleccione una opción
                </option>
                {medicos.map((medico) => {
                  return (
                    <>
                      <option
                        value={
                          medico.idCatMedico
                        }>{`${medico.catPersona.nombres} ${medico.catPersona.apellidoPaterno} ${medico.catPersona.apellidoMaterno}`}</option>
                    </>
                  );
                })}
              </select>
            </Col>
          </Row> */}
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">
              Aseguradora<span className="text-xs text-red-700">*</span>
            </label>
            <Col sm={7}>
              <select
                className="form-control"
                name="idCatAseguradora"
                disabled={disabled}
                onChange={onChangeExpediente}
                value={
                  historiaClinicaInicial.expedienteMedico && historiaClinicaInicial.expedienteMedico.idCatAseguradora
                    ? historiaClinicaInicial.expedienteMedico.idCatAseguradora
                    : ""
                }>
                <option selected value="">
                  SIN ASEGURADORA
                </option>
                {aseguradoras.map((aseguradora) => {
                  return (
                    <>
                      <option value={aseguradora.idCatAseguradora}>{aseguradora.razonSocial}</option>
                    </>
                  );
                })}
              </select>
              {/* {historiaClinicaInicial.expedienteMedico.idCatAseguradora === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>} */}
            </Col>
          </Row>

          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Comentarios</label>
            <Col sm={7}>
              <input
                type="text"
                maxLength={maxCharOtraOcupacion}
                name="comentario"
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.comentario
                    ? historiaClinicaInicial.paciente.comentario
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>          
        </Col>

        <Col xl={6} lg={12} md={12}>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Teléfono</label>
            <Col sm={7}>
              <input
                type="text"
                name="telefono"
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.telefono
                    ? historiaClinicaInicial.paciente.telefono
                    : ""
                }
                maxLength={maxCharTelefono}
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
              {/* {historiaClinicaInicial.paciente &&
              historiaClinicaInicial.paciente.telefono &&
              historiaClinicaInicial.paciente.telefono.length >= maxCharTelefono ? (
                <span>El número máximo de caracteres es de {maxCharTelefono}</span>
              ) : null} */}
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Escolaridad</label>
            <Col sm={7}>
              <select
                className="form-control"
                name="idEscolaridad"
                disabled={disabled}
                onChange={onChangePaciente}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idEscolaridad
                    ? historiaClinicaInicial.paciente.idEscolaridad
                    : ""
                }>
                <option selected value="">
                  Seleccione una opción
                </option>
                {escolaridades.map((escolaridad) => {
                  return (
                    <>
                      <option value={escolaridad.idCatEscolaridad}>{escolaridad.escolaridad}</option>
                    </>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row
            className="form-group"
            hidden={
              (historiaClinicaInicial.paciente && !historiaClinicaInicial.paciente.idEscolaridad) ||
              (historiaClinicaInicial.paciente &&
                historiaClinicaInicial.paciente.idEscolaridad &&
                !(historiaClinicaInicial.paciente.idEscolaridad == 8))
            }>
            <label className="col-sm-5 col-form-label">Otra Escolaridad</label>
            <Col sm={7}>
              <input
                type="text"
                maxLength={maxCharOtraOcupacion}
                name="otraEscolaridad"
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.otraEscolaridad
                    ? historiaClinicaInicial.paciente.otraEscolaridad
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Ocupación</label>
            <Col sm={7}>
              <select
                className="form-control"
                name="idOcupacion"
                disabled={disabled}
                onChange={onChangePaciente}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idOcupacion
                    ? historiaClinicaInicial.paciente.idOcupacion
                    : ""
                }>
                <option selected value="">
                  Seleccione una opción
                </option>
                {ocupaciones.map((ocupacion) => {
                  return (
                    <>
                      <option value={ocupacion.idCatOcupacion}>{ocupacion.ocupacion}</option>
                    </>
                  );
                })}
              </select>
            </Col>
          </Row>

          <Row
            className="form-group"
            hidden={
              (historiaClinicaInicial.paciente && !historiaClinicaInicial.paciente.idOcupacion) ||
              (historiaClinicaInicial.paciente &&
                historiaClinicaInicial.paciente.idOcupacion &&
                !(historiaClinicaInicial.paciente.idOcupacion == 19))
            }>
            <label className="col-sm-5 col-form-label">Otra Ocupación</label>
            <Col sm={7}>
              <input
                type="text"
                maxLength={maxCharOtraOcupacion}
                name="otraOcupacion"
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.otraOcupacion
                    ? historiaClinicaInicial.paciente.otraOcupacion
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Estado Civil</label>
            <Col sm={7}>
              <select
                className="form-control"
                name="idEstadoCivil"
                disabled={disabled}
                onChange={onChangePaciente}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.idEstadoCivil
                    ? historiaClinicaInicial.paciente.idEstadoCivil
                    : ""
                }>
                <option selected value="">
                  Seleccione una opción
                </option>
                {estadosCiviles.map((estadoCivil) => {
                  return (
                    <>
                      <option value={estadoCivil.idCatEstadoCivil}>{estadoCivil.estadoCivil}</option>
                    </>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Referencia</label>
            <Col sm={7}>
              <input
                type="text"
                maxLength={maxCharReferencia}
                name="referencia"
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.referencia
                    ? historiaClinicaInicial.paciente.referencia
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>

          <Row className="form-group">
            <label className="col-sm-5 col-form-label">CURP</label>
            <Col sm={7}>
              <input
                type="text"
                name="curp"
                maxLength={maxCharCurp}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.curp
                    ? historiaClinicaInicial.paciente.curp
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Religión</label>
            <Col sm={7}>
              <input
                type="text"
                name="religion"
                maxLength={maxCharReligion}
                value={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.religion
                    ? historiaClinicaInicial.paciente.religion
                    : ""
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Riesgo de Extravasación</label>
            <Col sm={7}>
              <input
                type="checkbox"
                name="riesgoExtravasacion"
                checked={
                  historiaClinicaInicial.paciente && historiaClinicaInicial.paciente.riesgoExtravasacion
                    ? historiaClinicaInicial.paciente.riesgoExtravasacion
                    : false
                }
                disabled={disabled}
                onChange={onChangePaciente}
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="form-group">
            <label className="col-sm-5 col-form-label">Carga de INE del paciente</label>
            <Col sm={7}>
              <button
                disabled={disabled}
                className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                type="button"
                style={{
                  marginRight: "2.5rem",

                  background: "#ff9933",
                  color: "white",
                  fontWeight: "bold",
                }}
                onClick={() => checkCargarDocumento()}>
                Cargar INE
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      {UISweetAlert.confirm_cargar_archivo ? (
        <SweetAlert
          title={infoSweetAlert.title}
          confirmBtnText={infoSweetAlert.confirmBtnText}
          confirmBtnBsStyle="success"
          cancelBtnText={infoSweetAlert.cancelBtnText}
          cancelBtnBsStyle="danger"
          showCancel
          closeOnClickOutside={false}
          style={{ width: width && width < 851 ? "100%" : "50%" }}
          onConfirm={() => {
            onFileUpload();
          }}
          onCancel={() => {
            onCancel();
          }}>
          <>
            <input
              onClick={limpiarArchivos}
              onChange={changeFiles}
              className="mt-4 block w-full text-sm text-gray-900 border border-gray-300 cursor-pointer "
              id="fileList"
              type="file"
              multiple
            />
          </>
        </SweetAlert>
      ) : null}
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.redirect) {
              props.history.push(UISweetAlert.path);
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() =>
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            })
          }>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}
      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
      {/* {Prompt} */}
    </div>
  );
}
