import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import AuthContext from "../../context/Auth/AuthContext";

// import AlmacenesContext from "../../context/Almacenes/AlmacenesContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import CatTipoCompraContext from "../../context/CatTipoCompra/CatTipoCompraContext";
import {NumericFormat} from "react-number-format";
import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";
import TableComponent from "../TablaPaginadaSinUpdate/index";
import LugaresContext from "../../context/Lugares/LugaresContext";

import { maxCharTelefono } from "../../config/variables";
import useWindowDimensions from "../../hooks/dimensionsHook";

const Proveedores = () => {
  const { width } = useWindowDimensions();
  const filtrosVacios = {
    razonSocial: "",
    contacto: "",
    idCatTipoCompra: "",
    rfc: "",
  };

  const datosProveedorVacio = {
    proveedor: "",
    razonSocial: "",
    contacto: "",
    correo: "",
    telefono: "",
    rfc: "",
    calle: "",
    colonia: "",
    idEstado: "",
    municipio: "",
    numExterno: "",
    numInterno: "",
    cp: "",
    telefonoFijo: "",
    telefonoCelular: "",
    idTipoCompra: "",
    diasPago: "",
  };

  const [datosNuevoProveedor, setdatosNuevoProveedor] = useState(datosProveedorVacio);
  const [proveedoreseleccionado, setproveedoreseleccionado] = useState(null);
  const [rowSeleccionada, setRowSeleccionada] = useState(null);

  const [UISweetAlert, setUISweetAlert] = useState({
    confirm_modificar_proveedor: false,
    confirm_nuevo_proveedor: false,
    confirm_eliminar_proveedor: false,
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [controlledPageIndex, setControlledPageIndex] = useState(0);
  const [data, setData] = useState([
    {
      proveedor: "",
      razonSocial: "",
      contacto: "",
      correo: "",
      telefono: "",
      rfc: "",
      calle: "",
      colonia: "",
      idEstado: "",
      municipio: "",
      numExterno: "",
      numInterno: "",
      cp: "",
      telefonoFijo: "",
      telefonoCelular: "",
      tipoCompra: "",
      idCatTipoCompra: "",
      diasPago: "",
      montoCredito: "",
    },
  ]);

  const lugaresContext = useContext(LugaresContext);
  const { lugares, listarLugares } = lugaresContext;

  useEffect(() => {
    listarLugares();
    listarTiposCompra();
  }, []);

  const fetchIdRef = useRef(0);

  const proveedoresContext = useContext(ProveedoresContext);

  //ACCESO AL ESTADO GLOBAL - AUTENCIACION
  const ctxAuth = useContext(AuthContext);
  const { usuario } = ctxAuth;

  const catTiposCompra = useContext(CatTipoCompraContext);

  const { tiposCompra, listarTiposCompra } = catTiposCompra;

  const {
    setFiltrosProveedores,
    filtrosProveedores,
    uploadNuevoProveedor,
    eliminarProveedor,
    setDataProveedor,
    updateProveedor,
  } = proveedoresContext;

  /**
   * @constant
   * @name autorizado
   * @type {boolean}
   * @description Constante true o false para checar si cumple con algún rol de autorización
   */

  const autorizado = usuario.authorities.includes("ROLE_ADMIN_SISTEMA") || usuario.authorities.includes("ROLE_COMPRAS");
  const esContadora = usuario && usuario.authorities.includes("ROLE_CONTABILIDAD");
  //   const autorizado =
  //     usuario.authorities.includes("OP_CATALOGO_PROVEEDOR_CREAR") ||
  //     usuario.authorities.includes("OP_CATALOGO_PROVEEDOR_MODIFICAR") ||
  //     usuario.authorities.includes("OP_CATALOGO_PROVEEDOR_ELIMINAR");

  const fetchAPIData = async ({ page, limit, byBusquedaFiltros }) => {
    try {
      if (byBusquedaFiltros) {
        setControlledPageIndex(0);
      } else {
        setControlledPageIndex(page - 1);
      }
      const response = await setDataProveedor({ page, limit });

      const data = response.proveedores;

      const count = response.count;

      setData(data);

      setRowSeleccionada(null);

      if (count % limit > 0) {
        setPageCount(Math.ceil(count / limit));
      } else {
        setPageCount(count / limit);
      }

      setLoadingData(false);
    } catch (e) {
      console.log("Error while fetching", e);
      // setLoading(false)
    }
  };

  const fetchData = async ({ pageSize, pageIndex }) => {
    console.log("fetchData is being called");

    const fetchId = ++fetchIdRef.current;
    setLoadingData(true);
    if (fetchId === fetchIdRef.current) {
      fetchAPIData({
        page: pageIndex + 1,
        limit: pageSize,
        byBusquedaFiltros: false,
      });
    }
  };

  const handdleCheckedProveedor = useCallback(
    (row) => {
      if (rowSeleccionada === null) {
        setRowSeleccionada(row.id);
        setproveedoreseleccionado(row.original);
      } else if (rowSeleccionada === row.id) {
        setRowSeleccionada(null);
        setproveedoreseleccionado(null);
      } else {
        setRowSeleccionada(row.id);
        setproveedoreseleccionado(row.original);
      }
    },
    [rowSeleccionada]
  );

  // Se definen las columnas para la tabla de react-tabe
  // Se debe usar useMemo para optimización y porque lo pide la librería

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "idCatProveedor",
        Cell: ({ row }) => {
          return (
            <input
              // disabled={rowSeleccionada === null || rowSeleccionada === row.id ? false : true}
              checked={rowSeleccionada === row.id ? true : false}
              type="checkbox"
              onChange={() => handdleCheckedProveedor(row)}
            />
          );
        },
        show: true,
        disableFilters: true,
        disableSortBy: true,
      },
      { Header: "Proveedor", accessor: "proveedor", show: true },
      { Header: "Razon Social", accessor: "razonSocial", show: true },
      { Header: "Tipo Compra", accessor: "tipoCompra.tipoCompra", show: true },
      { Header: "idCatTipoCompra", accessor: "tipoCompra.idCatTipoCompra" },
      { Header: "Días Crédito", accessor: "diasPago", show: true },
      { Header: "Monto Crédito",
      accessor: "montoCredito",
      Cell: ({row}) => {
          return (
                  <NumericFormat
                      // disabled={true}
                      id="precio"
                      name="precio"
                      thousandSeparator={true}
                      prefix="$"
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      displayType="text"
                      value={row.original.montoCredito ? row.original.montoCredito : "$0"}
                  />
          ); 
        },
      },
      { Header: "Contacto", accessor: "contacto", show: true },
      { Header: "Correo de Contacto", accessor: "correo", show: true },
      { Header: "Teléfono Contacto", accessor: "telefono", show: true },
      { Header: "RFC", accessor: "rfc", show: true },
      { Header: "Calle", accessor: "calle", show: true },
      { Header: "Colonia", accessor: "colonia", show: true },
      { Header: "Estado", accessor: "estado", show: true },
      { Header: "Num. Externo", accessor: "numExterno", show: true },
      { Header: "Num. Interno", accessor: "numInterno", show: true },
      { Header: "CP", accessor: "cp", show: true },
      { Header: "Teléfono Fijo", accessor: "telefonoFijo", show: true },
      { Header: "Teléfono Celular", accessor: "telefonoCelular", show: true },
      
    ],
    [rowSeleccionada, handdleCheckedProveedor]
  );

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    /**
     * @function fetchData
     * @param {object} filtrosProveedores Filtros enviados al back para filtrar los pacientes
     * @param {int} page Página a regresar de pacientes
     * @param {int} limit Número de pacientes que se van a regresar por llamada al back
     */

    setFiltrosProveedores(filtrosProveedores);
    fetchData({ pageSize: 10, pageIndex: 0 });
  };

  const limpiarFiltros = () => {
    setFiltrosProveedores(filtrosVacios);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltrosProveedores({
      ...filtrosProveedores,
      [name]: value,
    });
  };

  const changeNuevoProveedor = (e) => {
    const { name, value } = e.target;
    setdatosNuevoProveedor({
      ...datosNuevoProveedor,
      [name]: value,
    });
  };

  const changeModificarProveedor = (e) => {
    const { name, value } = e.target;
    setproveedoreseleccionado({
      ...proveedoreseleccionado,
      [name]: value,
    });
  };

  const tiposCompras = tiposCompra.map(({ idCatTipoCompra, tipoCompra, ...rest }) => ({
    ...rest,
    id: idCatTipoCompra,
    nombre: tipoCompra,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Nombre Proveedor",
          type: "text",
          name: "razonSocial",
          value: filtrosProveedores.razonSocial,
          onChange: onChange,
        },
        {
          colType: "input",
          label: "Nombre Contacto",
          type: "text",
          name: "contacto",
          value: filtrosProveedores.contacto,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "RFC",
          type: "text",
          name: "rfc",
          value: filtrosProveedores.rfc,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Tipo Compra",
          name: "idCatTipoCompra",
          value: filtrosProveedores.idCatTipoCompra,
          opciones: tiposCompras,
          onChange: onChange,
        },
      ],
    },
  ];

  const confirmNuevoProveedor = () => {
    if (autorizado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_nuevo_proveedor: true,
      });
    }
  };

  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneformat = /^\d{10}$/;
  const rfcformat = /^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?$/;
  const cpformat = /^\d{4,5}$/;

  const validarDatosProveedor = (proveedor) => {
    let errorNuevoProveedor = false;
    if (!errorNuevoProveedor && (proveedor.proveedor === "" || proveedor.proveedor === null)) {
      errorNuevoProveedor = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el nombre del proveedor",
      });
    }
    if (!errorNuevoProveedor && (proveedor.razonSocial === "" || proveedor.razonSocial === null)) {
      errorNuevoProveedor = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese la razón social del proveedor",
      });
    }

    // if (!errorNuevoProveedor && (proveedor.contacto === "" || proveedor.contacto === null)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el nombre del contacto",
    //   });
    // }
    if (!errorNuevoProveedor && (proveedor.correo === "" || proveedor.correo === null)) {
      errorNuevoProveedor = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el correo del contacto",
      });
    }
    if (!errorNuevoProveedor && proveedor.correo && !proveedor.correo.match(mailformat)) {
      errorNuevoProveedor = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un correo de contacto válido",
      });
    }
    // if (!errorNuevoProveedor && (proveedor.telefono === "" || proveedor.telefono === null)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el teléfono del contacto",
    //   });
    // }
    // if (!errorNuevoProveedor && !proveedor.telefono.match(phoneformat)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese un teléfono de contacto válido",
    //   });
    // }
    if (!errorNuevoProveedor && (proveedor.rfc === "" || proveedor.rfc === null)) {
      errorNuevoProveedor = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese el RFC del proveedor",
      });
    }
    if (!errorNuevoProveedor && proveedor.rfc && !proveedor.rfc.match(rfcformat)) {
      errorNuevoProveedor = true;
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, ingrese un RFC válido",
      });
    }
    // if (!errorNuevoProveedor && (proveedor.cp === "" || proveedor.cp === null)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el código postal de l proveedor",
    //   });
    // }
    // if (!errorNuevoProveedor && !proveedor.cp.toString().match(cpformat)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese un código postal válido",
    //   });
    // }
    // if (!errorNuevoProveedor && (proveedor.telefonoFijo === "" || proveedor.telefonoFijo === null)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el teléfono fijo",
    //   });
    // }
    // if (!errorNuevoProveedor && !proveedor.telefonoFijo.match(phoneformat)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese un teléfono fijo válido",
    //   });
    // }
    // if (!errorNuevoProveedor && (proveedor.telefonoCelular === "" || proveedor.telefonoCelular === null)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el teléfono celular",
    //   });
    // }
    // if (!errorNuevoProveedor && !proveedor.telefonoCelular.match(phoneformat)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese un teléfono celular válido",
    //   });
    // }

    // if (!errorNuevoProveedor && (proveedor.diasPago === "" || proveedor.diasPago === null)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese los días de pago",
    //   });
    // }
    // if (!errorNuevoProveedor && (proveedor.idTipoCompra === "" || proveedor.idTipoCompra === null)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el tipo de compra",
    //   });
    // }
    // if (!errorNuevoProveedor && (proveedor.idCatAlmacen === "" || proveedor.idCatAlmacen === null)) {
    //   errorNuevoProveedor = true;
    //   setUISweetAlert({
    //     ...UISweetAlert,
    //     error_dlg: true,
    //     dynamic_title: "Fallo",
    //     dynamic_description: "Por favor, ingrese el almacen",
    //   });
    // }

    return errorNuevoProveedor;
  };

  const agregarNuevoProveedor = async () => {
    const proveedorValido = validarDatosProveedor(datosNuevoProveedor);
    if (!proveedorValido) {
      setLoading(true);

      const res = await uploadNuevoProveedor(datosNuevoProveedor);

      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_nuevo_proveedor: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);

        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_nuevo_proveedor: false,
          dynamic_description: "Se guardó el nuevo proveedor correctamente",
        });
        setdatosNuevoProveedor(datosProveedorVacio);
      }
    }
  };

  const updateDataOnDeleteProveedor = async (idCatProveedor) => {
    const updatedData = data.filter((paciente) => paciente.idCatProveedor != idCatProveedor);
    setData(updatedData);
  };

  const checkeliminarProveedor = () => {
    if (autorizado && proveedoreseleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_eliminar_proveedor: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un proveedor",
      });
    }
  };

  const confirmEliminarProveedor = async () => {
    setLoading(true);
    const res = await eliminarProveedor(proveedoreseleccionado.idCatProveedor);
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        confirm_eliminar_proveedor: false,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    } else if (res) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        success_dlg: true,
        dynamic_title: "Exito",
        confirm_eliminar_proveedor: false,
        dynamic_description: "Se eliminó el proveedor correctamente",
      });
      updateDataOnDeleteProveedor(proveedoreseleccionado.idCatProveedor);
      setproveedoreseleccionado(null);
      setRowSeleccionada(null);
    }
  };

  const checkModificarProveedor = () => {
    if (autorizado && proveedoreseleccionado) {
      setUISweetAlert({
        ...UISweetAlert,
        confirm_modificar_proveedor: true,
      });
    } else {
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: "Por favor, seleccione un proveedor",
      });
    }
  };

  const confirmModicarProveedor = async () => {
    const proveedorValido = validarDatosProveedor(proveedoreseleccionado);
    if (!proveedorValido) {
      setLoading(true);
      const res = await updateProveedor(proveedoreseleccionado);
      if (res.data.error) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          error_dlg: true,
          confirm_modificar_proveedor: false,
          dynamic_title: "Fallo",
          dynamic_description: res.data.message,
        });
      } else if (res) {
        setLoading(false);
        setUISweetAlert({
          ...UISweetAlert,
          success_dlg: true,
          dynamic_title: "Exito",
          confirm_modificar_proveedor: false,
          dynamic_description: "Se modificó el proveedor correctamente",
        });

        const proveedoresActualizados = await data.map((proveedor) => {
          if (res.data.idCatProveedor == proveedor.idCatProveedor) {
            return (proveedor = res.data);
          } else {
            return proveedor;
          }
        });

        setData(proveedoresActualizados);
        setproveedoreseleccionado(null);
        setRowSeleccionada(null);
      }
    }
  };

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Catálogo de Proveedores</h3>
      <FiltroBusqueda
        toggleSearchBy={false}
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      <h4 className="flex h4 mt-5">Lista de proveedores</h4>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardBody>
              <Row>
                <Col>
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => confirmNuevoProveedor()}>
                      Nuevo proveedor
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkModificarProveedor()}>
                      Modificar proveedor
                    </button>
                  ) : null}
                  {autorizado ? (
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        marginRight: "2.5rem",
                        marginTop: "1rem",
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => checkeliminarProveedor()}>
                      Eliminar proveedor
                    </button>
                  ) : null}
                </Col>
              </Row>

              <TableComponent
                pageCount={pageCount}
                fetchData={fetchData}
                columns={columns}
                loading={loadingData}
                data={data}
                indexOfPage={controlledPageIndex}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {UISweetAlert.confirm_nuevo_proveedor ? (
        <SweetAlert
          title="Nuevo proveedor"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          showCancel
          closeOnClickOutside={false}
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            agregarNuevoProveedor();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_nuevo_proveedor: false,
            });
          }}>
          Por favor, llene todos los siguientes campos
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">
                    Nombre Proveedor<span className="text-xs text-red-700">*</span>
                  </label>

                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="proveedor"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.proveedor ? datosNuevoProveedor.proveedor : ""}
                    />
                    {datosNuevoProveedor.proveedor === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Razón Social<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="razonSocial"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.razonSocial ? datosNuevoProveedor.razonSocial : ""}
                    />
                    {datosNuevoProveedor.razonSocial === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-5 col-form-label">Tipo Compra</label>
                  <Col sm={7} className="pb-3">
                    <select
                      className="form-control"
                      name="idTipoCompra"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.idTipoCompra}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {tiposCompras.map((tipoCompra) => {
                        return <option value={tipoCompra.id}>{tipoCompra.nombre}</option>;
                      })}
                    </select>
                  </Col>                   

                  <label className="col-sm-5 col-form-label">Días de Crédito</label>
                  <Col sm={7} className="pb-3">
                  <NumericFormat
                      // disabled={true}
                      id="diasPago"
                      name="diasPago" 
                      thousandSeparator={true}
                      className="form-control"
                      decimalScale={0}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      displayType="input"
                      value={datosNuevoProveedor.diasPago ? datosNuevoProveedor.diasPago : ""}
                      onChange={changeNuevoProveedor}
                      // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                  />
                  </Col>
                  <label className="col-sm-5 col-form-label">
                    Nombre Contacto<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="contacto"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.contacto ? datosNuevoProveedor.contacto : ""}
                    />
                    {datosNuevoProveedor.contacto === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Correo Contacto<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="correo"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.correo ? datosNuevoProveedor.correo : ""}
                    />
                    {datosNuevoProveedor.correo === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    {datosNuevoProveedor.correo &&
                      !datosNuevoProveedor.correo.match(mailformat) &&
                      datosNuevoProveedor.correo !== "" && <span className="text-sm text-red-700">Correo inválido</span>}
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Teléfono Contacto<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="telefono"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.telefono ? datosNuevoProveedor.telefono : ""}
                    />
                    {datosNuevoProveedor.telefono === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                    {/* {datosNuevoProveedor.telefono == maxCharTelefono && (
                      <span className="text-sm text-red-700">El número máximo de caracteres es de {maxCharTelefono}</span>
                    )} */}
                    {datosNuevoProveedor.telefono &&
                      !datosNuevoProveedor.telefono.match(phoneformat) &&
                      datosNuevoProveedor.telefono !== "" && <span className="text-sm text-red-700">Teléfono inválido</span>}
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    RFC<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="rfc"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.rfc ? datosNuevoProveedor.rfc : ""}
                    />
                    {datosNuevoProveedor.rfc === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    {datosNuevoProveedor.rfc && !datosNuevoProveedor.rfc.match(rfcformat) && datosNuevoProveedor.rfc !== "" && (
                      <span className="text-sm text-red-700">RFC inválido</span>
                    )}
                  </Col>

                  <label className="col-sm-5 col-form-label">Calle</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="calle"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.calle ? datosNuevoProveedor.calle : ""}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-5 col-form-label">Municipio</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="municipio"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.municipio ? datosNuevoProveedor.municipio : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Num. Externo</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="numExterno"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.numExterno ? datosNuevoProveedor.numExterno : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Num. Interno</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="numInterno"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.numInterno ? datosNuevoProveedor.numInterno : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">
                    Código Postal<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="cp"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.cp ? datosNuevoProveedor.cp : ""}
                    />
                    {datosNuevoProveedor.cp === "" && <span className="text-sm text-red-700">Este campo es obligatorio</span>}
                    {datosNuevoProveedor.cp && !datosNuevoProveedor.cp.match(cpformat) && datosNuevoProveedor.cp !== "" && (
                      <span className="text-sm text-red-700">Código Postal inválido</span>
                    )}
                  </Col>

                  <label className="col-sm-5 col-form-label">Teléfono Fijo</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      maxLength={maxCharTelefono}
                      name="telefonoFijo"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.telefonoFijo ? datosNuevoProveedor.telefonoFijo : ""}
                    />
                  </Col>

                  <label className="col-sm-5 col-form-label">Teléfono Celular</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      maxLength={maxCharTelefono}
                      name="telefonoCelular"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.telefonoCelular ? datosNuevoProveedor.telefonoCelular : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Colonia</label>
                  <Col sm={7} className="pb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="colonia"
                      autoComplete="off"
                      onChange={changeNuevoProveedor}
                      value={datosNuevoProveedor.colonia ? datosNuevoProveedor.colonia : ""}
                    />
                  </Col>
                  <label className="col-sm-5 col-form-label">Estado</label>
                  <Col sm={7} className="pb-3">
                    <select
                      className="form-control"
                      onChange={changeNuevoProveedor}
                      name="idEstado"
                      value={datosNuevoProveedor.idEstado ? datosNuevoProveedor.idEstado : ""}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {lugares.map((lugar) => {
                        return <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>;
                      })}
                    </select>
                  </Col>      
                  {esContadora ? 
                  (    
                    <>     
                      <label className="col-sm-5 col-form-label">Monto Crédito</label>
                      <Col sm={7} className="pb-3">
                        <input
                          type="number"
                          className="form-control"
                          name="montoCredito"
                          autoComplete="off"
                          onChange={changeNuevoProveedor}
                          value={datosNuevoProveedor.montoCredito ? datosNuevoProveedor.montoCredito : ""}
                        />
                      </Col>
                    </>
                  ):null}

               </Row>
                <Row className="form-group mt-2">
                  <label className="col-sm-5 col-form-label"></label>
                  <Col sm={7}>
                    <button
                      className="btn btn-primary-purple-umo w-md waves-effect waves-light"
                      type="button"
                      style={{
                        background: "#ff9933",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => setdatosNuevoProveedor(datosProveedorVacio)}>
                      Limpiar Datos
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}
      {UISweetAlert.confirm_eliminar_proveedor ? (
        <SweetAlert
          title="¿Desea eliminar al proveedor seleccionado?"
          showCancel
          showConfirm
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          style={{
            width: "45%",
          }}
          onConfirm={() => confirmEliminarProveedor()}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_eliminar_proveedor: false,
            });
          }}></SweetAlert>
      ) : null}
      {UISweetAlert.confirm_modificar_proveedor ? (
        <SweetAlert
          title="Modificar Datos del Proveedor"
          confirmBtnText="Confirmar"
          confirmBtnBsStyle="success"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          closeOnClickOutside={false}
          showCancel
          style={{
            width: width && width < 851 ? "100%" : "80%",
          }}
          onConfirm={() => {
            confirmModicarProveedor();
          }}
          onCancel={() => {
            setUISweetAlert({
              ...UISweetAlert,
              confirm_modificar_proveedor: false,
            });
          }}>
          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">Nombre Comercial Proveedor</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="proveedor"
                      autoComplete="off"
                      value={proveedoreseleccionado.proveedor ? proveedoreseleccionado.proveedor : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Razón Social</label>
                  <Col sm={7}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="razonSocial"
                      autoComplete="off"
                      value={proveedoreseleccionado.razonSocial ? proveedoreseleccionado.razonSocial : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Tipo Compra</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      name="idTipoCompra"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.idTipoCompra}>
                      <option selected disabled value="">
                        Seleccione una opción
                      </option>
                      {tiposCompras.map((tipoCompra) => {
                        return <option value={tipoCompra.id}>{tipoCompra.nombre}</option>;
                      })}
                    </select>
                  </Col>

                  <label className="col-sm-4 col-form-label">Días de Crédito</label>
                  <Col sm={7}>
                  <NumericFormat
                      // disabled={true}
                      id="diasPago"
                      name="diasPago" 
                      thousandSeparator={true}
                      className="form-control"
                      decimalScale={0}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      displayType="input"
                      value={proveedoreseleccionado.diasPago ? proveedoreseleccionado.diasPago : ""}
                      onChange={changeModificarProveedor}
                      // onChange={(e) => changeInsumoPrecioUnitario(e, index, false)}
                  />                    
                  </Col>

                  <label className="col-sm-4 col-form-label">Nombre Contacto</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="contacto"
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.contacto ? proveedoreseleccionado.contacto : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">
                    Correo Contacto<span className="text-xs text-red-700">*</span>
                  </label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="correo"
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.correo ? proveedoreseleccionado.correo : ""}
                    />
                    {proveedoreseleccionado.correo === "" && (
                      <span className="text-sm text-red-700">Este campo es obligatorio</span>
                    )}
                    {proveedoreseleccionado.correo &&
                      !proveedoreseleccionado.correo.match(mailformat) &&
                      proveedoreseleccionado.correo !== "" && <span className="text-sm text-red-700">Correo inválido</span>}
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Contacto</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefono"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.telefono ? proveedoreseleccionado.telefono : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">RFC</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="rfc"
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.rfc ? proveedoreseleccionado.rfc : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Calle</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="calle"
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.calle ? proveedoreseleccionado.calle : ""}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <Row className="form-group mt-3">
                  <label className="col-sm-4 col-form-label">Municipio</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="municipio"
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.municipio ? proveedoreseleccionado.municipio : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Num. Externo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numExterno"
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.numExterno ? proveedoreseleccionado.numExterno : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Num. Interno</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="numInterno"
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.numInterno ? proveedoreseleccionado.numInterno : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Código Postal</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="cp"
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.cp ? proveedoreseleccionado.cp : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Fijo</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoFijo"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.telefonoFijo ? proveedoreseleccionado.telefonoFijo : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Teléfono Celular</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="telefonoCelular"
                      maxLength={maxCharTelefono}
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.telefonoCelular ? proveedoreseleccionado.telefonoCelular : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Colonia</label>
                  <Col sm={7}>
                    <input
                      type="text"
                      className="form-control"
                      name="colonia"
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.colonia ? proveedoreseleccionado.colonia : ""}
                    />
                  </Col>

                  <label className="col-sm-4 col-form-label">Estado</label>
                  <Col sm={7}>
                    <select
                      className="form-control"
                      onChange={changeModificarProveedor}
                      name="idEstado"
                      value={proveedoreseleccionado.idEstado}>
                      <option selected disabled>
                        Seleccione una opción
                      </option>
                      {lugares.map((lugar) => {
                        return <option value={lugar.idCatOrigenResidencia}>{lugar.lugar}</option>;
                      })}
                    </select>
                  </Col>    
                  
                  {esContadora ? 
                  (    
                    <>          
                  <label className="col-sm-4 col-form-label">Monto Crédito</label>
                  <Col sm={7}>
                    <input
                      type="number"
                      className="form-control"
                      name="montoCredito"
                      autoComplete="off"
                      onChange={changeModificarProveedor}
                      value={proveedoreseleccionado.montoCredito ? proveedoreseleccionado.montoCredito : ""}
                    />
                  </Col>
                  </>
                  ):null}
                </Row>
              </Col>
            </Row>
          </CardBody>
        </SweetAlert>
      ) : null}
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default Proveedores;
