/* eslint-disable import/no-anonymous-default-export */
import { LISTAR_CLIENTES, FILTRO_CLIENTES, SET_CLIENTE } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTAR_CLIENTES:
      return {
        ...state,
        clientes: action.payload,
      };
    case FILTRO_CLIENTES:
      return {
        ...state,
        filtrosClientes: action.payload,
      };
    case SET_CLIENTE:
      return {
        ...state,
        cliente: action.payload,
      };
    default:
      return state;
  }
};
