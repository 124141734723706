import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import AuthContext from "../../context/Auth/AuthContext";
import DetalleCorteCajaFarmacia from "./DetalleCorteCajaFarmacia";
import DetalleCorteCajaRecepcion from "./DetalleCorteCajaRecepcion";

export default function DetalleCorteCaja(props) {
  const { idCorteCaja } = useParams();

  // ACCESO AL ESTADO GLOBAL - AUTH
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  return (
    <>
      <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
        {usuario.authorities.includes("ROLE_RECEPCION") ? (
          <DetalleCorteCajaRecepcion idCorteCaja={idCorteCaja} usuarios={{ farmacia: 0, recepcion: 1 }} />
        ) : null}
        {usuario.authorities.includes("ROLE_FARMACIA") ? (
          <DetalleCorteCajaFarmacia idCorteCaja={idCorteCaja} usuarios={{ farmacia: 1, recepcion: 0 }} />
        ) : null}
      </div>
    </>
  );
}
