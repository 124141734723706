import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import PagosMedicoContext from "../../context/PagosMedico/PagosMedicoContext";

import InfoGeneralPagoMedico from "../InformacionGeneralPagoMedico";
import ListadoValesPagoMedico from "../ListadoValesPagoMedico";

export default function DetallePagoMedico(props) {
  const { folioValePago } = useParams();

  const pagosMedicoContext = useContext(PagosMedicoContext);

  const { pagoMedicoSeleccionado, seleccionarPagoMedico } = pagosMedicoContext;

  useEffect(() => {
    seleccionarPagoMedico(folioValePago);
  }, [seleccionarPagoMedico]);

  return (
    <div className="mt-4">
      {pagoMedicoSeleccionado ? <InfoGeneralPagoMedico pagoMedico={pagoMedicoSeleccionado} /> : null}
      {pagoMedicoSeleccionado ? <ListadoValesPagoMedico pagoMedico={pagoMedicoSeleccionado} /> : null}
    </div>
  );
}
