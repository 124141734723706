import React from "react";
import PropTypes from "prop-types";
import {AsyncPaginate} from "react-select-async-paginate";

/**
 * Componente reutilizable que permite la paginación en un select y la busqueda directa al back con una query
 * Se encarga de llevar el control de las página de forma automática, las opciones previas y las opciones nuevas
 *
 * @param {Object} opcionSeleccionadaCompleta - El objeto completo a mostrar cuando se selecciona la opción
 * @param {function} onChangeOptions - Una función para manejar el onChange cuando se selecciona una opción
 * @param {function} fetchOptions - Una función que permita regresar las opciones a mostrar
 * @param {Object} opcionesPaginado- Un objeto que tenga la configuración de paginado
 * @param {string} placeholderText - Opcional: String para mostrar en el placeholder del select
 * @param {function} optionLabel - Una función que recibe la opcion y regresa el texto a mostrar
 * (Ej: (option) => `${option.nombres} ${option.apellidoPaterno} ${option.apellidoMaterno}`
 * @param {function} optionValue - Una función que recibe la opcion y regresa el valor que se usará
 * (Ej: (option) => option.idCatPaciente)
 * @param {boolean} isDisabled - Booleano para saber si deshabilitar el select
 * @param {boolean} isMulti - Booleano para saber si se pueden seleccionar variar opciones
 * @param {string} name - Opcional: String para la propiedad name que se pueda usar en el onChange
 */

const SelectAsyncPaginate = ({
                                 opcionSeleccionadaCompleta,
                                 onChangeOptions,
                                 fetchOptions,
                                 opcionesPaginado,
                                 placeholderText,
                                 optionLabel,
                                 optionValue,
                                 isDisabled = false,
                                 isMulti = false,
                                 name = null,
                                 opcionesExtra = {menuPortalTarget: null, menuPosition: null},
                             }) => {
    /**
     * Función async que carga las options a desplegar en el select
     *
     * @param {string} searchQuery - Search query que el usuario escribió
     * @param {Object[]} loadedOptions - Las opciones que ya fueron cargadas previamente
     * @param {Object} param2 - La información de la paginación
     * @param {number} param2.page - La página actual
     * @returns {Object} Un objeto que contiene las opciones a ser mostradas, si hay más opciones, y datos adicionales.
     */
    const loadOptions = async (searchQuery, loadedOptions, {page}) => {
        // const nextPage = loadedOptions.length / opcionesPaginado.PAGE_SIZE;
        const response = await fetchOptions(page, opcionesPaginado.PAGE_SIZE, searchQuery);
        return {
            options: response.data,
            hasMore: response.hasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    /**
     * A function that handles the onChange event when an option is selected.
     *
     * @param {Object} option - The selected option.
     */
    const onChange = async (option) => {
        if (typeof onChangeOptions === "function") {
            await onChangeOptions(option, name);
        }
    };

    return (
        <AsyncPaginate
            name={name}
            isMulti={isMulti}
            isDisabled={isDisabled}
            value={opcionSeleccionadaCompleta}
            loadOptions={loadOptions}
            getOptionLabel={optionLabel}
            getOptionValue={optionValue}
            onChange={onChange}
            isSearchable={true}
            placeholder={placeholderText}
            additional={{
                page: 0,
            }}
        />
    );
};

SelectAsyncPaginate.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

export default SelectAsyncPaginate;
