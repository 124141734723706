import React from 'react';
import {Space, Spin} from 'antd';

const CustomSpinner = (props) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',  // This centers vertically
            justifyContent: 'center',  // This centers horizontally
            height: props.height ? props.height : "200px",  // You can adjust this to control the height of the spinner
        }}
    >
        <Space size="middle">
            <div className="content">
                {props.infoText ? "Cargando " + props.infoText + "..." : "Cargando..."}
            </div>
            <Spin tip="Cargando gráfica..." size="large"/>
        </Space>
    </div>
);

export default CustomSpinner;