import React, {useReducer} from 'react'

import TrasladoProductoInventarioContext from './TrasladoProductoInventarioContext'
import TrasladoProductoInventarioReducer from './TrasladoProductoInventarioReducer'

import {
    LISTAR_TRASLADO_PRODUCTOSINVENTARIOS,
    FILTROS_TRASLADOS
} from '../../types'

import clienteAxios from '../../config/axios'


export default function TrasladoProductoInventarioState (props) {

    const initialState = { 
        trasladosProductoInventario:[],
        filtrosTraslados: {}
    }

    const [state, dispatch] = useReducer(TrasladoProductoInventarioReducer,initialState)

    const listarTrasladosProductosInventario = async ({page, limit}) => {

        const res = await clienteAxios.post(`/traslado` , {...state.filtrosTraslados, pageSize: limit, pageIndex: page } , {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })

        dispatch({
            type: LISTAR_TRASLADO_PRODUCTOSINVENTARIOS,
            payload: res.data.traslados
        })

        return res;
        
    }

    const setFiltrosTraslados = async (filtrosTraslados) => {
        dispatch({
            type: FILTROS_TRASLADOS,
            payload: filtrosTraslados
        })
    }

    const uploadActualizarTraslado = async (traslado) => {

        try{
            const res = await clienteAxios.post(`/traslado/actualizar`,traslado, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                    }
            })
    
            return res;

        }catch (error){

            if(!error.response){
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error:"hubo error"
                    }
                }
            }

            return error.response;
        }

    }

    return (

        <TrasladoProductoInventarioContext.Provider value={{
            trasladosProductoInventario: state.trasladosProductoInventario,
            filtrosTraslados: state.filtrosTraslados,
            listarTrasladosProductosInventario,
            setFiltrosTraslados,
            uploadActualizarTraslado
        }}>
            {props.children}
        </TrasladoProductoInventarioContext.Provider>

    )


}