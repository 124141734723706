import React, {useReducer} from 'react'

import CatTipoOrdenCompraContext from './CatTipoOrdenCompraContext'
import CatTipoOrdenCompraReducer from './CatTipoOrdenCompraReducer'

import {
    LISTAR_CATTIPOORDENCOMPRAS,
} from '../../types'

import clienteAxios from '../../config/axios'


export default function CatTipoOrdenCompraState (props) {

    const initialState = { 
        tiposOrdenCompra:[],
    }

    const [state, dispatch] = useReducer(CatTipoOrdenCompraReducer,initialState)

    const listarTiposOrdenCompra = async () => {

        const res = await clienteAxios.get("/tipoordencompra/activos"  , {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
            dispatch({
                type: LISTAR_CATTIPOORDENCOMPRAS,
                payload: res.data
            })
    }

    return (

        <CatTipoOrdenCompraContext.Provider value={{
            tiposOrdenCompra: state.tiposOrdenCompra,
            listarTiposOrdenCompra,
        }}>
            {props.children}
        </CatTipoOrdenCompraContext.Provider>

    )

}