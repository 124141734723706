import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// import AuthContext from "../../context/Auth/AuthContext";
import ContabilidadReportesContext from "../../context/ContabilidadReportes/ContabilidadReportesContext";
import CatProductosContext from "../../context/CatProductos/CatProductosContext";
import ProveedoresContext from "../../context/Proveedores/ProveedoresContext";
import FabricantesContext from "../../context/Fabricantes/FabricantesContext";

import FiltroBusqueda from "../Filtros/FiltroBusqueda/FiltroBusqueda";

const ReporteUtilidades = () => {
  const [loading, setLoading] = useState(false);
  const [UISweetAlert, setUISweetAlert] = useState({
    success_dlg: false,
    reload_page: false,
    error_dlg: false,
    dynamic_title: "",
    dynamic_description: "",
  });

  const contabilidadReportesContext = useContext(ContabilidadReportesContext);
  const { setFiltrosReporteUtilidades, filtrosReporteUtilidades, exportReporteUtilidades } = contabilidadReportesContext;

  const ctxProductos = useContext(CatProductosContext);
  const { catProductos, listarCatProductos } = ctxProductos;

  const proveedoresContext = useContext(ProveedoresContext);
  const { listarProveedores, proveedores } = proveedoresContext;

  useEffect(() => {
    listarProveedores();
  }, []);

  const fabricantesContext = useContext(FabricantesContext);

  const { listarFabricantes, fabricantes } = fabricantesContext;

  useEffect(() => {
    listarFabricantes();
  }, []);

  useEffect(() => {
    listarCatProductos();
  });

  const filtrosVacios = {
    fechaInicial: "",
    fechaFinal: "",
    idCatProducto: null,
    idCatLaboratorio: null,
    idCatProveedor: null,
  };

  const buscarPorFiltros = async (e) => {
    e.preventDefault();

    setFiltrosReporteUtilidades(filtrosReporteUtilidades);
    generarReporte();
  };

  const generarReporte = async () => {
    setLoading(true);
    const res = await exportReporteUtilidades();
    if (res.data.error) {
      setLoading(false);
      setUISweetAlert({
        ...UISweetAlert,
        error_dlg: true,
        dynamic_title: "Fallo",
        dynamic_description: res.data.message,
      });
    }
    setLoading(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFiltrosReporteUtilidades({
      ...filtrosReporteUtilidades,
      [name]: value,
    });
  };

  const limpiarFiltros = () => {
    setFiltrosReporteUtilidades(filtrosVacios);
  };

  const opcionesProveedor = proveedores.map(({ idCatProveedor, proveedor, ...rest }) => ({
    ...rest,
    id: idCatProveedor,
    nombre: proveedor,
  }));

  const opcionesFabricante = fabricantes.map(({ idCatLaboratorio, laboratorio, ...rest }) => ({
    ...rest,
    id: idCatLaboratorio,
    nombre: laboratorio,
  }));

  const productosActivos = catProductos.map(({ idCatProducto, nombreComercial, ...medicamento }) => ({
    ...medicamento,
    id: idCatProducto,
    nombre: nombreComercial,
  }));

  const inputCols = [
    {
      col: [
        {
          colType: "input",
          label: "Fecha Inicial",
          type: "date",
          name: "fechaInicial",
          disabled: false,
          value: filtrosReporteUtilidades.fechaInicial,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Producto",
          name: "idCatProducto",
          value: filtrosReporteUtilidades.idCatProducto,
          opciones: productosActivos,
          onChange: onChange,
        },
        {
          colType: "select",
          label: "Fabricante (Laboratorio)",
          name: "idCatLaboratorio",
          value: filtrosReporteUtilidades.idCatLaboratorio,
          opciones: opcionesFabricante,
          onChange: onChange,
        },
      ],
    },
    {
      col: [
        {
          colType: "input",
          label: "Fecha Final",
          type: "date",
          name: "fechaFinal",
          disabled: !filtrosReporteUtilidades.fechaInicial ? true : false,
          min: filtrosReporteUtilidades.fechaInicial,
          value: filtrosReporteUtilidades.fechaFinal,
          onChange: onChange,
        },

        {
          colType: "select",
          label: "Proveedor",
          name: "idCatProveedor",
          value: filtrosReporteUtilidades.idCatProveedor,
          opciones: opcionesProveedor,
          onChange: onChange,
        },
      ],
    },
  ];

  return (
    <div className="mt-8 container-fluid" style={{ textAlign: "left" }}>
      <h3 className="flex h3">Reporte de Utilidades (Fabricante/Producto)</h3>
      <FiltroBusqueda
        toggleSearchBy={false}
        botonAplicar="Generar Reporte"
        buscarPorFiltros={buscarPorFiltros}
        onChange={onChange}
        limpiarFiltros={limpiarFiltros}
        paramsInput={inputCols}
      />
      {UISweetAlert.success_dlg ? (
        <SweetAlert
          success
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            if (UISweetAlert.reload_page) {
              window.location.reload();
            }
            setUISweetAlert({
              ...UISweetAlert,
              success_dlg: false,
              reload_page: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {UISweetAlert.error_dlg ? (
        <SweetAlert
          error
          title={UISweetAlert.dynamic_title}
          onConfirm={() => {
            setUISweetAlert({
              ...UISweetAlert,
              error_dlg: false,
            });
          }}>
          {UISweetAlert.dynamic_description}
        </SweetAlert>
      ) : null}

      {loading ? (
        <SweetAlert title="Cargando!" showConfirm={false}>
          <Spinner />
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default ReporteUtilidades;
