import React, {useReducer} from 'react'

import EstantesContext from './EstantesContext'
import EstantesReducer from './EstantesReducer'

import {
    LISTAR_ESTANTES,
    LIMPIAR_ESTANTES,
} from '../../types'

import clienteAxios from '../../config/axios'


export default function EstantesState (props) {

    const initialState = { 
        estantes: [],
        anaquelSeleccionado:{},
    }

    const [state, dispatch] = useReducer(EstantesReducer,initialState)

    const seleccionarAnaquel = async (idCatAnaquel) =>{
        const res = await clienteAxios.get(`/anaquel/detalles?id=${idCatAnaquel}`, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
        dispatch({
            type: LISTAR_ESTANTES,
            payload: res.data
        })
    }

    const uploadEstante = async (estante) => {

        try{

            const res = await clienteAxios.post("/estante/guardar", {...estante, anaquel: state.anaquelSeleccionado}, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                    }
            })
    
            seleccionarAnaquel(state.anaquelSeleccionado.idCatAnaquel);
            
            return res;

        }catch (error){

            if(!error.response){
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error:"hubo error"
                    }
                }
            }

            return error.response;
        }

    }

    const seleccionarAnaquelByEstante = async (idCatEstante) => {
        const res = await clienteAxios.get(`/anaquel/estante/detalles?id=${idCatEstante}`, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
  
        dispatch({
            type: LISTAR_ESTANTES,
            payload: res.data
        })
    }

    const limpiarEstantes = async() => {
        dispatch({
            type: LIMPIAR_ESTANTES,
            payload: null
        })
    }

    const uploadDeleteEstante = async (estante) => {

        try{

            const res = await clienteAxios.post("/estante/eliminar", estante, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                    }
            })
    
            seleccionarAnaquel(state.anaquelSeleccionado.idCatAnaquel);
            
            return res;

        }catch (error){

            if(!error.response){
                error.response = {
                    data: {
                        message: "Fallo en la conexión al servidor",
                        error:"hubo error"
                    }
                }
            }

            return error.response;
        }
    }
    

    return (

        <EstantesContext.Provider value={{
            estantes: state.estantes,
            anaquelSeleccionado: state.anaquelSeleccionado,
            seleccionarAnaquel,
            uploadEstante,
            seleccionarAnaquelByEstante,
            limpiarEstantes,
            uploadDeleteEstante
        }}>
            {props.children}
        </EstantesContext.Provider>

    )


}