const checkNombreMarcador = (nombreMarcador) => {
  // Object mapping of nombreMarcador values to corresponding keys
  const mappings = {
    talla: "talla",
    peso: "peso",
    imc: "indiceMasaCorporal",
    sct: "superficieCorporalTotal",
    "saturación oxígeno": "saturacionOxigeno",
    "tensión arterial sistólica": "tensionArterialSistolica",
    "tensión arterial diastólica": "tensionArterialDiastolica",
    "frecuencia cardiaca": "frecuenciaCardiaca",
    "frecuencia respiratoria": "frecuenciaRespiratoria",
    temperatura: "temperatura",
  };

  // Convert nombreMarcador to lowercase for case-insensitive matching
  const lowerCaseNombreMarcador = nombreMarcador.toLowerCase();

  // Iterate over the mappings object
  for (const key in mappings) {
    // Check if the key is present in the lowerCaseNombreMarcador
    if (lowerCaseNombreMarcador.includes(key)) {
      // Return the corresponding value if a match is found
      return mappings[key];
    }
  }

  // Return an empty string if no match is found
  return "";
};

const formatMarcadoresToObject = async (marcadores) => {
  let objectMarcadores = {};
  try {
    marcadores.forEach((marcador) => {
      const nombreMarcador = checkNombreMarcador(marcador.marcador);

      const ultimoIndex = marcador.valores && marcador.valores.length > 0 ? marcador.valores.length - 1 : null;

      const valor = ultimoIndex !== null ? marcador.valores[ultimoIndex] : null;

      objectMarcadores[nombreMarcador] = valor;
    });

    return objectMarcadores;
  } catch (error) {
    return error;
  }
};

export default formatMarcadoresToObject;
